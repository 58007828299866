/* eslint-disable camelcase */
import {
    Checkbox,
    FormControlLabel,
} from '@material-ui/core';
import { Container, ContainerPreview } from './Container';
import {
    FilterSettings,
    addCells,
    addOptionCells,
    buildSettingsTreePage,
    findChildComponentFlag,
    findComponent,
    findOptionCellMapping,
    findPage,
    findParent,
    inheritComponentFunction,
    setActiveComponent,
    setComponentType,
} from '../helper/commonHelper';
import { MenuDefaults, buildComponentMenu } from './utils/EditorMenu';
import { OrgContext, TimelineContext, WorkshopContext } from '../context';
import { Settings, makeInlineEditor } from './Settings';
import { TextAreaProps, TextBoxProps } from './forms/checkbox';
import { componentWithId } from './Atoms';
import { copyTemplateLR } from '../context/util';
import { makeStyles } from '@material-ui/core/styles';
import { standardHeaders } from './utils/StandardStyles';
import { toast } from 'react-toastify';
import {
    usePrevious,
} from '../helper/hooks';
import { useRecoilState } from 'recoil';
import { useRouteMatch } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import QuickAdd from './QuickAdd';
import React, {
    useContext, useEffect, useLayoutEffect, useMemo, useRef, useState,
} from 'react';
import TimelineColumn from './TimelineColumn';
import Typography from '@material-ui/core/Typography';

const titlee = {
    key: 'title',
    type: 'TextField',
    label: 'Enter Header',
    id: 'Column',
};
export const LRtitle = {
    key: 'title',
    type: 'TextField',
    label: 'Column Super Header',
    id: 'Column',

};

const defaultData = {
    selectSuggest: 'Select and Suggest',
};

export const TimelineColumnProps = {
    display: [
        {
            key: 'title',
            type: 'TextField',
            label: 'Column Title',
            isBasic: true,
            isAdvance: true,
            isAdmin: true,
            id: 'TimelineColumn',
        },
    ],
    poi: true,
};

const leftRightColumn = {
    key: 'columnType',
    type: 'ButtonList',
    label: 'Copy Column',
    buttons: [
        {
            type: 'left',
            label: 'Mirror Left',
            color: 'primary',
            show: false,
        },
        {
            type: 'right',
            label: 'Mirror Right',
            color: 'error',
            show: false,
        },
    ],
    isBasic: false,
    isAdvance: true,
    isAdmin: true,
    default: false,
};

export const ColumnProps = {
    display: [
        titlee,
        {
            key: 'showTitle',
            type: 'Radio',
            label: 'Show Column Header',
            options: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }],
            default: 'yes',
            id: 'Column',
        },
        {
            key: 'flexTextField',
            type: 'Select',
            label: 'Column Layout',
            id: 'Column',
            default: '1',
            options: [{ label: '1 Column', value: '1' },
                { label: '2 Columns', value: '2' },
                { label: '3 Columns', value: '3' }],
            isBasic: false,
            isAdvance: true,
            isAdmin: true,
        },
        {
            key: 'multiline_textArea',
            type: 'Radio',
            label: 'Multiline TextArea',
            options: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }],
            default: 'no',
            id: 'Column',
            isBasic: false,
            isAdvance: true,
            isAdmin: true,
        },
        {
            key: 'wow feature',
            type: 'Radio',
            label: 'WOW Feature',
            options: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }],
            default: 'no',
            id: 'Column',
            style: '',
            isBasic: false,
            isAdvance: true,
            isAdmin: true,
        },
        {
            key: 'hyperlink',
            type: 'TextFieldHyperlinkDebounce',
            label: 'Hyperlink',
            isBasic: true,
            isAdvance: true,
            isAdmin: true,
            id: 'Column',
        },
        {
            key: 'pcRelationship',
            type: 'Radio',
            label: 'Parent / Child Relationship',
            id: 'Column',
            value: 'standard',
            options: [{ label: 'Standard Spread Sheet P/C', value: 'standard' }, { label: 'Manually Select Children', value: 'manual' }],
            isBasic: true,
            isAdvance: true,
            isAdmin: true,
        },
    ],
    poi: true,
};
export const ColumnLRProps = {
    display: [
        LRtitle,
        {
            key: 'showTitle',
            type: 'Radio',
            label: 'Show Column Super Header',
            options: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }],
            default: 'yes',
            id: 'Column',
        }],
    poi: true,
};

const LRColumnProps = {
    display: [...ColumnLRProps.display, leftRightColumn], poi: true,
};

const useEditorStyles = makeStyles(() => ({
    borderCellCls: {
        borderBottom: '0.25px solid #ececec',
    },
    formControl: {
        width: '100%',
        alignItems: 'start',
        '& span, & svg': {
            fontSize: 16,
        },
        '& .MuiInput-root': {
            fontSize: 12,
        },
        '& input': {
            padding: '12px 7px',
        },
    },
    formControlWorkshop: {
        width: '99%',
        alignItems: 'start',
        borderRight: '1px solid #CBCBCB',
        '& span, & svg': {
            fontSize: 16,
        },
        '& .MuiInput-root': {
            fontSize: 12,
        },
        '& input': {
            padding: '12px 7px',
        },
    },
    formControl1: {
        margin: '0px',
        width: '100%',
        alignItems: 'center',
        marginBottom: 4,
        '& span, & svg': {
            fontSize: '0.625rem',
            margin: '0px 0px 4px 0px',
            overflow: 'hidden',
            fontWeight: 400,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        },
        '& > span:last-child': {
            margin: '0px 0px 8px 0px',
        },
        '& .MuiInput-root': {
            fontSize: 12,
        },
    },
    columHeadType: {
        textOverflow: 'ellipsis',
        display: 'flex',
        fontSize: '0.625rem !important',
        padding: '0px 2px',
        width: 'max-content',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        '&:hover': {
            overflowX: 'auto',
            '&::-webkit-scrollbar': {
                height: '2.5px',
                width: '2.5px',
                display: 'block',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#888',
            },
        },
    },
    columHeadType1: {
        textOverflow: 'ellipsis',
        display: 'flex',
        fontSize: '0.625rem !important',
        padding: '0px 4px',
        fontWeight: '400',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        '&:hover': {
            overflowX: 'auto',
            '&::-webkit-scrollbar': {
                height: '2.5px',
                width: '2.5px',
                display: 'block',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#888',
            },
        },
    },
    columHeadType2: {
        fontSize: '0.625rem !important',
        padding: '0px 2px',
        textAlign: 'center',
        width: 'max-content',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        display: 'flex',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        '&:hover': {
            overflowX: 'auto',
            textOverflow: 'clip',
            '&::-webkit-scrollbar': {
                height: '2.5px',
                width: '2.5px',
                display: 'block',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#888',
            },
        },
    },
    sectionHeader: {
        border: '1px solid #CBCBCB',
        ...standardHeaders.sectionHeader,
    },
    columnType2: {
        fontSize: '0.65rem',
    },
    checkBox: {
        padding: '0px 4px',
        '& svg': {
            height: '0.75em',
            width: '0.75em',
        },
        marginLeft: '8px',
    },
    checkBox1: {
        padding: '0px 2px 0px 0px',
        '& svg': {
            height: '.75rem',
            width: '.75rem',
            marginBottom: 2,
            color: '#000000de',
        },
        marginLeft: 0,
    },
    container: {
        '& p': {
            margin: 0,
            padding: '0px 2px',
        },
    },
    selectedComp: {
        background: 'cornflowerblue',
    },
    hideTextCls: {
        background: '#DEDEDE',
    },
    hideColumnCls: {
        display: 'none',
    },
    selectComponentText: {
        color: '#066785',
        fontSize: 12,
        fontWeight: '700',
        cursor: 'pointer',
        width: 'max-content',
        padding: '9px 6px !important',
    },
    borderRightSide: {
        borderBottom: 'none !important',
        background: '#ffffff',
    },
    selectComponentText1: {
        color: '#A2A2A2',
        fontSize: '.65rem',
        fontWeight: '700',
        cursor: 'pointer',
        width: 'max-content',
        padding: '9px 6px !important',
    },
    wrapperSelectComp: {
        background: '#ffffff',
        borderBottom: '.5px solid #CBCBCB',
    },
    wrapperSelectComp1: {
        background: '#ffffff',
    },
    activeLRSide: {
        pointerEvents: 'none',
        position: 'relative',
        backgroundColor: 'transparent',
    },
    inputLRStyle: {
        textAlign: 'start',
    },
    normalText: {
        color: 'inherit',
        textDecoration: 'none',
        cursor: 'text',
    },
    anchorTag: {
        color: '#066785',
        textDecoration: 'none',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    scrollCls: {
        '&::-webkit-scrollbar': {
            display:
                'none',
        },
        '&:hover': {
            '&::-webkit-scrollbar': {
                height: '2.5px',
                width: '2.5px',
                display: 'block',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#888',
            },
        },
    },
    multiSelectScrollRemove: {
        '&::-webkit-scrollbar': {
            display:
                'none',
        },
        '&:hover': {
            '&::-webkit-scrollbar': {
                height: '2.5px',
                width: '2.5px',
                display: 'block',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#888',
            },
        },
    },
    lastBorderRemo: {
        height: '22px !important',
        padding: '3.5px 2px',
        borderBottom: '.5px solid #CBCBCB',
    },
    disableClass: {
        background: '#0000001f',
        cursor: 'default',
        pointerEvents: 'none',
        '& p': {
            color: '#00000042',
        },
    },
    columnPrevTitle: {
        fontSize: 10,
        minHeight: '1rem',
        borderBottom: '.25px solid #cbcbcb',
        paddingLeft: 4,
    },
    sectionPrevTitle: {
        fontSize: 12,
    },
    accordionStyle: {
        margin: '4px !important',
        boxShadow: 'none',
        border: '1px solid #7070704E',
        borderRadius: '0px !important',
    },
    accSummary: {
        minHeight: '24px !important',
        margin: 2,
        background: '#efefef',
        flexDirection: 'row-reverse',
        padding: '0px 4px',
        '& > :first-child': {
            margin: 0,
        },
        '& p': {
            width: '100%',
            fontSize: 13,
        },
    },
    expandedAccIcon: {
        transform: 'rotateZ(90deg) translateY(-6px) !important',
        color: '#000000',
        padding: 0,
        marginRight: 8,
    },
    iconButton: {
        margin: '0 8px 0 2px',
        padding: 0,
        color: '#000000',
        borderRadius: 2,
    },
    accDetails: {
        padding: 2,
    },
    settingHeading: {
        margin: 0,
        padding: 2,
        background: '#efefef',
        border: '1px solid #ececec',
        borderBottom: 'none',
        fontSize: 13,
    },
    redBg: {
        backgroundColor: '#ff8a8a',
    },
    greenBg: {
        backgroundColor: '#8cf98c',
    },
}));

const Column = ({
    column, parent, index, classes, totalColumns, layout, flexTextField, inProtocol, componentParent2, componentIndex, componentColumn,
}) => {
    const editorClasses = useEditorStyles();
    const {
        // eslint-disable-next-line no-unused-vars
        inWorkshop: workshopOpen,
        handleContextMenu,
        duplicateComponent,
        settingComponent,
        setSettingComponentHandle,
        domain: contextDomain,
        setDirtyDomain,
        selectedTimeline,
        pageComponent: pageComponentRef,
        dirtyDomain,
        handleNewColumn,
        workshopActiveComponent,
        workshopComponent,
        selectedCF,
        setWorkshopComponent,
        selectCompCF,
        lastDeletedComp,
        selectedOptionIdsPreview,
        newCellAdded,
        selectedTemplate,
        hyperlinkCip,
        setHyperlinkCip,
        setHyperlinkCipText,
        setHyperlinkComponent,
        updateTimeline,
        complexFormType,
        setUpdateFlag,
        updateFlag,
        isEditMode,
        isMobile
    } = useContext(TimelineContext);
    const { inWorkshop, compareModal } = useContext(WorkshopContext);
    const {
        loggedUserType,
    } = useContext(OrgContext);
    let domain = contextDomain;
    const listType = parent?.props?.listTypePreview || '';
    const { hideFromWorkshop = false, hyperlink } = column.props;
    if (column?.previewDomain) {
        domain = column.previewDomain;
    }
    const [component, setComponentState] = useRecoilState(
        componentWithId(column.id, column.props, parent.props.components)
    );
    const [componentParent] = useRecoilState(componentWithId(column.parent));
    const [componentProtocol] = useRecoilState(componentWithId(column.props.protocolId));
    const [flag, setFlag] = useState(false);
    const [isLRChild, setIsLRChild] = useState(false);
    let firstColumn;
    const [secondColumn, setSecondColumn] = useState(null);
    const [thirdColumn, setThirdColumn] = useState(null);
    const positionCheck = column.props.index === 0 && column.props.isComplexForm;
    if (positionCheck) {
        firstColumn = column;
    }
    const isWowFeature = componentParent?.props?.title === 'Row' && componentParent?.props?.components?.some((c) => c.props['wow feature'] === 'yes');
    const notWowFeature = !isWowFeature && componentProtocol?.props?.['wow feature'];
    const checkWow = isWowFeature && componentProtocol.props;
    if (checkWow) {
        componentProtocol.props['wow feature'] = 'yes';
    }
    if (notWowFeature) {
        componentProtocol.props['wow feature'] = 'no';
    }
    useEffect(() => {
        if (firstColumn?.props?.components.length === 1) {
            const workShopList = [];
            buildSettingsTreePage(workShopList, workshopComponent);
            const secondColumnVal = workShopList.find((el) => el.type === 'Column' && el.props.index === 1 && el.props.isComplexForm);
            setSecondColumn(secondColumnVal);
            const thirdColumnVal = workShopList.find((el) => el.type === 'Column' && el.props.index === 2 && el.props.isComplexForm);
            setThirdColumn(thirdColumnVal);
        }
    }, [firstColumn?.props?.components.length]);

    // when user change Parent Child Relationship Setting
    useEffect(() => {
        if (component?.props?.pcRelationship) {
            if (inWorkshop && workshopComponent) {
                if (workshopComponent?.component === 'Universal One Sided CF') {
                    if (Array.isArray(component.parent)) {
                        component.parent.forEach((c) => {
                            c.props.pcRelationship = component.props.pcRelationship || 'standard';
                        });
                    }
                } else if (workshopComponent?.component === 'Universal LR CF') {
                    const newArr = [];
                    buildSettingsTreePage(newArr, workshopComponent);
                    for (let i = 0, l = newArr.length; i < l; i += 1) {
                        if (newArr[i].type === 'Column') {
                            if (component?.props?.LRProps) {
                                newArr[i].props.pcRelationship = component.props.pcRelationship || 'standard';
                            }
                        }
                    }
                }
                workshopComponent.props.pcRelationship = component?.props?.pcRelationship;
                setUpdateFlag(!updateFlag);
            }
        } else if (inWorkshop && workshopComponent) {
            if (workshopComponent?.component === 'Universal One Sided CF') {
                if (Array.isArray(component.parent)) {
                    component.parent.forEach((c) => {
                        c.props.pcRelationship = 'standard';
                    });
                }
            } else if (workshopComponent?.component === 'Universal LR CF') {
                const newArr = [];
                buildSettingsTreePage(newArr, workshopComponent);
                for (let i = 0, l = newArr.length; i < l; i += 1) {
                    if (newArr[i].type === 'Column') {
                        if (component?.props?.LRProps) {
                            newArr[i].props.pcRelationship = 'standard';
                        }
                    }
                }
            }
            workshopComponent.props.pcRelationship = 'standard';
            setUpdateFlag(!updateFlag);
        }
    }, [component?.props?.pcRelationship]);

    // when user change Column Layout Setting
    useEffect(() => {
        if (component?.props?.flexTextField && workshopComponent && inWorkshop) {
            if (Array.isArray(component.parent)) {
                component.parent.forEach((c) => {
                    c.props.flexTextField = component.props.flexTextField;
                });
            }
            workshopComponent.props.flexTextField = component?.props?.flexTextField;
            setUpdateFlag(!updateFlag);
        }
    }, [component?.props?.flexTextField]);

    if (!component.props) {
        component.props = { ...column.props };
    }

    if (!component.parent && componentParent?.props) {
        component.parent = componentParent?.props?.components ? [...componentParent.props.components] : [];
    }

    const pageComponent = pageComponentRef && Object.keys(pageComponentRef).length > 0
        ? pageComponentRef : selectedTimeline && findPage(selectedTimeline?.columns, component?.props?.parentID)?.[0];
    const componentList = [];
    buildSettingsTreePage(componentList, pageComponent && pageComponent[domain]);
    const currentComp = componentList.find((item) => item.id === column.id);
    const isPageElem = currentComp && domain
        && (currentComp.type === 'Page' || currentComp.props.pageChild || (currentComp.name === 'Page' && currentComp.type !== 'Protocol'));
    if (isPageElem && currentComp && currentComp[domain]) {
        component[domain] = currentComp[domain];
    }
    const pageDomain = component[domain];

    const filterComp = parent.props.components.find((comp) => comp.id === column.id);
    const inPage = !!(filterComp && filterComp[domain]);

    const colTitle = domain && pageDomain && inPage ? (pageDomain.title || '') : (component.props && component.props.title || '');

    const [columnTitle, setColumnTitle] = useState({
        __html: column?.props?.hyperlink
            ? `<a href=${column?.props?.hyperlink} class=${editorClasses.anchorTag} target='_blank'>${colTitle}</a>`
            : colTitle,
    });
    const columnTitleRef = useRef(null);
    const [columnTitleContextMenu, setColumnTitleContextMenu] = useState(null);

    const { components } = component?.props;
    const [, setDuplicateColumnLength] = useState(0);
    const [shouldUpdate, setShouldUpdate] = useState(false);
    let isSelected = false;
    // eslint-disable-next-line no-unused-vars
    let isShowEditorAction = true;

    if (settingComponent && settingComponent.id === column.id) {
        isSelected = true;
    }
    const click = (e) => {
        handleContextMenu(e, column, parent, index, null, true);
    };

    if (typeof component?.props?.showTitle === 'boolean') {
        component.props.showTitle = component.props.showTitle ? 'yes' : 'no';
    }
    let { showTitle = 'yes' } = component.props;
    showTitle = (showTitle === 'yes');

    let columnComp = isPageElem ? component[domain].components : column.props.components || [];
    if (inWorkshop === undefined && parent.props.group === 'Page Groups' && column.props.components) {
        const pIndex = column.props.components.findIndex((d) => d.Medguide);
        columnComp = isPageElem ? column[domain].components : column.props?.components ? [column.props.components[pIndex]] : [{}];
    }

    if (inWorkshop && parent?.props?.group === 'Page Groups' && parent?.props?.components[0]?.type === 'Column') {
        isShowEditorAction = false;
    }

    let size = component.props.size === 'auto' ? true : parseInt(component.props.size, 10);
    if (inProtocol && layout !== 'stacked') {
        size = 12 / totalColumns;
    }
    const currentTitleStatus = column?.props?.showTitle || 'yes';
    const isOneSidedCf = ((column?.props?.isComplexForm || column?.props?.isMixedForm)
        && !column?.props?.hasOwnProperty('isLR') && !column?.props?.hasOwnProperty('LRProps'));

    const isLRSuperHeader = column?.props?.isLR && !column?.props?.hasOwnProperty('LRProps');

    const isLRcompHeader = !column?.props?.hasOwnProperty('isLR') && column?.props?.LRProps;

    // /** handling show column Title from radio setting */
    useEffect(() => {
        if (workshopOpen && Array.isArray(component?.parent) && currentTitleStatus) {
            if ((isOneSidedCf || isLRSuperHeader)) {
                component.parent.forEach((c) => {
                    c.props.showTitle = currentTitleStatus;
                });
            } else if (isLRcompHeader) {
                component.parent.forEach((c) => {
                    c.props.showTitle = currentTitleStatus;
                });
            }
            setUpdateFlag(!updateFlag);
        }
    }, [component.props.showTitle]);

    useEffect(() => {
        if (componentParent?.props?.components?.length > 1 && workshopActiveComponent?.type === 'Column') {
            componentParent.props.components.forEach((c) => {
                if (c.id !== workshopActiveComponent?.id) {
                    c.props['wow feature'] = workshopActiveComponent.props['wow feature'];
                }
            });
        } else if (!['Column', 'Protocol'].includes(workshopActiveComponent?.type) && workshopActiveComponent?.colRef === column?.id) {
            componentParent.props.components.forEach((c) => {
                if (c.id !== workshopActiveComponent?.colRef) {
                    c.props['wow feature'] = column.props['wow feature'];
                }
            });
        }
    }, [component.props['wow feature']]);

    // /**LR Height Fix in Preview */
    useEffect(() => {
        if (parent?.component === 'L/R CF' && column?.props?.components) {
            const protocolId = column?.props?.components?.[0]?.id;
            if (protocolId) {
                // might need to use the following code , but using dom manipulation for now
                const protocolComp = document.querySelector(`#false-${protocolId}`);
                const gridComp = protocolComp?.querySelector('.mainGrid');
                if (gridComp) {
                    gridComp.style.flexDirection = 'row';
                }
            }
        }
    }, [parent?.component, column]);

    useEffect(() => {
        const pageDomain = component[domain];
        if (dirtyDomain) {
            return;
        }
        if (pageDomain && Object.keys(pageDomain).length > 0) {
            const currentComp = componentList.find((c) => c.id === column.id);

            if (currentComp) {
                currentComp[domain] = pageDomain;
            }
        }
    }, [domain, component, column, dirtyDomain]);

    useEffect(() => {
        const isLRComponent = workshopComponent?.props?.leftRightColumn;
        const addNewColLR = !!(isLRComponent && componentParent?.props?.components?.length < 2);
        if (workshopComponent?.component !== 'SELECT-COMPLETE') {
            if (workshopComponent && column?.props?.title
                && ((column?.props?.isComplexForm && !isLRComponent)
                    || (column?.props?.LRProps && addNewColLR)
                    || column?.props?.isMixedForm) && (!workshopComponent?.props?.isSimpleForm)) {
                handleNewColumn(column);
            }
        }
    }, [column?.props?.title]);

    const resetHighlitedColor = (data) => {
        if (data?.length) {
            data.forEach((colComp) => {
                if ((colComp?.type === 'Select' || colComp?.type === 'MultiSelect') && colComp?.props?.options?.length > 0) {
                    colComp.props.options.forEach((option) => {
                        if (!inWorkshop && (!workshopComponent?.props?.['wow feature'] && !workshopComponent?.props?.isCompleted)) {
                            if (option?.props?.associatedCell) {
                                option.isSelect = option.props.associatedCell === workshopActiveComponent.parent;
                            } else {
                                option.isSelect = (colComp.id === workshopActiveComponent.id
                                    || colComp.id === workshopActiveComponent?.associatedParent) ? option.isSelect : false;
                            }
                        }
                    });

                    if (colComp.id === workshopActiveComponent?.associatedParent && workshopActiveComponent.props?.options?.length > 0) {
                        const isFirstCol = colComp.props.options.some((item) => item.id);
                        // We dont need to lookup OptionCells for the options which have id
                        if (!isFirstCol) {
                            const parentComp = findOptionCellMapping(workshopComponent, workshopActiveComponent.id);
                            // eslint-disable-next-line array-callback-return
                            colComp.props.options.find((option, optionIndex) => {
                                option.isSelect = (optionIndex === parentComp?.index) || false;
                            });
                        }
                    }

                    if (colComp.id === workshopActiveComponent?.associatedParent && colComp.associatedParent) {
                        const parent = findComponent(workshopComponent.props.components, colComp.associatedParent, '') || null;
                        if (parent?.props) parent.props.isSelect = true;
                        const isFirstCol = parent?.props?.options?.some((item) => item.id);
                        if (isFirstCol) {
                            parent.props.options.forEach((option) => {
                                option.isSelect = option?.props?.associatedCell && option.props.associatedCell === colComp.parent;
                            });
                        }
                    }
                }

                if (colComp?.type === 'Checkbox') {
                    colComp.props.isSelect = (colComp.id === workshopActiveComponent?.associatedParent) || false;
                }

                if (colComp.props.components) {
                    resetHighlitedColor(colComp.props.components);
                }
            });
        }
    };

    useEffect(() => {
        if (!isEditMode) {
            if (component.props.title === component.props.prevTitle) {
                return;
            }
            if (!workshopComponent?.props.components || !component.props.LRProps) {
                return;
            }
            component.props.prevTitle = component.props.title;
            const { LRtype, mainParent, LRparent } = component.props.LRProps;
            if (LRtype === 'left') {
                const parentComp = findComponent(workshopComponent?.props.components, mainParent);
                const compIndex = parentComp?.props.components.findIndex((comp) => comp.id === LRparent);
                setShouldUpdate(true);
                if (parentComp) {
                    parentComp.props.components[compIndex].props.components.forEach((activeComp, i) => {
                        const compToUpdate = parentComp.props.components[compIndex + 1].props.components[i];
                        let LRPropsBak;
                        if (compToUpdate.props.LRProps) {
                            LRPropsBak = { ...compToUpdate.props.LRProps };
                        }
                        parentComp.props.components[compIndex + 1].props.components[i] = copyTemplateLR(activeComp, LRPropsBak, workshopComponent);
                    });
                }
            }
        }
    }, [component, workshopComponent?.props.components]);

    useEffect(() => {
        if (!isEditMode) {
            if (!component) return;
            if (!workshopComponent?.props.components || !component.props.LRProps) {
                return;
            }
            component.props.prevTitle = component.props.title;
            const { LRtype, mainParent, LRparent } = component.props.LRProps;
            if (LRtype === 'left') {
                const parentComp = findComponent(workshopComponent?.props.components, mainParent);
                const compIndex = parentComp?.props.components.findIndex((comp) => comp.id === LRparent);
                setShouldUpdate(true);
                if (parentComp) {
                    parentComp.props.components[compIndex].props.components.forEach((activeComp, i) => {
                        const compToUpdate = parentComp.props.components[compIndex + 1].props.components[i];
                        let LRPropsBak;
                        if (column?.props?.isMixedForm && compToUpdate?.props?.LRProps) {
                            LRPropsBak = { ...compToUpdate.props.LRProps };
                        }
                        parentComp.props.components[compIndex + 1].props.components[i] = copyTemplateLR(activeComp, LRPropsBak, workshopComponent);
                    });
                }
            }
        }
    }, [lastDeletedComp]);

    useEffect(() => {
        if (column.props && !!Object.prototype.hasOwnProperty.call(column.props, 'columnType')) {
            /** LR extra height fix (Has an extra column for some reason) */
            const LRcompList = [];
            buildSettingsTreePage(LRcompList, column);
            LRcompList.slice(1).forEach((item) => {
                item.props.LRProps = { LRparent: column.id, mainParent: column.parent, LRtype: column.props.columnType };
            });
        }
    }, [column]);

    useEffect(() => {
        if (shouldUpdate) {
            setWorkshopComponent((comp) => ({ ...comp }));
        }
    }, [shouldUpdate]);

    useEffect(() => {
        setShouldUpdate(false);
    }, [workshopComponent]);

    useEffect(() => {
        setIsLRChild(Boolean(component?.props?.LRProps));
    }, [component?.props?.LRProps]);

    let selectedIndex = -1;
    let showSelectBtn = false;
    if (workshopActiveComponent && workshopComponent) {
        const resultParent = [];
        findParent(workshopComponent.props.components, workshopActiveComponent.id, resultParent);
        if (workshopActiveComponent.type === 'Column'
            && (column.props.isComplexForm || Object.prototype.hasOwnProperty.call(column.props, 'columnType')
                || Object.prototype.hasOwnProperty.call(column.props, 'LRProps')) && resultParent.length > 0) {
            selectedIndex = resultParent[0].props.components.findIndex((c) => c.id === column.id);
        } else if (workshopActiveComponent?.type !== 'Column' && resultParent[0]?.type === 'Column') {
            const resultColParent = [];
            findParent(workshopComponent.props.components, resultParent[0].id, resultColParent);
            if (resultColParent[0]?.props?.components) {
                const selectedColIndex = resultColParent[0].props.components.findIndex((c) => c.id === resultParent[0].id);
                const d = selectedCF && selectedCF.find((c) => c?.index === (selectedColIndex - 1));
                if (selectedColIndex > 0 && d) {
                    d.component.props.children = selectedCF.find((c) => c.index === selectedColIndex).component;
                }
            }
        }

        switch (workshopActiveComponent.type) {
        case 'Checkbox':
            showSelectBtn = !workshopActiveComponent.props.children;
            break;
        case 'Select': {
            const selectedOption = workshopActiveComponent.props.options.find((o) => o.isSelect);
            showSelectBtn = !!(selectedOption && !selectedOption.children);
        }
            break;
        case 'MultiSelect': {
            const multiSelectedOption = workshopActiveComponent.props.options.find((o) => o.isSelect);
            showSelectBtn = !!(multiSelectedOption && !multiSelectedOption.children);
        }
            break;
        default:
        }

        resetHighlitedColor(workshopComponent.props.components);
    }

    useEffect(() => {
        setFlag(!flag);
    }, [selectCompCF]);

    const renderTitleEditor = () => {
        let extraProps = {};

        if (component.props.pageChild) {
            extraProps = {
                ...extraProps,
                domain,
                setDirtyDomain,
            };
        }
        return (
            <>
                {makeInlineEditor({
                    field: titlee,
                    component,
                    setComponentState,
                    classes: editorClasses,
                    ...extraProps,
                })}
            </>
        );
    };

    let showColTitle = true;

    if (component.props.isComplexForm) {
        const colComponents = [...component.props.components];
        let flag = true;
        colComponents.forEach((c) => {
            if (c?.type === 'Cell') {
                flag = c.props.components.length === 0 ? (flag || false) : true;
            } else if (c?.type === 'EmptyCell') {
                flag = flag || false;
            } else if (c?.type === 'SelectCell') {
                c.props.components.forEach((optionCell) => {
                    flag = optionCell.props.components.length === 0 ? (flag || false) : true;
                });
            }
        });
        showColTitle = component.props.components.length === 0 ? false : flag;
        component.props.isShowTimeline = component.props.components.length === 0 && colTitle.length === 0 ? false : flag;
    } else {
        component.props.isShowTimeline = !(component.props.components.length === 0 && colTitle.length === 0);
    }

    if (component.props.isLR) {
        component.props.isShowTimeline = true;
    }

    if (component.props.isComplexForm && component.parent && !inWorkshop && (Array.isArray(component.parent))) {
        const d = component?.parent.filter((c) => (c?.props?.renderColumnInTimeline !== false
            && (Object.prototype.hasOwnProperty.call(c?.props, 'isShowTimeline')
                ? (c?.props?.title ? true : c?.props?.isShowTimeline) : (!!c.props?.title))));
        size = 12 / d.length;
        if (d?.[0]?.props?.isComplexForm && !inWorkshop) {
            let count = 0;
            if (d?.[0]?.props?.LRProps) {
                count = d?.[0]?.props?.title || d?.[0]?.props?.components?.length > 0 ? 1 : 0; // LF CF 1st column
                if (d?.[1]?.props?.title) {
                    count += 1;
                } else {
                    for (let i = 0, l = d?.[1]?.props?.components?.length; i < l; i += 1) { // LR CF 2nd column
                        if (d?.[1]?.props?.components?.[i]?.props?.components?.length > 0) {
                            count += 1;
                            break;
                        }
                    }
                }
                size = 12 / count;
            } else {
                count = d?.[0]?.props?.title || d?.[0]?.props?.components?.length > 0 ? 1 : 0; // 1 sided CF, 1st column
                if (d?.[1]?.props?.title) { // 1 sided CF, 2nd column
                    count += 1;
                } else {
                    for (let i = 0, l = d?.[1]?.props?.components?.length; i < l; i += 1) {
                        if (d?.[1]?.props?.components?.[i]?.props?.components?.length > 0) {
                            count += 1;
                            break;
                        }
                    }
                }
                if (d?.[2]?.props?.title) { // 1 sided CF, 3rd column
                    count += 1;
                } else {
                    OUTER_LOOP: for (let i = 0, l = d?.[2]?.props?.components?.length; i < l; i += 1) {
                        for (let j = 0, m = d?.[2]?.props?.components?.[i]?.props?.components?.length; j < m; j += 1) {
                            if (d?.[2]?.props?.title || (
                                d?.[2]?.props?.components?.[i]?.props?.components?.[j]?.type === 'Checkbox'
                                || d?.[2]?.props?.components?.[i]?.props?.components?.[j]?.props?.components?.length > 0
                            )) {
                                count += 1;
                                break OUTER_LOOP;
                            }
                        }
                    }
                }
                size = 12 / count;
            }
        }
    }

    useEffect(() => {
        if (!hyperlinkCip) {
            let nodeTextConcat = '';
            const requiredChildNodes = columnTitleRef?.current?.childNodes;
            if (requiredChildNodes) {
                requiredChildNodes.forEach((childNode) => {
                    nodeTextConcat += childNode.textContent;
                });
                if (nodeTextConcat !== colTitle) {
                    setColumnTitle({
                        __html: colTitle,
                    });
                }
            }
        }
    }, [colTitle]);

    const openColumnTitleContextMenu = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setColumnTitleContextMenu(columnTitleContextMenu === null ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 } : null);
    };

    const closeColumnTitleContextMenu = () => {
        setColumnTitleContextMenu(null);
    };

    const addEditHyperLink = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setHyperlinkCip(true);
        setHyperlinkCipText(columnTitleRef?.current?.textContent);
        setHyperlinkComponent(column);
        handleContextMenu(
            event,
            componentParent2?.components[componentIndex],
            { ...componentParent2, components: [{ ...componentParent2?.components[componentIndex] }] },
            componentIndex,
            componentColumn,
        );
    };

    const removeHyperLink = () => {
        delete column?.props?.hyperlink;
        setColumnTitleContextMenu(null);
        setColumnTitle({
            __html: colTitle,
        });
        updateTimeline();
    };
    const renderTitle = () => {
        const title = (
            <>
                <div
                    className={editorClasses.lastBorderRemo}
                    style={{
                        overflow: !inWorkshop || ((!column?.props?.isComplexForm || !component?.props?.LRProps || component?.props?.isLR)) && 'auto',
                        height: (column?.props?.isComplexForm || component?.props?.LRProps) && colTitle?.length > 0 && '1.125rem',
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    role="navigation"
                    aria-label="primary"
                >
                    <Typography
                        className={(column?.props?.isComplexForm || component?.props?.LRProps) && colTitle?.length > 0
                            ? editorClasses.columHeadType1 : component?.props?.isLR ? editorClasses.columHeadType2 : editorClasses.columHeadType}
                        color="inherit"
                        dangerouslySetInnerHTML={columnTitle}
                        ref={columnTitleRef}
                        onContextMenu={!hyperlinkCip && !column?.isProgression && columnTitle ? openColumnTitleContextMenu : undefined}
                    />
                    <Menu
                        open={columnTitleContextMenu !== null}
                        onClose={closeColumnTitleContextMenu}
                        anchorReference="anchorPosition"
                        anchorPosition={
                            columnTitleContextMenu !== null
                                ? { top: columnTitleContextMenu.mouseY, left: columnTitleContextMenu.mouseX }
                                : undefined
                        }
                    >
                        {hyperlink ? (
                            <>
                                <MenuItem onClick={addEditHyperLink}>Edit Hyperlink</MenuItem>
                                <MenuItem onClick={removeHyperLink}>Remove Hyperlink</MenuItem>
                            </>
                        ) : (
                            <MenuItem onClick={addEditHyperLink}>Add Hyperlink</MenuItem>
                        )}
                    </Menu>
                </div>
            </>
        );
        if (((typeof component.props.columnCB === 'boolean' && component.props.columnCB)
            || component.props.columnCB === 'yes') && component.props.title) {
            return (
                <div className={editorClasses.lastBorderRemo}>
                    <FormControlLabel
                        label={component.props.title}
                        className={editorClasses.formControl1}
                        control={<Checkbox className={editorClasses.checkBox1} />}
                    />
                </div>
            );
        }
        return title;
    };

    let showCarousel = !inWorkshop;
    const lastComponent = inWorkshop
        && components
        && components.length > 0
        && components[components.length - 1];
    const quickAddSupported = lastComponent
        && (lastComponent.type === 'TextInput');

    const onQuickAdd = (label) => {
        let otherArg = {};
        if (lastComponent && lastComponent.type === 'TextInput') {
            otherArg = { label, textField: label };
        } else {
            otherArg = { label };
        }
        const duplicateComp = duplicateComponent(lastComponent, components, components.length - 1, otherArg);
        inheritComponentFunction(workshopComponent, duplicateComp, TextAreaProps);
        if (duplicateComp?.props?.['form-control'] || component?.props?.['form-control'] === 'yes') {
            delete duplicateComp?.props?.isCBChecked;
        }
        if (loggedUserType === 'Doctor' && workshopComponent?.name === 'Form') {
            duplicateComp.props.textField = '';
            duplicateComp.props.textPlaceholder = label;
            duplicateComp.props.loggedUserType = loggedUserType;
            duplicateComp.props.name = workshopComponent?.name;
        }
        if (workshopComponent.supergroup === 'Form' && lastComponent.type === 'TextInput' && lastComponent.props.hideFromWorkshop) {
            duplicateComp.props.hideFromWorkshop = false;
        }
        setDuplicateColumnLength(components.length);
        const parentColumnComp = findComponent(workshopComponent?.props?.components, duplicateComp?.parent);
        if (parentColumnComp?.props?.isComplexForm || parentColumnComp?.props?.LRProps) {
            parentColumnComp.props.components.forEach((item) => {
                if (!item?.props?.associatedCell) {
                    addCells({
                        mainComp: parentColumnComp, associatedComponent: item, workshopComponent, LRProps: parentColumnComp.props.LRProps,
                    });
                }
            });
        }
    };
    const carouselComps = [];
    const filterGalleryComp = columnComp && columnComp.filter((col) => col?.type === 'Gallery');
    if (filterGalleryComp && filterGalleryComp.length === 0) {
        showCarousel = false;
    }
    if (showCarousel) {
        let lastGallery = false;
        for (let i = 0; i < columnComp.length; i += 1) {
            const colComponent = columnComp[i];
            if (colComponent?.type === 'Gallery') {
                if (lastGallery) {
                    const lastItem = carouselComps[carouselComps.length - 1];
                    carouselComps[carouselComps.length - 1] = { ...lastItem, props: { components: [...lastItem.props.components, colComponent] } };
                } else {
                    const itemIndex = carouselComps.length;
                    const nextItem = columnComp[i + 1];
                    if (nextItem && nextItem.type === 'Gallery') {
                        carouselComps[itemIndex] = { type: 'Carousel', props: { components: [colComponent] } };
                    } else {
                        carouselComps.push(colComponent);
                    }
                }
                lastGallery = true;
            } else {
                carouselComps.push(colComponent);
                lastGallery = false;
            }
        }
    }

    const mergedSelectsMemo = useMemo(() => {
        let mergedSelects = null;
        if (column.props.isComplexForm || column.props.LRProps) {
            const firstCol = componentParent?.props?.components?.[0];
            const firstItem = firstCol?.props?.components?.[0];
            // First we'll check if first column is a Select or MultiSelect
            if ((firstItem?.type === 'Select' || firstItem?.type === 'MultiSelect')
                && firstItem?.props?.renderAs !== 'radio') {
                const selectComp = columnComp.find((item) => item?.props?.components?.[0]?.type === 'Select'
                    || item?.props?.components?.[0]?.type === 'MultiSelect');
                const selectCellComp = columnComp.find((item) => item.type === 'SelectCell');
                let isHighlight = true;
                // Then we'll check if current column has a Select or SelectCell.
                if (selectComp || selectCellComp) {
                    // Then we'll merge all the selects into a single one
                    const allComps = [];
                    buildSettingsTreePage(allComps, column);
                    // we dont want to affect actual components, therefore making deep copy
                    const allSelects = JSON.parse(JSON.stringify(allComps.filter((item) => item.type === 'Select' || item.type === 'MultiSelect')));
                    allSelects.forEach((selectItem) => {
                        isHighlight = !!selectItem.props.isHighlight;
                        const selectParent = allComps.find((item) => item.id === selectItem.parent);
                        if (!selectParent) {
                            return;
                        }
                        // Mapped using ids of option(2nd Column)
                        if (selectParent.type === 'Cell' && selectParent.forSelect) {
                            selectItem.id += '-preview';
                            selectItem.mappedOption = selectParent.props.associatedComponent;

                            // STEP1- We need to generate ids for each option in the select
                            // This is required to make mapping work with OptionCell
                            selectItem.props.options.forEach((option, index) => {
                                if (!option.id) {
                                    option.id = `${selectItem.id}-${index}`;
                                }
                            });
                        }
                        // Mapped using OptionCell
                        if (selectParent.type === 'OptionCell') {
                            selectItem.id += '-preview';
                            // this is SelectCell
                            const { selectComponentId, associatedIndex } = selectParent.props;
                            // STEP2- We will set the index generated id (as done in STEP1)
                            // as mappedOption

                            selectItem.mappedOption = `${selectComponentId}-preview-${associatedIndex}`;
                        }
                    });
                    allSelects.forEach((allSelect) => {
                        allSelect.props.options.forEach((option) => {
                            option.requiredId = allSelect.id;
                        });
                    });
                    let allOptions = allSelects
                        .flatMap((item) => item.props.options.map((option) => ({ ...option, mappedOption: item.mappedOption })));
                    const hasFilters = Object.keys(selectedOptionIdsPreview)
                        .some((item) => allOptions.some((option) => option.mappedOption === item) && selectedOptionIdsPreview[item]);
                    if (hasFilters && isHighlight) {
                        allOptions = allOptions.map((item) => {
                            if (selectedOptionIdsPreview[item.mappedOption]) {
                                item.isHighlight = true;
                                item.aData = selectedOptionIdsPreview[item.mappedOption];
                            } else {
                                item.isHighlight = false;
                            }
                            return (item);
                        });
                    } else {
                        allOptions = allOptions.filter((item) => selectedOptionIdsPreview[item.mappedOption]);
                    }
                    if (allSelects && allSelects.length > 0) {
                        const mergedSelect = JSON.parse(JSON.stringify(allSelects[0]));
                        mergedSelect.props.options = allOptions;
                        mergedSelects = [mergedSelect];
                    }
                }
            }
        }
        return mergedSelects;
    }, [column, newCellAdded, columnComp, componentParent, selectedOptionIdsPreview]);

    const isFormHeader = (workshopComponent?.props?.group === 'Form Components' && !column?.props?.isMixedForm
        && workshopComponent?.props?.components?.[0]?.type === 'Column')
        || selectedTemplate?.group === 'Form Components' || parent?.name === 'Form';
    const cfBorderBottom = (inWorkshop && !hideFromWorkshop && column?.props?.components?.length >= 0 && !column?.props?.LRProps);

    const isSelectSuggest = complexFormType === defaultData.selectSuggest;
    let hideSelectCompLabel = !!column.props.hideFromWorkshop;
    let colIndex = -1;
    if (isSelectSuggest && column.props.index === 1 && column.props?.components?.length > 0 && !column.props.hideFromWorkshop) {
        colIndex = component.parent.findIndex((c) => c.id === column.id);
        if (colIndex !== -1 && component?.parent[colIndex]?.props?.components?.length > 0) {
            hideSelectCompLabel = true;
        } else {
            hideSelectCompLabel = false;
        }
    }

    const isSelectSuggestLRCFBorderLeft = (column?.props?.isComplexForm && !inWorkshop)
        && (column?.props?.isLR || column?.props?.LRProps) && index === 1
        && columnComp?.length === 0;

    const isMixedType = inWorkshop && column?.props?.isMixedForm && ((column?.props?.LRProps?.LRtype || column?.props?.columnType) !== 'right')
        && ((column?.props?.columnType) !== 'left') && !column?.props?.isLR;
    const isMixedOnly = isMixedType && componentParent2?.components?.[0]?.component === 'Mixed Form'
        || isMixedType && componentParent2?.components?.[0]?.component === 'LR Mixed Form'
        && column?.props?.components?.length > 0;
    const colSelectIndex = (column?.props?.isMixedForm && ((column?.props?.LRProps || column?.props?.isLR))
        ? ((selectedIndex === 0) || (selectedIndex === 1)) : selectedIndex === 0);
    const universalOneSided = workshopComponent?.component === 'Universal One Sided CF';
    let firstColElPasses = firstColumn?.props?.components.every((item) => item.type === 'Checkbox' || item.type === 'TextAreaCF');
    if (firstColElPasses === undefined) {
        firstColElPasses = false;
    }
    const headers = secondColumn?.props?.title?.length || thirdColumn?.props?.title?.length;
    if (!universalOneSided) {
        firstColElPasses = false;
    }
    if (headers) {
        firstColElPasses = false;
    }
    if (firstColumn?.props?.components?.length === 0) {
        firstColElPasses = false;
    }
    const [rowComp] = useRecoilState(componentWithId(firstColumn?.parent));
    const firstColElCheckBoxPass = firstColumn?.props?.components.some((item) => item.type === 'Checkbox');
    const firstColElTextAreaPass = firstColumn?.props?.components.some((item) => item.type === 'TextAreaCF');
    const sendColChildren = secondColumn?.props?.components?.filter((val) => !val.isTimeline);
    const secondColChildElePass = sendColChildren?.some((child) => child?.props?.components?.length !== 0);
    let isSelectComponentVisible = (firstColElPasses) && secondColChildElePass !== undefined;
    if (!inWorkshop) {
        isSelectComponentVisible = false;
    }
    const isWorkShopAndSimpleForm = workshopComponent?.props?.isSimpleForm && firstColElPasses;
    const check = isWorkShopAndSimpleForm && inWorkshop;
    if (check) {
        isSelectComponentVisible = true;
    }

    useEffect(() => {
        if (!workshopComponent?.props?.isSimpleForm && rowComp?.props?.components && firstColElCheckBoxPass && firstColElTextAreaPass) {
            rowComp.props.components.length = 1;
            rowComp.props.isSimpleForm = true;
            if (workshopComponent?.props) {
                workshopComponent.props.isSimpleForm = true;
                toast.success('Changed to simple form');
            }
        }
    }, [firstColumn?.props?.components.length]);

    return (
        <LRContext.Provider value={{ isLRChild }}>
            <Grid
                key={column.id}
                item
                // eslint-disable-next-line no-restricted-globals
                xs={isFinite(size) && size}
                className={`${classes.column} ${column?.props?.columnType === 'right'
                && inWorkshop && !isEditMode ? editorClasses.activeLRSide : null}`}
                style={{
                    borderBottom: cfBorderBottom ? '0.25px solid #CBCBCB' : 'none',
                    borderLeft: (isFormHeader || (isSelectSuggestLRCFBorderLeft && column?.props?.cfType === 'Select and Suggest'))
                        ? '.25px solid #cbcbcb'
                        : ((workshopComponent?.component === 'Mixed Form' && column?.props?.components?.[0]?.type === 'Row')
                            || ((column?.compIndex === 0 && (column?.props?.isLR || column?.props?.LRProps || column?.props?.isMixedForm))
                                || (!inWorkshop && components?.length === 0 && (column?.props?.isLR || column?.props?.LRProps))))
                            ? 'none' : '',
                }}
            >
                {
                    inWorkshop ? (
                        !compareModal && (
                            <div
                                className={[(isSelected ? classes.selectedComp : null), (!showTitle ? editorClasses.hideTextCls : null),
                                    (hideFromWorkshop ? editorClasses.hideColumnCls : null)].join(' ')}
                                id={isSelected ? 'selectedCompId' : ''}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setActiveComponent(setSettingComponentHandle, column);
                                }}
                                style={{ textAlign: workshopActiveComponent?.category === 'Right and Left Complex Forms' && 'center' }}
                                aria-hidden="true"
                            >
                                {renderTitleEditor()}
                            </div>
                        )
                    ) : (
                        showTitle && !hideFromWorkshop && !component?.props?.hasOwnProperty('showTitleInTimeline')
                        && <div className={isSelected ? classes.selectedComp : null}>{renderTitle()}</div>
                    )

                }
                <div className={editorClasses.container} style={!showColTitle && !inWorkshop ? { display: 'none' } : null}>
                    <Container
                        complexFormColumnIndex={index}
                        props={{
                            components: (!inWorkshop && mergedSelectsMemo) ? mergedSelectsMemo : showCarousel ? carouselComps : columnComp,
                            isMergeSelect: true,
                            componentIndexCip: componentIndex,
                            componentParentCip: componentParent2,
                            flexTextField,
                            listTypePreview: listType,
                        }}
                    />
                </div>

                {isSelectComponentVisible ? renderColEditorActionPopup({
                    click, editorClasses, column, workshopComponent, hideSelectCompLabel, workshopActiveComponent,
                }) : null}

                {((column.props.isComplexForm
                    && column.props.components?.length === 0 && (workshopActiveComponent?.type === 'Protocol' || selectedIndex === 0)
                    && inWorkshop && !column.props.children && column?.props?.LRProps?.LRtype !== 'right' && component)
                    || (selectCompCF?.component?.id === column.id && inWorkshop && selectCompCF.isShow && showSelectBtn && !column.props.hasCells))
                    ? renderColEditorActionPopup({
                        click, editorClasses, column, workshopComponent, hideSelectCompLabel, workshopActiveComponent,
                    }) : ((column.props.LRProps && component
                        && column.props.components?.length === 0 && colSelectIndex && inWorkshop && !column.props.children)
                        || (selectCompCF?.component?.id === column.id && inWorkshop && selectCompCF.isShow && showSelectBtn
                            && !column.props.hasCells)) ? renderColEditorActionPopup({
                            click, editorClasses, column, workshopComponent, hideSelectCompLabel, workshopActiveComponent,
                        }) : null}

                {isMixedOnly ? (
                    renderColEditorActionPopup({
                        click, editorClasses, column, workshopComponent, hideSelectCompLabel,
                    })
                ) : null}

                {quickAddSupported && (
                    <QuickAdd component={lastComponent} onAdd={onQuickAdd} />
                )}
            </Grid>
        </LRContext.Provider>
    );
};

const SectionEditor = (section) => {
    const editorClasses = useEditorStyles();
    const { duplicateComponent } = useContext(TimelineContext);
    const [component, setComponentState] = useRecoilState(
        componentWithId(section?.id, section?.props)
    );
    const { components, showTitle = true } = component.props;
    const { inWorkshop, compareModal } = useContext(WorkshopContext);

    const columnComp = section?.props?.components;
    const size = component.props.size === 'auto' ? true : parseInt(component.props.size, 10);

    const renderTitleEditor = () => (
        <>
            {makeInlineEditor({
                field: titlee,
                component,
                setComponentState,
                classes: editorClasses,
            })}
        </>
    );

    const renderTitle = () => {
        const sectionTitle = (
            <Typography
                className={editorClasses.columHeadType}
                color="inherit"
            >
                {component.props.title}
            </Typography>
        );

        if ((component.props.columnCB === false || component.props.columnCB === 'no') || component.props.title === '') {
            return sectionTitle;
        }

        return (
            <FormControlLabel
                label={sectionTitle}
                className={inWorkshop ? editorClasses.formControlWorkshop : editorClasses.formControl}
                control={<Checkbox className={editorClasses.checkBox} />}
            />
        );
    };

    const lastComponent = inWorkshop
        && components
        && components.length > 0
        && components[components.length - 1];
    const quickAddSupported = lastComponent
        && (lastComponent.type === 'Checkbox' || lastComponent.type === 'TextInput');

    const onQuickAdd = (label) => {
        duplicateComponent(lastComponent, components, components.length - 1, {
            label,
        });
    };

    return (
        <Grid key={component.id} item xs={size} className={editorClasses.column}>
            {((inWorkshop && !compareModal) ? <div className={showTitle ? editorClasses.hideTextCls : null}>{renderTitleEditor()}</div>
                : <div className={showTitle ? editorClasses.hideTextCls : null}>{renderTitle()}</div>)}
            <div className={editorClasses.container}>
                <Container props={{ components: columnComp }} />
            </div>

            {quickAddSupported && (
                <QuickAdd component={lastComponent} onAdd={onQuickAdd} />
            )}
        </Grid>
    );
};

const ColumnPreview = ({
    column, classes,
}) => {
    const editorClasses = useEditorStyles();
    const size = column.props.size === 'auto' ? true : parseInt(column.props.size, 10);
    const {
        // eslint-disable-next-line no-unused-vars
        handleCompPreviewClick, settingComponent, workshopComponent, pageComponent: pageComponentRef, selectedOptionIdsPreview, newCellAdded,
    } = useContext(TimelineContext);
    const isColumnHidden = column.props.isEditorHidden || false;
    let isSelected = false;

    const componentParent = findComponent(workshopComponent?.props?.components, column?.parent);
    const columnComp = column.props.components || [];
    if (settingComponent && settingComponent.id === column.id) {
        isSelected = true;
    }
    const mergedSelectsMemo = useMemo(() => {
        let mergedSelects = null;
        if (column.props.isComplexForm || column.props.LRProps) {
            const firstCol = componentParent?.props?.components?.[0];
            const firstItem = firstCol?.props?.components?.[0];
            // First we'll check if first column is a Select or MultiSelect
            if ((firstItem?.type === 'Select' || firstItem?.type === 'MultiSelect') && firstItem?.props?.renderAs !== 'radio') {
                const selectComp = columnComp
                    .find((item) => item?.props?.components?.[0]?.type === 'Select' || item?.props?.components?.[0]?.type === 'MultiSelect');
                const selectCellComp = columnComp.find((item) => item.type === 'SelectCell');

                // Then we'll check if current column has a Select or SelectCell.
                if (selectComp || selectCellComp) {
                    // Then we'll merge all the selects into a single one
                    const allComps = [];
                    buildSettingsTreePage(allComps, column);
                    // we dont want to affect actual components, therefore making deep copy
                    const allSelects = JSON.parse(JSON.stringify(allComps.filter((item) => item.type === 'Select' || item.type === 'MultiSelect')));
                    allSelects.forEach((selectItem) => {
                        const selectParent = allComps.find((item) => item.id === selectItem.parent);
                        if (!selectParent) {
                            return;
                        }
                        // Mapped using ids of option(2nd Column)
                        if (selectParent.type === 'Cell' && selectParent.forSelect) {
                            selectItem.id += '-preview';
                            selectItem.mappedOption = selectParent.props.associatedComponent;

                            // STEP1- We need to generate ids for each option in the select
                            // This is required to make mapping work with OptionCell
                            selectItem.props.options.forEach((option, index) => {
                                if (!option.id) {
                                    option.id = `${selectItem.id}-${index}`;
                                }
                            });
                        }
                        // Mapped using OptionCell
                        if (selectParent.type === 'OptionCell') {
                            selectItem.id += '-preview';
                            // this is SelectCell
                            const { selectComponentId, associatedIndex } = selectParent.props;
                            // STEP2- We will set the index generated id (as done in STEP1)
                            // as mappedOption

                            selectItem.mappedOption = `${selectComponentId}-preview-${associatedIndex}`;
                        }
                    });
                    let allOptions = allSelects
                        .flatMap((item) => item.props.options.map((option) => ({ ...option, mappedOption: item.mappedOption })));
                    const hasFilters = Object.keys(selectedOptionIdsPreview).some((item) => allOptions
                        .some((option) => option.mappedOption === item) && selectedOptionIdsPreview[item]);
                    if (hasFilters) {
                        allOptions = allOptions.filter((item) => selectedOptionIdsPreview[item.mappedOption]);
                    }
                    if (allSelects && allSelects.length > 0) {
                        const mergedSelect = JSON.parse(JSON.stringify(allSelects[0]));
                        mergedSelect.props.options = allOptions;
                        mergedSelects = [mergedSelect];
                    }
                }
            }
        }
        return mergedSelects;
    }, [column, newCellAdded, columnComp, componentParent, selectedOptionIdsPreview]);

    return (
        <Grid key={column.id} item xs={size} className={classes.column}>
            {(!isColumnHidden && !column.props.hideFromWorkshop) && (
                <Typography
                    color="inherit"
                    onClick={(e) => handleCompPreviewClick(e, column)}
                    className={['mainCompWrapper', classes.labels, editorClasses.columnPrevTitle, isSelected ? classes.selectedComp
                        : (column.props.isSelect ? classes.firstCol : '')].join(' , ')}
                    id={isSelected ? 'selectedCompId' : ''}
                >
                    {column.props.title}
                </Typography>
            )}
            <ContainerPreview props={{ components: mergedSelectsMemo || column.props.components }} />
        </Grid>
    );
};

const SectionPreview = ({ component }) => {
    const editorClasses = useEditorStyles();
    const size = component.props.size === 'auto' ? true : parseInt(component.props.size, 10);
    const isColumnHidden = component.props.isEditorHidden || false;
    return (
        <Grid key={component.id} item xs={size}>
            {!isColumnHidden && (
                <Typography className={editorClasses.sectionPrevTitle} color="inherit">
                    {component.props.title}
                </Typography>
            )}
            <ContainerPreview props={{ components: component.props.components }} />
        </Grid>
    );
};

const ColumnTemplatePreview = (props) => {
    const { component } = props;
    return (<TimelineColumn columns={null} isTempPrev column={component || props} columnIndex={0} />);
};

const ColumnEditor = ({
    config,
    updateComponent,
    parent,
    heading,
}) => {
    const editorClasses = useEditorStyles();
    const isTimelineURL = useRouteMatch('/:orgId/timeline');
    const { selectedTemplate } = useContext(WorkshopContext);
    const [component, setComponentState] = useRecoilState(
        componentWithId(config.id)
    );
    // eslint-disable-next-line no-unused-vars
    const [rowComp, setRowComp] = useRecoilState(
        componentWithId(config.parent)
    );
    const findColIndex = rowComp.props.components.findIndex((c) => c.id === config.id);
    const {
        settingMenuType,
        handleLeftColumn,
        handleRightColumn,
        workshopSelectedTemplate,
        workshopComponent,
        selectedTimeline,
        selectedColumn,
        editTimelineColumn,
        selectedComponent,
    } = useContext(TimelineContext);
    const leftRightColumnFlag = config.props && !!Object.prototype.hasOwnProperty.call(config.props, 'columnType');
    const editorProps = (parent && parent.type === 'Timeline' || !isTimelineURL) ? TimelineColumnProps
        : (leftRightColumnFlag ? LRColumnProps : ColumnProps);

    if (workshopComponent?.isProgression) {
        editorProps.display = editorProps.display.filter((d) => d.key !== 'wow feature' && d.key !== 'pcRelationship');
    }

    if (workshopComponent?.id === selectedTimeline?.columns[selectedColumn]?.id && !editTimelineColumn) {
        editorProps.display = editorProps.display.filter((d) => d.key === 'title');
    }
    const columnEditorProps = { display: FilterSettings(editorProps, settingMenuType, 'Column'), poi: editorProps.poi };
    if (selectedComponent?.isProgression || workshopComponent?.isProgression) {
        columnEditorProps.display = columnEditorProps.display.filter((ele) => ele?.key !== 'hyperlink');
    }
    if (selectedTemplate?.template?.props?.cfType === 'Select and Suggest') {
        columnEditorProps.display = columnEditorProps.display.filter((el) => el.key !== 'pcRelationship');
    }

    if ((findColIndex === 0 || findColIndex === 1 || findColIndex === 2) && rowComp?.props?.components?.length > 2) {
        const firstColumn = rowComp?.props?.components[0];
        const secondColumn = rowComp?.props?.components[1];
        const secondColumnFirstEl = rowComp?.props?.components[1]?.props?.components[0];
        const thirdColumn = rowComp?.props?.components[2];
        const thirdColFlatten = [];
        buildSettingsTreePage(thirdColFlatten, thirdColumn);
        if (workshopComponent?.component === 'Select-Complete-Notes') {
            if (firstColumn?.props?.components.every((el) => el.type === 'Checkbox' || el.type === 'TextAreaCF') === false
                || secondColumnFirstEl?.props?.components.every((el) => el.type === 'Select') === false
                || thirdColFlatten?.every((el) => ['Column', 'SelectCell', 'OptionCell', 'EmptyCell', 'TextAreaCF'].includes(el.type)) === false) {
                // columnEditorProps.display = columnEditorProps.display.filter((ele) => ele?.key !== 'wow feature');
                columnEditorProps.display = columnEditorProps.display.map((obj) => (obj.key === 'wow feature'
                    ? { ...obj, style: 'none', default: 'no' } : obj));
                firstColumn.props['wow feature'] = 'no';
                secondColumn.props['wow feature'] = 'no';
                thirdColumn.props['wow feature'] = 'no';
            }
        } else if (firstColumn?.props?.components.every((el) => el.type === 'Checkbox' || el.type === 'TextAreaCF') === false
            || secondColumnFirstEl?.props?.components.every((el) => el.type === 'Select') === false
            || thirdColFlatten?.every((el) => ['Column', 'SelectCell', 'OptionCell', 'EmptyCell', 'TextAreaCF'].includes(el.type)) === false) {
            // columnEditorProps.display = columnEditorProps.display.filter((ele) => ele?.key !== 'wow feature');
            columnEditorProps.display = columnEditorProps.display.map((obj) => (obj.key === 'wow feature'
                ? { ...obj, style: 'none', default: 'no' } : obj));
            firstColumn.props['wow feature'] = 'no';
            secondColumn.props['wow feature'] = 'no';
            thirdColumn.props['wow feature'] = 'no';
        }
    }
    if (leftRightColumnFlag && editorProps.display.find((item) => item.key === 'columnType')) {
        const buttonList = editorProps.display.find((item) => item.key === 'columnType').buttons;
        const leftButton = buttonList.find((item) => item.type === 'left');
        const rightButton = buttonList.find((item) => item.type === 'right');
        leftButton.onClick = handleLeftColumn;
        rightButton.onClick = handleRightColumn;
    }

    let initialConfig = config;
    let template = selectedTemplate;
    if (!isTimelineURL && workshopSelectedTemplate) {
        initialConfig = { name: workshopSelectedTemplate?.supergroup };
        template = { name: workshopSelectedTemplate.name };
    }
    setComponentType(columnEditorProps, initialConfig, template);

    const textAreaProps = {
        display: FilterSettings(
            JSON.parse(JSON.stringify({ ...TextAreaProps, display: TextAreaProps.display.filter((ele) => ele?.isInherited) })),
            settingMenuType,
            'TextAreaCF'
        ),
        poi: TextAreaProps?.poi,
    };
    textAreaProps.display.forEach((ele) => {
        ele.key = `TextAreaCF-${ele?.key}`;
    });

    const textBoxProps = {
        display: FilterSettings(
            JSON.parse(JSON.stringify({ ...TextBoxProps, display: TextBoxProps.display.filter((ele) => ele?.isInherited) })),
            settingMenuType,
            'TextBoxCF'
        ),
        poi: TextBoxProps?.poi,
    };
    textBoxProps.display.forEach((ele) => {
        ele.key = `TextBoxCF-${ele?.key}`;
    });

    return (
        <React.Fragment>
            {heading && <Typography className={editorClasses.settingHeading}>{heading}</Typography>}
            <Settings
                editorProps={columnEditorProps}
                component={component}
                setComponentState={setComponentState}
                saveComponent={updateComponent}
                noSingleSelectCheckboxHyperlink
            />
        </React.Fragment>
    );
};

const renderColEditorAction = ({ click }) => (
    <IconButton disableRipple onClick={click} onContextMenu={click}>
        <MoreHorizIcon style={{ fontSize: 12 }} />
    </IconButton>
);

const renderColEditorActionPopup = ({
    click, editorClasses, column, workshopComponent, hideSelectCompLabel, workshopActiveComponent = null,
}) => {
    if (hideSelectCompLabel) return null;
    if (workshopActiveComponent?.type === 'Protocol' && column?.props?.index > 0
        && column?.props?.isComplexForm && !column?.props?.isMixedForm) return null;
    if (column?.mirrorId) return null;
    return (
        <div className={(column?.props?.LRProps && workshopComponent) ? editorClasses.borderRightSide
            : workshopComponent === null ? editorClasses.disableClass : editorClasses.wrapperSelectComp}
        >
            <Typography className={editorClasses.selectComponentText} onClick={click}>
                Select Component
            </Typography>
        </div>
    );
};

const Cell = ({
    Cell,
    componentParent,
    componentIndex,
    componentColumn,
}) => {
    const { props } = Cell;
    const { components = [] } = props;
    const {
        setLastDeletedComponent, lastDeletedComp, workshopActiveComponent, handleContextMenu, lastDeletedOption, workshopComponent, selectedOptionId,
        setLastDeletedComponentIds, lastDeletedCompIds,
    } = useContext(TimelineContext);
    useRecoilState(componentWithId(Cell.id));
    const [column] = useRecoilState(componentWithId(Cell.colRef));
    const [, setAssocComp] = useRecoilState(componentWithId(Cell.associatedComponent));
    const { inWorkshop } = useContext(WorkshopContext);
    const { associatedComponent } = props;
    const [, setAssociatedComponentHeight] = useState(0);
    const [rowComp] = useRecoilState(componentWithId(Cell.rowRef));
    // const secondCol = rowComp?.props?.components?.find((row) => row.props?.index === 1);
    // const isNotSimpleForm = secondCol?.props?.components?.some((x) => !x.isTimeline && x?.props?.components?.length > 0);
    const [isMultiline, setIsMultiLine] = useState(false);
    useEffect(() => {
        if (column?.props?.multiline_textArea === 'yes') {
            setIsMultiLine(true);
        } else {
            setIsMultiLine(false);
        }
    }, [column?.props?.multiline_textArea]);

    useLayoutEffect(() => {
        const associatedComponentRef = document.querySelector(`#true-${associatedComponent}`);
        if (associatedComponentRef?.clientHeight) setAssociatedComponentHeight(associatedComponentRef.clientHeight);
    }, [associatedComponent]);

    const findColumnIndex = rowComp?.props.components.findIndex((c) => c.id === Cell.colRef);

    let isUniversalManual = false;
    if (rowComp?.props?.components[findColumnIndex]?.props?.pcRelationship) {
        isUniversalManual = rowComp.props.components[findColumnIndex].props.pcRelationship === 'manual';
    }

    if (componentParent?.components?.[componentIndex]?.props?.pcRelationship
            && componentParent?.components?.[componentIndex]?.props?.leftRightColumn) {
        isUniversalManual = componentParent?.components?.[componentIndex]?.props?.pcRelationship === 'manual';
    }

    const handleClick = (e) => {
        let columnPosition;
        const requiredParent = [];
        findParent(workshopComponent?.props?.components, associatedComponent, requiredParent);
        if (requiredParent && requiredParent[0]) {
            columnPosition = requiredParent[0].type === 'Cell' ? 2 : 1;
        } else {
            columnPosition = 1;
        }
        handleContextMenu(e, Cell, Cell, components.length, null, true, e.target.getAttribute('last-comp-type'), columnPosition);
    };
    useEffect(() => {
        if (!lastDeletedComp) return;
        if (lastDeletedComp === associatedComponent
            || (lastDeletedCompIds?.length > 0 && Array.isArray(lastDeletedCompIds)
                && lastDeletedCompIds?.find((c) => c === associatedComponent))) {
            const colComp = findComponent(workshopComponent.props.components, Cell.colRef);
            colComp.props.components = colComp.props.components.filter((item) => item.id !== Cell.id);
            if (Cell?.props?.components?.[0]?.id) {
                setLastDeletedComponent(Cell.props.components[0].id);
                const deleteIds = Cell.props.components.map((c) => c.id);
                setLastDeletedComponentIds(deleteIds);
            }
        }
        const childDeleteIndex = Cell.props.components.findIndex((item) => item.mirrorId === lastDeletedComp);
        if (childDeleteIndex !== -1) {
            Cell.props.components = Cell.props.components.filter((_i, index) => index !== childDeleteIndex);
        }
    }, [lastDeletedComp]);

    useLayoutEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            setAssocComp((c) => ({ ...c, layoutChange: !c.layoutChange }));
        });
        const elem = document.querySelector(`#true-${Cell.id}`);
        if (elem) resizeObserver.observe(elem);
        return () => {
            if (elem) resizeObserver.unobserve(elem);
        };
    }, []);
    useEffect(() => {
        if (!lastDeletedOption) return;
        if (lastDeletedOption.id === associatedComponent) {
            const colComp = findComponent(workshopComponent.props.components, Cell.colRef);
            colComp.props.components = colComp.props.components.filter((item) => item.id !== Cell.id);
            if (Cell?.props?.components?.[0]?.id) { setLastDeletedComponent(Cell.props.components[0].id); }
        }
        const childDeleteIndex = Cell.props.components.findIndex((item) => lastDeletedOption.id && item.mirrorId === lastDeletedOption.id);
        if (childDeleteIndex !== -1) {
            Cell.props.components = Cell.props.components.filter((_i, index) => index !== childDeleteIndex);
        }
    }, [lastDeletedOption]);

    const editorClasses = useEditorStyles();
    const currentSelectedComponent = Cell.forSelect ? selectedOptionId : workshopActiveComponent?.id;

    if (isUniversalManual) {
        if (workshopComponent && Cell.isTimeline && inWorkshop) { // Hide timeline cell in workshop
            return null;
        }
        if (!Cell.isTimeline && !inWorkshop) { // Hide non timeline cell in timeline
            return null;
        }
    } else if (Cell.isTimeline) {
        return null;
    }

    const isTimelineBorder = Cell.isTimeline && components[0]?.type === 'Select' && !inWorkshop && components[0]?.props?.options?.length === 0;
    return (
        <div
            style={{
                minHeight: inWorkshop ? isMultiline ? 52 : 37 : 24,
                // Reverting changes of #PR1675
                borderBottom: ((components.length === 0 && Cell.index >= 0)
                    || (inWorkshop && Cell.index >= 0)) || isTimelineBorder ? '.25px solid #cbcbcb' : 'none',
            }}
            className={[(components.length > 1 && !inWorkshop)
                && editorClasses.wrapperSelectComp || (components.length === 1 && !inWorkshop) && editorClasses.wrapperSelectComp1 || undefined,
            (Cell.index === column?.props?.components?.length - 1) && editorClasses.borderCellCls].join(' , ')}
        >
            {
                components.length > 0 && (
                    <Container props={{
                        components, componentIndexCip: componentIndex, componentParentCip: componentParent, componentColumnCip: componentColumn,
                    }}
                    />
                )
            }
            {
                components.length === 0 && props.associatedComponent === currentSelectedComponent && inWorkshop && !Cell?.mirrorId
                && componentParent?.components?.[0]?.component !== 'LR Mixed Form' && (
                    <Typography
                        last-comp-type={workshopActiveComponent?.props?.renderAs === 'radio' ? 'Radio Group' : workshopActiveComponent?.type}
                        className={findChildComponentFlag(column) ? editorClasses.selectComponentText1 : editorClasses.selectComponentText}
                        onClick={handleClick}
                    >
                        {findChildComponentFlag(column) ? 'Add Options' : 'Select Component'}
                    </Typography>
                )
            }
        </div>
    );
};

const CellPreview = (componentProps) => {
    const Cell = componentProps?.id ? componentProps : { ...componentProps?.component };
    const { props } = Cell;
    const { components = [] } = props;
    const {
        setLastDeletedComponent, lastDeletedComp, workshopComponent,
    } = useContext(TimelineContext);
    useRecoilState(componentWithId(Cell.id));
    const { inWorkshop } = useContext(WorkshopContext);
    const editorClasses = useEditorStyles();
    const { associatedComponent } = props;

    useEffect(() => {
        if (!lastDeletedComp) return;
        if (lastDeletedComp === associatedComponent) {
            const colComp = findComponent(workshopComponent.props.components, Cell.colRef);
            colComp.props.components = colComp.props.components.filter((item) => item.id !== Cell.id);
            if (Cell?.props?.components?.[0]?.id) { setLastDeletedComponent(Cell.props.components[0].id); }
        }
        const childDeleteIndex = Cell.props.components.findIndex((item) => item.mirrorId === lastDeletedComp);
        if (childDeleteIndex !== -1) {
            Cell.props.components = Cell.props.components.filter((_i, index) => index !== childDeleteIndex);
        }
    }, [lastDeletedComp]);

    return (
        <div style={{ minHeight: 24, overflow: !inWorkshop && 'auto' }} className={editorClasses.wrapperSelectComp1}>
            {
                components.length > 0 && <ContainerPreview props={{ components }} />
            }
        </div>
    );
};

const EmptyCell = (Cell) => {
    const { props } = Cell;
    const {
        workshopComponent, lastDeletedComp, lastDeletedOption,
    } = useContext(TimelineContext);
    const { inWorkshop } = useContext(WorkshopContext);
    const [column] = useRecoilState(componentWithId(Cell.colRef));
    const { associatedComponent } = props;
    const [, setAssociatedComponentHeight] = useState(0);

    const [rowComp] = useRecoilState(componentWithId(Cell.rowRef));
    const secondCol = rowComp?.props?.components?.find((row) => row.props?.index === 1);
    const isNotSimpleForm = secondCol?.props?.components?.some((x) => !x.isTimeline && x?.props?.components?.length > 0);
    const findColumnIndex = rowComp?.props.components.findIndex((c) => c.id === Cell.colRef);

    const [isMultiline, setIsMultiLine] = useState(false);
    useEffect(() => {
        if (column?.props?.multiline_textArea === 'yes') {
            setIsMultiLine(true);
        } else {
            setIsMultiLine(false);
        }
    }, [column?.props?.multiline_textArea]);

    let isUniversalManual = false;
    if (rowComp?.props?.components[findColumnIndex]?.props?.pcRelationship) {
        isUniversalManual = rowComp.props.components[findColumnIndex].props.pcRelationship === 'manual';
    }
    useLayoutEffect(() => {
        const associatedComponentRef = document.querySelector(`#true-${associatedComponent}`);
        if (associatedComponentRef?.clientHeight) setAssociatedComponentHeight(associatedComponentRef.clientHeight);
    }, [associatedComponent]);

    useEffect(() => {
        if (lastDeletedComp === associatedComponent) {
            const colComp = findComponent(workshopComponent.props.components, Cell.colRef);
            colComp.props.components = colComp.props.components.filter((item) => item.id !== Cell.id);
            // setLastDeletedComponent(Cell.id);
        } else if (lastDeletedComp === Cell.mirrorId) {
            const colComp = findComponent(workshopComponent.props.components, Cell.colRef);
            colComp.props.components = colComp.props.components.filter((item) => item.id !== Cell.id);
        }
    }, [lastDeletedComp]);

    useEffect(() => {
        if (!lastDeletedOption) { return; }
        if (lastDeletedOption?.id === associatedComponent) {
            const colComp = findComponent(workshopComponent.props.components, Cell.colRef);
            colComp.props.components = colComp.props.components.filter((item) => item.id !== Cell.id);
        } else if (lastDeletedComp?.id === Cell.mirrorId) {
            const colComp = findComponent(workshopComponent.props.components, Cell.colRef);
            colComp.props.components = colComp.props.components.filter((item) => item.id !== Cell.id);
        }
    }, [lastDeletedOption]);

    const editorClasses = useEditorStyles();

    if (isUniversalManual) {
        if (workshopComponent && Cell.isTimeline && inWorkshop) { // Hide timeline cell in workshop
            return null;
        }
        if (!Cell.isTimeline && !inWorkshop) { // Hide non timeline cell in timeline
            return null;
        }
    } else if (Cell.isTimeline) {
        return null;
    }

    return (
        <div
            style={inWorkshop ? { minHeight: inWorkshop ? !isNotSimpleForm || isMultiline ? 52 : 37 : 36 } : { minHeight: 24, overflow: 'auto' }}
            className={editorClasses.wrapperSelectComp}
        />
    );
};

const EmptyCellPreview = (componentProps) => {
    const Cell = componentProps?.id ? componentProps : { ...componentProps?.component };
    const { props } = Cell;
    const {
        workshopComponent, lastDeletedComp, setLastDeletedComponent, inWorkshop,
    } = useContext(TimelineContext);
    const { associatedComponent } = props;

    useEffect(() => {
        if (lastDeletedComp === associatedComponent) {
            const colComp = findComponent(workshopComponent.props.components, Cell.colRef);
            colComp.props.components = colComp.props.components.filter((item) => item.id !== Cell.id);
            setLastDeletedComponent(Cell.id);
        } else if (lastDeletedComp === Cell.mirrorId) {
            const colComp = findComponent(workshopComponent.props.components, Cell.colRef);
            colComp.props.components = colComp.props.components.filter((item) => item.id !== Cell.id);
            setLastDeletedComponent(Cell.id);
        }
    }, [lastDeletedComp]);

    const editorClasses = useEditorStyles();

    return <div style={{ minHeight: 24, overflow: !inWorkshop && 'auto' }} className={editorClasses.wrapperSelectComp1} />;
};

const OptionCell = ({
    Cell,
    componentParent,
    componentIndex,
    componentColumn,
}) => {
    const { props } = Cell;
    const { components = [] } = props;
    const {
        handleContextMenu, workshopActiveComponent,
    } = useContext(TimelineContext);
    const [height, setHeight] = useState(36);
    const { associatedIndex, selectComponentId, parentCellId } = props;
    const [selectCell, setSelectCell] = useRecoilState(componentWithId(parentCellId));
    const [parentSelect, setParentSelect] = useRecoilState(componentWithId(selectComponentId));
    useRecoilState(componentWithId(Cell.colRef));
    const { inWorkshop } = useContext(WorkshopContext);
    const selectedOptionIndex = workshopActiveComponent?.props?.options?.findIndex((option) => option.keyIndex === associatedIndex);
    let addOptionFlag = false;

    if (Array.isArray(selectCell?.parent) && selectCell?.parent.length > 0) {
        selectCell.parent.forEach((sc) => {
            addOptionFlag = findChildComponentFlag(sc) ? true : addOptionFlag;
        });
    }

    const handleClick = (e) => {
        handleContextMenu(e, Cell, Cell, components.length, null, true, e.target.getAttribute('last-comp-type'), 2);
    };
    useEffect(() => {
        if (!Cell) return;
        const parentSelectComponent = document.querySelector(`#true-${Cell.id}`);
        const associatedElement = document.querySelector(`#true-${Cell.props.selectComponentId}`);
        const optionElements = associatedElement?.querySelectorAll('.listOption');
        const currentOptionElement = optionElements?.[associatedIndex];
        if (currentOptionElement && parentSelectComponent?.clientHeight > 10) {
            currentOptionElement.style.height = `${parentSelectComponent.clientHeight}px`;
        }
        setParentSelect((value) => ({ ...value, layoutChange: !value.layoutChange }));
        setSelectCell((value) => ({ ...value, layoutChange: !value.layoutChange }));
    }, [Cell?.props?.components, components.length]);

    useLayoutEffect(() => {
        const parentCellRef = document.querySelector(`#true-${selectComponentId}`);
        const optionRefAll = parentCellRef?.querySelectorAll('.listOption');
        const optionRef = optionRefAll?.[associatedIndex];
        if (optionRef?.clientHeight && !addOptionFlag) setHeight(optionRef.clientHeight);
    }, [selectComponentId, associatedIndex]);

    const editorClasses = useEditorStyles();
    const radioCB = parentSelect?.props?.renderAs === 'Checkbox' || parentSelect?.props?.renderAs === 'radio';

    return (
        <div
            style={inWorkshop ? (components.length > 0 ? { minHeight: height }
                : { height: 36 }) : (radioCB ? { minHeight: 24 } : null)}
            className={[
                (inWorkshop || (!inWorkshop && radioCB && components.length === 0) || Cell.isTimeline)
                    ? editorClasses.wrapperSelectComp : null, !inWorkshop && editorClasses.multiSelectScrollRemove].join(' , ')}
        >
            {
                components.length > 0 && (
                    <Container props={{
                        components, componentIndexCip: componentIndex, componentParentCip: componentParent, componentColumnCip: componentColumn,
                    }}
                    />
                )
            }
            {
                inWorkshop && components.length === 0
                && workshopActiveComponent?.id === selectComponentId && selectedOptionIndex === associatedIndex && (
                    <Typography
                        last-comp-type={workshopActiveComponent?.props?.renderAs === 'radio' ? 'Radio Group' : workshopActiveComponent?.type}
                        className={addOptionFlag ? editorClasses.selectComponentText1 : editorClasses.selectComponentText}
                        onClick={handleClick}
                    >
                        {addOptionFlag ? 'Add Options' : 'Select Component'}
                    </Typography>
                )
            }
        </div>
    );
};

const OptionCellPreview = (componentProps) => {
    const Cell = componentProps?.id ? componentProps : { ...componentProps?.component };
    const { props } = Cell;
    const { components = [] } = props;
    const [, setHeight] = useState(0);
    const { associatedIndex, selectComponentId, parentCellId } = props;
    const [, setSelectCell] = useRecoilState(componentWithId(parentCellId));
    const [parentSelect, setParentSelect] = useRecoilState(componentWithId(selectComponentId));

    useEffect(() => {
        if (!Cell) return;
        const parentSelectComponent = document.querySelector(`#true-${Cell.id}`);
        const associatedElement = document.querySelector(`#true-${Cell.props.selectComponentId}`);
        const optionElements = associatedElement?.querySelectorAll('.listOption');
        const currentOptionElement = optionElements?.[associatedIndex];
        if (currentOptionElement && parentSelectComponent?.clientHeight > 10) {
            currentOptionElement.style.height = `${parentSelectComponent.clientHeight}px`;
        }
        setParentSelect((value) => ({ ...value, layoutChange: !value.layoutChange }));
        setSelectCell((value) => ({ ...value, layoutChange: !value.layoutChange }));
    }, [Cell?.props?.components, components.length]);

    useLayoutEffect(() => {
        const parentCellRef = document.querySelector(`#true-${selectComponentId}`);
        const optionRefAll = parentCellRef?.querySelectorAll('.listOption');
        const optionRef = optionRefAll?.[associatedIndex];
        if (optionRef?.clientHeight) setHeight(optionRef.clientHeight);
    }, [selectComponentId, associatedIndex]);

    const editorClasses = useEditorStyles();

    return (
        <div
            style={
                ((parentSelect?.props?.renderAs === 'Checkbox' || parentSelect?.props?.renderAs === 'radio')
                    ? { minHeight: 24, overflow: 'auto' } : null)
            }
            className={editorClasses.wrapperSelectComp1}
        >
            {
                components.length > 0 && <ContainerPreview single props={{ components }} />
            }
        </div>
    );
};

const SelectCell = ({
    Cell,
    componentParent,
    componentIndex,
    componentColumn,
}) => {
    const { props } = Cell;
    const [height, setHeight] = useState(0);
    const {
        lastDeletedOption,
        setLastDeletedOption,
        setLastDeletedComponent,
        lastDeletedComp,
        workshopComponent,
        setSpecificOptions,
        workshopActiveComponent,
    } = useContext(TimelineContext);
    let clickedOptionIndex = null;
    const [rowComp] = useRecoilState(componentWithId(Cell.rowRef));
    const findColumnIndex = rowComp?.props.components.findIndex((c) => c.id === Cell.colRef);
    const thirdCol = rowComp?.props?.components?.find((c) => c?.props?.index === 2);
    const notTimeLineCell = !Cell?.isTimeline && Cell;
    const currentProtocol = componentParent?.components[componentIndex];
    const isThirdColWoW = (currentProtocol?.component === 'Select-Complete-Notes'
        || currentProtocol?.component === 'Select/Text-Complete-Notes') && thirdCol?.props?.['wow feature'] === 'yes';

    let isUniversalManual = false;
    if (rowComp?.props?.components[findColumnIndex]?.props?.pcRelationship) {
        isUniversalManual = rowComp.props.components[findColumnIndex].props.pcRelationship === 'manual';
    }

    for (let i = 0, l = workshopActiveComponent?.props?.options?.length; i < l; i += 1) {
        if (workshopActiveComponent?.props?.options?.[i]?.isSelect) {
            clickedOptionIndex = i;
            break;
        }
    }
    const { components = [], associatedComponent } = props;
    const [associatedComponentState] = useRecoilState(componentWithId(associatedComponent));
    const { inWorkshop } = useContext(WorkshopContext);
    const [component] = useRecoilState(componentWithId(Cell.id));
    const optionsLength = associatedComponentState?.props?.options?.length || 0;
    const prevOptionsLength = usePrevious(optionsLength) || 0;

    useEffect(() => {
        if (optionsLength > prevOptionsLength && !Cell.isTimeline) {
            addOptionCells(Cell, associatedComponent, optionsLength - 1, clickedOptionIndex);
        }
    }, [optionsLength, prevOptionsLength, Cell]);
    useLayoutEffect(() => {
        const associatedCompRef = document.querySelector(`#true-${associatedComponent}`);
        if (associatedCompRef?.clientHeight) setHeight(associatedCompRef.clientHeight);
    }, [associatedComponent, optionsLength]);
    /** Delete fix */
    useEffect(() => {
        if (lastDeletedComp === associatedComponent) {
            const colComp = findComponent(workshopComponent.props.components, Cell.colRef);
            const replaceWith = Cell?.associatedComponentParent;
            if (!replaceWith) return;
            const replaceWithRef = findComponent(workshopComponent.props.components, replaceWith);

            const deleteIndex = colComp.props.components.findIndex((item) => item.id === Cell.id);
            const EmptyCellComp = {
                id: uuid(),
                parent: colComp.id,
                type: 'EmptyCell',
                props: {
                    associatedComponent: replaceWithRef?.props?.associatedComponent,
                    components: [],
                    replaceWith,
                },
                index: colComp.props.components.length,
                rowRef: colComp.parent,
                colRef: colComp.id,
            };
            if (colComp.props.components.find((item) => item.props.replaceWith === replaceWith)) {
                // to prevent duplicate insertions of empty cell in case of re renders
                return;
            }
            colComp.props.components = [
                ...colComp.props.components.slice(0, deleteIndex),
                EmptyCellComp,
                ...colComp.props.components.slice(deleteIndex + 1),
            ];
            setLastDeletedComponent(Cell.id);
        }
    }, [lastDeletedComp]);

    useEffect(() => {
        if (!lastDeletedOption?.stale && lastDeletedOption?.selectId === associatedComponent
            && lastDeletedOption?.index !== null && lastDeletedOption?.index !== undefined) {
            Cell.props.associatedCells = Cell.props.associatedCells.filter((_, index) => index !== lastDeletedOption?.index);
            const elementsBeforeIndex = Cell.props.components.slice(0, lastDeletedOption?.index);
            let elementsAfterIndex = Cell.props.components.slice(lastDeletedOption?.index + 1);
            elementsAfterIndex = elementsAfterIndex.map((item) => {
                item.props.associatedIndex -= 1;
                return item;
            });
            Cell.props.components = [...elementsBeforeIndex, ...elementsAfterIndex];
            // to make sure it doesnt execute twice in case of race conditions during re render
            lastDeletedOption.stale = true;
            setLastDeletedOption(null);
            setSpecificOptions({});
        }
    }, [lastDeletedOption]);

    /** delete fix */
    useLayoutEffect(() => {
        const associatedCompState = findComponent(workshopComponent?.props?.components, associatedComponent);
        const firstLevelId = associatedCompState?.associatedParent;
        if (!firstLevelId) {
            const associatedCompRef = document.querySelector(`#true-${associatedComponent}`);
            if (associatedCompRef) {
                setHeight(associatedCompRef.clientHeight);
            }
            return;
        }
        const firstLevelCompRef = document.querySelector(`#true-${firstLevelId}`);
        const mainComp = firstLevelCompRef?.querySelector('.mainCompWrapper');
        const associatedCompRef = document.querySelector(`#true-${associatedComponent}`);
        if (associatedCompRef?.clientHeight) {
            setHeight(associatedCompRef.clientHeight);
            if (mainComp) {
                mainComp.style.height = `${associatedCompRef.clientHeight}px`;
            }
        }
    }, [associatedComponent, component.layoutChange, associatedComponentState]);
    const editorClasses = useEditorStyles();

    let blankOptionCellFlag = true;
    let radioCB = false;
    if (components?.length > 0 && !inWorkshop) {
        components.forEach((c) => {
            if (c.type === 'OptionCell') {
                blankOptionCellFlag = c?.props?.components?.length > 0 ? false : (!blankOptionCellFlag ? blankOptionCellFlag : true);
            }
        });
    }

    radioCB = associatedComponentState?.props?.renderAs === 'Checkbox' || associatedComponentState?.props?.renderAs === 'radio';
    if (radioCB && !inWorkshop) blankOptionCellFlag = false;

    if (isUniversalManual) {
        if (workshopComponent && Cell.isTimeline && inWorkshop) { // Hide timeline cell in workshop
            return null;
        }
        if (!Cell.isTimeline && !inWorkshop) { // Hide non timeline cell in timeline
            return null;
        }
    } else if (Cell.isTimeline) {
        return null;
    }

    return (
        <div
            style={inWorkshop ? { minHeight: height } : { minHeight: 24 }}
            className={[(inWorkshop || (!inWorkshop && (blankOptionCellFlag || Cell.isTimeline || Cell?.props?.components?.length === 0)))
                && editorClasses.wrapperSelectComp, 'mainSelectCell', inWorkshop, isThirdColWoW && notTimeLineCell
                ? notTimeLineCell.props.isCompleted === 'inComplete' ? editorClasses.redBg : notTimeLineCell.props.isCompleted === 'completed'
                    ? editorClasses.greenBg : '' : ''].join(' , ')}
        >
            {
                components.length > 0 && (
                    <Container props={{
                        components, componentIndexCip: componentIndex, componentParentCip: componentParent, componentColumnCip: componentColumn,
                    }}
                    />
                )
            }
        </div>
    );
};

const SelectCellPreview = (componentProps) => {
    const Cell = componentProps?.id ? componentProps : { ...componentProps?.component };
    const { props } = Cell;
    const {
        lastDeletedOption, setLastDeletedOption, setLastDeletedComponent, lastDeletedComp,
        workshopComponent, setSpecificOptions,
    } = useContext(TimelineContext);
    const { components = [], associatedComponent } = props;
    const [associatedComponentState] = useRecoilState(componentWithId(associatedComponent));
    const { inWorkshop } = useContext(WorkshopContext);
    useRecoilState(componentWithId(Cell.id));
    const editorClasses = useEditorStyles();

    const optionsLength = associatedComponentState?.props?.options?.length || 0;
    const prevOptionsLength = usePrevious(optionsLength) || 0;
    useEffect(() => {
        if (optionsLength > prevOptionsLength && !Cell.isTimeline) {
            addOptionCells(Cell, associatedComponent, optionsLength - 1);
        }
    }, [optionsLength, prevOptionsLength, Cell]);

    /** Delete fix */
    useEffect(() => {
        if (lastDeletedComp === associatedComponent) {
            const colComp = findComponent(workshopComponent.props.components, Cell.colRef);
            const replaceWith = Cell?.associatedComponentParent;
            if (!replaceWith) return;
            const replaceWithRef = findComponent(workshopComponent.props.components, replaceWith);

            const deleteIndex = colComp.props.components.findIndex((item) => item.id === Cell.id);
            const EmptyCellComp = {
                id: uuid(),
                parent: colComp.id,
                type: 'EmptyCell',
                props: {
                    associatedComponent: replaceWithRef?.props?.associatedComponent,
                    components: [],
                    replaceWith,
                },
                index: colComp.props.components.length,
                rowRef: colComp.parent,
                colRef: colComp.id,
            };
            if (colComp.props.components.find((item) => item.props.replaceWith === replaceWith)) {
                // to prevent duplicate insertions of empty cell in case of re renders
                return;
            }
            colComp.props.components = [
                ...colComp.props.components.slice(0, deleteIndex),
                EmptyCellComp,
                ...colComp.props.components.slice(deleteIndex + 1),
            ];
            setLastDeletedComponent(Cell.id);
        }
    }, [lastDeletedComp]);

    useEffect(() => {
        if (!lastDeletedOption?.stale && lastDeletedOption?.selectId === associatedComponent
            && lastDeletedOption?.index !== null && lastDeletedOption?.index !== undefined) {
            Cell.props.associatedCells = Cell.props.associatedCells.filter((_, index) => index !== lastDeletedOption?.index);
            const elementsBeforeIndex = Cell.props.components.slice(0, lastDeletedOption?.index);
            let elementsAfterIndex = Cell.props.components.slice(lastDeletedOption?.index + 1);
            elementsAfterIndex = elementsAfterIndex.map((item) => {
                item.props.associatedIndex -= 1;
                return item;
            });
            Cell.props.components = [...elementsBeforeIndex, ...elementsAfterIndex];
            // to make sure it doesnt execute twice in case of race conditions during re render
            lastDeletedOption.stale = true;
            setLastDeletedOption(null);
            setSpecificOptions({});
        }
    }, [lastDeletedOption]);

    return (
        <div style={{ minHeight: 24, overflow: !inWorkshop && 'auto' }} className={`${editorClasses.wrapperSelectComp1} mainSelectCell`}>
            {
                components.length > 0 && <ContainerPreview props={{ components }} />
            }
        </div>
    );
};

const ColumnEditMenu = ({ component, componentLibrary, parent }) => {
    const isTimelineColumn = parent.type === 'Timeline';
    const filter = isTimelineColumn ? (item) => (item.type === 'Text' || item.type === component.clickedArea || item.type === 'ProtocolGroup')
        : (item) => (item.type === 'Forms' || item.type === 'HTML' || item.type === 'Row');
    const children = buildComponentMenu(componentLibrary, filter, null, 'child');
    const acccessViewChild = [
        {
            label: 'Basic',
            children: null,
            action: { type: 'accessView', value: 'isBasic' },
        },
        {
            label: 'Advanced',
            children: null,
            action: { type: 'accessView', value: 'isAdvanced' },
        },
        {
            label: 'Comprehensive',
            children: null,
            action: { type: 'accessView', value: 'isUserView' },
        },
    ];
    const addColumnChild = [
        {
            label: 'Left Side',
            children: null,
            action: { type: 'leftColumn', value: '' },
        },
        {
            label: 'Right Side',
            children: null,
            action: { type: 'rightColumn', value: '' },
        },
    ];

    if (isTimelineColumn) {
        return [
            MenuDefaults.EDIT,
            MenuDefaults.DELETE,
            { label: 'Add Column', children: addColumnChild },
            MenuDefaults.DUPLICATE,
            MenuDefaults.TIMELINE_USER_VIEW,
            MenuDefaults.ADD_ACCESS_VIEW(acccessViewChild),
            MenuDefaults.SAVE_AS_TEMPLATE,
        ];
    }

    return [
        MenuDefaults.EDIT,
        MenuDefaults.DELETE,
        MenuDefaults.DUPLICATE,
        MenuDefaults.SAVE_AS_TEMPLATE,
        MenuDefaults.ADD_CHILD(children),
    ];
};

const WorkshopMenu = ({
    component, componentLibrary, parent,
}) => {
    if (component.type === 'Image') {
        return [
            MenuDefaults.DUPLICATE,
        ];
    }
    if (component.type === 'TextInput') {
        return [
            MenuDefaults.DELETE,
            MenuDefaults.ADD_TEXT_INPUT,
        ];
    }
    const isTimelineColumn = parent.type === 'Timeline';
    const filter = isTimelineColumn ? (item) => (item.type === 'Text' || item.type === component.clickedArea || item.type === 'ProtocolGroup')
        : (item) => (item.type === 'Forms' || item.type === 'HTML' || item.type === 'Row');
    buildComponentMenu(componentLibrary, filter, null, 'sibling');

    return [
        MenuDefaults.DELETE,
    ];
};

const WorkshopImageMenu = ({ component }) => {
    if (component.type === 'Image') {
        if (!component?.props?.imgSrc?.isVideo) {
            return [
                MenuDefaults.REMOVE_MEDIA,
                MenuDefaults.DUPLICATE,
                MenuDefaults.EDIT_MEDIA,
            ];
        }
        return [
            MenuDefaults.REMOVE_MEDIA,
            MenuDefaults.DUPLICATE,
        ];
    }
    return [];
};

const WorkshopAddMenu = ({
    component, componentLibrary, parent,
}) => {
    const isTimelineColumn = parent.type === 'Timeline';
    const filter = isTimelineColumn ? (item) => (item.type === 'Text' || item.type === component.clickedArea || item.type === 'ProtocolGroup')
        : (item) => (item.type === 'Forms' || item.type === 'HTML' || item.type === 'Row');
    buildComponentMenu(componentLibrary, filter, null, 'sibling');

    return [
        MenuDefaults.DELETE,
        MenuDefaults.SAVE_AS_TEMPLATE,
    ];
};

const LRContext = React.createContext();
export {
    LRContext,
    Column,
    renderColEditorAction,
    ColumnEditor,
    ColumnEditMenu,
    ColumnPreview,
    ColumnTemplatePreview,
    WorkshopMenu,
    WorkshopAddMenu,
    SectionPreview,
    SectionEditor,
    Cell,
    SelectCell,
    OptionCell,
    EmptyCell,
    CellPreview,
    EmptyCellPreview,
    SelectCellPreview,
    OptionCellPreview,
    WorkshopImageMenu,
};
