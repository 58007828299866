const standardHeaders = {
    sectionHeader: {
        textAlign: 'center',
        background: '#000',
        color: '#FFF',
        padding: '4px',
    },
    h1: {
        textAlign: 'center',
        background: '#6d6d6d',
        padding: '0px',
        fontSize: 14,
    },
    h2: {
        textAlign: 'center',
        background: '#b1b1b1',
        padding: '0px',
    },
    h3: {
        textAlign: 'center',
        background: '#efefef',
        padding: '0px',
    },
    h4: {
        textAlign: 'left',
        background: '#FFFFFF',
        padding: '0px 0px 0px 4px',
        textDecoration: 'underline',
    },
    h5: {
        textAlign: 'center',
        background: '#a5b8e1',
        padding: '4px',
    },
    h6: {
        textAlign: 'center',
        background: '#d0daef',
        padding: '4px',
    },
    protocolgroup: {
        padding: '4px',
        textDecoration: 'underline',
    },
};

// eslint-disable-next-line import/prefer-default-export
export { standardHeaders };
