import React, {
    createContext,
} from 'react';

export const Context = createContext({});

export const Provider = ({ children, column }) => {
    const columnContext = {
        column,
    };

    // pass the value in provider and return
    return <Context.Provider value={columnContext}>{children}</Context.Provider>;
};

export const { Consumer } = Context;
