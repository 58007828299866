import {
    MediaContext, OrgContext, TimelineContext, WorkshopContext,
} from '../context';

import { Snackbar } from '@material-ui/core';
import {
    createPlayer, getFileType, getIframe, isVideo, randomString,
} from '../helper/commonHelper';
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import { useDropzone } from 'react-dropzone';
import { useLazyQuery, useMutation } from 'react-apollo';
import { useStateDesigner } from '@state-designer/react';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ErrorBoundary from './error-boundary';
import FolderIcon from '@material-ui/icons/Folder';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import React, {
    Suspense, lazy, useCallback, useContext, useEffect, useRef, useState,
} from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import YouTubeIcon from '@material-ui/icons/YouTube';
import YoutubeModal from './YoutubeModal';
import gql from 'graphql-tag';

const GalleryBreadcrumbs = lazy(() => import('./gallery-breadcrumbs.js'));

const GET_MEDIA = gql`
query GetMedia($orgId: uuid, $parentFolderId: uuid) {
    media(where: {organization_id: {_eq: $orgId}, folder_id: {_eq: $parentFolderId}, is_delete: {_eq: false}}) {
        id
        name
        created_at
        updated_at
        filetype
        url
        is_youtube
        folder_id
    }
}
`;

const CREATE_TIMELINE_FOLDER = gql`
    mutation CreateTimelineFolder($timeline_id: uuid) {
        insert_media_folder_new(objects: {timeline_id: $timeline_id}) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

const CREATE_COLUMN_FOLDER = gql`
    mutation CreateColumnFolder($timeline_id: uuid, $column_id: uuid) {
        insert_media_folder_new(objects: {timeline_id: $timeline_id, column_id: $column_id}) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

const CREATE_OTHERS_FOLDER = gql`
    mutation CreateOthersFolder($timeline_id: uuid, $column_id: uuid, $is_progression: Boolean, $component_id: uuid) {
        insert_media_folder_new(objects: {
            timeline_id: $timeline_id,
            column_id: $column_id,
            is_progression: $is_progression,
            component_id: $component_id}) {
            affected_rows
            returning {
                id
            }
        }
    }
`;
export const DELETE_CONTENT_DISPLAY_BY_PROTOCOL = gql`mutation DeleteContentDisplay($protocol_id: uuid) {
    delete_content_display(where: {protocol_id: {_eq: $protocol_id}}) {
      affected_rows
      returning {
        protocol_id
      }
    }
  }`;

export const DELETE_CONTENT_DISPLAY_BY_COLUMN = gql`mutation DeleteByColumn($column_id: uuid) {
    delete_content_display(where: {column_id: {_eq: $column_id}}) {
      affected_rows
      returning {
        column_id
      }
    }
  }`;

export const DELETE_CONTENT_DISPLAY_BY_TIMELINE = gql`mutation DeleteByTimeline($timeline_id: uuid) {
    delete_content_display(where: {timeline_id: {_eq: $timeline_id}}) {
      affected_rows
      returning {
        timeline_id
      }
    }
  }`;

export const useImgStyles = makeStyles(() => createStyles({
    customTooltip: {
        marginTop: '80px',
        backgroundColor: 'yellow',
        fontSize: '50px',
    },
    imageSet: {
        height: 150,
        boxShadow: 'none',
        borderRadius: '0px',
        position: 'relative',
    },
    columHeadType: {
        fontSize: '0.75rem',
    },
    iframeStyle: {
        textAlign: 'center',
    },
    placeHolderStyle: {
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderLeft: '0px',
        borderRight: '0px',
    },
    loader: {
        width: '14px !important',
        height: '14px !important',
        marginLeft: '15px',
    },
    uploadIcon: {
        fontSize: '29px',
        marginLeft: '15px',
        cursor: 'pointer',
    },
    videoStyle: {
        padding: '0px !important',
    },
    youtubeStyle: {
        margin: 'auto',
        padding: '0px !important',
        maxWidth: 227,
        '& iframe': {
            maxHeight: 150,
        },
    },
    frameOverlay: {
        height: '100 %',
        width: '100 %',
        background: 'transparent',
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'none',
    },
    imageUploadButtonCont: {
        display: 'flex',
        justifyContent: 'center',
        border: '0.5px solid #70707053',
        borderRadius: 2,
        boxShadow: '0px 0px 6px #00000029',
        alignItems: 'center',
        width: '97%',
        cursor: 'pointer',
        paddingTop: 3,
        paddingBottom: 3,
    },
    contentDisplayImageUpload: {
        display: 'flex',
        justifyContent: 'center',
        border: '0.5px solid #70707053',
        borderRadius: 2,
        boxShadow: '0px 0px 6px #00000029',
        alignItems: 'center',
        width: 200,
        cursor: 'pointer',
        paddingTop: 3,
        paddingBottom: 3,
        margin: 'auto',
    },
    videoUploadButtonCon: {
        display: 'flex',
        justifyContent: 'center',
        border: '0.5px solid #70707053',
        borderRadius: 2,
        boxShadow: '0px 0px 6px #00000029',
        alignItems: 'center',
        width: '100%',
        cursor: 'pointer',
        paddingTop: 3,
        paddingBottom: 3,
    },
    photoCameraStyle: {
        fontSize: 80,
        color: '#d3d3d3',
    },
    iconRoot: {
        padding: 0,
    },
    removeRadius: {
        borderRadius: '0px',
        position: 'relative',
        borderLeft: '0px',
        borderRight: '0px',
    },
    positSet: {
        background: 'white',
        textAlign: 'end',
        position: 'absolute',
        top: '5px',
        right: '5px',
        lineHeight: '0px',
        borderRadius: '50px',
        padding: '2px',
        zIndex: '99',
    },
    toolPositionTop: {
        position: 'relative',
    },
    dragCont: {
        minHeight: 10,
    },
    iconStyle: {
        fontSize: '.8rem',
        cursor: 'pointer',
    },
    addYoutubeLink: {
        color: '#163139',
        fontSize: 12,
        marginLeft: 1,
        fontWeight: 300,
    },
}));

const Image = ({
    onChange, imgSrc, classes, isInSettings, handleImageVisible = null,
}) => {
    const {
        selectedComponent, updateComponent,
    } = useContext(TimelineContext);
    const { inWorkshop } = useContext(WorkshopContext);
    const [imgPlaceholder, setImgPlaceholder] = useState(false);
    const mediaObj = JSON.parse(JSON.stringify(imgSrc));
    const uniqueWsId = randomString(10);
    if (mediaObj && mediaObj.name && isVideo(mediaObj.name)) {
        mediaObj.filetype = getFileType(mediaObj.name);
        mediaObj.videoDivId = (isInSettings) ? `video-div-settings-new-${uniqueWsId}` : `video-div-workshop-new-${uniqueWsId}`;
        mediaObj.isVideo = true;
    }

    const renderWorkshopMedia = (media) => {
        const mediaStyle = {
            width: '100%', margin: '0px auto', height: '148px', maxWidth: mediaObj.maxWidth, objectFit: 'contain',
        };

        let workshopContent = (
            <CardMedia
                component="img"
                alt="Selected Image"
                style={mediaStyle}
                image={media.url}
                title="Selected Image"
                onError={() => setImgPlaceholder(true)}
            />
        );
        if (media.isVideo) {
            workshopContent = (
                <CardActionArea style={mediaStyle}>
                    <CardContent className={media.is_youtube ? classes.youtubeStyle : classes.videoStyle}>
                        {inWorkshop && <div style={{ minHeight: 20 }} />}
                        {getIframe(media)}
                    </CardContent>
                </CardActionArea>
            );
        }
        return workshopContent;
    };

    useEffect(() => {
        if (mediaObj && mediaObj.isVideo) {
            createPlayer([mediaObj], false, 600);
        }
    });

    const afterDrop = (event) => {
        const draggedMediaProp = event.dataTransfer.getData('mediaProp');
        if (draggedMediaProp) {
            const mediaProp = JSON.parse(draggedMediaProp);
            onChange(mediaProp);
            selectedComponent.props.isMediaComp = true;
            updateComponent(selectedComponent, true, false, null, true);
            mediaProp.isShow = true;
        }
    };

    const showMedia = Object.prototype.hasOwnProperty.call(mediaObj, 'isShow') ? mediaObj.isShow : true;

    if (imgPlaceholder) {
        return (<ImagePlaceholder setImgPlaceholder={setImgPlaceholder} onChange={onChange} classes={classes} iconColor="primary" />);
    }

    return (
        <div onDrop={afterDrop} className={classes.imageSet}>
            <Card variant="outlined" className={classes.removeRadius}>
                {inWorkshop
                    && (
                        <div
                            className={classes.positSet}
                        >
                            {imgSrc?.isShow
                                && (
                                    <IconButton aria-label="visibility" classes={{ root: classes.iconRoot }} onClick={handleImageVisible}>
                                        <Visibility className={classes.iconStyle} />
                                    </IconButton>
                                )}
                            {imgSrc && !imgSrc?.isShow
                                && (
                                    <IconButton aria-label="visibility" classes={{ root: classes.iconRoot }} onClick={handleImageVisible}>
                                        <VisibilityOff className={classes.iconStyle} />
                                    </IconButton>
                                )}
                            {!imgSrc?.hasOwnProperty('isShow') && <IconButton />}
                        </div>
                    )}
                {showMedia && renderWorkshopMedia(mediaObj)}
            </Card>
        </div>
    );
};

export const ImagePlaceholder = ({
    iconColor, classes, onChange, imgSrc = null, handleImageVisible = null, setImgPlaceholder,
}) => {
    const {
        selectedComponent, updateComponent,
    } = useContext(TimelineContext);
    const { inWorkshop } = useContext(WorkshopContext);
    const rootElement = useRef(null);

    const afterDrop = (event) => {
        const draggedMediaProp = event.dataTransfer.getData('mediaProp');
        if (draggedMediaProp) {
            if (setImgPlaceholder) {
                setImgPlaceholder(false);
            }
            const mediaProp = JSON.parse(draggedMediaProp);
            mediaProp.isShow = true;
            mediaProp.maxWidth = rootElement.current.clientWidth - 15;
            onChange(mediaProp);
            selectedComponent.props.isMediaComp = true;
            updateComponent(selectedComponent, true, false, null, true);
        }
    };

    return (
        <div ref={rootElement}>
            <Card onDrop={afterDrop} className={classes.imageSet}>
                {inWorkshop
                    && (
                        <div className={classes.positSet} style={{ display: !imgSrc && 'none' }}>
                            {imgSrc?.isShow
                                && (
                                    <IconButton aria-label="visibility" classes={{ root: classes.iconRoot }} onClick={handleImageVisible}>
                                        <Visibility className={classes.iconStyle} />
                                    </IconButton>
                                )}
                            {imgSrc && !imgSrc?.isShow
                                && (
                                    <IconButton aria-label="visibility" classes={{ root: classes.iconRoot }} onClick={handleImageVisible}>
                                        <VisibilityOff className={classes.iconStyle} />
                                    </IconButton>
                                )}
                            {!imgSrc?.hasOwnProperty('isShow') && <IconButton />}
                        </div>
                    )}
                <CardActionArea className={classes.placeHolderStyle} aria-label="upload picture">
                    <PhotoCamera color={iconColor} className={classes.photoCameraStyle} />
                </CardActionArea>
            </Card>
        </div>
    );
};

const ImageGallery = ({ classes }) => {
    const loaderClass = useImgStyles();
    const playerRef = useRef();
    const playerRefValue = playerRef.current;
    const { uploadImage } = useContext(MediaContext);
    const columnCount = 5;
    const [isUploading, setIsUploading] = useState(false);
    const [youTubeLinkUploading, setYouTubeLinkUploading] = useState(false);
    const [maxFileError, setMaxFileError] = useState(false);
    const [isYoutubeFlag, setIsYoutubeFlag] = useState(false);
    const [folderLoading, setFolderLoading] = useState(false);
    const [folderError, setFolderError] = useState(false);
    const [folderList, setFolderList] = useState([]);
    const [mediaList, setMediaList] = useState([]);
    const [activeFolderDetails, setActiveFolderDetails] = useState({
        id: null,
        timeline_id: null,
        column_id: null,
        is_progression: null,
        component_id: null,
        parent_id: null,
        folder_name: null,
        folder_type: null,
    });
    const [clickedFolder, setClickedFolder] = useState(null);

    const { selectedOrg } = useContext(OrgContext);
    const {
        imageEditorLoader,
        contentDisplayCip,
        contentDisplayImageList,
        setContentDisplayImageList,
    } = useContext(TimelineContext);

    const [getMedia, {
        data: mediaData,
    }] = useLazyQuery(GET_MEDIA, {
        fetchPolicy: 'cache-and-network',
    });

    const [createTimelineFolder, {
        loading: timelineFolderLoading,
        error: timelineFolderError,
        data: timelineFolderData,
    }] = useMutation(CREATE_TIMELINE_FOLDER);

    const [createColumnFolder, {
        loading: columnFolderLoading,
        error: columnFolderError,
        data: columnFolderData,
    }] = useMutation(CREATE_COLUMN_FOLDER);

    const [createOthersFolder, {
        loading: othersFolderLoading,
        error: othersFolderError,
        data: othersFolderData,
    }] = useMutation(CREATE_OTHERS_FOLDER);

    useEffect(() => {
        if (mediaData?.media) {
            setMediaList([...mediaData?.media]);
        }
    }, [mediaData]);

    useEffect(() => {
        if (timelineFolderLoading) {
            setFolderLoading(true);
            setFolderError(false);
        } else if (timelineFolderError) {
            setFolderLoading(false);
            setFolderError(true);
        } else if (timelineFolderData?.['insert_media_folder_new']) {
            const folderId = timelineFolderData?.['insert_media_folder_new']?.returning?.[0]?.id;
            uploadImage(
                folderId,
                document.getElementById('mediaSelector').files,
                () => {
                    setIsUploading(true);
                },
                () => {
                    setIsUploading(false);
                    setActiveFolderDetails({
                        ...activeFolderDetails,
                        id: folderId,
                    });
                    getMedia({
                        variables: {
                            orgId: selectedOrg?.id,
                            parentFolderId: folderId,
                        },
                    });
                },
                (error) => {
                    // eslint-disable-next-line no-alert
                    alert(error?.messgae);
                    setIsUploading(false);
                    return true;
                }
            );
            setFolderLoading(false);
            setFolderError(false);
        }
    }, [timelineFolderLoading, timelineFolderError, timelineFolderData]);

    useEffect(() => {
        if (columnFolderLoading) {
            setFolderLoading(true);
            setFolderError(false);
        } else if (columnFolderError) {
            setFolderLoading(false);
            setFolderError(true);
        } else if (columnFolderData?.['insert_media_folder_new']) {
            const folderId = columnFolderData?.['insert_media_folder_new']?.returning?.[0]?.id;
            uploadImage(
                folderId,
                document.getElementById('mediaSelector').files,
                () => {
                    setIsUploading(true);
                },
                () => {
                    setIsUploading(false);
                    setActiveFolderDetails({
                        ...activeFolderDetails,
                        id: folderId,
                    });
                    getMedia({
                        variables: {
                            orgId: selectedOrg?.id,
                            parentFolderId: folderId,
                        },
                    });
                },
                (error) => {
                    // eslint-disable-next-line no-alert
                    alert(error?.messgae);
                    setIsUploading(false);
                    return true;
                }
            );
            setFolderLoading(false);
            setFolderError(false);
        }
    }, [columnFolderLoading, columnFolderError, columnFolderData]);

    useEffect(() => {
        if (othersFolderLoading) {
            setFolderLoading(true);
            setFolderError(false);
        } else if (othersFolderError) {
            setFolderLoading(false);
            setFolderError(true);
        } else if (othersFolderData?.['insert_media_folder_new']) {
            const folderId = othersFolderData?.['insert_media_folder_new']?.returning?.[0]?.id;
            uploadImage(
                folderId,
                document.getElementById('mediaSelector').files,
                () => {
                    setIsUploading(true);
                },
                () => {
                    setIsUploading(false);
                    setActiveFolderDetails({
                        ...activeFolderDetails,
                        id: folderId,
                    });
                    getMedia({
                        variables: {
                            orgId: selectedOrg?.id,
                            parentFolderId: folderId,
                        },
                    });
                },
                (error) => {
                    // eslint-disable-next-line no-alert
                    alert(error?.messgae);
                    setIsUploading(false);
                    return true;
                }
            );
            setFolderLoading(false);
            setFolderError(false);
        }
    }, [othersFolderLoading, othersFolderError, othersFolderData]);

    useEffect(() => {
        if (!imageEditorLoader && activeFolderDetails?.id) {
            getMedia({
                variables: {
                    orgId: selectedOrg?.id,
                    parentFolderId: activeFolderDetails?.id,
                },
            });
        }
    }, [imageEditorLoader]);

    const setDragAttribute = (image, ev) => {
        ev.dataTransfer.setData('mediaProp', JSON.stringify(image));
    };

    const triggerFileSelection = () => {
        document.getElementById('mediaSelector').click();
    };

    const mediaSelected = (event) => {
        if (event.currentTarget.files && event.currentTarget.files.length > 10) {
            setMaxFileError(true);
            return;
        }
        if (event.currentTarget.files && event.currentTarget.files.length > 0) {
            if (activeFolderDetails?.id) {
                uploadImage(
                    activeFolderDetails?.id,
                    event.currentTarget.files,
                    () => {
                        setIsUploading(true);
                    },
                    (insertMediaResult) => {
                        setIsUploading(false);
                        mediaList.push(insertMediaResult);
                    },
                    (error) => {
                        // eslint-disable-next-line no-alert
                        alert(error?.messgae);
                        setIsUploading(false);
                        return (true);
                    }
                );
            } else if (activeFolderDetails?.['folder_type'] === 'timeline') {
                createTimelineFolder({
                    variables: {
                        timeline_id: activeFolderDetails?.['timeline_id'],
                    },
                });
            } else if (activeFolderDetails?.['folder_type'] === 'column') {
                createColumnFolder({
                    variables: {
                        timeline_id: activeFolderDetails?.['timeline_id'],
                        column_id: activeFolderDetails?.['column_id'],
                    },
                });
            } else if (activeFolderDetails?.['folder_type'] === 'others') {
                createOthersFolder({
                    variables: {
                        timeline_id: activeFolderDetails?.['timeline_id'],
                        column_id: activeFolderDetails?.['column_id'],
                        is_progression: activeFolderDetails?.['is_progression'],
                        component_id: activeFolderDetails?.['component_id'],
                    },
                });
            } else if (activeFolderDetails?.['folder_type'] === 'custom') {
                // no need because custom folders can never be virtual
            }
        }
    };

    const getIcon = (status) => {
        if (status) {
            return (<CircularProgress className={loaderClass.loader} />);
        }

        return (
            <CloudUploadIcon
                style={{ color: '#306A9D', height: 14, width: 14 }}
            />
        );
    };

    const getYoutubeIcon = () => {
        if (youTubeLinkUploading) {
            return (<CircularProgress className={loaderClass.loader} />);
        }

        return (
            <YouTubeIcon
                style={{ color: 'red', height: 14, width: 14 }}
            />
        );
    };

    mediaList.forEach((media, key) => {
        if (isVideo(media.name)) {
            media.filetype = getFileType(media.name);
            media.videoDivId = `video-div-gallery-${key}`;
            media.isVideo = true;
        }
    });

    const handleContentDisplay = (mediaUrl) => {
        const contentDisplayImageListCopy = [...contentDisplayImageList].filter((ele) => ele?.url !== mediaUrl);
        if (contentDisplayImageListCopy?.length === contentDisplayImageList?.length) {
            contentDisplayImageListCopy.push({
                slideTitle: 'Slide Title',
                slideCaption: 'Slide Caption',
                imageTitle: 'Image Title',
                imageCaption: 'Image Caption',
                url: mediaUrl,
            });
        }
        setContentDisplayImageList([...contentDisplayImageListCopy]);
    };

    const renderMedia = (media) => {
        let renderContent = (
            <img
                onDragStart={setDragAttribute.bind(this, media)}
                draggable="true"
                style={{ width: '100%', height: '100%' }}
                src={media.url}
                alt={media.name}
                loading="lazy"
            />
        );
        if (contentDisplayCip) {
            let isContentDisplayImage = false;
            for (let i = 0, l = contentDisplayImageList?.length; i < l; i += 1) {
                if (contentDisplayImageList?.[i]?.url === media?.url) {
                    isContentDisplayImage = true;
                    break;
                }
            }
            renderContent = (
                <img
                    onDragStart={setDragAttribute.bind(this, media)}
                    draggable="true"
                    style={{ width: '100%', height: '100%', border: isContentDisplayImage ? '2px solid red' : 'none' }}
                    src={media.url}
                    alt={media.name}
                    loading="lazy"
                    onClick={() => handleContentDisplay(media?.url)}
                />
            );
        }
        if (media.isVideo && !media.is_youtube) {
            renderContent = (
                <div onDragStart={setDragAttribute.bind(this, media)} draggable="true">
                    <div className={loaderClass.dragCont} />
                    <div className={classes.videoEditable} />
                    <div ref={playerRef} id={media.videoDivId} className="galleryVideoSize1" />
                </div>
            );
        }

        if (media.isVideo && media.is_youtube) {
            renderContent = (
                <div onDragStart={setDragAttribute.bind(this, media)} draggable="true">
                    <div className={loaderClass.dragCont} />
                    <div className={classes.videoEditable} />
                    <div id={media.videoDivId} className={classes.galleryVideoSize}>
                        <iframe
                            frameBorder="0"
                            allow="autoplay; encrypted-media"
                            allowFullScreen
                            title="video"
                            src={`https://www.youtube.com/embed/${media.url}`}
                        />
                    </div>
                </div>
            );
        }
        return renderContent;
    };

    useEffect(() => {
        if (playerRef.current && mediaList) { createPlayer(mediaList, false, 600, undefined, playerRefValue); }
    }, [mediaList, playerRefValue]);

    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles.length > 10) {
            setMaxFileError(true);
            return;
        }
        uploadImage(
            acceptedFiles,
            () => { setIsUploading(true); },
            () => { setIsUploading(false); },
            // eslint-disable-next-line no-console
            (error) => { console.log(error); return true; }
        );
    }, []);
    const { getInputProps } = useDropzone({ onDrop });

    const gridStyle2 = {
        maxWidth: 120,
        '& img': {
            width: '100%',
        },
    };

    const handleCloseToast = () => {
        setMaxFileError(false);
    };

    const handleYoutubeModal = () => {
        setIsYoutubeFlag(!isYoutubeFlag);
    };

    const TextOnlyTooltip = withStyles({
        tooltip: {
            position: 'relative !important',
            top: '75px',
        },
    })(Tooltip);

    const handleFolderDoubleClick = (folder) => {
        setClickedFolder(folder);
    };

    return (
        <Paper elevation={3}>
            <Snackbar open={maxFileError} autoHideDuration={5000} onClose={handleCloseToast} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error">
                    Maximum 10 Media allowed to upload at a time!
                </Alert>
            </Snackbar>

            <Grid
                container
                justify="space-around"
                direction="row"
                style={{
                    padding: 4,
                    marginTop: 8,
                }}
            >
                <Grid
                    item
                    xs={contentDisplayCip ? 12 : 6}
                >
                    <Paper
                        className={contentDisplayCip ? loaderClass.contentDisplayImageUpload : loaderClass.imageUploadButtonCont}
                        onClick={isUploading ? undefined : triggerFileSelection}
                    >
                        <CardActions style={{ padding: 0 }}>
                            {getIcon(isUploading)}
                            <Typography style={{
                                color: '#163139',
                                fontSize: 12,
                                marginLeft: 9,
                                fontWeight: 300,
                            }}
                            >
                                Upload Media
                            </Typography>
                            <input
                                style={{ display: 'none' }}
                                type="file"
                                id="mediaSelector"
                                accept="image/png, image/jpeg, video/mp4, video/quicktime"
                                multiple
                                onChange={mediaSelected}
                            />
                        </CardActions>
                    </Paper>
                </Grid>
                {!contentDisplayCip
                    ? (
                        <Grid
                            item
                            xs={6}
                        >
                            <Paper
                                className={loaderClass.videoUploadButtonCon}
                                onClick={youTubeLinkUploading ? undefined : handleYoutubeModal}
                            >
                                {getYoutubeIcon()}
                                <Typography className={loaderClass.addYoutubeLink}>
                                    Add Youtube Embed Link
                                </Typography>
                            </Paper>
                        </Grid>
                    ) : null}
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <ErrorBoundary messgae="gallery-breadcrumbs.js can't be imported.">
                        <Suspense fallback={<Typography>loading breadcrumbs....</Typography>}>
                            <GalleryBreadcrumbs
                                setFolderLoading={setFolderLoading}
                                setFolderError={setFolderError}
                                setFolderList={setFolderList}
                                setMediaList={setMediaList}
                                activeFolderDetails={activeFolderDetails}
                                setActiveFolderDetails={setActiveFolderDetails}
                                clickedFolder={clickedFolder}
                                setClickedFolder={setClickedFolder}
                            />
                        </Suspense>
                    </ErrorBoundary>
                </Grid>
            </Grid>
            <div
                style={{
                    width: '100%',
                    height: 'auto',
                    overflow: 'scroll',
                }}
            >
                <input {...getInputProps()} />
                <Card
                    className={classes.root}
                >
                    <CardContent className={classes.toolPositionTopCart} style={{ padding: 4 }}>
                        {folderLoading && <div style={{ textAlign: 'center' }}><CircularProgress /></div>}
                        {folderError && <Typography>Something Went Wrong. Please Try Again.</Typography>}
                        <GridList cellHeight={100} cols={columnCount}>
                            {folderList.map((folder) => (
                                <GridListTile key={folder.id} style={{ height: 'auto', wordBreak: 'break-all' }}>
                                    <FolderIcon
                                        style={{
                                            color: 'cornflowerblue',
                                            cursor: 'pointer',
                                            width: '100%',
                                            height: '70%',
                                        }}
                                        onDoubleClick={() => handleFolderDoubleClick(folder)}
                                    />
                                    <small style={{ position: 'relative', top: '-10px', display: 'block' }}>{folder.name}</small>
                                </GridListTile>
                            ))}
                            {mediaList.map((media) => (
                                <GridListTile style={gridStyle2} className={classes.toolPositionTop} key={media.url}>
                                    <TextOnlyTooltip
                                        title={media.name}
                                        classes={{ tooltip: classes.customTooltip }}
                                        placement="top"
                                    >
                                        {renderMedia(media)}
                                    </TextOnlyTooltip>
                                </GridListTile>
                            ))}
                        </GridList>
                    </CardContent>
                </Card>
            </div>

            {isYoutubeFlag && (
                <YoutubeModal
                    open={isYoutubeFlag}
                    handleClose={handleYoutubeModal}
                    activeFolderDetails={activeFolderDetails}
                    setActiveFolderDetails={setActiveFolderDetails}
                    mediaList={mediaList}
                    setMediaList={setMediaList}
                    setYouTubeLinkUploading={setYouTubeLinkUploading}
                    setFolderLoading={setFolderLoading}
                    setFolderError={setFolderError}
                />
            )}
        </Paper>
    );
};

function LinearProgressWithLabel(props) {
    const { label, value } = props;
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <Typography variant="body2" color="textSecondary">
                    {label}
                </Typography>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">
                    {`${Math.round(
                        value,
                    )}%`}
                </Typography>
            </Box>
        </Box>
    );
}

const renderUploading = (state) => state.isIn('uploading') && (
    <LinearProgressWithLabel
        label="Uploading new Media"
        value={state.data.uploadPercent}
    />
);

/**
 * Image Editor Component
 */
const ImageSelector = ({ imgSrc, onChange, isInSettings }) => {
    const classes = useImgStyles();
    const {
        uploadImage,
    } = useContext(MediaContext);
    const [flag, setFlag] = useState(false);

    const handleImageVisible = () => {
        imgSrc.isShow = imgSrc?.hasOwnProperty('isShow') ? !imgSrc?.isShow : (!!imgSrc);
        setFlag(!flag);
    };

    const state = useStateDesigner({
        id: 'imageSelector',
        data: {
            uploadPercent: 0,
            uploadFailureMessage: null,
            popupLocation: null,
        },
        initial: 'display',
        states: {
            display: {
                initial: {
                    if: 'hasImage',
                    to: 'hasImage',
                    else: { to: 'noImage' },
                },
                on: {
                    CLICKED: ['setGalleryLocation', { to: 'showGallery' }],
                    DRAG_ENTER: { to: 'dragOver' },
                    ON_DROP: ['resetUpload', { to: 'uploading' }],
                    IMAGE_CHANGED: ['setImage', { to: 'display' }],
                },
                states: {

                    noImage: {

                    },
                    hasImage: {
                        on: {
                            REMOVE_IMAGE: ['clearImage', { to: 'noImage' }],
                        },
                    },
                    showGallery: {
                        on: {
                            IMAGE_SELECTED: ['setImage', { to: 'display' }],
                            CLOSE_GALLERY: ['clearGalleryLocation', { to: 'display' }],
                        },

                    },
                },
            },
            imageUpload: {
                initial: 'idle',
                states: {
                    idle: {},
                    on: {

                    },
                    dragOver: {
                        on: {
                            DRAG_LEAVE: { to: 'display' },
                            ON_DROP: ['resetUpload', { to: 'uploading' }],
                        },
                    },
                    uploading: {
                        on: {
                            UPDATE_PROGRESS: ['setUploadProgress'],
                            UPLOAD_COMPLETE: ['setImage', { to: 'display' }],
                            UPLOAD_FAILED: ['setUploadError', { to: 'uploadFailed' }],
                        },
                    },
                    uploadComplete: {},
                    uploadFailed: {},
                },
            },
        },
        conditions: {
            hasImage() {
                return imgSrc;
            },
        },
        actions: {
            resetUpload(d) {
                d.uploadPercent = 0;
                d.uploadFailureMessage = null;
            },
            setUploadProgress(d, { uploadPercent }) {
                d.uploadPercent = uploadPercent;
            },
            setUploadError(d, { uploadFailureMessage }) { d.uploadPercent = uploadFailureMessage; },
            setImage(d, { imgSrc }) {
                onChange(imgSrc);
            },
            clearImage() {
                onChange(null);
            },
            setGalleryLocation(d, { location }) {
                d.popupLocation = location;
            },
            clearGalleryLocation(d) {
                d.popupLocation = null;
            },
        },
    });

    const onProgress = useCallback((progress) => {
        state.send('UPDATE_PROGRESS', { uploadPercent: progress });
    }, []);

    const onComplete = useCallback((image) => {
        state.send('UPLOAD_COMPLETE', { imgSrc: image });
    }, []);

    const onError = useCallback(() => {
    }, []);

    const onDrop = useCallback((acceptedFiles) => {
        //
        state.send(acceptedFiles && acceptedFiles.length > 0 ? 'ON_DROP' : 'DRAG_LEAVE', { acceptedFiles });
        if (acceptedFiles && acceptedFiles.length > 0) {
            uploadImage(acceptedFiles[0], onProgress, onComplete, onError);
        }
    }, []);

    const onDragEnter = useCallback((event) => {
        event.stopPropagation();
        state.send('DRAG_ENTER');
    }, []);

    const onDragLeave = useCallback((event) => {
        event.stopPropagation();
        state.send('DRAG_LEAVE');
    }, []);

    state.onUpdate(() => {
    });
    const {
        getRootProps, getInputProps, open,
    } = useDropzone({
        onDragEnter, onDragLeave, onDrop, noClick: true, maxFiles: 1, accept: 'image/jpeg, image/png, video/*',
    });
    const uploading = renderUploading(state);

    const showMedia = imgSrc?.hasOwnProperty('isShow') ? imgSrc?.isShow : true;
    return (
        <>
            <div {...getRootProps()}>
                <input {...getInputProps()} />
                {state.isIn('dragOver') && <ImagePlaceholder classes={classes} iconColor="primary" />}
                {!state.isIn('dragOver') && (showMedia && imgSrc
                    ? (
                        <Image
                            isInSettings={isInSettings}
                            onChange={onChange}
                            uploading={uploading}
                            imgSrc={imgSrc}
                            showFileDialog={open}
                            classes={classes}
                            handleImageVisible={handleImageVisible}
                        />
                    )
                    : (
                        <ImagePlaceholder
                            onChange={onChange}
                            uploading={uploading}
                            classes={classes}
                            imgSrc={imgSrc}
                            handleImageVisible={handleImageVisible}
                        />
                    ))}
            </div>
        </>
    );
};

export {
    ImageSelector,
    ImageGallery,
};
