import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import Paper from '@material-ui/core/Paper';
import React, { useRef } from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        borderRadius: 0,
        height: 35,
        paddingTop: '7px',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
        fontSize: '.65rem',
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));

const noOpp = () => { };

const AddInput = ({
    value = '', onChange = noOpp, onAdd = noOpp, placeholder = 'Add Option',
}) => {
    const classes = useStyles();

    const inputFieldRef = useRef(null);

    const onSubmit = (e) => {
        e.preventDefault();
        if (inputFieldRef.current.children[0].value) {
            onAdd();
        }
    };

    return (
        <Paper component="form" onSubmit={onSubmit} className={classes.root} elevation={0}>
            <Input
                ref={inputFieldRef}
                className={classes.input}
                placeholder={placeholder}
                inputProps={{ 'aria-label': 'Add Option' }}
                value={value}
                onChange={(e) => { onChange(e.target.value); }}
                disableUnderline
            />
        </Paper>
    );
};

export default AddInput;
