import { Container } from './Container';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import React, { useEffect, useState } from 'react';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const useRowStyles = makeStyles((theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

const Panel = ({ props: { header, components, defaultExpanded = true }, noChildren = false }) => {
    const classes = useRowStyles();

    if (noChildren) {
        return (<Typography className={classes.heading}>{header}</Typography>);
    }

    return (
        <ExpansionPanel defaultExpanded={defaultExpanded}>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
            >
                <Typography className={classes.heading}>{header}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Container props={{ components }} />
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },

    buttonGroup: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

// TODO implement Container Editor
const PanelEditor = ({ config, updateComponent }) => {
    const classes = useStyles();
    const [defaultExpanded, setDefaultExpanded] = useState(config.props.defaultExpanded);
    const [header, setHeader] = useState(config.props.header);

    useEffect(() => {
        setDefaultExpanded(config.props.defaultExpanded);
        setHeader(config.props.header);
    }, [config.props.defaultExpanded, config.props.header]);

    const handleExpandedChange = (event) => {
        setDefaultExpanded(event.target.checked);
    };

    const handleHeaderChange = (event) => {
        setHeader(event.target.value);
    };

    const save = () => {
        config.props.header = header;
        updateComponent(config);
    };

    const revert = () => {
        setHeader(config.props.text);
    };

    return (
        <div>
            <div>
                <FormControl component="fieldset">
                    <FormLabel component="legend" />
                    <FormGroup>
                        <FormControlLabel
                            control={<Switch checked={defaultExpanded} onChange={handleExpandedChange} name="defaultExpanded" />}
                            label="Default Expanded"
                        />
                    </FormGroup>
                </FormControl>
            </div>
            <FormControl variant="filled" className={classes.formControl}>
                <TextField label="text" variant="outlined" value={header} onChange={handleHeaderChange} />
            </FormControl>
            <div className={classes.buttonGroup}>
                <Button variant="contained" onClick={revert}>Revert</Button>
                <Button variant="contained" color="primary" onClick={save}>
                    Save
                </Button>

            </div>
        </div>
    );
};

export { Panel, PanelEditor };
