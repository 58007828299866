import 'date-fns';
import {
    Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Typography,
} from '@material-ui/core';
import { OrgContext, TimelineContext, UserContext } from '../../context';
import {
    createStyles, makeStyles,
} from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CloseIcon from '@material-ui/icons/Close';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ManagePatientModel from '../ManagePatientModel';
import ManageUserModel from '../ManageUserModel';
import Modal from '@material-ui/core/Modal';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import React, {
    Fragment, useContext, useEffect, useState,
} from 'react';

/**
 * @function useStyles
 * @description css for modal popup
 * @returns {object}
 */
const useStyles = makeStyles((theme) => createStyles({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: '.25rem',
        boxShadow: theme.shadows[5],
        maxWidth: 675,
        minWidth: 500,
        padding: '1rem 0rem .5rem 0rem',
    },
    modalHeader: {
        borderBottom: '1px solid #cbcbcb',
        padding: '0rem 1rem .375rem 1rem',
    },
    radioGroupStyle: {
        flexDirection: 'row',
    },
    radiobutton: {
        '& span, & svg': {
            fontSize: 14,
            marginBottom: 2,
        },
    },
    personAddStyle: {
        color: '#066785',
        margin: '1.25rem 1rem 1rem 1rem',
        fontSize: '2rem',
        cursor: 'pointer',
    },
    primaryBtn: {
        maxHeight: '56px',
        padding: '.25rem 1.5rem',
        boxShadow: '0px 0px 20px -4px rgba(51,36,5,0.37)',
        marginLeft: '1.5rem',
        '&:hover': {
            boxShadow: 'none',
        },
    },
    cancelBtn: {
        maxHeight: '56px',
        padding: '.25rem 1.25rem',
        boxShadow: 'none',
        '&:hover': {
            boxShadow: 'none',
        },
    },
    buttonCont: {
        textAlign: 'end',
    },
    capitalizeLabel: {
        textTransform: 'capitalize',
    },
    closeIcon: {
        cursor: 'pointer',
    },
}));

/**
 * @constructor EditorModal
 * @description create the modal pop. Taking props from parent and pass props to child component
 * @param {orgID}
 * @param {selectedUser}
 * @param {toggleDrawer}
 * @param {initialUserData}
 */
const ManageUser = ({
    isShareModal, handleModalClose, displayCongigureDialog,
}) => {
    const classes = useStyles();

    const { currentOrg } = useContext(TimelineContext);
    const { loggedUserType } = useContext(OrgContext);
    let { allUserData } = useContext(UserContext);
    const { fetchAllUserData, roles } = useContext(UserContext);
    const userTypes = [...new Set(roles.map((item) => item.role))];

    let radioList = [];
    let defaultUser = '';
    switch (loggedUserType) {
    case 'Super Admin':
        radioList.push('Doctor');
        defaultUser = 'Doctor';
        break;
    default: {
        const position = userTypes.indexOf('Doctor');
        if (position) userTypes.splice(position, 1);
        radioList = [...userTypes];
        defaultUser = radioList?.[0];
    }
        break;
    }

    const [state, setState] = useState({
        userType: defaultUser, user: '', isModalOpen: false, userData: [], cloneUserData: [],
    });

    const makeCustomUser = (userId = null) => {
        const items = [];
        allUserData.forEach((a) => {
            const obj = {
                id: a.id,
                name: a.name,
                lastName: a.last_name,
                role: a?.['organization_members'][0]?.role,
                organization_members: a.organization_members,
            };
            items.push(obj);
        });
        const data = items.filter((item) => item.role === state.userType);

        if (userId) {
            setState({
                ...state, user: userId, isModalOpen: false, userData: [...data], cloneUserData: [...items],
            });
        } else {
            setState({ ...state, userData: [...data], cloneUserData: [...items] });
        }
    };

    useEffect(() => {
        if (allUserData && state.cloneUserData.length === 0) {
            makeCustomUser();
        }
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updateState = { ...state };
        if (name === 'userType' && state.cloneUserData.length > 0) {
            let data = null;
            data = updateState.cloneUserData.filter((u) => u.role === value);
            updateState.userData = [...data];
        }
        setState({ ...updateState, [name]: value });
    };

    const onSubmit = () => {
        handleModalClose(state.userData.find((user) => user.id === state.user));
        displayCongigureDialog();
    };

    const timelineUserHandler = async (userId) => {
        allUserData = await fetchAllUserData();
        makeCustomUser(userId);
    };

    const addDoctorHandler = () => {
        setState({ ...state, isModalOpen: true });
    };

    const handleModalStatus = () => {
        setState({ ...state, isModalOpen: false });
    };

    /**
     * @function renderActionButton
     * @description enable and disable the action button
     */
    const renderActionButton = () => {
        const isDisable = !state.user;

        return (
            <Fragment>
                <Box clone>
                    <Grid container>
                        <Grid item xs={12} className={classes.buttonCont}>
                            <Button
                                className={classes.cancelBtn}
                                variant="contained"
                                onClick={handleModalClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                className={classes.primaryBtn}
                                disabled={isDisable}
                                variant="contained"
                                color="primary"
                                onClick={onSubmit}
                            >
                                User Access
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Fragment>
        );
    };

    return (
        <>
            <Modal
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                className={classes.modal}
                open={isShareModal}
                onClose={handleModalClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <div className={classes.paper}>
                    <Grid container className={classes.modalHeader}>
                        <Grid item md={11} lg={11}>
                            <Box clone mb={3}>
                                <Typography variant="h2" id="simple-modal-title">
                                    Share timeline with:
                                    {' '}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item md={1} lg={1} className={classes.buttonCont}>
                            <CloseIcon className={classes.closeIcon} onClick={handleModalClose} />
                        </Grid>
                    </Grid>
                    <Box px={3.5} py={1}>
                        <Grid container>
                            {loggedUserType !== 'Super Admin' && (
                                <Grid item md={12} lg={12}>
                                    <Box mt={1}>
                                        <FormControl component="fieldset">
                                            <RadioGroup
                                                aria-label="gender"
                                                name="userType"
                                                value={state.userType}
                                                onChange={handleChange}
                                                classes={{ root: classes.radioGroupStyle }}
                                            >
                                                {radioList.map((role, i) => (
                                                    <FormControlLabel
                                                        key={i}
                                                        value={role}
                                                        control={<Radio className={classes.radiobutton} />}
                                                        label={role}
                                                    />
                                                ))}
                                            </RadioGroup>
                                        </FormControl>
                                    </Box>
                                </Grid>
                            )}
                            {state.userType && (
                                <>
                                    <Grid item xs={11}>
                                        <Box mb={4} mt={1}>
                                            <FormControl variant="outlined" fullWidth>
                                                <InputLabel
                                                    id="demo-simple-select-outlined-label"
                                                    className={classes.capitalizeLabel}
                                                >
                                                    {state.userType}
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    name="user"
                                                    value={state.user}
                                                    onChange={handleChange}
                                                    label={state.userType}

                                                >
                                                    {state.userData.length > 0 && state.userData.map((user) => (
                                                        <MenuItem value={user.id}>
                                                            {user.name}
                                                            {user.last_name}
                                                        </MenuItem>

                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={1} className={classes.configButtonCont}>
                                        <PersonAddIcon classes={{ root: classes.personAddStyle }} onClick={addDoctorHandler} />
                                    </Grid>
                                    <Grid item md={12} lg={12}>
                                        <Grid container direction="row" justify="flex-end" alignItems="center">
                                            {renderActionButton()}
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Box>
                </div>
            </Modal>
            <>
                {state?.isModalOpen && (state.userType === 'Doctor' || state.userType === 'User' || state.userType === 'Admin')
                    && (
                        <ManageUserModel
                            open={state.isModalOpen}
                            selectedUser={{}}
                            handleClose={() => handleModalStatus(false)}
                            orgID={currentOrg.id}
                            routeLabel={state.userType}
                            timelineUser={timelineUserHandler}
                        />
                    )}
                {state?.isModalOpen && state.userType === 'Patient'
                    && (
                        <ManagePatientModel
                            open={state.isModalOpen}
                            selectedPatient={{}}
                            handleClose={() => handleModalStatus(false)}
                            orgID={currentOrg.id}
                            routeLabel={state.userType}
                            timelineUser={timelineUserHandler}
                        />
                    )}
            </>
        </>
    );
};

export default ManageUser;
