import { MenuDefaults, buildComponentMenu } from './utils/EditorMenu';

const ColumnSectionEditMenu = ({ componentLibrary, component, copyProtocolComponent }) => {
    const clickedArea = component.clickedArea || (component.isProgression ? 'Progression' : 'Protocol');
    const labelMaker = (label) => (`Add ${label}`);
    const add = buildComponentMenu(componentLibrary, (item) => (item.id === 'cl-1'
        || item.type === 'Protocol' || item.type === 'ProtocolGroup'), labelMaker, 'child');
    let menu = [
        ...add,
    ];

    if (clickedArea === 'Protocol' && copyProtocolComponent) {
        menu = [...menu, MenuDefaults.PASTE_PROTOCOL_CHILD];
    }

    if (clickedArea === 'Progression') {
        delete menu[1].children[4];
    }

    if (clickedArea === 'Protocol') {
        delete menu[1].children[3];
    }
    return menu;
};

export default ColumnSectionEditMenu;
