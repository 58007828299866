import { Column, ColumnPreview } from './Column';
import { FilterSettings, setActiveComponent } from '../helper/commonHelper';
import { MenuDefaults, buildComponentMenu } from './utils/EditorMenu';
import { Settings } from './Settings';
import { TimelineContext, WorkshopContext } from '../context';
import { componentWithId } from './Atoms';
import { makeStyles } from '@material-ui/core/styles';
import { useRecoilState } from 'recoil';
import Grid from '@material-ui/core/Grid';
import React, { Fragment, useContext } from 'react';

/**
 * Define the setting's dropdown value
 */
const TitleProp = {
    key: 'title',
    type: 'TextField',
    label: 'Row Title',
    isBasic: true,
    isAdvance: true,
    isAdmin: true,
};

const RowProps = {
    display: [TitleProp],
    poi: true,
};

const useRowStyles = makeStyles(() => ({
    column: {
        borderLeft: '.25px solid #cbcbcb',
        padding: 0,
    },
    selectedComp: {
        background: 'cornflowerblue',
    },
    addRowBtnCls: {
        textAlign: 'right',
    },
    btnCls: {
        border: 'none',
        cursor: 'pointer',
        color: '#066785',
    },
    fullWidth: {
        width: '100%',
    },
    rowPrevCont: {
        width: '100%',
        borderLeft: '.25px solid #ececec',
    },
}));

const Row = ({
    row, componentParent, componentIndex, componentColumn,
}) => {
    const { components: columns } = row.props;
    const classes = useRowStyles();
    const { inWorkshop, compareModal } = useContext(WorkshopContext);
    const {
        settingComponent, setSettingComponentHandle, setIsFullLR,
    } = useContext(TimelineContext);
    const rowSize = parseFloat(12 / columns.length);

    let isSelected = false;

    if (settingComponent && settingComponent.id === row.id) {
        isSelected = true;
    }

    let isFirstComponentDropDown = false;

    const renderColumn = (column, index) => {
        if (column?.props?.isComplexForm && !inWorkshop) {
            if (index === 0) {
                if ((column?.props?.components?.[0]?.type === 'Select'
                    || column?.props?.components?.[0]?.type === 'MultiSelect')
                        && column?.props?.components?.[0]?.props.renderAs === 'select') {
                    isFirstComponentDropDown = true;
                }
            }
            if (isFirstComponentDropDown && (index === 1 || index === 2)) {
                // column.props.components.length = 1;
                column.props.components.forEach((element, ColIndex) => {
                    if (ColIndex !== 0) {
                        element.skipTimelinePreview = true;
                    }
                });
            }
        }
        let columnJSX = (
            <Column
                key={column.id}
                column={column}
                parent={row}
                index={index}
                classes={classes}
                rowSize={rowSize}
                componentParent2={componentParent}
                componentIndex={componentIndex}
                componentColumn={componentColumn}
                flexTextField={row.props.flexTextField}
            />
        );

        if (column?.props?.isComplexForm && !inWorkshop) {
            if (column?.props?.LRProps) { // LR CF
                if (index === 0 && !column?.props?.title && column?.props?.components?.length === 0) { // LR CF 1st column
                    columnJSX = null;
                } else if (index === 1) { // LR CF 2nd column
                    let noFormComponent = true;
                    for (let i = 0, l = column?.props?.components?.length; i < l; i += 1) {
                        if (column?.props?.components?.[i]?.props?.components?.length > 0) {
                            noFormComponent = false;
                            break;
                        }
                    }
                    if (!column?.props?.title && noFormComponent) {
                        columnJSX = null;
                    }
                }
            } else if (index === 0 && !column?.props?.title && column?.props?.components?.length === 0) { // 1 sided CF, 1st column
                columnJSX = null;
            } else if (index === 1) { // 1 sided CF, 2nd column
                let noFormComponent = true;
                for (let i = 0, l = column?.props?.components?.length; i < l; i += 1) {
                    if (column?.props?.components?.[i]?.props?.components?.length > 0) {
                        noFormComponent = false;
                        break;
                    }
                }
                if ((!column?.props?.title && noFormComponent) || column?.props?.hasOwnProperty('renderColumnInTimeline')) {
                    columnJSX = null;
                }
            } else if (index === 2) { // 1 sided CF, 3rd column
                let noFormComponent = true;
                OUTER_LOOP : for (let i = 0, l = column?.props?.components?.length; i < l; i += 1) {
                    for (let j = 0, m = column?.props?.components?.[i]?.props?.components?.length; j < m; j += 1) {
                        if (column?.props?.components?.[i]?.props?.components?.[j]?.type === 'Checkbox'
                                || column?.props?.components?.[i]?.props?.components?.[j]?.props?.components?.length > 0) {
                            noFormComponent = false;
                            break OUTER_LOOP;
                        }
                    }
                }
                if (!column?.props?.title && noFormComponent) {
                    columnJSX = null;
                }
            }
        }

        if (!inWorkshop && column.props.isMixedForm && (!column.props.title || column.props.components.length === 0)) {
            columnJSX = null;
        }

        return (columnJSX);
    };

    const isInsideRow = row?.props?.components?.find((c) => c?.props?.isComplexForm);

    if (row?.props?.LRProps?.LRtype && row?.mirrorId) {
        const comps = row?.props?.components;
        if ((comps.length === 1) || (comps.length === 2 && comps?.[1]?.props?.components?.length === 0)) {
            setIsFullLR(false);
        } else setIsFullLR(true);
    }

    return (
        <div
            aria-hidden="true"
            className={classes.fullWidth}
            id={isSelected && !compareModal ? 'selectedCompId' : ''}
            onClick={(event) => { event.stopPropagation(); setActiveComponent(setSettingComponentHandle, row); }}
        >
            <Grid container>
                {(inWorkshop && !compareModal) && (
                    <Fragment>
                        <Grid
                            item
                            className={classes.workshopHeader}
                            xs={12}
                        />
                    </Fragment>
                )}

                {isInsideRow ? (
                    <Grid id="innerRow" className={classes.fullWidth}>
                        <Grid item xs={12}>
                            <Grid container>
                                {columns.map((column, index) => (
                                    renderColumn(column, index)
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        <Grid container>
                            {columns.map((column, index) => (
                                renderColumn(column, index)
                            ))}
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

const RowPreview = ({ component }) => {
    const { components: columns } = component.props;
    const classes = useRowStyles();
    return (
        <div className={classes.rowPrevCont}>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container>
                        {columns.map((column, index) => (
                            <ColumnPreview
                                key={column.id}
                                column={column}
                                parent={component}
                                index={index}
                                classes={classes}
                            />
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

const RowEditMenu = ({
    componentLibrary, templateLibraryByType,
}) => {
    const columnTemplates = templateLibraryByType.Column || [];
    // eslint-disable-next-line no-unused-vars
    const columnMenu = columnTemplates.map((item) => ({ label: item.name, action: { type: 'addColumn', template: item.template } }));
    const all = buildComponentMenu(componentLibrary);
    const menu = [
        MenuDefaults.EDIT,
        MenuDefaults.DELETE,
        MenuDefaults.ADD_SIBLING(all),
        MenuDefaults.DUPLICATE,
    ];
    return menu;
};

/**
 * @function RowEditor [Open setting]
 * @param {object} config
 * @param {object} updateComponent
 * @returns {component}
 */
const RowEditor = ({ config, updateComponent }) => {
    const [component, setComponentState] = useRecoilState(componentWithId(config.id));
    const { settingMenuType } = useContext(TimelineContext);
    const filterRowProps = { display: FilterSettings(RowProps, settingMenuType), poi: RowProps.poi };
    return (
        <Settings editorProps={filterRowProps} component={component} setComponentState={setComponentState} saveComponent={updateComponent} />
    );
};

export {
    Row, RowEditMenu, RowPreview, RowEditor,
};
