import '../css/style.css';
import 'react-toastify/dist/ReactToastify.css';
import {
    Box, Button, Dialog, Grid, IconButton, InputAdornment, OutlinedInput, Typography,
} from '@material-ui/core';
import { DELETE_CONTENT_DISPLAY, GET_CONTENT_DISPLAY, UPDATE_CONTENT_DISPLAY } from '../Workshop';
import { MediaEditorContext, OrgContext } from '../context';
import { ToastContainer, toast } from 'react-toastify';
import {
    checkEmptyObject, createPlayer, getFileType, getIframe, isVideo,
} from '../helper/commonHelper';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useLazyQuery, useMutation } from 'react-apollo';
import CancelIcon from '@material-ui/icons/CloseRounded';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/DoneRounded';
import EditIcon from '@material-ui/icons/BorderColorRounded';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import React, { useContext, useEffect, useState } from 'react';
import httpHelper from '../helper/httpHelper';

/**
 * Set the Style for media details
 */
const useStyles = makeStyles((theme) => createStyles({
    mediaDetailsContainer: {
        width: '100%',
    },
    headerFont: {
        fontSize: '25px',
        fontWeight: 500,
        color: '#333',
        marginTop: 18,
    },
    imageContainer: {
        width: '100%',
    },
    actionContainer: {
        marginTop: theme.spacing(4),
        paddingRight: theme.spacing(2),
    },
    renameInpt: {
        padding: '12px 14px',
    },
    mediaDtlTitle: {
        fontWeight: 500,
    },
    errorMsg: {
        marginTop: 8,
        marginBottom: -10,
        color: '#fd0303',
        fontWeight: 600,
    },
    successMsg: {
        marginTop: 8,
        marginBottom: -10,
        color: '#228B22',
        fontWeight: 600,
    },
    leftSection: {
        padding: 8,
    },
    dialogContent: {
        width: '440px',
        padding: '2rem .5rem',
    },
    majorBtn: {
        width: '100%',
        backgroundColor: '#f2f2f2',
        color: '#EA4F4F',
    },
    marginSixteen: {
        marginTop: '16px',
    },
    zeroPadding: {
        padding: 0,
    },
    marginEight: {
        margin: '8px',
    },
    actionIcon: {
        padding: 8,
        borderRadius: 4,
        backgroundColor: '#F2F2F2',
    },
    SecondaryBtn: () => ({
        // backgroundColor: theme.palette.primary.light,
        boxShadow: 'none',
        borderRadius: 8,
        maxHeight: 48,
        padding: '18px 14px',
        '&:hover': {
            boxShadow: 'none',
            backgroundColor: theme.palette.primary.main,
        },
    }),
    imageStyle: {
        width: '20rem',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    iframeStyle: {
        width: '300px',
        margin: 'auto',
    },
}));

/**
 * This component is used to show the media details
 */
const MediaDetails = ({
    selectedMedia, onSelectMedia, imageUploadHandler = null,
    isMediaDelete = false, mediaDataDelete = null, mediaDeleteSuccess = null, mediaData = null,
}) => {
    const classes = useStyles();
    const { updateMediaName, deleteMedia } = useContext(MediaEditorContext);
    const { idToken } = useContext(OrgContext);
    const [mediaDetails, setMediaDetails] = useState({});
    const [mediaName, setMediaName] = useState('');
    const [renameAction, setRenameAction] = useState(true);
    const [open, setOpen] = useState(false);
    const [state, setState] = useState([]);

    const [getContentDisplay, {
        data: contentDisplayData,
    }] = useLazyQuery(GET_CONTENT_DISPLAY, {
        fetchPolicy: 'cache-and-network',
    });

    const [updateContentDisplay] = useMutation(UPDATE_CONTENT_DISPLAY);

    const [deleteContentDisplay] = useMutation(DELETE_CONTENT_DISPLAY);

    /**
     * @function onChangeName
     * @param evt [handle the updated media name]
     */
    const onChangeName = (evt) => {
        setMediaName(evt.target.value);
    };

    /**
     * @function showMessage [display the toaster message]
     * @param {string} message
     * @param {string} messageType
     * @returns {component}
     */
    const showMessage = (message, messageType) => {
        const options = {
            autoClose: true || 5000,
            closeButton: <CancelIcon fontSize="medium" />,
            hideProgressBar: false,
            pauseOnHover: true,
        };

        if (messageType === 'success') {
            toast.success(message, options);
        } else {
            toast.error(message, options);
        }
    };

    /**
     * @function onRenameMediaName [change the state value and switch button accordingly]
     */
    const onRenameMediaName = (action = '') => {
        setRenameAction(!renameAction);
        if (action === 'cancel') {
            const mediaNameVal = mediaDetails.name.split('.').shift();
            setMediaName(mediaNameVal);
        }
    };

    /**
     * @function onUpdateMediaName [change the state value to context method]
     */
    const onUpdateMediaName = () => {
        const mediaExtension = mediaDetails.name.split('.').pop();
        const response = updateMediaName(mediaDetails.id, `${mediaName}.${mediaExtension}`);
        if (response) {
            setRenameAction(true);
            showMessage('Media name updated successfully', 'success');
        } else {
            showMessage('Please try again', 'error');
        }
    };

    /**
     * Add Basic Info failure callback.
     * @param {Object} error [Error Data.]
     * @return {[type]} [description]
     */
    const requestFailure = (e) => {
        if (e.message === 'Request failed with status code 404') {
            deleteSuccess({ data: { media: true } });
        } else {
            showMessage(e.message, 'error');
        }
    };

    /**
     * Close the confirm dialog box
     */
    const handleClose = () => {
        setOpen(false);
        if (mediaDeleteSuccess && isMediaDelete) { mediaDeleteSuccess(); }
    };

    /**
     * When the file uploading is successful.
     * @return {Object} [HTTP Response]
     */
    const deleteSuccess = async ({ data }) => {
        if (data?.message === 'File deleted') {
            const index = state?.indexOf(mediaDetails?.url);
            if (index > -1) {
                state.splice(index, 1);
            }
            if (state?.length > 0) {
                updateContentDisplay({
                    variables: {
                        protocol_id: mediaData?.selectedComponent?.id,
                        image_list: [...state],
                    },
                });
            } else {
                deleteContentDisplay({
                    variables: {
                        protocol_id: mediaData?.selectedComponent?.id,
                    },
                });
            }
        }

        try {
            if (data.media) {
                const mediaId = mediaDetails.id;
                await deleteMedia(mediaId);
                showMessage('Media Deleted Successfully!', 'success');
                imageUploadHandler(true);
                onSelectMedia({});
                handleClose();
            } else {
                requestFailure(data);
            }
        } catch (error) {
            showMessage('Something went wrong', 'error');
        }
    };

    /**
     * @function onDeleteMedia [call the context method and pass selected media to delete]
     */
    const onDeleteMedia = () => {
        if (mediaDetails.is_youtube) {
            deleteSuccess({ data: { media: true } });
        } else {
            const deletedTimelineMediaKeys = [];
            const mediaUrl = mediaDetails.url;
            const mediaId = mediaUrl.split('/').pop();
            let apiEndPoint = `storage/upload/${mediaId}`;
            const fileType = mediaDetails.filetype.split('/')[0];
            if (fileType === 'video' && !mediaDetails.is_youtube) {
                apiEndPoint = `storage/upload/${mediaId}`;
            }
            deletedTimelineMediaKeys.push({
                Key: mediaId,
            });
            const payload = {
                Objects: [...deletedTimelineMediaKeys],
            };

            const httpObj = {
                url: apiEndPoint,
                method: 'DELETE',
                data: JSON.stringify(payload),
                // fileType,
                headers: { Authorization: `Bearer ${idToken}` },
            };

            httpHelper(
                httpObj,
                deleteSuccess,
                requestFailure,
                false
            );
        }
    };

    /**
     * Recieved ISO 8601 formatted date and format before render
     * @param {date} isoDate
     * @returns {string} formatted date
     */
    const getFormattedDate = (isoDate) => {
        const date = new Date(isoDate);
        return date.toString('dd-MMM-yyyy');
    };

    useEffect(() => {
        if (isMediaDelete && mediaDataDelete) {
            setMediaDetails(mediaDataDelete);
            setOpen(true);
        }
    }, [isMediaDelete]);

    useEffect(() => {
        if (!checkEmptyObject(selectedMedia)) {
            const mediaName = selectedMedia.name.split('.').shift();
            setMediaName(mediaName);
            setMediaDetails(selectedMedia);
        }
    }, [selectedMedia]);

    useEffect(() => {
        getContentDisplay({
            variables: {
                protocol_id: mediaData?.selectedComponent?.id,
            },
        });
    }, []);

    useEffect(() => {
        setState(contentDisplayData?.['content_display']?.[0]?.['image_list']);
    }, [contentDisplayData]);

    /**
     * create the dynamic id to create the player
     */
    if (Object.keys(mediaDetails).length > 0 && isVideo(mediaDetails.name)) {
        mediaDetails.filetype = getFileType(mediaDetails.name);
        mediaDetails.videoDivId = `video-div-gallery-details-${mediaDetails.id}`;
        mediaDetails.isVideo = true;
    }

    useEffect(() => {
        createPlayer([mediaDetails], false);
    });

    /**
     * Open the confirm dialog box
     */
    const onConfirmOpenDialog = () => {
        setOpen(true);
    };

    /**
     * @function getCancelButton [render the cancel icon]
     * @returns {string} icon
     */
    const getCancelButton = () => {
        let cancelButton = '';
        if (!renameAction) {
            cancelButton = (
                <Box>
                    <IconButton className={classes.actionIcon}>
                        <CancelIcon onClick={onRenameMediaName} fontSize="medium" />
                    </IconButton>
                </Box>
            );
        }
        return cancelButton;
    };

    const getSaveButton = () => {
        let saveButton = '';
        if (!renameAction) {
            saveButton = (
                <Box>
                    <IconButton className={classes.actionIcon}>
                        <DoneIcon onClick={onUpdateMediaName} fontSize="medium" />
                    </IconButton>
                </Box>
            );
        }
        return saveButton;
    };

    const getEditButton = () => {
        let editButton = '';
        if (renameAction) {
            editButton = <EditIcon onClick={onRenameMediaName} />;
        }
        return editButton;
    };

    return (
        <div>
            {!isMediaDelete && (
                <Grid container className={classes.mediaDetailsContainer}>
                    <Grid item md={12} lg={12}>
                        <Box my={3} color="secondary.main">
                            <Typography variant="h2">Media Details</Typography>
                        </Box>
                    </Grid>
                    <Grid item md={12} lg={12} className={classes.cntntContnr}>
                        <Grid container className={classes.imageContainer}>
                            <Grid item md={12} lg={12}>
                                <Box mb={1} pr={3}>
                                    {mediaDetails.isVideo ? <div className={classes.iframeStyle}>{getIframe(mediaDetails)}</div>
                                        : (
                                            <div className={classes.imageStyle}>
                                                <img src={mediaDetails.url} className="detailImageSize" alt={mediaDetails.name} />
                                            </div>
                                        )}
                                </Box>
                            </Grid>
                            <Grid item md={12} lg={12}>
                                <div>
                                    <ToastContainer />
                                    <Grid item md={12} lg={12}>
                                        <Box mt={2} mb={1} color="secondary.main">
                                            <Typography variant="h5">Media Name: </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid container>
                                        <Grid item md={12} lg={12}>
                                            <Box pr={2}>
                                                <OutlinedInput
                                                    ariant="outlined"
                                                    fullWidth
                                                    variant="outlined"
                                                    onChange={onChangeName}
                                                    value={mediaName}
                                                    disabled={renameAction}
                                                    autoComplete="off"
                                                    endAdornment={(
                                                        <InputAdornment>
                                                            <IconButton
                                                                id="outlined-size-small"
                                                                size="small"
                                                                onClick={onRenameMediaName}
                                                            >
                                                                {getEditButton()}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item md={12} lg={12}>
                                            <Box justifyContent="flex-end" display="flex">
                                                <Box pr={2} mt={1} order={2}>
                                                    {getSaveButton()}
                                                </Box>
                                                <Box mt={1} pr={2} order={1}>
                                                    {getCancelButton()}
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item md={1} lg={1} />
                                    </Grid>
                                    <Grid item md={12} lg={12} className={classes.marginSixteen}>
                                        <Typography className={classes.mediaDtlTitle} variant="subtitle1">Updated Date:</Typography>
                                    </Grid>
                                    <Grid item md={12} lg={12}>{getFormattedDate(selectedMedia.updated_at)}</Grid>
                                </div>
                                <div className={classes.actionContainer}>
                                    <Button
                                        variant="contained"
                                        className={`${classes.SecondaryBtn} ${classes.majorBtn}`}
                                        onClick={onConfirmOpenDialog}
                                        startIcon={<DeleteIcon />}
                                    >
                                        Delete Media
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            ) }
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <MuiDialogTitle disableTypography className={classes.root}>
                    <Typography color="error.main" variant="h3">Delete Media</Typography>
                </MuiDialogTitle>
                <MuiDialogContent className={classes.dialogContent} dividers>
                    <Typography variant="subtitle1">
                        Are you sure want to delete media ?
                    </Typography>
                </MuiDialogContent>
                <MuiDialogActions className={classes.zeroPadding}>
                    <Button variant="contained" className={classes.SecondaryBtn} color="default" onClick={handleClose}>No</Button>
                    <Button
                        className={`${classes.SecondaryBtn} ${classes.marginEight}`}
                        variant="contained"
                        color="primary"
                        onClick={onDeleteMedia}
                    >
                        Yes
                    </Button>
                </MuiDialogActions>
            </Dialog>
        </div>
    );
};

export default MediaDetails;
