import '../css/loader.scss';
import { ADD_COMPONENT_AC, GET_COMPONENT_AC, UPDATE_COMPONENT_AC } from '../context/Timeline';
import { Alert } from '@material-ui/lab';
import {
    Button, Checkbox, FormControlLabel,
} from '@material-ui/core';
import { ProcedureLibrary } from '../initialConfig';
import { ThemeProvider } from '@material-ui/styles';
import { TimelineContext } from '../context';
import {
    createMuiTheme,
    createStyles,
    makeStyles,
} from '@material-ui/core/styles';
import { flattenSelected } from './ProcedureSelector';
import { standardHeaders } from './utils/StandardStyles';
import { useMutation } from '@apollo/react-hooks';
import Grid from '@material-ui/core/Grid';
import Loader from 'react-loaders';
import React, { useContext, useEffect, useRef, useState } from 'react';
// eslint-disable-next-line no-unused-vars
import _ from 'lodash';

/**
 * @function useStyles
 * @description css for modal popup
 * @returns {object}
 */

const theme = createMuiTheme({
    typography: {
        body1: {
            fontWeight: 700,
            fontSize: 15,
            display: 'block',
            paddingLeft: '12px',
            color: '#056784',

        },
    },
    palette: {
        primary: {
            main: '#066785 !important',
            light: '#e8e8e8',
        },
    },
});

const useStyles = makeStyles((theme) => createStyles({
    selectedRow: {
        backgroundColor: 'cornflowerblue',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        height: '100%',
        width: '100%',
    },
    cancelBtnAllign: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    headerAllignment: {
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: '700',
        margin: '3.5rem 0rem 0rem 0rem',
        color: '#1C1C1C',
        padding: '6px 0px 4px 0px',
        border: '1px solid #cbcbcb',
    },
    headerDiv: {
        textAlign: 'center',
        backgroundColor: '#0113191A',
        textTransform: 'uppercase',
        marginTop:'5px',
        '& h1': {
            fontSize: '16px',
            marginBottom: '0px',
            color: '#011E27',
            padding: '4px 0px 4px 0px',
        },
    },
    normalFont: {
        fontWeight: '100',
    },
    cellHeight: {
        height: '30px',
        width: '100%',
        borderBottom: '1px solid gray',
    },
    cellHeightProg: {
        height: '32px',
        width: '100%',
        border: '1px solid #cbcbcb',
        lineHeight: '32px',
    },
    cellHeightPro: {
        height: '32px',
        backgroundColor: '#041179',
        lineHeight: '32px',
    },
    cellHeightAcc: {
        backgroundColor: '#efefef',
        padding: '8px 8px 6px 8px',
        width: '33%',
        marginRight: 4,
    },
    bottomBorder: {
        borderBottom: '1px solid gray',
    },
    textCenter: {
        textAlign: 'center',
        fontSize: '15px',
        margin: '0px',
    },
    textCenterPro: {
        textAlign: 'center',
        fontSize: '15px',
        margin: '0px',
        color: '#FFFFFF',
    },
    checkboxPadding: {
        paddingLeft: 10,
        width: '100%',
        marginBottom: 4,
    },
    headingText: {
        fontSize: 14,
        border: '1px solid #CBCBCB',
        padding: 4,
        textAlign: 'center',
        overflowX: 'auto',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
       '&::-webkit-scrollbar': {
                height: '2.5px',
                width: '2.5px',
                display: 'block',
            },
            '&::-webkit-scrollbar-thumb': {
                background: 'transparent',
            },
        '&:hover': {
            textOverflow: 'clip',
            '&::-webkit-scrollbar-thumb': {
                background: '#888',
            },
        },
    },
    labels: {
        '& span': {
            fontSize: '.75rem',
        },
        width: 'auto',
        minHeight: 32,
    },
    labels1: {
        '& span': {
            fontSize: '.75rem',
            color: '#000000de !important',
        },
        width: '100%',
    },
    labels2: {
        '& span': {
            fontSize: '.75rem',
            color: '#fff !important',
        },
        width: '100%',
    },
    labelCont: {
        paddingTop: 4,
    },
    labelCont1: {
        padding: 0,
        display: 'block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: 'auto',
        '&::-webkit-scrollbar': {
            display:
                'none',
        },
        '&:hover': {
            overflow: 'hidded',
            overflowX: 'scroll',
            textOverflow: 'clip',
            '&::-webkit-scrollbar': {
                height: '2.5px',
                width: '2.5px',
                display: 'block',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#888',
            },
        },
    },
    checkboxRoot: {
        whiteSpace: 'pre',
        marginRight: 0,
        '& span svg': {
            height: '17px',
            width: '17px',
        },
        '& span.Mui-disabled svg': {
            opacity:'25%'
        },
    },
    checkbox: {
        padding: 5,
        '& svg': {
           color:'#282828'
        },
        marginLeft: '.75rem',
    },
    checkbox1: {
        padding: 5,
        color: '#fff !important',
        marginLeft: '.75rem',
    },
    labelContLeft: {
        border: '1px solid #CBCBCB',
        padding: '8px',
        textAlign: 'start',
        fontSize: 12,
        height: 34,
        display: 'block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: 'auto',
        '&::-webkit-scrollbar': {
            display:
                'none',
        },
        '&:hover': {
            overflow: 'hidded',
            overflowX: 'scroll',
            textOverflow: 'clip',
            '&::-webkit-scrollbar': {
                height: '2.5px',
                width: '2.5px',
                display: 'block',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#888',
            },
        },
    },
    labelContLeft1: {
        border: '1px solid #CBCBCB',
        padding: '6px 8px',
        textAlign: 'start',
        fontSize: 12,
        height: 34,
        display: 'block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: 'auto',
        '&::-webkit-scrollbar': {
            display:
                'none',
        },
        '&:hover': {
            overflow: 'hidded',
            overflowX: 'scroll',
            textOverflow: 'clip',
            '&::-webkit-scrollbar': {
                height: '2.5px',
                width: '2.5px',
                display: 'block',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#888',
            },
        },
    },
    checkboxCont: {
        border: '1px solid #CBCBCB',
        padding: 0,
        display: 'flex',
        justifyContent: 'center',
    },
    actionButton: {
        bottom: '0px',
        width: '100%',
        position: 'fixed',
        backgroundColor: '#fff',
        padding: '10px 0px 12px 0px',
        zIndex: '999',
        borderTop: '1px solid #cbcbcb',
        display: 'flex',
        justifyContent: 'flex-end',
        '& > :last-child': {
            marginRight: 16,
        },
        '& button': {
            margin: '0px 12px',
            fontSize: 16,
            textTransform: 'capitalize',
            fontWeight: 700,
        },
    },
    alertStyle: {
        padding: '2px 16px',
        marginRight: '4rem',
    },
    errorWidth: {
        width: '100%',
    },
    contentScrollProgression: {
        width: '100%',
        maxHeight: '50vh',
        overflow: 'hidden',
        overflowY: 'auto',
        padding: '2px 0px',
    },
    contentScrollProtocal: {
        width: '100%',
        maxHeight: '53.5vh',
        overflow: 'hidden',
        overflowY: 'auto',
    },
    checkBoxClass: {
        padding: '0px',
        paddingLeft: '15px',
        '& svg': {
            fontSize: '1rem',
        },
    },
    userTypeCont: {
        position: 'sticky',
        top: 0,
        zIndex: 1,
        background: '#fff',
        '& > :first-child': {
            textAlign: 'left',
            paddingLeft: 8,
        },
    },
    marSpac1: {
        width: '100%',
        margin: '1px 0px',
    },
    checkboxLabel: {
        color: '#000000',
        marginTop: 2,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontSize: 13,
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        '&:hover': {
            overflowX: 'auto',
            textOverflow: 'clip',
            '&::-webkit-scrollbar': {
                height: '2.5px',
                width: '2.5px',
                display: 'block',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#888',
            },
        },
    },
    tableContainer: {
        "& .scrollbarVisible1": {
           overflowY:'scroll'
        },
        "& .scrollbarVisible2": {
           overflowY:'scroll'
        }
    }
}));

/**
 * @constructor AccessControl
 * @description Provide the access to render the component in timeline.
 * @param {handleClose}
 */
const AccessControl = ({
    handleClose, column, isSettings = false, dialougObj = {},
}) => {
    const classes = useStyles();
    const {
        updateComponent, selectedTimeline, selectedPOI, workshopAccessData, selectedColumn, updateFlag,
        setUpdateFlag, promiseAccessControlData, setPromiseAccessControlData,
        setWorkshopAccessData, workshopComponent, setWorkshopAccessView, inWorkshop
    } = useContext(TimelineContext);
    const [selectedTimelineCopy] = useState(JSON.parse(JSON.stringify(selectedTimeline)));
    const [accessControlData, setAaccessControlData] = useState(JSON.parse(JSON.stringify(workshopAccessData)));
    const [localCopyOfAccessData] = useState(JSON.parse(JSON.stringify(workshopAccessData)));
    const [promiseACData] = useState([...promiseAccessControlData]);
    const [progressionComponentList] = useState([]);
    const [noACSelected, setNoACSelected] = useState(false);
    const [disableSaveBtn, setDisableSaveBtn] = useState(false);
    const {
        userType = '', state = {}, setter = null,
    } = dialougObj;

    const [updateComponentAC, {
        loading: updateComponentACLoading,
        data: updateComponentACData,
    }] = useMutation(
        UPDATE_COMPONENT_AC,
    );
    const [insertComponentAC, {
        loading: insertComponentACLoading,
        data: insertComponentACData,
    }] = useMutation(
        ADD_COMPONENT_AC,
        {
            refetchQueries: [{
                query: GET_COMPONENT_AC,
                variables: {
                    timeline_id: selectedTimeline?.id,
                },
            }],
        }
    );

    const checkHandler = (cbStatus, component, user) => {
        const id = component?.id;
        if (component.props.group === 'Headers') {
            component.props.components.forEach((c) => {
                if (cbStatus) {
                    checkHandler(cbStatus, c, user);
                }
            });
            if (cbStatus) {
                setter((prev) => ({
                    ...prev,
                    [id]: user,
                }));
            } else {
                setter((prev) => ({
                    ...prev,
                    [id]: null,
                }));
            }
        } else if (cbStatus) {
            setter((prev) => ({
                ...prev,
                [id]: user,
            }));
        } else {
            setter((prev) => ({
                ...prev,
                [id]: null,
            }));
        }
    };

    const poiId = [];
    const columnIndex = selectedTimelineCopy?.columns?.findIndex((col) => col?.id === column?.id);
    const targetColumn = selectedTimelineCopy.columns[columnIndex] || selectedTimeline?.columns[selectedColumn];
    let nothingSelectedAC = false;
    // eslint-disable-next-line camelcase
    if (promiseACData.some((item) => item?.access_data?.isUserView === false)) {
        nothingSelectedAC = true;
    }

    const showComponent = true;

    if (selectedPOI) {
        const flatternPOI = flattenSelected(ProcedureLibrary, selectedPOI);
        flatternPOI.forEach((item) => {
            poiId.push(item.id);
        });
    }

    useEffect(() => {
        if (insertComponentACData !== undefined) {
            updateComponent('', true, false);
            handleClose(false);
        }
    }, [insertComponentACLoading, insertComponentACData]);

    useEffect(() => {
        if (updateComponentACData !== undefined) {
            updateComponent('', true, false);
            handleClose(false);
        }
    }, [updateComponentACLoading, updateComponentACData]);

    useEffect(() => {
        setNoACSelected(nothingSelectedAC);
        setDisableSaveBtn(nothingSelectedAC);
    }, [nothingSelectedAC]);

    useEffect(() => {
        setWorkshopAccessView(accessControlData);
        setWorkshopAccessData(accessControlData);
    }, [accessControlData]);


    const scrollableGridRef1 = useRef(null);
    const scrollableGridRef2 = useRef(null);

    useEffect(() => {
        const scrollableGrid1 = scrollableGridRef1.current;
        const scrollableGrid2 = scrollableGridRef2.current;
        const tableHeader = document.getElementById("tableHeader");
        const tableData1 = document.getElementById("tableData1");
        const tableData2 = document.getElementById("tableData2");

        function checkScrollbar(gridRef, currGrid) {
            const gridClass = currGrid === '1' ? 'scrollbarVisible1' : 'scrollbarVisible2'

            if (gridRef.scrollHeight > gridRef.clientHeight) {
              tableHeader.classList.add(gridClass);
              tableData1.classList.add(gridClass);
              tableData2.classList.add(gridClass);
          } else {
              tableHeader.classList.remove(gridClass);
              tableData1.classList.remove(gridClass);
              tableData2.classList.remove(gridClass);
          }
        }

        checkScrollbar(scrollableGrid1,'1');
        checkScrollbar(scrollableGrid2,'2');

        const resizeObserver1 = new ResizeObserver(()=>checkScrollbar(scrollableGrid1,'1'));
        const resizeObserver2 = new ResizeObserver(()=>checkScrollbar(scrollableGrid2,'2'));

        resizeObserver1.observe(scrollableGrid1);
        resizeObserver2.observe(scrollableGrid2);

        window.addEventListener('resize', () => {
            checkScrollbar(scrollableGrid1, '1');
            checkScrollbar(scrollableGrid2, '2');
        });

        return () => {
            resizeObserver1.disconnect();
            resizeObserver2.disconnect();

            window.removeEventListener('resize', () => {
                checkScrollbar(scrollableGrid1, '1');
                checkScrollbar(scrollableGrid2, '2');
            });
        };
    }, []);

    const getChildProgressionComponents = (targetComponent) => {
        if (!progressionComponentList.some((item) => item.id === targetComponent.id)) {
            progressionComponentList.push(targetComponent);
        }
        if (targetComponent?.props?.components?.length > 0) {
            for (let iterator = 0; iterator < targetComponent.props.components.length; iterator += 1) {
                if (targetComponent.props.components[iterator]?.isProgression
                    && targetComponent.props.components[iterator]?.commonId) {
                    getChildProgressionComponents(targetComponent.props.components[iterator]);
                }
            }
        }
    };

    if (selectedTimelineCopy?.columns?.length > 1) {
        for (let i = 0; i < selectedTimelineCopy?.columns?.length; i += 1) {
            const progressionComponents = selectedTimeline?.columns[i]?.props?.progressions;
            for (let j = 0; j < progressionComponents?.length; j += 1) {
                if (progressionComponents[j]?.props?.components?.length > 0) {
                    getChildProgressionComponents(progressionComponents[j]);
                } else if (!progressionComponentList.some((item) => item.id === progressionComponents[j].id)) {
                    progressionComponentList.push(progressionComponents[j]);
                }
            }
        }
    }

    /**
     * Recursive child component calling.
     */
    const passTheAccesscontrolToChildren = (event, targetComponent, accessView) => {
        if (!event.target.checked) {
            if (event.target.name === 'isUserView') {
                const accessView = { isUserView: false, isAdvanced: false, isBasic: false };
                accessControlData[targetComponent?.id] = accessView;
                setAaccessControlData(accessControlData);
            } else if (event.target.name === 'isAdvanced') {
                const isUser = accessControlData[targetComponent?.id]?.isUserView;
                const accessView = { isUserView: isUser || true, isAdvanced: false, isBasic: false };
                accessControlData[targetComponent?.id] = accessView;
                setAaccessControlData(accessControlData);
            } else if (event.target.name === 'isBasic') {
                const isUser = accessControlData[targetComponent?.id]?.isUserView;
                const isAdvance = accessControlData[targetComponent?.id]?.isAdvanced;
                const accessView = { isUserView: isUser || true, isAdvanced: isAdvance, isBasic: false };
                accessControlData[targetComponent?.id] = accessView;
                setAaccessControlData(accessControlData);
            }
            if (targetComponent.isProgression) {
                const commonProgessionComponent = progressionComponentList.filter((item) => item.commonId === targetComponent?.commonId);
                for (let i = 0; i < commonProgessionComponent?.length; i += 1) {
                    if (commonProgessionComponent[i].associatedParent === null || commonProgessionComponent[i].isProgression) {
                        const promiseData = {
                            access_data: accessView,
                            column_id: selectedTimeline?.columns[selectedColumn]?.id,
                            protocol_id: commonProgessionComponent[i]?.id,
                            timeline_id: selectedTimeline.id,
                        };
                        if (promiseACData.some((item) => item.protocol_id === promiseData.protocol_id)) {
                            const existingPromiseData = promiseACData?.find((item) => item.protocol_id === promiseData.protocol_id);
                            existingPromiseData.access_data = promiseData.access_data;
                            const index = promiseACData.findIndex((item) => item.protocol_id === existingPromiseData.protocol_id);
                            promiseACData[index] = existingPromiseData;
                        } else {
                            promiseACData.push(promiseData);
                        }
                    }
                }
            } else if (targetComponent.associatedParent === null || targetComponent.isProgression) {
                const promiseData = {
                    access_data: accessView,
                    column_id: selectedTimeline?.columns[selectedColumn]?.id,
                    protocol_id: targetComponent?.id,
                    timeline_id: selectedTimeline.id,
                };
                if (promiseACData.some((item) => item.protocol_id === promiseData.protocol_id)) {
                    const existingPromiseData = promiseACData?.find((item) => item.protocol_id === promiseData.protocol_id);
                    existingPromiseData.access_data = promiseData.access_data;
                    const index = promiseACData.findIndex((item) => item.protocol_id === existingPromiseData.protocol_id);
                    promiseACData[index] = existingPromiseData;
                } else {
                    promiseACData.push(promiseData);
                }
            }
        }

        if (targetComponent.props && targetComponent.props.components && targetComponent.props.components.length > 0) {
            for (let iterator = 0; iterator < targetComponent.props.components.length; iterator += 1) {
                if (targetComponent.props.components[iterator].associatedParent === null
                    || targetComponent.props.components[iterator]?.isProgression) {
                    passTheAccesscontrolToChildren(event, targetComponent.props.components[iterator], accessView);
                }
            }
        }
    };

    /**
     * CB on change event
     * @param {Object} event
     * @param {Object} component
     * @param {Bool} isProgression
     */

    const onHandleChange = (event, component) => {
        const newAccesView = accessControlData[component.id]
            ? { ...accessControlData[component.id] } : { isUserView: true, isAdvanced: false, isBasic: false };
        const updatedAccessControl = { ...accessControlData };
        if (!event.target.checked) {
            if (event.target.name === 'isUserView') {
                newAccesView.isAdvanced = event.target.checked;
                newAccesView.isBasic = event.target.checked;
            } else if (event.target.name === 'isAdvanced') {
                newAccesView.isBasic = event.target.checked;
            }
        }
        newAccesView[event.target.name] = event.target.checked;
        updatedAccessControl[component.id] = newAccesView;
        setAaccessControlData(updatedAccessControl);
        passTheAccesscontrolToChildren(event, component, newAccesView);
        const commonProgessionComponent = progressionComponentList.filter((item) => item.commonId === component?.commonId);

        if (component?.isProgression) {
            for (let i = 0; i < commonProgessionComponent.length; i += 1) {
                const promiseData = {
                    access_data: newAccesView,
                    column_id: selectedTimeline?.columns[selectedColumn]?.id,
                    protocol_id: commonProgessionComponent[i]?.id,
                    timeline_id: selectedTimeline.id,
                };

                if (promiseACData.some((item) => item.protocol_id === promiseData.protocol_id)) {
                    const existingPromiseData = promiseACData?.find((item) => item.protocol_id === promiseData.protocol_id);
                    existingPromiseData.access_data = promiseData.access_data;
                    const index = promiseACData.findIndex((item) => item.protocol_id === existingPromiseData.protocol_id);
                    promiseACData[index] = existingPromiseData;
                } else {
                    promiseACData.push(promiseData);
                }
            }
        } else {
            const promiseData = {
                access_data: newAccesView,
                column_id: selectedTimeline?.columns[selectedColumn]?.id,
                protocol_id: component?.id,
                timeline_id: selectedTimeline.id,
            };
            if (promiseACData.some((item) => item.protocol_id === promiseData.protocol_id)) {
                const existingPromiseData = promiseACData?.find((item) => item.protocol_id === promiseData.protocol_id);
                existingPromiseData.access_data = promiseData.access_data;
                const index = promiseACData.findIndex((item) => item.protocol_id === existingPromiseData.protocol_id);
                promiseACData[index] = existingPromiseData;
            } else {
                promiseACData.push(promiseData);
            }
        }
        if (isSettings) {
            setPromiseAccessControlData(promiseACData);
            setUpdateFlag(!updateFlag);
        }
    };

    /**
   * @function submitAccessControl [update component with the selected value]
   */
    const submitAccessControl = () => {
        if (nothingSelectedAC) {
            setNoACSelected(true);
            setDisableSaveBtn(true);
        } else {
            const allPromises = [];

            promiseACData.forEach((i) => {
                if (localCopyOfAccessData[i.protocol_id]) {
                    const acUpdatePromises = updateComponentAC({
                        variables: {
                            protocol_id: i.protocol_id,
                            timeline_id: i.timeline_id,
                            access_data: i.access_data,
                        },
                    });
                    allPromises.push(acUpdatePromises);
                } else {
                    const acInsertPromise = insertComponentAC({
                        variables: {
                            objects: i,
                        },
                    });
                    allPromises.push(acInsertPromise);
                }
            });

            Promise.all(allPromises).then(() => {
                // setWorkshopLoading(false);
            }).catch((err) => {
                // eslint-disable-next-line no-console
                console.error('Inserting Access Control Failed', err);
            });
        }
    };

    /**
     * Design the row of the Access Control.
     * @param {Object} current component.
     */
    const getComponentDesign = (component) => {
        const accessViewValue = accessControlData[component.id]
            ? accessControlData[component.id] : { isUserView: true, isAdvanced: false, isBasic: false };

        const istH1Header = (component.type === 'Text' && component.props.type === 'h1');
        const parentComp = istH1Header ? component?.props?.childOf : (component.parent || component.props.childOf);
        const isAdvancedViewDisabled = (parentComp && !accessControlData[parentComp]?.isAdvanced) || !accessViewValue?.isUserView;
        const isBasicViewDisabled = (parentComp && !accessControlData[parentComp]?.isBasic) || !accessViewValue?.isAdvanced;

        let groupDesign = {};
        if (component.props.group === 'Headers') {
            groupDesign = {
                background: standardHeaders[component.props.type].background,
            };
        }

        let disableCB = false;
        let borderClr = '';
        let isStaffChecked = false;
        let isPatientChecked = false;
        if (state?.[component?.id] === 'Staff') {
            isStaffChecked = true;
        } else if (state?.[component?.id] === 'Patient') {
            isPatientChecked = true;
        }
        const childOfStaffHead = state[component?.props?.childOf] === 'Staff';
        const childOfPatientHead = state[component?.props?.childOf] === 'Patient';
        const isInnerHeader = (component?.props?.type === 'h2' || component?.props?.type === 'h3' || component?.props?.type === 'h4');

        switch (userType) {
        case 'Admin':
            borderClr = isStaffChecked || isPatientChecked ? '#A5DD9B' : borderClr;
            break;
        case 'Staff':
            disableCB = childOfStaffHead && (isInnerHeader || component?.props?.hadGroupHeader);
            borderClr = (isStaffChecked && 'black') || (isPatientChecked && '#A5DD9B') || '';
            break;
        case 'Patient':
            disableCB = isStaffChecked || (childOfPatientHead && (isInnerHeader || component?.props?.hadGroupHeader));
            borderClr = (isStaffChecked || isPatientChecked) ? 'black' : '';
            break;
        default:
            break;
        }

        if (borderClr) {
            groupDesign.background = borderClr && userType && userType !== 'Admin' ? '#000'
                : borderClr && userType && userType === 'Admin' && '#ffface';
        }

        return (
            <Grid container className={classes.marSpac1} style={groupDesign}>
                <Grid
                    item
                    xs={5}
                    className={`${(userType && userType !== 'Admin')
                        ? classes.labelContLeft1
                        : classes.labelContLeft} ${(workshopComponent?.id === component?.id) && !borderClr ? classes.selectedRow : ''}`}
                >
                    {isSettings && userType && userType !== 'Admin'
                        ? (
                            <FormControlLabel
                                className={borderClr ? classes.labels2 : classes.labels1}
                                classes={{ label: classes.labelCont1, root: classes.checkboxRoot }}
                                control={(
                                    <Checkbox
                                        classes={{ root: borderClr ? classes.checkbox1 : classes.checkbox }}
                                        onChange={(e) => checkHandler(e.target.checked, component, userType)}
                                        checked={state[component.id] === userType || isStaffChecked}
                                        disabled={disableCB}
                                    />
                                )}
                                label={workshopComponent?.id !== component?.id
                                    ? (component?.props?.title || component?.props?.text)
                                    : (workshopComponent?.props?.title || workshopComponent?.props?.text)}
                            />
                        )
                        : workshopComponent?.id !== component?.id
                            ? (component?.props?.title || component?.props?.text)
                            : (workshopComponent?.props?.title || workshopComponent?.props?.text)}
                </Grid>
                <Grid item xs={3} className={classes.checkboxCont}>
                    <FormControlLabel
                        className={classes.labels}
                        classes={{ label: classes.labelCont, root: classes.checkboxRoot }}
                        control={(
                            <Checkbox
                                color="default"
                                disabled={accessViewValue?.isUserView}
                                checked={accessViewValue?.isUserView}
                                name="isUserView"
                                classes={{ root: borderClr && userType && userType !== 'Admin' ? classes.checkbox1 : classes.checkbox }}
                                onChange={(e) => onHandleChange(e, component)}
                            />
                        )}
                        label=""
                    />
                </Grid>
                <Grid item xs={2} className={classes.checkboxCont}>
                    <FormControlLabel
                        className={classes.labels}
                        classes={{ label: classes.labelCont, root: classes.checkboxRoot }}
                        control={(
                            <Checkbox
                                color="default"
                                disabled={isAdvancedViewDisabled}
                                checked={accessViewValue?.isAdvanced}
                                name="isAdvanced"
                                classes={{ root: borderClr && userType && userType !== 'Admin' ? classes.checkbox1 : classes.checkbox }}
                                onChange={(e) => onHandleChange(e, component)}
                            />
                        )}
                        label=""
                    />
                </Grid>
                <Grid item xs={2} className={classes.checkboxCont}>
                    <FormControlLabel
                        className={classes.labels}
                        classes={{ label: classes.labelCont, root: classes.checkboxRoot }}
                        control={(
                            <Checkbox
                                color="default"
                                disabled={isBasicViewDisabled}
                                checked={accessViewValue?.isBasic}
                                name="isBasic"
                                classes={{ root: borderClr && userType && userType !== 'Admin' ? classes.checkbox1 : classes.checkbox }}
                                onChange={(e) => onHandleChange(e, component)}
                            />
                        )}
                        label=""
                    />
                </Grid>
            </Grid>
        );
    };

    /**
     * Recursive component calling.
     */
    const getTheAccessViewOfTheTree = (targetComponent, returnOutput, isProgression) => {
        if (targetComponent.type === 'Protocol' || targetComponent.type === 'ProtocolGroup' || targetComponent.type === 'Text') {
            returnOutput.push(getComponentDesign(targetComponent, isProgression));
            if (targetComponent.props && targetComponent.props.components && targetComponent.props.components.length > 0) {
                for (let iterator = 0; iterator < targetComponent.props.components.length; iterator += 1) {
                    getTheAccessViewOfTheTree(targetComponent.props.components[iterator], returnOutput, isProgression);
                }
            }
        }
    };

    const checkPOI = (component) => {
        let isShowComponent = true;
        const filterPOI = [];

        if (component.props && component.props.poi && poiId && poiId.length > 0) {
            Object.keys(component.props.poi).forEach((poiItem) => {
                poiId.forEach((data) => { // TODO see comments above about not having a loop here.
                    if (data === poiItem) {
                        filterPOI.push(poiItem);
                    }
                });
            });

            isShowComponent = (filterPOI.length > 0);
        }

        return isShowComponent;
    };

    const createAccessGrid = (componentsArray, isProgression) => {
        const result = [];
        componentsArray.forEach((component) => {
            if (checkPOI(component)) {
                getTheAccessViewOfTheTree(component, result, isProgression);
            }
        });
        return result;
    };

    /**
   * @function renderActionButton [Show and hide the button on the basis of comming data]
   */
    const renderActionButton = () => {
        if (targetColumn?.props?.progressions.length === 0 && (targetColumn?.props?.components?.length === 0 || !showComponent)) {
            return (
                <Grid item md={12} lg={12} className={classes.actionButton}>
                    <Button
                        variant="contained"
                        onClick={() => handleClose(false)}
                    >
                        Cancel
                    </Button>
                </Grid>
            );
        }

        return (
            <Grid item md={12} lg={12} className={classes.actionButton}>
                {noACSelected && disableSaveBtn && (
                    <Alert className={classes.alertStyle} severity="error">
                        Please select at least one User Access .
                    </Alert>
                )}
                <Button
                    variant="contained"
                    onClick={() => handleClose(false)}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={submitAccessControl}
                >
                    Save
                </Button>
            </Grid>
        );
    };

    /**
   * @function showMessage [Show and hide the message on the basis of comming data]
   */
    const showMessage = () => {
        if (targetColumn?.props?.progressions?.length === 0 && (targetColumn?.props?.components?.length === 0 || !showComponent)) {
            return (
                <div className={classes.errorWidth}>
                    <h3 className={classes.textCenter}>
                        No components are added to this column.
                    </h3>
                </div>
            );
        }

        return '';
    };

    return (
        <ThemeProvider theme={theme}>
            {(updateComponentACLoading || insertComponentACLoading) && (
                <div id="overlay">
                    <div id="loader">
                        <Loader type="line-scale" />
                    </div>
                </div>
            )}
            <div className={classes.paper} style={{ padding: isSettings ? 0 : '14px 0px 20px 4px' }}>
                <Grid container justifyContent='center'>
                    {!isSettings && (
                        <Grid item md={12} lg={12} xs={12} className={classes.headerAllignment}>
                            Access Control
                        </Grid>
                    )}
                    <Grid item md={inWorkshop ? 12 : 6} lg={inWorkshop ? 12 : 5} xl={inWorkshop ? 12 : 4} >
                        {!isSettings
                            && (
                                <div className={classes.headerDiv}>
                                    <h1>
                                        {column.props.title}
                                    </h1>
                                </div>
                            )}
                        <div className={classes.tableContainer}>
                            <Grid container className={classes.userTypeCont} style={{ marginBottom: !isSettings ? 4 : 0 }} id={'tableHeader'}>
                                <Grid item xs={5} className={classes.headingText}>Protocol</Grid>
                                <Grid item xs={3} className={classes.headingText}>{(userType) ? 'Comp...' : 'Comprehensive'}</Grid>
                                <Grid item xs={2} className={classes.headingText}>Advance</Grid>
                                <Grid item xs={2} className={classes.headingText}>Basic</Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12}>
                                    <div className={classes.cellHeightPro}>
                                        <h2 className={classes.textCenterPro}>Progression Section</h2>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.contentScrollProgression}
                                    ref={scrollableGridRef1}
                                    id={'tableData1'}
                                >
                                    {createAccessGrid(targetColumn?.props?.progressions, true)}
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={classes.cellHeightPro}>
                                        <h2 className={classes.textCenterPro}>Protocol Section</h2>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    style={{ paddingBottom: (targetColumn.props.progressions.length === 0) || isSettings ? 0 : '3rem' }}
                                    className={classes.contentScrollProtocal}
                                    ref={scrollableGridRef2}
                                    id={'tableData2'}
                                >
                                    {createAccessGrid(targetColumn.props.components, false)}
                                </Grid>
                                {showMessage()}
                            </Grid>
                        </div>
                    </Grid>
                    {!isSettings && renderActionButton()}
                </Grid>
            </div>
        </ThemeProvider>
    );
};

export default AccessControl;
