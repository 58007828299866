/* eslint-disable react/jsx-no-bind */
import 'react-toastify/dist/ReactToastify.css';
import {
    Box, Button, Dialog, Drawer, Grid,
    IconButton, Input, InputAdornment,
    Paper, Table, TableBody,
    TableCell, TableContainer, TableHead,
    TablePagination, TableRow, TableSortLabel, Typography,
} from '@material-ui/core';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

import { OrgContext, UserContext } from './context';
import { ToastContainer, toast } from 'react-toastify';
import { getFormattedDate } from './helper/commonHelper';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/CloseRounded';
import FilterList from '@material-ui/icons/FilterList';
import GridAction from './components/GridAction';
import ManagePatientModal from './components/ManagePatientModel';
import OrgBreadcrumbs from './components/utils/OrgBreadcrumbs';
import React, { Fragment, useContext, useState } from 'react';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    parentContainer: {
        width: '540px',
        padding: '20px',
    },
    formControl: {
        minWidth: '300px',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    buttonGroup: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    tlListDiv: {
        maxHeight: 'calc(100vh - 180px)',
        width: '100%',
        overflow: 'auto',
        scrollbarWidth: 'none', /* Firefox */
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    tlListItm: {
        backgroundColor: '#fff',
        padding: '14px 24px',
    },
    tlName: {
        fontSize: '20px',
        textTransform: 'capitalize',
    },
    primaryBtn: {
        maxHeight: '56px',
        padding: '16px 18px',
        boxShadow: '0px 0px 20px -4px rgba(51,36,5,0.37)',
        '&:hover': {
            boxShadow: 'none',
        },
    },
    cancelBtn: {
        maxHeight: '56px',
        padding: '16px 18px',
        boxShadow: 'none',
        '&:hover': {
            boxShadow: 'none',
        },
    },
    inputValue: {
        fontSize: '16px',
        fontWeight: '300px',
    },
    addIconContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
    },
    list: {
        width: 300,
    },
    sideDrawerPaper: {
        width: '36%',
    },
    tableContainer: {
        maxHeight: '70vh',
    },
    tableHeadCell: {
        verticalAlign: 'initial',
    },
    searchBoxStyle: {
        marginTop: '1rem',
    },
    textRight: {
        textAlign: 'right',
    },
}));

let typingTimeout = null;
/**
 * Define the column for patient table
 */
const columns = [
    {
        id: 'name', label: 'Name', minWidth: 100, type: 'desc', isShow: true, isFilter: true,
    },
    {
        id: 'email', label: 'Email', minWidth: 100, type: 'desc', isShow: true, isFilter: true,
    },
    {
        id: 'dob', label: 'DOB', minWidth: 100, isShow: true, isFilter: false,
    },
    {
        id: 'action', label: 'Action', minWidth: 100, isShow: false, isFilter: false,
    },
];

const PatientList = ({ showLoader, doctorURL = null }) => {
    const classes = useStyles();
    const {
        usersLoading, users, deleteUserCotext, totalUser, fetchUsers,
    } = useContext(UserContext);
    const { selectedOrg } = useContext(OrgContext);
    const [selectedPatient, setPatient] = useState({});
    const [isModalOpen, setModalStatus] = useState(false);
    const [open, setOpen] = useState(false);
    const [deletedPatient, setDeletedPatient] = useState({});
    let patientList = [];
    const orgID = selectedOrg.id;
    const anchor = 'right';
    const [state, setState] = useState({ right: false });

    const defaultPageSize = 20;
    const [page, setPage] = useState(0);
    const [maxCurrentPage, setMaxCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(defaultPageSize);
    const [orderBy, setOrderBy] = useState({});
    const filteringObj = { name: '', email: '', role: '' };
    const routeLabel = doctorURL ? 'Doctor' : 'Patient';

    /**
     * To open and close the drawer
     */
    const toggleDrawer = (anchor, open, patient = {}) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setPatient(patient);
        setModalStatus(true);
        setState({ ...state, [anchor]: false });
    };

    const list = (anchorEle) => (
        <div
            className={clsx(classes.list)}
            role="presentation"
            onClick={toggleDrawer(anchorEle, false)}
            onKeyDown={toggleDrawer(anchorEle, false)}
        />
    );

    /**
     * Open the confirm dialog box
     */
    const onConfirmOpenDialog = (patientDetails) => {
        const delObj = {
            userId: patientDetails.id,
            status: false,
        };
        setDeletedPatient(delObj);
        setOpen(true);
    };

    /**
     * Handle Patient grid action Edit and Delete component
     */
    const ActionComponent = (props) => {
        const { patient } = props;
        return (
            <GridAction
                {...props}
                deleteHandler={onConfirmOpenDialog}
                openDrawer={toggleDrawer}
                selectedPatient={patient}
            />
        );
    };

    if (!usersLoading) {
        patientList = users;
    } else {
        return showLoader(true);
    }

    /**
     * Handle Modal popup isModalOpen
     * @param {boolean} status [if modal  open status is `true` otherwise `false`]
     */
    const handleModalStatus = (status) => {
        setModalStatus(status);
    };

    /**
     * Close the confirm dialog box
     */
    const handleClose = () => {
        setOpen(false);
        setDeletedPatient({});
        setPatient({});
    };

    /**
     * @function showMessage [display the toaster message]
     * @param {string} message
     * @param {string} messageType
     * @returns {component}
     */
    const showMessage = (message, messageType) => {
        const options = {
            autoClose: true || 5000,
            closeButton: <CancelIcon fontSize="medium" />,
            hideProgressBar: false,
            pauseOnHover: true,
        };
        if (messageType) {
            toast.success(message, options);
        } else {
            toast.error(message, options);
        }
    };

    /**
     * @function onDeletePatient [delete the selected patient]
     */
    const onDeletePatient = () => {
        const response = deleteUserCotext(deletedPatient);

        if (response) {
            showMessage(`${routeLabel} deleted successfully`, true);
        } else {
            showMessage('Please try again', false);
        }
        handleClose();
    };

    /**
     *
     * @param {interger} rowsPerPage
     * @param {interger} page
     * @param {interger} maxCurrentPage
     */
    const pageChangeHandler = (orderByEle, rowsPerPageEle, pageEle, maxCurrentPageEle) => {
        const maxPage = Math.ceil(totalUser / rowsPerPage);

        if (pageEle > maxCurrentPageEle && maxCurrentPageEle < maxPage) {
            fetchUsers({
                orderBy: orderByEle, limit: rowsPerPageEle, offset: pageEle * rowsPerPageEle, filteringObj,
            });
        }
    };

    /**
     * Handle page change request
     * @param {event} event
     * @param {integer} newPage
     */
    const handleChangePage = (event, newPage) => {
        if (newPage > maxCurrentPage) {
            setMaxCurrentPage(newPage);
        }
        setPage(newPage);
        pageChangeHandler(orderBy, rowsPerPage, newPage, maxCurrentPage);
    };

    /**
     * Fetch Data when per page rows request change
     * @param {object} orderBy
     * @param {integer} rowsPerPage
     * @param {integer} page
     */
    const handleChangeRowsPerPageParent = (orderBy, rowsPerPage, page) => {
        fetchUsers({
            orderBy,
            limit: rowsPerPage,
            offset: page * rowsPerPage,
            clearData: true,
            filteringObj,
        });
    };

    /**
     * Handle the number of rows on a page.
     * @param {event} event
     */
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
        setMaxCurrentPage(0);
        handleChangeRowsPerPageParent(orderBy, event.target.value, 0);
    };

    /**
     * Clear patient list data
     */
    const initialUserData = () => {
        const event = {
            target: { value: defaultPageSize },
        };
        setTimeout(() => {
            handleChangeRowsPerPage(event);
        }, 1000);
    };

    const sortingHandlerParent = (orderBy, rowsPerPage, page, filteringObjData) => {
        fetchUsers({
            orderBy, limit: rowsPerPage, offset: page * rowsPerPage, clearData: true, filteringObj: filteringObjData,
        });
    };

    /**
     * Function which gets called after patient completes entering the field.
     * @param {interger} rowsPerPage
     * @param {interger} page
     * @param {interger} maxCurrentPage
     */
    const callSearchOnFilter = () => {
        setPage(0);
        setMaxCurrentPage(0);
        setOrderBy(orderBy);
        sortingHandlerParent(orderBy, rowsPerPage, 0, filteringObj);
    };

    /**
     * Function which gets onChange of the textField.
     * @param {interger} rowsPerPage
     * @param {interger} page
     * @param {interger} maxCurrentPage
     */
    const filterChangeHandler = (selectedColumn, { currentTarget }) => {
        clearTimeout(typingTimeout);

        if (selectedColumn.id === 'name') {
            filteringObj.name = currentTarget.value;
        } else if (selectedColumn.id === 'email') {
            filteringObj.email = currentTarget.value;
        } else if (selectedColumn.id === 'role') {
            filteringObj.role = currentTarget.value;
        }

        typingTimeout = setTimeout(callSearchOnFilter, 475);
    };

    /**
     * Handle the grid sorting
     * @param {object} column
     */
    const sortingHandler = (column) => {
        const obj = { name: column.id };
        const filterObjIndex = columns.findIndex((data) => data.id === column.id);
        const filterObj = columns[filterObjIndex];
        obj.type = filterObj.type === 'asc' ? 'desc' : 'asc';
        columns[filterObjIndex].type = obj.type;
        setPage(0);
        setMaxCurrentPage(0);
        setOrderBy(obj);
        sortingHandlerParent(obj, rowsPerPage, 0, filteringObj);
    };

    const handleAddPatient = () => {
        setPatient({});
        handleModalStatus(true);
    };

    return (
        <Fragment key="key">
            <div>
                <ToastContainer />
                <Box mx={4} mb={4}>
                    <Grid container alignItems="center">
                        <Grid item md={8} lg={8}>
                            <Typography variant="h2">
                                <Box mt={5} color="secondary.main">
                                    {routeLabel === 'Doctor' ? 'Organisation' : routeLabel}
                                    {' '}
                                    Management
                                </Box>
                            </Typography>
                            <Box mt={1}>
                                <OrgBreadcrumbs current={`${routeLabel === 'Doctor' ? 'Organisation' : routeLabel} Management`} />
                            </Box>
                        </Grid>
                        <Grid item md={4} lg={4} className={classes.textRight}>
                            <Box mt={5} clone>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    className={classes.primaryBtn}
                                    // onClick={toggleDrawer(anchor, true)}
                                    onClick={() => handleAddPatient()}
                                >
                                    Add New
                                    {' '}
                                    {routeLabel}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box mx={4} mt={2}>
                    <Paper className={classes.root}>
                        <TableContainer className={classes.tableContainer}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}
                                                className={classes.tableHeadCell}
                                            >
                                                <div>
                                                    {column.label}
                                                    {column.isShow ? (
                                                        <TableSortLabel
                                                            onClick={() => sortingHandler(column)}
                                                            active={false}
                                                        />
                                                    ) : null}
                                                </div>
                                                <div>
                                                    {column.isFilter
                                                        && (
                                                            <Input
                                                                id="standard-adornment-password"
                                                                type="text"
                                                                className={classes.searchBoxStyle}
                                                                onChange={filterChangeHandler.bind(this, column)}
                                                                endAdornment={(
                                                                    <InputAdornment position="start">
                                                                        <IconButton
                                                                            aria-label="filter list"
                                                                        >
                                                                            <FilterList />
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                )}
                                                            />
                                                        )}
                                                </div>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {patientList
                                        ?.slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        .map((row) => (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={row.id}
                                            >
                                                <TableCell key={`${row.id}1`} className={classes.dataColumn}>
                                                    {`${row.name} ${row.last_name ? row.last_name : ''}`}
                                                </TableCell>
                                                <TableCell key={`${row.id}2`} className={classes.dataColumn}>
                                                    {row.email}
                                                </TableCell>
                                                <TableCell key={`${row.id}4`} className={classes.dataColumn}>
                                                    {getFormattedDate(row.dob)}
                                                </TableCell>
                                                <TableCell key={`${row.id}5`} className={classes.actionColumn}>
                                                    <ActionComponent patient={row} />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[20, 50]}
                            component="div"
                            count={totalUser}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            sortingHandlerParent={sortingHandlerParent}
                        />
                    </Paper>
                </Box>
                <Drawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    classes={{ paper: classes.sideDrawerPaper }}
                >
                    {list(anchor)}
                </Drawer>
                {isModalOpen && (
                    <ManagePatientModal
                        open={isModalOpen}
                        selectedPatient={selectedPatient}
                        initialPatientData={initialUserData}
                        toggleDrawer={toggleDrawer(anchor, false)}
                        handleClose={() => handleModalStatus(false)}
                        orgID={orgID}
                        routeLabel={routeLabel}
                    />
                )}
                <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                    <MuiDialogTitle disableTypography className={classes.root}>
                        <Typography variant="h6">Delete Patient</Typography>
                    </MuiDialogTitle>
                    <MuiDialogContent className={classes.dialogContent} dividers>
                        <Typography gutterBottom>
                            Are you sure want to delete
                            {' '}
                            {routeLabel}
                            {' '}
                            ?
                        </Typography>
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <Button
                            variant="contained"
                            color="default"
                            onClick={handleClose}
                        >
                            No
                        </Button>
                        <Button className={classes.buttonMargin} variant="contained" color="primary" onClick={onDeletePatient}>Yes</Button>
                    </MuiDialogActions>
                </Dialog>
            </div>
        </Fragment>
    );
};

export default PatientList;
