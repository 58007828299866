/* eslint-disable no-case-declarations */
import { useMutation, useQuery } from '@apollo/react-hooks';
import React, { createContext, useState } from 'react';
import gql from 'graphql-tag';

const GET_USER_ACCESS = gql`
query accessData($timelineId: uuid) {
    user_access(where: { timeline_id: { _eq: $timelineId }}) {
        id
        timeline_id
        column_index
        protocol_id
        is_doctor_content
        access_data
        created_at
        updated_at
    },
  }
`;

const INSERT_USER_ACCESS = gql`
mutation insertUserAccess($timelineId: uuid, $columnIndex: smallint, $protocolId: uuid, $isDoctorContent: Boolean, $accessData: jsonb) {
    insert_user_access(objects: { timeline_id: $timelineId, column_index: $columnIndex, protocol_id: $protocolId, is_doctor_content: $isDoctorContent
        access_data: $accessData } ) {
        affected_rows
        returning {
            id
        }
    }
} 
`;

const UPDATE_USER_ACCESS = gql`
    mutation updateUserAccess($id: uuid, $isDoctorContent: Boolean, $accessData: jsonb) {
        update_user_access(where: {id: {_eq: $id}}, _set: { is_doctor_content: $isDoctorContent, access_data: $accessData } ) {
            affected_rows
            returning {
                id
            }
        }
    }
    `;

export const Context = createContext({});

export const Provider = ({ children }) => {
    const [selectedOrg, setSelectedOrg] = useState({ org: 1 });
    const [selectedUserType, setSelectedUserType] = useState({ userType: 1 });
    const timelineId = window.location.href.split('/')[5];
    const userAccessResponse = useQuery(GET_USER_ACCESS, {
        variables: {
            timelineId,
        },
    });

    const userAccessLoading = userAccessResponse.loading;
    const userAccessError = userAccessResponse.error;

    const userAccess = !userAccessResponse.loading ? userAccessResponse?.data?.['user_access'] : undefined;
    const [insertUserAccess] = useMutation(INSERT_USER_ACCESS);
    const [updateUserAccess] = useMutation(UPDATE_USER_ACCESS);
    const refetchUserAccess = () => userAccessResponse.refetch();

    /**
     * @function updateMediaName
     * @param {string} id [primary key]
     * @param {string} fileName [updated file name]
     * @returns get updated response
     */
    const insertUserAccessData = (accessData) => {
        const result = insertUserAccess({
            variables: {
                timelineId: accessData.timelineId,
                columnIndex: accessData.columnIndex,
                protocolId: accessData.protocolId,
                isDoctorContent: accessData.isDoctorContent,
                accessData: accessData.accessData,
            },
        });
        return result.then(() => {
            refetchUserAccess();
        });
    };

    const updateUserAccessData = (accessData) => {
        const result = updateUserAccess({
            variables: {
                id: accessData.id,
                isDoctorContent: accessData.isDoctorContent,
                accessData: accessData.userAccess,
            },
        });
        return result.then(() => {
            // refetchUserAccess();
        });
    };

    const userAccessContext = {
        insertUserAccessData,
        userAccessLoading,
        userAccessError,
        userAccess,
        selectedOrg,
        setSelectedOrg,
        selectedUserType,
        setSelectedUserType,
        updateUserAccessData,
    };

    // pass the value in provider and return
    return <Context.Provider value={userAccessContext}>{children}</Context.Provider>;
};

export const { Consumer } = Context;
