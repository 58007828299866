import { Alert } from '@material-ui/lab';
import {
    Checkbox, FormControlLabel,
} from '@material-ui/core';
import { Container } from './Container';
import { FilterSettings, buildSettingsTreePage, setActiveComponent } from '../helper/commonHelper';
import { Settings, makeInlineEditor } from './Settings';
import { TimelineContext, WorkshopContext } from '../context';
import { componentWithId } from './Atoms';
import {
    createStyles, makeStyles,
} from '@material-ui/core/styles';
import { useRecoilState } from 'recoil';
import Grid from '@material-ui/core/Grid';
import PageViewDialog from './PageView';
import React, { useContext, useEffect, useState } from 'react';
import StandardHeader from './StandardHeader';

const useStyles = makeStyles(() => createStyles({
    pageHyperlinkTitle: {
        color: '#4d4de6',
        textDecoration: 'underline',
        cursor: 'pointer',
        fontSize: 10,
        marginTop: -20,
        marginLeft: 20,
    },
    pageHyperlinkTitleOnly: {
        color: '#4d4de6',
        textDecoration: 'underline',
        cursor: 'pointer',
        fontSize: 10,
        margin: 8,
        padding: '4px 8px',
    },
    h2: {
        background: '#B4B4B4',
        padding: '4px',
        marginBottom: '10px',
    },
    checkBox: {
        padding: 4,
        '& svg': {
            height: '0.75em',
            width: '0.75em',
        },
        marginLeft: 8,
    },
    selectedComp: {
        background: 'cornflowerblue',
    },
    formControl: {
        margin: '10px',
        width: '98%',
    },
}));

const title = {
    key: 'title',
    type: 'TextField',
    label: 'Page Title',
};

/**
 * This component is used in workshop section for editor
 * @param {object} config [configuration recieved from props]
 */
const Page = (config) => {
    const classes = useStyles();
    const { domain: configDomain, id, props } = config;
    const { inWorkshop, compareModal } = useContext(WorkshopContext);
    const {
        selectedTimeline, settingComponent, setSettingComponentHandle, domain, pageComponent, isPageModalOpen, setIsPageModalOpen,
    } = useContext(TimelineContext);
    const componentList = [];
    buildSettingsTreePage(componentList, pageComponent && pageComponent[domain]);
    const currentComp = componentList.find((c) => c.id === config.id);
    const pageProps = configDomain && Object.keys(configDomain).length > 0 && !currentComp?.props?.pageChild ? configDomain : props;
    const [component, setComponentState] = useRecoilState(componentWithId(id, pageProps));
    const [pageViewData, setPage] = useState();
    const pageComponents = currentComp?.props?.pageChild ? component.props.components : component?.[domain]?.components || [];
    const size = component?.props?.size === 'auto' ? true : parseInt(component?.props?.size, 10);

    if (configDomain && !component.id) {
        component[domain] = config[domain];
    }

    useEffect(() => {
        if (currentComp?.props?.pageChild) {
            return;
        }
        const domainArray = ['Medguide', 'MedguideSSR', 'OCCS', 'LAGayne'];
        if (pageComponent && !component.id) {
            if (pageComponent.id === config.id) {
                domainArray.forEach((domainVal) => {
                    component[domainVal] = JSON.parse(JSON.stringify(pageComponent[domainVal] || {}));
                });
                component.props = JSON.parse(JSON.stringify(pageComponent.Medguide));
                setComponentState(component);
            }
        }
    }, [pageComponent]);

    let isSelected = false;

    if (settingComponent && settingComponent.id === id) {
        isSelected = true;
    }

    /**
     * @function findPage to find page html.
     * @param {string} children [children recieved from selected time line]
     * @param {string} pageId [pageId recieved from config]
     */
    const findPage = (children, pageId) => {
        if (children) {
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < children.length; i++) {
                if (children[i].id === pageId) {
                    return children;
                }
                if (children[i].props.components) {
                    const result = findPage(children[i].props.components, pageId);
                    if (result) {
                        return result;
                    }
                }
                /* In case of Progression it will find the Page and return the result */
                if (children[i].props.progressions) {
                    const result = findPage(children[i].props.progressions, pageId);
                    if (result) {
                        return result;
                    }
                }
            }
        }

        return null;
    };

    /**
     * @function openPageView to open the Page Modal.
     */
    const openPageView = () => {
        const targetPage = findPage(selectedTimeline.columns, config.id);
        setIsPageModalOpen(true);
        setPage(targetPage);
    };

    /**
     * @function closePageView to close the Page Modal.
     */
    const handleclose = () => {
        setPage(null);
        setIsPageModalOpen(false);
    };

    const getPageTitle = () => {
        if (config.props.pageCB === false || component?.[domain]?.title === '') {
            return (<h4 onClick={openPageView} className={classes.pageHyperlinkTitleOnly}>{component?.[domain]?.title}</h4>);
        }

        return (
            <div>
                <FormControlLabel
                    control={<Checkbox className={classes.checkBox} />}
                />
                <h4 onClick={openPageView} className={classes.pageHyperlinkTitle}>
                    {config?.props?.title}
                </h4>
            </div>
        );
    };

    const renderTitleEditor = () => {
        let componentVal = { ...component };
        componentVal = { ...componentVal, type: pageComponent?.type, [domain]: pageComponent && pageComponent[domain] };
        const extraProps = {
            component: componentVal,
        };

        return (
            <>
                {makeInlineEditor({
                    field: title,
                    setComponentState,
                    classes,
                    domain,
                    ...extraProps,
                })}
            </>
        );
    };

    const getPageEditor = () => {
        if (inWorkshop && !compareModal) {
            return (
                <Grid key={component.id} item xs={size} className={classes.column}>
                    {renderTitleEditor()}
                    <StandardHeader type="h6">Page</StandardHeader>
                    {(domain !== 'Medguide' && pageComponents.length === 0) && (
                        <Alert severity="error">
                            Please copy from Medguide Domain First
                        </Alert>
                    )}
                    <Container
                        stopContextMenu
                        props={{ components: (pageComponents.length === 0 && domain === 'Medguide') ? component.props.components : pageComponents }}
                    />
                </Grid>
            );
        }

        return getPageTitle();
    };

    let pIndex = 0;
    if (pageViewData && pageViewData.length > 0) {
        pIndex = pageViewData.findIndex((d) => d.Medguide);
    }

    return (
        <>
            <div
                aria-hidden="true"
                className={isSelected ? classes.selectedComp : null}
                id={isSelected ? 'selectedCompId' : ''}
                onClick={(event) => { event.stopPropagation(); setActiveComponent(setSettingComponentHandle, config); }}
            >
                {getPageEditor()}
            </div>
            {isPageModalOpen && pageViewData
                && (
                    <PageViewDialog
                        classes={{ paper: classes.paper }}
                        id="page-preview"
                        open={isPageModalOpen}
                        onClose={handleclose}
                        title={pageViewData[pIndex].props.title ? pageViewData[pIndex].props.title : ''}
                        data={pageViewData}
                    />
                )}
        </>
    );
};

/**
 * This component is preview of page
 * @param {object} config [configuration recieved from props]
 */
const PagePreview = ({ component }) => (
    <h4 style={{ borderBottom: '1px solid #cbcbcb' }}>
        {component.props.title}
    </h4>
);

/**
 * Set the key and type for title in workshop
 */
const TitlePropForWorkshop = {
    key: 'title',
    type: 'TextField',
    label: 'Title',
    placeholder: 'Enter Page Title',
    isBasic: true,
    isAdvance: true,
    isAdmin: true,
};

const CBForWorkshop = {
    key: 'pageCB',
    type: 'Checkbox',
    label: 'Show Checkbox before Page Title',
    default: true,
    isBasic: true,
    isAdvance: true,
    isAdmin: true,
};

const PagePropsForSetting = {
    display: [TitlePropForWorkshop, CBForWorkshop],
    poi: true,
};

/**
 * This component is used in setting section with template library
 * @param {object} config [configuration recieved from props]
 * @param {function} updateComponent [recieved function as props to save page editor]
 */
const PageEditor = ({ config, updateComponent }) => {
    const [component, setComponentState] = useRecoilState(componentWithId(config.id));
    const { settingMenuType, domain, pageComponent } = useContext(TimelineContext);

    const filterPageProps = { display: FilterSettings(PagePropsForSetting, settingMenuType), poi: PagePropsForSetting.poi };
    return (
        <Settings
            editorProps={filterPageProps}
            component={{ ...component, type: pageComponent?.type, [domain]: pageComponent && pageComponent[domain] }}
            setComponentState={setComponentState}
            saveComponent={updateComponent}
        />
    );
};

export {
    Page, PageEditor, PagePreview,
};
