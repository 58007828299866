import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';

export default function WarningDialog({
    open, onClose, title, desc, primaryBtnText,
}) {
    const handleClose = (ok) => {
        onClose(ok);
    };

    return (

        <Dialog
            open={open}
            onClose={() => handleClose(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {desc}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose(false)} variant="contained">
                    Cancel
                </Button>
                <Button onClick={() => handleClose(true)} color="primary" autoFocus>
                    {primaryBtnText || 'Okay'}
                </Button>
            </DialogActions>
        </Dialog>

    );
}
