/* eslint-disable camelcase */
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Checkbox,
    Grid,
    Tooltip,
} from '@material-ui/core';
import { ComponentPreview } from './components/index';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {
    GET_ORGANIZATION_BY_ID,
    GET_TEMPLATE_TIMELINES,
    GET_TIMELINE_BY_ID,
    GET_TIMELINE_COLUMN_BY_USER,
} from './context/Timeline';
import { GET_ROLES_BY_ORG_ID, GET_USER_BY_ID } from './context/User';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { OrgContext, TimelineContext, UserContext } from './context';
import { buildTemplateChildren, reorder, sortedObject } from './helper/commonHelper';
import { copyTemplateWithImg } from './context/util';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { standardHeaders } from './components/utils/StandardStyles';

import { useQuery } from 'react-apollo';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import IndustrySpecialityDialog from './components/dialog/IndustrySpecialityDialog';
import Info from '@material-ui/icons/InfoOutlined';
import List from '@material-ui/core/List';
import ManagePatient from './components/ManagePatientModel';
import ManageUser from './components/ManageUserModel';
import React, {
    Fragment, useContext, useEffect, useMemo, useState,
} from 'react';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import childSelectSuggestLRCF from './images/template/child_Select_Suggest_LR_CF.png';
import gql from 'graphql-tag';
import manullySelectChildren from './images/template/manually_select_children.png';
import selectComplete from './images/template/select_complete.png';
import selectCompleteNotes from './images/template/select_complete_notes.png';
import textCompleteNotes from './images/template/Text-Complete-Notes.png';
import selectTextCompleteNotes from './images/template/select-text-complete-notes.png';
import universalLRCF from './images/template/universal_LR_CF.png';
import universalOneSidedCF from './images/template/universal_one_sided_CF.png';

const GET_INDUSTRY = gql`
query GetIndustry( $is_null: Boolean ) {
    industry_speciality(where: { speciality_parent: { _is_null: $is_null } }) {
      id
      name
      category
    }
  }  
`;

const templateImgList = [
    {
        name: 'Universal LR CF',
        img: universalLRCF,
    },
    {
        name: 'Child Select & Suggest LR CF',
        img: childSelectSuggestLRCF,
    },
    {
        name: 'Universal One Sided CF',
        img: universalOneSidedCF,
    },
    {
        name: 'Child Select & Suggest One Sided CF',
        img: manullySelectChildren,
    },
    {
        name: 'SELECT-COMMPLETED-TEMPLATE',
        img: selectComplete,
    },
    {
        name: 'Select-Complete-Notes',
        img: selectCompleteNotes,
    },
    {
        name: 'Select/Text-Complete-Notes',
        img: selectTextCompleteNotes,
    },
];

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
        maxWidth: 600,
        padding: 4,
    },
    popper: {
        minWidth: '30%',
    },
}))(Tooltip);

const useRowStyles = makeStyles(() => ({
    sectionHeader: {
        margin: 0,
        minHeight: 'auto !important',
        height: 32,
        border: '1px solid #ececec',
        ...standardHeaders.sectionHeader,
        position: 'relative',
        flexDirection: 'row-reverse',
    },
    sectionHeader1: {
        margin: 0,
        minHeight: 'auto !important',
        border: '1px solid #ececec',
        ...standardHeaders.sectionHeader,
        position: 'relative',
        flexDirection: 'row-reverse',
    },
    iconButton: {
        margin: '0 8px 0 2px',
        padding: 0,
        background: ({ level, itemIcon }) => (level === 0 && !itemIcon ? '#bbb' : 'transparent'),
        color: '#000000',
        borderRadius: 2,
        '&:hover': {
            background: ({ level, itemIcon }) => (level === 0 && !itemIcon ? '#bbb' : 'transparent'),
        },
    },
    accordionSummaryText: {
        justifyContent: 'center',
    },
    accordionSummaryText1: {
        justifyContent: 'center',
        paddingRight: '15%',
        color: '#FFFFFF',
    },
    column: {
        border: '1px solid #ececec',
        padding: 0,
    },
    header: {
        border: '1px solid #ececec',
        padding: '4px',
        background: 'lightgrey',
    },
    formControl: {
        margin: '10px',
        width: '95%',
    },
    columHeadType: {
        fontSize: '0.75rem',
    },
    expandedColor: {
        color: '#066785',
    },
    headerColumnContent: {
        justifyContent: 'center',
    },
    headerColumnContent1: {
        justifyContent: 'center',
        fontSize: 12,
    },
    superGroupAccordionCont: {
        background: '#1C1C1C',
        color: '#FFFFFF',
    },
    fontSize13PX: {
        fontSize: 13,
        justifyContent: 'center',
    },
    templateCont: {
        border: '1px solid #ececec',
        margin: '0rem',
        display: 'flex',
        justifyContent: 'space-between',
    },
    templatePrevCont: {
        border: '1px solid #ececec',
        margin: '0rem',
        pointerEvents: 'none !important',
    },
    templateImage: {
        display: 'block',
        width: '100%',
    },
    fontSize13PX1: {
        fontSize: 13,
        justifyContent: 'center',
        paddingRight: '15%',
        color: '#1C1C1C',
    },
    fontSize12PX: {
        fontSize: 12,
        color: '#1C1C1C',
        justifyContent: 'center',
    },
    fontSize12PX1: {
        fontSize: 12,
        justifyContent: 'center',
        paddingRight: '15%',
        color: '#FFFFFF',
    },
    fontSize11PX: {
        fontSize: 11,
        textDecoration: 'underline',
        color: '#1C1C1C',
        textAlign: 'left',
    },
    fontSize10PX: {
        fontSize: 10,
    },
    rootAccordion: {
        '&:before': {
            height: 'auto',
        },
    },
    rootAccordionStyle: {
        border: '1px solid #6ca1ff1d',
        marginBottom: '1px !important',
        minHeight: 35,
        '&:before': {
            backgroundColor: '#FFFFFF',
        },
    },
    timelineLinkCont: {
        border: '1px solid #6ca1ff1d',
        marginBottom: '1px !important',
        padding: 8,
        minHeight: 35,
        color: '#2A73F4',
        '&:before': {
            backgroundColor: '#FFFFFF',
        },
    },
    checkBoxStyle: {
        '& svg': {
            height: 15,
            width: 15,
            color: '#000000',
        },
        padding: 6,
    },
    accCompDetails: {
        width: '100%',
    },
    detailTemplateCont: {
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'row',
        marginLeft: ({ level }) => (level * 8),
        marginRight: ({ level }) => (level * 8),
        marginTop: '2px',
        marginBottom: '2px',
        alignItems: 'center',
        minHeight: 35,
    },
    itemAccdetailTemplateCont: {
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'row',
        marginLeft: ({ level }) => (level * 8),
        marginRight: ({ level }) => (level * 8),
        alignItems: 'center',
        minHeight: 35,
        marginBottom: 1,
    },
    checkBoxCont: {
        cursor: 'pointer',
        margin: '-3px 4px -4px 2px',
        fontSize: 10,
        color: '#2A73F4',
        display: 'flex',
        alignItems: 'center',
    },
    templateNameCont: {
        cursor: 'pointer',
        margin: '7px 15px',
        fontSize: 10,
        color: '#2A73F4',
    },
    infoIconStyle: {
        color: '#011E27',
        fontSize: 16,
        marginTop: 8,
    },
    timelineAccordionSummary: {
        background: '#1C1C1C',
        border: '1px solid #6CA1FF1D',
        color: '#FFFFFF',
        marginBottom: 1,
    },
    timelineAccordionDetail: {
        display: 'block',
        padding: '0px',
        background: '#6CA1FF1D',
    },
    subCategoryCont: {
        justifyContent: 'space-between',
        marginLeft: ({ level }) => (level * 5),
        marginTop: '2px',
        marginBottom: '2px',
    },
    subCategoryText: {
        margin: '7px 15px',
        fontSize: 11,
    },
    subCategoryText1: {
        margin: '7px 15px',
        fontSize: 11,
    },
    templateListAccordionSummary: {
        background: '#1C1C1C',
        border: '1px solid #6CA1FF1D',
        color: '#FFFFFF',
        marginBottom: 1,
    },
    templateListAccordionDetail: {
        display: 'block',
        padding: '0px',
        backgroundColor: '#6CA1FF1D !important',
    },
    accordionStyle: {
        margin: '0px !important',
        boxShadow: 'none',
    },
    accordionStyle1: {
        margin: '2px !important',
        boxShadow: 'none',
        width: '100%',
    },
    accordionIconStyle: {
        background: '#1C1C1C',
        color: '#FFF',
    },
    accordionIconStyle1: {
        background: '#6d6d6d',
        color: '#1C1C1C',
    },
    accCompAccordionDetail: {
        padding: '0px',
        display: 'block',
    },
    accordionCompAccordionSummary: {
        marginLeft: ({ level }) => (level * 5),
        marginBottom: 1,
        color: ({ level }) => (level === 1 ? '#1C1C1C' : '#FFFFFF'),
    },
    accordionCompAccordionSummary1: {
        marginBottom: 1,
        color: ({ level }) => (level === 1 ? '#1C1C1C' : '#FFFFFF'),
        background: '#FFFFFF',
        paddingRight: 0,
        border: 'none',
    },
    marginRight10Px: {
        marginRight: 10,
    },
    itemAccordionStyle: {
        flexGrow: 1,
        boxShadow: 'none',
    },
    itemAccordionStyleExpanded: {
        '&&': {
            margin: 0,
        },
    },
    itemAccordionSummaryStyle: {
        flexGrow: 1,
        margin: 0,
        padding: 0,
    },
    itemAccordionContentExpanded: {
        '&&': {
            margin: 0,
        },
    },
    itemAccordionContentStyle: {
        width: '100%',
        display: 'block',
        margin: 0,
    },
    itemAccordionDetails: {
        display: 'flex',
        flexDirection: 'column',
        padding: 4,
        marginLeft: 8,
    },
    droppableCont: {
        minHeight: 'auto',
        width: '100%',
    },
    customNavlink: {
        textDecoration: 'none',
        width: '100%',
    },
    primaryBtn: {
        padding: '3px 6px',
        fontSize: 10,
        margin: '.5rem 0rem',
        borderRadius: '2px',
        boxShadow: '0px 0px 20px -4px rgba(51,36,5,0.37)',
        '&:hover': {
            boxShadow: 'none',
        },
    },
    AddDoctorBtn: {
        padding: '3px 6px',
        fontSize: 10,
        borderRadius: '2px',
        margin: '1px 0px',
        boxShadow: '0px 0px 20px -4px rgba(51,36,5,0.37)',
        '&:hover': {
            boxShadow: 'none',
        },
    },
    alignCenter: {
        textAlign: 'center',
    },
    addIndustryBtnCont: {
        textAlign: 'center',
        background: '#FFFFFF',
    },
    expandIconStyle: {
        background: '#FFFFFF',
        color: '#1C1C1C',
    },
    timelineOrgText: {
        fontSize: 10,
        marginLeft: '2rem',
    },
}));
const groupBy = (key, acc, value, additionalData = {}) => {
    acc[key] = acc[key] || { items: [] };
    acc[key] = { ...acc[key], ...additionalData };
    acc[key].items = [...acc[key].items, value];
    return acc;
};

let tlGroupByData = null;
export const TimelineLink = ({ timeline, text }) => {
    const classes = useRowStyles({ level: 1 });
    const { data: currentOrgData } = useQuery(GET_ORGANIZATION_BY_ID, {
        variables: {
            orgID: timeline?.['organization_id'],
        },
        skip: !timeline?.['organization_id'],
    });
    const timelineOrg = currentOrgData?.['organization_by_pk']?.orgCode;
    return (
        <div className={classes.timelineLinkCont}>
            <Typography
                target="_blank"
                component="a"
                href={`/${timelineOrg}/timeline/${timeline.id}`}
                className={classes.fontSize10PX}
            >
                {text || timeline.name}
            </Typography>
        </div>
    );
};
export const TimelineLinkUnStyled = ({ timeline, text }) => {
    const { data: currentOrgData } = useQuery(GET_ORGANIZATION_BY_ID, {
        variables: {
            orgID: timeline?.['organization_id'],
        },
        skip: !timeline?.['organization_id'],
    });
    const timelineOrg = currentOrgData?.['organization_by_pk']?.orgCode;
    return (
        <Typography target="_blank" component="a" href={`/${timelineOrg}/timeline/${timeline.id}`}>
            {text || timeline.name}
        </Typography>
    );
};
// eslint-disable-next-line camelcase
const NormalUserAccordion = ({ user_id }) => {
    /**
     *  Tried using the context data but data for some users were missing
     * hence using the query instead
     */

    const { data: userData } = useQuery(GET_USER_BY_ID, {
        variables: {
            id: user_id,
        },
        // eslint-disable-next-line camelcase
        skip: !user_id,
    });

    const user = { ...userData?.['user_by_pk'], user_id };
    const { data } = useQuery(GET_TIMELINE_COLUMN_BY_USER, {
        variables: {
            user_id: user.user_id,
        },
        skip: !user.user_id,
    });
    const timelineID = data?.['timeline_column_access']?.[0]?.['timeline_id'];
    const { data: orgData } = useQuery(GET_TIMELINE_BY_ID, {
        variables: {
            timelineID,
        },
        skip: !timelineID,
    });
    const orgID = orgData?.['timeline_by_pk']?.['organization_id'];
    const timelineName = orgData?.['timeline_by_pk'].name;
    const timeline = { id: timelineID, organization_id: orgID, name: timelineName };
    const classes = useRowStyles({ level: 1 });
    const [expanded, setExpanded] = useState(false);
    const handleChange = (event, newExpanded) => {
        setExpanded(newExpanded);
    };
    return (
        <Accordion
            expanded={expanded}
            onChange={handleChange}
            classes={{ root: classes.rootAccordionStyle, expanded: classes.itemAccordionStyleExpanded }}
            className={classes.itemAccordionStyle}
        >
            <AccordionSummary
                expandIcon={
                    !expanded ? (
                        <ChevronRightIcon />
                    )
                        : (
                            <ExpandLessIcon className={classes.expandIconStyle} />
                        )
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={`${classes.sectionHeader} ${classes.accordionCompAccordionSummary1} ${classes.itemAccordionSummaryStyle}`}
                classes={{ expandIcon: classes.iconButton }}
            >
                <div className={classes.templateNameCont}>
                    <Typography className={classes.fontSize10PX}>
                        {user?.name}
                    </Typography>
                </div>
            </AccordionSummary>
            <AccordionDetails className={classes.itemAccordionDetails}>
                {
                    timelineID && <TimelineLink timeline={timeline} />
                }
            </AccordionDetails>
        </Accordion>
    );
};
const RolesAccordion = ({
    // eslint-disable-next-line camelcase
    role, data, timelines_by_user, onTimelineUser,
}) => {
    const { selectedOrg, loggedUserType } = useContext(OrgContext);
    const isSuperAdmin = loggedUserType === 'Super Admin';
    const [isPatientModalOpen, setPatientModalOpen] = useState(false);
    const [isUserModalOpen, setUserModalOpen] = useState(false);
    const classes = useRowStyles({ level: 1 });
    const [expanded, setExpanded] = useState(false);
    const handleChange = (event, newExpanded) => {
        setExpanded(newExpanded);
    };

    const handleAddPatient = () => {
        setPatientModalOpen(true);
    };
    const handleAddUser = () => {
        setUserModalOpen(true);
    };

    return (
        <>
            <Accordion
                expanded={expanded}
                onChange={handleChange}
                classes={{ root: classes.rootAccordionStyle, expanded: classes.itemAccordionStyleExpanded }}
                className={classes.itemAccordionStyle}
            >

                <AccordionSummary
                    expandIcon={
                        !expanded ? (
                            <ChevronRightIcon />
                        )
                            : (
                                <ExpandLessIcon className={classes.expandIconStyle} />
                            )
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={`${classes.sectionHeader} ${classes.accordionCompAccordionSummary1} ${classes.itemAccordionSummaryStyle}`}
                    classes={{
                        expandIcon: classes.iconButton,
                    }}
                >
                    <div className={classes.templateNameCont}>
                        <Typography className={classes.fontSize10PX}>
                            {role === 'User' ? 'Staff' : role}
                        </Typography>
                    </div>
                </AccordionSummary>
                <AccordionDetails className={classes.itemAccordionDetails}>
                    {expanded ? (
                        <>
                            {_.map(data, ({ user_id }) => (
                                <NormalUserAccordion key={user_id} user_id={user_id} timelines_by_user={timelines_by_user} />
                            ))}
                            {!isSuperAdmin && role === 'Patient' && (
                                <div className={classes.alignCenter}>
                                    <Button
                                        size="small"
                                        color="primary"
                                        variant="contained"
                                        className={classes.primaryBtn}
                                        onClick={handleAddPatient}
                                    >
                                        Add Patient
                                    </Button>
                                </div>
                            )}
                            {!isSuperAdmin && role === 'User' && (
                                <div className={classes.alignCenter}>
                                    <Button size="small" color="primary" variant="contained" className={classes.primaryBtn} onClick={handleAddUser}>
                                        Add Staff
                                    </Button>
                                </div>
                            )}
                            {!isSuperAdmin && role === 'Admin' && (
                                <div className={classes.alignCenter}>
                                    <Button size="small" color="primary" variant="contained" className={classes.primaryBtn} onClick={handleAddUser}>
                                        Add Admin
                                    </Button>
                                </div>
                            )}
                        </>
                    ) : null}
                </AccordionDetails>
            </Accordion>
            <ManageUser
                timelineUser={onTimelineUser}
                open={isUserModalOpen}
                selectedUser={{}}
                resetOnClose
                handleClose={() => setUserModalOpen(false)}
                orgID={selectedOrg.id}
                routeLabel="User"
            />
            <ManagePatient
                timelineUser={onTimelineUser}
                open={isPatientModalOpen}
                selectedPatient={{}}
                handleClose={() => setPatientModalOpen(false)}
                orgID={selectedOrg.id}
                routeLabel="Patient"
            />
        </>
    );
};
const NonSharedAccordion = ({ non_shared }) => {
    const classes = useRowStyles({ level: 1 });
    const [expanded, setExpanded] = useState(false);
    const handleChange = (event, newExpanded) => {
        setExpanded(newExpanded);
    };
    return (
        <Accordion
            expanded={expanded}
            onChange={handleChange}
            classes={{ root: classes.rootAccordionStyle, expanded: classes.itemAccordionStyleExpanded }}
            className={classes.itemAccordionStyle}
        >
            <AccordionSummary
                expandIcon={
                    !expanded ? (
                        <ChevronRightIcon />
                    )
                        : (
                            <ExpandLessIcon className={classes.expandIconStyle} />
                        )
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={`${classes.sectionHeader} ${classes.accordionCompAccordionSummary1} ${classes.itemAccordionSummaryStyle}`}
                classes={{
                    expandIcon: classes.iconButton,
                }}
            >
                <div className={classes.templateNameCont}>
                    <Typography className={classes.fontSize10PX}>
                        Timeline
                    </Typography>
                </div>
            </AccordionSummary>
            <AccordionDetails className={classes.itemAccordionDetails}>
                {expanded ? (
                    _.map(non_shared, (item) => (
                        <TimelineLink key={item.id} timeline={item} />
                    ))
                ) : null}
            </AccordionDetails>
        </Accordion>
    );
};
const UserAccordion = ({ user, is_expert, timelines = [] }) => {
    const { loggedUserType } = useContext(OrgContext);
    const { orgMembers, timelineAssoc } = useContext(TimelineContext);
    const isSuperAdmin = loggedUserType === 'Super Admin';
    const classes = useRowStyles({ level: 1 });
    const [expanded, setExpanded] = useState(false);
    const handleChange = (event, newExpanded) => {
        setExpanded(newExpanded);
    };
    const orgID = orgMembers?.[user.id];
    const { data: roleData, refetch } = useQuery(GET_ROLES_BY_ORG_ID, {
        variables: {
            orgID,
        },
        skip: isSuperAdmin,
    });

    const assocTimelines = timelineAssoc?.[orgID];
    const roles = roleData?.['organization_member'] || [];
    const shared_timelines_ids = _.map(assocTimelines || [], (item) => ({ ...item, id: item.timeline_id }));
    const timelines_by_id = _.keyBy(timelines, 'id');
    const timelines_by_user = _.groupBy(_.map(shared_timelines_ids, (t) => ({ ...t, ...timelines_by_id[t.id] })), 'user_id');
    const shared_timeline = _.intersectionBy(timelines, shared_timelines_ids, 'id');
    const data_by_roles = _.groupBy(roles, 'role');
    if (!data_by_roles.Patient) {
        data_by_roles.Patient = {};
    }
    if (!data_by_roles.User) {
        data_by_roles.User = {};
    }
    if (!data_by_roles.Admin) {
        data_by_roles.Admin = {};
    }
    return (
        <>
            {is_expert && (
                <div className={classes.timelineLinkCont}>
                    <Typography className={classes.fontSize10PX}>
                        {`${user?.name} ${user?.['last_name']}`}
                    </Typography>
                </div>
            )}
            {!is_expert && (
                <Accordion
                    expanded={expanded}
                    onChange={handleChange}
                    classes={{ root: classes.rootAccordionStyle, expanded: classes.itemAccordionStyleExpanded }}
                    className={classes.itemAccordionStyle}
                >
                    <AccordionSummary
                        expandIcon={
                            !expanded ? (
                                <ChevronRightIcon />
                            )
                                : (
                                    <ExpandLessIcon className={classes.expandIconStyle} />
                                )
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className={`${classes.sectionHeader} ${classes.accordionCompAccordionSummary1} ${classes.itemAccordionSummaryStyle}`}
                        classes={{ expandIcon: classes.iconButton }}
                    >
                        <div className={classes.templateNameCont}>
                            <Typography className={classes.fontSize10PX}>
                                {`${user?.name} ${user?.['last_name']}`}
                            </Typography>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails className={classes.itemAccordionDetails}>
                        {expanded ? (
                            <>
                                {!is_expert && <NonSharedAccordion non_shared={shared_timeline} />}
                                {!is_expert && !isSuperAdmin && _.map(data_by_roles, (data, role) => (
                                    <RolesAccordion
                                        onTimelineUser={() => refetch()}
                                        timelines_by_user={timelines_by_user}
                                        role={role}
                                        data={data}
                                    />
                                ))}
                            </>
                        ) : null}
                    </AccordionDetails>
                </Accordion>
            )}
        </>
    );
};

const ExpertAccordion = ({
    is_expert, items, industryName, specialityName,
}) => {
    const { loggedUserType } = useContext(OrgContext);
    const { hrzViewTLFlag, orgMembers } = useContext(TimelineContext);
    const isSuperAdmin = loggedUserType === 'Super Admin';
    const { selectedOrg } = useContext(OrgContext);
    const classes = useRowStyles({ level: 1 });
    const [expanded, setExpanded] = useState(false);
    const [isDocterModalOpen, setDoctorModalOpen] = useState(false);

    const handleChange = (event, newExpanded) => {
        setExpanded(newExpanded);
    };
    const sameOrgUsers = useMemo(
        () => (isSuperAdmin ? [] : _.filter(items,
            (item) => _.some(item.organization_members, { organization_id: selectedOrg.id }), [isSuperAdmin, items, selectedOrg]))
    );
    const itemsToShow = useMemo(
        () => (is_expert ? _.filter(items, 'is_expert') : isSuperAdmin ? items : sameOrgUsers),
        [is_expert, items, sameOrgUsers]
    );

    const orgIDs = itemsToShow.map((item) => orgMembers?.[item.id]);
    const { data: timelinesData } = useQuery(GET_TEMPLATE_TIMELINES, {
        variables: {
            orgIDs,
        },
    });

    const timelines = (timelinesData?.timeline || []);
    const handleAddDoctor = () => {
        if (!hrzViewTLFlag) setDoctorModalOpen(true);
    };

    return (
        <>
            <Accordion
                expanded={expanded}
                onChange={handleChange}
                classes={{ root: classes.rootAccordionStyle, expanded: classes.itemAccordionStyleExpanded }}
                className={classes.itemAccordionStyle}
            >
                <AccordionSummary
                    expandIcon={
                        !expanded ? (
                            <ChevronRightIcon />
                        )
                            : (
                                <ExpandLessIcon className={classes.expandIconStyle} />
                            )
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={`${classes.sectionHeader} ${classes.accordionCompAccordionSummary1} ${classes.itemAccordionSummaryStyle}`}
                    classes={{
                        expandIcon: classes.iconButton,
                    }}
                >
                    <div className={classes.templateNameCont}>
                        <Typography className={classes.fontSize10PX}>
                            {is_expert ? 'Expert Doctors Timelines' : 'Doctors Timelines'}
                        </Typography>
                    </div>
                </AccordionSummary>
                <AccordionDetails className={classes.itemAccordionDetails}>
                    {expanded ? (
                        <>
                            {_.map(itemsToShow, (user) => <UserAccordion key={user.id} is_expert={is_expert} user={user} timelines={timelines} />)}
                            {isSuperAdmin && (
                                <Grid container>
                                    <Grid item xs={12} className={classes.alignCenter}>
                                        <div className={classes.templateNameCont}>
                                            <Button
                                                size="small"
                                                color="primary"
                                                variant="contained"
                                                className={classes.AddDoctorBtn}
                                                onClick={handleAddDoctor}
                                            >
                                                Add Doctor
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            )}
                        </>
                    ) : null}
                </AccordionDetails>
            </Accordion>
            <ManageUser
                is_expert={is_expert}
                open={isDocterModalOpen}
                selectedUser={{}}
                resetOnClose
                handleClose={() => setDoctorModalOpen(false)}
                orgID={selectedOrg.id}
                routeLabel="Doctor"
                industryName={industryName}
                specialityName={specialityName}
            />
        </>
    );
};
const SpecialityAccordion = ({ items, specialty, industryName }) => {
    const { loggedUserType } = useContext(OrgContext);
    const isSuperAdmin = loggedUserType === 'Super Admin';
    const classes = useRowStyles({ level: 1 });
    const [expanded, setExpanded] = useState(false);
    const handleChange = (event, newExpanded) => {
        setExpanded(newExpanded);
    };
    return (
        <>
            {specialty && (
                <Accordion
                    expanded={expanded}
                    onChange={handleChange}
                    classes={{ root: classes.rootAccordionStyle, expanded: classes.itemAccordionStyleExpanded }}
                    className={classes.itemAccordionStyle}
                >
                    <AccordionSummary
                        expandIcon={
                            !expanded ? (
                                <ChevronRightIcon />
                            )
                                : (
                                    <ExpandLessIcon className={classes.expandIconStyle} />
                                )
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className={`${classes.sectionHeader} ${classes.accordionCompAccordionSummary1} ${classes.itemAccordionSummaryStyle}`}
                        classes={{
                            expandIcon: classes.iconButton,
                        }}
                    >
                        <div className={classes.templateNameCont}>
                            <Typography className={classes.fontSize10PX}>
                                {specialty}
                            </Typography>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails className={classes.itemAccordionDetails}>
                        {expanded ? (
                            <>
                                {isSuperAdmin && <ExpertAccordion is_expert items={items} industryName={industryName} specialityName={specialty} />}
                                <ExpertAccordion items={items} industryName={industryName} specialityName={specialty} />
                            </>
                        ) : null}
                    </AccordionDetails>
                </Accordion>
            )}
        </>
    );
};

const IndustryAccordion = ({ items, industry }) => {
    const classes = useRowStyles({ level: 1 });
    const [expanded, setExpanded] = useState(false);
    const [industrySpecialityDialog, setIndustrySpecialityDialog] = useState(false);
    const { hrzViewTLFlag, spl } = useContext(TimelineContext);
    const { loggedUserType } = useContext(OrgContext);
    const handleChange = (event, newExpanded) => {
        setExpanded(newExpanded);
    };

    let specialties = _.omit(_.groupBy(items, 'specialty'), null);

    const specialityOrder = {};
    const splData = (spl?.[industry] || []);
    splData.forEach((speciality) => {
        specialityOrder[speciality.name] = null;
    });

    // eslint-disable-next-line no-unused-vars
    for (const key in specialityOrder) {
        if (Object.prototype.hasOwnProperty.call(specialties, key)) {
            specialityOrder[key] = specialties[key];
        }
    }
    specialties = specialityOrder;

    const showIndustrySpecialityDialog = () => {
        setIndustrySpecialityDialog(true);
    };

    return (
        <>
            {industrySpecialityDialog && (
                <IndustrySpecialityDialog
                    industrySpecialityDialog={setIndustrySpecialityDialog}
                    setIndustrySpecialityDialog={setIndustrySpecialityDialog}
                    isIndustryDialog={false}
                    nameList={Object.keys(specialties)}
                    industryName={industry}
                />
            )}
            {industry && (
                <Accordion
                    expanded={expanded}
                    onChange={handleChange}
                    classes={{ root: classes.rootAccordionStyle, expanded: classes.itemAccordionStyleExpanded }}
                    className={classes.itemAccordionStyle}
                >
                    <AccordionSummary
                        expandIcon={
                            !expanded ? (
                                <ChevronRightIcon />
                            )
                                : (
                                    <ExpandLessIcon className={classes.expandIconStyle} />
                                )
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className={`${classes.sectionHeader} ${classes.accordionCompAccordionSummary1} ${classes.itemAccordionSummaryStyle}`}
                        classes={{
                            expandIcon: classes.iconButton,
                            // expanded: classes.expandedColor,
                        }}
                    >

                        <div className={classes.templateNameCont}>
                            <Typography className={classes.fontSize10PX}>
                                {industry}
                            </Typography>
                        </div>

                    </AccordionSummary>
                    <AccordionDetails className={classes.itemAccordionDetails}>
                        {expanded ? (
                            <>
                                {_.map(specialties, (items, specialty) => items && (
                                    <SpecialityAccordion
                                        key={specialty}
                                        items={items}
                                        industryName={industry}
                                        specialty={specialty}
                                    />
                                ))}
                                {loggedUserType === 'Super Admin' && !hrzViewTLFlag && (
                                    <Grid container>
                                        <Grid item xs={12} className={classes.alignCenter}>
                                            <Button
                                                size="small"
                                                color="primary"
                                                variant="contained"
                                                className={classes.primaryBtn}
                                                onClick={showIndustrySpecialityDialog}
                                            >
                                                Add Speciality
                                            </Button>
                                        </Grid>
                                    </Grid>
                                )}
                            </>
                        ) : null}
                    </AccordionDetails>
                </Accordion>
            )}
        </>
    );
};

const MedGuideStaticAcc = () => {
    const classes = useRowStyles({ level: 1 });
    const [expanded, setExpanded] = useState(false);
    const handleChange = (event, newExpanded) => {
        setExpanded(newExpanded);
    };

    return (
        <Accordion
            expanded={expanded}
            onChange={handleChange}
            classes={{ root: classes.rootAccordionStyle, expanded: classes.itemAccordionStyleExpanded }}
            className={classes.itemAccordionStyle}
        >
            <AccordionSummary
                expandIcon={
                    !expanded ? (
                        <ChevronRightIcon />
                    )
                        : (
                            <ExpandLessIcon className={classes.expandIconStyle} />
                        )
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={`${classes.sectionHeader} ${classes.accordionCompAccordionSummary1} ${classes.itemAccordionSummaryStyle}`}
                classes={{
                    expandIcon: classes.iconButton,
                }}
            >
                <div className={classes.templateNameCont}>
                    <Typography className={classes.fontSize10PX}>
                        Medguide
                    </Typography>
                </div>
            </AccordionSummary>
            <AccordionDetails className={classes.itemAccordionDetails}>
                <GyneStaticAcc />
            </AccordionDetails>
        </Accordion>
    );
};

const GyneStaticAcc = () => {
    const classes = useRowStyles({ level: 1 });
    const [expanded, setExpanded] = useState(false);
    let { timelines = [] } = useContext(TimelineContext);
    timelines = _.omit(_.groupBy(timelines, 'category'), null);
    const [expanded2, setExpanded2] = useState(false);
    const handleChange = (event, newExpanded) => {
        setExpanded(newExpanded);
    };
    const handleChange2 = (event, newExpanded) => {
        setExpanded2(newExpanded);
    };

    return (
        <Accordion
            expanded={expanded}
            onChange={handleChange}
            classes={{ root: classes.rootAccordionStyle, expanded: classes.itemAccordionStyleExpanded }}
            className={classes.itemAccordionStyle}
        >
            <AccordionSummary
                expandIcon={
                    !expanded ? (
                        <ChevronRightIcon />
                    )
                        : (
                            <ExpandLessIcon className={classes.expandIconStyle} />
                        )
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={`${classes.sectionHeader} ${classes.accordionCompAccordionSummary1} ${classes.itemAccordionSummaryStyle}`}
                classes={{
                    expandIcon: classes.iconButton,
                }}
            >
                <div className={classes.templateNameCont}>
                    <Typography className={classes.fontSize10PX}>
                        Gynecomastia
                    </Typography>
                </div>
            </AccordionSummary>
            <AccordionDetails className={classes.itemAccordionDetails}>
                <div className={classes.templateNameCont} style={{ textAlign: 'left' }}>
                    {expanded && (
                        <BaseTimelineAccordion
                            base="Doctor"
                            timelines={timelines?.['Generic']}
                            expanded={expanded2}
                            handleChange={handleChange2}
                            timelinesData={timelines}
                        />
                    )}
                </div>
            </AccordionDetails>
        </Accordion>
    );
};

const BaseTimelineAccordion = ({
    base, timelines = [], expanded, handleChange, timelinesData,
}) => {
    const classes = useRowStyles({ level: 1 });
    const [nestedExpanded, setNestedExpanded] = useState(false);
    const handleNested = (event, newExpanded) => {
        setNestedExpanded(newExpanded);
    };

    return (
        <Accordion
            expanded={expanded}
            onChange={handleChange}
            classes={{ root: classes.rootAccordionStyle, expanded: classes.itemAccordionStyleExpanded }}
            className={classes.itemAccordionStyle}
        >
            <AccordionSummary
                expandIcon={
                    !expanded ? (
                        <ChevronRightIcon />
                    )
                        : (
                            <ExpandLessIcon className={classes.expandIconStyle} />
                        )
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={`${classes.sectionHeader} ${classes.accordionCompAccordionSummary1} ${classes.itemAccordionSummaryStyle}`}
                classes={{
                    expandIcon: classes.iconButton,
                }}
            >
                <div className={classes.templateNameCont}>
                    <Typography className={classes.fontSize10PX}>
                        {base}
                    </Typography>
                </div>
            </AccordionSummary>
            <AccordionDetails className={classes.itemAccordionDetails}>
                <div className={classes.templateNameCont} style={{ textAlign: 'left' }}>
                    {expanded && (
                        (timelines?.length > 0
                            ? timelines?.map((timeline) => <TimelineLink timeline={timeline} />)
                            : null)
                    )}
                    {expanded && timelinesData?.['Shared With Patient']?.length > 0
                        ? (
                            <BaseTimelineAccordion
                                base="Patients"
                                timelines={timelinesData?.['Shared With Patient']}
                                expanded={nestedExpanded}
                                handleChange={handleNested}
                                timelinesData={{}}
                            />
                        )
                        : null}
                </div>
            </AccordionDetails>
        </Accordion>
    );
};

const TimelineAccordion = ({ isPopup = null }) => {
    const { allUserData } = useContext(UserContext);
    let industries = _.omit(_.groupBy(allUserData, 'industry'), null);
    const { isDMRC, hrzViewTLFlag } = useContext(TimelineContext);
    const [expandedPanel, setExpandedPanel] = useState(isPopup ? 'panel1' : false);
    const [industrySpecialityDialog, setIndustrySpecialityDialog] = useState(false);

    const { loggedUserType } = useContext(OrgContext);

    const objectOrder = {};
    const industryList = useQuery(GET_INDUSTRY, { variables: { is_null: true } });
    if (industryList.data) {
        industryList.data.industry_speciality.forEach((industry) => {
            objectOrder[industry.name] = null;
        });
    }

    // eslint-disable-next-line no-unused-vars
    for (const key in objectOrder) {
        if (Object.prototype.hasOwnProperty.call(industries, key)) {
            objectOrder[key] = industries[key];
        }
    }
    industries = objectOrder;

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpandedPanel(isExpanded ? panel : false);
    };

    const showIndustrySpecialityDialog = () => {
        setIndustrySpecialityDialog(true);
    };

    const classes = useRowStyles({ level: 0 });
    return (
        <React.Fragment>
            {industrySpecialityDialog && (
                <IndustrySpecialityDialog
                    industrySpecialityDialog={setIndustrySpecialityDialog}
                    setIndustrySpecialityDialog={setIndustrySpecialityDialog}
                    isIndustryDialog
                    nameList={Object.keys(industries)}
                />
            )}
            <Accordion
                className={isDMRC || isPopup ? classes.accordionStyle1 : classes.accordionStyle}
                expanded={expandedPanel === 'panel1'}
                onChange={handleAccordionChange('panel1')}
            >
                <AccordionSummary
                    expandIcon={
                        expandedPanel === 'panel1' ? (
                            <ExpandLessIcon className={isPopup ? classes.accordionIconStyle1 : classes.accordionIconStyle} />
                        ) : (
                            <ChevronRightIcon className={isPopup ? classes.accordionIconStyle1 : classes.accordionIconStyle} />
                        )
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={`${classes.sectionHeader} ${classes.timelineAccordionSummary}`}
                    classes={{
                        expandIcon: classes.iconButton,
                        content: isDMRC ? classes.accordionSummaryText1 : classes.accordionSummaryText,
                    }}
                    style={{ background: isPopup && '#6d6d6d', color: isPopup && '#1C1C1C', paddingRight: isPopup && '15%' }}
                >
                    Timelines
                </AccordionSummary>
                <AccordionDetails className={classes.timelineAccordionDetail}>
                    {expandedPanel === 'panel1' ? (
                        <>
                            {loggedUserType !== 'Doctor'
                            && _.map(industries, (items, industry) => <IndustryAccordion key={industry} items={items} industry={industry} />)}
                            {loggedUserType === 'Super Admin' && !hrzViewTLFlag && (
                                <Grid container>
                                    <Grid item xs={12} className={classes.addIndustryBtnCont}>
                                        <Button
                                            size="small"
                                            color="primary"
                                            variant="contained"
                                            className={classes.primaryBtn}
                                            onClick={showIndustrySpecialityDialog}
                                        >
                                            Add Industry
                                        </Button>
                                    </Grid>
                                </Grid>
                            )}
                            {loggedUserType === 'Doctor' && !hrzViewTLFlag && (
                                <Grid container>
                                    <Grid item xs={12} className={classes.addIndustryBtnCont}>
                                        <MedGuideStaticAcc />
                                    </Grid>
                                </Grid>
                            )}
                        </>
                    ) : null}
                </AccordionDetails>
            </Accordion>
        </React.Fragment>
    );
};
const ItemAccordion = (props) => {
    const {
        level,
        isRClickDM,
        template,
        isTimeline,
        selectedItem,
        setSelected,
        onTemplateClick,
        hover,
        setHover,
        hoverItem,
        hoverFlag,
        isParentCollapsed,
        handleSelectDMTL,
        disableDnd,
        isTempLibrarySetting,
    } = props;
    const classes = useRowStyles({ level, itemIcon: true });
    const {
        actionRelation, setPreviousTemplate, workshopComponent, isDMRC, selectedDMWorkshopComponent, hrzViewTLFlag, tlAccordionData,
    } = useContext(TimelineContext);
    const [itemChildren, setChildren] = useState(template?.children || []);
    const [open, setOpen] = useState((tlAccordionData?.length > 0 && tlAccordionData.find((tl) => tl.id === template.id)) || template?.supergroup !== 'Column');
    const handleAccordionIconClicked = (e) => {
        e.stopPropagation();
        setOpen((open) => !open);
    };

    useEffect(() => {
        if (!template?.children) return;
        setChildren(template.children);
    }, [template?.children]);

    const handleChange = (e, template) => {
        if (e.target.checked) {
            if (!selectedDMWorkshopComponent?.props?.selectedTemplateLibrary) {
                selectedDMWorkshopComponent.props.selectedTemplateLibrary = [];
            }
            selectedDMWorkshopComponent.props.selectedTemplateLibrary.push(template);
        } else {
            const tempIndex = selectedDMWorkshopComponent.props.selectedTemplateLibrary.findIndex(
                (item) => item.template.id === template.template.id
            );
            selectedDMWorkshopComponent.props.selectedTemplateLibrary.splice(tempIndex, 1);
        }
        if (handleSelectDMTL) {
            handleSelectDMTL(selectedDMWorkshopComponent.props.selectedTemplateLibrary);
        }
    };

    const currentTemp = templateImgList?.find((i) => i?.name === template.name);

    return (
        <Droppable
            isCombineEnabled={!isParentCollapsed || !disableDnd}
            isDropDisabled={isParentCollapsed || disableDnd}
            droppableId={`droppable${template?.id}`}
            type="children"
        >
            {(provided) => (
                <div
                    ref={provided.innerRef}
                    className={classes.droppableCont}
                    {...provided.droppableProps}
                >
                    <Box
                        className={classes.itemAccdetailTemplateCont}
                        style={{
                            border: isDMRC ? '1px solid #DEE7F8' : '1px solid #6CA1FF1D',
                            marginLeft: template?.supergroup === 'Header' && 20,
                            margin: isTempLibrarySetting && '0px 0px 0px 5px',
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            if (!hrzViewTLFlag) {
                                if (selectedItem || actionRelation?.type === 'edit') {
                                    setPreviousTemplate({ template: workshopComponent });
                                }
                                setSelected(template);
                                onTemplateClick(template);
                            }
                        }}
                        selected={selectedItem === template}
                        onMouseEnter={() => { if (hover !== template && !hrzViewTLFlag) { setHover(template); hoverItem(template); } }}
                        onMouseLeave={() => {
                            if (!hrzViewTLFlag) {
                                setHover(selectedItem ? copyTemplateWithImg(workshopComponent) : null);
                            }
                            if (!hoverFlag) {
                                hoverItem(selectedItem ? copyTemplateWithImg(workshopComponent) : null, false);
                            }
                        }}
                    >
                        <Accordion expanded={open} classes={{ expanded: classes.itemAccordionStyleExpanded }} className={classes.itemAccordionStyle}>
                            <AccordionSummary
                                expandIcon={
                                    itemChildren.length === 0 ? <></> : !open ? (
                                        <ChevronRightIcon
                                            onClick={handleAccordionIconClicked}

                                        />
                                    )
                                        : (
                                            <ExpandLessIcon onClick={handleAccordionIconClicked} className={classes.expandIconStyle} />
                                        )

                                }
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className={`${classes.sectionHeader1} ${classes.accordionCompAccordionSummary1} ${classes.itemAccordionSummaryStyle}`}
                                classes={{
                                    expandIcon: classes.iconButton,
                                    expanded: classes.itemAccordionContentExpanded,
                                    content: `${(template?.children || []).length === 0 ? ''
                                        : classes.fontSize11PX} ${classes.itemAccordionContentStyle}`,
                                }}
                            >
                                <div className={classes.templateCont}>
                                    <div className={isDMRC ? classes.checkBoxCont : classes.templateNameCont}>
                                        {isDMRC && !isRClickDM && (
                                            <Checkbox
                                                className={classes.checkBoxStyle}
                                                checked={!!selectedDMWorkshopComponent?.props?.selectedTemplateLibrary?.find(
                                                (i) => i?.template?.id === template?.template?.id)}
                                                onChange={(e) => handleChange(e, template)}
                                            />
                                        )}
                                        <Typography className={classes.fontSize10PX}>
                                            {template?.name}
                                        </Typography>
                                    </div>
                                    {!isTimeline && !isRClickDM && (
                                        <LightTooltip
                                            placement="top-start"
                                            title={React.createElement(
                                                ComponentPreview[template?.template?.type],
                                                { component: template?.template }
                                            )}
                                        >
                                            <Box className={classes.marginRight10Px}>
                                                <Info className={classes.infoIconStyle} />
                                            </Box>
                                        </LightTooltip>
                                    )}
                                </div>
                                {template?.group === 'Complex Form Groups'
                                    ? <img className={classes.templateImage} src={currentTemp?.img} alt={currentTemp?.name} />
                                    : (
                                        <div className={classes.templatePrevCont}>
                                            { React.createElement(
                                                ComponentPreview[template?.template?.type],
                                                { component: template?.template }
                                            )}
                                        </div>
                                    )}

                            </AccordionSummary>
                            <AccordionDetails className={classes.itemAccordionDetails}>
                                {open ? (
                                    <>
                                        {itemChildren.filter((item) => item).map((item, index) => (
                                            <Draggable key={item?.id} draggableId={item?.id} index={index}>
                                                {(draggbaleProvided) => (
                                                    <div
                                                        ref={draggbaleProvided.innerRef}
                                                        {...draggbaleProvided.draggableProps}
                                                        {...draggbaleProvided.dragHandleProps}
                                                    >
                                                        <ItemAccordion
                                                            {...props}
                                                            isParentCollapsed={!open}
                                                            template={item}
                                                            isTempLibrarySetting={isTempLibrarySetting}
                                                        />
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </>
                                ) : null}
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                </div>
            )}
        </Droppable>
    );
};

const AccordionComp = ({
    item,
    itemKey,
    onTemplateClick,
    openAccordion,
    isTimeline,
    level = 0,
    hoverItem,
    handleSelectDMTL = null,
    isRClickDM = null,
    isPopup = null,
    isTempLibrarySetting,
}) => {
    const classes = useRowStyles({ level });
    const {
        actionRelation, saveTemplate, setPreviousTemplate, workshopComponent, isDMRC, selectedDMWorkshopComponent, hrzViewTLFlag, tlAccordionData,
        setSelectedComponent, setShowTimelineTable, setSelectedTemplate: setManagerTemplate, workshopLibraryFilter,
    } = useContext(TimelineContext);
    const [expandedPanel, setExpandedPanel] = useState(false);
    const [open, setOpen] = useState(openAccordion);
    const [hover, setHover] = useState();
    const [selectedItem, setSelected] = useState();
    const [listReordered, setListReordered] = useState(false);
    const [, setSelectedTemplate] = useState();
    if (item.type === 'category' && item.subCatData && isTimeline) {
        item.items = item.items.filter((i) => !i.sub_category);
    }

    if (item.children) {
        item.children = sortedObject(item.children);
    }

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpandedPanel(isExpanded ? panel : false);
        setOpen(!open);
    };

    const { selectedOrg } = useContext(OrgContext);
    function gotToTimeline(id) {
        window.open(`/${selectedOrg.orgCode}/timeline/${id}/`, '_blank');
    }

    let hoverFlag = false;
    if (selectedItem && workshopComponent && workshopComponent.type === 'Column'
        && workshopComponent.props.components.length === 0
        && workshopComponent.props.progressions.length === 0) {
        hoverFlag = true;
    }
    const topSuper = itemKey === 'Header' && item.type === 'group' || itemKey === 'Column' && item.type === 'group';
    if (itemKey) {
        if (itemKey === 'Form Components') {
            itemKey = 'Components';
        } else if (itemKey === 'Form Groups') {
            itemKey = 'Components Groups';
        } else if (itemKey === 'Sections') {
            itemKey = 'Page Sections';
        } else if (itemKey === 'Rows') {
            itemKey = 'Page Rows';
        } else if (itemKey === 'Pages') {
            itemKey = 'Page ';
        }
    }

    if (Object?.keys(item?.children).map((childKey) => (item?.children?.[childKey]))) {
        Object.keys(item.children).map((childKey) => (item?.children?.[childKey])).map((item) => {
            if (item.items?.[0]?.children?.length < 1) {
                item.items[0].children = null;
            } else if (item.items?.[0]?.children?.length === 4 && item.items?.[0]?.supergroup !== 'Complex Forms') {
                item.items[0].children[0].children = null;
                item.items[0].children[1].children = null;
                item.items[0].children[2].children = null;
                item.items[0].children[3] = null;
            }
            return item;
        });
    }

    const handleChange = (e, template) => {
        if (e.target.checked) {
            if (!selectedDMWorkshopComponent?.props?.selectedTemplateLibrary) {
                selectedDMWorkshopComponent.props.selectedTemplateLibrary = [];
            }
            selectedDMWorkshopComponent.props.selectedTemplateLibrary.push(template);
        } else {
            const tempIndex = selectedDMWorkshopComponent.props.selectedTemplateLibrary.findIndex(
                (item) => item.template.id === template.template.id
            );
            selectedDMWorkshopComponent.props.selectedTemplateLibrary.splice(tempIndex, 1);
        }
        if (handleSelectDMTL) {
            handleSelectDMTL(selectedDMWorkshopComponent.props.selectedTemplateLibrary);
        }
    };

    // Render Timeline Sub-Category
    const renderSubCatTimeline = () => (
        <>
            {item.type === 'category' && item.subCatData
                && Object.keys(item.subCatData).map((subCatKey) => (
                    <AccordionComp
                        key={subCatKey}
                        level={2}
                        isTimeline
                        onTemplateClick={(timeline) => {
                            gotToTimeline(timeline.id);
                        }}
                        itemKey={subCatKey}
                        item={item.subCatData[subCatKey]}
                        hoverItem={hoverItem}
                        handleSelectDMTL={handleSelectDMTL}
                        isTempLibrarySetting={isTempLibrarySetting}
                    />
                ))}
            {item.type === 'sub_category' && isTimeline && item.items.map((template) => (
                <Box
                    key={template.id}
                    className={classes.detailTemplateCont}
                    style={{ border: isDMRC ? '1px solid #DEE7F8' : '1px solid #6CA1FF1D' }}
                    onClick={() => {
                        if (!hrzViewTLFlag) {
                            if (selectedItem || actionRelation?.type === 'edit') {
                                setPreviousTemplate({ template: workshopComponent });
                            }
                            setSelected(template);
                            onTemplateClick(template);
                        }
                    }}
                    selected={selectedItem === template}
                    onMouseEnter={() => { if (hover !== template && !hrzViewTLFlag) { setHover(template); hoverItem(template); } }}
                    onMouseLeave={() => {
                        if (!hrzViewTLFlag) {
                            setHover(selectedItem ? copyTemplateWithImg(workshopComponent) : null);
                        }
                        if (!hoverFlag) {
                            hoverItem(selectedItem ? copyTemplateWithImg(workshopComponent) : null, false);
                        }
                    }}
                >
                    <Typography className={classes.templateNameCont}>
                        {template.name}
                    </Typography>
                </Box>
            ))}
        </>
    );
    const toggleTimelineTable = () => {
        setShowTimelineTable(true);
        setSelectedComponent(false);
        setManagerTemplate(null);
    };
    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination && !result.combine) {
            return;
        }
        if (result.type === 'children') {
            const children = Object.values(item.children).filter((item) => item.items).flatMap((item) => item.items);
            const allChildren = [];
            const sourceIndex = result.source.index;
            buildTemplateChildren(allChildren, { children });
            if (result.combine && !result.destination) {
                // triggers when dropped over an item with no children
                const sourceParent = allChildren.find((item) => `droppable${item.id}` === result.source.droppableId);
                const destinationParent = allChildren.find((item) => `${item.id}` === result.combine.draggableId);
                const movingItem = sourceParent.children[sourceIndex];
                sourceParent.children = sourceParent.children.filter((_, index) => index !== sourceIndex);

                if (sourceParent === destinationParent) {
                    const { associated_child } = destinationParent;
                    const dest_children = destinationParent.children;
                    const movingUp = associated_child ? associated_child[associated_child.length - 1] === movingItem.id
                        : dest_children[dest_children.length - 1].id === movingItem.id;
                    if (movingUp) {
                        // move up
                        destinationParent.children = [movingItem, ...destinationParent.children];
                    } else {
                        destinationParent.children = [...destinationParent.children, movingItem];
                        // move down
                    }
                    movingItem.parent_reference_id = destinationParent.id;
                } else if (!destinationParent.children) {
                    destinationParent.children = [movingItem];
                    movingItem.parent_reference_id = destinationParent.id;
                } else {
                    const { associated_child } = destinationParent;
                    const dest_children = destinationParent.children;
                    const movingUp = associated_child ? associated_child[associated_child.length - 1] === movingItem.id
                        : dest_children[dest_children.length - 1].id === movingItem.id;
                    if (movingUp) {
                        // move up
                        destinationParent.children = [movingItem, ...destinationParent.children];
                    } else {
                        destinationParent.children = [...destinationParent.children, movingItem];
                        // move down
                    }
                    movingItem.parent_reference_id = destinationParent.id;
                    sourceParent.associated_child = sourceParent.children.map((item) => item.id);
                    saveTemplate(sourceParent, setSelectedTemplate, true);
                }
                destinationParent.associated_child = destinationParent.children.map((item) => item.id);
                saveTemplate(destinationParent, setSelectedTemplate, true);
                saveTemplate(movingItem, setSelectedTemplate, true);
            } else {
                const destinationIndex = result.destination.index;
                const sourceParent = allChildren.find((item) => `droppable${item.id}` === result.source.droppableId);
                const destinationParent = allChildren.find((item) => `droppable${item.id}` === result.destination.droppableId);
                if (!sourceParent || !destinationParent) {
                    return;
                }
                if (sourceParent === destinationParent) {
                    // we are reordering inside the same parent
                    destinationParent.children = reorder(
                        destinationParent.children,
                        sourceIndex,
                        destinationIndex
                    );
                    destinationParent.associated_child = destinationParent.children.map((item) => item.id);
                    saveTemplate(destinationParent, setSelectedTemplate, true);
                } else {
                    // moving between parents
                    const movingItem = sourceParent.children[sourceIndex];
                    // remove from the parent
                    sourceParent.children = sourceParent.children.filter((_, index) => index !== sourceIndex);
                    // insert into the new parent
                    if (!destinationParent.children) {
                        destinationParent.children = [];
                    }
                    destinationParent.children = [...destinationParent.children.slice(0, destinationIndex),
                        movingItem, ...destinationParent.children.slice(destinationIndex)];
                    movingItem.parent_reference_id = destinationParent.id;
                    sourceParent.associated_child = sourceParent.children.map((item) => item.id);
                    saveTemplate(sourceParent, setSelectedTemplate, true);
                    destinationParent.associated_child = destinationParent.children.map((item) => item?.id);
                    saveTemplate(destinationParent, setSelectedTemplate, true);
                    saveTemplate(movingItem, setSelectedTemplate, true);
                }
            }
        } else if (result.destination) {
            const children = Object.values(item.children).filter((item) => item.items);
            const movedUp = result.destination.index < result.source.index;
            for (let i = 0; i < children.length; i += 1) {
                const child = children[i];
                if (child.index === result.source.index) {
                    child.index = result.destination.index;
                } else if (child.index >= result.destination.index && movedUp) {
                    child.index += 1;
                } else if (child.index <= result.destination.index && !movedUp) {
                    child.index -= 1;
                }
                const templateRef = child?.items?.[0];
                if (templateRef) {
                    templateRef.sort_index = child.index;
                    saveTemplate(templateRef, setSelectedTemplate, true);
                }
            }
            setListReordered((value) => !value);
        }
    };
    const superComponents = useMemo(() => (item.children && item.children.hasSuperComponents
        ? Object.keys(item.children)
            .filter((item) => item !== 'hasSuperComponents')
            .sort((a, b) => item.children[a].index - item.children[b].index) : []), [item.children, listReordered]);
    return itemKey === 'null' && !isTimeline ? (
        ''
    ) : (
        <React.Fragment>
            <Accordion
                className={isDMRC || hrzViewTLFlag ? classes.accordionStyle1 : classes.accordionStyle}
                classes={{
                    root: classes.rootAccordion,
                }}
                defaultExpanded={open}
                expanded={expandedPanel === 'panel1a' || open}
                onChange={handleAccordionChange('panel1')}
            >
                {itemKey !== 'null' && (
                    <AccordionSummary
                        expandIcon={
                            expandedPanel === 'panel1' || open ? (
                                <ExpandLessIcon style={{
                                    background: topSuper && !isPopup ? '#1C1C1C' : (level === 1 && !isTempLibrarySetting)
                                        || itemKey === 'Headers' || isPopup ? '#6d6d6d'
                                        : (level === 1 && isTempLibrarySetting) || level === 2 ? '#b1b1b1' : '#FFFFFF',
                                    color: topSuper && !isPopup ? '#FFF' : '#1C1C1C',
                                }}
                                />
                            ) : (
                                <ChevronRightIcon style={{
                                    background: topSuper && !isPopup ? '#1C1C1C' : (level === 1 && !isTempLibrarySetting)
                                        || itemKey === 'Headers' || isPopup ? '#6d6d6d'
                                        : (level === 1 && isTempLibrarySetting) || level === 2 ? '#b1b1b1' : '#FFFFFF',
                                    color: topSuper && !isPopup ? '#FFF' : '#1C1C1C',
                                }}
                                />
                            )
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className={`${classes.sectionHeader} ${classes.accordionCompAccordionSummary}`}
                        classes={{

                            expandIcon: classes.iconButton,
                            content: topSuper && !isPopup ? classes.headerColumnContent : isDMRC && level === 1
                                || itemKey === 'Headers' || isPopup ? classes.fontSize13PX1
                                : level === 1 ? classes.fontSize13PX
                                    : isDMRC && level === 2 ? classes.fontSize12PX1
                                        : level === 2 ? classes.fontSize12PX : classes.fontSize11PX,
                        }}
                        style={{
                            background: topSuper && !isPopup ? '#1C1C1C' : (level === 1 && !isTempLibrarySetting)
                                || itemKey === 'Headers' ? '#6d6d6d' : (level === 1 && isTempLibrarySetting) || level === 2 ? '#b1b1b1'
                                    : isPopup ? '#6d6d6d' : '#FFFFFF',
                            paddingRight: isDMRC && item.type === 'supergroup' && itemKey === 'Headers'
                                || isDMRC && item.type === 'supergroup' && itemKey === 'Column' ? '10%' : 0,
                            border: isDMRC ? '1px solid #DEE7F8' : '1px solid #6CA1FF1D',
                            height: isTempLibrarySetting && 26,
                            marginLeft: isTempLibrarySetting && 0,
                        }}
                    >
                        {itemKey}
                    </AccordionSummary>
                )}
                <AccordionDetails className={`${classes.accCompAccordionDetail} ${(isPopup && itemKey === 'Headers') && classes.accCompDetails}`}>
                    {expandedPanel === 'panel1a' || open ? (
                        <>
                            {item.children && !item.children.hasSuperComponents
                                && Object.keys(item.children).map((childKey) => (
                                    <AccordionComp
                                        key={childKey}
                                        level={level + 1}
                                        isTimeline={isTimeline}
                                        openAccordion={isRClickDM || openAccordion || (tlAccordionData?.length > 0 && tlAccordionData.find(
                                            (tl) => tl.id === item.id
                                        )
                                        )}
                                        onTemplateClick={onTemplateClick}
                                        item={item.children[childKey]}
                                        itemKey={childKey}
                                        hoverItem={hoverItem}
                                        handleSelectDMTL={handleSelectDMTL}
                                        isRClickDM={isRClickDM}
                                        isTempLibrarySetting={isTempLibrarySetting}
                                    />
                                ))}
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId={`droppable-${itemKey}`}>
                                    {(provided) => (
                                        <div {...provided.droppableProps} ref={provided.innerRef} className={classes.droppableCont}>
                                            {superComponents.map((child, index) => (
                                                <Fragment key={child}>
                                                    {
                                                        item.children[child]?.items?.map((template) => (
                                                            <Draggable key={template.id} draggableId={template.id} index={index}>
                                                                {(draggbaleProvided) => (
                                                                    <div
                                                                        ref={draggbaleProvided.innerRef}
                                                                        {...draggbaleProvided.draggableProps}
                                                                        {...draggbaleProvided.dragHandleProps}
                                                                    >
                                                                        <ItemAccordion
                                                                            level={level}
                                                                            selectedItem={selectedItem}
                                                                            setSelected={setSelected}
                                                                            onTemplateClick={onTemplateClick}
                                                                            hover={hover}
                                                                            setHover={setHover}
                                                                            hoverItem={hoverItem}
                                                                            hoverFlag={hoverFlag}
                                                                            isTimeline={isTimeline}
                                                                            isRClickDM={isRClickDM}
                                                                            template={template}
                                                                            isTempLibrarySetting={isTempLibrarySetting}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        ))
                                                    }
                                                </Fragment>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            {renderSubCatTimeline()}
                            {item.items
                                && item.items[0]?.sub_category !== null && !isTimeline
                                && item.items[0]?.sub_category !== item?.items[1]?.sub_category
                                ? (
                                    <>
                                        {item.type === 'category'
                                            && item.items.map((template) => (
                                                <Fragment key={template.id}>
                                                    <Box
                                                        className={classes.subCategoryCont}
                                                        style={{ border: isDMRC ? '1px solid #DEE7F8' : '1px solid #6CA1FF1D' }}
                                                    >
                                                        <Typography className={isDMRC ? classes.subCategoryText1 : classes.subCategoryText}>
                                                            {template.sub_category}
                                                        </Typography>
                                                    </Box>
                                                    <Box
                                                        className={classes.detailTemplateCont}
                                                        style={{ border: isDMRC ? '1px solid #DEE7F8' : '1px solid #6CA1FF1D' }}
                                                        onClick={() => {
                                                            if (!hrzViewTLFlag) {
                                                                if (selectedItem || actionRelation?.type === 'edit') {
                                                                    setPreviousTemplate({ template: workshopComponent });
                                                                }
                                                                setSelected(template);
                                                                onTemplateClick(template);
                                                            }
                                                        }}
                                                        selected={selectedItem === template}
                                                        onMouseEnter={() => {
                                                            if (hover !== template && !hrzViewTLFlag) {
                                                                setHover(template); hoverItem(template);
                                                            }
                                                        }}
                                                        onMouseLeave={() => {
                                                            if (!hrzViewTLFlag) {
                                                                setHover(selectedItem ? copyTemplateWithImg(workshopComponent) : null);
                                                            }
                                                            if (!hoverFlag) {
                                                                hoverItem(selectedItem ? copyTemplateWithImg(workshopComponent) : null, false);
                                                            }
                                                        }}
                                                    >
                                                        <div className={isDMRC ? classes.checkBoxCont : classes.templateNameCont}>
                                                            {isDMRC && !isRClickDM && (
                                                                <Checkbox
                                                                    className={classes.checkBoxStyle}
                                                                    checked={!!selectedDMWorkshopComponent?.props?.selectedTemplateLibrary?.find(
                                                                        (i) => i?.template?.id === template?.template?.id)}
                                                                    onChange={(e) => handleChange(e, template)}
                                                                />
                                                            )}
                                                            <Typography className={classes.fontSize10PX}>
                                                                {template.name}
                                                            </Typography>
                                                        </div>
                                                        {!isTimeline && !isRClickDM && (
                                                            <LightTooltip
                                                                placement="top-start"
                                                                title={React.createElement(
                                                                    ComponentPreview[template.template.type],
                                                                    { component: template.template }
                                                                )}
                                                            >
                                                                <Box className={classes.marginRight10Px}>
                                                                    <Info className={classes.infoIconStyle} />
                                                                </Box>
                                                            </LightTooltip>
                                                        )}
                                                    </Box>
                                                </Fragment>
                                            ))}
                                    </>
                                )
                                : (
                                    <>
                                        {item.type === 'category' && !isTimeline && item.items
                                            && item.items[0]?.sub_category && item.items[0]?.sub_category !== null
                                            && (
                                                <Box
                                                    className={classes.subCategoryCont}
                                                    style={{ border: isDMRC ? '1px solid #DEE7F8' : '1px solid #6CA1FF1D' }}
                                                >
                                                    <Typography className={isDMRC ? classes.subCategoryText1 : classes.subCategoryText}>
                                                        {item.items[0].sub_category}
                                                    </Typography>
                                                </Box>
                                            )}
                                        <DragDropContext>
                                            {item.type === 'category'
                                                && item.items.map((template) => (
                                                    <ItemAccordion
                                                        key={template.id}
                                                        disableDnd
                                                        level={level}
                                                        selectedItem={selectedItem}
                                                        setSelected={setSelected}
                                                        onTemplateClick={onTemplateClick}
                                                        hover={hover}
                                                        setHover={setHover}
                                                        hoverItem={hoverItem}
                                                        hoverFlag={hoverFlag}
                                                        isTimeline={isTimeline}
                                                        isRClickDM={isRClickDM}
                                                        template={template}
                                                        handleSelectDMTL={handleSelectDMTL}
                                                        isTempLibrarySetting={isTempLibrarySetting}
                                                    />
                                                ))}
                                        </DragDropContext>
                                    </>
                                )}
                            {itemKey === 'Images/Videos' && !workshopLibraryFilter && (
                                <Box className={classes.itemAccdetailTemplateCont}>
                                    <NavLink to={`/${selectedOrg.orgCode}/media/`} className={classes.customNavlink}>
                                        <Accordion>
                                            <AccordionSummary
                                                className={`${classes.sectionHeader} 
                                                ${classes.accordionCompAccordionSummary1} ${classes.itemAccordionSummaryStyle}`}
                                            >
                                                <Typography className={classes.templateNameCont}>
                                                    {'\u00A0'}
                                                    {'\u00A0'}
                                                    {'\u00A0'}
                                                    {'\u00A0'}
                                                    {'\u00A0'}
                                                    Media
                                                </Typography>
                                            </AccordionSummary>
                                        </Accordion>
                                    </NavLink>
                                </Box>
                            )}
                            {itemKey === 'Complex Forms' && !workshopLibraryFilter && (
                                <div className={classes.templateNameCont}>
                                    <Typography onClick={toggleTimelineTable} className={classes.timelineOrgText}>
                                        Timeline Organization
                                    </Typography>
                                </div>
                            )}
                        </>
                    ) : null}
                </AccordionDetails>
            </Accordion>
        </React.Fragment>
    );
};

const columnHeaderAccordion = (
    templates,
    onTemplateClick,
    openAccordion,
    classes,
    hoverItem,
    selectedDMWorkshopComponent = null,
    isPopup = null,
    handleSelectDMTL = null,
    isRClickDM = null,
    isTempLibrarySetting,
) => {
    templates = templates.filter(
        (item) => item?.supergroup === 'Header' || item?.supergroup === 'Column'
    );
    const templateData = templates.reduce((superGroup, superGroupVal) => {
        superGroup = groupBy(superGroupVal?.supergroup, superGroup, superGroupVal, {
            type: 'supergroup',
        });
        superGroup[superGroupVal?.supergroup].children = superGroup[
            superGroupVal?.supergroup
        ].items.reduce((group, groupVal) => {
            group = groupBy(groupVal?.group, group, groupVal, { type: 'group' });
            return group;
        }, {});
        return superGroup;
    }, {});
    Object.keys(templateData).forEach((superGroupKey) => {
        const superGroup = templateData[superGroupKey];
        Object.keys(superGroup.children).forEach((groupKey) => {
            const group = superGroup.children[groupKey];
            // const output = [];
            const parentRefItems = group.items.filter((item) => item.parent_reference_id);
            const groupedByParentRefItems = parentRefItems.reduce((acc, curr) => {
                const parentRefId = curr.parent_reference_id;
                acc[parentRefId] = acc[parentRefId] || [];
                acc[parentRefId] = [...acc[parentRefId], curr];
                return acc;
            }, {});

            if (Object.keys(groupedByParentRefItems).length > 0) {
                // eslint-disable-next-line no-unused-vars
                for (const item of parentRefItems) {
                    // nested items with children of their own
                    if (groupedByParentRefItems[item.id]) { item.children = groupedByParentRefItems[item.id]; }
                }
            }

            const topLevelItems = group.items.filter(
                (item) => !item.parent_reference_id && item.category === 'Super components'
            ).sort((a, b) => a.sort_index - b.sort_index);
            group.children = topLevelItems.reduce((cat, catVal, index) => {
                cat = groupBy(catVal.name, cat, catVal, { type: 'supergroupcomp', index });
                const nestedChildren = parentRefItems.filter((item) => item.parent_reference_id === catVal.id);
                if (nestedChildren.length > 0) {
                    if (catVal.associated_child && catVal.associated_child.length === nestedChildren.length) {
                        catVal.children = catVal?.associated_child.map((itemId) => nestedChildren.find((item) => item.id === itemId));
                    } else {
                        catVal.children = nestedChildren;
                    }
                }
                return cat;
            }, { hasSuperComponents: true });
        });
    });

    return (
        <List style={{
            margin: 0, paddingTop: 0, paddingBottom: 0, display: (isPopup ? 'flex' : null), width: isPopup ? '200%' : '100%',
        }}
        >
            {Object.keys(templateData)
                .sort()
                .map((superGroupKey) => {
                    // const superGroup = isPopup ? templateData[superGroupKey]?.children?.Headers : templateData[superGroupKey];
                    const superGroup = (superGroupKey === 'Header' ? templateData[superGroupKey]?.children?.Headers
                        : templateData[superGroupKey]?.children?.Column);
                    return (
                        <AccordionComp
                            key={superGroupKey}
                            openAccordion={((selectedDMWorkshopComponent?.type === 'Text' && superGroupKey === 'Header')
                                || isRClickDM) ? true : openAccordion}
                            onTemplateClick={onTemplateClick}
                            itemKey={superGroupKey}
                            item={superGroup}
                            hoverItem={hoverItem}
                            handleSelectDMTL={handleSelectDMTL}
                            // level={isPopup ? 1 : 0}
                            level={0}
                            isRClickDM={isRClickDM}
                            isPopup={isPopup}
                            isTempLibrarySetting={isTempLibrarySetting}
                        />
                    );
                })}
        </List>
    );
};

const templateListAccordion = (
    templates,
    onTemplateClick,
    open,
    classes,
    expandedPanel,
    handleAccordionChange,
    hoverItem,
    selectedDMWorkshopComponent = null,
    isDMRC,
    handleSelectDMTL = null,
    isPopup = false,
    isRClickDM = null,
    imageAcc = null,
    isTempLibrarySetting,
) => {
    let selectedDMWorkshopComponentKey = null;
    templates = templates.filter(
        (item) => item?.supergroup !== 'Header' && item?.supergroup !== 'Column'
    );

    const templateData = templates.reduce((superGroup, superGroupVal) => {
        superGroup = groupBy(superGroupVal?.supergroup, superGroup, superGroupVal, {
            type: 'supergroup',
        });
        superGroup[superGroupVal?.supergroup].children = superGroup[
            superGroupVal?.supergroup
        ].items.reduce((group, groupVal) => {
            group = groupBy(groupVal?.group, group, groupVal, { type: 'group' });
            return group;
        }, {});
        return superGroup;
    }, {});
    tlGroupByData = templateData ? { ...templateData } : tlGroupByData;
    Object.keys(templateData).forEach((superGroupKey) => {
        const superGroup = templateData[superGroupKey];
        Object.keys(superGroup.children).forEach((groupKey) => {
            const group = superGroup.children[groupKey];
            // const output = [];
            const parentRefItems = group.items.filter((item) => item?.parent_reference_id);
            const groupedByParentRefItems = parentRefItems.reduce((acc, curr) => {
                const parentRefId = curr?.parent_reference_id;
                acc[parentRefId] = acc[parentRefId] || [];
                acc[parentRefId] = [...acc[parentRefId], curr];
                return acc;
            }, {});

            if (Object.keys(groupedByParentRefItems).length > 0) {
                // eslint-disable-next-line no-unused-vars
                for (const item of parentRefItems) {
                    // nested items with children of their own
                    if (groupedByParentRefItems[item.id]) { item.children = groupedByParentRefItems[item.id]; }
                }
            }

            const topLevelItems = group.items.filter(
                (item) => !item?.parent_reference_id && item?.category === 'Super components'
            ).sort((a, b) => a.sort_index - b.sort_index);
            group.children = topLevelItems.reduce((cat, catVal, index) => {
                cat = groupBy(catVal.name, cat, catVal, { type: 'supergroupcomp', index });
                const nestedChildren = parentRefItems.filter((item) => item?.parent_reference_id === catVal.id);
                if (nestedChildren.length > 0) {
                    if (catVal?.associated_child && catVal?.associated_child.length === nestedChildren.length) {
                        catVal.children = catVal?.associated_child.map((itemId) => nestedChildren.find((item) => item.id === itemId));
                    } else {
                        catVal.children = nestedChildren;
                    }
                }
                return cat;
            }, { hasSuperComponents: true });
            if (groupKey !== 'null') {
                templateData[superGroupKey].superGroupChildren = templateData[superGroupKey]?.superGroupChildren
                    ? { ...templateData[superGroupKey].superGroupChildren, ...group.children }
                    : { ...group.children };
            }
        });
        templateData[superGroupKey].children = { ...templateData[superGroupKey]?.superGroupChildren };
    });
    if (Object.keys(templateData).length === 0) {
        return null;
    }

    if (selectedDMWorkshopComponent) {
        selectedDMWorkshopComponentKey = selectedDMWorkshopComponent.libraryFilter || selectedDMWorkshopComponent.name;
    }

    const childElement = (Object.keys(templateData).map((superGroupKey) => {
        const superGroup = templateData[superGroupKey];
        if (superGroupKey === 'Row' || superGroupKey === 'Section' || superGroupKey === 'Page'
            || superGroupKey === 'Protocol Only' || superGroupKey === 'Complex Forms1') {
            return null;
        }
        return (
            <AccordionComp
                key={superGroupKey}
                level={1}
                // openAccordion={open}
                openAccordion={(selectedDMWorkshopComponentKey === superGroupKey
                    || isRClickDM || (superGroupKey === 'Images/Videos' && imageAcc)) ? true : open}
                onTemplateClick={onTemplateClick}
                itemKey={superGroupKey}
                item={superGroup}
                hoverItem={hoverItem}
                handleSelectDMTL={handleSelectDMTL}
                isRClickDM={isRClickDM}
                isPopup={isPopup}
                isTempLibrarySetting={isTempLibrarySetting}
            />
        );
    }));

    if (isPopup) {
        return childElement;
    }

    return (
        <>
            {isTempLibrarySetting ? childElement
                : (
                    <Accordion
                        className={isDMRC ? classes.accordionStyle1 : classes.accordionStyle}
                        style={{ borderBottom: isDMRC ? '1px solid #6CA1FF1D' : 'none' }}
                        expanded={expandedPanel === 'panel1a' || (isRClickDM && open === undefined)
                            || (!isDMRC && open) || imageAcc}
                        onChange={handleAccordionChange('panel1a')}
                    >
                        <AccordionSummary
                            expandIcon={
                                expandedPanel === 'panel1a' || (isRClickDM && open === undefined)
                                    || (!isDMRC && open) || imageAcc
                                    ? (
                                        <ExpandLessIcon className={classes.accordionIconStyle} />
                                    ) : (
                                        <ChevronRightIcon className={classes.accordionIconStyle} />
                                    )
                            }
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            className={`${classes.sectionHeader} ${classes.templateListAccordionSummary}`}
                            classes={{
                                expandIcon: classes.iconButton,
                                content: isDMRC ? classes.accordionSummaryText1 : classes.accordionSummaryText,
                                // expanded: classes.expandedColor,
                            }}
                        >
                            Protocol/Content
                        </AccordionSummary>
                        <AccordionDetails className={classes.templateListAccordionDetail}>
                            {childElement}
                        </AccordionDetails>
                    </Accordion>
                )}
        </>
    );
};

const TemplateBrowser = ({
    templates,
    hoverItem = null,
    selectItem = null,
    showTimeLine,
    openAccordion,
    showLoader,
    isPopup = null,
    handleSelectDMTL = null,
    isRClickDM = null,
    isTempLibrarySetting,
}) => {
    const {
        filterTemplateBrowser, selectedDMWorkshopComponent, isDMRC,
    } = useContext(TimelineContext);
    const { search } = useLocation();
    const [imageAcc, setImageAcc] = useState(new URLSearchParams(search).get('imageAcc'));
    // const [hover, setHover] = useState();
    const [, setSelected] = useState();
    const [expandedPanel, setExpandedPanel] = useState(selectedDMWorkshopComponent?.type === 'Protocol' && isDMRC ? 'panel1a' : false);
    const [open, setOpen] = useState(openAccordion);
    const classes = useRowStyles({ level: 0 });
    const history = useHistory();
    const { selectedOrg } = useContext(OrgContext);
    if (!templates) {
        return showLoader(true);
    }

    // const renderTemplate = (template) => {
    //     if (!template) {
    //         return null;
    //     }
    //     let targetType = template.template.type;

    //     if (template.type === 'Section') {
    //         targetType = 'Section';
    //     }

    //     return (
    //         <ListItem
    //             key={template.id}
    //             button
    //             selected={selectedItem === template}
    //             onClick={() => {
    //                 if (!hrzViewTLFlag) {
    //                     setSelected(template);
    //                     selectItem(template);
    //                 }
    //             }}
    //             onMouseEnter={() => {
    //                 if (hover !== template && !hrzViewTLFlag) {
    //                     setHover(template);
    //                     hoverItem(template);
    //                 }
    //             }}
    //             onMouseLeave={() => {
    //                 if (!hrzViewTLFlag) {
    //                     setHover();
    //                     hoverItem();
    //                 }
    //             }}
    //             style={{ padding: '8px 5px' }}
    //         >
    //             <ListItemText
    //                 primary={(
    //                     <>
    //                         {React.createElement(
    //                             ComponentPreview[targetType],
    //                             { component: template.template }
    //                         )}
    //                     </>
    //                 )}
    //             />
    //         </ListItem>
    //     );
    // };

    // let currentGroup = '';
    // let currentTemplateName = '';

    // const templateList = () => (
    //     <List>
    //         {templates.map((template) => {
    //             if (!template) {
    //                 return null;
    //             }
    //             let header = '';
    //             let templateName = '';
    //             if (currentGroup !== template.group) {
    //                 currentGroup = template.group;
    //                 if (!currentGroup) {
    //                     currentGroup = 'General';
    //                 }
    //                 header = (
    //                     <ListSubheader disableSticky={false} className={classes.header}>
    //                         <Typography className={classes.columHeadType} color="inherit">
    //                             {currentGroup}
    //                         </Typography>
    //                     </ListSubheader>
    //                 );
    //             }
    //             if (currentTemplateName !== template.name) {
    //                 currentTemplateName = template.name;
    //                 templateName = currentTemplateName;
    //             }
    //             return (
    //                 <React.Fragment key={template.id}>
    //                     {header}
    //                     <div style={{ textDecoration: 'underline' }}>{templateName}</div>
    //                     {renderTemplate(template, header)}
    //                 </React.Fragment>
    //             );
    //         })}
    //     </List>
    // );

    const noTemplates = () => <div>No Templates</div>;
    const onTemplateClick = (template) => {
        setSelected(template);
        if (selectItem) selectItem(template);
    };

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        const openFlag = imageAcc ? true : open;
        const isExpandedVal = imageAcc ? false : isExpanded;
        if (imageAcc) {
            history.push(`/${selectedOrg.orgCode}/templates`);
            setImageAcc(null);
        }
        setExpandedPanel(isExpandedVal ? panel : false);
        setOpen(!openFlag);
    };

    return (
        <>
            {(showTimeLine && !filterTemplateBrowser && !isDMRC) && <TimelineAccordion hoverItem={hoverItem} isPopup={isPopup} />}
            {!(!templates || templates.length === 0)
                && columnHeaderAccordion(
                    templates,
                    onTemplateClick,
                    openAccordion,
                    classes,
                    hoverItem,
                    selectedDMWorkshopComponent,
                    isPopup,
                    handleSelectDMTL,
                    isRClickDM,
                    isTempLibrarySetting,
                )}
            {!templates || templates.length === 0
                ? noTemplates()
                : templateListAccordion(
                    templates,
                    onTemplateClick,
                    open,
                    classes,
                    expandedPanel,
                    handleAccordionChange,
                    hoverItem,
                    selectedDMWorkshopComponent,
                    isDMRC,
                    handleSelectDMTL,
                    isPopup,
                    isRClickDM,
                    imageAcc,
                    isTempLibrarySetting,
                )}
        </>
    );
};

export { TemplateBrowser };
