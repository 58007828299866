import { makeStyles } from '@material-ui/core/styles';
import { standardHeaders } from './utils/StandardStyles';
import React from 'react';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
    ...standardHeaders,
    headerContainer: (props) => ({
        cursor: props.isButton ? 'pointer' : 'initial',
        margin: '4px 4px 4px 0px',
        fontSize: 15,
    }),
}));

const StandardHeader = ({
    type, children, onClick, isButton,
}) => {
    const classes = useStyles({ isButton });

    const style = classes[type];

    return (
        <div aria-hidden="true" onClick={onClick} className={classes.headerContainer}>
            <Typography variant="body1" color="inherit" className={style}>
                {children}
            </Typography>
        </div>
    );
};

export default StandardHeader;
