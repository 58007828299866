import {
    Accordion,
    AccordionDetails,
    AccordionSummary, Box, FormControlLabel, Grid, Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { OrgContext } from '../context';
import { ProcedureLibrary } from '../initialConfig';
import { ProcedureSelector, handleSelectionChange } from './ProcedureSelector';
import { checkEmptyObject } from '../helper/commonHelper';
import { makeStyles } from '@material-ui/core/styles';
import { standardHeaders } from './utils/StandardStyles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import React, { useContext, useEffect, useState } from 'react';

const useRowStyles = makeStyles((theme) => ({
    poiAccordionDetails: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(130px, 1fr))',
        background: '#E0E0E0',
        textAlign: 'left',
    },
    inlineContainer: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        width: '100%',
    },
    column: {
        paddingLeft: 5,
        border: '1px solid #ececec',
    },
    sectionHeader: {
        border: '1px solid #ececec',
        padding: '4px',
        background: 'lightgrey',
    },
    txtBtn: {
        fontSize: '0.75rem',
        padding: '2px',
    },
    checkBoxColor: {
        color: '#000000',
    },
    FormControlLabel: {
        marginLeft: 0,
        marginTop: 1,
        fontSize: 13,
    },
    poupConHeading: {
        padding: '8px 12px 6px 12px',
        '& h6': {
            fontWeight: 700,
        },
    },
    poupConHeadingMeddle: {
        padding: 3,
    },
    procedureItemCont: {
        flexDirection: 'column',
        display: 'flex',
        maxHeight: '86vh',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    procedureItemCont1: {
        flexDirection: 'column',
        display: 'flex',
        maxHeight: '86vh',
        paddingLeft: 1,
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    paddSectiSpace: {
        paddingTop: 3,
        background: '#E8E8E8 0% 0% no-repeat padding-box',
        [theme.breakpoints.down('xs')]: {
            width: '50%',
        },
    },
    saveUpButton: {
        width: '75px',
        height: '40px',
        backgroundColor: '#066785 !important',
        color: '#FFFFFF',
        fontSize: '14px',
        marginLeft: '1.5rem !important',
        textTransform: 'capitalize',
    },
    poupConBoottom: {
        padding: '9px 22px',
    },
    paddSpac: {
        padding: '4px',
        transform: 'scale(.75)',
    },
    iconButton: {
        margin: '0 8px 0 2px',
        padding: 0,

    },
    sectionHeaderAcc: {
        margin: 0,
        minHeight: 'auto !important',
        height: 32,
        border: '1px solid #ececec',
        ...standardHeaders.sectionHeader,
        position: 'relative',
        background: '#efefef',
        color: '#000',
    },
    childCheckBox: {
        padding: '6px',
        transform: 'scale(.65)',
    },
    checkboxLableStyle: {
        marginTop: 2,
    },
    rightAlign: {
        textAlign: 'end',
    },
    closeIcon: {
        cursor: 'pointer',
        verticalAlign: 'sub',
        marginTop: 4,
    },
    selectAllCont: {
        background: '#d8d8d8',
        marginBottom: 2,
        padding: '2px 20px 2px 0px',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    procedureAccCont: {
        marginBottom: '1px',
    },
    libraryPopupMobileStyle: {
        [theme.breakpoints.down('xs')]: {
            width: '50%',
        },
    },
    procedureLibraryMobileStyle: {
        '& div': {
            '&:nth-child(3)': {
                '& div': {
                    '&:nth-child(1)': {
                        [theme.breakpoints.down('xs')]: {
                            margin: '1px 0px',
                        },
                    },
                },
            },
        },
    },
}));

const ProcedureDialog = ({
    onSelectionMade,
    isWorkshop,
    selectedPOI = {},
    componentParentPOI = {},
    explicitOpen = false,
    isFilter,
    initialSelectAll,
    filterList,
    handleClickOpenPOI = null,
}) => {
    const {
        userPoi,
        loggedUserType,
    } = useContext(OrgContext);
    const [selected, setSelected] = useState(isWorkshop ? selectedPOI : {});
    const [selectedParent] = useState(isWorkshop ? (checkEmptyObject(componentParentPOI) ? null : componentParentPOI) : null);
    const nothingSelected = Object.keys(selected).every((child) => !selected[child]);
    const nothingSelectedParent = filterList && Object.keys(filterList).every((child) => !filterList[child]);
    const classes = useRowStyles();
    const [open, setOpen] = React.useState(explicitOpen);

    let procedureLibraryChildren = [];
    if (loggedUserType !== 'Super Admin' && userPoi) {
        procedureLibraryChildren = JSON.parse(
            JSON.stringify(ProcedureLibrary.children)
        ).filter((poi) => Object.prototype.hasOwnProperty.call(userPoi, poi.id) && userPoi[poi.id]);
        procedureLibraryChildren.forEach((poi) => {
            poi.children = poi.children.filter((subPoi) => Object.prototype.hasOwnProperty.call(userPoi, subPoi.id) && userPoi[subPoi.id]);
        });
    }

    const [dirty, setDirty] = useState(false);

    const handleClose = ({ cancel }) => {
        if (cancel) {
            setSelected(selectedPOI);
            if (handleClickOpenPOI) handleClickOpenPOI();
            setOpen(false);
            if (!initialSelectAll) {
                onSelectionMade(selected, cancel);
            }
            return;
        }
        setDirty(true);
        if (nothingSelected) {
            if (!isFilter) return;
            const flatChildren = ProcedureLibrary.children.flatMap((item) => item.children);
            const flatList = [...flatChildren, ...ProcedureLibrary.children];
            const items = flatList.reduce((acc, item) => ({ ...acc, [item.id]: true }), {});
            onSelectionMade(items);
            setOpen(false);
            if (handleClickOpenPOI) handleClickOpenPOI();
            return;
        }
        onSelectionMade(selected, cancel);
        if (handleClickOpenPOI) handleClickOpenPOI();
        setOpen(false);
    };
    const selectMultiple = (list, checked) => {
        const items = list.reduce((acc, item) => ({ ...acc, [item.id]: checked }), {});
        setSelected({ ...selected, ...items });
    };

    useEffect(() => {
        setSelected(selectedPOI);
    }, [selectedPOI]);

    useEffect(() => {
        const flatChildren = ProcedureLibrary.children.flatMap((item) => item.children);
        const flatList = [...flatChildren, ...ProcedureLibrary.children];
        if (Object.keys(selected).length === 0 && initialSelectAll) {
            selectMultiple(flatList, true);
        }
    }, [initialSelectAll, selected]);

    const onSelectionChange = (level, checked, firstLevel) => {
        setDirty(true);
        if (isWorkshop) {
            if (firstLevel) {
                const levelChildren = (!checkEmptyObject(selectedPOI)
                    && level.children.filter((l) => Object.prototype.hasOwnProperty.call(selectedPOI, [l.id]))) || level.children;
                const childrenArr = levelChildren.reduce(
                    (acc, item) => ({ ...acc, [item.id]: filterList ? filterList[item.id] ? checked : false : checked }), {}
                );
                setSelected({ ...selected, [level.id]: checked, ...childrenArr });
            } else {
                setSelected({ ...selected, [level.id]: checked });
            }
        } else {
            setSelected(handleSelectionChange(selected, level, checked));
        }
    };

    useEffect(() => {
        if (Object.keys(selectedPOI).length > 0) {
            setSelected(selectedPOI);
        }
    }, [selectedPOI]);

    if (!open && explicitOpen) {
        setOpen(explicitOpen);
    }

    return (
        <div>
            <Dialog className={classes.procedureLibraryMobileStyle} maxWidth={isWorkshop ? 'xl' : 'sm'} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle className={classes.poupConHeading} id="form-dialog-title">
                    <Grid container>
                        <Grid item xs={10}>
                            <Typography variant="h6">Procedure Library</Typography>
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            className={classes.rightAlign}
                        >
                            <CloseIcon className={classes.closeIcon} onClick={() => handleClose({ cancel: true })} />
                        </Grid>
                    </Grid>
                </DialogTitle>
                <Divider />
                <DialogContent className={classes.poupConHeadingMeddle}>
                    {
                        nothingSelectedParent && (
                            <Alert severity="error">
                                Please select POI for Parent first.
                            </Alert>
                        )
                    }
                    {
                        isWorkshop
                            ? (
                                <ProcedureSelectorGrid
                                    filterList={filterList}
                                    selected={selected}
                                    selectedParent={selectedParent}
                                    library={loggedUserType !== 'Super Admin' && userPoi
                                        ? { ...ProcedureLibrary, children: procedureLibraryChildren } : ProcedureLibrary}
                                    onSelectionChange={onSelectionChange}
                                    selectMultiple={selectMultiple}
                                    isPopup
                                />
                            )
                            : (
                                <ProcedureSelector
                                    library={loggedUserType !== 'Super Admin' && userPoi ? {
                                        ...ProcedureLibrary,
                                        children: procedureLibraryChildren,
                                    } : ProcedureLibrary}
                                    selected={selected}
                                    selectedParent={selectedParent}
                                    onChange={onSelectionChange}
                                />
                            )
                    }
                </DialogContent>
                <Divider />
                <DialogActions className={classes.poupConBoottom}>
                    <Button variant="contained" onClick={() => handleClose({ cancel: true })}>
                        Cancel
                    </Button>
                    <Button onClick={handleClose} className={classes.saveUpButton}>
                        {isWorkshop ? 'Save' : 'Update'}
                    </Button>
                    {nothingSelected && dirty && !isFilter && (
                        <Alert severity="error">
                            Please select at least one POI.
                        </Alert>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    );
};
const ProcedureItem = ({
    level, onSelectionChange, inline, selected, setSelectAll, selectMultiple, filterList, selectedParent,
}) => {
    const classes = useRowStyles();
    const [activeAccordion, setActiveAccordion] = useState(true);
    const isDisabled = (filterList && !filterList[level.id]);
    const handleChange = (event) => {
        onSelectionChange(level, event.target.checked, true);
        if (!event.target.checked) {
            setSelectAll(false);
        }
    };
    const handleChangeChild = (event, child) => {
        if (event.target.checked) {
            selectMultiple([child, level], true);
        } else {
            const allSelected = level.children.filter((item) => selected[item.id]).length;
            if (allSelected < 2) {
                selectMultiple([child, level], false);
            } else {
                onSelectionChange(child, false);
            }
            setSelectAll(false);
        }
    };
    const CheckboxLabel = () => {
        const handleCheckBoxClick = (e) => {
            e.stopPropagation();
        };

        return (
            <FormControlLabel
                className={classes.FormControlLabel}
                classes={{ label: classes.checkboxLableStyle }}
                control={(
                    <Checkbox
                        onClick={handleCheckBoxClick}
                        checked={!!selected[level.id] || false}
                        onChange={handleChange}
                        className={classes.checkBoxColor}
                        disabled={isDisabled || (selectedParent && !selectedParent[level.id])}
                        classes={{ root: classes.paddSpac }}
                    />
                )}
                label={level.title}
            />
        );
    };

    return (
        <Grid container className={classes.procedureAccCont}>

            <Grid className={classes.libraryPopupMobileStyle} sm={inline ? 12 : 6} item style={{ background: inline ? 'transparent' : '#d8d8d8' }}>
                {inline && (
                    <Accordion
                        expanded={activeAccordion}
                        onChange={() => setActiveAccordion(!activeAccordion)}
                        style={{ margin: 0, boxShadow: 'none' }}
                    >
                        <AccordionSummary
                            expandIcon={<ArrowDropDownIcon />}
                            aria-controls="panel1a-content"
                            className={classes.sectionHeaderAcc}
                            classes={{
                                expandIcon: classes.iconButton,
                                expanded: classes.expandedAcc,
                            }}
                        >
                            <CheckboxLabel />
                        </AccordionSummary>
                        <AccordionDetails style={{ padding: '0px' }} className={classes.poiAccordionDetails}>
                            {
                                level.children.map((item, index) => (
                                    <div key={index}>
                                        <FormControlLabel
                                            className={classes.FormControlLabel}
                                            classes={{
                                                label: classes.txtBtn,
                                            }}
                                            control={(
                                                <Checkbox
                                                    checked={!!selected[item.id]}
                                                    onChange={(e) => handleChangeChild(e, item)}
                                                    disabled={(filterList && !filterList[item.id]) || (selectedParent && !selectedParent[item.id])}
                                                    classes={{ root: classes.childCheckBox }}
                                                    className={classes.checkBoxColor}
                                                />
                                            )}
                                            label={item.title}
                                        />
                                    </div>
                                ))
                            }
                        </AccordionDetails>
                    </Accordion>
                )}
                {!inline && <CheckboxLabel />}
            </Grid>
            {!inline && (
                <Grid item className={classes.paddSectiSpace} sm={6}>
                    {
                        level.children.map((item) => (
                            <div>
                                <FormControlLabel
                                    className={classes.FormControlLabel}
                                    control={(
                                        <Checkbox
                                            checked={!!selected[item.id]}
                                            onChange={(e) => handleChangeChild(e, item)}
                                            disabled={(filterList && !filterList[item.id]) || (selectedParent && !selectedParent[item.id])}
                                            classes={{ root: classes.paddSpac }}
                                            className={classes.checkBoxColor}
                                        />
                                    )}
                                    label={item.title}
                                />
                            </div>
                        ))

                    }

                </Grid>
            )}

        </Grid>
    );
};

const ProcedureSelectorGrid = ({
    library, onSelectionChange, inline, selected, selectedParent, selectMultiple, filterList, isPopup,
}) => {
    const firstLevel = library.children || [];
    const classes = useRowStyles();
    const halfLength = firstLevel.length / 2;
    const row1 = firstLevel.slice(0, halfLength);
    const row2 = firstLevel.slice(halfLength);
    const flatChildren = library.children.flatMap((item) => item.children);
    const flatList = [...flatChildren, ...library.children];
    const notSelectedList = flatList.filter((item) => !selected[item.id]);
    const [selectAllDirty, setSelectAllDirty] = useState(false);
    const isDisabled = filterList && notSelectedList.length !== 0 && !selectAllDirty;
    const [selectAll, setSelectAll] = useState(false);

    useEffect(() => {
        setSelectAll(notSelectedList.length === 0);
    }, [notSelectedList]);

    const handleSelectAll = () => {
        selectMultiple(flatList, !selectAll);
        setSelectAllDirty(true);
    };
    return (
        <Grid container>
            {!selectedParent
                && (
                    <Grid sm={12} item className={isPopup ? classes.selectAllCont : null}>
                        {!inline && (
                            <Grid container>
                                <Box>
                                    <FormControlLabel
                                        className={classes.FormControlLabel}
                                        classes={{ label: classes.checkboxLableStyle }}
                                        control={(
                                            <Checkbox
                                                checked={selectAll}
                                                onChange={handleSelectAll}
                                                disabled={isDisabled}
                                                classes={{ root: classes.paddSpac }}
                                                className={classes.checkBoxColor}
                                            />
                                        )}
                                        label="Select All Procedures"
                                    />
                                </Box>
                            </Grid>
                        )}

                    </Grid>
                )}
            {inline && (
                <Box className={classes.inlineContainer}>
                    {firstLevel.map((level, index) => (
                        <ProcedureItem
                            key={index}
                            inline
                            filterList={filterList}
                            selected={selected}
                            selectedParent={selectedParent}
                            selectMultiple={selectMultiple}
                            setSelectAll={setSelectAll}
                            onSelectionChange={onSelectionChange}
                            level={level}
                        />
                    ))}
                </Box>
            )}
            {!inline && (
                <>
                    {row1?.length > 0
                        ? (
                            <Grid
                                sm={6}
                                item
                                className={classes.procedureItemCont}
                            >
                                {row1.map((level, index) => (
                                    <ProcedureItem
                                        key={index}
                                        filterList={filterList}
                                        selected={selected}
                                        selectedParent={selectedParent}
                                        selectMultiple={selectMultiple}
                                        setSelectAll={setSelectAll}
                                        onSelectionChange={onSelectionChange}
                                        level={level}
                                    />
                                ))}
                            </Grid>
                        ) : null}
                    <Grid sm={row1?.length > 0 ? 6 : 12} item className={classes.procedureItemCont1}>
                        {
                            row2.map((level, index) => (
                                <ProcedureItem
                                    key={index}
                                    filterList={filterList}
                                    selected={selected}
                                    selectedParent={selectedParent}
                                    selectMultiple={selectMultiple}
                                    setSelectAll={setSelectAll}
                                    onSelectionChange={onSelectionChange}
                                    level={level}
                                />
                            ))
                        }
                    </Grid>
                </>
            )}
        </Grid>
    );
};
export { ProcedureDialog, ProcedureSelectorGrid };
