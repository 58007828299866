import { Alert } from '@material-ui/lab';
import { OrgContext, UserContext } from '../../context';
import { ProcedureLibrary, minimumColumnWidth } from '../../initialConfig';
import { makeStyles } from '@material-ui/core/styles';
import { useLazyQuery } from 'react-apollo';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import POIAccess from '../onboarding/POIAccess';
import React, {
    useContext, useEffect, useMemo, useState,
} from 'react';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import gql from 'graphql-tag';

const GET_INDUSTRY = gql`
query GetIndustry( $is_null: Boolean ) {
    industry_speciality(where: { speciality_parent: { _is_null: $is_null } }) {
      id
      name
      category
    }
  }  
`;

const GET_SPECIALITY = gql`
query GetSpeciality( $speciality_parent: String ) {
    industry_speciality(where: { speciality_parent: { _eq: $speciality_parent } }) {
      id
      name
      category
    }
  }  
`;

const useStyles = makeStyles(() => ({
    widthSectPart: {
        minWidth: 700,
    },
    formControl: {
        minWidth: 'auto',
    },
    headerCont: {
        padding: '.5rem 0rem',
    },
    modalHeader: {
        borderBottom: '1px solid #cbcbcb',
        padding: '0rem 1rem 0rem 1rem',
    },
    dialogContentRoot: {
        padding: '0.5rem 1rem',
    },
    primaryBtn: {
        maxHeight: '56px',
        padding: '.25rem 1rem',
        boxShadow: '0px 0px 20px -4px rgba(51,36,5,0.37)',
        marginLeft: '1.5rem !important',
        '&:hover': {
            boxShadow: 'none',
        },
    },
    cancelBtn: {
        maxHeight: '56px',
        padding: '.25rem 1.25rem',
        boxShadow: 'none',
        '&:hover': {
            boxShadow: 'none',
        },
    },
    inputValue: {
        fontSize: 16,
        fontWeight: 300,
    },
    closeIconCont: {
        textAlign: 'end',
    },
    closeIcon: {
        cursor: 'pointer',
        marginTop: '.25rem',
    },
    footerCont: {
        padding: '.5rem 1rem .55rem 0rem',
        borderTop: '1px solid #cbcbcb',
    },
}));

const TimelineForm = ({
    showModal, timelineTemplates, createTimeline, onCancel,
}) => {
    const classes = useStyles();
    const [userState, setUserState] = useState({
        userType: 'Doctor', user: '', userData: [], cloneUserData: [],
    });
    const [poi, setPOI] = useState({});
    const [industry, setIndustry] = useState('Medicine');
    const [title, setTitle] = useState('');
    const [speciality, setSpeciality] = useState('Plastic Surgery');
    const [category] = useState('Generic');
    const [subCategory, setSubCategory] = useState('Public');
    const [errorState, setErrorState] = useState({ message: '' });
    const [template, setTemplate] = useState('1');
    const [templateByCategory, setTemplateByCategory] = useState([]);
    const [, setSubCategoryByCategory] = useState([]);

    const { allUserData } = useContext(UserContext);

    const {
        userPoi,
        loggedUserType,
    } = useContext(OrgContext);

    const [getIndustryList, { data: industryData }] = useLazyQuery(GET_INDUSTRY);
    useEffect(() => {
        getIndustryList({ variables: { is_null: true } });
    }, []);
    const industryList = useMemo(() => (industryData
        && industryData.industry_speciality ? industryData.industry_speciality.map((industry) => industry.name) : []),
    [industryData]);

    const [getSpecialityList, { data: specialityData }] = useLazyQuery(GET_SPECIALITY);
    useEffect(() => {
        getSpecialityList({ variables: { speciality_parent: industry } });
    }, [industry]);
    const specialityList = useMemo(() => (specialityData
        && specialityData.industry_speciality ? specialityData.industry_speciality.map((speciality) => speciality.name) : []),
    [specialityData]);

    let procedureLibraryChildren = [];
    if (loggedUserType !== 'Super Admin' && userPoi) {
        procedureLibraryChildren = JSON.parse(JSON.stringify(ProcedureLibrary.children))
            .filter((poi) => Object.prototype.hasOwnProperty.call(userPoi, poi.id)
        && userPoi[poi.id]);
        procedureLibraryChildren.forEach((poi) => {
            poi.children = poi.children.filter((subPoi) => Object.prototype.hasOwnProperty.call(userPoi, subPoi.id) && userPoi[subPoi.id]);
        });
    }

    const makeCustomUser = (userId = null) => {
        const items = [];

        allUserData.forEach((a) => {
            const obj = {
                id: a.id,
                name: a.name,
                lastName: a.last_name,
                role: a?.['organization_members'][0]?.role,
            };
            items.push(obj);
        });
        const data = items.filter((item) => item.role === userState.userType);

        if (userId) {
            setUserState({
                ...userState, user: userId, isModalOpen: false, userData: [...data], cloneUserData: [...items],
            });
        } else {
            setUserState({ ...userState, userData: [...data], cloneUserData: [...items] });
        }
    };

    const handleIndustryChange = (event) => {
        setSpeciality(null);
        setIndustry(event.target.value);
    };
    const handleTitleChange = (event) => {
        setErrorState({ message: '' });
        setTitle(event.target.value);
    };
    const handleSpecialityChange = (event) => {
        setSpeciality(event.target.value);
    };
    const handleTemplateChange = (event) => {
        setErrorState({ message: '' });
        setTemplate(event.target.value);
    };

    const validateTimeline = () => {
        if (!title) {
            setErrorState({ message: 'Please enter timeline title.' });
            return false;
        } if (!template.columns) {
            setErrorState({ message: 'Please select template.' });
            return false;
        }
        return true;
    };
    const handleSelectionChange = (level, checked) => {
        if (checked) {
            setPOI({ [level.id]: true });
        } else {
            setPOI({});
        }
    };
    const clickCreate = () => {
        if (validateTimeline()) {
            const totalColumns = template.columns.length;
            const currentWidth = parseInt(template.props.width, 10);
            const minTimelineWidth = totalColumns * minimumColumnWidth;

            if (minTimelineWidth > currentWidth) {
                template.props.width = minTimelineWidth;
            }

            createTimeline({
                title, template, poi: Object.keys(poi)[0], speciality, industry,
            });
        }
    };
    useEffect(() => {
        if (allUserData && userState.cloneUserData.length === 0) {
            makeCustomUser();
        }
    }, []);
    useEffect(() => {
        const filterTimeline = timelineTemplates.filter((item) => item.category === category);
        let subCatData = [];
        if (filterTimeline) {
            subCatData = [...new Set(filterTimeline.map((ft) => ft.sub_category))];
            subCatData = subCatData.filter((item) => !!item); // remove null value;
            setTemplateByCategory(filterTimeline);
        }
        setSubCategoryByCategory(subCatData);
        if (category === 'Generic') {
            setSubCategory(null);
        }
    }, [category]);

    useEffect(() => {
        const unique = new Set();
        const filterArray = timelineTemplates.filter((obj) => {
            if (!unique.has(obj.id)
            && (loggedUserType === 'Doctor' && (obj.category === 'Shared With Patient' || obj.category === 'Shared' || obj.category === 'Generic'))
             || !unique.has(obj.id) && (loggedUserType === 'Super Admin'
             && (obj.category === 'Shared With Patient' || obj.category === 'Generic' || obj.category === 'Super'))) {
                unique.add(obj.id);
                return true;
            }
            return false;
        });
        setTemplateByCategory(filterArray);
    }, [subCategory]);

    return (
        <Dialog onClose={onCancel} open={showModal} fullWidth classes={{ paperWidthSm: classes.widthSectPart }}>
            <DialogTitle classes={{ root: classes.headerCont }}>
                <Grid container justify="space-between" className={classes.modalHeader}>
                    <Grid item xs={10}>Create New Timeline</Grid>
                    <Grid item xs={2} className={classes.closeIconCont}>
                        <CloseIcon className={classes.closeIcon} onClick={onCancel} />
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent classes={{ root: classes.dialogContentRoot }}>
                <Grid container>
                    <Grid item xs={speciality === 'Plastic Surgery' ? 6 : 12}>
                        <Grid item xs={12}>
                            <Box my={2} mx={1} mb={5.75}>
                                <FormControl fullWidth variant="outlined" required>
                                    <TextField
                                        color="secondary"
                                        label="Timeline Title"
                                        variant="outlined"
                                        value={title}
                                        onChange={handleTitleChange}
                                        inputProps={{ className: classes.inputValue }}
                                    />
                                </FormControl>
                            </Box>
                        </Grid>
                        {industryList.length > 0 && (
                            <Grid item xs={12}>
                                <Box my={2} mx={1} mb={5.75}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel id="demo-simple-select-filled-label">Industry</InputLabel>
                                        <Select
                                            id="demo-simple-select-filled"
                                            value={industry}
                                            label="Industry"
                                            onChange={handleIndustryChange}
                                        >
                                            {industryList.map((industry, index) => <MenuItem key={index + 1} value={industry}>{industry}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                        )}
                        {specialityList.length > 0 && (
                            <Grid item xs={12}>
                                <Box my={2} mx={1} mb={5.75}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel id="demo-simple-select-filled-label">Speciality</InputLabel>
                                        <Select
                                            id="demo-simple-select-filled"
                                            value={speciality}
                                            label="Speciality"
                                            onChange={handleSpecialityChange}
                                        >
                                            {specialityList.map((speciality, index) => (
                                                <MenuItem
                                                    key={index + 1}
                                                    value={speciality}
                                                >
                                                    {speciality}
                                                </MenuItem>
                                            ))}

                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Box my={2} mx={1}>
                                <FormControl fullWidth variant="outlined" required>
                                    <InputLabel id="demo-simple-select-filled-label">Template</InputLabel>
                                    <Select
                                        id="demo-simple-select-filled"
                                        value={template}
                                        name="template"
                                        label="Template"
                                        onChange={handleTemplateChange}
                                    >
                                        {templateByCategory && templateByCategory.map((tlItem) => (
                                            <MenuItem
                                                key={tlItem.id}
                                                value={tlItem}
                                            >
                                                {tlItem.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            {errorState.message
                                && (
                                    <Alert severity="error">
                                        {errorState.message}
                                    </Alert>
                                )}
                        </Grid>
                    </Grid>
                    {speciality === 'Plastic Surgery' && (
                        <Grid item xs={6}>
                            <Box my={2} mx={1}>
                                <POIAccess
                                    template={template}
                                    hideFirstLevel
                                    selected={poi}
                                    library={loggedUserType !== 'Super Admin'
                                        && userPoi ? { ...ProcedureLibrary, children: procedureLibraryChildren } : ProcedureLibrary}
                                    onSelectionChange={handleSelectionChange}
                                />
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions classes={{ root: classes.footerCont }}>
                <Button className={classes.cancelBtn} onClick={onCancel} variant="contained">
                    Cancel
                </Button>
                <Button className={classes.primaryBtn} variant="contained" color="primary" onClick={clickCreate}>
                    Create
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TimelineForm;
