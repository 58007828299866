import {
    Breadcrumbs, Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { OrgContext, TimelineContext } from '../../context';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import React, { useContext } from 'react';

const useStyles = makeStyles((theme) => ({
    breadcrumbsNavlink: {
        textDecoration: 'none',
        color: 'inherit',
    },
    breadcrumbMainClass: {
        cursor: 'pointer',
    },
    timelineWithEdit: {
        display: 'flex',
        alignItems: 'center',
    },
    timelineChildText: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        maxWidth: 235,
    },
    editIconStyle: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: 22,
    },
    breadcrumbContainer: {
        padding: '5px 0px 5px 7px',
    },
    breadcrumbSeparator: {
        marginLeft: 4,
        marginRight: 4,
    },
    mainEditIconStyle: {
        [theme.breakpoints.down('xs')]: {
            padding: '8px 10px 0px 10px',
        },
    },
}));

const OrgBreadcrumbs = ({ current, timelineChild, isDataMatrix = false }) => {
    const { loggedUserType, selectedOrg } = useContext(OrgContext);
    const {
        selectedTimeline, editComponent, setActionRelation, setDataMatrixComponent,
    } = useContext(TimelineContext);
    const classes = useStyles();

    const category = selectedTimeline?.category || null;

    const editTimeLine = () => {
        editComponent(selectedTimeline, false, {}, false);
        setActionRelation({ type: 'edit' });
    };

    /**
     * @function renderChild
     * @description return timeline name in breadcum if timelinechild otherwise return current
     */
    const renderChild = () => {
        if (timelineChild && !isDataMatrix) {
            return [
                <Link key={0} className={classes.breadcrumbsNavlink} to={`/${selectedOrg.orgCode}/timelines/`}>
                    {current}
                </Link>,
                (
                    <div key={1} className={classes.timelineWithEdit}>
                        <Typography
                            color="textPrimary"
                            className={classes.timelineChildText}
                            key={2}
                        >
                            {timelineChild}
                        </Typography>
                        {!(category === 'Super'
                            || category === 'Shared'
                            || loggedUserType === 'Admin'
                            || loggedUserType === 'User'
                            || loggedUserType === 'Patient') && (
                            <IconButton aria-label="edit"  className={classes.mainEditIconStyle} onClick={editTimeLine}>
                                <EditIcon className={classes.editIconStyle} />
                            </IconButton>
                        )}
                    </div>
                ),
            ];
        }
        if (isDataMatrix) {
            return [
                <Link className={classes.breadcrumbsNavlink} to={`/${selectedOrg.orgCode}/timelines/`} key={1}>
                    {current}
                </Link>,
                <Link className={classes.breadcrumbsNavlink} to={`/${selectedOrg.orgCode}/timeline/${selectedTimeline.id}`} key={2}>
                    <Typography className={classes.breadcrumbsNavlink} onClick={() => setDataMatrixComponent(null)} style={{ cursor: 'pointer' }}>
                        {timelineChild[0]}
                    </Typography>
                </Link>,
                (
                    <div className={classes.timelineWithEdit}>
                        <Typography color="textPrimary" key={2}>{timelineChild[1]}</Typography>
                    </div>
                ),
            ];
        }
        return <Typography color="textPrimary">{current}</Typography>;
    };

    return (
        <Breadcrumbs
            className={classes.breadcrumbContainer}
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            classes={{ separator: classes.breadcrumbSeparator }}
        >
            <Link className={classes.breadcrumbsNavlink} to="/">MedGuide Contents</Link>
            <Link
                className={classes.breadcrumbsNavlink}
                to={(loggedUserType === 'Super Admin' || loggedUserType === 'Doctor') ? `/${selectedOrg.orgCode}` : '/'}
            >
                {selectedOrg?.name || selectedOrg}
            </Link>
            {renderChild()}
        </Breadcrumbs>
    );
};

export default OrgBreadcrumbs;
