import { makeStyles } from '@material-ui/core/styles';
import AddInput from './AddInput';
import React, { useState } from 'react';

const useStyles = makeStyles(() => ({
    paddBotom: {
        paddingBottom: '5px',
    },
}));

const QuickAdd = ({ component, onAdd }) => {
    const classes = useStyles();
    const [label, setLabel] = useState('');
    const handleLabelChange = (updated) => {
        setLabel(updated);
    };
    const onAddClicked = () => {
        onAdd(label);
        setLabel('');
    };
    return (
        <div
            className={classes.paddBotom}
            style={{
                borderTop: (component?.type === 'TextInput' ? '.25px solid #cbcbcb' : 'none'),
                borderBottom: component?.type === 'TextInput' ? '.25px solid #cbcbcb' : 'none',
            }}
        >
            <AddInput value={label} onChange={handleLabelChange} onAdd={onAddClicked} placeholder={`Add ${component.type}`} />
        </div>
    );
};

export default QuickAdd;
