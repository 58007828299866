/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
import { Container } from './components/Container';
import { DeleteDialog } from './components/DeleteDialog';
import { RecoilRoot } from 'recoil';
import { SettingsContainer } from './Workshop';
import { TemplateBrowser } from './TemplateBrowser';
import {
    TimelineContext, WorkshopContextProvider,
} from './context';
import { componentWithId, resetAtoms } from './components/Atoms';
import { makeStyles } from '@material-ui/core/styles';
import { standardHeaders } from './components/utils/StandardStyles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import OrgBreadcrumbs from './components/utils/OrgBreadcrumbs';
import Paper from '@material-ui/core/Paper';
import React, { useContext, useEffect, useState } from 'react';
import TemplateDialog from './components/TemplateDialog';
import TimelineTable from './TimelineTable';
import Typography from '@material-ui/core/Typography';
// eslint-disable-next-line import/no-named-as-default
import WorkshopContextMenu from './WorkshopContextMenu';

const useStyles = makeStyles((theme) => ({
    containerMain: {
        display: 'flex',
        width: '100%',
    },
    containerStyle: {
        width: '100%',
        overflowY: 'auto',
        height: 'calc(100vh - 103px)',
        borderRight: '1px solid #CBCBCB',
    },
    workshop: {
        margin: '10px',
    },
    workshopSettingCont: {
        paddingLeft: 2,
    },
    workshopTemplateCont: {
        maxHeight: '85vh',
        borderBottom: '1px solid #CBCBCB',
    },
    templateCont: {
        maxHeight: '77vh',
        overflowY: 'auto',
        paddingBottom: '1rem',
    },
    header: {
        ...standardHeaders.h1,
        background: '#011E27',
        padding: 4,
        color: 'white',
        height: 50,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    subheader: standardHeaders.h2,
    buttonGroupCont: {
        bottom: 0,
        position: 'fixed',
        margin: '3px 0px',
        width: '75%',
        borderTop: '1px solid #CBCBCB',
    },
    buttonGroup: {
        '& > *': {
            margin: theme.spacing(1.5),
            marginLeft: theme.spacing(1.5),
        },
        textAlign: 'right',
    },
    textInputWidth: {
        width: '90%',
        marginTop: 0,
        height: 40,
    },
    columHeadType: {
        fontSize: '0.75rem',
    },
    formControl: {
        minWidth: '90%',
    },
    selectTimeline: {
        height: 40,
    },
    emptyTemplate: {
        justifyContent: 'center',
        alignItems: 'center',
        height: '85vh',
        flexDirection: 'column',
        display: 'flex',
        color: '#464646',
        '& p': {
            fontSize: '18px',
        },
        '& svg': {
            fontSize: '50px',
        },
    },
    marginLeft2PX: {
        marginLeft: 2,
        borderLeft: '1px solid #cbcbcb',
        borderBottom: '1px solid #cbcbcb',
        height: '83vh',
        overflowY: 'auto',
        paddingBottom: '1rem',
    },
    fontFamilyProNvaSemiBold: {
    },
    templatesTextCont: {
        background: '#D0D0D0',
        paddingTop: 5,
        paddingBottom: 5,
    },
    templateText: {
        marginLeft: 15,
        fontSize: 13,
    },
}));

const TemplateManager = ({ showLoader }) => {
    const classes = useStyles();
    const {
        templateLibraryByType,
        selectedComponent,
        setSelectedComponent,
        contextMenuConfig,
        contextMenuPosition,
        contextMenuClick,
        contextMenuClose,
        saveTemplate,
        createTemplate,
        deleteTemplate,
        setInWorkshop,
        setWorkshopSelectedTemplate,
        setWorkshopActiveComponent,
        templateData,
        showTimelineTable,
        setShowTimelineTable,
        setSelectedTemplate,
        selectedTemplate,
        setTemplateLibraryCip,
    } = useContext(TimelineContext);
    const [, setHasError] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [templatePayLoad, setTemplatePayLoad] = useState(null);
    const [isSaveAsFlag, setIsSaveAsFlag] = useState(false);

    const filteredLibrary = templateLibraryByType
        && templateLibraryByType.Protocol
        && templateLibraryByType.Protocol.concat(templateLibraryByType.Text, templateLibraryByType.Column);
    const [cloneFilteredLibrary, setCloneFilteredLibrary] = useState(filteredLibrary);
    let filteredByGroup = filteredLibrary && [...new Set(filteredLibrary.map((x) => x?.group))];
    let filteredBySupergroup = filteredLibrary && [...new Set(filteredLibrary.map((x) => {
        if (x?.supergroup !== 'Row' && x?.supergroup !== 'Section') {
            return x?.supergroup;
        }

        return false;
    }))];
    let filteredByCategory = filteredLibrary && [...new Set(filteredLibrary.map((x) => x?.category))];

    if (filteredByGroup && filteredByGroup.length > 0) {
        filteredByGroup = filteredByGroup.filter((item) => !!item);
    }
    if (filteredBySupergroup && filteredBySupergroup.length > 0) {
        filteredBySupergroup = filteredBySupergroup.filter((item) => !!item);
    }
    if (filteredByCategory && filteredByCategory.length > 0) {
        filteredByCategory = filteredByCategory.filter((item) => !!item);
    }
    const allGroup = [];
    const allSuperGroup = [];
    const allCategory = [];

    useEffect(() => {
        setTemplateLibraryCip(true);
    }, []);

    useEffect(() => {
        if (!cloneFilteredLibrary) {
            setCloneFilteredLibrary(filteredLibrary);
        }
    }, [filteredLibrary]);

    useEffect(() => {
        setCloneFilteredLibrary(filteredLibrary);
    }, [templateData]);

    if (selectedTemplate) {
        filteredLibrary && filteredLibrary.map((data) => {
            if (!allGroup.includes(data.group) && selectedTemplate?.supergroup === data?.supergroup) {
                allGroup.push(data.group);
            }

            if (!allSuperGroup.includes(data?.supergroup) && data?.supergroup !== 'Row' && data?.supergroup !== 'Section') {
                allSuperGroup.push(data?.supergroup);
            }

            if (!allCategory.includes(data.category)) {
                allCategory.push(data.category);
            }
        });
    }

    if (!filteredLibrary) {
        return showLoader(true);
    }

    const getSelectedTemplate = () => {
        if (selectedTemplate) {
            let name = selectedTemplate?.template?.props?.title;
            if (selectedTemplate.group === 'Page Groups') {
                name = selectedTemplate?.template?.props?.components?.[0]?.props?.components?.[0]?.props?.title;
            }
            const obj = {
                group: selectedTemplate.group || 'Protocol Only',
                id: selectedTemplate.id,
                name,
                supergroup: selectedTemplate?.supergroup || 'Protocol Only',
                template: { ...selectedTemplate },
                type: selectedTemplate.type,
                category: selectedTemplate.category,
                subCategory: selectedTemplate.sub_category,
            };
            return obj;
        }

        return null;
    };

    const onSave = () => {
        setDialogOpen(true);
        setTemplatePayLoad(getSelectedTemplate());
        setIsSaveAsFlag(false);
    };

    const onSaveAs = () => {
        setDialogOpen(true);
        setTemplatePayLoad(getSelectedTemplate());
        setIsSaveAsFlag(true);
    };

    const onDelete = () => {
        // eslint-disable-next-line no-alert
        if (window.confirm('Do you really want delete this Template')) {
            deleteTemplate(selectedTemplate).then(() => {
                const parentTemp = cloneFilteredLibrary.find((item) => item.id === selectedTemplate.parent_reference_id);
                if (parentTemp?.['associated_child']) {
                    const parentTempCopy = JSON.parse(JSON.stringify(parentTemp));
                    parentTempCopy.associated_child = parentTempCopy.associated_child.filter((item) => item !== selectedTemplate.id);
                    saveTemplate(parentTempCopy, null, true);
                }
                setSelectedTemplate(null);
                setSelectedComponent({});
                setWorkshopSelectedTemplate(null);
            });
        }
    };

    const onClose = () => {
        setSelectedComponent({});
        setSelectedTemplate(null);
        setWorkshopSelectedTemplate(null);
        setShowTimelineTable(false);
    };

    const onHover = () => { };

    const selectTemplate = (template) => {
        resetAtoms();
        componentWithId(template.id, template.template.props);
        setSelectedTemplate(template);
        setSelectedComponent(template.template);
        setWorkshopActiveComponent(template.template);
        setWorkshopSelectedTemplate(template); // remove initianl workshop active component.
        setInWorkshop(true);
        setShowTimelineTable(false);
    };

    const updateComponent = () => {
    };

    /** callback from dialogbox */
    const onDialogSave = (payload) => {
        if (!selectedTemplate.group) {
            setHasError(true);
            return;
        }
        const parentTemp = getSelectedTemplate()?.id;
        const selectedTemplateCopy = JSON.parse(JSON.stringify(selectedTemplate));
        setTemplatePayLoad(payload);
        selectedTemplateCopy.group = payload.group;
        selectedTemplateCopy.supergroup = payload?.supergroup;
        selectedTemplateCopy.category = payload.category;
        selectedTemplateCopy.subCategory = payload.subCategory;
        selectedTemplateCopy.group = payload.group;
        selectedTemplateCopy.name = payload.name;
        if (parentTemp !== payload.id) { selectedTemplateCopy.parent_reference_id = parentTemp; }
        // eslint-disable-next-line no-alert
        if (isSaveAsFlag && window.confirm('Save As will create a new template')) {
            selectedTemplateCopy.parent_reference_id = parentTemp;
            selectedTemplateCopy.associated_child = null;
            createTemplate(selectedTemplateCopy).then(() => {
                setSelectedTemplate(null);
                setSelectedComponent({});
                setWorkshopSelectedTemplate(null);
            });
        } else {
            saveTemplate(selectedTemplateCopy, setSelectedTemplate);
        }
    };

    const renderTemplate = () => (
        <TemplateDialog
            open={dialogOpen}
            setOpen={setDialogOpen}
            type="prompt"
            payload={templatePayLoad}
            setPayload={onDialogSave}
            inTemplateLibrary
        />
    );

    return (
        <div className={classes.containerMain}>
            <RecoilRoot>
                <WorkshopContextProvider>
                    {contextMenuConfig && selectedTemplate
                        && selectedTemplate.group !== 'Column'
                        && (
                            <WorkshopContextMenu
                                menuConfig={contextMenuConfig}
                                menuPosition={contextMenuPosition}
                                handleClick={contextMenuClick}
                                handleClose={contextMenuClose}
                            />
                        )}
                    <Grid container>
                        <Grid item xs={12}>
                            <Box mt={1}>
                                <OrgBreadcrumbs current="Template Library" />
                            </Box>
                            <Grid container>
                                <Grid item xs={3}>
                                    <Paper elevation={0}>
                                        <div className={classes.containerStyle}>
                                            <Paper
                                                elevation={0}
                                                square
                                                className={classes.templatesTextCont}
                                            >
                                                <Typography className={classes.templateText}>TEMPLATES</Typography>
                                            </Paper>
                                            <TemplateBrowser
                                                templates={cloneFilteredLibrary}
                                                hoverItem={onHover}
                                                selectItem={selectTemplate}
                                                showTimeLine
                                                showLoader={showLoader}
                                            />
                                        </div>
                                    </Paper>
                                </Grid>
                                <Grid item xs={9}>
                                    <Paper elevation={0} className={classes.workshopSettingCont}>
                                        <Grid container>
                                            {selectedComponent && Object.keys(selectedComponent).length > 0 && (
                                                <Grid item xs className={classes.workshopTemplateCont}>
                                                    <div className={classes.header}>
                                                        <Typography className={classes.columHeadType} color="inherit">Workshop</Typography>
                                                        <b className={classes.fontFamilyProNvaSemiBold}>
                                                            {selectedComponent !== {} && selectedComponent.props
                                                                && (selectedComponent.props.title || selectedComponent.props.text)}
                                                        </b>
                                                    </div>
                                                    {selectedTemplate
                                                        && (
                                                            <>
                                                                <div>
                                                                    {/* Template Properties: */}
                                                                    {dialogOpen ? renderTemplate() : null}
                                                                </div>
                                                                <div className={classes.templateCont}>
                                                                    <Container
                                                                        props={{ components: [selectedTemplate.template], isWorkshop: true }}
                                                                    />
                                                                </div>
                                                            </>
                                                        )}
                                                </Grid>
                                            )}
                                            {
                                                showTimelineTable && <Grid item xs={12}><TimelineTable /></Grid>
                                            }
                                            {selectedComponent && Object.keys(selectedComponent).length > 0 && (
                                                <Grid item xs={5} className={classes.marginLeft2PX}>
                                                    <div className={classes.header}>
                                                        <Typography className={classes.columHeadType} color="inherit">Settings</Typography>
                                                    </div>
                                                    {selectedTemplate && selectedComponent.id
                                                        && (
                                                            <SettingsContainer
                                                                workshopComponent={selectedTemplate.template}
                                                                updateComponent={updateComponent}
                                                                selectedComponent={selectedComponent}
                                                                setSelectedComponent={setSelectedComponent}
                                                            />
                                                        )}
                                                </Grid>
                                            )}
                                            {(!selectedComponent || Object.keys(selectedComponent).length === 0) && !showTimelineTable && (
                                                <Grid item xs={12}>
                                                    <Box
                                                        className={classes.emptyTemplate}
                                                    >
                                                        <LibraryBooksIcon />
                                                        <Typography color="inherit">Select a template from the library to continue</Typography>
                                                    </Box>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Paper>
                                    {selectedTemplate
                                        && (
                                            <Paper elevation={0} square className={classes.buttonGroupCont}>
                                                <Grid container justify="space-between" direction="row">
                                                    <Grid item xs={12} className={classes.buttonGroup}>
                                                        <Button variant="contained" color="primary" onClick={onSave}>
                                                            Save
                                                        </Button>
                                                        <Button variant="outlined" color="primary" onClick={onSaveAs}>
                                                            Save As
                                                        </Button>
                                                        {selectedTemplate?.['is_super_template'] !== true && (
                                                            <Button variant="contained" onClick={onDelete}>
                                                                Delete
                                                            </Button>
                                                        )}
                                                        <Button variant="contained" onClick={onClose}>
                                                            Close workshop
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </WorkshopContextProvider>
                <DeleteDialog />
            </RecoilRoot>
        </div>
    );
};

export default TemplateManager;
