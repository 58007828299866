/* eslint-disable react-hooks/exhaustive-deps */
import { ColumnContextProvider, OrgContext, TimelineContext } from '../context';
import { componentWithId } from './Atoms';
import { flatProtocols } from '../helper/commonHelper';
import { makeStyles } from '@material-ui/core/styles';
import { useSwipeable } from 'react-swipeable';
import AccessControl from './AccessControl';
import Box from '@material-ui/core/Box';
import ContentDisplayView from './dialog/ContentDisplayView';
import Grid from '@material-ui/core/Grid';
import Loader from 'react-loaders';
import React, {
    Fragment, useContext, useEffect,
    useState,
} from 'react';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TimelineColumn from './TimelineColumn';

const useStyles = makeStyles((theme) => ({
    overlatStyle: {
        '& .inner-container': {
            '& img': {
                [theme.breakpoints.down('xs')]: {
                    maxWidth: '100% !important',
                },
            },
        },
        [theme.breakpoints.down('xs')]: {
            position: 'absolute',
            top: '0%',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '100%',
            height: '100%',
            backgroundColor: '#fff',
        },
    },
    overlatStyleVideo: {
        [theme.breakpoints.down('xs')]: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
        },
    },
    timelineMobileStyle: {
        marginTop: '90px',
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            marginTop: '0px',
        },
    },
    timelineMobileTabsStyle: {
        [theme.breakpoints.down('xs')]: {
            '& div': {
                display: 'block',
                width: 'auto',
                '& svg': {
                    width: '25px',
                    height: '47px',
                },
            },
        },
    },
}));

/**
 * To find out the actual protocol using column title, hedaer title and protocol title
 * @param {Array}       superParent is the Timeline Columns List
 * @param {String}      colName is the Timeline Column Title (e.g. Research, Consultation)
 * @param {String}      headerTitle is Header(H1) Title String
 * @returns actual Column, Hedaer Component
 */
const findByColAndHeaderName = (superParent, colName, headerTitle) => {
    let header;
    const column = superParent.find((c) => c?.type === 'Column' && ((c?.props?.title)?.trim())?.toUpperCase() === colName);
    if (column?.props?.components?.length > 0) {
        header = column.props.components.find((c) => c?.type === 'Text' && (c?.props?.text?.trim())?.toUpperCase() === headerTitle);
    }
    if (column && header) {
        return { column, header };
    } return { column: null, header: null };
};

const Timeline = ({
    columns, selectedDoctor, selectedColHandler, selectedTC = null, setSelectedTC = null,
    selectedPsa, setTimelineContainerLoader, isPreview = false,
    selectedDoctorDetails, selectedPatientDetails,
}) => {
    const {
        isAccessControl, setAccessControl, selectedColumns, scrollState, setWPHeaders,
        columnWidthData, patientProfileData, contentDisplayLoader, isMobile, cdComponent, cdClickType, setColumnWidthData,
        setCDComponent, setCdClickType, setCurrentProtocol, setCurrentProtocolParents, queryParams,
    } = useContext(TimelineContext);
    const { loggedUserType } = useContext(OrgContext);
    const classes = useStyles();
    const bodyRef = React.createRef();
    const columnData = columns;
    const totalColumns = columnData.length;
    const [displayColumn, setDisplayColumn] = useState(totalColumns > 0 ? columnData[0] : {});
    const [indexNumber, setIndexSumber] = useState(totalColumns > 0 ? 0 : -1);

    let wpCol;
    let currentHeader = null;
    if (queryParams.length === 2) {
        const colName = queryParams[0];
        const headerTitle = queryParams[1];
        const { column: col = null, header = null } = findByColAndHeaderName(columns, colName, headerTitle);
        if (header !== null) {
            currentHeader = header;
            wpCol = col;
        }
    }

    // eslint-disable-next-line no-unused-vars
    const handleChange = (event = null, newValue) => {
        if (newValue > 0 < totalColumns) {
            setDisplayColumn(columnData[newValue]);
            setIndexSumber(newValue);
        }
    };

    const handlers = useSwipeable({
        onSwipedLeft: (eventData) => {
            if (eventData.event.target.tagName !== 'INPUT') {
                const protocols = [];
                const headers = [];
                flatProtocols(protocols, columns?.[indexNumber]?.props?.components || [], headers);
                const objComp = {
                    components: [...protocols],
                    headers: [...headers],
                };
                const hypProtocol = protocols.find((c) => Array.isArray(c?.props?.hyperlink) && (c.props.hyperlink?.[0]));
                const openHyperlinkType = hypProtocol?.props?.openHyperlinkType || 'inApp';
                setCurrentProtocol(hypProtocol);
                setCurrentProtocolParents(objComp);
                if (hypProtocol && openHyperlinkType !== 'newTab') {
                    setCDComponent(hypProtocol);
                    setCdClickType('hyperlink');
                }
            }
        },
    });

    const renderLoader = () => (
        <div id="overlay" style={{ zIndex: '9999' }}>
            <div id="loader">
                <Loader style={{ transform: 'scale(1.5)' }} type="line-scale" />
            </div>
        </div>
    );

    useEffect(() => {
        if (currentHeader !== null && wpCol) {
            const indx = columnData.findIndex((c) => c?.id === wpCol?.id);
            if (isMobile) {
                setDisplayColumn(wpCol);
                setIndexSumber(indx);
            } else {
                const colWidth = 500;
                const columnWidthObj = {
                    id: wpCol?.id,
                    colWidth,
                    highlightColWidth: wpCol?.props?.isProfile ? (colWidth + 122) : (colWidth + 250),
                    column: wpCol,
                };
                setColumnWidthData(columnWidthObj);
            }
            setWPHeaders(currentHeader);
        }
    }, [currentHeader]);

    useEffect(() => {
        if (bodyRef.current) {
            window.scrollTo(scrollState.hrz, scrollState.vertical);
        }
    }, []);

    /**
     * Handle Access Control popup isAccessControlOpen
     * @param {boolean} status [if modal  open status is `true` otherwise `false`]
     */
    const handleModalStatus = () => {
        setAccessControl(!isAccessControl);
    };

    if (columnData?.length === 0) return null;

    const renderTimelineColumn = (column, index) => {
        if (((loggedUserType === 'Super Admin' && selectedDoctor) || (loggedUserType === 'Doctor'
            && (selectedPsa || (patientProfileData?.profile?.firstName && patientProfileData?.profile?.email)))) && isPreview) {
            if (!selectedTC?.includes(column?.id)) {
                return null;
            }
        }
        return (
            <>
                <TimelineColumn
                    columns={columnData}
                    column={column}
                    columnIndex={index}
                    selectedDoctor={selectedDoctor}
                    selectedColHandler={selectedColHandler}
                    selectedTC={selectedTC}
                    selectedPsa={selectedPsa}
                    isPreview={isPreview}
                    selectedDoctorDetails={selectedDoctorDetails}
                    selectedPatientDetails={selectedPatientDetails}
                />

            </>
        );
    };
    // console.log(cdClickType, 'here');
    const renderAbsoluteDialouge = (show, comp) => (
        <>
            {show && (
                <div
                    id="overlay"
                >
                    <div
                        className={cdClickType === 'hyperlink' ? classes.overlatStyle : classes.overlatStyleVideo}
                    >
                        {comp}
                    </div>
                </div>
            )}
        </>
    );

    return (
        <>
            <Fragment>
                {contentDisplayLoader && renderLoader()}
                {isMobile
                    ? (
                        <Grid container>
                            <Box className={classes.timelineMobileStyle}>
                                <Tabs
                                    value={indexNumber}
                                    onChange={handleChange}
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    aria-label="scrollable auto tabs example"
                                    className={classes.timelineMobileTabsStyle}
                                >
                                    {columnData.map((column) => (<Tab label={column?.props?.title} />))}
                                </Tabs>
                            </Box>
                            <Grid item xs={12}>
                                <Grid container ref={bodyRef}>
                                    <div
                                        {...handlers}
                                        style={{
                                            width: '100vw',
                                            margin: '0 10px',
                                            touchAction: 'auto',
                                        }}
                                    >
                                        {!isAccessControl && (
                                            <ColumnContextProvider key={displayColumn.id} column={displayColumn}>
                                                <TimelineColumn
                                                    columns={columnData}
                                                    column={displayColumn}
                                                    columnIndex={indexNumber}
                                                    selectedDoctor={selectedDoctor}
                                                    selectedColHandler={selectedColHandler}
                                                    selectedTC={selectedTC}
                                                    selectedPsa={selectedPsa}
                                                    isPreview={isPreview}
                                                    selectedDoctorDetails={selectedDoctorDetails}
                                                    selectedPatientDetails={selectedPatientDetails}
                                                />
                                            </ColumnContextProvider>
                                        )}
                                    </div>
                                    {cdClickType === 'hyperlink' && renderAbsoluteDialouge(!!cdComponent, <ContentDisplayView />)}
                                    {isAccessControl
                                    && (
                                        <AccessControl
                                            open={isAccessControl}
                                            handleClose={handleModalStatus}
                                            column={selectedColumns}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid container ref={bodyRef}>
                                    {!isAccessControl && (
                                        <ColumnContextProvider key={columnData[0].id} column={columnData[0]}>
                                            <TimelineColumn
                                                columns={columnData}
                                                column={columnData[0]}
                                                columnIndex={0}
                                                selectedDoctor={selectedDoctor}
                                                selectedColHandler={selectedColHandler}
                                                selectedTC={selectedTC}
                                                setSelectedTC={setSelectedTC}
                                                selectedPsa={selectedPsa}
                                                isPreview={isPreview}
                                                selectedDoctorDetails={selectedDoctorDetails}
                                                selectedPatientDetails={selectedPatientDetails}
                                                setTimelineContainerLoader={setTimelineContainerLoader}
                                            />
                                        </ColumnContextProvider>
                                    )}
                                    {!isAccessControl && columnData.map((column, index) => {
                                        componentWithId(column.id, column.props, columnData);
                                        return (
                                            <ColumnContextProvider key={column.id} column={column}>
                                                {index > 0 && renderTimelineColumn(column, index)}
                                                {columnWidthData?.column?.id === column.id
                                                && !isPreview && cdClickType === 'hyperlink' && <ContentDisplayView />}
                                            </ColumnContextProvider>
                                        );
                                    })}
                                    {isAccessControl
                                    && (
                                        <AccessControl
                                            open={isAccessControl}
                                            handleClose={handleModalStatus}
                                            column={selectedColumns}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
            </Fragment>
        </>
    );
};

export default Timeline;
