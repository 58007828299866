import { atom } from 'recoil';

const componentAtoms = {};

const componentWithId = (id, props, parent) => {
    let atm = componentAtoms[id];
    if (!atm) {
        atm = atom({ key: `component${id}`, default: { props, parent }, dangerouslyAllowMutability: true });
        componentAtoms[id] = atm;
    }
    return atm;
};

export const updateComponentCallback = ({ set }) => (componentId, props, domain) => {
    set(componentWithId(componentId), (currentVal) => {
        const copy = { ...currentVal };
        if (domain) {
            copy[domain] = props;
        } else {
            copy.props = props;
        }
        return copy;
    });
};
export const mergeComponentPropsCallback = ({ set }) => (componentId, propsToMerge) => {
    set(componentWithId(componentId), (currentVal) => ({ ...currentVal, props: { ...currentVal.props, ...propsToMerge } }));
};

const resetAtoms = () => {
};

export { componentWithId, resetAtoms };
