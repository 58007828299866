import { Grid } from '@material-ui/core';
import { TemplateBrowser } from '../TemplateBrowser';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';

const useStyles = makeStyles(() => ({
    widthSectPart: {
        maxWidth: '90%',
        width: '90%',
    },
    widthSectPart1: {
        maxWidth: '90%',
        width: '50%',
    },
    headerStyle: {
        padding: '12px 24px 4px 24px',
    },
    bodyCont: {
        borderTop: '1px solid #70707053',
        padding: '8px 4px',
        marginBottom: 8,
    },
    bodySubCont: {
        display: 'flex',
        width: '150%',
        overflowX: 'scroll',
    },
    closeIconCont: {
        textAlign: 'right',
    },
    closeIcon: {
        cursor: 'pointer',
        marginTop: 4,
    },
}));

const TemplateLibraryDialog = (props) => {
    const {
        open, handleClose, templates,
        hoverItem,
        selectItem,
        showLoader,
        handleSelectDMTL,
    } = props;
    const classes = useStyles();

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{ paperWidthSm: classes.widthSectPart }}
            >
                <DialogTitle id="alert-dialog-title" classes={{ root: classes.headerStyle }}>
                    <Grid container>
                        <Grid item xs={10} md={11}>Template Library</Grid>
                        <Grid item xs={2} md={1} className={classes.closeIconCont}>
                            <CloseIcon onClick={handleClose} className={classes.closeIcon} />
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent classes={{ root: classes.bodyCont }}>
                    <div className={classes.bodySubCont}>
                        <TemplateBrowser
                            templates={templates}
                            hoverItem={hoverItem}
                            selectItem={selectItem}
                            showLoader={showLoader}
                            isPopup
                            openAccordion
                            handleSelectDMTL={handleSelectDMTL}
                            showTimeLine
                        />
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default TemplateLibraryDialog;
