import 'date-fns';
import {
    Box, Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ProcedureLibrary } from '../initialConfig';
import { UserContext } from '../context';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { emailValidator, getFormattedDate } from '../helper/commonHelper';
import { showLoader } from '../App';
import { toast } from 'react-toastify';
import { useLazyQuery } from 'react-apollo';
import Backdrop from '@material-ui/core/Backdrop';
import CloseIcon from '@material-ui/icons/Close';
import DateFnsUtils from '@date-io/date-fns';
import DoctorStepper from './onboarding/DoctorStepper';
import Modal from '@material-ui/core/Modal';
import POIAccess from './onboarding/POIAccess';
import React, {
    Fragment, useContext, useEffect, useMemo, useState,
} from 'react';
import gql from 'graphql-tag';

const CHECK_USER_EXISTENCE = gql`
    query user($email: String) {
        user(where: { is_active: { _eq: true }, email: { _eq: $email } }) {
            id,
        },
    }
`;

const GET_INDUSTRY = gql`
query GetIndustry( $is_null: Boolean ) {
    industry_speciality(where: { speciality_parent: { _is_null: $is_null } }) {
      id
      name
      category
    }
  }  
`;

const GET_SPECIALITY = gql`
query GetSpeciality( $speciality_parent: String ) {
    industry_speciality(where: { speciality_parent: { _eq: $speciality_parent } }) {
      id
      name
      category
    }
  }  
`;

/**
 * @function useStyles
 * @description css for modal popup
 * @returns {object}
 */
const useStyles = makeStyles((theme) => createStyles({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: '.25rem',
        boxShadow: theme.shadows[5],
        maxWidth: 700,
        minWidth: 675,
        padding: '.75rem 0rem',
        maxHeight: '90vh',
    },
    modalHeader: {
        borderBottom: '1px solid #cbcbcb',
        padding: '0rem 1rem .375rem 1rem',
    },
    modalCancel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    layoutTypeWidth: {
        width: '100%',
    },
    primaryBtn: {
        maxHeight: '56px',
        padding: '.25rem 1rem',
        boxShadow: '0px 0px 20px -4px rgba(51,36,5,0.37)',
        '&:hover': {
            boxShadow: 'none',
        },
    },
    cancelBtn: {
        maxHeight: '56px',
        padding: '.25rem 1.5rem',
        boxShadow: 'none',
        margin: '0rem 1.5rem',
        '&:hover': {
            boxShadow: 'none',
        },
    },
    showError: {
        color: '#DC143C',
    },
    genricDate: {
        width: '100%',
    },
    buttonCont: {
        textAlign: 'end',
    },
    closeIcon: {
        cursor: 'pointer',
    },
    modalBady: {
        overflow: 'auto',
        maxHeight: '60vh',
    },
    modalFooter: {
        borderTop: '1px solid #cbcbcb',
    },
}));

/**
 * @constructor EditorModal
 * @description create the modal pop. Taking props from parent and pass props to child component
 * @param {orgID}
 * @param {selectedUser}
 * @param {toggleDrawer}
 * @param {initialUserData}
 */
const ManageUser = ({
    orgID, selectedUser, initialUserData = null,
    routeLabel, open, handleClose, timelineUser = null, filterList,
    // eslint-disable-next-line camelcase
    is_expert, resetOnClose, industryName, specialityName, isDoctorDashboard = false,
}) => {
    const classes = useStyles();
    const {
        validateUserEmail,
        userDetailsContext,
        updateUserOrganizationContext,
        addNewUser,
        users,
    } = useContext(UserContext);
    let userData = (!userDetailsContext) ? [] : userDetailsContext;
    const initialState = {
        formData: {
            userEmail: '',
            userName: '',
            lastName: '',
            phoneNo: '',
            dob: new Date(),
            userRole: routeLabel === 'Doctor' ? routeLabel : (isDoctorDashboard ? 'Admin' : ''),
            orgName: '',
            orgEmail: '',
            industry: industryName || '',
            specialty: specialityName || '',
            isExpert: is_expert,
        },
        error: {
            userEmail: false,
            userName: false,
            userRole: false,
            orgEmail: false,
            orgName: false,
            specialty: false,
        },
        isEmailVerified: false,
        isShowValidateBtn: true,
        userID: null,
        userCreationDate: '',
        isDateCreation: false,
        isUserFromSameOrg: false,
        stepValue: 1,
    };
    const [state, setState] = useState(initialState);
    const [loaderState, setLoaderState] = useState(false);
    const [selected, setSelected] = useState({});
    const [validateError, setValidateError] = useState(false);
    const bodyRef = React.createRef();

    const [getIndustryList, { data: industryData }] = useLazyQuery(GET_INDUSTRY);
    useEffect(() => {
        getIndustryList({ variables: { is_null: true } });
    }, []);
    let industryList = useMemo(() => (
        industryData && industryData.industry_speciality ? industryData.industry_speciality.map((industry) => industry.name) : []), [industryData]);
    if (industryName) {
        industryList = [industryName];
    }

    const [getSpecialityList, { data: specialityData }] = useLazyQuery(GET_SPECIALITY);
    useEffect(() => {
        getSpecialityList({ variables: { speciality_parent: state.formData.industry } });
    }, [state.formData.industry]);
    let specialityList = useMemo(() => (
        specialityData && specialityData.industry_speciality ? specialityData.industry_speciality.map(
            (speciality) => speciality.name
        ) : []), [specialityData]);
    if (specialityName) {
        specialityList = [specialityName];
    }

    const [checkUserExistence] = useLazyQuery(CHECK_USER_EXISTENCE, {
        onCompleted: (data) => {
            if (data.user.length > 0) {
                setValidateError(true);
            } else {
                setState({
                    ...state,
                    isEmailVerified: true,
                    isDateCreation: false,
                    userID: null,
                    formData: {
                        ...state.formData,
                        userName: '',
                    },
                });
            }
        },
    });

    const getExistingOrgCodes = () => {
        const existingOrgCodesArr = [];
        if (users) {
            users.forEach((user) => user.organization_members.forEach((orgMembers) => existingOrgCodesArr.push(orgMembers.organization.orgCode)));
        }
        return (existingOrgCodesArr);
    };
    const existingOrgCodes = useMemo(() => (getExistingOrgCodes()), [users]);
    /**
     * @function onHandleChange
     * @description set the updated value
     */
    const onHandleChange = (event) => {
        if (!event.target) {
            setState({
                ...state,
                formData: {
                    ...state.formData,
                    dob: event,
                },
            });
        } else {
            const { name } = event.target;
            let { value } = event.target;

            if (event.target.type === 'checkbox') {
                value = event.target.checked;
            }

            let status = (!(value));

            if (name === 'userEmail' || name === 'orgEmail') {
                status = !(emailValidator(value));
            }

            setState({
                ...state,
                formData: {
                    ...state.formData,
                    [name]: value,
                },
                error: {
                    ...state.error,
                    [name]: status,
                },
                isShowValidateBtn: status,
            });
        }
    };

    const handleCloseModal = () => {
        if (resetOnClose) {
            setState(initialState);
        }
        if (handleClose) {
            handleClose();
        }
    };

    /**
     * @function onVerifyEmail
     * @description get the entered email to validate, is it valid or not
     */
    const onVerifyEmail = () => {
        validateUserEmail(state.formData.userEmail);
        setValidateError(false);
        checkUserExistence({
            variables: {
                email: state?.formData?.userEmail,
            },
        });
    };

    const handleOrgNameChange = (event) => {
        if (existingOrgCodes.includes((event.target.value).toLowerCase())) {
            setState({ ...state, error: { ...state.error, orgName: true }, formData: { ...state.formData, orgName: event.target.value } });
        } else {
            setState({ ...state, error: { ...state.error, orgName: false }, formData: { ...state.formData, orgName: event.target.value } });
        }
    };

    const handleIndustryChange = (event) => {
        setState({ ...state, formData: { ...state.formData, industry: event.target.value, specialty: '' } });
    };

    const nextStepHandler = () => {
        setState({ ...state, stepValue: 2 });
    };

    const prevStepHandler = () => {
        setState({ ...state, stepValue: 1 });
    };

    /**
     * @function onSubmit
     * @description pass the user data to save into database
     */
    const onSubmit = async () => {
        let response;
        const requestObj = {
            userRole: state.formData.userRole,
            orgID,
            userName: state.formData.userName,
            userEmail: state.formData.userEmail,
            last_name: state.formData.lastName,
            dob: state.formData.dob,
            phone_no: state.formData.phoneNo,
            poi: selected,
            industry: state.formData.industry,
            specialty: state.formData.industry === 'Medicine' ? state.formData.specialty : '',
            is_expert: state.formData.isExpert,
        };

        if (state.error.userName || state.error.userRole || !state.formData.userName || !state.formData.userRole) {
            return false;
        }

        if (state.formData.userRole === 'Doctor') {
            requestObj.name = state.formData.orgName; // org name
            requestObj.code = state.formData.orgName.toLowerCase();
            requestObj.org_email = state.formData.orgEmail;
        }

        if ((userData && userData.user && userData.user.length > 0) || Object.values(selectedUser).length > 0) {
            requestObj.isUserFromSameOrg = state.isUserFromSameOrg;
            requestObj.userID = state.userID;
            requestObj.poiId = state.poiId;
            requestObj.poi = selected;
            setLoaderState(true);
            response = await updateUserOrganizationContext(requestObj);
        } else {
            setLoaderState(true);
            response = await addNewUser(requestObj);
        }

        if (response) {
            const message = (state.isUserFromSameOrg) ? `${routeLabel} Updated successfully` : `${routeLabel} added successfully`;
            toast.success(message);
            if (timelineUser) {
                timelineUser(response?.data?.signup?.id);
            }
            setLoaderState(false);
            handleCloseModal();
        } else {
            setLoaderState(false);
            toast.error('Please try again!!!');
        }

        if (initialUserData) initialUserData();
        userData = [];

        return null;
    };

    const findParentLevel = (levelId) => levelId.substring(0, levelId.lastIndexOf('.'));
    const onSelectionChange = (level, checked, firstLevel) => {
        const parentLevel = findParentLevel(level.id);
        let isChildSelected = false;
        if (firstLevel) {
            const childrenArr = level.children.reduce((acc, item) => (
                { ...acc, [item.id]: filterList ? filterList[item.id] ? checked : false : checked }), {});
            let newSelected = { ...selected, [level.id]: checked, ...childrenArr };
            if (parentLevel && checked) {
                newSelected = { ...newSelected, [parentLevel]: checked };
            }
            // eslint-disable-next-line no-unused-vars
            for (const i in newSelected) {
                if (i !== parentLevel && newSelected[i] && i.startsWith(parentLevel)) {
                    isChildSelected = true;
                    break;
                }
            }
            if (!isChildSelected) {
                newSelected = { ...newSelected, [parentLevel]: false };
                if (findParentLevel(parentLevel)) {
                    newSelected = { ...newSelected, [findParentLevel(parentLevel)]: false };
                }
            } else if (findParentLevel(parentLevel) && Object.prototype.hasOwnProperty.call(newSelected, findParentLevel(parentLevel))) {
                newSelected = { ...newSelected, [findParentLevel(parentLevel)]: true };
            }
            setSelected(newSelected);
        } else if (checked) {
            setSelected({ ...selected, [level.id]: checked, [parentLevel]: checked });
        } else {
            setSelected({ ...selected, [level.id]: checked });
        }
    };

    const selectMultiple = (list, checked) => {
        const items = list.reduce((acc, item) => ({ ...acc, [item.id]: checked }), {});
        setSelected({ ...selected, ...items });
    };

    useEffect(() => {
        if (state.stepValue && bodyRef && bodyRef.current) {
            bodyRef.current.scrollTo(0, 0);
        }
    }, [state.stepValue]);

    useEffect(() => {
        const userDataCount = (userData && userData.user && userData.user.length) ? userData.user.length : 0;

        if ((state.isEmailVerified && userDataCount > 0) || Object.values(selectedUser).length > 0) {
            let validatedData;
            let checkOrgCount = [];

            if (Object.values(selectedUser).length > 0) {
                validatedData = selectedUser;

                validatedData.role = selectedUser.organization_members[0].role;
            } else {
                // eslint-disable-next-line prefer-destructuring
                validatedData = userData.user[0];
                checkOrgCount = Object.values(validatedData.organization_members).filter((data) => data.organization_id === orgID);
            }

            const checkOrg = !!((checkOrgCount.length > 0 || Object.values(selectedUser).length > 0));

            if (!state.isEmailVerified || userDataCount > 0) {
                const { poi, id = null } = (validatedData?.['poi_accesses'] && validatedData?.['poi_accesses'][0]) || { poi: {} };
                setState({
                    ...state,
                    formData: {
                        ...state.formData,
                        userName: validatedData.name,
                        userRole: validatedData.role,
                        userEmail: validatedData.email,
                        lastName: validatedData.last_name,
                        dob: validatedData.dob,
                        phoneNo: validatedData.phone_no,
                        industry: validatedData.industry,
                        specialty: validatedData.specialty,
                        isExpert: validatedData.is_expert,
                    },
                    userID: validatedData.id,
                    userCreationDate: getFormattedDate(validatedData.created_at),
                    isDateCreation: true,
                    isEmailVerified: true,
                    isUserFromSameOrg: checkOrg,
                    poiId: id,
                });
                setSelected(poi);
            }
        }
    }, [userData, selectedUser]);

    /**
     * @function renderActionButton
     * @description enable and disable the action button
     */
    const renderActionButton = () => {
        let isDisable = true;

        if (state.formData.userName && state.formData.userRole && !state.error.orgName) {
            isDisable = !!(routeLabel === 'Doctor' && !state.userID && (
                !state.formData.orgName || !state.formData.industry || (specialityList.length > 0 && !state.formData.specialty)));
            isDisable = routeLabel === 'Doctor' && state.stepValue === 2 && Object.entries(selected).filter(
                (c) => c[1]
            ).length === 0 ? true : isDisable;
        }
        return (
            <Fragment>
                <Box clone mt={1.5} mr={2.5}>
                    <Grid container>
                        <Grid item xs={12} className={classes.buttonCont}>
                            {state.stepValue === 2 && routeLabel === 'Doctor' && (
                                <Button
                                    className={classes.primaryBtn}
                                    variant="contained"
                                    color="primary"
                                    onClick={prevStepHandler}
                                >
                                    Back
                                </Button>
                            )}
                            <Button
                                className={classes.cancelBtn}
                                variant="contained"
                                color="default"
                                onClick={() => handleCloseModal()}
                            >
                                Cancel
                            </Button>
                            {state.stepValue === 1 && routeLabel === 'Doctor'
                                ? (
                                    <Button
                                        className={classes.primaryBtn}
                                        disabled={isDisable}
                                        variant="contained"
                                        color="primary"
                                        onClick={nextStepHandler}
                                    >
                                        Next
                                    </Button>
                                )
                                : (
                                    <Button
                                        className={classes.primaryBtn}
                                        disabled={isDisable}
                                        variant="contained"
                                        color="primary"
                                        onClick={onSubmit}
                                    >
                                        {state.userID ? 'Update' : 'Save'}
                                        {' '}
                                        {routeLabel}
                                    </Button>
                                )}
                        </Grid>
                    </Grid>
                </Box>
            </Fragment>
        );
    };

    /**
     * @function renderAdditionalField
     * @description render name field and user role field
     */
    const renderAdditionalField = () => {
        let isDisable = false;

        if (!state.isUserFromSameOrg && (userData && userData.user && userData.user.length > 0)) {
            isDisable = true;
        }
        if (state.isEmailVerified) {
            return (
                <Fragment>
                    <Grid item md={12} lg={12}>
                        <Box my={2}>
                            <TextField
                                fullWidth
                                id="outlined-basic"
                                // label="First Name"
                                label={`${routeLabel !== 'Doctor' ? routeLabel : 'First'} Name`}
                                variant="outlined"
                                name="userName"
                                value={state.formData.userName}
                                onChange={onHandleChange}
                                autoComplete="false"
                                disabled={isDisable}
                                required
                            />
                            <p className={classes.showError}>{(state.error.userName) ? "Name can't be blank. " : ''}</p>
                        </Box>
                    </Grid>
                    {routeLabel === 'Doctor'
                        ? (
                            <>
                                <Grid item md={12} lg={12}>
                                    <Box my={2}>
                                        <TextField
                                            fullWidth
                                            id="outlined-basic"
                                            label="Last Name"
                                            variant="outlined"
                                            name="lastName"
                                            onChange={onHandleChange}
                                            autoComplete="false"
                                            value={state.formData.lastName}
                                        />
                                    </Box>
                                </Grid>
                                {industryList.length > 0 && (
                                    <Grid item md={12} lg={12}>
                                        <Box my={2}>
                                            <FormControl fullWidth variant="outlined" required>
                                                <InputLabel id="demo-simple-select-filled-label">Industry</InputLabel>
                                                <Select
                                                    className={classes.layoutTypeWidth}
                                                    value={state.formData.industry}
                                                    name="industry"
                                                    label="Industry"
                                                    onChange={handleIndustryChange}
                                                    disabled={industryName}
                                                >
                                                    {industryList.map((industry, index) => (
                                                        <MenuItem
                                                            key={index + 1}
                                                            value={industry}
                                                        >
                                                            {industry}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                )}
                                {specialityList.length > 0
                                    && (
                                        <Grid item md={12} lg={12}>
                                            <Box my={2}>
                                                <FormControl fullWidth variant="outlined" required>
                                                    <InputLabel id="demo-simple-select-filled-label">Specialty</InputLabel>
                                                    <Select
                                                        className={classes.layoutTypeWidth}
                                                        value={state.formData.specialty}
                                                        name="specialty"
                                                        label="Specialty"
                                                        onChange={onHandleChange}
                                                        disabled={specialityName}
                                                    >
                                                        {specialityList.map((speciality, index) => (
                                                            <MenuItem
                                                                key={index + 1}
                                                                value={speciality}
                                                            >
                                                                {speciality}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    {(state.error.specialty) ? 'Please select specialty' : ''}
                                                </FormControl>
                                            </Box>
                                        </Grid>
                                    )}
                            </>
                        )
                        : (
                            <Grid item md={12} lg={12}>
                                <Box my={2}>
                                    <FormControl fullWidth variant="outlined" required>
                                        <InputLabel id="demo-simple-select-filled-label">User Role</InputLabel>
                                        <Select
                                            className={classes.layoutTypeWidth}
                                            value={state.formData.userRole}
                                            name="userRole"
                                            label="User Role"
                                            onChange={onHandleChange}
                                            disabled={isDoctorDashboard}
                                        >
                                            <MenuItem key={1} value="Admin">Admin</MenuItem>
                                            <MenuItem key={2} value="User">User</MenuItem>
                                        </Select>
                                        {(state.error.userRole) ? 'Please select user role' : ''}
                                    </FormControl>
                                </Box>
                            </Grid>
                        )}
                </Fragment>
            );
        }
        return '';
    };

    /**
     * @function renderValidationButton
     * @description render the validation button conditionally
     */
    const renderValidationButton = () => {
        if (!state.isEmailVerified) {
            return (
                <Box my={2}>
                    {validateError && <p className={classes.showError}>User is already registered, please try different email id.</p>}
                    <Button
                        className={classes.primaryBtn}
                        disabled={state.isShowValidateBtn}
                        variant="contained"
                        color="primary"
                        onClick={onVerifyEmail}
                    >
                        Verify Email
                    </Button>
                </Box>
            );
        }

        return '';
    };

    /**
     * @function renderDateCreation
     * @description render the user creation date
     */
    const renderDateCreation = () => {
        const isDisable = true;

        if (state.isDateCreation && routeLabel !== 'Doctor') {
            return (
                <Grid item md={12} lg={12}>
                    <Box my={2}>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Date of Joining"
                            variant="outlined"
                            name="userCreationDate"
                            value={state.userCreationDate}
                            onChange={onHandleChange}
                            autoComplete="false"
                            disabled={isDisable}
                        />
                    </Box>
                </Grid>
            );
        }
        return '';
    };

    const renderDoctorMoreField = () => {
        if (state.isEmailVerified) {
            return (
                <>
                    <Grid item md={12} lg={12}>
                        <Box my={2}>
                            <TextField
                                fullWidth
                                id="outlined-basic"
                                label="Phone No."
                                variant="outlined"
                                name="phoneNo"
                                onChange={onHandleChange}
                                autoComplete="false"
                                value={state.formData.phoneNo}
                            />
                        </Box>
                    </Grid>
                    <Grid item md={12} lg={12}>
                        <Box mb={1}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    className={classes.genricDate}
                                    disableToolbar
                                    variant="outlined"
                                    inputVariant="outlined"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    label="DOB"
                                    value={state.formData.dob}
                                    id="date-picker-inline"
                                    onChange={(e) => onHandleChange(e)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Box>
                    </Grid>
                    {!state.userID
                        && (
                            <>
                                <Grid item md={12} lg={12}>
                                    <Box my={2}>
                                        <TextField
                                            fullWidth
                                            id="outlined-basic"
                                            label="Org Name"
                                            variant="outlined"
                                            name="orgName"
                                            onChange={handleOrgNameChange}
                                            autoComplete="false"
                                            value={state.formData.orgName}
                                            disabled={state.userID}
                                            required
                                        />
                                    </Box>
                                    <p className={classes.showError}>{(state.error.orgName) ? 'Organization name already exists!' : ''}</p>
                                </Grid>
                                <Grid item md={12} lg={12}>
                                    <Box my={2}>
                                        <TextField
                                            fullWidth
                                            id="outlined-basic"
                                            label="Org Email"
                                            variant="outlined"
                                            name="orgEmail"
                                            onChange={onHandleChange}
                                            autoComplete="false"
                                            value={state.formData.orgEmail}
                                            disabled={state.userID}
                                        />
                                    </Box>
                                    <p className={classes.showError}>{(state.error.orgEmail) ? 'Enter the valid email.' : ''}</p>
                                </Grid>
                            </>
                        )}
                    <Grid item md={12} lg={12}>
                        <FormControlLabel
                            control={<Checkbox checked={state.formData.isExpert} onChange={onHandleChange} name="isExpert" />}
                            label="Is Expert"
                            labelPlacement="start"
                        />
                    </Grid>
                </>
            );
        }
        return null;
    };

    return (
        <Modal
            aria-labelledby="spring-modal-title"
            aria-describedby="spring-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleCloseModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <div className={classes.paper}>
                {loaderState && showLoader(loaderState)}
                <Grid container className={classes.modalHeader}>
                    <Grid item md={11}>
                        <Typography variant="h2" id="simple-modal-title">
                            Manage
                            {' '}
                            {state.stepValue === 2 && routeLabel === 'Doctor' ? 'PA/PV' : routeLabel === 'Doctor' ? 'Organisation' : `${routeLabel}s`}
                        </Typography>
                    </Grid>
                    <Grid item md={1} className={classes.buttonCont}>
                        <CloseIcon className={classes.closeIcon} onClick={() => handleCloseModal()} />
                    </Grid>
                </Grid>
                {routeLabel === 'Doctor' && <DoctorStepper stepValue={state.stepValue} />}
                <Box px={4} pt={2} pb={1}>
                    <Grid container className={classes.modalBady} ref={bodyRef}>
                        {state.stepValue === 1 && (
                            <Grid item md={12} lg={12}>
                                <div>
                                    <Box>
                                        <Paper elevation={0}>
                                            <Grid container>
                                                <Grid item md={12} lg={12}>
                                                    <Box my={2}>
                                                        <TextField
                                                            fullWidth
                                                            id="outlined-basic"
                                                            placeholder={`${routeLabel} Email`}
                                                            variant="outlined"
                                                            name="userEmail"
                                                            onChange={onHandleChange}
                                                            autoComplete="false"
                                                            value={state.formData.userEmail}
                                                            disabled={state.isEmailVerified}
                                                        />
                                                    </Box>
                                                    <p className={classes.showError}>{(state.error.userEmail) ? 'Enter the valid email.' : ''}</p>
                                                    {renderValidationButton()}
                                                </Grid>
                                                {renderAdditionalField()}
                                                {renderDateCreation()}
                                                {routeLabel === 'Doctor' && renderDoctorMoreField()}
                                            </Grid>
                                        </Paper>
                                    </Box>
                                </div>
                            </Grid>
                        )}
                        {state.stepValue === 2
                            && (
                                <>
                                    <POIAccess
                                        filterList={filterList}
                                        selected={selected}
                                        library={ProcedureLibrary}
                                        onSelectionChange={onSelectionChange}
                                        selectMultiple={selectMultiple}
                                        selectUser={selectedUser || state.formData}
                                    />
                                </>
                            )}

                    </Grid>
                </Box>
                <Grid container>
                    <Grid item xs={12} className={classes.modalFooter}>
                        <Grid container direction="row" justify="flex-end" alignItems="center">
                            {renderActionButton()}
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </Modal>
    );
};

export default ManageUser;
