import { Button } from '@material-ui/core';
import { TimelineContext } from '../../context';
import { buildSettingsTreePage, findComponent } from '../../helper/commonHelper';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useContext } from 'react';

const useStyles = makeStyles(() => ({
    widthSectPart: {
        minWidth: '100px',
        minHeight: '180px',
        maxHeight: '35%',
    },
    headerStyle: {
        borderBottom: '2px solid #CBCBCB',
        padding: '.5rem 1rem',
    },
    alignRight: {
        textAlign: 'right',
    },
    closeIconSTyle: {
        cursor: 'pointer',
        marginTop: '.5rem',
    },
    footerStyle: {
        padding: '.5rem 1rem',
    },
    primaryBtn: {
        maxHeight: '56px',
        padding: '.25rem 1rem',
        boxShadow: '0px 0px 20px -4px rgba(51,36,5,0.37)',
        '&:hover': {
            boxShadow: 'none',
        },
    },
    cancelBtn: {
        maxHeight: '56px',
        padding: '.25rem 1.5rem',
        boxShadow: 'none',
        margin: '0rem 1.5rem',
        '&:hover': {
            boxShadow: 'none',
        },
    },
}));

const ColumnChangeDialog = () => {
    const classes = useStyles();
    const {
        columnChangeDialog,
        setColumnChangeDialog,
        workshopComponent,
        columnValue,
        setUpdateFlag,
        updateFlag,
    } = useContext(TimelineContext);

    const handleClose = () => {
        setColumnChangeDialog(false);
        workshopComponent.props.flexTextField = columnValue;
        // if user cancel to chang the CF to Simple Form
        const colComponentList = [];
        buildSettingsTreePage(colComponentList, workshopComponent);
        if (colComponentList.length) {
            for (let i = 0, l = colComponentList.length; i < l; i += 1) {
                if (colComponentList[i].type === 'TextAreaCF') {
                    colComponentList[i].props['single-continuous'] = 'single';
                }
            }
            workshopComponent.props['single-continuous'] = 'single';
            setUpdateFlag(!updateFlag);
        }
    };
    /**
     * updates removes second and third column of the components which as type 'row'
     * @param {*} arr
     * @returns
     */
    function updateComponentsForTypeRow(arr) {
        function recursiveUpdate(array) {
            for (let i = 0; i < array.length; i += 1) {
                const item = array[i];
                if (item.type.toLowerCase() === 'row') {
                    item.props.flexTextField = workshopComponent?.props?.flexTextField;
                    item.props.isSimpleForm = true;
                    item.props.components.length = 1;
                }
                if (Array.isArray(item.props.components)) {
                    recursiveUpdate(item.props.components);
                }
            }
        }
        // Call the recursive function to update the 'components' for 'row' objects
        recursiveUpdate(arr);
        return arr;
    }

    const submitHandler = () => {
        if (!workshopComponent?.isProgression && (workshopComponent?.props?.flexTextField > 1
            || (workshopComponent?.props['single-continuous'] === 'continuous'))) {
            const tempComp = workshopComponent.props.components;
            const rowComponentUpdated = updateComponentsForTypeRow(tempComp);
            workshopComponent.props.components = rowComponentUpdated;
        }
        workshopComponent.props.isSimpleForm = true;
        setColumnChangeDialog(false);
    };

    return (
        <Dialog
            onClose={handleClose}
            open={((workshopComponent?.props['single-continuous'] === 'continuous')
            || !workshopComponent?.props?.isSimpleForm && columnChangeDialog) && !workshopComponent.isProgression}
            fullWidth
            classes={{ paperWidthSm: classes.widthSectPart }}
        >
            <DialogTitle classes={{ root: classes.headerStyle }}>
                Simple Form
            </DialogTitle>
            <DialogContent>
                {'Are you sure you want to update column! it\'ll make it a simple form'}
            </DialogContent>
            <DialogActions classes={{ root: classes.footerStyle }}>
                <Button
                    className={classes.cancelBtn}
                    onClick={handleClose}
                    variant="contained"
                    color="default"
                >
                    Cancel
                </Button>
                <Button
                    className={classes.primaryBtn}
                    onClick={submitHandler}
                    variant="contained"
                    color="primary"
                >
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ColumnChangeDialog;
