import '../css/style.css';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    Grid,
    Typography,
} from '@material-ui/core';
import { TimelineContext } from '../context';
import { findParent, setComponentType } from '../helper/commonHelper';
import { makeStyles } from '@material-ui/core/styles';
import { standardHeaders } from './utils/StandardStyles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import DataMatrixRClick from './DataMatrixRClick';
import EditIcon from '@material-ui/icons/Edit';
import React, { useContext, useEffect, useState } from 'react';
import TemplateDialog from './TemplateDialog';

const functionDisplayData = [
    {
        key: 'title',
        type: 'StaticLabel',
        label: 'Title',
        isBasic: true,
        isAdvance: true,
        isAdmin: true,
    },
    {
        key: 'supergroup',
        type: 'StaticLabel',
        label: 'Component Type',
        value: 'N/A',
        isBasic: false,
        isAdvance: false,
        isAdmin: true,
    },
    {
        key: 'componentSuperGroup',
        type: 'StaticLabel',
        label: 'Component Super Group',
        value: '',
        isBasic: false,
        isAdvance: false,
        isAdmin: true,
    },
    {
        key: 'componentGroup',
        type: 'StaticLabel',
        label: 'Component Group',
        value: '',
        isBasic: false,
        isAdvance: false,
        isAdmin: true,
    },
    {
        key: 'category',
        type: 'StaticLabel',
        label: 'Component',
        value: '',
        isBasic: false,
        isAdvance: false,
        isAdmin: true,
    },
];

const protocolEditorProps = { display: functionDisplayData, poi: true };

const useRowStyles = makeStyles(() => ({
    accessControlHeaderActive: {
        background: '#b1b1b1',
        padding: 4,
        textAlign: 'center',
        margin: '4px 4px 4px 0px',
    },
    accessControlHeader: {
        background: '#efefef',
        textAlign: 'center',
        padding: 4,
        margin: '4px 4px 4px 0px',
    },
    sectionHeader: {
        margin: 0,
        minHeight: 'auto !important',
        height: 32,
        fontSize: 13,
        border: '1px solid #DEE7F8',
        ...standardHeaders.sectionHeader,
        position: 'relative',
        flexDirection: 'row-reverse',
        background: 'rgba(6,103,133,0.09)',
        color: '#066785',
    },
    sectionHeader1: {
        margin: 0,
        minHeight: 'auto !important',
        height: 32,
        fontSize: 12,
        border: '1px solid #DEE7F8',
        ...standardHeaders.sectionHeader,
        position: 'relative',
        flexDirection: 'row-reverse',
        background: '#DEE7F8',
        color: '#1b1b1b',
    },
    iconButton: {
        margin: '0 8px 0 2px',
        padding: 0,
        background: '#bbb',
        color: '#FFFFFF',
        borderRadius: 2,
    },
    expandedAcc: {
        background: '#066785',
        color: '#FFFFFF',
        transition: 'none',
    },
    expandedAcc1: {
        background: '#DEE7F8',
        color: '#FFFFFF',
        transition: 'none',
    },
    expandedAccIcon: {
        transform: 'rotateZ(-90deg) translateY(-10px)',
        color: '#066785',
        height: 36,
    },
    checkBoxStyle: {
        '& svg': {
            height: 15,
            width: 15,
            color: '#000000',
        },
    },
    fontSize12Px: {
        fontSize: 12,
        padding: '4px 0px 4px 4px',
    },
    dmSettingCompCont: {
        padding: 2,
    },
    viewAssignCont: {
        background: '#FFFFFF',
        border: '0.5px solid #066785',
        borderRadius: 2,
        width: 73,
        height: 18,
        fontSize: 10,
    },
    viewCont: {
        color: '#066785',
        background: '#FFFFFF',
        textAlign: 'center',
        paddingTop: 2,
    },
    assignCont: {
        color: '#FFFFFF',
        background: '#066785',
        textAlign: 'center',
        paddingTop: 2,
    },
    dmSettingCompAccSummary: {
        textAlign: 'left',
        color: '#1b1b1b',
    },
    dmSettingCompAccDetail: {
        display: 'block',
        padding: 2,
        marginBottom: 2,
    },
    checkBoxCont: {
        borderRight: '1px solid #DEE7F8',
        borderBottom: '1px solid #DEE7F8',
        textAlign: 'center',
        padding: 1,
    },
    dmAccDetailCont: {
        border: '1px solid #DEE7F8',
        borderTop: 'none',
    },
    dmAccDetailCont1: {
        border: '1px solid #DEE7F8',
    },
    gridCellAssign: {
        height: 36,
        borderBottom: '1px solid #DEE7F8',
        borderLeft: '1px solid #DEE7F8',
        fontSize: 12,
    },
    gridCellAssign1: {
        height: 36,
        border: '1px solid #DEE7F8',
        borderTop: 'none',
        fontSize: 12,
        textAlign: 'center',
        cursor: 'pointer',
    },
    dmViewLeftCont: {
        padding: '8px 4px',
        borderRight: '1px solid #DEE7F8',
        fontSize: 12,
    },
    dmViewRightCont: {
        padding: '8px 4px',
        fontSize: 12,
        textAlign: 'center',
    },
    gridCell1: {
        padding: '8px 4px',
        textAlign: 'left',
        wordWrap: 'break-word',
        fontSize: '.75rem',
    },
    gridCell2: {
        padding: '8px 4px',
        borderLeft: '1px solid #DEE7F8',
        textAlign: 'center',
        wordWrap: 'break-word',
        fontSize: '.75rem',
    },
    gridCellChild: {
        padding: '8px 4px',
        borderLeft: '1px solid #DEE7F8',
        textAlign: 'center',
        wordWrap: 'break-word',
        fontSize: '.75rem',
    },
    accContMain: {
        margin: '0px !important ',
    },
    fontSize13Px: {
        fontSize: 13,
    },
    marginTop2Px: {
        marginTop: 2,
    },
    textColor1: {
        color: '#066785',
        borderRight: '1px solid #DEE7F8',
    },
    borderLeft: {
        borderRight: '1px solid #DEE7F8',
        padding: '9px 1px',
        fontSize: 12,
        textAlign: 'center',
    },
    textTransformCls: {
        textTransform: 'capitalize',
        height: 'inherit',
        alignSelf: 'center',
    },
    rClickMenuText: {
        textAlign: 'center',
        padding: '8px 4px',
    },
    rClickMenusCont: {
        textAlign: 'center',
        maxHeight: '57vh',
        overflowY: 'scroll',
        overflowX: 'hidden',
        fontSize: 12,
        borderLeft: '1px solid #DEE7F8',
        padding: 2,
    },
    rClickMenusSubCont: {
        width: '100%',
        padding: 2,
    },
    rClickMenusSubCont1: {
        border: '1px solid #DEE7F8',
        padding: '8px 4px',
        fontSize: 12,
    },
    userTypeCont: {
        width: '99%',
        margin: '0px 4px',
    },
    editIconStyleWrapper: {
        textAlign: 'right',
    },
    editIconStyle: {
        cursor: 'pointer',
        width: 16,
        height: 16,
    },
}));

const groupBy = (key, acc, value, additionalData = {}) => {
    acc[key] = acc[key] || { items: [] };
    acc[key] = { ...acc[key], ...additionalData };
    acc[key].items = [...acc[key].items, value];
    return acc;
};

const DataMatrixSetting = ({
    title, rClickMenu = [], templates = [],
}) => {
    const classes = useRowStyles();
    const [expandDMSetting, setExpandDMSetting] = useState(true);
    const [expandFunctionTL, setExpandFunctionTL] = useState('functionAccodion');
    const [state, setState] = useState({
        user: 1,
        category: 1,
        functionView: false,
        functionAccessView: 'isBasic',
        settingsFunction: [],
    });
    const [dialogOpen, setDialogOpen] = useState(false);
    const [, setSelectedTemplate] = useState();
    const {
        selectedDMUAComponent, getLocationOfTargetComponent, isProgressionDM, contextMenuTarget, selectedColIndex, saveTemplate,
    } = useContext(TimelineContext);
    const { column } = contextMenuTarget;
    setComponentType(protocolEditorProps, selectedDMUAComponent, null);

    const templateLibraryComp = templates?.find((t) => t.id === selectedDMUAComponent?.templateId);

    const handleAccordion = (panel) => (event, isExpanded) => {
        setExpandFunctionTL(isExpanded ? panel : false);
    };

    const handleFunctionView = () => {
        setState({ ...state, functionView: !state.functionView });
    };

    const handleDisplayAccessViewBtn = (e, value, settingType = null) => {
        e.stopPropagation();
        if (settingType) {
            setState({ ...state, functionAccessView: value });
        }
    };

    const inputChangeHandlerFunction = (e, key) => {
        let settings = {};
        if (selectedDMUAComponent.props.settings) {
            settings = { ...selectedDMUAComponent.props.settings };
            settings.function = settings.function || [];
            const compIndex = settings.function.findIndex((d) => d.key === key);
            if (compIndex !== -1) {
                const functinObj = settings.function[compIndex];
                functinObj.accessView = { ...functinObj.accessView, [e.target.name]: e.target.checked };
            } else if (compIndex === -1) {
                const accessViewObj = {
                    key,
                    accessView: { [e.target.name]: e.target.checked },
                };
                settings.function.push(accessViewObj);
            }
        } else {
            settings = {
                function: [{
                    key,
                    accessView: { [e.target.name]: e.target.checked },
                }],
            };
        }
        selectedDMUAComponent.props.settings = { ...settings };
        setState({ ...state, settingsFunction: settings.function });
    };

    const getSelectedTemplate = () => {
        let obj = {};
        if (templateLibraryComp) {
            obj = {
                group: templateLibraryComp.group || 'Protocol Only',
                id: templateLibraryComp.id,
                name: templateLibraryComp.name,
                supergroup: templateLibraryComp?.supergroup || 'Protocol Only',
                template: { ...templateLibraryComp },
                type: templateLibraryComp.type,
                category: templateLibraryComp.category,
                subCategory: templateLibraryComp.sub_category,
            };
        }
        return obj;
    };

    /** callback from dialogbox */
    const onDialogSave = (payload) => {
        templateLibraryComp.group = payload?.group;
        templateLibraryComp.supergroup = payload?.supergroup;
        templateLibraryComp.category = payload.category;
        templateLibraryComp.subCategory = payload.subCategory;
        templateLibraryComp.group = payload.group;
        templateLibraryComp.name = payload.name;
        saveTemplate(templateLibraryComp, setSelectedTemplate);
    };

    const openTMDialog = () => {
        setDialogOpen(true);
        setState({ ...state, templatePayLoad: getSelectedTemplate() });
    };

    const groupBySettingDisplay = () => {
        const isProgression = !!isProgressionDM;
        const routeOfTheSelectedComp = getLocationOfTargetComponent(selectedDMUAComponent, selectedColIndex, false, null, isProgression);
        let locationMap = [];
        let components = isProgression ? column.props.progressions : column.props.components;
        routeOfTheSelectedComp.forEach((val) => {
            locationMap.push(components[val]);
            components = components[val].props.components;
        });
        locationMap.splice(locationMap.length - 1, 1);
        const resultParent = [];
        const propsData = isProgression ? contextMenuTarget.column.props.progressions : contextMenuTarget.column.props.components;
        findParent(propsData, selectedDMUAComponent.id, resultParent);
        if (resultParent.length > 0 && resultParent) {
            locationMap = [...locationMap, ...resultParent[0].props.components];
        } else {
            locationMap = [...locationMap, ...propsData];
        }
        const data = locationMap.reduce((lm, lmValue) => {
            if (lmValue.type === 'Text') {
                lm = groupBy(lmValue.props.type, lm, lmValue, {
                    type: lmValue.props.type,
                });
            } else if (lmValue.type === 'Protocol') {
                lm = groupBy(lmValue.type, lm, lmValue, {
                    type: lmValue.type,
                });
            }
            return lm;
        }, {});
        return data;
    };

    const renderFunctionSettingsDisplayView = () => {
        let renderItem = null;
        renderItem = (
            <Grid container>
                {functionDisplayData.map((data) => (
                    <Grid item xs={12}>
                        {state.settingsFunction.find((sf) => sf.key === data.key)?.accessView[state.functionAccessView]
                            && (
                                <Grid container alignItems="center" justify="center" className={classes.dmAccDetailCont}>
                                    <Grid item xs={4} className={classes.dmViewLeftCont}>
                                        {data.label}
                                    </Grid>
                                    <Grid item xs={8} className={classes.dmViewRightCont}>
                                        {data.key === 'title' ? selectedDMUAComponent.props[data.key] : selectedDMUAComponent[data.key]}
                                    </Grid>
                                </Grid>
                            )}
                    </Grid>
                ))}
            </Grid>
        );
        return renderItem;
    };

    const renderFunctionSettingsAssignView = () => (
        <Grid container>
            {functionDisplayData.map((data) => (
                <Grid item xs={12}>
                    <Grid container alignItems="center" justify="center">
                        <Grid
                            item
                            xs={3}
                            className={classes.gridCellAssign}
                            style={{ padding: data.label.length > 17 ? '0px 4px' : '8px 4px' }}
                        >
                            {data.label}
                        </Grid>

                        <Grid
                            item
                            xs={3}
                            className={classes.gridCellAssign1}
                            style={{
                                color: data.label === 'Title' ? '#066785' : 'inherit',
                                fontWeight: data.label === 'Component' ? 'bold' : 'inherit',
                                padding: selectedDMUAComponent?.props[data.key]?.length > 19
                                    || selectedDMUAComponent[data.key]?.length > 19 ? '0px 4px' : '8px 4px',
                            }}
                        >
                            {
                                data.key === 'title'
                                    ? selectedDMUAComponent.props[data.key]
                                    : selectedDMUAComponent[data.key]
                            }
                        </Grid>
                        <Grid item xs={2} className={classes.checkBoxCont}>
                            <Checkbox
                                className={classes.checkBoxStyle}
                                name="isBasic"
                                onChange={(e) => inputChangeHandlerFunction(e, data.key)}
                                checked={state.settingsFunction?.find((sd) => sd.key === data.key)?.accessView.isBasic}
                            />
                        </Grid>
                        <Grid item xs={2} className={classes.checkBoxCont}>
                            <Checkbox
                                className={classes.checkBoxStyle}
                                name="isAdvanced"
                                onChange={(e) => inputChangeHandlerFunction(e, data.key)}
                                checked={state.settingsFunction?.find((sd) => sd.key === data.key)?.accessView.isAdvanced}
                            />
                        </Grid>
                        <Grid item xs={2} className={classes.checkBoxCont}>
                            <Checkbox
                                className={classes.checkBoxStyle}
                                name="isUserView"
                                onChange={(e) => inputChangeHandlerFunction(e, data.key)}
                                checked={state.settingsFunction?.find((sd) => sd.key === data.key)?.accessView.isUserView}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );

    const renderSettingDisplayView = () => {
        const data = groupBySettingDisplay();

        let protocolItem = null;
        if (data) {
            protocolItem = (
                <>
                    {Object.keys(data).map((key) => (
                        <Grid container className={classes.dmAccDetailCont}>
                            <Grid item xs={3} className={[classes.fontSize12Px, classes.textTransformCls].join(' ')}>
                                {key !== 'Protocol' ? `${key} Header` : key}
                            </Grid>
                            <Grid item xs={9}>
                                {data[key].items.map((item, index) => (
                                    <Grid container>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{
                                                borderTop: data[key].items.length > 1 && index > 0 ? '1px solid #DEE7F8' : 'none',
                                            }}
                                            className={classes.gridCellChild}
                                        >
                                            {item?.props?.text || item?.props?.title || ''}
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    ))}
                </>
            );
        }
        return (
            <Grid item xs={12}>
                <Grid container justify="center">
                    <Grid item xs={12}>
                        <Grid container className={classes.dmAccDetailCont1}>
                            <Grid item xs={3} className={classes.gridCell1}>Column</Grid>
                            <Grid item xs={9} className={classes.gridCell2}>{contextMenuTarget?.column?.props?.title}</Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container className={classes.dmAccDetailCont}>
                            <Grid item xs={3} className={classes.gridCell1}>Section</Grid>
                            <Grid item xs={9} className={classes.gridCell2}>
                                {isProgressionDM ? 'Progression' : 'Protocol'}
                                {' '}
                                Section
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {protocolItem}
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    const renderRClickSetting = () => (
        <Grid container>
            <Grid item xs={12}>
                <Grid container alignItems="center" justify="center" className={classes.dmAccDetailCont1}>
                    <Grid item xs={12} className={classes.rClickMenusCont}>
                        {rClickMenu && rClickMenu.length > 0 && (
                            <DataMatrixRClick templates={rClickMenu} />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );

    const renderDMSetting = () => {
        let dataMatrixSettingComp = null;

        dataMatrixSettingComp = (
            <>
                <Grid container className={classes.dmSettingCompCont}>
                    <Grid item xs={12}>
                        <Accordion
                            classes={{ expanded: classes.accContMain }}
                            expanded={expandFunctionTL === 'functionAccodion'}
                            onChange={handleAccordion('functionAccodion')}
                        >
                            <AccordionSummary
                                expandIcon={
                                    expandFunctionTL === 'functionAccodion' ? (
                                        <ArrowDropUpIcon />
                                    ) : (
                                        <ArrowDropDownIcon />
                                    )
                                }
                                aria-controls="panel1a-content"
                                className={`${classes.sectionHeader1} ${classes.fontSize13Px}`}
                                classes={{
                                    expandIcon: (expandDMSetting && expandFunctionTL === 'functionAccodion')
                                        ? classes.iconButton : classes.expandedAccIcon,
                                    expanded: classes.expandedAcc1,
                                }}
                            >
                                <Grid container className={classes.dmSettingCompAccSummary}>
                                    <Grid item xs={9} className={classes.marginTop2Px}>
                                        Function/TL Organization
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Grid container onClick={(e) => e.stopPropagation()} className={classes.viewAssignCont}>
                                            <Grid
                                                item
                                                xs={6}
                                                className={state.functionView ? classes.assignCont : classes.viewCont}
                                                onClick={handleFunctionView}
                                            >
                                                CIP
                                            </Grid>
                                            <Grid
                                                item
                                                xs={6}
                                                className={!state.functionView ? classes.assignCont : classes.viewCont}
                                                onClick={handleFunctionView}
                                            >
                                                DM
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails className={classes.dmSettingCompAccDetail}>
                                <Grid container alignItems="center" justify="center" className={classes.dmAccDetailCont1}>
                                    {state.functionView && (
                                        <Grid item xs={12}>
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="space-between"
                                                className={classes.userTypeCont}
                                            >
                                                <Grid item xs={4}>
                                                    <Typography
                                                        className={state.functionAccessView === 'isBasic'
                                                            ? classes.accessControlHeaderActive : classes.accessControlHeader}
                                                        onClick={(e) => handleDisplayAccessViewBtn(e, 'isBasic', 'function')}
                                                    >
                                                        All
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography
                                                        className={state.functionAccessView === 'isAdvanced'
                                                            ? classes.accessControlHeaderActive : classes.accessControlHeader}
                                                        onClick={(e) => handleDisplayAccessViewBtn(e, 'isAdvanced', 'function')}
                                                    >
                                                        Advanced
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography
                                                        className={state.functionAccessView === 'isUserView'
                                                            ? classes.accessControlHeaderActive : classes.accessControlHeader}
                                                        onClick={(e) => handleDisplayAccessViewBtn(e, 'isUserView', 'function')}
                                                    >
                                                        Basic
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                    {!state.functionView && (
                                        <Grid
                                            item
                                            xs={6}
                                            className={`${classes.fontSize12Px} ${classes.textColor1}`}
                                            style={{ padding: 6 }}
                                        >
                                            <Grid container className={classes.marginTop2Px}>
                                                <Grid item xs={9} className={classes.fontSize13Px}>MG</Grid>
                                                <Grid item xs={3} className={classes.editIconStyleWrapper}>
                                                    <EditIcon className={classes.editIconStyle} onClick={openTMDialog} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                    {!state.functionView
                                        && (
                                            <>
                                                <Grid item xs={2} className={classes.borderLeft}>Component</Grid>
                                                <Grid item xs={2} className={classes.borderLeft}>Advance</Grid>
                                                <Grid item xs={2} className={classes.borderLeft}>Basic</Grid>
                                            </>
                                        )}
                                </Grid>
                                <Grid container alignItems="center" justify="center">
                                    <Grid item xs={12}>
                                        {state.functionView ? renderFunctionSettingsDisplayView() : renderFunctionSettingsAssignView()}
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion
                            classes={{ expanded: classes.accContMain }}
                            expanded={expandFunctionTL === 'settingAccordion'}
                            onChange={handleAccordion('settingAccordion')}
                        >
                            <AccordionSummary
                                expandIcon={
                                    expandFunctionTL === 'settingAccordion' ? (
                                        <ArrowDropUpIcon />
                                    ) : (
                                        <ArrowDropDownIcon />
                                    )
                                }
                                aria-controls="panel1a-content"
                                className={`${classes.sectionHeader1} ${classes.fontSize13Px}`}
                                classes={{
                                    expandIcon: (expandDMSetting && expandFunctionTL === 'settingAccordion')
                                        ? classes.iconButton : classes.expandedAccIcon,
                                    expanded: classes.expandedAcc1,
                                }}
                            >
                                <Grid container className={classes.dmSettingCompAccSummary}>
                                    <Grid item xs={9} className={classes.marginTop2Px}>
                                        Timeline Display
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails className={classes.dmSettingCompAccDetail}>
                                <Grid container alignItems="center" justify="center">
                                    {renderSettingDisplayView()}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion
                            classes={{ expanded: classes.accContMain }}
                            expanded={expandFunctionTL === 'rClickAccordion'}
                            onChange={handleAccordion('rClickAccordion')}
                        >
                            <AccordionSummary
                                expandIcon={
                                    expandFunctionTL === 'rClickAccordion' ? (
                                        <ArrowDropUpIcon />
                                    ) : (
                                        <ArrowDropDownIcon />
                                    )
                                }
                                aria-controls="panel1a-content"
                                className={`${classes.sectionHeader1} ${classes.fontSize13Px}`}
                                classes={{
                                    expandIcon: (expandDMSetting && expandFunctionTL === 'rClickAccordion')
                                        ? classes.iconButton : classes.expandedAccIcon,
                                    expanded: classes.expandedAcc1,
                                }}
                            >
                                <Grid container className={classes.dmSettingCompAccSummary}>
                                    <Grid item xs={9} className={classes.marginTop2Px}>
                                        R-Click Menu
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails className={classes.dmSettingCompAccDetail}>
                                <Grid container alignItems="center" justify="center">
                                    {renderRClickSetting()}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>

                    </Grid>
                </Grid>
            </>
        );
        return dataMatrixSettingComp;
    };

    const renderTemplate = () => (
        <TemplateDialog
            open={dialogOpen}
            setOpen={setDialogOpen}
            type="prompt"
            payload={state.templatePayLoad}
            setPayload={onDialogSave}
            inTemplateLibrary
        />
    );

    useEffect(() => {
        setState({
            user: 1,
            category: 1,
            functionView: false,
            displayView: false,
            displayAccessView: 'isBasic',
            functionAccessView: 'isBasic',
            settingsDisplay: [],
            settingsFunction: [],
        });
    }, [selectedDMUAComponent.id]);

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Accordion expanded={expandDMSetting} onChange={() => setExpandDMSetting(!expandDMSetting)}>
                        <AccordionSummary
                            expandIcon={
                                expandDMSetting ? (
                                    <ArrowDropUpIcon />
                                ) : (
                                    <ArrowDropDownIcon />
                                )
                            }
                            aria-controls="panel1a-content"
                            className={classes.sectionHeader}
                            classes={{
                                expandIcon: expandDMSetting ? classes.iconButton : classes.expandedAccIcon,
                                expanded: classes.expandedAcc,
                            }}
                        >
                            <span>
                                {title}
                            </span>
                        </AccordionSummary>
                        <AccordionDetails className={classes.dmSettingCompAccDetail}>
                            {renderDMSetting()}
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                {dialogOpen ? renderTemplate() : null}
            </Grid>
        </>
    );
};

export default DataMatrixSetting;
