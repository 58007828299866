/* eslint-disable react/no-danger */
import {
    Button, Dialog, DialogActions, DialogContent, Grid, Typography,
} from '@material-ui/core';
import { Container } from './Container';
import { TimelineContext } from '../context';
import { makeStyles } from '@material-ui/core/styles';
import React, { Fragment, useContext } from 'react';

const useStyles = makeStyles(() => ({
    dialogPaper: {
        margin: '5rem',
    },
    dialogCont: {
        textAlign: 'center',
    },
    heading1: {
        fontSize: 15,
        fontWeight: 500,
        color: '#011E27',
        padding: '1rem',
    },
    heading2: {
        fontSize: 11,
        fontWeight: 500,
        color: '#1C1C1C',
        padding: '.5rem',
        borderTop: '1.800000011920929px solid #C6C6C6',
    },
    borderCont: {
        border: '1.800000011920929px solid #C6C6C6',
        borderBottom: 'none',
    },
    border: {
        borderTop: '0.800000011920929px solid #C6C6C6',
        borderBottom: '0.800000011920929px solid #C6C6C6',
    },
    componentBorder: {
        border: '0.800000011920929px solid #C6C6C6',
        borderTop: 'none',
    },
}));

export default function CompareAllDialog(props) {
    const classes = useStyles();
    const {
        onClose, open, title, data, ...other
    } = props;
    const { pageComponent } = useContext(TimelineContext);
    const {
        LAGayne,
        Medguide,
        MedguideSSR,
        OCCS,
    } = pageComponent;

    const handleCancel = () => {
        onClose();
    };

    const renderCompView = (compObj, type) => {
        if (compObj.length === 0) {
            return null;
        }
        const pageViewData = compObj.map((comp, index) => (
            <Fragment>
                <div className={classes.borderCont}>
                    {comp.type === 'HTML' ? (
                        <div
                            className={classes.border}
                            key={index}
                            dangerouslySetInnerHTML={{ __html: comp[type].html }}
                        />
                    ) : null}
                </div>
                <div className={classes.componentBorder}>
                    {comp.type !== 'HTML' ? <Container previewDomain={type} props={{ components: [comp] }} /> : null}
                </div>
            </Fragment>
        ));
        return pageViewData;
    };

    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="false"
            style={{ margin: '5rem' }}
            fullWidth
            aria-labelledby="pageview-dialog-title"
            open={open}
            {...other}
        >
            <DialogContent dividers>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                    spacing={5}
                    className={classes.dialogCont}
                >
                    <Grid item xs={3}>
                        <div className={classes.borderCont}>
                            <Typography className={classes.heading1}>
                                Medguide (Default)
                            </Typography>
                            <Typography className={classes.heading2}>{Medguide.title}</Typography>
                        </div>
                        {Medguide.components && renderCompView(Medguide.components, 'Medguide')}
                    </Grid>
                    <Grid item xs={3}>
                        <div className={classes.borderCont}>
                            <Typography className={classes.heading1}>
                                Medguide SSR
                            </Typography>
                            <Typography className={classes.heading2}>{MedguideSSR.title}</Typography>
                        </div>
                        {MedguideSSR.components && renderCompView(MedguideSSR.components, 'MedguideSSR')}
                    </Grid>
                    <Grid item xs={3}>
                        <div className={classes.borderCont}>
                            <Typography className={classes.heading1}>
                                OCCS
                            </Typography>
                            <Typography className={classes.heading2}>{OCCS.title}</Typography>
                        </div>
                        {OCCS.components && renderCompView(OCCS.components, 'OCCS')}
                    </Grid>
                    <Grid item xs={3}>
                        <div className={classes.borderCont}>
                            <Typography className={classes.heading1}>
                                LAGyne
                            </Typography>
                            <Typography className={classes.heading2}>{LAGayne.title}</Typography>
                        </div>
                        {LAGayne.components && renderCompView(LAGayne.components, 'LAGayne')}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} variant="contained" style={{ marginRight: '2rem', color: '#464646' }}>
                    <Typography variant="body2">Close</Typography>
                </Button>
            </DialogActions>
        </Dialog>
    );
}
