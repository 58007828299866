import { useMutation, useQuery } from '@apollo/react-hooks';
import React, {
    createContext,
} from 'react';
import gql from 'graphql-tag';
import httpHelper from '../helper/httpHelper';

const IMAGES = gql`
query Images($orgID: uuid) {
    media(where: {
        organization_id: {_eq: $orgID},
        is_delete: {_eq: false}, _or: [{filetype: {_like: "image/%"}}, {filetype: {_like: "video/%"}}]}, order_by: {updated_at: desc}) {
    created_at
    filetype
    id
    name
    url
    is_youtube
    updated_at
  }
}
`;

const ADD_MEDIA = gql`
mutation AddMedia( $name:String, $url:String, $filetype:String, $orgID:uuid, $isYoutube:Boolean, $activeFolderId: uuid ) {
    insert_media_one(object: {
        name: $name,
        url: $url,
        organization_id: $orgID,
        filetype: $filetype,
        is_youtube: $isYoutube,
        folder_id: $activeFolderId
    }) {
        id
        url
        filetype
        name
        is_youtube
        created_at
        updated_at
    }
  }
`;

export const Context = createContext({});

/**
 * Upload Image function and start progress bar
 * @return {[type]} [description]
 */
export const Provider = ({ bearerToken, selectedOrg, children }) => {
    // const {
    //     found, foundtemplateList,
    // } = useContext(TimelineContext);

    // let found = true;
    // found = getRoutePath().match(/timelines/);

    // const foundtemplateList = getRoutePath().match(/templates/);
    // const foundPatientsList = getRoutePath().match(/patients/);
    // const foundUsersList = getRoutePath().match(/users/);
    // const foundDoctorsList = getRoutePath().match(/doctors/);

    const { loading: imagesLoading, error: imageLoadError, data: imageData } = useQuery(IMAGES, { variables: { orgID: selectedOrg.id } });
    const [addMedia] = useMutation(
        ADD_MEDIA,
        {
            refetchQueries: [{ query: IMAGES, variables: { orgID: selectedOrg.id } }],
        }
    );
    const images = !imagesLoading ? imageData?.media : null;
    const uploadImage = (activeFolderId, mediaFile, onProgress, onComplete, onError) => {
        if (mediaFile && mediaFile.length > 0) {
            const selectedMedia = [...mediaFile];
            const formData = new FormData();
            selectedMedia.forEach((media) => {
                formData.append('media', media);
            });
            const update = () => {
                // const perc = (evt && evt.loaded && evt.total) ? Math.round((evt.loaded * 100) / evt.total) : 0;
                onProgress();
            };
            const mediaUploadComplete = ({ data }) => {
                if (data?.length > 0) {
                    for (let i = 0, l = data.length; i < l; i += 1) {
                        const { name, url } = data[i];
                        const isImage = name.includes('png') || name.includes('jpg') || name.includes('jpeg');
                        addMedia({
                            variables: {
                                name,
                                filetype: isImage ? `image/${data[i].name.split('.').pop()}` : `video/${data[i].name.split('.').pop()}`,
                                orgID: selectedOrg.id,
                                url,
                                isYoutube: false,
                                activeFolderId,
                            },
                        }).then(({ data: { insert_media_one: insertMediaResult } }) => {
                            onComplete(insertMediaResult);
                        });
                    }
                }
            };
            const httpObj = {
                url: 'storage/upload',
                method: 'POST',
                data: formData,
                headers: { Authorization: `Bearer ${bearerToken}` },
            };
            httpHelper(
                httpObj,
                mediaUploadComplete,
                onError,
                false,
                update
            );
        }
    };

    const youtubeVideoUpload = (data, onProgress, onComplete, onError) => {
        addMedia({
            variables: {
                name: data[0].name,
                filetype: 'video/mp4',
                orgID: selectedOrg.id,
                url: data[0].url,
                isYoutube: true,
                activeFolderId: data[0]?.activeFolderId,
            },
        }).then(({ data: { insert_media_one: insertMediaResult } }) => {
            onComplete(insertMediaResult);
        }).catch((error) => {
            onError(error);
        });
    };

    const mediaContext = {
        imagesLoading,
        imageLoadError,
        images,
        uploadImage,
        youtubeVideoUpload,
    };

    // pass the value in provider and return
    return <Context.Provider value={mediaContext}>{children}</Context.Provider>;
};

export const { Consumer } = Context;
