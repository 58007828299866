/* eslint-disable no-plusplus */
import { Container, ContainerPreview } from './Container';
import {
    FilterSettings, buildSettingsTreePage, findPage, setActiveComponent,
} from '../helper/commonHelper';
import { MenuDefaults, buildComponentMenu } from './utils/EditorMenu';
import { Settings, makeInlineEditor } from './Settings';
import { TimelineContext, WorkshopContext } from '../context';
import { componentWithId, updateComponentCallback } from './Atoms';
import { copyTemplate } from '../context/util';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useRecoilCallback, useRecoilState } from 'recoil';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import ImageEditorDialog from './dialog/ImageEditorDialog';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React, {
    useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import Typography from '@material-ui/core/Typography';
import WarningDialog from './WarningDialog';

const useStyles = makeStyles(() => createStyles({
    formControl: {
        marginTop: 10,
        minWidth: 260,
    },
    h2: {
        background: '#B4B4B4',
        padding: '4px',
        marginBottom: '10px',
    },
    titleStyle: {
        fontSize: 11,
        padding: '0px',
        maxHeight: 13,
        borderTop: '.5px solid #CBCBCB',
        alignItems: 'start',
    },
    captionContainer: {
        minHeight: 20,
        padding: '2px 0px 0px 0px',
        '&:last-child': {
            paddingBottom: 0,
        },
    },
    captionTypography: {
        margin: 0,
        fontSize: 11,
        textAlign: 'center',
        lineHeight: '12px',
        fontWeight: '400',
    },
    selectedComp: {
        background: 'cornflowerblue',
    },
    hideTextCls: {
        background: '#DEDEDE',
    },
    removeBorderRad: {
        borderRadius: '0px',
        boxShadow: 'none',
        borderBottom: '0.5px solid rgb(203, 203, 203)',
        borderLeft: '0.4px solid rgb(203, 203, 203)',
    },
    titleViewCls: {
        maxHeight: 15,
        minHeight: 13,
        padding: '0px',
    },
    scrollCls: {
        overflow: 'hidden',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            display:
                'none',
        },
        '&:hover': {
            '&::-webkit-scrollbar': {
                height: '2.5px',
                width: '2.5px',
                display: 'block',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#888',
            },
        },
    },
    anchorTag: {
        color: '#066785',
        textDecoration: 'none',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));

const TitleProp = {
    key: 'title',
    type: 'TextField',
    label: 'Slide Title',
    default: 'yes',
    id: 'Gallery',
    options: [{ label: 'YES', value: 'yes' }, { label: 'NO', value: 'no' }],
    isBasic: true,
    isAdvance: true,
    isAdmin: true,
};

const CaptionProp = {
    key: 'caption',
    type: 'TextField',
    label: 'Slide Caption',
    isBasic: true,
    isAdvance: true,
    isAdmin: true,
    id: 'Gallery',
};

const assembleImages = (components, layout) => {
    const rows = [];
    let row = [];
    rows.push(row);
    for (let i = 0; i < components.length; i += 1) {
        row.push(components[i]);
        if ((i + 1) % layout === 0 && i < components.length - 1) {
            row = [];
            rows.push(row);
        }
    }
    return rows;
};

const renderChildren = (rows, components, isPreview = false, componentParent = null, componentIndex = null, componentColumn = null) => {
    let counter = 0;
    const Comp = isPreview ? ContainerPreview : Container;
    return rows.map((row, index) => (
        <Grid key={index} container>
            {row.map(() => (
                <Grid item key={counter} xs={12 / row.length}>
                    <Comp
                        props={{
                            components,
                            componentPropsCip: components,
                            componentParentCip: componentParent,
                            componentIndexCip: componentIndex,
                            componentColumnCip: componentColumn,
                        }}
                        single
                        singleIndex={counter++}
                    />
                </Grid>
            ))}
        </Grid>
    ));
};

const CardHeaderComponent = ({
    title,
    classes,
    componentProps,
    componentParent,
    componentIndex,
    componentColumn,
}) => {
    const [slideTitle, setSlideTitle] = useState({
        __html: componentProps?.props?.['hyperlink-slideTitle']
            ? `<a href=${componentProps?.props?.['hyperlink-slideTitle']} class=${classes.anchorTag} target='_blank'>${title}</a>`
            : title,
    });
    const slideTitleRef = useRef(null);
    const [slideTitleContextMenu, setSlideTitleContextMenu] = useState(null);
    const {
        handleContextMenu,
        hyperlinkCip,
        setHyperlinkCip,
        setHyperlinkCipText,
        setHyperlinkComponent,
        setHyperlinkImageVideoComponent,
        updateTimeline,
    } = useContext(TimelineContext);

    useEffect(() => {
        if (!hyperlinkCip) {
            let nodeTextConcat = '';
            const requiredChildNodes = slideTitleRef?.current?.childNodes;
            if (requiredChildNodes) {
                requiredChildNodes.forEach((childNode) => {
                    nodeTextConcat += childNode.textContent;
                });
                if (nodeTextConcat !== title) {
                    setSlideTitle({
                        __html: title,
                    });
                }
            }
        }
    }, [title]);

    const openSlideTitleContextMenu = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setSlideTitleContextMenu(slideTitleContextMenu === null ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 } : null);
    };

    const closeSlideTitleContextMenu = () => {
        setSlideTitleContextMenu(null);
    };

    const addEditHyperLink = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setHyperlinkCip(true);
        setHyperlinkCipText(slideTitleRef?.current?.textContent);
        setHyperlinkComponent(componentProps);
        setHyperlinkImageVideoComponent('Slide Title');
        handleContextMenu(
            event,
            componentParent?.components[componentIndex],
            { ...componentParent, components: [{ ...componentParent?.components[componentIndex] }] },
            componentIndex,
            componentColumn
        );
    };

    const removeHyperLink = () => {
        delete componentProps?.props?.['hyperlink-slideTitle'];
        setSlideTitleContextMenu(null);
        setSlideTitle({
            __html: title,
        });
        updateTimeline();
    };

    if (typeof componentProps?.props?.showTitle === 'boolean') {
        componentProps.props.showTitle = componentProps.props.showTitle ? 'yes' : 'no';
    }

    let { showTitle = 'yes' } = componentProps.props;
    showTitle = (showTitle === 'yes');

    return (
        <>
            <CardHeader
                title={(
                    <Typography
                        classes={{ root: classes.captionTypography }}
                        ref={slideTitleRef}
                        onContextMenu={!hyperlinkCip && !componentProps?.isProgression && slideTitle ? openSlideTitleContextMenu : undefined}
                        dangerouslySetInnerHTML={showTitle ? slideTitle : null}
                    />
                )}
                classes={{
                    root: classes.titleStyle,
                }}
                className={[classes.titleViewCls, classes.scrollCls].join(' , ')}
            />
            <Menu
                open={slideTitleContextMenu !== null}
                onClose={closeSlideTitleContextMenu}
                anchorReference="anchorPosition"
                anchorPosition={
                    slideTitleContextMenu !== null
                        ? { top: slideTitleContextMenu.mouseY, left: slideTitleContextMenu.mouseX }
                        : undefined
                }
            >
                {componentProps?.props?.['hyperlink-slideTitle'] ? (
                    <>
                        <MenuItem onClick={addEditHyperLink}>Edit Hyperlink</MenuItem>
                        <MenuItem onClick={removeHyperLink}>Remove Hyperlink</MenuItem>
                    </>
                ) : (
                    <MenuItem onClick={addEditHyperLink}>Add Hyperlink</MenuItem>
                )}
            </Menu>
        </>
    );
};

const CardContentComponent = ({
    caption,
    classes,
    componentProps,
    componentParent,
    componentIndex,
    componentColumn,
}) => {
    const [slideCaption, setSlideCaption] = useState({
        __html: componentProps?.props?.['hyperlink-slideCaption']
            ? `<a href=${componentProps?.props?.['hyperlink-slideCaption']} class=${classes.anchorTag} target='_blank'>${caption}</a>`
            : caption,
    });
    const slideCaptionRef = useRef(null);
    const [slideCaptionContextMenu, setSlideCaptionContextMenu] = useState(null);
    const {
        handleContextMenu,
        hyperlinkCip,
        setHyperlinkCip,
        setHyperlinkCipText,
        setHyperlinkComponent,
        setHyperlinkImageVideoComponent,
        updateTimeline,
    } = useContext(TimelineContext);

    useEffect(() => {
        if (!hyperlinkCip) {
            let nodeTextConcat = '';
            const requiredChildNodes = slideCaptionRef?.current?.childNodes;
            if (requiredChildNodes) {
                requiredChildNodes.forEach((childNode) => {
                    nodeTextConcat += childNode.textContent;
                });
                if (nodeTextConcat !== caption) {
                    setSlideCaption({
                        __html: caption,
                    });
                }
            }
        }
    }, [caption]);

    const openSlideCaptionContextMenu = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setSlideCaptionContextMenu(slideCaptionContextMenu === null ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 } : null);
    };

    const closeSlideCaptionContextMenu = () => {
        setSlideCaptionContextMenu(null);
    };

    const addEditHyperLink = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setHyperlinkCip(true);
        setHyperlinkCipText(slideCaptionRef?.current?.textContent);
        setHyperlinkComponent(componentProps);
        setHyperlinkImageVideoComponent('Slide Caption');
        handleContextMenu(
            event,
            componentParent?.components[componentIndex],
            { ...componentParent, components: [{ ...componentParent?.components[componentIndex] }] },
            componentIndex,
            componentColumn
        );
    };

    const removeHyperLink = () => {
        delete componentProps?.props?.['hyperlink-slideCaption'];
        setSlideCaptionContextMenu(null);
        setSlideCaption({
            __html: caption,
        });
        updateTimeline();
    };

    if (typeof componentProps?.props?.showCaption === 'boolean') {
        componentProps.props.showCaption = componentProps.props.showCaption ? 'yes' : 'no';
    }

    let { showCaption = 'yes' } = componentProps.props;
    showCaption = (showCaption === 'yes');

    return (
        <>
            <CardContent classes={{ root: classes.captionContainer }} className={[classes.titleViewCls, classes.scrollCls].join(' , ')}>
                <Typography
                    classes={{ root: classes.captionTypography }}
                    ref={slideCaptionRef}
                    onContextMenu={!hyperlinkCip && !componentProps?.isProgression && slideCaption ? openSlideCaptionContextMenu : undefined}
                    dangerouslySetInnerHTML={showCaption ? slideCaption : null}
                />
            </CardContent>
            <Menu
                open={slideCaptionContextMenu !== null}
                onClose={closeSlideCaptionContextMenu}
                anchorReference="anchorPosition"
                anchorPosition={
                    slideCaptionContextMenu !== null
                        ? { top: slideCaptionContextMenu.mouseY, left: slideCaptionContextMenu.mouseX }
                        : undefined
                }
            >
                {componentProps?.props?.['hyperlink-slideCaption'] ? (
                    <>
                        <MenuItem onClick={addEditHyperLink}>Edit Hyperlink</MenuItem>
                        <MenuItem onClick={removeHyperLink}>Remove Hyperlink</MenuItem>
                    </>
                ) : (
                    <MenuItem onClick={addEditHyperLink}>Add Hyperlink</MenuItem>
                )}
            </Menu>
        </>
    );
};

const renderContent = (
    showTitle,
    title,
    rows,
    components,
    showCaption,
    caption,
    classes,
    isPreview,
    isProgression,
    componentProps,
    componentParent,
    componentIndex,
    componentColumn
) => (
    <Card className={classes.removeBorderRad}>
        {showTitle && (
            <CardHeaderComponent
                title={title}
                classes={classes}
                components={components}
                componentProps={componentProps}
                componentParent={componentParent}
                componentIndex={componentIndex}
                componentColumn={componentColumn}
            />
        )}
        {renderChildren(rows, components, isPreview, componentParent, componentIndex, componentColumn)}
        {showCaption && (
            <CardContentComponent
                caption={caption}
                classes={classes}
                components={components}
                componentProps={componentProps}
                componentParent={componentParent}
                componentIndex={componentIndex}
                componentColumn={componentColumn}
            />
        )}
    </Card>
);

const Gallery = ({
    config,
    componentParent,
    componentIndex,
    componentColumn,
}) => {
    const classes = useStyles();
    const { inWorkshop, compareModal } = useContext(WorkshopContext);
    const {
        settingComponent,
        setSettingComponentHandle,
        setDirtyDomain,
        selectedTimeline,
        domain: contextDomain,
        pageComponent: pageComponentRef,
        dirtyDomain,
        imageEditorDialogWs,
        setImageEditorDialogWs,
        imageEditorDataWs,
        setImageEditorLoader,
        workshopActiveComponent,
        updateFlag,
        setUpdateFlag,
    } = useContext(TimelineContext);
    const [component, setComponentState] = useRecoilState(componentWithId(config.id));
    const [componentPropsParent] = useRecoilState(componentWithId(config?.parent));
    const { isProgression } = config;
    const componentList = [];
    const { id } = config;
    let domain = contextDomain;
    if (config?.previewDomain) {
        domain = config.previewDomain;
    }

    if (!Object.prototype.hasOwnProperty.call(config?.props, 'isShowTimeline')) {
        config.props.isShowTimeline = true;
    }
    const pageComponent = pageComponentRef || selectedTimeline && findPage(selectedTimeline?.columns, component?.props?.parentID)?.[0];
    const isPageElem = domain && (component.type === 'Page'
        || component?.props?.pageChild || (component.name === 'Page' && component.type !== 'Protocol'));
    buildSettingsTreePage(componentList, pageComponent && pageComponent[domain]);
    const currentComp = componentList.find((item) => item.id === id);
    if (currentComp && currentComp[domain] && !component.id) {
        component[domain] = currentComp[domain];
    }
    const {
        layout = 2, title, caption, components,
    } = isPageElem ? (component?.[domain] || component.props) : component.props || config?.props;

    let rows = [];

    if (components && components.length > 0) {
        rows = assembleImages(components, layout); // cash output?
    }

    let isSelected = false;

    if (settingComponent && settingComponent.id === config.id) {
        isSelected = true;
    }
    useEffect(() => {
        const pageDomain = component[domain];
        if (dirtyDomain) {
            return;
        }
        if (pageDomain && Object.keys(pageDomain).length > 0 && !component.id) {
            const currentComp = componentList.find((c) => c.id === config.id);
            if (currentComp) {
                currentComp[domain] = pageDomain;
            }
        }
    }, [domain, component, id, dirtyDomain]);

    const imageEditorData = {
        url: imageEditorDataWs?.url,
        filetype: imageEditorDataWs?.filetype,
        name: imageEditorDataWs?.name,
    };

    if (typeof config?.props.showTitle === 'boolean' && typeof config?.props?.showCaption === 'boolean') {
        config.props.showTitle = config?.props.showTitle ? 'yes' : 'no';
        config.props.showCaption = config?.props.showCaption ? 'yes' : 'no';
    }

    let { showTitle = 'yes' } = config.props;
    showTitle = (showTitle === 'yes');

    let { showCaption = 'yes' } = config.props;
    showCaption = (showCaption === 'yes');

    useEffect(() => {
        if (componentPropsParent?.props?.components?.length > 0 && config?.id === workshopActiveComponent?.id) {
            const tempTitle = component?.props?.showTitle;
            const tempCaption = component?.props?.showCaption;
            componentPropsParent.props.components.forEach((ele) => {
                ele.props.showTitle = tempTitle;
                ele.props.showCaption = tempCaption;
            });
        }
        setUpdateFlag(!updateFlag);
    }, [component?.props?.showTitle, component?.props?.showCaption]);

    const renderEditor = () => (
        <>
            {imageEditorDialogWs && (
                <ImageEditorDialog
                    imageEditorDialog={imageEditorDialogWs}
                    setImageEditorDialog={setImageEditorDialogWs}
                    imageEditorData={imageEditorData}
                    imgSrc={imageEditorDataWs}
                    setImageEditorLoader={setImageEditorLoader}
                />
            )}
            <Card
                className={[classes.imageRoot, isSelected ? classes.selectedComp : null].join(' ')}
                id={isSelected ? 'selectedCompId' : ''}
                variant="outlined"
                style={{ padding: '0px 4px 0px 4px' }}
                onClick={(event) => { event.stopPropagation(); setActiveComponent(setSettingComponentHandle, config); }}
            >
                <CardContent
                    style={{ textAlign: 'center', padding: 4 }}
                    classes={{
                        root: ((!showTitle) ? classes.hideTextCls : null),
                    }}
                >
                    {
                        makeInlineEditor({
                            field: TitleProp,
                            component,
                            setComponentState,
                            classes,
                            domain,
                            setDirtyDomain,
                            config,
                        })
                    }
                </CardContent>
                {renderChildren(rows, components)}
                <CardContent
                    style={{ textAlign: 'center', padding: 4 }}
                    classes={{
                        root: ((!showCaption) ? classes.hideTextCls : null),
                    }}
                >
                    {
                        makeInlineEditor({
                            field: CaptionProp,
                            component,
                            setComponentState,
                            classes,
                            domain,
                            setDirtyDomain,
                            config,
                        })
                    }
                </CardContent>
            </Card>
        </>
    );

    return (inWorkshop && !compareModal)
        ? renderEditor() : renderContent(
            showTitle,
            title,
            rows,
            components,
            showCaption,
            caption,
            classes,
            false,
            isProgression,
            config,
            componentParent,
            componentIndex,
            componentColumn
        );
};

/**
 * This component is preview of page
 * @param {object} config [configuration recieved from props]
 */
const GalleryPreview = ({ component }) => {
    const classes = useStyles();
    const {
        layout = 2, title, caption, showTitle, showCaption, components,
    } = component.props;
    const { handleCompPreviewClick, settingComponent } = useContext(TimelineContext);
    let rows = [];
    const isPreview = true;

    let isSelected = false;

    if (settingComponent && settingComponent.id === component.id) {
        isSelected = true;
    }

    if (components && components.length > 0) {
        rows = assembleImages(components, layout); // cash output?
    }

    return (
        <Card
            style={{ padding: '0px 4px' }}
            onClick={(e) => handleCompPreviewClick(e, component)}
            className={[isSelected ? classes.selectedComp : null].join(' , ')}
            id={isSelected ? 'selectedCompId' : ''}
        >
            {showTitle
                && (
                    <CardHeader
                        titleTypographyProps={{ variant: 'display1' }}
                        title={title}
                        classes={{
                            root: classes.titleStyle,
                        }}
                    />
                )}
            {renderChildren(rows, components, isPreview)}
            <CardContent classes={{ root: classes.captionContainer }} style={{ padding: '0px 4px' }}>
                {showCaption && (
                    <Typography
                        variant="display1"
                        classes={{ root: classes.captionTypography }}
                    >
                        {caption}
                    </Typography>
                )}
            </CardContent>
        </Card>
    );
};

export const GalleryProps = {
    display: [TitleProp,
        {
            key: 'showTitle',
            type: 'Radio',
            label: 'Show Slide Title',
            default: 'yes',
            id: 'Gallery',
            options: [{ label: 'Yes', value: 'yes' }, { label: 'No  ', value: 'no' }],
            isBasic: true,
            isAdvance: true,
            isAdmin: true,
        },
        {
            key: 'layout',
            type: 'Select',
            label: 'Layout',
            default: 2,
            options: [{ label: 'One Column', value: 1 }, { label: 'Two Column', value: 2 }, { label: 'Three Column', value: 3 }],
            id: 'Gallery',
        },
        CaptionProp, {
            key: 'showCaption',
            type: 'Radio',
            label: 'Show Slide Caption',
            default: 'yes',
            id: 'Gallery',
            options: [{ label: 'Yes', value: 'yes' }, { label: 'No  ', value: 'no' }],
            isBasic: true,
            isAdvance: true,
            isAdmin: true,
        },
        {
            key: 'operations',
            type: 'ButtonList',
            label: 'Operations',
            buttons: [
                {
                    type: 'add',
                    label: 'Add Slide',
                    color: 'primary',
                    show: true,
                },
                {
                    type: 'delete',
                    label: 'Delete',
                    color: 'error',
                    show: true,
                },
            ],
            id: 'Gallery',

        },
        {
            key: 'hyperlink-slideTitle',
            type: 'TextFieldHyperlinkDebounce',
            label: 'Slide Title Hyperlink',
            isBasic: true,
            isAdvance: true,
            isAdmin: true,
            id: 'Gallery',
        },
        {
            key: 'hyperlink-slideCaption',
            type: 'TextFieldHyperlinkDebounce',
            label: 'Slide Caption Hyperlink',
            isBasic: true,
            isAdvance: true,
            isAdmin: true,
            id: 'Gallery',
        },
    ],
    poi: false,
};

/**
 * This component is used in setting section with template library
 * @param {object} config [configuration recieved from props]
 * @param {function} updateComponent [recieved function as props to save page editor]
 */
const GalleryEditor = ({ config, updateComponent }) => {
    const [component, setComponentState] = useRecoilState(componentWithId(config.id));
    const updateRecoilComponent = useRecoilCallback(updateComponentCallback);
    const [confirmDeleteDialogVisible, showConfirmDeleteDialog] = useState(false);
    const {
        domain, settingMenuType, workshopComponent, selectedTimeline, pageComponent, setPageComponent, setWorkshopComponent, selectedComponent,
    } = useContext(TimelineContext);
    let displaySettings = FilterSettings(GalleryProps, settingMenuType);
    if (selectedComponent?.isProgression) {
        displaySettings = displaySettings.filter((ele) => !ele?.key.includes('hyperlink-'));
    }
    const hasMultipleSlides = useMemo(() => {
        const componentList = [];
        buildSettingsTreePage(componentList, workshopComponent);
        return componentList.filter((item) => item.type === 'Gallery').length > 1;
    }, [workshopComponent]);
    const currentComponentExist = useMemo(() => {
        const componentList = [];
        buildSettingsTreePage(componentList, workshopComponent);
        return componentList.find((item) => item.id === config.id);
    }, [workshopComponent, config.id]);
    if (!currentComponentExist) {
        displaySettings = displaySettings.filter((item) => item.key !== 'operations');
    }
    const editorProps = { display: displaySettings, poi: GalleryProps.poi };
    const buttonList = GalleryProps.display.find((item) => item.key === 'operations').buttons;
    const addSlideButton = buttonList.find((item) => item.type === 'add');
    const deleteSlideButton = buttonList.find((item) => item.type === 'delete');

    const confirmRemoveSlide = () => {
        showConfirmDeleteDialog(true);
    };

    const handleRemoveSlide = () => {
        const componentList = [];
        if (workshopComponent.name === 'Page') {
            buildSettingsTreePage(componentList, pageComponent);
            let selectedCompParent = null;
            let selectedCompIndex = -1;
            for (let i = 0; i < componentList.length; i += 1) {
                const currentComponent = componentList[i];
                const currIndex = (currentComponent[domain].components || []).findIndex((item) => item.id === config.id);
                if (currIndex !== -1) {
                    selectedCompParent = currentComponent;
                    selectedCompIndex = currIndex;
                    break;
                }
            }
            if (selectedCompParent && selectedCompIndex !== -1) {
                selectedCompParent[domain].components = selectedCompParent[domain].components.filter((_, index) => index !== selectedCompIndex);
                const workshopcomponentList = [];
                buildSettingsTreePage(workshopcomponentList, workshopComponent);
                const pageRef = workshopcomponentList.find((item) => item.id === pageComponent.id);
                pageRef[domain].components = selectedCompParent[domain].components;
            }

            setPageComponent({ ...pageComponent });
            setWorkshopComponent({ ...workshopComponent });
        } else {
            buildSettingsTreePage(componentList, workshopComponent);
            let selectedCompParent = null;
            let selectedCompIndex = -1;
            for (let i = 0; i < componentList.length; i += 1) {
                const currentComponent = componentList[i];
                const currIndex = (currentComponent.props.components || []).findIndex((item) => item.id === config.id);
                if (currIndex !== -1) {
                    selectedCompParent = currentComponent;
                    selectedCompIndex = currIndex;
                    break;
                }
            }
            if (selectedCompParent && selectedCompIndex !== -1) {
                selectedCompParent.props.components = selectedCompParent.props.components.filter((_, index) => index !== selectedCompIndex);
            }
            setWorkshopComponent({ ...workshopComponent });
        }
    };

    const handleConfirmDialog = (ok) => {
        if (ok) handleRemoveSlide();
        showConfirmDeleteDialog(false);
    };

    const insertSlide = (template, originalConfig) => {
        const componentWrapper = workshopComponent.props.components[0].props.components;
        let insertIndex = componentWrapper.findIndex((item) => item.id === template.id) + 1;
        if (workshopComponent.name === 'Page' || workshopComponent.props.pageChild) {
            const targetPage = pageComponent || selectedTimeline && findPage(selectedTimeline?.columns, workshopComponent.props.parentID)[0];
            const componentList = [];
            buildSettingsTreePage(componentList, targetPage);

            const workshopComponentList = [];
            buildSettingsTreePage(workshopComponentList, workshopComponent);
            componentList.forEach((item) => {
                if (item.props) {
                    if (!item.props.pageChild) {
                        item.props.pageChild = true;
                        item.props.parentID = targetPage.id;
                        item[domain] = item.props;
                    }
                }
            });
            const templateParent = componentList.find((c) => c.id === originalConfig.parent);
            const currentComponent = templateParent[domain].components.find((c) => c.id === template.id);
            currentComponent[domain] = component[domain];
            currentComponent.props = component.props;

            insertIndex = templateParent[domain].components.findIndex((item) => item.id === template.id) + 1;
            const newItem = copyTemplate(template);
            newItem.props = newItem[domain];
            templateParent[domain].components = [
                ...templateParent[domain].components.slice(0, insertIndex), newItem,
                ...templateParent[domain].components.slice(insertIndex),
            ];

            const workshopRef = workshopComponentList.find((item) => item.id === templateParent.id);
            if (workshopRef) {
                workshopRef[domain] = templateParent[domain];
                workshopRef.props = workshopRef[domain];
                workshopComponent[domain] = workshopComponent.props;
            }
            setWorkshopComponent({ ...workshopComponent });
            updateRecoilComponent(templateParent.id, templateParent[domain], domain);
        } else {
            const insertArray = workshopComponent.props.components[0].props.components;
            workshopComponent.props.components[0].props.components = [...insertArray.slice(0, insertIndex),
                copyTemplate(template), ...insertArray.slice(insertIndex)];
            setWorkshopComponent({ ...workshopComponent });
        }
    };
    const handleAddSlide = () => {
        const configCopy = JSON.parse(JSON.stringify(config));
        const componentListConfig = [];
        buildSettingsTreePage(componentListConfig, configCopy);
        componentListConfig.forEach((item) => {
            item.props.title = '';
            item.parent = null;
            item.props.caption = '';
            if (item[domain]) {
                item[domain].title = '';
                item[domain].caption = '';
            }
        });
        insertSlide(configCopy, config);
    };

    addSlideButton.onClick = handleAddSlide;
    deleteSlideButton.onClick = confirmRemoveSlide;
    deleteSlideButton.show = hasMultipleSlides;
    return (
        <>
            <Settings editorProps={editorProps} component={component} setComponentState={setComponentState} saveComponent={updateComponent} />
            <WarningDialog
                title="Delete slide?"
                desc="Do you want to delete this slide?"
                onClose={handleConfirmDialog}
                open={confirmDeleteDialogVisible}
                primaryBtnText="Delete"
            />
        </>
    );
};

const GalleryEditMenu = ({
    componentLibrary,
}) => {
    const filter = (item) => (item.type === 'Image');
    const labelMaker = (label) => (`Add ${label}`);
    const add = buildComponentMenu(componentLibrary, filter, labelMaker, 'child');
    const all = buildComponentMenu(componentLibrary);
    const menu = [
        ...add,
        MenuDefaults.EDIT,
        MenuDefaults.ADD_SIBLING(all),
        MenuDefaults.DUPLICATE,
    ];
    return menu;
};

export {
    Gallery, GalleryEditor, GalleryPreview, GalleryEditMenu,
};
