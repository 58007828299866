import {
    Box, Button, Divider, Grid, IconButton,
    InputAdornment, Snackbar, TextField, Typography,
} from '@material-ui/core';
import { OrgContext } from './context';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { passwordValidator } from './helper/commonHelper';
import { toast } from 'react-toastify';
import { useAuth0 } from './react-auth0-spa';
import BulletIcon from '@material-ui/icons/FiberManualRecord';
import MuiAlert from '@material-ui/lab/Alert';
import React, { useContext, useState } from 'react';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/**
 * Set the Style for media details
 */
const useStyles = makeStyles((theme) => createStyles({
    actionBtnDiv: {
        position: 'absolute',
        bottom: 0,
        borderTop: '1px solid #33333322',
        width: '77%',
        backgroundColor: '#fff',
    },
    primaryBtn: {
        maxHeight: 56,
        width: 200,
        padding: '16px 18px',
        boxShadow: '0px 0px 20px -4px rgba(51,36,5,0.37)',
        '&:hover': {
            boxShadow: 'none',
        },
    },
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: 'calc(100vh - 180px)',
    },
    errorColor: {
        color: '#EA4F4F',
    },
}));

const ChangePassword = ({ userId, loggedInUserDetails }) => {
    const classes = useStyles();
    const { changeUserPassword, updateLoggedUser } = useContext(OrgContext);
    const { logout } = useAuth0();
    const [isShowCurrentPassword, setShowCurrentPassword] = useState(false);
    const [isShowNewPassword, setShowNewPassword] = useState(false);
    const [isShowConfirmPassword, setShowConfirmPassword] = useState(false);
    const [state, setState] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
        isErrorCurrentPass: false,
        isErrorNewPass: false,
        isErrorConfirmPass: false,
        isErrorMatchPass: false,
    });
    const [openSnackbarFlag, setOpenSnackbarFlag] = useState(loggedInUserDetails?.['organization_member'][0]?.user?.['is_dummy_password']);

    /**
     * @function onChangePassword
     * @param evt [handle the password field]
     */
    const onChangePassword = (evt, fieldName) => {
        const { name, value } = evt.target;
        const errorName = fieldName;
        let errorStatus = !passwordValidator(value);

        if (name === 'currentPassword') {
            errorStatus = !(value.length > 0);
        }

        const isMatch = !!((name === 'confirmPassword' && state.newPassword !== value));

        setState({
            ...state,
            [name]: value,
            [errorName]: errorStatus,
            isErrorMatchPass: isMatch,
        });
    };

    /**
     * @function onChangePassword [Handle show/hide password]
     */
    const handleClickShowPassword = (actionType, actionValue) => {
        actionType(!actionValue);
    };

    /**
     * @function handleLogout
     */
    const handleLogout = () => {
        logout({ returnTo: window.location.origin });
    };

    /**
     * @function onSubmitPassword [Submit the state value to server]
     */
    const onSubmitPassword = () => {
        let isMatchPass = false;
        const requestObj = {
            currentPassword: state.currentPassword,
            newPassword: state.newPassword,
            userId,
        };

        const oldPassStatus = !(state.currentPassword);
        const newPassStatus = !(state.currentPassword);
        const confirmPassstatus = !(state.currentPassword);

        if (state.newPassword !== state.confirmPassword) {
            isMatchPass = true;
        }

        if (isMatchPass
            || (oldPassStatus && newPassStatus && confirmPassstatus)
            || (state.isErrorCurrentPass || state.isErrorNewPass || state.isErrorConfirmPass)) {
            setState({
                ...state,
                isErrorCurrentPass: oldPassStatus,
                isErrorNewPass: newPassStatus,
                isErrorConfirmPass: confirmPassstatus,
                isErrorMatchPass: isMatchPass,
            });
            return false;
        }

        const changeStatus = changeUserPassword(requestObj);
        const updateUserFlag = loggedInUserDetails?.['organization_member'][0]?.user?.['is_dummy_password'];
        changeStatus.then(() => {
            if (updateUserFlag) {
                const userRequestObj = {
                    userID: userId,
                    is_dummy_password: false,
                };
                const userResponse = updateLoggedUser({
                    variables: { ...userRequestObj },
                });
                userResponse.then(() => {
                    handleLogout();
                }).catch((errors) => {
                    toast.error(errors?.graphQLErrors[0]?.message);
                });
            } else {
                handleLogout();
            }
            toast.info('Password updated successfully');
        }).catch((errors) => {
            toast.error(errors.graphQLErrors[0].message);
        });
        return null;
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbarFlag(false);
    };

    return (
        <>
            <Snackbar open={openSnackbarFlag} autoHideDuration={2000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="info">
                    Please change password!
                </Alert>
            </Snackbar>
            <Box my={1.5} mx={2.25}>
                <Typography variant="h3" color="primary">Account Credentials</Typography>
            </Box>
            <Divider />
            <Box py={4} px={4}>
                <Grid item md={12} lg={12}>
                    <Box display="flex" mt={2}>
                        <Box order={1}><BulletIcon fontSize="small" /></Box>
                        <Box order={2} ml={2}>
                            <Typography variant="h5">Your Password should be at least 7-8 characters long — longer is better.</Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={12} lg={12}>
                    <Box display="flex" mb={2}>
                        <Box order={1}><BulletIcon fontSize="small" /></Box>
                        <Box order={2} ml={2}>
                            <Typography variant="h5">
                                Should contain alphanumeric with lower-case letters, upper-case letter, numeric and special character.
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={7} lg={7}>
                    <Box component="span" display="block">
                        <Typography>Current Password</Typography>
                    </Box>
                    <Box component="span" display="block" mb={4}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Enter your Current Password"
                            onChange={(e) => onChangePassword(e, 'isErrorCurrentPass')}
                            name="currentPassword"
                            type={isShowCurrentPassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="Toggle password visibility"
                                            onClick={() => handleClickShowPassword(setShowCurrentPassword, isShowCurrentPassword)}
                                        >
                                            {isShowCurrentPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {state.isErrorCurrentPass && <span className={classes.errorColor}>Please enter the current password </span>}
                    </Box>
                </Grid>
                <Grid item md={7} lg={7}>
                    <Box component="span" display="block">
                        <Typography>Set New Password</Typography>
                    </Box>
                    <Box component="span" display="block" mb={4}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Enter a New Password"
                            onChange={(e) => onChangePassword(e, 'isErrorNewPass')}
                            name="newPassword"
                            type={isShowNewPassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="Toggle password visibility"
                                            onClick={() => handleClickShowPassword(setShowNewPassword, isShowNewPassword)}
                                        >
                                            {isShowNewPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {state.isErrorNewPass && <span className={classes.errorColor}>Please enter the valid password</span>}
                    </Box>
                </Grid>
                <Grid item md={7} lg={7}>
                    <Box component="span" display="block">
                        <Typography>Confirm New Password</Typography>
                    </Box>
                    <Box component="span" display="block">
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Re-Enter New Password"
                            onChange={(e) => onChangePassword(e, 'isErrorConfirmPass')}
                            name="confirmPassword"
                            type={isShowConfirmPassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="Toggle password visibility"
                                            onClick={() => handleClickShowPassword(setShowConfirmPassword, isShowConfirmPassword)}
                                        >
                                            {isShowConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {state.isErrorConfirmPass && <span className={classes.errorColor}>Please enter the valid password</span>}
                        <br />
                        {state.isErrorMatchPass && <span className={classes.errorColor}>New password and confirm password should be same</span>}
                    </Box>
                </Grid>
            </Box>
            <Box py={2} px={4} className={classes.actionBtnDiv}>
                <Grid item md={12} lg={12}>
                    <Button variant="contained" color="primary" onClick={onSubmitPassword} className={classes.primaryBtn}>Change Password</Button>
                </Grid>
            </Box>
        </>
    );
};

export default ChangePassword;
