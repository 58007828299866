import '../css/style.css';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    Grid,
    MenuItem,
    Select,
} from '@material-ui/core';
import { ProcedureLibrary } from '../initialConfig';
import { makeStyles } from '@material-ui/core/styles';
import { standardHeaders } from './utils/StandardStyles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import React, { useState } from 'react';

const usersData = [
    { id: 1, value: 1, label: 'Dr. Cruise' },
];

const useRowStyles = makeStyles(() => ({
    accessControlHeaderActive: {
        background: '#b1b1b1',
        padding: 4,
        textAlign: 'center',
        margin: '4px 4px 4px 0px',
    },
    accessControlHeader: {
        background: '#efefef',
        textAlign: 'center',
        padding: 4,
        margin: '4px 4px 4px 0px',
    },
    sectionHeader: {
        margin: 0,
        minHeight: 'auto !important',
        height: 32,
        fontSize: 13,
        border: '1px solid #DEE7F8',
        ...standardHeaders.sectionHeader,
        position: 'relative',
        flexDirection: 'row-reverse',
        background: 'rgba(6,103,133,0.09)',
        color: '#066785',
    },
    sectionHeader1: {
        margin: 0,
        minHeight: 'auto !important',
        height: 32,
        fontSize: 12,
        border: '1px solid #DEE7F8',
        ...standardHeaders.sectionHeader,
        position: 'relative',
        flexDirection: 'row-reverse',
        background: '#DEE7F8',
        color: '#1b1b1b',
    },
    iconButton: {
        margin: '0 8px 0 2px',
        padding: 0,
        background: '#bbb',
        color: '#FFFFFF',
        borderRadius: 2,
    },
    expandedAcc: {
        background: '#066785',
        color: '#FFFFFF',
        transition: 'none',
    },
    expandedAccIcon: {
        transform: 'rotateZ(-90deg) translateY(-10px)',
        color: '#066785',
        height: 36,
    },
    dmPOICompAccDetail: {
        display: 'block',
        padding: '2px',
        marginBottom: '-2px',
    },
    checkBoxStyle: {
        '& svg': {
            height: 15,
            width: 15,
            color: '#000000',
            margin: '0px 4px 0px 8px',
        },
    },
    checkBoxStyle1: {
        padding: 0,
        '& svg': {
            height: 15,
            width: 15,
            color: '#000000',
            margin: '0px 4px 0px 8px',
        },
    },
    fontSize12Px: {
        fontSize: 12,
        padding: '4px 0px 4px 4px',
    },
    poiContMain: {
        borderBottom: '1px solid #DEE7F8',
        borderLeft: '1px solid #DEE7F8',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    poiCont2: {
        maxHeight: '61vh',
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    poiCheckboxCont: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #DEE7F8',
        padding: '0px 4px',
        fontSize: 12,
        height: 36,
    },
    dmAccDetailCont: {
        borderTop: '1px solid #DEE7F8',
    },
    dmAccDetailCont1: {
        borderLeft: '1px solid #DEE7F8',
        borderRight: '1px solid #DEE7F8',
    },
    dmAccDetailCont2: {
        border: '1px solid #DEE7F8',
        marginTop: 2,
    },
    borderBottom: {
        fontSize: 12,
        padding: '10px 4px',
    },
    borderBottom1: {
        fontSize: 12,
        padding: '2px 4px',
    },
    selectUser: {
        background: '#DEE7F8',
    },
    textColor1: {
        color: '#066785',
    },
    borderLeft1: {
        color: '#066785',
        borderLeft: '1px solid #DEE7F8',
        padding: '9px 4px',
        fontSize: 12,
    },
    alignCenter: {
        alignItems: 'center',
    },
    padding2Px: {
        padding: 2,
    },
    boxShadowNone: {
        boxShadow: 'none',
    },
}));

const DataMatrixPOI = ({ title }) => {
    const classes = useRowStyles();
    const [expandDMSetting, setExpandDMSetting] = useState(true);
    const [state] = useState({
        user: 1,
        category: 1,
        functionView: false,
        functionAccessView: 'isBasic',
        settingsFunction: [],
    });

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Accordion
                        expanded={expandDMSetting}
                        onChange={() => setExpandDMSetting(!expandDMSetting)}
                        classes={{ expanded: classes.boxShadowNone }}
                    >
                        <AccordionSummary
                            expandIcon={
                                expandDMSetting ? (
                                    <ArrowDropUpIcon />
                                ) : (
                                    <ArrowDropDownIcon />
                                )
                            }
                            aria-controls="panel1a-content"
                            className={classes.sectionHeader}
                            classes={{
                                expandIcon: expandDMSetting ? classes.iconButton : classes.expandedAccIcon,
                                expanded: classes.expandedAcc,
                            }}
                        >
                            <span>
                                {title}
                            </span>
                        </AccordionSummary>
                        <AccordionDetails className={classes.dmPOICompAccDetail}>
                            <Grid container>
                                <Grid item xs={6} className={classes.padding2Px}>
                                    <Grid container className={`${classes.sectionHeader1} ${classes.alignCenter}`}>
                                        <Grid item xs={12}>
                                            Master User
                                        </Grid>
                                    </Grid>
                                    <Grid container alignItems="center" justify="center" className={classes.dmAccDetailCont2}>
                                        <Grid item xs={4} className={`${classes.fontSize12Px} ${classes.textColor1}`}>Procedure Group</Grid>
                                        <Grid
                                            item
                                            xs={8}
                                            className={classes.borderLeft1}
                                        >
                                            Procedure
                                        </Grid>
                                    </Grid>
                                    <Grid container alignItems="center" justify="center" className={classes.poiCont2}>
                                        {ProcedureLibrary.children.map((item) => (
                                            <div
                                                className={classes.poiContMain}
                                                style={{
                                                    borderRight: item.title === 'Others' ? '1px solid #DEE7F8' : 'none',
                                                    padding: item.title === 'Others' ? '6px 4px' : 'inherit',
                                                }}
                                            >
                                                <Grid item xs={4} className={classes.fontSize12Px}>
                                                    {item.title}
                                                </Grid>
                                                <Grid item xs={8} className={classes.dmAccDetailCont1}>
                                                    {item.children.map((item, index) => (
                                                        <Grid container>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                style={{ borderTop: index > 0 ? '1px solid #DEE7F8' : 'none' }}
                                                                className={classes.borderBottom}
                                                            >
                                                                {item.title}
                                                            </Grid>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </div>
                                        ))}
                                    </Grid>
                                </Grid>
                                <Grid item xs={6} className={classes.padding2Px}>
                                    <Grid container className={classes.selectUser}>
                                        <Grid item xs={12} className={classes.fontSize12Px}>
                                            <Select
                                                name="user"
                                                color="secondary"
                                                labelId="demo-simple-select-filled-label"
                                                id="demo-simple-select-filled"
                                                value={state.user}
                                                // onChange={selectChangeHandler}
                                                inputProps={{ className: classes.inputValue }}
                                                classes={{ root: classes.fontSize12Px }}
                                                fullWidth
                                            >
                                                {usersData && usersData.map((user) => (
                                                    <MenuItem value={user.value}>{user.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>
                                    </Grid>
                                    <Grid container alignItems="center" justify="center" className={classes.dmAccDetailCont2}>
                                        <Grid item xs={4} className={`${classes.fontSize12Px} ${classes.textColor1}`}>Procedure Group</Grid>
                                        <Grid
                                            item
                                            xs={8}
                                            className={classes.borderLeft1}
                                        >
                                            Procedure
                                        </Grid>
                                    </Grid>
                                    <Grid container alignItems="center" justify="center" className={classes.poiCont2}>
                                        {ProcedureLibrary.children.map((item, index) => (
                                            <div
                                                className={classes.poiContMain}
                                                style={{
                                                    borderRight: item.title === 'Others' ? '1px solid #DEE7F8' : 'none',
                                                    padding: item.title === 'Others' ? '8px 1px' : 'inherit',
                                                }}
                                            >
                                                <Grid item xs={item.title === 'Others' ? 12 : 4} className={classes.fontSize12Px}>
                                                    <Grid container justify="space-between" alignItems="center">
                                                        <Grid item xs={item.title === 'Others' ? 10 : 12}>{item.title}</Grid>
                                                        {
                                                            item.title === 'Others'
                                                            && (
                                                                <Grid item xs={2} style={{ textAlign: 'end' }}>
                                                                    <Checkbox className={classes.checkBoxStyle1} />
                                                                </Grid>
                                                            )
                                                        }
                                                    </Grid>
                                                </Grid>
                                                {
                                                    item.title !== 'Others'
                                                    && (
                                                        <Grid item xs={8} className={classes.dmAccDetailCont1}>
                                                            {item.children.map((item) => (
                                                                <Grid container>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        style={{ borderTop: index > 0 ? '1px solid #DEE7F8' : 'none' }}
                                                                        className={classes.borderBottom1}
                                                                    >
                                                                        <Grid container justify="space-between" alignItems="center">
                                                                            <Grid item xs={10}>{item.title}</Grid>
                                                                            <Grid item xs={2}>
                                                                                <Checkbox className={classes.checkBoxStyle} />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                    )
                                                }
                                            </div>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
        </>
    );
};

export default DataMatrixPOI;
