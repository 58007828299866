import { CloseOutlined } from '@material-ui/icons';
import { DELETE_CONTENT_DISPLAY_BY_COLUMN, DELETE_CONTENT_DISPLAY_BY_PROTOCOL } from './ImageSelector';
import { OrgContext, TimelineContext } from '../context';
import { TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useLazyQuery, useMutation } from 'react-apollo';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useContext, useEffect, useState } from 'react';
import gql from 'graphql-tag';
import httpHelper from '../helper/httpHelper';

const GET_DELETED_COLUMN_MEDIA = gql`
    query GetDeletedColumnMedia($column_id: uuid) {
        media_folder_new(where: {column_id: {_eq: $column_id}}) {
            timeline_id
            column_id
            media {
                url
            }
        }
    }
`;

const GET_DELETED_OTHERS_MEDIA = gql`
    query GetDeletedOthersMedia($component_id: [uuid!]) {
        media_folder_new(where: {component_id: {_in: $component_id}}) {
            timeline_id
            column_id
            component_id
            media {
                url
            }
        }
    }
`;

const DELETE_COLUMN_FOLDER = gql`
mutation DeleteColumnFolder($column_id: uuid) {
    delete_media_folder_new(where: {column_id: {_eq: $column_id}}) {
        affected_rows
        returning {
            id
        }
    }
  }
`;

const DELETE_OTHERS_FOLDER = gql`
mutation DeleteOthersFolder($component_id: [uuid!]) {
    delete_media_folder_new(where: {component_id: {_in: $component_id}}) {
        affected_rows
        returning {
            id
        }
    }
  }
`;

const useStyles = makeStyles(() => ({
    dialogTitle: {
        padding: '1rem',
        borderBottom: '.25px solid #cbcbcb',
        '& h2': {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
        },
        '& p': {
            fontSize: '1rem',
        },
        '& svg': {
            fontSize: '1.25rem',
            marginTop: 2,
            cursor: 'pointer',
        },
    },
    dialogContent: {
        padding: '2rem 2rem 1.5rem 2rem',
    },
    dialogActions: {
        borderTop: '.25px solid #cbcbcb',
        padding: '1rem 0rem',
        '& button': {
            marginRight: '1rem',
        },
    },
}));

export default function DeleteDialog({
    setDeleteDialogLoader,
}) {
    const classes = useStyles();
    const {
        deleteConfirmationDialogVisibility,
        setDeleteConfirmationDialogVisibility,
        removeComponent,
        selectedTimeline,
        setSelectedTimeline,
        deleteTargetParentAndIndex,
        specificSelect,
        setSpecificSelect,
        specificOptions,
        setSpecificOptions,
        setSelectedOptionIdsPreview,
        setCurrentSelectedValue,
        setAssociationValue,
        actionRelation,
    } = useContext(TimelineContext);

    const {
        idToken,
    } = useContext(OrgContext);

    const [getDeletedColumnMedia, {
        data: deletedColumnMedia,
    }] = useLazyQuery(GET_DELETED_COLUMN_MEDIA, {
        fetchPolicy: 'no-cache',
    });

    const [getDeletedOthersMedia, {
        data: deletedOthersMedia,
    }] = useLazyQuery(GET_DELETED_OTHERS_MEDIA, {
        fetchPolicy: 'no-cache',
    });

    const [deleteColumnFolder, {
        loading: deleteColumnFolderLoading,
    }] = useMutation(DELETE_COLUMN_FOLDER);

    const [deleteOthersFolder, {
        loading: deleteOthersFolderLoading,
    }] = useMutation(DELETE_OTHERS_FOLDER);

    const [deleteByProtocol, {
        loading: deleteContentDisplayByProtocolLoading,
    }] = useMutation(DELETE_CONTENT_DISPLAY_BY_PROTOCOL);

    const [deleteByColumn, {
        loading: deleteContentDisplayByColumnLoading,
    }] = useMutation(DELETE_CONTENT_DISPLAY_BY_COLUMN);

    useEffect(() => {
        if (setDeleteDialogLoader) {
            const requiredMedia = deletedColumnMedia?.['media_folder_new']?.[0]?.media;
            const deletedColumnMediaKeys = [];
            const deleteSuccess = () => {
                const deletedColumnId = deletedColumnMedia?.['media_folder_new']?.[0]?.['column_id'];
                deleteColumnFolder({
                    variables: {
                        column_id: deletedColumnId,
                    },
                });
                deleteByColumn({
                    variables: {
                        column_id: deletedColumnId,
                    },
                });
            };
            const requestFailure = () => {
            };
            if (requiredMedia) {
                requiredMedia.forEach((ele) => {
                    deletedColumnMediaKeys.push({
                        Key: ele.url.split('/').pop(),
                    });
                });
                const payload = {
                    Objects: [...deletedColumnMediaKeys],
                };
                const httpObj = {
                    url: 'storage/upload/',
                    method: 'DELETE',
                    data: JSON.stringify(payload),
                    headers: { Authorization: `Bearer ${idToken}` },
                };
                httpHelper(
                    httpObj,
                    deleteSuccess,
                    requestFailure,
                    false
                );
            } else {
                setDeleteDialogLoader(false);
            }
        }
    }, [deleteColumnFolder, deletedColumnMedia, idToken, setDeleteDialogLoader]);

    useEffect(() => {
        if (setDeleteDialogLoader && !deleteColumnFolderLoading && !deleteContentDisplayByColumnLoading) {
            setDeleteDialogLoader(false);
        }
    }, [deleteColumnFolderLoading, setDeleteDialogLoader, deleteContentDisplayByColumnLoading]);

    useEffect(() => {
        if (setDeleteDialogLoader) {
            if (deletedOthersMedia?.['media_folder_new']?.length > 0) {
                let requiredMedia = [];
                const deletedOthersMediaKeys = [];
                deletedOthersMedia.media_folder_new.forEach((ele) => {
                    requiredMedia = [...requiredMedia, ...ele.media];
                });
                requiredMedia.forEach((ele) => {
                    deletedOthersMediaKeys.push({
                        Key: ele.url.split('/').pop(),
                    });
                });
                const payload = {
                    Objects: [...deletedOthersMediaKeys],
                };
                const deleteSuccess = () => {
                    let deletedImagesVideosComponentId = [];
                    deletedOthersMedia.media_folder_new.forEach((ele) => {
                        deletedImagesVideosComponentId = [...deletedImagesVideosComponentId, ele?.['component_id']];
                    });
                    deleteOthersFolder({
                        variables: {
                            component_id: deletedImagesVideosComponentId,
                        },
                    });
                };
                const requestFailure = () => {
                };
                const httpObj = {
                    url: 'storage/upload/',
                    method: 'DELETE',
                    data: JSON.stringify(payload),
                    headers: { Authorization: `Bearer ${idToken}` },
                };
                httpHelper(
                    httpObj,
                    deleteSuccess,
                    requestFailure,
                    false
                );
            } else {
                setDeleteDialogLoader(false);
            }
        }
    }, [deleteOthersFolder, deletedOthersMedia, idToken, setDeleteDialogLoader]);

    useEffect(() => {
        if (setDeleteDialogLoader && !deleteOthersFolderLoading && !deleteContentDisplayByProtocolLoading) {
            setDeleteDialogLoader(false);
        }
    }, [deleteOthersFolderLoading, setDeleteDialogLoader, deleteContentDisplayByProtocolLoading]);

    const handleClose = () => {
        setDeleteConfirmationDialogVisibility(false);
    };

    const getComponentId = (comp, idList) => {
        idList.push(comp?.commonId || comp?.id);
        if (comp?.props?.components && comp?.name !== 'Images/Videos') {
            comp.props.components.forEach((c) => {
                getComponentId(c, idList);
            });
        }
    };

    const handleMediaFolderNewDelete = () => {
        const {
            component: {
                id,
                commonId,
                type,
            },
        } = deleteTargetParentAndIndex;
        if (type === 'Column') {
            getDeletedColumnMedia({
                variables: {
                    column_id: id,
                },
            });
        } else if (actionRelation?.type === 'deleteWithChild') {
            const idList = [];
            getComponentId(deleteTargetParentAndIndex?.component, idList);
            getDeletedOthersMedia({
                variables: {
                    component_id: idList,
                },
            });
        } else if (type === 'Protocol') {
            getDeletedOthersMedia({
                variables: {
                    component_id: [commonId || id],
                },
            });
            deleteByProtocol({
                variables: {
                    protocol_id: id,
                },
            });
        } else {
            setDeleteDialogLoader(false);
        }
    };

    const proceedDeletion = () => {
        const { parent, index, component } = deleteTargetParentAndIndex;

        if (specificOptions[component.associatedParent] && (component?.type === 'Select' || component?.type === 'MultiSelect')
            && component.props.renderAs !== 'radio' && component.props.renderAs !== 'Checkbox'
            && !Object.prototype.hasOwnProperty.call(component.props, 'associatedCell') && !specificSelect[component.associatedParent]) {
            setSpecificSelect({});
            setSpecificOptions({});
        }
        setSelectedOptionIdsPreview({});
        setCurrentSelectedValue({});
        setAssociationValue({});
        if (parent.type === 'Timeline') {
            const currentTimeLineWidth = selectedTimeline.props.width;
            const eachColumnWidth = parseInt(currentTimeLineWidth, 10) / (selectedTimeline.columns.length);
            selectedTimeline.props.width = parseInt(selectedTimeline.props.width, 10) - eachColumnWidth;
            setSelectedTimeline(selectedTimeline);
        }

        removeComponent(parent, index, component, handleMediaFolderNewDelete);
        setDeleteDialogLoader(true);
        handleClose();
    };

    return (
        <div>
            <Dialog
                open={deleteConfirmationDialogVisibility}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
                    <Typography>Confirm Deletion?</Typography>
                    <CloseOutlined onClick={handleClose} />
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to Delete it?
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button onClick={handleClose} variant="contained">
                        Cancel
                    </Button>
                    <Button onClick={proceedDeletion} variant="contained" color="primary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

function DuplicateDialog() {
    const {
        duplicateColumnNameDialogVisibility,
        setDuplicateColumnNameDialogVisibility,
        columnDuplicateTargetParentAndIndex,
        duplicateComponent,
        selectedTimeline,
        setSelectedTimeline,
    } = useContext(TimelineContext);

    const [columnName, setColumnName] = useState('');
    const [error, setError] = useState(null);

    const handleClose = () => {
        setColumnName('');
        setError();
        setDuplicateColumnNameDialogVisibility(false);
    };

    const proceedDuplicate = () => {
        const { columns } = selectedTimeline;
        const existingCoulmn = columns.filter((item) => (item.props.title === columnName)).length;
        if (existingCoulmn > 0) {
            return setError('Column name already exists.');
        }
        const { parent, index, component } = columnDuplicateTargetParentAndIndex;
        const newComponent = { ...component, props: { ...component.props, title: columnName } };

        if (parent.type === 'Timeline') {
            const currentTimeLineWidth = selectedTimeline.props.width;
            const eachColumnWidth = parseInt(currentTimeLineWidth, 10) / (selectedTimeline.columns.length - 1);
            selectedTimeline.props.width = parseInt(selectedTimeline.props.width, 10) + eachColumnWidth;
            setSelectedTimeline(selectedTimeline);
        }
        duplicateComponent(newComponent, parent.components || parent.props.components, index, false, parent);
        handleClose();
        return null;
    };

    const onChange = (e) => {
        const newValue = e.target.value;
        setColumnName(newValue);
        if (newValue.trim() === '') {
            setError('Column Name can not be empty!');
        } else {
            setError(null);
        }
    };

    useEffect(() => {
        const duplicateDefault = columnDuplicateTargetParentAndIndex?.component?.props?.title || '';
        setColumnName(`${duplicateDefault}-clone`);
    }, [columnDuplicateTargetParentAndIndex]);
    return (
        <div>
            <Dialog
                open={duplicateColumnNameDialogVisibility}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Duplicate Column Name</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <TextField fullWidth value={columnName} onChange={onChange} error={error} helperText={error} />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained">
                        Cancel
                    </Button>
                    <Button disabled={error} onClick={proceedDuplicate} color="primary" autoFocus>
                        Duplicate
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export {
    DeleteDialog, DuplicateDialog,
};
