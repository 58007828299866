import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';

import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';

import UploadMedia from './UploadMedia';

/**
 * @function getModalStyle
 * @description set the margin
 * @param None
 * @returns {object}
 */
function getModalStyle() {
    const top = 45;
    const left = 45;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

/**
 * @function useStyles
 * @description css for modal popup
 * @returns {object}
*/
const useStyles = makeStyles((theme) => createStyles({
    paper: {
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(0, 0, 1.5, 0),
    },
    closeIcon: {
        cursor: 'pointer',
    },
    cancelBtnAllign: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingRight: '1.5rem',
    },
    headerAllignment: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '20px',
        fontWeight: '500',
        paddingLeft: '1.5rem',
        '& p': {
            margin: '14px 0px',
        },
    },
    hideCls: {
        display: 'none',
    },
}));

const typeObj = {
    copy: 'Copy',
    duplicate: 'Duplicate',
};

/**
 * @constructor MediaGalleryModal
 * @description create the modal pop. Taking props from parent and pass props to child component
 * @param {handleClose}
 * @param {open}
*/
const MediaGalleryModal = ({
    handleClose,
    open,
    fetchFolderId,
    folderId = null,
    imageUploadHandler = null,
    mediaAction = null,
    selectedMedia = null,
    imageActionSuccessfull = null,
}) => {
    const classes = useStyles();
    const modalStyle = getModalStyle();
    const [state, setState] = useState({});

    /**
     * @function passUploadedMedia
     * @description set recent and newly uploaded into one
     * @param {object} recentUploadMedia
     */
    const passUploadedMedia = () => {
        handleClose(true);
    };

    useEffect(() => {
        if (mediaAction?.type === typeObj.copy || mediaAction?.type === typeObj.duplicate) {
            setState({ ...state, mediaAction });
        }
    }, [mediaAction]);

    const renderUploadMedia = () => (
        <UploadMedia
            passUploadedImage={passUploadedMedia}
            fetchFolderId={fetchFolderId}
            folderId={folderId}
            imageUploadHandler={imageUploadHandler}
            mediaActionType={state.mediaAction}
            selectedMedia={selectedMedia}
            setParentState={setState}
            imageActionSuccessfull={imageActionSuccessfull}
        />

    );

    return (
        <div>
            {(mediaAction?.type === typeObj.copy || mediaAction?.type === typeObj.duplicate) ? null : (
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    disableEnforceFocus
                >
                    <div style={modalStyle} className={classes.paper}>
                        <Grid container>
                            <Grid item md={11} lg={11} className={classes.headerAllignment}>
                                <p id="simple-modal-title">Media Manager</p>
                            </Grid>
                            <Grid item md={1} lg={1} className={classes.cancelBtnAllign}>
                                <CloseIcon onClick={() => handleClose(false)} className={classes.closeIcon} />
                            </Grid>
                            <Grid item md={12} lg={12}>
                                {renderUploadMedia()}
                            </Grid>
                        </Grid>
                    </div>
                </Modal>
            ) }
            {(mediaAction?.type === typeObj.copy || mediaAction?.type === typeObj.duplicate) && renderUploadMedia()}
        </div>
    );
};

export default MediaGalleryModal;
