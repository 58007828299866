import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import React from 'react';

const useStyles = makeStyles(() => ({
    panelWidth: {
        width: '100%',
    },
}));

const Level = ({ level, selected, onChange }) => {
    const classes = useStyles();
    const handleChange = (event) => {
        onChange(level, event.target.checked);
    };

    const checked = (selected[level.id] !== undefined);

    if (!level.children) {
        // leafe node
        return (
            <div>
                <FormControlLabel
                    control={(
                        <Checkbox
                            checked={checked}
                            onChange={handleChange}
                            color="primary"
                        />
                    )}
                    label={level.title}
                />
            </div>
        );
    }
    return (
        <ExpansionPanel expanded={checked} defaultExpanded={false}>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
            >
                <FormControlLabel
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()}
                    control={(
                        <Checkbox
                            checked={checked}
                            onChange={handleChange}
                            color="primary"
                        />
                    )}
                    label={level.title}
                />
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <div className={classes.panelWidth}>
                    {level.children.map((child) => <Level key={child.id} level={child} parent={level} selected={selected} onChange={onChange} />)}
                </div>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

const ProcedureSelector = ({ library, selected = {}, onChange = () => { } }) => (
    <div>
        {library.children.map((child) => <Level key={child.id} level={child} parent={library} selected={selected} onChange={onChange} />)}
    </div>
);

const handleSelectionChange = (selected, level, checked) => {
    const updated = { ...selected };
    const visit = (node) => {
        delete updated[node.id];
        if (node.children) {
            node.children.forEach((child) => { visit(child); });
        }
    };

    if (checked) {
        updated[level.id] = level;
    } else {
        visit(level);
    }
    return updated;
};

const flattenSelected = (library, selected) => {
    const flat = [];
    if (selected) {
        Object.keys(selected).forEach((key) => {
            if (selected[key] && library.children) {
                library.children.forEach((parent) => {
                    let childPoi;
                    if (parent.children.length > 0) {
                        childPoi = parent.children.find((poi) => poi.id === key);
                    } else {
                        childPoi = parent.id === key ? parent : null;
                    }

                    if (childPoi && childPoi.children && childPoi.children.length === 0) {
                        flat.push(childPoi);
                    }
                });
            }
        });
    }
    return flat;
};

export { ProcedureSelector, handleSelectionChange, flattenSelected };
