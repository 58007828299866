import * as React from 'react';
import { TimelineContext } from '../../context';
import { getIframeHyperlink, scrapThisURL } from '../../helper/commonHelper';
import { makeStyles } from '@material-ui/core/styles';
import {
    useContext, useEffect, useState,
} from 'react';

import Box from '@material-ui/core/Box';
import Loader from 'react-loaders';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabPanel from '@material-ui/lab/TabPanel';
import Tabs from '@material-ui/core/Tabs';

const useStyles = makeStyles((theme) => ({
    content_display_page: {
        '& section': {
            overflowX: 'hidden',
        },
        height: 'calc(100vh - 310px)',
        overflowY: 'scroll',
        [theme.breakpoints.down('xs')]: {
            height: 'calc(100vh - 60px)',
        },
        '& .inner-banner-section': {
            display: 'none',
        },
    },
    blankDiv: {
        height: 'calc(100% + 150px)',
        textAlign: 'center',
        paddingTop: '150px',
        fontSize: '1.2rem',
        [theme.breakpoints.down('xs')]: {
            height: 'calc(100vh + 50px)',
            paddingTop: '100%',
        },
    },
}));

const showLoader = (status) => {
    if (!status) {
        return '';
    }
    return (
        <div style={{
            zIndex: 10,
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            position: 'relative',
            background: 'rgba(0, 0, 0, 0.2)',
        }}
        >
            <div id="loader">
                <Loader type="line-scale" />
            </div>
        </div>
    );
};

export function GetScrappedHTML({ page = '' }) {
    const classes = useStyles();
    const blankElem = React.createElement('div',
        {
            className: classes.blankDiv,
        },
        'No Content Attached / Keep Scrolling.');
    const errorElem = React.createElement('div',
        {
            className: classes.blankDiv,
        },
        'Oops! error occurred please try again later');
    const [defaultPage, setDefaultPage] = useState(null);
    const [scrappedData, setScrappedData] = useState(null);
    const {
        currentProtocol, currentProtocolParents, setCurrentProtocol, setCollapseProtocol, setCDComponent,

    } = useContext(TimelineContext);
    const sectionRef = React.useRef();
    const [loading, setLoading] = useState(false);
    const [isScrolledDown, setIsScrollDown] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setIsScrollDown(false);
            try {
                const data = await scrapThisURL(page);
                setScrappedData(data);
                setDefaultPage(null);
            } catch (error) {
                // Handle any errors
                setDefaultPage(errorElem);
                console.error('Error fetching data:', error);
            }
            setLoading(false);
        };
        if (page) {
            fetchData();
        } else {
            setDefaultPage(blankElem);
        }
    }, [page]);

    const handleScroll = () => {
        const element = sectionRef?.current;
        let calculatedHeight = Math.round(element?.scrollHeight - element?.scrollTop);
        const clientHeight = element?.clientHeight;
        if (element.scrollTop > 10) {
            setIsScrollDown(true);
        }
        if (calculatedHeight < clientHeight) {
            calculatedHeight += 1;
        } else if (calculatedHeight > clientHeight) {
            calculatedHeight -= 1;
        }
        // when scrolled to the bottom of page
        if (calculatedHeight === clientHeight) {
            const currentIndex = currentProtocolParents?.components?.findIndex((c) => c.id === currentProtocol.id);
            if (currentProtocolParents?.components?.length > currentIndex + 1) {
                if (currentProtocolParents?.components[currentIndex + 1]?.props?.hyperlink === undefined) {
                    currentProtocolParents.components[currentIndex + 1].props.hyperlink = [''];
                }
                const currentHeader = currentProtocolParents.headers.find((h) => h.id
                    === currentProtocolParents?.components[currentIndex + 1]?.props?.childOf);
                if (currentHeader) {
                    currentHeader.props.isExpanded = true;
                }
                setCurrentProtocol(currentProtocolParents?.components[currentIndex + 1]);
                setCollapseProtocol([currentProtocolParents?.components[currentIndex + 1]]);
                setCDComponent(currentProtocolParents?.components[currentIndex + 1]);
            }
        // when scrolled to the top of page
        } else if (isScrolledDown && element.scrollTop === 0) {
            const currentIndex = currentProtocolParents?.components?.findIndex((c) => c.id === currentProtocol.id);
            // switch between protocol
            if (currentProtocolParents?.components?.length && currentProtocolParents?.components[currentIndex - 1]) {
                if (currentProtocolParents?.components[currentIndex - 1]?.props?.hyperlink === undefined) {
                    currentProtocolParents.components[currentIndex - 1].props.hyperlink = [''];
                }
                const currentHeader = currentProtocolParents.headers.find((h) => h.id
                    === currentProtocolParents?.components[currentIndex - 1]?.props?.childOf);
                if (currentHeader) {
                    currentHeader.props.isExpanded = true;
                }
                setCurrentProtocol(currentProtocolParents?.components[currentIndex - 1]);
                setCollapseProtocol([currentProtocolParents?.components[currentIndex - 1]]);
                setCDComponent(currentProtocolParents?.components[currentIndex - 1]);
            }
        }
    };
    return (
        <div ref={sectionRef} onScroll={handleScroll} className={classes.content_display_page}>
            {showLoader(loading)}
            {defaultPage || (<section style={{ pointerEvents: 'none' }} dangerouslySetInnerHTML={{ __html: scrappedData }} />)}
        </div>
    );
}

export default function WebPageTabs({ webPages }) {
    const [value, setValue] = React.useState(0);

    const {
        checkIfLAGyno, isMobile,
    } = useContext(TimelineContext);

    const handleChange = (_, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            {webPages.length > 0 && webPages?.[0] ? (
                <TabContext value={value}>
                    {/* <Box sx={{ display: isMobile ? 'none' : 'block', borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} centered>
                            {webPages.map((_, ind) => (<Tab label={`Page ${ind + 1}`} value={ind} />))}
                        </Tabs>
                    </Box> */}
                    {webPages.map((page, ind) => (
                        <TabPanel
                            key={page + ind}
                            value={ind}
                            style={{ height: '100%', padding: 0 }}
                        >
                            {checkIfLAGyno(page) ? <GetScrappedHTML page={page || ''} /> : getIframeHyperlink({ url: page })}
                        </TabPanel>
                    ))}
                </TabContext>
            ) : (
                <GetScrappedHTML page="" />
            )}
        </Box>
    );
}
