/* eslint-disable react/jsx-closing-tag-location */
import {
    Chip,
} from '@material-ui/core';
import { ProcedureLibrary } from './initialConfig';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
    chipContainer: {
        marginBottom: 0,
        marginTop: 0,

    },
    chip: {
        '&&': {
            borderRadius: 0,
            width: '94%',
            justifyContent: 'space-between',
            border: '1px solid #7070702E',
            backgroundColor: '#C7C7C71A',
        },
    },
    chipTimeline: {
        borderRadius: 0,
        width: 'auto',
        justifyContent: 'space-between',
        border: '1px solid #7070702E',
        backgroundColor: '#C7C7C71A',
        float: 'left',
        marginBottom: 3,
        marginRight: 3,
        fontSize: '11px',
        height: '27px',
        '& svg': {
            display: 'none',
            transition: '.5s',
        },
        '&:hover': {
            '& svg': {
                display: 'block',
            },
        },
        '& span': {
            paddingLeft: '2px',
            paddingRight: '4px',
            display: 'block',
            overflow: 'visible',
            textOverflow: 'unset',
            whiteSpace: 'unset',
            width: 'auto',
            '&::-webkit-scrollbar': {
                display:
                    'none',
            },
            '&:hover': {
                '& svg': {
                    display: 'block',
                },
                '&::-webkit-scrollbar': {
                    height: '2.5px',
                    width: '2.5px',
                    display: 'block',
                },
                '&::-webkit-scrollbar-thumb': {
                    background: '#888',
                },
            },
        },
    },

    fonSizSec: {
        width: '15px',
        height: '15px',
        margin: 0,
    },
    poiLabel: {
        textAlign: 'center',
        marginLeft: '0px',
        padding: '5px',
        paddingTop: '0px',
        color: '#483797',
        display: 'none',
    },
    poiTable: {
        maxHeight: '35vh',
        overflow: 'auto',
        marginTop: '-16px',
        paddingBottom: 24,
    },
}));

const POIList = ({
    library, selectedPOI, updatePOI, isWorkshop, initialSelectAll,
}) => {
    const [POI, setPOI] = useState(selectedPOI);
    const classes = useStyles();
    const flatChildren = library.children.flatMap((item) => item.children.map((x) => ({ ...x, parent: item.title, parentId: item.id })));
    const flatList = [...flatChildren, ...ProcedureLibrary.children];
    const selectedList = flatList.filter((item) => POI[item.id] && (!item.children || item.children.length === 0));
    const nothingSelected = Object.keys(POI).every((child) => !POI[child]);

    useEffect(() => {
        setPOI(selectedPOI);
    }, [selectedPOI]);

    useEffect(() => {
        if ((Object.keys(POI).length === 0 || nothingSelected) && initialSelectAll) {
            const items = flatList.reduce((acc, item) => ({ ...acc, [item.id]: true }), {});
            updatePOI(items);
        } else {
            updatePOI(POI);
        }
    }, [initialSelectAll, POI]);

    const onDelete = (item) => {
        const parent = library.children.find((child) => child.id === item.parentId);
        const newPOI = { ...POI };
        delete newPOI[item.id];
        if (parent) {
            const nothingSelectedEle = parent.children.every((child) => !newPOI[child.id]);
            if (nothingSelectedEle) {
                delete newPOI[parent.id];
            }
        }

        setPOI(newPOI);
    };
    return (
        <div className={isWorkshop && selectedList.length ? classes.poiTable : ''}>
            {selectedList.length > 0 && <Typography color="inherit" className={classes.poiLabel}>Selected POI</Typography>}
            {
                selectedList.map((item) => (
                    <div className={classes.chipContainer}>
                        <Chip
                            className={isWorkshop ? classes.chip : classes.chipTimeline}
                            classes={{ deleteIcon: classes.fonSizSec }}
                            deletable
                            onDelete={() => onDelete(item)}
                            label={`${item.parent ? `${item.parent}-` : ''}${item.title}`}
                        />
                    </div>
                ))
            }
        </div>
    );
};

export default POIList;
