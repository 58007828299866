/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
import {
    Grid, TextField,
} from '@material-ui/core';
import { MediaContext } from '../context';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from 'react-apollo';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useContext, useEffect, useState } from 'react';
import gql from 'graphql-tag';

const CREATE_TIMELINE_FOLDER = gql`
    mutation CreateTimelineFolder($timeline_id: uuid) {
        insert_media_folder_new(objects: {timeline_id: $timeline_id}) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

const CREATE_COLUMN_FOLDER = gql`
    mutation CreateColumnFolder($timeline_id: uuid, $column_id: uuid) {
        insert_media_folder_new(objects: {timeline_id: $timeline_id, column_id: $column_id}) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

const CREATE_OTHERS_FOLDER = gql`
    mutation CreateOthersFolder($timeline_id: uuid, $column_id: uuid, $is_progression: Boolean, $component_id: uuid) {
        insert_media_folder_new(objects: {
            timeline_id: $timeline_id,
            column_id: $column_id,
            is_progression: $is_progression,
            component_id: $component_id
        }) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        minWidth: 500,
        backgroundColor: theme.palette.background.paper,
    },
    paper: {
        width: '80%',
        minHeight: 500,
    },
    widthSectPart: {
        maxWidth: 600,
        minWidth: 500,
    },
    textInputWidth: {
        width: '95%',
        '& input': {
            paddingTop: 0,
            paddingBottom: 0,
            height: 30,
        },
        '& label': {
            marginTop: -4,
        },
    },
}));

const ConfirmationDialogRaw = ({
    open,
    handleClose,
    activeFolderDetails,
    setActiveFolderDetails,
    mediaList,
    setMediaList,
    setYouTubeLinkUploading,
    setFolderLoading,
    setFolderError,
}) => {
    const classes = useStyles();
    const [state, setState] = useState({ youtube: '' });
    const [error, setError] = useState('');
    const [isOpen, setIsOpen] = useState(open);
    const { youtubeVideoUpload } = useContext(MediaContext);

    const [createTimelineFolder, {
        loading: timelineFolderLoading,
        error: timelineFolderError,
        data: timelineFolderData,
        reset: timelineFolderReset,
    }] = useMutation(CREATE_TIMELINE_FOLDER);

    const [createColumnFolder, {
        loading: columnFolderLoading,
        error: columnFolderError,
        data: columnFolderData,
        reset: columnFolderReset,
    }] = useMutation(CREATE_COLUMN_FOLDER);

    const [createOthersFolder, {
        loading: othersFolderLoading,
        error: othersFolderError,
        data: othersFolderData,
        reset: othersFolderReset,
    }] = useMutation(CREATE_OTHERS_FOLDER);

    useEffect(() => {
        if (timelineFolderLoading) {
            setFolderLoading(true);
            setFolderError(false);
        } else if (timelineFolderError) {
            setFolderLoading(false);
            setFolderError(true);
        } else if (timelineFolderData?.['insert_media_folder_new']) {
            const folderId = timelineFolderData?.['insert_media_folder_new']?.returning?.[0]?.id;
            const updateState = { ...state };
            let videoId = updateState.youtube.split('v=')[1];
            const ampersandPosition = videoId.indexOf('&');
            if (ampersandPosition !== -1) {
                videoId = videoId.substring(0, ampersandPosition);
            }
            const data = [
                { name: 'Youtube', url: videoId, activeFolderId: folderId },
            ];
            youtubeVideoUpload(
                data,
                () => {
                    setYouTubeLinkUploading(true);
                },
                (insertMediaResult) => {
                    setActiveFolderDetails({
                        ...activeFolderDetails,
                        id: folderId,
                    });
                    setMediaList([...mediaList, insertMediaResult]);
                    setYouTubeLinkUploading(false);
                    setIsOpen(false);
                },
                (err) => {
                    alert(err?.message);
                    setYouTubeLinkUploading(false);
                    return (true);
                }
            );
            setFolderLoading(false);
            setFolderError(false);
        }
    }, [timelineFolderLoading, timelineFolderError, timelineFolderData]);

    useEffect(() => {
        if (columnFolderLoading) {
            setFolderLoading(true);
            setFolderError(false);
        } else if (columnFolderError) {
            setFolderLoading(false);
            setFolderError(true);
        } else if (columnFolderData?.['insert_media_folder_new']) {
            const folderId = columnFolderData?.['insert_media_folder_new']?.returning?.[0]?.id;
            const updateState = { ...state };
            let videoId = updateState.youtube.split('v=')[1];
            const ampersandPosition = videoId.indexOf('&');
            if (ampersandPosition !== -1) {
                videoId = videoId.substring(0, ampersandPosition);
            }
            const data = [
                { name: 'Youtube', url: videoId, activeFolderId: folderId },
            ];
            youtubeVideoUpload(
                data,
                () => {
                    setYouTubeLinkUploading(true);
                },
                (insertMediaResult) => {
                    setActiveFolderDetails({
                        ...activeFolderDetails,
                        id: folderId,
                    });
                    setMediaList([...mediaList, insertMediaResult]);
                    setYouTubeLinkUploading(false);
                    setIsOpen(false);
                },
                (err) => {
                    alert(err?.message);
                    setYouTubeLinkUploading(false);
                    return (true);
                }
            );
            setFolderLoading(false);
            setFolderError(false);
        }
    }, [columnFolderLoading, columnFolderError, columnFolderData]);

    useEffect(() => {
        if (othersFolderLoading) {
            setFolderLoading(true);
            setFolderError(false);
        } else if (othersFolderError) {
            setFolderLoading(false);
            setFolderError(true);
        } else if (othersFolderData?.['insert_media_folder_new']) {
            const folderId = othersFolderData?.['insert_media_folder_new']?.returning?.[0]?.id;
            const updateState = { ...state };
            let videoId = updateState.youtube.split('v=')[1];
            const ampersandPosition = videoId.indexOf('&');
            if (ampersandPosition !== -1) {
                videoId = videoId.substring(0, ampersandPosition);
            }
            const data = [
                { name: 'Youtube', url: videoId, activeFolderId: folderId },
            ];
            youtubeVideoUpload(
                data,
                () => {
                    setYouTubeLinkUploading(true);
                },
                (insertMediaResult) => {
                    setActiveFolderDetails({
                        ...activeFolderDetails,
                        id: folderId,
                    });
                    setMediaList([...mediaList, insertMediaResult]);
                    setYouTubeLinkUploading(false);
                    setIsOpen(false);
                },
                (err) => {
                    alert(err?.message);
                    setYouTubeLinkUploading(false);
                    return (true);
                }
            );
            setFolderLoading(false);
            setFolderError(false);
        }
    }, [othersFolderLoading, othersFolderError, othersFolderData]);

    const handleError = (fieldName, fieldValue) => {
        if (fieldValue.trim().length === 0) {
            const message = `${fieldName} is required`;
            setError(message);
        } else if (fieldValue.trim().length > 0) {
            setError('');
        }
    };

    const inputChangeHandler = (e) => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value });
        handleError(name, value);
    };

    const validateYouTubeUrl = (url) => {
        if (url !== undefined || url !== '') {
            // eslint-disable-next-line no-useless-escape
            const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            const match = url.match(regExp);
            if (match && match[2].length === 11) {
                return true;
            }

            setError('Invalid Youtube Video URL');
            return false;
        }

        return null;
    };

    const handleOk = () => {
        const updateState = { ...state };
        if (validateYouTubeUrl(updateState.youtube)) {
            if (activeFolderDetails?.id) {
                let videoId = updateState.youtube.split('v=')[1];
                const ampersandPosition = videoId.indexOf('&');
                if (ampersandPosition !== -1) {
                    videoId = videoId.substring(0, ampersandPosition);
                }
                const data = [
                    { name: 'Youtube', url: videoId, activeFolderId: activeFolderDetails?.id },
                ];
                youtubeVideoUpload(
                    data,
                    () => {
                        setYouTubeLinkUploading(true);
                    },
                    (insertMediaResult) => {
                        setMediaList([...mediaList, insertMediaResult]);
                        setYouTubeLinkUploading(false);
                        setIsOpen(false);
                    },
                    (err) => {
                        alert(err?.message);
                        setYouTubeLinkUploading(false);
                        return (true);
                    }
                );
            } else if (activeFolderDetails?.['folder_type'] === 'timeline') {
                createTimelineFolder({
                    variables: {
                        timeline_id: activeFolderDetails?.['timeline_id'],
                    },
                });
            } else if (activeFolderDetails?.['folder_type'] === 'column') {
                createColumnFolder({
                    variables: {
                        timeline_id: activeFolderDetails?.['timeline_id'],
                        column_id: activeFolderDetails?.['column_id'],
                    },
                });
            } else if (activeFolderDetails?.['folder_type'] === 'others') {
                createOthersFolder({
                    variables: {
                        timeline_id: activeFolderDetails?.['timeline_id'],
                        column_id: activeFolderDetails?.['column_id'],
                        is_progression: activeFolderDetails?.['is_progression'],
                        component_id: activeFolderDetails?.['component_id'],
                    },
                });
            } else if (activeFolderDetails?.['folder_type'] === 'custom') {
                // no need because custom folders can never be virtual
            }
        }
    };

    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            aria-labelledby="confirmation-dialog-title"
            open={isOpen}
            classes={{ paperWidthSm: classes.widthSectPart }}
        >
            <DialogTitle id="confirmation-dialog-title">YouTube Video</DialogTitle>
            <DialogContent dividers>
                <Grid container>
                    <Grid item md={12}>
                        <TextField
                            id="outlined-basic"
                            name="youtube"
                            className={classes.textInputWidth}
                            size="small"
                            margin="dense"
                            label="Youtube Video Url"
                            value={state.youtube}
                            onChange={inputChangeHandler}
                            error={error}
                            helperText={error}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button
                    onClick={handleOk}
                    color="primary"
                    disabled={!!(error.trim().length > 0 || state.youtube.trim().length === 0)}
                >
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialogRaw;
