/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */
import { Autocomplete } from '@material-ui/lab';
import {
    Button, FormControl, TextField,
} from '@material-ui/core';
import { DeleteDialog, DuplicateDialog } from './components/DeleteDialog';
import { GET_TIMELINE_COLUMN } from './context/Timeline';
import { OrgContext, TimelineContext, UserContext } from './context';
import { RecoilRoot } from 'recoil';
import {
    Route,
    Switch,
    useRouteMatch,
    useLocation,
} from 'react-router-dom';
import { Settings } from './Settings';
import { deepCopy } from './helper/commonHelper';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import { useApolloClient, useQuery } from 'react-apollo';
import AmpStoriesIcon from '@material-ui/icons/AmpStories';
import BallotIcon from '@material-ui/icons/Ballot';
import Box from '@material-ui/core/Box';
import ConfigureDialog from './components/dialog/ConfigureDialog';
import EmptyProtocolDialog from './components/EmptyProtocolDialog';
import Grid from '@material-ui/core/Grid';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ListAltIcon from '@material-ui/icons/ListAlt';
import OrgBreadcrumbs from './components/utils/OrgBreadcrumbs';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import React, {
    useContext, useEffect, useMemo, useState,
} from 'react';
import ShareIcon from '@material-ui/icons/Share';
import ShareTimelineModal from './components/onboarding/ShareTimelineModal';
import Timeline from './components/Timeline';
import TimelinePreviewDialog from './components/onboarding/TimelinePreviewDialog';
import Typography from '@material-ui/core/Typography';
import Workshop from './Workshop';
// eslint-disable-next-line import/no-named-as-default
import CallDataDialog from './components/dialog/CallDataDialog';
import ColumnChangeDialog from './components/dialog/ColumnChangeDialog';
import SettingUserViewDialog from './components/dialog/SettingUserViewDialog';
// eslint-disable-next-line import/no-named-as-default
import WorkshopContextMenu from './WorkshopContextMenu';
import gql from 'graphql-tag';

const useRowStyles = makeStyles((theme) => ({
    workshopView: {
        width: '100%',
    },
    headingConatiner: {
        background: 'white',
        textAlign: 'center',
    },
    showHiddenClass: {
        marginLeft: 10,
    },
    fontFaimlyProxima: {
    },
    timelineView: {
        width: '100%',
    },
    showCallDetailsButton: {
        position: 'fixed',
        right: 0,
        bottom: 0,
        zIndex: 999,
    },
    timelineHeading: {
        margin: '0px',
        fontSize: '25px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        [theme.breakpoints.down('xs')]: {
            margin: '10px 0px',
            fontSize: '15px',
        },
    },
    navCont: {
        paddingLeft: 0,
    },
    configButtonCont: {
        margin: '0rem 1.5rem .65rem 0rem',
        cursor: 'pointer',
        textAlign: 'end',
    },
    docAndUserDropdown: {
        padding: '0px',
    },
    docAndUserDropdownInner: {
        // width: '50%',
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    horizRularStyle: {
        margin: '1rem 0rem .75rem 0rem',
        border: '10px solid #cccccc',
    },
    userAccessButton: {
        padding: '.25rem 1rem',
        zIndex: 2,
        boxShadow: '0px 0px 20px -4px rgba(51,36,5,0.37)',
        marginLeft: '1.5rem',
        '&:hover': {
            boxShadow: 'none',
        },
    },
    shareButton: {
        padding: '0px 10px',
        fontSize: '13px',
        height: '30px !important',
        zIndex: 2,
        boxShadow: '0px 0px 20px -4px rgba(51,36,5,0.37)',
        '&:hover': {
            boxShadow: 'none',
        },
        '& svg': {
            fontSize: '16px !important',
        },
    },
    timelineContainer: {
        background: 'white',
    },
    progressionTitleCont: {
        borderBottom: '1px solid rgba(193, 193, 193, .5)',
        display: 'flex',
        alignItems: 'center',
    },
    progressionTitle: {
        display: 'flex',
        alignItems: 'center',
    },
    timeLine: {
        fontSize: 18,
        marginLeft: '10px',
    },
    timeLineIcon: {
        border: '0.5px solid #70707062',
        borderRadius: 4,
        height: 36,
        width: 36,
        marginTop: 0,
        marginBottom: 6,
    },
    componentIcon: {
        marginRight: 4,
        cursor: 'pointer',
    },
    timeLineSpace: {
        margin: '13px 20px 8px 0px',
    },
    tooltipCont: {
        background: '#FFFFFF',
        color: '#000000de',
        margin: 2,
        padding: 5,
        fontSize: 14,
        border: '1px solid #000000de',
    },
    timelineHeaderStyle: {
        zIndex: 2,
        background: '#FFFFFF',
        position: 'fixed',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '5px 8px',
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    capitalizeLabel: {
        textTransform: 'capitalize',
    },
    alignCenter: {
        textAlign: 'center',
    },
}));

const TimelineContainer = ({ showLoader }) => {
    const { path } = useRouteMatch();
    const classes = useRowStyles();
    const [isShareModal, setIsShareModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState();
    const [selectedDoctor, setSelectedDoctor] = useState({
        docId: '',
        docObj: {},
    });

    const [timelineContainerLoader, setTimelineContainerLoader] = useState(false);
    const [deleteDialogLoader, setDeleteDialogLoader] = useState(false);
    const [resetAutocomplete, setResetAutocomplete] = useState(false);
    const [showCallData, setShowCallData] = useState(false);

    const {
        handleContextMenu, contextMenuConfig, selectedTimeline, setSelectedComponent,
        contextMenuPosition, contextMenuClick, contextMenuClose, currentOrg,
        workshopComponent, isAccessControl, setTimelineShareMode, patientProfileData, inserPatientUTAssociation,
        clickedArea, progressionFilter, cdComponent, selectedPsa, setSelectedPsa, setPatientProfileData,
        dataMatrixComponent, isDMRC, showEmptyDMDialog, columnChangeDialog, configureDialog, setColumnWidthData, columnWidthData,
        setConfigureDialog, inWorkshop, createTimeline, insertUTAssociation, imageEditorLoader, found, settingUserViewDialog,
        setUpdateFlag, updateFlag, setSelectedDoc, isMobile, setCDComponent, setCdClickType, setQueryParams,
    } = useContext(TimelineContext);

    const { loggedUserType, userOrgs } = useContext(OrgContext);
    const { doctors, users, patients } = useContext(UserContext);
    const timeline = selectedTimeline;
    const [selectedTC, setSelectedTC] = useState([]);
    const client = useApolloClient();

    let { hash = '' } = useLocation();

    useEffect(() => {
        if (hash[0] === '#' && hash.length > 4) {
            hash = hash.slice(1);
            const wpQuery = hash.split('#');
            const qrArr = [];
            wpQuery.forEach((q) => {
                const r = q.split('_').join(' ');
                qrArr.push(r.toUpperCase());
            });
            if (qrArr.length >= 2) {
                setQueryParams(qrArr);
            }
        }
    }, [hash]);

    useEffect(() => {
        // eslint-disable-next-line consistent-return
        const fetchUserData = async (userId) => {
            try {
                const { data: val } = await client.query({
                    query: gql`
                      query userData($userId: uuid!) { 
                        user(where: { id: { _eq: $userId } }) {
                          name
                        }
                      }
                    `,
                    variables: { userId },
                });
                return val.user[0];
            } catch (error) {
                console.log(error);
            }
        };
        // eslint-disable-next-line camelcase
        if (timeline?.user_id) {
            fetchUserData(timeline.user_id).then((val) => {
                timeline.userName = val.name;
                setUpdateFlag(!updateFlag);
            });
        }
    }, [timeline]);

    useEffect(() => {
        if (!workshopComponent) {
            setTimelineContainerLoader(false);
        }
    }, [workshopComponent]);

    useEffect(() => {
        if (!selectedDoctor.docId
            && !selectedPsa.psaId && (!patientProfileData?.profile?.firstName || !patientProfileData?.profile?.email)) {
            setSelectedTC([]);
            setTimelineShareMode(null);
        }
    }, [selectedDoctor, selectedPsa, patientProfileData]);

    useEffect(() => {
        if (loggedUserType === 'Super Admin' && selectedDoctor?.docId && timeline?.columns) {
            const updateTC = [];
            for (let i = 1, l = timeline.columns.length; i < l; i += 1) {
                if (timeline?.columns?.[i]?.props?.userAccess?.isDoctor) {
                    updateTC.push(timeline.columns[i].id);
                }
            }
            setSelectedTC([...updateTC]);
        } else if (loggedUserType === 'Super Admin' && !selectedDoctor?.docId) {
            setSelectedTC([]);
        }
    }, [selectedDoctor]);

    const doctorList = useMemo(() => (doctors.map((doctor) => ({ ...doctor, label: `${doctor.name} ${doctor.last_name}` }))), [doctors]);
    const psaList = useMemo(() => {
        const organizationName = userOrgs[0].name;
        if (users) {
            users.forEach((user) => {
                user.label = `${user.name} ${user.last_name}`;
            });
        }

        if (patients) {
            patients.forEach((patient) => {
                patient.label = `${patient.name} ${patient.last_name}`;
            });
        }
        let filteredPatient = [];
        if (selectedTimeline?.props?.patientDetails) {
            filteredPatient = patients.filter((patient) => patient.id === selectedTimeline.props.patientDetails.id);
        }
        if (filteredPatient.length === 0) {
            filteredPatient = [...patients];
        }
        return (
            (users && patients) ? [...users.filter(
                (user) => user.organization_members[0].organization.name === organizationName
            ), ...filteredPatient.filter(
                (patient) => patient.organization_members[0].organization.name === organizationName
            )] : []
        );
    });

    const timelineColumnResponse = useQuery(GET_TIMELINE_COLUMN, {
        variables: {
            timeline_id: selectedTimeline?.id,
            user_id: selectedPsa?.id,
        },
        skip: (found || loggedUserType === 'Super Admin' || (!selectedTimeline?.id || !selectedPsa?.id)),
        refetchQueries: [{
            query: GET_TIMELINE_COLUMN,
            variables: {
                timeline_id: selectedTimeline?.id,
                user_id: selectedPsa?.psaId,
            },
        }],
    });

    const { data: { timeline_column_access: tcAccessData } = {} } = timelineColumnResponse || {};

    if (!timeline) {
        return showLoader(true);
    }

    const appBarHeight = document.getElementById('appBar')?.clientHeight;

    const pageWidth = (isAccessControl) ? '100%' : (columnWidthData?.id && !workshopComponent
        ? `${Number(timeline?.props?.width) + (cdComponent ? 750 : 250)}px` : `${timeline.props.width}px`);
    const sharedTimelineWidth = (timeline?.props?.width / timeline?.columns?.length) * (selectedTC?.length + 1);
    const isProgression = !!((clickedArea === 'Progression' || (workshopComponent && workshopComponent.isProgression)));

    let workshopView = null;
    // eslint-disable-next-line no-unused-vars
    let workshopIcon = '';
    switch (progressionFilter) {
    case 'Images/Videos':
        workshopIcon = <PhotoLibraryIcon />;
        break;
    case 'Form':
        workshopIcon = <ListAltIcon />;
        break;
    case 'Complex Forms':
        workshopIcon = <BallotIcon />;
        break;
    case 'Page':
        workshopIcon = <InsertDriveFileIcon />;
        break;
    case 'Header':
        workshopIcon = <InsertDriveFileIcon />;
        break;
    default:
        workshopIcon = <AmpStoriesIcon />;
    }

    if (workshopComponent && !isDMRC) {
        if (isProgression) {
            workshopView = (
                <Grid container justify="flex-start">
                    <Grid item xs={12}>
                        <div className={classes.workshopView}>
                            <Workshop hasHeader={false} showLoader={showLoader} setTimelineContainerLoader={setTimelineContainerLoader} />
                        </div>
                    </Grid>
                </Grid>
            );
        } else {
            workshopView = (<Workshop showLoader={showLoader} setTimelineContainerLoader={setTimelineContainerLoader} />);
        }
    }

    const displayCongigureDialog = () => {
        setConfigureDialog(true);
    };

    const createUserTimelineAssociation = (userId, patientName) => {
        if (!userId) return null;
        const updateState = { ...patientProfileData };
        updateState.profile.id = userId;
        const cloneTimeline = deepCopy(selectedTimeline);
        const { name } = cloneTimeline;
        const timelineTitle = `${patientName} - ${name}`;
        cloneTimeline.name = timelineTitle;
        cloneTimeline.props.name = timelineTitle;
        cloneTimeline.columns.forEach((col) => {
            if (!col?.props?.isProfile) {
                if (selectedTC.findIndex((tc) => tc === col.id) === -1) {
                    col.props.components = [];
                    col.props.progressions = [];
                }
            }
        });
        setTimelineContainerLoader(true);
        setPatientProfileData(updateState);
        createTimeline({
            title: timelineTitle,
            timelineTemplate: cloneTimeline,
            category: loggedUserType === 'Doctor' ? 'Shared With Patient' : 'Shared',
            userId: updateState.profile.id,
        }).then((timeline) => {
            const userObj = {
                userId: updateState.profile.id,
                orgId: currentOrg.id,
                timeline_id: timeline.id,
                parentTimelineId: selectedTimeline.id,
            };
            inserPatientUTAssociation({ variables: userObj });
            setTimelineContainerLoader(false);
            toast.success('Timeline shared successfully');
        }).catch(() => {
            toast.error('Something went wrong');
            setTimelineContainerLoader(false);
        });
        return null;
    };

    const addDoctorHandler = () => {
        if (loggedUserType === 'Doctor') {
            createUserTimelineAssociation(selectedPsa?.psaObj?.id, selectedPsa?.psaObj?.name);
            setSelectedPsa({ psaId: '', psaObj: { label: null } });
            setSelectedTC([]);
            setResetAutocomplete(!resetAutocomplete);
            setTimelineShareMode(null);
            // setTimelineByPatient();
        } else if (loggedUserType === 'Super Admin') {
            const timelineTemp = JSON.parse(JSON.stringify(selectedTimeline));
            const { name } = timelineTemp;
            const title = `${name}`;
            timelineTemp.name = title;
            timelineTemp.props.name = title;
            const reqObj = {
                shareTimelineFlag: true,
                orgId: selectedDoctor.docObj?.['organization_members'][0]?.['organization_id'],
                category: '',
                selectedDoctor,
            };
            timelineTemp.columns.forEach((col) => {
                if (!col?.props?.isProfile && selectedTC.findIndex((tc) => tc === col.id) === -1) {
                    col.props.components = [];
                    col.props.progressions = [];
                }
            });
            setTimelineContainerLoader(true);

            createTimeline({
                title, timelineTemplate: timelineTemp, category: 'Shared', otherArg: reqObj,
            }).then((timelineObj) => {
                const userObj = {
                    userId: selectedDoctor.docId,
                    orgId: reqObj.orgId,
                    timeline_id: timelineObj.id,
                };
                insertUTAssociation({ variables: userObj });
                setTimelineContainerLoader(false);
                toast.success('Timeline shared successfully');
                setSelectedTC([]);
                setSelectedDoctor({
                    docId: '',
                    docObj: { label: null },
                });
                setResetAutocomplete(!resetAutocomplete);
            });
        }
    };

    const handleModalStatus = () => {
        setIsShareModal(false);
    };

    const handleShareModalClose = (selecteduser = {}) => {
        setSelectedUser(selecteduser);
        handleModalStatus(false);
    };

    const backShareModalHandle = () => {
        setConfigureDialog(false);
        setIsShareModal(true);
    };

    const clearColumnSelection = (e) => {
        e.stopPropagation();
        setColumnWidthData(null);
        setCDComponent(null);
        setCdClickType('');
    };

    const handleDoctorChange = (event, doctorId) => {
        if (!doctorId) {
            setSelectedDoctor({ docId: '', docObj: {} });
            setTimelineShareMode(null);
        } else {
            // eslint-disable-next-line no-unused-vars
            for (const i of doctorList) {
                if (i.id === doctorId) {
                    setSelectedDoctor({ ...selectedDoctor, docId: i.id, docObj: i });
                    setSelectedDoc({ ...selectedDoctor, docId: i.id, docObj: i });
                    break;
                }
            }
            setTimelineShareMode({ id: doctorId });
        }
        clearColumnSelection(event);
    };

    const handlePsaChange = (event, psaId) => {
        if (!psaId) {
            setSelectedPsa({ psaId: '', psaObj: {} });
        } else {
            // eslint-disable-next-line no-unused-vars
            for (const i of psaList) {
                if (i.id === psaId) {
                    setSelectedPsa({ ...selectedPsa, psaId: i.id, psaObj: i });
                    break;
                }
            }
            setTimelineShareMode({ id: psaId });
        }
    };

    const selectedColHandler = (col) => {
        let tcIndex = -1;
        const updateTC = [...selectedTC];
        tcIndex = updateTC.length > 0 ? updateTC.findIndex((tc) => tc === col.id) : -1;

        if (tcIndex === -1) {
            updateTC.push(col.id);
        } else {
            updateTC.splice(tcIndex, 1);
        }
        setSelectedTC(updateTC);
    };

    let isTransferButton = false;
    if (loggedUserType !== 'Patient' && (selectedDoctor.docId || selectedPsa.psaId)) {
        isTransferButton = true;
    }

    return (
        <RecoilRoot>
            {(timelineContainerLoader || imageEditorLoader || deleteDialogLoader)
                && showLoader(timelineContainerLoader || imageEditorLoader || deleteDialogLoader)}
            <div className={classes.timelineContainer}>
                {contextMenuConfig && !workshopComponent
                    && (
                        <WorkshopContextMenu
                            menuConfig={contextMenuConfig}
                            menuPosition={contextMenuPosition}
                            handleClick={contextMenuClick}
                            handleClose={contextMenuClose}
                        />
                    )}
                <Switch>
                    <Route path={path}>
                        {!workshopComponent && (
                            <Grid container id="timelineHeader" className={classes.timelineHeaderStyle} style={{ top: !inWorkshop && appBarHeight }}>
                                <Grid item md={isTransferButton ? 5.5 : isTransferButton} className={classes.navCont}>
                                    <Box>
                                        {dataMatrixComponent
                                            ? (
                                                <OrgBreadcrumbs
                                                    isDataMatrix="true"
                                                    current="Timelines"
                                                    timelineChild={[timeline.name, 'Data Matrix']}
                                                />
                                            )
                                            : (
                                                <OrgBreadcrumbs
                                                    current="Timelines"
                                                    timelineChild={timeline.userName ? `${timeline.userName} - ${timeline.name}` : timeline.name}
                                                />
                                            )}
                                    </Box>
                                </Grid>
                                <Grid item md={isTransferButton ? 3.5 : 4} className={classes.headingConatiner}>
                                    <Typography
                                        variant="h2"
                                        color="inherit"
                                        onContextMenu={(e) => { handleContextMenu(e, timeline, {}, -1); }}
                                        className={classes.timelineHeading}
                                        style={{ maxWidth: isTransferButton && 350, cursor: 'pointer' }}
                                        onClick={(e) => clearColumnSelection(e)}
                                    >
                                        {`Timeline: ${timeline.userName ? `${timeline.userName} -` : ''} ${timeline.name}`}
                                        {' '}
                                    </Typography>
                                </Grid>
                                <Grid item md={2} className={classes.docAndUserDropdown}>
                                    {loggedUserType === 'Super Admin'
                                        && (selectedTimeline.category !== 'Super') ? (
                                            <FormControl variant="outlined" fullWidth size="small" margin="none">
                                                <Autocomplete
                                                    id="demo-simple-select-outlined"
                                                    key={resetAutocomplete}
                                                    className={classes.docAndUserDropdownInner}
                                                    onChange={(event, value) => handleDoctorChange(event, value?.id)}
                                                    value={selectedDoctor.docObj.label}
                                                    options={doctorList}
                                                    getOptionLabel={(option) => option.label}
                                                    renderOption={(option) => option.label}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            size="small"
                                                            label="Receiving Doctor"
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                        ) : null}
                                    {loggedUserType === 'Doctor'
                                        && (selectedTimeline.category !== 'Shared' && selectedTimeline.category !== 'Super') ? (
                                            <FormControl variant="outlined" fullWidth size="small" margin="none">
                                                <Autocomplete
                                                    id="demo-simple-select-outlined"
                                                    key={resetAutocomplete}
                                                    className={classes.docAndUserDropdownInner}
                                                    onChange={(event, value) => handlePsaChange(event, value?.id)}
                                                    value={selectedDoctor.docObj.label}
                                                    options={psaList}
                                                    getOptionLabel={(option) => option.label}
                                                    renderOption={(option) => option.label}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            size="small"
                                                            label="Receiving Patient"
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                        ) : null}
                                </Grid>
                                {isTransferButton && (
                                    <Grid item md={1} className={classes.alignCenter}>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            className={classes.shareButton}
                                            onClick={addDoctorHandler}
                                            endIcon={<ShareIcon />}
                                            style={{
                                                height: `${document.getElementById('demo-simple-select-outlined')?.parentNode?.clientHeight}px`,
                                            }}
                                        >
                                            Transfer
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        )}
                        <Box>
                            {contextMenuConfig
                                && (
                                    <WorkshopContextMenu
                                        menuConfig={contextMenuConfig}
                                        menuPosition={contextMenuPosition}
                                        handleClick={contextMenuClick}
                                        handleClose={contextMenuClose}
                                    />
                                )}
                            {!workshopComponent && !dataMatrixComponent && (
                                <Grid
                                    container
                                    justify="flex-start"
                                    spacing={1}
                                    style={{ width: isMobile ? '100%' : pageWidth, margin: isMobile ? '0px' : '-4px' }}
                                >
                                    <Grid item xs={12}>
                                        <Box className={classes.timelineView} onClick={() => { setSelectedComponent({}); }}>
                                            <Timeline
                                                {...timeline}
                                                selectedDoctor={selectedDoctor.docId}
                                                selectedColHandler={selectedColHandler}
                                                selectedTC={selectedTC}
                                                setSelectedTC={setSelectedTC}
                                                selectedPsa={selectedPsa.psaId}
                                                tcAccessData={tcAccessData}
                                                setTimelineContainerLoader={setTimelineContainerLoader}
                                            />

                                            {(selectedDoctor?.docId || selectedPsa.psaId) && (
                                                <>
                                                    <hr className={classes.horizRularStyle} />
                                                    <div style={{ width: sharedTimelineWidth }}>
                                                        <TimelinePreviewDialog
                                                            timelineData={timeline}
                                                            selectedDoctor={selectedDoctor.docId}
                                                            selectedColHandler={selectedColHandler}
                                                            selectedTC={selectedTC}
                                                            selectedPsa={selectedPsa.psaId}
                                                            tcAccessData={tcAccessData}
                                                            setTimelineContainerLoader={setTimelineContainerLoader}
                                                            selectedDoctorDetails={selectedDoctor.docObj}
                                                            selectedPatientDetails={selectedPsa.psaObj.organization_members
                                                                && selectedPsa.psaObj.organization_members[0].role
                                                                === 'Patient' ? selectedPsa.psaObj : null}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        </Box>
                                    </Grid>
                                </Grid>
                            )}
                            {workshopView}
                        </Box>
                        <Settings />
                    </Route>
                </Switch>
                <DeleteDialog setDeleteDialogLoader={setDeleteDialogLoader} />
                <DuplicateDialog />
                {showEmptyDMDialog && <EmptyProtocolDialog />}
                {configureDialog && <ConfigureDialog handleBack={backShareModalHandle} user={selectedUser} showLoader={showLoader} />}
                {columnChangeDialog && <ColumnChangeDialog handleBack={backShareModalHandle} showLoader={showLoader} />}
                {isShareModal && (
                    <ShareTimelineModal
                        isShareModal={isShareModal}
                        handleModalClose={handleShareModalClose}
                        displayCongigureDialog={displayCongigureDialog}
                    />
                )}
                {settingUserViewDialog && <SettingUserViewDialog />}
                {showCallData && <CallDataDialog showCallData={showCallData} setShowCallData={setShowCallData} />}
            </div>
        </RecoilRoot>
    );
};

// eslint-disable-next-line import/prefer-default-export
export { TimelineContainer };
