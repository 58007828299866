import './css/font.css';
import './css/loader.scss';
import { ApolloProvider } from '@apollo/react-hooks';
import { Box, CssBaseline } from '@material-ui/core';
import { CommonContextProvider, OrgContextProvider } from './context';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import {
    BrowserRouter as Router,
    Switch,
} from 'react-router-dom';
import { ThemeProvider, createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { ToastContainer } from 'react-toastify';
import { useAuth0 } from './react-auth0-spa';
import ApolloClient from 'apollo-client';
import Loader from 'react-loaders';
import Org from './Org';
import OrgList from './OrgList';
import PrivateRoute from './PrivateRoute';
import React, { useState } from 'react';
import SignUpForm from './SignUpForm';
import TopNav from './TopNav';
import UserProfile from './UserProfile';

// Theme
const theme = createMuiTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 767,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
    palette: {
        background: {
            default: '#FFF',
        },
        primary: {
            main: '#066785',
            light: '#e8e8e8',
        },
        secondary: {
            main: '#1B1B1B',
            light: '#1B1B1BAD',
        },
        tonalOffset: 0.1,
    },
    typography: {
        h1: {
            fontFamily: 'Proxima Nova Bold !important',
            fontWeight: 700,
            fontSize: 24,
        },
        h2: {
            fontFamily: 'ProximaNova-Regular !important',
            fontSize: 22,
            fontWeight: 700,
        },
        h3: {
            fontFamily: 'ProximaNova-Regular !important',
            fontSize: 20,
            fontWeight: 700,
        },
        h4: {
            fontFamily: 'ProximaNova-Regular !important',
            fontSize: 18,
            fontWeight: 700,
        },
        h5: {
            fontFamily: 'ProximaNova-Regular !important',
            fontSize: 16,
            fontWeight: 700,
        },
        subtitle1: {
            fontFamily: 'ProximaNova-Regular !important',
            fontSize: 16,
            fontWeight: 700,
        },
        subtitle2: {
            fontFamily: 'ProximaNova-Regular !important',
            fontSize: 16,
            fontWeight: 700,
        },
        body1: {
            fontFamily: 'ProximaNova-Regular !important',
            fontWeight: 700,
            fontSize: 14,
        },
        body2: {
            fontFamily: 'ProximaNova-Regular !important',
            fontWeight: 700,
            fontSize: 14,
        },
        button: {
            fontFamily: 'ProximaNova-Regular !important',
            fontSize: 16,
            fontWeight: 700,
            textTransform: 'capitalize',
        },
    },
});

// Custom Style
const useStyles = makeStyles((tempTheme) => ({
    root: {
        flexGrow: 1,
    },
    flex: {
        flexGrow: 1,
    },
    widget: {
        marginTop: tempTheme.spacing(2),
        marginBottom: tempTheme.spacing(2),
    },
    link: {
        color: tempTheme.palette.text.secondary,
    },
    buttonGroup: {
        '& > *': {
            margin: tempTheme.spacing(1),
        },
    },
}));

const createApolloClient = (authToken) => new ApolloClient({
    link: new HttpLink({
        uri: process.env.REACT_APP_GRAPH_QL_END_POINT,
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
    }),
    // cache: new InMemoryCache(),
    cache: new InMemoryCache({ addTypename: false }),
    connectToDevTools: true,
});

export const showLoader = (status) => {
    if (!status) {
        return '';
    }
    return (
        <div id="overlay">
            <div id="loader">
                <Loader type="line-scale" />
            </div>
        </div>
    );
};

const App = () => {
    const currentURL = window.location.href;
    const urlParams = new URLSearchParams(new URL(currentURL).search);
    const reqObj = urlParams.get('src') || null;
    let decodedReqObj;
    let authToken;
    if (reqObj) {
        decodedReqObj = JSON.parse(Buffer.from(reqObj, 'base64').toString());
        authToken = decodedReqObj?.authToken;
    }
    const [source, setSource] = useState(decodedReqObj?.source || null);
    const classes = useStyles();

    const {
        idToken,
        loading,
    } = useAuth0();

    if (loading) {
        return showLoader(true);
    }

    const client = createApolloClient(source === 'SSR' ? authToken : idToken);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <ToastContainer />
            <div className={classes.root}>
                {source === 'SSR'
                    ? (
                        <ApolloProvider client={client}>
                            <SignUpForm setSource={setSource} reqObj={decodedReqObj} />
                        </ApolloProvider>
                    ) : (
                        <ApolloProvider client={client}>
                            <Router>
                                <OrgContextProvider idToken={idToken}>
                                    <TopNav />
                                    <Box mt={8}>
                                        <Switch>
                                            <PrivateRoute exact path="/profile">
                                                <UserProfile showLoader={showLoader} />
                                            </PrivateRoute>
                                            <PrivateRoute exact path="/">
                                                <CommonContextProvider>
                                                    <OrgList showLoader={showLoader} />
                                                </CommonContextProvider>
                                            </PrivateRoute>
                                            <PrivateRoute path="/:orgID/">
                                                <Org showLoader={showLoader} />
                                            </PrivateRoute>
                                        </Switch>
                                    </Box>
                                </OrgContextProvider>
                            </Router>
                        </ApolloProvider>
                    ) }
            </div>
        </ThemeProvider>
    );
};
export default App;
