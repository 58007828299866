/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-extraneous-dependencies */
import { Button } from '@material-ui/core';
import { Line } from 'rc-progress';
import { MediaEditorContext, OrgContext } from '../context';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import 'tui-image-editor/dist/tui-image-editor.css';
import Dropzone from 'react-dropzone';
import Grid from '@material-ui/core/Grid';
import ImageEditor from '@toast-ui/react-image-editor';
import MediaStyle from '../styles/media';
import React, {
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import Resizer from 'react-image-file-resizer';
import Typography from '@material-ui/core/Typography';
import UploadImg from '../images/FileUpload.svg';
import httpHelper from '../helper/httpHelper';

const useStyles = makeStyles(() => ({
    mainCont: {
        borderTop: '1px solid #cbcbcb',
    },
    primaryBtn: {
        maxHeight: '56px',
        padding: '.25rem 1rem',
        boxShadow: 'none',
        '&:hover': {
            boxShadow: 'none',
        },
    },
    editorCont: {
        padding: '1rem',
        borderBottom: '1px solid #cbcbcb',
        '& .tui-image-editor-container': {
            height: '77vh !important',
        },
    },
    primaryBtnCont: {
        textAlign: 'right',
        margin: '.75rem .75rem 0rem 0rem',
    },
    width200: {
        width: 200,
    },
}));

const typeObj = {
    copy: 'Copy',
    duplicate: 'Duplicate',
};

const ToastUiImageEditor = ({
    imageEditorData,
    imageEditorRef,
}) => {
    useEffect(() => {
        document.getElementsByClassName('tie-btn-zoomIn')[0].remove();
        document.getElementsByClassName('tie-btn-zoomOut')[0].remove();
        document.getElementsByClassName('tie-btn-hand')[0].remove();
        document.getElementsByClassName('tie-btn-delete')[0].remove();
        document.getElementsByClassName('tie-btn-deleteAll')[0].remove();
    }, []);

    const myTheme = {
        'header.display': 'none',
    };

    return (
        <ImageEditor
            ref={imageEditorRef}
            includeUI={{
                loadImage: {
                    path: URL.createObjectURL(imageEditorData),
                    name: imageEditorData.name,
                },
                theme: myTheme,
                menu: ['crop', 'flip', 'rotate', 'draw', 'text', 'filter'],
                initMenu: 'filter',
                uiSize: {
                    width: '1000px',
                    height: '700px',
                },
                menuBarPosition: 'right',
            }}
            cssMaxHeight={500}
            cssMaxWidth={700}
            selectionStyle={{
                cornerSize: 20,
                rotatingPointOffset: 70,
            }}
            usageStatistics
        />
    );
};

const UploadMedia = (props) => {
    const cssClasses = useStyles();
    const {
        classes,
        handleClose,
        onSelectedMedia,
        fetchFolderId,
        imageUploadHandler,
        mediaActionType,
        selectedMedia,
        setParentState = null,
        imageActionSuccessfull = null,
    } = props;
    const { selectedOrg, idToken } = useContext(OrgContext);
    const { insertImage, selectedMediaAction } = useContext(MediaEditorContext);
    const [selectedImage, setSelectedImage] = useState([]);
    const [selectedVideo, setSelectedVideo] = useState([]);
    const [imageProgressObj, setImageProgressBar] = useState({
        imagePercent: 0,
        imageProgressBarFlag: false,
    });
    const [videoProgressObj, setVideoProgressBar] = useState({
        videoPercent: 0,
        videoProgressBarFlag: false,
    });
    const [uploadImageFlag, setUploadImageFlag] = useState(true);
    const imageEditorRef = useRef(null);

    const [error, setError] = useState({ status: false, message: '' });
    let intervalValue = 0;
    const videoUploadResponse = [];
    const vimeoVideoIds = [];
    let folderId = null;

    /**
     * Handle Drop event of React Dropzone
     * @param {Array} accepted [Accepted Files]
     * @param {Array} rejected [Rejected Files]
     */
    const handleDrop = (accepted, rejected) => {
        let fileError = {};
        const selectedImageArr = [];
        const selectedVideos = [];

        if (rejected.length > 0) {
            fileError = {
                status: true,
                message: 'Error while uploading the file. Please try again!',
            };
            setError(fileError);
        } else if (accepted.length > 0) {
            for (let i = 0; i < accepted.length; i += 1) {
                if (
                    accepted[i].type === 'image/jpeg'
                    || accepted[i].type === 'image/png'
                    || accepted[i].type === 'image/jpg'
                ) {
                    selectedImageArr.push(accepted[i]);
                } else {
                    selectedVideos.push(accepted[i]);
                }
            }
        }
    
        setSelectedImage([...selectedImageArr]);
        setSelectedVideo([...selectedVideos]);
    };

    useEffect(() => {
    }, [selectedImage]);

    useEffect(() => {
        if (selectedVideo?.length > 0) {
            uploadVideo();
        }
    }, [selectedVideo]);

    /**
     * Upload Image function and start progress bar
     * @return {[type]} [description]
     */
    const uploadImage = async (imageURL, isAction = false) => {
        const selectedImageArr = isAction ? [{ ...selectedMedia }] : [...selectedImage];
        if (selectedImageArr.length > 0) {
            const data = new FormData();
            data.append('media', imageURL);
            if (!isAction || mediaActionType.type === typeObj.duplicate) { folderId = await fetchFolderId(); }
            const httpObj = {
                url: 'storage/upload',
                method: 'POST',
                data,
                headers: { Authorization: `Bearer ${idToken}` },
            };
            setUploadImageFlag(true);
            setImageProgressBar({ imagePercent: 1, imageProgressBarFlag: true });
            httpHelper(
                httpObj,
                imageUploadSuccessfull,
                requestFailure,
                false,
                updateMediaProgressBarValue
            );
        }
    };

    /**
     * Upload Video function and start progress bar
     * @return {[type]} [description]
     */
    const uploadVideo = async () => {
        const selectedVideos = [...selectedVideo];

        if (selectedVideos.length > 0) {
            const data = new FormData();

            for (let i = 0; i < selectedVideos.length; i += 1) {
                data.append('media', selectedVideos[i]);
            }
            folderId = await fetchFolderId();
            const httpObj = {
                url: 'storage/upload',
                method: 'POST',
                data,
                headers: { Authorization: `Bearer ${idToken}` },
            };

            setUploadImageFlag(false);

            setVideoProgressBar({ videoPercent: 1, videoProgressBarFlag: true });
            httpHelper(
                httpObj,
                imageUploadSuccessfull,
                requestFailure,
                false,
                updateMediaProgressBarValue
            );
        }
    };

    /**
     * Calculate images upload progress bar
     * @return {null} [description]
     */
    const updateMediaProgressBarValue = (evt) => {
        const perc = Math.round((evt.loaded * 100) / evt.total);

        if (uploadImageFlag && selectedImage.length > 0) {
            setImageProgressBar({ imagePercent: perc, imageProgressBarFlag: true });
        } else {
            setVideoProgressBar({ videoPercent: perc, videoProgressBarFlag: true });
        }
    };

    /**
     * Remove images upload progress bar when upload completed
     * @return {null} [description]
     */
    const removeImageProgressBar = () => {
        setTimeout(() => {
            const progressError = {
                status: true,
                message: '',
            };
            setError(progressError);
            setImageProgressBar({ imagePercent: 0, imageProgressBarFlag: false });
        }, 2000);
    };

    /**
     * Remove videos upload progress bar when upload completed
     * @return {null} [description]
     */
    const removeVideoProgressBar = () => {
        setTimeout(() => {
            const progressError = {
                status: true,
                message: '',
            };

            setError(progressError);
            setVideoProgressBar({ videoPercent: 0, videoProgressBarFlag: false });
        }, 2000);
    };

    /**
     * Add Basic Info failure callback.
     * @param {Object} error [Error Data.]
     * @return {[type]} [description]
     */
    const requestFailure = (e) => {
        const requestError = {
            status: true,
            message: e.message,
        };

        removeImageProgressBar();
        removeVideoProgressBar();
        setError(requestError);
    };

    /**
     * When the file uploading is successful.
     * @return {Object} [HTTP Response]
     */
    const imageUploadSuccessfull = async ({ data }) => {
        if (data) {
            const dataObj = [];
            for (let i = 0; i < data.length; i += 1) {
                dataObj.push({
                    organization_id: selectedOrg.id,
                    name: data[i].name,
                    filetype: selectedVideo.length === 0 ? `image/${data[i].name.split('.').pop()}` : `video/${data[i].name.split('.').pop()}`,
                    url: data[i].url,
                    folder_id: folderId || null,
                });
            }

            if (!selectedMediaAction || (selectedMediaAction?.type === typeObj.duplicate)) {
                await insertImage(dataObj);
                imageUploadHandler(true);
            } else if (selectedMediaAction?.type === typeObj.copy) {
                imageActionSuccessfull(dataObj);
            }

            if ((selectedMediaAction?.type !== typeObj.copy && selectedMediaAction?.type !== typeObj.duplicate
                && selectedMediaAction?.type !== typeObj.move) || !selectedMediaAction) {
                props.passUploadedImage(dataObj);
            }

            const successMsg = {
                status: false,
                message: 'Images Uploaded Successfully!',
            };

            removeImageProgressBar();
            setError(successMsg);
            const array = [{ url: data[0].url, name: data[0].name }];
            if (typeof (onSelectedMedia) === 'function') {
                onSelectedMedia(array);
            }
            if (typeof (handleClose) === 'function') {
                handleClose();
            }
        } else {
            requestFailure(data);
        }
    };

    /**
     * Upload Video function and start progress bar
     * @param {Array} videoIds [passing recent video id array]
     * @return {[Array]} [return array of object with video id and status]
     */
    const checkVideoUpload = () => {
        const httpObj = {
            url: 'upload/transcode-status',
            method: 'POST',
            data: { videos: vimeoVideoIds },
            fileType: 'video',
            headers: { Authorization: `Bearer ${idToken}` },
        };

        httpHelper(httpObj, checkVideoUploadStatus, requestFailure, false, updateMediaProgressBarValue);
    };

    /**
     * Video status check
     * @return {[Array]} [return array of object with video id and status]
     */
    const checkVideoUploadStatus = ({ data }) => {
        const dataObj = [];

        if (data) {
            const filterVideoByStatus = data.filter((video) => video.status !== 'complete');

            if (filterVideoByStatus.length === 0) {
                clearInterval(intervalValue);

                for (let i = 0; i < videoUploadResponse.length; i += 1) {
                    dataObj.push({
                        organization_id: selectedOrg.id,
                        name: videoUploadResponse[i].name,
                        filetype: `video/${videoUploadResponse[i].name.split('.').pop()}`,
                        url: videoUploadResponse[i].url,
                        folder_id: folderId || null,
                    });
                }

                insertImage(dataObj);
                props.passUploadedImage(dataObj);

                const successMsg = {
                    status: false,
                    message: 'videos Uploaded Successfully!',
                };

                removeVideoProgressBar();
                setError(successMsg);
                const array = [{ url: videoUploadResponse[0].url, name: videoUploadResponse[0].name }];
                if (typeof (onSelectedMedia) === 'function') {
                    onSelectedMedia(array);
                }
                if (typeof (handleClose) === 'function') {
                    handleClose();
                }
            } else {
                clearInterval(intervalValue);
                intervalValue = setInterval(() => { checkVideoUpload(); }, 2000);
            }
        }
    };

    /**
     * Showcase or Hide Error Messages.
     */
    const showError = () => {
        let errorComp = null;
        // eslint-disable-next-line no-shadow
        const { classes } = props;
        const errorClass = error.status ? classes.errorMsg : classes.successMsg;

        if (error.message) {
            errorComp = (
                <Grid container>
                    <Grid item sm={12} md={12} lg={12} className={classes.errDiv}>
                        <span className={errorClass}>{error.message}</span>
                    </Grid>
                </Grid>
            );
        }

        return errorComp;
    };

    const saveImage = (selecteUrl = null) => {
        const imageEditorInstance = imageEditorRef?.current?.getInstance();
        const url = selecteUrl?.url ? selecteUrl.url : imageEditorInstance?.toDataURL();
        const mediaData = (selectedImage?.length > 0 && selectedImage) || [{ ...selectedMedia }];
        if (!mediaData[0]?.type && selecteUrl?.url) {
            mediaData[0].type = selectedMedia.filetype;
            mediaData[0].name = `${selectedMedia.name}`;
        }
        fetch(url)
            .then((res) => res.blob())
            .then((blob) => {
                const file = new File([blob], mediaData[0].name, { type: mediaData[0].type });
                const img = new Image();
                const objectUrl = URL.createObjectURL(file);
                img.onload = () => {
                    try {
                        let compressionFormat = '';
                        if (mediaData[0].type.includes('jpeg') || mediaData[0].type.includes('jpg')) {
                            compressionFormat = 'JPEG';
                        } else if (mediaData[0].type.includes('png')) {
                            compressionFormat = 'PNG';
                        } else {
                            compressionFormat = 'JPEG';
                        }
                        Resizer.imageFileResizer(
                            file,
                            img.width,
                            img.height,
                            compressionFormat,
                            95,
                            0,
                            (uri) => {
                                uploadImage(uri, !!selecteUrl?.url);
                            },
                            'file',
                            img.width,
                            img.height,
                        );
                    } catch (err) {
                        // eslint-disable-next-line no-console
                        console.error(err);
                    }
                };
                img.src = objectUrl;
            });
    };

    useEffect(() => {
        if ((mediaActionType?.type === typeObj.copy) || (mediaActionType?.type === typeObj.duplicate)) {
            if (!selectedMedia.isVideo) {
                saveImage(selectedMedia);
            } else if (selectedMedia.isVideo) {
                fetch(selectedMedia.url)
                    .then((res) => res.blob())
                    .then((blob) => {
                        const file = new File([blob], selectedMedia.name, { type: selectedMedia.filetype });
                        setSelectedVideo([file]);
                    });
            }
            if (setParentState) { setParentState({}); }
        }
    }, [mediaActionType]);

    if ((mediaActionType?.type === typeObj.copy) || (mediaActionType?.type === typeObj.duplicate) || selectedMedia) {
        return null;
    }

    return (
        <Grid container className={cssClasses.mainCont}>
            <Grid item sm={12} md={12} lg={12}>
                <Dropzone
                    onDrop={handleDrop}
                    multiple={false}
                    accept="image/jpeg, image/png, video/*"
                >
                    {({ getRootProps, getInputProps }) => (
                        <>
                            <Grid container {...getRootProps()}>
                                {selectedImage.length === 0 && selectedVideo.length === 0 ? (
                                    <>
                                        <Grid item sm={12} md={12} lg={12} className={classes.dropZoneMsg}>
                                            <img
                                                className={classes.UpldImg}
                                                src={UploadImg}
                                                alt="Data upload"
                                            />
                                        </Grid>
                                        <Grid item sm={12} md={12} lg={12} className={classes.dropZoneMsg}>
                                            <input {...getInputProps()} />
                                            <Typography className={classes.drpznTxt}>
                                                Drop Images here or Choose a Image to upload.
                                            </Typography>
                                        </Grid>
                                        <Grid item sm={12} md={12} lg={12} className={classes.drpznTxtHelper}>
                                            Supported format for Image (jpeg, png, jpg) and for video ( MP4, MOV)
                                        </Grid>
                                    </>
                                ) : null}
                                {imageProgressObj.imageProgressBarFlag || videoProgressObj.videoProgressBarFlag ? (
                                    <>
                                        <Grid item sm={12} md={12} lg={12} className={classes.mdleAlign}>
                                            <Grid container className={classes.mdleAlign}>
                                                <Grid item sm={12} md={12} lg={12} className={classes.mdleAlign}>
                                                    <Typography className={classes.fileName}>
                                                        {imageProgressObj.imageProgressBarFlag && (
                                                            <div className={cssClasses.width200}>
                                                                Uploading Images...
                                                                <Line
                                                                    percent={imageProgressObj.imagePercent}
                                                                    strokeWidth="4"
                                                                    strokeColor="#2db7f5"
                                                                    strokeLinecap="square"
                                                                />
                                                            </div>
                                                        )}
                                                    </Typography>
                                                </Grid>
                                                <Grid item sm={12} md={12} lg={12} className={classes.mdleAlign}>
                                                    <Typography className={classes.fileName}>
                                                        {videoProgressObj.videoProgressBarFlag && (
                                                            <div className={cssClasses.width200}>
                                                                Uploading Videos...
                                                                <Line
                                                                    percent={videoProgressObj.videoPercent}
                                                                    strokeWidth="4"
                                                                    strokeColor="#2db7f5"
                                                                    strokeLinecap="square"
                                                                />
                                                            </div>
                                                        )}
                                                    </Typography>
                                                </Grid>
                                                <Grid item sm={12} md={12} lg={12} className={classes.mdleAlign}>
                                                    <Typography className={classes.fileName}>
                                                        {showError()}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </>
                                ) : null}
                            </Grid>
                        </>
                    )}
                </Dropzone>
                {
                    !imageProgressObj.imageProgressBarFlag && selectedImage.map((ele, index) => (
                        <>
                            <Grid key={index} item xs={12} className={cssClasses.editorCont}>
                                <ToastUiImageEditor imageEditorData={ele} imageEditorRef={imageEditorRef} />
                            </Grid>
                            <Grid key={index} item xs={12} className={cssClasses.primaryBtnCont}>
                                <Button onClick={saveImage} variant="contained" color="primary" className={cssClasses.primaryBtn}>
                                    Submit
                                </Button>
                            </Grid>
                        </>
                    ))
                }
            </Grid>
        </Grid>
    );
};

export default withStyles(MediaStyle)(UploadMedia);
