import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { showLoader } from '../../App';
import { useMutation } from '@apollo/react-hooks';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import gql from 'graphql-tag';

const ADD_INDUSTRY_SPECIALITY = gql`
mutation AddIndustrySpeciality( $name: String, $category: String, $speciality_parent: String) {
    insert_industry_speciality(objects: {name: $name, category: $category, speciality_parent: $speciality_parent}) {
        affected_rows
         returning {
             id
         }
    }
  }
`;

const useStyles = makeStyles(() => ({
    widthSectPart: {
        width: 440,
        minHeight: 'auto',
    },
    headerStyle: {
        borderBottom: '1px solid #CBCBCB',
        padding: '.5rem 1rem',
    },
    alignRight: {
        textAlign: 'right',
    },
    closeIconSTyle: {
        cursor: 'pointer',
        marginTop: '.5rem',
    },
    showError: {
        color: '#DC143C',
    },
    footerStyle: {
        padding: '.5rem 1rem',
    },
    primaryBtn: {
        maxHeight: '56px',
        padding: '.25rem 1rem',
        boxShadow: '0px 0px 20px -4px rgba(51,36,5,0.37)',
        '&:hover': {
            boxShadow: 'none',
        },
    },
}));

const IndustrySpecialityDialog = ({
    industrySpecialityDialog, setIndustrySpecialityDialog, isIndustryDialog, nameList, industryName,
}) => {
    const classes = useStyles();
    const [loaderState, setLoaderState] = useState(false);

    const [addIndustrySpeciality] = useMutation(ADD_INDUSTRY_SPECIALITY);

    const [textFieldValue, setTextFieldValue] = useState('');
    const [isUniqueTextFieldValue, setIsUniqueTextFieldValue] = useState(true);

    const handleClose = () => {
        setIndustrySpecialityDialog(false);
    };

    const handleTextFieldChange = (event) => {
        setTextFieldValue(event.target.value);
        if (nameList.includes(event.target.value)) {
            setIsUniqueTextFieldValue(false);
        } else {
            setIsUniqueTextFieldValue(true);
        }
    };

    const addIndustry = async () => {
        setLoaderState(true);
        await addIndustrySpeciality({
            variables: {
                name: textFieldValue,
                category: 'Industry',
            },
        });
        setLoaderState(false);
        window.location.reload();
    };

    const addSpeciality = async () => {
        setLoaderState(true);
        await addIndustrySpeciality({
            variables: {
                name: textFieldValue,
                category: 'Speciality',
                speciality_parent: industryName,
            },
        });
        setLoaderState(false);
        window.location.reload();
    };

    return (
        <Dialog onClose={handleClose} open={industrySpecialityDialog} fullWidth classes={{ paperWidthSm: classes.widthSectPart }}>
            {loaderState && showLoader(loaderState)}
            <DialogTitle classes={{ root: classes.headerStyle }}>
                <Grid container justify="space-between" alignItems="center">
                    <Grid item xs={10}>
                        {isIndustryDialog ? 'Add Industry' : 'Add Speciality'}
                    </Grid>
                    <Grid item xs={2} className={classes.alignRight}>
                        <CloseIcon onClick={handleClose} className={classes.closeIconSTyle} />
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <Box display="flex" alignItems="center" justifyContent="center">
                            <TextField
                                placeholder={isIndustryDialog ? 'Industry Name' : 'Speciality Name'}
                                variant="outlined"
                                size="small"
                                fullWidth
                                margin="dense"
                                autoComplete="off"
                                value={textFieldValue}
                                onChange={handleTextFieldChange}
                            />
                        </Box>
                        {!isUniqueTextFieldValue && (
                            <small
                                className={classes.showError}
                            >
                                {isIndustryDialog ? 'Industry name alredy exists.' : 'Speciality name alredy exists.'}

                            </small>
                        )}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions classes={{ root: classes.footerStyle }}>
                <Button
                    className={classes.primaryBtn}
                    variant="contained"
                    color="primary"
                    disabled={!textFieldValue || !isUniqueTextFieldValue}
                    onClick={isIndustryDialog ? addIndustry : addSpeciality}
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default IndustrySpecialityDialog;
