/* eslint-disable react/jsx-closing-tag-location */
import './css/style.css';

import {
    ADD_COMPONENT_AC,
    ADD_COMPONENT_POI,
    GET_COMPONENT_AC,
    GET_COMPONENT_POI,
    GET_SELECTED_CHILDREN,
    UPDATE_COMPONENT_AC,
    UPDATE_COMPONENT_POI,
    UPDATE_SELECTED_CHILDREN,
    accessViewInitialValue,
} from './context/Timeline';
import { Alert } from '@material-ui/lab';
import {
    Box,
    Card, CardContent, CardMedia,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Snackbar,
    TextField,
    Tooltip,
} from '@material-ui/core';
import {
    ColumnContextProvider,
    OrgContext,
    TimelineContext,
    WorkshopContext,
    WorkshopContextProvider,
} from './context';
import { Container } from './components/Container';
import { ImageGallery, ImagePlaceholder, useImgStyles } from './components/ImageSelector';
import { ProcedureDialog, ProcedureSelectorGrid } from './components/ProcedureDialog';
import { ProcedureLibrary } from './initialConfig';
import { SettingsWidget } from './Settings';
import { TemplateBrowser } from './TemplateBrowser';
import {
    buildSettingsTreePage,
    checkEmptyObject,
    createEmptyCell,
    findComponent,
    findPage,
    findParents,
    findTimelineColumnWidth,
    flatOptions,
    getIframeHyperlink,
    getYouTubeId,
} from './helper/commonHelper';
import { componentWithId, updateComponentCallback } from './components/Atoms';
import {
    copyTemplate,
    copyTemplateWithAssociatedCell,
    copyTemplateWithImg,
    copyTemplateWithPOI,
} from './context/util';
import { makeStyles } from '@material-ui/core/styles';
import { standardHeaders } from './components/utils/StandardStyles';
import { toast } from 'react-toastify';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useRecoilCallback, useRecoilState } from 'recoil';
import AccessControl from './components/AccessControl';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AddBoxIcon from '@material-ui/icons/AddBox';
import AdminSettings from './components/AdminSettings';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';
import CloseIcon from '@material-ui/icons/Close';
import CompareAll from './components/CompareAll';
import CompareIcon from './images/compare_ico.svg';
import DeleteIcon from '@material-ui/icons/Delete';
import Divider from '@material-ui/core/Divider';
import ErrorBoundary from './components/error-boundary';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import Grid from '@material-ui/core/Grid';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Paper from '@material-ui/core/Paper';
import PhotoIcon from '@material-ui/icons/Photo';
import React, {
    Suspense,
    lazy,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import Split from 'react-split';
import StandardHeader from './components/StandardHeader';
import TemplateDialog from './components/TemplateDialog';
import TemplateLibraryDialog from './components/TemplateLibraryDialog';
import TimelineColumn from './components/TimelineColumn';
import TimelineUserViewDialog from './components/dialog/TimelineUserViewDialog';
import TuneIcon from '@material-ui/icons/Tune';
import Typography from '@material-ui/core/Typography';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import WarningDialog from './components/WarningDialog';
import _, { debounce } from 'lodash';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import gql from 'graphql-tag';
import httpHelper from './helper/httpHelper';

const CarouselSwiper = lazy(() => import('./components/utils/Swiper'));

const GET_DELETED_OTHERS_MEDIA = gql`
    query GetDeletedOthersMedia($component_id: [uuid!]) {
        media_folder_new(where: {component_id: {_in: $component_id}}) {
            timeline_id
            column_id
            component_id
            media {
                url
            }
        }
    }
`;

const DELETE_OTHERS_FOLDER = gql`
mutation DeleteOthersFolder($component_id: [uuid!]) {
    delete_media_folder_new(where: {component_id: {_in: $component_id}}) {
        affected_rows
        returning {
            id
        }
    }
  }
`;

const INSERT_CONTENT_DISPLAY = gql`
    mutation InsertContentDisplay($timeline_id: uuid, $column_id: uuid, $protocol_id: uuid, $image_list: jsonb,) {
        insert_content_display(objects: {timeline_id: $timeline_id, column_id: $column_id, protocol_id: $protocol_id, image_list: $image_list}) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const GET_CONTENT_DISPLAY = gql`
    query GetContentDisplay($protocol_id: uuid) {
        content_display(where: {protocol_id: {_eq: $protocol_id}}) {
            image_list
        }
    }
`;

export const UPDATE_CONTENT_DISPLAY = gql`
    mutation UpdateContentDisplay($protocol_id: uuid, $image_list: jsonb) {
        update_content_display(where: {protocol_id: {_eq: $protocol_id}}, _set: { image_list: $image_list, } ) {
            affected_rows
            returning {
                id
            }
        }
    } 
 `;

export const DELETE_CONTENT_DISPLAY = gql`
    mutation DeleteContentDisplay($protocol_id: uuid) {
        delete_content_display(where: {protocol_id: {_eq: $protocol_id}}) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

const breakpoints = createBreakpoints({});

const STEPS = {
    SELECT_TEMPLATE: { label: 'Select Template', index: 0, id: 'template' },
    PROTOCOL_SETTINGS: { label: 'Protocol Settings', index: 1, id: 'settings' },
    POI: { label: 'POI ', index: 2, id: 'POI' },
    ACCESS_CONTROL: { label: 'User Access', index: 3, id: 'userAccess' },
};

export const useStyles = makeStyles((theme) => ({
    accessControlGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3,minmax(auto ,1fr))',
        gap: '2px 2px',
        marginTop: 2,
        alignItems: 'left',
        width: 'inherit',
    },
    chipContainer: {
        marginBottom: 0,
        marginTop: 0,
    },
    headingTextLeft: {
        fontSize: 14,
        border: '1px solid #CBCBCB',
        padding: '4px 4px 4px 8px',
        textAlign: 'left',
    },
    headingTextCenter: {
        fontSize: 16,
        border: '1px solid #CBCBCB',
        padding: '4px 4px 4px 8px',
        textAlign: 'center',
    },
    headingText: {
        fontSize: 14,
        border: '1px solid #CBCBCB',
        padding: 4,
        textAlign: 'center',
    },
    chip: {
        '&&': {
            borderRadius: 0,
            width: '94%',
            justifyContent: 'space-between',
            border: '1px solid #7070702E',
            backgroundColor: '#C7C7C71A',
        },
    },
    accessControlTitle: {
        background: '#0113191A',
        textAlign: 'center',
        padding: 9,
        color: '#011E27',
        marginBottom: '4px',
        fontSize: '18px',
    },
    visibilityIconStyle: {
        border: '0.5px solid #70707062',
        borderRadius: 4,
        height: 32,
        width: 32,
        position: 'absolute',
        right: 12,
        color: '#fff',
        background: '#066785',
        marginBottom: 1,
        '&:hover': {
            background: '#017a9f',
        },
    },
    hideitem: {
        display: 'none',
    },
    accessControlItem: {
        background: '#D2D7D8',
        color: '#000000',
        paddingLeft: 4,
        textAlign: 'left',
    },
    accessControlItemPo: {
        color: '#066785',
        backgroundColor: '#D2D7D8',
        padding: '0px 25px',
        margin: '4px 2px 30px 2px',
        maxWidth: '32.8%',
        flexBasis: '32.8%',
    },
    accessControlDivider: {
        background: '#000',
        color: '#fff',
        marginTop: 8,
        textAlign: 'center',
        padding: 9,
    },
    accessControlHeader: {
        background: '#efefef',
        padding: 4,
        textAlign: 'center',
    },
    accessControlHeaderPo: {
        background: '#F8F8F8',
        padding: 8,
        textAlign: 'center',
        fontSize: '16px',
        color: '#011E27',
        margin: '0px 2px',
        maxWidth: '32.8%',
        flexBasis: '32.8%',
    },
    container: {
        marginTop: '20px',
        marginRight: '150px',
    },
    videoEditable: {
        height: '70%',
        width: '100%',
        position: 'absolute',
        cursor: 'pointer',
    },
    galleryVideoSize: {
        height: 90,
        '& iframe': {
            width: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.794)',
            height: '100%',
        },
    },
    centerSec: {
        textAlign: 'center',
        position: 'relative',
    },
    activeComp: {
        backgroundColor: 'cornflowerblue !important',
    },
    iconSettingTemplet: {
        fontSize: '14px',
        verticalAlign: 'middle',
        marginRight: 4,
        marginBottom: 2,
    },
    accessControlViewText: {
        display: 'flex',
        backgroundColor: '#66787D',
        border: '1px solid #7070704E',
        padding: '4px 7px',
        marginLeft: '2px',
    },
    centerSecIn: {
        backgroundColor: '#fff',
        color: '#066785',
        marginTop: '2px',
        marginRight: '7px',
        marginLeft: '7px',
        borderRadius: '40px',
        padding: 7,
        verticalAlign: 'top',
        cursor: 'pointer',
        display: 'inline-block',
    },
    galleryTab: {
        width: '50%',
        borderRadius: 1,
        marginLeft: 0,
        marginRight: 0,
        color: 'rgba(255, 255, 255, .42)o',
        fontSize: '12px',
    },
    toggleActive: {
        backgroundColor: '#ddd !important',
        color: '#066785 ',
        margin: 2,
    },
    inCenterSec: {
        padding: '5px',
        backgroundColor: '#2E3538',
        minHeight: '49px',
        marginLeft: '8px',
        marginBottom: '2px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    toggleSettingsProtocol: {
        position: 'absolute',
        width: '99%',
        display: 'flex',
        border: '0.5px solid #707070A5',
        borderRadius: 2,
        background: '#FFFFFF',
        margin: '3px 0px 2px 2px',
    },
    contentDisplayCipCont: {
        marginTop: 2,
        color: '#306a9d',
        padding: 6,
        borderTop: '.25px solid #cbcbcb',
        borderBottom: '.25px solid #cbcbcb',
        fontSize: 16,
        '& svg': {
            fontSize: 18,
        },
    },
    toggleSettingsProgression: {
        position: 'absolute',
        width: '99%',
        display: 'flex',
        border: '0.5px solid #707070A5',
        borderRadius: 2,
        background: '#FFFFFF',
        margin: '3px 0px 2px 2px',
    },
    progressionColumn: {
        height: '70vh',
    },
    workshop: {
        width: '100%',
        position: 'relative',
        borderRadius: '0px',
    },
    settingsTabHeight: {
        height: '86vh',
        overflowY: 'auto',
        width: '100%',
        boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
    },
    settingsTabHeightRemov: {
        height: 'auto',
    },
    protocal: {
        position: 'absolute',
        width: '40%',
    },
    progressions: {
        position: 'absolute',
        width: '100%',
    },
    buttonGroup: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingRight: '1rem',
        '& :last-child': {
            marginRight: 0,
        },
        '& > *': {
            margin: theme.spacing(1),
        },
        '& button': {
            margin: '0px 12px',
        },
    },
    noContentDisplay: {
        textAlign: 'center',
        margin: '40px auto',
        border: '.25px solid #ececec',
        borderBottom: 'none',
        borderTop: 'none',
        maxWidth: 338.5,
    },
    buttonGroupProtocal: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& :last-child': {
            marginRight: 0,
        },
        '& > *': {
            margin: theme.spacing(1),
        },
        '& button': {
            margin: '0px 7px',
        },
    },
    buttonGroupProtocal1: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& > *': {
            margin: theme.spacing(1),
        },
        '& button': {
            margin: '0px 7px',
        },
    },
    cancelButton: {
        marginRight: '1.45rem !important',
    },
    workshopHeader: {
        ...standardHeaders.h1,
        background: '#2E3538',
        height: 49,
        overflow: 'hidden',
        overflowY: 'auto',
        position: 'fixed',
        width: '43.62%',
        zIndex: '999',
        marginLeft: 1,
    },
    disabledWorkshop: {
        backgroundColor: 'rgba(0,0,0,0.2)',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        height: '100%',
        left: 0,
        zIndex: 999,
    },
    workshopHeaderProgression: {
        ...standardHeaders.h1,
        background: '#2E3538',
        minHeight: 49,
        position: 'relative',
        width: 'auto',
        zIndex: '999',
        marginLeft: '0px',
    },
    workshopHeaderTemplate: {
        ...standardHeaders.h1,
        background: '#2E3538',
        minHeight: 49,
        position: 'fixed',
        width: '25%',
        top: '64px',
        zIndex: '999',
        marginLeft: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    workshopHeaderTemplateProgression: {
        ...standardHeaders.h1,
        background: '#2E3538',
        minHeight: 49,
        position: 'relative',
        width: '100%',
        zIndex: '999',
        marginLeft: '0px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    workshopHeaderColumn: {
        ...standardHeaders.h1,
        background: '#2E3538',
        minHeight: 49,
        position: 'fixed',
        width: '25%',
        zIndex: '999',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    workshopHeaderColumnX: {
        ...standardHeaders.h1,
        background: '#2E3538',
        minHeight: 49,
        position: 'fixed',
        width: 'inherit',
        zIndex: '999',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    settingsHeader: {
        ...standardHeaders.h1,
        background: '#2E3538',
        minHeight: '49px',
        display: 'flex',
        padding: 0,
        alignItems: 'center',
        position: 'fixed',
        width: '31.25%',
        zIndex: '999',
        top: 64,
    },
    accessHeader: {
        fontSize: '13px',
        color: '#fff',
        backgroundColor: '#66787D',
        border: '1px solid #7070704E',
        padding: '4px 7px',
        marginLeft: '2px',
        textAlign: 'left',
    },
    selectPOIBtn: {
        marginLeft: '20px',
        marginTop: '20px',
        textAlign: 'left',
        '& button': {
            color: '#2779EA',
            fontSize: '14px',
            border: '1px solid #2779EA',
            height: '36px',
            width: '120px',
            borderRadius: '2px',
            marginBottom: 10,
        },
    },
    settingsHeaderProgression: {
        ...standardHeaders.h1,
        background: '#2E3538',
        minHeight: '49px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        width: '100%',
        zIndex: '999',
        borderLeft: '.7px solid #fff',
    },
    columHeadType: {
        fontSize: 14,
        color: 'white',
    },
    settingsSubHeader: {
        color: '#bfbfbf',
        fontSize: 12,
        maxHeight: '20px',
        overflow: 'hidden',
        overflowY: 'auto',
        display: 'block',
    },
    panelSummary: {
        minHeight: 25,
        height: 30,
        flexDirection: 'row-reverse',
        background: '#a5b8e1',
        margin: 2,
        '& SVG': {
            background: '#f5f5f5',
            height: 19,
            width: 19,
        },
        marginLeft: 0,
        paddingLeft: 0,
    },
    panelActive: {
        minHeight: 40,
        height: 40,
        background: 'cornflowerblue',
        '& SVG': {
            background: '#BACAE893',
        },
        '&&': {
            minHeight: 40,
        },
    },
    horizontalRadioGroup: {
        flexDirection: 'row',
    },
    fullWidth: {
        width: '100%',
        margin: '0px !important',
    },
    accordianHeader: {
        width: '100%',
        paddingLeft: 5,
        textAlign: 'left',
    },
    accordionDetails: {
        padding: '2px 3px 3px',
    },
    accordionDetails1: {
        padding: '2px 3px 3px',
        borderRadius: 4,
    },
    lRCFHeaderSetting: {
        padding: '4px 2px 0px 2px',
    },
    lRCFColumnSetting: {
        padding: '0px 2px 4px 2px',
    },
    protocolView: {
        width: '100%',
        boxShadow: '5px -10px 12px #ccc',
    },
    protocolColumn: {
        height: '86vh',
        paddingBottom: 6,
        overflow: 'scroll',
        boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
    },
    workshopWrapper: {
        marginLeft: 1,
    },
    myNavbarCollapse: {
        transition: 'all .6s ease',
        height: 0,
    },
    show: {
        height: '35vh',
    },
    mainButtonGroup: {
        border: '1px solid #70707021',
        boxShadow: '0px -3px 6px #0000000A',
        padding: '4px 12px',
        position: 'fixed',
        backgroundColor: '#fff',
        bottom: '0px',
        left: 0,
        width: '100%',
        zIndex: '999',
    },
    mainButtonGroupX: {
        border: '1px solid #70707021',
        boxShadow: '0px -3px 6px #0000000A',
        padding: '4px 12px',
        position: 'fixed',
        backgroundColor: '#fff',
        bottom: '0px',
        left: 0,
        width: '100%',
        zIndex: '999',
    },
    mainButtonGroupY: {
        border: '1px solid #70707021',
        boxShadow: '0px -3px 6px #0000000A',
        padding: '4px 12px',
        position: 'fixed',
        backgroundColor: '#fff',
        bottom: '0px',
        left: 0,
        width: '100%',
        zIndex: '999',
    },
    mainButtonGroupProgression: {
        border: '1px solid #70707021',
        boxShadow: '0px -3px 6px #0000000A',
        padding: '4px 12px',
        position: 'fixed',
        backgroundColor: '#fff',
        bottom: '0px',
        left: '0%',
        width: '100%',
        zIndex: '999',
    },
    stepLabelCont: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
    },
    stepLabelProgressionText: {
        fontSize: '.875rem',
        color: '#fff',
        display: 'flex',
        flexDirection: 'row',
    },
    stepLabelProtocalText: {
        fontSize: '.625rem',
        color: '#fff',
        display: 'flex',
        flexDirection: 'row',

    },
    stepLabel: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row-reverse',
        gap: '.375rem',
        position: 'relative',
        height: '2.5rem',
        marginLeft: '.75rem',
    },
    stepper: {
        padding: 0,
        background: 'transparent',
        width: '100%',
        marginRight: '1.75rem',
    },
    step: {
        flexDirection: 'row-reverse !important',
        border: '1px solid #163139',
        background: '#163139',
        width: 'inherit',
    },
    stepLabelRoot: {
        '&::after': {
            content: '""',
            width: 0,
            height: 0,
            borderTop: '1.28rem solid transparent',
            borderLeft: '1.7rem solid #163139',
            borderBottom: '1.28rem solid transparent',
            position: 'absolute',
            right: '-2.15rem',
        },
        '&::before': {
            content: '""',
            width: 0,
            height: 0,
            top: 0,
            borderTop: '1.28rem solid transparent',
            borderLeft: '1.7rem solid black',
            borderBottom: '1.28rem solid transparent',
            position: 'absolute',
            right: '-2.275rem',
        },
    },
    completedStep: {
        background: '#549A44',
        '&&': {
            background: '#549A44',
        },
        '& .stepLabel::after': {
            content: '""',
            width: 0,
            height: 0,
            top: 0,
            borderTop: '1.28rem solid transparent',
            borderLeft: '1.7rem solid #549A44',
            borderBottom: '1.28rem solid transparent',
            position: 'absolute',
            right: '-2.15rem',
        },
        '& .stepLabel::before': {
            content: '""',
            width: 0,
            height: 0,
            top: 0,
            borderTop: '1.28rem solid transparent',
            borderLeft: '1.7rem solid black',
            borderBottom: '1.28rem solid transparent',
            position: 'absolute',
            right: '-2.275rem',
        },
    },
    activeStep: {
        // background: '#066785',
    },
    stepperIconCompleted: {
        color: '#FFFFFF',
        '&&': {
            color: '#FFFFFF',
        },
    },
    stepperIconActive: {
        background: 'transparent',
    },
    activeLabelStep: {
        background: '#066785',
        '& .stepLabel::after': {
            content: '""',
            width: 0,
            height: 0,
            top: 0,
            borderTop: '1.28rem solid transparent',
            borderLeft: '1.7rem solid #066785',
            borderBottom: '1.28rem solid transparent',
            position: 'absolute',
            right: '-2.15rem',
        },
        '& .stepLabel::before': {
            content: '""',
            width: 0,
            height: 0,
            top: 0,
            borderTop: '1.28rem solid transparent',
            borderLeft: '1.7rem solid black',
            borderBottom: '1.28rem solid transparent',
            position: 'absolute',
            right: '-2.275rem',
        },
    },
    progressionLabelColor: {
        '&&': {
            color: 'white',
            fontSize: '1.275rem',
        },
        '& text': {
            fill: '#011E27',
        },
    },
    protocalLabelColor: {
        '&&': {
            color: 'white',
            fontSize: '1rem',
        },
        '& text': {
            fill: '#011E27',
        },
    },
    iconContainer: {
        padding: 0,
    },
    stepLevelProgressionTextCont: {
        width: 'min-content',
        marginRight: '2.5%',
    },
    stepLevelTwoStepCont: {
        width: 'max-content',
        marginRight: '13%',
    },
    stepLevelProtocalTextCont: {
        width: 'min-content',
    },
    stepperIconDisabled: {
        display: 'none',
    },
    disNone: {
        display: 'none',
    },
    triangleRight: {
        width: 0,
        height: 0,
    },
    butonStyle: {
        color: '#066785',
        backgroundColor: '#06678515',
    },
    butonStyleProt: {
        color: '#066785',
        backgroundColor: '#FFF',
        border: '1px solid #066785',
    },
    saveUpButton: {
        width: '75px',
        height: '40px',
        backgroundColor: '#066785 !important',
        color: '#FFFFFF',
        fontSize: '14px',
        marginLeft: '20px !important',
    },
    poupConBoottom: {
        padding: '9px 22px',
    },
    widthSectPart: {
        maxWidth: '750px',
        minWidth: '600px',
    },
    timelineUserViewDialog: {
        maxWidth: '78vw',
        minWidth: '75vw',
        [breakpoints.between('1200', '1450')]: {
            maxWidth: '95vw',
            minWidth: '95vw',
        },
    },
    poupConHeading: {
        padding: '12px 12px 4px 12px',
        '& h6': {
            fontWeight: 700,
        },
    },
    poupConHeadingMeddle: {
        padding: '5px',
    },
    fonSizSec: {
        width: '15px',
        height: '15px',
        margin: 0,
    },
    paddTopZero: {
        paddingTop: '41px',
    },
    paddTopHund: {
        paddingTop: '100px',
    },
    paddTopProg: {
        paddingTop: 35,
    },
    paddTopProcPage: {
        paddingTop: 54,
    },
    sectionHeader: {
        margin: 0,
        minHeight: 'auto !important',
        height: 32,
        fontSize: 13,
        border: '1px solid #ececec',
        ...standardHeaders.sectionHeader,
        position: 'relative',
        flexDirection: 'row-reverse',
        background: 'rgba(6,103,133,0.09)',
        color: '#066785',
    },
    paddSpac: {
        transform: 'scale(.75)',
    },
    poiSelectAll: {
        transform: 'scale(.75)',
    },
    poiSelectAllLabel: {
        marginRight: 0,
    },
    iconButton: {
        margin: '0 8px 0 2px',
        padding: 0,
        background: '#bbb',
        color: '#FFFFFF',
        borderRadius: 2,
    },
    expandedAcc: {
        background: '#066785',
        color: '#FFFFFF',
        transition: 'none',
    },
    expandedAccIcon: {
        transform: 'rotateZ(-90deg) translateY(-10px)',
        color: '#066785',
        height: 36,
    },
    explicitHeaderStyle: {
        position: 'sticky',
        top: 0,
        zIndex: 5,
        height: 40,
        lineHeight: '42px',
        backgroundColor: 'aliceblue',
        borderBottom: '2px solid lightskyblue',
    },
    explicitColumnItem: {
        textAlign: 'center',
        fontWeight: 600,
        zIndex: 10,
        marginRight: 1,

    },
    FormControlCont: {
        margin: 2,
        padding: '16px 24px 4px 24px',
        background: '#E6E6E6',
        width: '99%',
    },
    leftButtonCont: {
        textAlign: 'left',
        marginTop: 10,
    },
    rightButtonCont: {
        textAlign: 'right',
        marginTop: 10,
        paddingRight: 15,
    },
    buttonStyle: {
        fontSize: 12,
        color: '#066785',
    },
    iconStyle: {
        fontSize: 15,
        color: '#066785',
    },
    selectBOx: {
        padding: '8px 14px',
        fontSize: 12,
    },
    researchTextCont: {
        paddingTop: 8,
        textAlign: 'center',
        fontSize: 14,
        borderTop: '1px solid #ececec',
        marginTop: 50,
    },
    protocolDisplayCont: {
        padding: 2,
        border: '1px solid #ececec',
    },
    protocolDisplaySubCont: {
        padding: 4,
        border: '1px solid #ececec',
    },
    gridCell1: {
        border: '1px solid #ececec',
        padding: '10px 4px',
        textAlign: 'left',
        wordWrap: 'break-word',
        fontSize: 12,
    },
    gridCell2: {
        border: '1px solid #ececec',
        padding: '10px 12px',
        textAlign: 'left',
        wordWrap: 'break-word',
        fontSize: 12,
    },
    gridCellCont1: {
        border: '1px solid #ececec',
        padding: 4,
        textAlign: 'center',
        fontWeight: 'bold',
    },
    gridCellCont2: {
        border: '1px solid #ececec',
        textAlign: 'left',
        padding: 8,
        wordWrap: 'break-word',
        fontSize: 12,
    },
    settingListCont: {
        border: '3px solid cornflowerblue',
        borderRadius: 4,
        width: '100%',
    },
    tooltipCont: {
        background: '#FFFFFF',
        color: '#000000de',
        margin: 6,
        padding: '8px 12px',
        fontSize: 14,
        fontWeight: 600,
        border: '1px solid #cbcbcb',
    },
    filterNonStyle: {
        marginLeft: 'auto',
        fontSize: 16,
        marginRight: 7,
        verticalAlign: 'middle',
    },
    timeLineSpace: {
        margin: '0px 0px 0px 8px',
        width: 'min-content',
    },
    timeLineIcon: {
        border: '0.5px solid #70707062',
        borderRadius: 4,
        height: 36,
        width: 36,
        marginBottom: 6,
    },
    timeLineIcon1: {
        border: '0.5px solid #70707062',
        borderRadius: 4,
        height: 36,
        width: 36,
        margin: 4,
    },
    mainButtonGroupProgressionX: {
        border: '1px solid #70707021',
        boxShadow: '0px -3px 6px #0000000A',
        padding: '4px 12px',
        position: 'fixed',
        backgroundColor: '#fff',
        bottom: '0px',

        width: '100%',
        zIndex: '999',
    },
    workshopHeaderX: {
        ...standardHeaders.h1,
        background: '#2E3538',
        minHeight: 49,
        position: 'fixed',
        width: '41.4%',
        zIndex: '999',
        marginLeft: '0px',
    },
    workshopHeaderY: {
        ...standardHeaders.h1,
        background: '#2E3538',
        minHeight: 49,
        position: 'relative',
        width: 'auto',
        zIndex: '999',
        marginLeft: '0px',
    },
    settingsHeaderX: {
        ...standardHeaders.h1,
        background: '#2E3538',
        minHeight: '49px',
        display: 'flex',
        padding: 0,
        alignItems: 'center',
        position: 'fixed',
        width: '50%',
        zIndex: '999',
        top: 64,
    },
    settingsHeaderY: {
        ...standardHeaders.h1,
        background: '#2E3538',
        minHeight: '49px',
        display: 'flex',
        padding: 0,
        alignItems: 'center',
        position: 'fixed',
        width: 'inherit',
        zIndex: '999',
        top: 64,
    },
    settingsHeaderZ: {
        ...standardHeaders.h1,
        background: '#2E3538',
        minHeight: '49px',
        display: 'flex',
        padding: 0,
        alignItems: 'center',
        position: 'fixed',
        width: 'inherit',
        zIndex: '999',
        top: 64,
    },
    centerAlign: {
        alignItems: 'center',
    },
    leftTextAlign: {
        textAlign: 'left',
    },
    rightTextAlign: {
        textAlign: 'right',
    },
    openIconStyle: {
        fontSize: '1.1rem',
        margin: '6px 4px 0px 0px',
    },
    accContMain: {
        width: '100%',
    },
    accContMainRemov: {
        width: '100%',
        height: '44vh',
        overflow: 'hidden',
        overflowY: 'auto',
        paddingBottom: '1.5rem',
        [breakpoints.between('1200', '1450')]: {
            height: '42vh',
        },
    },
    accContMainRemovHide: {
        width: '100%',
        height: '73vh',
        overflow: 'hidden',
        overflowY: 'auto',
        paddingBottom: '1.25rem',
        [breakpoints.between('1200', '1450')]: {
            height: '68.5vh',
            paddingBottom: '.5rem',
        },
    },
    selectTemplateMainCont: {
        width: '100%',
        maxHeight: '86vh',
        overflow: 'scroll',
        minHeight: '86vh',
        position: 'relative',
        paddingBottom: '.5rem',
    },
    selectTemplateMainContRemov: {
        width: '100%',
        position: 'relative',
        height: '48vh',
        overflow: 'hidden',
        overflowY: 'auto',
        paddingBottom: '1.5rem',
        [breakpoints.between('1200', '1450')]: {
            height: '46vh',
        },
    },
    selectTemplateMainContRemovHIde: {
        width: '100%',
        position: 'relative',
        height: '77vh',
        overflow: 'hidden',
        overflowY: 'auto',
        paddingBottom: '1.25rem',
        [breakpoints.between('1200', '1450')]: {
            height: '72.5vh',
            paddingBottom: '.5rem',
        },
    },
    visibilityIconCont: {
        textAlign: 'left',
        marginLeft: '-.75rem',
    },
    timelineViewTabCont: {
        marginTop: 55,
        textAlign: 'center',
    },
    timelineViewTabStyle: {

        background: '#efefef',
        fontSize: 13,
        margin: '4px 4px 4px 0px',
        cursor: 'pointer',
        padding: 6,
    },
    activeTimelineViewTabStyle: {

        background: '#b1b1b1',
        fontSize: 13,
        margin: '4px 4px 4px 0px',
        cursor: 'pointer',
        padding: 6,
    },
    closeIconStyle: {
        cursor: 'pointer',
        marginTop: 6,
    },
    researchTextCont1: {
        background: '#2E3538',
        textAlign: 'center',
        padding: '.5rem',
        color: '#FFFFFF',
    },
    textInputLinkCont: {
        padding: '0px 4px',
    },
    textInputLinkWidth: {
        marginTop: '4px',
        borderRadius: '20px',
        backgroundColor: '#909090',
        color: '#000000',
        '& input': {
            padding: '4px 20px 4px 0px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            '&::-webkit-scrollbar': {
                display:
                    'none',
            },
            '&:hover': {
                textOverflow: 'clip',
                '&::-webkit-scrollbar': {
                    height: '2px',
                    width: '2px',
                    display: 'block',
                },
                '&::-webkit-scrollbar-thumb': {
                    background: '#888',
                },
            },
        },
    },
    textInputWidth: {
        width: '95%',
        '& input': {
            paddingTop: 12,
            paddingBottom: 0,
            height: 15,
            fontSize: 12,
        },
        '& svg': {
            fontSize: 14,
            marginTop: 8,
        },
    },
    showError: {
        color: '#DC143C',
        display: 'block',
        fontSize: 12,
    },
    columnContMain: {
        border: '1px solid #ececec',
    },
    detailContLeft: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        borderRight: '1px solid #ececec',
        borderBottom: '1px solid #ececec',
        padding: '12px 4px',
        wordWrap: 'break-word',
        fontSize: '.75rem',
        height: '45px',
    },
    detailContRight: {
        textAlign: 'left',
        borderBottom: '1px solid #ececec',
        padding: '14px 8px',
        fontSize: '.75rem',
        height: '45px',
    },
    pagehyperlinkRadioCont: {
        borderBottom: '1px solid #ececec',
        padding: '8px',
        fontSize: '.75rem',
        height: '45px',
        '& > :first-child': {
            flexDirection: 'row',
            '& svg, span': {
                fontSize: 12,
            },
        },
    },
    smallSectionHeight: {
        height: 'auto',
    },
    filterIconStyle: {
        marginLeft: 'auto',
        fontSize: 16,
        marginTop: 2,
        marginRight: 6,
    },
    accStyle: {
        margin: '4px 0px 0px 0px !important',
        boxShadow: 'none',
        border: '1px solid #7070704E',
        borderRadius: '0px !important',
    },
    switchStyle: {
        right: 4,
        position: 'absolute',
    },
    thumbStyle: {
        color: '#066785',
    },
    trackStyle: {
        backgroundColor: '#f5f5f5',
        opacity: 0.75,
        '.Mui-checked.Mui-checked + &': {
            opacity: 1,
            backgroundColor: '#fff',
        },
    },
    contentDisplayImgCont: {
        textAlign: 'center',
        border: '.25px solid #ececec',
    },
    imgTitleInput: {
        textAlign: 'center',
        width: '100%',
        border: 'none',
        display: 'grid',
        borderBottom: '.5px solid #ececec',
        '& input': {
            textAlign: 'center',
            fontSize: 11,
            fontWeight: 400,
            padding: 2,
            color: '#000000de',
        },
        '& div:before': {
            borderBottom: 'none !important',
        },
        '& div:after': {
            borderBottom: 'none',
        },
        '& div:hover': {
            borderBottom: 'none',
        },
    },
    titleCaptionHolder: {
        padding: '0 !important',
    },
}));
const domainArray = ['Medguide', 'MedguideSSR', 'OCCS', 'LAGayne'];
/**
     * Recursive component calling.
     */
function componentLabel(component) {
    let label;
    if (component && component.type) {
        switch (component.type) {
        case 'Protocol':
        case 'Column':
            if (!component.props) { // new code
                component.props = component.template.props;
            }
            label = `${component.type}: ${component.props.title || component.props.index}`;
            break;
        case 'Text':
            label = `Header: ${component.props.text}`;
            break;
        case 'TextInput':
            label = `Form: ${component.props.label}`;
            break;
        case 'Checkbox':
            label = `Checkbox ${component.props.label}`;
            break;
        case 'HTML':
            label = 'Paragraph';
            break;
        case 'Gallery':
            label = 'Slide';
            break;
        case 'Image':
            label = `${component.type} ${component.props.index}`;
            break;
        default:
            label = component.type;
        }
    }

    return label;
}

const TemplateLibrary = ({
    library, setDirty, onCommitTemplate, onPreview, updateSelectedColumn, showLoader, setHoverFlagWorkshop,
}) => {
    const {
        userPoi,
        loggedUserType,
    } = useContext(OrgContext);
    const {
        filteredTemplates,
        setPreviewTemplate,
        addTemplateFromLibrary,
        setSelectTemplate,
        compareModal,
        setCompareModal,
    } = useContext(WorkshopContext);
    const {
        workshopUseLibrary,
        selectedTimeline,
        selectedColumn,
        addSelectedColumn,
        templateLibraryByType,
        selectedComponent,
        isTemplateSelected,
        setIsTemplateSelected,
        actionRelation,
        contextMenuTarget,
        clickedArea,
        workshopComponent,
        selectedColIndex,
        setActiveStep,
        setSelectedComponent,
        updateComponent,
        setComponentPOI,
        componentPOI,
        componentParentPOI,
        workshopColumn,
        timeLineVisible,
        setWorkshopColumnCopy,
        workshopLibraryFilter,
        setActionRelation,
        setWorkshopUseLibrary,
        domain,
        setDomain,
        setPageComponent,
        pageComponent,
        setDirtyDomain,
        cloneWorkshopComp,
        previousTemplate,
        copyLRColumnState,
        handleWarningDialogLRClose,
        setClearCurrentAssociation,
        setHrzViewTLFlag,
        hrzViewTLFlag,
        setWorkshopAccessView,
        workshopActiveComponent,
        intitalTempSelected,
        setIntitalTempSelected,
        setComplexFormType,
        editTimeline,
        contentDisplayCip,
        setAccessControl,
        isAccessControl,
    } = useContext(TimelineContext);
    let settingsContent = null;
    const classes = useStyles();
    const [beforeActionRelation] = useState(actionRelation);
    useRecoilState(componentWithId(selectedComponent.id, selectedComponent.props));
    useRecoilCallback(updateComponentCallback);
    const isProgression = !!((clickedArea === 'Progression' || workshopComponent.isProgression));
    const [activeAccordion, setActiveAccordion] = useState('template');
    const [expandAccordion, setExpandAccordion] = useState(true);
    const [error, setError] = useState(false);
    const [warningDialogOpen, setWarningDialogOpen] = useState(false);
    const [clickedTemplate, setClickedTemplate] = useState(null);
    const [selected, setSelected] = useState(componentPOI);
    const [selectedParentPOI, setSelectedParentPOI] = useState(componentParentPOI);
    const [poiModalOpen, setPOIModalOpen] = useState(false);
    const [accessControlModalOpen, setAccessControlModalOpen] = useState(false);
    const [isToggleOn, setToggleStatus] = useState(true);
    const [selectAll, setSelectAll] = useState(false);
    const [components, setComponents] = useState([]);
    const [open, setOpen] = useState(false);
    const [overrideDomainDialogVisible, showOverrideDomainDialog] = useState(false);
    const [compareData, setCompareData] = useState();
    const [state, setState] = useState({ tlModalFlag: false });

    const flatChildren = ProcedureLibrary.children.flatMap((item) => item.children);
    const flatList = [...flatChildren, ...ProcedureLibrary.children];

    const notSelectedList = flatList.filter((item) => !selected[item.id]);
    const handleAccordionChange = (accordion) => {
        setActiveAccordion(accordion);
    };

    const libraryWithColumn = templateLibraryByType && templateLibraryByType.Protocol
        && templateLibraryByType.Protocol.concat(templateLibraryByType.Text,
            templateLibraryByType.Row);
    let templateLibraryData = [];

    if (hrzViewTLFlag) {
        templateLibraryData = [...libraryWithColumn, ...templateLibraryByType.Column];
    } else {
        templateLibraryData = libraryWithColumn?.filter((fl) => fl?.supergroup !== 'Column');
    }

    const handleAccordionOpen = (accordion) => {
        if (activeAccordion !== accordion) {
            setExpandAccordion(true);
        } else {
            setExpandAccordion(!expandAccordion);
        }
        if (accordion !== 'settings') {
            setClearCurrentAssociation(true);
        } else if (accordion === 'settings') {
            setClearCurrentAssociation(false);
        }
    };

    const componentList = [];
    buildSettingsTree(componentList, workshopComponent);
    const pageDomain = componentList.find((item) => item?.type === 'Page');

    const handleWarningDialogClose = (ok) => {
        setWarningDialogOpen(false);
        if (ok) {
            selectTemplate(clickedTemplate, true);
        } else {
            setPreviewTemplate(null);
        }
        handleCloseTL();
    };

    useEffect(() => {
        if (pageDomain) {
            domainArray.forEach((domain) => {
                if (!pageDomain[domain]) {
                    pageDomain[domain] = domain === 'Medguide' ? JSON.parse(JSON.stringify(pageDomain.props)) : {};
                }
            });
            if (!pageComponent || (pageComponent && (pageComponent.id !== pageDomain.id))) {
                setPageComponent(pageDomain);
            }
        }
    }, [pageDomain, domain]);

    useEffect(() => {
        const comp = componentList.find((item) => item?.type === 'Page');
        if (comp && pageComponent) {
            domainArray.forEach((domainVal) => {
                comp[domainVal] = JSON.parse(JSON.stringify(pageComponent[domainVal]));
            });
            comp.props = comp.Medguide;
        }
    }, [pageComponent]);

    useEffect(() => {
        setDomain('Medguide');
    }, []);

    useEffect(() => {
        setSelectAll(notSelectedList.length === 0);
    }, [notSelectedList]);

    useEffect(() => {
        if (workshopColumn) {
            setComponents(isProgression ? workshopColumn?.props?.progressions : workshopColumn?.props?.components);
        }
    }, [workshopColumn]);

    useEffect(() => {
        setWorkshopColumnCopy(JSON.parse(JSON.stringify(workshopColumn)));
    }, [setWorkshopColumnCopy, workshopColumn, selectedComponent?.props?.text, selectedComponent?.props?.title]);

    useEffect(() => {
        if ((isTemplateSelected && actionRelation.relation === 'append')
            || (actionRelation.component && actionRelation.component.type === 'Protocol-Workshop')) {
            const byType = [...templateLibraryByType[workshopComponent.type], ...templateLibraryByType.Section
                || [], ...templateLibraryByType.Row || []];
            const filtered = byType && byType.filter((item) => (item?.supergroup !== 'Page' && item?.supergroup !== 'Complex Forms'));
            addTemplateFromLibrary(filtered);
        } else {
            addTemplateFromLibrary(library);
        }
    }, [library, isToggleOn]);

    useEffect(() => {
        if (actionRelation?.component?.name === 'Protocol Only' || actionRelation?.type === 'edit'
            || actionRelation?.component?.name === 'Progression Only' || actionRelation?.component?.type === 'Text') {
            setActiveAccordion('settings');
        }
    }, [actionRelation]);

    useEffect(() => {
        const activeStep = Object.keys(STEPS).find((stepKey) => STEPS[stepKey].id === activeAccordion);
        setActiveStep(STEPS[activeStep].index);
    }, [activeAccordion, setActiveStep]);

    useEffect(() => {
        setSelected(componentPOI);
        setSelectedParentPOI(componentParentPOI);
    }, [componentPOI, componentParentPOI, selectedColIndex]);

    const onHover = (template, isEnter = true) => {
        if (isToggleOn && !hrzViewTLFlag) {
            if (template) {
                let temp = template;
                if (!isEnter) {
                    temp = { template: workshopComponent };
                    if (warningDialogOpen) {
                        temp = clickedTemplate;
                    }
                    setHoverFlagWorkshop(false);
                } else {
                    temp.template.props.group = temp.group;
                    setHoverFlagWorkshop(true);
                }
                setPreviewTemplate(temp);
                setWorkshopUseLibrary(false);
                onPreview(temp);
            } else {
                let temp = { template: isTemplateSelected ? workshopComponent : cloneWorkshopComp };
                if (clickedTemplate) {
                    temp = { template: previousTemplate };
                }
                setPreviewTemplate(temp);
                if (temp && !temp.template) {
                    return null;
                }
                setHoverFlagWorkshop(false);
                onPreview(temp);
            }
        }
        return null;
    };

    const onTemplateClick = () => {
        if (!intitalTempSelected) {
            openAcc('template');
        }
        setToggleStatus(true);
        setActionRelation(beforeActionRelation);
    };

    const selectTemplate = (template, overRide) => {
        if (isTemplateSelected && !overRide && isToggleOn) {
            setClickedTemplate(template);
            setWarningDialogOpen(true);
            return;
        }
        if (template.type !== 'Column') {
            template.template.props.group = template.group;
            setSelectTemplate(template);

            onCommitTemplate(template);
            if (!isTemplateSelected) {
                setIsTemplateSelected(true);
            }
        } else if (template.type === 'Column') {
            const colIndex = actionRelation.type === 'leftColumn' ? contextMenuTarget.index : contextMenuTarget.index + 1;
            let templateToCopy = JSON.parse(JSON.stringify(template.template));
            const { templateData } = template.template;
            if (templateData) {
                templateToCopy = { ...templateToCopy, props: { ...templateData.props } };
            }
            const clone = copyTemplate(templateToCopy);
            selectedTimeline.columns[colIndex] = clone;
            addSelectedColumn(clone);
            updateSelectedColumn();
        }
        setIntitalTempSelected(true);
        setActiveAccordion('settings');
        setComplexFormType(template?.template?.props?.cfType);
    };

    const handleCloseToast = () => {
        setError(false);
    };

    const selectMultiple = (list, checked) => {
        const items = list.reduce((acc, item) => ({ ...acc, [item.id]: checked }), {});
        // setSelected({ ...selected, ...items });
        setComponentPOI({ ...selected, ...items });
    };

    const onSelectionChange = (level, checked, firstLevel) => {
        let newSelected;
        if (firstLevel) {
            const levelChildren = (!checkEmptyObject(selectedParentPOI)
                && level.children.filter((l) => Object.prototype.hasOwnProperty.call(selectedParentPOI, [l.id]))) || level.children;
            const childrenArr = levelChildren.reduce((acc, item) => ({ ...acc, [item.id]: checked }), {});
            newSelected = { ...selected, [level.id]: checked, ...childrenArr };
            setSelected(newSelected);
        } else {
            newSelected = { ...selected, [level.id]: checked };
            setSelected(newSelected);
        }
        setComponentPOI(newSelected);
    };

    const onHandlePOIChange = (poi, isCancel) => {
        setPOIModalOpen(false);
        if (isCancel) {
            return;
        }
        setComponentPOI(poi);
        setDirty(true);
    };
    const openProcedureDialog = (e) => {
        if (!isTemplateSelected && actionRelation?.component?.name !== 'Protocol Only'
            && actionRelation?.component?.name !== 'Progression Only' && actionRelation?.component?.type !== 'Text') {
            setError(true);
            return;
        }
        setPOIModalOpen(true);
        e.stopPropagation();
    };
    const openAcDialog = (e) => {
        if (!isTemplateSelected && actionRelation?.component?.name !== 'Protocol Only'
            && actionRelation?.component?.name !== 'Progression Only' && actionRelation?.component?.type !== 'Text') {
            setError(true);
            return;
        }
        if (loggedUserType === 'Super Admin') {
            setAccessControlModalOpen(true);
        }
        e.stopPropagation();
    };

    const handleSelectAll = (e) => {
        setSelectAll(!selectAll);
        const items = flatList.reduce((acc, item) => ({ ...acc, [item.id]: !selectAll }), {});
        setComponentPOI(items);
        e.stopPropagation();
    };

    const handleChange = (event) => {
        setDomain(event.target.value);
    };
    const changeDomain = () => {
        if (pageComponent) {
            domainArray.forEach((domainVal) => {
                pageComponent[domainVal] = JSON.parse(JSON.stringify(pageComponent.Medguide));
                /* For html children */
                pageComponent[domainVal].components = pageComponent[domainVal].components.map((componentVal) => ({
                    ...componentVal,
                    [domainVal]: JSON.parse(JSON.stringify(componentVal.Medguide || {})),
                }));
                const compList = [];
                buildSettingsTreePage(compList, pageComponent[domainVal]);
                compList.forEach((componentVal) => {
                    componentVal[domainVal] = JSON.parse(JSON.stringify(componentVal.Medguide || {}));
                });
            });
            setDirtyDomain(true);
            setPageComponent({ ...pageComponent });
        }
    };
    const handleDomainDialogClose = (ok) => {
        if (ok) {
            changeDomain();
        }
        showOverrideDomainDialog(false);
    };
    const handleDomain = () => {
        const existingDomains = domainArray.filter((domainName) => domainName
            !== 'Medguide').some((domainName) => Object.keys(pageDomain[domainName]).length > 0);
        if (existingDomains) {
            showOverrideDomainDialog(true);
        } else {
            changeDomain();
        }
    };

    const compareModalClose = () => {
        setCompareModal(false);
    };

    const findPageId = (children) => {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < children.length; i++) {
            if (children[i].type === 'Page') {
                return children[i].id;
            }
            if (children[i].props.components) {
                const result = findPageId(children[i].props.components);
                if (result) {
                    return result;
                }
            }
        }
        return null;
    };

    const compareAll = () => {
        const pageId = findPageId(workshopComponent.props.components);
        const targetPage = findPage(selectedTimeline.columns, pageId);
        setCompareData(targetPage);
        setCompareModal(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const openAcc = (acc) => {
        if (activeAccordion === acc) {
            return;
        }
        if (!isTemplateSelected && actionRelation?.component?.name !== 'Protocol Only'
            && actionRelation?.component?.name !== 'Progression Only' && actionRelation?.component?.type !== 'Text'
            && actionRelation?.component?.type === 'Progression') {
            setError(true);
            return;
        }
        handleAccordionChange(acc);
        handleAccordionOpen(acc);
    };

    const onAccessControlDialog = (newAccessView) => {
        setAccessControlModalOpen(false);
        if (newAccessView.cancel) return;
        setWorkshopAccessView(newAccessView);
    };

    /**
    * Handle Access Control popup isAccessControlOpen
    * @param {boolean} status [if modal  open status is `true` otherwise `false`]
    */
    const handleModalStatus = () => {
        setAccessControl(!isAccessControl);
    };

    const renderUAGrid = () => (
        accessControlModalOpen ? null
            : <AccessControl handleClose={handleModalStatus} column={selectedTimeline?.columns?.[selectedColumn]} isSettings />
    );

    /**
     * Get the Setting Component with Toggle switch
     * @param {string} label
     */
    const getSettingContent = (label, IconComp, showSettingsLabel) => (
        <div className={classes.centerSec}>
            <div className={isProgression ? classes.toggleSettingsProgression : classes.toggleSettingsProtocol}>

                <span className={`${classes.centerSecIn} ${classes.activeComp} ${isToggleOn ? classes.toggleActive : ''} ${classes.galleryTab}`}>
                    <TuneIcon className={classes.iconSettingTemplet} />
                    {' '}
                    Settings
                </span>
                <span
                    className={`${classes.centerSecIn} ${!isToggleOn ? classes.toggleActive : ''} ${classes.galleryTab}`}
                    onClick={toggleChange}
                    aria-hidden="true"
                >
                    {IconComp}
                    {' '}
                    {' '}
                    {label}
                </span>
            </div>

            <div className={isProgression ? classes.paddTopZero : classes.paddTopProg}>
                <SettingsContainer
                    workshopComponent={workshopComponent}
                    updateComponent={updateComponent}
                    selectedComponent={selectedComponent}
                    setSelectedComponent={setSelectedComponent}
                    showSettingsLabel={showSettingsLabel}
                    renderProcedureSelectorGrid={renderProcedureSelectorGrid}
                    selectAllPOIHandler={selectAllPOIHandler}
                    renderUAGrid={renderUAGrid}
                    selectAllUAHandler={selectAllUAHandler}
                    renderSelectTemplate={renderSelectTemplate}
                    renderTemplateBrowser={renderTemplateBrowser}
                    renderHrzViewTL={renderHrzViewTL}
                />

            </div>

        </div>
    );

    const toggleChange = () => {
        if (workshopLibraryFilter === 'Page' && actionRelation.relation !== 'append') {
            setActionRelation({ ...actionRelation, relation: 'append' });
        }
        setToggleStatus(!isToggleOn);
    };
    const marginTo = isProgression ? '5px' : '48px';

    const openTLModalHandler = (e) => {
        e.stopPropagation();
        setHrzViewTLFlag(true);
        setState({ ...state, tlModalFlag: true });
    };

    const handleCloseTL = () => {
        setHrzViewTLFlag(false);
        setState({ ...state, tlModalFlag: false });
    };

    const renderProcedureSelectorGrid = () => {
        let procedureLibraryChildren = [];
        let selectedParentPOIClone = null;
        if (loggedUserType !== 'Super Admin' && userPoi) {
            procedureLibraryChildren = JSON.parse(JSON.stringify(ProcedureLibrary.children)).filter((poi) => Object.prototype.hasOwnProperty.call(
                userPoi, poi?.id
            ) && userPoi[poi.id]);
            procedureLibraryChildren.forEach((poi) => {
                poi.children = poi.children.filter((subPoi) => Object.prototype.hasOwnProperty.call(
                    userPoi, subPoi?.id
                ) && userPoi[subPoi.id]);
            });
        }

        if (!checkEmptyObject(selectedParentPOI)) {
            selectedParentPOIClone = selectedParentPOI;
        }

        return (
            <ProcedureSelectorGrid
                inline
                selected={selected}
                selectedParent={selectedParentPOIClone}
                library={loggedUserType !== 'Super Admin' && userPoi ? { ...ProcedureLibrary, children: procedureLibraryChildren } : ProcedureLibrary}
                onSelectionChange={onSelectionChange}
                selectMultiple={selectMultiple}
                isPopup={false}
            />
        );
    };

    const selectAllUAHandler = () => (
        <FilterNoneIcon
            className={classes.filterIconStyle}
            onClick={(e) => openAcDialog(e)}
        />
    );

    const selectAllPOIHandler = () => {
        const emptyParentPOIFlag = checkEmptyObject(selectedParentPOI);

        return (
            <>
                {emptyParentPOIFlag
                    && (
                        <FormControlLabel
                            value="Select all"
                            control={(
                                <Checkbox
                                    checked={selectAll}
                                    onClick={(e) => handleSelectAll(e)}
                                    style={{ color: activeAccordion === 'POI' && expandAccordion ? '#000000' : '#000000' }}
                                    classes={{ root: classes.poiSelectAll }}
                                />
                            )}
                            label="Select all"
                            labelPlacement="start"
                            classes={{ root: classes.poiSelectAllLabel }}
                        />
                    )}
                <FilterNoneIcon
                    className={classes.filterNonStyle}
                    onClick={(e) => openProcedureDialog(e)}
                />
            </>
        );
    };

    const renderHrzViewTL = () => (
        <OpenInNewIcon onClick={openTLModalHandler} className={classes.openIconStyle} />
    );

    const renderTemplateBrowser = (isTempLibrarySetting = false) => (
        <TemplateBrowser
            templates={filteredTemplates}
            hoverItem={onHover}
            selectItem={selectTemplate}
            openAccordion
            showLoader={showLoader}
            isTempLibrarySetting={isTempLibrarySetting}
        />
    );

    const renderSelectTemplate = () => (
        <Accordion
            expanded={activeAccordion === 'template' && expandAccordion}
            onChange={onTemplateClick}
            className={classes.accStyle}
        >
            <AccordionSummary
                expandIcon={
                    activeAccordion === 'template' && expandAccordion ? (
                        <ArrowDropUpIcon />
                    ) : (
                        <ArrowDropDownIcon />
                    )
                }
                aria-controls="panel1a-content"
                className={classes.sectionHeader}
                classes={{
                    expandIcon: activeAccordion === 'template' && expandAccordion ? classes.iconButton : classes.expandedAccIcon,
                    expanded: classes.expandedAcc,
                }}
                style={{
                    pointerEvents: (activeAccordion === 'template' && expandAccordion
                        || actionRelation?.component?.name === 'Protocol Only'
                        || actionRelation?.component?.name === 'Progression Only') ? 'none' : 'all',
                }}
            >
                <Grid container className={classes.centerAlign}>
                    <Grid item xs={10} className={classes.leftTextAlign}>Select Template</Grid>
                    <Grid
                        item
                        xs={2}
                        className={classes.rightTextAlign}
                    >
                        {renderHrzViewTL()}
                    </Grid>
                </Grid>
            </AccordionSummary>
            <div onClick={(e) => e.stopPropagation()} aria-hidden="true">
                <AccordionDetails style={{ display: 'block', padding: '0px' }}>
                    {renderTemplateBrowser()}
                </AccordionDetails>
            </div>
        </Accordion>
    );

    if (!workshopUseLibrary) {
        if ((workshopLibraryFilter === 'Images/Videos' || selectedComponent.props.isMediaComp
            || selectedComponent.props.group === 'Image/Video' || workshopActiveComponent?.type === 'Image') && !contentDisplayCip) {
            if (isToggleOn) {
                settingsContent = getSettingContent('Gallery', <PhotoIcon className={classes.iconSettingTemplet} />, false);
            } else {
                settingsContent = (
                    <div className={classes.centerSec}>
                        <div className={isProgression ? classes.toggleSettingsProgression : classes.toggleSettingsProtocol}>

                            <span
                                className={`${classes.centerSecIn} ${isToggleOn ? classes.toggleActive : ''} ${classes.galleryTab}`}
                                onClick={toggleChange}
                                aria-hidden="true"
                            >
                                {' '}
                                <TuneIcon className={classes.iconSettingTemplet} />
                                {' '}
                                Settings
                            </span>
                            <span
                                className={`${classes.centerSecIn} ${classes.activeComp} ${!isToggleOn
                                    ? classes.toggleActive : ''} ${classes.galleryTab}`}
                            >
                                {' '}
                                <PhotoIcon className={classes.iconSettingTemplet} />
                                Gallery
                            </span>
                        </div>
                        <div className={classes.paddTopProg}>
                            <ImageGallery classes={classes} isProgression={isProgression} />
                        </div>
                    </div>
                );
            }
        } else if ((workshopLibraryFilter === 'Page' || selectedComponent.props.group === 'Page Groups') && !contentDisplayCip) {
            if (isToggleOn) {
                settingsContent = getSettingContent('Template Library', <LibraryBooksIcon className={classes.iconSettingTemplet} />, false);
            } else {
                settingsContent = (
                    <div className={classes.centerSec}>
                        <div className={isProgression ? classes.toggleSettingsProgression : classes.toggleSettingsProtocol}>

                            <span
                                className={`${classes.centerSecIn} ${isToggleOn ? classes.toggleActive : ''} ${classes.galleryTab}`}
                                onClick={toggleChange}
                                aria-hidden="true"
                            >
                                {' '}
                                <TuneIcon className={classes.iconSettingTemplet} />
                                {' '}
                                Settings
                            </span>
                            <span
                                className={`${classes.centerSecIn} ${classes.activeComp} ${!isToggleOn
                                    ? classes.toggleActive : ''} ${classes.galleryTab}`}
                            >
                                <LibraryBooksIcon className={classes.iconSettingTemplet} />
                                {' '}
                                Template Library
                            </span>
                        </div>
                        <div className={isProgression ? classes.paddTopProg : classes.paddTopProcPage}>
                            <TemplateBrowser
                                templates={filteredTemplates}
                                hoverItem={onHover}
                                selectItem={selectTemplate}
                                openAccordion
                                showLoader={showLoader}
                            />
                        </div>
                    </div>
                );
            }
        } else if (contentDisplayCip) {
            settingsContent = (
                <div className={classes.centerSec}>
                    <div className={classes.contentDisplayCipCont}>
                        <span>
                            <PhotoIcon className={classes.iconSettingTemplet} />
                            Gallery
                        </span>
                    </div>
                    <ImageGallery classes={classes} isProgression={isProgression} />
                </div>
            );
        } else {
            settingsContent = (
                <div className={classes.centerSec}>
                    <div>
                        <SettingsContainer
                            workshopComponent={workshopComponent}
                            updateComponent={updateComponent}
                            selectedComponent={selectedComponent}
                            setSelectedComponent={setSelectedComponent}
                            showSettingsLabel
                            renderProcedureSelectorGrid={renderProcedureSelectorGrid}
                            selectAllPOIHandler={selectAllPOIHandler}
                            renderSelectTemplate={renderSelectTemplate}
                            renderTemplateBrowser={renderTemplateBrowser}
                            renderHrzViewTL={renderHrzViewTL}
                            renderUAGrid={renderUAGrid}
                            selectAllUAHandler={selectAllUAHandler}
                        />
                    </div>

                </div>

            );
        }
    } else {
        settingsContent = (
            <TemplateBrowser
                templates={filteredTemplates}
                hoverItem={onHover}
                selectItem={selectTemplate}
                openAccordion
                showLoader={showLoader}
            />
        );
    }
    const componentsDataInitial = useMemo(() => {
        const [allParents, parentComponent] = (components && findParents(selectedComponent, components)) || [];
        const siblings = parentComponent?.props?.components.filter((item) => item.id !== selectedComponent.id).map((item) => ({
            ...item, props: { ...item.props, childOf: parentComponent.id },
        })) || [];
        const allComponents = [...allParents.reverse(), selectedComponent, ...siblings];
        return allComponents;
    }, [selectedComponent, components]);

    const [componentsData, setComponentsData] = useState(componentsDataInitial);
    useEffect(() => {
        setComponentsData(componentsDataInitial);
    }, [componentsDataInitial]);

    return (
        <>
            <Paper square className={isProgression ? classes.settingsTabHeightRemov : classes.settingsTabHeight} style={{ marginTop: marginTo }}>
                <div className={isProgression ? (timeLineVisible ? classes.accContMainRemov : classes.accContMainRemovHide) : classes.accContMain}>
                    <div className={intitalTempSelected && classes.hideitem}>
                        {!editTimeline && renderSelectTemplate()}
                    </div>

                    {(intitalTempSelected || editTimeline) ? (
                        <div onClick={() => handleAccordionOpen('settings')} aria-hidden="true">
                            <Accordion
                                expanded={activeAccordion === 'settings' && expandAccordion}
                                onChange={() => openAcc('settings')}
                                className={classes.accStyle}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        activeAccordion === 'settings' && expandAccordion ? (
                                            <ArrowDropUpIcon />
                                        ) : (
                                            <ArrowDropDownIcon />
                                        )
                                    }
                                    aria-controls="panel1a-content"
                                    className={classes.sectionHeader}
                                    classes={{
                                        expandIcon: activeAccordion === 'settings' && expandAccordion ? classes.iconButton : classes.expandedAccIcon,
                                        expanded: classes.expandedAcc,
                                    }}
                                >
                                    {contentDisplayCip ? 'Content Display' : 'Settings'}
                                </AccordionSummary>
                                <div onClick={(e) => e.stopPropagation()} aria-hidden="true">
                                    <AccordionDetails style={{ display: 'block', padding: '0px' }}>
                                        {((workshopComponent?.name === 'Page'))
                                        && (
                                            <Grid container className={classes.FormControlCont}>
                                                <Grid item xs={12} style={{ marginRight: '1rem' }}>
                                                    <FormControl fullWidth variant="outlined">
                                                        <InputLabel id="select-label">Selected Domain</InputLabel>
                                                        <Select
                                                            labelId="select-label"
                                                            id="demo-controlled-open-select"
                                                            label="Selected Domain"
                                                            name="Selecteddomain"
                                                            open={open}
                                                            onClose={handleClose}
                                                            onOpen={handleOpen}
                                                            value={domain}
                                                            onChange={handleChange}
                                                            classes={{ select: classes.selectBOx }}
                                                        >
                                                            {domainArray.map((item, index) => (
                                                                <MenuItem key={`domain${index}`} value={item}>{item}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={6} className={classes.leftButtonCont}>
                                                    <Button
                                                        disabled={domain !== 'Medguide'}
                                                        onClick={handleDomain}
                                                        className={classes.buttonStyle}
                                                    >
                                                        Make copies to other sites
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={6} className={classes.rightButtonCont}>
                                                    <Button
                                                        className={classes.buttonStyle}
                                                        onClick={compareAll}
                                                        startIcon={<img src={CompareIcon} className={classes.iconStyle} alt="start" />}
                                                    >
                                                        Compare all
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        )}

                                        {settingsContent}
                                    </AccordionDetails>
                                </div>
                            </Accordion>
                        </div>
                    ) : null}
                    <WarningDialog
                        open={copyLRColumnState && copyLRColumnState.open}
                        onClose={handleWarningDialogLRClose}
                        title="Are you sure?"
                        desc="This will override your exisitng template."
                    />
                    <WarningDialog
                        open={warningDialogOpen}
                        onClose={handleWarningDialogClose}
                        title="Are you sure?"
                        desc="This will override your exisitng template."
                    />
                    <WarningDialog
                        open={overrideDomainDialogVisible}
                        onClose={handleDomainDialogClose}
                        title="Are you sure?"
                        desc="This will override existing domains."
                    />

                    <Snackbar
                        open={error}
                        autoHideDuration={5000}
                        onClose={handleCloseToast}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <Alert severity="error">
                            Please Select Template first.
                        </Alert>
                    </Snackbar>
                    {poiModalOpen && (
                        <ProcedureDialog
                            isWorkshop
                            showSelectBtn={false}
                            explicitOpen={poiModalOpen}
                            onSelectionMade={onHandlePOIChange}
                            selectedPOI={selected}
                            componentParentPOI={selectedParentPOI}
                        />
                    )}
                    {accessControlModalOpen && (
                        // <AccessControlDialog
                        //     components={componentsData}
                        //     open={accessControlModalOpen}
                        //     onClose={onAccessControlDialog}
                        // />
                        <TimelineUserViewDialog
                            components={componentsData}
                            open={accessControlModalOpen}
                            onClose={onAccessControlDialog}
                        />
                    )}
                    {compareModal && compareData
                        && (
                            <CompareAll
                                classes={{ paper: classes.paper }}
                                id="page-preview"
                                open={compareModal}
                                onClose={compareModalClose}
                                title="Test"
                                data={compareData}
                            />
                        )}
                </div>
                {state?.tlModalFlag && hrzViewTLFlag && (
                    <TemplateLibraryDialog
                        open={state.tlModalFlag}
                        handleClose={handleCloseTL}
                        templates={templateLibraryData}
                        hoverItem={onHover}
                        selectItem={selectTemplate}
                        showTimeLine
                        showLoader={showLoader}
                    />
                )}

            </Paper>
        </>
    );
};

const buildSettingsTree = (list, component, parent) => {
    if (component) {
        list.push(component);
        component.parent = parent;
        if (component.props && component.props.components) {
            component.props.components.forEach((element) => {
                buildSettingsTree(list, element, component.id);
            });
        }
    }
};

function addDepth(component, depthMap, depth = 1, setDepthMap) {
    const componentsChildren = component?.props?.components || [];
    depthMap[component.id] = depth;
    componentsChildren.map((item) => addDepth(item, depthMap, depth + 1, setDepthMap));
    setDepthMap(depthMap);
}

export const SettingsList = ({
    componentList, updateComponent, openComponent, heading,
}) => {
    const {
        expandedAccordian, handleChangeSettingAccordian,
        settingMenuType, selectedParent,
    } = useContext(TimelineContext);
    const classes = useStyles();

    const handleChange = (panel, component) => (event, isExpanded) => {
        handleChangeSettingAccordian(panel, component, isExpanded);
    };

    const getSettingsWidget = (component) => {
        let widget = '';
        if (openComponent && openComponent.id === component.id) {
            widget = (
                <SettingsWidget
                    selectedComponent={component}
                    selectedParent={selectedParent}
                    updateComponent={updateComponent}
                    heading={heading}
                />
            );
        } else if (component.group === 'Column') {
            widget = (
                <SettingsWidget
                    selectedComponent={component}
                    selectedParent={selectedParent}
                    updateComponent={updateComponent}
                    heading={heading}
                />
            );
        } else if (component.type === 'Column') {
            widget = (
                <SettingsWidget
                    selectedComponent={component}
                    selectedParent={selectedParent}
                    updateComponent={updateComponent}
                    heading={heading}
                />
            );
        }

        return widget;
    };

    const depthMapRef = useRef({});
    const [, setDepthMap] = useState({});
    useEffect(() => {
        const rootComponent = componentList[0];
        addDepth(rootComponent, depthMapRef.current, 0, setDepthMap);
    }, [depthMapRef.current, componentList, settingMenuType]);

    return (
        componentList.map((component) => (
            <Accordion
                expanded={expandedAccordian === component.id}
                onChange={handleChange(component.id, component)}
                className={classes.fullWidth}
                key={component.id}
            >
                <AccordionDetails classes={{ root: settingMenuType === 'Admin' ? classes.accordionDetails : classes.accordionDetails1 }}>
                    <Grid container>
                        <Grid item xs>
                            {getSettingsWidget(component)}
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        ))
    );
};

const SettingsContainer = ({
    workshopComponent,
    updateComponent,
    selectedComponent,
    renderProcedureSelectorGrid,
    selectAllPOIHandler,
    renderUAGrid,
    selectAllUAHandler,
    renderSelectTemplate = null,
    renderTemplateBrowser = null,
    renderHrzViewTL,
}) => {
    const componentList = [];
    buildSettingsTree(componentList, workshopComponent);
    const {
        settingMenuType, handleSettingMenuType, workshopActiveComponent, timeLineVisible, setTimeLineVisible,
    } = useContext(TimelineContext);

    /**
     * Set the menu type which are active
     * @param {string} type menu
     */
    const handleMenutType = (type) => {
        if ((type === 'Basic' || type === 'Advanced') && !timeLineVisible) {
            setTimeLineVisible(true);
        }
        handleSettingMenuType(type);
    };

    const renderSettingTypeButtons = () => (
        <>
            <Grid item xs={4}>
                <StandardHeader
                    isButton
                    type={settingMenuType === 'Basic' ? 'h2' : 'h3'}
                    onClick={() => { handleMenutType('Basic'); }}
                >
                    Basic
                </StandardHeader>
            </Grid>
            <Grid item xs={4}>
                <StandardHeader
                    isButton
                    type={settingMenuType === 'Advanced' ? 'h2' : 'h3'}
                    onClick={() => { handleMenutType('Advanced'); }}
                >
                    Advanced
                </StandardHeader>
            </Grid>
            <Grid item xs={4}>
                <StandardHeader
                    isButton
                    type={settingMenuType === 'Admin' ? 'h2' : 'h3'}
                    onClick={() => { handleMenutType('Admin'); }}
                >
                    Comprehensive
                </StandardHeader>
            </Grid>
        </>
    );

    let columnComponentFunction = null;
    let superHeaderComponentFunction = null;
    if (workshopActiveComponent?.type !== 'Protocol'
        && workshopActiveComponent?.type !== 'Column'
            && (workshopComponent?.component === 'One Sided CF' || workshopComponent?.component === '1 Column CF'
                || workshopComponent?.component === '2 Column CF' || workshopComponent?.component === '3 Column CF')) {
        if (workshopActiveComponent?.colRef) {
            columnComponentFunction = findComponent(workshopComponent?.props.components, workshopActiveComponent?.colRef);
        }
    } else if (workshopActiveComponent?.type !== 'Protocol' && workshopComponent?.component === 'LR Complex Form') {
        if (workshopActiveComponent?.type !== 'Column' && workshopActiveComponent?.colRef) {
            columnComponentFunction = findComponent(workshopComponent?.props.components, workshopActiveComponent?.colRef);
            superHeaderComponentFunction = findComponent(workshopComponent?.props.components, columnComponentFunction?.props?.LRProps?.LRparent);
        } else if (workshopActiveComponent?.type === 'Column' && !workshopActiveComponent?.props?.columnType) {
            superHeaderComponentFunction = findComponent(workshopComponent?.props.components, workshopActiveComponent?.props?.LRProps?.LRparent);
        }
    } else if (workshopActiveComponent?.type !== 'Protocol' && workshopComponent?.component === 'LR Mixed Form') {
        if (workshopActiveComponent?.type !== 'Column' && workshopActiveComponent?.parent) {
            columnComponentFunction = findComponent(workshopComponent?.props.components, workshopActiveComponent?.parent);
            superHeaderComponentFunction = findComponent(workshopComponent?.props.components, columnComponentFunction?.props?.LRProps?.LRparent);
        } else if (workshopActiveComponent?.type === 'Column' && !workshopActiveComponent?.props?.columnType) {
            superHeaderComponentFunction = findComponent(workshopComponent?.props.components, workshopActiveComponent?.props?.LRProps?.LRparent);
        }
    }

    return (
        <div>
            <div style={{
                width: '100%',
            }}
            >
                <Grid container>
                    <Grid item xs={12}>
                        {settingMenuType === 'Basic' && (
                            <AdminSettings
                                poiProps={{ renderProcedureSelectorGrid, selectAllPOIHandler }}
                                uaProps={{ renderUAGrid, selectAllUAHandler }}
                                tlProps={{
                                    renderSelectTemplate, renderTemplateBrowser, renderHrzViewTL, renderSettingTypeButtons,
                                }}
                            >
                                {superHeaderComponentFunction && (
                                    <SettingsList
                                        componentList={[superHeaderComponentFunction]}
                                        updateComponent={updateComponent}
                                        openComponent={superHeaderComponentFunction}
                                        heading="Super Header Component Function"
                                    />
                                )}
                                {columnComponentFunction && (
                                    <SettingsList
                                        componentList={[columnComponentFunction]}
                                        updateComponent={updateComponent}
                                        openComponent={columnComponentFunction}
                                        heading="Column Component Function"
                                    />
                                )}
                                <SettingsList
                                    componentList={[workshopActiveComponent || selectedComponent]}
                                    updateComponent={updateComponent}
                                    openComponent={workshopActiveComponent}
                                    heading={superHeaderComponentFunction || columnComponentFunction
                                        ? 'Selected Component\'s Component Function' : ''}
                                />
                            </AdminSettings>
                        )}
                        {settingMenuType === 'Advanced' && (
                            <AdminSettings
                                poiProps={{ renderProcedureSelectorGrid, selectAllPOIHandler }}
                                uaProps={{ renderUAGrid, selectAllUAHandler }}
                                tlProps={{
                                    renderSelectTemplate, renderTemplateBrowser, renderHrzViewTL, renderSettingTypeButtons,
                                }}
                            >
                                {superHeaderComponentFunction && (
                                    <SettingsList
                                        componentList={[superHeaderComponentFunction]}
                                        updateComponent={updateComponent}
                                        openComponent={superHeaderComponentFunction}
                                        heading="Super Header Component Function"
                                    />
                                )}
                                {columnComponentFunction && (
                                    <SettingsList
                                        componentList={[columnComponentFunction]}
                                        updateComponent={updateComponent}
                                        openComponent={columnComponentFunction}
                                        heading="Column Component Function"
                                    />
                                )}
                                <SettingsList
                                    componentList={[workshopActiveComponent || selectedComponent]}
                                    updateComponent={updateComponent}
                                    openComponent={workshopActiveComponent}
                                    heading={superHeaderComponentFunction || columnComponentFunction
                                        ? 'Selected Component\'s Component Function' : ''}
                                />
                                {' '}
                            </AdminSettings>
                        )}
                        {settingMenuType === 'Admin' && (
                            <AdminSettings
                                poiProps={{ renderProcedureSelectorGrid, selectAllPOIHandler }}
                                uaProps={{ renderUAGrid, selectAllUAHandler }}
                                tlProps={{
                                    renderSelectTemplate, renderTemplateBrowser, renderHrzViewTL, renderSettingTypeButtons,
                                }}
                            >
                                {superHeaderComponentFunction && (
                                    <SettingsList
                                        componentList={[superHeaderComponentFunction]}
                                        updateComponent={updateComponent}
                                        openComponent={superHeaderComponentFunction}
                                        heading="Super Header Component Function"
                                    />
                                )}
                                {columnComponentFunction && (
                                    <SettingsList
                                        componentList={[columnComponentFunction]}
                                        updateComponent={updateComponent}
                                        openComponent={columnComponentFunction}
                                        heading="Column Component Function"
                                    />
                                )}
                                <SettingsList
                                    componentList={[workshopActiveComponent || selectedComponent]}
                                    updateComponent={updateComponent}
                                    openComponent={workshopActiveComponent}
                                    heading={superHeaderComponentFunction || columnComponentFunction
                                        ? 'Selected Component\'s Component Function' : ''}
                                />
                            </AdminSettings>
                        )}
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

const Workshop = ({ hasHeader = true, showLoader, setTimelineContainerLoader }) => {
    const classes = useStyles();
    const imgClasses = useImgStyles();
    const {
        workshopComponent, selectedComponent, templateLibraryByType, cloneWorkshopComp,
        workshopUseLibrary,
        workshopLibraryFilter,
        workshopLoading,
        setWorkshopLoading,
        updateComponent,
        setWorkshopUseLibrary,
        setSelectedComponent,
        clearWorkshopComponent,
        selectedTimeline,
        clickedArea,
        setWorkshopComponent,
        selectedColumn,
        setSelectedColumn,
        contextMenuTarget,
        actionRelation,
        selectedPOIParentProc,
        handleSelectedPOI,
        selectedParent,
        removeComponent,
        progressionComponentRoute,
        getLocationOfTargetComponent,
        selectedPOI,
        updatePOI,
        setInitialPOI,
        initialPOI,
        isTemplateSelected,
        setIsTemplateSelected,
        createTemplate,
        deleteTemplate,
        clearSettingAccordian,
        timeLineVisible,
        setTimeLineVisible,
        activeStep,
        componentPOI,
        setComponentPOI,
        setComponentParentPOI,
        workshopColumn,
        selectedColIndex,
        setSelectedColIndex,
        rootComponent,
        setWorkshopColumnCopy,
        pageComponent,
        setPageComponent,
        setDirtyDomain,
        domain,
        setProgressionFilter,
        setClearCurrentAssociation,
        setSpecificSelect,
        settingMenuType,
        setSettingMenuType,
        setCurrentSelectedValue,
        setAssociationValue,
        editTimelineColumn,
        setEditTimelineColumn,
        editTimeline,
        setEditTimeline,
        setAddColumnLeftRight,
        setPageHyperlinkCip,
        hyperlinkCip,
        setHyperlinkCip,
        hyperlinkCipText,
        setHyperlinkCipText,
        hyperlinkComponent,
        setHyperlinkComponent,
        hyperlinkImageVideoComponent,
        setHyperlinkImageVideoComponent,
        scrollState,
        setWorkshopAccessView,
        workshopAccessView,
        workshopAccessData,
        setHierarchyComponent,
        poiData,
        setProgressionAccCollapse,
        bulkInsertPoi,
        bulkInsertAC,
        getComponentPOIUA,
        setWorkshopLibraryFilter,
        setIsEditMode,
        setInWorkshop,
        showTitleView,
        setShowTitleView,
        setComplexFormType,
        activeSelectSuggestParent,
        setActiveSelectSuggestParent,
        editProgressionComp,
        selectedChildren,
        videoHyperlinkCip,
        contentDisplayCip,
        setContentDisplayCip,
        contentDisplayComponent,
        setContentDisplayComponent,
        contentDisplayImageList,
        setContentDisplayImageList,
        refetchContentDisplay,
        setUniversalCompState,
        setUniversalScndState,
        promiseAccessControlData,
        setSettingComponentHandle,
        allContentDisplayData,
        setUpdateFlag,
        updateFlag,
    } = useContext(TimelineContext);

    const {
        idToken,
    } = useContext(OrgContext);
    const cdImageList = allContentDisplayData?.['content_display']?.find((i) => i.protocol_id === workshopComponent?.id);
    const compInfo = useMemo(() => ({
        column_id: workshopColumn?.id,
        poi_data: _.omitBy(componentPOI, (poi) => !poi),
        protocol_id: workshopComponent?.id,
        timeline_id: selectedTimeline?.id,
    }), [selectedTimeline, workshopComponent, componentPOI, workshopColumn]);
    const queryVariables = _.omit(compInfo, 'poi_data');

    const [insertComponentPOI] = useMutation(
        ADD_COMPONENT_POI,
        {
            refetchQueries: [{
                query: GET_COMPONENT_POI,
                variables: {
                    timeline_id: selectedTimeline?.id,
                },
            }],
        }
    );
    const [insertComponentAC] = useMutation(
        ADD_COMPONENT_AC,
        {
            refetchQueries: [{
                query: GET_COMPONENT_AC,
                variables: {
                    timeline_id: selectedTimeline?.id,
                },
            }],
        }
    );

    const [updateComponentAC] = useMutation(
        UPDATE_COMPONENT_AC,
    );

    const [updateComponentPOI] = useMutation(
        UPDATE_COMPONENT_POI,
        {
            refetchQueries: [{
                query: GET_COMPONENT_POI,
                variables: {
                    timeline_id: selectedTimeline?.id,
                },
            }],
        }
    );

    const [updateSelectdChildren] = useMutation(
        UPDATE_SELECTED_CHILDREN,
        {
            refetchQueries: [{
                query: GET_SELECTED_CHILDREN,
                variables: {
                    timeline_id: selectedTimeline?.id,
                },
            }],
        }
    );

    const [getDeletedOthersMedia, {
        data: deletedOthersMedia,
    }] = useLazyQuery(GET_DELETED_OTHERS_MEDIA, {
        fetchPolicy: 'no-cache',
    });

    const [insertContentDisplay] = useMutation(INSERT_CONTENT_DISPLAY);

    const [getContentDisplay, {
        loading: contentDisplayLoading,
        error: contentDisplayError,
        data: contentDisplayData,
    }] = useLazyQuery(GET_CONTENT_DISPLAY, {
        fetchPolicy: 'cache-and-network',
    });

    const [updateContentDisplay] = useMutation(UPDATE_CONTENT_DISPLAY);

    const [deleteContentDisplay] = useMutation(DELETE_CONTENT_DISPLAY);

    const [deleteOthersFolder] = useMutation(DELETE_OTHERS_FOLDER);
    const acRequests = useRef([]);
    const initialWorkshopAC = useRef();
    const poiRequests = useRef([]);
    const poiResponse = (poiData?.['components_poi'] || [])?.find((item) => item.protocol_id === workshopComponent?.id);
    const parentpoiValue = (poiData?.['components_poi'] || [])?.find((item) => item.protocol_id === workshopComponent?.props?.childOf);
    const poiValue = poiResponse?.['poi_data'] || parentpoiValue?.['poi_data'];
    const [selectedTimelineClone] = useState(JSON.parse(JSON.stringify(selectedTimeline)));

    const [filteredLibrary, setFilteredLibrary] = useState();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [templatePayLoad, setTemplatePayLoad] = useState(null);
    const [overRideDialogOpen, setOverRideDialogOpen] = useState(false);
    const [existingTemplate, setExistingTemplate] = useState(null);
    const [, setSettingsSelectedComponent] = useState(JSON.parse(JSON.stringify(selectedComponent)));

    const [component, setComponentState] = useRecoilState(componentWithId(selectedComponent.id, selectedComponent.props));
    const [, setComponentStatePreview] = useRecoilState(componentWithId(`preview${selectedComponent.id}`, selectedComponent.props));
    const updateRecoilComponent = useRecoilCallback(updateComponentCallback);
    const isProgression = !!((clickedArea === 'Progression' || workshopComponent.isProgression));
    const mediaWidth = 5;
    const [selectedRowIndex, setSelectedRowIndex] = useState(null);
    const [initialSelectedParent, setInitialSelectedParent] = useState(null);
    const [initialSelectedComponent, setInitialSelectedComponent] = useState(null);
    const [initialActionRelation, setInitialActionRelation] = useState(null);
    const [initialCloneWorkshopComponent, setInitialCloneWorkshopComponent] = useState(null);
    const rowIndex = contextMenuTarget ? (actionRelation.type === 'insert'
        ? ((actionRelation.relation === 'child') || (actionRelation?.relation === 'insert-content-display')) ? 0 : contextMenuTarget.index + 1 : contextMenuTarget.index) : 0;
    const colIndex = contextMenuTarget ? (actionRelation.type === 'leftColumn' ? contextMenuTarget.index : contextMenuTarget.index + 1) : 0;
    let targetElement = null;
    const bodyRef = React.createRef();
    let initialACValue = accessViewInitialValue;
    if (selectedComponent && selectedComponent.id && workshopAccessView[selectedComponent.id]) {
        initialACValue = workshopAccessView[selectedComponent.id];
    }
    const componentList = [];
    buildSettingsTree(componentList, workshopComponent);
    const [, setAccessView] = useState(initialACValue);
    const [dirty, setDirty] = useState(false);
    const [disableSaveBtn, setDisableSaveBtn] = useState(false);
    const [hoverFlagWorkshop, setHoverFlagWorkshop] = useState(false);
    const [cFWithoutComp, setCFWithoutComp] = useState(false);
    const [, setShowNextBtn] = useState(true);
    const [timelineViewTab] = useState('Hierarchy');
    const setSelectedCompHeight = () => {
        [targetElement] = document.querySelectorAll(
            '[style="padding: 1px; border: 0.25px solid cornflowerblue; background: cornflowerblue;"]'
        );

        if (targetElement && targetElement.children && targetElement.children.length > 0) {
            targetElement.children[0].style.height = '100%';
        }

        return targetElement;
    };
    let nothingSelectedAccessControl = false;
    // eslint-disable-next-line camelcase
    if (promiseAccessControlData.some((item) => item?.access_data?.isUserView === false)) {
        nothingSelectedAccessControl = true;
    }

    useEffect(() => {
        if (poiValue) {
            setComponentPOI(poiValue);
        }
        if (parentpoiValue?.['poi_data']) {
            setComponentParentPOI(parentpoiValue.poi_data);
        } else {
            setComponentParentPOI({});
        }
    }, [poiValue, parentpoiValue, selectedColumn]);

    useEffect(() => {
        setWorkshopAccessView(workshopAccessData);
    }, [workshopAccessData]);

    useEffect(() => {
        setDisableSaveBtn(nothingSelectedAccessControl);
    }, [nothingSelectedAccessControl]);

    useEffect(() => {
        if (!initialWorkshopAC.current && Object.keys(workshopAccessView).length > 0) {
            initialWorkshopAC.current = workshopAccessView;
        }
    }, [workshopAccessView]);

    useEffect(() => {
        if (bodyRef?.current) {
            bodyRef.current.scrollLeft = scrollState?.hrz;
            bodyRef.current.scrollTop = scrollState?.vertical;
        }
        if (!isProgression) {
            window.scrollTo(0, 0);
        }
    }, []);

    useEffect(() => {
        setAccessView(selectedComponent?.props?.accessView || initialACValue);
    }, [selectedComponent]);

    useEffect(() => {
        setSettingComponentHandle(workshopComponent);
    }, []);

    useEffect(() => {
        const byType = templateLibraryByType[workshopComponent.type];
        const filtered = byType && byType.filter((item) => item.supergroup === workshopLibraryFilter);
        setSelectedCompHeight();
        setFilteredLibrary(filtered);
    }, [workshopComponent.type, workshopLibraryFilter, templateLibraryByType]);

    useEffect(() => {
        const rowIndex = contextMenuTarget ? (actionRelation.type === 'insert'
            ? actionRelation.relation === 'child' ? 0 : contextMenuTarget.index + 1 : contextMenuTarget.index) : 0;
        const colIndex = contextMenuTarget ? (actionRelation.type === 'leftColumn'
            ? contextMenuTarget.index : actionRelation.type === 'rightColumn' ? contextMenuTarget.index + 1 : null) : null;
        setInitialSelectedParent(selectedParent);
        setInitialActionRelation(actionRelation);
        setInitialCloneWorkshopComponent(JSON.parse(JSON.stringify(workshopComponent)));
        setSelectedRowIndex(rowIndex);
        setSelectedColIndex(colIndex);
    }, []);

    useEffect(() => {
        if (!workshopComponent) {
            setInitialCloneWorkshopComponent(JSON.parse(JSON.stringify(workshopComponent)));
        }
        setInitialSelectedComponent(workshopComponent);
    }, [workshopComponent]);

    useEffect(() => {
        setInitialCloneWorkshopComponent(JSON.parse(JSON.stringify(workshopComponent)));
    }, [workshopComponent.id]);

    useEffect(() => {
        if (contentDisplayCip) {
            getContentDisplay({
                variables: {
                    protocol_id: contentDisplayComponent?.id,
                },
            });
        }
    }, [contentDisplayCip]);

    useEffect(() => {
        if (contentDisplayCip === 'edit' && contentDisplayData?.['content_display']?.[0]?.['image_list']) {
            setContentDisplayImageList([...contentDisplayData?.['content_display']?.[0]?.['image_list']] || []);
        }
    }, [contentDisplayCip, contentDisplayData]);

    const initiateCancelFunctionality = () => {
        setWorkshopLoading(true);
        setSpecificSelect({});
        setCurrentSelectedValue({});
        setAssociationValue({});
        setEditTimelineColumn(false);
        setEditTimeline(false);
        setAddColumnLeftRight(false);
        setHyperlinkCip(false);
        setPageHyperlinkCip(false);
        setHyperlinkCipText('');
        setContentDisplayCip(false);
        setContentDisplayComponent(false);
        setContentDisplayImageList([]);
        setHyperlinkComponent(false);
        setHyperlinkImageVideoComponent(false);
        setProgressionAccCollapse(false);
        setWorkshopLibraryFilter(false);
        setIsEditMode(false);
        setInWorkshop(false);
        if (settingMenuType !== 'Admin') {
            setSettingMenuType('Admin');
        }
        if (!timeLineVisible) {
            setTimeLineVisible(true);
        }
        if (initialActionRelation.type === 'insert') {
            let componentIndex = selectedRowIndex;
            if (initialActionRelation.relation === 'append') {
                componentIndex = 0;
            }
            if (rootComponent.type === 'Text') {
                removeComponent(rootComponent, componentIndex, initialSelectedComponent);
            } else {
                removeComponent(initialSelectedParent, componentIndex, initialSelectedComponent);
            }
        } else if (initialActionRelation.type === 'edit') {
            if (workshopComponent.isProgression) {
                selectedTimelineClone.columns.forEach((col, index) => {
                    if (!col?.props?.isProfile) {
                        const locationMap = getLocationOfTargetComponent(workshopComponent, selectedColumn);
                        if (locationMap && locationMap.length > 0) {
                            locationMap.pop();
                            let targetProgressionElementClone = locationMap?.length === 0 ? col.props.progressions[0]
                                : col.props.progressions[locationMap[0]];
                            let targetProgressionElement = locationMap?.length === 0 ? selectedTimeline.columns[index]?.props?.progressions[0]
                                : selectedTimeline.columns[index]?.props?.progressions[locationMap[0]];
                            // eslint-disable-next-line no-unused-vars
                            let targetClone = targetProgressionElement;
                            // eslint-disable-next-line no-unused-vars
                            let targetClone1 = targetProgressionElementClone;
                            if (locationMap && locationMap.length > 1) {
                                for (let iterator = 1; iterator < locationMap.length; iterator += 1) {
                                    targetClone1 = targetProgressionElementClone;
                                    targetProgressionElementClone = targetProgressionElementClone.props.components[locationMap[iterator]];
                                }

                                for (let iterator = 1; iterator < locationMap.length; iterator += 1) {
                                    targetClone = targetProgressionElement;
                                    targetProgressionElement = targetProgressionElement.props.components[locationMap[iterator]];
                                }
                            }
                            if (targetProgressionElementClone?.props) {
                                targetProgressionElement.props.components = [...targetProgressionElementClone?.props?.components];
                            }
                            const compList = [];
                            buildSettingsTreePage(compList, targetProgressionElement);
                            compList.forEach((comp) => {
                                updateRecoilComponent(comp.id, comp.props);
                            });
                        }
                    }
                });
            } else {
                workshopComponent.props = JSON.parse(JSON.stringify(initialCloneWorkshopComponent.props));
                setWorkshopComponent(workshopComponent);
                setSelectedComponent(workshopComponent);
                const compList = [];
                buildSettingsTreePage(compList, workshopComponent);
                compList.forEach((comp) => {
                    updateRecoilComponent(comp.id, comp.props);
                });
            }
        }
        setDirtyDomain(false);
        setPageComponent(null);
        clearWorkshopComponent();
        updatePOI(initialPOI);
        setInitialPOI({});
        setComponentPOI({});
        setIsTemplateSelected(false);
        setTemplatePayLoad(null);
        setProgressionFilter(null);
        setClearCurrentAssociation(true);
        clearSettingAccordian(!!isProgression);
        if (selectedColIndex !== null) {
            selectedTimeline.props.width = parseInt(selectedTimeline.props.width, 10) - findTimelineColumnWidth(selectedTimeline);
            selectedTimeline.columns = selectedTimeline.columns.filter((_item, index) => index !== selectedColIndex);
            setSelectedColIndex(null);
        }
        setWorkshopAccessView({});
        setWorkshopLoading(false);
    };

    useEffect(() => {
        if (deletedOthersMedia?.['media_folder_new']?.length > 0) {
            const requiredMedia = deletedOthersMedia?.['media_folder_new']?.[0]?.media;
            const deletedOthersMediaKeys = [];
            requiredMedia.forEach((ele) => {
                deletedOthersMediaKeys.push({
                    Key: ele.url.split('/').pop(),
                });
            });
            const payload = {
                Objects: [...deletedOthersMediaKeys],
            };
            const deleteSuccess = () => {
                let deletedImagesVideosComponentId = [];
                deletedOthersMedia.media_folder_new.forEach((ele) => {
                    deletedImagesVideosComponentId = [...deletedImagesVideosComponentId, ele?.['component_id']];
                });
                deleteOthersFolder({
                    variables: {
                        component_id: deletedImagesVideosComponentId,
                    },
                });
                initiateCancelFunctionality();
            };
            const requestFailure = () => {
            };
            const httpObj = {
                url: 'storage/upload/',
                method: 'DELETE',
                data: JSON.stringify(payload),
                headers: { Authorization: `Bearer ${idToken}` },
            };
            httpHelper(
                httpObj,
                deleteSuccess,
                requestFailure,
                false
            );
        } else if (deletedOthersMedia?.['media_folder_new']?.length === 0) {
            initiateCancelFunctionality();
        }
    }, [deleteOthersFolder, deletedOthersMedia, idToken]);

    const onTemplatePreview = (template) => {
        if (!template) return;

        if (isProgression) {
            if (actionRelation.relation !== 'append') {
                const childList = [];
                buildSettingsTreePage(childList, template.template);
                childList.forEach((ele) => {
                    ele.isProgression = true;
                });
                if (workshopLibraryFilter === 'Images/Videos') {
                    template.template.props.layout = 'default';
                    template.template.props.isProgression = true;
                }
                setComponentStatePreview({ ...component, props: template.template.props });
            }
        } else {
            const tempChildList = [];
            buildSettingsTreePage(tempChildList, template.template);
            tempChildList.forEach((ele) => {
                delete ele.isProgression;
            });

            if (template.template && template.template.type === 'Column') {
                let clone = copyTemplate(template.template.props);

                if (workshopLibraryFilter === 'Column') {
                    clone = copyTemplate(template.template);
                }
                selectedTimeline.columns[colIndex] = clone;
                selectedComponent.props = { ...selectedComponent.props, ...clone };
                setComponentStatePreview({ ...selectedComponent, props: selectedComponent.props });
                return;
            }
            if (actionRelation.relation !== 'append') {
                const componentListPage = [];
                buildSettingsTreePage(componentListPage, pageComponent);
                const pageComp = componentListPage.find((c) => c.id === selectedComponent.id);

                if (pageComp) {
                    pageComp.props = JSON.parse(JSON.stringify(template.template.props));
                    const componentChildren = [];
                    const pageCompChildren = [];
                    buildSettingsTreePage(pageCompChildren, pageComp);

                    buildSettingsTreePage(componentChildren, template.template);
                    pageCompChildren.forEach((item) => {
                        item.props.pageChild = true;
                        item.props.parentID = pageComp.id;
                        item[domain] = item.props;
                    });
                    componentChildren.forEach((item) => {
                        item.props.pageChild = true;
                        item.props.parentID = pageComp.id;
                        item[domain] = item.props;
                    });

                    setPageComponent({ ...pageComponent });
                }

                if (workshopLibraryFilter === 'Images/Videos' && !isProgression) {
                    template.template.props.layout = 'default';
                }
                setComponentState({ ...component, props: template.template.props });
            }
        }
    };

    const updateSelectedColumn = () => {
        setWorkshopUseLibrary(false);
    };

    /**
     * Calls after the selection of Template.
     * @param {Objec} template: Selected Template
     */
    const onCommitTemplate = async (template) => {
        const rootAC = rootComponent && workshopAccessView[rootComponent.id];
        let parentAccessView = rootAC || {
            isBasic: false,
            isAdvanced: false,
            isUserView: true,
        };
        if (isProgression) {
            if (actionRelation.relation === 'append') {
                let totalChild = workshopComponent?.props?.components[0]?.props?.components.length;
                if (workshopComponent.name === 'Page') {
                    totalChild = pageComponent[domain].components.length;
                    pageComponent[domain].components[totalChild] = copyTemplateWithImg(template.template);
                    const componentList = [];
                    buildSettingsTreePage(componentList, pageComponent);
                    componentList.forEach((item) => {
                        if (item.props) {
                            item.props.pageChild = true;
                            item.props.parentID = pageComponent.id;
                            item[domain] = item.props;
                        }
                    });
                    setPageComponent({ ...pageComponent });
                    const workshopComponentList = [];
                    buildSettingsTreePage(workshopComponentList, workshopComponent);
                    const workshopRef = workshopComponentList.find((item) => item.id === pageComponent.id);
                    if (workshopRef) {
                        workshopRef[domain] = pageComponent[domain];
                        workshopRef.props = workshopRef[domain];
                        workshopComponent[domain] = workshopComponent.props;
                    }
                    setWorkshopComponent({ ...workshopComponent });
                } else {
                    workshopComponent.props.components[0].props.components[totalChild] = copyTemplateWithImg(template.template);
                }
            } else {
                selectedTimeline.columns.forEach((col) => {
                    if (!col?.props?.isProfile) {
                        const locationMap = getLocationOfTargetComponent(workshopComponent, selectedColumn);
                        if (locationMap && locationMap.length > 1
                            && (actionRelation.relation === 'child' || actionRelation.relation === 'insert-content-display' || actionRelation.relation === 'sibling' || actionRelation.type === 'edit')) {
                            if ((actionRelation.relation === 'child') || actionRelation.relation === 'insert-content-display') {
                                locationMap.pop();
                            }

                            let targetProgressionElement = col.props.progressions[locationMap[0]];
                            parentAccessView = workshopAccessView[targetProgressionElement.id] || parentAccessView;
                            let targetComp = null;
                            let targetClone = targetProgressionElement;
                            for (let iterator = 1; iterator < locationMap.length; iterator += 1) {
                                targetClone = targetProgressionElement;
                                targetProgressionElement = targetProgressionElement.props.components[locationMap[iterator]];
                                parentAccessView = (targetProgressionElement && workshopAccessView[targetProgressionElement.id]) || parentAccessView;
                            }
                            if (actionRelation.relation === 'sibling' || actionRelation.type === 'edit') {
                                targetComp = targetClone?.props?.components[rowIndex];
                            } else {
                                targetComp = targetProgressionElement?.props?.components?.[0];
                            }

                            targetComp.component = template.name;
                            targetComp.supergroup = template.supergroup;
                            targetComp.category = template.category;
                            targetComp.templateId = template.id;
                            const cloneTargetComp = JSON.parse(JSON.stringify(targetComp));
                            targetComp.props = copyTemplateWithImg(template.template.props);

                            if (cloneTargetComp.type === 'Protocol' && cloneTargetComp.props.childOf && !template.template.props.childOf) {
                                targetComp.props.childOf = cloneTargetComp.props.childOf;
                            }
                            updateRecoilComponent(targetComp.id, targetComp.props);
                        } else {
                            selectedTimeline.columns.forEach((column) => {
                                if (!column?.props?.isProfile) {
                                    if (column && column.props && column.props.progressions) {
                                        column.props.progressions[rowIndex].component = template?.name;
                                        column.props.progressions[rowIndex].supergroup = template?.supergroup;
                                        column.props.progressions[rowIndex].category = template?.category;
                                        column.props.progressions[rowIndex].templateId = template?.id;
                                        column.props.progressions[rowIndex].props = copyTemplateWithImg(template?.template?.props);
                                        updateRecoilComponent(column.props.progressions[rowIndex]?.id, column.props.progressions[rowIndex]?.props);
                                    }
                                }
                            });
                        }
                    }
                });
            }
        } else {
            /** This code of if (isTemplateSelected) is not working as expected, It is adding another nested protocol. */
            if (actionRelation.relation === 'append') {
                let totalChild = workshopComponent.props.components[0].props.components.length;
                if (workshopComponent.name === 'Page') {
                    totalChild = pageComponent[domain].components.length;
                    pageComponent[domain].components[totalChild] = copyTemplateWithImg(template.template);
                    const componentList = [];
                    buildSettingsTreePage(componentList, pageComponent);
                    componentList.forEach((item) => {
                        if (item.props) {
                            item.props.pageChild = true;
                            item.props.parentID = pageComponent.id;
                            item[domain] = item.props;
                        }
                    });
                    const workshopComponentList = [];
                    buildSettingsTreePage(workshopComponentList, workshopComponent);
                    const workshopRef = workshopComponentList.find((item) => item.id === pageComponent.id);
                    if (workshopRef) {
                        workshopRef[domain] = pageComponent[domain];
                        workshopRef.props = workshopRef[domain];
                        workshopComponent[domain] = workshopComponent.props;
                    }
                    setWorkshopComponent({ ...workshopComponent });
                    setPageComponent({ ...pageComponent });
                } else {
                    workshopComponent.props.components[0].props.components[totalChild] = copyTemplateWithImg(template.template);
                }
            } else {
                const copyProps = copyTemplateWithAssociatedCell(template.template.props);

                if (workshopComponent.name === 'Page') {
                    const componentList = [];
                    buildSettingsTreePage(componentList, copyProps);
                    const copyPropsPage = componentList.find((item) => item.type === 'Page');
                    const copyPropsPageChildren = [];
                    buildSettingsTreePage(copyPropsPageChildren, copyPropsPage);
                    copyPropsPageChildren.forEach((item) => {
                        if (item.props) {
                            item.props.pageChild = true;
                            item.props.parentID = copyPropsPage.id;
                            item[domain] = item.props;
                        }
                    });
                }
                workshopComponent.component = template.name;
                workshopComponent.supergroup = template.supergroup;
                workshopComponent.category = template.category;
                workshopComponent.templateId = template.id;
                workshopComponent.props = { ...workshopComponent.props, ...copyProps };
                if (selectedTimeline?.columns[selectedColumn]?.props?.components[0]?.id === workshopComponent?.id) {
                    selectedTimeline.columns[selectedColumn].props.components[0] = workshopComponent;
                }
            }
            setComponentState({ ...workshopComponent, props: workshopComponent.props });
            updateComponent(workshopComponent, true, false);
        }
        setWorkshopUseLibrary(false);
    };

    /**
     * Used to set the height while working in the Progression Section.
     */
    const beforeSaveProgressionCheck = () => {
        let maxHeight = 0;
        const selectedCompHeight = setSelectedCompHeight();
        const targetHeight = (selectedCompHeight !== undefined) ? selectedCompHeight.scrollHeight : 0;
        const routeOfTheSelectedComp = getLocationOfTargetComponent(workshopComponent, selectedColumn);

        if (routeOfTheSelectedComp && routeOfTheSelectedComp.length > 0) {
            selectedTimeline.columns.forEach((col) => {
                if (!col?.props?.isProfile) {
                    let targetProgressionElement = col.props.progressions[routeOfTheSelectedComp[0]];
                    for (let iterator = 1; iterator < routeOfTheSelectedComp.length; iterator += 1) {
                        if (targetProgressionElement && targetProgressionElement.props) {
                            targetProgressionElement = targetProgressionElement.props.components[routeOfTheSelectedComp[iterator]];
                        }
                    }
                    if (targetProgressionElement
                        && targetProgressionElement.props
                        && targetProgressionElement.props.componentHeight
                        && maxHeight < targetProgressionElement.props.componentHeight) {
                        maxHeight = targetProgressionElement.props.componentHeight;
                    }
                }
            });
        }

        if (targetHeight > maxHeight) {
            maxHeight = targetHeight;
            selectedTimeline.columns.forEach((column) => {
                if (!column?.props?.isProfile) {
                    let targetProgressionElement = column.props.progressions[routeOfTheSelectedComp[0]];
                    for (let iterator = 1; iterator < routeOfTheSelectedComp.length; iterator += 1) {
                        if (targetProgressionElement && targetProgressionElement.props) {
                            targetProgressionElement = targetProgressionElement.props.components[routeOfTheSelectedComp[iterator]];
                        }
                    }

                    if (targetProgressionElement && targetProgressionElement.props) {
                        targetProgressionElement.props.componentHeight = maxHeight;
                        updateRecoilComponent(targetProgressionElement.id, targetProgressionElement.props);
                    }
                }
            });
        }

        setComponentState({ ...selectedComponent, componentHeight: maxHeight });
        updateComponent(selectedComponent, true, false);
    };

    /**
     * Update the Timeline POI.
     */
    const updateTimelinePOI = () => {
        if (Object.keys(initialPOI).length === 0 && selectedPOIParentProc) {
            setInitialPOI(selectedPOI);
        }

        updatePOI({ ...selectedPOI, ...selectedPOIParentProc });
    };

    /**
     * Used to set the POI in case of Progression across all sections.
     */
    const checkAndSetPOI = () => {
        if (componentPOI && selectedPOIParentProc) {
            selectedComponent.props.poiWithParent = { ...selectedPOIParentProc };

            if (isProgression) {
                const routeOfTheSelectedComp = getLocationOfTargetComponent(selectedComponent, selectedColumn);
                selectedTimeline.columns.forEach((column) => {
                    if (!column?.props?.isProfile) {
                        let targetProgressionElement = column.props.progressions[routeOfTheSelectedComp[0]];
                        for (let iterator = 1; iterator < routeOfTheSelectedComp.length; iterator += 1) {
                            if (targetProgressionElement && targetProgressionElement.props) {
                                targetProgressionElement = targetProgressionElement.props.components[routeOfTheSelectedComp[iterator]];
                            }
                        }
                        if (targetProgressionElement && targetProgressionElement.props) {
                            targetProgressionElement.props.poiWithParent = { ...selectedPOIParentProc };
                            updateRecoilComponent(targetProgressionElement.id, targetProgressionElement.props);
                        }
                    }
                });
                setComponentState(selectedComponent);
                updateComponent(selectedComponent, true, false);
                handleSelectedPOI([], {});
            } else {
                setComponentState(selectedComponent);
                updateComponent(selectedComponent, true, false);
                handleSelectedPOI([], {});
            }
        }
    };

    const setTheAccessControl = () => {
        const routeOfTheSelectedComp = getLocationOfTargetComponent(selectedComponent, selectedColumn);

        if (routeOfTheSelectedComp && routeOfTheSelectedComp.length > 0
            && selectedComponent && selectedComponent.id && workshopAccessView[selectedComponent.id]) {
            selectedTimeline.columns.forEach((column) => {
                if (!column?.props?.isProfile) {
                    let targetProgressionElement = column.props.progressions[routeOfTheSelectedComp[0]];
                    if (targetProgressionElement) {
                        if (routeOfTheSelectedComp.length > 1) {
                            for (let iterator = 1; iterator < routeOfTheSelectedComp.length; iterator += 1) {
                                if (targetProgressionElement) {
                                    targetProgressionElement = targetProgressionElement.props.components[routeOfTheSelectedComp[iterator]];
                                }
                            }
                        }
                        if (targetProgressionElement) {
                            updateRecoilComponent(targetProgressionElement.id, targetProgressionElement.props);
                        }
                    }
                }
            });
        }
    };

    /**
     * Used to set the Header Props in case of Progression across all columns.
     */
    const checkAndSetHeaderComponents = () => {
        if (isProgression && (workshopComponent.type === 'Text' || workshopComponent.type === 'Protocol')) {
            const routeOfTheSelectedComp = getLocationOfTargetComponent(workshopComponent, selectedColumn);
            selectedTimeline.columns.forEach((column) => {
                if (!column?.props?.isProfile) {
                    let targetProgressionElement = column.props.progressions[routeOfTheSelectedComp[0]];
                    for (let iterator = 1; iterator < routeOfTheSelectedComp.length; iterator += 1) {
                        if (targetProgressionElement && targetProgressionElement.props) {
                            targetProgressionElement = targetProgressionElement.props.components[routeOfTheSelectedComp[iterator]];
                        }
                    }

                    if (targetProgressionElement && targetProgressionElement.props) {
                        if (workshopComponent.type === 'Text') {
                            targetProgressionElement.props.text = workshopComponent.props.text;
                            targetProgressionElement.props.type = workshopComponent.props.type;
                        } else {
                            targetProgressionElement.props.title = workshopComponent.props.title;
                        }
                    }
                    updateRecoilComponent(targetProgressionElement?.id, targetProgressionElement?.props);
                }
            });
            setComponentState(selectedComponent);
            updateComponent(selectedComponent, true, false);
            handleSelectedPOI([], {});
        }
    };

    /**
     * Used to change the selected component in case of Save and Next & Save  and Previous.
     * @param {Number} The index of the new column.
     */
    const changeTheComponent = (columnIndex) => {
        const routeOfTheSelectedComp = getLocationOfTargetComponent(workshopComponent, selectedColumn);
        let targetProgressionElement = selectedTimeline.columns[columnIndex].props.progressions[routeOfTheSelectedComp[0]];

        for (let iterator = 1; iterator < routeOfTheSelectedComp.length; iterator += 1) {
            if (targetProgressionElement && targetProgressionElement.props) {
                targetProgressionElement = targetProgressionElement.props.components[routeOfTheSelectedComp[iterator]];
            }
        }
        setSelectedColumn(columnIndex);

        if (targetProgressionElement) {
            setWorkshopComponent(targetProgressionElement);
            setSelectedComponent(targetProgressionElement);
            setSettingsSelectedComponent(targetProgressionElement);
            setHierarchyComponent(targetProgressionElement);
            updateRecoilComponent(targetProgressionElement.id, targetProgressionElement.props);
        }
    };

    /**
     * Save the Workshop component as Page Template
     */
    const saveAsTemplate = () => {
        if (workshopComponent && workshopComponent.props) {
            let name = workshopComponent.props.title;
            if (workshopComponent.props.group === 'Page Groups') {
                name = workshopComponent.props.components[0].props.components[0].props.title;
            }
            const obj = {
                group: workshopComponent.props.group || 'Protocol Only',
                id: '',
                name,
                supergroup: workshopLibraryFilter || 'Protocol Only',
                template: { isCustom: true, ...workshopComponent },
                type: workshopComponent.type,
            };
            setDialogOpen(true);
            if (!templatePayLoad || (JSON.stringify(templatePayLoad) !== JSON.stringify(obj))) {
                setTemplatePayLoad(obj);
            }
        }
    };

    /** On Saving Template */
    const onSaveTemplate = () => {
        templatePayLoad.parent_reference_id = templatePayLoad?.template?.templateId;
        createTemplate(templatePayLoad);
    };

    /** over ride functionality for duplicate templates */
    const overRideTemplate = () => {
        deleteTemplate(existingTemplate).then(onSaveTemplate);
    };

    /** callback from dialogbox */
    const onDialogSave = (payload) => {
        setTemplatePayLoad(payload);
        const byType = templateLibraryByType[payload.type];
        const filtered = byType && byType.filter((item) => (
            item.supergroup === payload.supergroup
            && item.group === payload.group
            && item.name === payload.name
            && item.category === payload.category
            && item.sub_category === payload.subCategory
        ));

        if (filtered.length) {
            setOverRideDialogOpen(true);
            setExistingTemplate(filtered[0]);
        } else if (templatePayLoad) {
            onSaveTemplate();
        }
    };

    /**
     * Save button functionality.
     */

    const saveCallback = (cleanup) => () => {
        const componentList = [];
        buildSettingsTree(componentList, workshopComponent);
        const gallaryComp = componentList?.filter((i) => i?.type === 'Gallery');
        const protocolComp = componentList?.find((i) => i?.type === 'Protocol');

        const cdInsertImageList = [];

        if (gallaryComp) {
            gallaryComp.forEach((g, sindex) => {
                if (g?.props?.components) {
                    g.props.components.forEach((i) => cdInsertImageList.push({
                        url: i?.props?.imgSrc?.url,
                        imageTitle: i?.props?.title,
                        slideTitle: g?.props?.title,
                        imageCaption: i?.props?.caption,
                        slideCaption: g?.props?.caption,
                        slideNumber: sindex + 1,
                        positionIndex: i?.props?.index,
                    }));
                }
            });
        }

        if (actionRelation?.relation === 'insert-content-display') {
            insertContentDisplay({
                variables: {
                    timeline_id: selectedTimeline?.id,
                    column_id: selectedTimeline?.columns?.[selectedColumn]?.id,
                    protocol_id: contentDisplayComponent?.id,
                    image_list: [...cdInsertImageList, { compTemplate: protocolComp }],
                },
            }).then(() => {
                refetchContentDisplay();
            });
        }

        if (actionRelation?.relation === 'edit-content-display') {
            showLoader(true);
            updateContentDisplay({
                variables: {
                    protocol_id: contentDisplayComponent?.id,
                    image_list: [...cdInsertImageList, { compTemplate: protocolComp }],
                },
            }).then(() => {
                showLoader(false);
                refetchContentDisplay();
            });
        }
        checkAndSetPOI();
        checkAndSetHeaderComponents();
        setComponentPOI({});
        setWorkshopColumnCopy(null);
        clearWorkshopComponent();
        setTemplatePayLoad(null);
        setPageComponent(null);
        setIsTemplateSelected(false);
        clearSettingAccordian(!!isProgression);
        setProgressionFilter(null);
        setClearCurrentAssociation(true);
        setContentDisplayCip(false);
        setContentDisplayComponent(false);
        setContentDisplayImageList([]);
        if (actionRelation?.relation !== 'edit-content-display' && actionRelation?.relation !== 'insert-content-display') {
            cleanup();
        } else {
            setWorkshopLoading(false);
        }
    };

    const insertCleanup = () => {
        const components = isProgression ? workshopColumn?.props?.progressions : workshopColumn?.props?.components;
        const [allParents, parentComponent] = (components && findParents(selectedComponent, components)) || [];
        const siblings = parentComponent?.props?.components.filter((item) => item.id !== selectedComponent.id).map((item) => ({
            ...item,
            props: { ...item.props, childOf: parentComponent.id },
        })) || [];
        // const allComponents = [...allParents, selectedComponent, ...siblings];
        const allComponents = [selectedComponent];
        const updatedChildrenAC = {};
        allComponents.forEach((item) => {
            let accessData = workshopAccessView[item.id];
            if (!accessData) {
                const parentComp = allComponents.find((comp) => comp.id === item.props.childOf);
                const parentAccessData = parentComp && workshopAccessView[parentComp.id];
                accessData = parentAccessData;
            }
            const newAccessData = accessData || accessViewInitialValue;
            updatedChildrenAC[item.id] = newAccessData;
            acRequests.current.push({
                ...queryVariables,
                protocol_id: item.id,
                access_data: newAccessData,
            });
        });

        if (isProgression) {
            allComponents.forEach((comp) => {
                selectedTimeline.columns.forEach((column) => {
                    const locationMap = getLocationOfTargetComponent(comp, selectedColumn);
                    const topCompIndex = locationMap[0];
                    const topComp = column?.props?.progressions[topCompIndex];
                    let targetProgressionElement = topComp;
                    if (!targetProgressionElement) {
                        return;
                    }
                    for (let iterator = 1; iterator < locationMap.length; iterator += 1) {
                        targetProgressionElement = targetProgressionElement.props.components[locationMap[iterator]];
                    }
                    if (targetProgressionElement && comp.id !== targetProgressionElement.id) {
                        acRequests.current.push({
                            ...queryVariables,
                            protocol_id: targetProgressionElement.id,
                            column_id: column.id,
                            access_data: updatedChildrenAC[comp.id] || workshopAccessView[comp.id] || accessViewInitialValue,
                        });
                        // );
                    }
                });
            });
        }

        if (compInfo?.['column_id'] !== compInfo?.['protocol_id']) {
            poiRequests.current.push({
                ...compInfo,
            });
        }

        const acFilterData = acRequests.current.filter((ac) => ac.column_id !== ac.protocol_id);

        // _.reverse is necessary to keep  the latest mutations for a protocol_id
        const acPromises = acFilterData?.length > 0 && insertComponentAC({
            variables: {
                objects: _.uniqBy(_.reverse(acFilterData), 'protocol_id'),
            },
        });

        const poiPromises = insertComponentPOI({
            variables: {
                objects: _.uniqBy(_.reverse(poiRequests.current), 'protocol_id'),
            },
        });
        Promise.all([
            poiPromises,
            acPromises,
        ])
            .then(() => {
                setWorkshopLoading(false);
            }).catch((err) => {
                // eslint-disable-next-line no-console
                console.error('Inserting user access Failed', err);
                setWorkshopLoading(false);
            });
    };
    const editCleanup = () => {
        // TODO-> add accessView to Child
        const workshopList = [];
        buildSettingsTreePage(workshopList, workshopComponent);
        const components = isProgression ? workshopColumn?.props?.progressions : workshopColumn?.props?.components;
        const [allParents, parentComponent] = (components && findParents(selectedComponent, components)) || [];
        const siblings = parentComponent?.props?.components.filter((item) => item.id !== selectedComponent.id).map((item) => ({
            ...item,
            props: { ...item.props, childOf: parentComponent.id },
        })) || [];
        const allComponents = allParents ? [...allParents?.reverse(), selectedComponent, ...siblings]
            : [selectedComponent, ...siblings];
        const allComponentIds = allComponents.map((item) => item?.id);
        // only update children list if they are not visible in the sidebar.
        const childrenList = workshopList.filter((item) => !allComponentIds.includes(item?.id));
        const updatedChildrenAC = {};
        allComponents.forEach((item) => {
            acRequests.current.push({
                ...queryVariables,
                protocol_id: item.id,
                access_data: workshopAccessView[item.id] || accessViewInitialValue,
            });
        });
        childrenList.forEach((item) => {
            if (item.type === 'Text') {
                updatedChildrenAC[item.id] = workshopAccessView[workshopComponent.id];
                acRequests.current.push({
                    ...queryVariables,
                    protocol_id: item.id,
                    access_data: workshopAccessView[workshopComponent.id] || accessViewInitialValue,
                });
            }
        });
        const isUpdatePOIFlag = !(_.isEqual(poiValue, compInfo.poi_data));

        let childPOIPromises = null;
        const findWorkshopCompPOI = poiData.components_poi.find((c) => c.protocol_id === workshopComponent.id);

        if (!findWorkshopCompPOI) {
            const reqVariables = {
                ...compInfo,
                protocol_id: workshopComponent.id,
            };
            const data = [reqVariables];
            childPOIPromises = insertComponentPOI({
                variables: {
                    objects: data,
                },
            });
        } else {
            childPOIPromises = isUpdatePOIFlag
            && childrenList.map((item) => (((item.type === 'Text') || (item.type === 'Protocol' && item.supergroup))
                ? updateComponentPOI({
                    variables: {
                        ...compInfo,
                        protocol_id: item.id,
                    },
                }) : null));
        }
        if (isProgression) {
            [...workshopList, ...allComponents].filter((item) => item.id !== workshopComponent.id).forEach((comp) => {
                selectedTimeline.columns.forEach((column) => {
                    const locationMap = getLocationOfTargetComponent(comp, selectedColumn);
                    const topCompIndex = locationMap[0];
                    const topComp = column?.props?.progressions[topCompIndex];
                    let targetProgressionElement = topComp;
                    if (!targetProgressionElement) {
                        return;
                    }
                    for (let iterator = 1; iterator < locationMap.length; iterator += 1) {
                        targetProgressionElement = targetProgressionElement?.props?.components[locationMap[iterator]];
                    }
                    if (targetProgressionElement && comp.id !== targetProgressionElement.id) {
                        acRequests.current.push({
                            ...queryVariables,
                            protocol_id: targetProgressionElement.id,
                            column_id: column.id,
                            access_data: updatedChildrenAC[comp.id] || workshopAccessView[comp.id] || accessViewInitialValue,
                        });
                    }
                });
            });
        }

        const hasAcChanges = !_.isEqual(initialWorkshopAC.current, workshopAccessView);
        const allPromises = [
            isUpdatePOIFlag && updateComponentPOI({
                variables: {
                    ...compInfo,
                },
            }),
            childPOIPromises,
        ];

        if (hasAcChanges) {
            acRequests.current.forEach((i) => {
                const acPromises = updateComponentAC({
                    variables: {
                        protocol_id: i.protocol_id,
                        timeline_id: i.timeline_id,
                        access_data: i.access_data,
                    },
                });
                allPromises.push(acPromises);
            });
        }

        Promise.all(allPromises).then(() => {
            setWorkshopLoading(false);
        }).catch((err) => {
            // eslint-disable-next-line no-console
            console.error('Inserting POI Failed', err);
            setWorkshopLoading(false);
        });
    };

    const checkForMandatoryFields = () => {
        if (workshopComponent?.name === 'Complex Forms') {
            let hasMandatoryFields = false;
            for (let i = 0; i < componentList?.length; i += 1) {
                if ((componentList[i]?.type === 'Checkbox'
                    || componentList[i]?.type === 'TextAreaCF'
                    || componentList?.[i]?.type === 'TextBoxCF'
                    || (componentList[i]?.type === 'MultiSelect' && componentList[i]?.props?.options?.length > 0)
                    || (componentList[i]?.type === 'Select' && componentList[i]?.props?.options?.length > 0))
                    && (workshopComponent.props.title)) {
                    hasMandatoryFields = true;
                    break;
                }
            }
            if (!hasMandatoryFields) {
                setCFWithoutComp(true);
            } else {
                save();
            }
        } else {
            save();
        }
    };

    const handleSelectSuggest = () => {
        const protocolTableData = selectedChildren?.['selected_children']?.find((sc) => sc.protocol_id === workshopComponent.id);
        const workshopList = [];
        buildSettingsTreePage(workshopList, workshopComponent);
        // eslint-disable-next-line no-unused-vars
        for (const key in protocolTableData?.['children_data']) {
            const suggestComp = workshopList.find((w) => w.id === key);
            if (!suggestComp) delete protocolTableData.children_data[key];
            else {
                protocolTableData.children_data[key].title = suggestComp.props.label;
                // eslint-disable-next-line no-unused-vars
                for (const childKey in protocolTableData.children_data[key].children) {
                    const suggestChildComp = workshopList.find((w) => w.id === childKey);
                    if (!suggestChildComp) {
                        delete protocolTableData.children_data[key].children[childKey];
                    } else {
                        protocolTableData.children_data[key].children[childKey].title = suggestChildComp.props.label;
                    }
                }
            }
        }
        if (protocolTableData?.['children_data']) {
            updateSelectdChildren({
                variables: {
                    id: protocolTableData?.id,
                    childrenData: protocolTableData?.['children_data'],
                },
            });
        }
    };

    const removeHeaderChildCDComp = (comp) => {
        let parentComp = null;
        if (comp && !comp?.[0]?.isProgression) {
            comp.forEach((i) => {
                if (i?.type === 'Text') {
                    parentComp = i.props.components.find((j) => j?.id === selectedParent?.id);
                    const indexH = i?.props?.components?.indexOf(parentComp);
                    const removeComp = i?.props?.components?.[indexH]?.props?.components?.find((i) => i?.id === workshopComponent?.id);
                    const indexC = i?.props?.components?.[indexH]?.props?.components?.indexOf(removeComp);
                    if (indexC > -1 && removeComp) {
                        i.props.components[indexH].props.components.splice(indexC, 1);
                    } else if (!removeComp && i?.props?.components) {
                        removeHeaderChildCDComp(i?.props?.components);
                    }
                }
            });
        } else if (comp) {
            comp.forEach((i) => {
                if (i?.type === 'Text') {
                    const indexH = progressionComponentRoute?.[progressionComponentRoute?.length - 2];
                    const removeComp = i?.props?.components?.[indexH]?.props?.components?.find((i) => i?.commonId === workshopComponent?.commonId);
                    const indexC = i?.props?.components?.[indexH]?.props?.components?.indexOf(removeComp);
                    if (indexC > -1 && removeComp) {
                        i.props.components[indexH].props.components.splice(indexC, 1);
                    } else if (!removeComp && i?.props?.components) {
                        removeHeaderChildCDComp(i?.props?.components);
                    }
                }
            });
        }
    };

    const save = async () => {
        if (activeSelectSuggestParent) {
            delete activeSelectSuggestParent.props.highlightColor;
        }
        setComplexFormType('');
        setActiveSelectSuggestParent(null);
        setCFWithoutComp(false);
        setSpecificSelect({});
        setCurrentSelectedValue({});
        if (selectedTimeline?.name !== selectedTimeline?.props?.name) {
            selectedTimeline.name = selectedTimeline.props.name;
        }
        setAssociationValue({});
        setEditTimelineColumn(false);
        setEditTimeline(false);
        setAddColumnLeftRight(false);
        setHyperlinkCip(false);
        setPageHyperlinkCip(false);
        setHyperlinkCipText('');
        setHyperlinkComponent(false);
        setHyperlinkImageVideoComponent(false);
        setProgressionAccCollapse(false);
        setIsEditMode(false);
        if (settingMenuType !== 'Admin') {
            setSettingMenuType('Admin');
        }
        if (!timeLineVisible) {
            setTimeLineVisible(true);
        }
        const componentAccessView = workshopAccessView[workshopComponent.id] || accessViewInitialValue;
        const nothingSelectedPOI = Object.keys(componentPOI).every((child) => !componentPOI[child]);
        const nothingSelectedAC = Object.keys(componentAccessView).every((child) => !componentAccessView[child]);
        if ((nothingSelectedPOI || nothingSelectedAC) && !hyperlinkCip) {
            if ((workshopComponent?.type === 'Column' && !workshopComponent?.parent) || (workshopComponent?.type === 'Timeline' || contentDisplayComponent)) {
                // skip POI check
            } else {
                setDisableSaveBtn(!disableSaveBtn);
                setDirty(true);
                return;
            }
        }
        setTimelineContainerLoader(true);
        if (isProgression) {
            setTheAccessControl();
        }

        const comp = componentList.find((item) => item?.type === 'Page');
        if (comp && pageComponent) {
            domainArray.forEach((domainVal) => {
                if (pageComponent[domainVal]) {
                    (pageComponent[domainVal].components || []).forEach((c) => {
                        if (c.type === 'HTML') {
                            c.props = { ...c.props, ...c[domainVal] };
                        }
                    });
                    comp[domainVal] = JSON.parse(JSON.stringify(pageComponent[domainVal]));
                }
            });
            comp.props = { ...comp.props, ...comp.Medguide };
        }
        const workshopList = [];
        buildSettingsTreePage(workshopList, workshopComponent);
        const scndColumn = workshopList.find((c) => c.type === 'Column' && c?.compIndex === 1);
        let isManualChildren = false;
        if (!workshopComponent.props.isSimpleForm && scndColumn) {
            const scndColTLCells = scndColumn.props.components.filter((c) => c.isTimeline);
            // eslint-disable-next-line no-unused-vars
            for (const c of scndColTLCells) {
                if (c.props.components.length > 0) {
                    isManualChildren = true;
                    break;
                }
            }
        }
        workshopList.forEach((item) => {
            if (item.type === 'Text') {
                item.props.childComponents = false;
                if (item[domain]) {
                    item[domain].childComponents = false;
                }
            }
            item.props.protocolId = workshopComponent?.id;
            if (item?.props?.accessView) {
                delete item.props.accessView;
            }
            if (item?.props?.poi) {
                delete item.props.poi;
            }
            if (workshopComponent?.props?.isUniversal && workshopComponent.props.pcRelationship !== 'manual') {
                if ('highlightAssociation' in item.props) {
                    item.props.highlightAssociation.parentAssociation = [];
                    item.props.highlightAssociation.childAssociation = [];
                }

                if (item.isTimeline && item.props.components.length > 0) {
                    item.props.components = item.props.components[0].type === 'Select' ? item.props.components : [];
                }
            }

            if (workshopComponent?.props?.isUniversal
                && workshopComponent.props.pcRelationship !== 'manual' && item.isTimeline && item.type === 'Cell') {
                item.props.components.forEach((c) => {
                    c.props.options = [];
                });
            }
        });
        if (workshopComponent?.props?.isUniversal
            && workshopComponent.props.pcRelationship !== 'manual' && !workshopComponent?.props?.leftRightColumn) {
            const thirdColumn = workshopList.find((c) => c.type === 'Column' && c?.compIndex === 2);
            const isCellInThirdCol = thirdColumn?.props?.components?.find((c) => c.type === 'Cell');
            if (isCellInThirdCol && thirdColumn?.props?.components.length > 0 && isManualChildren) {
                thirdColumn.props.components = thirdColumn.props.components.filter((c) => !c.isTimeline || c.type === 'SelectCell');
                if (isCellInThirdCol) {
                    const frstColComp = workshopList.find((c) => c.type === 'Column' && c?.compIndex === 0)?.props?.components;
                    let len = frstColComp.length;
                    const isSelect = frstColComp[0].type === 'Select';
                    if (isSelect) {
                        len = frstColComp[0].props.options.length;
                    }
                    const thirdColTLCells = thirdColumn.props.components.filter((c) => c.isTimeline);
                    const thirdColWSCells = thirdColumn.props.components.filter((c) => !c.isTimeline);
                    if (thirdColTLCells.length === 0 && len > 0) {
                        const rowComp = workshopList.find((c) => c.type === 'Row');
                        for (let i = 0, l = len; i < l; i += 1) {
                            const emptyCell = createEmptyCell({
                                secondNextCol: thirdColumn,
                                associatedComponent: isSelect ? frstColComp?.[0]?.props?.options[i] : frstColComp[i],
                                nextCol: scndColumn,
                                parentComp: rowComp,
                                isTimeline: true,
                                indexValue: i,
                            });
                            thirdColTLCells.push(emptyCell);
                        }
                    }
                    thirdColumn.props.components = [...thirdColWSCells, ...thirdColTLCells];
                }
            }
            setUniversalCompState({});
        }
        if (workshopComponent?.props?.accessView) {
            delete workshopComponent.props.accessView;
        }

        if (workshopComponent.props.cfType === 'Select and Suggest') {
            handleSelectSuggest();
        }
        if (actionRelation?.relation === 'insert-content-display' && !isProgression) {
            const removeComp = workshopColumn?.props?.components?.find((i) => i?.id === workshopComponent?.id);
            const removeCompIndex = workshopColumn?.props?.components.indexOf(removeComp);
            if (removeCompIndex > -1) {
                workshopColumn.props.components.splice(removeCompIndex, 1);
            } else {
                const parentComp = workshopColumn?.props?.components?.find((i) => i?.id === selectedParent?.id);
                if (parentComp?.type === 'Text') {
                    const parentCompIndex = workshopColumn?.props?.components?.indexOf(parentComp);
                    const removeComp = workshopColumn?.props?.components?.[parentCompIndex]?.props?.components?.find((i) => i?.id === workshopComponent?.id);
                    const removeCompIndex = workshopColumn?.props?.components?.[parentCompIndex]?.props.components?.indexOf(removeComp);
                    if (removeCompIndex > -1) {
                        parentComp.props.components.splice(removeCompIndex, 1);
                    }
                } else if (!parentComp && workshopColumn?.props?.components) {
                    removeHeaderChildCDComp(workshopColumn?.props?.components);
                }
            }
        }
        if (initialActionRelation.type === 'insert') {
            if (isProgression) {
                const locationMap = getLocationOfTargetComponent(workshopComponent, selectedColumn);
                const currentCompIndex = locationMap[0];
                if (currentCompIndex !== -1) {
                    try {
                        (selectedTimeline?.columns || []).forEach((col) => {
                            if (!col?.props?.isProfile) {
                                const currentComp = col?.props?.progressions[currentCompIndex];
                                let targetProgressionElement = currentComp;
                                for (let iterator = 1; iterator < locationMap.length; iterator += 1) {
                                    targetProgressionElement = targetProgressionElement.props.components[locationMap[iterator]];
                                }
                                const workshopList = [];
                                buildSettingsTreePage(workshopList, targetProgressionElement);
                                workshopList.forEach((item) => {
                                    item.props.protocolId = targetProgressionElement?.id;
                                    if (item?.props?.accessView) {
                                        delete item.props.accessView;
                                    }
                                    if (item?.props?.poi) {
                                        delete item.props.poi;
                                    }
                                });
                                if (actionRelation?.relation === 'insert-content-display') {
                                    if (currentComp?.type === 'Text') {
                                        const compParentIndex = progressionComponentRoute?.[progressionComponentRoute?.length - 2];
                                        const removeComp = col.props.progressions[compParentIndex].props.components.find((i) => i?.commonId === workshopComponent?.commonId);
                                        const fIndex = col.props.progressions?.[compParentIndex]?.props?.components?.indexOf(removeComp);
                                        if (fIndex > -1 && removeComp) {
                                            col.props.progressions[compParentIndex].props.components.splice(fIndex, 1);
                                        } else if (!removeComp && col?.props?.progressions) {
                                            removeHeaderChildCDComp(col.props.progressions);
                                        }
                                    } else {
                                        col.props.progressions.splice(currentCompIndex, 1);
                                    }
                                }
                                updateComponent({ ...targetProgressionElement, props: { ...targetProgressionElement.props, poi: {} } }, true, false,);
                                if ((actionRelation?.relation !== 'insert-content-display')
                                    && (actionRelation?.relation !== 'edit-content-display')) {
                                    acRequests.current.push({
                                        ...queryVariables,
                                        protocol_id: targetProgressionElement.id,
                                        column_id: col?.id,
                                        access_data: workshopAccessView[workshopComponent.id] || accessViewInitialValue,
                                    });

                                    poiRequests.current.push({
                                        ...compInfo,
                                        protocol_id: targetProgressionElement?.id,
                                        column_id: col?.id,
                                    });
                                }
                            }
                        });
                    } catch (err) {
                        // eslint-disable-next-line no-console
                        console.error(err);
                    }
                }
            }
            updateComponent({ ...workshopComponent, props: { ...workshopComponent.props, poi: {} } }, true, false, saveCallback(insertCleanup));
        } else if (initialActionRelation.type === 'edit') {
            if (contentDisplayCip && contentDisplayImageList?.length > 0) {
                contentDisplayComponent.props.hasContentDisplay = true;
            } else if (contentDisplayCip) {
                contentDisplayComponent.props.hasContentDisplay = false;
            }
            if (isProgression) {
                const locationMap = getLocationOfTargetComponent(workshopComponent, selectedColumn);
                const currentCompIndex = locationMap[0];
                if (currentCompIndex !== -1) {
                    try {
                        await Promise.all([(selectedTimeline?.columns || []).forEach((col) => {
                            if (!col?.props?.isProfile) {
                                const currentComp = col?.props?.progressions[currentCompIndex];
                                let targetProgressionElement = currentComp;
                                for (let iterator = 1; iterator < locationMap.length; iterator += 1) {
                                    targetProgressionElement = targetProgressionElement.props.components[locationMap[iterator]];
                                }
                                const workshopList = [];
                                buildSettingsTreePage(workshopList, targetProgressionElement);
                                workshopList.forEach((item) => {
                                    item.props.protocolId = targetProgressionElement?.id;
                                    if (item?.props?.accessView) {
                                        delete item.props.accessView;
                                    }
                                    if (item?.props?.poi) {
                                        delete item.props.poi;
                                    }
                                });
                                updateComponent({ ...targetProgressionElement, props: { ...targetProgressionElement.props, poi: {} } }, true, false,);
                                const isUpdatePOIFlag = !(_.isEqual(poiValue, compInfo.poi_data));
                                const childPOIPromises = isUpdatePOIFlag && workshopList.map((item) => {
                                    updateComponentPOI({
                                        variables: {
                                            ...compInfo,
                                            protocol_id: item.id,
                                            column_id: col?.id,
                                        },
                                    });
                                    return null;
                                });
                                return Promise.all([
                                    isUpdatePOIFlag && updateComponentPOI({
                                        variables: {
                                            ...compInfo,
                                            protocol_id: targetProgressionElement?.id,
                                            column_id: col?.id,
                                        },
                                    }),
                                    childPOIPromises,

                                ]);
                            }
                            return null;
                        })]);
                    } catch {
                        toast.error('Something went wrong.');
                    }
                }
            }
            updateComponent({ ...workshopComponent, props: { ...workshopComponent.props, poi: {} } }, true, false, saveCallback(editCleanup));
        } else if (initialActionRelation.type === 'leftColumn' || initialActionRelation.type === 'rightColumn') {
            const { acDataObj, poiDataObj } = getComponentPOIUA(contextMenuTarget.component);
            const clone = copyTemplateWithPOI(contextMenuTarget.component, { poiObj: poiDataObj, acObj: acDataObj });
            selectedTimeline.columns[selectedColumn].props.progressions = clone.component.props.progressions;
            clone.component.props.progressions.forEach((item) => delete item.props.hasContentDisplay);

            updateComponent({ ...workshopComponent, props: { ...workshopComponent.props, poi: {} } }, true, false, saveCallback(insertCleanup));

            if (clone?.poiACData?.poiClone?.length > 0) {
                clone.poiACData.poiClone.forEach((c) => {
                    c.column_id = selectedTimeline.columns[selectedColumn].id;
                });
                bulkInsertPoi({
                    variables: {
                        objects: clone.poiACData.poiClone,
                    },
                });
            }

            if (clone?.poiACData?.acClone?.length > 0) {
                clone.poiACData.acClone.forEach((c) => {
                    c.column_id = selectedTimeline.columns[selectedColumn].id;
                });
                bulkInsertAC({
                    variables: {
                        objects: clone.poiACData.acClone,
                    },
                });
            }
        }

        if (workshopComponent?.props.isUniversal) { // delete color component from workshop
            const flatCompList = [];
            buildSettingsTreePage(flatCompList, workshopComponent);
            flatCompList.forEach((fc) => {
                delete fc.props.highlightComponent;
            });
            setUniversalCompState({});
        }
        const flatChildOptionData = [];
        flatOptions(flatChildOptionData, workshopComponent);
        flatChildOptionData.forEach((option) => {
            if ('highlightComponent' in option) delete option.highlightComponent;
            if (workshopComponent.props.pcRelationship !== 'manual') {
                option.highlightAssociation = { parentAssociation: [], child: [] };
            }
        });
        setComponentPOI({});
        setUniversalCompState({});
        setUniversalScndState({});
        setWorkshopAccessView({});
        setWorkshopLoading(true);
    };

    /**
     * Cancel button functionality.
     */

    const cancel = () => {
        if (activeSelectSuggestParent) {
            delete activeSelectSuggestParent.props.highlightColor;
        }
        setWorkshopLoading(true);
        setComplexFormType('');
        setActiveSelectSuggestParent(null);
        // Delete highlight component
        if (workshopComponent?.props?.isUniversal) {
            const flatOptionData = [];
            flatOptions(flatOptionData, workshopComponent);
            flatOptionData.forEach((item) => {
                if ('highlightComponent' in item) delete item.highlightComponent;
            });
        }

        if (actionRelation?.type === 'insert' && workshopComponent?.name === 'Images/Videos') {
            getDeletedOthersMedia({
                variables: {
                    component_id: [workshopComponent?.commonId || workshopComponent?.id],
                },
            });
        } else {
            initiateCancelFunctionality();
        }
        setUniversalCompState({});
        setUniversalScndState({});
    };

    useEffect(() => {
        let showNextBtnFlag = true;
        selectedTimeline.columns.forEach((column, index) => {
            if (index >= selectedColumn) {
                showNextBtnFlag = column.props.showProgression;
            }
        });
        setShowNextBtn(showNextBtnFlag);
    }, []);

    const findNextColumn = (data, index) => {
        let dIndex = index;
        if (dIndex < data.length - 1) {
            const column = data[dIndex + 1];
            if (Object.prototype.hasOwnProperty.call(column.props, 'showProgression') && !column.props.showProgression) {
                dIndex += 1;
                findNextColumn(data, dIndex);
            } else {
                return dIndex;
            }
        }
        return dIndex;
    };

    const checkNextColumn = (data, index) => {
        let isNextCol = false;
        for (let i = index + 1; i < data.length; i += 1) {
            const column = data[i];
            if (Object.prototype.hasOwnProperty.call(column.props, 'showProgression') && column.props.showProgression) {
                isNextCol = true;
                break;
            }
        }

        return isNextCol;
    };

    const findPreviousColumn = (data, index) => {
        let dIndex = index;
        if (dIndex <= data.length - 1) {
            const column = data[dIndex - 1];
            if (Object.prototype.hasOwnProperty.call(column.props, 'showProgression') && !column.props.showProgression) {
                dIndex -= 1;
                findPreviousColumn(data, dIndex);
            } else {
                return dIndex;
            }
        }
        return dIndex;
    };

    const checkPreviousColumn = (data, index) => {
        let isPreviousCol = false;
        for (let i = index - 1; i >= 1; i -= 1) {
            const column = data[i];
            if (Object.prototype.hasOwnProperty.call(column.props, 'showProgression') && column.props.showProgression) {
                isPreviousCol = true;
                break;
            }
        }

        return isPreviousCol;
    };

    const next = () => {
        let nextColIndex = -1;
        if (isProgression) {
            beforeSaveProgressionCheck();
            setTheAccessControl();
            updateTimelinePOI();
            nextColIndex = findNextColumn(selectedTimeline.columns, selectedColumn);
            setInitialSelectedParent(selectedTimeline.columns[nextColIndex + 1]);
        } else {
            updateComponent(selectedComponent, true, false);
        }
        checkAndSetPOI();
        checkAndSetHeaderComponents();
        changeTheComponent(nextColIndex !== -1 ? nextColIndex + 1 : selectedColumn + 1);
        clearSettingAccordian(true);
    };

    const previous = () => {
        let previousColIndex = -1;
        if (isProgression) {
            beforeSaveProgressionCheck();
            setTheAccessControl();
            updateTimelinePOI();
            previousColIndex = findPreviousColumn(selectedTimeline.columns, selectedColumn);
            setInitialSelectedParent(selectedTimeline.columns[previousColIndex - 1]);
        } else {
            updateComponent(selectedComponent, true, false);
        }
        checkAndSetPOI();
        checkAndSetHeaderComponents();
        changeTheComponent(previousColIndex !== -1 ? previousColIndex - 1 : selectedColumn - 1);
        clearSettingAccordian(true);
    };

    /**
     * Return the SaveAsTemplate Button in case of Page Component
     */
    const getSaveAsTemplateBtn = () => {
        if (workshopComponent.type === 'Protocol' && isTemplateSelected && !hyperlinkCip) {
            return <Button variant="contained" className={classes.butonStyle} onClick={saveAsTemplate}>Save as Template</Button>;
        }

        return '';
    };

    /**
     * Setting the current POI beofre Save and Close.
     */
    useEffect(() => {
        setInitialPOI(selectedPOI);
    }, []);

    const handleTimeLineVisibility = () => {
        setTimeLineVisible(!timeLineVisible);
    };

    const getProgressionColumn = ({ columns }) => {
        const columnNameHeader = [];
        const timelineHeader = [];
        const columnsView = [];
        const isAddExplicitHeader = workshopComponent.isProgression || editProgressionComp;

        columns.forEach((column, index) => {
            if (hasHeader) {
                timelineHeader.push((
                    <div key={column.id} className={editTimelineColumn ? classes.workshopHeaderColumnX : classes.workshopHeaderColumn}>
                        <Typography className={classes.columHeadType} color="inherit">Timeline</Typography>
                        <b>&nbsp;</b>
                        <Tooltip
                            classes={{ tooltip: classes.tooltipCont }}
                            title={showTitleView ? 'Show Component Structure' : 'Hide Component Structure'}
                            arrow
                            placement="bottom"
                        >
                            <IconButton
                                onClick={() => setShowTitleView(!showTitleView)}
                                className={classes.visibilityIconStyle}
                            >
                                {showTitleView ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                        </Tooltip>
                    </div>
                ));
            }

            if (isAddExplicitHeader && column.props.showProgression) {
                columnNameHeader.push(
                    <Grid item xs key={column.id}>
                        {column.props.title === 'Profile'
                            && (
                                <Tooltip
                                    classes={{ tooltip: classes.tooltipCont }}
                                    title={timeLineVisible ? 'Hide Timeline' : 'Show Timeline'}
                                    arrow
                                    placement="right"
                                >
                                    <div className={classes.timeLineSpace}>
                                        <IconButton color="primary" onClick={handleTimeLineVisibility} className={classes.timeLineIcon}>
                                            {timeLineVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    </div>
                                </Tooltip>
                            )}
                        <div
                            style={{ width: selectedTimeline / columns.length, marginTop: column.props.title === 'Profile' && '-2.75rem' }}
                            className={classes.explicitColumnItem}
                        >
                            {column.props.title}
                        </div>
                    </Grid>
                );
            }

            if (isProgression ? column.props.showProgression : true) {
                columnsView.push(
                    <Grid item xs key={column.id}>
                        {timelineViewTab === 'TL/RCM'
                            ? (
                                <TemplateBrowser
                                    templates={filteredLibrary}
                                    openAccordion
                                    showLoader={showLoader}
                                />
                            )
                            : (
                                <ColumnContextProvider key={column.id} column={column}>
                                    <TimelineColumn
                                        columns={columns}
                                        column={column}
                                        columnIndex={index}
                                        isWorkshop
                                        timelineViewTab={timelineViewTab}
                                    />
                                </ColumnContextProvider>
                            )}
                    </Grid>
                );
            }
        });

        let columnNameHeaderFinal = null;

        if (isAddExplicitHeader) {
            columnNameHeaderFinal = (
                <Grid
                    container
                    className={classes.explicitHeaderStyle}
                    style={{ width: selectedTimeline.props.width }}
                >
                    {columnNameHeader}
                </Grid>
            );
        } else {
            columnNameHeaderFinal = (
                <Grid container>
                    <Grid item xs={12}>
                        <Typography className={classes.researchTextCont}>
                            {columns[0].props.title}
                        </Typography>
                    </Grid>
                </Grid>
            );
        }

        return (
            <>
                {timelineHeader}
                {(timelineViewTab !== 'TL/RCM') && columnNameHeaderFinal}
                <Grid container>
                    {columnsView}
                </Grid>
            </>
        );
    };

    const GetWorkshopAndSetting = () => {
        const padding = isProgression ? '0px' : '50px';
        const [openError, setOpenError] = useState(false);
        const [hrefValue, setHrefValue] = useState('');
        const [pageLinkRef, setPageLinkRef] = useState(['']);
        const [isValidURLArr, setIsValidURLArr] = useState([true]);
        const [isValidURL, setIsValidURL] = useState(true);
        const textFieldRef = useRef(null);

        const {
            loggedUserType,
        } = useContext(OrgContext);

        const {
            pagehyperlinkCip,
        } = useContext(TimelineContext);

        useEffect(() => {
            if (hyperlinkCip) {
                const requiredParentNode = document.getElementById('hyperlink-innerHTML');
                requiredParentNode.innerHTML = hyperlinkComponent?.props?.hyperlink;
                if (!hyperlinkImageVideoComponent) {
                    if (videoHyperlinkCip) {
                        setHrefValue(hyperlinkComponent?.props?.videoHyperlink || '');
                    } else if (hyperlinkCip) {
                        setPageLinkRef(hyperlinkComponent?.props?.hyperlink || ['']);
                        const localCopy = hyperlinkComponent?.props?.hyperlink || [''];
                        setIsValidURLArr(new Array(localCopy.length).fill(true));
                    }
                } else if (hyperlinkImageVideoComponent === 'Slide Title') {
                    setHrefValue(hyperlinkComponent?.props?.['hyperlink-slideTitle'] || '');
                } else if (hyperlinkImageVideoComponent === 'Slide Caption') {
                    setHrefValue(hyperlinkComponent?.props?.['hyperlink-slideCaption'] || '');
                } else if (hyperlinkImageVideoComponent === 'Image Title') {
                    setHrefValue(hyperlinkComponent?.props?.['hyperlink-imageTitle'] || '');
                } else if (hyperlinkImageVideoComponent === 'Image Caption') {
                    setHrefValue(hyperlinkComponent?.props?.['hyperlink-imageCaption'] || '');
                }
            }
        }, []);

        useEffect(() => {
            if (hyperlinkCip && textFieldRef?.current) {
                textFieldRef.current.children[0].children[0].value = hrefValue;
                if (!hyperlinkImageVideoComponent) {
                    if (hyperlinkComponent.props) {
                        if (videoHyperlinkCip) {
                            hyperlinkComponent.props.videoHyperlink = hrefValue;
                        } else {
                            hyperlinkComponent.props.hyperlink = pageLinkRef;
                        }
                    } else {
                        hyperlinkComponent.props = videoHyperlinkCip ? { videoHyperlink: hrefValue } : { hyperlink: pageLinkRef };
                    }
                } else if (hyperlinkImageVideoComponent === 'Slide Title') {
                    hyperlinkComponent.props['hyperlink-slideTitle'] = hrefValue;
                } else if (hyperlinkImageVideoComponent === 'Slide Caption') {
                    hyperlinkComponent.props['hyperlink-slideCaption'] = hrefValue;
                } else if (hyperlinkImageVideoComponent === 'Image Title') {
                    hyperlinkComponent.props['hyperlink-imageTitle'] = hrefValue;
                } else if (hyperlinkImageVideoComponent === 'Image Caption') {
                    hyperlinkComponent.props['hyperlink-imageCaption'] = hrefValue;
                }
            }
        }, [hrefValue, pageLinkRef]);

        const handleDisableClick = () => {
            setOpenError(true);
        };
        const handleCloseToast = () => {
            setOpenError(false);
        };

        const urlDebouncedTest = debounce((txt, ind = -1) => {
            const pattern = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
            if (pagehyperlinkCip) {
                if (pattern.test(txt)) {
                    const copy = [...isValidURLArr];
                    copy[ind] = true;
                    setIsValidURLArr(copy);
                } else {
                    const copy = [...isValidURLArr];
                    copy[ind] = false;
                    setIsValidURLArr(copy);
                }
            } else if (pattern.test(txt)) {
                setIsValidURL(true);
            } else {
                setIsValidURL(false);
            }
        }, 1000);

        const textFieldChnageHandler = (txt, ind = -1) => {
            if (pagehyperlinkCip) {
                const copy = [...pageLinkRef];
                copy[ind] = txt;
                setPageLinkRef(copy);
            } else {
                setHrefValue(txt);
            }
            urlDebouncedTest(txt, ind);
        };

        const handleHyperlinkType = (e) => {
            component.props.openHyperlinkType = e.target?.value;
        };

        const clearLinkValue = (ind) => {
            if (pagehyperlinkCip) {
                pageLinkRef[ind] = '';
                isValidURLArr[ind] = true;
                setUpdateFlag(!updateFlag);
            } else {
                setHrefValue('');
            }
        };

        const addNewLinkField = () => {
            setPageLinkRef((prev) => [...prev, '']);
            setIsValidURLArr((bool) => [...bool, true]);
        };

        const deleteLinkField = (i) => {
            pageLinkRef.splice(i, 1);

            isValidURLArr.splice(i, 1);
            setUpdateFlag(!updateFlag);
        };

        let hoverWorkshopComp = null;

        if (hoverFlagWorkshop) {
            hoverWorkshopComp = JSON.parse(JSON.stringify(workshopComponent));
            hoverWorkshopComp.props = component?.props;
        }

        const renderWorkshopSetting = () => {
            let isYoutube = false;
            let url = '';

            if (videoHyperlinkCip && hrefValue && hrefValue !== '#' && isValidURL) {
                url = getYouTubeId(hrefValue);
                if (url) {
                    isYoutube = true;
                } else {
                    isYoutube = false;
                }
            }
            if (hyperlinkCip) {
                return (
                    <>
                        <Grid container>
                            <Grid item xs={12} className={classes.textInputLinkCont}>
                                <TextField
                                    style={{/* width: `${componentProps.props.hyperlinkDetails.hyperlink[keyIndex].length * 8}px` */ }}
                                    className={classes.textInputLinkWidth}
                                    size="small"
                                    margin="dense"
                                    value={`\u00A0 \u00A0 ${pagehyperlinkCip
                                        ? (pageLinkRef?.[0] ? pageLinkRef?.[0] : '')
                                        : (hrefValue && hrefValue !== '#' ? hrefValue : '')}`}
                                    onChange={null}
                                    variant="standard"
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Grid container style={{ height: '100vh' }}>
                            <Grid item md={12}>
                                {!pagehyperlinkCip && !isYoutube && hrefValue && hrefValue !== '#' && isValidURL ? (
                                    <iframe src={hrefValue} width="100%" height="100%" frameBorder="0" allowFullScreen title="link modal" />
                                ) : null}
                                {videoHyperlinkCip && isYoutube && getIframeHyperlink({ url: isYoutube ? url : hrefValue, is_youtube: true, expandHeight: true })}
                                {(pagehyperlinkCip && pageLinkRef?.[0] && pageLinkRef[0] !== '#' && isValidURLArr?.[0])
                                    ? (<iframe src={pageLinkRef?.[0]} width="100%" height="100%" frameBorder="0" allowFullScreen title="link modal" />)
                                    : null }
                            </Grid>
                        </Grid>
                    </>
                );
            }

            if (contentDisplayCip) {
                if (contentDisplayLoading) {
                    return (<h1>Loading...</h1>);
                }
                if (contentDisplayError) {
                    return (<h1>Error...</h1>);
                }

                if (contentDisplayImageList?.length < 1 || contentDisplayImageList === false) {
                    return (
                        <div className={classes.noContentDisplay}>
                            <Card className={classes.contentDisplayImgCont}>
                                <CardContent className={classes.titleCaptionHolder}>
                                    <TextField className={classes.imgTitleInput} variant="standard" placeholder="Slide Title" disabled />
                                    <TextField className={classes.imgTitleInput} variant="standard" placeholder="Image Title" disabled />
                                </CardContent>
                                <CardMedia>
                                    <ImagePlaceholder classes={imgClasses} iconColor="primary" />
                                </CardMedia>
                                <CardContent className={classes.titleCaptionHolder}>
                                    <TextField className={classes.imgTitleInput} variant="standard" placeholder="Image Caption" disabled />
                                    <TextField className={classes.imgTitleInput} variant="standard" placeholder="Slide Caption" disabled />
                                </CardContent>
                            </Card>
                        </div>
                    );
                }

                return (
                    <ErrorBoundary messgae="swiper.js can't be imported.">
                        <Suspense fallback={<Typography>loading carousel....</Typography>}>
                            <div>
                                <CarouselSwiper
                                    fullHeightNav
                                    variant="dots"
                                    isCD
                                    contentDisplayImageList={contentDisplayImageList}
                                    contentDisplayData={contentDisplayData}
                                    contentData={cdImageList}
                                    inWorkshop
                                />
                            </div>
                        </Suspense>
                    </ErrorBoundary>
                );
            }

            return (
                <>
                    {workshopComponent && !workshopUseLibrary && (
                        <Container
                            props={{ components: hoverFlagWorkshop ? [hoverWorkshopComp] : [workshopComponent], isWorkshop: true }}
                        />
                    )}
                    {workshopUseLibrary && (
                        <div style={{ textAlign: 'center', background: 'cornflowerblue' }}>
                            <Typography variant="body1">Select template from Template Library </Typography>
                        </div>
                    )}
                </>
            );
        };

        const workShopJsx = editTimelineColumn ? (null) : (
            <Grid item xs>
                <Paper elevation={3} className={classes.workshop}>
                    <div
                        className={isProgression ? classes.workshopHeaderProgression : (editTimeline
                            ? classes.workshopHeaderY : timeLineVisible ? classes.workshopHeader : classes.workshopHeaderX)}
                    >
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography
                                    className={classes.columHeadType}
                                    color="inherit"
                                >
                                    {contentDisplayCip ? 'Content Display' : 'Workshop'}
                                </Typography>
                                <b className={classes.settingsSubHeader}>
                                    {componentLabel(workshopComponent)}
                                </b>
                            </Grid>
                        </Grid>
                    </div>
                    <div
                        style={{ paddingTop: padding }}
                        className={isProgression ? (timeLineVisible
                            ? classes.selectTemplateMainContRemov : classes.selectTemplateMainContRemovHIde) : classes.selectTemplateMainCont}
                    >
                        {renderWorkshopSetting()}
                    </div>
                    {activeStep === STEPS.ACCESS_CONTROL.index && (
                        <div
                            className={classes.disabledWorkshop}
                            onClick={handleDisableClick}
                            onContextMenu={handleDisableClick}
                            aria-hidden="true"
                        />
                    )}
                    <Snackbar
                        open={openError}
                        autoHideDuration={5000}
                        onClose={handleCloseToast}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <Alert severity="error">
                            Please go to protocol settings on step 2 to access workshop
                        </Alert>
                    </Snackbar>
                </Paper>
            </Grid>
        );

        const renderLinksGroup = (classes, textFieldRef, link, index) => (
            <>
                <Grid item xs={3} className={classes.detailContLeft}>
                    {`URL ${index + 1}`}
                </Grid>
                <Grid item xs={9} className={classes.detailContRight} style={{ paddingTop: '2px' }}>
                    <TextField
                        ref={textFieldRef}
                        className={classes.textInputWidth}
                        size="small"
                        onChange={(event) => textFieldChnageHandler(event.target.value, index)}
                        variant="standard"
                        value={pageLinkRef[index]}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {pageLinkRef[index] && (
                                        <IconButton style={{ cursor: 'pointer' }} edge="end" aria-label="delete" onClick={() => clearLinkValue(index)}>
                                            <ClearIcon />
                                        </IconButton>
                                    )}
                                    {pageLinkRef[index] && !pageLinkRef.includes('') && (pageLinkRef.length - 1 === index) && (pageLinkRef.length < 5) && (
                                        <IconButton style={{ cursor: 'pointer' }} edge="end" aria-label="add" onClick={addNewLinkField}>
                                            <AddBoxIcon style={{ fill: '#066785' }} />
                                        </IconButton>
                                    )}
                                    {(index > 0 || pageLinkRef.length > 1) && (
                                        <IconButton style={{ cursor: 'pointer' }} edge="end" aria-label="add" onClick={() => deleteLinkField(index)}>
                                            <DeleteIcon style={{ fill: 'red' }} />
                                        </IconButton>
                                    )}
                                </InputAdornment>
                            ),
                        }}
                        autoFocus
                    />
                    {!isValidURLArr[index] && <small className={classes.showError}>Invalid URL</small>}
                </Grid>
            </>
        );

        const settingsJsx = (
            <div className={isProgression ? classes.settingsTabHeightRemov : `${classes.workshop} ${classes.settingsTabHeight}`}>
                <div
                    className={isProgression ? classes.settingsHeaderProgression : (editTimeline
                        ? classes.settingsHeaderZ : editTimelineColumn
                            ? classes.settingsHeaderY : timeLineVisible
                                ? classes.settingsHeader : classes.settingsHeaderX)}
                >
                    {hyperlinkCip && (loggedUserType === 'Super Admin' || loggedUserType === 'Doctor') ? (
                        <Typography className={classes.columHeadType} color="inherit" style={{ marginLeft: '43%' }}>Settings</Typography>
                    ) : null}
                </div>
                {hyperlinkCip && (loggedUserType === 'Super Admin' || loggedUserType === 'Doctor') ? (
                    <div style={{ marginTop: '60px' }}>
                        <Grid
                            container
                            alignItems="center"
                            className={classes.columnContMain}
                        >
                            <Grid item xs={3} className={classes.detailContLeft}>
                                Text
                            </Grid>
                            <Grid item xs={9} className={classes.detailContRight}>
                                {hyperlinkCipText}
                            </Grid>
                            {pagehyperlinkCip && pageLinkRef.map((link, ind) => (<>{renderLinksGroup(classes, textFieldRef, link, ind)}</>))}
                            {hyperlinkCip && !pagehyperlinkCip && (
                                <>
                                    <Grid item xs={3} className={classes.detailContLeft}>
                                        URL
                                    </Grid>
                                    <Grid item xs={9} className={classes.detailContRight} style={{ paddingTop: '2px' }}>
                                        <TextField
                                            ref={textFieldRef}
                                            className={classes.textInputWidth}
                                            size="small"
                                            onChange={(event) => textFieldChnageHandler(event.target.value)}
                                            variant="standard"
                                            InputProps={hrefValue && {
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton style={{ cursor: 'pointer' }} edge="end" aria-label="delete" onClick={clearLinkValue}>
                                                            <ClearIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            autoFocus
                                        />
                                        {!isValidURL && <small className={classes.showError}>Invalid URL</small>}
                                    </Grid>
                                </>
                            )}
                            {pagehyperlinkCip
                                ? (
                                    <>
                                        <Grid item xs={3} className={classes.detailContLeft}>
                                            Open Page Hyperlink
                                        </Grid>
                                        <Grid item xs={9} className={classes.pagehyperlinkRadioCont}>
                                            <RadioGroup
                                                defaultValue={component?.props?.hasOwnProperty('openHyperlinkType')
                                                    ? component.props.openHyperlinkType : 'inApp'}
                                                name="openHyperlinkType"
                                                onChange={(e) => handleHyperlinkType(e)}
                                            >
                                                <FormControlLabel value="newTab" control={<Radio small />} label="New Tab" />
                                                <FormControlLabel value="inApp" control={<Radio small />} label="Content Display" />
                                            </RadioGroup>
                                        </Grid>
                                    </>
                                )
                                : null}
                        </Grid>
                        <div id="hyperlink-innerHTML" style={{ display: 'none' }} />
                    </div>
                ) : (
                    <TemplateLibrary
                        setDirty={setDirty}
                        show={workshopUseLibrary}
                        library={filteredLibrary}
                        workshopLibraryFilter={workshopLibraryFilter}
                        onPreview={onTemplatePreview}
                        onCommitTemplate={onCommitTemplate}
                        updateSelectedColumn={updateSelectedColumn}
                        showSettingsLabel={false}
                        showLoader={showLoader}
                        setHoverFlagWorkshop={setHoverFlagWorkshop}
                    />
                )}
            </div>
        );

        return (
            <WorkshopContextProvider>
                {isProgression && workShopJsx && settingsJsx ? (
                    <Split
                        gutterSize={4}
                        minSize={[250, 250]}
                        direction="horizontal"
                        cursor="col-resize"
                        style={{
                            display: 'flex', flexDirection: 'row', height: '100%', width: '100%',
                        }}
                        className="split-flex"
                    >
                        {workShopJsx}
                        <Grid>
                            {settingsJsx}
                        </Grid>
                    </Split>
                ) : (
                    (editTimelineColumn || editTimeline)
                        ? (
                            <Split
                                gutterSize={4}
                                minSize={[300, 300]}
                                direction="horizontal"
                                cursor="col-resize"
                                style={{
                                    display: 'flex', flexDirection: 'row', height: '100%', width: '100%',
                                }}
                                className="split-flex"
                            >
                                {workShopJsx}
                                <div>
                                    {settingsJsx}
                                </div>
                            </Split>
                        )
                        : (
                            <>
                                {workShopJsx}
                                <Grid
                                    item
                                    xs={mediaWidth}
                                    className={classes.workshopWrapper}
                                >
                                    {settingsJsx}
                                </Grid>
                            </>
                        )
                )}
            </WorkshopContextProvider>
        );
    };

    let parentGridStyle = {};
    const progressionCIP = (((selectedTimeline.columns.length) * 300) > window.innerWidth)
        ? ((selectedTimeline.columns.length) * 300) : window.innerWidth;
    parentGridStyle = isProgression ? { width: `${progressionCIP}px` } : { width: `${selectedTimeline.props.width}px` };

    const getWorkshopView = () => {
        const componentAccessView = workshopAccessView[selectedComponent?.id] || accessViewInitialValue;
        const nothingSelectedPOI = componentPOI && Object.keys(componentPOI).every((child) => !componentPOI[child]);
        const nothingSelectedAC = componentAccessView && Object.keys(componentAccessView).every((child) => !componentAccessView[child]);
        const notCheckPOI = workshopComponent.id === selectedTimeline.id;
        const isnextCol = checkNextColumn(selectedTimeline.columns, selectedColumn);
        const ispreviousCol = checkPreviousColumn(selectedTimeline.columns, selectedColumn);
        let workshopView = (
            <>
                {workshopLoading && showLoader(true)}
                <div className={classes.protocolView} style={{ display: workshopLoading ? 'none' : 'block' }}>
                    <Grid container>
                        {!editTimeline && !editTimelineColumn && (
                            <Grid
                                item
                                xs={editTimelineColumn ? 6 : 3}
                                className={classes.protocolColumn}
                                style={{ display: timeLineVisible ? 'block' : 'none' }}
                            >
                                {getProgressionColumn({ columns: [selectedTimeline.columns[selectedColumn]] })}
                            </Grid>
                        )}
                        {
                            !editTimelineColumn
                            && (
                                <Grid container item xs={editTimeline ? 12 : editTimelineColumn ? 6 : timeLineVisible ? 9 : 12}>
                                    {GetWorkshopAndSetting()}
                                </Grid>
                            )
                        }
                        {
                            editTimelineColumn
                            && (
                                <Split
                                    gutterSize={4}
                                    minSize={[300, 300]}
                                    direction="horizontal"
                                    cursor="col-resize"
                                    style={{
                                        display: 'flex', flexDirection: 'row', height: '100%', width: '100%',
                                    }}
                                    className="split-flex"
                                >
                                    <div>
                                        {getProgressionColumn({ columns: [selectedTimeline.columns[selectedColumn]] })}
                                    </div>
                                    <div>
                                        {GetWorkshopAndSetting()}
                                    </div>
                                </Split>
                            )
                        }

                        <Grid
                            item
                            xs={12}
                            className={editTimeline ? classes.mainButtonGroupY : editTimelineColumn
                                ? classes.mainButtonGroupX : timeLineVisible ? classes.mainButtonGroup : classes.mainButtonGroupProgressionX}
                        >
                            <div
                                className={(workshopComponent.type === 'Protocol' && isTemplateSelected && !hyperlinkCip)
                                    ? classes.buttonGroupProtocal1 : classes.buttonGroupProtocal}
                            >
                                {(nothingSelectedPOI || nothingSelectedAC) && dirty && !notCheckPOI && (
                                    <Alert severity="error">
                                        Please select at least one
                                        {' '}
                                        {nothingSelectedPOI ? 'POI' : 'User Access'}
                                        .
                                    </Alert>
                                )}
                                {(nothingSelectedAccessControl) && (
                                    <Alert severity="error">
                                        Please select at least one User Access.
                                    </Alert>
                                )}
                                {cFWithoutComp ? (
                                    <Alert severity="error">
                                        Protocol title and atleast one form component are mandatory.
                                    </Alert>
                                ) : null}
                                <Box>
                                    <Button
                                        variant="contained"
                                        className={workshopComponent.type === 'Protocol'
                                            && isTemplateSelected && !hyperlinkCip && classes.cancelButton}
                                        onClick={cancel}
                                    >
                                        Cancel
                                    </Button>
                                    {((actionRelation?.relation !== 'insert-content-display')
                                        && (actionRelation?.relation !== 'edit-content-display'))
                                        ? getSaveAsTemplateBtn() : null}
                                </Box>
                                <Box>
                                    {isProgression && ispreviousCol && (
                                        <Button
                                            variant="contained"
                                            className={classes.butonStyle}
                                            onClick={previous}
                                        >
                                            Previous Column
                                        </Button>
                                    )}
                                    {activeStep !== STEPS.SELECT_TEMPLATE.index && (
                                        <Button variant="contained" color="primary" onClick={checkForMandatoryFields}>
                                            Save & Close
                                        </Button>
                                    )}
                                    {hyperlinkCip && (
                                        <Button variant="contained" color="primary" onClick={save}>
                                            Save & Close
                                        </Button>
                                    )}
                                    {isProgression && isnextCol && (
                                        <Button
                                            variant="contained"
                                            className={classes.butonStyle}
                                            onClick={next}
                                        >
                                            Next Column
                                        </Button>
                                    )}
                                </Box>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </>
        );

        if (isProgression) {
            workshopView = (
                <>
                    {workshopLoading && showLoader(true)}
                    <div
                        ref={bodyRef}
                        style={{ width: '100%', overflow: 'scroll', display: workshopLoading ? 'none' : 'block' }}
                        className={`${classes.progressionColumn} ${classes.myNavbarCollapse} ${timeLineVisible ? classes.show : ''} `}
                    >
                        <Grid container style={parentGridStyle}>
                            {getProgressionColumn(selectedTimeline)}
                        </Grid>
                    </div>

                    {!timeLineVisible
                        && (
                            <Tooltip
                                classes={{ tooltip: classes.tooltipCont }}
                                title={timeLineVisible ? 'Hide Timeline' : 'Show Timeline'}
                                arrow
                                placement="right"
                            >
                                <div className={classes.timeLineSpace}>
                                    <IconButton color="primary" onClick={handleTimeLineVisibility} className={classes.timeLineIcon1}>
                                        {timeLineVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                    </IconButton>
                                </div>
                            </Tooltip>
                        )}

                    <div
                        className={classes.smallSectionHeight}
                        style={{
                            width: '100%',
                            boxShadow: '5px -10px 12px #ccc',
                            marginTop: timeLineVisible && '0px',
                            marginBottom: '0px',
                            display: workshopLoading ? 'none' : 'block',
                        }}
                    >
                        <Grid container>{GetWorkshopAndSetting()}</Grid>
                        <Grid item xs={12} className={classes.mainButtonGroupProgression}>
                            <div className={classes.buttonGroup}>
                                <Box>
                                    <Button variant="contained" onClick={cancel}>Cancel</Button>
                                    {((actionRelation?.relation !== 'insert-content-display')
                                        && (actionRelation?.relation !== 'edit-content-display'))
                                        ? getSaveAsTemplateBtn() : null}
                                </Box>
                                {(nothingSelectedPOI || nothingSelectedAC) && dirty && (
                                    <Alert severity="error">
                                        Please select at least one
                                        {' '}
                                        {nothingSelectedPOI ? 'POI' : 'User Access'}
                                        .
                                    </Alert>
                                )}
                                <Box>

                                    {isProgression && ispreviousCol && (
                                        <Button
                                            variant="contained"
                                            className={classes.butonStyle}
                                            onClick={previous}
                                        >
                                            Previous Column
                                        </Button>
                                    )}
                                    {activeStep !== STEPS.SELECT_TEMPLATE.index && (
                                        <Button variant="contained" color="primary" onClick={save}>
                                            Save & Close
                                        </Button>
                                    )}
                                    {hyperlinkCip && (
                                        <Button variant="contained" color="primary" onClick={save}>
                                            Save & Close
                                        </Button>
                                    )}
                                    {!hyperlinkCip && isProgression && isnextCol && (
                                        <Button variant="contained" className={classes.butonStyle} onClick={next}>Next Column</Button>
                                    )}
                                </Box>
                            </div>
                        </Grid>
                    </div>
                </>
            );
        }

        return workshopView;
    };

    return (
        <div>
            {getWorkshopView()}
            <TemplateDialog
                open={dialogOpen}
                setOpen={setDialogOpen}
                type="prompt"
                payload={templatePayLoad}
                setPayload={onDialogSave}
            />
            <TemplateDialog
                open={overRideDialogOpen}
                setOpen={setOverRideDialogOpen}
                type="override"
                payload={templatePayLoad}
                setPayload={overRideTemplate}
            />
        </div>
    );
};

const AccessControlDialog = ({
    open, onClose, components,
}) => {
    const {
        workshopAccessView,
        setWorkshopAccessView,
        workshopAccessData,
        selectedTimeline,
        selectedColumn,
        isAccessControl,
        setAccessControl,
    } = useContext(TimelineContext);
    const [dialogAccessView] = useState(workshopAccessView);
    const nothingSelected = Object.keys(workshopAccessView).length > 0
    && Object.keys(workshopAccessView).every((child) => !workshopAccessView[child]);
    const classes = useStyles();
    // eslint-disable-next-line no-unused-vars
    const [_, setComponentsCopy] = useState();

    useEffect(() => {
        setWorkshopAccessView(workshopAccessData);
    }, [workshopAccessData]);

    const handleClose = ({ cancel }) => {
        if (nothingSelected && !cancel) {
            return;
        }
        onClose(cancel ? { cancel } : dialogAccessView);
    };

    /**
    * Handle Access Control popup isAccessControlOpen
    * @param {boolean} status [if modal  open status is `true` otherwise `false`]
    */
    const handleModalStatus = () => {
        setAccessControl(!isAccessControl);
    };

    useEffect(() => {
        setComponentsCopy(JSON.parse(JSON.stringify(components)));
    }, [components]);

    return (
        <Dialog open={open} classes={{ paperWidthSm: classes.widthSectPart }} onClose={handleClose}>
            <DialogTitle className={classes.poupConHeading}>
                <Grid container>
                    <Grid item xs={10}>
                        <Typography variant="h6">Timeline User Access</Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.rightTextAlign}>
                        <CloseIcon className={classes.closeIconStyle} onClick={() => handleClose({ cancel: true })} />
                    </Grid>
                </Grid>
            </DialogTitle>
            <Divider />
            <DialogContent className={classes.poupConHeadingMeddle}>
                <Grid container>
                    <Grid item xs={12} className={classes.researchTextCont1}>
                        <Typography variant="h4">{selectedTimeline?.columns?.[selectedColumn]?.props?.title}</Typography>
                    </Grid>
                </Grid>
                <AccessControl handleClose={handleModalStatus} column={selectedTimeline?.columns?.[selectedColumn]} isSettings />
            </DialogContent>
            <Divider />
            <DialogActions className={classes.poupConBoottom}>
                <Button variant="contained" onClick={() => handleClose({ cancel: true })}>Cancel</Button>
                <Button onClick={handleClose} className={classes.saveUpButton}>Save</Button>
                {nothingSelected && (
                    <Alert severity="error">
                        Please select at least one User Access!
                    </Alert>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default Workshop;
export { SettingsContainer };
