import { ThemeProvider as MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import React, { Component } from 'react';
// our App
import App from './App';

export default class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nightMode: false,
        };
    }

    updateTheme = (mode) => {
        this.setState({
            nightMode: mode,
        });
    };

    createTempTheme() {
        const { nightMode } = this.state;
        return createMuiTheme({
            palette: {
                type: nightMode ? 'dark' : 'light',
            },
            typography: {
                useNextVariants: true,
            },
        });
    }

    renderApp = () => {
        const { nightMode } = this.state;
        return (
            <App {...this.props} updateTheme={this.updateTheme} nightMode={nightMode} />
        );
    }

    render() {
        return (
            <MuiThemeProvider theme={this.createTempTheme()}>
                <CssBaseline />
                {this.renderApp()}
            </MuiThemeProvider>
        );
    }
}
