import { Container, ContainerPreview } from './Container';
import React from 'react';

const DefaultChildren = ({
    indent = false, components, dmColumViewType = null, selectedDropdownValue = null, id,
}) => {
    const childStyle = {
        paddingLeft: indent ? '2em' : '0em',
    };
    return (
        <React.Fragment key="key">
            {components && (
                <div style={childStyle}>
                    <Container props={{
                        components, dmColumViewType, selectedDropdownValue, isTimlineCol: true, id,
                    }}
                    />
                </div>
            )}
        </React.Fragment>
    );
};

const DefaultChildrenPreview = ({ indent = false, components }) => {
    const childStyle = {
        paddingLeft: indent ? '2em' : '0em',
    };
    return (
        <React.Fragment key="key">
            {components && <div style={childStyle}><ContainerPreview props={{ components }} /></div>}
        </React.Fragment>
    );
};

export { DefaultChildren, DefaultChildrenPreview };
