import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Paper,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { standardHeaders } from './utils/StandardStyles';
import ChevronRight from '@material-ui/icons/ChevronRight';
import React from 'react';

const useStyles = makeStyles(() => ({
    accordionStyle: {
        margin: '0px !important',
        boxShadow: 'none',
    },
    accordionIconStyle: {
        background: '#000000',
        color: '#FFFFFF',
        transform: 'rotate( -90deg )',
    },
    iconButton: {
        margin: '0 8px 0 2px',
        padding: 0,
        background: ({ level, itemIcon }) => (level === 0 && !itemIcon ? '#bbb' : 'transparent'),
        color: '#000000',
        borderRadius: 2,
        '&:hover': {
            background: ({ level, itemIcon }) => (level === 0 && !itemIcon ? '#bbb' : 'transparent'),
        },
        transform: 'rotate( 90deg )',
    },
    accordionSummaryText: {
        justifyContent: 'center',
    },
    timelineAccordionSummary: {
        background: '#1C1C1C',
        border: '1px solid #6CA1FF1D',
        color: '#FFFFFF',
        marginBottom: 1,
    },
    sectionHeader: {
        margin: 0,
        minHeight: 'auto !important',
        height: 32,
        border: '1px solid #ececec',
        ...standardHeaders.sectionHeader,
        position: 'relative',
        flexDirection: 'row-reverse',
    },
    timelineAccordionDetail: {
        display: 'block',
        padding: '0px',
        background: '#FFFFFF',
    },
    accDetailChild: {
        margin: '.125rem .125rem .125rem 1.25rem',
        border: '1px solid #CBCBCB',
        padding: 4,
        fontSize: 12,
    },
}));

const groupBy = (key, acc, value, additionalData = {}) => {
    acc[key] = acc[key] || { items: [] };
    acc[key] = { ...acc[key], ...additionalData };
    acc[key].items = [...acc[key].items, value];
    return acc;
};

const DataMatrixRClick = ({ templates }) => {
    const classes = useStyles();

    const templateData = templates.reduce((superGroup, superGroupVal) => {
        superGroup = groupBy(superGroupVal?.supergroup, superGroup, superGroupVal, {
            type: 'supergroup',
        });
        return superGroup;
    }, {});

    return (
        <div>
            {(Object.keys(templateData).map((superGroupKey) => {
                const superGroup = superGroupKey && templateData[superGroupKey];
                if (superGroupKey === 'Row' || superGroupKey === 'Section') {
                    return null;
                }

                return (
                    <>
                        <Accordion className={classes.accordionStyle} defaultExpanded>
                            <AccordionSummary
                                expandIcon={<ChevronRight className={classes.accordionIconStyle} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className={`${classes.sectionHeader} ${classes.timelineAccordionSummary}`}
                                classes={{
                                    expandIcon: classes.iconButton,
                                    content: classes.accordionSummaryText,
                                }}
                            >
                                <Typography className={classes.heading}>{superGroupKey}</Typography>
                            </AccordionSummary>
                            <AccordionDetails className={classes.timelineAccordionDetail}>
                                {superGroup.items.map((sg) => (<Paper elevation={0} classes={{ root: classes.accDetailChild }}>{sg.name}</Paper>))}
                            </AccordionDetails>
                        </Accordion>
                    </>
                );
            }))}
        </div>
    );
};
export default DataMatrixRClick;
