/* eslint-disable no-nested-ternary */
import { TextField } from '@material-ui/core';
import {
    TimelineContext,
} from '../context';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import React, { useContext, useEffect, useState } from 'react';

const useStyles = makeStyles(() => ({
    textInputWidth: {
        width: '90%',
        marginTop: 0,
        height: 40,
    },
    formControl: {
        minWidth: '90%',
    },
    selectTimeline: {
        height: 40,
    },
    selectStyle: {
        marginTop: '30px',
    },
    border: {
        borderTop: '1px outset',
    },
    saveBtn: {
        background: '#066785',
    },
    textInput: {
        borderBottom: '0.5px outset',
    },
}));

export default function TemplateDialog(props) {
    const classes = useStyles();
    const {
        templateLibraryByType,
    } = useContext(TimelineContext);
    const filteredLibrary = templateLibraryByType
        && templateLibraryByType.Protocol
        && templateLibraryByType.Protocol.concat(templateLibraryByType.Text, templateLibraryByType.Column);
    const {
        payload, type, open, inTemplateLibrary = false,
    } = props;
    const name = payload?.name;
    const [value, setValue] = useState(name);
    const [group, setGroup] = useState(payload?.group);
    const [superGroup, setSuperGroup] = useState(payload?.supergroup);
    const [category, setCategory] = useState(payload?.category);
    const [subCategory, setSubCategory] = useState(payload?.subCategory);
    const [error, setError] = useState(!value ? 'Template Name can not be empty!' : null);
    // eslint-disable-next-line no-unused-vars
    const [groupError, setGroupError] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [categoryError, setCategoryError] = useState(false);
    let allGroup = [];
    const allSuperGroup = [];
    let allCategory = [];
    let allSubCategory = [];

    if (filteredLibrary) {
        filteredLibrary.forEach((data) => {
            if (!allGroup.includes(data?.group) && superGroup === data?.supergroup && data?.group) {
                allGroup.push(data?.group);
            }
            if (!allSuperGroup.includes(data?.supergroup) && data?.supergroup) {
                allSuperGroup.push(data?.supergroup);
            }
            if (!allCategory.includes(data?.category) && group === data?.group && data?.category) {
                allCategory.push(data?.category);
            }
            if (!allSubCategory.includes(data?.['sub_category']) && group === data?.group && category === data?.category && data?.['sub_category']) {
                allSubCategory.push(data?.['sub_category']);
            }
        });
    }

    useEffect(() => {
        if (payload) {
            setSuperGroup(payload?.supergroup);
            setGroup(payload?.group);
            setCategory(payload?.category || 'Super components');
            setSubCategory(payload?.subCategory);
        }
    }, [payload]);

    useEffect(() => {
        setValue(name);
        setError(!name ? 'Template Name can not be empty!' : null);
    }, [name]);

    const onChange = (e) => {
        const newValue = e.target.value;
        setValue(newValue);
        if (newValue.trim() === '') {
            setError('Template Name can not be empty!');
        } else {
            setError(null);
        }
    };

    const onSubmit = () => {
        if (type === 'override') {
            props.setPayload(payload);
            props.setOpen(false);
            return;
        }
        if (!group) {
            setGroupError(true);
            return;
        }
        if (!category) {
            setCategoryError(true);
            return;
        }
        if (!error && payload) {
            payload.group = group;
            payload.supergroup = superGroup;
            payload.category = category;
            payload.subCategory = subCategory;
            payload.name = value;
            props.setPayload(payload);
            props.setOpen(false);
        }
        toast.success('updated template successfully');
    };

    const handleClose = () => {
        props.setOpen(false);
    };

    // eslint-disable-next-line no-unused-vars
    const getOption = (array) => (
        array && array.map((data) => (
            <MenuItem value={data}>{data}</MenuItem>
        ))
    );

    // eslint-disable-next-line no-unused-vars
    const onSelectionChange = (event) => {
        switch (event.target.name) {
        case 'category':
            setCategory(event.target.value);
            setCategoryError(false);
            break;
        case 'subCategory':
            setSubCategory(event.target.value);
            break;
        case 'group':
            setGroup(event.target.value);
            setGroupError(false);
            break;
        case 'supergroup':
            setSuperGroup(event.target.value);
            setGroup(null);
            setCategory(null);
            setSubCategory(null);
            allGroup = [];
            allCategory = [];
            allSubCategory = [];
            if (filteredLibrary) {
                filteredLibrary.forEach((data) => {
                    if (superGroup === data?.supergroup && data?.group) {
                        allGroup.push(data.group);
                    }
                    if (group === data?.group && data?.category) {
                        allCategory.push(data.category);
                    }

                    if (group === data.group && category === data.category && data.sub_category) {
                        allSubCategory.push(data.sub_category);
                    }
                });
            }
            break;
        default: break;
        }
    };

    const getFields = () => (
        <div style={{ marginTop: 8 }}>
            <Grid item md={12}>
                <TextField
                    id="outlined-basic"
                    className={classes.textInputWidth}
                    size="small"
                    margin="dense"
                    label="Name"
                    value={value}
                    onChange={onChange}
                    variant="outlined"
                    disabled={payload?.type === 'Protocol' && name}
                />
            </Grid>
        </div>
    );

    return (
        <div>
            <Dialog
                fullWidth
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle className={classes.textInput} id="alert-dialog-title">
                    {inTemplateLibrary ? 'Template Configuration'
                        : (type === 'prompt' ? 'Save Template' : 'A template already exists with this name')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {type === 'prompt'
                            ? getFields()
                            : 'Are you sure you want to override this data?'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.border}>
                    <Button onClick={handleClose} variant="contained">
                        Cancel
                    </Button>
                    <Button
                        disabled={type !== 'override' && error}
                        onClick={onSubmit}
                        variant="contained"
                        color="primary"
                        className={classes.saveBtn}
                    >
                        {type === 'override' ? 'Override' : 'save'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
