import {
    ProcedureLibrary,
    componentLibrary,
    componentLibraryMap,
    templateLibraryMap,
} from '../initialConfig';
import {
    addCells,
    addTLSubChildrenMenu,
    arrayToObject,
    buildSettingsTreePage,
    checkEmptyObject,
    findCompParentTL,
    findComponent,
    // findOptionCellMapping,
    // findPComponent,
    findPage,
    findParent,
    findProtocolComp,
    findTimelineColumnWidth,
    getFormattedDate,
    getRoutePath,
    getRouteValidation,
    inheritComponentFunction,
} from '../helper/commonHelper';
import {
    copyTemplate,
    copyTemplateForPage,
    copyTemplateLR,
    copyTemplateWithAssociatedCell,
    copyTemplateWithImg,
    getUniqueUUID,
} from './util';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';
import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';

/* eslint-disable no-case-declarations */
import { ComponentEditorMenu } from '../components/index';
import { DefaultEditMenu, MenuDefaults } from '../components/utils/EditorMenu';
import { OrgContext } from '.';
import { componentWithId } from '../components/Atoms';
// import EmptyProtocolDialog from '../components/EmptyProtocolDialog';
import { DELETE_CONTENT_DISPLAY } from '../Workshop';
import { GET_PHONE_CALLS } from '../components/ringCentral';
import { TextAreaProps, TextBoxProps } from '../components/forms/checkbox';
import { showLoader } from '../App';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import TemplateDialog from '../components/TemplateDialog';
import gql from 'graphql-tag';

export const GET_TIMELINES = gql`
query timelineList($orgID: uuid, $id: uuid) {
    timeline(order_by: { created_at: desc }, where: { organization_id: { _eq: $orgID }, id: {_eq: $id} }) {
      id
      name
      props
      columns
      category
      sub_category
      industries
      sub_specialities
      poi
      organization_id
      created_at
      user_id
    }
  }  
`;

export const GET_TIMELINES_SUPER_ADMIN_SHARED = gql`
query timelineListM( $parent_reference_id: uuid,) {
    timeline(where: { parent_reference_id: { _eq: $parent_reference_id } }) {
      id
      name
      props
      columns
      category
      sub_category
      industries
      sub_specialities
      poi
      organization_id
      parent_reference_id
      created_at
      user_id
    }
  }  
`;

export const GET_TEMPLATE_TIMELINES = gql`
query timelineList($orgIDs: [uuid!]) {
    timeline(order_by: { created_at: desc }, where: { organization_id: { _in: $orgIDs } }) {
      id
      name
      props
      columns
      category
      sub_category
      industries
      sub_specialities
      organization_id
      created_at
    }
  }  
`;

export const GET_SPECIALITY = gql`
query {
    industry_speciality {
      id
      name
      category
      speciality_parent
    }
  }  
`;

export const GET_SUPER_TIMELINE = gql`
query timelineList($category: String) {
    timeline(where: { category: { _eq: $category } }) {
      id
      name
      props
      columns
      category
      sub_category
      industries
      sub_specialities
      poi
      organization_id
      created_at
    }
  }  
`;

export const GET_ORGANIZATION_BY_ID = gql`
query orgList($orgID: uuid!) {
    organization_by_pk(id: $orgID) {
      orgCode
    }
  }  
`;

export const GET_TIMELINE_BY_ID = gql`
query timelineList($timelineID: uuid!) {
    timeline_by_pk(id: $timelineID ) {
        organization_id,
        name
    }
  }
`;

export const GET_TIMELINES_BY_USER = gql`
query timelineByUser($user_id: uuid) {
    user_timeline_association(where: { user_id: { _eq: $user_id } }) {
      timeline_id
      timeline {
        id
        name
        props
        columns
        category
        sub_category
        industries
        sub_specialities
        organization_id
        created_at
      }
    }
  }  
`;

export const GET_TIMELINES_ASSOC = gql`
    query  {
        user_timeline_association {
            timeline_id
            user_id
            oraganisation_id
        }
    }  
`;

export const GET_TIMELINES_BY_ORG_ID = gql`
query timelineList($orgID: uuid) {
    user_timeline_association(where: { oraganisation_id : { _eq: $orgID } }) {
      timeline_id
      user_id
    }
  }  
`;

export const ADD_TIMELINE = gql`
mutation AddTimeline( $name:String, $orgID:uuid, 
    $columns:jsonb, $props:jsonb, $category: String, 
    $poi: String, $speciality: String, $industry: String, $parentRefID:uuid, $userId:uuid) {
    insert_timeline_one(object: {name: $name, organization_id: $orgID, 
        columns: $columns, props: $props, category: $category, 
        poi:$poi, sub_specialities :$speciality, industries :$industry, parent_reference_id :$parentRefID, user_id :$userId }) {
        id
        name
        props
        columns
        poi
    }
  }
`;

const DELETE_TIMELINE = gql`
mutation MyMutation($id: uuid!, ) {
    delete_timeline_by_pk(id: $id) {
        id
    }
  }
`;

// const ADD_USER_TIMELINE_ASSOC = gql`
// mutation AddUserTimelineAssoc( $orgID:uuid,$user_id:uuid,$timeline_id:uuid) {
//     insert_user_timeline_association_one(object: {oraganisation_id: $orgID,user_id:$user_id,timeline_id:$timeline_id}) {
//         timeline_id
//     }
//   }
// `;

const GET_SETTINGS_ACCESS = gql`
query settingsAccess($orgID: uuid) {
    settings_access( where: { organization_id: { _eq: $orgID } }) {
        id
        setting
        type
        access
        organization_id
    }  
}`;

const UPDATE_SETTINGS_ACCESS = gql`
mutation updateSettingsAccess($setting:String,$orgID:uuid,$type:String,$access:jsonb) {
  insert_settings_access_one (
    object: {
        setting:$setting,
        type:$type,
        access:$access,
        organization_id:$orgID
        
    },
    on_conflict: {
        constraint: settings_access_type_organization_id_setting_key,
        update_columns: [access]
    }
  ) {
        id
        setting
        type
        access
        organization_id
    }
}`;

const UPDATE_TIMELINE_COLUMNS = gql`
mutation MyMutation($id: uuid,  $name:String, $props: jsonb,  $columns: jsonb) {
    update_timeline(where: {id: {_eq: $id}}, _set: {name: $name, props: $props, columns: $columns}) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

const GET_TEMPLATES = gql`
query templateList($orgID: uuid) {
  template(where: {_or: [{is_super_template: {_eq: true}},
    {organization_id:{_eq:$orgID}}]}, 
    order_by: {type: asc, group: asc, name: asc, created_at: asc}) {
    id
    name
    type
    template,
    group,
    supergroup,
    category,
    sub_category,
    parent_reference_id,
    associated_child,
    sort_index,
    is_super_template,
  }
}
`;

const ADD_TEMPLATE = gql`
mutation AddTemplate( $name:String, 
    $group:String, $supergroup:String, 
    $type: String $orgID:uuid, $template:jsonb,
     $category: String,
      $subCategory: String, $parent_reference_id:uuid) {
    insert_template_one(object: {name: $name,
        group: $group, supergroup: $supergroup,
        organization_id: $orgID, type: $type, 
        template: $template, category: $category, 
        sub_category: $subCategory,
        parent_reference_id: $parent_reference_id,}) {
        id
        name,
        type,
        parent_reference_id,
        template
    }
  }
`;

const UPDATE_TEMPLATE = gql`
mutation MyMutation($id: uuid,  
    $name:String, $template: jsonb,
    $group:String, $supergroup:String, 
    $category: String, $subCategory: String,
    $sort_index: Int,
    $associated_child:jsonb,
    $parent_reference_id:uuid) {
    update_template(where: {id: {_eq: $id}},
         _set: {name: $name, template: $template, 
            group: $group, supergroup: $supergroup, 
            category: $category, 
            sub_category: $subCategory,
            sort_index:$sort_index,
            associated_child:$associated_child, 
            parent_reference_id: $parent_reference_id}) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

const DELETE_TEMPLATE = gql`
mutation MyMutation($id: uuid!, ) {
    delete_template_by_pk(id: $id) {
        id
    }
  }
`;

const ADD_USER_TIMELINE = gql`
mutation createUser($userId: uuid, $orgId: uuid, $timeline_id: uuid) { 
    insert_user_timeline_association(objects: {user_id: $userId, oraganisation_id: $orgId, timeline_id: $timeline_id}) {
        affected_rows
    } 
}
`;

export const ADD_PATIENT_USER_TIMELINE = gql`
mutation createUser($userId: uuid, $orgId: uuid, $timeline_id: uuid, $parentTimelineId: uuid) { 
    insert_user_timeline_association(objects: {user_id: $userId, oraganisation_id: $orgId, 
        timeline_id: $timeline_id, 
        parent_timeline_id: $parentTimelineId}) {
        affected_rows
    } 
}
`;

export const ADD_TIMELINE_COLUMN = gql`
mutation timelineColumnAccess($timeline_id: uuid, $user_id: uuid, $column_access: jsonb) { 
    insert_timeline_column_access(objects: {timeline_id: $timeline_id, user_id: $user_id, column_access: $column_access}) {
        affected_rows
    } 
}
`;

export const UPDATE_TIMELINE_COLUMN = gql`
mutation timelineColumnAccess($id: uuid, $timeline_id: uuid, $user_id: uuid, $column_access: jsonb){
    update_timeline_column_access(where: { id: {_eq: $id}, 
        timeline_id: {_eq: $timeline_id}, 
        user_id: {_eq: $user_id} }, 
        _set: {column_access: $column_access} ) {
        affected_rows
        returning {
            id
        }
    }
}
`;

export const GET_TIMELINE_COLUMN = gql`
query timelineColumnAccess($timeline_id: uuid, $user_id: uuid) { 
    timeline_column_access(where: { timeline_id: {_eq: $timeline_id}, user_id: {_eq: $user_id}}) {
        id,
        column_access,
        timeline_id,
        user_id,
    } 
}
`;

export const GET_TIMELINE_COLUMN_BY_USER = gql`
query timelineColumnAccess($user_id: uuid) { 
    timeline_column_access(where: {user_id: {_eq: $user_id}}, order_by: {created_at: desc}, limit: 1) {
        id
        column_access
        timeline_id
        user_id
    }
}
`;

export const ADD_COMPONENT_AC = gql`
    mutation AddComponentAC($objects:[components_access_control_insert_input!]!){
        insert_components_access_control(
                objects:$objects,
            ){
                affected_rows
        }
        
    }
`;

// export const UpdateAccControlUserType = gql`
//     mutation update_components_access_control_user_type {
//         update_components_access_control_user_type_many (
//         updates: [
//             {
//             where: {rating: {_lte: 1}},
//             _set: {is_published: false}
//             },
//             {
//             where: {rating: {_gte: 4}},
//             _set: {is_published: true}
//             }
//         ]
//         ) {
//         affected_rows
//         }
//     }
// `;

export const UPDATE_COMPONENT_AC = gql`
mutation MyMutation($protocol_id: uuid, $timeline_id: uuid, $access_data: jsonb) {
    update_components_access_control(where: {protocol_id: {_eq: $protocol_id}, timeline_id: {_eq: $timeline_id}}, _set: {access_data: $access_data}) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const ADD_COMPONENT_POI = gql`
    mutation AddComponentPOI($objects:[components_poi_insert_input!]!){
        insert_components_poi(
                objects:$objects,
        ){
            affected_rows
        }
    }
`;

export const GET_COMPONENT_POI = gql`
    query ComponentPOI($timeline_id: uuid){
        components_poi(where: { timeline_id: {_eq: $timeline_id}}){
            id
            protocol_id
            poi_data
            column_id
            timeline_id
        }
    }
`;

export const GET_COMPONENT_AC = gql`
    query components_access_control($timeline_id: uuid){
        components_access_control(where: { timeline_id: {_eq: $timeline_id}}){
            id
            access_data
            protocol_id
            column_id
            timeline_id
            user_type
        }
    }
`;

export const GET_ALL_CONTENT_DISPLAY = gql`
    query GetContentDisplay($timeline_id: uuid){
        content_display(where: { timeline_id: {_eq: $timeline_id}}){
            image_list
            id
            protocol_id
            column_id
            timeline_id
        }
    }
`;

export const INSERT_AC_MANY = gql`
    mutation InsertManyAC($objects:[components_access_control_insert_input!]!){
        insert_components_access_control(objects:$objects){
            returning {
                id
            }
        }
    }
`;

export const INSERT_CD_MANY = gql`
    mutation InsertManyCD($objects:[content_display_insert_input!]!){
        insert_content_display(objects:$objects){
            returning {
                id
            }
        }
    }
`;

export const INSERT_POI_MANY = gql`
    mutation InsertManyPOI($objects:[components_poi_insert_input!]!){
        insert_components_poi(objects:$objects){
            returning {
                id
            }
        }
    }
`;

export const GET_COMPONENT_AC_BY_PROTOCOL = gql`
    query ComponentAC($protocol_id:uuid){
        components_access_control(
            where:{
                protocol_id:{_eq:$protocol_id},
            },
        ){
            id
            access_data
        }
    }
`;

export const DELETE_COMPONENT_UAC = gql`
mutation MyMutation($column_id:uuid,$timeline_id:uuid, $protocol_id: [uuid!]) {
    delete_components_access_control(where: {timeline_id: {_eq: $timeline_id}, column_id: {_eq: $column_id}, protocol_id: {_in: $protocol_id}, }) {
        affected_rows
    }
  }
`;

export const DELETE_COMPONENT_POI = gql`
mutation MyMutation($column_id:uuid,$timeline_id:uuid, $protocol_id: [uuid!]) {
    delete_components_poi(where: {timeline_id: {_eq: $timeline_id}, column_id: {_eq: $column_id}, protocol_id: {_in: $protocol_id}, }) {
        affected_rows
    }
  }
`;

export const UPDATE_COMPONENT_POI = gql`
    mutation UpdateComponentPOI($column_id:uuid,$poi_data:jsonb,$protocol_id:uuid,$timeline_id:uuid){
        update_components_poi(
            where:{
                _and:[
                    {timeline_id:{_eq:$timeline_id}},
                    {protocol_id:{_eq:$protocol_id}},
                    {column_id:{_eq:$column_id}}
                ]
            },
            _set:{
                poi_data:$poi_data
            }
        ){
            affected_rows
        }
    }
`;

export const GET_ALL_ORG_MEMBER = gql`
    query {
        organization_member{
            organization_id
            user_id
        }
    }
`;

export const UPDATE_USER_TYPE = gql`
  mutation UpdateUserType($protocolId: uuid!, $userType: String) {
    update_components_access_control(
      where: { protocol_id: { _eq: $protocolId } }
      _set: { user_type: $userType }
    ) {
      affected_rows
    }
  }
`;

export const INSERT_COMPONENTS_ACCESS = gql`
    mutation insertComponentsAccess($timelineId: uuid, $columnId: uuid, $protocolId: uuid, $accessData: jsonb, $userType: String) {
        insert_components_access_control(objects:{
            timeline_id: $timelineId, column_id: $columnId, protocol_id: $protocolId, access_data: $accessData, user_type:$userType} ) {
            affected_rows
            returning {
                protocol_id
            }
        }
    }
`;

/** -----------------------------------Query to work with Selected Children-------------------------- */
export const GET_SELECTED_CHILDREN = gql`
    query selected_children($timeline_id: uuid) {
        selected_children(where: { timeline_id: {_eq: $timeline_id}}) {
            id
            timeline_id
            column_id
            protocol_id
            children_data
        }
    }
`;

export const INSERT_SELECTED_CHILDREN = gql`
    mutation insertSelectedChildren($timelineId: uuid, $columnId: uuid, $protocolId: uuid, $childrenData: jsonb) {
        insert_selected_children(objects:{ timeline_id: $timelineId, column_id: $columnId, protocol_id: $protocolId, children_data: $childrenData} ) {
            affected_rows
            returning {
                id
                timeline_id
                column_id
                protocol_id
                children_data
            }
        }
    }
`;

export const UPDATE_SELECTED_CHILDREN = gql`
    mutation updateSelectedChildren($id: uuid, $childrenData: jsonb) {
        update_selected_children(where: { id: {_eq: $id} }, _set: {children_data: $childrenData} ) {
            affected_rows
            returning {
                id
                timeline_id
                column_id
                protocol_id
                children_data
            }
        }
    }
`;

// export const DELETE_SELECTED_CHILDREN = gql`
//     mutation deleteSelectedChildren($id: uuid) {
//         delete_selected_children(where: {id: {_eq: $id}}) {
//             affected_rows
//             returning {
//                 id
//             }
//         }
//     }
// `;

export const DELETE_SELECTED_CHILDREN = gql`
    mutation deleteSelectedChildren($column_id:uuid, $timeline_id:uuid, $protocol_id: [uuid!]) {
        delete_selected_children(where: {timeline_id: {_eq: $timeline_id}, column_id: {_eq: $column_id}, protocol_id: {_in: $protocol_id}, }) {
            affected_rows
            returning {
                id
                timeline_id
                column_id
                protocol_id
                children_data
            }
        }
    }
`;

const wowAction = {
    component: {
        header: 'Checkbox',
        id: 'cl-61',
        name: 'Radio Group',
        type: 'Select',
    },
    relation: 'child',
    type: 'insert',
};

const createTemplateMap = (templates) => {
    const map = {};
    const index = {};

    if (!templates) {
        return [map, index];
    }

    templates.template.forEach((template) => {
        let bucket;
        index[template.id] = template;
        if (map[template.type]) {
            bucket = map[template.type];
        } else {
            bucket = [];
            map[template.type] = bucket;
        }
        bucket.push(template);
    });
    return [map, index];
};

export const Context = createContext({});

const addColumnObj = {
    blankColumnId: 'cl-20-1-1',
    blankMixedColumnId: 'cl-20-1-2',
    timelineColumnId: 'cl-21',
    timelineLabelId: 'cl-22',
};
export const accessViewInitialValue = {
    isBasic: false,
    isAdvanced: false,
    isUserView: true,
};

const defaultData = {
    selectSuggest: 'Select and Suggest',
};

export const Provider = ({ currentOrg, children }) => {
    const [showTimelineTable, setShowTimelineTable] = useState(false);
    const [workshopLoading, setWorkshopLoading] = useState(false);
    const [contextMenuPosition, setContextMenuPosition] = useState(null);
    const [selectedTemplate, setSelectedTemplate] = useState();
    const [workshopAccessView, setWorkshopAccessView] = useState({});
    const [workshopAccessData, setWorkshopAccessData] = useState({});
    const [orgMembers, setOrgMembers] = useState({});
    const [spl, setSpl] = useState({});
    const [timelineAssoc, setTimelineAssoc] = useState({});
    const [templateLibraryCip, setTemplateLibraryCip] = useState(false);
    // const [isMultiOpen, setIsMultiOpen] = useState(false);
    // const [multiOpenComp, setMultiOpenComp] = useState(null);

    const [dirtyDomain, setDirtyDomain] = useState(false);
    const [newCellAdded, setNewCellAdded] = useState(false);
    const [selectedOptionId, setSelectedOptionId] = useState(false);
    const [compAccordion, setCompAccordion] = useState(false);
    const [selectedOptionIdsPreview, setSelectedOptionIdsPreview] = useState({});
    const [copyProtocolComponent, setCopyProtocolComponent] = useState(false);
    const [copyComponent, setCopyComponent] = useState({});
    const [componentPOI, setComponentPOI] = useState({});
    const [componentParentPOI, setComponentParentPOI] = useState({});
    const [lastDeletedComp, setLastDeletedComponent] = useState(null);
    const [lastDeletedCompIds, setLastDeletedComponentIds] = useState([]);
    const [lastDeletedOption, setLastDeletedOption] = useState(null);
    const [previewComponent, setPreviewComponent] = useState(null);
    const [contextMenuTarget, setContextMenuTarget] = useState(null);
    const [selectedColIndex, setSelectedColIndex] = useState(null);
    const [actionRelation, setActionRelation] = useState(null);
    const [selectedComponent, setSelectedComponent] = useState({});
    const [filterTemplateBrowser, setFilterTemplateBrowser] = useState(false);
    const [isLoadedAccordion, setIsLoadedAccordion] = useState(false);
    const [timeLineVisible, setTimeLineVisible] = useState(true);
    const [activeStep, setActiveStep] = useState(0);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [templatePayLoad, setTemplatePayLoad] = useState(null);
    const [overRideDialogOpen, setOverRideDialogOpen] = useState(false);
    const [existingTemplate, setExistingTemplate] = useState(null);
    const [selectedColumn, setSelectedColumn] = useState(0);
    const [selectedParent, setSelectedParent] = useState(null);
    const [rootComponent, setRootComponent] = useState(null);
    const [selectedTimeline, setSelectedTimeline] = useState(null);
    const [domain, setDomain] = useState('Medguide');
    const [workshopComponent, setWorkshopComponent] = useState(null);
    const [pageComponent, setPageComponent] = useState(null);
    const [workshopColumn, setWorkshopColumn] = useState(null);
    const [workshopColumnCopy, setWorkshopColumnCopy] = useState(null);
    const [contextMenuConfig, setContextMenuConfig] = useState(null);
    // TODO inWorkshop is being tracked in this context and the workshop context needs to be only one.
    const [inWorkshop, setInWorkshop] = useState(false);
    const [workshopUseLibrary, setWorkshopUseLibrary] = useState(false);
    const [selectedPOI, setSelectedPOI] = useState({});
    const [initialPOI, setInitialPOI] = useState({});
    const [workshopLibraryFilter, setWorkshopLibraryFilter] = useState(null);
    const [progressionFilter, setProgressionFilter] = useState(null);
    const [showHiddenComponents, setShowHiddenComponents] = useState(true);
    const [clickedArea, setClickedArea] = useState(null);
    const [isAccessControl, setAccessControl] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState(null);
    const [selectedView, setSelectedView] = useState(null);
    const [selectedViewHeader, setSelectedViewHeader] = useState(null);
    const [selectedHeader, setSelectedHeader] = useState(null);
    const [filteredChildIds, setFilteredChildIds] = useState([]);
    const [deleteConfirmationDialogVisibility, setDeleteConfirmationDialogVisibility] = useState(false);
    const [deleteTargetParentAndIndex, setDeleteTargetParentAndIndex] = useState({
        parent: null, index: null, component: null,
    });
    const [selectedPOIProc, setSelectedPOIProc] = useState();
    const [selectedPOIParentProc, setSelectedPOIParentProc] = useState({});
    const [isProgressionExpanded, setProgressionExpansion] = useState(false);
    const [progressionComponentRoute, setProgressionComponentRoute] = useState([]);
    const [isTemplateSelected, setIsTemplateSelected] = useState(false);
    const [duplicateColumnNameDialogVisibility, setDuplicateColumnNameDialogVisibility] = useState(false);
    const [columnDuplicateTargetParentAndIndex, setColumnDuplicateTargetParentAndIndex] = useState({
        parent: null, index: null, component: null,
    });
    const [settingMenuType, setSettingMenuType] = useState('Admin');
    const [LRCompList, setLRCompList] = useState({});
    const [settingComponent, setSettingComponent] = useState(null);
    const [workshopActiveComponent, setWorkshopActiveComponent] = useState(null);
    const [expandedAccordian, setExpandedAccordian] = React.useState(null);
    const [cloneWorkshopComp, setCloneWorkshopComp] = useState();
    const [isChildSibling, setIsChildSibling] = useState(false);
    const [associationData, setAssociationData] = useState([]);
    const [previousTemplate, setPreviousTemplate] = useState();
    const [workshopSelectedTemplate, setWorkshopSelectedTemplate] = useState(null);
    const [copyLRColumnState, setCopyLRColumnState] = useState(null);
    const [clearCurrentAssociation, setClearCurrentAssociation] = useState(false);
    const [dataMatrixComponent, setDataMatrixComponent] = useState(null);
    // Here DMRC denote the Data Matrix R-Clicked
    const [isDMRC, setIsDMRC] = useState(false);
    const [selectedDMWorkshopComponent, setSelectedDMWorkshopComponent] = useState(null);
    const [dmTemplateLibraryFlag, setDMTemplateLibraryFlag] = useState(false);
    const [selectedDMRClickMenuComp, setSelectedDMRClickMenuComp] = useState(null);
    // Here DMUA denote the Data Matrix User Access
    const [selectedDMUAComponent, setSelectedDMUAComponent] = useState(false);
    const [isProgressionDM, setIsProgressionDM] = useState(false);
    const [isDMViewMode, setIsDMViewMode] = useState(false);
    // Here CF denote the complex form
    const [newColumnCFFlag, setNewColumnCFFlag] = useState(false);
    const [selectedCF, setSelectedCF] = useState([]);
    const [selectCompCF, setSelectCompCF] = useState(null);
    const [specificSelect, setSpecificSelect] = useState({});
    const [updateFlag, setUpdateFlag] = useState(false);
    const [specificOptions, setSpecificOptions] = useState({});
    const [adminColumn, setAdminColumn] = useState({});
    const [compPreviewData, setCompPreviewData] = useState(null);
    const [hrzViewTLFlag, setHrzViewTLFlag] = useState(false);
    const [hierarchyComponent, setHierarchyComponent] = useState(null);
    const [tlAccordionData, setTLAccordionData] = useState(null);
    const [currentSelectedValue, setCurrentSelectedValue] = useState({});
    const [associationValue, setAssociationValue] = useState({});
    const [showEmptyDMDialog, setShowEmptyDMDialog] = useState(false);
    const [configureDialog, setConfigureDialog] = useState(false);
    const [promiseAccessControlData, setPromiseAccessControlData] = useState([]);
    const [editTimelineColumn, setEditTimelineColumn] = useState(false);
    const [editProgressionComp, setEditProgressionComp] = useState(false);
    const [editTimeline, setEditTimeline] = useState(false);
    const [addColumnLeftRight, setAddColumnLeftRight] = useState(false);
    const [configData, setConfigData] = useState({});
    const [contentDisplayLoader, setContentDisplayLoader] = useState(false);
    const [scrollState, setScrollState] = useState({ hrz: 0, vertical: 0 });
    const [pagehyperlinkCip, setPageHyperlinkCip] = useState(false);
    const [hyperlinkCip, setHyperlinkCip] = useState(false);
    const [videoHyperlinkCip, setVideoHyperlinkCip] = useState(false);
    const [hyperlinkCipText, setHyperlinkCipText] = useState('');
    const [hyperlinkComponent, setHyperlinkComponent] = useState(false);
    const [hyperlinkImageVideoComponent, setHyperlinkImageVideoComponent] = useState(false);
    const [progressionAccCollapse, setProgressionAccCollapse] = useState(true);
    const [imageEditorDialogWs, setImageEditorDialogWs] = useState(false);
    const [imageEditorDataWs, setImageEditorDataWs] = useState(false);
    const [imageEditorLoader, setImageEditorLoader] = useState(false);
    const [isPageModalOpen, setIsPageModalOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [showTitleView, setShowTitleView] = useState(false);
    const [intitalTempSelected, setIntitalTempSelected] = useState(false);
    const [complexFormType, setComplexFormType] = useState('');
    const [activeSelectSuggestParent, setActiveSelectSuggestParent] = useState(null);
    const [activeSelectSuggestData, setActiveSelectSuggestData] = useState({});
    const [columnWidthData, setColumnWidthData] = useState(null);
    const [timelineShareMode, setTimelineShareMode] = useState(null);
    const [patientProfileData, setPatientProfileData] = useState({
        profile: {},
    });
    const [selectedPsa, setSelectedPsa] = useState({
        psaId: '',
        psaObj: {},
    });
    const [selectedDoc, setSelectedDoc] = useState({
        psaId: '',
        psaObj: {},
    });
    const [LRUpdate, setLRUpdate] = useState({});
    const [contentDisplayCip, setContentDisplayCip] = useState(false);
    const [contentDisplayComponent, setContentDisplayComponent] = useState(false);
    const [contentDisplayImageList, setContentDisplayImageList] = useState([]);
    const [cdComponent, setCDComponent] = useState(null);
    const [cdClickType, setCdClickType] = useState('');
    const [collapseProtocol, setCollapseProtocol] = useState([]);
    const [collapseProgressionComp, setCollapseProgressionComp] = useState([]);
    const [universalCompState, setUniversalCompState] = useState({});
    const [universalScndState, setUniversalScndState] = useState({});
    const history = useHistory();
    const [columnChangeDialog, setColumnChangeDialog] = useState(false);
    const [singleOrContinuous, setSingleOrCont] = useState('');
    const [globalAssociationState, setGlobalAssociationState] = useState(false);
    const [settingUserViewDialog, setSettingUserViewDialog] = useState(false);
    const [userSettingsViewChanges, setUserSettingsViewChanges] = useState(false);
    const [userSettingsValues, setUserSettingsValues] = useState([]);
    const [workshopSC, setWorkshopSC] = useState(null);
    const [activeRow, setActiveRow] = useState(null);
    const [currentProtocol, setCurrentProtocol] = useState(null);
    const [currentProtocolParents, setCurrentProtocolParents] = useState(null);
    const webScrapingWebsites = ['lagynecomastia'];
    const [isFullLR, setIsFullLR] = useState(true);
    const [queryParams, setQueryParams] = useState([]);
    const [wpHeaders, setWPHeaders] = useState(null);

    // checking mobile screen size
    const isMobile = window.outerWidth <= 767;

    const checkIfLAGyno = (pageUrl) => webScrapingWebsites.some((element) => pageUrl.includes(element));

    const [addComponentPOI] = useMutation(
        ADD_COMPONENT_POI,
        {
            refetchQueries: [{
                query: GET_COMPONENT_POI,
                variables: {
                    timeline_id: selectedTimeline?.id,
                },
            }],
        }
    );

    useEffect(() => {
        if (complexFormType === 'Select and Suggest' && inWorkshop && workshopComponent?.props?.isLR) {
            const requiredLeftCfComponent = findComponent(workshopComponent?.props?.components, activeSelectSuggestParent?.props?.selectId);
            const requiredRightCfComponent = findComponent(workshopComponent?.props?.components, requiredLeftCfComponent?.mirrorId);
            if (!requiredLeftCfComponent?.props?.['select-suggest']) {
                delete requiredRightCfComponent?.props?.['select-suggest'];
            } else {
                const selectSuggest = requiredLeftCfComponent?.props?.['select-suggest'];
                const newSelectSuggest = {};
                // eslint-disable-next-line no-unused-vars
                for (const key in selectSuggest) {
                    const leftCfComponent = findComponent(workshopComponent?.props?.components, key);
                    newSelectSuggest[leftCfComponent?.mirrorId] = selectSuggest[key];
                }
                requiredRightCfComponent.props['select-suggest'] = newSelectSuggest;
            }
        }
    }, [updateFlag]);

    const [insertSelectedChildren, {
        data: insertSelectedChildrenData,
    }] = useMutation(INSERT_SELECTED_CHILDREN, {
        refetchQueries: [
            {
                query: GET_SELECTED_CHILDREN,
                variables: {
                    timeline_id: selectedTimeline?.id,
                },
            },
        ],
    });

    const [updateSelectedChildren, {
        data: updateSelectedChildrenData,
    }] = useMutation(UPDATE_SELECTED_CHILDREN, {
        refetchQueries: [
            {
                query: GET_SELECTED_CHILDREN,
                variables: {
                    timeline_id: selectedTimeline?.id,
                },
            },
        ],
    });

    const [deleteSelectedChildrenn, {
        data: deleteSelectedChildrenData,
    }] = useMutation(DELETE_SELECTED_CHILDREN, {
        refetchQueries: [
            {
                query: GET_SELECTED_CHILDREN,
                variables: {
                    timeline_id: selectedTimeline?.id,
                },
            },
        ],
    });

    const [deleteContentDisplay, {
        data: deleteContentDisplayData,
    }] = useMutation(DELETE_CONTENT_DISPLAY);

    useEffect(() => {
        if (deleteContentDisplayData) {
            updateTimeline();
        }
    }, [deleteContentDisplayData]);

    useEffect(() => {
        const activeSelectSuggestDataDeepCopy = JSON.parse(JSON.stringify(activeSelectSuggestData));
        const id = activeSelectSuggestDataDeepCopy?.id;
        const timelineId = activeSelectSuggestDataDeepCopy?.timelineId;
        const columnId = activeSelectSuggestDataDeepCopy?.columnId;
        const protocolId = activeSelectSuggestDataDeepCopy?.protocolId;
        const childrenData = activeSelectSuggestDataDeepCopy?.childrenData;
        if (activeSelectSuggestData?.operation === 'create') {
            insertSelectedChildren({
                variables: {
                    timelineId,
                    columnId,
                    protocolId,
                    childrenData,
                },
            });
        } else if (activeSelectSuggestData?.operation === 'edit') {
            updateSelectedChildren({
                variables: {
                    id,
                    childrenData,
                },
            });
        } else if (activeSelectSuggestData?.operation === 'delete') {
            deleteSelectedChildrenn({
                variables: {
                    timeline_id: timelineId,
                    column_id: columnId,
                    protocol_id: [protocolId],
                },
            });
        }
    }, [activeSelectSuggestData]);

    useEffect(() => {
        const insertResponse = insertSelectedChildrenData?.['insert_selected_children']?.returning;
        for (let i = 0, l = insertResponse?.length; i < l; i += 1) {
            if (activeSelectSuggestData?.protocolId === insertResponse?.[i]?.['protocol_id']) {
                setActiveSelectSuggestData({
                    id: insertResponse?.[i]?.id,
                    timelineId: insertResponse?.[i]?.['timeline_id'],
                    columnId: insertResponse?.[i]?.['column_id'],
                    protocolId: insertResponse?.[i]?.['protocol_id'],
                    childrenData: { ...insertResponse?.[i]?.['children_data'] },
                });
                break;
            }
        }
    }, [insertSelectedChildrenData]);

    useEffect(() => {
        const updateResponse = updateSelectedChildrenData?.['update_selected_children']?.returning;
        for (let i = 0, l = updateResponse?.length; i < l; i += 1) {
            if (activeSelectSuggestData?.protocolId === updateResponse?.[i]?.['protocol_id']) {
                setActiveSelectSuggestData({
                    id: updateResponse?.[i]?.id,
                    timelineId: updateResponse?.[i]?.['timeline_id'],
                    columnId: updateResponse?.[i]?.['column_id'],
                    protocolId: updateResponse?.[i]?.['protocol_id'],
                    childrenData: { ...updateResponse?.[i]?.['children_data'] },
                });
                break;
            }
        }
    }, [updateSelectedChildrenData]);

    useEffect(() => {
        const deleteResponse = deleteSelectedChildrenData?.['delete_selected_children']?.returning;
        if (deleteResponse) {
            setActiveSelectSuggestData({});
        }
    }, [deleteSelectedChildrenData]);

    const { loggedUserType, loggedInUserDetails } = useContext(OrgContext);

    // const workshopComponentRef = useRef();
    const workshopComponentRef = useCallback((node) => {
        if (node) {
            node.scrollIntoView({
                behavior: 'auto',
                inline: 'nearest',
            });
        }
    }, []);

    const timelineMatch = useRouteMatch('/:orgId/timeline/:timelineId');
    let found = true;
    found = getRoutePath().match(/timelines/);

    const foundtemplateList = getRoutePath().match(/templates/);
    const foundPatientsList = getRoutePath().match(/patients/);
    const foundUsersList = getRoutePath().match(/users/);
    const foundDoctorsList = getRoutePath().match(/doctors/);

    const { data: poiData } = useQuery(
        GET_COMPONENT_POI, {
            variables: {
                timeline_id: timelineMatch?.params?.timelineId,
            },
        },
    );
    const { data: AC_DATA } = useQuery(
        GET_COMPONENT_AC, {
            variables: {
                timeline_id: timelineMatch?.params?.timelineId,
            },
        }
    );

    const {
        data: callData, refetch: refetchCallData,
    } = useQuery(
        GET_PHONE_CALLS, {
            variables: {
                timeline_id: timelineMatch?.params?.timelineId,
            },
        }
    );

    const { data: allContentDisplayData, refetch: refetchContentDisplay } = useQuery(
        GET_ALL_CONTENT_DISPLAY, {
            variables: {
                timeline_id: timelineMatch?.params?.timelineId,
            },
        }
    );

    const { data: selectedChildren } = useQuery(
        GET_SELECTED_CHILDREN, {
            variables: {
                timeline_id: timelineMatch?.params?.timelineId,
            },
        }
    );

    const { data: orgMembersData } = useQuery(GET_ALL_ORG_MEMBER, { skip: getRouteValidation(['timelines', 'timeline']) },);
    const { data: specialities } = useQuery(GET_SPECIALITY, { skip: getRouteValidation(['timelines', 'timeline']) },);
    const { data: timelineAssocData } = useQuery(GET_TIMELINES_ASSOC, { skip: getRouteValidation(['timelines', 'timeline']) },);
    const { loading, error, data } = useQuery(GET_TIMELINES,
        {
            variables: { orgID: currentOrg.id, id: timelineMatch?.params?.timelineId || null },
            skip: getRouteValidation(['patients', 'users', 'doctors']) || loggedUserType === 'Patient',
        });
    const { data: shareWithDoctorTimelineData, loading: loadingTimelines } = useQuery(GET_TIMELINES_SUPER_ADMIN_SHARED, {
        variables: {
            parent_reference_id: currentOrg?.id,
        },
    });

    let timelinesLoading = (loading || loadingTimelines); const
        timelineError = error;

    const superTimelineData = useQuery(GET_SUPER_TIMELINE,
        {
            variables: { category: 'Super' },
            skip: loggedUserType !== 'Doctor' || getRouteValidation(['patients', 'users', 'doctors']),
        })?.data?.timeline[0];
    let timelines = !loading && data ? (loggedUserType === 'Doctor'
        ? [...data.timeline, {
            ...superTimelineData,
            category: 'Shared',
            created_at: loggedInUserDetails?.['created_at'],
            organization_id: loggedInUserDetails?.['organization_member']?.[0]?.organization?.id,
        }] : data?.timeline?.length > 0 ? data.timeline : shareWithDoctorTimelineData?.timeline) : undefined;
    const { data: settingsAccessResponse } = useQuery(GET_SETTINGS_ACCESS,
        {
            variables: { orgID: currentOrg.id },
            skip: getRouteValidation(['timelines', 'templates', 'patients', 'users', 'doctors']),
        });
    const { data: templateData } = useQuery(GET_TEMPLATES, { variables: { orgID: currentOrg.id } });
    // const columnTemplates = !templateLoading ? templateData.template : [{ name: "Empty", template: EmptyColumnTemplate }];
    const [updateTimelineColumns] = useMutation(
        UPDATE_TIMELINE_COLUMNS
    );
    const [bulkInsertPoi] = useMutation(
        INSERT_POI_MANY,
        {
            refetchQueries: [
                {
                    query: GET_COMPONENT_POI,
                    variables: {
                        timeline_id: selectedTimeline?.id,
                    },
                },
            ],
        }
    );
    const [bulkInsertAC] = useMutation(
        INSERT_AC_MANY,
        {
            refetchQueries: [
                {
                    query: GET_COMPONENT_AC,
                    variables: {
                        timeline_id: selectedTimeline?.id,
                    },
                },
            ],
        }
    );
    const [bulkInsertCD] = useMutation(
        INSERT_CD_MANY,
        {
            refetchQueries: [
                {
                    query: GET_ALL_CONTENT_DISPLAY,
                    variables: {
                        timeline_id: selectedTimeline?.id,
                    },
                },
            ],
        }
    );

    // const [addUserTimeline] = useMutation(
    //     ADD_USER_TIMELINE_ASSOC,
    //     {
    //         refetchQueries: [{ query: GET_TIMELINES, variables: { orgID: currentOrg.id } }],
    //     }
    // );
    const [addTimeline] = useMutation(
        ADD_TIMELINE,
        {
            refetchQueries: [{ query: GET_TIMELINES, variables: { orgID: currentOrg.id } }],
        }
    );

    const [insertUTAssociation] = useMutation(ADD_USER_TIMELINE);
    const [inserPatientUTAssociation] = useMutation(ADD_PATIENT_USER_TIMELINE);

    const [addTemplate] = useMutation(
        ADD_TEMPLATE,
        {
            refetchQueries: [{ query: GET_TEMPLATES, variables: { orgID: currentOrg.id } }],
        }
    );

    const [updateTemplate] = useMutation(
        UPDATE_TEMPLATE,
        {
            refetchQueries: [{ query: GET_TEMPLATES, variables: { orgID: currentOrg.id } }],
        }
    );

    const [updateSettingsAccessMutation] = useMutation(
        UPDATE_SETTINGS_ACCESS,
        {
            refetchQueries: [{ query: GET_SETTINGS_ACCESS, variables: { orgID: currentOrg.id } }],
        }
    );

    const [deleteTemplateMutation] = useMutation(
        DELETE_TEMPLATE,
        {
            refetchQueries: [{ query: GET_TEMPLATES, variables: { orgID: currentOrg.id } }],
        }
    );

    const [deleteTimelineMutation] = useMutation(
        DELETE_TIMELINE,
        {
            refetchQueries: [{ query: GET_TIMELINES, variables: { orgID: currentOrg.id, id: timelineMatch?.params?.timelineId || null } }],
        }
    );

    const [deleteCompPOI] = useMutation(DELETE_COMPONENT_POI);
    const [deleteCompUAC] = useMutation(DELETE_COMPONENT_UAC);
    const [deleteSelectedChild] = useMutation(DELETE_SELECTED_CHILDREN);

    const { data: userTimelines, loading: userLoading } = useQuery(GET_TIMELINES_BY_USER, {
        skip: loggedUserType !== 'Patient',
        variables: { user_id: loggedInUserDetails.id },
    });

    if (loggedUserType === 'Patient') {
        timelinesLoading = userLoading;
        if (userTimelines?.['user_timeline_association']?.length > 0 && !timelines) {
            timelines = userTimelines.user_timeline_association.map((t) => t.timeline);
        }
    }

    useEffect(() => {
        setColumnWidthData(null);
    }, [isMobile]);

    useEffect(() => {
        if (!AC_DATA) return;
        const accessObject = {};
        const acList = AC_DATA?.['components_access_control'];
        acList.forEach((ac) => {
            accessObject[ac.protocol_id] = ac.access_data;
            accessObject[`${ac.protocol_id}`].user_type = ac.user_type || ac?.['access_data']?.['user_type'];
        });
        setWorkshopAccessData(accessObject);
    }, [AC_DATA]);

    useEffect(() => {
        if (!timelineAssocData) return;
        const timelineAssocObject = {};
        const timelineAssocList = timelineAssocData?.['user_timeline_association'];
        timelineAssocList.forEach((timelineAssoc) => {
            const assocDataForOrg = timelineAssocObject[timelineAssoc.oraganisation_id] || [];
            timelineAssocObject[timelineAssoc.oraganisation_id] = [...assocDataForOrg, timelineAssoc];
        });
        setTimelineAssoc(timelineAssocObject);
    }, [timelineAssocData]);

    useEffect(() => {
        if (!orgMembersData) return;
        const membersObject = {};
        const membersList = orgMembersData?.['organization_member'];
        membersList.forEach((orgMember) => {
            membersObject[orgMember?.['user_id']] = orgMember?.['organization_id'];
        });
        setOrgMembers(membersObject);
    }, [orgMembersData]);

    useEffect(() => {
        if (!specialities) return;
        const splObject = {};
        const splList = specialities?.['industry_speciality'];
        splList.forEach((spl) => {
            const splArr = splObject[spl.speciality_parent] || [];
            splObject[spl.speciality_parent] = [...splArr, spl];
        });
        setSpl(splObject);
    }, [specialities]);

    useEffect(() => {
        if (timelineMatch && timelines) {
            const { timelineId } = timelineMatch.params;
            if (!loading) {
                let timeline;
                // eslint-disable-next-line no-param-reassign
                timelines.forEach((element) => { element.type = 'Timeline'; if (element.id === timelineId) { timeline = element; } });
                if (timeline?.name !== timeline?.props?.name) {
                    timeline.name = timeline?.props?.name;
                }
                if (selectedTimeline?.id !== timeline?.id) {
                    setSelectedTimeline(timeline);
                }
            }
        } else {
            setSelectedTimeline(undefined);
        }
    }, [timelineMatch, timelines, loading]);

    useEffect(() => {
        const headerChildren = [];
        buildSettingsTreePage(headerChildren, selectedHeader);
        const childIds = headerChildren.filter((item) => !workshopAccessData?.[item?.id]?.[selectedViewHeader]).map((item) => item.id);
        setFilteredChildIds(childIds);
    }, [selectedHeader, selectedViewHeader]);
    const [templateLibraryByType, templateLibraryById] = useMemo(() => createTemplateMap(templateData), [templateData]);

    const clearWorkshopComponent = () => {
        setWorkshopComponent(null);
        setRootComponent(null);
        setSelectedComponent({});
        setInWorkshop(false);
        setWorkshopComponent(null);
        setSelectedColumn(0);
        setActiveStep(0);
        setSelectedCF([]);
        setCompPreviewData(null);
        setSelectedOptionIdsPreview({});
        setHierarchyComponent(null);
        setTLAccordionData(null);
        setEditProgressionComp(false);
        setComponentParentPOI({});
        setShowTitleView(false);
        setIntitalTempSelected(false);
        setLastDeletedComponentIds([]);
        setVideoHyperlinkCip(false);
        setCDComponent(null);
        setUniversalCompState({});
        setUniversalScndState({});
        setWorkshopSC(null);
    };

    const isWorkshopComponent = (component) => (workshopComponent && workshopComponent.id === component.id);

    const styleComponent = ({ component, inWorkshop, isSelectedComp }) => {
        const isHighlited = isWorkshopComponent(component);
        const isSelectFlag = !checkEmptyObject(selectedComponent) && (selectedComponent && component.id === selectedComponent.id);
        const { type = null } = component;

        if ((type === 'Protocol') || (type === 'Row')) {
            return null;
        }

        if (isSelectFlag && component?.type !== 'Column') {
            return { background: !inWorkshop ? (component.id === selectedComponent.id ? 'CornflowerBlue' : null) : undefined };
        }

        if (isHighlited || isSelectedComp) {
            return { background: !inWorkshop && !workshopComponent ? 'CornflowerBlue' : undefined };
        }

        // if (component.props?.hide === true || component[selectedView] === false) {
        //     return { backgroundColor: '#e6e6e6', color: '#c9c9c9' };
        // }
        return null;
    };

    const getComponentRef = (component, inWorkshop) => (!inWorkshop && isWorkshopComponent(component) ? workshopComponentRef : null);

    const createTimeline = async ({
        title, timelineTemplate, category = 'Generic', otherArg = null, poi, speciality, industry,
    }) => {
        const loggedInUserRequiredDetails = {};
        if (loggedUserType === 'Doctor') {
            loggedInUserRequiredDetails.name = loggedInUserDetails.name;
            loggedInUserRequiredDetails.email = loggedInUserDetails.email;
            loggedInUserRequiredDetails.industry = loggedInUserDetails.organization_member[0].user.industry
                ? loggedInUserDetails.organization_member[0].user.industry : 'N/A';
            loggedInUserRequiredDetails.speciality = loggedInUserDetails.organization_member[0].user.specialty
                ? loggedInUserDetails.organization_member[0].user.specialty : 'N/A';
            loggedInUserRequiredDetails.orgName = loggedInUserDetails.organization_member[0].organization_data[0].name;
            loggedInUserRequiredDetails.orgEmail = loggedInUserDetails.organization_member[0].organization_data[0].org_email
                ? loggedInUserDetails.organization_member[0].organization_data[0].org_email : 'N/A';
            loggedInUserRequiredDetails.createdDate = getFormattedDate(loggedInUserDetails.created_at);
        }
        const reqObj = {
            name: title,
            orgID: (otherArg?.shareTimelineFlag ? otherArg.orgId : currentOrg.id),
            parentRefID: loggedUserType === 'Doctor' ? null : currentOrg.id,
            props: loggedUserType === 'Doctor'
                ? { ...timelineTemplate.props, name: title, createdBy: loggedInUserRequiredDetails }
                : { ...timelineTemplate.props, name: title },
            // parentRefID: loggedUserType === 'Doctor' ? null : currentOrg.id,
            // columns: timelineTemplate.columns,
            columns: otherArg ? timelineTemplate.columns : copyTemplateWithAssociatedCell(timelineTemplate.columns, true),
            category,
            poi: (otherArg?.shareTimelineFlag ? timelineTemplate.poi : poi),
            speciality: (otherArg?.shareTimelineFlag ? timelineTemplate.sub_specialities : speciality),
            industry: (otherArg?.shareTimelineFlag ? timelineTemplate.industries : industry),
            userId: otherArg?.selectedDoctor?.docId || null,
        };
        const allColumns = reqObj.columns;
        const poiPayload = [];
        const acPayload = [];
        const cdPayload = [];
        for (let i = 0, l = allColumns.length; i < l; i += 1) {
            const column = allColumns[i];
            column.progressionSectionId = getUniqueUUID();
            column.protocolSectionId = getUniqueUUID();
            if (column?.props?.isProfile) continue;
            if (!column?.props?.components) continue;

            const topLevelProtocols = column.props.components.concat(column.props.progressions);
            topLevelProtocols.forEach((protocol) => {
                const protocolChildren = [];
                buildSettingsTreePage(protocolChildren, protocol);
                protocolChildren.forEach((item) => {
                    item.props.protocolId = protocol?.id;
                });
            });
            let allProgressions = [];
            column.props.progressions.forEach((progression) => {
                const progressionChildren = [];
                buildSettingsTreePage(progressionChildren, progression);
                allProgressions = [...allProgressions, ...progressionChildren];
            });

            const allNewComp = [];
            const allComps = [];
            buildSettingsTreePage(allComps, timelineTemplate?.columns[i]);
            buildSettingsTreePage(allNewComp, column);
            const allChildren = [...allComps, ...allProgressions];
            allChildren.forEach((comp, index) => {
                const clonedId = comp?.id;
                const clonedPOI = poiData?.['components_poi'].find((item) => (item.protocol_id === clonedId)
                    && (item?.['timeline_id'] === timelineTemplate?.id));
                const clonedAC = AC_DATA?.['components_access_control'].find((item) => (item.protocol_id === clonedId
                    && (item?.['timeline_id'] === timelineTemplate?.id)));
                const clonedCD = allContentDisplayData?.['content_display'].find((item) => (item.protocol_id === clonedId)
                    && (item?.['timeline_id'] === timelineTemplate?.id));
                if (clonedPOI && allNewComp?.[index]?.id) {
                    poiPayload.push({
                        poi_data: clonedPOI.poi_data,
                        column_id: column.id,
                        protocol_id: allNewComp?.[index]?.id,
                    });
                }
                if (clonedAC && allNewComp?.[index]?.id) {
                    const userType = clonedAC?.['access_data']?.['user_type'];
                    delete clonedAC?.['access_data']?.['user_type'];
                    acPayload.push({
                        access_data: clonedAC.access_data,
                        user_type: userType,
                        column_id: column.id,
                        protocol_id: allNewComp?.[index]?.id,
                    });
                }
                if (clonedCD && allNewComp?.[index]?.id) {
                    cdPayload.push({
                        image_list: clonedCD.image_list,
                        column_id: column.id,
                        protocol_id: allNewComp?.[index]?.id,
                    });
                }
            });
        }
        const result = await addTimeline({
            variables: reqObj,
        });
        const { data: { insert_timeline_one: timeline } } = result;
        poiPayload.forEach((item) => {
            item.timeline_id = timeline.id;
        });
        acPayload.forEach((item) => {
            item.timeline_id = timeline.id;
        });
        cdPayload.forEach((item) => {
            item.timeline_id = timeline.id;
        });
        await bulkInsertPoi({
            variables: {
                objects: poiPayload,
            },
        });

        await bulkInsertAC({
            variables: {
                objects: acPayload,
            },
        });

        await bulkInsertCD({
            variables: {
                objects: cdPayload,
            },
        });

        return Promise.resolve(timeline);
    };

    const updateTimeline = (callback) => {
        // TODO need to refactor timeline update code.
        if (!selectedTimeline) {
            return Promise.resolve(false);
        }

        const result = updateTimelineColumns({
            variables: {
                id: selectedTimeline.id,
                name: selectedTimeline?.props?.name ? selectedTimeline.props.name : selectedTimeline.name,
                columns: selectedTimeline.columns,
                props: selectedTimeline.props,
            },
        });

        return result.then(
            () => {
                // ({ data: { update_timeline: updateTimelineResults } }) => {
                // TODO validation of results
                // console.log('update timeline result', updateTimelineResults);
                if (callback) {
                    callback();
                }
            }
        );
    };

    const saveTemplate = (template, setSelectedTemplate, silentUpdate) => {
        const result = updateTemplate({
            variables: {
                id: template?.id,
                name: template?.name,
                template: template?.template,
                group: template?.group,
                supergroup: template?.supergroup,
                category: template?.category,
                subCategory: template?.subCategory,
                sort_index: template.sort_index,
                associated_child: template.associated_child,
                parent_reference_id: template.parent_reference_id,
            },
        });

        return result.then(
            () => {
                if (!silentUpdate) {
                    // eslint-disable-next-line no-alert
                    window.alert('Template saved successfully!');
                }
                if (isDMRC && selectedDMUAComponent) {
                    selectedDMUAComponent.component = template?.name;
                    selectedDMUAComponent.supergroup = template?.supergroup;
                    selectedDMUAComponent.category = template?.category;
                    setSelectedDMUAComponent(selectedDMUAComponent);
                }
                if (setSelectedTemplate) {
                    setSelectedTemplate(null);
                }
                setSelectedComponent({});
                // console.log('updateTemplate complete', updateTemplateResults);
            }
        );
    };

    const createTemplate = (template) => {
        const mappedTemplateId = template?.template?.props?.templateId;
        if (mappedTemplateId) {
            // If mappedTemplateId exists, that means we are saving from the timeline column
            const templateName = template.name;
            const parentTemplate = template.template;
            parentTemplate.props.title = templateName;
            // Copy everything from parent template except parent_reference_id and name
            template = JSON.parse(JSON.stringify(templateLibraryByType.Column.find((item) => item.id === mappedTemplateId)));
            template.parent_reference_id = mappedTemplateId;
            template.name = templateName;
            template.template.props.title = templateName;
            template.template.templateData = parentTemplate;
            // template.template = parentTemplate;
        }
        const newTemplate = copyTemplateWithAssociatedCell(template.template);
        return addTemplate({
            variables: {
                name: template?.name,
                type: template?.type,
                orgID: currentOrg?.id,
                template: newTemplate,
                group: template?.group,
                supergroup: template?.supergroup,
                category: template?.category,
                subCategory: template?.subCategory,
                parent_reference_id: template.parent_reference_id,
            },
        }).then(() => {
        // }).then(({ data: { insert_template_one: insertTemplateResult } }) => {
            // console.log('createTemplate complete', insertTemplateResult);
            // TODO validation of results`
            // console.log('add template result', insertTemplateResult);
            setTemplatePayLoad(null);
        });
    };

    const deleteTemplate = (template) => deleteTemplateMutation(
        { variables: { id: template.id } }
    ).then(({ data: { delete_template_by_pk: id } }) => id);

    const deleteTimeline = (id) => deleteTimelineMutation(
        { variables: { id } }
    ).then(({ data: { delete_timeline_by_pk: id } }) => id);

    const saveAsTemplate = (component) => {
        // eslint-disable-next-line no-alert
        let name = prompt(`Name the ${component.type}`);
        if (!name) {
            return;
        }
        // eslint-disable-next-line no-alert
        let group = prompt('What Group is this template');
        if (!group) {
            return;
        }
        group = group.trim();
        name = name.trim();

        const template = copyTemplate(component);
        if (name.length > 0) {
            addTemplate({
                variables: {
                    name, type: component.type, orgID: currentOrg.id, template, group,
                },
            }).then(() => {
                // TODO validation of results
                // console.log('add template result', insertTemplateResult);
            });
        }
    };

    const saveWorkshopAsTemplate = () => {
        saveAsTemplate(workshopComponent);
    };

    const contextMenuClose = () => setContextMenuPosition(null);

    function editComponent(component, useLibrary = false, parent, libraryFilter, column, editFilter) {
        setWorkshopUseLibrary(useLibrary);

        if (libraryFilter) {
            setWorkshopLibraryFilter(libraryFilter);
        }

        if (!progressionFilter) {
            setProgressionFilter(libraryFilter || editFilter);
        }

        // TODO setSelectedComponent is called in every condition should it be repeated?
        setSelectedParent(parent);
        if (column) {
            setWorkshopColumn(column);
        }
        if (component) {
            if (inWorkshop) {
                if (!column) {
                    setSelectedComponent(component);
                } else {
                    setWorkshopComponent(component);
                    setWorkshopUseLibrary(useLibrary);
                    setSelectedComponent(component);
                }
                setCloneWorkshopComp(copyTemplate(component));
            } else {
                // console.log('Edit::setting In workshop and workshop component', component.type);
                const { type } = component;
                /* if (['Timeline', 'Column'].includes(type)) {
                    setInWorkshop(false);
                    setSelectedComponent(component);
                } else {
                    setInWorkshop(true);
                    setSelectedOptionIdsPreview({});
                    setWorkshopComponent(component);
                    setSelectedComponent(component);
                    setCloneWorkshopComp(copyTemplate(component));
                } */
                // if('Timeline' === type) {
                //     setInWorkshop(false);
                //     setSelectedComponent(component);
                // } else {
                // 'Column' === type ? setEditTimelineColumn(true); : setEditTimelineColumn(false);
                if (type === 'Column') {
                    setEditTimelineColumn(true);
                    setEditTimeline(false);
                } else if (type === 'Timeline') {
                    setEditTimeline(true);
                    setEditTimelineColumn(false);
                    setClickedArea(null);
                } else if (component?.isProgression) {
                    setEditProgressionComp(true);
                }
                setInWorkshop(true);
                setSelectedOptionIdsPreview({});
                setWorkshopComponent(component);
                setSelectedComponent(component);
                // setWorkshopAccessView({...workshopAccessData});
                setCloneWorkshopComp(copyTemplate(component));
            }
        }
        // let tempId = null;
        // if (timelineColumnMatch) {
        //     const { columnId } = timelineColumnMatch.params;
        //     tempId = columnId;
        // }
        // console.log('editComponent', column, tempId);
        // if (column && column.id !== tempId) {
        //     history.push(`workshop/${column.id}`);
        // }
    }

    /**
     * Add a new column in lEft or Right of the selected column
     *
     */
    function addColumnLeftAndRight(component, useLibrary = true, libraryFilter, index) {
        const clone = copyTemplate(component);
        clone.props.components = [];
        // clone.props.progressions = [];
        selectedTimeline.props.width = parseInt(selectedTimeline.props.width, 10) + findTimelineColumnWidth(selectedTimeline);
        setSelectedTimeline(selectedTimeline);
        selectedTimeline.columns.splice(index, 0, clone);

        setWorkshopUseLibrary(useLibrary);
        setInWorkshop(true);
        // setWorkshopAccessView({...workshopAccessData});
        setSelectedComponent(clone);
        setWorkshopLibraryFilter(libraryFilter);
        setWorkshopComponent(clone);
        setAddColumnLeftRight(true);
    }

    /**
     *
     * Add the Template in workshop after template selection
     */
    function addSelectedColumn(component) {
        setWorkshopComponent(component);
        setSelectedComponent(component);
        setWorkshopColumn(component);
        setWorkshopActiveComponent(component);
    }

    const getComponentPOIUA = (component) => {
        const filterACData = AC_DATA.components_access_control.filter((p) => p.column_id === component.id) || null;
        const acDataObj = filterACData?.length > 0 ? arrayToObject(filterACData, 'protocol_id') : null;

        const filterPOIData = poiData.components_poi.filter((p) => p.column_id === component.id) || null;
        const poiDataObj = filterPOIData?.length > 0 ? arrayToObject(filterPOIData, 'protocol_id') : null;

        return { acDataObj, poiDataObj };
    };

    const handleCompPreviewClick = (e, comp) => {
        if (e) e.stopPropagation();
        // const { type } = comp;
        setCompPreviewData(null);
        // switch (type) {
        // case 'Column':
        //     // setCompPreviewData(comp);
        //     setCompPreviewData(null);
        //     break;
        // case 'Protocol':
        //     setCompPreviewData(null);
        //     break;
        // case 'Text':
        //     setCompPreviewData(null);
        //     break;
        // default:
        //     setCompPreviewData(null);
        // }
        if (e) setSettingComponentHandle(comp);
        setSelectedComponent(comp);
    };

    const setSettingComponentHandle = (component) => {
        if (inWorkshop && component && !checkEmptyObject(component)) {
            if (workshopActiveComponent?.props?.focused) {
                delete workshopActiveComponent.props.focused;
            }
            setSettingComponent(component);
            setWorkshopActiveComponent(component);
            setExpandedAccordian(component.id);
            handleCompPreviewClick(null, component);
            if (component?.type === 'Column') {
                setWorkshopSC(component);
            } else if (component?.colRef) {
                const resultParent = [];
                findParent([workshopComponent], component.colRef, resultParent);
                const column = resultParent?.[0]?.props?.components.find((c) => c?.id === component?.colRef);
                setWorkshopSC(column);
            }
        }
    };

    /**
     *
     * Create a Duplicate of an existing column
     */
    const duplicateComponent = (component, parent, index, override, parentComp = null) => {
        const { acDataObj, poiDataObj } = parentComp?.type === 'Timeline' && getComponentPOIUA(component);
        const clone = !override ? (parentComp?.type === 'Timeline' && acDataObj && poiDataObj
            ? copyTemplateWithAssociatedCell(component, false, { poiObj: poiDataObj, acObj: acDataObj })
            : copyTemplateWithAssociatedCell(component))
            : copyTemplate(component);
        // const clone = !override ? copyTemplateWithAssociatedCell(component) : copyTemplate(component);
        const componentClone = clone.component || clone;
        if (componentClone?.props?.cfType === 'Select and Suggest' && componentClone?.type === 'Checkbox' && workshopComponent?.props?.isLR) {
            componentClone.mirrorId = getUniqueUUID();
        }
        if (!inWorkshop && parentComp?.name === 'Form' && parentComp?.componentType === 'Text Box On Timeline') {
            parent.forEach((item) => {
                delete item?.props?.focused;
            });
        }

        if (override) {
            componentClone.props = { ...componentClone.props, ...override };
        }
        parent.splice(index + 1, 0, componentClone);
        const compTypes = ['Checkbox', 'TextInput'];

        if (componentClone?.type && compTypes.includes(componentClone?.type)) {
            setSettingComponentHandle(componentClone);
        }
        /* DO NOT REMOVE THIS COMMENTED CODE |
        THIS LINE WILL PREVENT MAKING DB ENTRY ON EVERY CHANGE.
        */

        if (parentComp?.type === 'Timeline') {
            updateTimeline();
            if (clone?.poiACData?.poiClone?.length > 0) {
                clone.poiACData.poiClone.forEach((c) => {
                    c.column_id = componentClone.id;
                });
                bulkInsertPoi({
                    variables: {
                        objects: clone.poiACData.poiClone,
                    },
                });
            }

            if (clone?.poiACData?.acClone?.length > 0) {
                clone.poiACData.acClone.forEach((c) => {
                    c.column_id = componentClone.id;
                });
                bulkInsertAC({
                    variables: {
                        objects: clone.poiACData.acClone,
                    },
                });
            }
        }
        // updateTimeline();
        return componentClone;
    };

    const showDuplicateColumnDialog = (parent, index, component) => {
        setDuplicateColumnNameDialogVisibility(true);
        setColumnDuplicateTargetParentAndIndex({
            parent, index, component,
        });
    };

    /**
     * DFS Based recursive child finder inside the Progression Hierarchy.
     * @param {*} The component which will be compared, if not found then children will be checked.
     * @param {*} ID to look out for.
     * @param {*} Resultant.
     */
    const findChild = (progressionComp, targetId, locationMap) => {
        if (progressionComp?.id === targetId) {
            return progressionComp;
        }

        if (progressionComp?.props && progressionComp?.props?.components && progressionComp?.props?.components?.length > 0) {
            for (let iterator = 0; iterator < progressionComp.props.components.length; iterator += 1) {
                locationMap.push(iterator);
                const check = findChild(progressionComp.props.components[iterator], targetId, locationMap);
                if (check) {
                    return check;
                }
                locationMap.pop();
            }
        }
        return null;
    };

    /**
     * Provides the array based lookup location of the provided id in the Progression components tree.
     * @param {*} The component to be searched.
     * @param {*} The index to look out for.
     * @param {*} If instead of component, an Id is being passed then this argument is a flag to check.
     * @param {*} If id is passed and it to be used directly instead of targetComp.
     */
    const getLocationOfTargetComponent = (targetComp, currentColumnIndex, isIdToUse = false, id, isProgression = true) => {
        let locationMap = [];
        let progressionsOfColumn = selectedTimeline?.columns[currentColumnIndex]?.props?.components;
        if (isProgression) {
            progressionsOfColumn = selectedTimeline?.columns[currentColumnIndex]?.props?.progressions || [];
        }

        for (let iterator = 0; iterator < progressionsOfColumn?.length; iterator += 1) {
            const progressionComp = progressionsOfColumn[iterator];
            locationMap.push(iterator);
            let targetId = targetComp.id;

            if (isIdToUse) {
                targetId = id;
            }

            const output = findChild(progressionComp, targetId, locationMap);

            if (output) {
                return locationMap;
            }
            locationMap = [];
        }

        return locationMap;
    };

    const insertComponent = async (type, id, destination, index,
        useLibrary, parent, libraryFilter, column,
        targetComponent, currentColumnIndex, action, doNotSendReq) => {
        const isCell = targetComponent.type === 'Cell' || targetComponent.type === 'SelectCell';
        const isSelectSuggest = complexFormType === defaultData.selectSuggest;
        if (type === 'template') {
            const template = templateLibraryMap[id];
            const clone = copyTemplate(template.template);
            componentWithId(clone.id, clone.props);
            destination.splice(index, 0, ...clone);
        } else if (type === 'component') {
            if (!(targetComponent?.props?.isComplexForm || targetComponent?.props?.LRProps || targetComponent.type === 'Cell')) {
                setSelectedParent(targetComponent);
                setRootComponent(targetComponent);
            }
            const template = componentLibraryMap[id];
            if (!inWorkshop && (clickedArea === 'Progression' || template.isProgression || targetComponent.isProgression)) {
                // If the Progression Section is right clicked to insert the component.
                const commonId = getUniqueUUID();
                if (targetComponent.type === 'ColumnSection') {
                    selectedTimeline.columns.forEach((col, colIterator) => {
                        if (!col?.props?.isProfile) {
                            const clone = copyTemplate(template);
                            if (clone?.props?.components?.[0]?.type === 'Column') {
                                clone.props.components[0].isProgression = true;
                            }
                            clone.commonId = commonId;
                            clone.isProgression = true;
                            clone.name = libraryFilter;
                            col.props.progressions.splice(index, 0, clone);
                            componentWithId(clone.id, clone.props, clone.name);
                            if (currentColumnIndex === colIterator) {
                                editComponent(clone, useLibrary, col, libraryFilter, col);
                            }
                        }
                    });
                } else {
                    // To get the location of the right clicked component inside the Progression Section.
                    const locationMap = getLocationOfTargetComponent(targetComponent, currentColumnIndex);

                    setProgressionComponentRoute(locationMap);
                    selectedTimeline.columns.forEach((col, columnIterator) => {
                        if (!col?.props?.isProfile) {
                            let targetElement = col.props.progressions[locationMap[0]];
                            let clone = null;
                            // If the component is not inside any header.
                            if (!targetElement) {
                                clone = copyTemplate(template);
                                clone.commonId = commonId;
                                clone.isProgression = true;
                                clone.name = libraryFilter;
                                col.props.progressions.splice(index, 0, clone);
                                componentWithId(clone.id, clone.props, clone.name);
                            } else {
                                let compIndex = null;
                                let targetClone = targetElement;
                                if (action.relation === 'parent') {
                                    if (locationMap && locationMap.length > 0) {
                                        let targetElement = col.props.progressions;
                                        for (let iterator = 0; iterator <= locationMap.length - 1; iterator += 1) {
                                            const { type } = targetElement[locationMap[iterator]].props;
                                            const ele = targetElement;
                                            targetElement = targetElement[locationMap[iterator]].props.components;
                                            if (type === action.component.key) {
                                                index = locationMap[iterator] + 1;
                                                if (iterator === 0) {
                                                    destination = col.props.progressions;
                                                } else {
                                                    destination = ele;
                                                }
                                                break;
                                            } else if (iterator === locationMap.length - 1) {
                                                destination = ele;
                                            }
                                        }
                                    }
                                } else {
                                    // If the component is inside any header.
                                    for (let iterator = 1; iterator < locationMap.length; iterator += 1) {
                                        targetClone = targetElement;
                                        targetElement = targetElement.props.components[locationMap[iterator]];
                                        compIndex = iterator;
                                    }
                                }
                                clone = copyTemplate(template);
                                clone.commonId = commonId;
                                clone.isProgression = true;
                                clone.name = libraryFilter;

                                if (action.relation === 'child') {
                                    // targetElement.props.components.push(clone);
                                    clone.props.childOf = targetElement.id;
                                    targetElement.props.components.splice(index, 0, clone);
                                } else if (action.relation === 'parent') {
                                    destination.splice(index, 0, clone);
                                } else if (compIndex) {
                                    if (action.relation === 'sibling') {
                                        clone.props.childOf = targetElement.id;
                                    }
                                    targetClone.props.components.splice(index, 0, clone);
                                    if (!isChildSibling) {
                                        setIsChildSibling(true);
                                    }
                                } else {
                                    col.props.progressions.splice(index, 0, clone);
                                }
                                componentWithId(clone.id, clone.props, clone.name);
                            }

                            if (currentColumnIndex === columnIterator) {
                                editComponent(clone, useLibrary, col, libraryFilter, col);
                            }
                        }
                    });
                    // If the header is not right clicked.
                }
            } else {
                const clone = copyTemplate(template);
                if (complexFormType === 'Select and Suggest' && clone?.type === 'Checkbox' && workshopComponent?.props?.isLR) {
                    clone.mirrorId = getUniqueUUID();
                }
                clone.name = libraryFilter;
                clone.associatedParent = workshopActiveComponent?.id || null;
                clone.action = JSON.parse(JSON.stringify(action));

                if (column?.props?.isProfile && targetComponent?.props?.isMedicalHistory) {
                    clone.props.isMedicalHistory = true;
                }

                if (workshopAccessData[targetComponent.id]) {
                    setWorkshopAccessData((w) => ({
                        ...w,
                        [clone.id]: workshopAccessData[targetComponent.id],
                    }));
                }
                if (contextMenuTarget.component.type === 'Page' && inWorkshop) {
                    clone.props.pageChild = true;
                    clone.props.parentID = contextMenuTarget.component.id;
                    const componentList = [];
                    buildSettingsTreePage(componentList, clone);
                    componentList.forEach((item) => {
                        item.props.pageChild = true;
                        item.props.parentID = contextMenuTarget.component.id;
                        item[domain] = item.props;
                    });
                }
                // destination.splice(index, 0, clone);
                if (workshopComponent && workshopActiveComponent) {
                    const resultParent = [];
                    findParent(workshopComponent?.props?.components, workshopActiveComponent?.id, resultParent);
                    const resultData = [];
                    findParent(workshopComponent ? workshopComponent?.props?.components : [], workshopActiveComponent?.parent, resultData);
                    if (resultParent
                        && resultParent[0]?.type === 'Column'
                        && (resultData[0]?.props?.components[0]?.props?.isComplexForm || resultData[0]?.props?.components[0]?.props?.LRProps)) {
                        switch (workshopActiveComponent.type) {
                        case 'Checkbox':
                            workshopActiveComponent.props.children = {};
                            break;
                        case 'Select':
                            // const selectedOptionIndex = workshopActiveComponent.props.options.findIndex((option) => option.isSelect);
                            //     workshopActiveComponent.props.options[selectedOptionIndex].children = {};
                            break;
                        case 'MultiSelect':
                            // const selectedOptionIndex1 = workshopActiveComponent.props.options.findIndex((option) => option.isSelect);
                            // workshopActiveComponent.props.options[selectedOptionIndex1].children = {};
                            break;
                        default:
                        }
                    }
                }
                if (!isSelectSuggest && (targetComponent.props.isComplexForm
                    || targetComponent.props.LRProps || isCell) && !targetComponent.props.isMixedForm) {
                    addCells({
                        mainComp: targetComponent, associatedComponent: clone, workshopComponent, isCell, LRProps: targetComponent.props.LRProps,
                    });
                }
                if (!isSelectSuggest && !targetComponent.props.isMixedForm && (targetComponent?.props?.isComplexForm
                    || targetComponent?.props?.LRProps
                    || targetComponent?.props?.associatedComponent
                    || targetComponent?.type === 'Cell'
                    || targetComponent?.type === 'SelectCell' || targetComponent?.type === 'OptionCell' || targetComponent?.type === 'EmptyCell')) {
                    if (targetComponent?.type === 'Column' && targetComponent?.props?.isComplexForm) {
                        clone.rowRef = targetComponent?.parent;
                        clone.colRef = targetComponent?.id;
                    } else if (targetComponent?.hasOwnProperty('rowRef') || targetComponent?.hasOwnProperty('colRef')) {
                        clone.rowRef = targetComponent?.rowRef;
                        clone.colRef = targetComponent?.colRef;
                    }

                    destination.splice(destination.length, 0, clone); // for CF
                    if (workshopComponent?.props?.isHighlight) {
                        clone.props.isHighlight = true;
                    } else {
                        clone.props.isHighlight = false;
                    }
                } else if (workshopComponent?.component === 'Mixed Form' || workshopComponent?.component === 'LR Mixed Form') {
                    if (targetComponent?.type === 'Column') clone.colRef = targetComponent?.id;
                    clone.props.isMixedForm = true;
                    destination.splice(destination.length, 0, clone);
                } else {
                    destination.splice(index, 0, clone);
                }

                if (workshopComponent?.props?.isUniversal && parent?.rowRef && clone.type === 'Select') {
                    const row = findComponent(workshopComponent?.props?.components, parent?.rowRef);
                    const findCurrentColumnIndex = row.props.components.findIndex((item) => item.id === parent.colRef);
                    const currentColumn = row.props.components[findCurrentColumnIndex];
                    const nextColumn = row.props.components[findCurrentColumnIndex + 1];
                    const findCellIndex = currentColumn?.props?.components?.findIndex((c) => c.props.refCellId === parent.id);
                    if (findCellIndex !== -1 && findCellIndex !== undefined && nextColumn?.props?.components) {
                        const copyClone = JSON.parse(JSON.stringify(clone));
                        copyClone.id = uuid();
                        currentColumn.props.components[findCellIndex].props.components.splice(findCellIndex, 0, copyClone);
                        const nextColumnChild = nextColumn.props.components.find((c) => c.props.associatedComponent === clone.id && c.isTimeline);
                        nextColumnChild.props.associatedComponent = copyClone.id;
                    } else if (currentColumn?.props?.LRProps?.LRtype) {
                        const copyClone = JSON.parse(JSON.stringify(clone));
                        copyClone.id = uuid();
                        currentColumn.props.components[findCellIndex].props.components.splice(findCellIndex, 0, copyClone);
                    }
                }
                if (clone?.type === 'TextAreaCF') {
                    inheritComponentFunction(workshopComponent, clone, TextAreaProps);
                } else if (clone?.type === 'TextBoxCF') {
                    inheritComponentFunction(workshopComponent, clone, TextBoxProps);
                }
                // TheUC Change associated id's to match the new element id for Select/Text-Complete-Notes Template
                if (clone?.type === 'Checkbox' && workshopComponent?.component === 'Select/Text-Complete-Notes') {
                    const workshopList = [];
                    buildSettingsTreePage(workshopList, workshopComponent);
                    const secondColEl = workshopList.find((c) => c.props.index === 1 && c.props.isComplexForm && c.type === 'Column');
                    const thirdColEl = workshopList.find((c) => c.props.index === 2 && c.props.isComplexForm && c.type === 'Column');
                    secondColEl.props.components = secondColEl.props.components.filter((c) => c.props.components.length > 0 && !c.isTimeline);
                    thirdColEl.props.components = thirdColEl.props.components.filter((c) => c.props.components.length > 0 && !c.isTimeline);
                    secondColEl.props.components[0].props.associatedComponent = clone.id;
                    secondColEl.props.components[0].props.components[0].associatedParent = clone.id;
                    secondColEl.props.components[0].id = clone?.props?.associatedCell;
                }
                // TheUC add checkbox to the TextAreaCF & Change associated id's to match the new element id for Select/Text-Complete-Notes Template
                if (clone?.type === 'TextAreaCF' && workshopComponent?.component === 'Select/Text-Complete-Notes') {
                    const workshopList = [];
                    buildSettingsTreePage(workshopList, workshopComponent);
                    const secondColEl = workshopList.find((c) => c.props.index === 1 && c.props.isComplexForm && c.type === 'Column');
                    const thirdColEl = workshopList.find((c) => c.props.index === 2 && c.props.isComplexForm && c.type === 'Column');
                    secondColEl.props.components = secondColEl.props.components.filter((c) => c.props.components.length > 0 && !c.isTimeline);
                    thirdColEl.props.components = thirdColEl.props.components.filter((c) => c.props.components.length > 0 && !c.isTimeline);
                    clone.props['form-control'] = 'yes';
                    secondColEl.props.components[0].props.associatedComponent = clone.id;
                    secondColEl.props.components[0].props.components[0].associatedParent = clone.id;
                    secondColEl.props.components[0].id = clone?.props?.associatedCell;
                }
                componentWithId(clone.id, clone.props, clone.name);
                editComponent(clone, useLibrary, parent, libraryFilter, column);
                if (targetComponent.props.LRProps) {
                    const { LRtype, mainParent, LRparent } = targetComponent.props.LRProps;
                    // const LRclone = copyTemplate(clone);
                    const parentComp = findComponent(workshopComponent?.props.components, mainParent);
                    const compIndex = parentComp?.props.components.findIndex((comp) => comp.id === LRparent);
                    const updateIndex = LRtype === 'left' ? compIndex + 1 : compIndex - 1;
                    if (LRtype === 'right') {
                        return;
                    }
                    if (parentComp) {
                        parentComp.props.components[compIndex].props.components.forEach((activeComp, i) => {
                            const compToUpdate = parentComp.props.components[updateIndex].props.components[i];
                            let LRPropsBak;
                            if (compToUpdate.props.LRProps) {
                                LRPropsBak = { ...compToUpdate.props.LRProps };
                            }
                            parentComp.props.components[updateIndex].props.components[i] = copyTemplateLR(activeComp, LRPropsBak, workshopComponent);

                            // updateRecoilComponent(parentComp.props.components[compIndex + 1].props.components[i].id,parentComp.props.components[compIndex + 1].props.components[i].props);
                        });
                    }
                }
            }
            if (!hierarchyComponent) setHierarchyComponent(destination[index]);
        }
        if (contextMenuTarget.component.type === 'Page' && inWorkshop) {
            const newPageComp = {
                ...pageComponent,
                [domain]: {
                    ...pageComponent[domain],
                    components: destination,
                },
            };
            setPageComponent(newPageComp);
        }
        if (!doNotSendReq) updateTimeline();
    };

    // componentWithId
    const setComponentProperty = (component, newProps) => {
        Object.entries(newProps).forEach(([key, value]) => {
            component.props[key] = value;
        });

        updateTimeline();
    };

    const showDeletionDialog = (parent, index, component) => {
        setDeleteConfirmationDialogVisibility(true);
        setDeleteTargetParentAndIndex({
            parent, index, component,
        });
    };

    // delete poi of components
    const removeAssociatePOI = (component, ids = null) => {
        const { timelineId } = timelineMatch?.params;
        let { type } = component;
        if (ids && !type) type = 'deleteWithChild';
        const reqObj = {};
        switch (type) {
        case 'Column':
            reqObj.timeline_id = timelineId;
            reqObj.column_id = component.id;
            break;
        case 'Protocol':
            reqObj.timeline_id = timelineId;
            reqObj.protocol_id = [component.id];
            break;
        case 'Text':
            reqObj.timeline_id = timelineId;
            reqObj.protocol_id = [component.id];
            break;
        case 'deleteWithChild':
            reqObj.timeline_id = timelineId;
            reqObj.protocol_id = ids;
            break;
        default:
        }
        if (!checkEmptyObject(reqObj)) {
            const result = deleteCompPOI({
                variables: reqObj,
            });

            const resultUAC = deleteCompUAC({
                variables: reqObj,
            });

            result.then(() => {
            });
            return resultUAC.then(() => {
            });
        }
        return null;
    };

    // delete Selected Children of Child Select and Suggest Complex Form
    const removeSelectedChildren = (component, ids = null) => {
        const { timelineId } = timelineMatch?.params;
        let { type } = component;
        if (ids && !type) type = 'deleteWithChild';
        const reqObj = {};
        switch (type) {
        case 'Column':
            reqObj.timeline_id = timelineId;
            reqObj.column_id = component.id;
            break;
        case 'Protocol':
            reqObj.timeline_id = timelineId;
            reqObj.protocol_id = [component.id];
            break;
        case 'deleteWithChild':
            reqObj.timeline_id = timelineId;
            reqObj.protocol_id = ids;
            break;
        default:
        }

        if (!checkEmptyObject(reqObj)) {
            const result = deleteSelectedChild({
                variables: reqObj,
            });

            result.then(() => {
            });
        }
        return null;
    };

    const deleteComPOI = (deletedComp) => {
        if (actionRelation?.type === 'deleteWithChild') {
            const compTreeFlat = [];
            buildSettingsTreePage(compTreeFlat, deletedComp);
            const filterTreeData = compTreeFlat.filter((c) => (c.type === 'Protocol' && !c.props.hideFromWorkshop)
            || (c.type === 'Text' && !c.props.hideFromWorkshop));
            const ids = filterTreeData.map((item) => item.id);
            removeAssociatePOI({ type: null }, ids);
        } else removeAssociatePOI(deletedComp);
    };

    const deleteSelectedChildren = (deletedComp) => {
        if (actionRelation?.type === 'deleteWithChild') {
            const compTreeFlat = [];
            buildSettingsTreePage(compTreeFlat, deletedComp);
            const filterTreeData = compTreeFlat.filter((c) => (
                c.type === 'Protocol'
                && c?.props.cfType === 'Select and Suggest'
                && !c.props.hideFromWorkshop
            ));
            const ids = filterTreeData.map((item) => item.id);
            removeSelectedChildren({ type: null }, ids);
        } else if (deletedComp?.props?.cfType === 'Select and Suggest' || deletedComp?.type === 'Column') {
            removeSelectedChildren(deletedComp);
        }
    };

    const deleteAssociation = (deletedComp) => {
        // findOptionCellMapping(workshopComponent, deletedComp.id).selectCompId.props.association = {};
        switch (deletedComp?.type) {
        case 'Select':
            if (deletedComp?.props?.options.length > 0) {
                deletedComp.props.options.forEach((option) => {
                    if (option.children) {
                        const childrens = findPage(workshopComponent.props.components, option.children.id);
                        const childrenAsscociation = findComponent(workshopComponent.props.components, option.children.id);
                        if (childrens) {
                            childrens.splice(childrenAsscociation.compIndex, 1);
                        }
                        deleteAssociation(childrenAsscociation);
                    }
                });
            }
            break;
        case 'Checkbox':
            if (deletedComp?.props?.children) {
                const childrens = findPage(workshopComponent.props.components, deletedComp.props.children.id);
                const childrenAsscociation = findComponent(workshopComponent.props.components, deletedComp.props.children.id);
                if (childrens) {
                    childrens.splice(childrenAsscociation.compIndex, 1);
                }
                deleteAssociation(childrenAsscociation);
            }
            break;
        default:
        }
        updateTimeline();
    };

    const deleteChildrenFromParentObject = (deletedComp) => {
        const childrenAsscociation = findComponent(workshopComponent.props.components, deletedComp.associatedParent);
        switch (childrenAsscociation?.type) {
        case 'Select':
            if (childrenAsscociation?.props?.options.length > 0) {
                childrenAsscociation.props.options.forEach((option) => {
                    if (option.children && option.children.id === deletedComp.id) {
                        delete option.children;
                    }
                });
            }
            break;
        case 'Checkbox':
            if (childrenAsscociation?.props?.children) {
                delete childrenAsscociation.props.children;
            }
            break;
        default:
        }

        updateTimeline();
    };

    const removeComponent = (parent, index, component, callback) => {
        if (component.type === 'Option') {
            setLastDeletedOption(component);
            return;
        }
        if ((parent.components && parent.components[index]?.isProgression && parent.components[index]?.isProgression === true)
            || clickedArea === 'Progression' || parent.isProgression || component.isProgression) {
            const locationMap = getLocationOfTargetComponent(component, selectedColumn);
            selectedTimeline.columns.forEach((col) => {
                if (!col?.props?.isProfile && locationMap && locationMap.length > 1) {
                    let targetProgressionElementParent = col.props.progressions[locationMap[0]];
                    for (let iterator = 1; iterator < locationMap.length - 1; iterator += 1) {
                        targetProgressionElementParent = targetProgressionElementParent.props.components[locationMap[iterator]];
                    }

                    const deletedComp = targetProgressionElementParent.props.components[index];
                    targetProgressionElementParent.props.components.splice(index, 1);

                    if (actionRelation
                    && actionRelation.type === 'deleteWithoutChild'
                    && deletedComp && deletedComp.type
                    && deletedComp.type !== 'Protocol') {
                        for (let i = 0; i < deletedComp.props.components.length; i += 1) {
                            delete deletedComp.props.components[i]?.props?.hadGroupHeader;
                            targetProgressionElementParent.props.components.splice((index + i), 0, deletedComp.props.components[i]);
                        }
                    }
                    if (timelineMatch) {
                        deleteComPOI(deletedComp);
                    }
                } else if (!col?.props?.isProfile && locationMap && locationMap.length === 1) {
                    const deletedComp = col.props.progressions[locationMap[0]];
                    col.props.progressions.splice(index, 1);
                    if (actionRelation
                    && actionRelation.type === 'deleteWithoutChild'
                    && deletedComp && deletedComp.type
                    && deletedComp.type !== 'Protocol') {
                        for (let i = 0; i < deletedComp.props.components.length; i += 1) {
                            delete deletedComp.props.components[i]?.props?.hadGroupHeader;
                            col.props.progressions.splice((index + i), 0, deletedComp.props.components[i]);
                        }
                    }
                    if (timelineMatch) {
                        deleteComPOI(deletedComp);
                    }
                } else if (!col?.props?.isProfile) {
                    col.props.progressions.splice(index, 1);
                }
            });
        } else {
            const components = parent.components || parent.props.components;
            const deletedComp = components[index];
            components.splice(index, 1);
            if (deletedComp) {
                if (inWorkshop) { deleteAssociation(deletedComp); }
                if (deletedComp?.associatedParent) {
                    deleteChildrenFromParentObject(deletedComp);
                }
                // if (deletedComp.props.associatedCell) {
                const deleteArray = [deletedComp?.id];
                setLastDeletedComponentIds(deleteArray);
                setLastDeletedComponent(deletedComp?.id);
                // }
                if (actionRelation && actionRelation.type === 'deleteWithoutChild'
                    && deletedComp && deletedComp?.type && deletedComp?.type !== 'Protocol') {
                    for (let i = 0; i < deletedComp.props.components.length; i += 1) {
                        delete deletedComp.props.components[i]?.props?.hadGroupHeader;
                        components.splice((index + i), 0, deletedComp.props.components[i]);
                    }
                }
            }
            if (timelineMatch) {
                deleteComPOI(component);
                deleteSelectedChildren(component);
            }
        }

        // close content display if same component is delete.
        if (cdComponent?.id === component?.id) {
            setCDComponent(null);
        }
        updateTimeline(callback);
    };

    const addColumn = (action, target, parent, index) => {
        // console.log('********addColumn', action, target, parent, index);
        // if the target is a column then add col new col after the current column,
        // else the target is the timeline itself so insert at the beginning of its columns.
        let { columns } = target;
        let at = index + 1;

        if (target.type === 'Column') {
            columns = parent.props.components;
            const clone = copyTemplate(action.template);
            columns.splice(at, 0, clone);
        } else if (target.type === 'Row') {
            columns = target.props.components;
            at = 0;
            const clone = copyTemplate(action.template);
            columns.splice(at, 0, clone);
        } else if (target.type === 'Timeline') {
            const clone = copyTemplate(action.template);
            columns.splice(at, 0, clone);
        }

        updateTimeline();
    };

    /**
     * @function setAccessControl [Set the Access]
     * @param {object} component
     * @returns {object} updated state
     */
    const setAccessControlFlow = (component) => {
        setAccessControl(true);
        setSelectedColumns(component);
    };

    /**
     * @function setAccessView [Set the Admin]
     * @param {object} component
     * @returns {object} updated state
     */
    const setAccessView = (component, selectedView) => {
        setSelectedView(selectedView);
        setSelectedColumns(component);
        setSelectedViewHeader(null);
        setSelectedHeader(null);
    };
    const setAccessViewHeader = (component, selectedView) => {
        setSelectedViewHeader(selectedView);
        setSelectedHeader(component);
    };

    const addAsTemplate = ({ component }) => {
        if (component) {
            const copyComponent = JSON.parse(JSON.stringify(component));
            copyComponent.isCustom = true;

            if (copyComponent.type === 'Column') {
                copyComponent.type = 'Section';

                let customComponent = {
                    group: 'Section',
                    id: '',
                    name: component.props.title,
                    supergroup: 'Section',
                    template: copyComponent,
                    type: 'Section',
                };
                if (inWorkshop) {
                    let name = workshopComponent.props.title;
                    if (workshopComponent.props.group === 'Page Groups') {
                        name = workshopComponent.props.components[0].props.components[0].props.title;
                    }
                    customComponent = {
                        group: workshopComponent.props.group || 'Protocol Only',
                        id: '',
                        name,
                        supergroup: workshopLibraryFilter || 'Protocol Only',
                        template: { isCustom: true, ...workshopComponent },
                        type: workshopComponent.type,
                    };
                }
                setDialogOpen(true);
                if (!templatePayLoad) {
                    setTemplatePayLoad(customComponent);
                }
            } else if (copyComponent.type === 'Row') {
                const customComponent = {
                    group: copyComponent.type,
                    id: '',
                    name: component.props.title,
                    supergroup: copyComponent.type,
                    template: copyComponent,
                    type: copyComponent.type,
                };
                setDialogOpen(true);
                setTemplatePayLoad(customComponent);
            }
            setContextMenuTarget(null);
        }
    };

    /** On Saving Template */
    const onSaveTemplate = () => {
        createTemplate(templatePayLoad);
    };

    /** over ride functionality for duplicate templates */
    const overRideTemplate = () => {
        deleteTemplate(existingTemplate).then(onSaveTemplate);
    };

    /** callback from dialogbox */
    const onDialogSave = (payload) => {
        setTemplatePayLoad(payload);
        const byType = templateLibraryByType[payload.type];
        const filtered = byType && byType.filter((item) => (
            item?.supergroup === payload?.supergroup
            && item?.group === payload?.group
            && item?.name === payload?.name
        ));

        if (filtered?.length) {
            setOverRideDialogOpen(true);
            setExistingTemplate(filtered[0]);
        } else if (templatePayLoad) {
            onSaveTemplate();
        }
    };

    const savePOI = (type, contextMenuTargetRef) => {
        let poiObj = {};
        const procedureLibrary = ProcedureLibrary;
        switch (type) {
        case 'paste':
            const parentPOI1 = poiData.components_poi.find((p) => p.protocol_id === contextMenuTargetRef.parent.id);
            if (!parentPOI1) {
                const procdureChild1 = procedureLibrary.children.flatMap((item) => item.children);
                poiObj.column_id = contextMenuTargetRef.column.id;
                poiObj.protocol_id = copyComponent.id;
                poiObj.timeline_id = selectedTimeline.id;
                poiObj.poi_data = [...procedureLibrary.children, ...procdureChild1].reduce((acc, item) => ({ ...acc, [item.id]: true }), {});
            } else {
                poiObj = JSON.parse(JSON.stringify(parentPOI1));
                poiObj.protocol_id = copyComponent.id;
            }
            delete poiObj.id;
            break;
        case 'pasteHeaderChild':
            const parentPOI = poiData.components_poi.find((p) => p.protocol_id === contextMenuTargetRef.component.id);
            poiObj = JSON.parse(JSON.stringify(parentPOI));
            poiObj.protocol_id = copyComponent.id;
            delete poiObj.id;
            break;
        case 'pasteProtocolChild':
            const procdureChild = procedureLibrary.children.flatMap((item) => item.children);
            poiObj.column_id = contextMenuTargetRef.column.id;
            poiObj.protocol_id = copyComponent.id;
            poiObj.timeline_id = selectedTimeline.id;
            poiObj.poi_data = [...procedureLibrary.children, ...procdureChild].reduce((acc, item) => ({ ...acc, [item.id]: true }), {});
            break;
        default:
            break;
        }
        if (poiObj.protocol_id) {
            try {
                addComponentPOI({
                    variables: {
                        objects: [poiObj],
                    },
                });
            } catch {
                toast.error('Something went wrong.');
            }
        }
    };

    const contextMenuClick = (event, action, parentMenu, customContextMenuTarget) => {
        let colIndex;
        let componentToCopy;
        let copyPayload;
        let contextMenuTargetRef = contextMenuTarget;
        if (customContextMenuTarget) {
            contextMenuTargetRef = customContextMenuTarget;
        }
        const componentCD = allContentDisplayData?.['content_display'].find((i) => i.protocol_id === contextMenuTargetRef?.component?.id);
        const currentCDcomponent = componentCD?.['image_list']?.[componentCD?.['image_list'].length - 1]?.compTemplate;
        if (contextMenuTargetRef.column) {
            colIndex = selectedTimeline.columns.findIndex((col) => col.id === contextMenuTargetRef.column.id);
            setSelectedColumn(colIndex);
        }
        setCollapseProtocol([]);
        setCollapseProgressionComp([]);
        if (!workshopComponent) setScrollState({ hrz: window.pageXOffset, vertical: window.pageYOffset });
        switch (action.type) {
        case 'edit':
            setSpecificSelect({});
            setCurrentSelectedValue({});
            setAssociationValue({});
            setActionRelation(action);
            if (action.relation === 'edit-content-display') {
                setContentDisplayComponent(contextMenuTargetRef.component);
                editComponent(currentCDcomponent,
                    false,
                    contextMenuTargetRef.parent, action?.filter?.name,
                    contextMenuTargetRef.column, action?.filter?.name);
                setHierarchyComponent(contextMenuTargetRef.component);
            } else {
                editComponent(contextMenuTargetRef.component,
                    false,
                    contextMenuTargetRef.parent, action?.filter?.name,
                    contextMenuTargetRef.column, action?.filter?.name);
                setHierarchyComponent(contextMenuTargetRef.component);
            }
            setIsTemplateSelected(true);
            setProgressionAccCollapse(false);
            setIsEditMode(true);
            setIntitalTempSelected(true);
            setComplexFormType(contextMenuTargetRef?.component?.props?.cfType);
            break;
        case 'delete':
            showDeletionDialog(contextMenuTargetRef.parent, contextMenuTargetRef.index, contextMenuTargetRef.component);
            break;
        case 'addTextInput':
            const uniqueID = uuid();
            const newTextInput = {
                id: uniqueID,
                clonedFrom: contextMenuTargetRef?.component?.clonedFrom,
                parent: contextMenuTargetRef?.component?.parent,
                previewDomain: undefined,
                props: {
                    childOf: contextMenuTargetRef?.component?.parent,
                    filterAC: { isBasic: false, isAdvanced: false, isUserView: true },
                    hideFromWorkshop: false,
                    label: 'Text Input',
                    listTypePreview: '',
                    position: contextMenuTargetRef.index + 1,
                    prevTextField: 'Enter Text Input Title',
                    selectId: uniqueID,
                    textField: '',
                },
                type: 'TextInput',
            };
            if (contextMenuTargetRef?.parent?.components) {
                contextMenuTargetRef.parent.components.splice(contextMenuTargetRef.index + 1, 0, newTextInput);
            }
            break;
        case 'deleteWithoutChild':
            showDeletionDialog(contextMenuTargetRef.parent, contextMenuTargetRef.index, contextMenuTargetRef.component);
            setActionRelation(action);
            break;
        case 'deleteWithChild':
            showDeletionDialog(contextMenuTargetRef.parent, contextMenuTargetRef.index, contextMenuTargetRef.component);
            setActionRelation(action);
            break;
        case 'copy':
            setCopyProtocolComponent(true);
            componentToCopy = contextMenuTargetRef?.parent?.components[contextMenuTargetRef?.index];
            if (contextMenuTargetRef?.component?.name === 'Page') {
                copyPayload = copyTemplateForPage(componentToCopy);
                copyPayload.props = copyPayload[domain];
            } else if (contextMenuTargetRef?.component?.name === 'Images/Videos') {
                copyPayload = copyTemplateWithAssociatedCell(componentToCopy, true);
            } else {
                copyPayload = copyTemplateWithAssociatedCell(componentToCopy);
            }
            setCopyComponent(copyPayload);
            updateTimeline();
            break;
        case 'cut':
            setCopyProtocolComponent(true);
            componentToCopy = contextMenuTargetRef?.parent?.components[contextMenuTargetRef?.index];
            if (contextMenuTargetRef?.component?.name === 'Page') {
                copyPayload = copyTemplateForPage(componentToCopy);
                copyPayload.props = copyPayload[domain];
            } else if (contextMenuTargetRef?.component?.name === 'Images/Videos') {
                copyPayload = copyTemplateWithImg(componentToCopy);
            } else {
                copyPayload = copyTemplateWithAssociatedCell(componentToCopy);
            }
            setCopyComponent(copyPayload);
            // setCopyComponent(copyTemplateWithImg(contextMenuTargetRef?.parent?.components[contextMenuTargetRef?.index]));
            const pasteCompoTree = [];
            buildSettingsTreePage(pasteCompoTree, contextMenuTargetRef.column);
            const copyComponentArray = contextMenuTargetRef.parent.components;
            contextMenuTargetRef.parent.components = [...copyComponentArray.slice(0, contextMenuTargetRef.index),
                ...copyComponentArray.slice(contextMenuTargetRef.index + 1)];
            if (contextMenuTargetRef.parent.id === contextMenuTargetRef.column.id) {
                contextMenuTargetRef.column.props.components = contextMenuTargetRef.parent.components;
            } else {
                const targetElem = pasteCompoTree.find((item) => item.id === contextMenuTargetRef.parent.id);
                if (targetElem) {
                    targetElem.props.components = contextMenuTargetRef.parent.components;
                }
            }
            updateTimeline();
            break;
        case 'paste':
            if (copyProtocolComponent) {
                const pasteCompoTree = [];
                buildSettingsTreePage(pasteCompoTree, contextMenuTargetRef.column);
                const copyComponentArray = contextMenuTargetRef.parent.components;
                contextMenuTargetRef.parent.components = [...copyComponentArray.slice(0, contextMenuTargetRef.index + 1),
                    copyComponent, ...copyComponentArray.slice(contextMenuTargetRef.index + 1)];
                if (contextMenuTargetRef.parent.id === contextMenuTargetRef.column.id) {
                    contextMenuTargetRef.column.props.components = contextMenuTargetRef.parent.components;
                } else {
                    const targetElem = pasteCompoTree.find((item) => item.id === contextMenuTargetRef.parent.id);
                    if (targetElem) {
                        targetElem.props.components = contextMenuTargetRef.parent.components;
                    }
                }
                setCopyProtocolComponent(false);
                updateTimeline();
                savePOI(action.type, contextMenuTargetRef);
            }
            break;
        case 'pasteHeaderChild':
            if (copyProtocolComponent) {
                const pasteCompoTree = [];
                buildSettingsTreePage(pasteCompoTree, contextMenuTargetRef.column);
                const copyComponentArray = contextMenuTargetRef.component.props.components;

                if (copyComponent?.props?.type === 'Protocol' && copyComponent?.props?.childOf !== contextMenuTargetRef.component.id) {
                    copyComponent.props.childOf = contextMenuTargetRef.component.id;
                }

                contextMenuTargetRef.component.props.components = [copyComponent, ...copyComponentArray];
                const targetElem = pasteCompoTree.find((item) => item.id === contextMenuTargetRef.parent.id);
                if (targetElem) {
                    targetElem.props.components = contextMenuTargetRef.parent.components;
                }
                setCopyProtocolComponent(false);
                updateTimeline();
                savePOI(action.type, contextMenuTargetRef);
            }
            break;
        case 'pasteProtocolChild':
            if (copyProtocolComponent) {
                const pasteCompoTree = [];
                buildSettingsTreePage(pasteCompoTree, contextMenuTargetRef.column);
                const copyComponentArray = contextMenuTargetRef.component.props.components;
                contextMenuTargetRef.component.props.components = [copyComponent, ...copyComponentArray];
                const targetElem = pasteCompoTree.find((item) => item.id === contextMenuTargetRef.parent.id);
                if (targetElem) {
                    targetElem.props.components = contextMenuTargetRef.component.props.components;
                }
                if (contextMenuTargetRef?.parent?.type === 'Column' && copyComponent?.props?.childOf) {
                    delete copyComponent.props.childOf;
                }
                setCopyProtocolComponent(false);
                updateTimeline();
                savePOI(action.type, contextMenuTargetRef);
            }
            break;
        case 'addColumn':
            addColumn(action, contextMenuTargetRef.component, contextMenuTargetRef.parent, contextMenuTargetRef.index);
            setSelectedParent(contextMenuTargetRef.parent);
            break;
        case 'duplicate':
            if (contextMenuTargetRef.parent && contextMenuTargetRef.parent.type === 'Timeline') {
                showDuplicateColumnDialog(contextMenuTargetRef.parent, contextMenuTargetRef.index, contextMenuTargetRef.component);
            } else {
                duplicateComponent(contextMenuTargetRef.component,
                    contextMenuTargetRef.parent.components || contextMenuTargetRef.parent.props.components,
                    contextMenuTargetRef.index);
            }
            break;
        case 'removeMedia':
            delete contextMenuTargetRef?.component?.props?.imgSrc;
            break;
        case 'editMedia':
            setImageEditorDialogWs(true);
            setImageEditorDataWs(contextMenuTargetRef?.component?.props?.imgSrc);
            break;
        case 'setProperty':
            setComponentProperty(contextMenuTargetRef.component, action.props);
            break;
        case 'accessControl':
            setAccessControlFlow(contextMenuTargetRef.component, contextMenuTargetRef.column, contextMenuTargetRef.parent);
            break;
        case 'accessView':
            setAccessView(contextMenuTargetRef.component, action.value);
            break;
        case 'accessViewHeader':
            setAccessViewHeader(contextMenuTargetRef.component, action.value);
            break;
        case 'insert':
            setSpecificSelect({});
            setCurrentSelectedValue({});
            setAssociationValue({});
            const type = action.template ? 'template' : 'component';
            let id = action.template ? action.template?.id : action.component?.id;
            if (workshopLibraryFilter === 'Complex Forms' && action && action.component?.type === 'Row') {
                id = `cl-16-${action.component.value}`;
            }
            const useLibrary = action.component && action.component.useLibrary;
            const libraryFilter = action.component && action.component.libraryFilter;
            let { components } = contextMenuTargetRef.component.type === 'Page' ? contextMenuTargetRef.component.props : contextMenuTargetRef.parent;
            let index = contextMenuTargetRef.index + 1;
            if (action.relation === 'child' || (action.relation === 'insert-content-display' && contextMenuTargetRef.component.isProgression)) {
                components = contextMenuTargetRef.component.props.components;
                if (!contextMenuTargetRef.component.props.components) {
                    components = [];
                    contextMenuTargetRef.component.props.components = components;
                }

                index = 0;
                // if (clickedArea === 'Progression' || clickedArea === 'Protocol') {
                //     index = 0;
                // } else {
                //     index = contextMenuTargetRef.component.props.components.length;
                // }
            }
            let { parent } = contextMenuTargetRef;
            let targetComp = contextMenuTargetRef.component;
            if (action.relation === 'sibling') {
                index = contextMenuTargetRef.index + 1;
                if (contextMenuTargetRef.component.type === 'Page' && inWorkshop) {
                    parent = pageComponent?.[domain]?.components;
                    components = contextMenuTargetRef?.component[domain]?.components || components;
                    index = parent.length;
                    targetComp = pageComponent?.[domain]?.components[0];
                }
            }
            if (action.relation === 'parent') {
                const isProgression = !!((clickedArea === 'Progression' || targetComp.isProgression));
                const locationMap = getLocationOfTargetComponent(contextMenuTargetRef.component, colIndex, false, null, isProgression);
                if (locationMap && locationMap.length > 0) {
                    let targetElement = contextMenuTargetRef.column.props.components;
                    if (isProgression) {
                        targetElement = contextMenuTargetRef.column.props.progressions;
                    }
                    for (let iterator = 0; iterator <= locationMap.length - 1; iterator += 1) {
                        const { type } = targetElement[locationMap[iterator]].props;
                        const ele = targetElement;
                        targetElement = targetElement[locationMap[iterator]].props.components;
                        if (type === action.component.key) {
                            index = locationMap[iterator] + 1;
                            if (iterator === 0) {
                                components = isProgression
                                    ? contextMenuTargetRef.column.props.progressions
                                    : contextMenuTargetRef.column.props.components;
                            } else {
                                components = ele;
                            }
                            break;
                        }
                    }
                }
            }

            if (action.relation === 'insert-content-display') {
                setContentDisplayComponent(contextMenuTargetRef.component);
            }

            if (!useLibrary) {
                setIntitalTempSelected(true);
            }
            insertComponent(type, id, components, index,
                useLibrary, parent,
                libraryFilter, contextMenuTargetRef.column,
                targetComp, colIndex, action, true);
            setActionRelation(action);
            break;
        case 'saveAsTemplate':
            saveAsTemplate(contextMenuTargetRef.component);
            break;
        case 'leftColumn':
            addColumnLeftAndRight(contextMenuTargetRef.component, true, 'Column', contextMenuTargetRef.index);
            setSelectedColumn(contextMenuTargetRef.index);
            setActionRelation(action);
            setSelectedParent(contextMenuTargetRef.parent);
            break;
        case 'rightColumn':
            addColumnLeftAndRight(contextMenuTargetRef.component, true, 'Column', contextMenuTargetRef.index + 1);
            setSelectedColumn(contextMenuTargetRef.index + 1);
            setActionRelation(action);
            setSelectedParent(contextMenuTargetRef.parent);
            break;
        case 'saveAsColumnTemplate':
            addAsTemplate(contextMenuTargetRef);
            break;
        case 'dataMatrix':
            const i = colIndex !== undefined ? colIndex : selectedColumn;
            const clickAreaType = !!((clickedArea === 'Progression' || contextMenuTargetRef?.component?.isProgression));
            const timelineColumn = selectedTimeline.columns[i];
            if (timelineColumn?.props?.components.length === 0) {
                setShowEmptyDMDialog(true);
                break;
            }
            setShowEmptyDMDialog(false);
            setSelectedColIndex(i);
            setSelectedColumn(i);
            // setIsProgressionDM(contextMenuTargetRef?.parent?.isProgression);
            setIsProgressionDM(clickAreaType);
            setDataMatrixComponent(timelineColumn);
            setSelectedDMUAComponent(contextMenuTargetRef?.component?.type === 'Column'
                ? timelineColumn?.props?.components?.[0] : contextMenuTargetRef.component);
            setDMTemplateLibraryFlag(true);
            setIsDMRC(true);
            setSelectedDMWorkshopComponent(contextMenuTargetRef?.component?.type === 'Column'
                ? timelineColumn?.props?.components?.[0] : contextMenuTargetRef.component);
            history.push(`/${currentOrg.orgCode}/timeline/${selectedTimeline.id}/dataMatrix`);
            clearWorkshopComponent();
            setInWorkshop(true);
            // setWorkshopAccessView({...workshopAccessData});
            setWorkshopComponent(contextMenuTargetRef?.component?.type === 'Column'
                ? timelineColumn?.props?.components?.[0] : contextMenuTargetRef.component);
            setSelectedComponent(contextMenuTargetRef?.component?.type === 'Column'
                ? timelineColumn?.props?.components?.[0] : contextMenuTargetRef.component);
            setCloneWorkshopComp(copyTemplate(contextMenuTargetRef?.component?.type === 'Column'
                ? timelineColumn?.props?.components?.[0] : contextMenuTargetRef.component));
            break;
        case 'editHyperLink':
        case 'addHyperLink':
            setHyperlinkCip(true);
            setPageHyperlinkCip(true);
            setHyperlinkCipText(contextMenuTargetRef.component?.props?.title);
            setHyperlinkComponent(contextMenuTargetRef.component);
            break;
        case 'removeHyperLink':
            delete contextMenuTargetRef.component?.props?.hyperlink;
            updateTimeline();
            break;
        case 'editVideoLink':
        case 'addVideoLink':
            setHyperlinkCip(true);
            setVideoHyperlinkCip(true);
            setHyperlinkCipText(contextMenuTargetRef.component?.props?.title);
            setHyperlinkComponent(contextMenuTargetRef.component);
            break;
        case 'removeVideoLink':
            delete contextMenuTargetRef.component?.props?.videoHyperlink;
            updateTimeline();
            break;
        // case 'addContentDisplay':
        //     setContentDisplayCip('insert');
        //     setContentDisplayComponent(contextMenuTargetRef.component);
        //     break;
        // case 'editContentDisplay':
        //     setContentDisplayCip('edit');
        //     setContentDisplayComponent(contextMenuTargetRef.component);
        //     break;
        case 'removeContentDisplay':
            delete contextMenuTargetRef?.component?.props?.hasContentDisplay;
            setContentDisplayLoader(true);
            deleteContentDisplay({
                variables: {
                    protocol_id: contextMenuTargetRef?.component?.id,
                },
            }).then(() => {
                refetchContentDisplay();
                setContentDisplayLoader(false);
            });
            break;
        default:
            break;
        }
        setContextMenuPosition(null);
    };

    const handleContextMenuOption = (event, index, selectId) => {
        event.stopPropagation();
        event.preventDefault();
        setContextMenuPosition({
            top: event.clientY,
            left: event.clientX,
        });
        setContextMenuTarget({
            component: { type: 'Option', selectId, index }, parent: selectId,
        });
        const menuBuilder = ComponentEditorMenu.WorkshopMenu;
        const componentMenu = menuBuilder({
            component: { type: 'Option', selectId, index }, parent: { selectId }, componentLibrary, templateLibraryByType, copyProtocolComponent,
        });
        setContextMenuConfig(componentMenu);
    };
    const handleContextMenu = (event, component, parent, index, column, selectCF = false, lastCompType) => {
        event.stopPropagation();
        event.preventDefault();
        let { type } = component;
        if (timelineShareMode) {
            return;
        }
        if (column?.props?.isProfile && type === 'Protocol'
            && (component?.props?.title?.toLowerCase() === 'name' || component?.props?.title?.toLowerCase() === 'email')) return;

        if (component.type === 'Text' && !component.props.components?.length) {
            MenuDefaults.DELETE.label = 'Delete without children';
            delete MenuDefaults.DELETE?.children;
        } else {
            MenuDefaults.DELETE.label = 'Delete';
        }

        if (type === 'Timeline' || (type === 'Protocol' && component.props.hideFromWorkshop) || isPageModalOpen) { return; }
        if (dataMatrixComponent && isDMRC) {
            setIsDMRC(true);
            return;
        }

        // if (dataMatrixComponent && isDMRC) {
        //     type = 'DataMatrixWorkshopMenu';
        // }
        if ((type === 'Protocol' || type === 'Text' || type === 'ColumnSection' || type === 'Column')
        && (loggedUserType === 'Patient')) { return; }

        if (inWorkshop && column !== undefined && !selectCF) { return; }

        if (inWorkshop && component.type === 'Gallery' && !selectCF) { return; }

        if (inWorkshop && component.type !== 'Column' && !selectCF) {
            type = 'WorkshopMenu';
        }

        if (inWorkshop && component.type === 'Row') {
            type = 'WorkshopAddMenu';
        }

        if (inWorkshop && component?.type === 'Image' && component?.props?.imgSrc) {
            type = 'WorkshopImageMenu';
        }

        // getting delete menu on text click
        if (type === 'Text' && !inWorkshop && parent && parent.isTimlineCol) {
            type = 'TimelineDeleteTextMenu';
        }

        const menuBuilder = ComponentEditorMenu[type] || DefaultEditMenu;
        let componentMenu = menuBuilder({
            component, parent, componentLibrary, templateLibraryByType, copyProtocolComponent,
        });

        if (selectCF) {
            // const deleteComp = componentMenu.find((c) => c.label === 'Delete');
            const first = componentMenu.find((c) => c.label === 'Add Child');
            const formMenu = first.children.find((c) => c.label === 'Forms').children;
            // if (lastCompType === 'Select' || lastCompType === 'MultiSelect') {
            //     componentMenu = formMenu.filter((menu) => menu.label === 'Single Select' || menu.label === 'Multi Select');
            // } else if ((lastCompType === 'Checkbox' || lastCompType === 'Radio Group') && columnPosition === 1) {
            //     componentMenu = formMenu.filter((menu) => menu.label === 'Single Select' || menu.label === 'Multi Select' || menu.label === 'Checkbox' || menu.label === 'Radio Group');
            // } else if ((lastCompType === 'Checkbox' || lastCompType === 'Radio Group') && columnPosition === 2) {
            //     componentMenu = formMenu.filter((menu) => menu.label === 'Checkbox' || menu.label === 'Radio Group');
            // } else {
            //     componentMenu = formMenu.filter((menu) => menu.label === 'Checkbox'
            //     || menu.label === 'Checkbox Group' /* || menu.label === 'Text Input' */
            //     || menu.label === 'Single Select'
            //     || menu.label === 'Multi Select'
            //     || menu.label === 'Radio Group' /* || menu.label === 'Label' */);
            // }
            if ((lastCompType === 'Select' || lastCompType === 'MultiSelect') && workshopComponent?.props?.cfType !== 'POI') {
                componentMenu = formMenu.filter((menu) => menu.label === 'Single Select' || menu.label === 'Multi Select');
            } else if (workshopComponent?.props?.cfType === 'POI' && workshopComponent?.component === 'Group Options') {
                componentMenu = formMenu.filter((menu) => menu.label === 'Single Select'); // Single Select DropDown in CF Group Options - Select Component
            } else {
                componentMenu = formMenu.filter((menu) => menu.label === 'Checkbox'
                || menu.label === 'Checkbox Group' /* || menu.label === 'Text Input' */
                || menu.label === 'Single Select'
                || menu.label === 'Multi Select'
                || menu.label === 'Radio Group' /* || menu.label === 'Label' */
                || menu?.action?.component?.type === 'TextAreaCF'
                || menu?.action?.component?.type === 'TextBoxCF');
            }
            // componentMenu.push(deleteComp);
        }

        // if (column?.props?.isProfile || component.props.isMedicalHistory) {
        //     componentMenu = componentMenu.filter((menu) => menu.label === 'Add Protocol'
        //         || menu.label === 'Add Header' || menu.label === 'Add H1 Header'
        //         || menu.label === 'Edit' || menu.label === 'Delete');
        // }

        if (!inWorkshop && component.type === 'Column' && parent.type !== 'Timeline' && !isDMRC) {
            componentMenu = [];
            return;
        }

        if ((inWorkshop && component.type === 'Text')) {
            componentMenu = [];
            return;
        }

        if (workshopComponent && workshopComponent.id === component.id) {
            componentMenu = [];
            return;
        }

        if (component?.props?.selectedTemplateLibrary && component?.props?.selectedTemplateLibrary?.length > 0) {
            componentMenu = addTLSubChildrenMenu(component, componentMenu); // TL means Template Library
        }

        // Delete children of delete menu option
        const deleteIndex = componentMenu.findIndex((menu) => menu?.label === 'Delete');
        if (deleteIndex && componentMenu[deleteIndex] && componentMenu[deleteIndex].children) {
            if (type !== 'TimelineDeleteTextMenu' || (type === 'TimelineDeleteTextMenu' && component.props.components.length === 0)) {
                delete componentMenu[deleteIndex].children;
            }
        }

        const dmIndex = componentMenu?.findIndex((menu) => menu?.label === 'Data Matrix');
        if (dmIndex && componentMenu[dmIndex] && inWorkshop) {
            componentMenu.splice(dmIndex, 1);
        }

        setContextMenuConfig(componentMenu);
        if (!component.clickedArea) {
            if (parent.isProgression && parent.isProgression === true) {
                setClickedArea('Progression');
            } else if (parent.isProgression && parent.isProgression === false) {
                setClickedArea('Protocol');
            } else if (component.props.isMedicalHistory) {
                setClickedArea('Medical History');
            } else {
                setClickedArea(null);
            }
        } else {
            setClickedArea(component.clickedArea);
        }

        if (!column && parent?.type === 'Timeline') {
            column = { ...component };
        }
        setAdminColumn({ ...component });
        setContextMenuPosition({
            top: event.clientY,
            left: event.clientX,
            // eslint-disable-next-line no-unneeded-ternary
            isFirstCol: component?.props?.associatedComponent ? false : true,
        });
        setContextMenuTarget({
            component, parent, index, column,
        });
        if (component.type === 'OptionCell') {
            const selectCell = findComponent(workshopComponent?.props.components, component.props.parentCellId);
            const colComp = findComponent(workshopComponent?.props.components, selectCell.colRef);
            const allChildren = colComp.props.components.flatMap((item) => item.props.components);
            const alreadySelectedElem = allChildren.find((item) => item.props.components.length > 0);
            const hasType = Boolean(alreadySelectedElem);
            const children = component?.props?.components;
            // if (actionRelation.type === 'edit') {
            //     hasType = false;
            // }
            if (hasType && children && children?.length === 0) {
                const customAction = alreadySelectedElem.props.components[0].action;
                const customContextMenuTarget = {
                    component, parent, index, column,
                };
                contextMenuClick(event, customAction, null, customContextMenuTarget);
            }
        }
        if (component.type === 'Cell' && component.colRef) {
            const colComp = findComponent(workshopComponent?.props.components, component.colRef);
            const alreadySelectedElem = colComp?.props?.components.find((item) => item.props.components.length > 0);
            const hasType = Boolean(alreadySelectedElem);
            const children = component?.props?.components;
            // if (actionRelation?.type === 'edit') {
            //     hasType = false;
            // }
            if (colComp?.props?.isComplexForm && hasType && children && children?.length === 0) {
                let customAction = alreadySelectedElem.props.components[0].action;
                if (workshopComponent?.props?.['wow feature']) {
                    customAction = wowAction;
                }
                const customContextMenuTarget = {
                    component, parent, index, column,
                };
                contextMenuClick(event, customAction, null, customContextMenuTarget);
            }
        }

        if (component.props.isComplexForm && index > 0 && component.props.components.length > 0) {
            if (contextMenuTarget) {
                contextMenuTarget.component = component;
                contextMenuTarget.parent = parent;
                contextMenuTarget.index = index;
                contextMenuTarget.column = column;
            }
            contextMenuClick(event, actionRelation, null);
        }
        setActiveSelectSuggestParent(null);
        handleColumnClick(event, column);
    };

    const updateComponent = (component, saveAfterUpdate = true, deslectComponentAfterSave = true, saveCallback, doNotSendReq) => { // config
        if (saveAfterUpdate) {
            if (deslectComponentAfterSave) {
                setSelectedComponent({});
            }
            if (!doNotSendReq) updateTimeline(saveCallback);
        }
    };

    /**
     * @function updatePOI [update the state]
     * @param {object} poi
     * @returns {object} updated state
     */
    const updatePOI = (poi) => {
        setSelectedPOI(poi);
    };

    const handleSelectedPOI = (selected, selection) => {
        setSelectedPOIProc(selected);
        setSelectedPOIParentProc(selection);
    };

    const handleSettingMenuType = (type) => {
        setSettingMenuType(type);
    };

    /**
     * @function handleChangeSettingAccordian [update the state]
     * @param {number} panel
     * @returns {object} component
     * @param {number} isExpanded
     */
    const handleChangeSettingAccordian = (panel, component, isExpanded) => {
        setExpandedAccordian(isExpanded ? panel : false);
        setWorkshopActiveComponent(component);
        if (component?.type === 'Column') {
            setWorkshopSC(component);
        }

        if (settingComponent && settingComponent.id === component.id) {
            setSettingComponent(null);
        } else {
            setSettingComponent(component);
        }
    };

    /**
     * @function clearSettingAccordian [update the state]
     * clear expanded accordian value
     * clear setting component value
     */
    const clearSettingAccordian = (progressionColumnChange) => {
        setExpandedAccordian(null);
        setSettingComponent(null);
        setIsLoadedAccordion(false);
        setProgressionExpansion(progressionColumnChange);
        setWorkshopActiveComponent(null);
        setAssociationData([]);
        if (!progressionColumnChange) {
            // NOTE: do not reset library filter in case of progression next/previous buttons
            setWorkshopLibraryFilter(null);
        }
    };

    const handleAssoctiaonChange = (nextOptions) => {
        const index = associationData.findIndex((d) => d.id === nextOptions.id);
        if (index !== -1) {
            associationData.splice(index, 1);
        }
        const data = [...associationData];
        if (nextOptions.association) {
            data.push(nextOptions);
        }
        setAssociationData(data);
    };

    /**
     * @function addRowColumnHandler [update the state]
     * @param {event} e
     * @returns {object} row
     */
    const addRowColumnHandler = (e, row) => {
        // let propsComp = workshopComponent && workshopComponent.props.components;

        // if (!propsComp && workshopSelectedTemplate && !timelineMatch) {
        //     propsComp = workshopSelectedTemplate.template.props.components;
        // }
        // const childComp = findComponent(propsComp, row.id, 'Row');

        // if (!childComp || childComp.length === 0) {
        //     return;
        // }
        const id = addColumnObj.blankColumnId;
        const template = componentLibraryMap[id];
        const clone = copyTemplate(template);
        row.props.components.push(clone);
    };

    /**
     * @function addRowHandler [update the state]
     * @param {event} e
     * @returns {object} row
     */
    const addRowHandler = (e, row) => {
        let propsComp = workshopComponent && workshopComponent.props.components;

        if (!propsComp && workshopSelectedTemplate && !timelineMatch) {
            propsComp = workshopSelectedTemplate.template.props.components;
        }
        const protocolComps = findProtocolComp(propsComp, row.parent, row);

        if (!protocolComps) {
            return null;
        }
        const id = `cl-16-${row.props.components.length}`;
        const template = componentLibraryMap[id];
        const clone = copyTemplate(template);
        protocolComps[0].splice(protocolComps[1] + 1, 0, clone);
        return null;
    };

    const handleWarningDialogLRClose = (ok) => {
        if (ok && copyLRColumnState && copyLRColumnState.type === 'left') {
            const parentComp = findComponent(workshopComponent.props.components, workshopActiveComponent.parent);
            const compIndex = parentComp.props.components.findIndex((comp) => comp.id === workshopActiveComponent.id);
            workshopActiveComponent.props.components.forEach((activeComp, i) => {
                parentComp.props.components[compIndex + 1].props.components[i] = copyTemplate(activeComp);
            });
            setWorkshopComponent({ ...workshopComponent });
        } else if (ok && copyLRColumnState && copyLRColumnState.type === 'right') {
            const parentComp = findComponent(workshopComponent.props.components, workshopActiveComponent.parent);
            const compIndex = parentComp.props.components.findIndex((comp) => comp.id === workshopActiveComponent.id);
            workshopActiveComponent.props.components.forEach((activeComp, i) => {
                parentComp.props.components[compIndex - 1].props.components[i] = copyTemplate(activeComp);
            });
            setWorkshopComponent({ ...workshopComponent });
        }
        setCopyLRColumnState(null);
    };

    const handleRightColumn = () => {
        setCopyLRColumnState({ open: true, type: 'right' });
    };

    const handleLeftColumn = () => {
        setCopyLRColumnState({ open: true, type: 'left' });
    };

    const handleDMCompClick = (component) => {
        setIsDMRC(true);
        setSelectedDMWorkshopComponent(component);
        setSelectedDMUAComponent(component);
        setWorkshopComponent(component);
        setSelectedComponent(component);
        setCloneWorkshopComp(copyTemplate(component));
        setDMTemplateLibraryFlag(false);
    };

    const handleNewColumn = (column) => {
        if (workshopComponent && ((complexFormType !== defaultData.selectSuggest && complexFormType !== 'POI')
        || column?.props?.LRProps?.LRtype || column?.props?.isMixedForm)) {
            const findParent = findPage(workshopComponent?.props?.components, column?.parent);
            const findColIndex = findParent && findParent[0]?.props?.components?.findIndex((c) => c.id === column.id);
            const maxColIndex = column?.props?.LRProps ? 1 : 2;

            if (findParent && findColIndex !== undefined
                && !findParent[0]?.props?.components[findColIndex + 1] && findColIndex !== -1 && findColIndex < maxColIndex) {
                // const id = addColumnObj.blankColumnId;
                const id = column?.props?.isMixedForm ? addColumnObj.blankMixedColumnId : addColumnObj.blankColumnId;
                const template = componentLibraryMap[id];
                const clone = copyTemplate(JSON.parse(JSON.stringify(template)));
                const parentComp = findComponent(workshopComponent?.props?.components, column?.parent);
                if (parentComp.props.LRProps) clone.props.LRProps = { ...parentComp.props.LRProps };
                if (column?.props?.cfType === defaultData.selectSuggest) {
                    clone.props.cfType = defaultData.selectSuggest;
                }
                if (clone.type === 'Column' && findParent[0]?.type === 'Row') {
                    clone.props.index = findParent[0].props.components.length;
                }
                findParent[0].props.components.push(clone);
                setNewColumnCFFlag(!newColumnCFFlag);
            }
        }
    };

    const clearDMComponent = () => {
        setIsDMRC(false);
        setDataMatrixComponent(null);
        setSelectedDMWorkshopComponent(null);
        setSelectedDMRClickMenuComp(null);
        // setDMTemplateLibraryFlag(false);
        setIsProgressionDM(false);
        setSelectedColIndex(null);
        setSelectedColumn(0);
        setInWorkshop(false);
        setWorkshopComponent(null);
        setSelectedComponent(null);
        setCloneWorkshopComp(null);
        setActionRelation(null);
        setCompPreviewData(null);
    };

    const createNewComponent = (type = null) => {
        let id = null;
        if (type === 'isColumn') id = addColumnObj.timelineColumnId;
        else if (type === 'isTimeline') id = addColumnObj.timelineLabelId;
        const template = componentLibraryMap[id];
        const clone = copyTemplate(template);
        return clone;
    };

    const expandTLAccordion = (filteredLibrary, template) => {
        const parentListData = [];
        findCompParentTL(filteredLibrary, template, parentListData);
        setTLAccordionData(parentListData);
    };
    const updateSettingsAccess = ({ type, setting, access }) => {
        // console.log({type,setting,access,orgID:currentOrg.id})
        showLoader(true);
        updateSettingsAccessMutation({
            variables: {
                type,
                setting,
                access,
                orgID: currentOrg.id,
            },
        }).then(() => {
            showLoader(false);
            // console.log('settings saved successfully', { data });
        }).catch(() => {
            // console.log({ err });
            // alert('Failed to save settings access');
        });
    };

    /**
     * @function handleDMColumnChange [update the state] call on change column value in data matrix
     * @returns {number} columnId
     */
    const handleDMColumnChange = (columnId) => {
        const timelineColumn = selectedTimeline.columns[columnId];
        setSelectedColIndex(columnId);
        setSelectedColumn(columnId);
        setDataMatrixComponent(timelineColumn);
        setSelectedDMUAComponent(timelineColumn?.props?.components[0]);
        setSelectedDMWorkshopComponent(timelineColumn?.props?.components[0]);
        setWorkshopComponent(timelineColumn?.props?.components[0]);
        setSelectedComponent(timelineColumn?.props?.components[0]);
        setCloneWorkshopComp(timelineColumn?.props?.components[0]);
    };

    const handleColumnClick = (e, column, component = null) => {
        // e.preventDefault();
        e.stopPropagation();
        if (!isMobile) {
            if ((column?.props?.group === 'Column' || column?.props?.isProfile) && !timelineShareMode) {
                const colWidth = findTimelineColumnWidth(selectedTimeline);
                const columnWidthObj = {
                    id: column.id,
                    colWidth,
                    highlightColWidth: column?.props?.isProfile ? (colWidth + 122) : (colWidth + 250),
                    column,
                };
                setColumnWidthData(columnWidthObj);
                if ((columnWidthData && column?.id !== columnWidthData?.id && component?.type !== 'Protocol') || !component) setCDComponent(null);
            }

            if (timelineShareMode) {
                const columnWidthObj = {
                    // id: column.id,
                    column,
                };
                setColumnWidthData(columnWidthObj);
            }
        }
    };

    const timelineContext = {
        currentProtocolParents,
        setCurrentProtocolParents,
        currentProtocol,
        setCurrentProtocol,
        checkIfLAGyno,
        activeRow,
        setActiveRow,
        userSettingsValues,
        setUserSettingsValues,
        userSettingsViewChanges,
        setUserSettingsViewChanges,
        settingUserViewDialog,
        setSettingUserViewDialog,
        columnChangeDialog,
        setColumnChangeDialog,
        timelineMatch,
        currentOrg,
        updateSettingsAccess,
        settingsAccessData: settingsAccessResponse?.['settings_access'],
        activeStep,
        setActiveStep,
        selectedComponent,
        selectedParent,
        rootComponent,
        setSelectedComponent,
        setFilterTemplateBrowser,
        filterTemplateBrowser,
        shareWithDoctorTimelineData,
        timelinesLoading,
        timelineError,
        workshopLoading,
        setWorkshopLoading,
        timelines,
        createTimeline,
        addColumn,
        contextMenuConfig,
        contextMenuPosition,
        setSelectedTemplate,
        selectedTemplate,
        handleContextMenu,
        handleContextMenuOption,
        contextMenuClose,
        contextMenuClick,
        contextMenuTarget,
        selectedColIndex,
        setSelectedColIndex,
        selectedTimeline,
        setSelectedTimeline,
        domain,
        setDomain,
        updateComponent,
        workshopComponent,
        workshopColumn,
        workshopColumnCopy,
        setWorkshopColumnCopy,
        isLoadedAccordion,
        setIsLoadedAccordion,
        clearWorkshopComponent,
        saveWorkshopAsTemplate,
        templateLibraryByType,
        templateLibraryById,
        workshopUseLibrary,
        setWorkshopUseLibrary,
        workshopLibraryFilter,
        setWorkshopLibraryFilter,
        progressionFilter,
        styleComponent,
        updatePOI,
        handleSelectedPOI,
        selectedPOIProc,
        selectedPOIParentProc,
        selectedPOI,
        saveTemplate,
        createTemplate,
        deleteTemplate,
        deleteTimeline,
        duplicateComponent,
        showDeletionDialog,
        showHiddenComponents,
        setShowHiddenComponents,
        getComponentRef,
        clickedArea,
        setWorkshopComponent,
        selectedColumn,
        setSelectedColumn,
        isAccessControl,
        setAccessControl,
        setSelectedColumns,
        componentPOI,
        setComponentPOI,
        componentParentPOI,
        setComponentParentPOI,
        selectedColumns,
        selectedView,
        actionRelation,
        removeComponent,
        deleteConfirmationDialogVisibility,
        setDeleteConfirmationDialogVisibility,
        deleteTargetParentAndIndex,
        editComponent,
        setActionRelation,
        inWorkshop,
        setInWorkshop,
        addSelectedColumn,
        isProgressionExpanded,
        setProgressionExpansion,
        progressionComponentRoute,
        setProgressionComponentRoute,
        getLocationOfTargetComponent,
        initialPOI,
        setInitialPOI,
        isTemplateSelected,
        setIsTemplateSelected,
        duplicateColumnNameDialogVisibility,
        setDuplicateColumnNameDialogVisibility,
        columnDuplicateTargetParentAndIndex,
        setColumnDuplicateTargetParentAndIndex,
        settingMenuType,
        setSettingMenuType,
        handleSettingMenuType,
        settingComponent,
        handleChangeSettingAccordian,
        expandedAccordian,
        clearSettingAccordian,
        setSettingComponentHandle,
        timeLineVisible,
        setTimeLineVisible,
        setExpandedAccordian,
        cloneWorkshopComp,
        isChildSibling,
        workshopActiveComponent,
        setWorkshopActiveComponent,
        pageComponent,
        setPageComponent,
        dirtyDomain,
        setDirtyDomain,
        copyProtocolComponent,
        setCopyProtocolComponent,
        setCopyComponent,
        copyComponent,
        handleAssoctiaonChange,
        associationData,
        addRowColumnHandler,
        addRowHandler,
        setProgressionFilter,
        previousTemplate,
        setPreviousTemplate,
        setWorkshopSelectedTemplate,
        workshopSelectedTemplate,
        handleLeftColumn,
        handleRightColumn,
        copyLRColumnState,
        handleWarningDialogLRClose,
        selectedViewHeader,
        setSelectedViewHeader,
        selectedHeader,
        setSelectedHeader,
        filteredChildIds,
        setFilteredChildIds,
        previewComponent,
        setPreviewComponent,
        clearCurrentAssociation,
        updateTimeline,
        setClearCurrentAssociation,
        // Data Matrix Export Item start
        setDataMatrixComponent,
        dataMatrixComponent,
        isDMRC,
        setIsDMRC,
        setAccessView,
        handleDMCompClick,
        selectedDMWorkshopComponent,
        dmTemplateLibraryFlag,
        setDMTemplateLibraryFlag,
        clearDMComponent,
        selectedDMRClickMenuComp,
        setSelectedDMRClickMenuComp,
        selectedDMUAComponent,
        setSelectedDMUAComponent,
        isProgressionDM,
        setIsDMViewMode,
        isDMViewMode,
        // Data Matrix Export Item end
        handleNewColumn,
        selectedCF,
        setSelectCompCF,
        selectCompCF,
        specificSelect,
        setSpecificSelect,
        updateFlag,
        setUpdateFlag,
        createNewComponent,
        lastDeletedComp,
        setLastDeletedComponent,
        lastDeletedCompIds,
        setLastDeletedComponentIds,
        lastDeletedOption,
        setLastDeletedOption,
        specificOptions,
        setSpecificOptions,
        LRCompList,
        setLRCompList,
        adminColumn,
        found,
        foundtemplateList,
        foundPatientsList,
        foundUsersList,
        foundDoctorsList,
        setAdminColumn,
        selectedOptionId,
        setSelectedOptionId,
        selectedOptionIdsPreview,
        setSelectedOptionIdsPreview,
        setCompAccordion,
        compAccordion,
        handleCompPreviewClick,
        compPreviewData,
        setCompPreviewData,
        hrzViewTLFlag,
        setHrzViewTLFlag,
        newCellAdded,
        setNewCellAdded,
        hierarchyComponent,
        setHierarchyComponent,
        expandTLAccordion,
        setTLAccordionData,
        tlAccordionData,
        currentSelectedValue,
        setCurrentSelectedValue,
        templateData,
        associationValue,
        setAssociationValue,
        handleDMColumnChange,
        showEmptyDMDialog,
        setShowEmptyDMDialog,
        configureDialog,
        setConfigureDialog,
        promiseAccessControlData,
        setPromiseAccessControlData,
        editTimelineColumn,
        setEditTimelineColumn,
        editTimeline,
        setEditTimeline,
        insertUTAssociation,
        inserPatientUTAssociation,
        configData,
        setConfigData,
        superTimelineData,
        showTimelineTable,
        setShowTimelineTable,
        addColumnLeftRight,
        setAddColumnLeftRight,
        scrollState,
        setScrollState,
        pagehyperlinkCip,
        setPageHyperlinkCip,
        contentDisplayLoader,
        hyperlinkCip,
        setHyperlinkCip,
        hyperlinkCipText,
        setHyperlinkCipText,
        videoHyperlinkCip,
        setVideoHyperlinkCip,
        hyperlinkComponent,
        setHyperlinkComponent,
        hyperlinkImageVideoComponent,
        setHyperlinkImageVideoComponent,
        editProgressionComp,
        setEditProgressionComp,
        workshopAccessView,
        setWorkshopAccessView,
        workshopAccessData,
        setWorkshopAccessData,
        poiData,
        progressionAccCollapse,
        setProgressionAccCollapse,
        imageEditorDialogWs,
        setImageEditorDialogWs,
        imageEditorDataWs,
        imageEditorLoader,
        setImageEditorLoader,
        orgMembers,
        setOrgMembers,
        spl,
        setSpl,
        timelineAssoc,
        setTimelineAssoc,
        templateLibraryCip,
        setTemplateLibraryCip,
        bulkInsertPoi,
        // isMultiOpen,
        // setIsMultiOpen,
        // multiOpenComp,
        // setMultiOpenComp,
        bulkInsertAC,
        AC_DATA,
        allContentDisplayData,
        callData,
        refetchCallData,
        isPageModalOpen,
        setIsPageModalOpen,
        getComponentPOIUA,
        isEditMode,
        setIsEditMode,
        setWorkshopColumn,
        setSelectedParent,
        showTitleView,
        setShowTitleView,
        intitalTempSelected,
        setIntitalTempSelected,
        complexFormType,
        setComplexFormType,
        activeSelectSuggestParent,
        setActiveSelectSuggestParent,
        activeSelectSuggestData,
        setActiveSelectSuggestData,
        selectedChildren,
        columnWidthData,
        setColumnWidthData,
        handleColumnClick,
        setTimelineShareMode,
        timelineShareMode,
        patientProfileData,
        setPatientProfileData,
        LRUpdate,
        setLRUpdate,
        contentDisplayCip,
        setContentDisplayCip,
        contentDisplayComponent,
        setContentDisplayComponent,
        universalScndState,
        setUniversalScndState,
        contentDisplayImageList,
        setContentDisplayImageList,
        cdComponent,
        setCDComponent,
        cdClickType,
        setCdClickType,
        setCollapseProtocol,
        collapseProtocol,
        collapseProgressionComp,
        setCollapseProgressionComp,
        refetchContentDisplay,
        selectedPsa,
        setSelectedPsa,
        selectedDoc,
        setSelectedDoc,
        universalCompState,
        setUniversalCompState,
        setSingleOrCont,
        singleOrContinuous,
        globalAssociationState,
        setGlobalAssociationState,
        workshopSC,
        setWorkshopSC,
        isMobile,
        isFullLR,
        setIsFullLR,
        queryParams,
        setQueryParams,
        wpHeaders,
        setWPHeaders,
    };

    // pass the value in provider and return
    return (
        <div>
            <Context.Provider value={timelineContext}>
                {children}
                <TemplateDialog
                    open={dialogOpen}
                    setOpen={setDialogOpen}
                    type="prompt"
                    payload={templatePayLoad}
                    setPayload={onDialogSave}
                />
                <TemplateDialog
                    open={overRideDialogOpen}
                    setOpen={setOverRideDialogOpen}
                    type="override"
                    payload={templatePayLoad}
                    setPayload={overRideTemplate}
                />
            </Context.Provider>
        </div>
    );
};

export const { Consumer } = Context;
