import { Close } from '@material-ui/icons';
import { ImagePlaceholder, useImgStyles } from '../ImageSelector';
import { OrgContext, TimelineContext } from '../../context';
import { Typography } from '@material-ui/core';
import { getIframeHyperlink, getYouTubeId } from '../../helper/commonHelper';
import { makeStyles } from '@material-ui/core/styles';
import { useSwipeable } from 'react-swipeable';
import ErrorBoundary from '../error-boundary';
import React, { Suspense, lazy, useContext } from 'react';
import WebPageTabs from '../utils/WebPageTabs';

const CarouselSwiper = lazy(() => import('../utils/Swiper'));

const useRowStyles = makeStyles((theme) => ({
    timelineColumn: {
        marginRight: 2,
        height: 'max-content',
        // width: 500,
        zIndex: 1,
        margin: '0px 4px',
        border: '.25px solid #ececec',
        position: 'sticky',
        top: 130,
        background: '#fff',
        '& > :first-child': {
            textAlign: 'center',
            marginBottom: 36,
        },
        overflow: 'hidden',
        [theme.breakpoints.down('xs')]: {
            width: '100% !important',
            margin: '0px',
            top: 56,
        },
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0px 12px',
        borderBottom: '.25px solid #ececec',
        alignItems: 'center',
        paddingLeft: '42%',
        '& p': {
            [theme.breakpoints.down('xs')]: {
                width: '100%',
                textAlign: 'center',
                fontSize: '17px',
            },
        },
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '0px',
            justifyContent: 'flex-start',
            padding: '7px',
        },
    },
    protocolTitle: {
        padding: '5px 10px',
        background: '#000',
        textAlign: 'center',
        color: '#fff',
    },
    closeIcon: {
        cursor: 'pointer',
        fontSize: 14,
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    noContentDisplay: {
        margin: '4rem',
        borderLeft: '1px solid #0000001f',
        borderRight: '1px solid #0000001f',
    },
    displayNone: {
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
}));

const ContentDisplayView = () => {
    const {
        cdComponent, setCDComponent, allContentDisplayData, cdClickType, setCdClickType, isMobile, currentProtocol,
    } = useContext(TimelineContext);
    const { loggedUserType } = useContext(OrgContext);
    const classes = useRowStyles();
    const imgClasses = useImgStyles();

    const expandWidth = cdClickType === 'hyperlink' || cdClickType === 'video';

    let isYoutube = false;
    let url = '';

    if (cdComponent?.props?.videoHyperlink && cdClickType === 'video') {
        url = getYouTubeId(cdComponent?.props?.videoHyperlink);
        if (url) {
            isYoutube = true;
        } else {
            isYoutube = false;
        }
    }

    const cdImageList = allContentDisplayData?.['content_display']?.find((i) => i.protocol_id === cdComponent?.id);

    const closeContentDisplayView = () => {
        setCDComponent(null);
        setCdClickType('');
    };

    const handlers = useSwipeable({
        onSwipedRight: () => {
            closeContentDisplayView();
        },
    });

    const isContentDisplay = ((cdClickType === 'image') && cdImageList?.['image_list']);
    const isPageContent = cdClickType === 'hyperlink' && cdComponent?.props?.openHyperlinkType !== 'newTab' && cdComponent?.props?.hyperlink;
    const isVideoContent = cdClickType === 'video' && cdComponent?.props?.videoHyperlink;
    const noContent = !isContentDisplay && !isPageContent && !isVideoContent;

    if (!cdComponent?.id) return null;

    return (
        <div
            {...handlers}
            className={[classes.timelineColumn].join(' , ')}
            style={{ marginTop: isMobile ? '0px' : (loggedUserType === 'Patient' ? 41 : 66), width: expandWidth ? 750 : 500 }}
        >
            <div className={[classes.header, classes.displayNone].join(' , ')}>
                <Typography variant="body1">Content Display</Typography>
                <Close onClick={closeContentDisplayView} className={classes.closeIcon} />
            </div>
            <Typography className={[classes.protocolTitle, classes.displayNone].join(' , ')}>
                {`${cdComponent?.props?.isProgression ? 'Progression' : 'Protocol'}: ${cdComponent?.props?.title}`}
            </Typography>
            <ErrorBoundary messgae="swiper.js can't be imported.">
                <Suspense fallback={<Typography>loading content display....</Typography>}>
                    {isContentDisplay
                        && (
                            <CarouselSwiper
                                fullHeightNav
                                variant="dots"
                                isCD
                                contentDisplayImageList={cdImageList?.['image_list']}
                                contentData={cdImageList}
                            />
                        )}
                    {isPageContent && (
                        <WebPageTabs
                            webPages={currentProtocol?.props?.hyperlink}
                        />
                    )}
                    {isVideoContent
                        && getIframeHyperlink({
                            url: isYoutube ? url : cdComponent.props?.videoHyperlink,
                            is_youtube: isYoutube,
                            expandHeight: true,
                        })}
                    {noContent
                        && (
                            <div
                                className={classes.noContentDisplay}
                            >
                                <ImagePlaceholder classes={imgClasses} iconColor="primary" />
                            </div>
                        )}
                </Suspense>
            </ErrorBoundary>

        </div>

    );
};

export default ContentDisplayView;
