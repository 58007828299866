import { ComponentEditorLookup } from './components';
import { TimelineContext } from './context';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { minimumColumnWidth } from './initialConfig';
import { standardHeaders } from './components/utils/StandardStyles';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import React, { useContext, useState } from 'react';
import StandardHeader from './components/StandardHeader';
import Typography from '@material-ui/core/Typography';

/**
 * Set Setting Component style
 */
const useStyles = makeStyles((theme) => createStyles({
    widgetContainer: {
        width: '100%',
        overflowY: 'auto',
        maxHeight: '96vh',
        minHeight: '96vh',
    },
    buttonGroup: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    settingsHeader: standardHeaders.h1,
    columHeadType: {
        fontSize: '0.75rem',
    },
    settingsBody: {
        width: '100%',
        maxHeight: '90vh',
        minHeight: '90vh',
        overflow: 'scroll',
    },
    dangerColor: {
        color: 'red',
    },
}));

/**
 * @function EditorActions [render save and revert button]
 * @param {function} revert
 * @param {function} save
 * @returns {component}
 */
const EditorActions = ({ revert, save }) => {
    const classes = useStyles();
    return (
        <div className={classes.buttonGroup}>
            <Button variant="contained" onClick={revert}>Revert</Button>
            <Button variant="contained" color="primary" onClick={save}>
                Save
            </Button>
        </div>
    );
};

const SettingsWidget = ({
    selectedComponent,
    selectedParent,
    updateComponent,
    heading,
}) => {
    const { type } = selectedComponent;
    const editor = ComponentEditorLookup[type];
    return editor && React.createElement(editor, {
        config: selectedComponent,
        updateComponent,
        parent: selectedParent,
        heading,
        renderAsTextArea: type === 'TextAreaCF',
        renderAsTextBox: type === 'TextBoxCF',
    });
};

const Settings = () => {
    const classes = useStyles();
    const {
        selectedComponent, selectedParent, setSelectedComponent, workshopComponent, updateComponent, settingMenuType, handleSettingMenuType,
    } = useContext(TimelineContext);
    const [error, showError] = useState('');

    const showDrawer = !!(selectedComponent && selectedComponent.id) && !workshopComponent;

    const drawerWidth = `${window.innerWidth * 0.3}px`;

    const save = () => {
        if (selectedComponent.type === 'Timeline') {
            const numberOfColumns = selectedComponent.columns.length;
            const minimumTimelineWidth = numberOfColumns * minimumColumnWidth;
            const timelineWidth = parseInt(selectedComponent.props.width, 10);

            if (timelineWidth >= minimumTimelineWidth) {
                showError('');
                updateComponent();
            } else {
                showError(`The minimum allowed width of this timeline is: ${minimumTimelineWidth}`);
            }
        } else {
            updateComponent();
        }
    };

    const revert = () => {
        // setTitle(config.props.title);
    };

    const handleMenutType = (type) => {
        handleSettingMenuType(type);
    };

    return (
        <Drawer anchor="right" open={showDrawer} onClose={() => { setSelectedComponent({}); showError(''); }}>
            <div style={{ width: drawerWidth }}>
                <div className={classes.settingsHeader}>
                    <Typography className={classes.columHeadType} color="inherit">Settings</Typography>
                </div>
                <div className={classes.settingsBody}>
                    <Grid container>
                        <Grid item xs>
                            <StandardHeader
                                isButton
                                type={settingMenuType === 'Basic' ? 'h2' : 'h3'}
                                onClick={() => { handleMenutType('Basic'); }}
                            >
                                Basic

                            </StandardHeader>
                        </Grid>
                        <Grid item xs>
                            <StandardHeader
                                isButton
                                type={settingMenuType === 'Advanced' ? 'h2' : 'h3'}
                                onClick={() => { handleMenutType('Advanced'); }}
                            >
                                Advanced

                            </StandardHeader>
                        </Grid>
                        <Grid item xs>
                            <StandardHeader
                                isButton
                                type={settingMenuType === 'Admin' ? 'h2' : 'h3'}
                                onClick={() => { handleMenutType('Admin'); }}
                            >
                                Admin

                            </StandardHeader>
                        </Grid>
                        {selectedComponent && selectedComponent.id && settingMenuType === 'Basic'
                         && (
                             <SettingsWidget
                                 selectedComponent={selectedComponent}
                                 selectedParent={selectedParent}
                                 updateComponent={updateComponent}
                             />
                         )}
                        {selectedComponent && selectedComponent.id && settingMenuType === 'Advanced'
                        && <SettingsWidget selectedComponent={selectedComponent} selectedParent={selectedParent} updateComponent={updateComponent} />}
                        {selectedComponent && selectedComponent.id && settingMenuType === 'Admin'
                        && <SettingsWidget selectedComponent={selectedComponent} selectedParent={selectedParent} updateComponent={updateComponent} />}
                        <Grid item xs={12}>
                            <span className={classes.dangerColor}>{error}</span>
                        </Grid>
                        <Grid item xs>
                            <EditorActions revert={revert} save={save} />
                        </Grid>
                    </Grid>
                </div>

            </div>
        </Drawer>
    );
};

export { Settings, SettingsWidget };
