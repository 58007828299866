import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Container } from './Container';
import { WorkshopContext } from '../context';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';

const useStyles = makeStyles((theme) => ({
    dialogTitleCont: {
        padding: '.5rem 1.25rem .5rem 2.5rem',
    },
    dialogPaperBody: {
        overflowY: 'auto',
        padding: '0rem 2.5rem',
    },
    DialogContentCont: {
        overflowY: 'visible',
    },
    leftTitleCont: {
        textAlign: 'center',
    },
    rightCloseCont: {
        textAlign: 'right',
        marginTop: 10,
    },
    closeIcon: {
        cursor: 'pointer',
        '&:hover': {
            background: theme.palette.primary.light,
        },
    },
    dialogActionCont: {
        padding: '.5rem 1.25rem',
    },
}));

export default function PageViewDialog(props) {
    const classes = useStyles();
    const {
        onClose, open, title, data, ...other
    } = props;
    const pIndex = data.findIndex((d) => d.Medguide);
    const { inWorkshop } = useContext(WorkshopContext);
    const columnComp = data[0].Medguide.components || [];

    const handleCancel = () => {
        onClose();
    };

    const renderPageView = () => {
        const showCarousel = !inWorkshop;
        const carouselComps = [];
        if (showCarousel) {
            let lastGallery = false;
            for (let i = 0; i < columnComp.length; i += 1) {
                const colComponent = columnComp[i];
                if (colComponent?.type === 'Gallery') {
                    if (lastGallery) {
                        const lastItem = carouselComps[carouselComps.length - 1];
                        carouselComps[carouselComps.length - 1] = {
                            ...lastItem,
                            props: { components: [...lastItem.props.components, colComponent] },
                        };
                    } else {
                        const itemIndex = carouselComps.length;
                        const nextItem = columnComp[i + 1];
                        if (nextItem && nextItem.type === 'Gallery') {
                            carouselComps[itemIndex] = { type: 'Carousel', props: { components: [colComponent] } };
                        } else {
                            carouselComps.push(colComponent);
                        }
                    }
                    lastGallery = true;
                } else {
                    carouselComps.push(colComponent);
                    lastGallery = false;
                }
            }
        }
        const pageViewData = showCarousel ? <Container props={{ components: carouselComps }} /> : data[pIndex].props.components.map((comp, i) => (
            <DialogContent dividers classes={{ root: classes.DialogContentCont }}>
                {comp.type === 'HTML'
                    ? (
                        <div
                            key={i}
                            dangerouslySetInnerHTML={{ __html: comp.props.html }}
                        />
                    )
                    : <Container props={{ components: [comp] }} />}
            </DialogContent>
        ));
        return pageViewData;
    };

    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            fullWidth
            aria-labelledby="pageview-dialog-title"
            open={open}
            {...other}
            classes={{ paper: classes.dialogPaperMain }}
        >
            <DialogTitle id="confirmation-dialog-title" classes={{ root: classes.dialogTitleCont }}>
                <Grid container direction="row" justify="space-between" alignItems="center">
                    <Grid item xs={10} className={classes.leftTitleCont}>{title}</Grid>
                    <Grid item xs={2} className={classes.rightCloseCont}>
                        <Close classes={{ root: classes.closeIcon }} onClick={handleCancel} />
                    </Grid>
                </Grid>
            </DialogTitle>
            <div className={classes.dialogPaperBody}>
                {data[pIndex].props.components && renderPageView()}
            </div>
            <DialogActions classes={{ root: classes.dialogActionCont }}>
                <Button onClick={handleCancel} variant="contained">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}
