/* eslint-disable react/no-array-index-key */
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Checkbox,
    FormControlLabel,
    Grid,
    makeStyles,
} from '@material-ui/core';
import { ArrowRight } from '@material-ui/icons';
import { OrgContext, TimelineContext } from '../context';
import { SettingsList } from '../Workshop';
import { TimelineTableForDoctoreView } from './AllTimelineUniversalSettings';
import {
    buildSettingsTreePage, findAssociatedParent, findComponent, findParent,
} from '../helper/commonHelper';
import { standardHeaders } from './utils/StandardStyles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import React, {
    Fragment,
    useContext, useEffect, useState,
} from 'react';
import SettingsAccess from './SettingsAccess';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const breakpoints = createBreakpoints({});

const useStyles = makeStyles(() => ({
    accessControlGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3,minmax(auto ,1fr))',
        gap: '2px 2px',
        marginTop: 2,
        alignItems: 'left',
        width: 'inherit',
    },
    accessControlHeader: {
        background: '#efefef',
        padding: 4,
        textAlign: 'center',
    },
    adminSettingAccSumContMain: {
        textAlign: 'center',
    },
    userAccessCont: {
        borderBottom: '.25px solid #cbcbcb',
    },
    userAccessColumn: {
        textAlign: 'left',
        fontSize: 12,
        paddingLeft: 8,
    },
    userAccessTypeCont: {
        borderLeft: '.25px solid #cbcbcb',
        '& span': {
            fontSize: 12,
        },
    },
    accordionStyle: {
        margin: '0px 4px !important',
        boxShadow: 'none',
        border: '1px solid #7070704E',
        borderRadius: '0px !important',
        width: '99%',
    },
    selectTempAcc: {
        margin: '0px !important',
        boxShadow: 'none',
        border: '1px solid #7070704E',
        borderRadius: '0px !important',
    },
    accordionDetailStyle: {
        display: 'block',
        padding: '0px',
    },
    accordionDetailStyle1: {
        display: 'block',
        padding: 0,
    },
    userAccessDetailStyle: {
        display: 'block',
        padding: '0px',
        maxHeight: '54vh',
        overflowY: 'auto',
    },
    adminSettingAccDetailCont: {
        borderBottom: 'none',
        fontSize: 12,
    },
    bBottom: {
        borderBottom: '1px solid #cbcbcb',
        borderRight: '1px solid #cbcbcb',
        padding: '5px 4px 3px 4px',
    },
    detailContLeft: {
        textAlign: 'left',
        borderRight: '1px solid #cbcbcb',
        borderBottom: '1px solid #cbcbcb',
        padding: '4px 8px',
        fontSize: 12,
    },
    detailContLeft1: {
        padding: 4,
        fontSize: 12,
        textAlign: 'center',
    },
    detailContRightLink: {
        textAlign: 'left',
        borderBottom: '1px solid #cbcbcb',
        padding: '4px 8px',
        color: '#2A73F4',
        cursor: 'pointer',
        fontSize: 12,
    },
    paddSpac: {
        transform: 'scale(.75)',
        color: '#000000',
    },
    emptyRightCont: {
        borderBottom: '1px solid #cbcbcb',
        height: '26.16px',
    },
    detailContRight: {
        textAlign: 'left',
        borderBottom: '1px solid #cbcbcb',
        padding: '4px 8px',
        fontSize: 12,
        wordBreak: 'break-all',
        maxHeight: 100,
        overflow: 'auto',
    },
    adminSettingCont: {
        padding: '4px 2px',
    },
    breadCrumbCont: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 4,
    },
    breadCrumbText: {
        textTransform: 'capitalize',
        marginRight: 10,
    },
    timelineTableOrgCont: {
        borderBottom: '2px solid #000000',
    },
    expandedAccIcon1: {
        transform: 'rotateZ(-90deg) translateY(-10px)',
        color: '#000000',
        height: 24,
    },
    expandedColAccIcon: {
        color: '#000000',
        height: 24,
        padding: '0px 4px',
    },
    iconChildAccButton: {
        transform: 'rotateZ(90deg)',
        margin: '0 8px 0 2px',
        padding: 0,
        color: 'black',
        borderRadius: 2,
    },
    expandedAccIcon2: {
        transform: 'rotateZ(-90deg) translateY(-10px)',
        color: '#FFFFFF',
        height: 36,
    },
    expandedAccIcon3: {
        color: '#000000',
        height: 36,
    },
    centerAlign: {
        alignItems: 'center',
    },
    leftTextAlign: {
        textAlign: 'left',
    },
    rightTextAlign: {
        textAlign: 'right',
    },
    expandedAcc1: {
        background: '#6d6d6d',
        color: '#000000',
        transition: 'none',
    },
    expandedAccPOI: {
        color: '#000000',
        transition: 'none',
    },
    expandedAcc2: {
        color: '#FFFFFF',
        transition: 'none',
    },
    expandedAcc3: {
        color: '#000000',
        transition: 'none',
    },
    expandedColAcc: {
        color: '#000000',
        transition: 'none',
        '& svg': {
            transform: 'rotateZ(-90deg)',
        },
    },
    iconButton: {
        margin: '0 8px 0 2px',
        padding: 0,
        background: '#bbb',
        color: '#FFFFFF',
        borderRadius: 2,
    },
    expandedAcc: {
        background: '#066785 !important',
        color: '#FFFFFF',
        transition: 'none',
    },
    sectionHeader: {
        margin: 0,
        minHeight: 'auto !important',
        height: 32,
        fontSize: 13,
        border: '1px solid #ececec',
        ...standardHeaders.sectionHeader,
        position: 'relative',
        flexDirection: 'row-reverse',
        background: 'rgba(6,103,133,0.09)',
        color: '#066785',
    },
    expandedAccIcon: {
        transform: 'rotateZ(-90deg) translateY(-10px)',
        color: '#066785',
        height: 36,
    },
    iconButton1: {
        margin: '0 8px 0 2px',
        padding: 0,
        color: 'black',
        borderRadius: 2,
    },
    iconButton2: {
        margin: '0 8px 0 2px',
        padding: 0,
        color: '#FFFFFF',
        borderRadius: 2,
    },
    iconButton3: {
        margin: '0 8px 0 2px',
        padding: 0,
        color: '#000000',
        borderRadius: 2,
    },
    sectionHeader1: {
        margin: 0,
        minHeight: 'auto !important',
        height: 26,
        fontSize: 13,
        border: '1px solid #ececec',
        ...standardHeaders.sectionHeader,
        position: 'relative',
        flexDirection: 'row-reverse',
        background: '#6d6d6d',
        color: '#000000',
    },
    sectionHeader2: {
        margin: 0,
        minHeight: 'auto !important',
        height: 26,
        fontSize: 13,
        border: '1px solid #ececec',
        ...standardHeaders.sectionHeader,
        position: 'relative',
        flexDirection: 'row-reverse',
        background: '#000000',
        color: '#FFFFFF',
    },
    sectionHeader3: {
        margin: 0,
        minHeight: 'auto !important',
        height: 26,
        fontSize: 13,
        border: '1px solid #ececec',
        ...standardHeaders.sectionHeader,
        position: 'relative',
        flexDirection: 'row-reverse',
        background: '#b1b1b1',
        color: '#000000',
    },
    sectionHeader4: {
        margin: 0,
        minHeight: 'auto !important',
        height: 26,
        fontSize: 13,
        border: '1px solid #ececec',
        ...standardHeaders.sectionHeader,
        position: 'relative',
        flexDirection: 'row-reverse',
        background: '#fff',
        color: '#000000',
    },
    sectionHeader5: {
        margin: 0,
        minHeight: 'auto !important',
        height: 26,
        fontSize: 13,
        border: '1px solid #ececec',
        ...standardHeaders.sectionHeader,
        position: 'relative',
        flexDirection: 'row-reverse',
        background: '#efefef',
        color: '#000000',
    },
    timelineImage: {
        width: '100%',
        border: '1px solid #cbcbcb',
        padding: '0px 1px',
    },
    selectedColor: {
        backgroundColor: 'cornflowerblue',
        color: '#ffffff',
    },
    timelinePrevCont: {
        paddingLeft: 1,
    },
    templateLibraryText: {
        background: '#bdd9b6',
        borderBottom: '1px solid #cbcbcb',
    },
    columnCont: {
        padding: '0px 2px',
    },
    contPrevCont: {
        border: '1px solid #cbcbcb',
    },
    settingListCont: {
        width: '-webkit-fill-available',
    },
    displayDetailCont: {
        maxHeight: '44vh',
        overflow: 'auto',
    },
    accRoot: {
        width: 'inherit',
        margin: '0px !important',
        borderRadius: '0px !important',
        boxShadow: 'none',
    },
    accRoot1: {
        width: 'inherit',
        margin: '0px 8px !important',
        borderBottom: '.5px solid #CBCBCB',
    },
    accRoot2: {
        width: 'inherit',
        margin: '0px 8px !important',
    },
    ml5: {
        marginLeft: 5,
    },
    sortRadioLabel: {
        color: 'black',
    },
    poiSelectCont: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        paddingLeft: '30%',
        [breakpoints.between('1330', '1420')]: {
            paddingLeft: '25%',
        },
        [breakpoints.between('1180', '1330')]: {
            paddingLeft: '12%',
        },
        [breakpoints.between('1110', '1180')]: {
            paddingLeft: '7%',
        },
    },
}));

let userTypeData = [
    {
        id: 1, label: 'Super Admin', value: 'isSuperAdmin', isShow: true, isDisable: false, key: 'isSuperAdmin',
    },
    {
        id: 2, label: 'Doctor', value: 'isDoctor', isShow: true, isDisable: false, key: 'isDoctor',
    },
    {
        id: 4, label: 'Write Patient', value: 'writePatient', isShow: true, isDisable: false, key: 'isPatient',
    },
];

const accordionLabel = {
    Select: 'Single Select CB',
    Checkbox: 'Multi Select CB',
    TextAreaCF: 'Textarea',
    SuperHeader: 'Super Header',
    ComponentGroup: 'Column',
};

const AdminSettings = (componentProps) => {
    const classes = useStyles();
    const {
        selectedColumn,
        workshopComponent,
        selectedTimeline,
        selectedComponent,
        setSelectedComponent,
        setWorkshopComponent,
        createNewComponent,
        setSettingComponentHandle,
        workshopActiveComponent,
        setWorkshopActiveComponent,
        settingMenuType,
        editTimelineColumn,
        editTimeline,
        setCompAccordion,
        addColumnLeftRight,
        setEditTimelineColumn,
        setEditTimeline,
        setSelectedColumn,
        setSelectedParent,
        updateComponent,
        contentDisplayComponent,
    } = useContext(TimelineContext);

    const {
        loggedInUserDetails, loggedUserType,
    } = useContext(OrgContext);

    const { props = {} } = selectedTimeline?.columns[selectedColumn] || {};
    const {
        children = [], poiProps = null, uaProps = null, tlProps = null,
    } = componentProps || {};
    const {
        components = [],
    } = props;
    const [, , children3] = children;

    const componentType = children3?.props?.openComponent?.type;
    if (componentType === 'Select'
        || componentType === 'MultiSelect'
        || componentType === 'Checkbox'
        || componentType === 'TextInput'
        || componentType === 'DatePicker'
        || componentType === 'TextAreaCF'
        || componentType === 'TextBoxCF') {
        const requiredColumn = findComponent(workshopComponent?.props.components, children3?.props?.openComponent?.colRef
            || children3?.props?.openComponent?.parent);
        let columnComponents = [];
        buildSettingsTreePage(columnComponents, requiredColumn);
        columnComponents = columnComponents.filter((ele) => ele?.type === componentType && !ele?.props?.hideFromWorkshop);
        for (let i = 0, l = columnComponents?.length; i < l; i += 1) {
            if (children3?.props?.openComponent?.id === columnComponents?.[i]?.id) {
                break;
            }
        }
    }

    const initialAccState = {
        protocol: true,
        column: true,
        gallery: true,
        component: true,
        useraccessnew: false,
        poi: false,
        display: false,
    };

    // Change from State activeAcc to activeH1Level - Sprint-59 | PR - 2268
    const [activeH1Level, setActiveH1Level] = useState(initialAccState);
    // const [activeAcc, setActiveAcc] = useState('component-function');
    const [activeProtocolChildAcc, setActiveProtocolChildAcc] = useState({ activeCol: true, activeComp: workshopActiveComponent?.id });
    const [activeChildAcc, setActiveChildAcc] = useState('Column - 1');
    const [activeCompAcc, setActiveCompAcc] = useState('Comp - 1');
    const [state, setState] = useState({ type: null, breadCrumbsCF: null, flag: false });
    const [activePOIAccordion, setActivePOIAccordion] = useState(false);
    const [TimeLineAccessAcc, setTimeLineAccessAcc] = useState(false);

    const isTimelineAccFlag = workshopComponent?.type === 'Column' || workshopComponent?.type === 'Timeline' || false;

    /**
    * @function handleActiveChildAcc2 to call on child level accordion(level - 2)
    * @param {object} e
    * @param {string} acc
    */

    /**
    * @function handleActiveChildAcc to call on column accordion(level - 2)
    * @param {object} e
    * @param {string} acc
    */
    const handleActiveChildAcc = (e, acc, comp) => {
        e.stopPropagation();
        e.preventDefault();

        if (acc === activeChildAcc) {
            setActiveChildAcc('');
        } else {
            setActiveChildAcc(acc);
            setActiveH1Level((prev) => ({
                ...prev,
                protocol: true,
            }));
            if (acc === 'Timeline Column') {
                setSettingComponentHandle(workshopComponent);
            } else if (comp) {
                setSettingComponentHandle(comp);
            }
        }
    };

    // const getAcc = (acc) => {
    //     if (acc) {
    //         const result = (acc === 'Protocol'
    //             ? 'component-function' : ((acc === 'Column') || (acc === 'Gallery'))
    //                 ? 'Column-Settings' : acc === 'Component' ? 'Component-Settings' : acc);
    //         return result;
    //     }
    //     return null;
    // };

    /**
    * @function handleActiveAcc to call on column accordion(level - 1)
    * @param {object} e
    * @param {string} acc
    */
    // eslint-disable-next-line no-unused-vars
    const handleActiveAcc = (e, acc, comp = null) => {
        // eslint-disable-next-line no-unused-expressions
        e?.stopPropagation();
        // eslint-disable-next-line no-unused-expressions
        e?.preventDefault();
        const typeLabel = acc.toLowerCase();
        if (activeH1Level) {
            // setActiveAcc('');
            setActiveH1Level((prev) => ({
                ...prev,
                [typeLabel]: !activeH1Level?.[typeLabel],
            }));
        }
        setCompAccordion(true);
    };

    /**
    * @function handleCompSettingAcc to call on component accordion(level - 2)
    * @param {object} e
    * @param {object} comp
    */
    const handleCompSettingAcc = (e, comp) => {
        // eslint-disable-next-line no-unused-expressions
        e?.stopPropagation();
        // eslint-disable-next-line no-unused-expressions
        e?.preventDefault();
        if (`Comp - ${comp?.id}` === activeCompAcc) {
            setActiveCompAcc('');
        } else {
            setSettingComponentHandle(comp);
            setActiveCompAcc(`Comp - ${comp?.id}`);
        }
    };

    const handleTimeLineAccessAcc = () => {
        setTimeLineAccessAcc((prev) => !prev);
    };

    useEffect(() => {
        setCompAccordion(true);
    }, [workshopActiveComponent?.id]);

    useEffect(() => {
        setActiveProtocolChildAcc({ ...activeProtocolChildAcc, activeComp: selectedComponent?.id });
    }, [selectedComponent?.id]);

    /**
    * @function workshopCFComp to call when workshop component  is a complex form.
    */
    const workshopCFComp = () => {
        const { type = null } = selectedComponent;
        switch (type) {
        case 'Protocol':
            if (workshopComponent.id === selectedComponent.id) {
                if (selectedComponent?.props?.leftRightColumn) {
                    setActiveChildAcc('Column - 2');
                } else {
                    setActiveChildAcc('Column - 1');
                }
                // setActiveAcc('component-function');
                setActiveH1Level((prev) => ({
                    ...prev,
                    protocol: true,
                }));
            }
            break;
        case 'Column': {
            const parentComp = findComponent([workshopComponent], selectedComponent?.parent) || null;
            let findColIndex = parentComp && parentComp.props.components.findIndex((c) => c.id === selectedComponent?.id);
            if (selectedComponent.props.LRProps
                    || (workshopComponent.component === 'LR Complex Form' && selectedComponent.props.columnType !== 'left')) {
                findColIndex += 1;
            }
            if (parentComp) {
                setActiveChildAcc(`Column - ${findColIndex + 1}`);
                // setActiveAcc('Column-Settings');
                setActiveH1Level((prev) => ({
                    ...prev,
                    column: true,
                }));
            }
            const cloneData1 = [];
            cloneData1.push(workshopComponent);
            cloneData1.push(selectedComponent);
            setState({ ...state, breadCrumbsCF: cloneData1 });
        }
            break;
        default: {
            const parentComp1 = findComponent(workshopComponent?.props.components, selectedComponent?.rowRef) || null;
            let findColIndex1 = parentComp1?.props?.components && parentComp1.props.components.findIndex((c) => c.id === selectedComponent?.colRef);
            if (selectedComponent.props.LRProps || workshopComponent.component === 'Universal LR CF') {
                findColIndex1 += 1;
            }
            if (parentComp1) {
                setActiveChildAcc(`Column - ${findColIndex1 + 1}`);
                // setActiveAcc('Component-Settings');
                setActiveH1Level((prev) => ({
                    ...prev,
                    component: true,
                }));
                setActiveCompAcc(`Comp - ${selectedComponent?.id}`);
            }
            const data = [];
            let cloneData = [];
            findAssociatedParent(workshopComponent, [parentComp1], selectedComponent, data);
            data.push(workshopComponent);
            data.reverse();
            data.push(selectedComponent);
            cloneData = data.filter((d) => d.type !== 'Column');
            setState({ ...state, breadCrumbsCF: cloneData });
        }
        }
    };

    /**
    * @function workshopImageVideosComp to call when workshop component is a image/video.
    */
    const workshopImageVideosComp = () => {
        const { type = null } = selectedComponent;
        switch (type) {
        case 'Protocol':
            if (workshopComponent.id === selectedComponent.id) {
                setActiveChildAcc('Column - 1');
                // setActiveAcc('component-function');
                setActiveH1Level((prev) => ({
                    ...prev,
                    protocol: true,
                }));
            }
            break;
        case 'Gallery': {
            const galleryParentComp = findComponent([workshopComponent], selectedComponent?.parent) || null;
            const galleryFindColIndex = galleryParentComp && galleryParentComp.props.components.findIndex((c) => c.id === selectedComponent?.id);
            if (galleryParentComp) {
                setActiveChildAcc(`Column - ${galleryFindColIndex + 1}`);
                // setActiveAcc('Column-Settings');
                setActiveH1Level((prev) => ({
                    ...prev,
                    column: true,
                }));
            }
        }
            break;
        case 'Image': {
            const resultParent = [];
            if (components) {
                findParent([workshopComponent], selectedComponent.parent, resultParent);
            }
            const imageFindColIndex = resultParent.length > 0
                    && resultParent[0].props.components.findIndex((c) => c.id === selectedComponent?.parent);
            if (resultParent.length > 0) {
                setActiveChildAcc(`Column - ${imageFindColIndex + 1}`);
                // setActiveAcc('Component-Settings');
                setActiveH1Level((prev) => ({
                    ...prev,
                    component: true,
                }));
                setActiveCompAcc(`Comp - ${selectedComponent?.id}`);
            }
        }
            break;
        default:
            return false;
        }

        return null;
    };

    /**
    * @function workshopFormComp to call when workshop component is a Form.
    */
    const workshopFormComp = () => {
        const { type = null } = selectedComponent;
        switch (type) {
        case 'Protocol':
            if (workshopComponent.id === selectedComponent.id) {
                setActiveChildAcc('Timeline Column');
                // setActiveAcc('component-function');
                setActiveH1Level((prev) => ({
                    ...prev,
                    protocol: true,
                }));
            }
            break;
        case 'Column': {
            const galleryParentComp = findComponent([workshopComponent], selectedComponent?.parent) || null;
            const galleryFindColIndex = galleryParentComp && galleryParentComp.props.components.findIndex((c) => c.id === selectedComponent?.id);
            if (galleryParentComp) {
                setActiveChildAcc(`Column - ${galleryFindColIndex + 1}`);
                // setActiveAcc('component-function');
                setActiveH1Level((prev) => ({
                    ...prev,
                    protocol: true,
                }));
            }
        }
            break;
        default: {
            const resultParent = [];
            if (components) {
                findParent([workshopComponent], selectedComponent.parent, resultParent);
            }
            const imageFindColIndex = resultParent?.length > 0
                    && resultParent[0].props.components.findIndex((c) => c.id === selectedComponent?.parent);
            if (resultParent?.length > 0) {
                setActiveChildAcc(`Column - ${imageFindColIndex + 1}`);
                // setActiveAcc('component-function');
                setActiveH1Level((prev) => ({
                    ...prev,
                    protocol: true,
                }));
            }
        }
        }
    };

    useEffect(() => {
        const { supergroup = null } = workshopComponent || {};

        if (selectedComponent && supergroup) {
            switch (supergroup) {
            case 'Complex Forms':
                setState({ ...state, breadCrumbsCF: null });
                workshopCFComp();
                break;
            case 'Images/Videos':
                setState({ ...state, breadCrumbsCF: null });
                workshopImageVideosComp();
                break;
            case 'Form':
                setState({ ...state, breadCrumbsCF: null });
                workshopFormComp();
                break;
            case 'Header':
                setState({ ...state, breadCrumbsCF: null });
                setActiveChildAcc('Timeline Column');
                break;
            default: break;
            }
        }
    }, [selectedComponent?.id]);

    const checkCompType = (comp, timelineCol) => {
        const flag = (comp?.type === 'Text') || (comp?.type === 'Protocol') || (timelineCol === 'isColumn') || (timelineCol === 'isTimeline');
        return flag;
    };

    const handleClickComp = (e, item, otherArg = {}) => {
        const { type = null, index = null } = otherArg;
        const updateState = { ...state };

        if (!item && type !== 'isTimeline' && type !== 'isColumn') return false;

        if (!checkCompType(item, type)) {
            setSettingComponentHandle(item);
            setSelectedComponent(item);
            return false;
        }
        const columnClone = type && createNewComponent(type);

        switch (type) {
        case 'isTimeline':
            columnClone.props.title = selectedTimeline.name;
            setWorkshopComponent(selectedTimeline);
            setSelectedComponent(selectedTimeline);
            setWorkshopActiveComponent(selectedTimeline);
            setEditTimelineColumn(false);
            setEditTimeline(true);
            // setActiveAcc('component-function');
            setActiveH1Level((prev) => ({
                ...prev,
                protocol: true,
            }));
            break;
        case 'isColumn':
            if (index) {
                const parent = {
                    id: null,
                    props: { components: selectedTimeline.columns },
                    type: 'Timeline',
                };
                setEditTimelineColumn(true);
                setEditTimeline(false);
                setSelectedColumn(index);
                setWorkshopComponent(selectedTimeline.columns[index]);
                setSelectedComponent(selectedTimeline.columns[index]);
                setWorkshopActiveComponent(selectedTimeline.columns[index]);
                setSelectedParent(parent);
                // setActiveAcc('component-function');
                setActiveH1Level((prev) => ({
                    ...prev,
                    protocol: true,
                }));
            } else {
                setWorkshopComponent(selectedTimeline.columns[selectedColumn]);
                setSelectedComponent(selectedTimeline.columns[selectedColumn]);
                setWorkshopActiveComponent(selectedTimeline.columns[selectedColumn]);
            }
            break;
        default:
            setWorkshopComponent(item);
            setSelectedComponent(item);
            setSettingComponentHandle(item);
        }
        setState(updateState);

        return null;
    };

    const setLRComponents = (columnData, column) => {
        let filterComp = [];
        if (workshopActiveComponent?.props?.isLR) {
            filterComp = [workshopComponent, ...columnData];
        } else {
            filterComp = [workshopComponent, columnData[0], column];
        }
        return filterComp;
    };

    const getFlatComponents = (data, input) => {
        if (input?.props?.components?.length > 0 && !input?.isTimeline) {
            input.props.components.forEach((c) => {
                if (!input.isTimeline) getFlatComponents(data, c);
            });
        } else if ((input?.props?.components?.length === 0
            || !input?.props?.components?.length) && !input.isTimeline && !input.props.hideFromWorkshop) {
            data.push(input);
        }
    };

    const renderColumnChild = (column, columnData = []) => {
        const flatColumnComp = [];
        const compTypes = ['Select', 'Checkbox', 'Textarea', 'TextAreaCF', 'Image'];

        if (column?.type === 'Gallery') {
            buildSettingsTreePage(flatColumnComp, column);
        } else {
            getFlatComponents(flatColumnComp, column);
        }

        let filterComp = [];

        if (workshopComponent?.props?.leftRightColumn) {
            filterComp = setLRComponents(columnData, column);
        } else {
            filterComp = [workshopComponent, column];
        }
        let protocolSettings = [...filterComp, { type: 'Component' }];
        const columnSettings = filterComp?.filter((i) => (i?.type === 'Column') || (i?.type === 'Gallery'));
        const compCol = columnSettings?.filter((i) => !i?.props?.isLR);
        const compSettings = flatColumnComp.filter((c) => compTypes.includes(c.type));

        // For LR CF Child Select & Suggest Only
        if (workshopComponent?.component === 'Child Select & Suggest LR CF') {
            const removableIndex = protocolSettings.findIndex((c) => c.type === 'Column' && !c?.props?.isLR);
            protocolSettings.splice(removableIndex, 1);
        }
        if (workshopActiveComponent?.component === 'Child Select & Suggest LR CF') {
            const removableIndex = protocolSettings.findIndex((c) => c.type === 'Column' && !c?.props?.isLR);
            protocolSettings.splice(removableIndex, 1);
        }
        if (workshopComponent?.props?.leftRightColumn && workshopComponent?.component !== 'Child Select & Suggest LR CF') {
            protocolSettings = protocolSettings.filter((i) => !i?.props?.isLR);
        }
        if (workshopComponent?.name === 'Images/Videos' && contentDisplayComponent) {
            protocolSettings = protocolSettings.filter((i) => i.type !== 'Protocol');
        }

        return (
            <>
                {protocolSettings.map((c) => {
                    // const currentAcc = getAcc(c?.type);
                    const typeLabel = (c?.type).toLowerCase();
                    return (
                        <>
                            <Accordion
                                expanded={activeH1Level?.[typeLabel]}
                                onChange={(e) => handleActiveAcc(e, c?.type, c)}
                                classes={{ root: classes.accordionStyle }}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        activeH1Level?.[typeLabel]
                                            ? (<ArrowDropUpIcon />) : (
                                                <ArrowDropDownIcon />
                                            )
                                    }
                                    aria-controls="panel1a-content"
                                    className={classes.sectionHeader1}
                                    classes={{
                                        expandIcon: activeH1Level?.[typeLabel]
                                            ? classes.iconButton1 : classes.expandedAccIcon1,
                                        expanded: classes.expandedAcc1,
                                    }}
                                >
                                    <Grid container>
                                        <Grid item xs={12} className={classes.adminSettingAccSumContMain}>
                                            {c.type}
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails
                                    classes={{
                                        root: ((c?.type === 'Column') || (c?.type === 'Gallery'))
                                            ? classes.accordionDetailStyle1 : classes.accordionDetailStyle,
                                    }}
                                >
                                    {((c?.type === 'Column') || (c?.type === 'Gallery'))
                                        ? (
                                            <>
                                                { columnSettings.map((col) => (
                                                    <Accordion defaultExpanded classes={{ root: classes.accordionStyle }}>
                                                        <AccordionSummary
                                                            className={classes.sectionHeader3}
                                                            expandIcon={<ArrowRight />}
                                                            aria-controls="panel1a-content"
                                                            classes={{
                                                                expandIcon: classes.expandedColAccIcon,
                                                                expanded: classes.expandedColAcc,
                                                            }}
                                                        >
                                                            <Grid container>
                                                                <Grid item xs={12} className={classes.adminSettingAccSumContMain}>
                                                                    {col?.props?.isLR
                                                                        ? accordionLabel?.SuperHeader
                                                                        : c?.type === 'Gallery' ? 'Gallery Settings'
                                                                            : 'Column Header'}
                                                                </Grid>
                                                            </Grid>
                                                        </AccordionSummary>
                                                        <AccordionDetails classes={{ root: classes.accordionDetailStyle }}>
                                                            <Grid container>
                                                                <SettingsList
                                                                    componentList={[col]}
                                                                    updateComponent={updateComponent}
                                                                    openComponent={col}
                                                                />
                                                            </Grid>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                ))}

                                            </>
                                        )
                                        : c?.type === 'Component'
                                            ? (
                                                <>
                                                    { compSettings.map((comp, i) => (
                                                        <Accordion
                                                            expanded={activeCompAcc === `Comp - ${comp?.id}`}
                                                            onChange={(e) => handleCompSettingAcc(e, comp)}
                                                            classes={{ root: classes.accordionStyle }}
                                                        >
                                                            <AccordionSummary
                                                                expandIcon={
                                                                    (activeCompAcc === `Comp - ${comp?.id}`) ? (
                                                                        <ArrowDropUpIcon />
                                                                    ) : (
                                                                        <ArrowDropDownIcon />
                                                                    )
                                                                }
                                                                aria-controls="panel1a-content"
                                                                className={classes.sectionHeader3}
                                                                classes={{
                                                                    expandIcon: (activeCompAcc === `Comp - ${comp?.id}`)
                                                                        ? classes.iconChildAccButton : classes.expandedAccIcon1,
                                                                    expanded: classes.expandedAcc3,
                                                                }}
                                                            >
                                                                <Grid container>
                                                                    <Grid item xs={12} className={classes.adminSettingAccSumContMain}>
                                                                        {`${comp.type}-${i + 1}`}
                                                                    </Grid>
                                                                </Grid>
                                                            </AccordionSummary>
                                                            <AccordionDetails classes={{ root: classes.accordionDetailStyle }}>
                                                                <Grid container>
                                                                    <SettingsList
                                                                        componentList={[comp]}
                                                                        updateComponent={updateComponent}
                                                                        openComponent={comp}
                                                                    />
                                                                </Grid>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    ))}

                                                </>
                                            )
                                            : (
                                                <Grid container>
                                                    <SettingsList
                                                        componentList={[c]}
                                                        updateComponent={updateComponent}
                                                        openComponent={c}
                                                    />

                                                    {(editTimelineColumn || editTimeline || addColumnLeftRight) ? null : (
                                                        <>
                                                            <Grid item xs={12}>
                                                                <Accordion
                                                                    expanded={activePOIAccordion}
                                                                    onChange={() => setActivePOIAccordion(!activePOIAccordion)}
                                                                    classes={{ root: classes.accordionStyle }}
                                                                >
                                                                    <AccordionSummary
                                                                        expandIcon={
                                                                            activePOIAccordion ? (
                                                                                <ArrowDropUpIcon />
                                                                            ) : (
                                                                                <ArrowDropDownIcon />
                                                                            )
                                                                        }
                                                                        aria-controls="panel1a-content"
                                                                        className={classes.sectionHeader3}
                                                                        classes={{
                                                                            expandIcon: activePOIAccordion
                                                                                ? classes.iconChildAccButton : classes.expandedAccIcon1,
                                                                            expanded: classes.expandedAcc3,
                                                                        }}
                                                                    >
                                                                        <div className={classes.poiSelectCont}>
                                                                            <div className={classes.adminSettingAccSumContMain}>
                                                                                Procedure of Interest
                                                                            </div>
                                                                            <div>
                                                                                {poiProps?.selectAllPOIHandler && poiProps?.selectAllPOIHandler()}
                                                                            </div>
                                                                        </div>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails classes={{ root: classes.accordionDetailStyle }}>
                                                                        <Grid container>
                                                                            {poiProps?.renderProcedureSelectorGrid
                                                                            && poiProps?.renderProcedureSelectorGrid()}
                                                                        </Grid>
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            </Grid>
                                                            {(workshopComponent?.isProgression || !workshopComponent?.isProgression) && (
                                                                <Accordion classes={{ root: classes.accordionStyle }}>
                                                                    <AccordionSummary
                                                                        className={classes.sectionHeader3}
                                                                        expandIcon={
                                                                            (activeProtocolChildAcc.activeCol
                                                                                || (activeProtocolChildAcc.activeComp === c?.id))
                                                                                ? (
                                                                                    <ArrowDropUpIcon />
                                                                                ) : (
                                                                                    <ArrowDropDownIcon />
                                                                                )
                                                                        }
                                                                        aria-controls="panel1a-content"
                                                                        classes={{
                                                                            expandIcon: (activeProtocolChildAcc.activeCol
                                                                                || (activeProtocolChildAcc.activeComp === c?.id))
                                                                                ? classes.iconChildAccButton : classes.expandedAccIcon1,
                                                                            expanded: classes.expandedAcc3,
                                                                        }}
                                                                    >
                                                                        <Grid container>
                                                                            <Grid item xs={12} className={classes.adminSettingAccSumContMain}>
                                                                                User View / Privileges
                                                                            </Grid>
                                                                        </Grid>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails classes={{ root: classes.accordionDetailStyle }}>
                                                                        <Grid container>
                                                                            <Grid item xs={12}>
                                                                                <Accordion
                                                                                    expanded={TimeLineAccessAcc}
                                                                                    onChange={(e) => handleTimeLineAccessAcc(e)}
                                                                                    classes={{ root: classes.accordionStyle }}
                                                                                >
                                                                                    <AccordionSummary
                                                                                        expandIcon={
                                                                                            TimeLineAccessAcc ? (
                                                                                                <ArrowDropUpIcon />
                                                                                            ) : (
                                                                                                <ArrowDropDownIcon />
                                                                                            )
                                                                                        }
                                                                                        aria-controls="panel1a-content"
                                                                                        className={classes.sectionHeader5}
                                                                                        classes={{
                                                                                            expandIcon: TimeLineAccessAcc
                                                                                                ? classes.iconButton1 : classes.expandedAccIcon1,
                                                                                            expanded: classes.expandedAcc3,
                                                                                        }}
                                                                                    >
                                                                                        <Grid container>
                                                                                            <Grid
                                                                                                item
                                                                                                xs={12}
                                                                                                className={classes.adminSettingAccSumContMain}
                                                                                            >
                                                                                                Timeline User View
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                        {uaProps?.selectAllUAHandler && uaProps.selectAllUAHandler()}
                                                                                    </AccordionSummary>
                                                                                    <AccordionDetails
                                                                                        classes={{ root: classes.accordionDetailStyle }}
                                                                                    >
                                                                                        {uaProps?.renderUAGrid && uaProps.renderUAGrid()}
                                                                                    </AccordionDetails>
                                                                                </Accordion>
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                {settingMenuType === 'Admin'
                                                                        && (
                                                                            <SettingsAccess
                                                                                component={compCol?.[0]}
                                                                            />
                                                                        )}
                                                                            </Grid>
                                                                        </Grid>
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            )}
                                                        </>
                                                    )}
                                                </Grid>
                                            )}
                                </AccordionDetails>
                            </Accordion>
                        </>
                    );
                })}
            </>
        );
    };

    // render column view on first level
    const RenderCFColumnView = () => {
        const flatCompList = [];
        if ((selectedComponent?.type === 'Column'
            && Array.isArray(selectedComponent?.props?.progressions)) || workshopComponent?.type !== 'Protocol') return null;

        buildSettingsTreePage(flatCompList, workshopComponent);
        let filterCompList = flatCompList.length > 0 && flatCompList.filter((fc) => ((fc.type === 'Column' || fc.type === 'Gallery')
            && fc.props.isShowTimeline)).filter((c) => !c.props.hideFromWorkshop);
        filterCompList = filterCompList.filter((fc) => (fc.props.columnType === 'right' ? false : !fc.mirrorId));
        return (
            <Grid container>
                {filterCompList && filterCompList.map((component, i) => (
                    !component?.props?.isLR ? (
                        <Fragment key={component.id}>
                            <Accordion
                                expanded={activeChildAcc === `Column - ${i + 1}`}
                                onChange={(e) => handleActiveChildAcc(e, `Column - ${i + 1}`, component)}
                                classes={{ root: classes.accRoot }}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        activeChildAcc === `Column - ${i + 1}` ? (
                                            <ArrowDropUpIcon />
                                        ) : (
                                            <ArrowDropDownIcon />
                                        )
                                    }
                                    aria-controls="panel1a-content"
                                    className={classes.sectionHeader2}
                                    classes={{
                                        expandIcon: activeChildAcc === `Column - ${i + 1}` ? classes.iconButton2 : classes.expandedAccIcon2,
                                        expanded: classes.expandedAcc2,
                                    }}
                                >
                                    <Grid container>
                                        <Grid item xs={12} className={classes.detailContLeft1}>
                                            {component.type === 'Gallery' ? 'Slide' : (component?.props?.LRProps ? accordionLabel.ComponentGroup
                                                : (component?.props?.isLR ? accordionLabel.SuperHeader : 'Column'))}
                                            {' '}
                                            {component?.props?.LRProps ? `${i}` : (!component?.props?.isLR && i + 1)}
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails classes={{ root: classes.accordionDetailStyle }}>
                                    <Grid item xs={12}>
                                        {activeChildAcc === `Column - ${i + 1}` && renderColumnChild(component, filterCompList)}
                                    </Grid>
                                    {workshopComponent?.isProgression && workshopComponent?.type === 'Column' && (
                                        <Grid item xs={12}>
                                            <Accordion
                                                expanded={activeH1Level?.useraccessnew}
                                                onChange={(e) => handleActiveAcc(e, 'userAccessNew')}
                                                classes={{ root: classes.accordionStyle }}
                                            >
                                                <AccordionSummary
                                                    expandIcon={
                                                        activeH1Level?.useraccessnew ? (
                                                            <ArrowDropUpIcon />
                                                        ) : (
                                                            <ArrowDropDownIcon />
                                                        )
                                                    }
                                                    aria-controls="panel1a-content"
                                                    className={classes.sectionHeader1}
                                                    classes={{
                                                        expandIcon: activeH1Level?.useraccessnew ? classes.iconButton1 : classes.expandedAccIcon1,
                                                        expanded: classes.expandedAcc1,
                                                    }}
                                                >
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            className={classes.adminSettingAccSumContMain}
                                                        >
                                                            User Access
                                                        </Grid>
                                                    </Grid>
                                                </AccordionSummary>
                                                <AccordionDetails classes={{ root: classes.accordionDetailStyle }}>
                                                    <Box className={classes.accessControlGrid}>
                                                        {userTypeData && userTypeData.map((ua) => (
                                                            <Box className={classes.accessControlItem}>
                                                                <FormControlLabel
                                                                    control={(
                                                                        <Checkbox
                                                                            disabled={loggedUserType === ua.label}
                                                                            checked={workshopComponent.props.userAccess?.[ua.value]}
                                                                            name={ua.value}
                                                                            classes={{ root: classes.paddSpac }}
                                                                            onChange={(e) => handleChangeUA(e)}
                                                                        />
                                                                    )}
                                                                    label={ua.label}
                                                                />
                                                            </Box>
                                                        ))}
                                                    </Box>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    )}
                                </AccordionDetails>
                            </Accordion>
                        </Fragment>
                    ) : null
                ))}
            </Grid>
        );
    };

    const getAllTimelineAccordionView = () => (
        <Grid item xs={12} className={classes.displayDetailCont}>
            <Grid container className={classes.adminSettingAccDetailCont}>
                <Grid item xs={4} className={classes.detailContLeft}>All User Timelines</Grid>
                <Grid item xs={8} className={classes.detailContRight}>{loggedInUserDetails?.name}</Grid>
                <Grid item xs={4} className={classes.detailContLeft}>Industry</Grid>
                <Grid item xs={8} className={classes.detailContRight}>{selectedTimeline?.industries}</Grid>
                <Grid item xs={4} className={classes.detailContLeft}>Specialty</Grid>
                <Grid item xs={8} className={classes.detailContRight}>{selectedTimeline?.['sub_specialities']}</Grid>
                <Grid item xs={4} className={classes.detailContLeft}>Doctor Office</Grid>
                <Grid item xs={8} className={classes.detailContRight}>{loggedInUserDetails?.['organization_member']?.[0]?.organization?.name}</Grid>
                <Grid item xs={4} className={classes.detailContLeft}>Office Subset Timeline</Grid>
                <Grid item xs={8} className={classes.detailContRight}>{loggedInUserDetails?.['organization_member']?.[0]?.role}</Grid>
                <Grid item xs={4} className={classes.detailContLeft}>Specific User Timeline</Grid>
                <Grid item xs={8} className={classes.detailContRight}>{selectedTimeline?.name}</Grid>
            </Grid>
        </Grid>
    );

    const getAllTimelineTableOrganisation = () => (
        <TimelineTableForDoctoreView />
    );

    const getTimelineAccordionView = () => {
        const childArray = [];
        if (selectedTimeline) {
            selectedTimeline.columns.forEach((column, index) => {
                childArray.push(
                    <>
                        <Grid item xs={5} className={classes.detailContLeft}>
                            {`Column ${index + 1}`}
                        </Grid>
                        <Grid
                            item
                            xs={7}
                            onClick={(e) => handleClickComp(e, null, { type: 'isColumn', column, index })}
                            className={classes.detailContRightLink}
                        >
                            {column.props.title}
                        </Grid>
                    </>
                );
            });
        }
        return (
            <Grid item xs={12} className={classes.displayDetailCont}>
                <Grid container className={classes.adminSettingAccDetailCont}>
                    <Grid item xs={5} className={classes.detailContLeft}>Timeline</Grid>
                    <Grid
                        item
                        xs={7}
                        className={[classes.detailContRightLink,
                            (state?.type === 'isTimeline' || editTimeline) ? classes.selectedColor : null].join(' , ')}
                        onClick={(e) => handleClickComp(e, null, { type: 'isTimeline' })}
                    >
                        {selectedTimeline?.name}
                    </Grid>
                    {childArray}
                </Grid>
            </Grid>
        );
    };

    const handleChangeUA = (e, column = null) => {
        const { name, checked } = e.target;
        if (column) {
            column.props.userAccess[name] = checked;
        } else {
            workshopComponent.props.userAccess[name] = checked;
        }
        setState({ ...state, flag: !state.flag });
    };

    if (workshopComponent?.type === 'Column') {
        if (!Object.prototype.hasOwnProperty.call(workshopComponent.props, 'userAccess')) {
            workshopComponent.props.userAccess = {
                isSuperAdmin: loggedUserType === 'Super Admin',
                isDoctor: loggedUserType === 'Doctor',
                writePatient: false,
            };
        } else if (Object.prototype.hasOwnProperty.call(workshopComponent.props, 'userAccess')) {
            workshopComponent.props.userAccess = {
                isSuperAdmin: loggedUserType === 'Super Admin' ? true : workshopComponent.props.userAccess.isSuperAdmin,
                isDoctor: loggedUserType === 'Doctor' ? true : workshopComponent.props.userAccess.isDoctor,
                writePatient: !!workshopComponent.props.userAccess.writePatient,
            };
        }
    }

    if (workshopComponent?.type === 'Timeline') {
        workshopComponent.columns.forEach((c) => {
            if (!c?.props?.isProfile && !Object.prototype.hasOwnProperty.call(c.props, 'userAccess')) {
                c.props.userAccess = {
                    isSuperAdmin: loggedUserType === 'Super Admin',
                    isDoctor: loggedUserType === 'Doctor',
                };
            } else if (Object.prototype.hasOwnProperty.call(c.props, 'userAccess')) {
                c.props.userAccess = {
                    isSuperAdmin: loggedUserType === 'Super Admin' ? true : c.props.userAccess.isSuperAdmin,
                    isDoctor: loggedUserType === 'Doctor' ? true : c.props.userAccess.isDoctor,
                };
            }
        });
    }

    useEffect(() => {
        if (workshopComponent?.type === 'Column' || workshopComponent?.type === 'Timeline') {
            switch (loggedUserType) {
            case 'Super Admin':
                userTypeData = userTypeData.filter((u) => u.key !== 'isPatient');
                break;
            case 'Doctor':
                userTypeData = userTypeData.filter((u) => u.key !== 'isSuperAdmin');
                break;
            default:
                break;
            }
            setState({ ...state, flag: !state.flag });
        }
    }, []);

    return (
        <div className={classes.settingListCont}>
            <Grid container className={classes.adminSettingCont}>
                {tlProps.renderSettingTypeButtons && tlProps.renderSettingTypeButtons()}
                {/* All timeline Accordion Start Here */}
                {isTimelineAccFlag
                    && (
                        <Accordion
                            expanded={activeChildAcc === 'All Timeline'}
                            onChange={(e) => handleActiveChildAcc(e, 'All Timeline')}
                            classes={{ root: classes.accRoot }}
                        >
                            <AccordionSummary
                                expandIcon={
                                    activeChildAcc === 'All Timeline' ? (
                                        <ArrowDropUpIcon />
                                    ) : (
                                        <ArrowDropDownIcon />
                                    )
                                }
                                aria-controls="panel1a-content"
                                className={classes.sectionHeader2}
                                classes={{
                                    expandIcon: activeChildAcc === 'All Timeline' ? classes.iconButton2 : classes.expandedAccIcon2,
                                    expanded: classes.expandedAcc2,
                                }}
                            >
                                <Grid item xs={12}>
                                    {`All Timeline (${loggedInUserDetails?.name})`}
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails classes={{ root: classes.accordionDetailStyle }}>
                                <Grid item xs={12}>
                                    <Accordion
                                        expanded={activeH1Level?.display}
                                        onChange={(e) => handleActiveAcc(e, 'display')}
                                        classes={{ root: classes.accordionStyle }}
                                    >
                                        <AccordionSummary
                                            expandIcon={
                                                activeH1Level?.display ? (
                                                    <ArrowDropUpIcon />
                                                ) : (
                                                    <ArrowDropDownIcon />
                                                )
                                            }
                                            aria-controls="panel1a-content"
                                            className={classes.sectionHeader1}
                                            classes={{
                                                expandIcon: activeH1Level?.display ? classes.iconButton1 : classes.expandedAccIcon1,
                                                expanded: classes.expandedAcc1,
                                            }}
                                        >
                                            <Grid container>
                                                <Grid item xs={12} className={classes.adminSettingAccSumContMain}>Display</Grid>
                                            </Grid>
                                        </AccordionSummary>
                                        <AccordionDetails classes={{ root: classes.accordionDetailStyle }}>
                                            <div className={classes.timelineTableOrgCont}>
                                                {getAllTimelineTableOrganisation()}
                                            </div>
                                            {getAllTimelineAccordionView()}
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                <Grid item xs={12}>
                                    {!editTimeline ? (
                                        <Accordion
                                            expanded={activeH1Level?.protocol}
                                            onChange={(e) => handleActiveAcc(e, 'protocol')}
                                            classes={{ root: classes.accordionStyle }}
                                        >
                                            <AccordionSummary
                                                expandIcon={
                                                    activeH1Level?.protocol ? (
                                                        <ArrowDropUpIcon />
                                                    ) : (
                                                        <ArrowDropDownIcon />
                                                    )
                                                }
                                                aria-controls="panel1a-content"
                                                className={classes.sectionHeader1}
                                                classes={{
                                                    expandIcon: activeH1Level?.protocol ? classes.iconButton1 : classes.expandedAccIcon1,
                                                    expanded: classes.expandedAcc1,
                                                }}
                                            >
                                                <Grid container>
                                                    <Grid item xs={12} className={classes.adminSettingAccSumContMain}>Function</Grid>
                                                </Grid>
                                            </AccordionSummary>
                                            <AccordionDetails classes={{ root: classes.accordionDetailStyle }}>
                                                <Grid container>
                                                    <Grid item xs={12} className={classes.displayDetailCont}>
                                                        <Grid container className={classes.adminSettingAccDetailCont}>
                                                            {/* {getAllTimelineTableOrganisation()} */}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    )
                                        : null}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    )}
                {/* All timeline Accordion End Here */}
                {/* Timeline Accordion Start Here */}
                {isTimelineAccFlag
                    && (
                        <Accordion
                            expanded={activeChildAcc === 'Timeline'}
                            onChange={(e) => handleActiveChildAcc(e, 'Timeline')}
                            classes={{ root: classes.accRoot }}
                        >
                            <AccordionSummary
                                expandIcon={
                                    activeChildAcc === 'Timeline' ? (
                                        <ArrowDropUpIcon />
                                    ) : (
                                        <ArrowDropDownIcon />
                                    )
                                }
                                aria-controls="panel1a-content"
                                className={classes.sectionHeader2}
                                classes={{
                                    expandIcon: activeChildAcc === 'Timeline' ? classes.iconButton2 : classes.expandedAccIcon2,
                                    expanded: classes.expandedAcc2,
                                }}
                            >
                                <Grid item xs={12}>
                                    {`Timeline (${selectedTimeline?.name})`}
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails classes={{ root: classes.accordionDetailStyle }}>
                                <Grid item xs={12}>
                                    <Accordion
                                        expanded={activeH1Level?.display}
                                        onChange={(e) => handleActiveAcc(e, 'display')}
                                        classes={{ root: classes.accordionStyle }}
                                    >
                                        <AccordionSummary
                                            expandIcon={
                                                activeH1Level?.display ? (
                                                    <ArrowDropUpIcon />
                                                ) : (
                                                    <ArrowDropDownIcon />
                                                )
                                            }
                                            aria-controls="panel1a-content"
                                            className={classes.sectionHeader1}
                                            classes={{
                                                expandIcon: activeH1Level?.display ? classes.iconButton1 : classes.expandedAccIcon1,
                                                expanded: classes.expandedAcc1,
                                            }}
                                        >
                                            <Grid container>
                                                <Grid item xs={12} className={classes.adminSettingAccSumContMain}>Display</Grid>
                                            </Grid>
                                        </AccordionSummary>
                                        <AccordionDetails classes={{ root: classes.accordionDetailStyle }}>
                                            {getTimelineAccordionView()}
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                <Grid item xs={12}>
                                    <Accordion
                                        expanded={activeH1Level?.protocol}
                                        onChange={(e) => handleActiveAcc(e, 'protocol')}
                                        classes={{ root: classes.accordionStyle }}
                                    >
                                        <AccordionSummary
                                            expandIcon={
                                                activeH1Level?.protocol ? (
                                                    <ArrowDropUpIcon />
                                                ) : (
                                                    <ArrowDropDownIcon />
                                                )
                                            }
                                            aria-controls="panel1a-content"
                                            className={classes.sectionHeader1}
                                            classes={{
                                                expandIcon: activeH1Level?.protocol ? classes.iconButton1 : classes.expandedAccIcon1,
                                                expanded: classes.expandedAcc1,
                                            }}
                                        >
                                            <Grid container>
                                                <Grid item xs={12} className={classes.adminSettingAccSumContMain}>Function</Grid>
                                            </Grid>
                                        </AccordionSummary>
                                        <AccordionDetails classes={{ root: classes.accordionDetailStyle }}>
                                            <Grid container>
                                                {children}
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    )}
                {/* Timeline Accordion End Here */}
                {/* Timeline Column Accordion Start Here */}
                {!editTimeline && workshopComponent?.type === 'Text' && (
                    <>
                        <Accordion
                            expanded={activeChildAcc === 'Timeline Column'}
                            onChange={(e) => handleActiveChildAcc(e, 'Timeline Column')}
                            classes={{ root: classes.accRoot }}
                        >
                            <AccordionSummary
                                expandIcon={activeChildAcc === 'Timeline Column' ? (
                                    <ArrowDropUpIcon />
                                ) : (
                                    <ArrowDropDownIcon />
                                )}
                                aria-controls="panel1a-content"
                                className={classes.sectionHeader2}
                                classes={{
                                    expandIcon: activeChildAcc === 'Timeline Column' ? classes.iconButton2 : classes.expandedAccIcon2,
                                    expanded: classes.expandedAcc2,
                                }}
                            >
                                <Grid item xs={12}>
                                    {workshopComponent?.props?.text}
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails classes={{ root: classes.accordionDetailStyle }}>
                                {workshopComponent?.isProgression && workshopComponent?.type === 'Column' && (
                                    <Grid item xs={12}>
                                        <Accordion
                                            expanded={activeH1Level?.useraccessnew}
                                            onChange={(e) => handleActiveAcc(e, 'userAccessNew')}
                                            classes={{ root: classes.accordionStyle }}
                                        >
                                            <AccordionSummary
                                                expandIcon={
                                                    activeH1Level?.useraccessnew ? (
                                                        <ArrowDropUpIcon />
                                                    ) : (
                                                        <ArrowDropDownIcon />
                                                    )
                                                }
                                                aria-controls="panel1a-content"
                                                className={classes.sectionHeader1}
                                                classes={{
                                                    expandIcon: activeH1Level?.useraccessnew ? classes.iconButton1 : classes.expandedAccIcon1,
                                                    expanded: classes.expandedAcc1,
                                                }}
                                            >
                                                <Grid container>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        className={classes.adminSettingAccSumContMain}
                                                    >
                                                        User Access
                                                    </Grid>
                                                </Grid>
                                            </AccordionSummary>
                                            <AccordionDetails classes={{ root: classes.accordionDetailStyle }}>
                                                <Box className={classes.accessControlGrid}>
                                                    {userTypeData && userTypeData.map((ua) => (
                                                        <Box className={classes.accessControlItem}>
                                                            <FormControlLabel
                                                                control={(
                                                                    <Checkbox
                                                                        disabled={loggedUserType === ua.label}
                                                                        checked={workshopComponent.props.userAccess?.[ua.value]}
                                                                        name={ua.value}
                                                                        classes={{ root: classes.paddSpac }}
                                                                        onChange={(e) => handleChangeUA(e)}
                                                                    />
                                                                )}
                                                                label={ua.label}
                                                            />
                                                        </Box>
                                                    ))}
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                )}

                                {(editTimelineColumn || editTimeline || addColumnLeftRight) ? null : (
                                    <>
                                        <Grid item xs={12}>
                                            <Accordion
                                                expanded={activeH1Level?.poi}
                                                onChange={(e) => handleActiveAcc(e, 'POI')}
                                                classes={{ root: classes.accordionStyle }}
                                            >
                                                <AccordionSummary
                                                    expandIcon={
                                                        activeH1Level?.poi ? (
                                                            <ArrowDropUpIcon />
                                                        ) : (
                                                            <ArrowDropDownIcon />
                                                        )
                                                    }
                                                    aria-controls="panel1a-content"
                                                    className={classes.sectionHeader1}
                                                    classes={{
                                                        expandIcon: activeH1Level?.poi ? classes.iconButton1 : classes.expandedAccIcon1,
                                                        expanded: classes.expandedAccPOI,
                                                    }}
                                                >
                                                    <div className={classes.poiSelectCont}>
                                                        <div className={classes.adminSettingAccSumContMain}> Procedure of Interest</div>
                                                        <div>{poiProps?.selectAllPOIHandler && poiProps?.selectAllPOIHandler()}</div>
                                                    </div>
                                                </AccordionSummary>
                                                <AccordionDetails classes={{ root: classes.accordionDetailStyle }}>
                                                    <Grid container>
                                                        {poiProps?.renderProcedureSelectorGrid && poiProps?.renderProcedureSelectorGrid()}
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                        {(workshopComponent?.isProgression || !workshopComponent?.isProgression) && (
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Accordion
                                                            expanded={TimeLineAccessAcc}
                                                            onChange={(e) => handleTimeLineAccessAcc(e)}
                                                            classes={{ root: classes.accordionStyle }}
                                                        >
                                                            <AccordionSummary
                                                                expandIcon={
                                                                    TimeLineAccessAcc ? (
                                                                        <ArrowDropUpIcon />
                                                                    ) : (
                                                                        <ArrowDropDownIcon />
                                                                    )
                                                                }
                                                                aria-controls="panel1a-content"
                                                                className={classes.sectionHeader1}
                                                                classes={{
                                                                    expandIcon: TimeLineAccessAcc ? classes.iconButton1 : classes.expandedAccIcon1,
                                                                    expanded: classes.expandedAcc1,
                                                                }}
                                                            >
                                                                <Grid container>
                                                                    <Grid item xs={12} className={classes.adminSettingAccSumContMain}>
                                                                        Timeline User View
                                                                    </Grid>
                                                                </Grid>
                                                                {uaProps?.selectAllUAHandler && uaProps.selectAllUAHandler()}
                                                            </AccordionSummary>
                                                            <AccordionDetails classes={{ root: classes.accordionDetailStyle }}>
                                                                {uaProps?.renderUAGrid && uaProps.renderUAGrid()}
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {settingMenuType === 'Admin'
                                                        && (
                                                            <SettingsAccess
                                                                component={workshopComponent}
                                                            />
                                                        )}
                                                </Grid>
                                            </Grid>
                                        )}
                                    </>
                                )}
                            </AccordionDetails>
                        </Accordion>
                        {/* Timeline Column Accordion End Here */}
                    </>
                )}
                <Grid item xs={12}>
                    {RenderCFColumnView()}
                </Grid>
            </Grid>
        </div>
    );
};

export default AdminSettings;
