/* eslint-disable react/jsx-props-no-spreading */
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {
    Grid, Paper, Tooltip, Typography,
} from '@material-ui/core';
import { TimelineContext, UniversalContext } from '../context';
import {
    buildSettingsTreePage, findComponent,
} from '../helper/commonHelper';
import { componentWithId, mergeComponentPropsCallback } from './Atoms';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useDrag, useDrop } from 'react-dnd';
import { useRecoilCallback, useRecoilState } from 'recoil';
import CheckboxUI from '@material-ui/core/Checkbox';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React, {
    useContext, useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import TextField from '@material-ui/core/TextField';
import ToolTipIcon from '../images/TooltipIcon.svg';

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 10,
        fontFamily: 'ProximaNova-Semibold',
        maxWidth: 500,
        padding: 5,
        margin: 5,
    },
}))(Tooltip);

const useStyles = makeStyles(() => ({
    radioCustom: {
        '& label': {
            alignItems: 'start',
        },
        '& span': {
            padding: '0px 0px 0px 1px',
        },
    },
    pd0: {
        padding: 0,
    },
    listItemText: {
        '& svg': {
            fontSize: '.75rem',
        },
        '& span': {
            fontSize: 10,
            '&:nth-child(2)': {
                margin: '1.4px 0px 0px 2px',
                lineHeight: '10px',
                fontWeight: '400',
                wordBreak: 'break-all',
            },
        },
    },
    deleteIconSize: {
        fontSize: '.6rem',
        cursor: 'pointer',
    },
    tooltipIconCont: {
        height: 16,
        width: 16,
        cursor: 'pointer',
        borderRadius: '50%',
        textAlign: 'center',
        background: 'inherit',
        marginRight: 5,
        '&:hover': {
            background: '#E1E8FC',
        },
    },
    highlightOptionCls: {
        background: '#E1E8FC',
        border: '1px solid #7070703B',
        padding: 3,
        width: 60,
    },
    disabledOptionCls: {
        border: '1px solid #7070703B',
        padding: 3,
        width: 60,
        color: '#6A6A6A',
    },
    disabledOption: {
        opacity: 0.3,
        fontSize: 10,
    },
    toolTipleftCont: {
        border: '1px solid #7070703B',
        padding: 3,
        width: 60,
    },
    toolTipRightCont: {
        display: 'flex',
        flexDirection: 'column',
    },
    hideOptionCls: {
        background: '#ececec',
        display: 'none',
    },
    flex: {
        display: 'flex',
        alignItems: 'center',
        top: 20,
        right: 12,
    },
    darkSelectedAssociationCls: {
        background: '#FFC000',
    },
    selectedAssociationCls: {
        background: '#FFF2CC',
    },
    activeOption: {
        // background: '#718FF65C',
    },
    defaultOption: {
        background: '#ffffff',
    },
    topAlign: {
        alignItems: 'baseline',
        padding: '4px 0px 4px 4px',
    },
    scrollCls: {
        '&::-webkit-scrollbar': {
            display:
                'none',
        },
        '&:hover': {
            '&::-webkit-scrollbar': {
                height: '2.5px',
                width: '2.5px',
                display: 'block',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#888',
            },
        },
    },
    fixedHeight: {
        height: 36.5,
    },
    fixedHeightSelectComplete: {
        height: 38,
    },
    listItemStyle: {
        padding: 0,
    },
    editableData: {
        margin: '8px 8px 0px 4px',
        '& input': {
            fontSize: '12px',
            fontWeight: '400',
            fontFamily: 'ProximaNova-Regular !important',
            borderBottom: '0.5px solid rgb(203, 203, 203)',
            color: 'rgba(0, 0, 0, 0.87)',
            paddingBottom: '0px',
        },
    },
    checkbox: {
        padding: '1px 2px 2px 0px',
        '& svg': {
            height: '0.75rem',
            width: '0.75rem',
            marginBottom: 1.25,
            color: '#000000de',
        },
        margin: '8px 0px 0px 0px',
    },
}));

const grid = 0;
const style = {
    border: '1px dashed gray',
    marginBottom: '.5rem',
    backgroundColor: 'white',
    cursor: 'move',
};

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? 'grey' : '#fafafa',

    // styles we need to apply on draggables
    ...draggableStyle,
});

const getListStyle = () => ({ // isDraggingOver
    width: '100%',
});

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

// eslint-disable-next-line func-names
const nop = function () { };

const SortableList = ({
    data,
    dataType = 'dataList',
    currentAssociation,
    onOrderChange = nop,
    // eslint-disable-next-line no-unused-vars
    render,
    deleteItem = nop,
    associationData = null,
    parentComp,
    associatedCell,
    onAdd,
    config,
    updateValueOnEdit,
}) => {
    const {
        clearCurrentAssociation, workshopActiveComponent, workshopComponent, updateFlag, setUpdateFlag,
        lastDeletedOption, setLastDeletedOption, setSelectedOptionId, inWorkshop, selectedComponent,
        universalCompState, universalScndState,
    } = useContext(TimelineContext);
    const {
        handleManualSelection, handleSingleClickSSCB,
    } = useContext(UniversalContext);
    const classes = useStyles();
    const [component, setComponentState] = useRecoilState(componentWithId(parentComp));
    const [, setSelectedListIndex] = useState(null);
    const [showCloseIcon, setShowCloseIcon] = useState(false);
    const [showCloseIndex, setShowCloseIndex] = useState(null);
    const [flag, setFlag] = useState(null);
    const [optionCell] = useRecoilState(componentWithId(workshopActiveComponent?.parent));
    const [, setSelectCell] = useRecoilState(componentWithId(optionCell?.props?.parentCellId));
    const [, setParentSelect] = useRecoilState(componentWithId(optionCell?.props?.selectComponentId));
    const updatePropsRecoilComponent = useRecoilCallback(mergeComponentPropsCallback);
    const [editableListItemIndex, setEditableListItemIndex] = useState(null);
    const [editableListItemText, setEditableListItemText] = useState('');
    const [addOptionContextMenu, setAddOptionContextMenu] = useState(null);
    const [addOptionContextMenuActive, setAddOptionContextMenuActive] = useState(false);
    const [addOptionContextMenuIndex, setAddOptionContextMenuIndex] = useState(null);
    const [addOptionContextMenuParent, setAddOptionContextMenuParent] = useState(null);
    let findCompIndex = -1;
    findCompIndex = Array.isArray(component?.parent) ? component?.parent.findIndex((c) => c.id === component.props.selectId) : -1;
    const componentProps = findCompIndex !== -1 ? component.parent[findCompIndex] : config;
    const [rowComp] = useRecoilState(componentWithId(componentProps?.rowRef || workshopActiveComponent?.rowRef));

    const isManual = workshopComponent?.props?.pcRelationship === 'manual';
    const addOptionRef = useRef();

    useEffect(() => {
        if (workshopActiveComponent?.props?.options?.length) {
            setFlag(!flag);
        }
    }, [workshopActiveComponent?.props?.options?.length]);

    useLayoutEffect(() => {
        if (workshopActiveComponent && optionCell) {
            if (optionCell?.parent?.[0]?.type === 'Cell') {
                const parentSelectComponent = document.querySelector(`#true-${workshopActiveComponent.id}`);
                // eslint-disable-next-line no-unused-vars
                const associatedElement = document.querySelector(`#true-${optionCell.props.associatedComponent}`);
                const associatedCell = document.querySelector(`#true-${workshopActiveComponent?.props?.associatedCell}`);
                setTimeout(() => {
                    if (parentSelectComponent) {
                        updatePropsRecoilComponent(optionCell.props.associatedComponent, { updatedHeight: parentSelectComponent.clientHeight });
                    }
                    if (associatedCell) {
                        // eslint-disable-next-line no-unused-vars
                        const associatedCellSelectElem = associatedCell?.querySelector('.mainSelectCell');
                    }
                }, 0);
            } else if (optionCell?.props?.selectComponentId) {
                const { associatedIndex } = optionCell.props;
                const parentSelectComponent = document.querySelector(`#true-${workshopActiveComponent.id}`);
                const associatedElement = document.querySelector(`#true-${optionCell.props.selectComponentId}`);
                const optionElements = associatedElement?.querySelectorAll('.listOption');
                const currentOptionElement = optionElements?.[associatedIndex];
                if (currentOptionElement && parentSelectComponent && currentOptionElement.clientHeight < parentSelectComponent.clientHeight) {
                    currentOptionElement.style.height = `${parentSelectComponent.clientHeight}px`;
                }
                setParentSelect((value) => ({ ...value, layoutChange: !value.layoutChange }));
                setSelectCell((value) => ({ ...value, layoutChange: !value.layoutChange }));
            }
        }
    }, [workshopActiveComponent?.props?.options, optionCell]);

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const items = reorder(
            data,
            result.source.index,
            result.destination.index,
        );
        onOrderChange(items);
    };

    const renderAccociateionChild = (data) => {
        let optionMenu = '';
        const highlightedChildren = data.highlightedChildren || [];
        const disabledChildren = data.notHighlightedChildren || [];
        if (!workshopComponent?.props?.isHighlight) {
            const immediateChildren = data.immediateChildren || [];
            const highlightedChildrenImmediate = [];
            const disabledChildrenImmediate = [];
            immediateChildren.forEach((ele) => {
                if (highlightedChildren.includes(`${ele.value}#${ele.requiredId}`)) {
                    highlightedChildrenImmediate.push(`${ele.value}#${ele.requiredId}`);
                }
                if (disabledChildren.includes(`${ele.value}#${ele.requiredId}`)) {
                    disabledChildrenImmediate.push(`${ele.value}#${ele.requiredId}`);
                }
            });
            optionMenu = (
                <>
                    {highlightedChildrenImmediate.map((ele) => (
                        <div
                            className={classes.highlightOptionCls}
                        >
                            {ele.substring(0, ele.lastIndexOf('#'))}
                        </div>
                    ))}
                    {disabledChildrenImmediate.map((ele) => (
                        <div
                            className={classes.disabledOptionCls}
                        >
                            <Typography className={classes.disabledOption}>{ele.substring(0, ele.lastIndexOf('#'))}</Typography>
                        </div>
                    ))}
                </>
            );
        } else if (highlightedChildren) {
            optionMenu = (
                <>
                    {highlightedChildren.map((ele) => (
                        <div
                            className={classes.highlightOptionCls}
                        >
                            {ele.substring(0, ele.lastIndexOf('#'))}
                        </div>
                    ))}
                    {disabledChildren.map((ele) => (
                        <div
                            className={classes.disabledOptionCls}
                        >
                            <Typography className={classes.disabledOption}>{ele.substring(0, ele.lastIndexOf('#'))}</Typography>
                        </div>
                    ))}
                </>
            );
        }

        return optionMenu;
    };

    const renderAssociateTooltip = (parent, data) => (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="top"
        >
            <Grid item xs={6}>
                <div className={classes.toolTipleftCont}>{parent}</div>
            </Grid>
            <Grid item xs={6}>
                <div className={classes.toolTipRightCont}>
                    {renderAccociateionChild(data)}
                </div>
            </Grid>
        </Grid>
    );

    const renderAssociation = (item, associationItem,) => (
        <LightTooltip
            placement="bottom-start"
            title={renderAssociateTooltip(item.value, associationItem)}
        >
            <Paper elevation={0} square className={classes.tooltipIconCont}>
                <img src={ToolTipIcon} alt="tooltip-icon" />
            </Paper>
        </LightTooltip>
    );

    const handleSingleClick = (e, index) => {
        if (isManual && !rowComp?.props?.LRProps) {
            const reqObj = {
                rowComp, data, index, componentProps,
            };
            handleSingleClickSSCB(reqObj);
        }
        if (!inWorkshop && !workshopComponent?.props?.['wow feature']) {
            data.forEach((d, i) => {
                d.isSelect = i === index || false;
            });
        }
        const selectedOption = data[index];
        if (selectedOption.id) {
            setSelectedOptionId(selectedOption.id);
        }
        setSelectedListIndex(index);
    };

    const handleListIndex = (e, index) => {
        if (isManual) {
            const propsData = {
                index, rowComp, componentProps, data, states: {},
            };
            handleManualSelection(propsData);
        }
        setComponentState({ ...component });
        setUpdateFlag(!updateFlag);
    };

    const openAddOptionContextMenu = (event, index) => {
        event.preventDefault();
        event.stopPropagation();
        if (!addOptionRef?.current) {
            setAddOptionContextMenuIndex(index);
            setAddOptionContextMenuParent(event.target);
            setAddOptionContextMenu(addOptionContextMenu === null ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 } : null);
        }
    };

    useEffect(() => {
        if (!addOptionContextMenu && addOptionContextMenuActive && typeof (addOptionContextMenuIndex) === 'number') {
            setAddOptionContextMenuActive(false);
            addOptionContextMenuParent.click();
            onAdd(addOptionContextMenuIndex);
            setEditableListItemText('');
            setEditableListItemIndex(null);
            setAddOptionContextMenuIndex(null);
            setAddOptionContextMenuParent(null);
            setUpdateFlag(!updateFlag);
        }
    }, [addOptionContextMenu]);

    const closeAddOptionContextMenu = () => {
        setAddOptionContextMenu(null);
    };

    const addOptionBelow = () => {
        setAddOptionContextMenuActive(true);
        closeAddOptionContextMenu();
    };

    const makeListItemNonEditable = (event, index) => {
        if (event?.key === 'Enter' && event?.target?.value) {
            const requiredWorkshopComponent = [];
            buildSettingsTreePage(requiredWorkshopComponent, workshopComponent);
            for (let i = 0, l = requiredWorkshopComponent.length; i < l; i += 1) {
                if (requiredWorkshopComponent[i].id === workshopActiveComponent.id) {
                    requiredWorkshopComponent[i].props.options[index].label = editableListItemText;
                    requiredWorkshopComponent[i].props.options[index].value = editableListItemText;
                    break;
                }
            }
            setEditableListItemText('');
            setEditableListItemIndex(null);
            setUpdateFlag(!updateFlag);
        }
    };

    const handleTextChange = (event, index) => {
        updateValueOnEdit(event.target.value, index);
        setEditableListItemText(event.target.value);
    };

    const handleMouseEnter = (e, index, showClose) => {
        setShowCloseIcon(showClose);
        setShowCloseIndex(index);
    };

    const handleMouseLeave = (e, showClose) => {
        setShowCloseIcon(showClose);
        setShowCloseIndex(null);
    };

    const handleDelete = (index) => {
        const selectedOption = data[index];
        setLastDeletedOption({ index, selectId: parentComp, id: selectedOption.id });
    };

    useEffect(() => {
        if (!lastDeletedOption || lastDeletedOption?.staleDelete) return;
        const { index, selectId } = lastDeletedOption;
        if (selectId === parentComp) {
            deleteItem(index);
            lastDeletedOption.staleDelete = true;
        }
    }, [lastDeletedOption]);

    const renderListItem = (item, index, associatedCell) => {
        let associationItem = associationData && associationData[item.value];

        if (isManual && item?.highlightAssociation?.parentAssociation?.find((c) => ((c.optionId === universalCompState?.option?.id)
        || (c.optionId === universalCompState?.parent?.id)))) {
            item.highlightComponent = true;
        }

        if (!associationItem && workshopComponent?.props?.isHighlight) {
            const rc = !associatedCell && item?.props?.associatedCell ? findComponent(
                workshopComponent?.props?.components, findComponent(
                    workshopComponent?.props?.components, item.props.associatedCell
                )?.props?.components[0]?.associatedParent
            ) : findComponent(workshopComponent?.props?.components, associatedCell);
            const requiredRc = rc?.props?.components && rc?.props?.components[index];
            const requiredComponent = requiredRc?.props?.components[0];
            const notHighlightedChildren = [];
            if (requiredRc) {
                rc.props.components.forEach((comp, ind) => {
                    if ((index !== ind) && comp?.props?.components[0]?.props?.options) {
                        comp.props.components[0].props.options.forEach((opt) => {
                            notHighlightedChildren.push(`${opt.value}#${comp.id}`);
                        });
                    }
                });
            } else if (rc?.props?.options) {
                rc.props.options.forEach((opt, ind) => {
                    if (index !== ind) {
                        const requiredOptionCell = findComponent(workshopComponent?.props?.components, opt.props.associatedCell).props.components[0];
                        if (requiredOptionCell?.props?.options) {
                            requiredOptionCell.props.options.forEach((opt2) => {
                                notHighlightedChildren.push(`${opt2.value}#${requiredOptionCell.id}`);
                            });
                        }
                    }
                });
            }

            const requiredComponentOpt = requiredRc ? requiredComponent?.props?.options : findComponent(
                workshopComponent?.props?.components, rc?.props?.options[index]?.props.associatedCell
            )?.props?.components[0].props.options;
            if (requiredComponentOpt) {
                associationItem = {
                    highlightedChildren: requiredComponentOpt.map((opt) => `${opt.value}#${requiredRc ? requiredComponent.id : rc.id}`),
                    immediateChildren: requiredComponentOpt.map((opt) => ({ ...opt, requiredId: requiredRc ? requiredComponent.id : rc.id })),
                    notHighlightedChildren: [...notHighlightedChildren],
                };
            }
        }
        let tooltipDataFlag = false;

        const isCF = workshopComponent?.supergroup || selectedComponent?.supergroup === 'Complex Forms';
        if (associationItem && ((associationItem.disabledChildren && associationItem.disabledChildren.length > 0)
            || (associationItem.highlightedChildren && associationItem.highlightedChildren.length > 0))) {
            tooltipDataFlag = true;
        }

        let divHeight = null;
        let emptyAssociatedCell = true;
        let associatedCellHeight = null;
        if (item?.children?.id) {
            const id = `true-${item.children.id}`;
            const ele = document.getElementById(id);
            if (ele) {
                divHeight = ele.clientHeight;
            }
            emptyAssociatedCell = false;
        }
        if (item?.props?.associatedCell) {
            const id = `true-${item?.props?.associatedCell}`;
            const ele = document.getElementById(id);
            if (ele) {
                associatedCellHeight = ele.clientHeight;
            }
        }
        const idProps = item.id ? { id: `${inWorkshop || false}-${item.id}` } : {};
        return (
            <ListItem
                {...idProps}
                className={[classes.scrollCls, (item.value === currentAssociation && tooltipDataFlag && !clearCurrentAssociation
                    ? classes.darkSelectedAssociationCls
                    : (tooltipDataFlag ? classes.selectedAssociationCls : null),
                (emptyAssociatedCell && inWorkshop ? (workshopComponent?.component === 'SELECT-COMPLETED'
                || workshopComponent?.component === 'Select-Complete-Notes' || workshopComponent?.component === 'Text-Complete-Notes')
                    ? classes.fixedHeightSelectComplete : classes.fixedHeight : '')),
                (item.isSelect ? classes.activeOption : classes.defaultOption), classes.topAlign, 'listOption',
                ].join(' ')}
                style={{
                    height: associatedCellHeight > 10 ? associatedCellHeight : (item.children && divHeight),
                    borderBottom: isCF ? '0.5px solid #CBCBCB' : 'none',
                    overflow: 'hidden',
                    overflowY: 'scroll',
                    backgroundColor: (isManual && ((universalScndState?.option?.id === item?.id)
                    || universalScndState?.child?.includes(item.id))
                    )
                        ? '#16FF00' : (item.highlightComponent && isManual ? 'yellow' : 'white'),
                }}
                onContextMenu={index === editableListItemIndex || !item?.label ? undefined : (event) => openAddOptionContextMenu(event, index)}
            >
                <Menu
                    open={addOptionContextMenu !== null}
                    onClose={closeAddOptionContextMenu}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        addOptionContextMenu !== null
                            ? { top: addOptionContextMenu.mouseY, left: addOptionContextMenu.mouseX }
                            : undefined
                    }
                >
                    <MenuItem onClick={addOptionBelow}>Add Option</MenuItem>
                </Menu>
                <ListItem className={classes.listItemStyle}>
                    <CheckboxUI className={classes.checkbox} disabled />
                    <TextField
                        size="small"
                        value={item.label}
                        onChange={(event) => { handleTextChange(event, index); }}
                        variant="standard"
                        className={classes.editableData}
                        InputProps={{
                            disableUnderline: true,
                            onDoubleClick: (e) => handleListIndex(e, index),
                        }}
                        ref={addOptionRef}
                        fullWidth
                        onKeyPress={(event) => makeListItemNonEditable(event, index)}
                        onClick={(e) => { handleSingleClick(e, index); }}
                    />
                </ListItem>
                <ListItemSecondaryAction classes={{ root: classes.flex }}>
                    {associationData && associationItem && tooltipDataFlag && renderAssociation(item, associationItem)}
                    {showCloseIcon && showCloseIndex === index
                        && (
                            <IconButton className={classes.pd0} edge="end" aria-label="delete">
                                <CloseIcon className={classes.deleteIconSize} onClick={() => { handleDelete(index, item); }} />
                            </IconButton>
                        )}
                </ListItemSecondaryAction>
            </ListItem>
        );
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={dataType}>
                {(provided) => (
                    <List dense disablePadding>
                        <Paper
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle()}
                            elevation={0}
                        >
                            {data.map((item, index) => (
                                <Draggable
                                    key={index}
                                    draggableId={`id${index}`}
                                    index={index}
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style,
                                            )}
                                            className={classes.radioCustom}
                                            onMouseEnter={(e) => handleMouseEnter(e, index, true)}
                                            onMouseLeave={(e) => handleMouseLeave(e, false)}
                                        >
                                            {renderListItem(item, index, associatedCell)}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </Paper>
                    </List>
                )}
            </Droppable>
        </DragDropContext>
    );
};

/**
 * When user drag and drop selected media or set the selected media order
 * then this function will get called
 * @param {interger} id [unique index for each item]
 * @param {string} text [Text / Label value]
 * @param {integer} index [unique index]
 * @param {function} moveCard [Callback function after drag event]
 */
const Drag = ({
    id, text, index, moveCard,
}) => {
    const ref = useRef(null);
    const [, drop] = useDrop({
        accept: 'card',
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });
    const [{ isDragging }, drag] = useDrag({
        item: { type: 'card', id, index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));
    return (
        <div ref={ref} style={{ ...style, opacity }}>
            {text}
        </div>
    );
};

export { SortableList, Drag };
