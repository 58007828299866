/* eslint-disable no-case-declarations */
import { useMutation, useQuery } from '@apollo/react-hooks';
import React, { createContext } from 'react';
import gql from 'graphql-tag';

const GET_PATIENTS = gql`
query patientList($orgID: uuid, $offset: Int, $limit: Int, $order_by: [patient_order_by!], $nameFilter: String, $timelineFilter: String) {
    patient(order_by: $order_by, offset: $offset, limit: $limit,
        where: { organization_id: { _eq: $orgID }, full_name: {_like: $nameFilter}, timeline: {name: {_like: $timelineFilter}}}) {
      id
      timeline_id
      timeline {
        name
        id
      }
      full_name
      created_at
      updated_at
      photo {
        url
      }
      profile_photo
  },
  patient_aggregate(where: { organization_id: { _eq: $orgID }, full_name: {_like: $nameFilter}, timeline: {name: {_like: $timelineFilter}}}) {
        aggregate {
            count
        }
    }
  }
`;

/**
 * query to Delete patient
 */
const DELETE_PATIENT = gql`
    mutation deletePatient($id: uuid) {
        delete_patient(where: {id: {_eq: $id}}) {
            affected_rows
            returning {
                id
            }
        }
    } 
    `;

/**
 * query to Update File Name
 */
const UPDATE_PATIENT = gql`
    mutation updatePatient($id: uuid, $name: String,$timeline_id:uuid,$image: String) {
        update_patient(where: {id: {_eq: $id}}, _set: { full_name: $name,timeline_id:$timeline_id,profile_photo:$image } ) {
            affected_rows
            returning {
                id
            }
        }
    } 
    `;

/**
 * query to Update File Name
 */
const INSERT_PATIENT = gql`
mutation insertPatient($name: String,$timeline_id:uuid,$organization_id: uuid,$image: String,$timeline_data: jsonb){
    insert_patient(objects:{ full_name: $name,timeline_id:$timeline_id,organization_id: $organization_id,profile_photo:$image,
        timeline_data:$timeline_data } ) {
        affected_rows
        returning {
            id
        }
    }
} 
`;

export const Context = createContext({});

export const Provider = ({ currentOrg, children }) => {
    const patientsResponse = useQuery(GET_PATIENTS, {
        variables: {
            orgID: currentOrg.id,
            limit: 20,
            offset: 0,
            order_by: { updated_at: 'asc' },
            nameFilter: '%%',
            timelineFilter: '%%',
        },
    });
    const patientsLoading = patientsResponse.loading;
    const patientError = patientsResponse.error;

    const patients = !patientsResponse.loading ? patientsResponse.data.patient : undefined;
    const totalPatients = !patientsResponse.loading ? patientsResponse.data.patient_aggregate.aggregate.count : 0;
    const [updatePatient] = useMutation(UPDATE_PATIENT);
    const [insertPatient] = useMutation(INSERT_PATIENT);
    const [deletePatient] = useMutation(DELETE_PATIENT);

    /**
     * @function refetchPatients
     * @returns get updated response
     */
    const refetchPatients = () => patientsResponse.refetch();

    /**
     * @function updateMediaName
     * @param {string} id [primary key]
     * @param {string} fileName [updated file name]
     * @returns get updated response
     */
    const updatePatientDetail = (patientObj) => {
        const result = updatePatient({
            variables: {
                id: patientObj.id, name: patientObj.full_name, timeline_id: patientObj.timeline_id, image: patientObj.profile_photo,
            },
        });
        return result.then(() => {
            refetchPatients();
        });
    };

    /**
     * @function updateMediaName
     * @param {string} id [primary key]
     * @param {string} fileName [updated file name]
     * @returns get updated response
     */
    const insertPatientDetail = (patientObj) => {
        const result = insertPatient({
            variables: {
                name: patientObj.full_name,
                timeline_id: patientObj.timeline_id,
                image: patientObj.profile_photo,
                organization_id: currentOrg.id,
                timeline_data: patientObj.timeline_data,
            },
        });
        return result.then(() => {
            refetchPatients();
        });
    };

    /**
     * @function deleteMediaById
     * @param {string} id [primary key]
     * @returns get updated response
     */
    const deletePatientCotext = (patientId) => {
        const result = deletePatient({
            variables: {
                id: patientId,
            },
        });

        return result.then(() => {
            refetchPatients();
        });
    };

    /**
     * Integrate Pagination
     * @param {object} pagingObj
     */
    const fetchPatients = (pagingObj) => {
        let sortObj = {};
        if (pagingObj.clearData) {
            patientsResponse.data.patient = [];
        }
        if (pagingObj.orderBy) {
            sortObj = { [pagingObj.orderBy.name]: pagingObj.orderBy.type };
        }

        if (pagingObj.filteringObj) {
            pagingObj.filteringObj.full_name = `%${pagingObj.filteringObj.full_name}%`;
            pagingObj.filteringObj.timeline = `%${pagingObj.filteringObj.timeline}%`;
        }

        patientsResponse.fetchMore({
            variables: {
                orgID: currentOrg.id,
                offset: pagingObj.offset,
                limit: pagingObj.limit,
                order_by: sortObj,
                nameFilter: pagingObj.filteringObj.full_name,
                timelineFilter: pagingObj.filteringObj.timeline,
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return {
                    ...prev,
                    patient: [...prev.patient, ...fetchMoreResult.patient],
                    patient_aggregate: fetchMoreResult.patient_aggregate,
                };
            },
        });
    };

    const patientContext = {
        patientsLoading,
        patientError,
        patients,
        updatePatientDetail,
        insertPatientDetail,
        deletePatientCotext,
        fetchPatients,
        totalPatients,
    };

    // pass the value in provider and return
    return <Context.Provider value={patientContext}>{children}</Context.Provider>;
};

export const { Consumer } = Context;
