/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { Autocomplete } from '@material-ui/lab';
import {
    Checkbox, Grid, TextField, Typography,
} from '@material-ui/core';
import {
    FilterSettings, buildSettingsTreePage, checkEmptyObject, fetchPatientAuth, findColumnCF, findComponent, setActiveComponent,
} from '../../helper/commonHelper';
import { OrgContext, TimelineContext, WorkshopContext } from '../../context';
import { Settings, makeInlineEditor } from '../Settings';
import { componentWithId } from '../Atoms';
import { makeStyles } from '@material-ui/core/styles';
import { useIsSuperAdmin } from '../../helper/hooks';
import { useRecoilState } from 'recoil';
import DefaultComponentLayout from '../DefaultComponentLayout';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import React, {
    Fragment,
    useContext, useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import Select from '@material-ui/core/Select';
// eslint-disable-next-line no-unused-vars
import { MultiSelect } from './multiselect';

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 'auto',
        width: '100%',
    },
    formControlMain: {
        minWidth: 'auto',
        width: '100%',
        margin: 0,
    },
    formControlMain1: {
        minWidth: 'auto',
        width: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    selectStyle: {
        padding: 5,
        marginLeft: 5,
        fontSize: 10,
    },
    selectCont: {
        '& > :first-child': {
            height: 23.75,
        },
    },
    selectedComp: {
        background: 'cornflowerblue',
    },
    highlightOptionCls: {
        background: '#E1E8FC !important',
    },
    hideOptionCls: {
        background: '#ececec',
        display: 'none',
    },
    radioButton: {
        '& svg': {
            width: 12,
            height: 12,
        },
    },
    radioButton1: {
        padding: '1px 2px',
        '& svg': {
            width: 12,
            height: 12,
        },
    },
    clearIndicatorStyle: {
        marginRight: '-4px',
        padding: 3,
        display: 'none',
        '& svg': {
            fontSize: '.8rem',
        },
    },
    popupIndicatorStyle: {
        marginRight: '0px',
        padding: '0px',
        '& svg': {
            fontSize: '1.25rem',
        },
    },
    checkboxViewLabel: {
        fontSize: 10,
        marginTop: 2.5,
        fontWeight: 400,
        marginLeft: -2,
    },
    checkboxViewRoot: {
        margin: 0,
        borderBottom: '0.5px solid #CBCBCB',
        minHeight: 36,
        '& > :first-child': {
            padding: '4px 6px 9px 2px',
        },
    },
    radioViewItemRoot: {
        margin: 0,
        borderBottom: '0.5px solid #CBCBCB',
        height: 23,
        width: '100%',
    },
    radioViewItemRoot1: {
        margin: '0px 3px 0px 0px',
        borderBottom: '.25px solid #CBCBCB',
        height: 24,
        width: '100%',
        padding: '4.75px 1px',
    },
    fontSize10: {
        fontSize: 10,
        fontWeight: '400',
        lineHeight: '11px',
        paddingTop: 2,
    },
    fontSize101: {
        fontSize: 10,
        marginTop: 10,
    },
    selectRoot: {
        '&:before': {
            borderColor: 'transparent',
        },
    },
    inputRoot: {
        '& input': {
            height: 22,
            minWidth: '55px !important',
            textOverflow: 'clip',
            fontWeight: '400',
        },
    },
    mainInputRoot: {
        '&:focus': {
            borderBottom: 'none !important',
        },
        '& div': {
            '&:focus': {
                borderBottom: 'none !important',
            },
            '&::before': {
                borderBottom: 'none !important',
            },
            '&::after': {
                borderBottom: 'none !important',
            },
        },
    },
    paddSection: {
        paddingTop: '0px',
        paddingRight: '15px !important',
    },
    borderBot: {
        '&:brfore': {
            content: 'none',
        },
    },
    autoCompletePaper: {
        fontSize: 10,
    },
    autoCompleteInput: {
        fontSize: 10,
        padding: '6px !important',
    },
    normalText: {
        color: 'inherit',
        textDecoration: 'none',
        cursor: 'text',
    },
    anchorTag: {
        color: '#066785',
        textDecoration: 'none',
        cursor: 'pointer',
        padding: '0px 4px 0px 0px',
        '&:hover': {
            textDecoration: 'underline',
            fontWeight: 700,
        },
    },
    scrollCls: {
        width: '100%',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        '&:hover': {
            '&::-webkit-scrollbar': {
                height: '2.5px',
                width: '2.5px',
                display: 'block',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#888',
            },
        },
    },
    overflowCls: {
        overflow: 'hidden',
        overflowY: 'auto',
        wordBreak: 'break-all',
        alignItems: 'start',
    },
    singleSelectTimeline: {
        '& textarea': {
            '&:nth-child(2)': {
                display: 'none !important',
            },
            height: '23.75px !important',
            padding: '5px 0px 2.4px 6px !important',
            boxSizing: 'border-box',
            lineHeight: '12px',
            overflowY: 'auto !important',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
            '&:hover': {
                '&::-webkit-scrollbar': {
                    height: '2.5px',
                    width: '2.5px',
                    display: 'block',
                },
                '&::-webkit-scrollbar-thumb': {
                    background: '#888',
                },
            },
        },
    },
    settingHeading: {
        margin: 0,
        padding: 2,
        background: '#efefef',
        border: '1px solid #ececec',
        borderBottom: 'none',
        fontSize: 13,
    },
}));

const RenderRadioViewItem = ({
    item, indexx, eleDivHeight, componentProps, componentParent2,
    componentIndex, componentColumn, isDisabled = false, selectedRadioValue,
    optionRadioCell, isRender = false,
}) => {
    const classes = useStyles();
    const [colComp] = useRecoilState(componentWithId(componentProps.colRef));
    const [associatedCell] = useRecoilState(componentWithId(componentProps.associatedParent));
    const {
        hyperlinkCip,
        setHyperlinkCip,
        setHyperlinkCipText,
        setHyperlinkComponent,
        updateTimeline, selectedOptionId,
        handleContextMenu, inWorkshop, workshopComponent,
        setUpdateFlag, updateFlag,
        setActiveRow,
    } = useContext(TimelineContext);
    let divHeight = eleDivHeight(indexx) || null;
    let label = '';
    let hyperlink = {};
    if (item.props) {
        label = item.label;
        hyperlink = item.props.hyperlink;
    }

    if (item.label) {
        label = item.label;
    }
    const [radioLabel, setRadioLabel] = useState(label);
    const radioLabelRef = useRef(null);
    const [radioLabelContextMenu, setRadioLabelContextMenu] = useState(null);
    const isSuperAdmin = useIsSuperAdmin();
    const [protocol] = useRecoilState(componentWithId(componentProps?.props?.protocolId));

    let associatedCellHeight = null;
    let emptyAssociatedCell = true;
    const isUniversal = protocol?.props?.isUniversal || workshopComponent?.props?.isUniversal;
    const isManual = protocol?.props?.group === 'Complex Form Groups' ? protocol?.props?.pcRelationship === 'manual'
        : workshopComponent?.props?.pcRelationship === 'manual';
    let cellId = !isManual ? item?.props?.associatedCell : item?.props?.associatedCellTimeline;

    if (!isUniversal) cellId = item?.props?.associatedCell;
    if (item?.props?.associatedCell) {
        const id = `false-${cellId}`;
        const ele = document.getElementById(id);
        if (ele) {
            associatedCellHeight = ele.clientHeight;
        }
        emptyAssociatedCell = false;
    }

    useLayoutEffect(() => {
        divHeight = eleDivHeight(indexx);
    }, [selectedOptionId]);

    useEffect(() => {
        if (!hyperlinkCip) {
            let nodeTextConcat = '';
            const requiredChildNodes = radioLabelRef?.current?.childNodes;
            if (requiredChildNodes) {
                requiredChildNodes.forEach((childNode) => {
                    nodeTextConcat += childNode.textContent;
                });
                if (nodeTextConcat !== label) {
                    setRadioLabel(label);
                }
            }
        }
    }, [label]);

    const openRadioLabelContextMenu = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setRadioLabelContextMenu(radioLabelContextMenu === null ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 } : null);
    };

    const closeRadioLabelContextMenu = () => {
        setRadioLabelContextMenu(null);
    };

    const addEditHyperLink = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setHyperlinkCip(true);
        setHyperlinkCipText(radioLabelRef?.current?.textContent);
        setHyperlinkComponent(item);
        handleContextMenu(
            event,
            componentParent2?.components[componentIndex],
            { ...componentParent2, components: [{ ...componentParent2?.components[componentIndex] }] },
            componentIndex,
            componentColumn
        );
    };

    const removeHyperLink = () => {
        delete item?.props?.hyperlink;
        setRadioLabelContextMenu(null);
        setRadioLabel(label);
        updateTimeline();
    };

    useEffect(() => {
        if (workshopComponent?.props?.['wow feature'] === 'yes' && !inWorkshop) {
            const componentList1 = [];
            buildSettingsTreePage(componentList1, componentParent2?.components[componentIndex]);
            // WOW feature checks
            const currentFirstCol = componentList1.find((val) => val.props.index === 0 && val.props.isComplexForm && val.type === 'Column');
            const firstColAssEl = currentFirstCol?.props?.components.find((c) => c.id === componentProps.associatedParent && c.type === 'Checkbox');
            if (firstColAssEl) {
                if (componentProps.props.options.length) {
                    if (firstColAssEl.props.isChecked && componentProps.props.options.some((el) => el.isSelect)) {
                        firstColAssEl.props.isCompleted = 'completed';
                        componentProps.props.isCompleted = 'completed';
                        associatedCell.props.isCompleted = 'completed';
                    } else if (!firstColAssEl.props.isChecked && componentProps.props.options.every((el) => !el.isSelect)) {
                        firstColAssEl.props.isCompleted = undefined;
                        componentProps.props.isCompleted = undefined;
                        associatedCell.props.isCompleted = undefined;
                    } else if (!firstColAssEl.props.isChecked || componentProps.props.options.every((el) => !el.isSelect)) {
                        firstColAssEl.props.isCompleted = 'inComplete';
                        componentProps.props.isCompleted = 'inComplete';
                        associatedCell.props.isCompleted = 'inComplete';
                    }
                }
                if (currentFirstCol?.props?.components.some((el) => el.props.isCompleted === 'inComplete')) {
                    const protocolEl = componentList1.find((el) => el.type === 'Protocol');
                    protocolEl.props.isCompleted = 'inComplete';
                } else if (currentFirstCol?.props?.components.some((el) => el.props.isCompleted === 'completed')) {
                    const protocolEl = componentList1.find((el) => el.type === 'Protocol');
                    protocolEl.props.isCompleted = 'completed';
                } else if (currentFirstCol?.props?.components.every((el) => el.props.isCompleted === undefined)) {
                    const protocolEl = componentList1.find((el) => el.type === 'Protocol');
                    protocolEl.props.isCompleted = undefined;
                }
                setUpdateFlag(!updateFlag);
                updateTimeline();
            }
        }
    }, [workshopComponent?.props?.['wow feature']]);

    const handleRadioClick = (event, radioId) => {
        event.preventDefault();
        event.stopPropagation();
        const componentList1 = [];
        buildSettingsTreePage(componentList1, componentParent2?.components[componentIndex]);
        const currentProtocol = componentParent2?.components[componentIndex];
        const radioList = componentList1.filter((i) => i?.props?.renderAs === 'radio');
        const assParent = componentList1.filter((i) => componentProps?.associatedParent === i?.id)?.[0];
        const radioCellCont = optionRadioCell?.parent.find((i) => i?.id === componentProps?.parent);
        // WOW feature checks
        const activeRow = componentList1.find((val) => val.type === 'Row');
        setActiveRow(activeRow);
        const currentFirstCol = componentList1.find((val) => val.props.index === 0 && val.props.isComplexForm && val.type === 'Column');
        const firstColAssEl = currentFirstCol?.props?.components.find((c) => c.id === componentProps.associatedParent && c.type === 'Checkbox');
        const firstColTextAssEl = currentFirstCol?.props?.components.find((c) => c.id === componentProps.associatedParent && c.type === 'TextAreaCF');
        if (firstColAssEl || firstColTextAssEl) {
            if (componentProps.props.options.length
                && currentProtocol?.component !== 'Select-Complete-Notes' && currentProtocol?.component !== 'Select/Text-Complete-Notes') {
                const correspondingOption = componentProps.props.options.find((option) => option.id === event.target.id || option.id === radioId);
                correspondingOption.isSelect = !correspondingOption.isSelect;
                if (firstColAssEl.props.isChecked && componentProps.props.options.some((el) => el.isSelect)) {
                    firstColAssEl.props.isCompleted = 'completed';
                    componentProps.props.isCompleted = 'completed';
                    associatedCell.props.isCompleted = 'completed';
                } else if (!firstColAssEl.props.isChecked && componentProps.props.options.every((el) => !el.isSelect)) {
                    firstColAssEl.props.isCompleted = undefined;
                    componentProps.props.isCompleted = undefined;
                    associatedCell.props.isCompleted = undefined;
                } else if (!firstColAssEl.props.isChecked || componentProps.props.options.every((el) => !el.isSelect)) {
                    firstColAssEl.props.isCompleted = 'inComplete';
                    componentProps.props.isCompleted = 'inComplete';
                    associatedCell.props.isCompleted = 'inComplete';
                }
            }
            if (componentProps.props.options.length > 0
                && (currentProtocol?.component === 'Select-Complete-Notes' || currentProtocol?.component === 'Select/Text-Complete-Notes')) {
                const currentThirdCol = componentList1.find((val) => val.props.index === 2 && val.props.isComplexForm && val.type === 'Column');
                const thirdColAssEl = currentThirdCol?.props?.components?.find((val) => val.props.associatedComponent === componentProps?.id);
                if (thirdColAssEl) {
                    const correspondingOption = componentProps.props.options.find((option) => option.id === event.target.id || option.id === radioId);
                    correspondingOption.isSelect = !correspondingOption.isSelect;
                    if ((firstColAssEl?.props?.isChecked || firstColTextAssEl?.props?.isChecked)
                    && componentProps.props.options.some((el) => el.isSelect)) {
                        if (firstColAssEl) {
                            firstColAssEl.props.isCompleted = 'completed';
                        } else {
                            firstColTextAssEl.props.isCompleted = 'completed';
                        }
                        componentProps.props.isCompleted = 'completed';
                        associatedCell.props.isCompleted = 'completed';
                        if (thirdColAssEl) {
                            thirdColAssEl.props.isCompleted = 'completed';
                        }
                    } else if ((!firstColAssEl?.props?.isChecked && !firstColTextAssEl?.props?.isChecked)
                    && componentProps.props.options.every((el) => !el.isSelect)) {
                        if (firstColAssEl) {
                            firstColAssEl.props.isCompleted = undefined;
                            componentProps.props.isCompleted = undefined;
                            associatedCell.props.isCompleted = undefined;
                            if (thirdColAssEl) {
                                thirdColAssEl.props.isCompleted = undefined;
                            }
                        } else {
                            firstColTextAssEl.props.isCompleted = undefined;
                            componentProps.props.isCompleted = undefined;
                            associatedCell.props.isCompleted = undefined;
                            if (thirdColAssEl) {
                                thirdColAssEl.props.isCompleted = undefined;
                            }
                        }
                    } else if ((!firstColAssEl?.props?.isChecked && !firstColTextAssEl?.props?.isChecked)
                    || componentProps.props.options.every((el) => !el.isSelect)) {
                        if (firstColAssEl) {
                            firstColAssEl.props.isCompleted = 'inComplete';
                        } else {
                            firstColTextAssEl.props.isCompleted = 'inComplete';
                        }
                        componentProps.props.isCompleted = 'inComplete';
                        associatedCell.props.isCompleted = 'inComplete';
                        if (thirdColAssEl) {
                            thirdColAssEl.props.isCompleted = 'inComplete';
                        }
                    }
                }
            }

            if (currentFirstCol?.props?.components.some((el) => el.props.isCompleted === 'inComplete')) {
                const protocolEl = componentList1.find((el) => el.type === 'Protocol');
                protocolEl.props.isCompleted = 'inComplete';
            } else if (currentFirstCol?.props?.components.some((el) => el.props.isCompleted === 'completed')) {
                const protocolEl = componentList1.find((el) => el.type === 'Protocol');
                protocolEl.props.isCompleted = 'completed';
            } else if (currentFirstCol?.props?.components.every((el) => el.props.isCompleted === undefined)) {
                const protocolEl = componentList1.find((el) => el.type === 'Protocol');
                protocolEl.props.isCompleted = undefined;
            }
            setUpdateFlag(!updateFlag);
            updateTimeline();
        } else if (selectedRadioValue === event.target.value) {
            item.isSelect = false;
            if (colComp?.props?.index < 3 && componentProps?.props?.LRProps?.LRtype !== 'right') {
                radioList.forEach((i) => {
                    i.props.options.map((i) => {
                        i.isSelect = false;
                        return i;
                    });
                });
                if (assParent && radioCellCont) {
                    assParent.props.options.map((i, index) => {
                        if (index === radioCellCont?.index) {
                            i.isSelect = true;
                            return i;
                        }
                    });
                }
            } else {
                const assLRParent = radioList.find((i) => (i?.mirrorId || i?.id) === componentProps?.associatedParent);
                radioList.forEach((i) => {
                    if (i?.mirrorId) {
                        i.props.options.map((i) => {
                            i.isSelect = false;
                            return i;
                        });
                    }
                });
                if (componentProps?.associatedParent === assLRParent?.mirrorId) {
                    assLRParent.props.options.map((i, index) => {
                        if (index === radioCellCont?.index) {
                            i.isSelect = true;
                            return i;
                        }
                    });
                }
            }
        }
        setUpdateFlag(!updateFlag);
        if (!isSuperAdmin) {
            updateTimeline();
        }
        return item;
    };
    const flagCheckboxRender = workshopComponent && !isRender ? inWorkshop : false;
    return (
        <>
            <FormControlLabel
                key={indexx}
                value={item.value}
                control={flagCheckboxRender ? <Checkbox classes={{ root: classes.radioButton1 }} />
                    : <Radio classes={{ root: classes.radioButton1 }} id={item.id} checked={item.isSelect} />}
                label={
                    item?.props?.hyperlink ? (
                        <a href={hyperlink} className={classes.anchorTag} target="_blank" rel="noreferrer">{radioLabel}</a>
                    ) : radioLabel
                }
                onClick={(e) => handleRadioClick(e, item.id)}
                ref={radioLabelRef}
                onContextMenu={!hyperlinkCip
                    && !componentParent2?.isProgression && item?.label ? openRadioLabelContextMenu : undefined}
                style={{
                    height: associatedCellHeight || (emptyAssociatedCell
                        && !componentProps.props.associatedCell ? '24' : (componentProps.props.associatedCell
                            && divHeight > 10 && divHeight)),
                    display: 'flex',
                    alignItems: 'center',
                }}
                classes={{ root: classes.radioViewItemRoot1, label: classes.fontSize10 }}
                className={[classes.overflowCls, classes.scrollCls].join(' , ')}
                disabled={isDisabled}
                id={item?.id}
            />
            <Menu
                open={radioLabelContextMenu !== null}
                onClose={closeRadioLabelContextMenu}
                anchorReference="anchorPosition"
                anchorPosition={
                    radioLabelContextMenu !== null
                        ? { top: radioLabelContextMenu.mouseY, left: radioLabelContextMenu.mouseX }
                        : undefined
                }
            >
                {item?.props?.hyperlink ? (
                    <>
                        <MenuItem onClick={addEditHyperLink}>Edit Hyperlink</MenuItem>
                        <MenuItem onClick={removeHyperLink}>Remove Hyperlink</MenuItem>
                    </>
                ) : (
                    <MenuItem onClick={addEditHyperLink}>Add Hyperlink</MenuItem>
                )}
            </Menu>
        </>
    );
};

const RenderCheckboxViewItem = ({
    item, indexx, eleDivHeight, componentProps, componentParent2, componentIndex, componentColumn, handleChange,
}) => {
    const classes = useStyles();

    let label = '';
    let hyperlink = {};
    if (item.props) {
        label = item.label;
        hyperlink = item.props.hyperlink;
    }

    if (item.label) {
        label = item.label;
    }

    const checkboxLabelRef = useRef(null);
    const [checkboxLabel, setCheckboxLable] = useState(label);
    const [checkboxLabelContextMenu, setCheckboxLabelContextMenu] = useState(null);
    const {
        hyperlinkCip,
        setHyperlinkCip,
        setHyperlinkCipText,
        setHyperlinkComponent,
        updateTimeline,
        handleContextMenu,
    } = useContext(TimelineContext);
    const divHeight = eleDivHeight(indexx) || null;
    let associatedCellHeight = null;
    if (item?.props?.associatedCell) {
        const id = `false-${item?.props?.associatedCell}`;
        const ele = document.getElementById(id);
        if (ele) {
            associatedCellHeight = ele.clientHeight;
        }
    }

    useEffect(() => {
        if (!hyperlinkCip) {
            let nodeTextConcat = '';
            const requiredChildNodes = checkboxLabelRef?.current?.childNodes;
            if (requiredChildNodes) {
                requiredChildNodes.forEach((childNode) => {
                    nodeTextConcat += childNode.textContent;
                });
                if (nodeTextConcat !== label) {
                    setCheckboxLable(label);
                }
            }
        }
    }, [label]);

    const openCheckboxLabelContextMenu = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setCheckboxLabelContextMenu(checkboxLabelContextMenu === null ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 } : null);
    };

    const closeCheckboxLabelContextMenu = () => {
        setCheckboxLabelContextMenu(null);
    };

    const addEditHyperLink = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setHyperlinkCip(true);
        setHyperlinkCipText(checkboxLabelRef?.current?.textContent);
        setHyperlinkComponent(item);
        handleContextMenu(
            event,
            componentParent2?.components[componentIndex],
            { ...componentParent2, components: [{ ...componentParent2?.components[componentIndex] }] },
            componentIndex,
            componentColumn
        );
    };

    const removeHyperLink = () => {
        delete item?.props?.hyperlink;
        setCheckboxLabelContextMenu(null);
        setCheckboxLable(label);
        updateTimeline();
    };

    return (
        <>
            <FormControlLabel
                key={indexx}
                value={item.value}
                control={(
                    <Checkbox
                        value={item.value}
                        checked={item.isSelect}
                        onChange={(event) => handleChange(event, null, item)}
                        classes={{ root: classes.radioButton }}
                    />
                )}
                label={
                    item?.props?.hyperlink ? (
                        <a href={hyperlink} className={classes.anchorTag} target="_blank" rel="noreferrer">{checkboxLabel}</a>
                    ) : checkboxLabel
                }
                ref={checkboxLabelRef}
                onContextMenu={!hyperlinkCip
                && !componentParent2?.isProgression && item?.label ? openCheckboxLabelContextMenu : undefined}
                style={{
                    height: associatedCellHeight
                        || (componentProps.props.associatedCell && divHeight > 10 ? divHeight : 'inherit'),
                    alignItems: 'flex-start',
                }}
                classes={{ root: classes.checkboxViewRoot, label: classes.checkboxViewLabel }}
            />
            <Menu
                open={checkboxLabelContextMenu !== null}
                onClose={closeCheckboxLabelContextMenu}
                anchorReference="anchorPosition"
                anchorPosition={
                    checkboxLabelContextMenu !== null
                        ? { top: checkboxLabelContextMenu.mouseY, left: checkboxLabelContextMenu.mouseX }
                        : undefined
                }
            >
                {item?.props?.hyperlink ? (
                    <>
                        <MenuItem onClick={addEditHyperLink}>Edit Hyperlink</MenuItem>
                        <MenuItem onClick={removeHyperLink}>Remove Hyperlink</MenuItem>
                    </>
                ) : (
                    <MenuItem onClick={addEditHyperLink}>Add Hyperlink</MenuItem>
                )}
            </Menu>
        </>
    );
};

const SelectOne = ({
    componentProps, componentParent2, componentIndex, componentColumn,
}) => {
    const [currentCompRef, setCurrentCompRef] = useState();
    let isCorrectOption = false;
    // eslint-disable-next-line no-unused-vars
    for (const i of componentProps?.props?.options) {
        if (i?.value === currentCompRef?.props?.selectedOption?.value) {
            isCorrectOption = true;
            break;
        }
    }
    if (!isCorrectOption && componentProps.colRef) {
        delete currentCompRef?.props?.selectedOption;
    }
    const isSuperAdmin = useIsSuperAdmin();
    const { id } = componentProps;
    const classes = useStyles();
    // eslint-disable-next-line prefer-const
    let [component, setComponentState] = useRecoilState(componentWithId(id));
    const [optionRadioCell] = useRecoilState(componentWithId(componentProps?.parent));

    if (!componentProps?.props.hasOwnProperty('associatedCell') && componentProps?.props?.options?.length !== component?.props?.options?.length) {
        component = JSON.parse(JSON.stringify({
            ...component,
            props: {
                ...component.props,
                options: [...componentProps?.props.options],
                currentAssociation: componentProps?.props.currentAssociation,
                isHighlight: componentProps?.props.isHighlight,
            },
        }));
    } else if (!componentProps?.props.hasOwnProperty('associatedCell') && componentProps?.props.options.length === component.props.options.length) {
        for (let i = 0, l = componentProps?.props.options.length; i < l; i += 1) {
            if (componentProps?.props.options[i].value !== component.props.options[i].value) {
                component = JSON.parse(JSON.stringify({
                    ...component,
                    props: {
                        ...component.props,
                        options: [...componentProps?.props.options],
                        currentAssociation: componentProps?.props.currentAssociation,
                        isHighlight: componentProps?.props.isHighlight,
                    },
                }));
                break;
            }
        }
    }

    const [componentParent] = useRecoilState(componentWithId(componentProps.parent));
    const [, setAssocSelectParent] = useRecoilState(componentWithId(componentParent?.props?.associatedComponent));
    const [protocolComp] = useRecoilState(componentWithId(componentProps?.props?.protocolId));
    const [selected, setSelected] = useState(componentProps?.props?.selectedOption || '');
    const [renderAsComp, setRrenderAsComp] = useState(null);

    const { inWorkshop, compareModal } = useContext(WorkshopContext);
    const {
        settingComponent, setSettingComponentHandle, setDirtyDomain, domain, pageComponent, dirtyDomain, handleAssoctiaonChange, associationData,
        workshopComponent, workshopActiveComponent, setSelectCompCF, specificSelect, universalCompState, universalScndState,
        setSpecificSelect, updateFlag, setUpdateFlag, selectedTimeline, selectedColumn,
        setSelectedOptionIdsPreview, selectedOptionIdsPreview, currentSelectedValue, setCurrentSelectedValue, updateTimeline,
    } = useContext(TimelineContext);
    const { loggedUserType } = useContext(OrgContext);
    if (!component?.props && componentProps?.props) {
        component.props = componentProps?.props;
    }
    const { association } = component?.props;
    const componentList = [];

    let isPatient = false;
    if (loggedUserType === 'Patient') isPatient = true;
    const { isPatientWrite = false } = isPatient && componentColumn?.props?.group === 'Column'
    && fetchPatientAuth(loggedUserType, componentColumn);

    buildSettingsTreePage(componentList, pageComponent && pageComponent[domain]);

    if (componentProps[domain]) {
        component[domain] = componentProps[domain];
    }

    const customMargin = inWorkshop ? classes.formControl : classes.formControlMain1;

    let isSelected = false;

    if (settingComponent && settingComponent.id === id) {
        isSelected = true;
    }
    useEffect(() => {
        const pageDomain = component[domain];
        if (dirtyDomain) {
            return;
        }
        if (pageDomain && Object.keys(pageDomain).length > 0) {
            const currentComp = componentList.find((c) => c.id === id);
            if (currentComp) {
                currentComp[domain] = pageDomain;
            }
        }
    }, [domain, component, id, dirtyDomain]);

    useEffect(() => {
        if (associationData && associationData.length > 0) {
            const findItem = associationData && associationData.find((ad) => ad.id === componentProps.id);
            const disableVal = findItem && findItem.association?.disabledChildren?.find((v) => v === selected);
            if (disableVal) {
                setSelected('');
            }
        }
    }, [componentProps]);

    useEffect(() => {
        if (!componentProps?.id || !selectedTimeline) return;
        let currentCompRef;
        const componentPropsId = componentProps.id.includes('-preview') ? componentProps.id.substring(0,
            componentProps.id.indexOf('-preview')) : componentProps.id;
        for (let i = 0, l = selectedTimeline.columns.length; i < l; i += 1) {
            currentCompRef = findComponent(componentProps.isProgression ? selectedTimeline.columns[i].props.progressions
                : selectedTimeline.columns[i].props.components, componentPropsId);
            if (currentCompRef) {
                break;
            }
        }
        if (currentCompRef) {
            setCurrentCompRef(currentCompRef);
        }
    }, [selectedTimeline, componentProps]);
    useEffect(() => {
        if (workshopComponent && workshopActiveComponent) {
            const data = findColumnCF(workshopComponent, workshopActiveComponent, componentProps);
            if (data) {
                setSelectCompCF(data);
            }
        }
    }, [workshopActiveComponent]);

    useEffect(() => {
        setAssocSelectParent((value) => ({ ...value, layoutChange: component.layoutChange }));
    }, [component.layoutChange]);

    useEffect(() => {
        if ((!component?.props?.selectId?.includes('-preview') ? !Object.prototype.hasOwnProperty.call(specificSelect, component?.props?.selectId)
            && document.getElementById(`false-${componentProps.props.associatedCell}`)?.children[0]?.children[0]?.children[0]?.children[0]?.innerHTML
            : !Object.prototype.hasOwnProperty.call(currentSelectedValue, component.props.selectId))
            && currentCompRef?.props?.selectedOption && componentProps.props.associatedCell) {
            handleAutocompleteChange('event', component.props.selectId, currentCompRef?.props?.selectedOption);
        }
    });

    useEffect(() => {
        setRrenderAsComp(component?.props?.renderAs);
    }, [component?.props?.renderAs]);

    if (component?.props?.renderAs === 'Singleselect' && renderAsComp !== 'select'
        && (componentParent2?.components?.[0]?.supergroup === 'Form' || workshopComponent?.props?.group === 'Form Components')) {
        componentProps.props.value = [];
        if (component.props.options) {
            component.props.options.forEach((item) => { item.props = {}; return item; });
        }
    }

    if (component?.props?.renderAs === 'select' && renderAsComp !== 'Singleselect'
        && (componentParent2?.components?.[0]?.supergroup === 'Form' || workshopComponent?.props?.group === 'Form Components')) {
        componentProps.props.selectedOption = null;
        if (component.props.options) {
            component.props.options.forEach((item) => { item.props = {}; return item; });
        }
    }

    const handleChangeRadio = (event) => {
        const componentList1 = [];
        buildSettingsTreePage(componentList1, componentParent2?.components[componentIndex]);
        const radioList = componentList1.filter((i) => i?.props?.renderAs === 'radio');
        const assParent = componentList1.filter((i) => i?.props?.renderAs === 'radio' && componentProps?.associatedParent === i?.id)?.[0];
        const assNextParent = componentList1.filter((i) => i?.props?.renderAs === 'radio' && assParent?.associatedParent === i?.id)?.[0];
        const radioCellCont = optionRadioCell?.parent.find((i) => i?.id === componentProps?.parent);
        // WOW feature checks
        const currentFirstCol = componentList1.find((val) => val.props.index === 0 && val.props.isComplexForm && val.type === 'Column');
        const firstColAssEl = currentFirstCol?.props?.components.find((item) => item.id
        === componentProps.associatedParent && item.type === 'Checkbox');
        if (firstColAssEl) {
            if (componentProps.props.options.length === 1) {
                componentProps.props.options[0].isSelect = !componentProps.props.options[0].isSelect;
                if (firstColAssEl.props.isChecked && componentProps.props.options[0].isSelect) {
                    firstColAssEl.props.isCompleted = 'completed';
                    componentProps.props.isCompleted = 'completed';
                } else if (!firstColAssEl.props.isChecked && !componentProps.props.options[0].isSelect) {
                    firstColAssEl.props.isCompleted = undefined;
                    componentProps.props.isCompleted = undefined;
                } else {
                    firstColAssEl.props.isCompleted = 'inComplete';
                    componentProps.props.isCompleted = 'inComplete';
                }
            }
            if (currentFirstCol?.props?.components.some((el) => el.props.isCompleted === 'inComplete')) {
                const protocolEl = componentList1.find((el) => el.type === 'Protocol');
                protocolEl.props.isCompleted = 'inComplete';
            } else if (currentFirstCol?.props?.components.some((el) => el.props.isCompleted === 'completed')) {
                const protocolEl = componentList1.find((el) => el.type === 'Protocol');
                protocolEl.props.isCompleted = 'completed';
            } else if (currentFirstCol?.props?.components.every((el) => el.props.isCompleted === undefined)) {
                const protocolEl = componentList1.find((el) => el.type === 'Protocol');
                protocolEl.props.isCompleted = undefined;
            }
        } else if (componentProps?.props?.LRProps?.LRtype !== 'right') {
            radioList.map((i) => {
                if (!i?.mirrorId) {
                    i.props.options.map((i) => {
                        i.isSelect = false;
                        return i;
                    });
                }
            });
            componentProps.props.options.map((i) => {
                if (i.value === event.target.value) {
                    i.isSelect = true;
                    if (assParent) {
                        assParent.props.options.map((i, index) => {
                            if ((i?.keyIndex === optionRadioCell?.props?.associatedIndex) || (index === radioCellCont?.index)) {
                                i.isSelect = true;
                            }
                            return i;
                        });
                        if (assNextParent) {
                            assNextParent.props.options.map((i) => {
                                if (i.props.associatedCell === assParent.parent) {
                                    i.isSelect = true;
                                    return i;
                                }
                            });
                        }
                    }
                    return i;
                }
            });
        } else {
            const assLRParent = radioList.find((i) => i?.mirrorId === componentProps?.associatedParent);
            radioList.map((i) => {
                if (i.mirrorId) {
                    i.props.options.map((i) => {
                        i.isSelect = false;
                        return i;
                    });
                }
            });
            componentProps.props.options.map((i) => {
                if (i.value === event.target.value) {
                    i.isSelect = true;
                    if (componentProps?.associatedParent === assLRParent?.mirrorId) {
                        assLRParent.props.options.map((i, index) => {
                            if (index === radioCellCont?.index) {
                                i.isSelect = true;
                            }
                        });
                    }
                }
                return i;
            });
        }
        if (!isSuperAdmin) {
            updateTimeline();
        }
    };

    const handleChange = (event, selectId, selectedItem) => {
        const selectedOption = componentProps.props.options.find((item) => item.value === (event.target.value || selectedItem.value));
        componentProps.props.options.map((item) => {
            if (item.value === selectedItem?.value && componentProps?.props?.label !== 'Radio Group') {
                item.isSelect = !item.isSelect;
                return item;
            }
        });

        if (selectedOption?.id) {
            const newIds = {};
            let selectCell = null;
            for (let i = 0, l = selectedTimeline.columns.length; i < l; i += 1) {
                selectCell = findComponent(selectedTimeline.columns[i].props.components, componentProps.parent);
                if (selectCell) {
                    selectCell = findComponent(selectedTimeline.columns[i].props.components, selectCell.parent);
                    break;
                }
            }
            if (selectCell && selectCell.type === 'Column') {
                selectCell.props.components.forEach((ele) => {
                    const { id } = ele?.props?.components?.[0];
                    ele.props.components[0].props.options.forEach((option, index) => {
                        if (Object.prototype.hasOwnProperty.call(selectedOptionIdsPreview, `${id}-preview-${index}`)) {
                            newIds[`${id}-preview-${index}`] = false;
                        }
                    });
                });
            }
            componentProps.props.options.forEach((option) => {
                if (option.id) {
                    newIds[option.id] = option.id === selectedOption.id;
                }
            });
            setSelectedOptionIdsPreview((state) => ({ ...state, ...newIds }));
        }

        setSelected(event.target.value);

        if (!componentParent.parent) {
            return;
        }

        let selectedOptionIndex = null;
        const optionsArray = [...componentParent.props.components[0].props.options];
        for (let i = 0, l = optionsArray.length; i < l; i += 1) {
            if (event.target.value === optionsArray[i].value) {
                selectedOptionIndex = i;
                break;
            }
        }
        let selectCell = null;
        for (let i = 0, l = selectedTimeline.columns.length; i < l; i += 1) {
            selectCell = findComponent(selectedTimeline.columns[i].props.components, componentParent.props.components[0].props.associatedCell);
            if (selectCell) {
                break;
            }
        }
        if (selectCell) {
            const requiredComponent = selectCell?.props?.components?.[selectedOptionIndex]?.props?.components;
            if (requiredComponent?.length > 0) {
                setSpecificSelect({ ...specificSelect, [selectId]: requiredComponent[0].id });
            } else {
                setSpecificSelect({ ...specificSelect, [selectId]: '' });
            }
        }

        const cpIndex = componentParent.parent.findIndex((cp) => cp.id === componentProps.parent);
        const childComp = componentParent.parent[cpIndex + 1];
        if (!childComp) {
            return;
        }
        const selectComp = childComp.props.components.find((obj) => obj.type === 'Select' || obj.type === 'MultiSelect');
        const obj = {
            id: selectComp?.id,
        };
        if (association && association[event.target.value]) {
            obj.options = selectComp.props.options;
            obj.association = association[event.target.value];
            handleAssoctiaonChange(obj);
        } else {
            handleAssoctiaonChange(obj);
        }
        if (!event.target.checked) {
            currentCompRef.props.selectedOption = null;
        } else {
            currentCompRef.props.selectedOption = selectedOption.value;
        }
        if (!isSuperAdmin) {
            updateTimeline();
        }
    };

    useEffect(() => {
        if (component.props.options) {
            setUpdateFlag(!updateFlag);
        }
    }, [component.props.options]);

    const renderOption = (item) => (
        (componentProps?.props?.renderAs === 'radio' && !componentProps?.isProgression)
            ? (<FormControlLabel style={{ pointerEvents: 'none' }} control={<Checkbox />} label={item.label} />)
            : (<FormControlLabel control={<Radio />} label={item.label} />)
    );

    const renderEditor = () => makeInlineEditor({
        field: {
            key: 'options',
            type: 'List',
            label: 'Options',
            itemRender: renderOption,
        },
        component,
        setComponentState,
        classes,
        domain,
        setDirtyDomain,
        componentProps,
    });

    const handleAutocompleteChange = (event, selectId, newValue) => {
        if (isPatient && !isPatientWrite) return;

        const selectedOption = componentProps.props.options.find(
            (item) => ((item.value === newValue?.value) && ((item.requiredId === newValue?.requiredId) || (item?.id === newValue?.id)))
        );
        if (selectedOption?.id) {
            const newIds = {};
            let selectCell = null;
            for (let i = 0, l = selectedTimeline.columns.length; i < l; i += 1) {
                selectCell = findComponent(selectedTimeline.columns[i].props.components, componentProps.parent);
                if (selectCell) {
                    selectCell = findComponent(selectedTimeline.columns[i].props.components, selectCell.parent);
                    break;
                }
            }
            if (selectCell && selectCell.type === 'Column') {
                selectCell.props.components.forEach((ele) => {
                    const { id } = ele.props.components[0];
                    ele.props.components[0].props.options.forEach((option, index) => {
                        if (Object.prototype.hasOwnProperty.call(selectedOptionIdsPreview, `${id}-preview-${index}`)) {
                            newIds[`${id}-preview-${index}`] = false;
                        }
                    });
                });
            }
            componentProps.props.options.forEach((option) => {
                if (option.id) {
                    newIds[option.id] = option.id === selectedOption.id;
                }
            });
            setSelectedOptionIdsPreview((state) => ({ ...state, ...newIds }));
            setCurrentSelectedValue({ ...currentSelectedValue, [selectId]: newValue?.value });
        }
        setSelected(newValue?.value);
        if (currentCompRef) {
            currentCompRef.props.selectedOption = newValue;
            const isUpdate = isPatient ? isPatientWrite : !isSuperAdmin;

            if (component.props.selectedOption && isUpdate) componentProps.props.selectedOption = component.props.selectedOption;
            if (isUpdate) {
                updateTimeline();
            }
        }
        if (!componentParent.parent) {
            return;
        }

        let selectedOptionIndex = null;

        if (!componentParent?.props?.isMixedForm
            && componentParent?.props?.LRProps?.LRtype !== 'left'
            && componentParent?.props?.LRProps?.LRtype !== 'right') {
            const optionsArray = [...componentParent?.props?.components?.[0]?.props?.options];
            for (let i = 0, l = optionsArray.length; i < l; i += 1) {
                if (newValue?.value === optionsArray[i].value) {
                    selectedOptionIndex = i;
                    break;
                }
            }
        }

        let selectCell = null;
        for (let i = 0, l = selectedTimeline.columns.length; i < l; i += 1) {
            selectCell = findComponent(selectedTimeline.columns[i].props.components, componentParent.props.components[0].props.associatedCell);
            if (selectCell) {
                break;
            }
        }
        if (selectCell) {
            const requiredComponent = selectCell?.props?.components?.[selectedOptionIndex]?.props?.components;
            if (requiredComponent?.length > 0) {
                setSpecificSelect({ ...specificSelect, [selectId]: requiredComponent[0].id });
                setCurrentSelectedValue({ ...currentSelectedValue, [selectId]: newValue?.value });
            } else {
                setSpecificSelect({ ...delete specificSelect[selectId] });
            }
        }

        const cpIndex = componentParent?.parent?.findIndex((cp) => cp.id === componentProps?.parent);
        const childComp = componentParent?.parent[cpIndex + 1];
        if (!childComp) {
            return;
        }
        const selectComp = childComp.props.components.find((obj) => obj.type === 'Select' || obj.type === 'MultiSelect');
        const obj = {
            id: selectComp?.id,
        };
        if (association && association[newValue?.value]) {
            obj.options = selectComp?.props.options;
            obj.association = association[newValue?.value];
            handleAssoctiaonChange(obj);
        } else {
            handleAssoctiaonChange(obj);
        }
    };

    const getAutocompleteOptions = (item) => {
        let optionClass = '';
        const hideOptionCls = '';
        let selectCell = null;
        if (item.mappedOption && item.mappedOption.includes('-preview')) {
            const requiredId = item.mappedOption.substring(0, item.mappedOption.indexOf('-preview'));
            for (let i = 0, l = selectedTimeline.columns.length; i < l; i += 1) {
                selectCell = findComponent(selectedTimeline.columns[i].props.components, requiredId);
                if (selectCell) {
                    break;
                }
            }
        } else {
            for (let i = 0, l = selectedTimeline.columns.length; i < l; i += 1) {
                selectCell = findComponent(selectedTimeline.columns[i].props.components, componentProps.associatedParent);
                if (selectCell) {
                    break;
                }
            }
        }
        let associationIndex = -1;
        const selectCellOptions = [];
        for (let i = 0, l = selectCell?.props.options?.length; i < l; i += 1) {
            selectCellOptions[i] = selectCell?.props.options[i].value;
        }
        const currentSelectedValueKey = Object.prototype.hasOwnProperty.call(currentSelectedValue,
            `${componentProps.associatedParent}-preview`) ? `${componentProps.associatedParent}-preview` : componentProps.associatedParent;// ;componentProps.associatedParent || `${componentProps.associatedParent}-preview`;
        if (!selectCell?.props?.association?.hasOwnProperty(currentSelectedValue[currentSelectedValueKey])
            || selectCell?.props.association[currentSelectedValue[currentSelectedValueKey]]?.highlightedChildren?.length === 0) {
            optionClass = item.isHighlight ? classes.highlightOptionCls : '';
        } else {
            // eslint-disable-next-line no-unused-vars
            for (const key in selectCell?.props.association) {
                if (selectCellOptions.includes(key)) {
                    // eslint-disable-next-line no-plusplus
                    ++associationIndex;
                    if (key === currentSelectedValue[componentProps.associatedParent]
                        || key === currentSelectedValue[`${componentProps.associatedParent}-preview`]) {
                        const hc = selectCell.props.association[key].highlightedChildren;
                        optionClass = (hc.includes(`${item.value}#${item.requiredId}`)
                            || hc.includes(`${item.value}#${item.requiredId}`.substring(0,
                                `${item.value}#${item.requiredId}`.lastIndexOf('-preview'))))
                            && (Object.prototype.hasOwnProperty.call(specificSelect,
                                componentProps.associatedParent)
                                || selectedOptionIdsPreview[`${selectCell.id}-preview-${associationIndex}`]
                                || selectedOptionIdsPreview[`${selectCell.props.options[associationIndex]?.id}`]) ? classes.highlightOptionCls : '';
                        if (optionClass) {
                            break;
                        }
                    }
                }
            }
        }
        // }
        return (
            <Grid key={item.keyIndex} container className={[optionClass, hideOptionCls].join(' ')} spacing={1}>
                <Grid item xs={12}>
                    {item.label}
                </Grid>
            </Grid>
        );
    };

    const renderAutocompleteGroupView = () => {
        const optionList = component?.props?.options || [];
        const groupOptionList = [];
        optionList.forEach((parent) => {
            if (parent?.props?.childList) {
                parent.props.childList.forEach((child) => {
                    groupOptionList.push({
                        label: child?.label,
                        value: child?.value,
                        category: parent?.label,
                    });
                });
            }
        });
        return (
            <div>
                <FormControl className={customMargin} classes={{ root: classes.selectCont }}>
                    <Autocomplete
                        id="demo-simple-select-outlined"
                        className={`${classes.selectRoot} ${classes.mainInputRoot}`}
                        classes={{
                            paper: classes.autoCompletePaper,
                            inputRoot: classes.paddSection,
                            input: classes.autoCompleteInput,
                            clearIndicator: classes.clearIndicatorStyle,
                            popupIndicator: classes.popupIndicatorStyle,
                        }}
                        options={[...groupOptionList]}
                        groupBy={(option) => option.category}
                        getOptionLabel={(option) => option.label}
                        renderOption={(option) => option.label}
                        renderInput={(params) => (
                            <TextField
                                multiline
                                maxRows={10}
                                minRows={1}
                                {...params}
                                label=""
                                classes={{ root: classes.inputRoot }}
                                className={classes.singleSelectTimeline}
                            />
                        )}
                        style={!inWorkshop ? { borderBottom: '0.5px solid #CBCBCB' } : ''}
                    />
                </FormControl>
            </div>
        );
    };

    const handleOptionHeight = (option) => {
        const parentEle = document.getElementById(`${option?.id}`);
        const cellId = workshopComponent?.props?.pcRelationship === 'standard'
            ? option?.props?.associatedCell : option?.props?.associatedCellTimeline;
        const childEle = document.getElementById(`false-${cellId}`);
        if (parentEle && childEle && parentEle?.clientHeight !== childEle?.clientHeight) {
            parentEle.style.height = `${childEle.clientHeight}px`;
        }
    };

    // update the heighti of option on-change pc second column option add/remove.
    useEffect(() => {
        const isUniversal = protocolComp?.props?.isUniversal || workshopComponent?.props?.isUniversal;
        if (isUniversal && universalCompState?.option?.id && universalCompState?.option?.props?.associatedCellTimeline) {
            setTimeout(() => { handleOptionHeight(universalCompState?.option); }, 500);
        }
    }, [universalScndState?.child?.length]);

    // this will work only first column update the height of option on-change pc relationshipt in workshop component.
    useEffect(() => {
        if (workshopComponent && workshopActiveComponent?.id === workshopComponent?.id
            && component?.props?.options?.length > 0
            && component?.props?.options[0]?.props?.associatedCell) {
            setTimeout(() => {
                component.props.options.forEach((option) => {
                    if (option?.props?.associatedCell && option?.id) {
                        handleOptionHeight(option);
                    }
                });
            }, 500);
        }
    }, [workshopComponent?.props?.pcRelationship]);

    const renderAutocompleteView = () => (
        <div>
            <FormControl className={customMargin} classes={{ root: classes.selectCont }} onClick={(e) => e.stopPropagation()}>
                <Autocomplete
                    onChange={(event, newValue) => {
                        handleAutocompleteChange(event, component.props.selectId, newValue);
                    }}
                    className={`${classes.selectRoot} ${classes.mainInputRoot}`}
                    value={currentCompRef?.props?.selectedOption || null}
                    classes={{
                        paper: classes.autoCompletePaper,
                        inputRoot: classes.paddSection,
                        input: classes.autoCompleteInput,
                        clearIndicator: classes.clearIndicatorStyle,
                        popupIndicator: classes.popupIndicatorStyle,
                    }}
                    options={
                        !inWorkshop ? componentProps.props.options
                                && componentProps.props.options.map((item, index) => (item.label
                                    ? { ...item, keyIndex: index }
                                    : null))
                            : component.props.options
                                && component.props.options.map((item, index) => (item.label
                                    ? { ...item, keyIndex: index }
                                    : null))
                    }
                    getOptionLabel={(option) => option?.label}
                    renderOption={(props) => getAutocompleteOptions(props, component.props.isHighlight)}
                    renderInput={(params) => (
                        <TextField
                            multiline
                            maxRows={10}
                            minRows={1}
                            {...params}
                            label=""
                            classes={{ root: classes.inputRoot }}
                            className={classes.singleSelectTimeline}
                        />
                    )}
                    style={!inWorkshop ? { borderBottom: '0.25px solid #CBCBCB' } : ''}
                />
            </FormControl>
        </div>
    );

    const renderMultipleSelectView = () => {
        const multiSelectCompProps = componentProps?.props && {
            compIndex: componentProps?.compIndex,
            id: componentProps?.id,
            parent: componentProps?.parent,
            props:
            {
                association: {},
                childOf: componentProps?.props?.childOf,
                currentAssociation: componentProps?.props?.currentAssociation,
                defaultOption: componentProps?.props?.defaultOption,
                disabledChildren: componentProps?.props?.disabledChildren,
                highlightedChildren: componentProps?.props?.highlightedChildren,
                isHighlight: componentProps?.props?.isHighlight,
                label: componentProps?.props?.label,
                options: componentProps?.props?.options,
                poiWithParent: componentProps?.props?.poiWithParent,
                protocolId: componentProps?.props?.protocolId,
                renderAs: componentProps?.props?.renderAs,
                selectId: componentProps?.props?.selectId,
                value: componentProps?.props?.value,
            },
            type:
                'MultiSelect',
        };
        return (
            <div>
                <MultiSelect multiSelectCompProps={multiSelectCompProps} />
            </div>
        );
    };

    const eleDivHeight = (index) => {
        let divHeight = null;
        if (componentProps.props.associatedCell) {
            if (!inWorkshop) {
                const isUniversal = protocolComp?.props?.isUniversal || workshopComponent?.props?.isUniversal;
                selectedTimeline.columns.forEach((column, i) => {
                    if (!column?.props?.isProfile) {
                        const isManual = protocolComp?.props?.group === 'Complex Form Groups' ? protocolComp?.props?.pcRelationship === 'manual'
                            : workshopComponent?.props?.pcRelationship === 'manual';
                        let cellId = !isManual ? componentProps?.props?.associatedCell : componentProps?.props?.associatedCellTimeline;

                        if (!isUniversal) cellId = componentProps?.props?.associatedCell;
                        const slectCell1 = findComponent(selectedTimeline.columns[i].props.components, cellId);
                        const id1 = `false-${slectCell1?.props?.components[index]?.id}`;
                        const ele1 = document.getElementById(id1);
                        if (ele1) {
                            divHeight = ele1.clientHeight;
                        }
                    }
                });
            } else {
                const slectCell = findComponent(selectedTimeline.columns[selectedColumn].props.components, componentProps.props.associatedCell);
                const id = `false-${slectCell?.props?.components[index]?.id}`;
                const ele = document.getElementById(id);
                if (ele) {
                    divHeight = ele.clientHeight;
                }
            }
        }
        return divHeight;
    };

    const renderRadioView = () => {
        let selectedRadioValue = null;
        const isDisabled = isPatient && !isPatientWrite;
        if (component.props.options) {
            component.props.options.forEach((item, i) => {
                item.keyIndex = i;
            });
        }
        if (component.props.options && componentProps?.props?.label === 'Radio Group') {
            componentProps.props.options.map((i) => {
                if (i?.isSelect && !workshopComponent) {
                    selectedRadioValue = i?.value;
                }
            });
        }

        return (
            <div>
                <FormControl className={customMargin}>
                    <RadioGroup
                        name="radio"
                        value={selectedRadioValue}
                        onClick={handleChangeRadio}
                    >
                        {component.props.options && component.props.options.map((item, index) => (item.label
                            ? (
                                <Fragment key={index}>
                                    <RenderRadioViewItem
                                        item={item}
                                        indexx={index}
                                        eleDivHeight={eleDivHeight}
                                        componentProps={componentProps}
                                        componentParent2={componentParent2}
                                        componentIndex={componentIndex}
                                        componentColumn={componentColumn}
                                        isDisabled={isDisabled}
                                        selectedRadioValue={selectedRadioValue}
                                        optionRadioCell={optionRadioCell}
                                        isRender
                                    />
                                </Fragment>
                            )
                            : null))}
                    </RadioGroup>
                </FormControl>
            </div>
        );
    };

    const renderCheckboxView = () => (
        <div>
            <FormControl className={customMargin}>
                {component.props.options && component.props.options.map((item, index) => ((item.label
                    ? (
                        <Fragment key={index}>
                            <RenderCheckboxViewItem
                                item={item}
                                indexx={index}
                                eleDivHeight={eleDivHeight}
                                componentProps={componentProps}
                                componentParent2={componentParent2}
                                componentIndex={componentIndex}
                                componentColumn={componentColumn}
                                currentCompRef={currentCompRef}
                                selected={selected}
                                component={component}
                                handleChange={handleChange}
                            />
                        </Fragment>
                    )
                    : null)
                ))}
            </FormControl>
        </div>
    );
    return (
        <DefaultComponentLayout component={component} id={id}>
            {(inWorkshop && !compareModal) ? (
                <div
                    aria-hidden="true"
                    id={isSelected ? 'selectedCompId' : ''}
                    onClick={(event) => { event.stopPropagation(); setActiveComponent(setSettingComponentHandle, componentProps); }}
                    className={isSelected ? classes.selectedComp : null}
                    style={isSelected ? { padding: '0px 1px' } : null}
                >
                    {renderEditor()}
                </div>
            ) : component.props.renderAs === 'radio'
                ? renderRadioView() : (component.props.renderAs === 'Checkbox' ? renderCheckboxView()
                    : (component.props.renderAs === 'select'
                        ? renderMultipleSelectView() : (component?.props?.options?.[0]?.props?.hasOwnProperty('childList')
                            ? renderAutocompleteGroupView() : renderAutocompleteView())))}
        </DefaultComponentLayout>
    );
};

const SelectPreview = ({ component }) => {
    const classes = useStyles();
    const {
        options, defaultOption, renderAs,
    } = component.props;
    const { selectedTimeline, selectedColumn } = useContext(TimelineContext);

    const eleDivHeight = (index) => {
        let divHeight = null;
        if (component.props.associatedCell) {
            const slectCell = findComponent(selectedTimeline?.columns?.[selectedColumn]?.props?.components, component?.props?.associatedCell);
            const id = `preview-${slectCell?.props?.components[index]?.id}`;
            const ele = document.getElementById(id);
            if (ele) {
                divHeight = ele.clientHeight;
            }
        }
        return divHeight;
    };

    const renderSelectView = () => (
        <Select value={defaultOption} classes={{ root: classes.selectStyle }}>
            {options && options.map((item, index) => (item.label
                ? <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                : null))}
        </Select>
    );

    const renderMultipleSelectView = () => (
        <Select value={defaultOption} multiple classes={{ root: classes.selectStyle }}>
            {options && options.map((item, index) => (item.label
                ? <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                : null))}
        </Select>
    );

    const renderRadioViewItem = (item, index) => {
        const divHeight = eleDivHeight(index) || null;

        return (
            <FormControlLabel
                key={index}
                value={item.value}
                control={<Radio classes={{ root: classes.radioButton }} />}
                label={item.label}
                style={{ height: component.props.associatedCell && divHeight > 10 ? divHeight : 'inherit', alignItems: 'flex-start' }}
                classes={{ root: classes.radioViewItemRoot, label: classes.fontSize101 }}
            />
        );
    };

    const renderRadioView = () => (
        <div>
            <FormControl className={classes.formControlMain1}>
                <RadioGroup>
                    {component.props.options && component.props.options.map((item, index) => (item.label
                        ? renderRadioViewItem(item, index)
                        : null))}
                </RadioGroup>
            </FormControl>
        </div>
    );

    const renderCheckboxViewItem = (item, index) => {
        const divHeight = eleDivHeight(index) || null;
        return (
            <FormControlLabel
                key={index}
                value={item.value}
                control={<Checkbox classes={{ root: classes.radioButton }} />}
                label={item.label}
                style={{ height: component.props.associatedCell && divHeight > 10 ? divHeight : 'inherit', alignItems: 'flex-start' }}
                classes={{ root: classes.checkboxViewRoot, label: classes.checkboxViewLabel }}
            />
        );
    };

    const renderCheckboxView = () => (
        <div>
            <FormControl className={classes.formControlMain1}>
                {component.props.options && component.props.options.map((item, index) => (renderCheckboxViewItem(item, index)))}
            </FormControl>
        </div>
    );

    return (
        <DefaultComponentLayout preview component={component}>
            <FormControl className={classes.formControlMain}>
                {renderAs === 'radio' ? renderRadioView() : (component.props.renderAs === 'Checkbox'
                    ? renderCheckboxView() : (component.props.renderAs === 'select' ? renderMultipleSelectView() : renderSelectView()))}
            </FormControl>
        </DefaultComponentLayout>
    );
};

export const SelectProps = {
    display: [
        {
            key: 'renderAs',
            type: 'Select',
            label: 'Component Options',
            default: 'Singleselect',
            options: [{ label: 'Single Select Checkbox', value: 'radio' },
                { label: 'Multi Select Checkbox', value: 'Checkbox' },
                { label: 'Single Select DropDown', value: 'Singleselect' },
            ],
            id: 'Select',
        },
    ],
    poi: false,
};

const SelectEditor = ({ config, updateComponent, heading }) => {
    const classes = useStyles();
    const [component, setComponentState] = useRecoilState(componentWithId(config.id));

    if (!component?.parent) {
        component.parent = [config];
    }

    if (!component?.props && !checkEmptyObject(config.props)) {
        component.props = config.props;
    }
    const {
        settingMenuType, workshopComponent, workshopSelectedTemplate, selectedComponent, workshopActiveComponent,
    } = useContext(TimelineContext);

    if (selectedComponent?.isProgression) {
        SelectProps.display = SelectProps.display.filter((ele) => ele?.key !== 'hyperlink');
    }

    const propsCopy = JSON.parse(JSON.stringify(SelectProps));

    const currentAssoc = config.props.currentAssociation;
    let currentAssociationIndex;
    for (let i = 0, l = config.props.options.length; i < l; i += 1) {
        if (config.props.options[i].value === currentAssoc) {
            currentAssociationIndex = i;
            break;
        }
    }

    let propsComp = workshopComponent && workshopComponent.props.components;
    let configParent = config.parent && config.parent;

    propsCopy.display.forEach((obj) => {
        if (obj.key === 'currentAssociation') {
            obj.options = config.props.options;
        }
    });
    if (!component?.props?.currentAssociation) {
        component.props.currentAssociation = component.props.options?.[0]?.value;
    }
    const currentAssociation = component?.props?.currentAssociation;
    if (!component?.props?.association && component.props?.options?.length > 0) {
        component.props.options.forEach((associationKey) => {
            component.props.association = {
                [associationKey]: {
                    highlightedChildren: [],
                    disabledChildren: [],
                },
            };
        });
    }

    if (!propsComp && workshopSelectedTemplate) {
        propsComp = workshopSelectedTemplate.template.props.components;
    }

    if (!configParent) {
        configParent = selectedComponent.parent;
    }

    component.props.highlightedChildren = component?.props?.association?.[currentAssociation]?.highlightedChildren || [];
    component.props.disabledChildren = component?.props?.association?.[currentAssociation]?.disabledChildren || [];

    const requiredComponentFromConfigParent = findComponent(propsComp, configParent);
    const requiredComponent = requiredComponentFromConfigParent?.props?.components.length > 0
        ? findComponent(propsComp, requiredComponentFromConfigParent.props.components[0].props.associatedCell) : null;
    let requiredOptions = [];
    let childComp = null;
    if (!requiredComponent) {
        const rc = findComponent(propsComp, configParent);
        if (rc && rc.props?.components.length > 0) {
            if (!workshopComponent?.props?.isHighlight) {
                const opt = rc?.props?.components?.[0]?.props?.options?.[currentAssociationIndex];
                if (opt?.props && opt?.props?.associatedCell) {
                    childComp = JSON.parse(JSON.stringify(findComponent(propsComp, opt?.props?.associatedCell)));
                    const options = childComp?.props?.components?.[0]?.props?.options;
                    if (options) {
                        options.forEach((o) => {
                            o.requiredId = childComp?.props?.components?.[0]?.id;
                        });
                        requiredOptions = [...requiredOptions, ...childComp?.props?.components?.[0]?.props?.options];
                    }
                }
            } else {
                rc.props.components[0].props.options.forEach((opt) => {
                    if (opt.props && opt.props?.associatedCell) {
                        childComp = JSON.parse(JSON.stringify(findComponent(propsComp, opt.props?.associatedCell)));
                        if (childComp.props?.components?.[0]) {
                            childComp.props.components[0].props.options.forEach((o) => {
                                o.requiredId = childComp.props.components[0].id;
                            });
                            requiredOptions = [...requiredOptions, ...childComp?.props?.components[0].props.options];
                        }
                    }
                });
            }
        }
    }
    if (requiredComponent) {
        if (!workshopComponent?.props?.isHighlight
            && requiredComponent.props.components[currentAssociationIndex]
            && requiredComponent.props.components[currentAssociationIndex].props.components[0]) {
            const requiredId = requiredComponent.props.components[currentAssociationIndex].props.components[0].id;
            // eslint-disable-next-line no-prototype-builtins
            if (requiredComponent.props.components[currentAssociationIndex].props.components[0].props.hasOwnProperty('options')) {
                requiredComponent.props.components[currentAssociationIndex].props.components[0].props.options.forEach((opt) => {
                    opt.requiredId = requiredId;
                });
                childComp = JSON.parse(JSON.stringify(requiredComponent.props.components[currentAssociationIndex]));
                requiredOptions = [...requiredComponent.props.components[currentAssociationIndex].props.components[0].props.options];
            }
        } else {
            requiredComponent.props.components.forEach((comp) => {
                if (Array.isArray(comp?.props?.components)) {
                    comp.props.components.forEach((c) => {
                        if (Array.isArray(c.props.options)) {
                            c.props.options.forEach((o) => {
                                o.requiredId = c.id;
                            });
                        }
                    });
                }
                childComp = JSON.parse(JSON.stringify(comp));
                requiredOptions = Array.isArray(comp?.props?.components) ? (comp.props.components.length > 0
                    ? [...requiredOptions, ...(Array.isArray(comp?.props?.components[0]?.props?.options)
                        ? [...comp.props.components[0].props.options] : [])] : [...requiredOptions]) : [...requiredOptions];
            });
        }
    }

    if (childComp) {
        if (childComp?.props?.components?.length > 0) {
            childComp.props.components[0].props.options = [...requiredOptions];
        }
        const selectComp = childComp.props.components.find((obj) => obj.type === 'Select' || obj.type === 'MultiSelect');
        if (component?.props.association && component?.props.association[currentAssociation]) {
            if (!workshopComponent?.props?.isHighlight) {
                component.props.association[currentAssociation].immediateChildren = [...requiredOptions];
            }
            component.props.association[currentAssociation].notHighlightedChildren = component?.
            props?.association?.[currentAssociation]?.notHighlightedChildren
                || selectComp?.props.options.map((item) => `${item.value}#${item.requiredId}`);
            component.props.association[currentAssociation].notHighlightedChildren = selectComp?.props.options.map(
                (item) => `${item.value}#${item.requiredId}`).filter((item) => !component?.props.highlightedChildren.includes(item));
        }
        if (selectComp) {
            propsCopy.display.forEach((obj) => {
                if (obj.key === 'highlightedChildren' || obj.key === 'disabledChildren') {
                    obj.options = selectComp.props.options;
                }
            });
        }
    } else {
        propsCopy.display = propsCopy.display.filter(
            (obj) => (obj.key !== 'association' && obj.key !== 'highlightedChildren'
                && obj.key !== 'disabledChildren' && obj.key !== 'notHighlightedChildren')
        );
    }

    if (workshopComponent?.supergroup === 'Complex Forms') {
        propsCopy.display = propsCopy.display.filter((ele) => ele?.key !== 'renderAs');
    }

    if (workshopComponent?.supergroup === 'Form') {
        propsCopy.display = propsCopy.display.filter((ele) => ele?.key !== 'currentAssociation');
    }

    let requiredColumn = findComponent(workshopComponent?.props.components, component?.parent?.[0]?.colRef);
    const selectedCompColumn = findComponent(workshopComponent?.props.components, selectedComponent?.colRef);
    const editorProps = { display: FilterSettings(propsCopy, settingMenuType, 'Select'), poi: SelectProps.poi };

    if (config?.id === workshopActiveComponent?.id) {
        const cloneColumnComponents = [];
        buildSettingsTreePage(cloneColumnComponents, requiredColumn);
        requiredColumn = cloneColumnComponents.filter((ele) => ele?.type === config.type && !ele?.props?.hideFromWorkshop);
        const compIndexValue = requiredColumn.findIndex((c) => c.id === component?.props?.selectId);

        for (let i = 0, l = editorProps?.display?.length; i < l; i += 1) {
            if (editorProps?.display?.[i]?.key === 'compRowNumber') {
                const mixedCompIndex = component.props.isMixedForm ? cloneColumnComponents.findIndex((c) => c.id === config.id) : compIndexValue + 1;
                editorProps.display[i].value = mixedCompIndex;
                break;
            }
        }
    }
    for (let i = 0, l = editorProps?.display?.length; i < l; i += 1) {
        if (editorProps?.display?.[i]?.key === 'columnNumber') {
            editorProps.display[i].value = (selectedCompColumn?.compIndex + 1) || 1;
            break;
        }
    }
    return (
        <>
            {heading && <Typography className={classes.settingHeading}>{heading}</Typography>}
            <Settings editorProps={editorProps} component={component} setComponentState={setComponentState} saveComponent={updateComponent} />
        </>
    );
};

export { SelectOne as Select, SelectEditor, SelectPreview };
