import {
    Cell, CellPreview, ColumnEditMenu, ColumnEditor, ColumnTemplatePreview,
    EmptyCell, EmptyCellPreview, OptionCell, OptionCellPreview, SectionEditor, SectionPreview,
    SelectCell, SelectCellPreview, WorkshopAddMenu, WorkshopImageMenu, WorkshopMenu,
} from './Column';
import { Checkbox, CheckboxEditor, CheckboxPreview } from './forms/checkbox';

import { Container } from './Container';
import { DatePicker, DatePickerEditor, DatePickerPreview } from './forms/datepicker';
import {
    HTML, HTMLEditMenu, HTMLEditor, HTMLPreview,
} from './HTML';
import ColumnSectionEditMenu from './ColumnSection';

import {
    Image, ImageEditMenu, ImageEditor, ImagePreview,
} from './Image';

import {
    Gallery, GalleryEditMenu, GalleryEditor, GalleryPreview,
} from './Gallery';

import {
    Label, LabelInputEditor, LabelInputPreview, TimelineLabel,
} from './forms/label';
import { Media, MediaEditor, MediaPreview } from './Media';
import { MultiSelect, MultiSelectEditor, MultiSelectPreview } from './forms/multiselect';
import {
    Page, PageEditor, PagePreview,
} from './Page';
import { Panel, PanelEditor } from './Panel';
import {
    Progression, ProgressionEditMenu, ProgressionEditor, ProgressionPreview,
} from './Progression';
import {
    Protocol, ProtocolEditMenu, ProtocolEditor, ProtocolPreview,
} from './Protocol';
import { ProtocolGroup, ProtocolGroupEditMenu, ProtocolGroupEditor } from './ProtocolGroup';
import {
    Row, RowEditMenu, RowEditor, RowPreview,
} from './Row';
import { Select, SelectEditor, SelectPreview } from './forms/select';
import {
    Text, TextEditMenu, TextEditor, TextPreview, TimelineDeleteTextMenu,
} from './Text';
import { TextInput, TextInputEditor, TextInputPreview } from './forms/textinput';
import { TimelineEditor } from './TimelineEditor';
import CarouselSwiper from './utils/Swiper';
import RowSymmetric from './RowSymmetric';

const ComponentViewLookup = {
    Text,
    Label,
    Checkbox,
    TextInput,
    Select,
    MultiSelect,
    Container,
    DatePicker,
    Row,
    RowSymmetric,
    Panel,
    Media,
    Page,
    HTML,
    Protocol,
    Progression,
    ProtocolGroup,
    Image,
    Gallery,
    Column: ColumnTemplatePreview,
    Section: SectionEditor,
    Carousel: CarouselSwiper,
    Cell,
    SelectCell,
    OptionCell,
    EmptyCell,
    Timeline: TimelineLabel,
};

const ComponentPreview = {
    Protocol: ProtocolPreview,
    Progression: ProgressionPreview,
    TextInput: TextInputPreview,
    Label: LabelInputPreview,
    Checkbox: CheckboxPreview,
    Select: SelectPreview,
    MultiSelect: MultiSelectPreview,
    DatePicker: DatePickerPreview,
    Media: MediaPreview,
    Page: PagePreview,
    HTML: HTMLPreview,
    Row: RowPreview,
    Text: TextPreview,
    Image: ImagePreview,
    Gallery: GalleryPreview,
    Column: ColumnTemplatePreview,
    Section: SectionPreview,
    Cell: CellPreview,
    EmptyCell: EmptyCellPreview,
    SelectCell: SelectCellPreview,
    OptionCell: OptionCellPreview,
};

const ComponentEditorLookup = {
    Text: TextEditor,
    Label: LabelInputEditor,
    Checkbox: CheckboxEditor,
    TextInput: TextInputEditor,
    TextAreaCF: CheckboxEditor,
    TextBoxCF: CheckboxEditor,
    Select: SelectEditor,
    MultiSelect: MultiSelectEditor,
    Panel: PanelEditor,
    Timeline: TimelineEditor,
    Media: MediaEditor,
    Page: PageEditor,
    HTML: HTMLEditor,
    Column: ColumnEditor,
    Protocol: ProtocolEditor,
    Progression: ProgressionEditor,
    ProtocolGroup: ProtocolGroupEditor,
    DatePicker: DatePickerEditor,
    Row: RowEditor,
    Image: ImageEditor,
    Gallery: GalleryEditor,
    Section: ColumnEditor,
};

const ComponentEditorMenu = {
    ProtocolGroup: ProtocolGroupEditMenu,
    Column: ColumnEditMenu,
    Text: TextEditMenu,
    Protocol: ProtocolEditMenu,
    Progression: ProgressionEditMenu,
    ColumnSection: ColumnSectionEditMenu,
    Row: RowEditMenu,
    HTML: HTMLEditMenu,
    Image: ImageEditMenu,
    Gallery: GalleryEditMenu,
    WorkshopMenu,
    WorkshopAddMenu,
    WorkshopImageMenu,
    TimelineDeleteTextMenu,
};

export {
    ComponentViewLookup, ComponentEditorLookup, ComponentEditorMenu, ComponentPreview,
};
