import {
    Button,
    Grid,
    LinearProgress,
    Table as MaterialTable,
    TableBody, TableCell, TableHead, TableRow,
} from '@material-ui/core';
import {
    GET_ALL_DOCTORS, GET_ORG_ID_BY_USER, GET_ROLES_BY_ORG_ID, GET_USER_BY_ID,
} from './context/User';
import {
    GET_TIMELINES, GET_TIMELINES_BY_ORG_ID, GET_TIMELINE_BY_ID, GET_TIMELINE_COLUMN_BY_USER,
} from './context/Timeline';
import { TimelineContext } from './context';
import { TimelineLinkUnStyled as TimelineLink } from './TemplateBrowser';
import { makeStyles, withStyles } from '@material-ui/styles';
import { useIsSuperAdmin, useOrg } from './helper/hooks';
import { useQuery } from '@apollo/react-hooks';
import IndustrySpecialityDialog from './components/dialog/IndustrySpecialityDialog';
import ManagePatient from './components/ManagePatientModel';
import ManageUser from './components/ManageUserModel';
import React, { useContext, useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import gql from 'graphql-tag';

const Table = withStyles({
    root: {
        '& td': {
            verticalAlign: 'top',
            padding: '5px 7px',
            minWidth: 150,
        },
    },
})(MaterialTable);

const HeaderTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        padding: '5px 7px',
        fontWeight: '700',
        borderRight: '0.5px solid #fff',
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
const headers = [
    'Protocol',
    'Col 1',
    'Col 2',
    'Col 3',
    'Col 4',
    'Col 5',
    'Col 6',

];
const headersAdmin = headers.slice(0, -1);

const subHeaders = [
    'Universal',
    'Industry',
    'Speciality',
    "Doctor's Office",
    'Roles',
    'Users',

];
const subHeadersAdmin = [...subHeaders.slice(0, -2), 'Timeline'];

const getClasses = (classes) => ({
    Speciality: classes.w150,
    "Doctor's Office": classes.w150,
    Users: classes.w150,
    Roles: classes.w150,
});

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
    roleContainer: {
        '& > div:last-child': {
            borderBottom: 'none',
        },
    },
    w150: {
        width: 170,
    },
    w50: {
        width: '50%',
    },
    row: {
        '& > td': {
            borderRight: '1px solid #e0e0e0',
        },
    },
    borderBottom: {
        borderBottom: '1px solid #e0e0e0',
    },
    addIndustryCont: {
        border: '1px solid #e0e0e0',
        '& > td': {
            borderRight: 'solid 1px #e0e0e0',
        },
    },
    noBorderBottom: {
        '&&': {
            borderBottom: 'none',
        },
    },
    noPadding: {
        '&&': {
            padding: 0,
        },
    },
    innerTableBody: {
        '& > tr:last-child >td': {
            borderBottom: 'none',
        },
    },
    alignTop: {
        verticalAlign: 'top',
    },
    fontBold: {
        fontWeight: 'bold',
    },
    subheaderBG: {
        background: 'lightgray',
    },
    primaryBtn: {
        padding: '2px 4px 2px 4px',
        fontSize: 10,
        boxShadow: '0px 0px 20px -4px rgba(51,36,5,0.37)',
        borderRadius: '2px',
        '&:hover': {
            boxShadow: 'none',
        },
    },
    tableCont: {
        maxHeight: 'calc(100vh - 164px)',
        overflow: 'auto',
    },
    buttonCont: {
        borderTop: '1px solid #CBCBCB',
        bottom: 0,
        position: 'fixed',
        textAlign: 'right',
        width: '75%',
        background: '#fff',
    },
    closeWorkshopButton: {
        margin: '.75rem 1.8rem .5rem .5rem',
        boxShadow: '0px 0px 20px -4px rgba(51,36,5,0.37)',
        '&:hover': {
            boxShadow: 'none',
        },
    },
    tableHeader: {
        position: 'sticky',
        top: 0,
    },
}));

const GET_INDUSTRIES = gql`
query MyQuery {
    industry_speciality {
      category
      id
      name
      speciality_parent
    }
  }  
`;
const PatientLink = ({ user }) => {
    const { loading: loadingColumnAccess, data } = useQuery(GET_TIMELINE_COLUMN_BY_USER, {
        variables: {
            user_id: user.userId,
        },
        skip: !user?.userId,
    });
    const timelineID = data?.['timeline_column_access']?.[0]?.['timeline_id'];
    const { loading, data: orgData } = useQuery(GET_TIMELINE_BY_ID, {
        variables: {
            timelineID,
        },
        skip: !timelineID,
    });
    const orgID = orgData?.['timeline_by_pk']?.['organization_id'];
    const timeline = { id: timelineID, organization_id: orgID };
    return (loadingColumnAccess || loading) ? (
        <LinearProgress
            variant="query"
        />
    ) : !orgID ? user?.name : <TimelineLink timeline={timeline} text={user.name} />;
};
const UserColumn = ({ userId, role }) => {
    const classes = useStyles();
    const { data: userData, loading } = useQuery(GET_USER_BY_ID, {
        variables: {
            id: userId,
        },
        skip: !userId,
    });
    const user = userData?.['user_by_pk'];
    const userWithId = { ...user, userId };
    return (
        <TableRow className={classes.row}>
            <TableCell className={classes.w150}>
                {loading ? <LinearProgress variant="query" /> : role === 'Patient' ? <PatientLink user={userWithId} /> : user?.name}
            </TableCell>
        </TableRow>
    );
};
const RoleColumn = ({
    role, users, onUserAdd, timelines,
}) => {
    const { orgID } = useOrg();
    const classes = useStyles();
    const [isPatientModalOpen, setPatientModalOpen] = useState(false);
    const [isUserModalOpen, setUserModalOpen] = useState(false);
    const handleAddPatient = () => {
        setPatientModalOpen(true);
    };
    const handleAddUser = () => {
        setUserModalOpen(true);
    };

    return (
        <TableRow className={classes.row}>
            <TableCell className={classes.w150}>
                {role === 'User' ? 'Staff' : role}
            </TableCell>
            <TableCell className={classNames(classes.noPadding, classes.roleContainer)}>
                <Table>
                    <TableBody>
                        {role === 'Timeline' ? _.map(timelines, (timeline) => (
                            <TimelineColumn
                                key={timeline.id}
                                timeline={timeline}
                            />
                        )) : _.map(users, (user) => <UserColumn role={role} userId={user.user_id} />)}
                        {role !== 'Timeline' && (
                            <TableRow className={classes.row}>
                                <TableCell className={classes.w150}>
                                    {role === 'Patient' && (
                                        <Button variant="contained" color="primary" className={classes.primaryBtn} onClick={handleAddPatient}>
                                            Add Patient
                                        </Button>
                                    )}
                                    {(role === 'User' || role === 'Admin') && (
                                        <Button variant="contained" color="primary" className={classes.primaryBtn} onClick={handleAddUser}>
                                            Add
                                            {' '}
                                            {role === 'User' ? 'Staff' : 'Admin'}
                                        </Button>
                                    )}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>

            </TableCell>
            {role !== 'Timeline' && (
                <>
                    <ManageUser
                        timelineUser={onUserAdd}
                        open={isUserModalOpen}
                        selectedUser={{}}
                        resetOnClose
                        handleClose={() => setUserModalOpen(false)}
                        orgID={orgID}
                        routeLabel="User"
                    />
                    <ManagePatient
                        timelineUser={onUserAdd}
                        open={isPatientModalOpen}
                        selectedPatient={{}}
                        handleClose={() => setPatientModalOpen(false)}
                        orgID={orgID}
                        routeLabel="Patient"
                    />
                </>
            )}
        </TableRow>

    );
};

const TimelineColumn = ({ timeline }) => {
    const classes = useStyles();
    return (
        <TableRow className={classes.row}>
            <TableCell className={classes.w150}>
                <TimelineLink timeline={timeline} />
            </TableCell>
        </TableRow>
    );
};
const DoctorColumn = ({ user, key }) => {
    const isSuperAdmin = useIsSuperAdmin();
    const classes = useStyles();
    const { data } = useQuery(GET_ORG_ID_BY_USER, {
        variables: {
            user_id: user.id,
        },
    });
    const orgID = data?.['organization_member']?.[0]?.['organization_id'];
    const { data: roleData, loading, refetch } = useQuery(GET_ROLES_BY_ORG_ID, {
        variables: {
            orgID,
        },
        skip: isSuperAdmin,
    });
    const { data: timelinesData, loading: loadingTimelines } = useQuery(GET_TIMELINES, {
        variables: {
            orgID,
        },
    });
    const { data: assocTimelines, loading: loadingAssocTimelines } = useQuery(GET_TIMELINES_BY_ORG_ID, {
        variables: {
            orgID,
        },
    });
    const timelines = timelinesData?.timeline || [];
    const members = roleData?.['organization_member'] || [];

    const sharedTimelineIds = _.map(assocTimelines?.['user_timeline_association'] || [], (item) => ({ ...item, id: item.timeline_id }));
    const sharedTimelines = _.intersectionBy(timelines, sharedTimelineIds, 'id');
    const dataByRoles = _.groupBy(members, 'role');
    if (!dataByRoles.Patient) {
        dataByRoles.Patient = {};
    }
    if (!dataByRoles.User) {
        dataByRoles.User = {};
    }
    const timelineObj = { Timeline: {} };
    const onUserAdd = () => {
        refetch();
    };
    return (
        <TableRow className={classes.row} key={key}>
            <TableCell className={classes.w150}>
                {user.name}
            </TableCell>
            {isSuperAdmin ? (
                <TableCell className={classNames(classes.noPadding)}>
                    {(loadingAssocTimelines || loadingTimelines) ? <LinearProgress variant="query" /> : _.map(sharedTimelines, (timeline) => (
                        <Table>
                            <TableBody>
                                <TimelineColumn timeline={timeline} />
                            </TableBody>
                        </Table>
                    ))}
                </TableCell>
            )
                : (
                    <TableCell colSpan={2} className={classNames(classes.noPadding, classes.roleContainer)}>
                        {loading ? (
                            <LinearProgress
                                variant="query"
                            />
                        ) : _.map(_.merge(timelineObj, dataByRoles), (users, role) => (
                            <RoleColumn
                                onUserAdd={onUserAdd}
                                users={users}
                                role={role}
                                timelines={sharedTimelines}
                            />
                        ))}
                    </TableCell>
                )}

        </TableRow>
    );
};
const SpecialityColumn = ({ speciality, industry }) => {
    const isSuperAdmin = useIsSuperAdmin();
    const classes = useStyles();
    const { orgID } = useOrg();
    const { data: doctorData, refetch } = useQuery(GET_ALL_DOCTORS, { variables: { role: 'Doctor' } });
    const doctors = doctorData?.user || [];
    const filterArgs = { specialty: speciality.name, industry };
    const [isDoctorModal, setIsDoctorModal] = useState(false);
    const filterFn = (doc) => {
        const hasOrg = _.some(doc.organization_members, { organization_id: orgID });
        return doc.specialty === speciality.name && doc.industry === industry && hasOrg;
    };
    let doctorSpeciality = '';
    const specialityUsers = _.filter(doctors, !isSuperAdmin ? filterFn : filterArgs);
    if (!isSuperAdmin && specialityUsers.length > 0) {
        doctorSpeciality = specialityUsers[0].specialty;
    }
    const handleAddDoctor = () => {
        setIsDoctorModal(true);
    };
    const onDoctorAdded = () => {
        refetch();
    };

    return (
        <>
            <TableRow className={classes.row}>
                {isSuperAdmin ? (
                    <TableCell className={classes.w150}>
                        {speciality.name }
                    </TableCell>
                ) : specialityUsers.length > 0 && (
                    <TableCell className={classes.w150}>
                        {doctorSpeciality}
                    </TableCell>
                )}
                <TableCell className={classes.noPadding} colSpan={2}>
                    <Table>
                        <TableBody className={classes.innerTableBody}>
                            {_.map(specialityUsers, (user) => <DoctorColumn user={user} key={user.id} />)}
                            {isSuperAdmin && (
                                <TableRow className={classes.row}>
                                    <TableCell>
                                        <Button variant="contained" color="primary" className={classes.primaryBtn} onClick={handleAddDoctor}>
                                            Add Doctor
                                        </Button>
                                    </TableCell>

                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableCell>
            </TableRow>
            <ManageUser
                timelineUser={onDoctorAdded}
                open={isDoctorModal}
                selectedUser={{}}
                resetOnClose
                handleClose={() => setIsDoctorModal(false)}
                orgID={orgID}
                routeLabel="Doctor"
                industryName={industry}
                specialityName={speciality.name}
            />
        </>
    );
};

function TimelineTable() {
    const isSuperAdmin = useIsSuperAdmin();
    const [selectedIndustry, setSelectedIndustry] = useState();
    const { data: industryData, loading } = useQuery(GET_INDUSTRIES);
    const { data: doctorData } = useQuery(GET_ALL_DOCTORS, { variables: { role: 'Doctor' } });
    const classes = useStyles();
    const { orgID } = useOrg();
    const allData = industryData?.['industry_speciality'] || [];
    let industries = _.filter(allData, { category: 'Industry' }) || [];
    const specialities = _.filter(allData, { category: 'Speciality' }) || [];
    const [industrySpecialityDialog, setIndustrySpecialityDialog] = useState(false);
    const [specialityDialog, setSpecialityDialog] = useState(false);

    const newDoctor = doctorData?.user.filter((item) => (item?.['organization_members'][0]?.['organization_id'] === orgID ? item : null));
    if (!isSuperAdmin) {
        industries = industries.filter((item) => item.name === newDoctor[0]?.industry);
    }
    const groupedSpecialities = _.groupBy(specialities, 'speciality_parent');
    const {
        setShowTimelineTable,
        setSelectedComponent,
        setSelectedTemplate,
    } = useContext(TimelineContext);

    const onClose = () => {
        setShowTimelineTable(false);
        setSelectedComponent(null);
        setSelectedTemplate(null);
    };

    const showIndustrySpecialityDialog = () => {
        setIndustrySpecialityDialog(true);
    };

    const handleSpecialityDialog = (value) => (industry) => {
        setSpecialityDialog(value);
        setSelectedIndustry(industry);
    };

    const showSpecialityDialog = (industry) => () => {
        handleSpecialityDialog(true)(industry);
    };

    return (
        <>
            {industrySpecialityDialog && (
                <IndustrySpecialityDialog
                    industrySpecialityDialog={setIndustrySpecialityDialog}
                    setIndustrySpecialityDialog={setIndustrySpecialityDialog}
                    isIndustryDialog
                    nameList={Object.keys(industries)}
                />
            )}
            <div className={classes.tableCont}>
                {loading ? <LinearProgress variant="query" /> : (
                    <Table>
                        <TableHead>
                            <TableRow className={classes.tableHeader}>
                                {_.map(isSuperAdmin ? headersAdmin : headers, (col) => <HeaderTableCell>{col}</HeaderTableCell>)}
                            </TableRow>
                        </TableHead>
                        <TableBody className={classes.borderBottom}>
                            <TableRow className={classes.row}>
                                <TableCell className={classNames(classes.noBorderBottom, classes.w150, classes.fontBold)}>
                                    Timeline Organization
                                </TableCell>
                                {(isSuperAdmin ? subHeadersAdmin : subHeaders).map((subheader) => (
                                    <HeaderTableCell className={classNames(getClasses(classes)[subheader], classes.fontBold, classes.subheaderBG)}>
                                        {subheader}
                                    </HeaderTableCell>
                                ))}
                            </TableRow>
                            {_.map(industries, (industry, index) => (
                                <TableRow className={classes.row} key={industry.id}>
                                    <TableCell className={classes.noBorderBottom} />
                                    <TableCell className={classNames(classes.noBorderBottom, classes.w150, classes.alignTop)}>
                                        {index === 0 ? 'Universal Timeline' : ''}
                                    </TableCell>
                                    <TableCell className={classes.w150}>
                                        {industry.name}
                                    </TableCell>
                                    <TableCell colSpan={4} className={classes.noPadding}>
                                        <Table>
                                            <TableBody className={classes.innerTableBody}>
                                                {_.map(groupedSpecialities[industry.name],
                                                    (speciality) => <SpecialityColumn industry={industry.name} speciality={speciality} />)}
                                                {isSuperAdmin && (
                                                    <TableRow className={classes.row}>
                                                        <TableCell>

                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                className={classes.primaryBtn}
                                                                onClick={showSpecialityDialog(industry.id)}
                                                            >
                                                                Add Speciality
                                                            </Button>

                                                        </TableCell>

                                                    </TableRow>
                                                )}
                                                {(selectedIndustry === industry.id && specialityDialog) && (
                                                    <IndustrySpecialityDialog
                                                        industrySpecialityDialog={handleSpecialityDialog(industry.id)}
                                                        setIndustrySpecialityDialog={handleSpecialityDialog(industry.id)}
                                                        isIndustryDialog={false}
                                                        nameList={_.map(groupedSpecialities[industry.name], (s) => s.name)}
                                                        industryName={industry.name}
                                                    />
                                                )}

                                            </TableBody>
                                        </Table>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {isSuperAdmin && (
                                <TableRow className={classes.row}>
                                    <TableCell />
                                    <TableCell />
                                    <TableCell>
                                        <div className={classes.templateNameCont}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className={classes.primaryBtn}
                                                onClick={showIndustrySpecialityDialog}
                                            >
                                                Add Industry
                                            </Button>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                )}
            </div>

            <Grid container className={classes.buttonCont}>
                <Grid item xs={12}>
                    <Button className={classes.closeWorkshopButton} color="primary" variant="contained" onClick={onClose}>
                        Close workshop
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}
export default TimelineTable;
