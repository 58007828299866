/* eslint-disable consistent-return */
import '../css/style.css';
import {
    Checkbox, FormControlLabel, Grid, MenuItem, Select, Typography,
} from '@material-ui/core';
import {
    OrgContext, TimelineContext, UserAccessContext, UserContext,
} from '../context';
import { buildSettingsTreePage } from '../helper/commonHelper';
import { makeStyles } from '@material-ui/core/styles';
import DataMatrixViewModeModal from './DataMatrixViewModeModal';
import Loader from 'react-loaders';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';

const useRowStyles = makeStyles(() => ({
    accessControlGrid: {
        marginTop: 4,
        borderCollapse: 'collapse',
        alignItems: 'center',
        textAlign: 'center',
    },
    accessControlGrid2: {
        borderCollapse: 'collapse',
        alignItems: 'center',
        textAlign: 'center',
    },
    accessControlHeader: {
        background: '#f5f5f5',
        textAlign: 'center',
        border: '1px solid #D5D5D5',
        minHeight: 36,
        paddingTop: 10,
        fontSize: 12,
    },
    accessControlHeader2: {
        background: '#f5f5f5',
        textAlign: 'center',
        border: '1px solid #D5D5D5',
        minHeight: 36,
        paddingTop: 3,
    },
    accessControlItem: {
        borderRight: '1px solid #D5D5D5',
        borderBottom: '1px solid #D5D5D5',
        padding: '8px 0px 8px 10px',
        cursor: 'pointer',
        fontSize: 12,
        textAlign: 'left',
        minHeight: 33,
    },
    accessControlItem2: {
        paddingLeft: '1.3rem',
        borderRight: '1px solid #D5D5D5',
        borderBottom: '1px solid #D5D5D5',
        textAlign: 'center',
    },
    accessControlItem3: {
        borderRight: '1px solid #D5D5D5',
        borderBottom: '1px solid #D5D5D5',
        padding: '8px 0px 8px 10px',
        fontSize: 12,
        textAlign: 'left',
        minHeight: 33,
    },
    workshopTitle: {
        padding: '8px 0px',
        textAlign: 'center',
        width: '100%',
        borderLeft: '1px solid #D5D5D5',
        borderBottom: '1px solid #D5D5D5',
    },
    checkboxRoot: {
        '& svg': {
            height: 15,
            width: 15,
            color: '#000000',
        },
    },
    checkboxChecked: {
        '& svg': {
            color: '#000000',
        },
    },
    checkboxDisabled: {
        '& svg': {
            color: '#0000008a',
        },
    },
    fontSize12: {
        fontSize: 12,
    },
    selectRoot: {
        fontSize: 12,
        height: 14,
        width: 180,
    },
    highLightCls: {
        border: '3px solid cornflowerblue',
    },
    dataMatrixAccessCont: {
        border: '1px solid #7070703E',
        padding: 4,
        marginLeft: 4,
        maxHeight: '77.5vh',
        overflowY: 'auto',
    },
    columnCont: {
        background: '#E6E5E5',
        textAlign: 'center',
        padding: '4px 0px',
        marginBottom: 4,
        fontSize: 12,
    },
    accessControlCompCont: {
        marginTop: 4,
        borderTop: '1px solid #D5D5D5',
        borderLeft: '1px solid #D5D5D5',
    },
    accessControlCompContMain: {
        textAlign: 'center',
    },
    dmColumn1ContMain: {
        width: 825,
    },
    dmColumn1ContMain2: {
        width: '100%',
    },
    templateLibraryIconCont: {
        paddingRight: 10,
        textAlign: 'right',
    },
    colTitle1TextCont: {
        textAlign: 'center',
    },
    workshopTitle1: {
        padding: '5px 0px',
        textAlign: 'center',
        width: '100%',
        height: 35,
        border: '1px solid #011E27',
        marginBottom: '.75rem',
        fontSize: 12,
    },
    menuitem: {
        direction: 'rtl',
    },
    menuitemhidden: {
        display: 'none',
    },
}));

const AccessControlComponent = ({
    component,
    parent,
    selectedDropdownValue,
    parentUserAccess = null,
    parentComponentAccess = null,
    isChild = false,
    loaderHandler,
    fromConfigureDialog,
    defaultColIndex,
    tcAccessData = null,
}) => {
    const classes = useRowStyles();
    const {
        selectedColIndex, handleDMCompClick, selectedDMUAComponent,
        configData,
        setConfigData,
    } = useContext(TimelineContext);
    const {
        insertUserAccessData,
        userAccess,
        userAccessLoading,
        updateUserAccessData,
    } = useContext(UserAccessContext);
    const { doctors, roles } = useContext(UserContext);
    const uniqueRoles = [...new Set(roles.map((item) => item.role))];
    const { loggedUserType, loggedInUserDetails } = useContext(OrgContext);
    const [flag, setFlag] = useState(false);

    const orgData = doctors.find((org) => org.id === selectedDropdownValue.org);
    const userType = uniqueRoles.find((role) => role === selectedDropdownValue.userType);

    let componentUserAccess = null;
    let selectedOrgAccess = null;
    let selectedUserAccess = null;
    if (userAccessLoading) {
        loaderHandler(true);
    } else {
        loaderHandler(false);
    }

    if (userAccess && userAccess.length > 0) {
        let findUser;
        componentUserAccess = userAccess && userAccess.find((access) => access.protocol_id === component.id);
        selectedOrgAccess = componentUserAccess
            && componentUserAccess.access_data.Org.find((orgAccess) => orgAccess.doctorId === selectedDropdownValue.org);
        selectedUserAccess = selectedOrgAccess && selectedOrgAccess.visibility.find((obj) => userType in obj);

        if (componentUserAccess?.['access_data']?.Org.length > 0 && loggedInUserDetails && loggedUserType !== 'Super Admin') {
            findUser = componentUserAccess?.['access_data']?.Org.find((o) => o.doctorId === loggedInUserDetails.id);
            if (!findUser?.isVisible) {
                return null;
            }
        }
    }

    const changeChildrenAcccess = (e, compList, viewType) => {
        compList.forEach((componentVal) => {
            const componentAccessForChild = userAccess.find((access) => access.protocol_id === componentVal.id);
            const orgAccessForChild = componentAccessForChild
                && componentAccessForChild.access_data.Org.find((orgAccessData) => orgAccessData.doctorId === selectedDropdownValue.org);
            const userAccessForChild = orgAccessForChild && orgAccessForChild.visibility.find((obj) => userType in obj);
            if (userAccessForChild && userAccessForChild[userType]) {
                userAccessForChild[userType][viewType] = userAccessForChild[userType][0] ? e.target.checked : false;
                updateUserAccessData({
                    id: componentAccessForChild.id,
                    isDoctorContent: componentUserAccess.is_doctor_content,
                    userAccess: componentAccessForChild.access_data,
                });
            }
        });
    };

    const insertAccessControl = (compId, isDoctorView) => {
        const accessObj = {
            Org: [
                {
                    doctorName: orgData.name,
                    doctorId: orgData.id,
                    isVisible: isDoctorView,
                    visibility: [{ [userType]: [false, false, false] }],
                },
            ],
        };

        const userAccessData = {
            timelineId: window.location.href.split('/')[5],
            columnIndex: selectedColIndex || defaultColIndex,
            protocolId: compId,
            isDoctorContent: true,
            accessData: accessObj,
        };

        insertUserAccessData(userAccessData);
    };

    const handleChange = (e, clickedComponent) => {
        const compList = [];
        buildSettingsTreePage(compList, clickedComponent, true);
        switch (e.target.name) {
        case 'isColumn':
            clickedComponent.props.isShareDoctor = e.target.checked;
            setFlag(!flag);
            break;
        case 'isDoctorContent':
            compList.forEach((componentVal) => {
                const componentAccessForChild = userAccess && userAccess.find((access) => access.protocol_id === componentVal.id);
                if (componentAccessForChild) {
                    const orgAccessForChild = componentAccessForChild && componentAccessForChild.access_data.Org.find(
                        (orgAccessData) => orgAccessData.doctorId === selectedDropdownValue.org
                    );
                    if (orgAccessForChild) {
                        componentUserAccess.is_doctor_content = e.target.checked;
                        orgAccessForChild.isVisible = false;
                        orgAccessForChild.visibility = [{ [userType]: [false, false, false] }];
                        updateUserAccessData({
                            id: componentAccessForChild.id,
                            isDoctorContent: e.target.checked,
                            userAccess: componentAccessForChild.access_data,
                        });
                    } else {
                        componentUserAccess.is_doctor_content = e.target.checked;
                        const newOrgAccess = {
                            doctorName: orgData.name,
                            doctorId: orgData.id,
                            isVisible: false,
                            visibility: [{ [userType]: [false, false, false] }],
                        };
                        componentAccessForChild.access_data.Org.push(newOrgAccess);
                        updateUserAccessData({
                            id: componentAccessForChild.id,
                            isDoctorContent: e.target.checked,
                            userAccess: componentAccessForChild.access_data,
                        });
                    }
                } else {
                    insertAccessControl(componentVal.id, false);
                }
            });
            break;
        case 'isUserContent':
            clickedComponent.props.isUserContent = e.target.checked;
            setFlag(!flag);
            break;
        case 'isOrgView':
            compList.forEach((componentVal) => {
                const componentAccessForChild = userAccess && userAccess.find((access) => access.protocol_id === componentVal.id);
                if (componentAccessForChild) {
                    const orgAccessForChild = componentAccessForChild
                        && componentAccessForChild.access_data.Org.find((orgAccessData) => orgAccessData.doctorId === selectedDropdownValue.org);
                    if (orgAccessForChild) {
                        orgAccessForChild.isVisible = e.target.checked;
                        orgAccessForChild.visibility = [{ [userType]: [false, false, false] }];
                        updateUserAccessData({
                            id: componentAccessForChild.id,
                            isDoctorContent: componentUserAccess.is_doctor_content,
                            userAccess: componentAccessForChild.access_data,
                        });
                    } else {
                        const newOrgAccess = {
                            doctorName: orgData.name,
                            doctorId: orgData.id,
                            isVisible: true,
                            visibility: [{ [userType]: [false, false, false] }],
                        };
                        componentAccessForChild.access_data.Org.push(newOrgAccess);
                        updateUserAccessData({
                            id: componentAccessForChild.id,
                            isDoctorContent: componentUserAccess.is_doctor_content,
                            userAccess: componentAccessForChild.access_data,
                        });
                    }
                } else {
                    insertAccessControl(componentVal.id, true);
                }
            });
            break;
        case 'isUserView': {
            const columnAccessClone = (configData?.isUserView
                && configData?.isUserView?.length > 0 && [...configData.isUserView[0]?.['column_access']]) || [];
            if (e.target.checked) {
                columnAccessClone.push(clickedComponent.id);
            } else {
                const findCompIndex = columnAccessClone.findIndex((c) => c === clickedComponent.id);
                columnAccessClone.splice(findCompIndex, 1);
            }
            if (configData.isUserView.length === 0) {
                configData.isUserView.push({ column_access: [] });
            }
            configData.isUserView[0].column_access = [...columnAccessClone];

            setConfigData({ ...configData });
        }
            break;
        case 'isAdvancedView':
            changeChildrenAcccess(e, compList, 1);
            break;
        case 'isBasicView':
            changeChildrenAcccess(e, compList, 2);
            break;
        default:
        }
    };

    const click = (e, component) => {
        handleDMCompClick(component, parent);
    };

    return (
        <>
            <Grid container alignItems="center" className={classes.accessControlCompContMain}>
                {(loggedUserType === 'Super Admin' || loggedUserType === 'Doctor') && (
                    <Grid
                        item
                        xs={6}
                        className={[fromConfigureDialog ? classes.accessControlItem3 : classes.accessControlItem,
                            selectedDMUAComponent.id === component.id ? classes.highLightCls : null,
                            `${component?.props?.type ? component?.props?.type : 'other'}Cls`].join(' ')}
                        onClick={fromConfigureDialog ? () => null : (e) => click(e, component)}
                    >
                        {component?.props?.title || component?.props?.text}
                    </Grid>
                )}
                <Grid item xs={6}>
                    <Grid container>
                        {loggedUserType === 'Super Admin' && (
                            <>
                                <Grid item xs={6} className={classes.accessControlItem2}>
                                    <FormControlLabel
                                        control={(
                                            <Checkbox
                                                disabled={isChild && !parentComponentAccess?.['is_doctor_content']}
                                                checked={component && component?.props?.isShareDoctor}
                                                name="isColumn"
                                                classes={{
                                                    root: classes.checkboxRoot,
                                                    checked: classes.checkboxChecked,
                                                    disabled: classes.checkboxDisabled,
                                                }}
                                                onChange={(e) => handleChange(e, component)}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6} className={classes.accessControlItem2}>
                                    <FormControlLabel
                                        control={(
                                            <Checkbox
                                                disabled={!component?.props?.isShareDoctor}
                                                checked={!!(configData.isUserView
                                                    && configData?.isUserView[0]?.['column_access']?.find((c) => c === component.id))}
                                                name="isUserView"
                                                classes={{
                                                    root: classes.checkboxRoot,
                                                    checked: classes.checkboxChecked,
                                                    disabled: classes.checkboxDisabled,
                                                }}
                                                onChange={(e) => handleChange(e, component)}
                                            />
                                        )}
                                    />
                                </Grid>
                            </>
                        )}
                        {loggedUserType === 'Doctor' && (
                            <>
                                <Grid item xs={6} className={classes.accessControlItem2}>
                                    <FormControlLabel
                                        control={(
                                            <Checkbox
                                                checked={component && component?.props?.isUserContent}
                                                name="isUserContent"
                                                classes={{
                                                    root: classes.checkboxRoot,
                                                    checked: classes.checkboxChecked,
                                                    disabled: classes.checkboxDisabled,
                                                }}
                                                onChange={(e) => handleChange(e, component)}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={6} className={classes.accessControlItem2}>
                                    <FormControlLabel control={(
                                        <Checkbox
                                            disabled={!component.props.isUserContent}
                                            checked={!!(configData.isUserView
                                                && configData?.isUserView[0]?.['column_access']?.find((c) => c === component.id))}
                                            name="isUserView"
                                            classes={{
                                                root: classes.checkboxRoot,
                                                checked: classes.checkboxChecked,
                                                disabled: classes.checkboxDisabled,
                                            }}
                                            onChange={(e) => handleChange(e, component)}
                                        />
                                    )}
                                    />
                                </Grid>
                            </>
                        )}
                        {(loggedUserType === 'User' || loggedUserType === 'Patient') && (
                            <>
                                <Grid item xs={6} className={classes.accessControlItem2}>
                                    <FormControlLabel control={(
                                        <Checkbox
                                            disabled={isChild ? (!selectedUserAccess?.[userType]?.[0] || !parentUserAccess?.[1])
                                                : !selectedUserAccess?.[userType]?.[0]}
                                            checked={selectedUserAccess && selectedUserAccess[userType][1]}
                                            name="isAdvancedView"
                                            classes={{
                                                root: classes.checkboxRoot,
                                                checked: classes.checkboxChecked,
                                                disabled: classes.checkboxDisabled,
                                            }}
                                            onChange={(e) => handleChange(e, component)}
                                        />
                                    )}
                                    />
                                </Grid>
                                <Grid item xs={6} className={classes.accessControlItem2}>
                                    <FormControlLabel control={(
                                        <Checkbox
                                            disabled={isChild ? (!selectedUserAccess?.[userType]?.[0] || !parentUserAccess?.[2])
                                                : !selectedUserAccess?.[userType]?.[0]}
                                            checked={selectedUserAccess && selectedUserAccess[userType][2]}
                                            name="isBasicView"
                                            classes={{
                                                root: classes.checkboxRoot,
                                                checked: classes.checkboxChecked,
                                                disabled: classes.checkboxDisabled,
                                            }}
                                            onChange={(e) => handleChange(e, component)}
                                        />
                                    )}
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            {
                !fromConfigureDialog && component.props.components && component.props.components.filter(
                    (targetComponent) => targetComponent.type === 'Protocol'
                        || targetComponent.type === 'ProtocolGroup' || targetComponent.type === 'Text'
                ).map((item) => (
                    <AccessControlComponent
                        key={`Access_control_child_${item.id}`}
                        component={item}
                        parent={parent}
                        selectedDropdownValue={selectedDropdownValue}
                        parentUserAccess={selectedUserAccess ? selectedUserAccess[userType] : null}
                        parentOrgAccess={selectedOrgAccess}
                        parentComponentAccess={componentUserAccess}
                        isChild
                        loaderHandler={loaderHandler}
                        fromConfigureDialog={fromConfigureDialog}
                        defaultColIndex={defaultColIndex}
                        tcAccessData={tcAccessData}
                    />
                ))
            }
        </>
    );
};

const DataMatrixColumn = ({
    fromConfigureDialog, requiredColumnIndex, column, parent, selectedUser, tcAccessData = null,
}) => {
    const [component] = useState(column);
    const { props } = component;
    const [dmViewMode, setDMViewMode] = useState(false);
    const [, setTimelineColumnValue] = useState('');

    const {
        progressions, components,
    } = props;
    const {
        isProgressionDM, setIsDMViewMode, dataMatrixComponent, selectedTimeline, selectedColIndex,
    } = useContext(TimelineContext);
    const { doctors, roles } = useContext(UserContext);
    const { loggedUserType, loggedInUserDetails } = useContext(OrgContext);
    let selectedDropDownVal = { org: doctors?.[0].id, userType: 'User' };

    if (fromConfigureDialog) {
        selectedDropDownVal = {
            org: loggedUserType === 'Super Admin' ? selectedUser?.id : doctors?.[0].id,
            userType: loggedUserType === 'Doctor' ? selectedUser?.role : 'User',
        };
    }
    const [state, setState] = useState(selectedDropDownVal);
    const classes = useRowStyles();
    const [loader, setLoader] = useState(false);
    const [value] = useState('none');
    const [showPlaceholder] = useState(value === 'none');
    // const { columns = [] } = selectedTimeline;
    const uniqueRoles = [...new Set(roles.map((item) => item.role))];
    useEffect(() => () => {
        delete dataMatrixComponent?.dmColumViewType;
        setTimelineColumnValue(selectedColIndex);
    }, []);

    const handleCloseDMViewMode = () => {
        setDMViewMode(!dmViewMode);
        setIsDMViewMode(!dmViewMode);
        if (dataMatrixComponent?.dmColumViewType) {
            delete dataMatrixComponent.dmColumViewType;
        }
    };

    const selectChangeHandler = (e) => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value });
    };

    const handleDMViewModel = () => {
        setDMViewMode(!dmViewMode);
        setIsDMViewMode(!dmViewMode);
    };

    const loaderHandler = (status) => {
        setLoader(status);
    };

    const renderLoader = () => (
        <div id="overlay">
            <div id="loader">
                <Loader style={{ transform: 'scale(1.5)' }} type="line-scale" />
            </div>
        </div>
    );

    const renderDMView = () => {
        let datePOIComp = null;

        datePOIComp = (
            <>
                <Grid container>
                    {fromConfigureDialog ? '' : (
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" className={classes.columnCont}>Column 1</Typography>
                        </Grid>
                    )}
                    <Grid container className={classes.workshopTitle1}>
                        <Grid item xs={10} md={11} className={classes.colTitle1TextCont}>
                            <Typography variant="h4">{selectedTimeline?.name}</Typography>
                        </Grid>
                        {fromConfigureDialog ? '' : (
                            <Grid item xs={2} md={1} className={classes.templateLibraryIconCont}>
                                <OpenInNewIcon onClick={handleDMViewModel} />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={12} className={fromConfigureDialog ? classes.accessControlGrid2 : classes.accessControlGrid}>
                    <Grid container alignItems="center">
                        {(loggedUserType === 'Super Admin' || loggedUserType === 'Doctor') && (
                            <Grid item xs={6} className={classes.accessControlHeader}>
                                {loggedUserType === 'Super Admin' ? 'MedGuide' : loggedInUserDetails?.name}
                            </Grid>
                        )}
                        <Grid item xs={6}>
                            <Grid container>
                                {loggedUserType === 'Super Admin' && (
                                    <>
                                        <Grid item xs={6} className={classes.accessControlHeader}>
                                            Available Doctor Content
                                        </Grid>
                                        <Grid item xs={6} className={classes.accessControlHeader2}>
                                            <Select
                                                name="org"
                                                color="secondary"
                                                label="Select Doctor List"
                                                labelId="demo-simple-select-filled-label"
                                                id="demo-simple-select-filled"
                                                value={state.org}
                                                onChange={selectChangeHandler}
                                                inputProps={{ className: classes.inputValue }}
                                                classes={{ root: classes.fontSize12 }}
                                                fullWidth
                                                disabled={selectedUser}
                                            >
                                                <MenuItem
                                                    className={clsx(classes.menuitem, !showPlaceholder ? classes.menuitemhidden : null)}
                                                    key="0"
                                                    disabled
                                                    value="none"
                                                >
                                                    Select Doctor
                                                </MenuItem>
                                                {doctors && doctors.map((org) => (
                                                    <MenuItem value={org.id}>{org.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>
                                    </>
                                )}
                                {loggedUserType === 'Doctor' && (
                                    <>
                                        <Grid item xs={6} className={classes.accessControlHeader}>
                                            Available User Content
                                        </Grid>
                                        <Grid item xs={6} className={classes.accessControlHeader2}>
                                            <Select
                                                name="userType"
                                                color="secondary"
                                                labelId="demo-simple-select-filled-label"
                                                id="demo-simple-select-filled"
                                                value={state.userType}
                                                onChange={selectChangeHandler}
                                                inputProps={{ className: classes.inputValue }}
                                                classes={{ root: classes.fontSize12 }}
                                                fullWidth
                                                disabled={selectedUser}
                                            >
                                                {uniqueRoles && uniqueRoles.forEach((role) => {
                                                    if (role !== 'Doctor') {
                                                        return (<MenuItem value={role}>{selectedUser?.name ? selectedUser?.name : role}</MenuItem>);
                                                    }
                                                })}
                                            </Select>
                                        </Grid>
                                    </>
                                )}
                                {(loggedUserType === 'User' || loggedUserType === 'Patinet') && (
                                    <>
                                        <Grid item xs={6} className={classes.accessControlHeader}>
                                            Advanced
                                        </Grid>
                                        <Grid item xs={6} className={classes.accessControlHeader}>
                                            Basic
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <div className={classes.accessControlCompCont}>
                    {!fromConfigureDialog && (isProgressionDM
                        ? progressions.map((progression) => (
                            <AccessControlComponent
                                key={`Access_control_${progression.id}`}
                                component={progression}
                                parent={parent}
                                selectedDropdownValue={state}
                                loaderHandler={loaderHandler}
                                fromConfigureDialog={fromConfigureDialog}
                                defaultColIndex={requiredColumnIndex}
                                tcAccessData={tcAccessData}
                            />
                        ))
                        : components.map((component) => (
                            <AccessControlComponent
                                key={`Access_control_${component.id}`}
                                component={component}
                                parent={parent}
                                selectedDropdownValue={state}
                                loaderHandler={loaderHandler}
                                fromConfigureDialog={fromConfigureDialog}
                                defaultColIndex={requiredColumnIndex}
                                tcAccessData={tcAccessData}
                            />
                        )))}
                    {
                        fromConfigureDialog && selectedTimeline.columns.map((column) => (
                            <AccessControlComponent
                                key={`Access_control_${column.id}`}
                                component={column}
                                parent={parent}
                                selectedDropdownValue={state}
                                loaderHandler={loaderHandler}
                                fromConfigureDialog={fromConfigureDialog}
                                defaultColIndex={requiredColumnIndex}
                                selectedUser={selectedUser}
                                tcAccessData={tcAccessData}
                            />
                        ))
                    }
                </div>
            </>
        );
        return datePOIComp;
    };

    return (
        <>
            {loader && renderLoader()}
            <Grid container className={fromConfigureDialog ? classes.dmColumn1ContMain2 : classes.dmColumn1ContMain}>
                <Grid item xs={12} className={classes.dataMatrixAccessCont}>
                    {renderDMView()}
                </Grid>
            </Grid>
            {dmViewMode
                && <DataMatrixViewModeModal dmViewMode={dmViewMode} handleCloseDMViewMode={handleCloseDMViewMode} selectedDropdownValue={state} />}
        </>
    );
};

export default DataMatrixColumn;
