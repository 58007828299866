import { Typography } from '@material-ui/core';
import React from 'react';

class ErrorBoundary extends React.Component {
    constructor() {
        super();
        this.state = {
            hasError: false,
        };
    }

    static getDerivedStateFromError() {
        return ({
            hasError: true,
        });
    }

    render() {
        const { hasError } = this.state;
        const { children, message } = this.props;

        if (hasError) {
            return (
                <Typography>{message}</Typography>
            );
        }
        return (
            children
        );
    }
}

export default ErrorBoundary;
