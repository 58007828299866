/* eslint-disable react/jsx-closing-tag-location */
import { Container } from './Container';
import {
    TimelineContext,
    WorkshopContextProvider,
} from '../context';
import { makeStyles } from '@material-ui/core/styles';
import { standardHeaders } from './utils/StandardStyles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import React, {
    useContext, useEffect,
} from 'react';

const useStyles = makeStyles(() => ({
    container: {
        marginTop: '20px',
        marginRight: '150px',
    },
    progressionColumn: {
        height: '70vh',
    },
    workshop: {
        width: '100%',
        position: 'relative',
        height: '100%',
    },
    protocal: {
        position: 'absolute',
        width: '40%',
    },
    progressions: {
        position: 'absolute',
        width: '100%',
    },
    workshopHeaderColumn: {
        ...standardHeaders.h1,
        background: '#2E3538',
        minHeight: 49,
        position: 'relative',
        width: '100%',
        zIndex: '999',
    },
    columHeadType: {
        fontSize: 14,
        color: 'white',
    },
    protocolView: {
        width: '100%',
        boxShadow: '5px -10px 12px #ccc',
    },
    myNavbarCollapse: {
        transition: 'all .6s ease',
        height: 0,
    },
    show: {
        height: '35vh',
    },
    explicitHeaderStyle: {
        position: 'sticky',
        top: 0,
        zIndex: 5,
        height: 40,
        lineHeight: '42px',
        backgroundColor: 'aliceblue',
        borderBottom: '2px solid lightskyblue',
    },
    explicitColumnItem: {
        textAlign: 'center',
        fontWeight: 600,
        zIndex: 10,
        marginRight: 1,
    },
    researchTextCont: {
        marginTop: 55,
        textAlign: 'center',
        fontSize: 14,
    },
    disabledWorkshop: {
        backgroundColor: 'transparent',
        position: 'absolute',
        height: '100%',
        zIndex: 999,
        width: '100%',
    },
}));

const Workshop = () => {
    const classes = useStyles();
    const {
        workshopComponent,
    } = useContext(TimelineContext);

    useEffect(() => {
    }, [workshopComponent]);

    const renderWorkshopItem = () => {
        if (Array.isArray(workshopComponent)) {
            return workshopComponent.map((comp, i) => <Container props={{ components: [workshopComponent[i]], isWorkshop: true }} />);
        }
        return <Container props={{ components: [workshopComponent], isWorkshop: true }} />;
    };

    const GetWorkshopAndSetting = () => (
        <WorkshopContextProvider>
            <Grid item xs={12}>
                <Paper elevation={3} className={classes.workshop}>
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            position: 'relative',
                        }}
                    >
                        <div className={classes.disabledWorkshop} />
                        {renderWorkshopItem()}
                    </div>
                </Paper>
            </Grid>
        </WorkshopContextProvider>
    );

    const getWorkshopView = () => {
        const workshopView = (
            <div className={classes.protocolView}>
                <Grid container>
                    <Grid container item xs={12}>
                        {GetWorkshopAndSetting()}
                    </Grid>
                </Grid>
            </div>
        );

        return workshopView;
    };

    return (
        <div>
            {getWorkshopView()}
        </div>
    );
};

export default Workshop;
