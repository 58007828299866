/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { Column, ColumnPreview } from './Column';
import {
    ExpandLess, ExpandMore, Image, YouTube,
} from '@material-ui/icons';
import {
    FilterSettings, buildSettingsTreePage,
    flatOptions, setActiveComponent, setComponentType, updateComponentsForTypeRow,
} from '../helper/commonHelper';
import { MenuDefaults, buildComponentMenu } from './utils/EditorMenu';
import { OrgContext, TimelineContext, WorkshopContext } from '../context';
import { Settings, makeInlineEditor } from './Settings';
import { componentWithId } from './Atoms';
import { makeStyles } from '@material-ui/core/styles';
import { useRecoilState } from 'recoil';
import { useRouteMatch } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import React, {
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import Typography from '@material-ui/core/Typography';

const useRowStyles = makeStyles((theme) => ({
    column: {
        padding: 0,
        borderTop: 'none',
    },
    redBg: {
        backgroundColor: '#ff8a8a',
    },
    greenBg: {
        backgroundColor: '#8cf98c',
    },
    column2: {
        padding: 0,
    },
    protocalPrevTitle: {
        overflowWrap: 'break-word',
        fontSize: 12,
        padding: 4,
    },
    hideCls: {
        border: 'none',
    },
    protocolTitleHidden: {
        border: 'none',
        cursor: 'pointer',
    },
    protocolTitleVisible: {
        padding: 0,
        cursor: 'pointer',
    },
    iconStyle: {
        cursor: 'pointer',
        display: 'none',
    },
    formControl: {
        margin: '5px 4px',
        width: '98%',
    },
    formControl1: {
        margin: '10px 0px',
        width: '100%',
    },
    groupHeader: {
        border: '1px solid #ececec',
        padding: '4px',
        textAlign: 'center',
    },
    cfPrevCont: {
        border: '1px solid #ececec',
        '& p': {
            padding: 4,
            fontSize: '.625rem',
            fontWeight: 400,
            lineHeight: '12px',
        },
    },
    protocalTitleText: {
        fontWeight: '700 !important',
        cursor: 'pointer',
    },
    protocalTitleCont: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'left',
        '& svg': {
            color: 'red',
        },
    },
    cfPrevHeader: {
        borderBottom: '.25px solid #cbcbcb',
        borderLeft: '.25px solid #cbcbcb',
        height: 25,
        '& p': {
            textOverflow: 'ellipsis',
            display: 'flex',
            fontSize: '0.625rem !important',
            padding: '6px 4px',
            fontWeight: '400',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
            '&:hover': {
                overflowX: 'auto',
                '&::-webkit-scrollbar': {
                    height: '2.5px',
                    width: '2.5px',
                    display: 'block',
                },
                '&::-webkit-scrollbar-thumb': {
                    background: '#888',
                },
            },
        },
    },
    cfPrevLeftRightCont: {
        borderBottom: '.25px solid #cbcbcb',
        height: 25,
    },
    cfPrevLRHeader: {
        borderBottom: '.25px solid #cbcbcb',
        height: 25,
        '& p': {
            textOverflow: 'ellipsis',
            display: 'flex',
            fontSize: '0.625rem !important',
            padding: '6px 4px',
            fontWeight: '400',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
            '&:hover': {
                overflowX: 'auto',
                '&::-webkit-scrollbar': {
                    height: '2.5px',
                    width: '2.5px',
                    display: 'block',
                },
                '&::-webkit-scrollbar-thumb': {
                    background: '#888',
                },
            },
        },
    },
    cfPrevLR2ndHeader: {
        borderBottom: '.25px solid #cbcbcb',
        borderLeft: '.25px solid #cbcbcb',
        height: 25,
        '& p': {
            textOverflow: 'ellipsis',
            display: 'flex',
            fontSize: '0.625rem !important',
            padding: '6px 4px',
            fontWeight: '400',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
            '&:hover': {
                overflowX: 'auto',
                '&::-webkit-scrollbar': {
                    height: '2.5px',
                    width: '2.5px',
                    display: 'block',
                },
                '&::-webkit-scrollbar-thumb': {
                    background: '#888',
                },
            },
        },
    },
    cfPrevTopHeader: {
        borderBottom: '.25px solid #cbcbcb',
    },
    cfPrevParentCont: {
        borderLeft: '.25px solid #cbcbcb',
        '& p': {
            overflowX: 'auto',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
                display:
                    'none',
            },
            '&:hover': {
                '&::-webkit-scrollbar': {
                    height: '2.5px',
                    width: '2.5px',
                    display: 'block',
                },
                '&::-webkit-scrollbar-thumb': {
                    background: '#888',
                },
            },
        },
    },
    cfPrevLRParentCont: {
        borderBottom: '.25px solid #cbcbcb',
        '& p': {
            overflowX: 'auto',
            overflowY: 'auto',
            height: 'auto',
            minHeight: 40,
            '&::-webkit-scrollbar': {
                display:
                    'none',
            },
            '&:hover': {
                '&::-webkit-scrollbar': {
                    height: '2.5px',
                    width: '2.5px',
                    display: 'block',
                },
                '&::-webkit-scrollbar-thumb': {
                    background: '#888',
                },
            },
        },
    },
    cfPrevChidCont: {
        '& p': {
            borderLeft: '.25px solid #cbcbcb',
            borderBottom: '.25px solid #cbcbcb',
            height: 40,
            overflowX: 'auto',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
                display:
                    'none',
            },
            '&:hover': {
                '&::-webkit-scrollbar': {
                    height: '2.5px',
                    width: '2.5px',
                    display: 'block',
                },
                '&::-webkit-scrollbar-thumb': {
                    background: '#888',
                },
            },
        },
        '& > :last-child': {
            borderBottom: 'none',
        },
    },
    cfPrevLRChidCont: {
        '& p': {
            borderLeft: '.25px solid #cbcbcb',
            borderBottom: '.25px solid #cbcbcb',
            height: 40,
            overflowX: 'auto',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
                display:
                    'none',
            },
            '&:hover': {
                '&::-webkit-scrollbar': {
                    height: '2.5px',
                    width: '2.5px',
                    display: 'block',
                },
                '&::-webkit-scrollbar-thumb': {
                    background: '#888',
                },
            },
        },
    },
    columHeadType: {
        fontSize: '0.75rem',
    },
    formPreviewBorder: {
        borderRight: '.25px solid #cbcbcb',
    },
    pageViewBorder: {
        borderBottom: '.25px solid #cbcbcb',
    },
    selectedComp: {
        background: 'cornflowerblue',
    },
    hideText: {
        background: '#DEDEDE',
    },
    fullWidth: {
        width: '100%',
        border: '.25px solid #cbcbcb',
    },
    titleStyle: {
        fontSize: '.625rem',
        wordBreak: 'break-word',
        fontWeight: 700,
        lineHeight: '13px',
        padding: '2px',
        textTransform: 'uppercase',
    },
    titleStyleProgress: {
        fontSize: '.625rem',
        wordBreak: 'break-word',
        fontWeight: 700,
        lineHeight: '13px',
        padding: '2px',
        textTransform: 'uppercase',
        maxHeight: '230px',
        overflow: 'hidden',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            display:
                'none',
        },
        '&:hover': {
            '&::-webkit-scrollbar': {
                height: '2.5px',
                width: '2.5px',
                display: 'block',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#888',
            },
        },
    },
    stackTitleView: {
        maxHeight: 13,
    },
    defaultTitleView: {
        maxHeight: 200,
    },
    contentDisplayIcon: {
        cursor: 'pointer',
        fontSize: 18,
        display: 'block',
    },
    tooltipClass: {
        '&:hover': {
            display: 'block',
            '& p': {
                display: 'block',
            },
        },
        '& p': {
            display: 'none',
            border: '1px solid #cbcbcb',
            position: 'absolute',
            background: '#fff',
            zIndex: 999,
            padding: '4px 8px',
            borderRadius: 4,
            left: 48,
            top: 'auto',
            fontSize: 12,
        },
    },
    block: {
        display: 'block',
    },
    normalText: {
        color: 'inherit',
        textDecoration: 'none',
        cursor: 'text',
    },
    anchorTag: {
        color: '#066785',
        textDecoration: 'none',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
        wordBreak: 'initial',
    },
    extraBorderCls: {
        borderBottom: '1.5px solid rgb(84 79 79)',
        borderLeft: '1px solid #ececec',
        borderRight: '1px solid #ececec',
    },
    borderLeftSide: {
        borderLeft: '.25px solid #cbcbcb',
    },
    borderLeftCls: {
        borderLeft: '.25px solid #cbcbcb',
    },
    coulmnContainerWrapperTime: {
        width: '100%',
    },
    coulmnContainerWrapper: {
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            overflowX: 'auto',
        },
    },
    coulmnContainerInnerWrapper: {
        [theme.breakpoints.down('xs')]: {
            width: 'calc(100% + 300px)',
        },
    },
}));

const TitleProp = {
    key: 'title',
    type: 'TextField',
    label: 'Enter Protocol',
    isBasic: true,
    isAdvance: true,
    isAdmin: true,
    id: 'Protocol',
};

export const ProtocolProps = {
    display: [
        TitleProp,
        {
            key: 'layout',
            type: 'Select',
            label: 'Protocol Layout',
            id: 'Protocol',
            default: 'default',
            options: [{ label: 'Default', value: 'default' }, { label: 'Stacked', value: 'stacked' }],
            isBasic: false,
            isAdvance: true,
            isAdmin: true,
        },
        // {
        //     key: 'flexTextField',
        //     type: 'Select',
        //     label: 'Column Layout',
        //     id: 'Protocol',
        //     default: '1',
        //     options: [{ label: '1 Column', value: '1' },
        //         { label: '2 Columns', value: '2' },
        //         { label: '3 Columns', value: '3' }],
        //     isBasic: false,
        //     isAdvance: true,
        //     isAdmin: true,
        // },
        {
            key: 'showProtocol',
            type: 'Checkbox',
            label: 'Show Protocol Section',
            default: true,
            id: 'Timeline',
        },
        {
            key: 'listTypePreview',
            type: 'Select',
            label: 'Column List Type',
            id: 'Protocol',
            default: 'none',
            options: [{ label: 'None', value: 'none' }, { label: 'Number', value: 'number' }, { label: 'Bullet', value: 'bullet' }],
            isBasic: false,
            isAdvance: true,
            isAdmin: true,
        },
        // {
        //     key: 'single-continuous',
        //     type: 'Select',
        //     label: 'Single vs Continuous Textarea',
        //     id: 'Protocol',
        //     options: [
        //         { label: 'Single', value: 'single' },
        //         { label: 'Continuous', value: 'continuous' },
        //     ],
        //     default: 'single',
        //     isBasic: false,
        //     isAdvance: true,
        //     isAdmin: true,
        // },
        // {
        //     key: 'hyperlink',
        //     type: 'TextFieldHyperlinkDebounce',
        //     label: 'Page Hyperlink',
        //     isBasic: true,
        //     isAdvance: true,
        //     isAdmin: true,
        //     id: 'Protocol',
        // },
        // {
        //     key: 'openHyperlinkType',
        //     type: 'Radio',
        //     label: 'Open Page Hyperlink',
        //     id: 'Protocol',
        //     default: 'inApp',
        //     options: [{ label: 'New Tab', value: 'newTab' }, { label: 'Content Display', value: 'inApp' }],
        //     isBasic: true,
        //     isAdvance: true,
        //     isAdmin: true,
        // },
        {
            key: 'videoHyperlink',
            type: 'TextFieldHyperlinkDebounce',
            label: 'Video Hyperlink',
            isBasic: true,
            isAdvance: true,
            isAdmin: true,
            id: 'Protocol',
        },
        // {
        //     key: 'pcRelationship',
        //     type: 'Radio',
        //     label: 'Parent / Child Relationship',
        //     id: 'Protocol',
        //     default: 'newTab',
        //     value: 'standard',
        //     options: [{ label: 'Standard Spread Sheet P/C', value: 'standard' }, { label: 'Manually Select Children', value: 'manual' }],
        //     isBasic: true,
        //     isAdvance: true,
        //     isAdmin: true,
        // },
    ],
    poi: true,
};

const Protocol = ({
    componentProps, componentParent, componentIndex,
}) => {
    const classes = useRowStyles();
    const isHyperlink = !!(Array.isArray(componentProps?.props?.hyperlink) && componentProps?.props?.hyperlink.find((c) => c !== ''));
    const openHyperlinkType = componentProps?.props?.hasOwnProperty('openHyperlinkType') ? componentProps.props.openHyperlinkType : 'inApp';
    const [protocolTitle, setProtocolTitle] = useState({
        __html: isHyperlink && openHyperlinkType === 'newTab'
            ? `<a href=${componentProps?.props?.hyperlink?.[0]} class=${classes.anchorTag} target='_blank'>${componentProps?.props?.title}</a>`
            : componentProps?.props?.title,
    });
    const protocolTitleRef = useRef(null);
    const { inWorkshop, compareModal, previewTemplate } = useContext(WorkshopContext);
    const {
        clickedArea, workshopComponent, actionRelation, showTitleView, selectedChildren, cdComponent, selectedComponent,
        setCDComponent, selectedTimeline, getLocationOfTargetComponent, workshopActiveComponent,
        contextMenuPosition, contextMenuTarget, domain, setDirtyDomain, dirtyDomain, cdClickType, setCdClickType,
        hyperlinkCip, progressionAccCollapse, setWorkshopComponent, workshopAccessData,
        setCollapseProtocol, collapseProtocol, allContentDisplayData, setUniversalCompState, setUniversalScndState,
        collapseProgressionComp, setCollapseProgressionComp, templateLibraryCip, updateFlag, setUpdateFlag,
        setCurrentProtocolParents, setCurrentProtocol,
    } = useContext(TimelineContext);

    const { loggedUserType } = useContext(OrgContext);

    if (loggedUserType === 'Doctor') {
        if (workshopAccessData?.[`${componentProps?.id}`]?.user_type === 'Staff') {
            return;
        }
    }
    if (loggedUserType === 'Patient') {
        if (workshopAccessData?.[`${componentProps?.id}`]?.user_type === 'Staff'
            || workshopAccessData?.[`${componentProps?.id}`]?.user_type === 'Patient') {
            return;
        }
    }

    const {
        id,
    } = componentProps;
    const isProgression = !!((clickedArea === 'Progression' || (workshopComponent && workshopComponent.isProgression)));
    const coilId = isProgression && previewTemplate ? `preview${id}` : id;
    const [component, setComponentState] = useRecoilState(componentWithId(coilId));
    const [componentById] = useRecoilState(componentWithId(id));
    const [showComponent, setShowComponent] = useState(false);
    if (!component.props) {
        component.props = { ...componentById.props };
        component.parent = Array.isArray(componentById.parent) && [...componentById.parent];
    }
    const {
        settingComponent,
        setSettingComponentHandle,
        setColumnChangeDialog,
        userSettingsViewChanges,
        updateSettingsAccess,
        setUserSettingsViewChanges,
        userSettingsValues,
        setUserSettingsValues,
        isMobile,
        isFullLR,
    } = useContext(TimelineContext);
    const {
        components: columns, componentHeight, hideFromWorkshop = false, title,
    } = componentProps.props;

    if (typeof componentProps?.props?.showTitle === 'boolean') {
        componentProps.props.showTitle = componentProps.props.showTitle ? 'yes' : 'no';
    }
    let { showTitle = 'yes' } = componentProps.props;
    showTitle = (showTitle === 'yes');

    let isSelected = false;

    if ((settingComponent && settingComponent.id === id || (cdComponent?.id === id))
        || (!workshopComponent && contextMenuPosition && contextMenuTarget?.component?.id === componentProps?.id)) {
        isSelected = true;
    }

    let isUniversalManual = false;
    if (componentProps?.props?.isUniversal) {
        isUniversalManual = componentProps.props.pcRelationship === 'manual';
    }

    if (componentProps && componentProps[domain] && !component.id && !component[domain] && componentProps.props.pageChild) {
        component[domain] = componentProps[domain];
    }
    if (componentProps?.name === 'Form' && (componentProps?.component === 'Text Area' || componentProps?.component === 'Text Box')) {
        if (!componentProps?.props?.hasOwnProperty('single-continuous')) {
            componentProps.props['single-continuous'] = 'continuous';
        }
    }

    const isCF = (componentProps?.supergroup === 'Complex Forms' && componentProps?.component !== 'One Sided CF'
        && componentProps?.component !== 'Group Options');
    const direction = component.props.layout === 'stacked' ? 'column' : 'row';

    const componentCD = allContentDisplayData?.['content_display'].find((i) => i.protocol_id === id);

    if (componentCD?.['image_list']?.length > 0) {
        component.props.hasContentDisplay = true;
    } else {
        component.props.hasContentDisplay = false;
    }

    if (componentProps.props.isUniversal && !Object.prototype.hasOwnProperty.call(componentProps.props, 'pcRelationship')) {
        componentProps.props.pcRelationship = 'standard';
    }
    const wowTemplates = ['Select/Text-Complete-Notes', 'SELECT-COMPLETED', 'Select-Complete-Notes', 'SELECT-COMMPLETED-TEMPLATE'];

    useEffect(() => {
        if (!wowTemplates.includes(workshopComponent?.component)) {
            if (!workshopComponent?.isProgression && !workshopComponent?.props?.isSimpleForm
                && workshopComponent?.id === componentProps?.id && ((workshopComponent?.props?.flexTextField !== undefined
                    && workshopComponent.props.flexTextField !== 1)
                    || (workshopComponent?.props['single-continuous'] === 'continuous'))) {
                setColumnChangeDialog(true);
            }
            if (!workshopComponent?.isProgression && workshopComponent?.props?.isSimpleForm && workshopComponent?.id === componentProps?.id) {
                componentProps.props.flexTextField = workshopComponent.props.flexTextField;
                componentProps.props.isSimpleForm = workshopComponent.props.isSimpleForm;
                const tempComp = workshopComponent.props.components;
                const rowComponentUpdated = updateComponentsForTypeRow(tempComp, workshopComponent?.props?.flexTextField);
                workshopComponent.props.components = rowComponentUpdated;
                setUpdateFlag(!updateFlag);
            }
        }
    }, [workshopComponent?.props?.flexTextField, workshopComponent?.props['single-continuous']]);

    useEffect(() => {
        if ((workshopComponent?.component === 'SELECT-COMMPLETED-TEMPLATE' || workshopComponent?.component === 'SELECT-COMPLETED')
            && workshopComponent?.id === componentProps?.id) {
            const workShopList = [];
            buildSettingsTreePage(workShopList, workshopComponent);
            const rowComp = workShopList.find((val) => val.type === 'Row');
            rowComp.props.components.length = 2;
        }
    }, [workshopComponent?.component]);

    useEffect(() => {
        const pageDomain = component[domain];
        if (dirtyDomain) {
            return;
        }
        if (pageDomain && Object.keys(pageDomain).length > 0 && componentProps.props.pageChild && componentProps[domain]) {
            const currentComp = componentProps;
            let findCompIndex = -1;
            findCompIndex = Array.isArray(component.parent) && component.parent?.findIndex((c) => c.id === componentProps.id);
            if (currentComp && !component.id && findCompIndex !== -1) {
                currentComp[domain] = pageDomain;
                currentComp.props = component[domain];
                component.props = component[domain];
                component.parent[findCompIndex][domain] = component[domain];
                component.parent[findCompIndex].props = component.props;
            } else if (component.id) {
                currentComp[domain] = pageDomain;
                currentComp.props = component[domain];
            }
        }
    }, [domain, component, id, dirtyDomain]);

    useEffect(() => {
        if (inWorkshop && userSettingsViewChanges) {
            userSettingsValues.forEach((el) => updateSettingsAccess(el));
            setUserSettingsValues([]);
            setUserSettingsViewChanges(false);
        }
    }, [userSettingsViewChanges]);

    useEffect(() => {
        if (component?.name === 'Page' || component?.parent === 'Page') {
            ProtocolProps.display.forEach((item) => {
                if (item.type === 'Select') {
                    item.default = 'stacked';
                }
            });
            component.props.layout = 'stacked';
        }
    }, [component]);

    useEffect(() => {
        if (!hyperlinkCip) {
            let nodeTextConcat = '';
            const requiredChildNodes = protocolTitleRef?.current?.childNodes;
            if (requiredChildNodes) {
                requiredChildNodes.forEach((childNode) => {
                    nodeTextConcat += childNode.textContent;
                });
                if (nodeTextConcat !== title) {
                    setProtocolTitle({
                        __html: title,
                    });
                }
            }
        }
    }, [title]);

    useEffect(() => {
        if ((component.props.isProgression || componentProps.isProgression) && progressionAccCollapse) {
            setCollapseProgressionComp([]);
        }
    }, [progressionAccCollapse]);

    useEffect(() => {
        setProtocolTitle({
            __html: isHyperlink ? (openHyperlinkType === 'newTab'
                ? `<a href=${componentProps?.props?.hyperlink?.[0]} class=${classes.anchorTag} target='_blank'>${componentProps?.props?.title}</a>`
                : `<span class=${classes.anchorTag}>${componentProps?.props?.title}</span>`) : componentProps?.props?.title,
        });
    }, [componentProps?.props?.hyperlink]);

    useEffect(() => {
        if (workshopComponent?.id === componentProps.id
            && Object.prototype.hasOwnProperty.call(componentProps, 'supergroup')
            && Object.prototype.hasOwnProperty.call(componentProps.props, 'pcRelationship')
            && workshopComponent) {
            const flatCompList = [];
            buildSettingsTreePage(flatCompList, workshopComponent);
            flatCompList.forEach((c) => {
                if (c.type === 'Column' || c.type === 'Row') {
                    c.props.pcRelationship = componentProps.props.pcRelationship;
                }
                if (componentProps.props.pcRelationship !== 'manual') delete c.props.highlightComponent;
            });
            if (componentProps.props.pcRelationship !== 'manual') {
                const flatOptionData = [];
                flatOptions(flatOptionData, workshopComponent);
                flatOptionData.forEach((item) => {
                    if ('highlightComponent' in item) delete item.highlightComponent;
                });
            }
            if (componentProps.props.pcRelationship !== 'manual') {
                setUniversalCompState({});
                setUniversalScndState({});
            }
            setUpdateFlag(!updateFlag);
            setWorkshopComponent(workshopComponent);
        }
    }, [componentProps.props.pcRelationship]);

    const renderTitleEditor = () => makeInlineEditor({
        field: TitleProp,
        component,
        setComponentState,
        classes,
        config: componentProps,
        domain,
        setDirtyDomain,
        compHideFromWorkshop: hideFromWorkshop,
    });

    const handleProtocolAccClick = (e) => {
        if (isOpenProtocol && cdClickType) {
            setCdClickType('');
        }
        if (openHyperlinkType === 'newTab') {
            e.stopPropagation();
        }
        const isProgression = componentProps.isProgression ? componentProps.isProgression : component.props.isProgression;
        if (workshopComponent === null && !isProgression) {
            let findCompIndex = -1;
            findCompIndex = collapseProtocol.findIndex((c) => c.id === componentProps.id);

            if (findCompIndex !== -1 && !collapseProtocol[0]?.dbClick) setCollapseProtocol([]);
            else if (findCompIndex !== -1 && collapseProtocol[0]?.dbClick) return;
            else if (findCompIndex === -1) setCollapseProtocol([componentProps]);
            if (findCompIndex === -1 && isHyperlink) {
                setCDComponent(componentProps);
            } else if (findCompIndex !== -1) {
                setCDComponent(null);
            }
            setShowComponent(!showComponent);
        } else if (workshopComponent === null && isProgression) {
            if (collapseProgressionComp.find((c) => c.id === componentProps.id)) {
                setCollapseProgressionComp([]);
            } else {
                let location = [];
                const collapseProgression = [];
                for (let columnIterator = 0; columnIterator < selectedTimeline.columns.length; columnIterator += 1) {
                    location = getLocationOfTargetComponent(component, columnIterator, true, id);

                    if (location && location.length > 0) {
                        break;
                    }
                }

                selectedTimeline.columns.forEach((column) => {
                    if (!column?.props?.isProfile) {
                        let targetProgressionElement = column.props.progressions[location[0]];
                        if (targetProgressionElement) {
                            for (let iterator = 1; iterator < location.length; iterator += 1) {
                                targetProgressionElement = targetProgressionElement.props.components[location[iterator]];
                            }
                        }

                        if (targetProgressionElement && targetProgressionElement.props) {
                            collapseProgression.push(targetProgressionElement);
                        }
                    }
                });

                if (collapseProgression.length > 0) setCollapseProgressionComp(collapseProgression);
            }
            if ((collapseProgressionComp.findIndex((c) => c.id === componentProps.id) === -1)) {
                if (isHyperlink) setCDComponent(componentProps);
            } else if (collapseProgressionComp.findIndex((c) => c.id === componentProps.id) !== -1) {
                setCDComponent(null);
            }
        }
    };

    const isProgressionCompShow = (component.props.isProgression || componentProps.isProgression)
        && (!!collapseProgressionComp.find((c) => (c.id === componentProps.id)));

    const handleCDClick = (e) => {
        setCDComponent(componentProps);
        setCdClickType('image');
    };

    const findH1Childs = (protocols, parentComps) => {
        for (let i = 0; i < parentComps.length; i += 1) {
            if (parentComps[i]?.type === 'Protocol') {
                protocols.push(parentComps[i]);
            }
        }
    };

    const handleTextInput = () => {
        const protocols = [];
        findH1Childs(protocols, componentParent.components);
        const objComp = {
            components: [...protocols],
            headers: [],
        };
        setCurrentProtocol(componentProps);
        setCurrentProtocolParents(objComp);
        if (isHyperlink && openHyperlinkType !== 'newTab') {
            setCDComponent(componentProps);
            setCdClickType('hyperlink');
        } else {
            setCDComponent(null);
            setCdClickType('');
        }
    };

    const handleYoutubeClick = () => {
        setCDComponent(componentProps);
        setCdClickType('video');
    };

    const renderTitle = () => (
        <div className={classes.protocalTitleCont}>
            {component?.props?.group
                ? (showComponent || (!timelinePreviewFlag && workshopComponent !== null))
                    ? <ExpandLess classes={{ root: classes.iconStyle }} /> : <ExpandMore classes={{ root: classes.iconStyle }} />
                : null}
            <Typography
                color="inherit"
                className={[isSelected ? classes.selectedComp : null,
                    isProgression ? classes.titleStyleProgress : classes.titleStyle, !inWorkshop && classes.titleStyleProgress,
                    direction === 'column' ? classes.stackTitleView : classes.defaultTitleView].join(' , ')}
                dangerouslySetInnerHTML={showTitle ? protocolTitle : null}
                ref={protocolTitleRef}
                onClick={() => handleTextInput()}
            />
            {(component?.props?.videoHyperlink) && (
                <YouTube
                    onClick={() => handleYoutubeClick()}
                    style={{ cursor: 'pointer' }}
                />
            )}
            {component?.props?.hasContentDisplay ? (
                <div className={classes.tooltipClass}>
                    <Image className={classes.contentDisplayIcon} onClick={() => handleCDClick()} />
                    <Typography>Content Display</Typography>
                </div>
            )
                : null}
        </div>
    );

    let styleProp = { width: '100%' };

    if (componentHeight && !inWorkshop) {
        styleProp = { width: '100%', height: `${componentHeight}px` };
    }

    // hide column on timeline column in workshop view
    let timelinePreviewFlag = false;

    //  [Selected protocol component] Toggle column component on timeline column in workshop view
    if (workshopComponent && !inWorkshop && showTitleView && workshopComponent.id === id && componentProps?.supergroup && !isProgression) {
        timelinePreviewFlag = true;
    }

    // [Non-Selected protocol component]Selected protocol component's Hide all column component of on timeline column in workshop view
    if (workshopComponent && !inWorkshop && workshopComponent.id !== id && componentProps?.supergroup && !isProgression) {
        timelinePreviewFlag = true;
    }

    const getSortedChildrenData = (childrenData) => {
        const childrenDataIndexArray = [];
        for (const key in childrenData) {
            if (childrenData?.[key]?.children) {
                childrenDataIndexArray[childrenData?.[key]?.index] = {
                    title: childrenData?.[key]?.title,
                    children: getSortedChildrenData(childrenData?.[key]?.children),
                };
            } else {
                childrenDataIndexArray[childrenData?.[key]?.index] = {
                    title: childrenData?.[key]?.title,
                };
            }
        }
        return (childrenDataIndexArray);
    };

    const handleDoubleClick = () => {
        if (isUniversalManual) {
            const flatCompList = [];
            buildSettingsTreePage(flatCompList, workshopActiveComponent);
            flatCompList.forEach((fc) => {
                delete fc.props.highlightComponent;
            });
            const flatOptionData = [];
            flatOptions(flatOptionData, workshopComponent);
            flatOptionData.forEach((item) => {
                if ('highlightComponent' in item) delete item.highlightComponent;
            });
        }
        setUniversalCompState({});
        setUniversalScndState({});
    };

    const renderTable = () => {
        if (selectedChildren?.['selected_children']?.length === 0) {
            return null;
        }
        const protocolTableData = selectedChildren?.['selected_children']?.find((sc) => sc.protocol_id === componentProps.id);
        const childrenData = protocolTableData?.['children_data'] || [];
        let sortedChildrenData = {};
        let leftSortedChildrenData = {};
        let rightSortedChildrenData = {};
        if (component?.props?.leftRightColumn) {
            for (const key in childrenData) {
                if (childrenData?.[key]?.leftRight === 'left') {
                    leftSortedChildrenData[key] = childrenData[key];
                } else {
                    rightSortedChildrenData[key] = childrenData[key];
                }
            }
            leftSortedChildrenData = getSortedChildrenData(leftSortedChildrenData);
            rightSortedChildrenData = getSortedChildrenData(rightSortedChildrenData);
        } else {
            sortedChildrenData = getSortedChildrenData(childrenData);
        }

        if (!protocolTableData) return null;

        const componentList = [];
        buildSettingsTreePage(componentList, componentProps);
        let findColumnComp = componentList.filter((c) => (c.type === 'Column'
            && c.props.cfType === 'Select and Suggest' && !c.props?.hideFromWorkshop));
        if (componentProps?.props?.leftRightColumn) {
            findColumnComp = findColumnComp.filter((c) => c?.props?.LRProps?.LRtype);
        }

        return (
            <>
                {componentProps?.props?.leftRightColumn
                    ? (
                        <Grid container className={classes.cfPrevCont}>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={3}>
                                        <Typography className={classes.protocalTitleText}>{componentProps?.props?.title?.toUpperCase()}</Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Grid container>
                                            <Grid item xs={6} className={classes.borderLeftSide}>
                                                <Grid item xs={12}>
                                                    <Typography className={classes.cfPrevLeftRightCont}>Left</Typography>
                                                    <Grid container>
                                                        <Grid item xs={6} className={classes.cfPrevLRHeader}>
                                                            <Typography>
                                                                {findColumnComp[0]?.props?.title}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6} className={classes.cfPrevLR2ndHeader}>
                                                            <Typography>
                                                                {findColumnComp[1]?.props?.title}
                                                            </Typography>
                                                        </Grid>
                                                        {Object.entries(leftSortedChildrenData).map((item) => (
                                                            <Grid container key={item.name}>
                                                                <Grid item xs={6} className={classes.cfPrevLRParentCont}>
                                                                    <Typography
                                                                        style={{
                                                                            maxHeight: item[1].children?.length < 1
                                                                                ? 40 : (item[1]?.children?.filter((item) => item)?.length * 40),
                                                                        }}
                                                                    >
                                                                        {item[1].title}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={6}
                                                                    className={classes.cfPrevLRChidCont}
                                                                    style={{
                                                                        borderLeft: item[1].children?.length < 1 ? '.25px solid #cbcbcb' : 'none',
                                                                        borderBottom: item[1].children?.length < 1 ? '.25px solid #cbcbcb' : 'none',
                                                                    }}
                                                                >
                                                                    {Object.entries(item[1].children).map((child) => (
                                                                        <Typography>{child[1].title}</Typography>
                                                                    ))}
                                                                </Grid>
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={6} className={classes.borderLeftSide}>
                                                <Grid item xs={12}>
                                                    <Typography className={classes.cfPrevLeftRightCont}>Right</Typography>
                                                    <Grid container>
                                                        <Grid item xs={6} className={classes.cfPrevLRHeader}>
                                                            <Typography>
                                                                {findColumnComp[0]?.props?.title}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6} className={classes.cfPrevLR2ndHeader}>
                                                            <Typography>
                                                                {findColumnComp[1]?.props?.title}
                                                            </Typography>
                                                        </Grid>
                                                        {Object.entries(rightSortedChildrenData).map((item) => (
                                                            <Grid container key={item.name}>
                                                                <Grid item xs={6} className={classes.cfPrevLRParentCont}>
                                                                    <Typography
                                                                        style={{
                                                                            maxHeight: item[1].children?.length < 1
                                                                                ? 40 : (item[1]?.children?.filter((item) => item)?.length * 40),
                                                                        }}
                                                                    >
                                                                        {item[1].title}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={6}
                                                                    className={classes.cfPrevLRChidCont}
                                                                    style={{
                                                                        borderLeft: item[1].children?.length < 1 ? '.25px solid #cbcbcb' : 'none',
                                                                        borderBottom: item[1].children?.length < 1 ? '.25px solid #cbcbcb' : 'none',
                                                                    }}
                                                                >
                                                                    {Object.entries(item[1].children).map((child) => (
                                                                        <Typography>{child[1].title}</Typography>
                                                                    ))}
                                                                </Grid>
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                    : (
                        <Grid container className={classes.cfPrevCont}>
                            <Grid item xs={12}>
                                {isOpenProtocol ? (
                                    <Grid container>
                                        <Grid item xs={3} onClick={(e) => handleProtocolAccClick(e)}>
                                            <Typography className={classes.protocalTitleText}>
                                                {componentProps?.props?.title?.toUpperCase()}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Grid container>
                                                <Grid item xs={6} className={classes.cfPrevHeader}>
                                                    <Typography>
                                                        {findColumnComp[0]?.props?.title}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} className={classes.cfPrevHeader}>
                                                    <Typography>
                                                        {findColumnComp[1]?.props?.title}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ) : null}
                            </Grid>
                            <Grid item xs={12}>
                                {isOpenProtocol ? Object.entries(sortedChildrenData).map((item) => (
                                    <Grid container key={item.name}>
                                        <Grid item xs={3} />
                                        <Grid item xs={9} className={classes.cfPrevTopHeader}>
                                            <Grid container>
                                                <Grid item xs={6} className={classes.cfPrevParentCont}>
                                                    <Typography
                                                        style={{
                                                            height: item[1].children?.length < 1
                                                                ? 40 : (item[1]?.children?.filter((item) => item)?.length * 40),
                                                        }}
                                                    >
                                                        {item[1].title}
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    className={classes.cfPrevChidCont}
                                                    style={{ borderLeft: item[1].children?.length < 1 ? '.25px solid #cbcbcb' : 'none' }}
                                                >
                                                    {Object.entries(item[1].children).map((child) => (
                                                        <Typography>{child[1].title}</Typography>
                                                    ))}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )) : null}
                            </Grid>
                        </Grid>
                    )}
            </>
        );
    };

    const isOpenProtocol = !componentProps?.isProgression && !component?.props?.isProgression
        && collapseProtocol?.length > 0 && collapseProtocol?.find((c) => c.id === componentProps?.id);
    // eslint-disable-next-line consistent-return
    return (
        <>
            {(!hideFromWorkshop) && !workshopComponent && componentProps.props.cfType === 'Select and Suggest' && renderTable()}
            <div style={styleProp} className={!hideFromWorkshop && !inWorkshop && classes.extraBorderCls}>
                <Grid container>
                    <Grid item xs={12}>
                        <Grid
                            container
                            className={direction === 'column' ? classes.block : undefined}
                            direction={isMobile ? 'column' : direction}
                            onClick={(e) => handleProtocolAccClick(e)}
                        >
                            {
                                (inWorkshop && ((workshopComponent?.action?.relation === 'insert-content-display')
                                    || (workshopComponent?.action?.relation === 'edit-content-display')))
                                    || (workshopComponent?.isProgression
                                        && ((actionRelation?.relation === 'insert-content-display')
                                            || (actionRelation?.relation === 'edit-content-display')))
                                    ? null
                                    : (
                                        <Grid
                                            item
                                            xs={isMobile ? 12 : (component.props.layout === 'stacked' ? 12 : 3)}
                                            style={{
                                                borderTop: (!hideFromWorkshop && columns?.length === 0) ? '.25px solid #cbcbcb' : 'none',
                                                borderRight: (!showComponent || (showComponent && isCF)) ? '.25px solid #cbcbcb' : 'none',
                                                borderBottom: (inWorkshop && !component?.props?.LRProps) ? '.25px solid rgb(203, 203, 203)' : 'none',
                                                display: (hideFromWorkshop && inWorkshop) && 'none',
                                            }}
                                            className={[hideFromWorkshop ? classes.protocolTitleHidden : classes.protocolTitleVisible,
                                                (!inWorkshop && component.props['wow feature'] === 'yes')
                                                    ? component.props.isCompleted === 'completed'
                                                        ? classes.greenBg : component.props.isCompleted === 'inComplete'
                                                            ? classes.redBg : '' : ''].join(' , ')}
                                        >
                                            {
                                                ((inWorkshop && !compareModal)
                                                    && (
                                                        <div
                                                            aria-hidden="true"
                                                            className={[(isSelected ? classes.selectedComp : null),
                                                                (!showTitle ? classes.hideText : null)].join(' ')}
                                                            id={isSelected ? 'selectedCompId' : ''}
                                                            onClick={(event) => {
                                                                event.stopPropagation();
                                                                setActiveComponent(setSettingComponentHandle, componentProps);
                                                            }}
                                                            onDoubleClick={() => handleDoubleClick({})}
                                                        >
                                                            {renderTitleEditor()}
                                                        </div>
                                                    )
                                                )
                                            }
                                            {(hideFromWorkshop || !inWorkshop || compareModal) && renderTitle()}
                                        </Grid>
                                    )
                            }
                            <Grid
                                item
                                xs={isMobile ? 12 : ((((component.props.layout === 'stacked')
                                    || hideFromWorkshop || (inWorkshop && (workshopComponent?.action?.relation === 'insert-content-display')))
                                    || (workshopComponent?.isProgression
                                        && ((actionRelation?.relation === 'insert-content-display')
                                            || (actionRelation?.relation === 'edit-content-display')))) ? 12 : 9)}
                                className={[timelinePreviewFlag
                                    && classes.borderLeftCls, hideFromWorkshop ? classes.hideCls : classes.column2].join(' , ')}
                            >
                                <Grid container>
                                    {((!timelinePreviewFlag && (workshopComponent !== null || templateLibraryCip))
                                        || isOpenProtocol
                                        || (!component?.props?.group && !inWorkshop)
                                        || isProgressionCompShow)
                                        ? (columns && columns.map((column, index) => (
                                            componentParent?.components?.[componentIndex]?.supergroup === 'Complex Forms'
                                                ? (
                                                    <Grid className={(component?.props?.leftRightColumn && isFullLR) ? classes.coulmnContainerWrapper : classes.coulmnContainerWrapperTime}>
                                                        <Grid className={(component?.props?.leftRightColumn && isFullLR) ? classes.coulmnContainerInnerWrapper : null}>
                                                            <Column
                                                                key={column.id}
                                                                column={column}
                                                                parent={componentProps}
                                                                index={index}
                                                                classes={classes}
                                                                totalColumns={columns.length}
                                                                layout={component.props.layout}
                                                                flexTextField={componentProps?.props?.flexTextField}
                                                                isSimpleForm={componentProps?.props?.isSimpleForm}
                                                                inProtocol
                                                                componentParent2={componentParent}
                                                                componentIndex={componentIndex}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                ) : (
                                                    <Column
                                                        key={column.id}
                                                        column={column}
                                                        parent={componentProps}
                                                        index={index}
                                                        classes={classes}
                                                        totalColumns={columns.length}
                                                        layout={component.props.layout}
                                                        flexTextField={componentProps?.props?.flexTextField}
                                                        isSimpleForm={componentProps?.props?.isSimpleForm}
                                                        inProtocol
                                                        componentParent2={componentParent}
                                                        componentIndex={componentIndex}
                                                    />
                                                )))) : null}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

const ProtocolPreview = ({ component }) => {
    const { props } = component;
    const { handleCompPreviewClick, settingComponent } = useContext(TimelineContext);
    const {
        components: columns, title,
    } = props;
    const classes = useRowStyles();

    let isSelected = false;

    if (settingComponent && settingComponent.id === component.id) {
        isSelected = true;
    }

    const direction = props.layout === 'stacked' ? 'column' : 'row';

    return (
        <div className={classes.fullWidth}>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container direction={direction}>
                        {!props.hideFromWorkshop && (
                            <Grid
                                item
                                xs={props.layout === 'stacked' ? 12 : 3}
                                className={`${classes.column} 
                                ${component?.props?.group === 'Form Components'
                                        || (component?.props?.group === 'Column' && component?.name === 'Page')
                                        || (component?.isCustom && component?.isProgression)
                                    ? classes.formPreviewBorder : component?.props?.group === 'Page Groups' ? classes.pageViewBorder : ''}`}
                            >
                                <Typography
                                    variant="body1"
                                    color="inherit"
                                    onClick={(e) => handleCompPreviewClick(e, component)}
                                    className={[classes.protocalPrevTitle, isSelected ? classes.selectedComp : null].join(' , ')}
                                    id={isSelected ? 'selectedCompId' : ''}
                                >
                                    {title}
                                </Typography>
                            </Grid>
                        )}
                        {columns.map((column, index) => (
                            <ColumnPreview
                                key={column.id}
                                column={column}
                                parent={component}
                                index={index}
                                classes={classes}
                            />
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

const ProtocolEditor = ({ config, updateComponent }) => {
    const isTimelineURL = useRouteMatch('/:orgId/timeline');
    const [component, setComponentState] = useRecoilState(componentWithId(config.id));

    const {
        actionRelation, settingMenuType, workshopSelectedTemplate, workshopComponent, selectedComponent,
    } = useContext(TimelineContext);
    const { selectedTemplate } = useContext(WorkshopContext);
    const filterImage = (displayConfig) => {
        const hasImage = (config?.name === 'Images/Videos');
        const hasCF = (config?.supergroup === 'Complex Forms');

        if (!hasImage) {
            displayConfig = displayConfig.filter((obj) => obj?.type !== 'exportURL');
        }
        if (selectedComponent?.isProgression) {
            displayConfig = displayConfig.filter((ele) => ele?.key !== 'hyperlink');
        }
        if (hasImage) {
            displayConfig = displayConfig.filter((ele) => ele?.key !== 'pcRelationship');
        }
        return displayConfig;
    };

    const protocolEditorProps = { display: filterImage(FilterSettings(ProtocolProps, settingMenuType, 'Protocol')), poi: ProtocolProps.poi };
    let template = selectedTemplate;

    let initialConfig = config;
    if (!isTimelineURL && workshopSelectedTemplate) {
        initialConfig = { name: workshopSelectedTemplate?.supergroup };
        template = { name: workshopSelectedTemplate?.name };
    }

    if (actionRelation?.type === 'edit') {
        initialConfig = { name: workshopComponent?.supergroup };
        template = { name: workshopComponent?.component };
    }
    setComponentType(protocolEditorProps, initialConfig, template);

    return (
        <Settings editorProps={protocolEditorProps} component={component} setComponentState={setComponentState} saveComponent={updateComponent} />
    );
};

const ProtocolEditMenu = ({
    component, componentLibrary, parent, copyProtocolComponent,
}) => {
    const clickedArea = component.clickedArea || ((component.isProgression || parent.isProgression) ? 'Progression' : 'Protocol');
    const filter = (item) => (item.id === 'cl-112' || item.type === 'Protocol' || item.type === 'ProtocolGroup');
    const labelMaker = (label) => (`Add ${label}`);
    const add = buildComponentMenu(componentLibrary, filter, labelMaker, 'sibling', component);
    // const cDChildren = {
    //     1: { label: 'Add Page Content', action: { type: 'addHyperLink' } },
    //     2: { label: 'Edit Page Content', action: { type: 'editHyperLink' } },
    //     3: { label: 'Remove Page Content', action: { type: 'removeHyperLink' } },
    //     4: { label: 'Add Video Content', action: { type: 'addVideoLink' } },
    //     5: { label: 'Edit Video Content', action: { type: 'editVideoLink' } },
    //     6: { label: 'Remove Video Content', action: { type: 'removeVideoLink' } },
    //     7: { label: 'Add Content Display', action: { type: 'addContentDisplay' } },
    //     8: { label: 'Edit Content Display', action: { type: 'editContentDisplay' } },
    //     9: { label: 'Remove Content Display', action: { type: 'removeContentDisplay' } },
    // };
    let menu = [
        ...add,
        MenuDefaults.EDIT_PROTOCOL(component),
        MenuDefaults.DELETE,
        MenuDefaults.TIMELINE_USER_VIEW,
    ];
    if (clickedArea === 'Protocol') {
        // MenuDefaults.CONTENT_DISPLAY.children = [];
        // MenuDefaults.CONTENT_DISPLAY.children = [...MenuDefaults.CONTENT_DISPLAY?.children, cDChildren[1], cDChildren[4], cDChildren[7]];
        menu = [...menu, MenuDefaults.COPY, MenuDefaults.CUT, MenuDefaults.CONTENT_DISPLAY];
    }
    // else if (clickedArea === 'Progression') {
    //     MenuDefaults.CONTENT_DISPLAY.children = [];
    //     MenuDefaults.CONTENT_DISPLAY.children = [...MenuDefaults.CONTENT_DISPLAY?.children, cDChildren[7]];
    //     menu = [...menu, MenuDefaults.CONTENT_DISPLAY];
    // }
    if (copyProtocolComponent && clickedArea === 'Protocol') {
        menu = [...menu, MenuDefaults.PASTE];
    }
    const isHyperlink = !!(Array.isArray(component?.props?.hyperlink) && component?.props?.hyperlink.find((c) => c !== ''));
    // const subChildMenu = menu.find((c) => c.action.type === 'addContent').children;

    if (isHyperlink && clickedArea === 'Protocol' && !component.isProgression) {
        const splIndex = menu?.findIndex((c) => c.action.type === 'addHyperLink');
        if (!Number.isNaN(splIndex) && splIndex !== -1) {
            menu.splice(splIndex, 1, MenuDefaults.EDIT_CONTENT_DISPLAY, MenuDefaults.REMOVE_CONTENT_DISPLAY);
        }
    }

    // if (component.props.videoHyperlink && clickedArea === 'Protocol' && !component.isProgression) {
    //     const splIndex = subChildMenu?.findIndex((c) => c.action.type === 'addVideoLink');
    //     if (!Number.isNaN(splIndex) && splIndex !== -1) {
    //         subChildMenu.splice(splIndex, 1, cDChildren[5], cDChildren[6]);
    //     }
    // }

    // if (component?.props?.hasContentDisplay) {
    //     const splIndex = subChildMenu?.findIndex((c) => c.action.type === 'addContentDisplay');
    //     if (!Number.isNaN(splIndex) && splIndex !== -1) {
    //         subChildMenu.splice(splIndex, 1, cDChildren[8], cDChildren[9]);
    //     }
    // }

    if (clickedArea === 'Progression') {
        delete menu[1].children[4];
    }

    if (clickedArea === 'Protocol') {
        delete menu[1].children[3];
    }
    return menu;
};

export {
    Protocol, ProtocolEditMenu, ProtocolEditor, ProtocolPreview,
};
