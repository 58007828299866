import { Grid, Typography } from '@material-ui/core';
import { ProcedureLibrary } from '../../initialConfig';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';

const useRowStyles = makeStyles(() => ({
    textCenter: {
        textAlign: 'center',
        border: '1px solid #cbcbcb',
        height: 28,
    },
    selectedUserCont: {
        textAlign: 'center',
        border: '1px solid #cbcbcb',
        borderLeft: 'none',
        height: 28,
    },
    textCenter1: {
        textAlign: 'center',
        border: '1px solid #cbcbcb',
        height: 24,
        background: '#d8d8d8',
    },
    textCenter2: {
        textAlign: 'center',
        border: '1px solid #cbcbcb',
        borderTop: 'none',
        height: 24,
    },
    textLeft1: {
        textAlign: 'left',
        border: '1px solid #cbcbcb',
        height: 24,
        paddingLeft: 4,
        background: '#d8d8d8',
    },
    textLeft: {
        textAlign: 'left',
        border: '1px solid #cbcbcb',
        borderRight: 'none',
        borderTop: 'none',
        height: 24,
        paddingLeft: 4,
    },
    checkBoxStyle: {
        '& svg': {
            height: 15,
            width: 15,
            color: '#000000',
        },
        padding: 4,
    },
    headingMain: {

        fontSize: 16,
    },
    headingPOI: {

        fontSize: 14,
    },
    poiText: {
        fontSize: 12,
    },
}));

const POIAccess = ({
    library, onSelectionChange, selected, selectUser, hideFirstLevel, template,
}) => {
    const classes = useRowStyles();
    const [previousSelectChild, setPreviousSelectedChild] = useState(null);

    useEffect(() => {
        if (previousSelectChild) {
            onSelectionChange(previousSelectChild, false, false);
        }
    }, [template]);

    useEffect(() => {
        let isPresent = false;
        // eslint-disable-next-line no-unused-vars
        for (const i of library.children) {
            // eslint-disable-next-line no-unused-vars
            for (const j of i.children) {
                if (j?.title === filteredPOI?.[0]?.title) {
                    setPreviousSelectedChild(j);
                    onSelectionChange(j, true, true);
                    isPresent = true;
                    break;
                }
            }
            if (isPresent) {
                break;
            }
        }
    }, [template]);

    const handleChange = (event, level) => {
        setPreviousSelectedChild(level);
        onSelectionChange(level, event.target.checked, true);
    };
    const allPoi = _.flatMapDeep(ProcedureLibrary.children, (item) => [item, ...item.children]);
    let filteredPOI = allPoi.filter((item) => item?.id === template?.poi);

    return (
        <Grid container>
            <Grid item xs={6}>
                <Grid container>
                    <Grid item xs={12} className={classes.textCenter}>
                        <Typography className={classes.headingMain}>All POI</Typography>
                    </Grid>
                    {library.children.map((item) => (
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12} className={classes.textLeft1}>
                                    <Typography className={classes.headingPOI}>{item.title}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    {
                                        item.children.map((child) => (
                                            <div className={classes.textLeft}>
                                                <Typography className={classes.poiText}>{child.title}</Typography>
                                            </div>
                                        ))
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <Grid container>
                    <Grid item xs={12} className={classes.selectedUserCont}>
                        <Typography className={classes.headingMain}>{selectUser?.name}</Typography>
                    </Grid>
                    {library.children.map((item) => (
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12} className={classes.textCenter1}>
                                    {
                                        !hideFirstLevel && (
                                            <Checkbox
                                                className={classes.checkBoxStyle}
                                                checked={!!selected[item.id]}
                                                onChange={(e) => handleChange(e, item)}
                                            />
                                        )
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    {
                                        item.children.map((child) => (
                                            <div className={classes.textCenter2}>
                                                <Checkbox
                                                    checked={!!selected[child.id]}
                                                    className={classes.checkBoxStyle}
                                                    onChange={(e) => handleChange(e, child)}
                                                />
                                            </div>
                                        ))
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default POIAccess;
