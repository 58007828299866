import { Settings } from './Settings';
import { WorkshopContext } from '../context';
import { componentWithId } from './Atoms';
import { useRecoilState } from 'recoil';
import React, { useContext } from 'react';
import WorkshopSelectedMedia from './WorkshopSelectedMedia';
import WorkshopUpdateMedia from './WorkshopUpdateMedia';

/**
 * Set the key and type for grid option in workshop
 */
const layoutOption = {
    key: 'layoutOption',
    type: 'Media',
    label: '',
};

/**
 * Define the setting's dropdown value
 */
const MediaSettingProps = {
    display: [
        layoutOption,
    ],
    poi: true,
};

/**
 * This component will return WorkshopSelectedMedia or blank
 * @param {boolean} isWorkshop [boolean]
 * @param {integer} id [Media Compoinent Id]
 * @param {object} imageData [list of images data]
 */
const Media = ({ id, props }) => {
    const { inWorkshop, compareModal } = useContext(WorkshopContext);

    if (!Array.isArray(props.imageData)) {
        return '';
    }

    if (inWorkshop && !compareModal) {
        return (
            <WorkshopUpdateMedia
                props={props}
                isWorkshop
                mediaCompId={id}
                imageList={props.imageData}
                compType="media"
            />
        );
    }
    return (
        <WorkshopSelectedMedia
            props={props}
            isWorkshop={false}
            mediaCompId={id}
            imageList={props.imageData}
            // props={props}
            compType="media"
        />
    );
};

/**
 * This component will return WorkshopSelectedMedia or blank
 * @param {boolean} isWorkshop [boolean]
 * @param {integer} id [Media Compoinent Id]
 * @param {object} imageData [list of images data]
 */
const MediaPreview = ({ id, props }) => {
    if (!Array.isArray(props.imageData)) {
        return '';
    }
    return (
        <WorkshopSelectedMedia
            props={props}
            isWorkshop={false}
            mediaCompId={id}
            imageList={props.imageData}
            // props={props}
            compType="media"
        />
    );
};

/**
 * This component is used to Edit the Media and save data
 * @param {object} config [configuration recieved from props]
 * @param {function} updateComponent [recieved function as props to save the selected media]
 */
const MediaEditor = ({ config, updateComponent }) => {
    const [component, setComponentState] = useRecoilState(componentWithId(config.id));
    return (
        <Settings
            editorProps={MediaSettingProps}
            component={component}
            setComponentState={setComponentState}
            saveComponent={updateComponent}
        />
    );
};

export { Media, MediaEditor, MediaPreview };
