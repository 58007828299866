/* eslint-disable camelcase */
import {
    Button,
    Checkbox,
    Container,
    ExpansionPanelDetails,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    ListSubheader,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Typography,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { OrgContext, TimelineContext } from '../context';
import {
    buildSettingsTreePage,
    findCompByType,
    findComponent,
    findGenericComponent,
    findPage,
    flatComponentByType,
    getWorkShopRow,
} from '../helper/commonHelper';
import { copyTemplateLR } from '../context/util';
import React, {
    Fragment,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';

import { ImageSelector } from './ImageSelector';
import { LRContext } from './Column';
import { componentWithId, updateComponentCallback } from './Atoms';
import { debounce } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { standardHeaders } from './utils/StandardStyles';
import { useRecoilCallback, useRecoilState } from 'recoil';
import CloseIcon from '@material-ui/icons/Close';
import DateFnsUtils from '@date-io/date-fns';
import FroalaEditor from 'react-froala-wysiwyg';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import ListEditor from './ListEditor';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const editorStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(0.5),
        width: '95%',
    },
    formControl1: {
        margin: '0px 8px',
        width: '95%',
    },
    formControl2: {
        paddingLeft: 4,
    },
    horizontalRadioGroup: {
        flexDirection: 'row',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    buttonGroup: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    patientDisableStyle: {
        color: '#000000de',
        background: '#f6f6f6',
    },
    poi: {
        display: 'block',
    },
    expandPanel: {
        padding: '8px 0px 8px',
    },
    expandRoot: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    layoutFormControl: {
        marginLeft: 0,
        marginRight: 0,
        marginTop: 16,
        minWidth: 200,
    },
    titleCaptionInpt: {
        padding: '15.5px 14px',
    },
    titleWrapper: {
        marginBottom: 10,
    },
    column: {
        textAlign: 'left',
        wordWrap: 'break-word',
    },
    columnContMain: {
        border: '1px solid #ececec',
    },
    columnContLeft: {
        padding: '0px 4px',
        textAlign: 'left',
        wordWrap: 'break-word',
        fontSize: '.75rem',
        overflow: 'auto',
        maxHeight: 33,
    },
    columnCont4: {
        padding: '8px',
        wordWrap: 'break-word',
        fontSize: '.75rem',
        textAlign: 'center',
    },
    columnContRight: {
        borderLeft: '2px solid #ececec',
        padding: '2px 4px',
        textAlign: 'left',
        wordWrap: 'break-word',
        fontSize: '.75rem',
        minHeight: 33,
    },
    columnContRight1: {
        textAlign: 'left',
        wordWrap: 'break-word',
    },
    textFieldRender: {
        '& > :first-child': {
            width: '95%',
            margin: 4,
        },
    },
    settingImageComp: {
        display: 'contents',
    },
    ContRight: {
        padding: 0,
    },
    header: standardHeaders.h3,
    dragWrapper: {
        width: '100%',
    },
    dragEle: {
        cursor: 'move',
    },
    templateName: {
        textDecoration: 'underline',
    },
    settingsHeader: standardHeaders.h1,
    groupHeader: {
        border: '1px solid #ececec',
        padding: 4,
        textAlign: 'center',
        fontWeight: 'bold',
    },
    columHeadType: {
        fontSize: '0.75rem',
        opacity: 0.6,
    },
    formGroup: {
        background: '#fff',
    },
    fontSize12: {
        fontSize: '.75rem',
    },
    fontSize10: {
        fontSize: '.625rem',
    },
    checkboxSize: {
        transform: 'scale(.625)',
        padding: 5,
    },
    radiobutton: {
        '& span, & svg': {
            fontSize: 10,
        },
    },
    buttonList: {
        display: 'flex',
        gap: 8,
    },
    numberingStyle: {
        paddingTop: 4,
        '& span': {
            paddingLeft: 1,
        },
    },
    StaticLabelStyle: {
        fontSize: '.75rem',
        paddingLeft: 4,
    },
    exportURLCont: {
        margin: '3px 5px 2px',
        display: 'flex',
    },
    exportURLInputCont: {
        border: '0.800000011920929px solid #CAC9CA',
        borderRadius: 4,
        marginRight: '1.1rem',
        width: '80%',
        padding: '0px 5px',
        '&:before': {
            borderBottom: 'none !important',
        },
    },
    exportURLInput: {
        padding: '5px 0px',
    },
    exportURLButton: {
        color: '#066785',
        padding: '6px 14px',
        fontSize: 12,
        background: '#FFFFFF',
        border: '1px solid #066785',
        borderRadius: 4,
        '&:hover': {
            background: '#066785',
            color: '#FFFFFF',
        },
    },
    checkBoxStyle: {
        '& svg': {
            height: '1rem',
            width: '1rem',
        },
        marginBottom: 4,
    },
    staticLabelSubCont: {
        textAlign: 'center',
        borderLeft: '1px solid #ececec',
        borderRight: '1px solid #ececec',
        padding: 2,
    },
    staticLabelSubCont1: {
        textAlign: 'center',
        border: '1px solid #ececec',
        padding: 2,
    },
    textInputBorder: {
        width: '100% !important',
        padding: '0px 2px',
        '& div': {
            marginLeft: '4px',
            '& button': {
                padding: '0px',
                paddingBottom: '2px',
            },
        },
        '& textarea': {
            textAlign: 'justify',
            padding: '9.1px 0px',
        },
        '&::before': {
            borderBottom: '.5px solid #CBCBCB !important',
        },
        '&::after': {
            borderBottom: '.5px solid #CBCBCB !important',
        },
        '& input': {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
            '&:hover': {
                overflowX: 'auto',
                '&::-webkit-scrollbar': {
                    height: '2.5px',
                    width: '2.5px',
                    display: 'block',
                },
                '&::-webkit-scrollbar-thumb': {
                    background: '#888',
                },
            },
        },
    },
    textInputBorderText: {
        alignItems: 'center !important',
    },
    textInputBorder1: {
        width: '100% !important',
        padding: '0px 2px',
        alignItems: 'baseline',
        '& div': {
            marginLeft: '4px',
            '& button': {
                padding: '0px',
                paddingBottom: '2px',
            },
        },
        '& textarea': {
            textAlign: 'justify',
            padding: '9.1px 0px',
        },
        '&::before': {
            borderBottom: 'none !important',
        },
        '&::after': {
            borderBottom: 'none !important',
        },
        '& input': {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
            '&:hover': {
                overflowX: 'auto',
                '&::-webkit-scrollbar': {
                    height: '2.5px',
                    width: '2.5px',
                    display: 'block',
                },
                '&::-webkit-scrollbar-thumb': {
                    background: '#888',
                },
            },
        },
    },
    selectInputSettings: {
        '& > :first-child': {
            padding: '10px 12px 8px',
            fontSize: 12,
        },
    },
    closeIconStyle: {
        fontSize: '.75rem',
        cursor: 'pointer',
    },
    showError: {
        color: '#DC143C',
        display: 'block',
        padding: '2px 0px 0px 8px',
    },
}));

/**
 * @Variable dragId for save drag id of drag item
 * @Variable editorInstance for save instance of editor
 */
let dragId = '';
let editorInstance = '';

const makeChangeHandler = (
    prop,
    component,
    setComponentState,
    setter,
    domain,
    setDirtyDomain,
    setClearCurrentAssociation,
    inWorkshop,
) => (event, updateTimeline, inputFieldId, patientProfileData, setPatientProfileData) => {
    let newConf = { ...component };
    if (newConf.props === undefined) {
        newConf = { props: {} };
    }
    if (newConf[domain] === undefined) {
        newConf = { ...newConf, [domain]: { [prop]: '' } };
    }
    if (event || event === '') {
        if (domain && (component.type === 'Page'
            || (component.props && component.props.pageChild) || (component.name === 'Page' && component.type !== 'Protocol'))) {
            newConf[domain][prop] = setter(event);
        } else if (prop === 'hideFromWorkshop' && event.target.checked) {
            newConf.props[prop] = setter(event);
            newConf.props.showTitle = false;
        } else if (prop === 'showTitle' && event.target.checked) {
            if (inWorkshop?.component === 'LR Complex Form' || inWorkshop?.component === 'LR Mixed Form'
            || inWorkshop?.component === 'LR- 2CF' || inWorkshop?.component === 'Child Select & Suggest LR CF') {
                const newArr = [];
                buildSettingsTreePage(newArr, inWorkshop);
                newConf.props[prop] = setter(event);
                for (let i = 0, l = newArr.length; i < l; i += 1) {
                    if (newArr[i].type === 'Column' && newArr?.[i]?.mirrorId) {
                        if (component?.props?.LRProps) {
                            newArr[i].props[prop] = setter(event);
                        }
                    }
                }
            } else {
                newConf.props[prop] = setter(event);
            }
            newConf.props.hideFromWorkshop = false;
        } else if (prop === 'listTypePreview') {
            newConf.props[prop] = setter(event);
        } else if (prop === 'single-continuous') {
            newConf.props[prop] = setter(event);
        } else if (prop === 'typeOfTextField') {
            newConf.props[prop] = setter(event);
        } else if (prop === 'hyperlink') {
            newConf.props[prop] = setter(event);
        } else if (prop === 'hyperlink-slideTitle') {
            newConf.props[prop] = setter(event);
        } else if (prop === 'hyperlink-slideCaption') {
            newConf.props[prop] = setter(event);
        } else if (prop === 'hyperlink-imageTitle') {
            newConf.props[prop] = setter(event);
        } else if (prop === 'hyperlink-imageCaption') {
            newConf.props[prop] = setter(event);
        } else if (prop.startsWith('hyperlink-')) {
            const requiredIndex = prop.substring(10, prop.length);
            if (newConf.props.options[requiredIndex].props) {
                newConf.props.options[requiredIndex].props.hyperlink = setter(event);
            } else {
                newConf.props.options[requiredIndex].props = {
                    hyperlink: setter(event),
                };
            }
        } else if (newConf.props.loggedUserType === 'Doctor' && newConf.props.name === 'Form' && prop === 'textField') {
            if (inWorkshop) {
                newConf.props.textField = '';
                newConf.props.textPlaceholder = setter(event);
                newConf.props.label = '';
            } else if (!inWorkshop) {
                newConf.props.textField = setter(event);
                newConf.props.label = setter(event);
            }
        } else if (prop === 'isHighlight' && newConf?.props?.group === 'Complex Form Groups') {
            newConf.props[prop] = setter(event) === 'yes';
        } else if (prop === 'wow feature') {
            newConf.props['wow feature'] = setter(event);
            // eslint-disable-next-line no-return-assign
            newConf.parent.forEach((c) => c.props['wow feature'] = setter(event));
        } else if (prop === 'multiline_textArea') {
            newConf.props.multiline_textArea = setter(event);
            // eslint-disable-next-line no-return-assign
            newConf.parent.forEach((c) => {
                c.props.multiline_textArea = setter(event);
            });
        } else {
            newConf.props[prop] = setter(event);
        }
    }
    setComponentState(newConf);
    if (setDirtyDomain) {
        setDirtyDomain(false);
    }
    if ((prop === 'currentAssociation' || prop === 'highlightedChildren' || prop === 'disabledChildren')
        && setClearCurrentAssociation) {
        setClearCurrentAssociation(false);
    }

    if (inputFieldId) {
        if (inputFieldId === 'patient-first-name') {
            setPatientProfileData({
                profile: { ...patientProfileData?.profile, firstName: event },
            });
        } else if (inputFieldId === 'patient-email') {
            setPatientProfileData({
                profile: { ...patientProfileData?.profile, email: event },
            });
        }
    }

    if (typeof (updateTimeline) === 'function') {
        updateTimeline();
    }
};

/**
 * Handle drag item
 * @param {event} e
 */
const dragStart = (e) => {
    e.stopPropagation();
    dragId = e.target.id;
};

/**
 * @function renderTemplate [render template list item]
 * @param {object} data
 * @returns {component}
 */
const renderTemplate = (data) => (
    <ListItem key={data.id}>
        <ListItemText primary={(
            <div dangerouslySetInnerHTML={{ __html: data.template.html }} />
        )}
        />
    </ListItem>
);

/**
 * @function renderDragElement [render template library]
 * @param {object} component
 * @param {object} field
 * @returns {component}
 */
const renderDragElement = (component, field) => {
    const classes = editorStyles();
    let currentGroup = '';
    let currentTemplateName = '';

    return (
        <List key={1}>
            {field.templates.map((template) => {
                let header = '';
                let templateName = '';

                if (currentGroup !== template.group) {
                    currentGroup = template.group;

                    if (!currentGroup) {
                        currentGroup = 'General';
                    }
                    header = (
                        <ListSubheader disableSticky={false} className={classes.header}>
                            <Typography className={classes.columHeadType} color="inherit">
                                {template.group}
                            </Typography>
                        </ListSubheader>
                    );
                }

                if (currentTemplateName !== template.name) {
                    currentTemplateName = template.name;
                    templateName = currentTemplateName;
                }
                return (
                    <Fragment key={template.id}>
                        {header}
                        <div style={{ textDecoration: 'underline' }}>{templateName}</div>
                        <div onDragStart={dragStart} id={template.id} draggable className={classes.dragEle}>
                            {renderTemplate(template, header)}
                        </div>
                    </Fragment>
                );
            })}
        </List>
    );
};

// eslint-disable-next-line no-unused-vars
const RenderTextInput = (component, field, classes, domainType = null, config = null, componentProps, compHideFromWorkshop = false) => {
    const isLRChild = useContext(LRContext)?.isLRChild;
    const inputRef = useRef();
    const {
        domain, inWorkshop, workshopComponent, updateFlag, setUpdateFlag, clickedArea, selectedColumn, getLocationOfTargetComponent,
        selectedTimeline, selectedComponent, updateTimeline, patientProfileData, setPatientProfileData, setLRUpdate, selectedPsa, selectedDoc,
    } = useContext(TimelineContext);
    const { loggedUserType } = useContext(OrgContext);
    const updateRecoilComponent = useRecoilCallback(updateComponentCallback);
    const fieldValue = component?.props?.[field.key];
    const classes1 = editorStyles();
    const [showCloseIcon, setShowCloseIcon] = useState(false);
    const isPageElem = domain && (component?.type === 'Page'
        || component?.props?.pageChild || (component?.name === 'Page' && component?.type !== 'Protocol'));
    const pageDomain = component?.[domain] || { [field.key]: '' };
    const value = domain && pageDomain && isPageElem ? (pageDomain?.[field.key] || '') : (component?.props?.[field.key] || '');
    const isCFHeader = !!component?.props?.hasOwnProperty('columnType')
        || (component?.props?.isComplexForm && !component?.props?.hasOwnProperty('LRProps'));
    const isCHeader = !!(Array.isArray(component?.parent)
        && component?.parent[0]?.type === 'Column' && component?.parent[0]?.props?.hasOwnProperty('LRProps'));

    if (component?.props?.hasOwnProperty('showTitle') && typeof component?.props?.showTitle === 'boolean') {
        component.props.showTitle = component?.props?.showTitle ? 'yes' : 'no';
    }
    if (component?.props?.hasOwnProperty('showCaption') && typeof component?.props?.showCaption === 'boolean') {
        component.props.showCaption = component?.props?.showCaption ? 'yes' : 'no';
    }
    let isShowTitleTag = component?.props?.hasOwnProperty('showTitle') ? (component?.props?.showTitle === 'yes') : true;
    const isProgression = !!((clickedArea === 'Progression' || (workshopComponent && workshopComponent.isProgression)));
    const compTypes = ['Gallery', 'Image'];
    const isShowEyeVisiblity = compTypes.includes(config?.type);
    let isValidURL = true;
    const ifNoRowComp = useRecoilState(componentWithId(component?.parent?.[0]?.rowRef || selectedComponent?.rowRef));
    let rowComp = getWorkShopRow(workshopComponent?.props?.components);
    if (!rowComp) {
        rowComp = ifNoRowComp?.[0];
    }
    const secondCol = rowComp?.props?.components?.find((c) => c?.props?.index === 1);
    const isNotSimpleForm = secondCol?.props?.components?.some((x) => !x.isTimeline && x?.props?.components?.length > 0);

    const firstCol = rowComp?.props?.components?.find((c) => c?.props?.index === 0);
    const [isMultiline, setIsMultiLine] = useState(false);
    const multilineRows = isMultiline ? 2 : 1;
    useEffect(() => {
        if (firstCol?.props?.multiline_textArea === 'yes') {
            setIsMultiLine(true);
        } else {
            setIsMultiLine(false);
        }
    }, [firstCol?.props?.multiline_textArea]);

    useEffect(() => {
        if ((fieldValue || fieldValue === '') && inputRef.current && fieldValue !== inputRef.current.value) {
            inputRef.current.value = fieldValue;
        }
    }, [fieldValue]);

    useEffect(() => {
        if (component?.props?.['text-input-id'] === 'patient-first-name') {
            setPatientProfileData((pendingState) => ({
                profile: { ...pendingState.profile, firstName: component?.props?.label },
            }));
            inputRef.current.value = (component?.props?.loggedUserType === 'Doctor' ? selectedDoc?.docObj?.name : selectedPsa?.psaObj?.name)
                || component?.props?.label;
        }
    }, []);

    useEffect(() => {
        if (component?.props?.['text-input-id'] === 'patient-email') {
            setPatientProfileData((pendingState) => ({
                profile: { ...pendingState.profile, email: component?.props?.label },
            }));
            inputRef.current.value = (component?.props?.loggedUserType === 'Doctor' ? selectedDoc?.docObj?.email : selectedPsa?.psaObj?.email)
                || component?.props?.label;
        }
    }, []);

    useEffect(() => {
        if (component?.props?.loggedUserType === 'Doctor' && component?.props?.name === 'Form'
            && field?.key === 'textField' && inWorkshop) {
            inputRef.current.placeholder = component?.props?.textPlaceholder;
            inputRef.current.value = '';
        }
    }, [inWorkshop]);

    if (component?.props?.loggedUserType === 'Doctor' && component?.props?.name === 'Form'
    && field?.key === 'textField' && !inWorkshop && inputRef?.current?.value) {
        component.props.textField = inputRef.current.value;
    }

    if (isShowEyeVisiblity && field.key === 'caption') {
        isShowTitleTag = component?.props?.hasOwnProperty('showCaption') ? (component?.props?.showCaption === 'yes') : true;
    }

    const handleGalleryVisiblity = () => {
        if (isProgression) {
            selectedTimeline.columns.forEach((col) => {
                if (!col?.props?.isProfile) {
                    const locationMap = getLocationOfTargetComponent(workshopComponent, selectedColumn);
                    if (locationMap && locationMap.length > 0) {
                        let targetProgressionElement = col.props.progressions[locationMap[0]];
                        // eslint-disable-next-line no-unused-vars
                        let targetClone = targetProgressionElement;
                        if (locationMap.length > 1) {
                            for (let iterator = 1; iterator < locationMap.length; iterator += 1) {
                                targetClone = targetProgressionElement;
                                targetProgressionElement = targetProgressionElement.props.components[locationMap[iterator]];
                            }
                        }

                        const key = (field.key === 'caption') ? 'showCaption' : 'showTitle';
                        const innerComps = findCompByType(targetProgressionElement, field.id);
                        innerComps.forEach((c) => {
                            if (c.props[key] === ('yes' || true)) {
                                c.props[key] = 'no';
                            } else {
                                c.props[key] = 'yes';
                            }
                        });
                    }
                }
            });
        } else {
            const key = (field.key === 'caption') ? 'showCaption' : 'showTitle';
            const innerComps = findCompByType(workshopComponent, field.id);
            innerComps.forEach((c) => {
                c.props[key] = c?.props[key] === 'yes' ? 'no' : 'yes';
            });
        }
        setUpdateFlag(!updateFlag);
    };

    const handleClose = () => {
        if (isCHeader && !isCFHeader) {
            const arr2 = [];
            buildSettingsTreePage(arr2, workshopComponent);
            const filterArr2 = arr2.filter((a) => a.type === 'Column');
            const filterRowArr = arr2.filter((a) => a.type === 'Row');
            filterRowArr.forEach((row, i) => {
                if (i > 0) {
                    const col = filterArr2.filter((a) => a.parent === row.id);
                    col.forEach((c) => {
                        c.props.showTitle = c.props.showTitle === undefined ? 'no' : (c.props.showTitle === 'yes' ? 'no' : 'yes');
                    });
                }
            });
            setUpdateFlag(!updateFlag);
        } else if (isCFHeader && !isCHeader) {
            component.parent.forEach((c) => {
                c.props.showTitle = c.props.showTitle === undefined ? 'no' : (c.props.showTitle === 'yes' ? 'no' : 'yes');
                updateRecoilComponent(c.id, c.props);
            });
            setUpdateFlag(!updateFlag);
        } else if (isShowEyeVisiblity) {
            handleGalleryVisiblity();
        }
    };
    const endAdornment = !field.disableClear && (
        <InputAdornment position="end">
            {(
                workshopComponent
                    ? (
                        <IconButton
                            style={{ visibility: showCloseIcon ? 'visible' : 'hidden' }}
                            aria-label="delete"
                            className={classes.margin}
                            onClick={() => {
                                if ((!isCFHeader && !isCHeader && !isShowEyeVisiblity) || (field.inSettings)) {
                                    field.onChange({ target: { value: '' } });
                                } else {
                                    handleClose();
                                }
                            }}
                        >
                            {((!isCFHeader && !isCHeader && !isShowEyeVisiblity) || (field.inSettings))
                                ? <CloseIcon style={{ fontSize: '.9rem', cursor: 'pointer' }} />
                                : (((isCFHeader || isCHeader) || isShowEyeVisiblity) && (isShowTitleTag
                                    ? <Visibility style={{ fontSize: '.8rem', cursor: 'pointer' }} />
                                    : <VisibilityOff style={{ fontSize: '.8rem', cursor: 'pointer' }} />
                                ))}
                        </IconButton>
                    )
                    : (
                        <IconButton aria-label="delete" className={classes.margin} onClick={() => { field.onChange({ target: { value: '' } }); }}>
                            <CloseIcon style={{ fontSize: '.75rem', cursor: 'pointer' }} />
                        </IconButton>
                    )
            )}
        </InputAdornment>
    );

    const labelMultiline = field.key === 'label' && isMultiline;

    const multiline = field.key === 'caption' || labelMultiline || config?.type === 'Protocol' && workshopComponent?.id === config?.id;
    const progressionFontSize = null;

    let extraProps = field.onSubmit ? {
        autoFocus: component?.props?.focused,
        style: { fontSize: field.label === 'Header' ? 13 : 10 },
        onKeyPress: (ev) => {
            if (ev.key === 'Enter') {
                ev.preventDefault();
                field.onSubmit(component);
            }
        },

    } : {
        style: { fontSize: field.label === 'Header' ? 13 : progressionFontSize || 10, width: component?.props?.isLR || inWorkshop && 'inherit' },
    };
    if (isLRChild && inWorkshop) {
        extraProps = {
            ...extraProps,
            onBlur: (ev) => {
                field.onChange(ev);
            },
            onChange: (ev) => {
                if (field.setComponentState) {
                    field.setComponentState((comp) => ({ ...comp, currentValue: ev.target.value }));
                }
            },

        };
    } else if (isLRChild) {
        extraProps = {
            ...extraProps,
            onChange: (field?.isNotDebounce)
                ? (event) => { field.onChange(event); } : (event) => {
                    textFieldChnageHandler(event?.target?.id, event?.target?.value, field?.onChange);
                },
        };
    }

    const hideFromWorkshop = (inWorkshop && Object.prototype.hasOwnProperty.call(component?.props, 'hideFromWorkshop')
        && component?.props?.hideFromWorkshop) || false;

    let hyperlinkValue = '';
    if (field.key.includes('hyperlink')) {
        if (selectedComponent?.type === 'Select' && selectedComponent?.props?.renderAs === 'radio') {
            const requiredIndex = field.key.substring(10, field.key.length);
            hyperlinkValue = selectedComponent?.props?.options?.[requiredIndex]?.props?.hyperlink;
        } else if (selectedComponent?.type === 'Gallery') {
            if (field?.key === 'hyperlink-slideTitle') {
                hyperlinkValue = selectedComponent?.props?.['hyperlink-slideTitle'];
            } else if (field?.key === 'hyperlink-slideCaption') {
                hyperlinkValue = selectedComponent?.props?.['hyperlink-slideCaption'];
            }
        } else if (selectedComponent?.type === 'Image') {
            if (field?.key === 'hyperlink-imageTitle') {
                hyperlinkValue = selectedComponent?.props?.['hyperlink-imageTitle'];
            } else if (field?.key === 'hyperlink-imageCaption') {
                hyperlinkValue = selectedComponent?.props?.['hyperlink-imageCaption'];
            }
        } else {
            hyperlinkValue = selectedComponent?.props?.hyperlink;
        }
        const pattern = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
        if (hyperlinkValue && pattern.test(hyperlinkValue)) {
            isValidURL = true;
        } else if (hyperlinkValue) {
            isValidURL = false;
        }
    }

    const textFieldChnageHandler = debounce((inputFieldId, txt, func) => {
        if (component?.props?.loggedUserType === 'Doctor' && (inputFieldId === 'patient-first-name' || inputFieldId === 'patient-email')) {
            func(txt, updateTimeline, inputFieldId, patientProfileData, setPatientProfileData);
        } else {
            if (isLRChild) {
                const updateObj = {
                    id: component?.props?.selectId,
                    label: txt,
                };
                setLRUpdate(updateObj);
            }
            func(txt, updateTimeline);
        }
    }, 3000);

    let inputHandlers = {
        onChange: field?.hasOwnProperty('isNotDebounce') ? (
            field?.isNotDebounce ? field?.onChange : (event) => {
                textFieldChnageHandler(event?.target?.id, event?.target?.value, field?.onChange);
            }
        ) : field.onChange,
        defaultValue: field.key.includes('hyperlink')
            ? hyperlinkValue : (field.key === 'textPlaceholder'
                && !Object.prototype.hasOwnProperty.call(component?.props, 'textPlaceholder') ? 'Text Input' : value),
    };

    if (isLRChild) {
        inputHandlers = {
            defaultValue: field.key === 'textPlaceholder'
                && !Object.prototype.hasOwnProperty.call(component?.props, 'textPlaceholder') ? 'Text Input' : value,
        };
    }

    const headerType = component?.props?.type;

    const textAreaCheck = (component?.props?.label || component?.parent?.[0]?.type === 'TextInput');
    const marginVal = textAreaCheck ? '-7px' : '';

    if (compHideFromWorkshop && !inWorkshop) return null;
    let requiredIndex;
    const listTypePreview = componentProps?.props?.listTypePreview;
    if (listTypePreview === 'number') {
        requiredIndex = component.props.position - 1;
    }
    const startAdornment = listTypePreview === 'number' ? (
        <div className={classes1.numberingStyle}>
            {requiredIndex}
            <span>.</span>
        </div>
    ) : (listTypePreview === 'bullet' ? (
        <p>&bull;</p>
    ) : null);

    const readOnlyWhenPsa = !inWorkshop && selectedPsa?.psaId && (component?.props?.['text-input-id'] === 'patient-first-name'
    || component?.props?.['text-input-id'] === 'patient-email');

    const readOnlyNew = (!inWorkshop && (component?.props?.['read-only'] === 'yes'));

    const isDisableForPatient = ((component?.props?.['text-input-id'] === 'patient-first-name'
        || component?.props?.['text-input-id'] === 'patient-middle-name'
        || component?.props?.['text-input-id'] === 'patient-email'
        || component?.props?.['text-input-id'] === 'patient-last-name') && loggedUserType === 'Patient');
    const check = ((config?.type === 'Protocol' && workshopComponent?.id === config?.id) || (isNotSimpleForm !== undefined && !isNotSimpleForm));
    return (
        <>
            {!hideFromWorkshop && (
                <FormControl
                    key={field.key}
                    variant="outlined"
                    className={component?.props?.isLR
                        ? classes.formControl2 : (component?.props?.label || component?.parent?.[0]?.type === 'TextInput')
                            ? classes.formControlText : workshopComponent?.props?.leftRightColumn
                                ? classes.formControl1 : (workshopComponent?.supergroup === 'Header' || headerType === 'h1'
                                    || headerType === 'h2' || headerType === 'h3' || headerType === 'h4')
                                    ? classes.headerFormControl : classes.formControl}
                    style={{
                        background:
                            headerType === 'h1' ? '#6d6d6d'
                                : headerType === 'h2' ? '#b1b1b1'
                                    : headerType === 'h3' ? '#efefef'
                                        : headerType === 'h4' ? '#FFFFFF'
                                            : null,
                        paddingLeft: (headerType === 'h1' || headerType === 'h2' || headerType === 'h3' || headerType === 'h4') && 4,
                        width: component?.props?.isLR && '100%',
                    }}
                >
                    <Input
                        inputProps={{
                            ref: inputRef,
                            style: {
                                marginTop: marginVal,
                                padding: textAreaCheck && '7px 0px 0px 0px',
                                textAlign: field?.label === 'Slide Title' || field?.label === 'Slide Caption'
                                    || field?.label === 'Image Title' || field?.label === 'Image Caption' ? 'center' : 'left',
                                fontWeight: readOnlyNew && 700,
                                fontSize: field?.inSettings && 12,
                            },
                            readOnly: readOnlyNew || readOnlyWhenPsa,
                        }}
                        type="text"
                        startAdornment={!inWorkshop && startAdornment}
                        endAdornment={inWorkshop && endAdornment}
                        placeholder={!component?.props?.[field.key] ? (component?.props?.textPlaceholder || field.label) : undefined}
                        multiline={multiline}
                        minRows={config?.type === 'Protocol' && workshopComponent?.id === config?.id ? 1 : multilineRows}
                        maxRows={check ? 20 : multilineRows}
                        {...extraProps}
                        {...inputHandlers}
                        disabled={field.isDisable || isDisableForPatient || (workshopComponent?.type !== 'Timeline'
                            && inWorkshop && Object.prototype.hasOwnProperty.call(component?.props, 'showTitle')
                            && component?.props?.showTitle !== 'yes')
                            || (loggedUserType === 'User' && component?.props?.typeOfTextField !== 'user')}
                        classes={{
                            input: component?.props?.isLR
                                ? classes.inputLRStyle : (component?.props?.label || component?.parent?.[0]?.type === 'TextInput' && !inWorkshop)
                                    ? classes.textInputStyle : null,
                            underline: (inWorkshop && component?.parent?.[0]?.type === 'TextInput')
                                ? `${classes1.textInputBorder1} ${classes1.textInputBorderText}`
                                : inWorkshop ? classes1.textInputBorder : classes1.textInputBorder1,
                            disabled: isDisableForPatient ? classes1.patientDisableStyle : null,
                        }}
                        onMouseEnter={() => setShowCloseIcon(true)}
                        onMouseLeave={() => setShowCloseIcon(false)}
                        id={component?.props?.['text-input-id'] || `${component?.props?.selectId}-input-field`}
                    />
                </FormControl>
            )}
            {!isValidURL && <small className={classes.showError}>Invalid URL</small>}
        </>
    );
};

const RenderTextInputHyperlink = (props) => {
    const {
        component,
        field,
        classes,
        config = null,
        compHideFromWorkshop = false,
    } = props;
    const isLRChild = useContext(LRContext)?.isLRChild;
    const inputRef = useRef();
    const {
        domain, inWorkshop, workshopComponent, updateFlag, setUpdateFlag, clickedArea, selectedColumn, getLocationOfTargetComponent,
        selectedTimeline, selectedComponent,
    } = useContext(TimelineContext);
    const updateRecoilComponent = useRecoilCallback(updateComponentCallback);
    const fieldValue = component?.props?.[field.key];
    const classes1 = editorStyles();
    const [showCloseIcon, setShowCloseIcon] = useState(false);
    const isPageElem = domain && (component?.type === 'Page'
        || component?.props?.pageChild || (component?.name === 'Page' && component?.type !== 'Protocol'));
    const pageDomain = component?.[domain] || { [field.key]: '' };
    const value = domain && pageDomain && isPageElem ? (pageDomain?.[field.key] || '') : (component?.props?.[field.key] || '');
    const isCFHeader = !!component?.props?.hasOwnProperty('columnType')
        || (component?.props?.isComplexForm && !component?.props?.hasOwnProperty('LRProps'));
    const isCHeader = !!(Array.isArray(component?.parent)
        && component?.parent[0]?.type === 'Column' && component?.parent[0]?.props?.hasOwnProperty('LRProps'));
    let isShowTitleTag = component?.props?.hasOwnProperty('showTitle') ? (component?.props?.showTitle === 'yes') : true;
    const isProgression = !!((clickedArea === 'Progression' || (workshopComponent && workshopComponent.isProgression)));
    const compTypes = ['Gallery', 'Image'];
    const isShowEyeVisiblity = compTypes.includes(config?.type);
    let isValidURL = true;

    useEffect(() => {
        if ((fieldValue || fieldValue === '') && inputRef.current && fieldValue !== inputRef.current.value) {
            inputRef.current.value = fieldValue;
        }
    }, [fieldValue]);

    if (isShowEyeVisiblity && field.key === 'caption') {
        isShowTitleTag = component?.props?.hasOwnProperty('showCaption') ? (component?.props?.showCaption === 'yes') : true;
    }

    const handleGalleryVisiblity = () => {
        if (isProgression) {
            selectedTimeline.columns.forEach((col) => {
                if (!col?.props?.isProfile) {
                    const locationMap = getLocationOfTargetComponent(workshopComponent, selectedColumn);
                    if (locationMap && locationMap.length > 0) {
                        let targetProgressionElement = col.props.progressions[locationMap[0]];
                        // eslint-disable-next-line no-unused-vars
                        let targetClone = targetProgressionElement;
                        if (locationMap.length > 1) {
                            for (let iterator = 1; iterator < locationMap.length; iterator += 1) {
                                targetClone = targetProgressionElement;
                                targetProgressionElement = targetProgressionElement.props.components[locationMap[iterator]];
                            }
                        }

                        const key = (field.key === 'caption') ? 'showCaption' : 'showTitle';
                        const innerComps = findCompByType(targetProgressionElement, field.id);
                        innerComps.forEach((c) => {
                            c.props[key] = !c?.props[key];
                        });
                    }
                }
            });
        } else {
            const key = (field.key === 'caption') ? 'showCaption' : 'showTitle';
            const innerComps = findCompByType(workshopComponent, field.id);
            innerComps.forEach((c) => {
                c.props[key] = !c?.props[key];
            });
        }
        setUpdateFlag(!updateFlag);
    };

    const handleClose = () => {
        if (isCHeader && !isCFHeader) {
            const arr2 = [];
            buildSettingsTreePage(arr2, workshopComponent);
            const filterArr2 = arr2.filter((a) => a.type === 'Column');
            const filterRowArr = arr2.filter((a) => a.type === 'Row');
            filterRowArr.forEach((row, i) => {
                if (i > 0) {
                    const col = filterArr2.filter((a) => a.parent === row.id);
                    col.forEach((c) => {
                        c.props.showTitle = c.props.showTitle === undefined ? false : !c.props.showTitle;
                    });
                }
            });
            setUpdateFlag(!updateFlag);
        } else if (isCFHeader && !isCHeader) {
            component.parent.forEach((c) => {
                c.props.showTitle = c.props.showTitle === undefined ? false : !c.props.showTitle;
                updateRecoilComponent(c.id, c.props);
            });
            setUpdateFlag(!updateFlag);
        } else if (isShowEyeVisiblity) {
            handleGalleryVisiblity();
        }
    };

    const endAdornment = !field.disableClear && (
        <InputAdornment position="end">
            {(
                workshopComponent
                    ? (
                        <IconButton
                            style={{ visibility: showCloseIcon ? 'visible' : 'hidden' }}
                            aria-label="delete"
                            className={classes?.margin}
                            onClick={() => {
                                if ((!isCFHeader && !isCHeader && !isShowEyeVisiblity) || (field.inSettings)) {
                                    field.onChange('');
                                } else {
                                    handleClose();
                                }
                            }}
                        >
                            {((!isCFHeader && !isCHeader && !isShowEyeVisiblity) || (field.inSettings))
                                ? <CloseIcon style={{ fontSize: '.9rem', cursor: 'pointer' }} />
                                : (((isCFHeader || isCHeader) || isShowEyeVisiblity) && (isShowTitleTag
                                    ? <Visibility style={{ fontSize: '.8rem', cursor: 'pointer' }} />
                                    : <VisibilityOff style={{ fontSize: '.8rem', cursor: 'pointer' }} />
                                ))}
                        </IconButton>
                    )
                    : (
                        <IconButton aria-label="delete" className={classes.margin} onClick={() => { field.onChange(''); }}>
                            <CloseIcon style={{ fontSize: '.75rem', cursor: 'pointer' }} />
                        </IconButton>
                    )
            )}
        </InputAdornment>
    );
    const ifNoRowComp = useRecoilState(componentWithId(component?.parent?.[0]?.rowRef || selectedComponent?.rowRef));

    let rowComp = getWorkShopRow(workshopComponent?.props?.components);
    if (!rowComp) {
        rowComp = ifNoRowComp?.[0];
    }
    const firstCol = rowComp?.props?.components?.find((c) => c?.props?.index === 0);
    const [isMultiline, setIsMultiLine] = useState(false);
    useEffect(() => {
        if (firstCol?.props?.multiline_textArea === 'yes') {
            setIsMultiLine(true);
        } else {
            setIsMultiLine(false);
        }
    }, [firstCol?.props?.multiline_textArea]);

    const labelMultiline = field.key === 'label' && isMultiline;

    const multiline = field.key === 'caption' || labelMultiline || config?.type === 'Protocol' && workshopComponent?.id === config?.id;
    const progressionFontSize = null;

    let extraProps = field.onSubmit ? {
        autoFocus: component?.props?.focused,
        style: { fontSize: field.label === 'Header' ? 13 : 10 },
        onKeyPress: (ev) => {
            if (ev.key === 'Enter') {
                ev.preventDefault();
                field.onSubmit(component);
            }
        },

    } : {
        style: { fontSize: field.label === 'Header' ? 13 : progressionFontSize || 10, width: component?.props?.isLR || inWorkshop && 'inherit' },
    };
    if (isLRChild) {
        extraProps = {
            ...extraProps,
            onBlur: (ev) => {
                field.onChange(ev);
            },
            onChange: (ev) => {
                if (field.setComponentState) {
                    field.setComponentState((comp) => ({ ...comp, currentValue: ev.target.value }));
                }
            },
        };
    }

    const hideFromWorkshop = (inWorkshop && component?.props && Object.prototype.hasOwnProperty.call(component?.props, 'hideFromWorkshop')
        && component?.props?.hideFromWorkshop) || false;

    let hyperlinkValue = '';
    if (field?.key?.includes('hyperlink')) {
        if (selectedComponent?.type === 'Select' && selectedComponent?.props?.renderAs === 'radio') {
            const requiredIndex = field.key.substring(10, field.key.length);
            hyperlinkValue = selectedComponent?.props?.options?.[requiredIndex]?.props?.hyperlink;
        } else if (selectedComponent?.type === 'Gallery') {
            if (field?.key === 'hyperlink-slideTitle') {
                hyperlinkValue = selectedComponent?.props?.['hyperlink-slideTitle'];
            } else if (field?.key === 'hyperlink-slideCaption') {
                hyperlinkValue = selectedComponent?.props?.['hyperlink-slideCaption'];
            }
        } else if (selectedComponent?.type === 'Image') {
            if (field?.key === 'hyperlink-imageTitle') {
                hyperlinkValue = selectedComponent?.props?.['hyperlink-imageTitle'];
            } else if (field?.key === 'hyperlink-imageCaption') {
                hyperlinkValue = selectedComponent?.props?.['hyperlink-imageCaption'];
            }
        } else {
            hyperlinkValue = selectedComponent?.props?.hyperlink;
        }
        const pattern = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
        if (hyperlinkValue && pattern.test(hyperlinkValue)) {
            isValidURL = true;
        } else if (hyperlinkValue) {
            isValidURL = false;
        }
    }
    const textFieldChnageHandler = debounce((txt, func) => {
        func(txt);
    }, 1000);

    let inputHandlers = {
        onChange: (event) => {
            textFieldChnageHandler(event.target.value, field?.onChange);
        },
        defaultValue: field?.key?.includes('hyperlink')
            ? hyperlinkValue : (field.key === 'textPlaceholder'
                && !Object.prototype.hasOwnProperty.call(component?.props, 'textPlaceholder') ? 'Text Input' : value),
    };

    if (isLRChild) {
        inputHandlers = {
            defaultValue: field.key === 'textPlaceholder'
                && !Object.prototype.hasOwnProperty.call(component?.props, 'textPlaceholder') ? 'Text Input' : value,
        };
    }

    const headerType = component?.props?.type;
    const readOnlyNew = (!inWorkshop && (component?.props?.['read-only'] === 'yes' || component?.props?.['read-only']));

    if (compHideFromWorkshop && !inWorkshop) return null;

    return (
        <>
            {!hideFromWorkshop && (
                <FormControl
                    key={field.key}
                    variant="outlined"
                    className={component?.props?.isLR
                        ? classes?.formControl2 : (component?.props?.label || component?.parent?.[0]?.type === 'TextInput')
                            ? classes?.formControlText : workshopComponent?.props?.leftRightColumn
                                ? classes?.formControl1 : (workshopComponent?.supergroup === 'Header' || headerType === 'h1'
                                    || headerType === 'h2' || headerType === 'h3' || headerType === 'h4')
                                    ? classes?.headerFormControl : classes?.formControl}
                    style={{
                        background:
                            headerType === 'h1' ? '#6d6d6d'
                                : headerType === 'h2' ? '#b1b1b1'
                                    : headerType === 'h3' ? '#efefef'
                                        : headerType === 'h4' ? '#FFFFFF'
                                            : null,
                        paddingLeft: (headerType === 'h1' || headerType === 'h2' || headerType === 'h3' || headerType === 'h4') && 4,
                        width: '100%',
                    }}
                >
                    <Input
                        inputProps={{
                            ref: inputRef,
                            style: {
                                textAlign: field?.label === 'Slide Title' || field?.label === 'Slide Caption'
                                    || field?.label === 'Image Title' || field?.label === 'Image Caption' ? 'center' : 'left',
                                fontWeight: readOnlyNew && 700,
                                fontSize: field?.inSettings && 12,
                                padding: '2px 0px !important',
                                minHeight: inWorkshop ? isMultiline ? 52 : 37 : 24,
                            },
                        }}
                        type="text"
                        endAdornment={inWorkshop && endAdornment}
                        placeholder={!component?.props?.[field.key] ? (component?.props?.textPlaceholder || field.label) : undefined}
                        multiline={multiline}
                        minRows={config?.type === 'Protocol' && workshopComponent?.id === config?.id ? 1 : 2}
                        maxRows={config?.type === 'Protocol' && workshopComponent?.id === config?.id ? 20 : 2}
                        {...extraProps}
                        {...inputHandlers}
                        disabled={workshopComponent?.type !== 'Timeline'
                            && inWorkshop && component?.props && Object.prototype.hasOwnProperty.call(component?.props, 'showTitle')
                                && component?.props?.showTitle !== 'yes'}
                        classes={{
                            input: component?.props?.isLR
                                ? classes?.inputLRStyle : (component?.props?.label || component?.parent?.[0]?.type === 'TextInput' && !inWorkshop)
                                    ? classes?.textInputStyle : null,
                            underline: inWorkshop ? classes1.textInputBorder : classes1.textInputBorder1,
                        }}
                        onMouseEnter={() => setShowCloseIcon(true)}
                        onMouseLeave={() => setShowCloseIcon(false)}
                    />
                </FormControl>
            )}
            {!isValidURL && <small className={classes?.showError}>Invalid URL</small>}
        </>
    );
};

const RenderExportURL = (component, field) => {
    const classes1 = editorStyles();
    const { selectedComponent, selectedTimeline } = useContext(TimelineContext);
    let protocolId = null;

    if (selectedComponent && selectedComponent.id) {
        protocolId = selectedComponent.id;
    }

    const handleUrlClick = () => {
        const copyVal = `${field.value}?timeline=${selectedTimeline.id}&slide=${protocolId}`;
        navigator.clipboard.writeText(copyVal)
            .then(() => {
                // eslint-disable-next-line no-alert
                alert('Export URL is copied.');
            })
            .catch((err) => {
                // eslint-disable-next-line no-alert
                alert('Could not copy text: ', err);
            });
    };

    return (
        <div className={classes1.exportURLCont}>
            <Input
                classes={{
                    root: classes1.exportURLInputCont,
                    input: classes1.exportURLInput,
                }}
                type="text"
                value={field.value}
                id="exportURL"
                disabled
            />
            <button
                className={classes1.exportURLButton}
                onClick={handleUrlClick}
                type="button"
            >
                Copy
            </button>
        </div>
    );
};

const renderGroupSeparator = (component, field) => (
    <div>
        {field.label}
    </div>
);

const RenderCheckInput = (component, field, classes) => {
    const { domain, workshopComponent } = useContext(TimelineContext);
    const [checkBoxValue, setCheckBoxValue] = useState(false);
    useEffect(() => {
        const isPageElem = domain
            && (component.type === 'Page' || component.props.pageChild || (component.name === 'Page' && component.type !== 'Protocol'));
        const pageDomain = component[domain] || { [field.key]: '' };
        if (pageDomain && isPageElem && component?.[domain]?.[field.key] !== undefined) {
            setCheckBoxValue(component[domain][field.key]);
        } else if (component.props[field.key] !== undefined) {
            setCheckBoxValue(component.props[field.key]);
        } else {
            setCheckBoxValue(field.default);
        }
    }, [domain, component, field]);
    useEffect(() => {
        if (field.key === 'isHighlight') {
            const arr = [];
            buildSettingsTreePage(arr, workshopComponent);
            arr.forEach((ele) => {
                if (ele.type === 'Select' || ele.type === 'MultiSelect') {
                    ele.props.isHighlight = !!workshopComponent.props.isHighlight;
                }
            });
        }
    }, [field]);

    if (!component?.props?.['form-control'] || component?.props?.['form-control'] === 'no') {
        delete component?.props?.isCBChecked;
    }

    if (workshopComponent?.supergroup !== 'Complex Forms' && field.key === 'isHighlight') return null;

    return (
        <FormControlLabel
            key={field.key}
            label={field.secondaryLabel || field.label}
            className={classes.formControl2}
            classes={{
                label: classes.fontSize10,
            }}
            control={<Checkbox name={field.key} classes={{ root: classes.checkboxSize }} checked={checkBoxValue} onChange={field.onChange} />}
        />
    );
};

const RenderSelectInput = (component, field, classes) => {
    const { domain } = useContext(TimelineContext);
    const isPageElem = domain
        && (component?.type === 'Page' || component?.props?.pageChild || (component?.name === 'Page' && component?.type !== 'Protocol'));
    const pageDomain = component?.[domain] || { [field.key]: '' };
    return (
        <FormControl key={field.key} variant="filled" className={classes.formControl1}>
            <Select
                value={domain && pageDomain && isPageElem
                    ? (component?.[domain]?.[field.key] || field.default) : (component?.props?.[field.key] || field.default)}
                onChange={field.onChange}
                className={field?.inSettings ? classes.selectInputSettings : classes.fontSize10}
            >
                {field.options && field.options.map((option, index) => (
                    <MenuItem
                        key={index}
                        style={{ fontSize: '.625rem' }}
                        value={option.value}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

const RenderMultiSelectInput = (component, field, classes) => {
    const { domain } = useContext(TimelineContext);
    const isPageElem = domain
        && (component?.type === 'Page' || component?.props.pageChild || (component?.name === 'Page' && component?.type !== 'Protocol'));
    const pageDomain = component?.[domain] || { [field.key]: '' };
    const disabledItems = {};
    const handleChange = (e) => {
        if (component && component.props && component.props.currentAssociation) {
            const parentKey = component.props.currentAssociation;
            const parentKeyValue = component.props.association[parentKey];
            component.props.association = {
                ...component.props.association,
                [parentKey]: {
                    ...parentKeyValue, [field.key]: e.target.value,
                },
            };
        }
        field.onChange(e);
    };

    if (field.key === 'disabledChildren' || field.key === 'highlightedChildren') {
        const otherKey = field.key === 'disabledChildren' ? 'highlightedChildren' : 'disabledChildren';
        field.options.forEach((option) => {
            const otherKeyValue = component?.props?.[otherKey];
            const exists = otherKeyValue && otherKeyValue.find((item) => item === `${option.value}#${option.requiredId}`);
            if (exists) {
                disabledItems[`${option.value}#${option.requiredId}`] = true;
            }
        });
    }

    return (
        <FormControl key={field.key} variant="filled" className={classes.formControl1}>
            <InputLabel style={{ fontSize: 12 }}>{field.label}</InputLabel>
            <Select
                value={domain && pageDomain && isPageElem
                    ? (component?.[domain]?.[field.key] || field.default) : (component?.props?.[field.key] || field.default)}
                onChange={handleChange}
                multiple
            >
                {field.options && field.options.map((option, index) => (
                    <MenuItem
                        disabled={disabledItems[`${option.value}#${option.requiredId}`]}
                        key={index}
                        value={`${option.value}#${option.requiredId}`}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

const toggleLRButton = (component, buttons) => {
    const leftIndex = buttons.findIndex((b) => b.type === 'left');
    const rightIndex = buttons.findIndex((b) => b.type === 'right');
    if (component.props.columnType === 'left') { // hide right button
        buttons[leftIndex].show = true;
        buttons[rightIndex].show = false;
    } else if (component.props.columnType === 'right') { // hide left column
        buttons[leftIndex].show = false;
        buttons[rightIndex].show = true;
    }
    return buttons;
};

const RenderButtonList = (component, field, classes) => {
    let { buttons } = field;

    if (Object.prototype.hasOwnProperty.call(component?.props, 'columnType')) { // For LR Column
        buttons = toggleLRButton(component, buttons);
    }

    return (
        <div className={classes.buttonList}>
            {buttons.filter((button) => button.show).map((button, index) => (
                <Button
                    key={index}
                    style={{ borderColor: button.color === 'error' ? 'red' : null, color: button.color === 'error' ? 'red' : null }}
                    size="small"
                    variant="outlined"
                    color={button.color}
                    onClick={button.onClick}
                >
                    {button.label}
                </Button>
            ))}
        </div>
    );
};
const RenderRadioInput = (component, field, classes) => {
    const { domain, workshopComponent } = useContext(TimelineContext);
    const isPageElem = domain
        && (component?.type === 'Page' || component?.props.pageChild || (component?.name === 'Page' && component?.type !== 'Protocol'));
    const pageDomain = component?.[domain] || { [field.key]: '' };
    let fieldValue = domain && pageDomain && isPageElem
        ? (component?.[domain]?.[field.key] || field.default) : (component?.props?.[field.key] || field.default);
    if (field.key === 'isHighlight') {
        fieldValue = component?.props?.[field.key] === true ? 'yes' : 'no';
    }

    if (field.key === 'componentsRow') {
        const componentChildren = [];

        buildSettingsTreePage(componentChildren, component);

        const rowComponent = componentChildren.find((i) => i?.type === 'Row')?.props?.components;

        if (rowComponent !== undefined) {
            const column1stComp = rowComponent[0]?.props?.components;

            const column2ndComp = rowComponent[1]?.props?.components[0]?.props?.components;

            const column3rdComp = rowComponent[2]?.props?.components[0]?.props?.components;

            if (((column1stComp?.length === 0 || column2ndComp?.length === 0)
                && (rowComponent[1]?.props?.title === '' || !rowComponent[1]?.props?.title))) {
                fieldValue = '1';
            } else if ((column2ndComp?.length > 0 && (column3rdComp?.length === 0 || !column3rdComp) && rowComponent[2]?.props?.title === '')
                || (rowComponent[1]?.props?.title !== '' && (rowComponent[2]?.props?.title === '' || !rowComponent[2]?.props?.title))) {
                fieldValue = '2';
            } else if (column3rdComp?.length > 0 || rowComponent[2]?.props?.title !== '') {
                fieldValue = '3';
            }
        }
    }

    // useEffect(() => {
    //     if (field.key === 'isHighlight') {
    const arr = [];
    buildSettingsTreePage(arr, field.key === 'isHighlight' ? workshopComponent : {});
    arr.forEach((ele) => {
        if (ele.type === 'Select' || ele.type === 'MultiSelect') {
            ele.props.isHighlight = !!workshopComponent.props.isHighlight;
        }
    });
    //     }
    // }, [field]);

    return (
        <FormControl key={field.key} variant="filled" className={classes.formControl1}>
            <RadioGroup
                value={fieldValue}
                onChange={field.onChange}
                style={{ flexDirection: field?.inSettings ? 'row' : 'column' }}
            >
                {field.options && field.options.map((option, index) => (
                    <FormControlLabel
                        classes={{ label: classes.fontSize10 }}
                        control={<Radio className={classes.radiobutton} />}
                        key={index}
                        value={option.value}
                        label={option.label}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
};

const RenderLabel = (component, field, classes) => {
    const { domain, inWorkshop } = useContext(TimelineContext);
    const classes1 = editorStyles();
    const isPageElem = domain
        && (component?.type === 'Page' || component?.props.pageChild || (component?.name === 'Page' && component?.type !== 'Protocol'));
    const pageDomain = component?.[domain] || { [field.key]: '' };
    const [showCloseIcon, setShowCloseIcon] = useState(false);

    const extraProps = field.onSubmit && {
        autoFocus: component?.props?.focused,
        style: { fontSize: field.label === 'Header' ? 13 : 10 },
        onKeyPress: (ev) => {
            if (ev.key === 'Enter') {
                ev.preventDefault();
                field.onSubmit(component);
            }
        },
    };

    const endAdornment = !field.disableClear && (
        <InputAdornment position="end">
            <IconButton aria-label="delete" className={classes.margin} onClick={() => { field.onChange({ target: { value: '' } }); }}>
                <CloseIcon className={classes1.closeIconStyle} />
            </IconButton>
        </InputAdornment>
    );

    return (
        <FormControl key={field.key} variant="outlined" className={classes.formControl}>
            <Input
                type="text"
                classes={{ input: classes1.fontSize10, underline: inWorkshop ? classes1.textInputBorder : classes1.textInputBorder1 }}
                placeholder={field.label}
                value={domain && pageDomain && isPageElem ? (component?.[domain]?.label || '') : component?.props?.label}
                onChange={field.onChange}
                {...extraProps}
                endAdornment={showCloseIcon && inWorkshop && endAdornment}
                onMouseEnter={() => setShowCloseIcon(true)}
                onMouseLeave={() => setShowCloseIcon(false)}
            />
        </FormControl>
    );
};

const RenderStaticLabel = (component, field, classes) => {
    const {
        domain, isDMRC, workshopComponent, templateLibraryByType, selectedComponent,
    } = useContext(TimelineContext);
    const templateLibrary = templateLibraryByType[workshopComponent?.type];
    const isPageElem = domain
        && (component.type === 'Page' || component.props.pageChild || (component.name === 'Page' && component.type !== 'Protocol'));
    const pageDomain = component[domain] || { [field.key]: '' };
    const value = domain && pageDomain && isPageElem ? (pageDomain[field.key] || '') : (component.props[field.key] || '');
    const selectCompTypes = ['Select', 'Checkbox', 'MultiSelect', 'TextBoxCF', 'TextAreaCF'];
    if (field.key === 'componentTypes') {
        if (selectCompTypes.includes(selectedComponent?.type)) {
            if (selectedComponent?.type === 'Select' && selectedComponent?.props?.renderAs === 'select') {
                field.value = 'N/A';
            } else if (selectedComponent?.type === 'MultiSelect') {
                field.value = 'Multi Select- Generic';
            } else if (selectedComponent?.type === 'Checkbox') {
                field.value = 'Checkbox-MultiSelect';
            } else if (selectedComponent?.type === 'TextBoxCF') {
                field.value = 'Textbox';
            } else if (selectedComponent?.type === 'TextAreaCF') {
                field.value = 'Textarea';
            } else if (selectedComponent?.type === 'Select' && selectedComponent?.props?.renderAs === 'radio') {
                field.value = 'Radio Group- Generic';
            } else if (selectedComponent?.type === 'Select' && selectedComponent?.props?.renderAs === 'Checkbox') {
                field.value = 'Checkbox Group- Generic';
            } else if (selectedComponent?.type === 'Select' && selectedComponent?.props?.renderAs === 'Singleselect') {
                field.value = 'Single Select- Generic';
            }
        } else {
            const [genericComponent = null] = findGenericComponent(workshopComponent, templateLibrary) || [];
            field.value = genericComponent?.name || 'N/A';
        }
    }
    return (
        [(
            <Grid item xs={12} key={0}>
                {Array.isArray(field.value) ? field.value.map((val, i) => (
                    <Grid container key={i}>
                        <Grid item xs={isDMRC ? 6 : 12} className={isDMRC ? classes.staticLabelSubCont1 : 'none'}>
                            <FormControl key={field.key} variant="outlined" className={`${classes.formControl} ${classes.StaticLabelStyle}`}>
                                {val}
                            </FormControl>
                        </Grid>
                        {isDMRC
                            && (
                                <>
                                    <Grid xs={3} className={classes.staticLabelSubCont1}>
                                        <FormControl>
                                            <Checkbox className={classes.checkBoxStyle} />
                                        </FormControl>
                                    </Grid>
                                    <Grid xs={3} className={classes.staticLabelSubCont1}>
                                        <FormControl>
                                            <Checkbox className={classes.checkBoxStyle} />
                                        </FormControl>
                                    </Grid>
                                </>
                            )}
                    </Grid>
                )) : (
                    <Grid container>
                        <Grid item xs={isDMRC ? 6 : 12} className={isDMRC ? classes.staticLabelSubCont : 'none'}>
                            <FormControl key={field.key} variant="outlined" className={`${classes.formControl} ${classes.StaticLabelStyle}`}>
                                {field.value || value}
                            </FormControl>
                        </Grid>
                        {isDMRC
                            && (
                                <>
                                    <Grid xs={3} className={classes.staticLabelSubCont}>
                                        <FormControl>
                                            <Checkbox className={classes.checkBoxStyle} />
                                        </FormControl>
                                    </Grid>
                                    <Grid xs={3} className={classes.staticLabelSubCont}>
                                        <FormControl>
                                            <Checkbox className={classes.checkBoxStyle} />
                                        </FormControl>
                                    </Grid>
                                </>
                            )}
                    </Grid>
                )}
            </Grid>
        ),
        ]);
};

const RenderMultiCB = (component, field, classes) => {
    const { selectedTimeline } = useContext(TimelineContext);
    const { id, key, showTitle } = field;
    const [flag, setFlag] = useState(false);
    let data = [];

    if (id === 'Timeline') {
        data = selectedTimeline.columns.filter((c) => (c.props.progressions && !c.props.isProfile));
        data.forEach((d) => {
            if (key === 'showProgressionSection' && d.props[key] === undefined) {
                d.props[key] = true;
            }
        });
    }

    const handleChange = (e, index) => {
        data[index].props[key] = e.target.checked;
        setFlag(!flag);
    };

    return (
        <>
            {data?.length > 0 && data.map((d, index) => (
                <FormControlLabel
                    key={d.id}
                    control={(
                        <Checkbox
                            checked={d.props[key]}
                            onChange={(e) => handleChange(e, index)}
                            name={key}
                            classes={{ root: classes.checkboxSize }}
                        />
                    )}
                    label={d.props[showTitle] || ''}
                />
            ))}
        </>
    );
};

const RenderListInput = (component, field, classes, domain1, config, componentProps) => {
    const {
        domain,
        workshopComponent,
        lastDeletedComp,
        workshopSelectedTemplate,
        workshopActiveComponent,
        setWorkshopComponent,
        selectedTimeline,
        selectedTemplate,
    } = useContext(TimelineContext);
    const isPageElem = domain
        && (component?.type === 'Page' || component?.props.pageChild || (component?.name === 'Page' && component?.type !== 'Protocol'));
    const pageDomain = component?.[domain] || { [field.key]: '' };
    let selectComp = [];
    const [shouldUpdate, setShouldUpdate] = useState(false);
    let propsComp = workshopComponent && workshopComponent.props?.components;

    if (!propsComp && workshopSelectedTemplate) {
        propsComp = workshopSelectedTemplate?.props?.components;
    }

    if (component?.props?.association && component?.parent) {
        const parentId = Array.isArray(component.parent) ? component.parent[0].parent
            : (component && component.parent ? component.parent : '');
        const componentList = findPage(propsComp, parentId) || [];
        const componentIndex = componentList.findIndex((obj) => obj.id === parentId);
        const childComp = componentList && componentList[componentIndex + 1];
        if (childComp) {
            selectComp = childComp.props.components.find((obj) => obj.type === 'Select' || obj.type === 'MultiSelect');
        }
    }

    let currentAssociation = component?.props?.currentAssociation;

    if (workshopActiveComponent && component && component?.parent && workshopActiveComponent.id !== component?.parent[0].id) {
        currentAssociation = null;
    }

    const itemRender = (item) => (domain ? field.itemRender(item, domain) : field.itemRender(item));
    const list = (domain
        && pageDomain && isPageElem ? (component?.[domain]?.[field.key] || component?.props?.[field.key]) : component?.props?.[field.key]);
    useEffect(() => {
        const currentSelect = findComponent(workshopComponent?.props.components, component?.props.selectId);
        const listParent = findComponent(workshopComponent?.props.components, currentSelect?.parent);
        if (listParent?.props?.LRProps) {
            const { LRtype, mainParent, LRparent } = listParent.props.LRProps;
            const parentComp = findComponent(workshopComponent?.props.components, mainParent);
            const compIndex = parentComp?.props.components.findIndex((comp) => comp.id === LRparent);
            if (LRtype === 'right') {
                return;
            }
            setShouldUpdate(true);
            if (parentComp) {
                parentComp.props.components[compIndex].props.components.forEach((activeComp, i) => {
                    const compToUpdate = parentComp.props.components[compIndex + 1].props.components[i];
                    if (!compToUpdate) {
                        return;
                    }
                    let LRPropsBak;
                    if (compToUpdate.props.LRProps) {
                        LRPropsBak = { ...compToUpdate.props.LRProps };
                    }
                    parentComp.props.components[compIndex + 1].props.components[i] = copyTemplateLR(activeComp, LRPropsBak, workshopComponent);
                });
            }
        }
    }, [list, workshopComponent, component, lastDeletedComp]);

    useEffect(() => {
        if (shouldUpdate) {
            setWorkshopComponent((comp) => ({ ...comp }));
        }
    }, [shouldUpdate]);

    useEffect(() => {
        setShouldUpdate(false);
    }, [workshopComponent]);

    const isBorderBottom = !selectedTimeline && !workshopComponent && selectedTemplate.supergroup === 'Form'
        && (selectedTemplate?.name === 'Multiselect- Without Protocol'
            || selectedTemplate.name === 'Radio Group- Without Protocol' || selectedTemplate?.name === 'Without Protocol');

    return (
        <div>
            <FormControl
                key={field.key}
                variant="filled"
                className={classes.formControl}
                style={{ borderBottom: isBorderBottom && '.5px solid #cbcbcb' }}
            >
                <ListEditor
                    parentComp={component?.props?.selectId}
                    associatedCell={component?.props?.associatedCell}
                    list={list}
                    render={itemRender}
                    onChange={field.onChange}
                    currentAssociation={currentAssociation || null}
                    associationData={component?.props?.association || null}
                    associationOptions={selectComp && selectComp.props && selectComp.props.options || []}
                    config={componentProps}
                />
            </FormControl>
        </div>
    );
};

const RenderImageInput = (component, field) => {
    const { domain } = useContext(TimelineContext);
    const isPageElem = domain
        && (component?.type === 'Page' || component?.props?.pageChild || (component?.name === 'Page' && component?.type !== 'Protocol'));
    const pageDomain = component?.[domain] || { [field.key]: '' };
    const isInSettings = field && field.inSettings ? field.inSettings : false;
    return (
        <div>
            <ImageSelector
                imgSrc={isPageElem && pageDomain ? component?.[domain]?.[field.key] : component?.props?.[field.key]}
                onChange={field.onChange}
                isInSettings={isInSettings}
            />
        </div>
    );
};

/**
 * @function renderMedia [render media]
 * @param {object} component
 * @param {object} field
 * @param {object} classes
 * @returns {component}
 */
const renderMedia = (component, field, classes) => (
    <Fragment>
        <div>{field.label}</div>
        <Container maxWidth="md" className={classes.expandRoot}>
            <ExpansionPanelDetails className={classes.expandPanel}>
                <FormControl fullWidth variant="filled" className={classes.layoutFormControl}>
                    <InputLabel htmlFor="age-native-helper">Layout Option</InputLabel>
                    <Select
                        fullWidth
                        value={component.props[field.key]}
                        onChange={field.onChange}
                        className={classes.layoutTypeWidth}
                    >
                        <MenuItem key={1} value="scroll">Scroll</MenuItem>
                        <MenuItem key={2} value="grid">Grid</MenuItem>
                    </Select>
                </FormControl>
            </ExpansionPanelDetails>
        </Container>
    </Fragment>
);

/**
 * @function renderDatePicker[render datepicker]
 * @param {object} component
 * @param {object} field
 * @param {object} classes
 * @returns {component}
 */
const RenderDatePicker = (component, field, classes) => {
    const { domain } = useContext(TimelineContext);
    let defaultDate = new Date();

    if (component && component?.props) {
        defaultDate = component.props[field.key];
    }
    if (domain && component?.[domain]) {
        defaultDate = component[domain][field.key] ? component[domain][field.key] : component.props[field.key];
    }

    return (
        <div key={field.key}>
            <FormControl key={field.key} variant="filled" className={classes.formControl}>
                <Typography className={classes.columHeadType} color="inherit">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            format="MM/dd/yyyy"
                            margin="normal"
                            value={defaultDate}
                            onChange={field.onChange}
                            id="date-picker-inline"
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            style={{ marginTop: 4, marginBottom: 0, fontSize: 12 }}
                        />
                    </MuiPickersUtilsProvider>
                </Typography>
            </FormControl>
        </div>
    );
};

/**
 * @function onDrop [calling on when drag element drop on editor]
 * @param {object} field
*/
const onDrop = (field) => {
    const ele = `${document.getElementById(dragId).innerHTML}`;
    editorInstance.html.insert(ele);
    field.pageDrop();
    dragId = '';
};

/**
 * @function renderPage [render page editor]
 * @param {object} component
 * @param {object} field
 * @returns {component}
 */
const RenderPage = (component, field, classes, domain) => {
    const [reRender, setReRender] = useState(false);
    const editorConfig = {
        placeholderText: field.placeholder,
        key: process.env.REACT_APP_FROALA_KEY,
        events: {
            initialized() {
                editorInstance = this;
            },
            drop: () => {
                onDrop(field);
            },
        },
        toolbarButtons: {
            moreText: {
                buttons: [
                    'bold',
                    'italic',
                    'underline',
                    'strikeThrough',
                    'subscript',
                    'superscript',
                    'fontFamily',
                    'fontSize',
                    'textColor',
                    'backgroundColor',
                    'inlineClass',
                    'inlineStyle',
                    'clearFormatting',
                ],
                align: 'left',
                buttonsVisible: 3,
            },
            moreParagraph: {
                buttons: [
                    'alignLeft',
                    'alignCenter',
                    'formatOLSimple',
                    'alignRight',
                    'alignJustify',
                    'formatOL',
                    'formatUL',
                    'paragraphFormat',
                    'paragraphStyle',
                    'lineHeight',
                    'outdent',
                    'indent',
                    'quote',
                ],
                align: 'left',
                buttonsVisible: 3,
            },
            moreRich: {
                buttons: [
                    'insertLink',
                    'insertTable',
                    'emoticons',
                    'fontAwesome',
                    'specialCharacters',
                    'embedly',
                    'insertFile',
                    'insertHR',
                ],
                align: 'left',
                buttonsVisible: 3,
            },
            moreMisc: {
                buttons: [
                    'undo',
                    'redo',
                    'fullscreen',
                    'print',
                    'getPDF',
                    'spellChecker',
                    'selectAll',
                    'html',
                    'help',
                ],
                align: 'right',
                buttonsVisible: 2,
            },
        },
    };
    const pageDomain = component?.[domain] || { [field.key]: '' };
    const model = domain && pageDomain ? (pageDomain[field.key] || '') : component && component?.props && component?.props?.[field.key];

    useEffect(() => {
        setReRender(false);
        setReRender(true);
    }, [model]);

    return (
        <>
            {reRender && (
                <FroalaEditor
                    config={editorConfig}
                    model={model}
                    onModelChange={field.onChange}
                />
            )}
        </>
    );
};

const FormInputMap = {
    GroupSeparator: {
        render: renderGroupSeparator, setter: (event) => (event.target.value),
    },
    TextField: {
        render: RenderTextInput, setter: (event) => (event.target.value),
    },
    TextFieldDebounce: {
        render: RenderTextInput, setter: (value) => (value),
    },
    TextFieldHyperlinkDebounce: {
        render: RenderTextInputHyperlink, setter: (value) => (value),
    },
    exportURL: {
        render: RenderExportURL, setter: (event) => (event.target.value),
    },
    Label: {
        render: RenderLabel, setter: (event) => (event.target.value),
    },
    StaticLabel: {
        render: RenderStaticLabel, setter: (event) => (event.target.value),
    },
    Checkbox: {
        render: RenderCheckInput, setter: (event) => (event.target.checked),
    },
    Select: {
        render: RenderSelectInput, setter: (event) => (event.target.value),
    },
    MultiSelect: {
        render: RenderMultiSelectInput, setter: (event) => (event.target.value),
    },
    Radio: {
        render: RenderRadioInput, setter: (event) => (event.target.value),
    },
    List: {
        render: RenderListInput, setter: (event) => (event),
    },
    Media: {
        render: renderMedia, setter: (event) => (event.target.value),
    },
    ButtonList: {
        render: RenderButtonList, setter: () => { },
    },
    DatePicker: {
        render: RenderDatePicker, setter: (event) => (event),
    },
    Page: {
        render: RenderPage, setter: (event) => (event),
    },
    DragItem: {
        render: renderDragElement, setter: (event) => (event.target.value),
    },
    Image: {
        render: RenderImageInput, setter: (imgSrc) => (imgSrc),
    },
    MultiCB: {
        render: RenderMultiCB, setter: (event) => (event.target.checked),
    },
};

const makeInlineEditor = ({
    field, component, setComponentState, classes, domain,
    setDirtyDomain, setClearCurrentAssociation, config = null, componentProps, inWorkshop = false,
}) => {
    const type = field?.hasOwnProperty('isNotDebounce') ? (
        field?.isNotDebounce ? FormInputMap[field.type] : FormInputMap[`${field.type}Debounce`]
    ) : FormInputMap[field.type];
    let pageDrop = ''; // for page drop

    if (type) {
        const onChange = makeChangeHandler(field.key, component, setComponentState,
            type.setter, domain, setDirtyDomain, setClearCurrentAssociation, inWorkshop);

        if (field.type === 'Page') { // for page drop
            pageDrop = makeChangeHandler(field.key, component, setComponentState, type.setter, domain, setDirtyDomain, setClearCurrentAssociation);
        }
        return type.render(component, {
            ...field, onChange, pageDrop, setComponentState,
        }, classes, domain, config, componentProps);
    }
    return '';
};

const MakeForm = (editorProps, formData, setFormData, domain, setDirtyDomain = null, setClearCurrentAssociation) => {
    const { workshopComponent } = useContext(TimelineContext);
    const formFields = [];
    editorProps.forEach((prop) => {
        const {
            type: typeName, setting, access, key, ...rest
        } = prop;
        const type = FormInputMap[typeName];
        if (!type) {
            // eslint-disable-next-line no-console
            console.log('Component Editor prop not found', prop);
        } else {
            formFields.push({
                setting,
                access,
                key,
                type: typeName,
                render: type.render,
                onChange: makeChangeHandler(prop.key, formData, setFormData,
                    type.setter, domain, setDirtyDomain, setClearCurrentAssociation, workshopComponent),
                inSettings: true,
                ...rest,
            });
        }
    });
    return formFields;
};

const RenderFormGroup = ({
    formFields, component, classes, domain, isDMRC,
}) => {
    const { workshopComponent } = useContext(TimelineContext);
    const { loggedUserType } = useContext(OrgContext);
    const row = getWorkShopRow(workshopComponent.props.components);
    let filteredFormFields = formFields;
    const wowTemplates = ['Select/Text-Complete-Notes', 'SELECT-COMPLETED', 'Select-Complete-Notes', 'SELECT-COMMPLETED-TEMPLATE'];
    if (loggedUserType !== 'Super Admin' && loggedUserType !== 'Doctor') {
        filteredFormFields = formFields.filter((c) => c.key !== 'typeOfTextField');
    }
    const sendColChildren = row?.props?.components[1]?.props?.components?.filter((val) => !val.isTimeline);
    const firstColChildren = row?.props?.components[0]?.props?.components?.filter((val) => !val.isTimeline);
    const firstColElTextAreaPass = firstColChildren?.some((item) => item.type === 'TextAreaCF');
    if ((firstColChildren?.length > 0 && !firstColElTextAreaPass
        && !wowTemplates.includes(workshopComponent?.component)) || workshopComponent?.isProgression) {
        filteredFormFields = formFields.filter((item) => item?.key !== 'single-continuous');
    }
    if ((row?.props?.components[1]?.props?.title || row?.props?.components[2]?.props?.title
        || sendColChildren?.some((child) => child?.props?.components?.length !== 0)
        || workshopComponent?.name === 'Images/Videos') && !wowTemplates.includes(workshopComponent?.component)) {
        filteredFormFields = formFields.filter((item) => item?.key !== 'flexTextField' && item?.key !== 'single-continuous');
    }
    return (
        <FormGroup row className={classes.formGroup}>
            {
                filteredFormFields.map((field, index) => (
                    <Grid container key={index} style={field?.style ? { display: field.style } : {}}>
                        {field.type === 'GroupSeparator' && <Grid item xs={12} className={classes.groupHeader}>{field.label}</Grid>}
                        {
                            (field.type !== 'GroupSeparator' && (
                                <Grid
                                    item
                                    xs={12}
                                    style={{ display: (workshopComponent?.supergroup !== 'Complex Forms' && field.key === 'isHighlight') && 'none' }}
                                >
                                    <Grid
                                        container
                                        alignItems="center"
                                        style={{
                                            background: field.label === 'Header' && component.props.type === 'h1' ? '#6d6d6d'
                                                : field.label === 'Header' && component.props.type === 'h2' ? '#b1b1b1'
                                                    : field.label === 'Header' && component.props.type === 'h3' ? '#efefef'
                                                        : '#FFFFFF',
                                        }}
                                        className={classes.columnContMain}
                                    >
                                        <Grid item xs={5} className={classes.columnContLeft}>
                                            {field.label}
                                        </Grid>
                                        <Grid item xs={7} className={isDMRC ? classes.columnContRight1 : classes.columnContRight}>
                                            <Grid
                                                container
                                                className={`${field?.type === 'TextField' ? classes.textFieldRender
                                                    : field?.type === 'Image' ? classes.settingImageComp : null} ${classes.ContRight}`}
                                            >
                                                {field?.type === 'TextFieldHyperlinkDebounce' ? (
                                                    React.createElement(field?.render, {
                                                        component,
                                                        field,
                                                        classes,
                                                        domain,
                                                    }, null)
                                                ) : (field.render(component, field, classes, domain))}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))
                        }
                    </Grid>
                ))
            }
        </FormGroup>
    );
};

const Settings = ({
    editorProps, component, setComponentState,
}) => {
    const {
        setClearCurrentAssociation, isDMRC, workshopSC, workshopComponent,
        handleSelectedPOI, selectedPOIProc, selectedPOIParentProc, domain, setDirtyDomain,
    } = useContext(
        TimelineContext
    );

    const classes = editorStyles();

    // create dynamic hyperlink options for select component
    const createHyperLinkOption = (comp) => {
        comp.props.options.forEach((ele, index) => {
            editorProps.display.push({
                key: `hyperlink-${index}`,
                type: 'TextFieldHyperlinkDebounce',
                label: `${ele.label} Hyperlink`,
                isBasic: true,
                isAdvance: true,
                isAdmin: true,
                id: 'Select',
            });
        });
    };

    if (workshopSC?.type === 'Column') {
        const data = [];
        flatComponentByType(data, workshopSC, 'Select');
        data.forEach((d) => {
            if (d.id === component?.props?.selectId) {
                createHyperLinkOption(d);
            }
        });
    }

    const formFields = MakeForm(editorProps?.display, component, setComponentState, domain, setDirtyDomain, setClearCurrentAssociation);

    // Initially set POI from PROPS
    if ((selectedPOIProc && selectedPOIProc?.length === 0 && component?.props?.poi && component?.props?.poi?.length > 0)
        || (selectedPOIParentProc && Object.keys(selectedPOIParentProc).length === 0
            && component.props && component.props.poiWithParent && Object.keys(component.props.poiWithParent).length > 0)) {
        handleSelectedPOI(component.props.poi, component.props.poiWithParent);
    }

    /**
     * @function onHandleChange
     * @param {object} click event
     * @param {string} colType [Basic/Advanced/Admin]
     */

    return (
        <div style={{ width: '100%' }}>
            <Grid container>
                <Grid
                    item
                    xs={12}
                    className={classes.column}
                    style={{
                        padding: workshopComponent?.props?.leftRightColumn ? '0px 4px' : isDMRC ? 0 : 4,
                        border: isDMRC || workshopComponent?.props?.leftRightColumn ? 'none' : '1px solid #ececec',
                    }}
                >
                    <RenderFormGroup formFields={formFields} component={component} classes={classes} domain={domain} isDMRC={isDMRC} inSetting />
                </Grid>
            </Grid>
        </div>
    );
};

export {
    MakeForm, RenderFormGroup, Settings, makeInlineEditor,
};
