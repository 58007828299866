import React, { useContext, useEffect, useState } from 'react';
// Material-UI
import './css/font.css';
import { Link, useRouteMatch } from 'react-router-dom';
import { OrgContext } from './context';
import { Switch } from '@material-ui/core';
import {
    bindMenu,
    bindTrigger,
    usePopupState,
} from 'material-ui-popup-state/hooks';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth0 } from './react-auth0-spa';
import { useMutation } from '@apollo/react-hooks';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import gql from 'graphql-tag';
// Custom Style
const useStyles = makeStyles((theme) => ({
    flex: {
        color: '#d1d1d1',
        flexGrow: 1,
    },
    appBar: {
        position: 'fixed',
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.primary.main,
    },
    NavButtons: {
        textTransform: 'none',
        color: '#FFFFFF',
    },
    IconButtonStyle: {
        color: '#FFFFFF',
    },
    profileLink: {
        textDecoration: 'none',
        color: theme.palette.secondary.main,
    },
    switchCont: {
        padding: '0px 16px',
    },
}));

export const UPDATE_ACTIVE_ROLE = gql`
    mutation updateActiveRole($user_id: uuid, $active_role: String) {
        update_organization_member(where: { user_id: {_eq: $user_id} }, _set: {active_role: $active_role} ) {
            affected_rows
            returning {
                active_role
            }
        }
    }
`;

const TopNav = () => {
    const {
        userOrgs, selectedOrg, loggedUserType, loggedInUserDetails, setlLoggedUserType,
    } = useContext(OrgContext);
    const [checked, setChecked] = useState(true);
    const orgPopupState = usePopupState({ variant: 'popover', popupId: 'orgSwitcher' });
    const userPopupState = usePopupState({ variant: 'popover', popupId: 'orgSwitcher' });
    const classes = useStyles();
    const { logout } = useAuth0();

    const [updateActiveRole, {
        data: updateActiveRoleData,
    }] = useMutation(UPDATE_ACTIVE_ROLE);

    const isOrgLanding = useRouteMatch('/:orgID/')?.isExact;

    useEffect(() => {
        setChecked(loggedUserType === 'Super Admin');
    }, [loggedUserType]);

    useEffect(() => {
        if (updateActiveRoleData) {
            setlLoggedUserType(updateActiveRoleData?.['update_organization_member']?.returning?.[0]?.['active_role']);
        }
    }, [updateActiveRoleData]);

    const handleLogout = () => {
        logout({
            returnTo: window.location.origin,
        });
    };
    let orgSelector;
    if (userOrgs && selectedOrg) {
        orgSelector = (
            <React.Fragment key="key">
                <Button className={classes.NavButtons} color="inherit" {...bindTrigger(orgPopupState)}>
                    {(loggedUserType === 'Super Admin' || loggedUserType === 'Doctor') ? selectedOrg.name : loggedInUserDetails.name}
                    <ExpandMoreIcon fontSize="small" />
                </Button>
            </React.Fragment>
        );
    }

    const handleSwitchToggle = (event) => {
        updateActiveRole({
            variables: {
                user_id: loggedInUserDetails?.id,
                active_role: event?.target?.checked ? 'Super Admin' : 'Doctor',
            },
        });
    };

    return (
        <AppBar id="appBar" className={classes.appBar}>
            <Toolbar>
                <Typography variant="h2" className={classes.flex}>
                    MedGuide
                </Typography>
                {orgSelector}
                <div>
                    <IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        classes={{ root: classes.IconButtonStyle }}
                        {...bindTrigger(userPopupState)}
                    >
                        <AccountCircle />
                    </IconButton>
                    <Menu {...bindMenu(userPopupState)}>
                        <MenuItem onClick={userPopupState.close}>
                            <Link to="/profile" className={classes.profileLink}>Profile</Link>
                        </MenuItem>
                        <MenuItem onClick={() => { handleLogout(); userPopupState.close(); }}>Logout</MenuItem>
                        {loggedInUserDetails?.['organization_member']?.[0]?.role === 'Super Admin' && isOrgLanding ? (
                            <MenuItem className={classes.switchCont}>
                                <Typography>Doctor</Typography>
                                <Switch
                                    checked={checked}
                                    onChange={handleSwitchToggle}
                                />
                                <Typography className={classes.marginRight12}>Super Admin</Typography>
                            </MenuItem>
                        ) : null}
                    </Menu>
                </div>
            </Toolbar>
        </AppBar>
    );
};
export default TopNav;
