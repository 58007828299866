/* eslint-disable no-plusplus */
/* eslint-disable no-return-assign */
/* eslint-disable eqeqeq */
/* eslint-disable max-len */
import { Menu, MenuItem } from '@material-ui/core';
import { TimelineContext } from './context';
import { buildSettingsTreePage } from './helper/commonHelper';
import { makeStyles } from '@material-ui/core/styles';
import NestedMenuItem from './components/NestedMenu';
import React, { useContext, useEffect } from 'react';
import _ from 'lodash';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
    liSectionPart: {
        padding: '2px 10px 5px 22px',
        '& li': {
            fontSize: '14px !important',
            border: 'none !important',
            paddingLeft: '0px !important',
            marginBottom: '0px !important',
        },
    },
    mainLiSectionPart: {
        '& li': {
            fontSize: '15px',
        },
    },
    olLSectionPart: {
        padding: '0px',
        '& li': {
            borderBottom: '1px solid #cbcbcb',
            paddingLeft: '15px',
            marginBottom: '7px',
            '&:last-child': {
                borderBottom: 'none',
                marginBottom: '0px',
            },
        },
    },
}));

export const WorkshopContextMenu = ({
    menuConfig, menuPosition, handleClick, handleClose,
}) => {
    const {
        hyperlinkCip, complexFormType, contentDisplayCip, workshopComponent,
    } = useContext(TimelineContext);
    const makeOnClick = (action, parentMenu) => (e) => { handleClick(e, action, parentMenu); };
    const workshopList = [];

    if (workshopComponent?.props?.isUniversal) {
        buildSettingsTreePage(workshopList, workshopComponent);
    }
    const [firstColumn] = workshopList.filter((el) => el.type === 'Column' && el.props.index === 0);
    let firstColElPasses = false;
    if (firstColumn?.props?.components.length > 0) {
        firstColElPasses = !!firstColumn?.props?.components.every((item) => item.type === 'Checkbox' || item.type === 'TextAreaCF');
    }
    const findProtocol = menuConfig.find((menu) => menu.label === 'Add Protocol');
    if (findProtocol?.children) {
        const newChild = findProtocol?.children?.filter((e) => e?.label !== 'Page' && e?.label !== 'Protocol Only');
        findProtocol.children = newChild;
    }
    menuConfig = menuConfig?.filter((item) => (item?.action?.component?.header == 'Checkbox'
        ? (item?.action?.component?.name == 'Checkbox' ? item.label = 'Multi Select Checkbox' : item.label = 'Single Select Checkbox')
        : (item?.action?.component?.header == 'Drop Down' ? (item?.action?.component?.name == 'Single Select' ? item.label = 'Single Select Dropdown' : item.label = 'Multi Select Dropdown')
            : item?.label)));

    if (workshopComponent?.props?.isUniversal && workshopComponent?.props?.pcRelationship) {
        menuConfig = menuConfig.filter((ele) => ele?.label !== 'Single Select Dropdown' && ele?.label !== 'Textbox');
    }

    if (complexFormType === 'Select and Suggest') {
        menuConfig = menuConfig.filter((ele) => ele?.label === 'Multi Select Checkbox' || ele?.label === 'Delete' || ele?.label === 'Add Options');
    }
    if (workshopComponent?.supergroup || workshopComponent?.name) {
        menuConfig = menuConfig.filter((ele) => ele?.label !== 'Multi Select Dropdown');
    }
    const imgVideoAction = {
        component:
        {
            id: 'cl-16', type: 'Protocol', name: 'Image/Video', useLibrary: true, libraryFilter: 'Images/Videos',
        },
        relation:
            'insert-content-display',
        type:
            'insert',
    };

    const imgVideoEditAction = {
        filter: { action: imgVideoAction },
        relation: 'edit-content-display',
        type: 'edit',
    };
    let findAdCDMenu = {};
    if (findAdCDMenu?.action?.relation !== 'insert-content-display') {
        const findCdMenu = menuConfig?.find((i) => i?.action?.type === 'addContent');
        findAdCDMenu = findCdMenu?.children?.find((c) => c?.action.type === 'addContentDisplay');
        if (findAdCDMenu?.action) {
            findAdCDMenu.action = imgVideoAction;
        }
    }

    if (findAdCDMenu?.action?.relation !== 'edit-content-display') {
        const findCdMenu = menuConfig?.find((i) => i?.action?.type === 'addContent');
        findAdCDMenu = findCdMenu?.children?.find((c) => c?.action.type === 'editContentDisplay');
        if (findAdCDMenu?.action) {
            findAdCDMenu.action = imgVideoEditAction;
        }
    }

    let menuConfigCollection = _.groupBy(menuConfig, 'action.component.header');
    const hasheader = _.some(menuConfigCollection, (value, key) => key !== 'undefined');
    const menuConfigOrder = {};
    if (!menuConfigCollection.Checkbox && menuConfigCollection['Drop Down']) {
        menuConfigOrder['Drop Down'] = null;
    } else if (menuConfigCollection.Checkbox && !menuConfigCollection['Drop Down']) {
        menuConfigOrder.Checkbox = null;
    } else {
        menuConfigOrder.Checkbox = null;
        menuConfigOrder['Drop Down'] = null;
    }
    useEffect(() => {
        for (let i = 0, l = menuConfig.length; i < l; i += 1) {
            if (menuConfig[i].action.type === 'edit') {
                if (hyperlinkCip || contentDisplayCip) {
                    handleClick(null, menuConfig[i].action, null);
                }
                break;
            }
        }
    }, [hyperlinkCip, contentDisplayCip]);
    menuConfigCollection = Object.assign(menuConfigOrder, menuConfigCollection);
    const renderNested = (item, index, depth, root) => (
        <NestedMenuItem
            key={index}
            label={item.label}
            parentMenuOpen={!!menuPosition}
        >
            {item.children.map((child, cindex) => (child.children ? renderNested(child, cindex, ++depth, root) : <MenuItem key={`${depth}-${index}-${cindex}`} onClick={makeOnClick(child.action, root)}>{child.label}</MenuItem>))}
        </NestedMenuItem>
    );
    const classes = useStyles();

    if (firstColElPasses && menuPosition?.isFirstCol) {
        const multiSelectOption = menuConfigCollection?.Checkbox?.filter((el) => el.label === 'Multi Select Checkbox');
        menuConfigCollection = { ...menuConfigCollection, Checkbox: multiSelectOption };
    }

    if (workshopComponent?.component === 'Select/Text-Complete-Notes' && menuPosition?.isFirstCol) {
        const multiSelectOption = menuConfigCollection?.Checkbox?.filter((el) => el.label === 'Multi Select Checkbox');
        menuConfigCollection = { ...menuConfigCollection, Checkbox: multiSelectOption };
    }

    return (
        <Menu
            open={!!menuPosition}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={menuPosition}
            className={classes.mainLiSectionPart}
            style={{ display: hyperlinkCip ? 'none' : 'block' }}
        >
            {!hasheader && menuConfig.map((item, index) => (item.children ? renderNested(item, index, 0, item) : <MenuItem key={index} onClick={makeOnClick(item.action, null)} style={{ fontFamily: 'ProximaNova-Semibold' }}>{item.label}</MenuItem>))}
            {hasheader && _.map(menuConfigCollection, (items, header) => {
                if (items?.length === 1) {
                    return (
                        <MenuItem
                            onClick={makeOnClick(items?.[0]?.action, null)}
                            style={{ fontFamily: 'ProximaNova-Semibold', listStyleType: 'bullet' }}
                        >
                            {items?.[0]?.label}
                        </MenuItem>
                    );
                }
                return (
                    <NestedMenuItem
                        key={header}
                        label={header}
                        parentMenuOpen={!!menuPosition}
                    >
                        {items && items.map((item, index) => <MenuItem key={index} onClick={makeOnClick(item.action, null)} style={{ fontFamily: 'ProximaNova-Semibold', listStyleType: 'bullet' }}>{item.label}</MenuItem>)}
                    </NestedMenuItem>
                );
            })}
        </Menu>
    );
};

export default WorkshopContextMenu;
