import { TimelineContext } from '.';
import {
    addOptionCells, buildSettingsTreePage, createCell, createEmpltyCellForList, createEmptyCell, deepCopy, flatOptions,
} from '../helper/commonHelper';
import { v4 as uuid } from 'uuid';
import React, {
    createContext,
    useContext,
} from 'react';

export const Context = createContext({});

export const Provider = ({ children }) => {
    const {
        workshopActiveComponent,
        setUpdateFlag,
        updateFlag,
        universalCompState, setUniversalCompState, universalScndState, setUniversalScndState,
        workshopComponent,
    } = useContext(TimelineContext);

    const createEmptySelect = (obj) => {
        const { component, associatedParentComp, parentCell } = obj;
        const cloneNextColComp = deepCopy(component);
        cloneNextColComp.id = uuid();
        cloneNextColComp.associatedParent = associatedParentComp.id;
        cloneNextColComp.parent = parentCell.id;
        cloneNextColComp.props.childOf = parentCell.id;
        cloneNextColComp.props.options = [];
        cloneNextColComp.props.selectId = cloneNextColComp.id;
        cloneNextColComp.isTimeline = true;
        const selectInsertIndex = parentCell?.props?.components?.length;
        if (selectInsertIndex !== -1 && selectInsertIndex !== undefined) parentCell.props.components[selectInsertIndex] = cloneNextColComp;
    };

    const createSelectComp = (props) => {
        const {
            compList, nextColSelectCell, timelineSelect, optionCell, rowComp, componentProps, emptyCell, clonedComp,
        } = props;
        if (componentProps && componentProps?.type !== 'Select') {
            const thirdColWSCells = rowComp?.props?.components[2]?.props?.components?.filter((c) => !c.isTimeline);
            const thirdColFComp = thirdColWSCells?.[0]?.props?.components?.[0];
            if (thirdColFComp?.type === 'Select') {
                const obj = {
                    component: thirdColFComp,
                    associatedParentComp: clonedComp,
                    parentCell: emptyCell,
                };
                createEmptySelect(obj);
            }
        } else {
            const nextColFormComp = compList.find((c) => c.colRef === nextColSelectCell?.colRef && c.type === 'Select');
            if (!nextColFormComp?.id) return;
            const obj = {
                component: nextColFormComp,
                associatedParentComp: timelineSelect,
                parentCell: optionCell,
            };
            createEmptySelect(obj);
        }
    };

    const checkValidIndex = (index) => {
        let isValid = false;
        if (index !== -1 && index !== undefined) {
            isValid = true;
        }
        return isValid;
    };

    const removeColorFromColumn = (props) => {
        const {
            findColIndex, index, data, rowComp, compList,
        } = props;
        const flatOptionData = [];
        switch (findColIndex) {
        case 0:
            rowComp.props.components.forEach((c) => {
                flatOptions(flatOptionData, c);
            });
            break;
        case 1:
            break;
        case 2:
            break;
        default:
            break;
        }
        if (flatOptionData.length > 0) {
            flatOptionData.forEach((po) => {
                if ('highlightComponent' in po && po.id !== data[index].id) delete po.highlightComponent;
            });
        }
        const filterCompList = compList.filter((c) => c.type === 'Checkbox' || c.type === 'TextAreaCF') || [];
        filterCompList.forEach((c) => {
            if ('highlightComponent' in c.props) delete c.props.highlightComponent;
        });
        return flatOptionData;
    };

    const handleFirstColumn = (obj) => {
        const {
            index, compList, findColIndex, data, rowComp, componentProps,
        } = obj;
        const flatOptionData = removeColorFromColumn({
            findColIndex, index, data, rowComp, compList,
        });
        const filterCompList = compList.filter((c) => c.type === 'Checkbox' || c.type === 'TextAreaCF') || [];
        const thirdColumn = compList.find((col) => col.type === 'Column' && col.compIndex === 2);
        if (universalCompState.option.id === data[index].id && data[index].highlightComponent) {
            const timelineSecSelect = compList.find((c) => c.id === universalCompState?.option?.props?.associatedCellTimeline)?.props?.components[0];
            let timelineSecCell;
            if (timelineSecSelect?.type !== 'Select') {
                timelineSecCell = compList.find((c) => c.id === universalCompState?.option?.props?.associatedCellTimeline);
                const nextCellWorkshopComp = rowComp.props.components[1].props.components.filter((c) => !c.isTimeline) || [];
                const associatedCellIds = [];
                for (let i = 0, l = timelineSecCell.props.components.length; i < l; i += 1) {
                    const item = timelineSecCell.props.components[i];
                    associatedCellIds.push(item.props.associatedCellTimeline);
                    const clrIndex = item.props.highlightAssociation.parentAssociation.findIndex((c) => c === universalCompState.option.id);
                    if (clrIndex !== -1) {
                        item.props.highlightAssociation.parentAssociation.splice(clrIndex, 1);
                    }
                }
                const thirdTLCells = thirdColumn?.props?.components?.filter((c) => c.isTimeline);
                const thirdWSCells = thirdColumn?.props?.components?.filter((c) => !c.isTimeline);
                if (timelineSecCell && thirdTLCells && associatedCellIds.length > 0) {
                    const newEmptyCellIndex = thirdTLCells?.findIndex((c) => associatedCellIds.includes(c.id));
                    let i = 0;
                    let l = thirdTLCells.length;
                    while (i < l && l > 0) {
                        if (associatedCellIds.includes(thirdTLCells[i]?.id)) {
                            thirdTLCells.splice(i, 1);
                            l -= 1;
                        } else {
                            i += 1;
                        }
                    }
                    const newEmptyCell = createEmpltyCellForList({
                        cellId: universalCompState?.parent?.id,
                        secondNextCol: rowComp.props.components[2],
                        nextCellWorkshopComp,
                        index: newEmptyCellIndex,
                        parentComp: rowComp.parent[0],
                        associatedComponent: universalCompState.option,
                        isTimeline: true,
                    });
                    if (newEmptyCellIndex > -1 && thirdTLCells) {
                        thirdTLCells.splice(newEmptyCellIndex, 0, newEmptyCell);
                    }
                }
                timelineSecCell.props.components = [];
                data[index].highlightAssociation.childAssociation = [];
                if (thirdTLCells && thirdWSCells) {
                    thirdColumn.props.components = [...thirdWSCells, ...thirdTLCells];
                }
            } else {
                if (timelineSecSelect?.props?.options) timelineSecSelect.props.options = [];
                const colSecCell = compList.find((c) => c.isTimeline && c.id === data[index].props.associatedCellTimeline);

                if (colSecCell.props.components[0]?.type === 'Select') {
                    colSecCell.props.components[0].props.options = [];
                } else {
                    colSecCell.props.components = [];
                }

                if (!componentProps?.props?.LRProps?.LRtype && !workshopComponent?.props?.leftRightColumn) {
                    const timelineThirdSelect = compList.find((c) => c.id === timelineSecSelect.props.associatedCellTimeline && c.isTimeline);
                    timelineThirdSelect.props.components = [];
                    // const colThirSelectCell = compList.find((c) => c.isTimeline && c.type === 'SelectCell' && c.props.associatedComponent === colSecCell.props.components[0].id);
                    if (colSecCell?.props?.components?.length > 0) {
                        const colThirSelectCell = compList.find((c) => c.isTimeline
                        && c.props.associatedComponent === colSecCell?.props?.components[0]?.id);
                        colThirSelectCell.props.components = [];
                    }
                }
                flatOptionData.forEach((option) => {
                    if (option?.highlightAssociation?.parentAssociation) {
                        option.highlightAssociation.parentAssociation = option?.
                        highlightAssociation?.parentAssociation.filter((o) => o.optionId !== data[index].id);
                    }
                });
                rowComp.props.components.forEach((column, i) => {
                    if (i > 0) {
                        const colOptions = [];
                        flatOptions(colOptions, column);
                        if (i === 1) {
                            colOptions.forEach((option) => {
                                if (option?.highlightAssociation?.parentAssociation) {
                                    option.highlightAssociation.parentAssociation = option?.highlightAssociation?.parentAssociation?.
                                    filter((o) => o.optionId !== data[index].id);
                                }
                                if (option?.highlightAssociation?.childAssociation) {
                                    option.highlightAssociation.childAssociation = option?.highlightAssociation?.childAssociation?.
                                    filter((o) => o.grandOptionId !== data[index].id);
                                }
                            });
                            filterCompList.forEach((comp) => {
                                if (comp?.props?.highlightAssociation?.parentAssociation) {
                                    comp.props.highlightAssociation.parentAssociation = comp?.props?.highlightAssociation?.parentAssociation?.
                                    filter((i) => i !== data[index].id);
                                }
                            });
                        } else {
                            colOptions.forEach((option) => {
                                if (option?.highlightAssociation?.parentAssociation) {
                                    option.highlightAssociation.parentAssociation = option?.highlightAssociation?.parentAssociation?.
                                    filter((o) => o.grandParentOptionId !== data[index].id);
                                }
                            });
                        }
                    } else {
                        data[index].highlightAssociation.childAssociation = [];
                    }
                });
                Object.keys(universalCompState).forEach((key) => delete universalCompState[key]);
            }
        }
        data[index].highlightComponent = !data[index].highlightComponent;
    };

    const handleSecondColumn = (obj) => {
        const {
            // index, compList, data, rowComp,
            index, compList, data, rowComp, componentProps,
        } = obj;
        if (!universalCompState.option && !universalCompState.parent) return;
        if (universalCompState.parent && universalCompState?.parent?.type !== 'Select') {
            universalCompState.child = universalCompState?.child || [];
            universalCompState.parent.props.highlightAssociation.childAssociation = universalCompState
                .parent.props.highlightAssociation?.childAssociation || [];
            data[index].highlightAssociation = { parentAssociation: data[index]?.highlightAssociation?.parentAssociation || [] };
            const findPOptionIndex = universalCompState.parent.props.highlightAssociation.childAssociation
                .findIndex((i) => i.optionId === data[index].id);
            const findCOptionIndex = data[index].highlightAssociation.parentAssociation.findIndex((i) => i.optionId === universalCompState.parent.id);
            // select comp in second column and checkbox/textarea in universal state

            const timelineSelect = compList.find((c) => c.id === universalCompState?.parent?.props?.associatedCellTimeline)?.props?.components[0];
            const existIndex = timelineSelect?.props?.options?.findIndex((i) => i.id === data[index].id);

            if (existIndex === -1) {
                // insert option in select cell
                if (timelineSelect?.props?.options) timelineSelect.props.options.push(data[index]);
                universalCompState.child.push(data[index].id);
                // colour implementation
                universalCompState.parent.props.highlightAssociation.childAssociation.push({
                    optionId: data[index].id,
                });
                data[index].highlightAssociation.parentAssociation.push({
                    optionId: universalCompState.parent.id,
                });
                // create option cell in 3rd column
                if (!rowComp?.props?.LRProps) {
                    const nextColSelectCell = compList.find((c) => c.props.associatedComponent
                    === timelineSelect.id && c.isTimeline);
                    const optionCell = addOptionCells(nextColSelectCell,
                        timelineSelect.id, timelineSelect.props.options.length - 1, null, data[index].id);
                    if (nextColSelectCell) { // it will not work for LR CF
                        const nextColFormComp = compList.find((c) => c.colRef === nextColSelectCell?.colRef && c.type === 'Select');
                        if (nextColFormComp) {
                            const reqObj = {
                                compList, nextColSelectCell, timelineSelect, optionCell,
                            };
                            createSelectComp(reqObj);
                        } else {
                            nextColSelectCell.props.components[timelineSelect.props.options.length - 1] = optionCell;
                        }
                    }
                }
            } else {
                timelineSelect.props.options.splice(existIndex, 1);
                universalCompState.child.splice(existIndex, 1);
                // colour remove
                universalCompState.parent.props.highlightAssociation.childAssociation.splice(findPOptionIndex, 1);
                data[index].highlightAssociation.parentAssociation.splice(findCOptionIndex, 1);
                if ('highlightComponent' in data[index]) delete data[index].highlightComponent;

                // remove option cell from 3rd column
                if (!rowComp.props.LRProps) {
                    const nextColCell = compList.find((c) => c.props.associatedComponent === timelineSelect?.id && c.isTimeline);
                    const findOptionCellIndex = nextColCell.props.components.findIndex((c) => c.props.associateOptionId === data[index].id);
                    nextColCell.props.components.splice(findOptionCellIndex, 1);
                    nextColCell.props.associatedCells = nextColCell.props.components.map((c) => c.id);
                    setUniversalScndState({});
                }
            }
        } else if (universalCompState.option) {
            universalCompState.option.highlightAssociation.childAssociation = universalCompState.option.highlightAssociation.childAssociation || [];
            const timelineSelect = compList.find((c) => c.id === universalCompState?.option?.props?.associatedCellTimeline)?.props?.components[0];
            const findPOptionIndex = universalCompState.option.highlightAssociation.childAssociation.findIndex((i) => i.optionId === data[index].id);
            const findCOptionIndex = data[index].highlightAssociation.parentAssociation.findIndex((i) => i.optionId === universalCompState.option.id);
            const findTOptionIndex = timelineSelect?.props?.options?.findIndex((i) => i.id === data[index].id);

            // making color association between parent and child
            if (!checkValidIndex(findPOptionIndex)) { // add component
                universalCompState.option.highlightAssociation.childAssociation.push({
                    optionId: data[index].id,
                });
                data[index].highlightAssociation.parentAssociation.push({
                    optionId: universalCompState.option.id,
                });

                // insert option in timeline cell.
                if (timelineSelect?.props?.options) timelineSelect.props.options.push(data[index]);
                // create option cell in 3rd column
                const nextColSelectCell = compList.find((c) => c.props.associatedComponent === timelineSelect?.id && c.isTimeline);
                if (nextColSelectCell) {
                    const optionCell = addOptionCells(nextColSelectCell,
                        timelineSelect.id, timelineSelect.props.options.length - 1, null, data[index].id);
                    const compObj = {
                        compList, nextColSelectCell, timelineSelect, optionCell, rowComp: obj.rowComp,
                    };
                    createSelectComp(compObj);
                }
            } else { // remove component
                universalCompState.option.highlightAssociation.childAssociation.splice(findPOptionIndex, 1);

                // add check if need
                data[index].highlightAssociation.parentAssociation.splice(findCOptionIndex, 1);

                if (data[index].highlightAssociation?.childAssociation?.[0]?.grandOptionId) {
                    data[index].highlightAssociation.childAssociation = data[index].highlightAssociation?.childAssociation?.
                    filter((o) => o.grandOptionId === universalCompState.option.id)?.map((o) => o.optionId) || [];
                } else {
                    data[index].highlightAssociation.childAssociation = [];
                }

                delete data[index].highlightComponent;

                // remove the option from timeline cell
                timelineSelect.props.options.splice(findTOptionIndex, 1);
                // it will work only for one sided universal complex form
                if (!componentProps?.props?.LRProps?.LRtype && !workshopComponent?.props?.leftRightColumn) {
                    // remove option cell from 3rd column
                    const nextColCell = compList.find((c) => c.props.associatedComponent === timelineSelect?.id && c.isTimeline);
                    const findOptionCellIndex = nextColCell.props.components.findIndex((c) => c.props.associateOptionId === data[index].id);
                    nextColCell.props.components.splice(findOptionCellIndex, 1);
                    nextColCell.props.associatedCells = nextColCell.props.components.map((c) => c.id);
                }
                setUniversalScndState({});
            }
        }
    };

    const removeCompColor = (props) => {
        const { findColIndex, componentProps, component } = props;
        if (componentProps.props.type === 'Select') return;
        switch (findColIndex) {
        case 0:
            componentProps.props.highlightComponent = !componentProps.props.highlightComponent;
            component.props.highlightComponent = componentProps.props.highlightComponent;
            if (componentProps?.props?.LRProps && Object.keys(componentProps?.props?.highlightAssociation).length === 0) {
                delete componentProps.props.highlightComponent;
            }
            break;
        case 1:
            componentProps.props.highlightComponent = componentProps.props.highlightAssociation.parentAssociation
                .includes(universalCompState.parent.id);
            component.props.highlightComponent = componentProps.props.highlightComponent;
            break;
        case 2:
            break;
        default:
            break;
        }
    };

    const handleThirdColumn = (obj) => {
        const {
            index, compList, data, rowComp = {},
        } = obj;
        if ((!universalCompState.option || !universalScndState.option) && (!universalCompState.parent || !universalScndState.parent)) return;

        if (universalScndState?.parent?.type !== 'Select') {
            // when Checkbox/TextArea in the second Column
            const scndTimelineComp = findScndStateTimelineComp(universalScndState.parent, obj.rowComp);
            const targetedCell = rowComp.props.components[2].props.components.find((c) => c.isTimeline
            && c.props.associatedComponent === scndTimelineComp.id && c.id === scndTimelineComp.props.associatedCellTimeline);
            if (targetedCell && targetedCell.props.components[0]) {
                if (targetedCell.type === 'SelectCell') {
                    const targetdeOptionCell = targetedCell.props.components
                        .find((oc) => oc.props.associateOptionId === universalScndState.option.id);
                    const existIndex = targetdeOptionCell.props.components[0].props.options.findIndex((o) => o.id === data[index].id);
                    if (existIndex !== -1) {
                        targetdeOptionCell.props.components[0].props.options.splice(existIndex, 1);
                    } else {
                        targetdeOptionCell.props.components[0].props.options.push(data[index]);
                    }
                } else {
                    const existIndex = targetedCell.props.components[0].props.options.findIndex((oc) => oc.id === data[index].id);
                    // remove if option already is there
                    if (existIndex !== -1) {
                        targetedCell.props.components[0].props.options.splice(existIndex, 1);
                        scndTimelineComp.props.highlightAssociation.childAssociation.splice(existIndex, 1);
                    } else {
                        // inserting new option
                        targetedCell.props.components[0].props.options.push(data[index]);
                        scndTimelineComp.props.highlightAssociation.childAssociation.push(data[index].id);
                    }
                }
            }
        } else {
            // when Select component in the second Column
            universalScndState.option.highlightAssociation.childAssociation = universalScndState.option.highlightAssociation.childAssociation || [];
            data[index].highlightAssociation = {
                parentAssociation: data[index].highlightAssociation?.parentAssociation || [],
            };
            const findPOptionIndex = universalScndState.option.highlightAssociation.childAssociation.findIndex((i) => i.optionId === data[index].id
            && ((i.grandOptionId === universalScndState?.parentOption?.id) || (i.grandOptionId === universalCompState?.parent?.id)));
            const findCOptionIndex = data[index].highlightAssociation.parentAssociation.findIndex((i) => i.optionId === universalScndState.option.id
            && ((i.grandOptionId === universalScndState?.parentOption?.id) || (i.grandOptionId === universalCompState?.parent?.id)));

            // making color association between parent and child
            if (!checkValidIndex(findPOptionIndex)) {
                universalScndState.option.highlightAssociation.childAssociation = universalScndState.option.highlightAssociation.childAssociation
                || [];
                if (universalCompState?.parent?.type !== 'Select') {
                    universalScndState.option.highlightAssociation.childAssociation.push({
                        optionId: data[index].id,
                        grandOptionId: universalCompState.parent.id,
                    });
                    data[index].highlightAssociation.parentAssociation.push({
                        optionId: universalScndState.option.id,
                        grandParentOptionId: universalCompState.parent.id,
                    });
                    const filterOptionCell = compList.filter((c) => c.isTimeline && c.props.associateOptionId === universalScndState.option.id);
                    const findFirstColOptionI = universalCompState.parent.compIndex;
                    const selectCell = compList.filter((c) => c.type === 'SelectCell' && c.isTimeline)[findFirstColOptionI];
                    filterOptionCell.forEach((oc) => {
                        if (oc.parent === selectCell?.id) {
                            oc.props.components[0].props.options.push(data[index]);
                        }
                    });
                    universalScndState.child = universalScndState.option.highlightAssociation?.childAssociation?.
                    filter((o) => o.grandOptionId === universalCompState.parent.id)?.map((o) => o.optionId) || [];
                } else {
                    universalScndState.option.highlightAssociation.childAssociation.push({
                        optionId: data[index].id,
                        grandOptionId: universalCompState.option.id,
                    });
                    data[index].highlightAssociation.parentAssociation.push({
                        optionId: universalScndState.option.id,
                        grandParentOptionId: universalCompState.option.id,
                    });
                    const filterOptionCell = compList.filter((c) => c.isTimeline && c.props.associateOptionId === universalScndState.option.id);
                    const findFirstColOptionI = universalCompState.component.props.options
                        .findIndex((o) => o.id === universalScndState.parentOption.id);
                    const selectCell = compList.filter((c) => c.type === 'SelectCell' && c.isTimeline)[findFirstColOptionI];
                    filterOptionCell.forEach((oc) => {
                        if (oc.parent === selectCell?.id) {
                            oc.props.components[0].props.options.push(data[index]);
                        }
                    });
                    universalScndState.child = universalScndState.option.highlightAssociation?.childAssociation?.
                    filter((o) => o.grandOptionId === universalCompState.option.id)?.map((o) => o.optionId) || [];
                }
            } else {
                // remove element
                universalScndState.option.highlightAssociation.childAssociation.splice(findPOptionIndex, 1);
                // add check if need
                data[index].highlightAssociation.parentAssociation.splice(findCOptionIndex, 1);
                delete data[index].highlightComponent;
                // remove option cell from 3rd column
                let filterOptionCell = [];
                let findFirstColOptionI = -1;
                if (universalCompState?.parent?.type !== 'Select') {
                    filterOptionCell = compList.filter((c) => c.isTimeline && c.props.associateOptionId === universalScndState.option.id);
                    findFirstColOptionI = universalCompState.parent.compIndex;
                } else {
                    filterOptionCell = compList.filter((c) => c.isTimeline && c.props.associateOptionId === universalScndState.option.id);
                    findFirstColOptionI = universalCompState.component.props.options.findIndex((o) => o.id === universalScndState.parentOption.id);
                }
                const selectCell = compList.filter((c) => c.type === 'SelectCell' && c.isTimeline)[findFirstColOptionI];
                filterOptionCell.forEach((oc) => {
                    if (oc.parent === selectCell?.id) {
                        const findRemoveOptionI = oc.props.components[0].props.options.findIndex((o) => o.id === data[index].id);
                        oc.props.components[0].props.options.splice(findRemoveOptionI, 1);
                    }
                });
                // remove green color from element
                universalScndState.child = universalScndState.option.highlightAssociation.childAssociation
                    .filter((o) => ((o.grandOptionId === universalScndState?.parentOption?.id)
                    || (o.grandOptionId === universalCompState?.parent?.id))).map((c) => c.optionId);
            }
            setUniversalScndState({ ...universalScndState });
        }
    };

    const handleManualSelection = (props) => {
        const {
            index = -1, rowComp, componentProps, data = null, states,
        } = props;
        const { component = null, setComponentState = null } = states;
        const compList = [];
        buildSettingsTreePage(compList, rowComp);
        const findColIndex = rowComp?.props?.components?.findIndex((c) => c.id === componentProps?.colRef);
        const flatOptionData1 = [];
        switch (componentProps.type) {
        case 'Checkbox':
        case 'TextAreaCF':
            {
                switch (findColIndex) {
                case 0:
                    setUniversalScndState({});
                    rowComp.props.components.forEach((c) => {
                        flatOptions(flatOptionData1, c);
                    });

                    if (flatOptionData1.length > 0) {
                        flatOptionData1.forEach((po) => {
                            if ('highlightComponent' in po) delete po.highlightComponent;
                        });
                    }

                    break;
                case 1:
                    if (!universalCompState?.parent?.id) {
                        return;
                    }
                    break;
                case 2:
                    if (universalCompState?.parent?.id && universalScndState?.parent?.id) {
                        thirdColumnDoubleClciked(componentProps, rowComp, compList);
                    }
                    break;
                default:
                    break;
                }

                componentProps.props.highlightAssociation = componentProps.props.highlightAssociation || {};
                componentProps.props.highlightAssociation.parentAssociation = componentProps.props.highlightAssociation.parentAssociation || [];

                const universalCompClone = universalCompState;
                const parentCompId = universalCompState?.parent?.type !== 'Select' ? universalCompClone?.parent?.id : universalCompClone?.option?.id;
                const findParentIndex = componentProps.props.highlightAssociation?.parentAssociation?.findIndex((c) => c === parentCompId);
                const isUniversalComp = componentProps?.props?.highlightComponent;

                if ((findColIndex === 2 && !universalCompState.parent) || (universalCompState?.parentColIndex > findColIndex)) return;
                const flatCompList = compList.filter((c) => c.type !== 'Row' && c.type !== 'Column' && c.type !== 'Protocol');
                if (findColIndex === universalCompState.parentColIndex) {
                    sameColumnClick(flatCompList, universalCompClone, isUniversalComp, componentProps, rowComp);
                }
                if (!universalCompState?.parent) {
                    universalCompState.parent = componentProps;
                    universalCompState.parentColIndex = findColIndex;
                } else if (universalCompState?.parent?.colRef === componentProps?.colRef
                    || findColIndex < universalCompState?.parentColIndex) {
                    flatCompList.forEach((fc) => {
                        if (workshopActiveComponent?.props?.highlightAssociation?.parentAssociation?.includes(fc.id)
                            && workshopActiveComponent.id === universalCompState.parent.id) {
                            fc.props.highlightComponent = !(universalCompState.parentColIndex === 1
                                && fc?.props?.highlightAssociation?.parentAssociation?.length === 0);
                        } else if (Object.prototype.hasOwnProperty.call(fc?.props, 'highlightComponent')) {
                            delete fc.props.highlightComponent;
                        }
                    });
                } else if (findColIndex !== universalCompState?.parentColIndex + 1) return;

                if (findColIndex === universalCompState?.parentColIndex + 1) differentColumnClick(findParentIndex, componentProps, rowComp, compList);

                const reqObj = {
                    findColIndex, componentProps, component, flatOptionData1,
                };
                removeCompColor(reqObj);
                setComponentState(component);
                setUpdateFlag(!updateFlag);
            }
            break;
        case 'Select':
            {
                const compList = [];

                if (!universalCompState?.component && findColIndex === 0) {
                    universalCompState.component = componentProps;
                    universalCompState.parent = componentProps;
                    universalCompState.option = data[index];
                    universalCompState.parentColIndex = findColIndex;
                } else if (!universalCompState?.component && !universalCompState.parent && findColIndex > 0
                    || findColIndex === -1 || findColIndex === undefined) {
                    return;
                }
                buildSettingsTreePage(compList, rowComp);
                const obj = {
                    index,
                    compList,
                    findColIndex,
                    data,
                    rowComp,
                    componentProps,
                };
                if (universalCompState.component) {
                    universalCompState.option.highlightAssociation = universalCompState?.option?.highlightAssociation || {};
                    data[index].highlightAssociation = data[index].highlightAssociation || {};
                    data[index].highlightAssociation.parentAssociation = data[index].highlightAssociation.parentAssociation || [];
                    data[index].highlightAssociation.childAssociation = data[index].highlightAssociation.childAssociation || [];
                }
                switch (findColIndex) {
                case 0:
                    universalCompState.option = data[index];
                    universalCompState.child = [];
                    universalCompState.option.highlightAssociation.childAssociation.forEach((c) => {
                        universalCompState.child.push({ id: c });
                    });
                    setUniversalScndState({});
                    handleFirstColumn(obj);
                    break;
                case 1:
                    handleSecondColumn(obj);
                    break;
                case 2:
                    handleThirdColumn(obj);
                    break;
                default:
                    break;
                }
                setUniversalCompState({ ...universalCompState });
            }
            break;
        default:
            break;
        }
    };

    // Manual selection functionality code for checkbox/Textarea Components starts here
    const findScndStateTimelineComp = (scndColWSComp, rowComp) => {
        const scndColTimelineCells = rowComp.props.components[1].props.components.filter((c) => c.isTimeline);
        let targetedTimelineCell = {};
        let targetedTimelineComp = {};
        if (universalCompState.parent.type === 'Select') {
            targetedTimelineCell = scndColTimelineCells.find((cell) => cell?.id === universalCompState.option.props.associatedCellTimeline);
            if (targetedTimelineCell?.props?.components[0]?.type === 'Select') {
                // eslint-disable-next-line prefer-destructuring
                targetedTimelineComp = targetedTimelineCell.props.components[0];
            } else {
                targetedTimelineComp = targetedTimelineCell.props.components.find((comp) => comp?.id === scndColWSComp.id);
            }
        } else if (scndColWSComp.type !== 'Select') {
            targetedTimelineCell = scndColTimelineCells.find((cell) => cell?.props?.associatedComponent === universalCompState.parent.id);
            targetedTimelineComp = targetedTimelineCell.props.components.find((comp) => comp?.id === scndColWSComp.id);
        } else {
            targetedTimelineCell = scndColTimelineCells.find((cell) => cell?.props?.associatedComponent === universalCompState.parent.id);
            // eslint-disable-next-line prefer-destructuring
            targetedTimelineComp = targetedTimelineCell.props.components[0];
        }
        return targetedTimelineComp;
    };

    const checkManualinScndColumn = (findColIndex, rowComp, componentProps) => {
        let status = false;
        let currentTimelineCell = {};
        if (universalCompState.parent.type === 'Select') {
            currentTimelineCell = rowComp.props.components[findColIndex].props.components.find((c) => (
                c.isTimeline && c?.id === universalCompState?.option?.props?.associatedCellTimeline));
        } else {
            currentTimelineCell = rowComp.props.components[findColIndex].props.components.find((c) => (
                c.isTimeline && c?.props?.associatedComponent === universalCompState?.parent?.id));
        }
        const comps = currentTimelineCell.props.components || [];
        for (let j = 0, m = comps.length; j < m; j += 1) {
            if (comps[j].id === componentProps.id) {
                status = true;
                break;
            }
        }
        return status;
    };

    const sameColumnClick = (flatCompList, universalCompClone, isUniversalComp, componentProps, rowComp) => {
        const { parentColIndex = -1 } = universalCompState;
        if (componentProps.id !== universalCompState.parent.id) {
            universalCompClone.child = null;
            universalCompClone.parent = componentProps;
            setUniversalCompState(universalCompClone);
        } else if (componentProps.id === universalCompState.parent.id && isUniversalComp) {
            componentProps.props.highlightAssociation = {};
            flatCompList.forEach((fc) => {
                let findParentIndexInChild = -1;
                findParentIndexInChild = fc.props.highlightAssociation?.parentAssociation?.findIndex((id) => id === componentProps.id);
                if (findParentIndexInChild !== -1 && fc.props?.highlightAssociation?.parentAssociation?.length > 0) {
                    fc.props.highlightAssociation.parentAssociation.splice(findParentIndexInChild, 1);
                }
            });
            const associatedCell = flatCompList.find((c) => c?.props?.associatedComponent === componentProps.id && c.isTimeline);
            if (associatedCell) {
                const isSelect = associatedCell.props.components[0].type === 'Select';
                const selectComp = associatedCell.props.components[0];
                if (isSelect) {
                    selectComp.props.options.forEach((o) => {
                        const clrIndex = o.highlightAssociation.parentAssociation.findIndex((c) => c.optionId === componentProps.id);
                        o.highlightAssociation.parentAssociation.splice(clrIndex, 1);
                    });
                }
            }
            const timelineCells = rowComp.props.components[parentColIndex + 1].props.components.filter((c) => c.isTimeline);
            const targetedTimelineCell = timelineCells.find((cell) => cell?.props?.associatedComponent === universalCompState.parent.id);
            const associatedCellIds = [];
            targetedTimelineCell.props.components.forEach((c) => associatedCellIds.push(c.props.associatedCellTimeline));
            if (parentColIndex === 0) {
                // deleting 3rd column associated cells when childrens of first column component gets unhighlighted
                const scndNextColWSCells = rowComp?.props?.components?.[parentColIndex + 2]?.props?.components.filter((c) => !c.isTimeline);
                const scndNextTimelineCells = rowComp?.props?.components?.[parentColIndex + 2]?.props?.components.filter((c) => c.isTimeline);
                const newEmptyCellIndex = scndNextTimelineCells && scndNextTimelineCells.findIndex((c) => associatedCellIds.includes(c.id));
                let i = 0;
                let l = scndNextTimelineCells?.length;
                while (i < l && l > 0) {
                    if (associatedCellIds.includes(scndNextTimelineCells?.[i]?.id)) {
                        scndNextTimelineCells.splice(i, 1);
                        l -= 1;
                    } else {
                        i += 1;
                    }
                }
                const newEmptyCell = createEmptyCell({
                    secondNextCol: rowComp.props.components[2],
                    associatedComponent: universalCompState.parent,
                    nextCol: rowComp.props.components[1],
                    parentComp: rowComp.parent[0],
                    isTimeline: true,
                    indexValue: -1,
                });
                if (newEmptyCellIndex > -1 && scndNextTimelineCells) {
                    scndNextTimelineCells.splice(newEmptyCellIndex, 0, newEmptyCell);
                }
                if (rowComp?.props?.components?.[parentColIndex + 2]?.props?.components) {
                    rowComp.props.components[parentColIndex + 2].props.components = [...scndNextColWSCells, ...scndNextTimelineCells];
                }
            }
            if (targetedTimelineCell?.props?.components?.[0].type === 'Select' && targetedTimelineCell?.props?.components?.[0]?.props?.options) {
                targetedTimelineCell.props.components[0].props.options = [];
            } else {
                targetedTimelineCell.props.components = [];
            }
            setUniversalScndState({});
        }
    };

    const differentColumnClick = (findParentIndex, componentProps, rowComp) => {
        const { parentColIndex = -1 } = universalCompState;
        const nextColWSCells = rowComp.props.components[parentColIndex + 1].props.components.filter((c) => !c.isTimeline);
        const nextColTimelineCells = rowComp.props.components[parentColIndex + 1].props.components.filter((c) => c.isTimeline);
        let scndNextColWSCells = [];
        let scndNextColTimelineCells = [];
        if (parentColIndex === 0) {
            scndNextColWSCells = rowComp?.props?.components?.[parentColIndex + 2]?.props?.components.filter((c) => !c.isTimeline);
            scndNextColTimelineCells = rowComp?.props?.components?.[parentColIndex + 2]?.props?.components.filter((c) => c.isTimeline);
        }
        universalCompState.child = universalCompState.child ? universalCompState.child : [];
        const findCompIndex = universalCompState.child?.findIndex((c) => c.id === componentProps.id);
        // to verify
        const assoicatedTCellId = universalCompState.parent.type !== 'Select'
            ? universalCompState.parent.id : universalCompState?.option?.props?.associatedCellTimeline;

        let targetedScndTimelineCell = nextColTimelineCells.find((cell) => cell?.props?.associatedComponent === assoicatedTCellId);

        if (!targetedScndTimelineCell && universalCompState.parent.type === 'Select') {
            targetedScndTimelineCell = nextColTimelineCells.find((cell) => cell?.id === assoicatedTCellId);
        }

        if (findCompIndex === -1 || findCompIndex === undefined) {
            if (componentProps.props.highlightComponent && workshopActiveComponent.id === componentProps.id) {
                // delete saved manual selected childrens( elements and cells )
                const existCompIndex = targetedScndTimelineCell.props.components.findIndex((c) => c.id === componentProps.id);
                const existCellIndex = scndNextColTimelineCells?.findIndex((c) => c.props.associatedComponent === componentProps.id);
                targetedScndTimelineCell.props.components.splice(existCompIndex, 1);
                if (handleManualSelection && scndNextColTimelineCells) {
                    scndNextColTimelineCells.splice(existCellIndex, 1);
                }
                componentProps.props.highlightAssociation.parentAssociation.splice(findParentIndex, 1);
                setUniversalScndState({});
            } else {
                const associateParentId = universalCompState.parent.type !== 'Select' ? universalCompState.parent.id : universalCompState.option.id;
                componentProps.props.highlightAssociation.parentAssociation.push(associateParentId);
                universalCompState.child.push(componentProps);
                // doing deep copy
                const clonedComp = deepCopy(componentProps);
                clonedComp.associatedParent = associateParentId;
                const nextCol = rowComp.props.components[2];
                const parentComp = rowComp.parent[0];
                const emptyCell = createCell({
                    associatedComponent: clonedComp, nextCol, parentComp, isCell: false, mainComp: null, LRProps: null,
                });
                emptyCell.isTimeline = true;
                emptyCell.associatedComponentParent = targetedScndTimelineCell.id;
                // keeping props in shallow copy except "associatedCellTimeline" id & "childAssociation"
                // because manual childrens will may different for every component
                clonedComp.props = {
                    ...componentProps.props,
                    associatedCellTimeline: emptyCell.id,
                    highlightAssociation: { ...componentProps.props.highlightAssociation, childAssociation: [] },
                };
                // inserting component copy in second column and empty cell in third column
                targetedScndTimelineCell.props.components.push(clonedComp);
                let replaceIndex = scndNextColTimelineCells && scndNextColTimelineCells.findIndex((c) => c.type === 'EmptyCell'
                && c?.props?.associatedComponent === universalCompState.parent.id);

                // First column single select cb
                if (universalCompState.parent.type === 'Select' && replaceIndex === -1) {
                    replaceIndex = scndNextColTimelineCells.findIndex((c) => c.type === 'EmptyCell'
                        && c?.props?.associateComponentOptionId === universalCompState.option.id);
                }
                if (replaceIndex > -1) {
                    scndNextColTimelineCells[replaceIndex] = emptyCell;
                    const compObj = {
                        rowComp,
                        emptyCell,
                        componentProps,
                        clonedComp,
                    };
                    createSelectComp(compObj);
                } else {
                    let compsCount = 0;
                    for (let i = 0, l = nextColTimelineCells.length; i < l; i += 1) {
                        const grandId = universalCompState.parent.type !== 'Select'
                            ? universalCompState.parent.props.associatedCellTimeline : universalCompState.option.props.associatedCellTimeline;
                        if (grandId !== nextColTimelineCells[i].id) {
                            if (nextColTimelineCells[i].props.components.length > 0) {
                                compsCount += nextColTimelineCells[i].props.components.length;
                            } else {
                                compsCount += 1;
                            }
                        } else {
                            break;
                        }
                    }
                    compsCount += targetedScndTimelineCell.props.components.length;
                    if (scndNextColTimelineCells) {
                        scndNextColTimelineCells.splice(compsCount - 1, 0, emptyCell);
                    }
                    const compObj = {
                        rowComp,
                        emptyCell,
                        componentProps,
                        clonedComp,
                    };
                    createSelectComp(compObj);
                }
            }
        } else {
            // delete manual selected elements and cells which are not saved yet
            universalCompState.child.splice(findCompIndex, 1);
            componentProps.props.highlightAssociation.parentAssociation.splice(findParentIndex, 1);
            const existCompIndex = targetedScndTimelineCell.props.components.findIndex((c) => c.id === componentProps.id);

            const scndTLComp = findScndStateTimelineComp(componentProps, rowComp);
            const existCellIndex = scndNextColTimelineCells && scndNextColTimelineCells
                .findIndex((c) => c.props.associatedComponent === componentProps.id && scndTLComp.props.associatedCellTimeline === c.id);

            targetedScndTimelineCell.props.components.splice(existCompIndex, 1);
            if (scndNextColTimelineCells) {
                scndNextColTimelineCells.splice(existCellIndex, 1);
            }
            setUniversalScndState({});
        }

        if (universalCompState?.parent?.type === 'Select') {
            universalCompState.option.highlightAssociation.childAssociation = universalCompState?.child.map((c) => c.id);
        } else {
            universalCompState.parent.props.highlightAssociation.childAssociation = universalCompState?.child.map((c) => c.id);
        }
        rowComp.props.components[parentColIndex + 1].props.components = [...nextColWSCells, ...nextColTimelineCells];
        if ((parentColIndex === 0 || scndNextColTimelineCells > 0) && rowComp?.props?.components?.[2]?.props?.components) {
            rowComp.props.components[2].props.components = [...scndNextColWSCells, ...scndNextColTimelineCells];
        }
    };

    const thirdColumnDoubleClciked = (componentProps, rowComp) => {
        const thirdColWSCells = rowComp.props.components[2].props.components.filter((c) => !c.isTimeline);
        const thirdColTimelineCells = rowComp.props.components[2].props.components.filter((c) => c.isTimeline);
        const scndColTLComp = findScndStateTimelineComp(universalScndState.parent, rowComp);
        const targetedThirdTLCell = thirdColTimelineCells.find((cell) => (cell.id === scndColTLComp?.props?.associatedCellTimeline));
        let isExistIndex = -1;
        let optionCell = {};
        if (targetedThirdTLCell.type === 'SelectCell') {
            optionCell = targetedThirdTLCell.props.components
                .find((optionCell) => optionCell.props.associateOptionId === universalScndState.option.id);
            isExistIndex = optionCell?.props.components
                .findIndex((c) => c.id === componentProps.id);
        } else {
            isExistIndex = targetedThirdTLCell.props.components.findIndex((c) => c.id === componentProps.id);
        }

        if (isExistIndex !== -1) {
            // removing element if already is there
            if (universalScndState.parent.type !== 'Select') {
                targetedThirdTLCell.props.components.splice(isExistIndex, 1);
                const colorIndex = scndColTLComp.props.highlightAssociation.childAssociation.findIndex((c) => c === componentProps.id);
                if (colorIndex !== -1) {
                    scndColTLComp.props.highlightAssociation.childAssociation.splice(colorIndex, 1);
                }
            } else {
                optionCell.props.components.splice(isExistIndex, 1);
                const scndColTLCompOption = scndColTLComp.props.options.find((o) => o.id === universalScndState.option.id);
                const colorIndex = scndColTLCompOption.highlightAssociation.childAssociation.findIndex((c) => c === componentProps.id);
                if (colorIndex !== -1) {
                    scndColTLCompOption.highlightAssociation.childAssociation.splice(colorIndex, 1);
                    universalScndState.child = scndColTLCompOption.highlightAssociation?.childAssociation || [];
                }
            }
        } else {
            // doing deep copy but keeping props in shallow
            const clonedComp = deepCopy(componentProps);
            clonedComp.props = componentProps.props;

            if (universalScndState.parent.type !== 'Select') {
                clonedComp.associatedParent = universalScndState.parent.id;
                targetedThirdTLCell.props.components.push(clonedComp);
                scndColTLComp.props.highlightAssociation.childAssociation.push(componentProps.id);
            } else {
                universalScndState.option.highlightAssociation.childAssociation = universalScndState
                    .option?.highlightAssociation?.childAssociation || [];
                clonedComp.associatedParent = universalScndState.option.id;
                const findOptionCell = targetedThirdTLCell.props.components.find((o) => o.props.associateOptionId === universalScndState.option.id);
                findOptionCell.props.components.push(clonedComp);
                // don't remove comment code
                // if (universalCompState?.parent.type === 'Select') {
                const findIOption = scndColTLComp.props.options.findIndex((o) => o.id === universalScndState.option.id);
                const scndColTLCompOption = deepCopy(scndColTLComp.props.options[findIOption]);
                scndColTLCompOption.highlightAssociation.childAssociation.push(componentProps.id);
                scndColTLComp.props.options[findIOption] = scndColTLCompOption;
                universalScndState.child = scndColTLCompOption.highlightAssociation.childAssociation || [];
                // } else {
                //     const scndTLComp = findScndStateTimelineComp(universalScndState.parent, rowComp);
                //     const scndColTLCompOption = scndTLComp.props.options.find((o) => o.id === universalScndState.option.id);
                //     // inserting id for colour
                //     scndColTLCompOption.highlightAssociation.childAssociation.push(componentProps.id);
                //     universalScndState.child = scndColTLCompOption.highlightAssociation.childAssociation || [];
                // }
            }
        }
        rowComp.props.components[2].props.components = [...thirdColWSCells, ...thirdColTimelineCells];
        setUpdateFlag(!updateFlag);
    };

    const handleSingleClick = (componentProps, isUniversalManual, rowComp) => {
        if (isUniversalManual && universalCompState?.parentColIndex === 0 && !componentProps?.props?.LRProps) {
            const currentColIndex = rowComp?.props?.components?.findIndex((c) => c.id === componentProps?.colRef);
            if (currentColIndex === 1 && checkManualinScndColumn(currentColIndex, rowComp, componentProps,)) {
                const scndColTLComp = findScndStateTimelineComp(componentProps, rowComp);
                const scndStateClone = {
                    parent: componentProps,
                    child: scndColTLComp?.props?.highlightAssociation?.childAssociation,
                    parentColIndex: currentColIndex,
                };
                setUniversalScndState(scndStateClone);
                setUpdateFlag(!updateFlag);
            }
        }
    };

    const handleSingleClickSSCB = (props) => {
        const {
            rowComp, data, index, componentProps,
        } = props;
        const findColIndex = rowComp?.props?.components?.findIndex((c) => c.id === componentProps?.colRef);
        if (data[index].highlightComponent && findColIndex === 1 && universalScndState?.option?.id !== data[index].id) {
            const scndColTLComp = findScndStateTimelineComp(componentProps, rowComp);
            universalScndState.parent = componentProps;
            universalScndState.option = data[index];
            universalScndState.parentOption = universalCompState?.option;
            if (data[index].highlightAssociation?.childAssociation?.[0]?.grandOptionId) {
                universalScndState.child = data[index].highlightAssociation?.childAssociation?.
                    filter((o) => ((o.grandOptionId === universalCompState?.option?.id)
                    || (o.grandOptionId === universalCompState?.parent?.id)))?.map((o) => o.optionId) || [];
            } else {
                const scndColTLCompOption = scndColTLComp.props.options.find((o) => o.id === universalScndState.option.id);
                universalScndState.child = scndColTLCompOption.highlightAssociation?.childAssociation || [];
            }
            setUniversalScndState({ ...universalScndState });
        }
    };

    // manual selection code for checkbox/Textarea ends here

    // export all function
    const universalContext = {
        handleManualSelection,
        handleSingleClick,
        handleSingleClickSSCB,
        universalCompState,
        universalScndState,
        setUniversalCompState,
        setUniversalScndState,
    };

    // pass the value in provider and return
    return (
        <div>
            <Context.Provider value={universalContext}>
                {children}
            </Context.Provider>
        </div>
    );
};

export const { Consumer } = Context;
