import { Container, ContainerPreview } from './Container';
import { DefaultChildren, DefaultChildrenPreview } from './DefaultChildren';
import { TimelineContext, WorkshopContext } from '../context';
import { componentWithId } from './Atoms';
import { makeStyles } from '@material-ui/core/styles';
import { useRecoilState } from 'recoil';
import Grid from '@material-ui/core/Grid';
import React, { useContext } from 'react';

const useStyles = makeStyles(() => ({
    column: {
        padding: 0,
        marginRight: '-2px',
    },
    formControl: {
        margin: '10px',
        width: '95%',
    },
    adminContainerCls: {
        borderTop: '.25px solid #cbcbcb',
    },
    timelineConatinerCls: {
        borderTop: 'none',
    },
    redBg: {
        backgroundColor: '#ff8a8a',
    },
    greenBg: {
        backgroundColor: '#8cf98c',
    },
}));

const DefaultComponentLayout = ({
    component,
    children,
    preview,
    handleDoubleClick,
}) => {
    const classes = useStyles();
    const { inWorkshop } = useContext(WorkshopContext);
    const { activeRow } = useContext(TimelineContext);
    const hasChildren = component.props.components && component.props.components.length > 0;
    let [rowComp] = useRecoilState(
        componentWithId(component?.parent?.length > 0 ? component?.parent[0]?.rowRef : '')
    );
    if (rowComp.props === undefined) {
        rowComp = activeRow;
    }
    const isWowFeature = rowComp?.props?.components?.some((c) => c.props['wow feature'] === 'yes') && !inWorkshop;
    if (component.props.display === 'tree') {
        return (
            <div className={[isWowFeature ? component.props.isCompleted === 'inComplete'
                ? classes.redBg : component.props.isCompleted === 'completed' ? classes.greenBg : '' : ''].join(' , ')}
            >
                {children}
                {preview ? <DefaultChildrenPreview indent components={component.props.components} />
                    : <DefaultChildren indent components={component.props.components} id={component.id} />}
            </div>
        );
    }
    return (
        <Grid container className={preview ? classes.adminContainerCls : classes.timelineConatinerCls}>
            <Grid
                item
                xs
                className={[classes.column, isWowFeature ? component.props.isCompleted === 'inComplete' ? classes.redBg
                    : component.props.isCompleted === 'completed' ? classes.greenBg : '' : ''].join(' , ')}
                onClick={handleDoubleClick}
            >
                {children}

            </Grid>
            {hasChildren && (
                <Grid
                    item
                    xs
                    className={[classes.column, isWowFeature ? component.props.isCompleted === 'inComplete'
                        ? classes.redBg : component.props.isCompleted === 'completed' ? classes.greenBg : '' : ''].join(' , ')}
                >
                    {preview ? <ContainerPreview props={{ components: component.props.components }} />
                        : <Container props={{ components: component.props.components }} />}
                </Grid>
            )}
        </Grid>
    );
};

export default DefaultComponentLayout;
