/* eslint-disable react-hooks/exhaustive-deps */
import { FilterSettings } from '../helper/commonHelper';
import { Settings } from './Settings';
import { TimelineContext } from '../context';
import { componentWithId } from './Atoms';
import { useRecoilState } from 'recoil';
import React, { useContext, useEffect } from 'react';

export const ProtocolProps = {
    display: [
        {
            key: 'name',
            type: 'TextField',
            label: 'Timeline Title',
            id: 'Timeline',
        },
        {
            key: 'showProgression',
            type: 'MultiCB',
            label: 'Show Column',
            default: true,
            id: 'Timeline',
            data: [],
            showTitle: 'title',
        },
        {
            key: 'showProgressionSection',
            type: 'MultiCB',
            label: 'Show Progression Section',
            default: true,
            data: [],
            id: 'Timeline',
            showTitle: 'title',
        },
        {
            key: 'showProtocol',
            type: 'Checkbox',
            label: 'Show Protocol Section',
            default: true,
            id: 'Timeline',
        },
        {
            key: 'width',
            type: 'TextField',
            label: 'Width (px)',
            id: 'Timeline',
        },
        {
            key: 'industries',
            type: 'StaticLabel',
            label: 'Industry',
            id: 'Timeline',
        },
        {
            key: 'sub_specialities',
            type: 'StaticLabel',
            label: 'Speciality',
            id: 'Timeline',
        },
    ],
    poi: false,
};

const TimelineEditor = ({ config, updateComponent }) => {
    const { props } = config;
    if (Object.prototype.hasOwnProperty.call(config, 'industries')) {
        props.industries = config?.industries || 'N/A';
    }
    if (Object.prototype.hasOwnProperty.call(config, 'sub_specialities')) {
        props.sub_specialities = config?.['sub_specialities'] || 'N/A';
    }
    const {
        settingMenuType,
    } = useContext(TimelineContext);
    useEffect(() => {
        props.name = config.name;
    }, [config.id]);
    const filterImage = (displayConfig) => {
        const hasImage = (config?.name === 'Images/Videos');

        if (!hasImage) {
            displayConfig = displayConfig.filter((obj) => obj?.type !== 'exportURL');
        }
        return displayConfig;
    };
    const [component, setComponentState] = useRecoilState(componentWithId(config.id, props, null));
    const timelineEditorProps = { display: filterImage(FilterSettings(ProtocolProps, settingMenuType)), poi: ProtocolProps.poi };

    const saveComponent = () => {
        config.name = component.props.name;
        updateComponent();
    };

    return (
        <Settings editorProps={timelineEditorProps} component={component} setComponentState={setComponentState} saveComponent={saveComponent} />
    );
};

export { TimelineEditor };
