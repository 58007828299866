import React, {
    createContext, useReducer, useState,
} from 'react';

export const Context = createContext({});
const initialState = { filteredTemplates: null, previewTemplate: null };

function addTemplateReducer(state, action) {
    switch (action.type) {
    case 'setLibrary': {
        const selected = action.templateOptions && action.templateOptions.length > 0 ? action.templateOptions[0] : undefined;
        return { filteredTemplates: action.templateOptions, selectedTemplate: selected, previewTemplate: null };
    }
    case 'preview':
        return { ...state, previewTemplate: action.template };
    case 'selectTemplate':
        return { ...state, selectedTemplate: action.template };
    case 'commitTemplate':
        return initialState;
    default:
        throw new Error('addTemplateReducer needs a valid action type', action);
    }
}

export const Provider = ({ children }) => {
    const [addTemplateState, dispatchAddTemplate] = useReducer(addTemplateReducer, initialState);
    const [compareModal, setCompareModal] = useState(false);
    const inWorkshop = true;

    const commitTemplate = (template) => {
        // console.log('commitTemplate', template);
        dispatchAddTemplate({ type: 'commitTemplate', template });
    };

    const setPreviewTemplate = (template) => {
        // console.log('setPreviewTemplate', template);
        dispatchAddTemplate({ type: 'preview', template });
    };

    const setSelectTemplate = (template) => {
        // console.log('setPreviewTemplate', template);
        dispatchAddTemplate({ type: 'selectTemplate', template });
    };

    const addTemplateFromLibrary = (library) => {
        dispatchAddTemplate({ type: 'setLibrary', templateOptions: library });
    };

    const workshopContext = {
        addTemplateFromLibrary,
        setPreviewTemplate,
        commitTemplate,
        setSelectTemplate,
        ...addTemplateState,
        inWorkshop,
        compareModal,
        setCompareModal,
    };

    // pass the value in provider and return
    return <Context.Provider value={workshopContext}>{children}</Context.Provider>;
};

export const { Consumer } = Context;
