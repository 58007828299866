import { Container, GridList, GridListTile } from '@material-ui/core';
import { MediaEditorContext } from '../context';
import {
    createPlayer, getFileType, isVideo, randomString,
} from '../helper/commonHelper';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useContext, useEffect } from 'react';

/**
 * @function useStyles
 * @description css for recent media
 * @returns object
 */
const useStyles = makeStyles(() => createStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
    },
    parentContainer: {
        margin: 0,
        padding: 0,
    },
    gridList: {
        flexWrap: 'nowrap',
        transform: 'translateZ(0)',
    },
    title: {
        color: 'red',
    },
    checkBoxPosition: {
        position: 'relative',
        zIndex: '0',
        width: 'auto',
        float: 'left',
        marginLeft: '10px',
    },
    videoStyle: {
        height: '170px',
        cursor: 'pointer',
        position: 'relative',
        '&:hover': {
            transform: 'scale(0.92)',
            border: '4px solid #CEAA5766',
        },
    },
    selectedImg: {
        border: '4px solid #CEAA57',
        borderRadius: '4px',
    },
    videoEditable: {
        height: '100%',
        width: '100%',
        position: 'absolute',
        cursor: 'pointer',
    },
    videoHover: {
        '&:hover': {
            transform: 'scale(0.92)',
            border: '4px solid #CEAA5766',
        },
    },
}));

/**
 * @function RecentUploadedMedia
 * @description create the modal pop. Taking props from parent and pass props to child component
 * @param onSelectedMedia, selectedMedia
 */
const RecentUploadedMedia = ({ onSelectedMedia, selectedMedia, mediaCompId }) => {
    const classes = useStyles();
    const { recentUploadedContext } = useContext(MediaEditorContext);
    let finalUploadedMedia = [];
    const recentMedia = (!recentUploadedContext) ? [] : recentUploadedContext;

    /**
     * @function onChange
     * @description pass the selected media to the parent method
     * @param media details with checked status
     */
    const onChange = (fileValue, evt) => {
        evt.stopPropagation();
        onSelectedMedia([fileValue]);
    };

    /**
     * @description check selected media with comming media from server
     */
    if (recentMedia.length > 0) {
        finalUploadedMedia = [...recentMedia];

        /**
         * Create the dynamic id and set it into the array
         */
        finalUploadedMedia.forEach((recent, key) => {
            if (isVideo(recent.name)) {
                const rs = randomString(5);
                recent.videoDivId = `video-div-${rs}-${key}`;
                recent.isVideo = true;
            } else {
                recent.isVideo = false;
            }
        });
    }

    finalUploadedMedia.forEach((media, key) => {
        if (isVideo(media.name)) {
            media.filetype = getFileType(media.name);
            media.videoDivId = `video-div-recent-${mediaCompId}-${key}`;
            media.isVideo = true;
        }
    });

    const renderRecentUploadedMedia = () => {
        if (finalUploadedMedia !== undefined && finalUploadedMedia.length > 0) {
            return (
                <GridList className={classes.gridList} cols={3}>
                    {finalUploadedMedia.map((recentImg, index) => (
                        <div
                            aria-hidden="true"
                            key={recentImg.id + index}
                            className={classes.checkBoxPosition}
                            onClick={(e) => onChange(recentImg, e)}
                        >
                            <GridListTile
                                className={(selectedMedia.length > 0 && (selectedMedia[0].url === recentImg.url)) ? classes.selectedImg : ''}
                            >
                                {recentImg.isVideo
                                    ? (
                                        <div className={classes.videoHover}>
                                            <div className={classes.videoEditable} />
                                            <div id={recentImg.videoDivId} />
                                        </div>
                                    )
                                    : (
                                        <div>
                                            <img
                                                className={classes.videoStyle}
                                                src={recentImg.url}
                                                alt={recentImg.name}
                                            />
                                        </div>
                                    )}
                            </GridListTile>
                        </div>
                    ))}
                </GridList>
            );
        }
        return 'Recently no image uploaded, First upload the image.';
    };

    useEffect(() => {
        createPlayer(finalUploadedMedia, false);
    });

    return (
        <Container maxWidth="md" className={classes.parentContainer}>
            <h2>Recent Uploaded Media</h2>
            {renderRecentUploadedMedia()}
        </Container>
    );
};

export default RecentUploadedMedia;
