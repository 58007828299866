import { MediaEditorContext } from '../context';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import React, { useContext, useState } from 'react';
import RecentUploadedMedia from './RecentUploadedMedia';
import UploadMedia from './UploadMedia';

/**
 * @function getModalStyle
 * @description set the margin
 * @param None
 * @returns {object}
 */
function getModalStyle() {
    const top = 45;
    const left = 45;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

/**
 * @function useStyles
 * @description css for modal popup
 * @returns {object}
*/
const useStyles = makeStyles((theme) => createStyles({
    paper: {
        position: 'absolute',
        width: '60%',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    closeIcon: {
        cursor: 'pointer',
    },
    modalHeader: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '20px',
        fontWeight: '500',
    },
    modalCancel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
}));

/**
 * @constructor EditorModal
 * @description create the modal pop. Taking props from parent and pass props to child component
 * @param {onImagesSelected}
 * @param {mediaList}
 * @param {handleClose}
 * @param {open}
*/
const EditorModal = ({
    onImagesSelected, mediaList, handleClose, open, mediaCompId,
}) => {
    const classes = useStyles();
    const [modalStyle] = useState(getModalStyle);
    const [newlyUploadedMedia, setNewlyUploadedMedia] = useState([]);
    const { deletedMediaContext } = useContext(MediaEditorContext);
    const mediaContext = (!deletedMediaContext) ? [] : deletedMediaContext;

    if (mediaContext.length > 0) {
        mediaList.forEach((data) => {
            const status = mediaContext.filter((ele) => data.url === ele.url);

            if (status.length > 0) {
                data.isShow = false;
            } else {
                data.isShow = true;
            }
        });
    }
    const [selectedMedia, setSelectedMedia] = useState(mediaList);

    /**
    * @function onSelectedMedia
    * @description handle checked/unchecked media
    * @param {object} media
    * @param {boolean} isChecked
    */
    const onSelectedMedia = (media) => {
        setSelectedMedia(media);
        onImagesSelected(media);
    };

    /**
     * @function passUploadedMedia
     * @description set recent and newly uploaded into one
     * @param {object} recentUploadMedia
     */
    const passUploadedMedia = (recentUploadMedia) => {
        setNewlyUploadedMedia([...newlyUploadedMedia, ...recentUploadMedia]);
    };

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <Grid container>
                <Grid item md={11} lg={11} className={classes.modalHeader}>
                    <p id="simple-modal-title">Media Manager</p>
                </Grid>
                <Grid item md={1} lg={1} className={classes.modalCancel}>
                    <CloseIcon onClick={handleClose} className={classes.closeIcon} />
                </Grid>
                <Grid item md={12} lg={12}>
                    <UploadMedia
                        passUploadedImage={passUploadedMedia}
                        handleClose={handleClose}
                        onSelectedMedia={onSelectedMedia}
                    />
                </Grid>
                <Grid item md={12} lg={12}>
                    <RecentUploadedMedia
                        newlyUploadedMedia={newlyUploadedMedia}
                        onSelectedMedia={onSelectedMedia}
                        selectedMedia={selectedMedia}
                        mediaCompId={mediaCompId}
                    />
                </Grid>
            </Grid>
        </div>
    );

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </div>
    );
};

export default EditorModal;
