import { Column, ColumnPreview } from './Column';
import { MenuDefaults, buildComponentMenu } from './utils/EditorMenu';
import { Settings, makeInlineEditor } from './Settings';
import { WorkshopContext } from '../context';
import { componentWithId } from './Atoms';
import { makeStyles } from '@material-ui/core/styles';
import { useRecoilState } from 'recoil';
import Grid from '@material-ui/core/Grid';
import React, { useContext } from 'react';
import Typography from '@material-ui/core/Typography';

const useRowStyles = makeStyles(() => ({
    column: {
        border: '1px solid #ececec',
        padding: 0,
    },
    formControl: {
        margin: '10px',
        width: '95%',
    },
    groupHeader: {
        border: '1px solid #ececec',
        padding: '4px',
        textDecoration: 'underline',
    },
    columHeadType: {
        fontSize: '0.75rem',
    },
    hideTextCls: {
        background: '#DEDEDE',
    },
}));

const TitleProp = {
    key: 'title',
    type: 'TextField',
    label: 'Progression Title',
};

const GroupTitleProp = {
    key: 'groupTitle',
    type: 'TextField',
    label: 'Progression Group Title',
    id: 'Progression',
};

export const ProgressionProps = {
    display: [
        {
            type: 'GroupSeparator',
            key: 'ProgressionGroup',
            label: 'Progression Group',
            id: 'Progression',
        },
        GroupTitleProp,
        {
            key: 'hasGroupHeader',
            type: 'Checkbox',
            label: 'Show Progression Group Header',
            default: false,
            id: 'Progression',
        },
        {
            type: 'GroupSeparator',
            key: 'GroupSeparator',
            label: 'Progression',
            id: 'Progression',
        },
        TitleProp,
        {
            key: 'showTitle',
            type: 'Checkbox',
            label: 'Show Progression Title',
            default: true,
            id: 'Progression',
        },
        {
            key: 'layout',
            type: 'Select',
            label: 'Column Layout',
            default: 'default',
            id: 'Progression',
            options: [{ label: 'Default', value: 'default' }, { label: 'Stacked', value: 'stacked' }],
        },
    ],
    poi: true,
};

const Progression = (progression) => {
    const {
        id,
    } = progression;
    const [component, setComponentState] = useRecoilState(componentWithId(id));

    const { inWorkshop, compareModal } = useContext(WorkshopContext);
    const {
        components: columns, title, groupTitle, hasGroupHeader, showTitle = true,
    } = component.props;
    const classes = useRowStyles();

    const direction = component.props.layout === 'stacked' ? 'column' : 'row';

    const renderTitleEditor = () => makeInlineEditor({
        field: TitleProp,
        component,
        setComponentState,
        classes,
    });

    const renderTitle = () => (
        <Typography className={classes.columHeadType} color="inherit">
            {title}
        </Typography>
    );

    const renderGroupTitle = () => (
        <Typography className={classes.columHeadType} color="inherit">
            {groupTitle}
        </Typography>
    );

    const renderGroupTitleEditor = () => makeInlineEditor({
        field: GroupTitleProp,
        component,
        setComponentState,
        classes,
    });

    return (
        <div style={{ width: '100%' }}>
            <Grid container>
                <Grid item xs={12}>
                    {hasGroupHeader
                        && (
                            <Grid item xs={12} className={classes.groupHeader}>
                                {(inWorkshop && !compareModal) ? renderGroupTitleEditor() : renderGroupTitle()}
                            </Grid>
                        )}
                    <Grid container direction={direction}>
                        <Grid item xs={component.props.layout === 'stacked' ? 12 : 3} className={classes.column}>
                            {(inWorkshop && !compareModal)
                                ? <div className={!showTitle ? classes.hideTextCls : null}>{renderTitleEditor()}</div>
                                : <div className={!showTitle ? classes.hideTextCls : null}>{renderTitle()}</div>}
                        </Grid>
                        {columns && columns.map((column, index) => (
                            <Column
                                key={column.id}
                                column={column}
                                parent={progression}
                                index={index}
                                classes={classes}
                            />
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

const ProgressionPreview = ({ component }) => {
    const { props } = component;
    const {
        components: columns, title, groupTitle, hasGroupHeader, layout,
    } = props;

    const classes = useRowStyles();

    const direction = layout === 'stacked' ? 'column' : 'row';

    return (
        <div style={{ width: '100%' }}>
            <Grid container>
                <Grid item xs={12}>
                    {hasGroupHeader
                        && (
                            <Grid item xs={12} className={classes.groupHeader}>
                                <Typography className={classes.columHeadType} color="inherit">
                                    {groupTitle}
                                </Typography>
                            </Grid>
                        )}
                    <Grid container direction={direction}>
                        <Grid item xs={layout === 'stacked' ? 12 : 3} className={classes.column}>
                            <Typography variant="body1" color="inherit">
                                {title}
                            </Typography>
                        </Grid>
                        {columns.map((column, index) => (
                            <ColumnPreview
                                key={column.id}
                                column={column}
                                parent={component}
                                index={index}
                                classes={classes}
                            />
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

const ProgressionEditor = ({ config, updateComponent }) => {
    const [component, setComponentState] = useRecoilState(componentWithId(config.id));

    return (
        <Settings editorProps={ProgressionProps} component={component} setComponentState={setComponentState} saveComponent={updateComponent} />
    );
};

const ProgressionEditMenu = ({ component, componentLibrary, parent }) => {
    const clickedArea = component.clickedArea || (parent.isProgression === true) ? 'Progression' : 'Protocol';
    const filter = (item) => (item.type === 'Text' || item.type === clickedArea || item.type === 'ProgressionGroup');
    const labelMaker = (label) => (`Add ${label}`);
    const add = buildComponentMenu(componentLibrary, filter, labelMaker);

    const menu = [
        ...add,
        MenuDefaults.EDIT,
        MenuDefaults.DELETE,
    ];
    return menu;
};

export {
    Progression, ProgressionEditor, ProgressionEditMenu, ProgressionPreview,
};
