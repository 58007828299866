/* eslint-disable react/no-unescaped-entities */
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
} from '@material-ui/core';
import { OrgContext, TimelineContext } from './context';
import React, { useContext, useEffect, useState } from 'react';

import { DELETE_CONTENT_DISPLAY_BY_TIMELINE } from './components/ImageSelector';
import { GET_TIMELINES_SUPER_ADMIN_SHARED, GET_TIMELINE_COLUMN } from './context/Timeline';
import { getFormattedDate } from './helper/commonHelper';
import { makeStyles } from '@material-ui/core/styles';
import { standardHeaders } from './components/utils/StandardStyles';
import {
    useApolloClient, useLazyQuery, useMutation, useQuery,
} from 'react-apollo';
import Box from '@material-ui/core/Box';
import ChevronRight from '@material-ui/icons/ChevronRight';
import DeleteIcon from '@material-ui/icons/Delete';
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Loader from 'react-loaders';
import OrgBreadcrumbs from './components/utils/OrgBreadcrumbs';
import TimelineForm from './components/dialog/TimelineCreationDialog';
import Typography from '@material-ui/core/Typography';
import Visibility from '@material-ui/icons/Visibility';
import _ from 'lodash';
import gql from 'graphql-tag';
import httpHelper from './helper/httpHelper';

const GET_DELETED_TIMELINE_MEDIA = gql`
    query GetDeletedTimelineMedia($timeline_id: uuid) {
        media_folder_new(where: {timeline_id: {_eq: $timeline_id}}) {
            timeline_id
            media {
                url
            }
        }
    }
`;

const DELETE_TIMELINE_FOLDER = gql`
mutation DeleteTimelineFolder($id: uuid) {
    delete_media_folder_new(where: {timeline_id: {_eq: $id}}) {
        affected_rows
        returning {
            id
        }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
    buttonGroup: {
        [theme.breakpoints.down('xs')]: {
            right: '0px',
        },
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    tlListDiv: {
        maxHeight: 'calc(100vh - 180px)',
        width: '100%',
        overflow: 'auto',
        padding: 0,
        scrollbarWidth: 'none', /* Firefox */
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    tlListItm: {
        backgroundColor: '#fff',
        padding: '0px 0px 0px 14px',
        [theme.breakpoints.down('xs')]: {
            padding: '0px 0px 0px 7px',
        },
    },
    tlName: {
        fontSize: 20,
        textTransform: 'capitalize',
        [theme.breakpoints.down('xs')]: {
            fontSize: 15,
            marginBottom: '5px',
            textOverflow: 'ellipsis',
            width: '80%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
    },
    primaryBtn: {
        maxHeight: 56,
        padding: '8px 18px',
        boxShadow: '0px 0px 20px -4px rgba(51,36,5,0.37)',
        '&:hover': {
            boxShadow: 'none',
        },
    },
    createButtonContainer: {
        textAlign: 'right',
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    deleteIcon: {
        cursor: 'pointer',
        [theme.breakpoints.down('xs')]: {
            fontSize: 18,
            marginRight: '-10px',
        },
    },
    visibleIcon: {
        color: '#757575',
        [theme.breakpoints.down('xs')]: {
            fontSize: 18,
            marginRight: '-10px',
        },
    },
    editIcon: {
        color: '#757575',
        [theme.breakpoints.down('xs')]: {
            fontSize: 18,
            marginRight: '-35px',
        },
    },
    itemAccordionStyleExpanded: {
        '&&': {
            margin: 0,
        },
    },
    itemAccordionStyle: {
        flexGrow: 1,
        boxShadow: 'none',
        border: '1px solid #cbcbcb',
        margin: '2px 0px',
    },
    sectionHeader: {
        margin: 0,
        minHeight: 'auto !important',
        height: 32,
        border: '1px solid #ececec',
        ...standardHeaders.sectionHeader,
        position: 'relative',
        flexDirection: 'row-reverse',
    },
    accordionCompAccordionSummary1: {
        marginBottom: 1,
        color: ({ level }) => (level === 1 ? '#1C1C1C' : '#FFFFFF'),
        background: '#6d6d6d',
        paddingRight: 0,
        border: 'none',
        fontSize: '1rem',
    },
    accordionIconStyle: {
        background: '#6d6d6d',
        color: '#1c1c1c',
        transform: 'rotate( -90deg )',
    },
    itemAccordionSummaryStyle: {
        flexGrow: 1,
        margin: 0,
        padding: 0,
    },
    accordionDetail: {
        padding: 0,
        display: 'block',
        height: 'auto',
        maxHeight: '60vh',
        minHeight: '6vh',
        overflowY: 'auto',
    },
    iconButton: {
        margin: '0 8px 0 2px',
        padding: 0,
        background: ({ level, itemIcon }) => (level === 0 && !itemIcon ? '#bbb' : 'transparent'),
        color: '#000000',
        borderRadius: 2,
        '&:hover': {
            background: ({ level, itemIcon }) => (level === 0 && !itemIcon ? '#bbb' : 'transparent'),
        },
        transform: 'rotate( 90deg )',
    },
    accordionSummaryText: {
        justifyContent: 'center',
        color: '#1c1c1c',
        [theme.breakpoints.down('xs')]: {
            fontSize: '15px',
        },
    },
    textCenter: {
        textAlign: 'center',
    },
    mobileBradocumeStyle: {
        [theme.breakpoints.down('xs')]: {
            margin: '80px 10px',
        },
    },
    maxHeightSectin: {
        [theme.breakpoints.down('xs')]: {
            margin: '16px 0px',
        },
    },
}));

const TimelineList = ({ showLoader }) => {
    const classes = useStyles();
    const [showModal, setShowModal] = useState(false);
    const [isShowLoader, setIsShowLoader] = useState(false);
    const {
        timelinesLoading, found, timelines, createTimeline, deleteTimeline, shareWithDoctorTimelineData, setTimelineSharedUser,
    } = useContext(TimelineContext);
    const {
        selectedOrg,
        loggedUserType,
        loggedInUserDetails,
        idToken,
    } = useContext(OrgContext);
    const client = useApolloClient();
    const { data: { timeline_column_access: tcAccessData } = {} } = useQuery(GET_TIMELINE_COLUMN, {
        variables: {
            user_id: loggedInUserDetails.id,
        },
        skip: found,
    });

    const [getDeletedTimelineMedia, {
        data: deletedTimelineMedia,
    }] = useLazyQuery(GET_DELETED_TIMELINE_MEDIA, {
        fetchPolicy: 'no-cache',
    });

    const [deleteTimelineFolder, {
        loading: deleteTimelineFolderLoading,
    }] = useMutation(DELETE_TIMELINE_FOLDER);

    const [deleteByTimeline, {
        loading: deleteContentDisplayByTimelineLoading,
    }] = useMutation(DELETE_CONTENT_DISPLAY_BY_TIMELINE);

    useEffect(() => {
        const requiredMedia = deletedTimelineMedia?.['media_folder_new']?.[0]?.media;
        const deletedTimelineMediaKeys = [];
        const deleteSuccess = () => {
            const deletedTimelineId = deletedTimelineMedia?.['media_folder_new']?.[0]?.['timeline_id'];
            deleteTimelineFolder({
                variables: {
                    id: deletedTimelineId,
                },
            });
            deleteByTimeline({
                variables: {
                    timeline_id: deletedTimelineId,
                },
            });
        };
        const requestFailure = () => {
        };
        if (requiredMedia) {
            requiredMedia.forEach((ele) => {
                deletedTimelineMediaKeys.push({
                    Key: ele.url.split('/').pop(),
                });
            });
            const payload = {
                Objects: [...deletedTimelineMediaKeys],
            };
            const httpObj = {
                url: 'storage/upload/',
                method: 'DELETE',
                data: JSON.stringify(payload),
                headers: { Authorization: `Bearer ${idToken}` },
            };
            httpHelper(
                httpObj,
                deleteSuccess,
                requestFailure,
                false
            );
        } else {
            setIsShowLoader(false);
        }
    }, [deletedTimelineMedia, deleteTimelineFolder, idToken]);

    useEffect(() => {
        if (!deleteTimelineFolderLoading && !deleteContentDisplayByTimelineLoading) {
            setIsShowLoader(false);
        }
    }, [deleteTimelineFolderLoading, deleteContentDisplayByTimelineLoading]);

    let timelinesData = (timelines && [...timelines]) || [];

    let timelinesByCategory = _.groupBy(timelinesData, 'category');

    const timelineListOrder = loggedUserType === 'Super Admin' ? {
        Super: null,
        Generic: null,
    } : {
        Shared: null,
        Generic: null,
    };

    timelinesByCategory = Object.assign(timelineListOrder, timelinesByCategory);

    if (loggedUserType === 'Super Admin') {
        delete timelinesByCategory?.['Shared With Patient'];
        timelinesByCategory = { ...timelinesByCategory, 'Shared With Doctor': shareWithDoctorTimelineData?.timeline };
    }

    if (loggedUserType !== 'Doctor' && loggedUserType !== 'Super Admin' && timelinesByCategory) {
        timelinesData = [];
        timelinesData = (tcAccessData && timelines && timelinesByCategory.Generic?.filter(
            (t) => tcAccessData.some((tc) => tc.timeline_id === t.id))) || [];
        timelinesByCategory.Shared = [...timelinesData];

        if (loggedUserType === 'Patient') {
            timelinesByCategory.Shared = timelinesByCategory.Generic;
            delete timelinesByCategory.Generic;
            delete timelinesByCategory.Shared;
        }
    }

    if (loggedInUserDetails?.['organization_member']?.[0]?.role === 'Super Admin' && loggedUserType === 'Doctor') {
        delete timelinesByCategory.Super;
    }

    useEffect(() => {
        const fetchUserData = async (userId) => {
            try {
                const { data: val } = await client.query({
                    query: gql`
                      query userData($userId: uuid!) { 
                        user(where: { id: { _eq: $userId } }) {
                          name
                        }
                      }
                    `,
                    variables: { userId },
                });
                return val.user[0];
            } catch (error) {
                console.log(error);
            }
        };
        // eslint-disable-next-line no-unused-expressions
        timelinesByCategory?.['Shared With Doctor']?.forEach(async (tc) => {
            if (tc.user_id) {
                const userData = await fetchUserData(tc.user_id);
                tc.userName = userData?.name;
            }
        });
    }, [timelinesByCategory?.['Shared With Doctor']]);

    function gotToTimeline(id) {
        window.location.href = `/${selectedOrg.orgCode}/timeline/${id}/`;
    }

    const deleteTimelines = (id) => {
        // eslint-disable-next-line no-alert
        if (window.confirm('Do you really want delete this Timeline')) {
            setIsShowLoader(true);
            deleteTimeline(id).then(() => {
                getDeletedTimelineMedia({
                    variables: {
                        timeline_id: id,
                    },
                });
            });
        }
    };

    if (timelinesLoading) {
        return showLoader(true);
    }
    const newTimeline = () => {
        setShowModal(true);
    };

    const renderLoader = () => (
        <div id="overlay">
            <div id="loader">
                <Loader style={{ transform: 'scale(1.5)' }} type="line-scale" />
            </div>
        </div>
    );

    const clickCreatTimeline = ({
        title, template, poi, speciality, industry,
    }) => {
        setShowModal(false);
        setIsShowLoader(true);
        createTimeline({
            title, timelineTemplate: template, poi, speciality, industry,
        }).then((timeline) => {
            gotToTimeline(timeline.id);
            setIsShowLoader(false);
        });
    };

    return (
        <React.Fragment key="key">
            {isShowLoader && renderLoader()}
            {showModal && (
                <TimelineForm
                    showModal
                    timelineTemplates={timelinesData}
                    createTimeline={clickCreatTimeline}
                    onCancel={() => { setShowModal(false); }}
                />
            )}
            <div>
                <Box className={classes.mobileBradocumeStyle} mt={11} mx={4}>
                    <Grid container>
                        <Grid item md={10} lg={10}>
                            <Typography variant="h2">
                                <Box color="secondary.main">Timeline List</Box>
                            </Typography>
                            <Box mt={1}>
                                <OrgBreadcrumbs current="Timelines" />
                            </Box>
                        </Grid>
                        {(loggedUserType === 'Super Admin' || loggedUserType === 'Doctor') && (
                            <Grid item md={2} lg={2} className={classes.createButtonContainer}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    className={classes.primaryBtn}
                                    disabled={showModal}
                                    onClick={newTimeline}
                                >
                                    Create New Timeline
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                    <Box mx={4} mt={2} className={classes.maxHeightSectin}>
                        {_.map(timelinesByCategory, (timelines, category) => (
                            <Accordion
                                defaultExpanded
                                classes={{ expanded: classes.itemAccordionStyleExpanded }}
                                className={classes.itemAccordionStyle}
                                key={category}
                            >
                                <AccordionSummary
                                    expandIcon={<ChevronRight className={classes.accordionIconStyle} />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    className={`
                                        ${classes.sectionHeader}
                                        ${classes.accordionCompAccordionSummary1}
                                        ${classes.itemAccordionSummaryStyle}
                                    `}
                                    classes={{
                                        expandIcon: classes.iconButton,
                                        content: classes.accordionSummaryText,
                                    }}
                                >
                                    {category === 'Generic' ? 'Saved' : category}
                                </AccordionSummary>
                                <AccordionDetails classes={{ root: classes.accordionDetail }}>
                                    {timelines?.length > 0 ? _.map(timelines, (timeline) => (
                                        <List key={timeline.id} className={classes.tlListDiv}>
                                            <ListItem className={classes.tlListItm}>
                                                <ListItemText
                                                    classes={{ primary: classes.tlName }}
                                                    primary={category === 'Shared With Doctor' && timeline.userName ? `${timeline.userName} - ${timeline.name}` : timeline.name}
                                                    secondary={`${timeline?.category === 'Shared'
                                                        || loggedUserType === 'Patient'
                                                        || loggedUserType === 'User' ? 'Shared on' : 'Created at'}
                                                        ${getFormattedDate(timeline.created_at)}`}
                                                />
                                                <ListItemSecondaryAction className={classes.buttonGroup}>
                                                    {(timeline?.category === 'Super' || loggedUserType === 'User' || (loggedUserType === 'Doctor' && timeline?.category === 'Shared') || loggedUserType === 'Patient'
                                                        || loggedUserType === 'Admin') ? (
                                                            <IconButton edge="end" aria-label="edit" onClick={() => gotToTimeline(timeline.id)}>
                                                                <Visibility className={classes.visibleIcon} />
                                                            </IconButton>
                                                        ) : (
                                                            <>
                                                                <IconButton
                                                                    edge="end"
                                                                    aria-label="edit"
                                                                    onClick={() => gotToTimeline(timeline.id)}
                                                                >
                                                                    <EditIcon className={classes.editIcon} />
                                                                </IconButton>
                                                                <IconButton
                                                                    style={{ color: '#FF8484' }}
                                                                    onClick={() => deleteTimelines(timeline?.id)}
                                                                    aria-label="delete"
                                                                >
                                                                    <DeleteIcon className={classes.deleteIcon} />
                                                                </IconButton>
                                                            </>
                                                        )}
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                            <Divider />
                                        </List>
                                    ))
                                        : (
                                            <div className={classes.textCenter}>
                                                {category === 'Generic' ? <h4>You have not created any timeline</h4>
                                                    : <h4>You don't have any shared timeline</h4>}
                                            </div>
                                        )}
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Box>
                </Box>
            </div>
        </React.Fragment>
    );
};

export default TimelineList;
