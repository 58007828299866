import {
    GET_ALL_DOCTORS, GET_ORG_ID_BY_USER, GET_ORG_PATIENTS_WITH_TIMELINE, GET_ROLES_BY_ORG_ID, GET_USER_BY_ID,
} from '../context/User';
import {
    GET_TIMELINES, GET_TIMELINES_BY_ORG_ID, GET_TIMELINE_BY_ID, GET_TIMELINE_COLUMN_BY_USER,
} from '../context/Timeline';
import {
    LinearProgress,
    Table as MaterialTable, TableBody, TableCell, TableRow, Typography,
} from '@material-ui/core';
import { OrgContext, TimelineContext } from '../context';
import { ProcedureLibrary } from '../initialConfig';
import { makeStyles, withStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import { useIsSuperAdmin, useOrg, usePrevious } from '../helper/hooks';
import { useQuery } from '@apollo/react-hooks';
import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import gql from 'graphql-tag';

const Table = withStyles({
    root: {
        '& td': {
            verticalAlign: 'top',
            padding: 8,
            minWidth: 150,
        },
    },
})(MaterialTable);

const HeaderTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const subHeaders = [
    'Industry',
    'Speciality',
    "Doctor's Office",
    'Roles',
    'Users',
];

const doctorViewColumn = [
    "Doctor's Office",
    'Office Procedures',
    'Patient Procedures',
];

const subHeadersAdmin = [...subHeaders.slice(0, -2), 'Specific User Timeline'];

const getClasses = (classes) => ({
    Speciality: classes.w150,
    "Doctor's Office": classes.w150,
    Users: classes.w150,
    Roles: classes.w150,
});

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
    roleContainer: {
        '& > div:last-child': {
            borderBottom: 'none',
        },
    },
    w150: {
        width: 205,
    },
    width33: {
        width: '33%',
    },
    underlineText: {
        textDecoration: 'underline',
        fontSize: 12,
    },
    colAccContMain: {
        padding: '4px 0px !important',
        width: '33%',
        '& > div:last-child': {
            borderBottom: 'none',
        },
    },
    colAccCont: {
        borderBottom: '1px solid #cbcbcb',
        padding: '4px 8px',
    },
    w50: {
        width: '50%',
    },
    row: {
        '& > td': {
            borderRight: '1px solid #cbcbcb',
        },
    },
    addIndustryCont: {
        border: '1px solid #cbcbcb',
        '& > td': {
            borderRight: 'solid 1px #cbcbcb',
        },
    },
    fontSize14: {
        fontSize: 14,
    },
    displayNone: {
        display: 'none',
    },
    textCenter: {
        textAlign: 'center',
    },
    noBorderBottom: {
        '&&': {
            borderBottom: 'none',
        },
    },
    noPadding: {
        '&&': {
            padding: 0,
        },
    },
    innerTableBody: {
        '& > tr:last-child >td': {
            borderBottom: 'none',
        },
    },
    alignTop: {
        verticalAlign: 'top',
    },
    fontBold: {
        fontWeight: 'bold',
    },
    subheaderBG: {
        background: 'lightgray',
    },
    primaryBtn: {
        padding: '1px 4px 0px 4px',
        fontSize: 10,
        boxShadow: '0px 0px 20px -4px rgba(51,36,5,0.37)',
        '&:hover': {
            boxShadow: 'none',
        },
    },
    tableCont: {
        maxHeight: '83vh',
        overflow: 'auto',
    },
    tableContDoctorView: {
        maxHeight: '83vh',
        overflow: 'auto',
    },
    buttonCont: {
        borderTop: '1px solid #CBCBCB',
        bottom: 0,
        position: 'absolute',
    },
    closeWorkshopButton: {
        margin: '.75rem .5rem .5rem .5rem',
        boxShadow: '0px 0px 20px -4px rgba(51,36,5,0.37)',
        '&:hover': {
            boxShadow: 'none',
        },
    },
    layoutTypeWidth: {
        width: '100%',
    },
    linkStyle: {
        cursor: 'pointer',
        color: 'blue',
        fontSize: 12,
    },
    active: {
        background: 'cornflowerblue',
    },
}));

const GET_INDUSTRIES = gql`
query MyQuery {
    industry_speciality {
      category
      id
      name
      speciality_parent
    }
  }  
`;

const PatientLink = ({ user }) => {
    const classes = useStyles();
    const { loading: loadingColumnAccess, data } = useQuery(GET_TIMELINE_COLUMN_BY_USER, {
        variables: {
            user_id: user.userId,
        },
        skip: !user?.userId,
    });
    const timelineID = data?.['timeline_column_access']?.[0]?.['timeline_id'];
    const { loading, data: orgData } = useQuery(GET_TIMELINE_BY_ID, {
        variables: {
            timelineID,
        },
        skip: !timelineID,
    });
    const orgID = orgData?.['timeline_by_pk']['organization_id'];
    const timeline = { id: timelineID, organization_id: orgID };
    return (
        <>
            {(loadingColumnAccess || loading) && <LinearProgress variant="query" />}
            {!orgID ? user?.name
                : user.name ? (
                    <>
                        <Typography className={classes.fontBold}>
                            {`${user?.name} ${user.last_name}`}
                        </Typography>
                        <br />
                        <Typography
                            target="_blank"
                            component="a"
                            href="#"
                            className={classes.fontSize14}
                        >
                            {orgData?.['timeline_by_pk']?.name}
                        </Typography>
                    </>
                ) : timeline?.name}
        </>
    );
};

const UserColumn = ({ userId, role }) => {
    const classes = useStyles();
    const { data: userData, loading } = useQuery(GET_USER_BY_ID, {
        variables: {
            id: userId,
        },
        skip: !userId,
    });
    const user = userData?.['user_by_pk'];
    const userWithId = { ...user, userId };
    return (
        <TableRow className={classes.row}>
            <TableCell className={classes.w150}>
                {loading ? <LinearProgress variant="query" /> : role === 'Patient' ? <PatientLink user={userWithId} /> : user?.name}
            </TableCell>
        </TableRow>
    );
};
const RoleColumn = ({
    role, users,
}) => {
    const classes = useStyles();

    return (
        <TableRow className={classes.row}>
            {
                (role === 'Patient' || role === 'Generic') && (
                    <TableCell className={classes.w150}>
                        {role}
                    </TableCell>
                )
            }

            <TableCell className={classNames(classes.noPadding, classes.roleContainer)}>
                <Table>
                    <TableBody>
                        {_.map(users, (user) => <UserColumn role={role} userId={user.user_id} />)}
                    </TableBody>
                </Table>

            </TableCell>
        </TableRow>

    );
};

const TimelineColumn = ({ timeline }) => {
    const classes = useStyles();
    return (
        <TableRow className={classes.row}>
            <TableCell className={classes.w150}>
                <Typography
                    target="_blank"
                    component="a"
                    href="#"
                    className={classes.fontSize14}
                >
                    {timeline.name}
                </Typography>
            </TableCell>
        </TableRow>
    );
};

const DoctorColumn = ({ user, setShared, diffList }) => {
    const isSuperAdmin = useIsSuperAdmin();
    const classes = useStyles();
    const { data } = useQuery(GET_ORG_ID_BY_USER, {
        variables: {
            user_id: user.id,
        },
    });
    const orgID = data?.['organization_member']?.[0]?.['organization_id'];
    const { data: roleData, loading, refetch } = useQuery(GET_ROLES_BY_ORG_ID, {
        variables: {
            orgID,
        },
        skip: isSuperAdmin || !orgID,
    });
    const { data: timelinesData, loading: loadingTimelines } = useQuery(GET_TIMELINES, {
        variables: {
            orgID,
        },
        skip: !orgID,
    });
    const { data: assocTimelines, loading: loadingAssocTimelines } = useQuery(GET_TIMELINES_BY_ORG_ID, {
        variables: {
            orgID,
        },
        skip: !orgID,
    });

    const timelines = timelinesData?.timeline || [];
    const members = roleData?.['organization_member'] || [];

    const sharedTimelineIds = _.map(assocTimelines?.['user_timeline_association'] || [], (item) => ({ ...item, id: item.timeline_id }));
    const sharedTimelines = _.intersectionBy(timelines, sharedTimelineIds, 'id');
    const prevShared = usePrevious(sharedTimelines);
    const dataByRoles = _.groupBy(members, 'role');
    if (!dataByRoles.Patient) {
        dataByRoles.Patient = {};
    }
    if (!dataByRoles.User) {
        dataByRoles.User = {};
    }
    const timelineObj = { Generic: {} };
    const onUserAdd = () => {
        refetch();
    };

    useEffect(() => {
        if (prevShared?.length === sharedTimelines?.length) return;
        setShared((prevNonShared) => {
            const allNonSharedList = [...prevNonShared, ...sharedTimelines];
            return _.uniqBy(allNonSharedList, 'id');
        });
    }, [sharedTimelines, prevShared]);

    return (
        <TableRow className={classes.row}>
            <TableCell className={classes.w150}>
                {user.name}
            </TableCell>
            {user.name === 'Medguide' && isSuperAdmin && (
                <TableCell className={classNames(classes.noPadding, classes.w150)}>
                    {(loadingAssocTimelines || loadingTimelines) ? <LinearProgress variant="query" /> : _.map(diffList, (timeline) => (
                        <Table>
                            <TableBody>
                                <TimelineColumn timeline={timeline} />
                            </TableBody>
                        </Table>
                    ))}
                </TableCell>
            )}
            {isSuperAdmin ? (
                <TableCell className={classNames(classes.noPadding, classes.w150)}>
                    {(loadingAssocTimelines || loadingTimelines) ? <LinearProgress variant="query" /> : _.map(timelines, (timeline) => (
                        <Table>
                            <TableBody>
                                <TimelineColumn timeline={timeline} />
                            </TableBody>
                        </Table>
                    ))}
                </TableCell>
            ) : (
                <TableCell colSpan={2} className={classNames(classes.noPadding, classes.roleContainer)}>
                    {loading ? <LinearProgress variant="query" /> : _.map(_.merge(dataByRoles, timelineObj), (users, role) => (
                        <RoleColumn
                            onUserAdd={onUserAdd}
                            users={users}
                            role={role}
                            timelines={timelines}
                        />
                    ))}
                </TableCell>
            )}

        </TableRow>
    );
};

const SpecialityColumn = ({ speciality, industry }) => {
    const isSuperAdmin = useIsSuperAdmin();
    const classes = useStyles();
    const { orgID } = useOrg();
    const { data: doctorData } = useQuery(GET_ALL_DOCTORS, { variables: { role: 'Doctor' } });
    const doctors = doctorData?.user || [];
    const filterArgs = { specialty: speciality.name, industry };
    const [SharedList, setSharedList] = useState([]);
    const { loggedInUserDetails } = useContext(OrgContext);
    const loggedUserOrgsId = loggedInUserDetails?.['organization_member']?.[0]?.organization?.id;
    const { data: loggedUserTimelineData } = useQuery(GET_TIMELINES, {
        variables: {
            orgID: loggedUserOrgsId,
        },
    });
    const loggedUserTimelineList = loggedUserTimelineData?.timeline || [];
    const diffList = _.differenceBy(loggedUserTimelineList, SharedList, 'id').filter(
        (item) => item.industries === industry && item.sub_specialities === speciality.name
    );
    const filterFn = (doc) => {
        const hasOrg = _.some(doc.organization_members, { organization_id: orgID });
        return doc.specialty === speciality.name && doc.industry === industry && hasOrg;
    };
    let doctorSpeciality = '';
    const specialityUsers = _.filter(doctors, !isSuperAdmin ? filterFn : filterArgs);
    let newSpecialityUsers = [...specialityUsers];
    if (isSuperAdmin) {
        newSpecialityUsers = [...specialityUsers, { name: 'Medguide' }];
    }
    if (!isSuperAdmin && specialityUsers.length > 0) {
        doctorSpeciality = specialityUsers[0].specialty;
    }

    return (
        <>
            <TableRow className={classes.row}>
                {isSuperAdmin ? (
                    <TableCell className={classes.w150}>
                        {speciality.name }
                    </TableCell>
                ) : specialityUsers.length > 0 && (
                    <TableCell className={classes.w150}>
                        {doctorSpeciality}
                    </TableCell>
                )}
                <TableCell className={classes.noPadding}>
                    <Table>
                        <TableBody className={classes.innerTableBody}>
                            {_.map(newSpecialityUsers, (user) => <DoctorColumn user={user} setShared={setSharedList} diffList={diffList} />)}
                        </TableBody>
                    </Table>
                </TableCell>
            </TableRow>
        </>
    );
};

function TimelineTableForDoctoreView() {
    const { loggedInUserDetails, selectedOrg } = useContext(OrgContext);
    const { selectedTimeline } = useContext(TimelineContext);
    const history = useHistory();
    const [selectedTimelineForEdit, setSelectedTimelineForEdit] = useState(selectedTimeline?.id);
    const { id, name } = loggedInUserDetails?.['organization_member'][0]?.organization;
    const classes = useStyles();
    const { data: timelinesData, loading: loadingTimelines } = useQuery(GET_TIMELINES, {
        variables: {
            orgID: id,
        },
        skip: !id,
    });

    const { data: patientsData } = useQuery(GET_ORG_PATIENTS_WITH_TIMELINE, {
        variables: {
            role: 'Patient',
            orgName: name,
        },
        skip: !id,
    });

    const timelines = timelinesData?.timeline || [];
    const patients = patientsData?.user || [];

    const sharedTimelineIds = [];
    if (patients.length > 0) {
        patients.map((item) => (
            item?.['timeline_column_accesses'].map((sharedTimeline) => (sharedTimelineIds.push({ id: sharedTimeline?.['timeline_id'] })))
        ));
    }
    const sharedTimelines = _.intersectionBy(timelines, sharedTimelineIds, 'id');

    const switchTimeline = (timelineId) => {
        if (timelineId) {
            history.push(`/${selectedOrg.orgCode}/timeline/${timelineId}/`);
            setSelectedTimelineForEdit(timelineId);
        }
    };

    const getProcedure = (timelineId) => {
        let poiTitle = '';
        // eslint-disable-next-line array-callback-return
        sharedTimelines.find((currentTimeline) => {
            if (currentTimeline.id === timelineId) {
                const flatChildren = ProcedureLibrary.children.flatMap((item) => item.children);
                const flatList = [...flatChildren, ...ProcedureLibrary.children];
                // eslint-disable-next-line array-callback-return
                flatList.filter((item) => {
                    if (item.id === currentTimeline.poi) {
                        poiTitle = item.title;
                    }
                });
            }
        });

        return poiTitle;
    };

    return (
        <>
            <div className={classes.tableContDoctorView}>
                {loadingTimelines ? <LinearProgress variant="query" /> : (
                    <Table>
                        <TableBody className={classes.borderBottom}>
                            <TableRow className={classes.row}>
                                {doctorViewColumn.map((subheader) => (
                                    <HeaderTableCell className={classNames(getClasses(classes)[subheader], classes.fontBold, classes.subheaderBG)}>
                                        {subheader}
                                    </HeaderTableCell>
                                ))}
                            </TableRow>
                            <TableRow className={classes.row}>
                                <TableCell className={classes.width33}>
                                    <Typography className={classes.underlineText}>
                                        {name}
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.width33}>
                                    <Typography className={classNames(classes.underlineText)}>
                                        {loggedInUserDetails?.name}
                                    </Typography>
                                    {timelines.map((timeline) => (
                                        <Typography className={classNames(classes.linkStyle)} onClick={() => switchTimeline(timeline.id)}>
                                            {timeline.name}
                                        </Typography>
                                    ))}
                                </TableCell>
                                <TableCell className={classes.colAccContMain}>
                                    {_.map(patients, (patient) => (
                                        // eslint-disable-next-line camelcase
                                        patient?.timeline_column_accesses.map((sharedTimeline) => (
                                            sharedTimeline.timeline_id && (
                                                <div className={classes.colAccCont}>
                                                    <Typography className={classNames(classes.underlineText)}>
                                                        {getProcedure(sharedTimeline.timeline_id)}
                                                    </Typography>
                                                    <Typography
                                                        className={classNames(classes.linkStyle,
                                                            selectedTimelineForEdit === sharedTimeline.timeline_id ? classes.active : '')}
                                                        onClick={() => switchTimeline(sharedTimeline.timeline_id)}
                                                    >
                                                        {patient?.name}
                                                    </Typography>
                                                </div>
                                            )
                                        ))
                                    ))}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                )}
            </div>
        </>
    );
}

function TimelineTable() {
    const isSuperAdmin = useIsSuperAdmin();
    const { data: industryData, loading } = useQuery(GET_INDUSTRIES);
    const { data: doctorData } = useQuery(GET_ALL_DOCTORS, { variables: { role: 'Doctor' } });
    const classes = useStyles();
    const { orgID } = useOrg();
    const allData = industryData?.['industry_speciality'] || [];
    let industries = _.filter(allData, { category: 'Industry' }) || [];
    const specialities = _.filter(allData, { category: 'Speciality' }) || [];

    const newDoctor = doctorData?.user.filter((item) => (item?.['organization_members'][0]?.['organization_id'] === orgID ? item : null));
    if (!isSuperAdmin) {
        industries = industries.filter((item) => item.name === newDoctor?.[0].industry);
    }
    const groupedSpecialities = _.groupBy(specialities, 'speciality_parent');

    return (
        <>
            <div className={classes.tableCont}>
                {loading ? <LinearProgress variant="query" /> : (
                    <Table>
                        <TableBody className={classes.borderBottom}>
                            <TableRow className={classes.row}>
                                {(isSuperAdmin ? subHeadersAdmin : subHeaders).map((subheader) => (
                                    <HeaderTableCell className={classNames(getClasses(classes)[subheader], classes.fontBold, classes.subheaderBG)}>
                                        {subheader}
                                    </HeaderTableCell>
                                ))}
                            </TableRow>
                            {_.map(industries, (industry) => (
                                <TableRow className={classes.row} key={industry.id}>
                                    <TableCell className={classes.w150}>
                                        {industry.name}
                                    </TableCell>
                                    <TableCell colSpan={4} className={classes.noPadding}>
                                        <Table>
                                            <TableBody className={classes.innerTableBody}>
                                                {_.map(groupedSpecialities[industry.name],
                                                    (speciality) => <SpecialityColumn industry={industry.name} speciality={speciality} />)}
                                            </TableBody>
                                        </Table>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </div>
        </>
    );
}
export { TimelineTable, TimelineTableForDoctoreView };
