const buildComponentMenu = (componentLibrary, selector, labelMaker, relation = 'sibling', component = null) => {
    let temp = componentLibrary;
    if (selector) {
        temp = componentLibrary.filter(selector);
    }
    return temp.map((item) => {
        let children;
        if (item.variants) {
            children = item.variants.map((variant) => {
                if (component && component.type === 'Text') {
                    const clickedComponent = component.props.type;
                    if (variant.type === 'Text' && variant.key === clickedComponent) {
                        relation = 'sibling';
                    } else if (variant.type === 'Text' && variant.key < clickedComponent) {
                        relation = 'parent';
                    } else {
                        relation = 'child';
                    }
                }
                if (component && component.type !== 'Text') {
                    if (variant.type === 'Text') {
                        relation = 'parent';
                    } else {
                        relation = 'sibling';
                    }
                }

                return ({ label: variant.name, action: { type: 'insert', relation, component: variant } });
            });
        }
        const label = labelMaker ? labelMaker(item.name) : item.name;
        return { label, children, action: { type: 'insert', relation, component: item } };
    });
};

const MenuDefaults = {
    EDIT_PROTOCOL: (filter) => ({ label: 'Edit', action: { type: 'edit', filter } }),
    EDIT: { label: 'Edit', action: { type: 'edit' } },
    REMOVE_MEDIA: { label: 'Remove Media', action: { type: 'removeMedia' } },
    EDIT_MEDIA: { label: 'Edit Media', action: { type: 'editMedia' } },
    TIMELINE_USER_VIEW: { label: 'Timeline User View', action: { type: 'accessControl' } },
    HIDE: { label: 'Hide', action: { type: 'setProperty', props: { hide: true } } },
    SHOW: { label: 'Show', action: { type: 'setProperty', props: { hide: false } } },
    DELETE: { label: 'Delete', action: { type: 'delete' } },
    ADD_TEXT_INPUT: { label: 'Add Text Input', action: { type: 'addTextInput' } },
    COPY: { label: 'Copy', action: { type: 'copy' } },
    CUT: { label: 'Cut', action: { type: 'cut' } },
    PASTE: { label: 'Paste', action: { type: 'paste' } },
    PASTE_HEADER_CHILD: { label: 'Paste', action: { type: 'pasteHeaderChild' } },
    PASTE_PROTOCOL_CHILD: { label: 'Paste', action: { type: 'pasteProtocolChild' } },
    DUPLICATE: { label: 'Duplicate', action: { type: 'duplicate' } },
    ADD_CHILD: (children) => ({ label: 'Add Child', children }),
    ADD_SIBLING: (children) => ({ label: 'Add Sibling', children }),
    ADD_ACCESS_VIEW: (children) => ({ label: 'User View', children }),
    SAVE_AS_TEMPLATE: { label: 'Save As Template', action: { type: 'saveAsColumnTemplate' } },
    SIBLING: (children) => ({ label: 'Add Sibling', children }),
    CHILD: (children) => ({ label: 'Add Child', children }),
    CONTENT_DISPLAY: { label: 'Content Display', action: { type: 'addHyperLink' } },
    EDIT_CONTENT_DISPLAY: { label: 'Edit Content Display', action: { type: 'editHyperLink' } },
    REMOVE_CONTENT_DISPLAY: { label: 'Remove Content Display', action: { type: 'removeHyperLink' } },

};

const DefaultEditMenu = ({
    componentLibrary,
}) => {
    const all = buildComponentMenu(componentLibrary);
    const children = buildComponentMenu(componentLibrary, null, null, 'child');
    const menu = [
        MenuDefaults.EDIT,
        MenuDefaults.DELETE,
        MenuDefaults.DUPLICATE,
        MenuDefaults.ADD_CHILD(children),
        MenuDefaults.ADD_SIBLING(all),
    ];
    return menu;
};

export { buildComponentMenu, MenuDefaults, DefaultEditMenu };
