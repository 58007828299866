/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import '../css/style.css';
import {
    Box, Container, Grid, GridList, GridListTile, GridListTileBar, TextField,
} from '@material-ui/core';
import { MediaEditorContext, MediaEditorProvider, OrgContext } from '../context';
import { createPlayer, getFileType, isVideo } from '../helper/commonHelper';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/DeleteRounded';
import EditorModal from './EditorModal';
import IconButton from '@material-ui/core/IconButton';
import MediaPlaceholder from '../images/imageDeletedPlaceholder.png';
import MediaViewer from './MediaViewer';
import React, {
    Fragment, useContext, useEffect, useState,
} from 'react';

/**
 * Set WorkshopUpdatedMedia Component style
 */
const useStyles = makeStyles(() => createStyles({
    childContainer: {
        float: 'left',
        paddingLeft: 0,
        paddingRight: 0,
    },
    parentContainer: {
        margin: 0,
        padding: 0,
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        width: '100%',
    },
    scrollMedia: {
        flexWrap: 'nowrap',
        transform: 'translateZ(0)',
    },
    videoEditable: {
        height: '100%',
        width: '100%',
        position: 'absolute',
        cursor: 'pointer',
    },
    mediaAllignment: {
        top: 'unset',
        transform: 'none',
        height: '100%',
        width: '100%',
        cursor: 'pointer',
    },
    gridList: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    gridScrollView: {
        flexWrap: 'nowrap', // for scroll view
        transform: 'translateZ(0)',
        width: '100%',
    },
    titleBar: {
        background:
            '#fff',
    },
    hiddenTitle: {
        display: 'none',
    },

    gridInputDiv: {
        width: '100%',
    },
    addIconBtn: {
        position: 'absolute',
        top: '28%',
        left: '34%',
    },
    addIcon: {
        height: 60,
        width: 60,
    },
    deleteIcon: {
        position: 'absolute',
        right: 0,
        top: 48,
        color: '#F44E4E',
        backgroundColor: '#f9f9f9',
        borderRadius: 1,
        padding: 6,
    },
    gridImg: {
        width: '100%',
        paddingTop: 36,
        paddingBottom: 36,
        height: 170,
    },
    titleCaptionInpt: {
        padding: '15.5px 14px',
    },
    mediaCls: {
        cursor: 'pointer',
    },
}));

/**
 * This component is used to Edit the Media and save data
 * @param {boolean}  isWorkshop[ recieved from props]
 * @param {string} mediaCompId [recieved function as props to save the selected image]
 * @param {array} imageList [recieved function as props to save the selected image]
 * @param {props} props [recieved function all props]
 */
const WorkshopUpdateMedia = ({
    isWorkshop, mediaCompId, imageList, props,
}) => {
    const classes = useStyles();
    const { deletedMediaContext, getRefetchRecentImage } = useContext(MediaEditorContext);
    const { selectedOrg } = useContext(OrgContext);
    const mediaContext = (!deletedMediaContext) ? [] : deletedMediaContext;
    const [viewMedia, setViewMedia] = useState({});
    const [isMediaViewerOpen, setMediaViewerOpen] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState([]);
    const [mediaAddFlag, setMediaAddFlag] = useState(false);
    const [isModalOpen, setModalStatus] = useState(false);
    const [imageListData, setImageListData] = useState(imageList);
    const [imageDataObj, setImageDataObj] = useState({
        slideTitle: props.slideTitle,
        slideCaption: props.slideCaption,
    });
    let singleClickTimer = null;
    let clickCount = 0;

    imageListData.forEach((image, key) => {
        if (isVideo(image.name)) {
            image.filetype = getFileType(image.name);
            image.videoDivWorkshopId = `video-div-ws-um-${mediaCompId}-${key}`;
            image.videoDivId = `video-div-um-${mediaCompId}-${key}`;
            image.isVideo = true;
        }
    });

    if (mediaContext.length > 0) {
        imageListData.forEach((data) => {
            const status = mediaContext.filter((ele) => data.url === ele.url);

            if (status.length > 0) {
                data.isShow = false;
            } else {
                data.isShow = true;
            }
        });
    } else {
        imageListData.forEach((data) => data.isShow);
    }

    /**
    * handleMediaViewerStatus [set media viewer true or false]
    */
    const handleMediaViewerStatus = () => {
        setMediaViewerOpen(!isMediaViewerOpen);
    };

    /**
    * @function onSelectMedia
    * @param {object} get the selected media to pass data to the child component
    */
    const onSelectMedia = (e, media) => {
        handleMediaViewerStatus();
        setViewMedia(media);
    };

    /**
    * Handle Modal popup isModalOpen
    * @param {boolean} status [if modal  open status is `true` otherwise `false`]
    */
    const handleModalStatus = (status) => {
        setModalStatus(status);

        if (!status) {
            getRefetchRecentImage();
        }
    };

    /**
    * Handle Image manager popup
    * @param {boolean} status [if modal  open status is `true` otherwise `false`]
    */
    const openImageManager = () => {
        setSelectedMedia([]);
        setMediaAddFlag(true);
        handleModalStatus(true);
    };

    /**
    * Handle double click on media
    * @param {object} tile [passing media ]
    */
    const handleDoubleClick = (tile) => {
        const data = [];
        data.push(tile);
        setSelectedMedia([...data]);
        setMediaAddFlag(false);
        handleModalStatus(true);
    };

    /**
    * Handle media click and check single click or double click
    * @param {event} e
    * @param {object} tile [passing media ]
    */
    const doubleClick = (e, tile) => {
        clickCount += 1;

        if (clickCount === 1) {
            singleClickTimer = setTimeout(() => {
                clickCount = 0;
                onSelectMedia(e, tile);
            }, 300);
        } else if (clickCount === 2) {
            clearTimeout(singleClickTimer);
            clickCount = 0;
            handleDoubleClick(tile);
        }
    };

    /**
    * Recieve all the selected media and set in state
    * @param {object} selectedImg [update image data and close media manager]
    */
    const getselectedImages = (selectedImg) => {
        const newMediaObj = {
            isShow: true,
            url: selectedImg[0].url,
            name: selectedImg[0].name,
            caption: '',
            title: '',
        };
        setSelectedMedia(selectedImg);
        let data = [];

        if (mediaAddFlag) {
            data = [...imageListData];
            data.splice(data.length, 0, newMediaObj);
        } else {
            const index = imageListData.findIndex((item) => item.url === selectedMedia[0].url);
            data = [...imageListData];
            data[index] = { ...newMediaObj };
        }

        setImageListData(data);
        props.imageData = [...data];
        handleModalStatus(false);
    };

    /**
    *Handle input change for media data title and caption
    * @param {event} e
    * @param {number} i index value of selected media
    */
    const inputChangeHandlerImage = (e, i) => {
        const imageData = [...imageListData];
        const imageObj = { ...imageData[i] };
        const { name, value } = e.target;
        imageObj[name] = value;
        imageData[i] = { ...imageObj };
        setImageListData(imageData);
        props.imageData = [...imageData];
    };

    /**
    *Handle media delete
    * @param {number} i index value of selected media
    */
    const deleteMediaHandler = (i) => {
        const imageData = [...imageListData];
        imageData.splice(i, 1);
        setImageListData(imageData);
        props.imageData = [...imageData];
    };

    /**
    *Handle input change for Header and slider
    * @param {event} e
    */
    const inputChangeHandler = (e) => {
        const { name, value } = e.target;
        setImageDataObj({ imageDataObj, [name]: value });
        props[name] = value;
    };

    const renderMedia = () => imageListData.map((tile, key) => {
        const targetId = isWorkshop ? tile.videoDivWorkshopId : tile.videoDivId;
        const mediaUrl = (tile.isShow) ? tile.url : MediaPlaceholder;
        let mediaElement = (
            <img src={mediaUrl} alt="img" onClick={(e) => doubleClick(e, tile)} className={classes.mediaCls} />
        );

        if (tile.isVideo && tile.isShow) {
            mediaElement = (
                <Fragment>
                    <div className={classes.videoEditable} onClick={(e) => doubleClick(e, tile)} />
                    <div className="videoSize" id={targetId} />
                </Fragment>
            );
        }

        return (
            <GridListTile>
                <Fragment>
                    <GridListTile
                        classes={{ imgFullHeight: classes.gridImg }}
                    >
                        {mediaElement}
                        <IconButton className={classes.deleteIcon} onClick={() => deleteMediaHandler(key)}>
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                        <GridListTileBar
                            titlePosition="top"
                            actionIcon={(
                                <TextField
                                    inputProps={{ className: classes.titleCaptionInpt, maxLength: 20 }}
                                    fullWidth
                                    placeholder="Enter Image Header"
                                    variant="outlined"
                                    name="title"
                                    value={tile.title}
                                    onChange={(e) => inputChangeHandlerImage(e, key)}
                                />
                            )}
                            className={classes.titleBar}
                            classes={{
                                titleWrap: classes.hiddenTitle,
                                actionIcon: classes.gridInputDiv,
                            }}
                            actionPosition="left"
                        />
                        <GridListTileBar
                            titlePosition="bottom"
                            actionIcon={(
                                <TextField
                                    inputProps={{ className: classes.titleCaptionInpt, maxLength: 30 }}
                                    fullWidth
                                    placeholder="Enter Media Caption"
                                    variant="outlined"
                                    name="caption"
                                    value={tile.caption}
                                    onChange={(e) => inputChangeHandlerImage(e, key)}
                                />
                            )}
                            className={classes.titleBar}
                            classes={{
                                titleWrap: classes.hiddenTitle,
                                actionIcon: classes.gridInputDiv,
                            }}
                            actionPosition="left"
                        />
                        <Box component="div" display="inline" />
                    </GridListTile>
                </Fragment>
            </GridListTile>
        );
    });

    useEffect(() => {
        createPlayer(imageListData, isWorkshop);
    });

    return (
        <Container maxWidth="xl" className={classes.childContainer}>
            {isMediaViewerOpen && <MediaViewer open={isMediaViewerOpen} handleClose={handleMediaViewerStatus} viewMedia={viewMedia} />}
            {isModalOpen && (
                <Grid
                    item
                    md={12}
                    lg={12}
                    className={classes.gridContainer}
                >
                    <MediaEditorProvider selectedOrg={selectedOrg}>
                        <EditorModal
                            open={isModalOpen}
                            onImagesSelected={getselectedImages}
                            mediaList={selectedMedia}
                            handleClose={() => handleModalStatus(false)}
                            mediaCompId={mediaCompId}
                        />
                    </MediaEditorProvider>
                </Grid>
            )}
            <Grid container>
                <Grid item xs={12}>
                    <Box>
                        <TextField
                            fullWidth
                            placeholder="Enter Slide Title"
                            variant="outlined"
                            onChange={inputChangeHandler}
                            name="slideTitle"
                            value={imageDataObj.slideTitle}
                        />
                    </Box>
                    <Box>
                        <Grid container>
                            <div className={classes.root}>
                                <GridList className={props.layoutOption === 'scroll' ? classes.gridScrollView : classes.gridList} cols={3}>
                                    {renderMedia()}
                                    <GridListTile
                                        classes={{
                                            imgFullHeight: classes.gridImg,
                                        }}
                                    >
                                        <IconButton
                                            className={classes.addIconBtn}
                                            onClick={openImageManager}
                                        >
                                            <AddCircleIcon className={classes.addIcon} color="primary" />
                                        </IconButton>
                                    </GridListTile>
                                </GridList>
                            </div>
                        </Grid>
                    </Box>
                    <Box>
                        <TextField
                            fullWidth
                            placeholder="Enter Caption"
                            variant="outlined"
                            onChange={inputChangeHandler}
                            name="slideCaption"
                            value={imageDataObj.slideCaption}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default WorkshopUpdateMedia;
