import { TimelineContext } from '../context';
import { copyTemplateWithAssociatedCell, getUniqueUUID } from '../context/util';
import { v4 as uuid } from 'uuid';
import React, { useContext } from 'react';

const selectionColors = [
    { id: 1, priority: 1, color: 'yellow' },
    { id: 2, priority: 2, color: 'blue' },
    { id: 3, priority: 3, color: 'white' },
];

function getSelectionColors(comp, type) {
    let findHighlightColor = null;
    if (type === 'child') {
        switch (comp.priority) {
        case 1:
            findHighlightColor = selectionColors.find((hc) => hc.priority === 2);
            break;
        case 2:
            findHighlightColor = selectionColors.find((hc) => hc.priority === 3);
            break;
        case 3:
            findHighlightColor = selectionColors.find((hc) => hc.priority === 1);
            break;
        default:
            findHighlightColor = selectionColors.find((hc) => hc.priority === 1);
        }
    } else if (type === 'parent') {
        switch (comp.priority) {
        case 1:
            findHighlightColor = selectionColors.find((hc) => hc.priority === 3);
            break;
        case 3:
            findHighlightColor = selectionColors.find((hc) => hc.priority === 1);
            break;
        default:
            findHighlightColor = selectionColors.find((hc) => hc.priority === 1);
        }
    }

    return findHighlightColor;
}

/**
 * Return random string
 */
function randomString(length) {
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyz';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i += 1) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}

/**
 * Function to test extenison of file name.
 * @param  {String} fileName [String value of file name]
 * @return {Boolean}        [Return boolean value]
 */
const isVideo = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase();
    if (extension === 'png' || extension === 'jpeg' || extension === 'jpg') {
        return false;
    }
    return true;
};

/**
 * @description Create Vimeo player and set it to div element
 * @param {object} mediaList
 * @param {boolean} isWorkshop
 */
const createPlayer = (mediaList, isWorkshop = false, config = { useWidth: true }, playerRef) => {
    mediaList.forEach((data) => {
        let targetDiv = data.videoDivId;
        if (isWorkshop) {
            targetDiv = data.videoDivWorkshopId;
        }
        const selectedDiv = playerRef || document.getElementById(targetDiv);
        if (selectedDiv && (!selectedDiv.firstElementChild || selectedDiv?.firstElementChild?.firstElementChild?.src !== data?.url)
            && !data.is_youtube) {
            selectedDiv.innerHTML = null;
            const videoElement = document.createElement('video');
            videoElement.height = 148;
            videoElement.controls = true;
            if (config?.useWidth) {
                videoElement.width = config.width || 210;
            }
            selectedDiv.appendChild(videoElement);
            const sourceElement = document.createElement('source');
            sourceElement.src = data.url;
            videoElement.appendChild(sourceElement);
            const wrapperDiv = document.getElementById(`${targetDiv}-wrapper`);
            if (wrapperDiv) {
                wrapperDiv.style.position = 'absolute';
                wrapperDiv.style.height = `${selectedDiv.offsetHeight}px`;
                wrapperDiv.style.width = `${selectedDiv.offsetWidth}px`;
                wrapperDiv.addEventListener('click', () => {

                });
            }
            const selectorDiv = document.getElementById(`${targetDiv}-selector`);
            if (selectorDiv) {
                selectorDiv.style.position = 'absolute';
                selectorDiv.style.height = `${selectedDiv.offsetHeight}px`;
                selectorDiv.style.width = `${selectedDiv.offsetWidth}px`;
            }
            // const customUrl = new URL(data.url);
            // const options = {
            //     id: parseFloat(customUrl.pathname.slice(1)),
            //     title: false,
            //     height: 120,

            // };
            // if (config.useWidth) {
            //     options.width = config.width || 300;
            // }

            // const player = new Player(selectedDiv, options);

            // if (selectedDiv.firstElementChild) {
            //     const { src } = selectedDiv.firstElementChild;
            //     const id = parseFloat(src.split('/')[4].split('?')[0]);
            //     if (id !== options.id) {
            //         // Reload the video and set the iframe src with updated video id
            //         player.loadVideo(options.id).then(() => {
            //             selectedDiv.firstElementChild.src = `https://player.vimeo.com/video/${options.id}?title=0&app_id=${process.env.REACT_APP_VIMEO_APPID}`;
            //         });
            //     }
            // }

            // player.ready().then(() => {
            //     const wrapperDiv = document.getElementById(`${targetDiv}-wrapper`);
            //     if (wrapperDiv) {
            //         wrapperDiv.style.position = 'absolute';
            //         wrapperDiv.style.height = `${selectedDiv.offsetHeight}px`;
            //         wrapperDiv.style.width = `${selectedDiv.offsetWidth}px`;
            //         wrapperDiv.addEventListener('click', () => {
            //             player.getPaused().then((paused) => {
            //                 if (paused) {
            //                     player.play();
            //                 } else {
            //                     player.pause();
            //                 }
            //             });
            //         });
            //     }
            //     const selectorDiv = document.getElementById(`${targetDiv}-selector`);
            //     if (selectorDiv) {
            //         selectorDiv.style.position = 'absolute';
            //         selectorDiv.style.height = `${selectedDiv.offsetHeight}px`;
            //         selectorDiv.style.width = `${selectedDiv.offsetWidth}px`;
            //     }
            // });
        }
    });
};

/**
 * Get the uploaded media type.
 * @param {string} filename [file name]
 * @return {string} [uploaded media type]
 */
const getFileType = (fileName) => {
    const fileExtension = fileName.split('.').pop().toLowerCase();
    if (
        fileExtension === 'png'
        || fileExtension === 'jpeg'
        || fileExtension === 'jpg'
    ) {
        return `image/${fileExtension}`;
    }
    return `video/${fileExtension}`;
};

/**
 * Get the vimeo url.
 * @param {string} filename [vimeo video url]
 * @return {string} [vimeo video id]
 */
const getVimeoVideoId = (url) => {
    const regex = new RegExp(/(https?:\/\/)?(www.)?(player.)?vimeo.com\/([a-z]*\/)*([0-9]{6,11})[?]?.*/);
    if (regex.test(url)) {
        return regex.exec(url)[5];
    }

    return null;
};

/**
 * Recieved ISO 8601 formatted date and format before render
 * @param {date} isoDate
 * @returns {string} formatted date
 */
const getFormattedDate = (isoDate) => {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const date = new Date(isoDate);
    return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;
};

/**
 * emailValidator [validate email]
 * @param {email} Sting
 * @returns {boolean}
 */
const emailValidator = (email) => {
    const expression = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    return expression.test(String(email).toLowerCase());
};

/**
 * passwordValidator [validate password]
 * @param {password} Sting
 * @returns {boolean}
 */
const passwordValidator = (password) => {
    const expression = /^(?=^.{8,32}$)((?=.*\d)(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
    return expression.test(password);
};

const filterFormTextAreaSettings = (editorDiplayProps, selectedComponent) => {
    let filteredEditorDiplayProps;
    if (selectedComponent?.name === 'Form' && (selectedComponent?.component === 'Text Area' || selectedComponent?.component === 'Text Box')) {
        filteredEditorDiplayProps = editorDiplayProps;
    } else {
        filteredEditorDiplayProps = editorDiplayProps.filter((item) => item?.key !== 'listTypePreview');
    }
    return filteredEditorDiplayProps;
};

const filterUniversalSettings = (editorDiplayProps, selectedComponent) => {
    let filteredEditorDiplayProps = editorDiplayProps;
    if (selectedComponent?.type === 'Protocol' && !Object.prototype.hasOwnProperty.call(selectedComponent.props, 'isUniversal')) {
        filteredEditorDiplayProps = editorDiplayProps.filter((c) => c?.key !== 'pcRelationship');
    }
    return filteredEditorDiplayProps;
};

/**
 * updates removes second and third column of the components which as type 'row'
 * @param {*} arr
 * @returns
 */
export function updateComponentsForTypeRow(arr, flexTextField) {
    function recursiveUpdate(array) {
        for (let i = 0; i < array.length; i += 1) {
            const item = array[i];
            if (item.type.toLowerCase() === 'row') {
                item.props.flexTextField = flexTextField;
                item.props.components.length = 1;
            }
            if (Array.isArray(item.props.components)) {
                recursiveUpdate(item.props.components);
            }
        }
    }
    // Call the recursive function to update the 'components' for 'row' objects
    recursiveUpdate(arr);
    return arr;
}

/**
 * Filter the settings in Form and TextBox particularly
 * @param {object} editorDiplayProps protocol display props
 * @param {string} selectedComponent selected component
 */

// const filterColumnLayout = (editorDiplayProps, selectedComponent) => {
//     let filteredEditorDiplayProps;
//     if (selectedComponent.name === 'Form') {
//         if (selectedComponent.component === 'Text Area') {
//             filteredEditorDiplayProps = editorDiplayProps;
//         } else {
//             filteredEditorDiplayProps = editorDiplayProps.filter((item) => item.key !== 'flexTextField');
//         }
//     } else {
//         filteredEditorDiplayProps = editorDiplayProps.filter((item) => item.key !== 'flexTextField');
//     }
//     return filteredEditorDiplayProps;
// };
/**
 * Filter the settings which will show in particuler menu
 * @param {object} props components
 * @param {string} selectedSettingMenu selectedMenu
 */
const FilterSettings = (props, selectedSettingMenu, componentType) => {
    const {
        settingsAccessData, selectedComponent, compAccordion, editTimeline, editTimelineColumn, addColumnLeftRight,
    } = useContext(TimelineContext);
    const accessData = settingsAccessData?.filter(
    (item) => item.type === ((editTimelineColumn || addColumnLeftRight) ? 'TimelineColumn' : (props?.display?.[0]?.id === 'Gallery'
    || props?.display?.[0]?.id === 'Image') ? props?.display?.[0]?.id : componentType || selectedComponent.type));
    let filterDisplay = [];
    if (compAccordion || editTimeline || editTimelineColumn || addColumnLeftRight) {
        if (selectedSettingMenu === 'Basic') {
            filterDisplay = props.display.filter(
                (obj) => accessData?.find((item) => item.setting === obj.key && item.type === obj.id)?.access?.isBasic
            );
        } else if (selectedSettingMenu === 'Advanced') {
            filterDisplay = props.display.filter(
                (obj) => accessData?.find((item) => item.setting === obj.key && item.type === obj.id)?.access?.isAdvanced
            );
            filterDisplay = filterFormTextAreaSettings(filterDisplay, selectedComponent);
            filterDisplay = filterUniversalSettings(filterDisplay, selectedComponent);
        } else if (selectedSettingMenu === 'Admin') {
            filterDisplay = props.display.filter(
                (obj) => accessData?.find((item) => item.setting === obj.key && item.type === obj.id)?.access?.isAdmin
            );
            filterDisplay = filterFormTextAreaSettings(filterDisplay, selectedComponent);
            filterDisplay = filterUniversalSettings(filterDisplay, selectedComponent);
        }
    }
    return filterDisplay;
};

const setActiveComponent = (callback, prop) => {
    callback(prop);
};

/**
 * Check object is empty or not
 * @param {object} obj
 */
const checkEmptyObject = (obj) => {
    if (obj && Object.keys(obj).length === 0) {
        return true;
    }
    return false;
};

/**
 * Get the Ifram HTML on the basis of video type
 * @param {object} media
 */
const getIframe = (media) => {
    let iframeHtml = (
        <div>
            <div
                id={media.videoDivId}
                style={{ textAlign: 'center' }}
            />
        </div>
    );
    if (media.is_youtube) {
        iframeHtml = (
            <iframe
                style={{ height: '100%', width: '100%' }}
                frameBorder={0}
                allow="autoplay; encrypted-media"
                allowFullScreen
                title="video"
                src={`https://www.youtube.com/embed/${media.url}`}
            />
        );
    }

    return iframeHtml;
};

const getIframeHyperlink = (media) => {
    let iframeHtml = null;
    if (media.is_youtube) {
        iframeHtml = (
            <div style={{ height: '422px' }}>
                <iframe
                    style={{ height: '100%', width: '100%' }}
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                    title="video"
                    src={`https://www.youtube.com/embed/${media.url}`}
                />
            </div>
        );
    }
    if (!media.is_youtube) {
        iframeHtml = (
            <div style={{ height: media?.expandHeight ? 'calc(100vh - 250px)' : 'calc(100vh - 300px)' }}>
                <iframe
                    style={{ height: '100%', width: '100%' }}
                    title="alt"
                    src={media.url}
                />
            </div>
        );
    }
    return iframeHtml;
};

/**
 * @function scrapThisURL to get HTML of the link
 */

async function scrapThisURL(URL) {
    const res = await fetch(`${process.env.REACT_APP_HTML_CONTENT_ENDPOINT}/?url=${URL}`);
    const html = await res.json();
    return html;
}
/**
 * @function findPage to find page html.
 * @param {string} children [children recieved from selected time line]
 * @param {string} pageId [pageId recieved from config]
 */
const findPage = (children, pageId) => {
    if (children) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < children.length; i++) {
            if (children?.[i]?.id === pageId) {
                return children;
            }
            if (children[i]?.props.components) {
                const result = findPage(children[i].props.components, pageId);
                if (result) {
                    return result;
                }
            }
            /* In case of Progression it will find the Page and return the result */
            if (children[i]?.props.progressions) {
                const result = findPage(children[i].props.progressions, pageId);
                if (result) {
                    return result;
                }
            }
        }
    }

    return null;
};

const findComponent = (parentComp, compId, compType) => {
    if (parentComp) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < parentComp.length; i++) {
            if (parentComp[i] && parentComp[i]?.id === compId) {
                parentComp[i].compIndex = i;
                return parentComp[i];
            }
            if (parentComp[i]?.props?.components) {
                const result = findComponent(parentComp[i].props.components, compId, compType);
                if (result) {
                    return result;
                }
            }
        }
    }

    return null;
};

const inheritComponentFunction = (workshopComponent, component, props) => {
    if (component?.type === 'TextAreaCF') {
        const requiredColumn = findComponent(workshopComponent?.props?.components, component?.colRef);
        props.display.forEach((ele) => {
            if (requiredColumn?.props?.hasOwnProperty(`TextAreaCF-${ele?.key}`)) {
                component.props[ele.key] = requiredColumn?.props?.[`TextAreaCF-${ele?.key}`];
            }
        });
    } else if (component?.type === 'TextBoxCF') {
        const requiredColumn = findComponent(workshopComponent?.props?.components, component?.colRef);
        props.display.forEach((ele) => {
            if (requiredColumn?.props?.hasOwnProperty(`TextBoxCF-${ele?.key}`)) {
                component.props[ele.key] = requiredColumn?.props?.[`TextBoxCF-${ele?.key}`];
            }
        });
    } else if (component?.type === 'TextInput') {
        const requiredColumn = workshopComponent?.props?.components?.[0];
        props.display.forEach((ele) => {
            if (requiredColumn?.props?.hasOwnProperty(`TextAreaCF-${ele?.key}`)) {
                component.props[ele.key] = requiredColumn?.props?.[`TextAreaCF-${ele?.key}`];
            }
        });
    }
};

/**
//  * adds duplicate empty input field which as type 'row'
    * @param {*} arr
    * @param {*} duplicateComp
    * @returns row object
    */
export function getWorkShopRow(arr) {
    if (arr === undefined) return false;
    let rowItem = {};
    function recursiveUpdate(array) {
        for (let i = 0; i < array.length; i += 1) {
            const item = array[i];
            if (item.type.toLowerCase() === 'row') {
                rowItem = item;
                return;
            }
            if (Array.isArray(item.props.components)) {
                recursiveUpdate(item.props.components);
            }
        }
    }
    const updatedArr = deepCopy(arr);
    recursiveUpdate(updatedArr);
    return rowItem;
}

const addOptionCells = (parentCell, selectComponentId, associatedIndex, clickedOptionIndex, associateOptionId = null) => {
    // To make sure one cell is mapped to only a single option
    if (parentCell.props.components.find((item) => item.props.associatedIndex === associatedIndex) && associateOptionId === null) {
        return false;
    }
    const OptionCell = {
        id: uuid(),
        type: 'OptionCell',
        props: {
            associatedIndex,
            parentCellId: parentCell.id,
            selectComponentId,
            components: [],
        },
        colRef: parentCell?.colRef,
        rowRef: parentCell?.rowRef,
    };
    parentCell.props.associatedCells = parentCell.props.associatedCells || [];
    parentCell.props.associatedCells[associatedIndex] = OptionCell.id;
    if (associateOptionId) {
        OptionCell.props.associateOptionId = associateOptionId;
        OptionCell.isTimeline = true;
    }
    if (typeof (clickedOptionIndex) === 'number') {
        // parentCell.props.components.push(OptionCell);
        parentCell.props.components.splice(clickedOptionIndex + 1, 0, OptionCell);
        parentCell.props.components.forEach((ele, index) => {
            ele.props.associatedIndex = index;
        });
    } else {
        parentCell.props.components.push(OptionCell);
    }

    return OptionCell;
};

const createCellForList = (obj) => {
    const {
        cellId, nextCol, associatedComponent, LRProps, parentComp, selectComp, refCell: RefCellComp,
    } = obj;
    const cellComp = {
        id: cellId,
        parent: nextCol.id,
        type: 'Cell',
        props: {
            associatedComponent: associatedComponent.id,
            components: [],
            LRProps,
        },
        index: nextCol?.props?.components.length,
        rowRef: parentComp?.id,
        colRef: nextCol?.id,
        forSelect: true,
        forSelectId: selectComp.id,
    };
    if (obj.isTimeline) {
        cellComp.isTimeline = true;
        cellComp.cellId = obj.cellTimelineId;
        cellComp.props.refCellId = RefCellComp.id;
    }
    return cellComp;
};

export const createEmpltyCellForList = (obj) => {
    const {
        cellId, secondNextCol, nextCellWorkshopComp, index, parentComp, associatedComponent,
    } = obj;
    const emptyCellComp = {
        id: uuid(),
        parent: secondNextCol?.id,
        type: 'EmptyCell',
        props: {
            associatedComponent: cellId,
            associateComponentOptionId: associatedComponent.id,
            components: [],
            replaceWith: typeof (index) === 'number' ? nextCellWorkshopComp[index + 1].id : nextCellWorkshopComp?.slice(-1)?.[0].id,
        },
        index: secondNextCol?.props?.components?.length,
        rowRef: parentComp.id,
        colRef: secondNextCol?.id,
        forSelect: true,
    };
    if (obj.isTimeline) {
        emptyCellComp.isTimeline = true;
    }
    return emptyCellComp;
};

/** Trigerred when first column component in Complex/LR Forms is of List Type
 *  and it maps to options using their ids instead of index
 * */
const addCellsForList = ({
    associatedComponent, selectComp, columnComp, workshopComponent, LRProps, index,
}) => {
    const parentComp = findComponent(workshopComponent?.props.components, columnComp.parent);
    const isUniversal = workshopComponent?.props?.isUniversal;
    const currentColIndex = parentComp?.props?.components.findIndex((item) => item.id === columnComp.id);
    if (currentColIndex !== 0) {
        // this means we are not calling this component from first column
        return;
    }
    const nextCol = parentComp?.props?.components[currentColIndex + 1];
    const secondNextCol = parentComp?.props?.components?.[currentColIndex + 2];
    let nextColHasMultiselect = false;
    let emptyCellIndex = 0;
    const cellId = uuid();
    const cellTimelineId = uuid();
    const nextCellWorkshopComp = nextCol?.props?.components.filter((c) => !c.isTimeline) || [];
    const nextCellTimelineComp = nextCol?.props?.components.filter((c) => c.isTimeline) || [];

    const secondCellWorkshopComp = secondNextCol?.props?.components?.filter((c) => !c.isTimeline) || [];
    const secondCellTimelineComp = secondNextCol?.props?.components?.filter((c) => c.isTimeline) || [];
    if (nextCol) {
        const obj = {
            cellId, nextCol, associatedComponent, LRProps, parentComp, selectComp,
        };
        const CellComp = createCellForList(obj);
        const refCell = CellComp;
        const CellCompTimeline = isUniversal ? createCellForList({
            ...obj, isTimeline: true, cellId: cellTimelineId, refCell,
        }) : null;
        // if (replaceIndex !== undefined && replaceIndex !== null && replaceIndex > -1) {
        //     nextCol.props.components[replaceIndex] = CellComp;
        // } else {
        // }
        if (typeof (index) === 'number') {
            nextCellWorkshopComp.splice(index + 1, 0, CellComp);
            // manual
        } else {
            nextCellWorkshopComp.push(CellComp);
            // manual
            if (isUniversal) nextCellTimelineComp.push(CellCompTimeline);
        }
        nextCol.props.hasCells = true;
        associatedComponent.props.associatedCell = CellComp.id;
        associatedComponent.props.associatedCellTimeline = isUniversal ? CellCompTimeline?.id : null;
        const requiredComponent = nextCol?.props?.components;
        for (let i = 0, l = requiredComponent.length; i < l; i += 1) {
            if (i > index) {
                break;
            } else {
                emptyCellIndex += requiredComponent?.[i]?.props?.components?.length || 1;
            }
            if (requiredComponent?.[i]?.props?.components?.[0]?.type === 'Checkbox' && !nextColHasMultiselect) {
                nextColHasMultiselect = true;
            }
        }
        nextCol.props.components = [...nextCellWorkshopComp, ...nextCellTimelineComp];
    }
    if (secondNextCol) {
        const obj = {
            cellId, secondNextCol, nextCellWorkshopComp, index, parentComp, associatedComponent,
        };
        const EmptyCellComp = createEmpltyCellForList(obj);
        const EmptyCellCompTimeline = isUniversal ? createEmpltyCellForList(
            {
                ...obj, isTimeline: true, cellId: cellTimelineId, nextCellWorkshopComp: nextCellTimelineComp,
            }
        ) : null;
        if (typeof (index) === 'number' && secondNextCol) {
            secondNextCol.props.components.splice(nextColHasMultiselect ? emptyCellIndex : index + 1, 0, EmptyCellComp);
            for (let i = 0, l = secondNextCol?.props?.components?.length; i < l; i += 1) {
                secondNextCol.props.components[i].index = i;
            }
        } else {
            secondCellWorkshopComp.push(EmptyCellComp);
            // Manual
            if (isUniversal) secondCellTimelineComp.push(EmptyCellCompTimeline);
        }
        if (secondNextCol) {
            secondNextCol.props.components = [...secondCellWorkshopComp, ...secondCellTimelineComp];
            secondNextCol.props.hasCells = true;
        }
    }
};

export const createCell = (props) => {
    const {
        associatedComponent, nextCol, parentComp, isCell, mainComp, LRProps, isTimeline = false, indexValue = -1, refCell: RefCellComp,
        currentCol = null,
    } = props;
    const CellComp = {
        id: uuid(),
        parent: nextCol?.id,
        /** Add SelectCell only in case of third column */
        type: (associatedComponent.type === 'Select' || associatedComponent.type === 'MultiSelect') ? 'SelectCell' : 'Cell',
        props: {
            associatedComponent: associatedComponent.id,
            components: [],
            LRProps,
        },
        index: indexValue !== -1 ? indexValue : nextCol?.props?.components.length,
        rowRef: parentComp?.id,
        colRef: nextCol?.id,
        associatedComponentParent: isCell ? mainComp?.id : null,
    };
    if (isTimeline) {
        CellComp.isTimeline = true;
        CellComp.props.refCellId = RefCellComp.id;
        const refCell = currentCol?.props?.components?.find((c) => c.props.refCellId === mainComp.id);
        if (refCell) {
            CellComp.associatedComponentParent = isCell ? refCell?.id : null;
        }
    }
    return CellComp;
};

export const createEmptyCell = (props) => {
    const {
        secondNextCol, associatedComponent, nextCol, parentComp, isTimeline = false, indexValue = -1,
    } = props;
    let workshopCompList = [];
    let timelineCompList = [];
    if (nextCol.props.components.length > 0) {
        workshopCompList = nextCol.props.components.filter((c) => !c.isTimeline) || [];
        timelineCompList = nextCol.props.components.filter((c) => c.isTimeline) || [];
    }
    const EmptyCellComp = {
        id: uuid(),
        parent: secondNextCol?.id,
        type: 'EmptyCell',
        props: {
            associatedComponent: associatedComponent.id,
            components: [],
            replaceWith: workshopCompList?.slice(-1)?.[0].id,
            replaceWithTimeline: timelineCompList?.slice(-1)?.[0]?.id,
        },
        index: indexValue !== -1 ? indexValue : secondNextCol?.props?.components?.length,
        rowRef: parentComp?.id,
        colRef: secondNextCol?.id,
    };
    if (isTimeline) EmptyCellComp.isTimeline = true;
    return EmptyCellComp;
};

/** Trigerred when first column component in Complex/LR Forms is of Checkbox Type */
const addCells = ({
    mainComp, associatedComponent, workshopComponent, isCell = false, LRProps, cellIndex = null,
}) => {
    const isUniversal = workshopComponent?.props?.isUniversal;
    if (workshopComponent?.props?.cfType === 'Select and Suggest') {
        return;
    }
    const parentComp = findComponent(workshopComponent?.props.components, isCell ? mainComp.rowRef : mainComp.parent);
    if (!parentComp) {
        return;
    }
    if (parentComp?.type === 'Row') {
        const columnComp = isCell ? parentComp.props.components.find((item) => item.id === mainComp.colRef) : mainComp;
        const currentColIndex = parentComp.props.components.findIndex((item) => item.id === columnComp.id);
        if (currentColIndex === -1) {
            return;
        }
        if ((associatedComponent.type === 'Select' || associatedComponent.type === 'MultiSelect') && currentColIndex === 0) {
            // will be handled y addCellsForList
            return;
        }
        const currentCol = parentComp.props.components[currentColIndex];
        const nextCol = parentComp.props.components[currentColIndex + 1];
        const secondNextCol = parentComp?.props?.components?.[currentColIndex + 2];
        let inMiddle = false;
        const isTimeline = true;
        let childCellIndex = -1;
        if (nextCol) {
            let shouldInsert = false;
            let workshopCompList = [];
            let timelineCompList = [];
            if (nextCol.props.components.length > 0) {
                workshopCompList = nextCol.props.components.filter((c) => !c.isTimeline) || [];
                timelineCompList = nextCol.props.components.filter((c) => c.isTimeline) || [];
            }
            let replaceIndex = workshopCompList.findIndex((item) => item.props.replaceWith === mainComp.id);
            childCellIndex = replaceIndex;
            const CellComp = createCell({
                nextCol, associatedComponent, parentComp, isCell, mainComp, LRProps, indexValue: workshopCompList.length,
            });
            const refCell = CellComp;
            const CellCompTimeline = isUniversal ? createCell({
                nextCol,
                associatedComponent,
                parentComp,
                isCell,
                mainComp,
                LRProps,
                isTimeline,
                indexValue: timelineCompList.length,
                refCell,
                currentCol,
            }) : null;
            if (columnComp.compIndex === 1 && isCell && replaceIndex === -1) {
                const currentIndex = columnComp.props.components.findIndex((item) => item.id === mainComp.id);
                const prevElemsCount = columnComp.props.components.slice(0, currentIndex).map(
                    (item) => Math.max(1, item.props.components.length)
                ).reduce((acc, curr) => acc + curr, 0);
                const nextElemCount = columnComp.props.components[currentIndex + 1]?.props?.components?.length;
                if (nextElemCount) {
                    shouldInsert = true;
                }
                if (columnComp.props.components[currentIndex]?.props?.components?.length === 0) {
                    childCellIndex = null;
                }

                let childLength = mainComp.props.components.length;
                childLength = childLength === 0 ? childLength : childLength - 1;
                if (cellIndex !== null && (cellIndex !== mainComp?.props?.components?.length - 2)) {
                    childLength = cellIndex + 1;
                    inMiddle = true;
                }
                replaceIndex = prevElemsCount + childLength;
            }
            const componentToPush = CellComp;
            const componentToPushTimeline = CellCompTimeline;
            if (replaceIndex !== undefined && replaceIndex !== null && replaceIndex > -1) {
                if (shouldInsert) {
                    if (workshopCompList?.[replaceIndex]?.type === 'EmptyCell') {
                        workshopCompList.splice(replaceIndex, 1);
                    }
                    const comps = workshopCompList;
                    if (mainComp?.props?.components?.[0]?.type === 'TextAreaCF' && currentCol?.props?.index === 1
                        && currentCol?.props?.pcRelationship === 'manual') {
                        // preventing to add additional cell in 3rd column on adding textArea component in 2nd column
                        workshopCompList = [...comps.slice(0, replaceIndex), componentToPush, ...comps.slice(replaceIndex)];
                    } else if (isUniversal) {
                        workshopCompList = [...comps.slice(0, replaceIndex), componentToPush, componentToPushTimeline, ...comps.slice(replaceIndex)];
                    } else {
                        workshopCompList = [...comps.slice(0, replaceIndex), componentToPush, ...comps.slice(replaceIndex)];
                    }
                } else if (inMiddle) {
                    workshopCompList.splice(replaceIndex, 0, componentToPush);
                    // Universal
                    if (isUniversal && (currentColIndex !== 1
                        || associatedComponent.type === 'Select')) timelineCompList.splice(replaceIndex, 0, componentToPushTimeline);
                } else if (columnComp.compIndex === 1
                    && childCellIndex === -1 && !shouldInsert
                    && nextCol?.props?.components[replaceIndex]?.type === 'EmptyCell') {
                    workshopCompList.splice(replaceIndex, 0, componentToPush);
                    // Universal
                    if (isUniversal && (currentColIndex !== 1
                        || associatedComponent.type === 'Select')) timelineCompList.splice(replaceIndex, 0, componentToPushTimeline);
                } else {
                    workshopCompList[replaceIndex] = componentToPush;
                    if (isUniversal && (currentColIndex !== 1
                        || associatedComponent.type === 'Select')) timelineCompList[replaceIndex] = componentToPushTimeline;
                    // Universal
                }
            } else if (cellIndex !== null) {
                workshopCompList.splice(cellIndex + 1, 0, componentToPush);
                // Universal
                if (isUniversal && (currentColIndex !== 1
                    || associatedComponent.type === 'Select')) timelineCompList.splice(timelineCompList.length, 0, componentToPushTimeline);
            } else {
                workshopCompList.push(componentToPush);
                // Universal
                if (isUniversal && (currentColIndex !== 1 || associatedComponent.type === 'Select')) timelineCompList.push(componentToPushTimeline);
            }
            associatedComponent.props.associatedCell = componentToPush.id;
            associatedComponent.props.associatedCellTimeline = isUniversal ? componentToPushTimeline?.id : null;
            nextCol.props.components = [...workshopCompList, ...timelineCompList];
            /*
                Need this prop in column to maintain select component state in cells
            */
            nextCol.props.hasCells = true;
        }
        if (secondNextCol) {
            let workshopCompList1 = [];
            let timelineCompList1 = [];
            if (secondNextCol.props.components.length > 0) {
                workshopCompList1 = secondNextCol.props.components.filter((c) => !c.isTimeline) || [];
                timelineCompList1 = secondNextCol.props.components.filter((c) => c.isTimeline) || [];
            }

            const EmptyCellComp = createEmptyCell({
                secondNextCol, associatedComponent, nextCol, parentComp, indexValue: workshopCompList1.length,
            });
            const EmptyCellCompTimeline = isUniversal ? createEmptyCell({
                secondNextCol, associatedComponent, nextCol, parentComp, isTimeline, indexValue: timelineCompList1.length,
            }) : null;

            if (cellIndex !== null) {
                const ele = nextCol.props.components[cellIndex];
                let secondIndex = secondNextCol.props.components.findLastIndex((s) => s.associatedComponentParent === ele.id);
                if (secondIndex === -1) {
                    secondIndex = secondNextCol.props.components.findLastIndex((s) => s.props.associatedComponent === ele.props.associatedComponent);
                }
                if (secondIndex !== -1) {
                    workshopCompList1.splice(secondIndex + 1, 0, EmptyCellComp);
                    // Universal
                    if (isUniversal) timelineCompList1.push(EmptyCellCompTimeline);
                } else {
                    workshopCompList1.push(EmptyCellComp);
                    // Universal
                    if (isUniversal) timelineCompList1.push(EmptyCellCompTimeline);
                }
            } else {
                workshopCompList1.push(EmptyCellComp);
                // Universal
                if (isUniversal) timelineCompList1.push(EmptyCellCompTimeline);
            }

            secondNextCol.props.components = [...workshopCompList1, ...timelineCompList1];

            /** Save issue fix */
            secondNextCol.props.hasCells = true;
        }
    }
};

const findPComponent = (parentComp, compId) => {
    if (parentComp) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < parentComp.length; i++) {
            if (parentComp[i].type === 'Protocol' && parentComp[i].props
                && Object.prototype.hasOwnProperty.call(parentComp[i].props, 'hideFromWorkshop')) {
                const rowData = findComponent([parentComp[i]], compId, 'Row');
                if (rowData) {
                    return [parentComp[i], i];
                }
                findPComponent(parentComp[i + 1], compId);
            }
            if (parentComp[i].props.components) {
                const result = findPComponent(parentComp[i].props.components, compId);
                if (result) {
                    return result;
                }
            }
        }
    }

    return null;
};

const findProtocolComp = (list, id, row = null) => {
    const col = findPage(list, id);
    const protocolComp = col && findPage(list, col[0].parent);
    const findProIndex = protocolComp && protocolComp.findIndex((p) => p.props.components[0].id === id);
    if (protocolComp || (findProIndex && findProIndex !== -1)) {
        return [protocolComp, findProIndex];
    }
    if (col && !protocolComp && (!findProIndex || findProIndex === -1) && row) {
        const findI = col[0].props.components.findIndex((c) => c.id === row.id);
        return [col[0].props.components, findI];
    }
    return null;
};

const buildSettingsTreePage = (list, component, isProtocolOnly = false) => {
    if (component) {
        list.push(component);
        if (component && component.components) {
            component.components.forEach((element) => {
                if (isProtocolOnly && (element.type === 'Protocol' || element.type === 'ProtocolGroup' || element.type === 'Text')) {
                    buildSettingsTreePage(list, element, isProtocolOnly);
                }

                if (!isProtocolOnly) {
                    buildSettingsTreePage(list, element, isProtocolOnly);
                }
            });
        }
        if (!component.components && component.props && component.props.components) {
            component.props.components.forEach((element) => {
                if (isProtocolOnly && (element.type === 'Protocol' || element.type === 'ProtocolGroup' || element.type === 'Text')) {
                    buildSettingsTreePage(list, element, isProtocolOnly);
                }

                if (!isProtocolOnly) {
                    buildSettingsTreePage(list, element, isProtocolOnly);
                }
            });
        }
    }
};

// get all options from nested object
const flatOptions = (flatOptiondata, colComp) => {
    function findOption(componentData) {
        if (componentData?.type === 'Select') {
            flatOptiondata.push(...componentData.props.options);
        } else if (componentData?.props?.components?.length > 0) {
            componentData.props.components.forEach((c) => {
                findOption(c);
            });
        }
    }
    findOption(colComp);
};

const flatComponentByType = (data, component, compType) => {
    if (component?.props?.components?.length > 0 && !component?.isTimeline) {
        component.props.components.forEach((c) => {
            if (!component.isTimeline) flatComponentByType(data, c, compType);
        });
    } else if ((component?.props?.components?.length === 0 || !component?.props?.components?.length) && !component.isTimeline
        && component.type === compType) {
        data.push(component);
    }
};

const buildTemplateChildren = (list, template) => {
    if (template) {
        if (template.id) { list.push(template); }
        if (template && template.children) {
            template.children.forEach((element) => {
                buildTemplateChildren(list, element);
            });
        }
    }
};

const findParent = (components = [], id, result) => {
    components.forEach((component) => {
        if (component) {
            if (!component.components && component.props && component.props.components) {
                component.props.components.forEach((element) => {
                    if (element?.id === id) {
                        result.push(component);
                    } else {
                        findParent([element], id, result);
                    }
                });
            }
        }
    });
};

const findParents = (component, components) => {
    const list = [];
    let element = component;
    let firstParent = null;
    while (element) {
        const result = [];
        if (components) {
            findParent(components, element.id, result);
        }
        if (result?.[0]) {
            list.push(result[0]);
            element.props.childOf = result[0]?.id;
            if (!firstParent) [firstParent] = result;
        }
        [element] = result;
    }
    return [list, firstParent];
};
/**
 * @function findTimelineColumnWidth to find width of timeline column.
 * @param {object} timeline [timeline recieved from selected time line]
 */
const findTimelineColumnWidth = (timeline) => {
    const currentTimeLineWidth = timeline?.props?.width;
    const columnWidth = parseInt(currentTimeLineWidth, 10) / (timeline?.columns?.length);
    return columnWidth;
};

const findGenericComponent = (component, templateLibrary, template) => {
    let superComp = null;
    template = (template || templateLibrary) && templateLibrary.find((temp) => temp.id === component?.templateId);
    if (!template) {
        return null;
    }
    const list = [];
    let currentTemplate = template;
    while (currentTemplate.parent_reference_id) {
        list.unshift(currentTemplate);
        currentTemplate = templateLibrary.find((item) => item.id === template.parent_reference_id);
    }
    superComp = currentTemplate;
    const genericComponent = list[0];
    return [genericComponent, superComp];
};
const setComponentType = (EditorProps, config, selectedTemplate) => {
    if (EditorProps.display) {
        EditorProps.display.forEach((element) => {
            if (element.key === 'componentType' && config.type !== 'Column') {
                element.value = config?.name || 'Protocol Only';
            }
            if (element.key === 'component') {
                element.value = selectedTemplate?.name || 'NA';
            }
        });
    }
};

const addTLSubChildrenMenu = (component, componentMenu) => {
    const filterByProtocol = component.props.selectedTemplateLibrary.filter((comp) => comp.type === 'Protocol');
    const findProtocolIndex = componentMenu.findIndex((menu) => menu.label === 'Add Protocol');
    if (findProtocolIndex && findProtocolIndex !== -1) {
        filterByProtocol.forEach((item) => {
            const childIndex = componentMenu[findProtocolIndex].children.findIndex((cm) => cm?.label === item?.supergroup);
            if (childIndex && childIndex !== -1) {
                if (!componentMenu[findProtocolIndex].children[childIndex].children) {
                    componentMenu[findProtocolIndex].children[childIndex].children = [];
                }
                item.label = item.name;
                componentMenu[findProtocolIndex].children[childIndex].children.push(item);
            }
        });
    }
    return componentMenu;
};

const findColumnCF = (workshopComponent, workshopActiveComponent, componentProps) => {
    const compParent = [];
    let obj = null;
    let selectOptionIndex = -1;
    const associatedParentId = workshopActiveComponent?.associatedParent || null;
    const { type } = workshopActiveComponent;
    findParent(workshopComponent?.props.components, workshopActiveComponent.id, compParent); // find parent of active component

    if (compParent?.length > 0 && (compParent[0]?.props.isComplexForm || compParent[0]?.props.LRProps)) {
        const colComParent = [];
        findParent(workshopComponent.props.components, compParent[0].id, colComParent); // find parent of active column
        const colIndex = colComParent[0].props.components.findIndex((c) => c.id === componentProps.parent);

        // Find associate parent component and index of active component
        const associatedParentComp = findPage(colComParent[0].props.components, associatedParentId)?.find(
            (c) => (c.id === associatedParentId && associatedParentId === componentProps.id)) || null;
        const activeColIndex = colComParent[0].props.components.findIndex((comp) => comp.id === workshopActiveComponent.parent);

        // check children in component
        // componentProps.props.children = componentProps.props.children ? componentProps.props.children : {};

        // find index of selected option in workshop active component
        if (type === 'Select' || type === 'MultiSelect') {
            selectOptionIndex = workshopActiveComponent.props.options.findIndex((o) => o.isSelect);
        }

        if (activeColIndex === 0 && workshopActiveComponent.id === componentProps.id) { // this works only first column
            obj = { index: colIndex + 1, component: colComParent[0].props.components[colIndex + 1], isShow: !(componentProps.props.children) };
        } else if ((associatedParentComp && activeColIndex > 0)) {
            obj = activeColIndex > 2 ? null : {
                index: activeColIndex + 1,
                component: colComParent[0].props.components[activeColIndex + 1],
                isShow: (type === 'Select' || type === 'MultiSelect') && selectOptionIndex !== -1
                    ? (!((workshopActiveComponent.props.options[selectOptionIndex].children && activeColIndex === colIndex + 1)))
                    : (!((componentProps.props.children && associatedParentComp?.id && activeColIndex === colIndex + 1))),
            };
        }

        if (associatedParentComp && associatedParentComp.type !== 'Select' && associatedParentComp.type !== 'MultiSelect' && activeColIndex > 0) {
            associatedParentComp.props.children = { ...workshopActiveComponent };
        } else if (associatedParentComp && (associatedParentComp?.type === 'Select' || associatedParentComp?.type === 'MultiSelect')) {
            const optionIndex = associatedParentComp.props.options.findIndex((option) => option.isSelect);
            if (associatedParentComp?.props?.options[optionIndex]) {
                associatedParentComp.props.options[optionIndex].children = { ...workshopActiveComponent };
            }
        }
    }
    return obj;
};

const findOptionCellMapping = (workshopComponent, parentComp) => {
    const parentCompRef = findComponent(workshopComponent.props.components, parentComp);
    const cell = findComponent(workshopComponent?.props?.components, parentCompRef?.parent);

    if (cell?.type === 'OptionCell') {
        const selectCell = findComponent(workshopComponent.props.components, cell.props.parentCellId);
        const selectCompId = findComponent(workshopComponent.props.components, cell.props.selectComponentId);
        const index = selectCell.props.associatedCells.findIndex((item) => item === cell.id);
        return { selectCompId, index };
    }

    return null;
};

/**
 * @function transformText to change text from uppercase to capitalize.
 * @param {object} item
 */
const transformText = (str) => {
    const data = str && str.toLowerCase().replace(/\b(\w)/g, (x) => x.toUpperCase());
    return data;
};

/**
 *
 * @param {*} protocolComp
 * @param {*} duplicateComp
 * @param {*} rowComp
 * @param {*} compAddIndex
 * @param {*} setUpdateFlag
 * @param {*} updateFlag
 */

export const wowOnEnterFn = (protocolComp, duplicateComp, rowComp, compAddIndex, setUpdateFlag, updateFlag) => {
    if ((protocolComp?.component === 'SELECT-COMMPLETED-TEMPLATE' || protocolComp?.component === 'SELECT-COMPLETE')) {
        duplicateComp.props.isCompleted = undefined;
        duplicateComp.props.isChecked = false;
        const secondColEl = rowComp?.props?.components?.find((c) => c.props.index === 1);
        const firstColEl = rowComp?.props?.components?.find((c) => c.props.index === 0);
        const secondColFirstEl = secondColEl?.props?.components.find((c) => c.index === 0 && !c.isTimeline);
        secondColEl.props.components.forEach((c) => {
            if (c.index >= compAddIndex + 1) {
                c.index += 1;
            }
        });
        const deepCopyFirstEL = deepCopy(secondColFirstEl);
        deepCopyFirstEL.index = compAddIndex + 1;
        deepCopyFirstEL.id = uuid();
        firstColEl.props.components[compAddIndex + 1].props.associatedCell = deepCopyFirstEL.id;
        deepCopyFirstEL.props.selectId = deepCopyFirstEL.id;
        deepCopyFirstEL.props.associatedComponent = duplicateComp.id;
        deepCopyFirstEL.props.components[0].action = {
            component: {
                header: 'Checkbox',
                id: 'cl-61',
                name: 'Radio Group',
                type: 'Select',
            },
            relation: 'child',
            type: 'insert',
        };
        deepCopyFirstEL.props.components[0].associatedParent = duplicateComp.id;
        deepCopyFirstEL.props.components[0].id = uuid();
        deepCopyFirstEL.props.components[0].props.isCompleted = undefined;
        deepCopyFirstEL.props.components[0].props.options[0].id = uuid();
        deepCopyFirstEL.props.components[0].props.options[0].isSelect = false;
        deepCopyFirstEL.props.components[0].props.selectId = deepCopyFirstEL.props.components[0].id;
        secondColEl.props.components = secondColEl.props.components.filter((c) => c.props.components.length !== 0);
        secondColEl.props.components = secondColEl.props.components.filter((c) => c.index !== compAddIndex + 1);
        secondColEl.props.components.splice(compAddIndex + 2, 0, deepCopyFirstEL);
        setUpdateFlag(!updateFlag);
    }
    if (protocolComp?.component === 'Select-Complete-Notes' || protocolComp?.component === 'Select/Text-Complete-Notes') {
        duplicateComp.props.isCompleted = undefined;
        duplicateComp.props.isChecked = false;
        const secondColEl = rowComp?.props?.components?.find((c) => c.props.index === 1);
        const thirdColEl = rowComp?.props?.components?.find((c) => c.props.index === 2);
        const firstColEl = rowComp?.props?.components?.find((c) => c.props.index === 0);
        const secondColFirstEl = secondColEl?.props?.components.find((c) => c.index === 0 && !c.isTimeline);
        const filteredComp = thirdColEl?.props?.components?.filter((c) => !c.isTimeline);
        const onlyOneElInThird = filteredComp.length === 1;
        if (onlyOneElInThird) {
            thirdColEl.props.components[0].index = 0;
        }
        secondColEl.props.components = secondColEl.props.components.filter((c) => !c.isTimeline);
        thirdColEl.props.components = thirdColEl.props.components.filter((c) => !c.isTimeline);
        const thirdColFirstEl = thirdColEl?.props?.components.find((c) => c.index === 0 && !c.isTimeline);
        secondColEl.props.components.forEach((c) => {
            if (c.index >= compAddIndex + 1) {
                c.index += 1;
            }
        });
        if (thirdColEl?.props?.components) {
            thirdColEl.props.components.forEach((c) => {
                if (c.index >= compAddIndex + 1) {
                    c.index += 1;
                }
            });
        }
        // second Col add completed select component
        const deepCopyFirstEL = deepCopy(secondColFirstEl);
        deepCopyFirstEL.index = compAddIndex + 1;
        deepCopyFirstEL.id = uuid();
        firstColEl.props.components[compAddIndex + 1].props.associatedCell = deepCopyFirstEL.id;
        deepCopyFirstEL.props.selectId = deepCopyFirstEL.id;
        deepCopyFirstEL.props.associatedComponent = duplicateComp.id;
        deepCopyFirstEL.props.components[0].action = {
            component: {
                header: 'Checkbox',
                id: 'cl-61',
                name: 'Radio Group',
                type: 'Select',
            },
            relation: 'child',
            type: 'insert',
        };
        deepCopyFirstEL.props.components[0].associatedParent = duplicateComp.id;
        deepCopyFirstEL.props.components[0].id = uuid();
        deepCopyFirstEL.props.components[0].props.isCompleted = undefined;
        deepCopyFirstEL.props.components[0].props.options[0].id = uuid();
        deepCopyFirstEL.props.components[0].props.options[0].isSelect = false;
        deepCopyFirstEL.props.components[0].props.selectId = deepCopyFirstEL.props.components[0].id;
        secondColEl.props.components = secondColEl.props.components.filter((c) => c.props.components.length !== 0);
        secondColEl.props.components = secondColEl.props.components.filter((c) => c.index !== compAddIndex + 1);
        secondColEl.props.components = secondColEl.props.components.filter((c) => !c.isTimeline);
        // third col adding text field
        const deepCopyThirdColFirstEL = deepCopy(thirdColFirstEl);
        deepCopyThirdColFirstEL.index = compAddIndex + 1;
        deepCopyThirdColFirstEL.id = uuid();
        deepCopyThirdColFirstEL.props.isCompleted = undefined;
        deepCopyFirstEL.props.components[0].props.associatedCell = deepCopyThirdColFirstEL.id;
        deepCopyThirdColFirstEL.props.selectId = deepCopyThirdColFirstEL.id;
        deepCopyThirdColFirstEL.props.associatedComponent = deepCopyFirstEL.props.components[0].id;
        deepCopyThirdColFirstEL.props.components[0].props.components[0].id = uuid();
        deepCopyThirdColFirstEL.props.components[0].props.components[0].props.label = '';
        thirdColEl.props.components = thirdColEl.props.components.filter((c) => c.props.components.length !== 0);
        thirdColEl.props.components = thirdColEl.props.components.filter((c) => c.compIndex !== compAddIndex + 1);

        secondColEl.props.components.splice(compAddIndex + 2, 0, deepCopyFirstEL);
        thirdColEl.props.components.splice(compAddIndex + 2, 0, deepCopyThirdColFirstEL);
        secondColEl.props.components = secondColEl.props.components.sort((a, b) => a.index - b.index);
        thirdColEl.props.components = thirdColEl.props.components.sort((a, b) => a.index - b.index);
        setUpdateFlag(!updateFlag);
    }
};

/**
 * @function sortedObject to sorted object by key.
 * @param {object} item
 */
const sortedObject = (item) => {
    const data = Object.fromEntries(Object.entries(item).sort());
    return data;
};

const findParentWithoutHide = (data, component, parentComponent) => {
    const filterComp = data.find((d) => d.id === component.parent);
    if (filterComp?.props?.hideFromWorkshop || filterComp?.type === 'Column') {
        findParentWithoutHide(data, filterComp, parentComponent);
    } else if (filterComp && !filterComp?.props?.hideFromWorkshop) {
        parentComponent.push(filterComp);
    }
};

const findChildWithoutHide = (data, components, childComponent) => {
    if (components[0]?.props?.components && (components[0]?.props?.hideFromWorkshop || components[0]?.type === 'Column')) {
        findChildWithoutHide(data, components[0].props.components, childComponent);
    } else {
        childComponent.push(components);
    }
};

const findChildComponentFlag = (data) => {
    let flag = false;
    data.props.components.forEach((d) => {
        flag = d?.props?.components?.length > 0 ? true : flag;
    });
    return flag;
};

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const findCompParentTL = (items, item, data) => {
    if (item.parent_reference_id) {
        const findItem = items.find((i) => i.id === item.parent_reference_id);
        if (findItem) data.push(findItem);
        findCompParentTL(items, findItem, data);
    } else {
        return data;
    }

    return null;
};

const findAssociatedParent = (workshopComponent, rowComponent, selectedComponent, data) => {
    if (selectedComponent.associatedParent && selectedComponent.type !== 'Column') {
        const associatedParentComp = findComponent(rowComponent, selectedComponent.associatedParent);
        if (associatedParentComp) {
            data.push(associatedParentComp);
            findAssociatedParent(workshopComponent, rowComponent, associatedParentComp, data);
        }
    } else {
        return data;
    }

    return null;
};

const findRouteUserType = (location) => {
    const route = location.pathname.split('/').filter(Boolean).pop();
    let routeUserType = null;
    switch (route) {
    case 'doctors':
        routeUserType = 'Doctor';
        break;
    case 'patients':
        routeUserType = 'Patient';
        break;
    default:
        routeUserType = ['Admin', 'User'];
    }
    return routeUserType;
};

const getRoutePath = () => window.location.pathname;

const getRouteValidation = (routes) => {
    let isValid = false;
    routes.forEach((item) => {
        isValid = window.location.pathname.match(`/${item}/`) ? true : isValid;
    });
    return isValid;
};

const arrayToObject = (arr, inputKey) => {
    const res = {};
    for (let i = 0; i < arr.length; i += 1) {
        const key = arr[i][inputKey];
        delete arr[i].id;
        res[key] = arr[i];
    }
    return res;
};

const findCompByType = (comp, type) => {
    const components = [];
    buildSettingsTreePage(components, comp);
    const items = components.filter((c) => c.type === type);
    return items;
};

const deepCopy = (obj) => {
    let copyObj = null;
    if (!checkEmptyObject()) copyObj = JSON.parse(JSON.stringify(obj));
    return copyObj;
};

const fetchPatientAuth = (userType, column) => {
    const isPatient = userType === 'Patient';
    let isPatientWrite = false;

    if (isPatient && column?.props?.userAccess?.writePatient) {
        isPatientWrite = true;
    }
    return { isPatientWrite };
};

const getYouTubeId = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    if (match && match[2]) {
        return match[2];
    }
    return null;
};

const createTimelineForPatient = async ({
    title, timelineTemplate, category = 'Generic', otherArg = null, poi, speciality, industry,
}) => {
    const {
        patientDetails, orgId, addTimeline, poiData, AC_DATA, allContentDisplayData, setCurrentTimeline,
        //  bulkInsertPoi, bulkInsertAC,
        // bulkInsertCD,
    } = otherArg;
    const reqObj = {
        name: title,
        orgID: (orgId),
        props: { ...timelineTemplate.props, name: title },
        // columns: timelineTemplate.columns,
        columns: otherArg ? timelineTemplate.columns : copyTemplateWithAssociatedCell(timelineTemplate.columns, true),
        category,
        poi: (otherArg?.shareTimelineFlag ? timelineTemplate.poi : poi),
        speciality: (otherArg?.shareTimelineFlag ? timelineTemplate.sub_specialities : speciality),
        industry: (otherArg?.shareTimelineFlag ? timelineTemplate.industries : industry),
    };
    const allColumns = reqObj.columns;
    const poiPayload = [];
    const acPayload = [];
    const cdPayload = [];
    for (let i = 0, l = allColumns.length; i < l; i += 1) {
        const column = allColumns[i];
        column.progressionSectionId = getUniqueUUID();
        column.protocolSectionId = getUniqueUUID();

        // In the below if Condition we are setting patient name and email to the profile column of the timeline duplicate to him
        // this may not work if there is any structural change in profile column, please consider
        if (column?.props?.isProfile) {
            const profileCNamehildren = [];
            buildSettingsTreePage(profileCNamehildren, column?.props?.components?.[0]?.props?.components[0]);
            for (let i = 0, l = profileCNamehildren.length; i < l; i += 1) {
                if (profileCNamehildren[i]?.props?.['text-input-id'] === 'patient-first-name') {
                    profileCNamehildren[i].props.textField = patientDetails?.firstName;
                    profileCNamehildren[i].props.label = patientDetails?.firstName;
                }
                if (profileCNamehildren[i]?.props?.['text-input-id'] === 'patient-last-name') {
                    profileCNamehildren[i].props.textField = patientDetails?.lastName;
                    profileCNamehildren[i].props.label = patientDetails?.lastName;
                }
            }
            const profileEmailChildren = [];
            buildSettingsTreePage(profileEmailChildren, column?.props?.components?.[0]?.props?.components[3]);
            for (let i = 0, l = profileEmailChildren.length; i < l; i += 1) {
                if (profileEmailChildren[i]?.props?.['text-input-id'] === 'patient-email') {
                    profileEmailChildren[i].props.textField = patientDetails?.email;
                    profileEmailChildren[i].props.label = patientDetails?.email;
                }
            }
        }
        if (column?.props?.isProfile) continue;
        if (!column?.props?.components) continue;

        const topLevelProtocols = column.props.components.concat(column.props.progressions);
        topLevelProtocols.forEach((protocol) => {
            const protocolChildren = [];
            buildSettingsTreePage(protocolChildren, protocol);
            protocolChildren.forEach((item) => {
                item.props.protocolId = protocol?.id;
            });
        });
        let allProgressions = [];
        column.props.progressions.forEach((progression) => {
            const progressionChildren = [];
            buildSettingsTreePage(progressionChildren, progression);
            allProgressions = [...allProgressions, ...progressionChildren];
        });

        const allNewComp = [];
        const allComps = [];
        buildSettingsTreePage(allComps, timelineTemplate?.columns[i]);
        buildSettingsTreePage(allNewComp, column);
        const allChildren = [...allComps, ...allProgressions];

        allChildren.forEach((comp, index) => {
            const clonedId = comp?.id;
            const clonedPOI = otherArg?.selectedDoctor ? null : poiData?.['components_poi'].find((item) => item.protocol_id === clonedId);
            const clonedAC = otherArg?.selectedDoctor ? null
                : AC_DATA?.['components_access_control'].find((item) => item.protocol_id === clonedId);
            const clonedCD = otherArg?.selectedDoctor ? null
                : allContentDisplayData?.['content_display'].find((item) => item.protocol_id === clonedId);
            if (clonedPOI) {
                poiPayload.push({
                    poi_data: clonedPOI.poi_data,
                    column_id: column.id,
                    protocol_id: allNewComp?.[index]?.id,
                });
            }
            if (clonedAC) {
                acPayload.push({
                    access_data: clonedAC.access_data,
                    column_id: column.id,
                    protocol_id: allNewComp?.[index]?.id,
                });
            }
            if (clonedCD) {
                cdPayload.push({
                    image_list: clonedCD.image_list,
                    column_id: column.id,
                    protocol_id: allNewComp?.[index]?.id,
                });
            }
        });
    }
    const result = await addTimeline({
        variables: reqObj,
    });
    const { data: { insert_timeline_one: timeline } } = result;
    setCurrentTimeline(timeline.id);
    poiPayload.forEach((item) => {
        item.timeline_id = timeline.id;
    });
    acPayload.forEach((item) => {
        item.timeline_id = timeline.id;
    });
    cdPayload.forEach((item) => {
        item.timeline_id = timeline.id;
    });
    // await bulkInsertPoi({
    //     variables: {
    //         objects: poiPayload,
    //     },
    // });

    // await bulkInsertAC({
    //     variables: {
    //         objects: acPayload,
    //     },
    // });

    // await bulkInsertCD({
    //     variables: {
    //         objects: cdPayload,
    //     },
    // });

    return Promise.resolve(timeline);
};

const flatProtocols = (list, parentComponents, headers) => {
    for (let i = 0, l = parentComponents.length; i < l; i += 1) {
        if (parentComponents[i].type === 'Protocol') {
            list.push(parentComponents[i]);
        } else if (parentComponents[i].type === 'Text') {
            headers.push(parentComponents[i]);
            flatProtocols(list, parentComponents[i].props.components, headers);
        }
    }
};

export {
    findGenericComponent,
    findParents,
    reorder,
    randomString,
    isVideo,
    createPlayer,
    getFileType,
    getVimeoVideoId,
    getFormattedDate,
    emailValidator,
    passwordValidator,
    FilterSettings,
    setActiveComponent,
    checkEmptyObject,
    getIframe,
    findPage,
    buildSettingsTreePage,
    flatOptions,
    findComponent,
    findPComponent,
    findTimelineColumnWidth,
    setComponentType,
    findProtocolComp,
    findParent,
    addTLSubChildrenMenu,
    findColumnCF,
    addCells,
    addOptionCells,
    findOptionCellMapping,
    transformText,
    addCellsForList,
    sortedObject,
    findParentWithoutHide,
    findChildWithoutHide,
    findChildComponentFlag,
    buildTemplateChildren,
    findCompParentTL,
    findAssociatedParent,
    findRouteUserType,
    getRoutePath,
    getRouteValidation,
    arrayToObject,
    findCompByType,
    deepCopy,
    getSelectionColors,
    fetchPatientAuth,
    getYouTubeId,
    getIframeHyperlink,
    filterFormTextAreaSettings,
    inheritComponentFunction,
    createTimelineForPatient,
    flatComponentByType,
    scrapThisURL,
    flatProtocols,
};
