import {
    Checkbox, TextField,
} from '@material-ui/core';
import {
    FilterSettings, fetchPatientAuth, findColumnCF, findComponent, setActiveComponent,
} from '../../helper/commonHelper';
import { OrgContext, TimelineContext, WorkshopContext } from '../../context';
import { Settings, makeInlineEditor } from '../Settings';
import { componentWithId } from '../Atoms';

import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { useIsSuperAdmin, usePrevious } from '../../helper/hooks';
import { useRecoilState } from 'recoil';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import DefaultComponentLayout from '../DefaultComponentLayout';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import React, {
    useContext, useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
        width: '100%',
    },
    formControlMain: {
        minWidth: 'auto',
        width: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    selectStyle: {
        padding: 5,
        marginLeft: 5,
        fontSize: 10,
    },
    autoCompleteListItem: {
        width: '100%',
    },
    selectedComp: {
        background: 'cornflowerblue',
        padding: '0px 2px',
    },
    radioButton: {
        '& svg': {
            width: 12,
            height: 12,
        },
    },
    clearIndicatorStyle: {
        marginRight: '-4px',
        padding: 3,
        '& svg': {
            fontSize: '.8rem',
        },
    },
    popupIndicatorStyle: {
        marginRight: '0px',
        padding: '0px',
        '& svg': {
            fontSize: '1.25rem',
        },
    },
    highlightOptionCls: {
        background: '#E1E8FC !important',
    },
    inputRoot: {
        '& input': {
            minWidth: '5px !important',
            fontWeight: '400',
            height: 13.5,
        },
    },
    inputRootLRRight: {
        '& input': {
            height: 27,
            minWidth: '5px !important',
            fontWeight: '400',
        },
    },
    eventNone: {
        pointerEvents: 'none',
        justifyContent: 'center',
        height: '100% !important',
    },
    mainInputRoot: {
        '&:focus': {
            borderBottom: 'none !important',
        },
        '& div': {
            '&:focus': {
                borderBottom: 'none !important',
            },
            '&::before': {
                borderBottom: 'none !important',
            },
            '&::after': {
                borderBottom: 'none !important',
            },
        },
    },
    paddSection: {
        paddingRight: '15px !important',
        flexWrap: 'nowrap',
        width: '100%',
        overflow: 'hidden',
        overflowX: 'auto',
    },
    mainInputRootValues: {
        fontSize: '11px',
        display: 'flex',
        overflowX: 'scroll',
        width: '100%',
        paddingBottom: '2.5px',
        fontWeight: '400',
        paddingLeft: '1px',
        '&::-webkit-scrollbar': {
            display:
                'none',
        },
        '&:hover': {
            '&::-webkit-scrollbar': {
                height: '2.5px',
                width: '2.5px',
                display: 'block',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#888',
            },
        },
    },
    fontSizsSett: {
        fontSize: '11px',
        display: 'flex',
        overflowX: 'auto',
        width: '100%',
        '&::-webkit-scrollbar': {
            display:
                'none',
        },
        '&:hover': {
            '&::-webkit-scrollbar': {
                height: '2.5px',
                width: '2.5px',
                display: 'block',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#888',
            },
        },
    },
    menuItemStyle: {
        '& *': {
            padding: '1px !important',
        },
        '& svg': {
            fontSize: '14px !important',
            margin: '0px 4px',
        },
        fontSize: 12,
    },
    menuPaperStyle: {
        '& > :first-child': {
            padding: '2px 0px !important',
            maxHeight: 150,
            overflow: 'hidden',
            overflowY: 'auto',
        },
    },
    borderBot: {
        '&:brfore': {
            content: '""',
        },
    },
    autoCompletePaper: {
        fontSize: 10,
    },
    autoCompleteInput: {
        fontSize: 10,
        padding: '6px !important',
    },
    borderBottomMultiSect: {
        borderBottom: '.25px solid #cbcbcb',
    },
    autoCompleteInputRoot: {
        paddingRight: '18px !important',
        display: 'block',
    },
    tagSizeSmallStyle: {
        height: 'auto',
        background: 'transparent',
        width: '100%',
        margin: '0px !important',
        display: 'block',
        padding: '4px 8px',
        '& span': {
            whiteSpace: 'initial',
            textOverflow: 'clip',
            paddingRight: 0,
            paddingLeft: 0,
        },
        '& svg': {
            display: 'none',
        },
    },
    endAdornmentStyle: {
        height: '100%',
        top: 0,
        alignItems: 'center',
        display: 'flex',
    },
}));

const MultiSelect = (componentProps) => {
    if (componentProps?.multiSelectCompProps) {
        componentProps = componentProps.multiSelectCompProps;
    }
    const [currentCompRef, setCurrentCompRef] = useState();
    const { id } = componentProps;
    const classes = useStyles();
    const [component, setComponentState] = useRecoilState(componentWithId(id));
    const [componentParent] = useRecoilState(componentWithId(componentProps?.parent));
    const [selected, setSelected] = useState(component.props.defaultOption);
    let [autoCompleteState] = useState([]);
    const { inWorkshop, compareModal } = useContext(WorkshopContext);
    const autoCompleteRef = useRef(null);
    const prevVal = usePrevious(componentProps?.props?.value);
    const {
        settingComponent,
        setSettingComponentHandle,
        associationData,
        workshopComponent,
        workshopActiveComponent,
        setSelectCompCF,
        specificSelect,
        setSpecificSelect,
        selectedTimeline,
        selectedColumn,
        setSelectedOptionIdsPreview,
        selectedOptionIdsPreview,
        currentSelectedValue,
        setCurrentSelectedValue,
        setAssociationValue,
        associationValue,
        updateTimeline,
        columnWidthData,
    } = useContext(TimelineContext);
    let isPatient = false;
    const { loggedUserType } = useContext(OrgContext);
    const isSuperAdmin = useIsSuperAdmin();
    if (loggedUserType === 'Patient') isPatient = true;
    const { isPatientWrite = false } = isPatient && columnWidthData?.column && columnWidthData?.column?.props?.group === 'Column'
    && fetchPatientAuth(loggedUserType, columnWidthData.column) || { isPatientWrite: false };
    const [selectedValue] = useState(isPatient && !isPatientWrite ? componentProps.props.value : []);
    const customMargin = inWorkshop ? classes.formControl : classes.formControlMain;

    let isSelected = false;
    if (settingComponent && settingComponent.id === id) {
        isSelected = true;
    }

    useEffect(() => {
        setSelected(component.props.defaultOption);
    }, [component.props.defaultOption]);

    useEffect(() => {
        if (workshopComponent && workshopActiveComponent) {
            const data = findColumnCF(workshopComponent, workshopActiveComponent, componentProps);
            if (data) {
                setSelectCompCF(data);
            }
        }
    }, [workshopActiveComponent]);

    useEffect(() => {
        if (!componentProps?.id || !selectedTimeline) return;
        let currentCompRef;
        const componentPropsId = componentProps.id.includes('-preview')
            ? componentProps.id.substring(0, componentProps.id.indexOf('-preview')) : componentProps.id;
        for (let i = 0, l = selectedTimeline.columns.length; i < l; i += 1) {
            currentCompRef = findComponent(componentProps.isProgression ? selectedTimeline.columns[i].props.progressions
                : selectedTimeline.columns[i].props.components, componentPropsId);
            if (currentCompRef) {
                break;
            }
        }
        if (currentCompRef) {
            setCurrentCompRef(currentCompRef);
        }
    }, [selectedTimeline, componentProps]);

    useEffect(() => {
        if ((!component.props.selectId.includes('-preview')
            ? !Object.prototype.hasOwnProperty.call(specificSelect, component.props.selectId)
            : !Object.prototype.hasOwnProperty.call(currentSelectedValue, component.props.selectId))
                && componentProps.props.value && componentProps.props.associatedCell) {
            handleAutocompleteChange(
                'event', component.props.selectId, componentProps.props.value, componentProps.props.value.map((ele) => ele.value)
            );
        }
    });

    useLayoutEffect(() => {
        if (prevVal?.length === componentProps?.props?.value?.length || !componentProps?.rowRef) return;
        const rowRef = document.querySelector(`#false-${componentProps?.rowRef}`);
        if (rowRef?.querySelectorAll('.inputRoot')) {
            rowRef.querySelectorAll('.inputRoot').forEach((node) => {
                node.style.height = 'auto';
            });

            const allItems = [...rowRef.querySelectorAll('.inputRoot')].map((item) => item.clientHeight);
            const maxHeight = Math.max(...allItems);
            rowRef.querySelectorAll('.inputRoot').forEach((node) => {
                node.style.height = `${maxHeight}px`;
            });
        }
    }, [componentProps?.id, componentProps?.rowRef, componentProps?.props?.value, prevVal]);

    const handleChange = (event, selectId) => {
        setSelected(event.target.value);
        const newIds = {};
        let selectCel = null;
        for (let i = 0, l = selectedTimeline.columns.length; i < l; i += 1) {
            selectCel = findComponent(selectedTimeline.columns[i].props.components, componentProps.parent);
            if (selectCel) {
                selectCel = findComponent(selectedTimeline.columns[i].props.components, selectCel.parent);
                break;
            }
        }
        if (selectCel && selectCel.type === 'Column') {
            selectCel.props.components.forEach((ele) => {
                const { id } = ele.props.components[0];
                ele.props.components[0].props.options.forEach((option, index) => {
                    if (Object.prototype.hasOwnProperty.call(selectedOptionIdsPreview, `${id}-preview-${index}`)) {
                        newIds[`${id}-preview-${index}`] = false;
                    }
                });
            });
        }
        componentProps.props.options.forEach((option) => {
            if (option.id) {
                newIds[option.id] = event.target.value.find((item) => item === option.value);
            }
        });
        setSelectedOptionIdsPreview((state) => ({ ...state, ...newIds }));
        // }

        let selectedOptionIndex = null;
        const optionsArray = [...componentParent.props.components[0].props.options];
        const selectIds = [];
        for (let i = 0, l = optionsArray.length; i < l; i += 1) {
            if (event.target.value.includes(optionsArray[i].value)) {
                selectedOptionIndex = i;
                if (componentParent.props.components[0].props.associatedCell) {
                    const selectCell = findComponent(
                        selectedTimeline.columns[selectedColumn].props.components, componentParent.props.components[0].props.associatedCell
                    );
                    if (selectCell) {
                        const requiredComponent = selectCell.props?.components?.[selectedOptionIndex]?.props?.components;
                        if (requiredComponent?.length > 0) {
                            selectIds.push(requiredComponent[0].id);
                        }
                    }
                }
            }
        }
        setSpecificSelect({ ...specificSelect, [selectId]: selectIds });
    };

    const renderOption = (item) => (
        <FormControlLabel control={<Radio />} label={item.label} />
    );

    const renderEditor = () => makeInlineEditor({
        field: {
            key: 'options',
            type: 'List',
            label: 'Options',
            itemRender: renderOption,
        },
        component,
        setComponentState,
        classes,
    });

    const handleCloseMultiSelect = () => {
        if (isPatient && !isPatientWrite) {
            componentProps.props.value = selectedValue;
            component.props.value = selectedValue;
            setComponentState({ ...component });
        }
    };

    const handleAutocompleteChange = (event, selectId, newValue, newValueOptions) => {
        const newIds = {};
        let selectCel = null;
        for (let i = 0, l = selectedTimeline.columns.length; i < l; i += 1) {
            selectCel = findComponent(selectedTimeline.columns[i].props.components, componentProps.parent);
            if (selectCel) {
                selectCel = findComponent(selectedTimeline.columns[i].props.components, selectCel.parent);
                break;
            }
        }
        if (selectCel && selectCel.type === 'Column') {
            selectCel.props.components.forEach((ele) => {
                const comp = ele.props.components?.[0];
                if (comp) {
                    const { id } = comp;
                    ele.props.components[0].props.options.forEach((option, index) => {
                        if (Object.prototype.hasOwnProperty.call(selectedOptionIdsPreview, `${id}-preview-${index}`)) {
                            newIds[`${id}-preview-${index}`] = false;
                        }
                    });
                }
            });
        }
        componentProps.props.options.forEach((option) => {
            if (option.id) {
                newIds[option.id] = newValueOptions
                    .find((item) => item.requiredComponentFromConfigParent === newValue.requiredId && item === option.value);
            }
        });
        setSelectedOptionIdsPreview((state) => ({ ...state, ...newIds }));
        setCurrentSelectedValue({ ...currentSelectedValue, [selectId]: newValueOptions });
        if (currentCompRef) currentCompRef.props.value = newValue;

        const isUpdate = isPatient ? isPatientWrite : !isSuperAdmin;
        if (isUpdate) {
            componentProps.props.value = newValue;
        }

        if (!isPatient || (isPatient && isPatientWrite)) {
            updateTimeline();
        }

        let selectedOptionIndex = null;
        if (!componentParent.parent) {
            return;
        }
        const optionsArray = [...componentParent?.props?.components[0].props.options];
        const selectIds = [];
        for (let i = 0, l = optionsArray.length; i < l; i += 1) {
            if (newValueOptions.includes(optionsArray[i].value)) {
                selectedOptionIndex = i;
                if (componentParent.props.components[0].props.associatedCell) {
                    let selectCell = null;
                    for (let j = 0, m = selectedTimeline.columns.length; j < m; j += 1) {
                        selectCell = findComponent(
                            selectedTimeline.columns[j].props.components, componentParent.props.components[0].props.associatedCell
                        );
                        if (selectCell) {
                            break;
                        }
                    }
                    if (selectCell) {
                        const requiredComponent = selectCell.props?.components?.[selectedOptionIndex]?.props?.components;
                        if (requiredComponent?.length > 0) {
                            selectIds.push(requiredComponent[0].id);
                            setCurrentSelectedValue({ ...currentSelectedValue, [selectId]: newValueOptions });
                            setAssociationValue({ ...associationValue, [requiredComponent[0].id]: optionsArray[i].value });// childrenId: parentValue
                        }
                    }
                } else {
                    // eslint-disable-next-line no-loop-func
                    componentParent.props.components[0].props.options.forEach((opt) => {
                        if (opt.props && opt.props.associatedCell) {
                            let selectCell = null;
                            for (let j = 0, m = selectedTimeline.columns.length; j < m; j += 1) {
                                selectCell = findComponent(selectedTimeline.columns[j].props.components, opt.props.associatedCell);
                                if (selectCell) {
                                    break;
                                }
                            }
                            if (selectCell) {
                                if (selectCell.index === selectedOptionIndex) {
                                    const requiredComponent = selectCell.props?.components[0];
                                    selectIds.push(requiredComponent?.id);
                                    setCurrentSelectedValue({ ...currentSelectedValue, [selectId]: newValueOptions });
                                }
                            }
                        }
                    });
                }
            }
        }
        setSpecificSelect({ ...specificSelect, [selectId]: selectIds });
    };

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;

    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    const getAutocompleteOptions = (props) => {
        let optionClass = '';
        const hideOptionCls = '';
        let selectCell = null;
        if (props.mappedOption && props.mappedOption.includes('-preview')) {
            const requiredId = props.mappedOption.substring(0, props.mappedOption.indexOf('-preview'));
            for (let i = 0, l = selectedTimeline.columns.length; i < l; i += 1) {
                selectCell = findComponent(selectedTimeline.columns[i].props.components, requiredId);
                if (selectCell) {
                    break;
                }
            }
        } else {
            for (let i = 0, l = selectedTimeline.columns.length; i < l; i += 1) {
                selectCell = findComponent(selectedTimeline.columns[i].props.components, componentProps.associatedParent);
                if (selectCell) {
                    break;
                }
            }
        }
        let associationIndex = -1;
        const selectCellOptions = [];
        for (let i = 0, l = selectCell?.props.options?.length; i < l; i += 1) {
            selectCellOptions[i] = selectCell?.props.options[i].value;
        }
        const currentSelectedValueKey = Object.prototype.hasOwnProperty.call(currentSelectedValue, `${componentProps.associatedParent}-preview`)
            ? `${componentProps.associatedParent}-preview` : componentProps.associatedParent;
        const visitedValues = [];
        if (currentSelectedValue[currentSelectedValueKey] && Array.isArray(currentSelectedValue[currentSelectedValueKey])) {
            currentSelectedValue[currentSelectedValueKey].forEach((val) => {
                if ((!selectCell?.props?.association?.hasOwnProperty(val)
                    || selectCell?.props.association[val]?.highlightedChildren?.length === 0) && props.aData === val) {
                    optionClass = props.isHighlight ? classes.highlightOptionCls : '';
                    visitedValues.push(props.value);
                } else {
                    // eslint-disable-next-line no-unused-vars
                    for (const key in selectCell?.props.association) {
                        if (selectCellOptions.includes(key) && !visitedValues.includes(props.value)) {
                            associationIndex += 1;
                            if (currentSelectedValue[componentProps.associatedParent]?.includes(key)
                                || currentSelectedValue[`${componentProps.associatedParent}-preview`]?.includes(key)) {
                                const hc = selectCell.props.association[key].highlightedChildren;
                                optionClass = (hc.includes(`${props.value}#${props.requiredId}`)
                                    || hc.includes(`${props.value}#${props.requiredId}`
                                        .substring(0, `${props.value}#${props.requiredId}`.lastIndexOf('-preview'))))
                                    && (Object.prototype.hasOwnProperty.call(specificSelect, componentProps.associatedParent)
                                    || selectedOptionIdsPreview[`${selectCell.id}-preview-${associationIndex}`]
                                    || selectedOptionIdsPreview[`${selectCell.props.options[associationIndex]?.id}`]) !== undefined
                                    ? classes.highlightOptionCls : '';
                                if (optionClass) {
                                    break;
                                }
                            }
                        }
                    }
                    visitedValues.push(props.value);
                }
            });
        }
        const isChecked = autoCompleteState.includes(props.label);

        return (
            // eslint-disable-next-line react/no-unknown-property
            <li key={props.key} container className={[classes.autoCompleteListItem, optionClass, hideOptionCls].join(' ')}>
                <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    checked={isChecked}
                />
                {props.label}
            </li>
        );
    };

    const renderAutocompleteView = () => {
        let requiredOptions = !component?.props?.associatedCell || !inWorkshop ? (
            componentProps.props.options && componentProps.props.options.map((item, index) => (item.value ? { ...item, key: index } : null))
        ) : (
            component.props.options && component.props.options.map((item, index) => (item.value ? { ...item, key: index } : null))
        );

        if ((!component?.props?.associatedCell || !inWorkshop) && requiredOptions.length < (componentProps?.props?.value?.length || 0)) {
            requiredOptions = componentProps?.props?.value;
        }

        return (
            <div className={classes.borderBottomMultiSect}>
                <FormControl
                    className={`${customMargin} ${componentProps?.props?.LRProps?.LRtype === 'right' && classes.eventNone}`}
                    onClick={(e) => e.stopPropagation()}
                >
                    <Autocomplete
                        multiple
                        options={[...requiredOptions]}
                        ref={autoCompleteRef}
                        className={classes.mainInputRoot}
                        classes={{
                            inputRoot: `inputRoot parent-${componentProps?.associatedParent} ${classes.autoCompleteInputRoot}`,
                            tagSizeSmall: classes.tagSizeSmallStyle,
                            popupIndicator: classes.popupIndicatorStyle,
                            listbox: classes.menuItemStyle,
                            paper: classes.menuPaperStyle,
                            endAdornment: classes.endAdornmentStyle,
                        }}
                        value={componentProps.props.value}
                        disableClearable
                        getOptionLabel={(option) => option?.label}
                        renderOption={(props) => (getAutocompleteOptions(props, component.props.isHighlight))}
                        onClose={(event) => {
                            handleCloseMultiSelect(event, component.props.selectId);
                        }}
                        onOpen={() => {}}
                        disableCloseOnSelect
                        size="small"
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label=""
                                classes={{ root: classes.inputRoot }}
                                inputProps={{
                                    ...params.inputProps,
                                    'data-parent': componentProps?.associatedParent,
                                }}
                            />
                        )}
                        onChange={(event, newValue) => {
                            handleAutocompleteChange(
                                event, component.props.selectId, newValue, newValue.map((ele) => ele.value)
                            );
                        }}
                        getOptionSelected={(option, values) => {
                            if (option.value === values.value) {
                                autoCompleteState = autoCompleteState.includes(option.label)
                                    ? [...autoCompleteState] : [...autoCompleteState, option.label];
                                return (true);
                            }
                            return (false);
                        }}
                    />
                </FormControl>
            </div>
        );
    };

    const renderRadioView = () => (
        <div>
            <FormControl className={customMargin}>
                <RadioGroup
                    value={selected}
                    onChange={handleChange}
                >
                    {component.props.options
                        && component.props.options.map((item, index) => (
                            <FormControlLabel
                                key={index}
                                value={item.value}
                                control={<Radio classes={{ root: classes.radioButton }} />}
                                label={item.label}
                            />
                        ))}
                </RadioGroup>
            </FormControl>
        </div>
    );

    const renderSelectOption = (item, index) => {
        let optionClass = '';
        let hideOptionCls = '';
        const findItem = associationData && associationData.find((ad) => ad.id === componentProps.id);
        const highlightedChildren = findItem && findItem.association.highlightedChildren || [];
        const disabledChildren = findItem && findItem.association?.disabledChildren || [];
        if ((highlightedChildren && findItem) || (disabledChildren && findItem)) {
            optionClass = highlightedChildren.find((option) => option === item.value) ? classes.highlightOptionCls : '';
            hideOptionCls = disabledChildren.find((option) => option === item.value) ? classes.hideOptionCls : '';
        }
        return (
            <MenuItem key={index} value={item.value} className={[optionClass, hideOptionCls].join(' ')}>{item.label}</MenuItem>
        );
    };

    const renderSingleSelectView = () => (
        <div>
            <FormControl className={customMargin}>
                <Select
                    value={selected}
                    onChange={handleChange}
                    classes={{
                        select: classes.selectStyle,
                    }}
                >
                    {component.props.options && component.props.options.map((item, index) => (item.label
                        ? renderSelectOption(item, index)
                        : null))}
                </Select>
            </FormControl>
        </div>
    );

    const renderCheckboxView = () => (
        <div>
            <FormControl className={customMargin}>
                {component.props.options && component.props.options.map((item, index) => (
                    <FormControlLabel
                        key={index}
                        value={item.value}
                        control={<Checkbox value={selected} onChange={handleChange} classes={{ root: classes.radioButton }} />}
                        label={item.label}
                    />
                ))}
            </FormControl>
        </div>
    );
    return (
        <DefaultComponentLayout component={component} id={id}>
            {(inWorkshop && !compareModal) ? (
                <div
                    className={isSelected ? classes.selectedComp : null}
                    id={isSelected ? 'selectedCompId' : ''}
                    onClick={(event) => { event.stopPropagation(); setActiveComponent(setSettingComponentHandle, componentProps); }}
                    aria-hidden="true"
                >
                    {renderEditor()}
                </div>
            ) : component.props.renderAs === 'radio' ? renderRadioView()
                : (component.props.renderAs === 'Checkbox' ? renderCheckboxView()
                    : (component.props.renderAs === 'Singleselect' ? renderSingleSelectView() : renderAutocompleteView()))}
        </DefaultComponentLayout>
    );
};

const MultiSelectPreview = ({ component }) => {
    const classes = useStyles();
    const {
        options, defaultOption, renderAs,
    } = component.props;
    const renderSelectView = () => (
        <Select value={defaultOption} multiple classes={{ root: classes.selectStyle }}>
            {options && options.map((item, index) => (item.label
                ? <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                : null))}
        </Select>
    );

    const renderSingleSelectView = () => (
        <Select value={defaultOption} classes={{ root: classes.selectStyle }}>
            {options && options.map((item, index) => (item.label
                ? <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                : null))}
        </Select>
    );

    const renderRadioView = () => (
        <RadioGroup>
            {options && options.map((item, index) => (item.label
                ? (
                    <FormControlLabel
                        key={index}
                        disabled
                        value={item.value}
                        control={<Radio classes={{ root: classes.radioButton }} />}
                        label={item.label}
                    />
                )
                : null))}
        </RadioGroup>
    );

    const renderCheckboxView = () => (
        <Checkbox>
            {options && options.map((item, index) => (item.label
                ? (
                    <FormControlLabel
                        key={index}
                        disabled
                        value={item.value}
                        control={<Checkbox classes={{ root: classes.radioButton }} />}
                        label={item.label}
                    />
                )
                : null))}
        </Checkbox>
    );

    return (
        <DefaultComponentLayout preview component={component}>
            <FormControl className={classes.formControlMain}>
                {renderAs === 'radio'
                    ? renderRadioView() : (component.props.renderAs === 'Checkbox'
                        ? renderCheckboxView() : (component.props.renderAs === 'Singleselect' ? renderSingleSelectView() : renderSelectView()))}
            </FormControl>
        </DefaultComponentLayout>
    );
};

export const SelectProps = {
    display: [
        {
            key: 'display',
            type: 'Select',
            label: 'Layout',
            default: 'tree',
            options: [{ label: 'Spreadsheet', value: 'spreadsheet' }, { label: 'Tree', value: 'tree' }],
            id: 'MultiSelect',
        },
        {
            key: 'renderAs',
            type: 'Select',
            label: 'Component Options',
            default: 'select',
            options: [
                { label: 'Single Select Checkbox', value: 'radio' },
                { label: 'Multi Select Checkbox', value: 'Checkbox' },
                { label: 'Single Select DropDown', value: 'Singleselect' },
                { label: 'Multi Select DropDown', value: 'select' },
            ],
            id: 'MultiSelect',
        },
        {
            key: 'defaultOption',
            type: 'TextField',
            label: 'Default Value',
            id: 'MultiSelect',
        },
        {
            key: 'currentAssociation',
            type: 'Select',
            label: 'Association',
            default: 'tree',
            options: [],
            id: 'MultiSelect',
        },
        {
            key: 'highlightedChildren',
            type: 'MultiSelect',
            label: 'Children-Highlighted',
            default: 'tree',
            options: [],
            id: 'MultiSelect',
        },
        {
            key: 'disabledChildren',
            type: 'MultiSelect',
            label: 'Children-Hidden',
            default: 'tree',
            options: [],
            id: 'MultiSelect',
        },
        {
            key: 'componentTypes',
            type: 'StaticLabel',
            label: 'Generic Component',
            value: 'N/A',
            id: 'MultiSelect',
        },
        {
            key: 'columnNumber',
            type: 'StaticLabel',
            label: 'Column',
            value: 'N/A',
            id: 'MultiSelect',
        },
    ],
    poi: false,
};

const MultiSelectEditor = ({ config, updateComponent }) => {
    const propsCopy = JSON.parse(JSON.stringify(SelectProps));
    const [component, setComponentState] = useRecoilState(componentWithId(config.id));
    const {
        settingMenuType, workshopComponent, workshopSelectedTemplate, selectedComponent,
    } = useContext(TimelineContext);

    const currentAssoc = config.props.currentAssociation;
    let currentAssociationIndex;
    for (let i = 0, l = config.props.options.length; i < l; i += 1) {
        if (config.props.options[i].value === currentAssoc) {
            currentAssociationIndex = i;
            break;
        }
    }

    let propsComp = workshopComponent && workshopComponent.props.components;
    let configParent = config.parent && config.parent;

    propsCopy.display.forEach((obj) => {
        if (obj.key === 'currentAssociation') {
            obj.options = config.props.options;
        }
    });
    if (!component?.props?.currentAssociation) {
        component.props.currentAssociation = component.props.options?.[0]?.value;
    }
    const currentAssociation = component?.props?.currentAssociation;
    if (!component?.props?.association) {
        component.props.options.forEach((associationKey) => {
            component.props.association = {
                [associationKey]: {
                    highlightedChildren: [],
                    disabledChildren: [],
                },
            };
        });
    }

    if (!propsComp && workshopSelectedTemplate) {
        propsComp = workshopSelectedTemplate.template.props.components;
    }

    if (!configParent) {
        configParent = selectedComponent.parent;
    }

    component.props.highlightedChildren = component?.props?.association?.[currentAssociation]?.highlightedChildren || [];
    component.props.disabledChildren = component?.props?.association?.[currentAssociation]?.disabledChildren || [];

    const requiredComponentFromConfigParent = findComponent(propsComp, configParent);
    const requiredComponent = requiredComponentFromConfigParent?.props?.components.length > 0
        ? findComponent(propsComp, requiredComponentFromConfigParent.props.components[0].props.associatedCell) : null;
    let requiredOptions = [];
    let childComp = null;
    if (!requiredComponent) {
        const rc = findComponent(propsComp, configParent);
        if (rc && rc.props?.components.length > 0) {
            if (!workshopComponent?.props?.isHighlight) {
                const opt = rc.props.components[0].props.options[currentAssociationIndex];
                if (opt?.props && opt?.props.associatedCell) {
                    childComp = JSON.parse(JSON.stringify(findComponent(propsComp, opt.props.associatedCell)));
                    if (childComp.props.components[0]) {
                        childComp.props.components[0].props.options.forEach((o) => {
                            o.requiredId = childComp.props.components[0].id;
                        });
                        requiredOptions = [...requiredOptions, ...childComp?.props?.components[0].props.options];
                    }
                }
            } else {
                rc.props.components[0].props.options.forEach((opt) => {
                    if (opt.props && opt.props.associatedCell) {
                        childComp = JSON.parse(JSON.stringify(findComponent(propsComp, opt.props.associatedCell)));
                        if (childComp.props.components[0]) {
                            childComp.props.components[0].props.options.forEach((o) => {
                                o.requiredId = childComp.props.components[0].id;
                            });
                            requiredOptions = [...requiredOptions, ...childComp?.props?.components[0].props.options];
                        }
                    }
                });
            }
        }
    }
    if (requiredComponent) {
        if (!workshopComponent?.props?.isHighlight
            && requiredComponent.props.components[currentAssociationIndex]
            && requiredComponent.props.components[currentAssociationIndex].props.components[0]) {
            const requiredId = requiredComponent.props.components[currentAssociationIndex].props.components[0].id;
            requiredComponent.props.components[currentAssociationIndex].props.components[0].props.options.forEach((opt) => {
                opt.requiredId = requiredId;
            });
            childComp = JSON.parse(JSON.stringify(requiredComponent.props.components[currentAssociationIndex]));
            requiredOptions = [...requiredComponent.props.components[currentAssociationIndex].props.components[0].props.options];
        } else {
            requiredComponent.props.components.forEach((comp) => {
                comp.props.components.forEach((c) => {
                    c.props.options.forEach((o) => {
                        o.requiredId = c.id;
                    });
                });
                childComp = JSON.parse(JSON.stringify(comp));
                requiredOptions = comp.props.components.length > 0
                    ? [...requiredOptions, ...comp.props.components[0].props.options] : [...requiredOptions];
            });
        }
    }

    if (childComp) {
        if (childComp.props.components.length > 0) {
            childComp.props.components[0].props.options = [...requiredOptions];
        }
        const selectComp = childComp.props.components.find((obj) => obj.type === 'Select' || obj.type === 'MultiSelect');
        if (component?.props.association && component?.props.association[currentAssociation]) {
            if (!workshopComponent?.props?.isHighlight) {
                component.props.association[currentAssociation].immediateChildren = [...requiredOptions];
            }
            // eslint-disable-next-line max-len
            component.props.association[currentAssociation].notHighlightedChildren = component?.props?.association?.[currentAssociation]?.notHighlightedChildren || selectComp?.props.options.map((item) => `${item.value}#${item.requiredId}`);
            // eslint-disable-next-line max-len
            component.props.association[currentAssociation].notHighlightedChildren = selectComp?.props.options.map((item) => `${item.value}#${item.requiredId}`).filter((item) => !component?.props.highlightedChildren.includes(item));
        }
        if (selectComp) {
            propsCopy.display.forEach((obj) => {
                if (obj.key === 'highlightedChildren' || obj.key === 'disabledChildren') {
                    obj.options = selectComp.props.options;
                }
            });
        }
    } else {
        propsCopy.display = propsCopy.display
            .filter((obj) => (obj.key !== 'association' && obj.key !== 'highlightedChildren'
                && obj.key !== 'disabledChildren' && obj.key !== 'notHighlightedChildren'));
    }
    const editorProps = { display: FilterSettings(propsCopy, settingMenuType), poi: SelectProps.poi };
    const requiredColumn = findComponent(workshopComponent?.props.components, component?.parent?.[0]?.colRef || config?.colRef);
    for (let i = 0, l = editorProps?.display?.length; i < l; i += 1) {
        if (editorProps?.display?.[i]?.key === 'columnNumber') {
            editorProps.display[i].value = (requiredColumn?.compIndex + 1) || 1;
            break;
        }
    }
    return (
        <Settings editorProps={editorProps} component={component} setComponentState={setComponentState} saveComponent={updateComponent} />
    );
};

export { MultiSelect, MultiSelectEditor, MultiSelectPreview };
