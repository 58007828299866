/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import '../css/style.css';
import {
    Box, Container, Grid, GridList, GridListTile, GridListTileBar,
} from '@material-ui/core';
import { MediaEditorContext } from '../context';
import { createPlayer, getFileType, isVideo } from '../helper/commonHelper';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import MediaPlaceholder from '../images/imageDeletedPlaceholder.png';
import MediaViewer from './MediaViewer';
import React, {
    Fragment, useContext, useEffect, useState,
} from 'react';

/**
 * Set WorkshopSelectedMedia Component style
 */
const useStyles = makeStyles(() => createStyles({
    childContainer: {
        float: 'left',
        paddingLeft: 0,
        paddingRight: 0,
    },
    videoEditable: {
        height: '100%',
        width: '100%',
        position: 'absolute',
        cursor: 'pointer',
    },
    mediaAllignment: {
        height: '100%',
        width: '100%',
        cursor: 'pointer',
    },
    gridImg: {
        width: '100%',
        paddingTop: 36,
        paddingBottom: 36,
        height: 170,
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
    },
    gridList: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    gridScrollView: {
        flexWrap: 'nowrap',
        transform: 'translateZ(0)',
        width: '100%',
    },
    titleBar: {
        background:
            '#fff',
    },
    hiddenTitle: {
        display: 'none',
    },
    gridInputDiv: {
        width: '100%',
    },
    titleCaptionInpt: {
        padding: '15.5px 14px',
    },
}));

/**
 * This component is used to Edit the Media and save data
 * @param {boolean}  isWorkshop[ recieved from props]
 * @param {string} mediaCompId [recieved function as props to save the selected image]
 * @param {array} imageList [recieved function as props to save the selected image]
 * @param {props} props [recieved function all props]
 */
const WorkshopSelectedMedia = ({
    isWorkshop, mediaCompId, imageList, props,
}) => {
    const classes = useStyles();
    const { deletedMediaContext } = useContext(MediaEditorContext);
    const mediaContext = (!deletedMediaContext) ? [] : deletedMediaContext;
    let targetComp = 'No Image Selected';
    const imageListData = [...imageList];
    const [viewMedia, setViewMedia] = useState({});
    const [isMediaViewerOpen, setMediaViewerOpen] = useState(false);
    const { slideTitle, slideCaption } = props;

    imageListData.forEach((image, key) => {
        if (isVideo(image.name) && mediaCompId) {
            image.filetype = getFileType(image.name);
            image.videoDivWorkshopId = `video-div-ws-${mediaCompId}-${key}`;
            image.videoDivId = `video-div-${mediaCompId}-${key}`;
            image.isVideo = true;
        }
    });

    if (mediaContext.length > 0) {
        imageListData.forEach((data) => {
            const status = mediaContext.filter((ele) => data.url === ele.url);
            if (status.length > 0) {
                data.isShow = false;
            } else {
                data.isShow = true;
            }
        });
    } else {
        imageListData.forEach((data) => data.isShow);
    }

    /**
    * handleMediaViewerStatus [set media viewer true or false]
    */
    const handleMediaViewerStatus = () => {
        setMediaViewerOpen(!isMediaViewerOpen);
    };

    /**
     * @function onSelectMedia
     * @param {object} get the selected media to pass data to the child component
     */
    const onSelectMedia = (media) => {
        handleMediaViewerStatus();
        setViewMedia(media);
    };

    const renderMedia = () => imageListData.map((tile) => {
        const targetId = isWorkshop ? tile.videoDivWorkshopId : tile.videoDivId;
        const mediaUrl = (tile.isShow) ? tile.url : MediaPlaceholder;
        let mediaElement = (
            <img
                src={mediaUrl}
                alt=""
                className={classes.mediaAllignment}
                onClick={() => onSelectMedia(tile)}
            />
        );

        if (tile.isVideo && tile.isShow) {
            mediaElement = (
                <Fragment>
                    <div className={classes.videoEditable} onClick={() => onSelectMedia(tile)} />
                    <div className="videoSize" id={targetId} />
                </Fragment>
            );
        }

        return (
            <GridListTile>
                <Fragment>
                    <GridListTile
                        classes={{ imgFullHeight: classes.gridImg }}
                    >
                        {mediaElement}
                        <GridListTileBar
                            titlePosition="top"
                            actionIcon={(
                                <div>{tile.title}</div>
                            )}
                            className={classes.titleBar}
                            classes={{
                                titleWrap: classes.hiddenTitle,
                                actionIcon: classes.gridInputDiv,
                            }}
                            actionPosition="left"
                        />
                        <GridListTileBar
                            titlePosition="bottom"
                            actionIcon={(
                                <div>{tile.caption}</div>
                            )}
                            className={classes.titleBar}
                            classes={{
                                titleWrap: classes.hiddenTitle,
                                actionIcon: classes.gridInputDiv,
                            }}
                            actionPosition="left"
                        />
                        <Box component="div" display="inline" />
                    </GridListTile>
                </Fragment>
            </GridListTile>
        );
    });

    useEffect(() => {
        createPlayer(imageListData, isWorkshop);
    });

    if (imageListData.length > 0) {
        targetComp = (
            <Grid container>
                <Grid item xs={12}>
                    <Box>
                        {slideTitle}
                    </Box>
                    <Box>
                        <Grid container>
                            <div className={classes.root}>
                                <GridList
                                    className={props.layoutOption === 'scroll' ? classes.gridScrollView : classes.gridList}
                                    cols={3}
                                >
                                    {renderMedia()}
                                </GridList>
                            </div>
                        </Grid>
                    </Box>
                    <Box>
                        {slideCaption}
                    </Box>
                </Grid>
            </Grid>
        );
    }

    return (
        <Container maxWidth="xl" className={classes.childContainer}>
            {isMediaViewerOpen
                && (
                    <MediaViewer
                        open={isMediaViewerOpen}
                        handleClose={handleMediaViewerStatus}
                        viewMedia={viewMedia}
                    />
                )}
            {targetComp}
        </Container>
    );
};

export default WorkshopSelectedMedia;
