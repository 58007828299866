import { Container } from './Container';
import { MenuDefaults, buildComponentMenu } from './utils/EditorMenu';
import { Settings, makeInlineEditor } from './Settings';
import { WorkshopContext } from '../context';
import { componentWithId } from './Atoms';
import { makeStyles } from '@material-ui/core/styles';
import { useRecoilState } from 'recoil';
import Grid from '@material-ui/core/Grid';
import React, { useContext } from 'react';
import Typography from '@material-ui/core/Typography';

const TitleProp = {
    key: 'title',
    type: 'TextField',
    label: 'Enter Protocol',
};

const ProtocolGroupProps = {
    display: [],
    poi: true,
};

const useRowStyles = makeStyles(() => ({
    column: {
        border: '1px solid #ececec',
        padding: 0,
    },
    header: {
        border: '1px solid #ececec',
        padding: '4px',
        textDecoration: 'underline',
    },
    formControl: {
        margin: '10px',
        width: '95%',
    },
    columHeadType: {
        fontSize: '0.75rem',
    },
    fullWidth: {
        width: '100%',
    },
}));

const ProtocolGroup = ({ id, props: { title, components }, noChildren = false }) => {
    const classes = useRowStyles();
    const [component, setComponentState] = useRecoilState(componentWithId(id));

    const { inWorkshop, compareModal } = useContext(WorkshopContext);

    if (noChildren) {
        return (<Typography className={classes.heading}>{title}</Typography>);
    }

    const renderTitleEditor = () => makeInlineEditor({
        field: TitleProp,
        component,
        setComponentState,
        classes,
    });

    const renderTitle = () => (
        <Typography className={classes.columHeadType} color="inherit">
            {title}
        </Typography>
    );

    return (
        <div className={classes.fullWidth}>
            <Grid container>
                <Grid item xs={12} className={classes.header}>
                    {(inWorkshop && !compareModal) ? renderTitleEditor() : renderTitle()}
                </Grid>
                <Grid item xs={12} className={classes.column}>
                    <Container props={{ components }} />
                </Grid>
            </Grid>
        </div>
    );
};

const ProtocolGroupEditor = ({ config, updateComponent }) => {
    const [component, setComponentState] = useRecoilState(componentWithId(config.id));

    return (
        <Settings editorProps={ProtocolGroupProps} component={component} setComponentState={setComponentState} saveComponent={updateComponent} />
    );
};

const ProtocolGroupEditMenu = ({ componentLibrary, component }) => {
    const filter = (item) => (item.type === 'Text' || item.type === component.clickedArea || item.type === 'ProtocolGroup');
    const labelMaker = (label) => (`Add ${label}`);
    const add = buildComponentMenu(componentLibrary, filter, null, labelMaker);

    const children = buildComponentMenu(componentLibrary, (item) => item.type === 'Protocol', null, 'child');
    const menu = [
        ...add,
        MenuDefaults.EDIT,
        MenuDefaults.DELETE,
        MenuDefaults.ADD_CHILD(children),
    ];
    return menu;
};

export { ProtocolGroup, ProtocolGroupEditor, ProtocolGroupEditMenu };
