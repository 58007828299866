import { Container } from './Container';
import { Grid, Typography } from '@material-ui/core';
import { TimelineContext } from '../context';
import { makeStyles } from '@material-ui/core/styles';
import { standardHeaders } from './utils/StandardStyles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useContext } from 'react';

const useStyles = makeStyles(() => ({
    widthSectPart: {
        width: '95%',
        maxWidth: '96%',
    },
    modalTitleCont: {
        borderBottom: '1px solid #707070',
    },
    viewModeColumnCont: {
        padding: 4,
    },
    viewModeColumnTitleCont: {
        border: '1px solid #066785',
        textAlign: 'center',
        padding: '8px 0px',
    },
    viewModeContMain: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 4,
    },
    viewModeColCont: {
        width: '20%',
        padding: 4,
    },
    columnTitle1: {
        border: '1px solid #066785',
        height: 36,
        textAlign: 'center',
        paddingTop: 8,
    },
    columnTitle2: {
        background: '#F7F7F7',
        textTransform: 'uppercase',
        textAlign: 'center',
        paddingTop: 8,
        marginTop: 4,
        height: 36,
    },
    closeIconCont: {
        textAlign: 'right',
    },
    closeIcon: {
        cursor: 'pointer',
    },
    fontSize12: {
        fontSize: 12,
    },
    dialogContent: {
        padding: '0px 8px',
        marginBottom: 8,
    },
    sectionHeader: {
        margin: 0,
        minHeight: 'auto !important',
        height: 32,
        border: '1px solid #ececec',
        ...standardHeaders.sectionHeader,
        position: 'relative',
    },
    iconButton: {
        color: '#fff',
        position: 'absolute',
        left: '0px',
    },
    columHeadType: {
        fontSize: '0.75rem',
        width: '100%',
    },
}));

const renderItems = ['Medguide', 'Org', 'User', 'Advance', 'Basic'];
const orgsData = [
    { id: 1, name: 'Dr. Cruise' },
    { id: 2, name: 'Dr. X' },
];

const usersData = [
    { id: 1, type: 'Staff' },
    { id: 2, type: 'Patients' },
    { id: 3, type: 'All' },
];

const DataMatrixViewModeModal = ({ dmViewMode, handleCloseDMViewMode, selectedDropdownValue }) => {
    const classes = useStyles();
    const { dataMatrixComponent, isProgressionDM, selectedTimeline } = useContext(TimelineContext);
    const orgData = orgsData.find((org) => org.id === selectedDropdownValue.org);
    const userData = usersData.find((user) => user.id === selectedDropdownValue.userType);

    let requiredColumnIndex;
    for (let i = 0, l = selectedTimeline.columns.length; i < l; i += 1) {
        if (selectedTimeline.columns[i].props.components.length > 0) {
            requiredColumnIndex = i;
            break;
        }
    }

    return (
        <div>
            <Dialog
                open={dmViewMode}
                onClose={handleCloseDMViewMode}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{ paperWidthSm: classes.widthSectPart }}
            >
                <DialogTitle id="alert-dialog-title" className={classes.modalTitleCont}>
                    <Grid container>
                        <Grid item xs={10} md={11}>View Mode</Grid>
                        <Grid item xs={2} md={1} className={classes.closeIconCont}>
                            <CloseIcon onClick={handleCloseDMViewMode} className={classes.closeIcon} />
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent classes={{ root: classes.dialogContent }}>
                    <Grid container>
                        <Grid xs={12}>
                            <div className={classes.viewModeContMain}>
                                {renderItems.map((item) => (
                                    <div className={classes.viewModeColCont}>
                                        <Grid container>
                                            <Grid item xs={12} className={classes.columnTitle1}>
                                                <Typography
                                                    className={classes.fontSize12}
                                                >
                                                    {item === 'Org' ? `${item} (${orgData.name})`
                                                        : item === 'User' ? `${item} (${userData.type})` : item}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} className={classes.columnTitle2}>
                                                <Typography variant="h6" className={classes.fontSize12}>
                                                    {dataMatrixComponent ? dataMatrixComponent.props.title
                                                        : selectedTimeline?.columns[requiredColumnIndex].props.title}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Accordion
                                                    defaultExpanded
                                                    style={{ margin: 0 }}
                                                >
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                        className={classes.sectionHeader}
                                                        color="primary"
                                                        classes={{
                                                            expandIcon: classes.iconButton,
                                                        }}
                                                    >
                                                        <Grid item style={{ width: '100%' }}>
                                                            <Typography className={classes.columHeadType} color="inherit">
                                                                {isProgressionDM ? 'Progression Section' : 'Protocol Section'}
                                                            </Typography>
                                                        </Grid>
                                                    </AccordionSummary>
                                                    <AccordionDetails style={{ display: 'block', padding: '0px' }}>
                                                        <Container props={{
                                                            components: isProgressionDM ? (dataMatrixComponent
                                                                ? dataMatrixComponent.props.progressions
                                                                : selectedTimeline?.columns[requiredColumnIndex].props.progressions)
                                                                : (dataMatrixComponent ? dataMatrixComponent.props.components
                                                                    : selectedTimeline?.columns[requiredColumnIndex].props.components),
                                                            isProgression: isProgressionDM,
                                                            dmColumViewType: item,
                                                            selectedDropdownValue,
                                                        }}
                                                        />
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Grid>
                                        </Grid>
                                    </div>
                                ))}
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default DataMatrixViewModeModal;
