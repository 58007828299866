/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import Timeline from '../Timeline';

const useStyles = makeStyles(() => ({
    mainCont: {
        width: '100%',
    },
}));

const TimelinePreviewDialog = (props) => {
    const {
        selectedDoctor,
        selectedColHandler,
        selectedTC,
        selectedPsa,
        tcAccessData,
        setTimelineContainerLoader,
        timelineData,
        selectedDoctorDetails,
        selectedPatientDetails,
    } = props;
    const classes = useStyles();
    const cloneTimeline = JSON.parse(JSON.stringify(timelineData));
    const [state, setState] = useState(cloneTimeline);

    useEffect(() => {
        if (cloneTimeline.columns.length > 0) {
            const updateState = { ...state };
            updateState.columns.forEach((col, i) => {
                col.props.components = timelineData.columns[i].props.components;
                col.props.progressions = timelineData.columns[i].props.progressions;
            });
            setState(updateState);
        }
    }, [selectedTC.length]);

    return (
        <>
            <div className={classes.mainCont}>
                <Timeline
                    {...state}
                    selectedDoctor={selectedDoctor}
                    selectedColHandler={selectedColHandler}
                    selectedPsa={selectedPsa}
                    tcAccessData={tcAccessData}
                    setTimelineContainerLoader={setTimelineContainerLoader}
                    isPreview
                    selectedDoctorDetails={selectedDoctorDetails}
                    selectedPatientDetails={selectedPatientDetails}
                    selectedTC={selectedTC}
                />
            </div>
        </>
    );
};

export default TimelinePreviewDialog;
