import { Alert } from '@material-ui/lab';
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Typography,
} from '@material-ui/core';
import {
    GET_COMPONENT_AC, INSERT_COMPONENTS_ACCESS, UPDATE_USER_TYPE,
} from '../../context/Timeline';
import { TimelineContext } from '../../context';
import { showLoader } from '../../App';
import { useMutation } from '@apollo/react-hooks';
import { useStyles } from '../../Workshop';
import AccessControl from '../AccessControl';
import CloseIcon from '@material-ui/icons/Close';
import React, { useContext, useEffect, useState } from 'react';

const TimelineUserViewDialog = ({
    open, onClose, components,
}) => {
    const {
        workshopAccessView,
        setWorkshopAccessView,
        workshopAccessData,
        selectedTimeline,
        selectedColumn,
        isAccessControl,
        setAccessControl,

    } = useContext(TimelineContext);
    const [isLoading, setIsLoading] = useState(false);
    const [settingAccCntrlUser, setSettingAccCntrlUser] = useState({});
    const nothingSelected = Object.keys(workshopAccessView).length > 0
        && Object.keys(workshopAccessView).every((child) => !workshopAccessView[child]);
    const classes = useStyles();
    // eslint-disable-next-line no-unused-vars
    const [_, setComponentsCopy] = useState();
    const [updateUserType] = useMutation(UPDATE_USER_TYPE);
    const [insertComponentsAccess] = useMutation(INSERT_COMPONENTS_ACCESS);

    /**
     * @param {string} protocolId
     * @param {string} userType
     * updated user type by calling mutation function asynchronously
     */
    const updateUserTypeForProtocolId = async (protocolId, userType) => {
        try {
            const timelineId = selectedTimeline.id;
            if (!workshopAccessData?.[`${protocolId}`]) {
                // insert component access control and user type data to db
                const columnId = selectedTimeline.columns[selectedColumn]?.id;
                const defaultAccessData = { isBasic: false, isAdvanced: true, isUserView: true };
                await insertComponentsAccess({
                    variables: {
                        timelineId, columnId, protocolId, accessData: defaultAccessData, userType,
                    },
                    refetchQueries: [{ query: GET_COMPONENT_AC, variables: { timeline_id: timelineId } }],
                });
            } else {
                // update user type
                await updateUserType({
                    variables: { protocolId, userType },
                    refetchQueries: [{ query: GET_COMPONENT_AC, variables: { timeline_id: timelineId } }],
                });
            }
        } catch (error) {
            console.log(error.message);
        }
    };

    useEffect(() => {
        setWorkshopAccessView(workshopAccessData);
    }, [workshopAccessData]);

    const handleClose = ({ cancel, save }) => {
        if (nothingSelected && !cancel) {
            return;
        }
        if (save) {
            setIsLoading(true);
            // Update user types for each protocolId when component mounts
            // eslint-disable-next-line no-unused-vars
            for (const protocolId in settingAccCntrlUser) {
                if (Object.hasOwnProperty.call(settingAccCntrlUser, protocolId)) {
                    const userType = settingAccCntrlUser[protocolId];
                    updateUserTypeForProtocolId(protocolId, userType);
                }
            }
            setIsLoading(false);
        }
        onClose(cancel ? { cancel } : workshopAccessView);
    };

    /**
    * Handle Access Control popup isAccessControlOpen
    * @param {boolean} status [if modal  open status is `true` otherwise `false`]
    */
    const handleModalStatus = () => {
        setAccessControl(!isAccessControl);
    };

    useEffect(() => {
        setComponentsCopy(JSON.parse(JSON.stringify(components)));
    }, [components]);

    useEffect(() => {
        if (Object.keys(workshopAccessData).length > 0) {
            setIsLoading(true);
            const userAccObj = {};
            // eslint-disable-next-line no-unused-vars
            for (const [key, value] of Object.entries(workshopAccessData)) {
                userAccObj[key] = value.user_type;
            }
            setSettingAccCntrlUser(userAccObj);
            setIsLoading(false);
        }
    }, [workshopAccessData]);

    const toAdmin = {
        userType: 'Admin',
        state: settingAccCntrlUser,
        setter: setSettingAccCntrlUser,
    };
    const toStaff = {
        userType: 'Staff',
        state: settingAccCntrlUser,
        setter: setSettingAccCntrlUser,
    };
    const toPatient = {
        userType: 'Patient',
        state: settingAccCntrlUser,
        setter: setSettingAccCntrlUser,
    };

    return (
        <Dialog open={open} classes={{ paperWidthSm: classes.timelineUserViewDialog }} onClose={handleClose}>
            {showLoader(isLoading)}
            <>
                <DialogTitle className={classes.poupConHeading}>
                    <Grid container>
                        <Grid item xs={10}>
                            <Typography variant="h6">Timeline User Access</Typography>
                        </Grid>
                        <Grid item xs={2} className={classes.rightTextAlign}>
                            <CloseIcon className={classes.closeIconStyle} onClick={() => handleClose({ cancel: true })} />
                        </Grid>
                    </Grid>
                </DialogTitle>
                <Divider />
                <DialogContent className={classes.poupConHeadingMeddle}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} className={classes.researchTextCont1}>
                            <Typography variant="h4">{selectedTimeline?.columns?.[selectedColumn]?.props?.title}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="h4" className={classes.headingTextCenter}>Admin</Typography>
                                </Grid>
                            </Grid>
                            <AccessControl
                                handleClose={handleModalStatus}
                                column={selectedTimeline?.columns?.[selectedColumn]}
                                isSettings
                                dialougObj={toAdmin}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="h4" className={classes.headingTextCenter}>Staff</Typography>
                                </Grid>
                            </Grid>
                            <AccessControl
                                handleClose={handleModalStatus}
                                column={selectedTimeline?.columns?.[selectedColumn]}
                                isSettings
                                dialougObj={toStaff}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="h4" className={classes.headingTextCenter}>Patient</Typography>
                                </Grid>
                            </Grid>
                            <AccessControl
                                handleClose={handleModalStatus}
                                column={selectedTimeline?.columns?.[selectedColumn]}
                                isSettings
                                dialougObj={toPatient}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <Divider />
                <DialogActions className={classes.poupConBoottom}>
                    <Button variant="contained" onClick={() => handleClose({ cancel: true })}>Cancel</Button>
                    <Button onClick={() => handleClose({ save: true })} className={classes.saveUpButton}>Save</Button>
                    {nothingSelected && (
                        <Alert severity="error">
                            Please select at least one User Access!
                        </Alert>
                    )}
                </DialogActions>
            </>
        </Dialog>
    );
};

export default TimelineUserViewDialog;
