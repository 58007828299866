/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import {
    Button, Grid, Tooltip, Typography,
} from '@material-ui/core';
import { Container, ContainerPreview } from './Container';
import { OrgContext, TimelineContext, UserContext } from '../context';
import { ProcedureDialog } from './ProcedureDialog';
import { ProcedureLibrary } from '../initialConfig';
import {
    deepCopy, emailValidator,
} from '../helper/commonHelper';
import { flattenSelected } from './ProcedureSelector';
import { makeStyles } from '@material-ui/core/styles';
import { standardHeaders } from './utils/StandardStyles';
import { toast } from 'react-toastify';
import { useLazyQuery } from 'react-apollo';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import POIList from '../POIList';
import React, { useContext, useEffect, useState } from 'react';
import gql from 'graphql-tag';

export const CHECK_USER_EXISTENCE = gql`
    query user($email: String) {
        user(where: { is_active: { _eq: true }, email: { _eq: $email } }) {
            id,
            name,
            last_name
        },
    }
`;

const useRowStyles = makeStyles((theme) => ({
    column: {
        // border: '1px solid #ececec',
    },
    timelineColumn: {
        marginRight: 2,
        height: 'max-content',
    },
    videoPopUpColumn: {
        marginRight: 0,
    },
    timelineColumnSelected: {
        boxShadow: '0 0 15px CornflowerBlue',
    },
    workshopColumn: {
        marginRight: 4,
        border: '1px solid #ececec',
    },
    poiText: {
        padding: 5,
        paddingLeft: 5,
        border: '.5px solid #ececec',
        borderRight: 'none',
        fontSize: 10,
        color: '#1C1C1C',
        maxHeight: '36px',
        lineHeight: '24px',
        overflow: 'hidden',
        overflowY: 'auto',
        '&::-webkit-scrollbar': { display: 'none' },
        '&:hover': {
            '&::-webkit-scrollbar': { height: '2.5px', width: '2.5px', display: 'block' },
            '&::-webkit-scrollbar-thumb': { background: '#888' },
        },
    },
    sectionHeader: {
        margin: 0,
        minHeight: 'auto !important',
        boxShadow: 'none',
        height: 32,
        borderTop: '1px solid #ececec',
        borderBottom: '1px solid #ececec',
        ...standardHeaders.sectionHeader,
        position: 'relative',
    },
    borderTopStyle: {
        borderTop: '1px solid #ececec',
    },
    rootAcc: {
        margin: '0px !important',
    },
    columHeadType: {
        fontSize: '0.75rem',
        width: '100%',
    },
    columnType2: {
        fontSize: '11px',
        border: '.5px solid #ececec',
        borderBottom: 'none',
        borderLeft: 'none',
        height: '36px',
        borderRightStyle: 'none',
        justifyContent: 'start',
        alignItems: 'center',
        display: 'flex',
        cursor: 'pointer',
        color: '#2b6682',
        transition: '.5s',
        '&:hover': {
            color: '#000',
        },
    },
    profileAccCont: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        '& h1': {
            marginLeft: '12%',
        },
        '& button': {
            padding: '0px 16px',
            fontSize: 14,
        },
    },
    protocalAccCont: {
        width: '100%',
    },
    textPOICont: {
        borderBottom: '.5px solid #ececec',
    },
    iconButton: {
        color: '#fff',
        position: 'absolute',
        left: '0px',
    },
    fixedTop: {
        position: 'sticky',
        zIndex: 1,
        background: '#FFFFFF',
        borderTop: '.25px solid #ececec',
        borderLeft: '1px solid #ececec',
        borderRight: '1px solid #ececec',
        [theme.breakpoints.down('xs')]: {
            top: '56px !important',
        },
    },
    accessControlGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(4,minmax(auto ,1fr))',
        gap: '2px 2px',
        marginTop: 2,
    },
    accessControlItem: {
        paddingLeft: 4,
    },
    notWSTitleCont: {
        padding: 0,
    },
    progressionSectionTextCont: {
        width: '100%',
    },
    containerWithComp: {
        display: 'block',
        padding: '0px',
    },
    adminSettingColumn: {
        margin: '1px 0px',
    },
    selectedColCls: {
        backgroundColor: 'cornflowerBlue',
    },
    selectedColClsHover: {
        // '&:hover': {
        //     cursor: 'pointer',
        //     backgroundColor: '#aac3ef',
        // },
    },
    detailContLeft: {
        textAlign: 'left',
        borderRight: '1px solid #cbcbcb',
        borderBottom: '1px solid #cbcbcb',
        padding: 8,
        fontSize: 12,
    },
    detailContRight: {
        textAlign: 'left',
        borderBottom: '1px solid #cbcbcb',
        padding: 6,
        fontSize: 14,
    },
    detailContRightText: {
        borderBottom: '1px solid #cbcbcb',
        padding: '0px 8px',
        fontSize: 12,
        '& input': {
            height: 18,
        },
    },
    mainAccordion: {
        margin: '0px !important',
    },
    removeShadow: {
        boxShadow: 'none',
    },
    progressionMobileMargn: {
        [theme.breakpoints.down('xs')]: {
            marginTop: '20px !important',
        },
    },
    primaryBtn: {
        maxHeight: '56px',
        padding: '.25rem 1rem',
        boxShadow: '0px 0px 20px -4px rgba(51,36,5,0.37)',
        '&:hover': {
            boxShadow: 'none',
        },
    },
    showError: {
        color: '#DC143C',
    },
    buttonCont: {
        textAlign: 'end',
        padding: '8px 16px',
        borderBottom: '1.5px solid #544f4f',
    },
    transparentBG: {
        background: 'transparent',
    },
    templatePrevWrapper: {
        width: '110%',
    },
    timelineColProfileWrap: {
        borderBottom: '.25px solid #ececec',
    },
    timelineColTempPrevWrap: {
        border: '.25px solid #cbcbcb',
        width: '105% !important',
    },
    highlighColumnBCls: {
        outline: '2px solid cornflowerblue',
        boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
        '& > :first-child': {
            boxShadow: '0 2px 0 cornflowerblue inset',
            border: 'none',
            borderTop: '.25px solid #ececec',
        },
    },
    highlighColumnCls: {
        backgroundColor: 'cornflowerblue',
    },
    highlighTitileCls: {
        color: 'cornflowerblue',
    },
    highlighTitileCls1: {
        color: 'inherit',
    },
    leftSvgIconStylMobile: {
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            padding: '5px',
        },
        '& p': {
            [theme.breakpoints.down('xs')]: {
                width: '92%',
                textAlign: 'center',
            },
        },
        '& svg': {
            display: 'none',
            [theme.breakpoints.down('xs')]: {
                verticalAlign: 'text-top',
                cursor: 'pointer',
                display: 'block',
            },
        },
    },
}));

const TimelineColumn = ({
    timelineId, columnIndex, columns, column,
    isWorkshop, isAdminSetting = false,
    timelineViewTab = null,
    selectedDoctor,
    selectedColHandler = null,
    selectedTC, setSelectedTC, selectedPsa, isPreview = false,
    isTempPrev = false, setTimelineContainerLoader = null, isVideoPopUp = false,
}) => {
    const classes = useRowStyles();
    const [openPOI, setOpenPOI] = useState(false);
    const appBarHeight = document.getElementById('appBar')?.clientHeight;
    const component = column;
    const { props } = component;
    const {
        title, progressionSectionLabel,
        progressions, protocolSectionLabel, components, general, hide, isProfile,
    } = props;
    const {
        handleContextMenu,
        selectedPOI,
        showHiddenComponents,
        styleComponent,
        updatePOI,
        selectedTimeline,
        templateLibraryByType,
        currentOrg,
        inserPatientUTAssociation,
        isProgressionExpanded,
        setProgressionExpansion,
        inWorkshop,
        contextMenuTarget,
        isDMRC,
        workshopComponent,
        selectedComponent,
        selectedColumn,
        getLocationOfTargetComponent,
        hierarchyComponent,
        createTimeline,
        workshopActiveComponent,
        editProgressionComp,
        handleColumnClick,
        columnWidthData,
        setColumnWidthData,
        setTimelineShareMode,
        patientProfileData,
        setPatientProfileData,
        setCDComponent,
        globalAssociationState,
        isMobile,
    } = useContext(TimelineContext);
    const { loggedUserType } = useContext(OrgContext);

    const progressionDefault = contextMenuTarget?.component?.isProgression || contextMenuTarget?.component?.clickedArea === 'Progression';
    const [isProtocolExpanded, setIsProtocolExpanded] = useState((!!component?.props?.isProtocolExpand));
    const isSharedTimeline = (selectedTimeline?.category === 'Super' || selectedTimeline?.category === 'Shared'
        || loggedUserType === 'User' || loggedUserType === 'Patient' || loggedUserType === 'Admin');
    const timelineHeaderHeight = document.getElementById('timelineHeader')?.clientHeigh || (isSharedTimeline ? 41 : 66);
    const marginSet = isSharedTimeline ? '2.45rem' : '4.1rem';

    let isTransferButton = false;
    if (isProfile && patientProfileData?.profile?.firstName && patientProfileData?.profile?.email
        && loggedUserType === 'Doctor' && !selectedPsa) {
        isTransferButton = true;
    }

    const { addNewUser } = useContext(UserContext);

    if (column && !props.isProfile && !Object.prototype.hasOwnProperty.call(props, 'showProgressionSection')) {
        props.showProgressionSection = true;
    }

    const createUserTimelineAssociation = (userId, patientName) => {
        if (!userId) return null;
        const updateState = { ...patientProfileData };
        updateState.profile.id = userId;
        const cloneTimeline = deepCopy(selectedTimeline);
        const { name } = cloneTimeline;
        const timelineTitle = `${patientName} - ${name}`;
        cloneTimeline.name = timelineTitle;
        cloneTimeline.props.name = timelineTitle;
        cloneTimeline.columns.forEach((col) => {
            if (!col?.props?.isProfile) {
                if (selectedTC.findIndex((tc) => tc === col.id) === -1) {
                    col.props.components = [];
                    col.props.progressions = [];
                }
            }
        });
        setTimelineContainerLoader(true);
        setPatientProfileData(updateState);
        createTimeline({
            title: timelineTitle,
            timelineTemplate: cloneTimeline,
            category: loggedUserType === 'Doctor' ? 'Shared With Patient' : 'Shared',
            userId: updateState.profile.id,
        }).then((timeline) => {
            const userObj = {
                userId: updateState.profile.id,
                orgId: currentOrg.id,
                timeline_id: timeline.id,
                parentTimelineId: selectedTimeline.id,
            };
            inserPatientUTAssociation({ variables: userObj });
            setTimelineContainerLoader(false);
            toast.success('Timeline shared successfully');
            setSelectedTC([]);
        }).catch(() => {
            toast.error('Something went wrong');
            setTimelineContainerLoader(false);
            setSelectedTC([]);
        });

        return null;
    };

    const createNewUser = async () => {
        const updateState = { ...patientProfileData };
        updateState.profile.userRole = 'Patient';
        updateState.profile.orgID = currentOrg.id;
        updateState.profile.last_name = '';
        updateState.profile.phone_no = '';
        try {
            const response = await addNewUser(updateState.profile);
            const patientName = updateState.profile.firstName;
            createUserTimelineAssociation(response.data.signup.id, patientName);
        } catch (error) {
            toast.error('Something went wrong');
        }
    };

    const [checkUserExistence] = useLazyQuery(CHECK_USER_EXISTENCE, {
        onCompleted: (data) => {
            if (data.user.length > 0) {
                const patientName = `${data.user[0].name} ${data.user[0].last_name}`;
                createUserTimelineAssociation(data.user[0].id, patientName);
            } else {
                createNewUser();
            }
        },
    });

    useEffect(() => {
        if (loggedUserType === 'Doctor' && ((patientProfileData?.profile?.firstName && patientProfileData?.profile?.email)
            || selectedPsa) && setSelectedTC) {
            const updateTC = [];
            for (let i = 1, l = selectedTimeline.columns.length; i < l; i += 1) {
                if (selectedTimeline?.columns?.[i]?.props?.userAccess?.readPatient) {
                    updateTC.push(selectedTimeline.columns[i].id);
                }
            }
            setColumnWidthData(null);
            setTimelineShareMode({ id: 'patient' });
        } else if (loggedUserType === 'Doctor' && ((!patientProfileData?.profile?.firstName || !patientProfileData?.profile?.email)
            || !selectedPsa) && setSelectedTC) {
            setTimelineShareMode(null);
        }
    }, [patientProfileData?.profile?.firstName, patientProfileData?.profile?.email]);

    useEffect(() => {
        if (loggedUserType === 'Doctor' && selectedPsa && setSelectedTC) {
            const columnsExceptProfile = [];
            for (let i = 1, l = selectedTimeline.columns.length; i < l; i += 1) {
                if (!selectedTimeline?.columns?.[i]?.props.isProfile) {
                    columnsExceptProfile.push(selectedTimeline.columns[i].id);
                }
            }
            setSelectedTC([...columnsExceptProfile]);
            setColumnWidthData(null);
            setCDComponent(null);
        }
    }, [selectedPsa]);

    useEffect(() => {
        if (isWorkshop) setProgressionExpansion(progressionDefault);
    }, [progressionDefault]);

    useEffect(() => {
        const previewEnabled = (selectedDoctor || selectedPsa);
        setIsProtocolExpanded(!previewEnabled);
    }, [selectedDoctor, selectedPsa, isPreview]);

    let { showProgression, showProtocol } = selectedTimeline?.props
        ? selectedTimeline?.props : { showProgression: undefined, showProtocol: undefined };
    const category = selectedTimeline?.category || null;
    showProgression = column?.props?.showProgression;

    if (showProgression === undefined) {
        showProgression = true;
    }
    if (showProtocol === undefined) {
        showProtocol = true;
    }

    /**
     * @function onHandlePOIChange [update the state]
     * @param {object} poi
     */
    const onHandlePOIChange = (poi) => {
        updatePOI(poi);
    };

    /**
     * Set the selected POI from context
     */
    if (selectedPOI) {
        flattenSelected(ProcedureLibrary, selectedPOI);
    }

    if (!general) {
        props.general = [];
    }

    const click = (e) => {
        if (loggedUserType === 'Doctor' && category === 'Shared') return;
        handleContextMenu(e, column, { id: timelineId, type: 'Timeline', props: { components: columns } }, columnIndex);
    };

    const protocolSectionClick = (e) => {
        if (loggedUserType === 'Doctor' && category === 'Shared') return;
        handleContextMenu(e, { type: 'ColumnSection', clickedArea: 'Protocol', props: { components } }, column, columnIndex, column);
    };

    const progressionClick = (e) => {
        if (loggedUserType === 'Doctor' && category === 'Shared') return;
        handleContextMenu(e, { type: 'ColumnSection', clickedArea: 'Progression', props: { components: progressions } }, column, columnIndex, column);
    };

    const size = props.size === 'auto' ? true : parseInt(props.size, 10);

    if (hide === true && showHiddenComponents === false) {
        return (<></>);
    }

    const handleChangeProgression = (_event, isExpanded) => {
        setProgressionExpansion(isExpanded);
    };

    const handleChangeProtocol = (_event, isExpanded) => {
        if (!selectedDoctor && !selectedPsa) column.props.isProtocolExpand = isExpanded;
        setIsProtocolExpanded(isExpanded);
    };

    // eslint-disable-next-line no-nested-ternary
    const customClass = inWorkshop ? progressionDefault
        ? classes.timelineColumn : isAdminSetting
            ? classes.adminSettingColumn : classes.workshopColumn : classes.timelineColumn;
    const allSelected = Object.keys(selectedPOI).every((child) => selectedPOI[child]);

    const renderCompHierarchy = () => {
        let compList = [];
        const isProgression = contextMenuTarget?.component?.isProgression || contextMenuTarget?.component?.clickedArea === 'Progression' || false;
        if (!isProgression && hierarchyComponent?.id && hierarchyComponent?.type !== 'Column') {
            const routeOfTheSelectedComp = getLocationOfTargetComponent(hierarchyComponent, selectedColumn, false, null, isProgression);
            let data = isProgression ? progressions : components;
            let locationMap = [];
            routeOfTheSelectedComp.forEach((val, i) => {
                if (i === routeOfTheSelectedComp.length - 1) {
                    locationMap = [...locationMap, ...data];
                } else {
                    locationMap.push(data[val]);
                    data = data[val].props.components;
                }
            });
            compList = [...locationMap];
        }

        if (isProgression && hierarchyComponent?.id && hierarchyComponent?.type !== 'Column') {
            const routeOfTheSelectedComp = getLocationOfTargetComponent(hierarchyComponent, selectedColumn, false, null, isProgression);
            let data = progressions;
            let locationMap = [];
            routeOfTheSelectedComp.forEach((val, i) => {
                if (i === routeOfTheSelectedComp.length - 1) {
                    locationMap = [...locationMap, ...data];
                } else {
                    locationMap.push(data[val]);
                    data = data[val]?.props?.components;
                }
            });

            compList = [...locationMap];
        }
        return compList;
    };

    const renderTCWorkshop = () => {
        switch (timelineViewTab) {
        case 'Comp Only':
            return [workshopComponent];
        case 'TL': {
            const templateLibrary = templateLibraryByType[workshopComponent?.type];
            const template = templateLibrary && templateLibrary.find((temp) => temp.id === workshopComponent?.templateId);
            return (template ? [template.template] : [{}]);
        }
        default:
            return renderCompHierarchy();
        }
    };

    const handleColumnWidth = (e, comp) => {
        e.stopPropagation();
        if (selectedDoctor || selectedPsa) {
            setColumnWidthData(null);
        } else {
            handleColumnClick(e, comp);
        }
    };

    const columnSelectHandler = (e, col) => {
        e.stopPropagation();
        if (isPreview) return;
        if (selectedDoctor || (loggedUserType === 'Doctor' && (patientProfileData?.profile?.firstName && patientProfileData?.profile?.email)
            || selectedPsa)) {
            selectedColHandler(col);
        }
        handleColumnWidth(e, component);
    };

    const handleClickOpen = () => {
        setOpenPOI(!openPOI);
    };

    const checkProfileError = () => {
        let errorFlag = false;
        if (emailValidator(patientProfileData?.profile?.email)) {
            errorFlag = true;
            return errorFlag;
        }
        return errorFlag;
    };

    const profileSubmitHandler = (e) => {
        e.stopPropagation();
        const updateState = { ...patientProfileData };
        if (checkProfileError()) {
            updateState.profile.userRole = 'Patient';
            checkUserExistence({
                variables: {
                    email: updateState?.profile?.email,
                },
            });
        } else {
            setPatientProfileData({ ...updateState });
        }
    };

    if (!isProfile && !column.props.showProgression) return false;

    const isTemplateColSelected = workshopActiveComponent?.type === 'Column'
        && selectedComponent?.type === 'Column' && !selectedTimeline && !workshopComponent;

    const showProgressionSectionAcc = showProgression && !isProfile && component.props.showProgressionSection
        && ((editProgressionComp && inWorkshop) || !inWorkshop);

    const showProtocolSectionAcc = showProtocol && !isProfile && (!inWorkshop || (inWorkshop && !editProgressionComp));

    const isHighlightColumn = columnWidthData && (columnWidthData.id === component.id);
    const isSelectedDocOrPt = selectedDoctor || selectedPsa;

    const contextMenuCondition = (!inWorkshop && !isProfile && (category !== 'Super') && !(category === 'Shared' && loggedUserType === 'Doctor'));

    return (
        <>
            <Grid
                key={column.id}
                item
                xs={size}
                className={[customClass, isTempPrev && classes.templatePrevWrapper, isVideoPopUp && classes.videoPopUpColumn, (selectedTC?.length > 0
                    && selectedTC.find((tc) => tc === column.id) && classes.timelineColumnSelected),
                ].join(' , ')}
                onClick={(e) => handleColumnWidth(e, component)}
            >
                <div
                    style={styleComponent({ component: column })}
                    className={[isTemplateColSelected && classes.transparentBG,
                        columnWidthData && (columnWidthData.id === column.id)
                    && !workshopComponent && !isVideoPopUp && classes.highlighColumnBCls].join(' , ')}
                >
                    <div
                        className={[(!inWorkshop && !isTempPrev) && classes.fixedTop,
                            isProfile && classes.timelineColProfileWrap, isTempPrev && classes.timelineColTempPrevWrap, (
                            selectedTC?.length > 0 && selectedTC.find((tc) => tc === column.id)
                            && classes.selectedColCls)
                        || ((selectedDoctor || selectedPsa)
                            && (!isProfile && classes.selectedColClsHover)),
                        ].join(' , ')}
                        style={{
                            top: isDMRC && !inWorkshop ? '0px' : isVideoPopUp ? '0px' : !inWorkshop ? appBarHeight + timelineHeaderHeight : 'none',
                            background: isTemplateColSelected && 'transparent',
                            width: (isVideoPopUp ? '100%' : isHighlightColumn && !workshopComponent ? columnWidthData?.highlightColWidth : 'auto'),
                        }}
                        onClick={!isProfile ? (e) => columnSelectHandler(e, column) : null}
                    >
                        <Grid
                            container
                            justify="center"
                            onContextMenu={contextMenuCondition ? click : undefined}
                            alignItems="center"
                            direction="column"
                            style={{ marginTop: !showProtocol && !showProgression ? '98px' : 0 }}
                        >
                            <div
                                className={classes.leftSvgIconStylMobile}
                            >
                                <Typography
                                    className={[classes.notWSTitleCont,
                                        isHighlightColumn ? classes.highlighTitileCls : classes.highlighTitileCls1].join(' , ')}
                                >
                                    {!isWorkshop && title}
                                </Typography>
                            </div>
                            {!(inWorkshop && progressionDefault) && (
                                <Grid item xs={8} style={{ visibility: isProfile ? 'hidden' : 'visible' }}>
                                    <ProcedureDialog
                                        isFilter
                                        initialSelectAll
                                        isWorkshop
                                        onSelectionMade={onHandlePOIChange}
                                        selectedPOI={selectedPOI}
                                        explicitOpen={openPOI}
                                        handleClickOpenPOI={handleClickOpen}
                                    />
                                </Grid>
                            )}
                        </Grid>

                        {!(inWorkshop && progressionDefault) && (
                            <Grid item xs={12}>
                                <Grid container style={{ visibility: isProfile ? 'hidden' : 'visible' }}>
                                    <Grid item xs={3} className={classes.textPOICont}>
                                        <Tooltip title="Procedure Of Interest" placement="top">
                                            <Typography
                                                className={classes.columnType2}
                                                style={{
                                                    padding: inWorkshop ? '8px 3px' : isDMRC ? '8px 1px' : '0px 2px',
                                                }}
                                                onClick={handleClickOpen}
                                            >
                                                Select POI
                                            </Typography>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={9} className={classes.poiText}>
                                        {(Object.keys(selectedPOI).length === 0 || allSelected) && <div>All Procedure Selected</div>}
                                        {
                                            selectedPOI && !allSelected && (
                                                <POIList
                                                    initialSelectAll
                                                    onHandlePOIChange={onHandlePOIChange}
                                                    selectedPOI={selectedPOI}
                                                    updatePOI={updatePOI}
                                                    library={ProcedureLibrary}
                                                />
                                            )
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </div>
                    {showProgressionSectionAcc && !isProfile ? (
                        <Accordion
                            onChange={handleChangeProgression}
                            expanded={isProgressionExpanded}
                            className={`${classes.removeShadow} ${classes.progressionMobileMargn}`}
                            style={{ marginTop: isVideoPopUp ? 0 : !inWorkshop && !isPreview && !isTempPrev ? marginSet : 0 }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className={classes.sectionHeader}
                                classes={{
                                    expandIcon: classes.iconButton,
                                }}
                            >
                                <Grid
                                    item
                                    onContextMenu={(!inWorkshop
                                        && !(category === 'Super'
                                            || (loggedUserType === 'Doctor' && category === 'Shared') || loggedUserType === 'Admin'
                                            || loggedUserType === 'User' || loggedUserType === 'Patient'))
                                        ? progressionClick : undefined}
                                    className={classes.progressionSectionTextCont}
                                >
                                    <Typography className={classes.columHeadType} variant="h1" color="inherit">
                                        {progressionSectionLabel
                                            && progressionSectionLabel.trim().length > 0 ? progressionSectionLabel : 'Progression Section'}
                                    </Typography>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails className={classes.containerWithComp}>
                                {isProgressionExpanded ? (
                                    <Grid item className={classes.column}>
                                        {isAdminSetting ? (
                                            <ContainerPreview props={{
                                                components: [], isProgression: true, isTimlineCol: true, isAdminSetting,
                                            }}
                                            />
                                        )
                                            : (((editProgressionComp && inWorkshop) || !inWorkshop) && (
                                                <Container props={isWorkshop ? {
                                                    components: renderTCWorkshop(), isProgression: true,
                                                } : {
                                                    components: workshopComponent?.id === column.id && !isWorkshop ? [] : progressions,
                                                    isProgression: true,
                                                    columnId: column.id,
                                                    isTimlineCol: true,
                                                    isAdminSetting,
                                                }}
                                                />
                                            ))}
                                    </Grid>
                                ) : null}
                            </AccordionDetails>
                        </Accordion>
                    ) : null}

                    {(showProtocolSectionAcc || isProfile) && (
                        <Accordion
                            onChange={handleChangeProtocol}
                            expanded={isProtocolExpanded}
                            className={[classes.removeShadow, workshopComponent && classes.mainAccordion].join(' , ')}
                            style={{
                                marginTop: ((isProfile && selectedTimeline?.category === 'Super') || (loggedUserType === 'Patient' && isProfile))
                                    ? '40px' : (isSelectedDocOrPt && isProfile && isPreview)
                                        ? 0 : isProfile ? '65px' : component.props.showProgressionSection ? 0 : marginSet,
                                top: isMobile && isProfile ? '-40px' : '0px',
                            }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className={classes.sectionHeader}
                                color="primary"
                                classes={{
                                    expandIcon: classes.iconButton,
                                }}
                            >
                                {isTransferButton
                                    ? (
                                        <Grid
                                            item
                                            className={classes.profileAccCont}
                                            onContextMenu={(!inWorkshop
                                                && !(category === 'Super' || loggedUserType === 'Admin'
                                                    || loggedUserType === 'User' || loggedUserType === 'Patient'))
                                                ? protocolSectionClick : undefined}
                                        >
                                            <Typography
                                                className={classes.columHeadType}
                                                variant="h1"
                                                color="inherit"
                                            >
                                                Profile Summary
                                            </Typography>
                                            <Button variant="contained" color="primary" onClick={profileSubmitHandler}>Transfer</Button>
                                        </Grid>
                                    )
                                    : (
                                        <Grid
                                            item
                                            className={classes.protocalAccCont}
                                            onContextMenu={(!inWorkshop
                                                && !(category === 'Super' || loggedUserType === 'Admin'
                                                    || loggedUserType === 'User' || loggedUserType === 'Patient'))
                                                ? protocolSectionClick : undefined}
                                        >
                                            <Typography className={classes.columHeadType} variant="h1" color="inherit">
                                                {isProfile ? 'Profile Summary' : (
                                                    protocolSectionLabel && protocolSectionLabel
                                                        .trim().length > 0 ? protocolSectionLabel : 'Protocol Section'
                                                )}
                                            </Typography>
                                        </Grid>
                                    )}
                            </AccordionSummary>
                            <AccordionDetails style={{ display: 'block', padding: '0px' }}>
                                {isAdminSetting
                                    ? (
                                        isProtocolExpanded && (
                                            <ContainerPreview props={{
                                                components: [], isProgression: false, isTimlineCol: true, isAdminSetting,
                                            }}
                                            />
                                        )
                                    )
                                    : (
                                        ((isProtocolExpanded && !inWorkshop) || (!editProgressionComp && inWorkshop && isProtocolExpanded)) && (
                                            <Container
                                                key={workshopComponent?.props?.isUniversal && workshopComponent?.props?.leftRightColumn
                                                    && workshopComponent?.props?.pcRelationship === 'manual' ? globalAssociationState : true}
                                                props={isWorkshop ? {
                                                    components: renderTCWorkshop(), isProgression: false,
                                                } : {
                                                    components: workshopComponent?.id === column.id && !isWorkshop ? [] : components,
                                                    isProgression: false,
                                                    columnId: column.id,
                                                    isTimlineCol: true,
                                                    id: column.id,
                                                    isAdminSetting,
                                                }}
                                            />
                                        )
                                    )}
                            </AccordionDetails>
                        </Accordion>
                    )}

                </div>
            </Grid>

        </>
    );
};

export default TimelineColumn;
