import {
    Box, Grid, Modal, Typography,
} from '@material-ui/core';
import { createPlayer, getFileType, isVideo } from '../helper/commonHelper';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import MediaPlaceholder from '../images/imageDeletedPlaceholder.png';
import React, { Fragment, useEffect, useState } from 'react';

/**
 * @function getModalStyle
 * @description set the margin
 * @param None
 * @returns {object}
 */
function getModalStyle() {
    const top = 45;
    const left = 45;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

/**
 * @function useStyles
 * @description css for modal popup
 * @returns {object}
*/
const useStyles = makeStyles((theme) => createStyles({
    paper: {
        position: 'absolute',
        maxWidth: '80%',
        maxHeight: '80%',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        overflow: 'auto',
    },
    cancelBtnAllign: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    headerAllignment: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '20px',
        fontWeight: '500',
    },
    imageAllignment: {
        maxHeight: '100%',
        maxWidth: '100%',
    },
}));

/**
 * @constructor MediaViewer
 * @description create the modal pop to view the selected media
 * @param {handleClose}
 * @param {open}
 * @param {viewMedia} | media data which have to render in larger view
*/
const MediaViewer = ({ handleClose, open, viewMedia }) => {
    const classes = useStyles();
    const [modalStyle] = useState(getModalStyle);
    const [mediaDetails, setMediaDetails] = useState({});

    /**
     * create the dynamic id to create the player
     */
    if (Object.keys(mediaDetails).length > 0 && isVideo(mediaDetails.name)) {
        mediaDetails.filetype = getFileType(mediaDetails.name);
        viewMedia.videoDivId = `video-div-gallery-media-viewer-New-${viewMedia.id}`;
        mediaDetails.isVideo = true;
    }

    useEffect(() => {
        setMediaDetails(viewMedia);
    }, [viewMedia]);

    useEffect(() => {
        createPlayer([mediaDetails], false, { useWidth: false });
    });

    /**
     * @function renderMedia [view media]
     * @returns {string} media
     */
    const renderMedia = () => {
        let showMedia = '';
        let displayTitle = '';
        let displayCaption = '';

        if (mediaDetails && mediaDetails.title) {
            displayTitle = `Title: ${mediaDetails.title}`;
        }

        if (mediaDetails && mediaDetails.caption) {
            displayCaption = `Caption: ${mediaDetails.caption}`;
        }

        if (!mediaDetails.isShow) {
            showMedia = <img id="mdiaMngrGrid" src={MediaPlaceholder} alt={mediaDetails.name} />;
        } else if (viewMedia.isVideo) {
            showMedia = (
                <Fragment>
                    <span>{displayTitle}</span>
                    <div className={`videoSize ${classes.imageAllignment}`} id={viewMedia.videoDivId} />
                    <span>{displayCaption}</span>
                </Fragment>
            );
        } else {
            showMedia = (
                <Fragment>
                    <Box my={1} ml={1}>
                        <Typography variant="h4">{displayTitle}</Typography>
                    </Box>
                    <img id="mdiaMngrGrid" className={classes.imageAllignment} src={mediaDetails.url} alt={mediaDetails.name} />
                    <Box my={1} ml={1}>
                        <Typography variant="subtitle1">{displayCaption}</Typography>
                    </Box>
                </Fragment>
            );
        }

        return showMedia;
    };

    return (
        <div>
            <Modal open={open} onClose={handleClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                <div style={modalStyle} className={classes.paper}>
                    <Grid container>
                        <Grid item md={12} lg={12}>
                            {renderMedia()}
                        </Grid>
                    </Grid>
                </div>
            </Modal>
        </div>
    );
};

export default MediaViewer;
