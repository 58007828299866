import { Container } from './Container';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import React, { } from 'react';

const useRowStyles = makeStyles(() => ({
    column: {
        border: '1px solid #ececec',
        padding: 10,
    },
    darkColumn: {
        padding: 0,
        backgroundColor: 'black',
        color: 'white',
    },
    greyColumn: {
        padding: 0,
        backgroundColor: '#878787',
    },
    lightGreyColumn1: {
        padding: 0,
        backgroundColor: '#ababab',
    },
    lightGreyColumn2: {
        padding: 0,
        backgroundColor: '#cecece',
    },
}));

/**
 * RowSymmetric is speclized row with 3 columns.
 * The second and third columns are exact mirrors of each other.
 * There should be exaclty two columns for this row
 * @param {*} param0
 */
const RowSymmetric = ({ props }) => {
    const columns = props.columns.slice(0);
    const classes = useRowStyles();

    if (columns.length === 0) {
        return <div />;
    }

    const secondCol = columns.pop();
    const firstCol = columns.pop();

    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={firstCol.config.size} className={classes.column}>
                        <Container props={{ components: firstCol.components }} />
                    </Grid>
                    <Grid item xs={secondCol.config.size} className={classes.column}>
                        <Container props={{ components: secondCol.components }} />
                    </Grid>
                    <Grid item xs={secondCol.config.size} className={classes.column}>
                        <Container props={{ editable: false, components: secondCol.components }} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default RowSymmetric;
