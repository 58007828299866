import {
    Box, Breadcrumbs, Button, Grid, Typography,
} from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { OrgContext } from './context';
import { makeStyles } from '@material-ui/core/styles';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import React, { useContext } from 'react';

const useStyles = makeStyles((theme) => ({
    orgIconFont: {
        fontFamily: 'CPS-Icon',
        fontSize: 20,
        padding: '7px 11px',
        borderRadius: '4px',
        backgroundColor: '#F7F9FB',
        border: '0.5px solid #EAF0F5',
    },
    orgCard: {
        backgroundColor: '#fff',
        borderRadius: 4,
        boxShadow: '0px 3px 12px #3e3c3c11',
        border: '0.5px solid #0000001a',
        cursor: 'pointer',
        margin: '-8px',
        [theme.breakpoints.down('xs')]: {
            margin: '0px',
            marginBottom: '15px',
        },
    },
    secondaryBtn: () => ({
        backgroundColor: theme.palette.primary.light,
        boxShadow: 'none',
        borderRadius: 4,
        maxHeight: 48,
        marginLeft: 16,
        padding: '18px 14px',
        marginTop: 16,
        marginBottom: 16,
        '&:hover': {
            boxShadow: 'none',
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
        },
    }),
    breadcrumbsNavlink: {
        textDecoration: 'none',
        color: 'inherit',
    },
    customNavlink: {
        textDecoration: 'none',
    },
    doctorDetails: {
        width: '100%',
        marginTop: 20,
    },
    medguideDashboardStyle: {
        [theme.breakpoints.down('xs')]: {
            marginLeft: '15px',
            marginRight: '15px',
        },
    },
    orgCardWidthStyle: {
        width: '100%',
    },
}));
/**
 * Gets the user info card on the basis of role.
 */
const getUserCard = (selectedOrg, userOrgRoles, classes) => {
    let usersInfoCard = null;
    usersInfoCard = (
        <Grid item md={6} lg={6}>
            <div className={classes.orgCard}>
                <Grid container>
                    <Grid item md={12} lg={12}>
                        <Box mt={3} mx={3} display="flex">
                            <Box order={1}>
                                <Typography color="primary" className={classes.orgIconFont}>B</Typography>
                            </Box>
                            <Box order={2} ml={1}>
                                <Grid container>
                                    <Grid item md={12} lg={12} className={classes.alignCenter}>
                                        <Typography variant="h4">
                                            <Box color="secondary.main">User Management</Box>
                                        </Typography>
                                    </Grid>
                                    <Grid item md={12} lg={12} className={classes.alignCenter}>
                                        <Typography variant="subtitle1">
                                            <Box color="secondary.light"> Add New users, Edit Existing users.</Box>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Box my={1} mx={3} textAlign="right">
                    <Grid item md={12} lg={12}>
                        <NavLink to={`/${selectedOrg.orgCode}/patients/`} className={classes.customNavlink}>
                            <Button variant="contained" className={classes.secondaryBtn}>Patients</Button>
                        </NavLink>
                        <NavLink
                            to={`/${selectedOrg.orgCode}/users/`}
                            className={classes.customNavlink}
                        >
                            <Button variant="contained" className={classes.secondaryBtn}>Users</Button>

                        </NavLink>
                    </Grid>
                </Box>
            </div>
        </Grid>
    );
    // }
    return usersInfoCard;
};
const OrgLandingPage = () => {
    const { selectedOrg, loggedUserType, userOrgRoles } = useContext(OrgContext);
    const classes = useStyles();
    const usersInfoCard = getUserCard(selectedOrg, userOrgRoles, classes);
    return (
        <Grid container>
            <Grid item md={12} lg={12}>
                <Typography variant="h2">
                    <Box ml={4} mt={2} className={classes.medguideDashboardStyle} color="secondary.main">MedGuide Dashboard</Box>
                </Typography>
            </Grid>
            <Grid item md={12} lg={12}>
                <Box className={classes.medguideDashboardStyle} ml={4} mt={1}>
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                        <NavLink className={classes.breadcrumbsNavlink} to="/">MedGuide Contents</NavLink>
                        <Typography color="textPrimary" className={classes.breadcrumbsNavlink}>{selectedOrg.name}</Typography>
                    </Breadcrumbs>
                </Box>
            </Grid>
            {(loggedUserType === 'Super Admin' || loggedUserType === 'Doctor')
                && (
                    <Box className={classes.medguideDashboardStyle} mx={4} mt={4}>
                        <Grid container>
                            <Grid item md={6} lg={6} className={classes.orgCardWidthStyle}>
                                <div className={classes.orgCard}>
                                    <Grid container>
                                        <Grid item md={12} lg={12}>
                                            <Box mt={3} mx={3} display="flex">
                                                <Box order={1}>
                                                    <Typography color="primary" className={classes.orgIconFont}>A</Typography>
                                                </Box>
                                                <Box order={2} ml={1}>
                                                    <Grid container>
                                                        <Grid item md={12} lg={12} className={classes.alignCenter}>
                                                            <Typography variant="h4">
                                                                <Box color="secondary.main">Content Library</Box>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item md={12} lg={12} className={classes.alignCenter}>
                                                            <Typography variant="subtitle1">
                                                                <Box color="secondary.light">Content Input Display</Box>
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Box my={1} mx={3} textAlign="right" style={{ margin: '0rem 1.5rem 1rem 0rem' }}>
                                        <Grid item md={12} lg={12}>
                                            <NavLink to={`/${selectedOrg.orgCode}/timelines/`} className={classes.customNavlink}>
                                                <Button variant="contained" className={classes.secondaryBtn}>Timelines</Button>
                                            </NavLink>
                                            <NavLink to={`/${selectedOrg.orgCode}/templates/`} className={classes.customNavlink}>
                                                <Button variant="contained" className={classes.secondaryBtn}>Template Library</Button>
                                            </NavLink>
                                        </Grid>
                                    </Box>
                                </div>
                            </Grid>
                            {loggedUserType === 'Super Admin'
                            && (
                                <Grid item md={6} lg={6}>
                                    <div className={classes.orgCard}>
                                        <Grid container>
                                            <Grid item md={12} lg={12}>
                                                <Box mt={3} mx={3} display="flex">
                                                    <Box order={1}>
                                                        <Typography color="primary" className={classes.orgIconFont}>B</Typography>
                                                    </Box>
                                                    <Box order={2} ml={1}>
                                                        <Grid container>
                                                            <Grid item md={12} lg={12} className={classes.alignCenter}>
                                                                <Typography variant="h4">
                                                                    <Box color="secondary.main">Organisation Management</Box>
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item md={12} lg={12} className={classes.alignCenter}>
                                                                <Typography variant="subtitle1">
                                                                    <Box color="secondary.light"> Active, prospective and Doctor Management.</Box>
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Box my={1} mx={3} textAlign="right">
                                            <Grid item md={12} lg={12}>
                                                <NavLink to={`/${selectedOrg.orgCode}/doctors/`} className={classes.customNavlink}>
                                                    <Button variant="contained" className={classes.secondaryBtn}>Organisations</Button>
                                                </NavLink>
                                            </Grid>
                                        </Box>
                                    </div>
                                </Grid>
                            )}
                            {loggedUserType !== 'Super Admin' && usersInfoCard}
                            {/* {usersInfoCard} */}
                        </Grid>
                    </Box>
                )}
        </Grid>
    );
};
export default OrgLandingPage;
