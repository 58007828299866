import {
    FilterSettings, addCells, buildSettingsTreePage, findComponent, setActiveComponent,
} from '../../helper/commonHelper';
import { Settings, makeInlineEditor } from '../Settings';
import { TimelineContext, WorkshopContext } from '../../context';
import { componentWithId } from '../Atoms';
import { makeStyles } from '@material-ui/core/styles';
import { useRecoilState } from 'recoil';
import DefaultComponentLayout from '../DefaultComponentLayout';
import React, { useContext, useEffect, useState } from 'react';
import TimelineImg from '../../images/timeline.png';
import Typography from '@material-ui/core/Typography';

/**
 * Set the key and type of the field
 */
const LabelProp = {
    key: 'label',
    type: 'Label',
    label: 'Label Text',
    id: 'Label',
};

/**
 * Set the Style for component
 */
const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '95%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },

    buttonGroup: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    textInputWidth: {
        width: '95%',
    },
    textFieldContainer: {
        width: '100%',
    },
    columHeadType: {
        padding: 4,
        fontSize: 10,
    },
    columnHeadType1: {
        fontSize: '0.625rem',
        width: '100%',
        padding: '6px !important',
        borderTop: '1px solid #CBCBCB',
        minHeight: '1.5rem',
        fontWeight: '400',
    },
    selectedComp: {
        background: 'cornflowerblue',
    },
    timelineImage: {
        width: '100%',
        border: '1px solid #cbcbcb',
        padding: '0px 1px',
    },
    timelineLabelCont: {
        textAlign: 'center',
    },
    anchorTag: {
        color: 'inherit',
        textDecoration: 'none',
        cursor: 'text',
    },
    commonGroup: {
        color: 'inherit',
        textDecoration: 'none',
        cursor: 'text',
        '&:hover': {
            backgroundColor: '#87CEFA',
        },
    },
    commonGroupWithHyperlink: {
        color: '#066785',
        textDecoration: 'none',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));

/**
 * Define the setting's dropdown value
 */
export const LabelProps = {
    display: [
        LabelProp,
    ],
    poi: true,
};

/**
 * @component Label
 * @param {string} id
 * @param {object} props
 * @returns {component}
 */
const Label = ({
    componentProps,
}) => {
    const { id } = componentProps;
    const classes = useStyles();
    const [component, setComponentState] = useRecoilState(componentWithId(id));
    const [componentParent] = useRecoilState(componentWithId(componentProps.parent));
    const [divHeight, setDivHeight] = useState(null);
    const { inWorkshop, compareModal } = useContext(WorkshopContext);
    const {
        settingComponent,
        setSettingComponentHandle,
        setDirtyDomain,
        domain,
        pageComponent,
        dirtyDomain,
        duplicateComponent,
        workshopComponent,
        workshopActiveComponent,
    } = useContext(TimelineContext);
    const componentList = [];
    buildSettingsTreePage(componentList, pageComponent && pageComponent[domain]);
    if (componentProps[domain]) {
        component[domain] = componentProps[domain];
    }
    let isSelected = false;

    if (settingComponent && settingComponent.id === id) {
        isSelected = true;
    }

    useEffect(() => {
        const pageDomain = component[domain];
        if (dirtyDomain) {
            return;
        }
        if (pageDomain && Object.keys(pageDomain).length > 0) {
            const currentComp = componentList.find((c) => c.id === id);
            if (currentComp) {
                currentComp[domain] = pageDomain;
            }
        }
    }, [domain, component, id, dirtyDomain]);

    const onChangeOfLabel = (lastComponent) => {
        let hasLabel = false;
        if (lastComponent?.currentValue || lastComponent?.props?.label) {
            hasLabel = true;
        }
        if (hasLabel && component.parent) {
            const duplicateComp = duplicateComponent(
                component.parent[component.parent.length - 1], component.parent, component.parent.length - 1, { label: '', focused: true }
            );
            const parentColumnComp = findComponent(workshopComponent?.props?.components, duplicateComp.parent);
            if (parentColumnComp.props.isComplexForm || parentColumnComp.props.LRProps || parentColumnComp.props.components) {
                parentColumnComp.props.components.forEach((item, index, arr) => {
                    if (index === arr.length - 1) {
                        item.props.label = '';
                    }
                    if (!item.props.associatedCell) {
                        addCells({
                            mainComp: parentColumnComp, associatedComponent: item, workshopComponent, LRProps: parentColumnComp.props.LRProps,
                        });
                    }
                });
            }
        } else if (hasLabel && !component.parent) {
            const parent = componentParent.props.components;
            const duplicateComp = duplicateComponent(parent[parent.length - 1], parent, parent.length - 1, { label: '', focused: true });
            const parentColumnComp = findComponent(workshopComponent?.props?.components, duplicateComp.parent);
            if (parentColumnComp.props.isComplexForm || parentColumnComp.props.LRProps || parentColumnComp.props.components) {
                parentColumnComp.props.components.forEach((item, index, arr) => {
                    if (index === arr.length - 1) {
                        item.props.label = '';
                    }
                    if (!item.props.associatedCell) {
                        addCells({
                            mainComp: parentColumnComp, associatedComponent: item, workshopComponent, LRProps: parentColumnComp.props.LRProps,
                        });
                    }
                });
            }
        }
    };

    const renderEditor = () => makeInlineEditor({
        field: { ...LabelProp, onSubmit: onChangeOfLabel },
        component,
        setComponentState,
        classes,
        domain,
        setDirtyDomain,
    });

    const compHeightChange = () => {
        if (componentProps?.props?.associatedCell) {
            const id = `${inWorkshop || false}-${componentProps?.props?.associatedCell}`;
            const ele = document.getElementById(id);
            if (ele) {
                setDivHeight(ele.clientHeight);
            }
        }
    };

    useEffect(() => {
        setTimeout(() => {
            compHeightChange();
        }, 0);
    }, [workshopActiveComponent?.props?.textField, workshopActiveComponent?.props?.associatedCell, workshopActiveComponent?.props?.options]);

    let divHeightView1 = null;
    /** Height fix */
    if (componentProps?.props?.associatedCell) {
        const id = `false-${componentProps.props.associatedCell}`;
        const ele = document.getElementById(id);
        if (ele) {
            divHeightView1 = ele.clientHeight;
        }
    }

    const renderView = () => (
        <div style={{ height: divHeightView1 || 'inherit', overflow: !inWorkshop && 'auto' }}>
            <Typography className={classes.columnHeadType1} color="inherit">
                {component?.props?.label || ''}
            </Typography>
        </div>
    );

    return (
        <DefaultComponentLayout component={component} id={id}>
            {(inWorkshop && !compareModal) ? (
                <div
                    style={{ height: divHeight || 'inherit', borderTop: '1px solid #CBCBCB' }}
                    className={isSelected ? classes.selectedComp : null}
                    id={isSelected ? 'selectedCompId' : ''}
                    onClick={(event) => {
                        event.stopPropagation();
                        setActiveComponent(setSettingComponentHandle, componentProps);
                    }}
                    aria-hidden="true"
                >
                    {renderEditor()}
                </div>
            ) : renderView()}
        </DefaultComponentLayout>
    );
};

/**
 * @component LabelPreview [preview text input]
 * @param {string} id
 * @param {object} props
 * @returns {component}
 */
const LabelInputPreview = ({ component }) => {
    const classes = useStyles();
    return (
        <DefaultComponentLayout preview component={component}>
            <div className={classes.textFieldContainer}>
                <Typography className={classes.columHeadType} color="inherit">
                    {component.props.label}
                </Typography>
            </div>
        </DefaultComponentLayout>
    );
};

/**
 * @component LabelInputEditor [render the settings]
 * @param {string} config
 * @param {function} updateComponent
 * @returns {component}
 */
const LabelInputEditor = ({ config, updateComponent }) => {
    const [component, setComponentState] = useRecoilState(componentWithId(config.id));
    const { settingMenuType } = useContext(TimelineContext);
    const editorProps = { display: FilterSettings(LabelProps, settingMenuType), poi: LabelProps.poi };

    return (
        <Settings editorProps={editorProps} component={component} setComponentState={setComponentState} saveComponent={updateComponent} />
    );
};

const TimelineLabel = (componentProps) => {
    const classes = useStyles();
    const { selectedTimeline } = useContext(TimelineContext);
    return (
        <div className={classes.timelineLabelCont}>
            <Typography variant="h2">
                {componentProps?.type}
                {' '}
                -
                {' '}
                {selectedTimeline?.name}
            </Typography>
            <img src={TimelineImg} alt="timeline" className={classes.timelineImage} />
        </div>
    );
};

export {
    Label, LabelInputEditor, LabelInputPreview, TimelineLabel,
};
