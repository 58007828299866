import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useContext } from 'react';

import { SETTINGS_ACCESS_MAP, SettingsAccessGrid } from '../SettingsAccess';
import { TimelineContext } from '../../context';
import { filterFormTextAreaSettings } from '../../helper/commonHelper';
import { useStyles } from '../../Workshop';

const SettingUserViewDialog = () => {
    const classes = useStyles();
    const {
        updateSettingsAccess, selectedComponent, workshopComponent, setSettingUserViewDialog,
        settingUserViewDialog, setUserSettingsViewChanges, setUserSettingsValues,
    } = useContext(TimelineContext);
    const type = 'Protocol';

    let settingsItems = SETTINGS_ACCESS_MAP[type]?.length > 0 && SETTINGS_ACCESS_MAP[type].filter((ele) => {
        if (workshopComponent?.isProgression && ele?.key?.includes('hyperlink')) {
            return false;
        }
        return true;
    }) || [];
    settingsItems = filterFormTextAreaSettings(settingsItems, selectedComponent);

    const handleClose = () => {
        setSettingUserViewDialog(false);
        setUserSettingsValues([]);
    };

    const handleSave = () => {
        setUserSettingsViewChanges(true);
        setSettingUserViewDialog(false);
    };

    const handleAccessChange = (access, setting) => {
        const newSettingsAccess = { type, access, setting };
        updateSettingsAccess(newSettingsAccess);
    };

    return (
        <Dialog open={settingUserViewDialog} classes={{ paperWidthSm: classes.widthSectPart }} onClose={handleClose}>
            <DialogTitle className={classes.poupConHeading}>
                <Grid container>
                    <Grid item xs={10}>
                        <Typography variant="h6">Setting User View</Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.rightTextAlign}>
                        <CloseIcon className={classes.closeIconStyle} onClick={handleClose} />
                    </Grid>
                </Grid>
            </DialogTitle>
            <Divider />
            <DialogContent className={classes.poupConHeadingMeddle}>
                <Grid container>
                    <Grid item xs={5} className={classes.headingTextLeft}>Master</Grid>
                    <Grid item xs={3} className={classes.headingText}>Comprehensive</Grid>
                    <Grid item xs={2} className={classes.headingText}>Advance</Grid>
                    <Grid item xs={2} className={classes.headingText}>Basic</Grid>
                    {settingsItems.map((item) => (
                        <SettingsAccessGrid
                            type={type}
                            setting={item.key}
                            onAccessChange={(access) => handleAccessChange(access, item.key)}
                            key={item.key}
                            label={item.label}
                        />
                    ))}
                </Grid>
            </DialogContent>
            <Divider />
            <DialogActions className={classes.poupConBoottom}>
                <Button variant="contained" onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSave} className={classes.saveUpButton}>Save</Button>
            </DialogActions>
        </Dialog>
    );
};

export default SettingUserViewDialog;
