const componentLibrary = [
    {
        id: 'cl-1',
        type: 'Text',
        name: 'H1 Header',
        group: 'Headers',
        superGroup: 'Header',
        component: 'H1',
        useLibrary: false,
        isExpanded: true,
    },
    {
        id: 'cl-111',
        type: 'Text',
        name: 'Header',
        useLibrary: false,
        isExpanded: true,
        variants: [
            {
                id: 'cl-1', type: 'Text', name: 'H1 Header', key: 'h1', group: 'Headers', superGroup: 'Header', component: 'H1', useLibrary: false,
            },
            {
                id: 'cl-1.1', type: 'Text', name: 'H2 Header', key: 'h2', useLibrary: false,
            },
            {
                id: 'cl-1.3', type: 'Text', name: 'H4 Protocol Group', key: 'h4', useLibrary: false,
            },
        ],
    },
    {
        id: 'cl-112',
        type: 'Text',
        name: 'Header',
        useLibrary: false,
        isExpanded: true,
        variants: [
            {
                id: 'cl-1', type: 'Text', name: 'H1 Header', key: 'h1', useLibrary: false,
            },
            {
                id: 'cl-1.1', type: 'Text', name: 'H2 Header', key: 'h2', useLibrary: false,
            },
            {
                id: 'cl-1.2', type: 'Text', name: 'H3 Header', key: 'h3', useLibrary: false,
            },
            {
                id: 'cl-1.3', type: 'Text', name: 'H4 Header', key: 'h4', useLibrary: false,
            },
        ],
    },
    {
        id: 'cl-30',
        type: 'Forms',
        name: 'Forms',
        variants: [
            {
                id: 'cl-3', type: 'Checkbox', name: 'Checkbox', header: 'Checkbox',
            },
            // { id: 'cl-3-6', type: 'Select', name: 'Checkbox Group' },
            {
                id: 'cl-4', type: 'TextInput', name: 'Text Input', header: 'Text Input',
            },
            { id: 'cl-5', type: 'DatePicker', name: 'Date Picker' },
            {
                id: 'cl-6', type: 'Select', name: 'Single Select', header: 'Drop Down',
            },
            {
                id: 'cl-69', type: 'MultiSelect', name: 'Multi Select', header: 'Drop Down',
            },
            {
                id: 'cl-61', type: 'Select', name: 'Radio Group', header: 'Checkbox',
            },
            {
                id: 'cl-99', type: 'Label', name: 'Label', header: 'Text Input',
            },
            {
                id: 'cl-41', type: 'HTML', name: 'Paragraph', props: { html: '' },
            },
            {
                id: 'cl-44', type: 'TextAreaCF', name: 'Textarea', header: 'Text Area',
            },
            {
                id: 'cl-45', type: 'TextBoxCF', name: 'Textbox', header: 'Text Box',
            },
        ],
    },
    {
        id: 'cl-82', type: 'Protocol-Workshop', name: 'Page', useLibrary: false, libraryFilter: 'Page', keepProtocolHidden: true,
    },
    {
        id: 'cl-42', type: 'Image', name: 'Image', props: {},
    },
    {
        id: 'cl-43', type: 'Gallery', name: 'Gallery', props: {},
    },
    {
        id: 'cl-15',
        type: 'Row',
        name: 'Row',
        variants: [
            {
                id: 'cl-15', type: 'Row', name: '1 Column', value: 1,
            },
            {
                id: 'cl-18', type: 'Row', name: '2 Column', value: 2,
            },
            {
                id: 'cl-19', type: 'Row', name: '3 Column', value: 3,
            },
            {
                id: 'cl-20', type: 'Row', name: '4 Column', value: 4,
            },
        ],
    },
    {
        id: 'cl-16',
        type: 'Protocol',
        name: 'Protocol',
        useLibrary: true,
        variants: [
            {
                id: 'cl-16', type: 'Protocol', name: 'Protocol Only', useLibrary: false,
            },
            {
                id: 'cl-16', type: 'Protocol', name: 'Page', useLibrary: true, libraryFilter: 'Page',
            },
            {
                id: 'cl-16', type: 'Protocol', name: 'Image/Video', useLibrary: true, libraryFilter: 'Images/Videos',
            },
            {
                id: 'cl-16', type: 'Protocol', name: 'Form', useLibrary: true, libraryFilter: 'Form',
            },
            {
                id: 'cl-16', type: 'Protocol', name: 'Forms', useLibrary: true, libraryFilter: 'Complex Forms',
            },
        ],
    },
    {
        id: 'cl-12',
        type: 'Progression',
        name: 'Progression',
        useLibrary: true,
        variants: [
            {
                id: 'cl-1.3', type: 'Text', name: 'H4 Protocol Group', key: 'h4', useLibrary: false,
            },
            {
                id: 'cl-12', type: 'Progression', name: 'Image/Video', useLibrary: true, libraryFilter: 'Images/Videos',
            },
            {
                id: 'cl-12', type: 'Progression', name: 'Form', useLibrary: true, libraryFilter: 'Form',
            },
            {
                id: 'cl-12', type: 'Progression', name: 'Progression Only', useLibrary: false,
            },
            {
                id: 'cl-12', type: 'Progression', name: 'Page', useLibrary: true, libraryFilter: 'Page',
            },
            {
                id: 'cl-12', type: 'Progression', name: 'Complex Forms', useLibrary: true, libraryFilter: 'Complex Forms',
            },
        ],
    },
];

const accessView = {
    isBasic: false,
    isAdvanced: false,
    isUserView: true,
};

const componentLibraryMap = {
    'cl-1': {
        id: 0,
        type: 'Text',
        supergroup: 'Header',
        component: 'H1',
        props: {
            text: 'H1 Header', type: 'h1', group: 'Headers', components: [], isExpanded: true, accessView,
        },
    },
    'cl-1.1': {
        id: 0,
        type: 'Text',
        supergroup: 'Header',
        component: 'H2',
        props: {
            text: 'H2 Header', type: 'h2', group: 'Headers', components: [], isExpanded: true, accessView,
        },
    },
    'cl-1.2': {
        id: 0,
        type: 'Text',
        supergroup: 'Header',
        component: 'H3',
        props: {
            text: 'H3 Header', type: 'h3', group: 'Headers', components: [], isExpanded: true, accessView,
        },
    },
    'cl-1.3': {
        id: 0,
        type: 'Text',
        supergroup: 'Header',
        component: 'H4',
        props: {
            text: 'H4 Protocol Group', type: 'h4', group: 'Headers', components: [], isExpanded: true, accessView,
        },
    },
    'cl-2': { id: 0, type: 'Text', props: { text: 'Body Text', type: 'body1', accessView } },
    'cl-3': { id: 0, type: 'Checkbox', props: { checkboxes: [{ label: '', accessView }] } },
    'cl-4': { id: 0, type: 'TextInput', props: { label: 'Text Input', accessView } },
    'cl-99': { id: 0, type: 'Label', props: { label: 'Label Text', accessView } },
    'cl-5': { id: 0, type: 'DatePicker', props: { accessView } },
    'cl-44': { id: 0, type: 'TextAreaCF', props: { label: '', accessView } },
    'cl-45': { id: 0, type: 'TextBoxCF', props: { label: '', accessView } },
    'cl-6': {
        id: 0,
        type: 'Select',
        props: {
            renderAs: 'Singleselect',
            label: 'Select One',
            defaultOption: 'one',
            options: [],
            accessView,
        },
    },
    'cl-3-6': {
        id: 0,
        type: 'Select',
        props: {
            renderAs: 'Checkbox',
            label: 'Select One',
            defaultOption: 'one',
            options: [],
            accessView,
        },
    },
    'cl-69': {
        id: 0,
        type: 'MultiSelect',
        props: {
            renderAs: 'select',
            label: 'Select One',
            defaultOption: ['one'],
            options: [],
            accessView,
        },
    },
    'cl-61': {
        id: 0,
        type: 'Select',
        props: {
            renderAs: 'radio',
            label: 'Radio Group',
            defaultOption: '',
            options: [],
            accessView,
        },
    },
    'cl-15': {
        id: 0,
        type: 'Row',
        props: {
            title: 'Row',
            components: [
                {
                    id: 0,
                    type: 'Column',
                    props: {
                        size: 'auto', index: 1, components: [], columnCB: true,
                    },
                },
            ],
            accessView,
        },
    },
    'cl-18': {
        id: 0,
        type: 'Row',
        props: {
            title: 'Row',
            components: [
                {
                    id: 0,
                    type: 'Column',
                    props: {
                        size: 'auto', index: 1, components: [], columnCB: true, accessView,
                    },
                },
                {
                    id: 0,
                    type: 'Column',
                    props: {
                        size: 'auto', index: 2, components: [], columnCB: true, accessView,
                    },
                },
            ],
            accessView,
        },
    },
    'cl-19': {
        id: 0,
        type: 'Row',
        props: {
            title: 'Row',
            components: [
                {
                    id: 0,
                    type: 'Column',
                    props: {
                        size: 'auto', index: 1, components: [], columnCB: true, accessView,
                    },
                },
                {
                    id: 0,
                    type: 'Column',
                    props: {
                        size: 'auto', index: 2, components: [], columnCB: true, accessView,
                    },
                },
                {
                    id: 0,
                    type: 'Column',
                    props: {
                        size: 'auto', index: 3, components: [], columnCB: true, accessView,
                    },
                },
            ],
        },
    },
    'cl-20-1': {
        id: 0,
        type: 'Column',
        props: {
            size: 'auto', index: 1, components: [], columnCB: true, accessView,
        },
    },

    'cl-20-1-1': {
        id: 0,
        type: 'Column',
        props: {
            size: 'auto', index: 1, components: [], columnCB: false, isComplexForm: true, accessView,
        },
    },

    'cl-20-1-2': {
        id: 0,
        type: 'Column',
        props: {
            size: 'auto', index: 1, components: [], columnCB: false, isMixedForm: true, accessView, isShowTimeline: true,
        },
    },

    'cl-20': {
        id: 0,
        type: 'Row',
        props: {
            title: 'Row',
            components: [
                {
                    id: 0,
                    type: 'Column',
                    props: {
                        size: 'auto', index: 1, components: [], columnCB: true, accessView,
                    },
                },
                {
                    id: 0,
                    type: 'Column',
                    props: {
                        size: 'auto', index: 2, components: [], columnCB: true, accessView,
                    },
                },
                {
                    id: 0,
                    type: 'Column',
                    props: {
                        size: 'auto', index: 3, components: [], columnCB: true, accessView,
                    },
                },
                {
                    id: 0,
                    type: 'Column',
                    props: {
                        size: 'auto', index: 4, components: [], columnCB: true, accessView,
                    },
                },
            ],
        },
    },

    'cl-22': {
        id: 0,
        type: 'Timeline',
        props: {
            label: 'Label Text',
            components: [],
            accessView,
        },
    },

    'cl-21': {
        id: '',
        type: 'Column',
        props: {
            poi: [],
            size: 'auto',
            group: 'Column',
            title: '',
            general: [],
            showPOI: true,
            components: [],
            progressions: [],
            showDatePicker: true,
            showProgression: true,
            showProgressionSection: true,
        },
    },
    'cl-16': {
        id: 0,
        type: 'Protocol',
        props: {
            title: 'Enter Protocol',
            groupTitle: 'Enter Protocol Group Title',
            hadGroupHeader: true,
            accessView,
            components: [
                {
                    id: 0,
                    type: 'Column',
                    props: {
                        size: 'auto',
                        index: 1,
                        components: [],
                        columnCB: true,
                        accessView,
                    },
                },
            ],
        },
    },
    'cl-16-1': {
        id: 0,
        type: 'Protocol',
        props: {
            title: 'Enter Protocol',
            groupTitle: 'Enter Protocol Group Title',
            hadGroupHeader: true,
            accessView,
            showTitle: false,
            layout: 'stacked',
            hideFromWorkshop: true,
            components: [
                {
                    id: 0,
                    type: 'Column',
                    props: {
                        size: 'auto',
                        index: 1,
                        components: [
                            {
                                id: 0,
                                type: 'Row',
                                props: {
                                    title: 'Row',
                                    components: [
                                        {
                                            id: 0,
                                            type: 'Column',
                                            props: {
                                                size: 'auto', index: 1, components: [], columnCB: true,
                                            },
                                        },
                                    ],
                                    accessView,
                                    showAddRow: true,
                                    showAddColumn: true,
                                },
                            },
                        ],
                        columnCB: true,
                        accessView,
                        hideFromWorkshop: true,
                    },
                },
            ],
        },
    },
    'cl-16-2': {
        id: 0,
        type: 'Protocol',
        props: {
            title: 'Enter Protocol',
            groupTitle: 'Enter Protocol Group Title',
            hadGroupHeader: true,
            accessView,
            showTitle: false,
            layout: 'stacked',
            hideFromWorkshop: true,
            components: [
                {
                    id: 0,
                    type: 'Column',
                    props: {
                        size: 'auto',
                        index: 1,
                        components: [
                            {
                                id: 0,
                                type: 'Row',
                                props: {
                                    title: 'Row',
                                    components: [
                                        {
                                            id: 0,
                                            type: 'Column',
                                            props: {
                                                size: 'auto', index: 1, components: [], columnCB: true,
                                            },
                                        },
                                        {
                                            id: 0,
                                            type: 'Column',
                                            props: {
                                                size: 'auto', index: 2, components: [], columnCB: true, accessView,
                                            },
                                        },
                                    ],
                                    accessView,
                                    showAddRow: true,
                                    showAddColumn: true,
                                },
                            },
                        ],
                        columnCB: true,
                        accessView,
                        hideFromWorkshop: true,
                    },
                },
            ],
        },
    },
    'cl-16-3': {
        id: 0,
        type: 'Protocol',
        props: {
            title: 'Enter Protocol',
            groupTitle: 'Enter Protocol Group Title',
            hadGroupHeader: true,
            accessView,
            showTitle: false,
            layout: 'stacked',
            hideFromWorkshop: true,
            components: [
                {
                    id: 0,
                    type: 'Column',
                    props: {
                        size: 'auto',
                        index: 1,
                        components: [
                            {
                                id: 0,
                                type: 'Row',
                                props: {
                                    title: 'Row',
                                    components: [
                                        {
                                            id: 0,
                                            type: 'Column',
                                            props: {
                                                size: 'auto', index: 1, components: [], columnCB: true,
                                            },
                                        },
                                        {
                                            id: 0,
                                            type: 'Column',
                                            props: {
                                                size: 'auto', index: 2, components: [], columnCB: true, accessView,
                                            },
                                        },
                                        {
                                            id: 0,
                                            type: 'Column',
                                            props: {
                                                size: 'auto', index: 3, components: [], columnCB: true, accessView,
                                            },
                                        },
                                    ],
                                    accessView,
                                    showAddRow: true,
                                    showAddColumn: true,
                                },
                            },
                        ],
                        columnCB: true,
                        accessView,
                        hideFromWorkshop: true,
                    },
                },
            ],
        },
    },
    'cl-16-4': {
        id: 0,
        type: 'Protocol',
        props: {
            title: 'Enter Protocol',
            groupTitle: 'Enter Protocol Group Title',
            hadGroupHeader: true,
            accessView,
            showTitle: false,
            layout: 'stacked',
            hideFromWorkshop: true,
            components: [
                {
                    id: 0,
                    type: 'Column',
                    props: {
                        size: 'auto',
                        index: 1,
                        components: [
                            {
                                id: 0,
                                type: 'Row',
                                props: {
                                    title: 'Row',
                                    components: [
                                        {
                                            id: 0,
                                            type: 'Column',
                                            props: {
                                                size: 'auto', index: 1, components: [], columnCB: true,
                                            },
                                        },
                                        {
                                            id: 0,
                                            type: 'Column',
                                            props: {
                                                size: 'auto', index: 2, components: [], columnCB: true, accessView,
                                            },
                                        },
                                        {
                                            id: 0,
                                            type: 'Column',
                                            props: {
                                                size: 'auto', index: 3, components: [], columnCB: true, accessView,
                                            },
                                        },
                                        {
                                            id: 0,
                                            type: 'Column',
                                            props: {
                                                size: 'auto', index: 4, components: [], columnCB: true, accessView,
                                            },
                                        },
                                    ],
                                    accessView,
                                    showAddRow: true,
                                    showAddColumn: true,
                                },
                            },
                        ],
                        columnCB: true,
                        accessView,
                        hideFromWorkshop: true,
                    },
                },
            ],
        },
    },
    'cl-82': {
        id: 0,
        type: 'Protocol',
        props: {
            title: '',
            showTitle: false,
            layout: 'stacked',
            groupTitle: 'Enter Protocol Group Title',
            group: 'Page Groups',
            hadGroupHeader: true,
            accessView,
            components: [
                {
                    id: 0,
                    type: 'Column',
                    props: {
                        showTitle: false,
                        title: '',
                        size: 'auto',
                        index: 1,
                        components: [
                            {
                                id: 'cl-31',
                                type: 'Page',
                                name: 'Page',
                                props: {
                                    pageHTML: '',
                                    title: '',
                                    titleType: 'inline',
                                    pageCB: true,
                                    components: [
                                        {
                                            id: 'cl-41', type: 'HTML', name: 'Paragraph', props: { html: '' },
                                        },
                                    ],
                                },
                            },
                        ],
                        columnCB: true,
                        accessView,
                    },
                },
            ],
        },
    },
    'cl-17': {
        id: 0,
        type: 'Protocol',
        props: {
            title: 'Enter Protocol Title',
            components: [
                {
                    id: 0,
                    type: 'Column',
                    props: {
                        size: 5,
                        index: 1,
                        columnCB: true,
                        accessView,
                        components: [
                            { id: 0, type: 'Text', props: { text: 'Enter Header', type: 'body1', accessView } },
                            { id: 0, type: 'Checkbox', props: { checkboxes: [{ label: 'Enter Check Box Title' }], accessView } },
                        ],
                    },
                },
                {
                    id: 0,
                    type: 'Column',
                    props: {
                        size: 5,
                        index: 1,
                        columnCB: true,
                        accessView,
                        components: [
                            { id: 0, type: 'Text', props: { text: 'Enter Header', type: 'body1', accessView } },
                            { id: 0, type: 'Checkbox', props: { checkboxes: [{ label: 'Enter Check Box Title' }], accessView } },
                        ],
                    },
                },
            ],
            accessView,
        },
    },
    'cl-12': {
        id: 0,
        type: 'Protocol',
        isProgression: true,
        props: {
            title: 'Enter Progression',
            groupTitle: 'Enter Progression Group Title',
            hadGroupHeader: true,
            components: [
                {
                    id: 0,
                    type: 'Column',
                    props: {
                        size: 'auto',
                        index: 1,
                        columnCB: true,
                        accessView,
                        components: [
                        ],
                    },
                },
            ],
            accessView,
        },
    },
    'cl-7': {
        id: 0,
        type: 'Progression',
        props: {
            title: 'Enter Progression Title',
            accessView,
            components: [
                {
                    id: 0,
                    type: 'Column',
                    props: {
                        size: 5,
                        index: 1,
                        columnCB: true,
                        accessView,
                        components: [
                            { id: 0, type: 'Text', props: { text: 'Enter Header', type: 'body1', accessView } },
                            { id: 0, type: 'Checkbox', props: { checkboxes: [{ label: 'Enter Check Box Title' }], accessView } },
                        ],
                    },
                },
                {
                    id: 0,
                    type: 'Column',
                    props: {
                        size: 5,
                        index: 1,
                        columnCB: true,
                        accessView,
                        components: [
                            { id: 0, type: 'Text', props: { text: 'Enter Header', type: 'body1', accessView } },
                            { id: 0, type: 'Checkbox', props: { checkboxes: [{ label: 'Enter Check Box Title' }], accessView } },
                        ],
                    },
                },
            ],
        },
    },
    'cl-8': {
        id: 0,
        type: 'ProgressionGroup',
        props: {
            title: 'Enter Progression Group Title',
            components: [],
            accessView,
        },
    },
    'cl-13': {
        id: 0,
        type: 'Panel',
        props: {
            header: 'Panel', defaultExpanded: true, components: [], accessView,
        },
    },
    'cl-31': {
        id: 0,
        type: 'Page',
        props: {
            pageHTML: '',
            title: '',
            titleType: 'inline',
            pageCB: true,
            accessView,
            components: [
                {
                    id: 'cl-41', type: 'HTML', name: 'Paragraph', props: { html: '' },
                },
            ],
        },
    },
    'cl-41': { id: 0, type: 'HTML', props: { accessView } },
    'cl-42': { id: 0, type: 'Image', props: { accessView } },
    'cl-43': { id: 0, type: 'Gallery', props: { showTitle: true, showCaption: true, accessView } },
    'cl-14': {
        id: 0,
        type: 'Media',
        props: {
            slideTitle: '',
            slideCaption: '',
            layoutOption: 'scroll',
            accessView,
            imageData: [{
                title: '',
                caption: '',
                name: 'download (2).jpeg',
                url: 'https://mg3.ams3.digitaloceanspaces.com/e252a4d7-1df6-4732-acf2-9fd2fb332349',
            },
            {
                title: '',
                caption: '',
                name: 'download (4).jpeg',
                url: 'https://mg3.ams3.digitaloceanspaces.com/760df8f7-08a1-44f6-a2b2-9b4e1d597a5b',
            },
            {
                title: '',
                caption: '',
                name: 'download.jpeg',
                url: 'https://mg3.ams3.digitaloceanspaces.com/b6d71f08-0693-438b-9a58-b57ca9769ccb',
            }],
        },
    },
    'cl-30': {
        id: 0,
        type: 'Progression',
        props: {
            title: 'Progression',
            layout: 'stacked',
            accessView,
            components: [{
                id: 0,
                type: 'Column',
                props: {
                    size: 'auto',
                    index: 1,
                    columnCB: true,
                    accessView,
                    components: [
                        {
                            id: 0,
                            type: 'Media',
                            props: {
                                slideTitle: '',
                                slideCaption: '',
                                layoutOption: 'scroll',
                                accessView,
                                imageData: [{
                                    title: '',
                                    caption: '',
                                    name: 'download (2).jpeg',
                                    url: 'https://mg3.ams3.digitaloceanspaces.com/e252a4d7-1df6-4732-acf2-9fd2fb332349',
                                },
                                {
                                    title: '',
                                    caption: '',
                                    name: 'download (4).jpeg',
                                    url: 'https://mg3.ams3.digitaloceanspaces.com/760df8f7-08a1-44f6-a2b2-9b4e1d597a5b',
                                },
                                {
                                    title: '',
                                    caption: '',
                                    name: 'download.jpeg',
                                    url: 'https://mg3.ams3.digitaloceanspaces.com/b6d71f08-0693-438b-9a58-b57ca9769ccb',
                                }],
                            },
                        },
                    ],
                },
            }],
        },
    },
};

const templateLibrary = [
];

const temp = {};
// eslint-disable-next-line no-return-assign
templateLibrary.forEach((template) => temp[template.id] = template);
const templateLibraryMap = temp;

const ProcedureLibrary = {
    id: '1',
    title: 'Test Procedure Library',
    children: [
        {
            id: '1.1',
            title: 'Face',
            children: [
                {
                    id: '1.1.1',
                    title: 'Brow Lift',
                    children: [],
                },
                {
                    id: '1.1.2',
                    title: 'Temple Lift',
                    children: [],
                },
                {
                    id: '1.1.3',
                    title: 'Upper Eyelid',
                    children: [],
                },
                {
                    id: '1.1.4',
                    title: 'Lower Eyelid',
                    children: [],
                },
                {
                    id: '1.1.5',
                    title: 'Lower Lid Bags',
                    children: [],
                },
                {
                    id: '1.1.6',
                    title: 'Volume Replacement',
                    children: [],
                },
                {
                    id: '1.1.7',
                    title: 'Lower Face/Neck Lift',
                    children: [],
                },
                {
                    id: '1.1.8',
                    title: 'Chin Implant',
                    children: [],
                },
                {
                    id: '1.1.9',
                    title: 'Platysmaplasty',
                    children: [],
                },
                {
                    id: '1.1.10',
                    title: 'Liposuction Neck/Jawline',
                    children: [],
                },
            ],
        },
        {
            id: '1.2',
            title: 'Body',
            children: [
                {
                    id: '1.2.1',
                    title: 'Arm Lift',
                    children: [],
                },
                {
                    id: '1.2.2',
                    title: 'Tummy Tuck',
                    children: [],
                },
                {
                    id: '1.2.3',
                    title: 'Brazilian Butt Lift',
                    children: [],
                },
            ],
        },
        {
            id: '1.3',
            title: 'Liposuction',
            children: [
                {
                    id: '1.3.1',
                    title: 'Area 1',
                    children: [],
                },
                {
                    id: '1.3.2',
                    title: 'Area 2',
                    children: [],
                },
            ],
        },
        {
            id: '1.4',
            title: 'Breast',
            children: [
                {
                    id: '1.4.1',
                    title: 'Breast Augmentation',
                    children: [],
                },
                {
                    id: '1.4.2',
                    title: 'Breast Lift',
                    children: [],
                },
                {
                    id: '1.4.3',
                    title: 'Breast Reduction',
                    children: [],
                },
            ],
        },
        {
            id: '1.5',
            title: 'Gynecomastia',
            children: [
                {
                    id: '1.5.1',
                    title: 'Type 1',
                    children: [],
                },
                {
                    id: '1.5.2',
                    title: 'Type 2',
                    children: [],
                },
                {
                    id: '1.5.3',
                    title: 'Type 3',
                    children: [],
                },
                {
                    id: '1.5.4',
                    title: 'Type 4',
                    children: [],
                },
                {
                    id: '1.5.5',
                    title: 'Type 5',
                    children: [],
                },
                {
                    id: '1.5.6',
                    title: 'Type 6',
                    children: [],
                },
            ],
        },
        {
            id: '1.6',
            title: 'Rhinoplasty',
            children: [
                {
                    id: '1.6.1',
                    title: 'Rhinoplasty',
                    children: [],
                },
            ],
        },
        {
            id: '1.7',
            title: 'Others',
            children: [],
        },
    ],
};

const minimumColumnWidth = 500;

export {
    componentLibrary, componentLibraryMap, templateLibrary, templateLibraryMap, ProcedureLibrary, minimumColumnWidth,
};
