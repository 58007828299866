import { TimelineContext } from '../context';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useContext } from 'react';

const EmptyProtocolDialog = () => {
    const {
        showEmptyDMDialog,
        setShowEmptyDMDialog,
    } = useContext(TimelineContext);

    const handleClose = () => {
        setShowEmptyDMDialog(false);
    };

    return (
        <div>
            <Dialog
                open={showEmptyDMDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Empty protocol</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        No Protocol available in Protocol Section, Please add atleast one protocol!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Okay.
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
};

export default EmptyProtocolDialog;
