import { MediaEditorProvider, OrgContext } from '../context';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import MediaFolder from './MediaFolder';
import React, { useContext } from 'react';

/**
 * Set the Style for Media
 */
const useStyles = makeStyles(() => createStyles({
    mediaContainer: {
        width: '100%',
        padding: '0px 0px 0px 30px',
    },
    headerFont: {
        fontSize: '25px',
    },
}));

/**
 * This component is used to edit or delete the media
 */
const ManageMedia = ({ showLoader }) => {
    const classes = useStyles();
    const { selectedOrg } = useContext(OrgContext);

    return (
        <div className={classes.mediaContainer}>
            <MediaEditorProvider selectedOrg={selectedOrg}>
                <MediaFolder showLoader={showLoader} />
            </MediaEditorProvider>
        </div>
    );
};

export default ManageMedia;
