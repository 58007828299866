/* eslint-disable max-len */
import { Grid } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions'; // web.cjs is required for IE 11 support
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useEffect, useState } from 'react';
import Slide from '@material-ui/core/Slide';
import SvgIcon from '@material-ui/core/SvgIcon';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';

const typeObj = {
    showTimeline: 'ShowTimeline',
    timeline: 'Timeline',
    column: 'Column',
    progression: 'Progression',
    protocolItem: 'ProtocolItem',
    protocol: 'Protocol',
    imageSupergroup: 'Images/Videos',
    text: 'Text',
};

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

function MinusSquare(props) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
        </SvgIcon>
    );
}

function PlusSquare(props) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
        </SvgIcon>
    );
}

function CloseSquare(props) {
    return (
        <SvgIcon className="close" fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
        </SvgIcon>
    );
}

// eslint-disable-next-line no-unused-vars
const StyledTreeItem = withStyles((theme) => ({
    root: {
        color: 'rgba(0, 0, 0, 0.87)',
        '&:focus': {
            '& div': {
                '&:nth-child(2)': {
                    backgroundColor: 'cornflowerblue !important',
                    color: '#fff !important',
                },
            },
            '& ul': {
                '& div': {
                    '&:nth-child(2)': {
                        '&:hover': {
                            backgroundColor: 'cornflowerblue !important',
                            color: '#fff !important',
                        },
                        backgroundColor: 'transparent !important',
                        color: 'rgba(0, 0, 0, 0.87) !important',
                    },
                },
            },
        },
    },
    label: {
        '&:hover': {
            backgroundColor: 'cornflowerblue !important',
            color: '#fff !important',
        },
    },
    iconContainer: {
        '& .close': {
            opacity: 0.3,
        },
    },
    group: {
        marginLeft: 0,
        paddingLeft: 20,
    },
}))((props) => <TreeItem {...props} />);

const useStyles = makeStyles({
    root: {
        height: 264,
        flexGrow: 1,
        maxWidth: 400,
    },
    textCls: {
        textTransform: 'capitalize',
        borderBottom: '1px solid #cbcbcb',
        padding: '5px 12px 0px 12px',
    },
    dialogCont: {
        padding: '.75rem 1.75rem',
    },
    dialogAction: {
        borderTop: '1px solid #cbcbcb',
        padding: '8px 12px',
        '& :not(:first-child)': {
            marginLeft: '2rem',
        },
    },
    closeIconCont: {
        textAlign: 'right',
        marginTop: 4,
    },
});

export default function CustomizedTreeView(props) {
    const classes = useStyles();
    const [state, setState] = useState({ flag: false, selected: null });
    const {
        open, handleClose, type, handleTypeSubmit, selectedTimeline, isShowLoader = false, showLoader,
    } = props;

    const handleIconClick = () => {
    };

    const handleLabelClick = (e, column, component) => {
        setState({ ...state, selected: component, selectedColumn: column });
    };

    const handleSubmit = () => {
        const componentType = null;
        const reqObj = {
            timeline: state.data,
            column: state.selectedColumn || null,
            component: state.selected || null,
            componentType,
        };
        const { selected } = state;
        if (selected?.columns?.length > 0) {
            reqObj.componentType = typeObj.timeline;
        } else if (selected.type) {
            reqObj.componentType = selected.type;
        } else if (selected === typeObj.progression) {
            reqObj.componentType = typeObj.progression;
            reqObj.component = state.selectedColumn;
        } else if (selected === typeObj.protocolItem) {
            reqObj.componentType = typeObj.protocolItem;
            reqObj.component = state.selectedColumn;
        }
        handleTypeSubmit(reqObj);
    };

    useEffect(() => {
        if (selectedTimeline) {
            setState({ ...state, data: selectedTimeline });
        }
    }, []);

    const renderChildItem = (data, column) => (data?.map((d) => (
        (d.type === typeObj.text || (d.type === typeObj.protocol && d.supergroup === typeObj.imageSupergroup))
                && (
                    <StyledTreeItem nodeId={d.id} label={d.props.title || d.props.text} onLabelClick={(e) => handleLabelClick(e, column, d)}>
                        {(d.props.components?.length > 0 && renderChildItem(d.props.components, column)) || [<div key="stub" />]}
                    </StyledTreeItem>
                )
    )));

    const renderTreeItem = (data) => (
        <StyledTreeItem nodeId={data.id} label={data.name} onLabelClick={(e) => handleLabelClick(e, data, data)}>
            {data.columns?.length > 0 && (
                data.columns.map((d) => (d?.props?.isProfile
                    ? null
                    : (
                        <StyledTreeItem
                            nodeId={d.id}
                            label={d.props.title}
                            onIconClick={(e) => handleIconClick(e, d)}
                            onLabelClick={(e) => handleLabelClick(e, d, d)}
                        >
                            <StyledTreeItem
                                nodeId={`${d.id}-Progression`}
                                label="Progression"
                                onIconClick={(e) => handleIconClick(e, d)}
                                onLabelClick={(e) => handleLabelClick(e, d, 'Progression')}
                            >
                                {renderChildItem(d.props.progressions, d)}
                            </StyledTreeItem>
                            <StyledTreeItem
                                nodeId={`${d.id}-Protocol`}
                                label="Protocol"
                                onIconClick={(e) => handleIconClick(e, d)}
                                onLabelClick={(e) => handleLabelClick(e, d, 'ProtocolItem')}
                            >
                                {renderChildItem(d.props.components, d)}
                            </StyledTreeItem>
                        </StyledTreeItem>
                    )))
            )}
        </StyledTreeItem>
    );

    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                fullWidth
            >
                {isShowLoader
            && showLoader(true)}
                <DialogTitle id="alert-dialog-slide-title" className={classes.textCls}>
                    <Grid container>
                        <Grid item xs={10}>
                            {type}
                            {'\u00A0'}
                            Media
                        </Grid>
                        <Grid item xs={2} className={classes.closeIconCont}><CloseIcon onClick={handleClose} /></Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent classes={{ root: classes.dialogCont }}>
                    <DialogContentText id="alert-dialog-slide-description">
                        <TreeView
                            className={classes.root}
                            defaultExpanded={['1']}
                            defaultCollapseIcon={<MinusSquare />}
                            defaultExpandIcon={<PlusSquare />}
                            defaultEndIcon={<CloseSquare />}
                            selected={state.selected}
                        >

                            {state.data && renderTreeItem(state.data)}
                        </TreeView>
                    </DialogContentText>
                </DialogContent>
                <DialogActions classes={{ root: classes.dialogAction }}>
                    <Button onClick={handleClose} variant="contained">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} variant="contained" color="primary" disabled={!state.selected}>
                        <div>{type}</div>
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
