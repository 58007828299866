import { ADD_TIMELINE_COLUMN, GET_TIMELINE_COLUMN, UPDATE_TIMELINE_COLUMN } from '../../context/Timeline';
import { Grid } from '@material-ui/core';
import { OrgContext, TimelineContext } from '../../context';
import { makeStyles } from '@material-ui/core/styles';
import { minimumColumnWidth } from '../../initialConfig';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from 'react-apollo';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import DataMatrixColumn from '../DataMatrixColumn';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useContext, useEffect } from 'react';

const useStyles = makeStyles(() => ({
    widthSectPart: {
        minWidth: '50%',
        minHeight: '30%',
        maxHeight: '75%',
    },
    headerStyle: {
        borderBottom: '2px solid #CBCBCB',
        padding: '.5rem 1rem',
    },
    alignRight: {
        textAlign: 'right',
    },
    closeIconSTyle: {
        cursor: 'pointer',
        marginTop: '.5rem',
    },
    footerStyle: {
        padding: '.5rem 1rem',
    },
    primaryBtn: {
        maxHeight: '56px',
        padding: '.25rem 1rem',
        boxShadow: '0px 0px 20px -4px rgba(51,36,5,0.37)',
        '&:hover': {
            boxShadow: 'none',
        },
    },
    cancelBtn: {
        maxHeight: '56px',
        padding: '.25rem 1.5rem',
        boxShadow: 'none',
        margin: '0rem 1.5rem',
        '&:hover': {
            boxShadow: 'none',
        },
    },
}));

const ConfigureDialog = (props) => {
    const classes = useStyles();
    const { handleBack, user, showLoader } = props;
    const {
        configureDialog,
        setConfigureDialog,
        selectedTimeline,
        createTimeline,
        insertUTAssociation,
        updateTimeline,
        configData,
        setConfigData,
        found,
    } = useContext(TimelineContext);

    const [insertTCAccess] = useMutation(ADD_TIMELINE_COLUMN, {
        refetchQueries: [{
            query: GET_TIMELINE_COLUMN,
            variables: {
                timeline_id: selectedTimeline?.id,
                user_id: user?.id,
            },
        }],
    });
    const [updateTCAccess] = useMutation(UPDATE_TIMELINE_COLUMN, {
        refetchQueries: [{
            query: GET_TIMELINE_COLUMN,
            variables: {
                timeline_id: selectedTimeline?.id,
                user_id: user?.id,
            },
        }],
    });
    const { data: { timeline_column_access: tcAccessData } = {} } = useQuery(GET_TIMELINE_COLUMN, {
        variables: {
            timeline_id: selectedTimeline?.id,
            user_id: user?.id,
        },
        skip: found,
    });

    const { loggedUserType } = useContext(OrgContext);

    const handleClose = () => {
        setConfigureDialog(false);
    };

    let requiredColumnIndex;
    for (let i = 0, l = selectedTimeline.columns.length; i < l; i += 1) {
        if (selectedTimeline.columns[i].props.components.length > 0) {
            requiredColumnIndex = i;
            break;
        }
    }

    const submitHandler = async () => {
        updateTimeline();

        if (Array.isArray(configData.isUserView)
            && (!configData?.isUserView[0]?.['column_access']
                || configData?.isUserView[0]?.['column_access']?.length === 0)) {
            handleClose();
            return;
        }

        const timelineTemp = JSON.parse(JSON.stringify(selectedTimeline));
        const { name } = timelineTemp;

        if (user?.role === 'Doctor' && configData?.isUserView.length > 0 && configData?.isUserView[0]?.['column_access']?.length > 0) {
            timelineTemp.columns = (configData.isUserView.length > 0
                && selectedTimeline.columns.filter((cl) => configData.isUserView[0].column_access.includes(cl.id)))
                || [];
        } else if (user?.role === 'Patient' || user?.role === 'User') {
            timelineTemp.columns = timelineTemp.columns.filter((col) => (col.props.isUserContent));
        }

        const totalColumns = timelineTemp.columns.length;
        const minTimelineWidth = totalColumns * minimumColumnWidth;
        timelineTemp.props.width = minTimelineWidth;
        const title = `${name}`;
        timelineTemp.name = title;
        timelineTemp.props.name = title;

        if (timelineTemp.columns.length > 0 && loggedUserType === 'Super Admin') {
            const reqObj = {
                shareTimelineFlag: true,
                orgId: user?.['organization_members'][0]?.['organization_id'],
                category: '',
            };
            showLoader(true);
            createTimeline({
                title, timelineTemplate: timelineTemp, category: 'Shared', otherArg: reqObj,
            }).then((timeline) => {
                const userObj = {
                    userId: user.id,
                    orgId: user?.['organization_members'][0]?.['organization_id'],
                    timeline_id: timeline.id,
                };
                insertUTAssociation({ variables: userObj });
                showLoader(false);
                toast.success('Timeline shared successfully');
            });
        }
        if (timelineTemp.columns.length > 0 && (loggedUserType === 'Super Admin' || loggedUserType === 'Doctor')) {
            const timelineColumn = {
                timeline_id: selectedTimeline.id,
                column_access: [...configData?.isUserView[0]?.['column_access']],
                user_id: user.id,
            };

            if (tcAccessData?.length > 0
                && tcAccessData[0].user_id === user.id
                && tcAccessData[0].timeline_id === selectedTimeline.id
                && configData?.isUserView[0]) {
                timelineColumn.id = tcAccessData[0].id;
                updateTCAccess({ variables: timelineColumn });
            } else {
                insertTCAccess({ variables: timelineColumn });
            }

            handleClose();
        } else {
            handleClose();
        }
    };

    useEffect(() => {
        if (tcAccessData) {
            setConfigData({ ...configData, isUserView: JSON.parse(JSON.stringify(tcAccessData)) });
        }
    }, [tcAccessData]);

    useEffect(() => () => {
        setConfigData({});
    }, []);

    return (
        <Dialog onClose={handleClose} open={configureDialog} fullWidth classes={{ paperWidthSm: classes.widthSectPart }}>
            <DialogTitle classes={{ root: classes.headerStyle }}>
                <Grid container justify="space-between" alignItems="center">
                    <Grid item xs={10}>
                        {requiredColumnIndex !== undefined ? '' : 'Empty Protocol'}
                    </Grid>
                    <Grid item xs={2} className={classes.alignRight}>
                        <CloseIcon onClick={handleClose} className={classes.closeIconSTyle} />
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                {requiredColumnIndex !== undefined ? (
                    selectedTimeline?.columns?.length > 0 && (
                        <DataMatrixColumn
                            selectedUser={user}
                            fromConfigureDialog
                            requiredColumnIndex={requiredColumnIndex}
                            showLoader
                            column={selectedTimeline?.columns[requiredColumnIndex]}
                            parent={{ id: selectedTimeline.id, type: 'Timeline', props: { components: selectedTimeline.columns } }}
                            tcAccessData={tcAccessData}
                        />
                    )
                ) : 'No Protocol available in Protocol Section, Please add atleast one protocol!'}
            </DialogContent>
            <DialogActions classes={{ root: classes.footerStyle }}>
                {user && (
                    <Button
                        className={classes.cancelBtn}
                        onClick={handleBack}
                        variant="contained"
                        color="default"
                    >
                        Back
                    </Button>
                )}
                <Button
                    className={classes.primaryBtn}
                    onClick={submitHandler}
                    variant="contained"
                    color="primary"
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfigureDialog;
