import { SortableList } from './SortableList';
import { TimelineContext } from '../context';
import { addCellsForList, findComponent } from '../helper/commonHelper';
import { makeStyles } from '@material-ui/core/styles';
import { v4 as uuid } from 'uuid';
import AddInput from './AddInput';
import React, { useContext, useState } from 'react';

const useStyles = makeStyles(() => ({
    addInputCont: {
        height: 36.5,
    },
}));

const nop = () => { };

const ListEditor = ({
    currentAssociation = null, list, onChange = nop, render, associationData = null,
    associationOptions = [], parentComp, associatedCell, config = null,
}) => {
    const classes = useStyles();
    const [label, setLabel] = useState('');
    const {
        workshopComponent,
        setNewCellAdded,
        complexFormType,
    } = useContext(TimelineContext);
    const onOrderChange = (listArg) => {
        onChange(listArg);
    };

    const addOption = (index) => {
        const newList = Array.from(list);
        const selectComponent = findComponent(workshopComponent?.props?.components, parentComp);
        const selectParentComp = findComponent(workshopComponent?.props?.components, selectComponent?.parent);
        if (selectParentComp?.props?.isComplexForm || selectParentComp?.props?.LRProps) {
            let colComponent = selectParentComp;
            if (selectParentComp.type === 'Cell') {
                colComponent = findComponent(workshopComponent.props.components, selectParentComp.colRef);
            }
            const associatedComponent = {
                label, value: label, id: uuid(), props: {},
            };
            if (typeof (index) === 'number') {
                newList.splice(index + 1, 0, associatedComponent);
                addCellsForList({
                    associatedComponent,
                    selectComp: selectComponent,
                    columnComp: colComponent,
                    workshopComponent,
                    LRProps: colComponent?.props?.LRProps,
                    index,
                });
            } else {
                newList.push(associatedComponent);
                addCellsForList({
                    associatedComponent,
                    selectComp: selectComponent,
                    columnComp: colComponent,
                    workshopComponent,
                    LRProps: colComponent?.props?.LRProps,
                });
            }
        } else if (typeof (index) === 'number') {
            newList.splice(index + 1, 0, { label, value: label });
        } else {
            newList.push({ id: uuid(), label, value: label });
        }
        if (complexFormType === 'POI' && selectParentComp?.type === 'Cell') {
            const requiredParent = findComponent(workshopComponent?.props.components, selectComponent?.associatedParent);
            // eslint-disable-next-line no-unused-vars,
            for (const option of requiredParent?.props?.options) {
                if (option?.isSelect) {
                    option.props.childList = [...newList];
                }
            }
        }
        onChange(newList);
        setLabel('');
        setNewCellAdded((oldValue) => !oldValue);
    };

    const deleteOption = (index) => {
        const selectComponent = findComponent(workshopComponent?.props?.components, parentComp);
        const selectParentComp = findComponent(workshopComponent?.props?.components, selectComponent?.parent);
        const newList = Array.from(list);
        newList.splice(index, 1);
        if (complexFormType === 'POI' && selectParentComp?.type === 'Cell') {
            const requiredParent = findComponent(workshopComponent?.props.components, selectComponent?.associatedParent);
            // eslint-disable-next-line no-unused-vars
            for (const option of requiredParent?.props?.options) {
                if (option?.isSelect) {
                    option.props.childList = [...newList];
                }
            }
        }
        onChange(newList);
    };

    const handleLabelChange = (value) => {
        setLabel(value);
    };

    /**
     * to update the respective cell value
     * @param {*} value
     * @param {*} index
    */
    const updateValueOnEdit = (value, index) => {
        const newList = Array.from(list);
        newList[index].value = value;
        newList[index].label = value;
        onChange(newList);
    };

    return (
        <>
            <SortableList
                associatedCell={associatedCell}
                parentComp={parentComp}
                data={list}
                currentAssociation={currentAssociation}
                render={render}
                onOrderChange={onOrderChange}
                deleteItem={deleteOption}
                associationData={associationData || null}
                associationOptions={associationOptions || []}
                onAdd={addOption}
                config={config}
                updateValueOnEdit={updateValueOnEdit}
            />
            {!(workshopComponent?.component === 'SELECT-COMMPLETED-TEMPLATE' || workshopComponent?.component === 'SELECT-COMPLETE'
                || workshopComponent?.component === 'Select-Complete-Notes' || workshopComponent?.component === 'Select/Text-Complete-Notes') && (
                <div
                    className={classes.addInputCont}
                    style={{
                        borderBottom: (workshopComponent?.component === 'Mixed Form'
                        || workshopComponent?.component === 'LR Mixed Form') ? '.25px solid #cbcbcb' : 'none',
                    }}
                >
                    <AddInput value={label} onChange={handleLabelChange} onAdd={addOption} />
                </div>
            )}
        </>
    );
};

export default ListEditor;
