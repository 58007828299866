const ImageStyle = {
    uploadTitle: {
        fontSize: 22,
        fontWeight: 700,
        paddingTop: 32,
    },
    slctBoxLabel: {
        fontSize: 14,
        fontWeight: 500,
        color: '#333',
        fontFamily: 'Montserrat, sans-serif',
        paddingBottom: 5,
    },
    selctBoxDiv: {
        marginTop: 40,
        marginBottom: 60,
    },
    dropZone: {
        border: '1px solid #ccc',
        borderRadius: 2,
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        cursor: 'pointer',
    },
    dropZoneMsg: {
        display: 'flex',
        justifyContent: 'center',
    },
    UpldImg: {
        height: '120px',
    },
    drpznTxt: {
        fontSize: 16,
        fontWeight: 500,
        color: '#333',
        fontFamily: 'Montserrat, sans-serif',
        marginBottom: 5,
        cursor: 'pointer',
        textAlign: 'center',
    },
    drpznTxtHelper: {
        fontSize: 14,
        fontWeight: 300,
        color: '#333',
        opacity: 0.8,
        fontFamily: 'Montserrat, sans-serif',
        display: 'flex',
        cursor: 'pointer',
        justifyContent: 'center',
    },
    errDiv: {
        display: 'flex',
        justifyContent: 'start',
        fontWeight: 600,
        fontSize: 16,
        marginTop: 10,
    },
    mdleAlign: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    fileName: {
        fontWeight: 600,
        fontSize: '0.8vw',
        color: '#429CD6',
        fontFamily: 'Montserrat, sans-serif',
        marginBottom: '2%',
        marginTop: '2%',
        textDecoration: 'underline',
    },
    errorMsg: {
        marginTop: 8,
        marginBottom: -10,
        color: '#fd0303',
    },
    successMsg: {
        marginTop: 8,
        marginBottom: -10,
        color: '#228B22',
    },
    viewSelectedImage: {
        height: 180,
        width: 180,
    },
};

export default ImageStyle;
