/* eslint-disable no-case-declarations */
import { useMutation, useQuery } from '@apollo/react-hooks';
import React, {
    createContext, useEffect, useState,
} from 'react';
import gql from 'graphql-tag';

/**
 * query to get the recent uploaded media with limit 25
 */
const GET_RECENT_UPLOADED_IMAGE = gql`
    query media($orgID: uuid) {
        media(order_by: {updated_at: desc}, limit: 25,
            where: {organization_id: {_eq: $orgID}, is_delete: {_eq: false}, _or: [{filetype: {_like: "image/%"}}, {filetype: {_like: "video/%"}}]}) {
        id
        filetype
        name
        url
        is_delete
        is_youtube
        created_at
        updated_at
        }
    }
    `;

/**
 * query to add new media into media table
 */
const ADD_RECENT_UPLOAD = gql`
    mutation insertMedia($filetype: [media_insert_input!]!) {
        insert_media(objects: $filetype) {
            affected_rows
        }
    } 
    `;

/**
 * query to get the recent uploaded media
 */
const GET_MEDIA_GALLERY = gql`
query media($orgID: uuid, $orderBy: [media_order_by!]) {
    media(order_by: $orderBy, 
        where: {organization_id: {_eq: $orgID} is_delete: {_eq: false}, _or: [{filetype: {_like: "image/%"}}, {filetype: {_like: "video/%"}}]}) {
    id
    filetype
    name
    url
    is_delete
    is_youtube
    created_at
    updated_at
    }
}
`;

/**
 * query to Update File Name
 */
const UPDATE_FILE_NAME = gql`
    mutation updateFileName($id: uuid, $name: String) {
        update_media(where: {id: {_eq: $id}}, _set: { name: $name } ) {
            affected_rows
            returning {
                id
            }
        }
    } 
    `;

/**
 * query to Update delete status
 */
const UPDATE_DELETE_STATUS = gql`
    mutation updateDeleteStatus($id: uuid!) {
        delete_media_by_pk(id: $id) {
            id
        }
    }  
    `;

/**
 * query to get deleted media
 */
const GET_DELETED_MEDIA = gql`
    query media($orgID: uuid) {
        media(order_by: {updated_at: desc},
            where: {organization_id: {_eq: $orgID}, is_delete: {_eq: true}, _or: [{filetype: {_like: "image/%"}}, {filetype: {_like: "video/%"}}]}) {
        id
        filetype
        name
        url
        is_delete
        is_youtube
        created_at
        updated_at
        }
    }
    `;
/**
 * query to Update File Name
 */
export const UPDATE_MEDIA_FILE = gql`
 mutation updateFileName($id: uuid, $folderId: uuid) {
     update_media(where: {id: {_eq: $id}}, _set: { folder_id: $folderId } ) {
         affected_rows
         returning {
             id
         }
     }
 } 
 `;

export const Context = createContext({});

export const Provider = ({ selectedOrg, children }) => {
    const [recentUploadedContext, setRecentUploadedContext] = useState(null);
    const [mediaGalleryContext, setMediaGalleryContext] = useState(null);
    const [deletedMediaContext, setDeletedMediaContext] = useState(null);
    const [selectedMediaAction, setSelectedMediaAction] = useState(null);
    const orgId = selectedOrg.id;

    /**
     * Execute the query to get the recent uploaded image
     */
    const response = useQuery(GET_RECENT_UPLOADED_IMAGE, { variables: { orgID: orgId } });
    const recentImageLoading = response.loading;
    const recentImages = !recentImageLoading ? response.data.media : undefined;

    /**
     * Execute the query to get the media gallery
     */
    const mediaResponse = useQuery(GET_MEDIA_GALLERY, { variables: { orgID: orgId, orderBy: { updated_at: 'desc' } } });
    const mediaLoading = mediaResponse.loading;
    const mediaData = !mediaLoading ? mediaResponse.data.media : undefined;

    /**
     * Execute the query to get the deleted media
     */
    const deletedMediaresponse = useQuery(GET_DELETED_MEDIA, { variables: { orgID: orgId } });
    const deletedMediaLoading = deletedMediaresponse.loading;
    const deletedMedia = !deletedMediaLoading ? deletedMediaresponse.data.media : undefined;

    useEffect(() => {
        setRecentUploadedContext(recentImages);
        setMediaGalleryContext(mediaData);
    }, [recentImages, mediaData]);

    useEffect(() => {
        setDeletedMediaContext(deletedMedia);
    }, [deletedMedia]);

    const [insertMedia] = useMutation(
        ADD_RECENT_UPLOAD
    );

    const [updateFileName] = useMutation(
        UPDATE_FILE_NAME
    );

    const [updateDeleteStatus] = useMutation(
        UPDATE_DELETE_STATUS
    );

    /**
     * @function getRefetchRecentImage
     * @returns get updated response
     */
    const getRefetchRecentImage = () => response.refetch();

    /**
     * @function refetchMediaGallery
     * @returns get updated response
     */
    const refetchMediaGallery = (sortQuery = '') => mediaResponse.refetch(sortQuery);

    /**
     * @function sortMediaGalleryContext
     * @param {value} sortType
     * @returns get updated response
     */
    const sortMediaGalleryContext = (sortType) => {
        const orderType = (sortType === 'name') ? 'asc' : 'desc';
        const sortQuery = {
            orderBy: { [sortType]: orderType },
        };
        refetchMediaGallery(sortQuery);
    };

    /**
     * @function insertImage
     * @param {object} mediaData
     * @returns get updated response
     */
    const insertImage = (mediaObj) => {
        const result = insertMedia({
            variables: {
                filetype: mediaObj,
            },
        });

        return result.then(() => {
            refetchMediaGallery();
        });
    };

    /**
     * @function updateMediaName
     * @param {string} id [primary key]
     * @param {string} fileName [updated file name]
     * @returns get updated response
     */
    const updateMediaName = (id, fileName) => {
        const result = updateFileName({
            variables: {
                id,
                name: fileName,
            },
        });

        return result.then(() => {
            refetchMediaGallery();
        });
    };

    /**
     * @function updateDeleteStatus
     * @param {string} id [primary key]
     * @param {string} fileName [updated file name]
     * @returns get updated response
     */
    const deleteMedia = (id) => {
        const result = updateDeleteStatus({
            variables: {
                id,
            },
        });

        return result.then(() => {
            refetchMediaGallery();
        });
    };

    const mediaEditorContext = {
        recentUploadedContext,
        insertImage,
        getRefetchRecentImage,
        mediaLoading,
        mediaGalleryContext,
        sortMediaGalleryContext,
        updateMediaName,
        refetchMediaGallery,
        deleteMedia,
        deletedMediaContext,
        selectedMediaAction,
        setSelectedMediaAction,
    };

    /**
     * Pass the value in provider and return
     */
    return <Context.Provider value={mediaEditorContext}>{children}</Context.Provider>;
};

export const { Consumer } = Context;
