import {
    FilterSettings, addCells, buildSettingsTreePage, fetchPatientAuth, findComponent, inheritComponentFunction, setActiveComponent,
} from '../../helper/commonHelper';
import { OrgContext, TimelineContext, WorkshopContext } from '../../context';
import { Settings, makeInlineEditor } from '../Settings';
import { componentWithId } from '../Atoms';
import { copyTemplateLR } from '../../context/util';
import { debounce } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { useRecoilState } from 'recoil';
import CheckboxUI from '@material-ui/core/Checkbox';
import DefaultComponentLayout from '../DefaultComponentLayout';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import React, {
    useCallback, useContext, useEffect, useState,
} from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

/**
 * Set the key and type of the field
 */
const TextProp = {
    key: 'textField',
    type: 'TextField',
    label: 'Text Input',
    id: 'TextInput',
};

/**
 * Set the Style for component
 */
const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '95%',
    },
    formControl1: {
        margin: theme.spacing(0.5),
        minWidth: 'auto',
        width: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },

    buttonGroup: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    textInputWidth: {
        width: '95%',
        '& input': {
            paddingTop: 0,
            paddingBottom: 0,
            height: 30,
            fontSize: 10,
        },
        '& label': {
            marginTop: 2,
            fontSize: 12,
        },
    },
    textFieldContainer: {
        width: '100%',
    },
    columHeadType: {
        fontSize: '0.625rem',
        width: '100%',
        padding: '6px !important',
        borderTop: '1px solid #CBCBCB',
        minHeight: '1.5rem',
    },
    selectedComp: {
        background: 'cornflowerblue',
    },
    formControlText: {
        margin: 0,
        width: '100%',
    },
    formControlRoot: {
        display: 'block',
        margin: 0,
        padding: 0,
    },
    formControlRoot1: {
        marginRight: 4,
        marginLeft: '-1rem',
        width: '100%',
        '& span:nth-child(2)': {
            width: '100%',
        },
    },
    textInputClass: {
        marginRight: 4,
        marginLeft: 0,
        width: '100%',
        '& span': {
            width: '100%',
        },
        '& svg': {
            fontSize: '.75rem',
            marginTop: 2,
            color: '#000000de',
        },
        '& input[type=radio]': {
            accentColor: '#000000de',
            height: '.625rem',
            width: '.625rem',
        },
        '& input[type=radio]:checked': {
            accentColor: '#000000de',
            height: '.75rem',
            width: '.75rem',
        },
    },
    textInputClass1: {
        marginRight: 4,
        marginLeft: 0,
        width: '100%',
        borderBottom: '.25px solid #cbcbcb',
        alignItems: 'start',
        '& span': {
            width: '100%',
        },
        '& svg': {
            fontSize: '.75rem',
            color: '#000000de',
        },
        '& input[type=radio]': {
            accentColor: '#000000de',
            height: '.625rem',
            width: '.625rem',
        },
        '& input[type=radio]:checked': {
            accentColor: '#000000de',
            height: '.75rem',
            width: '.75rem',
        },
    },
    textInputClassWorkshop: {
        marginRight: 4,
        marginLeft: 0,
        width: '100%',
        alignItems: 'center',
        '& input': {
            fontWeight: 400,
            height: 23,
        },
        '& svg': {
            fontSize: '.75rem',
            color: '#000000de',
            marginBottom: 2,
        },
        '& span': {
            width: '100%',
        },
    },
    textInputStyle: {
        height: 23,
        padding: '8px 0px 5px 2px',
        fontWeight: '400',
    },
    fullWithForm: {
        width: '100%',
        paddingBottom: 2.5,
        '& > :first-child': {
            width: '100%',
            marginLeft: '1rem',
        },
        '& :nth-child(2)': {
            display: 'contents',
        },
    },
}));

/**
 * Define the setting's dropdown value
 */
export const TextProps = {
    display: [
        TextProp,
        {
            key: 'textPlaceholder',
            type: 'TextField',
            label: 'Placeholder',
            default: 'Text Input',
            id: 'TextInput',
            isInherited: false,
        },
        {
            key: 'form-control',
            type: 'Radio',
            label: 'Add Checkbox',
            options: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }],
            default: 'no',
            isBasic: true,
            isAdvance: true,
            isAdmin: true,
            id: 'TextInput',
            isInherited: false,
        },
        {
            key: 'read-only',
            type: 'Radio',
            label: 'Read only',
            options: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }],
            default: 'no',
            isBasic: true,
            isAdvance: true,
            isAdmin: true,
            id: 'TextInput',
            isInherited: false,
        },
        {
            key: 'columnNumber',
            type: 'StaticLabel',
            label: 'Column',
            value: '1',
            id: 'TextInput',
            isInherited: false,
        },
    ],
    poi: true,
};

/**
 * @component TextInput
 * @param {string} id
 * @param {object} props
 * @returns {component}
 */
const TextInput = (props) => {
    // debugger
    const classes = useStyles();
    const componentProps = props.item;
    const { componentColumn } = props;
    const { id } = componentProps;

    const [component, setComponentState] = useRecoilState(componentWithId(id));
    const [componentParent] = useRecoilState(componentWithId(componentProps?.parent));
    const [divHeight, setDivHeight] = useState(null);
    const [shouldUpdate, setShouldUpdate] = useState(false);
    const { inWorkshop, compareModal } = useContext(WorkshopContext);
    const {
        settingComponent,
        setSettingComponentHandle,
        setDirtyDomain, domain:
        contextDomain,
        pageComponent,
        dirtyDomain,
        duplicateComponent,
        workshopComponent,
        setWorkshopComponent,
        workshopActiveComponent,
        selectedTemplate,
        handleColumnClick,
        updateTimeline,
        selectedTimeline,
    } = useContext(TimelineContext);
    const { loggedUserType } = useContext(OrgContext);
    const isCF = (workshopComponent?.supergroup === 'Complex Forms' || workshopComponent?.supergroup === 'Form');
    const componentList = [];
    let domain = contextDomain;
    if (componentProps?.previewDomain) {
        domain = componentProps.previewDomain;
    }
    const isPageElem = domain
        && (component.type === 'Page' || component.props.pageChild || (component.name === 'Page' && component.type !== 'Protocol'));
    buildSettingsTreePage(componentList, pageComponent && pageComponent[domain]);
    if (componentProps?.[domain]) {
        component[domain] = componentProps[domain];
    }
    let isSelected = false;

    if (settingComponent && settingComponent.id === id) {
        isSelected = true;
    }
    let isPatient = false;
    if (loggedUserType === 'Patient') isPatient = true;
    const { isPatientWrite = false } = isPatient && componentColumn?.props?.group === 'Column'
        && fetchPatientAuth(loggedUserType, componentColumn);

    if (!component?.parent && componentParent?.props?.components?.length > 0) {
        component.parent = componentParent?.props?.components;
    }

    const handleTextEditorClick = (e) => {
        if (props?.componentColumn?.props?.group === 'Column') {
            handleColumnClick(e, componentColumn);
        }
    };

    useEffect(() => {
        const pageDomain = component[domain];
        if (dirtyDomain) {
            return;
        }
        if (pageDomain && Object.keys(pageDomain).length > 0) {
            const currentComp = componentList.find((c) => c.id === id);
            if (currentComp) {
                currentComp[domain] = pageDomain;
            }
        }
    }, [domain, component, id, dirtyDomain]);

    useEffect(() => {
        setTimeout(() => {
            compHeightChange();
        }, 0);
    }, [workshopActiveComponent?.props?.textField, workshopActiveComponent?.props?.associatedCell, workshopActiveComponent?.props?.options]);

    const compHeightChange = () => {
        if (componentProps?.props?.associatedCell) {
            const id = `${inWorkshop || false}-${componentProps?.props?.associatedCell}`;
            const ele = document.getElementById(id);
            if (ele) {
                setDivHeight(ele.clientHeight);
            }
        }
    };

    const handleUpdateComponent = useCallback(() => {
        if (component.props.textField === component.props.prevTextField) {
            return;
        }
        component.props.prevTextField = component.props.textField;
        const myList = [];
        buildSettingsTreePage(myList, workshopComponent);
        const listParent = myList.find((item) => item.id === componentProps.parent);

        if (listParent?.props?.LRProps) {
            const { LRtype, mainParent, LRparent } = listParent.props.LRProps;
            const parentComp = findComponent(workshopComponent?.props.components, mainParent);
            const compIndex = parentComp?.props.components.findIndex((comp) => comp.id === LRparent);
            const updateIndex = LRtype === 'left' ? compIndex + 1 : compIndex - 1;
            if (LRtype === 'right') {
                return;
            }
            setShouldUpdate(true);
            if (parentComp) {
                parentComp.props.components[compIndex].props.components.forEach((activeComp, i) => {
                    const compToUpdate = parentComp.props.components[updateIndex].props.components[i];
                    if (!compToUpdate) {
                        return;
                    }
                    let LRPropsBak;
                    if (compToUpdate?.props?.LRProps) {
                        LRPropsBak = { ...compToUpdate.props.LRProps };
                    }
                    parentComp.props.components[updateIndex].props.components[i] = copyTemplateLR(activeComp, LRPropsBak, workshopComponent);
                });
            }
        }
    }, [workshopComponent, component, componentProps]);

    useEffect(() => {
        handleUpdateComponent();
    }, [handleUpdateComponent]);

    useEffect(() => {
        if (shouldUpdate) {
            setWorkshopComponent((comp) => ({ ...comp }));
        }
    }, [shouldUpdate, setWorkshopComponent]);

    useEffect(() => {
        setShouldUpdate(false);
    }, [workshopComponent]);

    const parentId = typeof (component?.parent) === 'string' ? component.parent : '';
    const [componentParent2] = useRecoilState(componentWithId(parentId));
    const [componentProtocol] = useRecoilState(componentWithId(component?.props?.protocolId));
    const [componentProp1] = useRecoilState(componentWithId(component?.props?.childOf));
    const [componentProp2] = useRecoilState(componentWithId(componentProp1?.props?.childOf));

    const onChangeOfLabel = (lastComponent) => {
        let hasTextField = false;
        if ((Object.prototype.hasOwnProperty.call(lastComponent, 'currentValue') && lastComponent.currentValue)
            || (Object.prototype.hasOwnProperty.call(lastComponent, 'textField') && lastComponent.props.textField)) {
            hasTextField = true;
        }
        const hasSingleContProp1 = componentProtocol?.props?.hasOwnProperty('single-continuous');

        let singleOrCont = !componentColumn?.props?.isProfile ? componentProtocol?.props?.['single-continuous'] : undefined;
        let hasSingleContProp2;
        if (componentColumn?.props?.isProfile && componentProp2?.props?.isMedicalHistory) {
            hasSingleContProp2 = componentProp2?.props?.hasOwnProperty('single-continuous');
            singleOrCont = componentProp2?.props?.['single-continuous'];
        }
        const parentComponent1 = !parentId ? component?.parent : componentParent2?.parent[0]?.props?.components;
        if (!inWorkshop && (hasSingleContProp1 || hasSingleContProp2) && singleOrCont === 'continuous') {
            duplicateComponent(
                props?.item, parentComponent1,
                component?.props.position - 1, { label: '', textField: '', focused: true }, { name: 'Form', componentType: 'Text Box On Timeline' }
            );
            updateTimeline();
        }
        if (hasTextField && component.parent) {
            const duplicateComp = duplicateComponent(
                component?.parent[component?.parent.length - 1], component?.parent, component?.parent.length - 1, { label: '', focused: true }
            );
            if (inWorkshop) {
                inheritComponentFunction(workshopComponent, duplicateComp, TextProps);
            }
            const parentColumnComp = findComponent(workshopComponent?.props?.components, duplicateComp?.parent);
            if (parentColumnComp?.props?.isComplexForm || parentColumnComp?.props?.LRProps || parentColumnComp?.props?.components) {
                parentColumnComp.props.components.forEach((item, index, arr) => {
                    if (index === arr.length - 1) {
                        item.props.textField = '';
                    }
                    if (!item.props.associatedCell) {
                        addCells({
                            mainComp: parentColumnComp, associatedComponent: item, workshopComponent, LRProps: parentColumnComp.props.LRProps,
                        });
                    }
                });
            }
        } else if (hasTextField && !component.parent) {
            const parent = componentParent.props.components;
            const duplicateComp = duplicateComponent(parent[parent.length - 1], parent, parent.length - 1, { label: '', focused: true });
            if (inWorkshop) {
                inheritComponentFunction(workshopComponent, duplicateComp, TextProps);
            }
            const parentColumnComp = findComponent(workshopComponent?.props?.components, duplicateComp.parent);
            if (parentColumnComp.props.isComplexForm || parentColumnComp.props.LRProps || parentColumnComp.props.components) {
                parentColumnComp.props.components.forEach((item, index, arr) => {
                    if (index === arr.length - 1) {
                        item.props.textField = '';
                    }
                    if (!item.props.associatedCell) {
                        addCells({
                            mainComp: parentColumnComp, associatedComponent: item, workshopComponent, LRProps: parentColumnComp.props.LRProps,
                        });
                    }
                });
            }
        } else if (inWorkshop && workshopComponent?.supergroup === 'Form' && workshopComponent?.component === 'Text Area') {
            const parentComponent1 = !parentId ? component?.parent : componentParent2?.parent[0]?.props?.components;
            const duplicateComp = duplicateComponent(props?.item, parentComponent1, component?.props.position - 1,
                { label: '', textField: '', focused: true });
            inheritComponentFunction(workshopComponent, duplicateComp, TextProps);
        }
    };

    const debounceMixedInputs = debounce(() => {
        updateTimeline();
    }, 1000);

    const handleRadioChange = (controlProp) => {
        for (let i = 0, l = component.parent.length; i < l; i += 1) {
            if (component.parent[i].props[controlProp]) {
                delete component.parent[i].props[controlProp];
            }
        }
        component.props[controlProp] = component.props.label;
        debounceMixedInputs();
    };

    const checkInputHandle = (e) => {
        if (!inWorkshop) {
            component.props.isCBChecked = e.target.checked;
        }
        if (!inWorkshop && !workshopComponent) {
            debounceMixedInputs();
        }
    };

    const renderEditor = () => (
        <div
            className={isSelected ? classes.selectedComp : null}
            id={isSelected ? 'selectedCompId' : ''}
            onClick={(
                event
            ) => {
                event.stopPropagation();
                setActiveComponent(setSettingComponentHandle, componentProps);
                handleTextEditorClick(event);
            }}
            style={{
                height: componentProps?.props?.associatedCell && divHeight !== 0 ? divHeight : 'inherit',
                borderBottom: isCF
                    || (component?.props?.label || componentProps?.type === 'TextInput' && !inWorkshop) ? '.25px solid #cbcbcb' : 'none',
            }}
            aria-hidden="true"
        >
            <FormControlLabel
                control={
                    (component?.props?.['form-control'] === 'checkbox'
                        || component?.props?.['form-control'] === 'yes' || component?.props?.['form-control'] === true) ? (
                            <CheckboxUI
                                onChange={(e) => checkInputHandle(e)}
                                checked={component?.props?.isCBChecked || false}
                                className={classes.checkbox}
                                style={{ width: '5px' }}
                                readOnly={inWorkshop}
                            />
                        ) : (typeof (component?.props?.['form-control']) === 'string' && component?.props?.['form-control']?.endsWith('-radio')) ? (
                            <input
                                type="radio"
                                disabled={((selectedTimeline?.category === 'Super' || selectedTimeline?.category === 'Shared')
                                || (!props?.componentColumn?.props?.isProfile && isPatient && !isPatientWrite))}
                                checked={component.props[component?.props?.['form-control']]}
                                onChange={() => handleRadioChange(component?.props?.['form-control'])}
                                name={`${workshopComponent?.id}-${component?.props?.['form-control']}`}
                            />
                        ) : <></>
                }
                label={makeInlineEditor({
                    field: {
                        ...TextProp,
                        onSubmit: onChangeOfLabel,
                        isNotDebounce: inWorkshop,
                        isDisable: ((selectedTimeline?.category === 'Super' || selectedTimeline?.category === 'Shared')
                            || (!props?.componentColumn?.props?.isProfile && isPatient && !isPatientWrite)),
                    },
                    component,
                    setComponentState,
                    classes,
                    domain,
                    setDirtyDomain,
                    componentProps,
                    inWorkshop,
                })}
                classes={{
                    root: !inWorkshop ? classes.textInputClass
                        : selectedTemplate?.supergroup === 'Form'
                            && (selectedTemplate?.name === 'Text Box- Without Protocol'
                                || selectedTemplate?.name === 'Label - Without Protocol' || selectedTemplate?.name === 'Text Box')
                            ? classes.fullWithForm : componentProps?.type === 'TextInput' ? classes.textInputClassWorkshop : classes.formControlRoot1,
                }}
            />
        </div>
    );


    const renderEditorTimeline = () => {
        if (workshopComponent) {
            let listType;
            const valueToRender = isPageElem ? (component?.[domain]?.textField || '') : (component?.props?.textField || '');
            const requiredIndex = component?.props?.position - 1;
            if (workshopComponent?.isProgression) {
                listType = componentProps?.props?.listTypePreview;
            } else {
                listType = workshopComponent.props?.listTypePreview;
            }
            const bulletHTML = <span>&bull;</span>;
            return (
                <FormControlLabel
                    control={
                        (component?.props?.['form-control'] === 'checkbox'
                            || component?.props?.['form-control'] === 'yes' || component?.props?.['form-control'] === true) ? (
                                <CheckboxUI
                                    className={classes.checkbox}
                                    checked={component?.props?.isCBChecked || false}
                                    onChange={(e) => checkInputHandle(e)}
                                    style={{ width: '5px' }}
                                    disabled={inWorkshop}
                                />
                            ) : (typeof (component?.props?.['form-control']) === 'string' && component?.props?.['form-control']?.
                            endsWith('-radio'))
                                ? (
                                    <input type="radio" name={`${workshopComponent?.id}-${component?.props?.['form-control']}`} />
                                ) : <></>
                    }
                    label={(
                        <Typography
                            color="inherit"
                            style={{
                                height: componentProps?.props?.associatedCell && divHeight !== 0 ? divHeight : 'inherit',
                                minHeight: '31.5px',
                                fontSize: 10,
                                padding: (component?.props?.['form-control'] === 'checkbox'
                                || component?.props?.['form-control'] === 'yes' || component?.props?.['form-control'] === true)
                                    ? '8px 2px' : '8px 0px 8.5px 4px',
                            }}
                            startAdornment={
                                (component?.props?.['form-control'] === 'checkbox'
                                    || component?.props?.['form-control'] === 'yes' || component?.props?.['form-control'] === true) ? (
                                        <CheckboxUI className={classes.checkbox} style={{ width: '5px' }} />
                                    ) : (typeof (component?.props?.['form-control']) === 'string'
                                        && component?.props?.['form-control']?.endsWith('-radio')) ? (
                                            <input type="radio" name={`${workshopComponent?.id}-${component?.props?.['form-control']}`} />
                                        ) : <></>
                            }
                        >
                            {listType === 'bullet' && `${bulletHTML?.props?.children} ${valueToRender}`
                                || (listType === 'number' && `${requiredIndex || 1}. ${valueToRender}`) || valueToRender}
                        </Typography>
                    )}
                    classes={{
                        root: !inWorkshop ? classes.textInputClass1
                            : selectedTemplate?.supergroup === 'Form'
                                && (selectedTemplate?.name === 'Text Box- Without Protocol'
                                    || selectedTemplate?.name === 'Label - Without Protocol' || selectedTemplate?.name === 'Text Box')
                                ? classes.fullWithForm : componentProps?.type === 'TextInput' ? classes.textInputClass : classes.formControlRoot1,
                    }}
                />
            );
        }
        return renderEditor();
    };

    if (componentProps.props.hideFromWorkshop) return null;
    return (
        <DefaultComponentLayout component={component} id={id}>
            {(inWorkshop && !compareModal) ? renderEditor() : renderEditorTimeline()}
        </DefaultComponentLayout>
    );
};

/**
 * @component TextInputPreview [preview text input]
 * @param {string} id
 * @param {object} props
 * @returns {component}
 */
const TextInputPreview = ({ component }) => {
    const classes = useStyles();
    return (
        <DefaultComponentLayout preview component={component}>
            <div className={classes.textFieldContainer}>
                <TextField
                    id="outlined-basic"
                    className={classes.textInputWidth}
                    disabled
                    size="small"
                    margin="dense"
                    label={component.props.label ? component.props.label : 'Text Input'}
                    value={component.props?.textField}
                    variant="outlined"
                />
            </div>
        </DefaultComponentLayout>
    );
};

/**
 * @component TextInputEditor [render the settings]
 * @param {string} config
 * @param {function} updateComponent
 * @returns {component}
 */
const TextInputEditor = ({ config, updateComponent }) => {
    const [component, setComponentState] = useRecoilState(componentWithId(config.id));
    const { settingMenuType } = useContext(TimelineContext);
    const filterTextProps = { display: FilterSettings(TextProps, settingMenuType), poi: TextProps.poi };

    return (
        <Settings editorProps={filterTextProps} component={component} setComponentState={setComponentState} saveComponent={updateComponent} />
    );
};

export { TextInput, TextInputEditor, TextInputPreview };
