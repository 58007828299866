/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import {
    FilterSettings, addCells, buildSettingsTreePage, fetchPatientAuth, findColumnCF,
    findComponent, findPage, getSelectionColors, inheritComponentFunction, setActiveComponent, wowOnEnterFn,
} from '../../helper/commonHelper';
import {
    OrgContext, TimelineContext, UniversalContext, WorkshopContext,
} from '../../context';
import { Settings, makeInlineEditor } from '../Settings';
import { Input, Typography } from '@material-ui/core';
import { componentWithId, updateComponentCallback } from '../Atoms';
import { copyTemplateLR } from '../../context/util';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import { useRecoilCallback, useRecoilState } from 'recoil';
import { v4 as uuid } from 'uuid';
import CheckboxUI from '@material-ui/core/Checkbox';
import CloseIcon from '@material-ui/icons/Close';
import DefaultComponentLayout from '../DefaultComponentLayout';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React, {
    useCallback, useContext, useEffect, useRef, useState,
} from 'react';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
    formControl: {
        width: '100%',
    },
    checkbox: {
        padding: '1px 2px 2px 0px',
        '& svg': {
            height: '0.75rem',
            width: '0.75rem',
            marginBottom: 1.25,
            color: '#000000de',
        },
        margin: '0rem 0rem 0rem .75rem',
    },
    checkbox1: {
        padding: '0px 1px 0px 2px',
        '& svg': {
            height: '0.75rem',
            width: '0.75rem',
            marginRight: 2,
        },
        margin: '0rem',
        pointerEvents: 'auto',
    },
    textBox: {
        width: 'auto',
        margin: '0px 3px 0px 0px',
        alignItems: 'start',
        pointerEvents: 'none',
        '& span': {
            color: '#000000de !important',
            paddingLeft: 2,
        },
    },
    labels: {
        '& span': {
            fontSize: '.625rem',
            lineHeight: '13px',
            fontWeight: '400',
        },
        width: '85%',
    },
    labelsPrev: {
        '& span': {
            fontSize: '.625rem',
        },
        width: '85%',
    },
    labels1: {
        '& span': {
            fontSize: '.625rem',
            paddingLeft: 4,
        },
        width: '99%',
        height: 36,
        margin: 'auto 0px',
    },
    labelWorkshop: {
        '& span': {
            fontSize: '.625rem',
        },
        width: '99%',
        height: 36,
        marginRight: 0,
    },
    labelWorkshopMultiline: {
        '& span': {
            fontSize: '.625rem',
        },
        width: '99%',
        height: 51,
        marginRight: 0,
    },
    labelsTextInput: {
        '& span': {
            fontSize: '.625rem',
        },
        '& input': {
            fontWeight: 400,
            padding: 0,
        },
        '& > :first-child': {
            marginLeft: 2,
            paddingTop: 2.25,
        },
        '& > :last-child': {
            paddingTop: 2,
        },
        width: '99%',
        marginRight: 0,
        padding: '3px 4px 3px 12px',
    },
    labelCont: {
        paddingTop: 4,
    },
    checkboxRoot: {
        width: 'auto',
        margin: '0px 3px 0px 0px',
        alignItems: 'start',
        pointerEvents: 'none',
        '& span': {
            color: '#000000de !important',
        },
    },
    checkboxTextRoot: {
        width: 'auto',
        margin: '0px 3px 0px 0px',
        alignItems: 'start',
        pointerEvents: 'none',
        '& > :first-child': {
            color: '#000000de !important',
            paddingLeft: 2,
        },
    },
    marginLeft0: {
        marginLeft: '0',
    },
    checkboxRoot1: {
        alignItems: 'baseline',
    },
    textInputSize: {
        width: '100%',
        '& div': {
            width: '100%',
        },
    },
    selectedComp: {
        background: 'cornflowerblue',
    },
    pd0: {
        padding: 0,
    },
    firstCol: {
    },
    secondCol: {
        background: '#718FF65C',
    },
    normalText: {
        color: 'inherit',
        textDecoration: 'none',
        cursor: 'text',
    },
    anchorTag: {
        color: '#066785',
        textDecoration: 'none',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    redBg: {
        backgroundColor: '#ff8a8a',
    },
    greenBg: {
        backgroundColor: '#8cf98c',
    },
    checkboxMain: {
        overflow: 'hidden',
        overflowY: 'auto',
        transition: '.5s',
        display: 'flex',
        alignItems: 'start',
        padding: '5px 0px 2.395px 0px',
        '&::-webkit-scrollbar': {
            display:
                'none',
        },
        '&:hover': {
            '&::-webkit-scrollbar': {
                height: '2.5px',
                width: '2.5px',
                display: 'block',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#888',
            },
        },
    },
    dummyHeight: {
        '& span': {
            '&:last-child': {
                height: '16.11px',
                display: 'flex',
            },
        },
    },
    closeIconStyle: {
        fontSize: '.75rem',
        cursor: 'pointer',
    },
    settingHeading: {
        margin: 0,
        padding: 2,
        background: '#efefef',
        border: '1px solid #ececec',
        borderBottom: 'none',
        fontSize: 13,
    },
    timeline_input: {
        '&.Mui-disabled': {
            color: 'black',
        }
    },
}));

const constData = {
    selectSuggest: 'select-suggest',
};

const Checkbox = ({
    componentProps,
    componentParent2,
    componentIndex2,
    componentColumn,
    complexFormColumnIndex,
    renderAsTextArea,
    renderAsTextBox,
}) => {
    const classes = useStyles();
    const [checkboxLabel, setCheckboxLabel] = useState(componentProps?.props?.label);
    const checkboxLabelRef = useRef(null);
    const [checkboxLabelContextMenu, setCheckboxLabelContextMenu] = useState(null);
    const [showCloseIcon, setShowCloseIcon] = useState(false);
    const { id, props: { label, hyperlink } } = componentProps;
    const [component, setComponentState] = useRecoilState(componentWithId(id));
    const [componentParent] = useRecoilState(componentWithId(componentProps.parent));
    const [associatedParent] = useRecoilState(componentWithId(componentProps?.associatedParent));
    const [rowComp] = useRecoilState(componentWithId(componentProps.rowRef));
    const [colComp] = useRecoilState(componentWithId(componentProps.colRef));
    const divHeightRef = useRef(null);
    const [, setDivHeightView] = useState(null);
    const { inWorkshop, compareModal } = useContext(WorkshopContext);
    const [addOptionContextMenu, setAddOptionContextMenu] = useState(null);
    const [shouldUpdate, setShouldUpdate] = useState(false);
    const [renderAsTextBoxPlaceHolder, setRenderAsTextBoxPlaceHolder] = useState(false);
    const [protocolComponent] = useRecoilState(componentWithId(componentProps.props.protocolId));
    useRecoilCallback(updateComponentCallback);
    const {
        duplicateComponent, showDeletionDialog, settingComponent, setSettingComponentHandle, domain, setDirtyDomain, pageComponent, dirtyDomain,
        workshopActiveComponent, setWorkshopComponent, workshopComponent, setSelectCompCF, selectedComponent,
        updateTimeline,
        handleContextMenu,
        hyperlinkCip,
        setHyperlinkCip,
        setHyperlinkCipText,
        setHyperlinkComponent,
        setUpdateFlag,
        updateFlag,
        activeSelectSuggestParent,
        setActiveSelectSuggestParent,
        activeSelectSuggestData,
        setActiveSelectSuggestData,
        selectedTimeline,
        selectedChildren,
        complexFormType,
        handleColumnClick,
        LRUpdate,
        setLRUpdate,
        universalCompState,
        universalScndState,
        setSingleOrCont,
        singleOrContinuous,
        setGlobalAssociationState,
        globalAssociationState,
        setActiveRow,
        isEditMode,
    } = useContext(TimelineContext);

    const {
        handleManualSelection, handleSingleClick,
    } = useContext(UniversalContext);

    const firstCol = rowComp?.props?.components?.find(c => c?.props?.index === 0);
    const [isMultiline, setIsMultiLine] = useState(false);
    useEffect(() => {
        if (firstCol?.props?.multiline_textArea === 'yes') {
            setIsMultiLine(true);
        } else {
            setIsMultiLine(false);
        }
    }, [firstCol?.props?.multiline_textArea]);

    if (universalCompState?.parent
        && componentProps?.props?.highlightAssociation?.parentAssociation?.length > 0) {
        let parentIndex = -1;
        const associatedId = universalCompState?.parent.type !== 'Select' ? universalCompState.parent.id : universalCompState.option.id;
        parentIndex = componentProps.props.highlightAssociation.parentAssociation.findIndex((c) => c === associatedId);
        componentProps.props.highlightComponent = parentIndex !== -1 ? true : !!componentProps.props.highlightComponent;
    }

    // useEffect(() => {
    //     if (LRUpdate?.id && LRUpdate?.id === componentProps?.mirrorId) {
    //         componentProps.props.label = LRUpdate?.label;
    //         setLRUpdate({});
    //         updateTimeline();
    //     }
    // }, [LRUpdate]);

    useEffect(() => {
        const workshopList = [];
        buildSettingsTreePage(workshopList, rowComp);
        const similarTlComp = workshopList?.filter((item) => item.id === id);
        // eslint-disable-next-line no-return-assign
        similarTlComp.map((item) => item.props.label = componentProps?.props?.label);
        setUpdateFlag(!updateFlag);
    }, [componentProps?.props?.label]);

    const { loggedUserType } = useContext(OrgContext);
    const isSuperAdmin = loggedUserType === 'Super Admin';

    let isPatient = false;
    if (loggedUserType === 'Patient') isPatient = true;
    const { isPatientWrite = false } = isPatient && componentColumn?.props?.group === 'Column'
        && fetchPatientAuth(loggedUserType, componentColumn);

    if (complexFormType === 'Select and Suggest' && workshopComponent?.props?.isLR && component.props.label !== componentProps.props.label) {
        component.props.label = componentProps.props.label;
    }
    if (complexFormType === 'Select and Suggest' && workshopComponent?.props?.isLR && componentProps?.props?.['select-suggest']) {
        component.props['select-suggest'] = componentProps.props['select-suggest'];
    }
    if (complexFormType === 'Select and Suggest' && workshopComponent?.props?.isLR && !component?.props?.protocolId) {
        component.props.protocolId = workshopComponent?.id;
    }
    const isCF = (workshopComponent?.supergroup || selectedComponent?.supergroup) === 'Complex Forms';
    let isSelected = false;
    if (componentProps[domain]) {
        component[domain] = componentProps[domain];
    }
    if (settingComponent && settingComponent.id === id) {
        isSelected = true;
    }
    const componentList = [];
    buildSettingsTreePage(componentList, pageComponent && pageComponent[domain]);
    let selectSuggestColor = null;

    let isUniversalManual = false;
    if (workshopComponent?.props?.isUniversal) {
        isUniversalManual = workshopComponent.props.pcRelationship === 'manual'
            || componentParent2?.components?.[componentIndex2]?.props?.pcRelationship === 'manual';
    }

    if (activeSelectSuggestParent?.props[constData.selectSuggest]) {
        selectSuggestColor = activeSelectSuggestParent?.props[constData.selectSuggest][id];
    }

    const handleTextEditorClick = (e) => {
        if (componentColumn?.props?.group === 'Column') {
            handleColumnClick(e, componentColumn);
        }
    };

    useEffect(() => {
        if (component?.props?.cfType === 'Select and Suggest' && complexFormColumnIndex === 0 && activeSelectSuggestParent) {
            for (let i = 0, l = selectedChildren?.['selected_children']?.length; i < l; i += 1) {
                if (activeSelectSuggestParent?.props?.protocolId === selectedChildren?.['selected_children']?.[i]?.['protocol_id']) {
                    const requiredData = selectedChildren?.['selected_children']?.[i];
                    setActiveSelectSuggestData({
                        id: requiredData?.id,
                        timelineId: requiredData?.['timeline_id'],
                        columnId: requiredData?.['column_id'],
                        protocolId: requiredData?.['protocol_id'],
                        childrenData: { ...requiredData?.['children_data'] },
                    });
                    break;
                }
            }
        }
    }, [activeSelectSuggestParent]);

    useEffect(() => {
        // Temporary until we migrate all the timelines. Must manualy save timeline for this to take effect
        if (component.props.checkboxes && component.props.checkboxes.length > 0) {
            const temp = { ...component };
            temp.props.label = component.props.checkboxes[0].label;
            delete temp.props.checkboxes;
            setComponentState(temp);
        }
    }, []);
    useEffect(() => {
        const pageDomain = component[domain];

        if (dirtyDomain) {
            return;
        }
        if (pageDomain && Object.keys(pageDomain).length > 0) {
            const currentComp = componentList.find((c) => c.id === id);
            if (currentComp) {
                currentComp[domain] = pageDomain;
            }
        }
    }, [domain, component, id, dirtyDomain]);
    /** Height */
    useEffect(() => {
        if (component.props.updatedHeight && componentParent2?.components?.[componentIndex2]?.component
            !== 'LR Mixed Form' && colComp.props.pcRelationship !== 'manual') {
            // #PR1677 only update when in workshop as in previous version setDivHeight was only updating workshop element
            if (inWorkshop) {
                divHeightRef.current.style.height = `${component.props.updatedHeight}px`;
            }
            setDivHeightView(component.props.updatedHeightView);
            setComponentState((value) => ({ ...value, props: { ...value.props, updatedHeight: undefined } }));
        }
    }, [component?.props?.updatedHeight]);

    const isTextBox3ColCF = rowComp?.parent?.[0]?.props?.components?.every((i) => i?.props?.components?.[0]?.type === 'TextBoxCF'
        || i?.props?.components?.[0]?.props?.components?.[0]?.type === 'TextBoxCF');

    const findTextBoxComp = (comp) => {
        if (comp?.type === 'Row') {
            if (componentParent2?.components?.[componentIndex2]?.name === 'Complex Forms'
            && !componentParent2?.components?.[componentIndex2]?.props?.leftRightColumn
            && isTextBox3ColCF) {
                comp.props.components.map((i) => {
                    if ((i?.compIndex === 1) || (i?.compIndex === 2)) {
                        i.props.components.map((j) => {
                            const placeHolder = i?.props.components?.[0]?.props?.components?.[0]?.props?.textPlaceholder;
                            j.compIndex = i?.compIndex;
                            if ((j?.type === 'EmptyCell' || j?.type === 'Cell') && (j?.props?.components?.length === 0)) {
                                const uniqueId = uuid();
                                const compProtocolId = i?.props?.protocolId;
                                const newObj = {
                                    action: {
                                        component:
                                        {
                                            header: 'Text Box',
                                            id: 'cl-45',
                                            name: 'Textbox',
                                            type: 'TextBoxCF',
                                        },
                                        relation: 'child',
                                        type: 'insert',
                                    },
                                    id: uniqueId,
                                    associatedParent: j.props?.associatedComponent,
                                    colRef: j?.colRef,
                                    parent: j.id,
                                    compIndex: j?.compIndex,
                                    props: {
                                        label: '',
                                        childOf: j.id,
                                        currentAssociation: '',
                                        disabledChildren: [],
                                        highlightedChildren: [],
                                        isHighlight: false,
                                        selectId: uniqueId,
                                        prevLabel: '',
                                        options: [{ label: '', value: '' }],
                                        protocolId: compProtocolId,
                                        textPlaceholder: placeHolder,
                                    },
                                    rowRef: j?.rowRef,
                                    type: 'TextBoxCF',
                                };
                                j.props.components.push(newObj);
                                j.type = 'Cell';
                                return j;
                            }
                        });
                        return i;
                    }
                });
            }
        }
    };

    if (componentProps?.type === 'TextBoxCF' && renderAsTextBox && isTextBox3ColCF && renderAsTextBoxPlaceHolder
        && (componentParent2?.components?.[componentIndex2]?.name === 'Complex Forms'
        && !componentParent2?.components?.[componentIndex2]?.props?.leftRightColumn)
        && componentParent2?.components?.[componentIndex2]?.props?.pcRelationship !== 'manual' && !inWorkshop) {
        findTextBoxComp(rowComp?.parent[0]);
    }
    const wowTemplates = ['Select/Text-Complete-Notes', 'SELECT-COMPLETED', 'Select-Complete-Notes', 'SELECT-COMMPLETED-TEMPLATE'];

    const onChangeOfLabel = () => {
        let protocolComp = wowTemplates.includes(protocolComponent?.component)
            ? protocolComponent : Array.isArray(protocolComponent?.parent)
            && protocolComponent?.parent?.find((c) => c.id === colComp?.props?.protocolId);
        if (protocolComp === undefined && inWorkshop && (wowTemplates.includes(workshopComponent?.component))) {
            protocolComp = workshopComponent;
        }
        if (!inWorkshop && wowTemplates.includes(protocolComp?.component)) {
            const firstColEl = rowComp?.props?.components?.find((c) => c.props.index === 0);
            if (firstColEl?.props?.components[0]?.props?.['single-continuous'] === 'single') return;
        }
        if ((wowTemplates.includes(protocolComp?.component)) && componentProps?.props?.label.length === 0) {
            toast.info('Add Value in Checkbox');
            return;
        }
        if ((protocolComp?.component === 'Select-Complete-Notes'
        || protocolComp?.component === 'Select/Text-Complete-Notes') && componentProps?.type === 'TextAreaCF' && complexFormColumnIndex !== 0) return;
        let singleOrCont = null;
        if (componentColumn?.props?.components.length > 0 && !wowTemplates.includes(protocolComp?.component)) {
            const isIndex = componentParent2.components.findIndex((element) => element.id === componentProps.props.protocolId);
            let counter = 0;
            if (isIndex === 0 || isIndex !== -1) {
                singleOrCont = componentParent2.components[isIndex].props['single-continuous'];
                setSingleOrCont(componentProps.props.associatedCellTimeline);
                counter += 1;
            }
            const data = singleOrContinuous === componentProps.props.associatedCellTimeline;
            if (data && counter === 0) {
                singleOrCont = 'continuous';
            }
        }
        if (workshopComponent?.props?.isSimpleForm) {
            const parent = rowComp.props.components[0].props.components;
            if (componentProps.props.label) {
                for (let i = 0; i < parent.length; i += 1) {
                    if (parent[i].props?.focused) {
                        parent[i].props.focused = false;
                    }
                }
                const compFindIndex = rowComp.props.components[0].props.components.findIndex((p) => p.id === componentProps.id);
                const duplicateComp = duplicateComponent(parent[compFindIndex], parent, compFindIndex, { label: '', focused: true });
                setActiveComponent(setSettingComponentHandle, duplicateComp);
                setUpdateFlag(!updateFlag);
            }
            return;
        }
        // preventing to add options between two components in 1st col
        let compAddIndex = null;
        let compFindIndex = null;
        const compParent = componentParent?.props?.components;
        compFindIndex = Array.isArray(compParent) && compParent.findIndex((p) => p.id === id);
        compAddIndex = compFindIndex !== null && compFindIndex !== -1 ? compFindIndex : compParent?.length - 1;
        if (!inWorkshop && singleOrCont && singleOrCont === 'continuous' && compParent && !wowTemplates.includes(protocolComp?.component)) {
            const parent = rowComp.props.components[0].props.components;
            compFindIndex = rowComp.props.components[0].props.components.findIndex((p) => p.id === componentProps.id);
            if (componentProps?.props?.label) {
                // removing the earlier focused from the previous component
                for (let i = 0; i < parent.length; i += 1) {
                    if (parent[i].props?.focused) {
                        parent[i].props.focused = false;
                    }
                }
                const duplicateComp = duplicateComponent(
                    parent[compFindIndex], parent, compFindIndex, { label: '', focused: true }
                );
                setUpdateFlag(!updateFlag);
                setActiveComponent(setSettingComponentHandle, duplicateComp);
            }
            return;
        }
        if ((((colComp?.props?.components?.length - 1) > colComp?.props?.components
            .findIndex((i) => i?.id === componentProps?.id)) && colComp?.props?.pcRelationship === 'manual')
            && colComp?.props?.index === 0) {
            return;
        }

        const currentCellComp = colComp?.props?.components.find((i) => i?.id === componentProps?.parent);
        const currentCompIndex = currentCellComp?.props?.components.findIndex((i) => i?.id === componentProps?.id);
        if (((currentCellComp?.props?.components?.length - 1) > currentCompIndex) && colComp?.props?.index > 0
        && colComp?.props?.pcRelationship === 'manual') {
            return;
        }

        if (colComp?.props?.components?.[0]?.compIndex !== 0 && renderAsTextBox
            && isTextBox3ColCF && componentParent2?.components?.[componentIndex2]?.name === 'Complex Forms'
            && !componentParent2?.components?.[componentIndex2]?.props?.leftRightColumn
            && componentParent2?.components?.[componentIndex2]?.props?.pcRelationship !== 'standard' && !inWorkshop
            || (componentParent2?.components?.[componentIndex2]?.props?.pcRelationship === 'manual' && !inWorkshop)) {
            return;
        }

        setRenderAsTextBoxPlaceHolder(true);
        let hasLabel = false;
        let lastCheckbox = compFindIndex !== null && compFindIndex !== -1 && (component.parent || componentParent.props.components[compAddIndex]);
        if (component.parent && compFindIndex !== null && compFindIndex !== -1 && Array.isArray(component.parent)) {
            lastCheckbox = component.parent[compAddIndex];
        }
        if ((component?.currentValue && workshopActiveComponent?.id === lastCheckbox?.id)
            || (lastCheckbox?.hasOwnProperty('currentValue') && lastCheckbox?.currentValue)
            || (lastCheckbox?.props?.hasOwnProperty('label') && (lastCheckbox?.props?.label || lastCheckbox?.props?.label === ''))) {
            hasLabel = true;
        }
        if ( component.parent && (
            wowTemplates.includes(protocolComp?.component))) {
            const duplicateComp = duplicateComponent(
                component.parent[compAddIndex], component.parent, compAddIndex, { label: '', focused: true }
            );
            // wow main Function
            wowOnEnterFn(protocolComp, duplicateComp, rowComp, compAddIndex, setUpdateFlag, updateFlag);
            if (renderAsTextArea) {
                inheritComponentFunction(workshopComponent, duplicateComp, TextAreaProps);
            } else if (renderAsTextBox) {
                inheritComponentFunction(workshopComponent, duplicateComp, TextBoxProps);
            }
            const parentColumnComp = findComponent(workshopComponent
                ? workshopComponent?.props?.components : componentColumn?.props?.components?.[componentIndex2]?.props?.components,
            duplicateComp.parent);
            let colCompEle = parentColumnComp;
            if (parentColumnComp?.type === 'Cell') {
                colCompEle = findComponent(workshopComponent
                    ? workshopComponent?.props?.components : componentColumn?.props?.components?.[componentIndex2]?.props?.components,
                parentColumnComp.colRef);
            }

            if (parentColumnComp?.props?.components?.length > 0) {
                parentColumnComp.props.components.forEach((c) => {
                    if (c.id !== duplicateComp.id && c.props.focused) {
                        delete c.props.focused;
                    }
                });
            }
            if (colCompEle?.props?.isComplexForm || colCompEle?.props?.LRProps) {
                parentColumnComp.props.components.forEach((item) => {
                    if (!item.props.associatedCell && !(wowTemplates.includes(protocolComp?.component))) {
                        addCells({
                            mainComp: parentColumnComp,
                            associatedComponent: item,
                            workshopComponent: workshopComponent || componentColumn?.props?.components?.[componentIndex2],
                            LRProps: parentColumnComp.props.LRProps,
                            isCell: parentColumnComp.type === 'Cell',
                            cellIndex: compFindIndex,
                        });
                    }
                });
            }
            setActiveComponent(setSettingComponentHandle, duplicateComp);
        } else if (!component.parent && (
            wowTemplates.includes(protocolComp?.component))) {
            const parent = componentParent.props.components;
            const duplicateComp = duplicateComponent(parent[compAddIndex], parent, compAddIndex, { label: '', focused: true });
            // wow main Function
            wowOnEnterFn(protocolComp, duplicateComp, rowComp, compAddIndex, setUpdateFlag, updateFlag);
            if (renderAsTextArea) {
                inheritComponentFunction(workshopComponent, duplicateComp, TextAreaProps);
            } else if (renderAsTextBox) {
                inheritComponentFunction(workshopComponent, duplicateComp, TextBoxProps);
            }
            const parentColumnComp = findComponent(workshopComponent
                ? workshopComponent?.props?.components : componentColumn?.props?.components?.[componentIndex2]?.props?.components,
            duplicateComp.parent);
                // We are in the second row, need to get the column from the colRef prop
            let colComp = parentColumnComp;
            if (parentColumnComp?.type === 'Cell') {
                colComp = findComponent(workshopComponent
                    ? workshopComponent?.props?.components : componentColumn?.props?.components?.[componentIndex2]?.props?.components,
                parentColumnComp.colRef);
            }

            if (parentColumnComp.props.components.length > 0) {
                parentColumnComp.props.components.forEach((c) => {
                    if (c.id !== duplicateComp.id && c.props.focused) {
                        delete c.props.focused;
                    }
                });
            }

            if (colComp?.props?.isComplexForm || colComp?.props?.LRProps) {
                parentColumnComp.props.components.forEach((item) => {
                    if (!item.props.associatedCell && !(wowTemplates.includes(protocolComp?.component))) {
                        addCells({
                            mainComp: parentColumnComp,
                            associatedComponent: item,
                            workshopComponent: workshopComponent || componentColumn?.props?.components?.[componentIndex2],
                            LRProps: parentColumnComp.props.LRProps,
                            isCell: parentColumnComp.type === 'Cell',
                            cellIndex: compFindIndex,
                        });
                    }
                });
            }
            setActiveComponent(setSettingComponentHandle, duplicateComp);
        }
        if (isEditMode && (protocolComp?.component === 'Universal LR CF' || workshopComponent?.component === 'Universal LR CF')) {
            const workshopList = [];
            buildSettingsTreePage(workshopList, workshopComponent);
            const componentParentArray = Array.isArray(component.parent) ? component.parent : compParent;
            const mirrorCell = workshopList?.find((c) => c?.mirrorId === componentParentArray?.[compAddIndex]?.parent);
            if (!mirrorCell) return;
            const duplicateComp = duplicateComponent(
                componentParentArray[compAddIndex], componentParentArray, compAddIndex, { label: '', focused: true }
            );
            if (renderAsTextArea) {
                inheritComponentFunction(workshopComponent, duplicateComp, TextAreaProps);
            } else if (renderAsTextBox) {
                inheritComponentFunction(workshopComponent, duplicateComp, TextBoxProps);
            }
            const parentColumnComp = findComponent(workshopComponent
                ? workshopComponent?.props?.components : componentColumn?.props?.components?.[componentIndex2]?.props?.components,
            duplicateComp.parent);
            let colCompEle = parentColumnComp;
            if (parentColumnComp?.type === 'Cell') {
                colCompEle = findComponent(workshopComponent
                    ? workshopComponent?.props?.components : componentColumn?.props?.components?.[componentIndex2]?.props?.components,
                parentColumnComp.colRef);
            }

            if (parentColumnComp?.props?.components?.length > 0) {
                parentColumnComp.props.components.forEach((c) => {
                    if (c.id !== duplicateComp.id && c.props.focused) {
                        delete c.props.focused;
                    }
                });
            }
            if (colCompEle?.props?.isComplexForm || colCompEle?.props?.LRProps) {
                parentColumnComp.props.components.forEach((item) => {
                    if (!item.props.associatedCell) {
                        addCells({
                            mainComp: parentColumnComp,
                            associatedComponent: item,
                            workshopComponent: workshopComponent || componentColumn?.props?.components?.[componentIndex2],
                            LRProps: parentColumnComp.props.LRProps,
                            isCell: parentColumnComp.type === 'Cell',
                            cellIndex: compFindIndex,
                        });
                    }
                });
            }
            setActiveComponent(setSettingComponentHandle, duplicateComp);
            // mirror cell addition
            const mirrorDuplicateComp = duplicateComponent(
                mirrorCell?.props?.components?.[compAddIndex], mirrorCell?.props?.components, compAddIndex, { label: '', focused: true }
            );
            if (renderAsTextArea) {
                inheritComponentFunction(workshopComponent, mirrorDuplicateComp, TextAreaProps);
            } else if (renderAsTextBox) {
                inheritComponentFunction(workshopComponent, mirrorDuplicateComp, TextBoxProps);
            }
            const mirrorParentColumnComp = findComponent(workshopComponent
                ? workshopComponent?.props?.components : componentColumn?.props?.components?.[componentIndex2]?.props?.components,
            mirrorDuplicateComp.parent);
            let mirrorColCompEle = mirrorParentColumnComp;
            if (mirrorParentColumnComp?.type === 'Cell') {
                mirrorColCompEle = findComponent(workshopComponent
                    ? workshopComponent?.props?.components : componentColumn?.props?.components?.[componentIndex2]?.props?.components,
                mirrorParentColumnComp.colRef);
            }

            if (mirrorParentColumnComp?.props?.components?.length > 0) {
                mirrorParentColumnComp.props.components.forEach((c) => {
                        delete c.props.focused;
                });
            }
            if (mirrorColCompEle?.props?.isComplexForm || mirrorColCompEle?.props?.LRProps) {
                mirrorParentColumnComp.props.components.forEach((item) => {
                    if (!item.props.associatedCell) {
                        addCells({
                            mainComp: mirrorParentColumnComp,
                            associatedComponent: item,
                            workshopComponent: workshopComponent || componentColumn?.props?.components?.[componentIndex2],
                            LRProps: mirrorParentColumnComp.props.LRProps,
                            isCell: mirrorParentColumnComp.type === 'Cell',
                            cellIndex: compFindIndex,
                        });
                    }
                });
            }
            setUpdateFlag(!updateFlag);
            closeAddOptionContextMenu();
            return;
            // setActiveComponent(setSettingComponentHandle, duplicateComp);
        }

        if (hasLabel && component.parent && inWorkshop && !(
            wowTemplates.includes(protocolComp?.component))) {
            const duplicateComp = duplicateComponent(
                component.parent[compAddIndex], component.parent, compAddIndex, { label: '', focused: true }
            );
            if (renderAsTextArea) {
                inheritComponentFunction(workshopComponent, duplicateComp, TextAreaProps);
            } else if (renderAsTextBox) {
                inheritComponentFunction(workshopComponent, duplicateComp, TextBoxProps);
            }
            const parentColumnComp = findComponent(workshopComponent
                ? workshopComponent?.props?.components : componentColumn?.props?.components?.[componentIndex2]?.props?.components,
            duplicateComp.parent);
            let colCompEle = parentColumnComp;
            if (parentColumnComp?.type === 'Cell') {
                colCompEle = findComponent(workshopComponent
                    ? workshopComponent?.props?.components : componentColumn?.props?.components?.[componentIndex2]?.props?.components,
                parentColumnComp.colRef);
            }

            if (parentColumnComp?.props?.components?.length > 0) {
                parentColumnComp.props.components.forEach((c) => {
                    if (c.id !== duplicateComp.id && c.props.focused) {
                        delete c.props.focused;
                    }
                });
            }
            if (colCompEle?.props?.isComplexForm || colCompEle?.props?.LRProps) {
                parentColumnComp.props.components.forEach((item) => {
                    if (!item.props.associatedCell) {
                        addCells({
                            mainComp: parentColumnComp,
                            associatedComponent: item,
                            workshopComponent: workshopComponent || componentColumn?.props?.components?.[componentIndex2],
                            LRProps: parentColumnComp.props.LRProps,
                            isCell: parentColumnComp.type === 'Cell',
                            cellIndex: compFindIndex,
                        });
                    }
                });
            }
            setActiveComponent(setSettingComponentHandle, duplicateComp);
        } else if (hasLabel && !component.parent && inWorkshop && !(
            wowTemplates.includes(protocolComp?.component))) {
            const parent = componentParent.props.components;
            const duplicateComp = duplicateComponent(parent[compAddIndex], parent, compAddIndex, { label: '', focused: true });
            if (renderAsTextArea) {
                inheritComponentFunction(workshopComponent, duplicateComp, TextAreaProps);
            } else if (renderAsTextBox) {
                inheritComponentFunction(workshopComponent, duplicateComp, TextBoxProps);
            }
            const parentColumnComp = findComponent(workshopComponent
                ? workshopComponent?.props?.components : componentColumn?.props?.components?.[componentIndex2]?.props?.components,
            duplicateComp.parent);
            // We are in the second row, need to get the column from the colRef prop
            let colComp = parentColumnComp;
            if (parentColumnComp?.type === 'Cell') {
                colComp = findComponent(workshopComponent
                    ? workshopComponent?.props?.components : componentColumn?.props?.components?.[componentIndex2]?.props?.components,
                parentColumnComp.colRef);
            }

            if (parentColumnComp.props.components.length > 0) {
                parentColumnComp.props.components.forEach((c) => {
                    if (c.id !== duplicateComp.id && c.props.focused) {
                        delete c.props.focused;
                    }
                });
            }

            if (colComp?.props?.isComplexForm || colComp?.props?.LRProps) {
                parentColumnComp.props.components.forEach((item) => {
                    if (!item.props.associatedCell) {
                        addCells({
                            mainComp: parentColumnComp,
                            associatedComponent: item,
                            workshopComponent: workshopComponent || componentColumn?.props?.components?.[componentIndex2],
                            LRProps: parentColumnComp.props.LRProps,
                            isCell: parentColumnComp.type === 'Cell',
                            cellIndex: compFindIndex,
                        });
                    }
                });
            }
            setActiveComponent(setSettingComponentHandle, duplicateComp);
        }
        setUpdateFlag(!updateFlag);
        closeAddOptionContextMenu();
    };

    const openAddOptionContextMenu = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setAddOptionContextMenu(addOptionContextMenu === null ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 } : null);
    };

    const closeAddOptionContextMenu = () => {
        setAddOptionContextMenu(null);
    };

    const deleteCheckBox = () => {
        const parent = component.parent || componentParent.props.components;
        const compIndex = parent && parent.findIndex((comp) => comp.id === id);
        if (compIndex >= 0) {
            closeAddOptionContextMenu();
            showDeletionDialog({ components: parent }, compIndex, parent[compIndex]);
        }
    };
    const handleUpdateComponent = useCallback(() => {
        if (!isEditMode) {
            if (component.props.label === component.props.prevLabel) {
                return;
            }
    
            component.props.prevLabel = component.props.label;
            const myList = [];
            buildSettingsTreePage(myList, workshopComponent);
            const listParent = myList.find((item) => item.id === componentProps.parent);
    
            if (listParent?.props?.LRProps) {
                const { LRtype, mainParent, LRparent } = listParent.props.LRProps;
                const parentComp = findComponent(workshopComponent?.props.components, mainParent);
                const compIndex = parentComp?.props.components.findIndex((comp) => comp.id === LRparent);
                const updateIndex = LRtype === 'left' ? compIndex + 1 : compIndex - 1;
                if (LRtype === 'right') {
                    return;
                }
                setShouldUpdate(true);
                if (parentComp) {
                    parentComp.props.components[compIndex].props.components.forEach((activeComp, i) => {
                        const compToUpdate = parentComp.props.components[updateIndex].props.components[i];
                        if (!compToUpdate) {
                            return;
                        }
                        let LRPropsBak;
                        if (compToUpdate.props.LRProps) {
                            LRPropsBak = { ...compToUpdate.props.LRProps };
                        }
                        parentComp.props.components[updateIndex].props.components[i] = copyTemplateLR(activeComp, LRPropsBak, workshopComponent);
                    });
                }
            }
        }
    }, [workshopComponent, component, componentProps]);

    const handleAssociation = () => {
        const reqObj = {
            index: -1,
            rowComp,
            componentProps,
            data: null,
            states: {
                component, setComponentState,
            },
            protocolComponent,
        };
        handleManualSelection(reqObj);
        setGlobalAssociationState(!globalAssociationState);
    };

    const handleDoubleClick = () => {
        const columnComp = componentParent?.parent[complexFormColumnIndex];
        if (complexFormColumnIndex === 0) {
            if (activeSelectSuggestParent) {
                delete activeSelectSuggestParent.props.highlightColor;
            }
            if (activeSelectSuggestParent?.parent && Array.isArray(activeSelectSuggestParent.parent)) {
                activeSelectSuggestParent.parent.forEach((c) => { delete c.props.highlightColor; });
            }
            if (columnComp) {
                columnComp.props.components.forEach((c) => {
                    if (c.props?.highlightColor?.color) {
                        delete c.props.highlightColor;
                    }
                });
            }
            if (activeSelectSuggestParent?.props?.selectId !== id && workshopComponent) {
                if (!component.props.highlightColor) {
                    component.props.highlightColor = { id: 99, priority: 99 };
                }
                component.props.highlightColor = getSelectionColors(component.props.highlightColor, 'parent');
                if (component.props.highlightColor.priority === 3) { delete component.props.highlightColor; }
                setActiveSelectSuggestParent(component);
            } else if (activeSelectSuggestParent?.props?.selectId !== id && !workshopComponent) {
                setActiveSelectSuggestParent(component);
            } else {
                setActiveSelectSuggestParent(null);
            }
            setUpdateFlag(!updateFlag);
        }
        if (complexFormColumnIndex === 1 && inWorkshop) { // inWorkshop property will prevent double click on 2nd coloumn in timeline
            if (activeSelectSuggestParent
                && activeSelectSuggestParent?.props?.['select-suggest']) {
                if (activeSelectSuggestParent?.props?.['select-suggest']?.hasOwnProperty(component?.props?.selectId)
                    && activeSelectSuggestParent?.props?.['select-suggest']?.[component?.props?.selectId]?.color === 'yellow') {
                    activeSelectSuggestParent.props['select-suggest'][component?.props?.selectId].color = 'blue';
                } else if (activeSelectSuggestParent?.props?.['select-suggest']?.hasOwnProperty(component?.props?.selectId)
                    && activeSelectSuggestParent?.props?.['select-suggest']?.[component?.props?.selectId]?.color === 'blue') {
                    delete activeSelectSuggestParent.props['select-suggest'][component?.props?.selectId];
                } else {
                    activeSelectSuggestParent.props['select-suggest'][component?.props?.selectId] = {
                        color: 'yellow',
                    };
                }
            } else if (activeSelectSuggestParent) {
                activeSelectSuggestParent.props['select-suggest'] = {
                    [component?.props?.selectId]: {
                        color: 'yellow',
                    },
                };
            }
            setUpdateFlag(!updateFlag);
        }
    };

    useEffect(() => {
        handleUpdateComponent();
    }, [handleUpdateComponent]);

    useEffect(() => {
        if (shouldUpdate) {
            setWorkshopComponent((comp) => ({ ...comp }));
        }
    }, [shouldUpdate]);

    useEffect(() => {
        setShouldUpdate(false);
    }, [workshopComponent]);

    const compHeightChange = () => {
        const timelineCompFlag = divHeightRef?.current?.id === 'renderview' || renderAsTextBox || renderAsTextArea;
        const isManual = colComp?.props?.pcRelationship === 'manual';
        const data = (isManual && timelineCompFlag && !inWorkshop)
            ? componentProps?.props?.associatedCellTimeline : componentProps?.props?.associatedCell;
        if (data) {
            const rowList = [];
            buildSettingsTreePage(rowList, rowComp);
            const secondCol = rowList.find(row => row.props?.index === 1);
            const isNotSimpleForm = secondCol?.props?.components?.some(x => !x.isTimeline && x?.props?.components?.length > 0);
            const firstColCompId = inWorkshop ? `${componentProps?.id}-input-field` : `${componentProps?.id}-input-field-false`;
            const divTextEl = document.getElementById(firstColCompId);
            const id = `${inWorkshop || false}-${data}`;
            const ele = document.getElementById(id);

            if (ele && divHeightRef.current
                && componentParent2?.components?.[componentIndex2]?.component !== 'LR Mixed Form' && isNotSimpleForm) {
                if (!inWorkshop &&  divHeightRef.current.clientHeight <= ele.clientHeight) {
                    divHeightRef.current.style.height = `${ele.clientHeight}px`;
                } else if (!inWorkshop) {
                    ele.style.height = `${divHeightRef.current.clientHeight}px`;
                }
                if (inWorkshop) {
                    divHeightRef.current.style.height = `${ele.clientHeight}px`;
                }
                if (divHeightRef.current.offsetHeight > 37 && divTextEl) {
                    divTextEl.style.height = divHeightRef.current.offsetHeight - 15 + 'px';
                }
            } else if (!isNotSimpleForm && isMultiline && divTextEl?.scrollHeight > 40 && divTextEl.scrollHeight > divHeightRef.current.clientHeight && componentParent2?.components?.[componentIndex2]?.component !=="Universal LR CF") {
                divHeightRef.current.style.height = `${divTextEl.scrollHeight + 10}px`;
                if (!inWorkshop) {
                    divTextEl.style.height = `${divTextEl.scrollHeight}px`;
                }
            }
        }
    };

    useEffect(() => {
        if (workshopComponent && workshopActiveComponent) {
            const data = findColumnCF(workshopComponent, workshopActiveComponent, componentProps);
            if (data) {
                setSelectCompCF(data);
            }
        }
    }, [workshopActiveComponent]);

    useEffect(() => {
        // #PR1677
        // this useEffect updates multiple checkbox components at ones
        // as all components are updated at ones, sometimes an associatedComponents height is changed after its parent (a component it is associated to).
        // causing parent component to have small height than its associated component
        compHeightChange();
        // so calculating height ones again by delaying an update to match its associated component
        setTimeout(() => {
            compHeightChange();
        }, 150);
    }, [workshopActiveComponent?.props?.options, updateFlag]);

    useEffect(() => {
        compHeightChange();
    }, [component.layoutChange]);

    const isRightColumn = componentProps?.props?.LRProps?.LRtype === 'right';

    const handleTextCheck = (event) => {
        if (componentProps?.props) {
            componentProps.props.isChecked = event.target.checked;
        }
        // WOW feature checks
        const currentProtocol = componentParent2.components.find((val) => (val.id === component.props.protocolId)
        || (val.id === componentParent?.props?.protocolId));
        if (!inWorkshop && currentProtocol?.props?.['wow feature'] === 'yes') {
            const workShopList = [];
            buildSettingsTreePage(workShopList, currentProtocol);
            const activeRow = workShopList.find((val) => val.type === 'Row');
            setActiveRow(activeRow);
            const currentFirstCol = workShopList.find((val) => val.props.index === 0 && val.props.isComplexForm);
            const matchingEl = currentFirstCol?.props?.components.find((val) => val.id === component.props.selectId);
            if (matchingEl) {
                const currentSecondCol = workShopList.find((val) => val.props.index === 1
                && val.props.isComplexForm)?.props?.components.find((val) => matchingEl.id === val.props.associatedComponent);
                const currentThirdCol = workShopList.find((val) => val.props.index === 2
                && val.props.isComplexForm)?.props?.components[0];
                const currentThirdColumn = workShopList.find((val) => val.props.index === 2
                && val.props.isComplexForm);
                if (currentFirstCol?.props?.components[0].type === 'TextAreaCF'
                && currentSecondCol?.props?.components.some((val) => val.type === 'Select')
                && !currentThirdCol?.props?.components?.some((val) => ['Checkbox', 'Select', 'TextBoxCF'].includes(val.type))) {
                    const secondColAssEl = workShopList.find((val) => val.type === 'Select' && val.parent === component.props.associatedCell)
                    || currentSecondCol?.props?.components[0];
                    const thirdColAssEl = currentThirdColumn?.props?.components?.find((val) => val.props.associatedComponent === secondColAssEl?.id);
                    if (event.target.checked && secondColAssEl?.props?.options.some((el) => el.isSelect)) {
                        matchingEl.props.isCompleted = 'completed';
                        component.props.isCompleted = 'completed';
                        secondColAssEl.props.isCompleted = 'completed';
                        if (thirdColAssEl) {
                            thirdColAssEl.props.isCompleted = 'completed';
                        }
                    } else if (!event.target.checked && secondColAssEl?.props?.options.every((el) => !el.isSelect)) {
                        matchingEl.props.isCompleted = undefined;
                        component.props.isCompleted = undefined;
                        secondColAssEl.props.isCompleted = undefined;
                        if (thirdColAssEl) {
                            thirdColAssEl.props.isCompleted = undefined;
                        }
                    } else if (!event.target.checked || secondColAssEl?.props?.options.every((el) => !el.isSelect)) {
                        matchingEl.props.isCompleted = 'inComplete';
                        component.props.isCompleted = 'inComplete';
                        secondColAssEl.props.isCompleted = 'inComplete';
                        if (thirdColAssEl) {
                            thirdColAssEl.props.isCompleted = 'inComplete';
                        }
                    }
                    if (currentFirstCol?.props?.components.some((el) => el.props.isCompleted === 'inComplete')) {
                        const protocolEl = workShopList.find((el) => el.type === 'Protocol');
                        protocolEl.props.isCompleted = 'inComplete';
                    } else if (currentFirstCol?.props?.components.some((el) => el.props.isCompleted === 'completed')) {
                        const protocolEl = workShopList.find((el) => el.type === 'Protocol');
                        protocolEl.props.isCompleted = 'completed';
                    } else if (currentFirstCol?.props?.components.every((el) => el.props.isCompleted === undefined)) {
                        const protocolEl = workShopList.find((el) => el.type === 'Protocol');
                        protocolEl.props.isCompleted = undefined;
                    }
                    setUpdateFlag(!updateFlag);
                    updateTimeline();
                }
            }
        }
        setUpdateFlag(!updateFlag);
        if (!isSuperAdmin) {
            updateTimeline();
        }
    };

    const renderEditor = () => (
        <div
            className={[classNames('mainCompWrapper', isSelected && (!component?.props?.highlightColor?.color)
                ? !selectSuggestColor && classes.selectedComp
                : (componentProps.props.isSelect ? classes.firstCol : '')),
            component?.props?.highlightColor?.color && `${component?.props?.highlightColor?.color}Cls`,
            selectSuggestColor && `${selectSuggestColor?.color}Cls`,
            ].join(' , ')}
            id={isSelected ? 'selectedCompId' : ''}
            onClick={(
                event
            ) => {
                event.stopPropagation();
                setActiveComponent(setSettingComponentHandle, componentProps);
                handleSingleClick(componentProps, isUniversalManual, rowComp);
            }}
            style={{
                borderBottom: (isCF || renderAsTextArea || renderAsTextBox
                    || workshopComponent?.component === 'Mixed Form') ? '.25px solid #cbcbcb' : 'none',
                // overflow: !inWorkshop && 'auto',
                // pointerEvents: (renderAsTextArea || renderAsTextBox) && isRightColumn ? 'none' : '',
                backgroundColor: ((universalScndState?.parent?.id === componentProps?.id) || universalScndState?.child?.includes(componentProps.id))
                    ? '#16FF00' : componentProps?.props?.highlightComponent && isUniversalManual && 'yellow',
                display: 'flex',
                alignItems: 'center',
            }}
            ref={divHeightRef}
            onMouseEnter={() => { setShowCloseIcon(true); setRenderAsTextBoxPlaceHolder(false); }}
            onMouseLeave={() => { setShowCloseIcon(false); setRenderAsTextBoxPlaceHolder(false); }}
            onDoubleClick={component?.props?.cfType === 'Select and Suggest' ? handleDoubleClick : isUniversalManual && handleAssociation}
            aria-hidden="true"
            onContextMenu={inWorkshop ? (e) => openAddOptionContextMenu(e) : undefined}
        >
            <FormControlLabel
                className={!inWorkshop
                    ? classes.labelsTextInput : (renderAsTextArea && (!component?.props?.['form-control']
                    || component?.props?.['form-control'] === 'no'))
                        || (renderAsTextBox && component?.props?.hasOwnProperty('form-control')
                        && (!component?.props?.['form-control'] || component?.props?.['form-control'] === 'no'))
                        ? classes.labels1 : isMultiline ? classes.labelWorkshopMultiline : classes.labelWorkshop}
                control={
                    ((renderAsTextArea && (!component?.props?.['form-control'] || component?.props?.['form-control'] === 'no'))
                        || (renderAsTextBox && component?.props?.hasOwnProperty('form-control')
                        && (!component?.props?.['form-control'] || component?.props?.['form-control'] === 'no')))
                        ? <></> : (
                            <CheckboxUI
                                className={classes.checkbox}
                                checked={componentProps?.props?.isChecked}
                                onClick={(event) => {
                                    const protocolEl = protocolComponent?.parent?.find((c) => c.id === protocolComponent.props.protocolId);
                                    if (protocolEl?.component === 'Select/Text-Complete-Notes' || protocolComponent?.component === 'Select/Text-Complete-Notes') {
                                        handleTextCheck(event);
                                    }
                                }}
                            />
                        )
                }
                label={makeInlineEditor({
                    field: {
                        key: 'label',
                        label: (renderAsTextArea || renderAsTextBox ? 'Text Input' : 'Add Option'),
                        type: 'TextField',
                        disableClear: true,
                        onSubmit: onChangeOfLabel,
                        config: componentProps,
                        isNotDebounce: inWorkshop,
                    },
                    component,
                    setComponentState,
                    classes,
                    domain,
                    setDirtyDomain,
                })}
                classes={{ label: classes.textInputSize }}
            />
            {!renderAsTextArea && !renderAsTextBox && showCloseIcon
                && (
                    <IconButton edge="end" className={classes.pd0} aria-label="delete" onClick={() => { deleteCheckBox(component); }}>
                        <CloseIcon className={classes.closeIconStyle} />
                    </IconButton>
                )}
            <Menu
                open={addOptionContextMenu !== null}
                onClose={closeAddOptionContextMenu}
                anchorReference="anchorPosition"
                anchorPosition={
                    addOptionContextMenu !== null
                        ? { top: addOptionContextMenu.mouseY, left: addOptionContextMenu.mouseX }
                        : undefined
                }
            >
                {!isUniversalManual ? <MenuItem onClick={onChangeOfLabel}>Add Option</MenuItem> : null}
                <MenuItem onClick={deleteCheckBox}>Delete</MenuItem>
            </Menu>
        </div>
    );

    const handleChange = (e, cfType, cfColumnIndex, checkboxId, label, component) => {
        const isChecked = e.target.checked;
        if (isPatient && !isPatientWrite) { // Patient loggedin and no right to modify
            return null;
        }
        const findSelectProtocol = selectedChildren?.['selected_children']?.
        find((c) => c.protocol_id === activeSelectSuggestParent?.props?.protocolId);
        if (cfType === 'Select and Suggest') {
            if (cfColumnIndex === 0 && (isChecked)) {
                if (Object.keys(activeSelectSuggestData)?.length === 0
                    || activeSelectSuggestData?.protocolId !== activeSelectSuggestParent?.props?.protocolId) {
                    if (!findSelectProtocol) {
                        setActiveSelectSuggestData({
                            timelineId: selectedTimeline?.id,
                            columnId: componentColumn?.id,
                            protocolId: activeSelectSuggestParent?.props?.protocolId,
                            childrenData: {
                                [activeSelectSuggestParent?.props?.selectId]: {
                                    leftRight: activeSelectSuggestParent?.props?.LRProps?.LRtype,
                                    index: activeSelectSuggestParent?.props?.checkboxIndex,
                                    title: activeSelectSuggestParent?.props?.label,
                                    children: {},
                                },
                            },
                            operation: 'create',
                        });
                    }
                } else {
                    setActiveSelectSuggestData({
                        id: activeSelectSuggestData.id,
                        timelineId: selectedTimeline?.id,
                        columnId: componentColumn?.id,
                        protocolId: activeSelectSuggestParent?.props?.protocolId,
                        childrenData: {
                            ...activeSelectSuggestData.childrenData,
                            [activeSelectSuggestParent?.props?.selectId]: {
                                leftRight: activeSelectSuggestParent?.props?.LRProps?.LRtype,
                                index: activeSelectSuggestParent?.props?.checkboxIndex,
                                title: activeSelectSuggestParent?.props?.label,
                                children: {},
                            },
                        },
                        operation: 'edit',
                    });
                }
            } else if (cfColumnIndex === 0 && !isChecked) {
                // The delete line is commented to indicate that it should not be deleted when moving to different parts of the data
                delete activeSelectSuggestData?.childrenData?.[activeSelectSuggestParent?.props?.selectId];
                if (activeSelectSuggestData?.childrenData) {
                    setActiveSelectSuggestData({
                        ...activeSelectSuggestData,
                        operation: Object.keys(activeSelectSuggestData?.childrenData).length > 0 ? 'edit' : 'delete',
                    });
                }
            } else if (cfColumnIndex === 1 && isChecked) {
                if (activeSelectSuggestData.childrenData?.hasOwnProperty(activeSelectSuggestParent?.props?.selectId)) {
                    activeSelectSuggestData.childrenData[activeSelectSuggestParent?.props?.selectId].children = {
                        ...activeSelectSuggestData?.childrenData[activeSelectSuggestParent?.props?.selectId]?.children,
                        [checkboxId]: {
                            index: componentProps?.props?.checkboxIndex,
                            title: label,
                        },
                    };
                }

                setActiveSelectSuggestData({
                    ...activeSelectSuggestData,
                    operation: 'edit',
                });
            } else if (cfColumnIndex === 1 && !isChecked) {
                delete activeSelectSuggestData.childrenData[activeSelectSuggestParent?.props?.selectId].children[checkboxId];
                setActiveSelectSuggestData({
                    ...activeSelectSuggestData,
                    operation: 'edit',
                });
            }

            if (activeSelectSuggestParent?.props?.selectId === component?.props?.selectId) {
                setActiveSelectSuggestParent((activeSelectSuggestParent) => ({
                    ...activeSelectSuggestParent,
                    props: { ...activeSelectSuggestParent.props, isChecked },
                }));
            } else {
                setComponentState((component) => ({ ...component, props: { ...component.props, isChecked } }));
            }
        }
        component.props.isChecked = isChecked;
        componentProps.props.isChecked = isChecked;

        // WOW feature checks
        const currentProtocol = componentParent2.components.find((val) => (val.id === component.props.protocolId)
        || (val.id === componentParent?.props?.protocolId));
        if (!inWorkshop && currentProtocol?.props?.['wow feature'] === 'yes') {
            const workShopList = [];
            buildSettingsTreePage(workShopList, currentProtocol);
            const activeRow = workShopList.find((val) => val.type === 'Row');
            setActiveRow(activeRow);
            const currentFirstCol = workShopList.find((val) => val.props.index === 0 && val.props.isComplexForm);
            const matchingEl = currentFirstCol?.props?.components.find((val) => val.id === component.props.selectId);
            if (matchingEl) {
                const currentSecondCol = workShopList.find((val) => val.props.index === 1
                && val.props.isComplexForm)?.props?.components.find((val) => matchingEl.id === val.props.associatedComponent);
                const currentThirdCol = workShopList.find((val) => val.props.index === 2
                && val.props.isComplexForm)?.props?.components[0];
                const currentThirdColumn = workShopList.find((val) => val.props.index === 2
                && val.props.isComplexForm);
                if (currentFirstCol?.props?.components[0].type === 'Checkbox'
                && currentSecondCol?.props?.components.some((val) => val.type === 'Select')
                && !currentThirdCol?.props?.components?.some((val) => ['Checkbox', 'Select', 'TextBoxCF'].includes(val.type))) {
                    const secondColAssEl = workShopList.find((val) => val.type === 'Select' && val.parent === component.props.associatedCell)
                    || currentSecondCol?.props?.components[0];
                    const thirdColAssEl = currentThirdColumn?.props?.components?.find((val) => val.props.associatedComponent === secondColAssEl?.id);
                    if (matchingEl?.props.isChecked && secondColAssEl?.props?.options.some((el) => el.isSelect)) {
                        matchingEl.props.isCompleted = 'completed';
                        component.props.isCompleted = 'completed';
                        secondColAssEl.props.isCompleted = 'completed';
                        if (thirdColAssEl) {
                            thirdColAssEl.props.isCompleted = 'completed';
                        }
                    } else if (!matchingEl?.props.isChecked && secondColAssEl?.props?.options.every((el) => !el.isSelect)) {
                        matchingEl.props.isCompleted = undefined;
                        component.props.isCompleted = undefined;
                        secondColAssEl.props.isCompleted = undefined;
                        if (thirdColAssEl) {
                            thirdColAssEl.props.isCompleted = undefined;
                        }
                    } else if (!matchingEl?.props.isChecked || secondColAssEl?.props?.options.every((el) => !el.isSelect)) {
                        matchingEl.props.isCompleted = 'inComplete';
                        component.props.isCompleted = 'inComplete';
                        secondColAssEl.props.isCompleted = 'inComplete';
                        if (thirdColAssEl) {
                            thirdColAssEl.props.isCompleted = 'inComplete';
                        }
                    }
                    if (currentFirstCol?.props?.components.some((el) => el.props.isCompleted === 'inComplete')) {
                        const protocolEl = workShopList.find((el) => el.type === 'Protocol');
                        protocolEl.props.isCompleted = 'inComplete';
                    } else if (currentFirstCol?.props?.components.some((el) => el.props.isCompleted === 'completed')) {
                        const protocolEl = workShopList.find((el) => el.type === 'Protocol');
                        protocolEl.props.isCompleted = 'completed';
                    } else if (currentFirstCol?.props?.components.every((el) => el.props.isCompleted === undefined)) {
                        const protocolEl = workShopList.find((el) => el.type === 'Protocol');
                        protocolEl.props.isCompleted = undefined;
                    }
                    setUpdateFlag(!updateFlag);
                    updateTimeline();
                }
            }
        }
        setUpdateFlag(!updateFlag);
        if (!isSuperAdmin) {
            updateTimeline();
        }
        handleTextEditorClick(e);
        return null;
    };

    useEffect(() => {
        const { isChecked } = component?.props;
        if (!inWorkshop && !workshopComponent && componentParent2?.components?.[componentIndex2]?.component === 'Universal One Sided CF') {
            if (isChecked && associatedParent) {
                associatedParent.props.isChecked = isChecked;
                setUpdateFlag(!updateFlag);
            } else if (!isChecked) {
                const columnsArray = rowComp?.props?.components;
                const compId = componentProps?.id;
                let i = 1;
                const l = columnsArray.length;
                while (i < l) {
                    for (let j = 0, m = columnsArray[i]?.props?.components.length; j < m; j += 1) {
                        const insideComp = columnsArray[i]?.props?.components?.[j]?.props;
                        if (insideComp.associatedComponent === compId) {
                            insideComp.components.forEach((child) => {
                                child.props.isChecked = isChecked;
                            });
                        }
                    }
                    i += 1;
                }
                setUpdateFlag(!updateFlag);
            }
        }
    }, [component?.props?.isChecked]);

    useEffect(() => {
        if (!hyperlinkCip) {
            let nodeTextConcat = '';
            const requiredChildNodes = checkboxLabelRef?.current?.childNodes;
            if (requiredChildNodes) {
                requiredChildNodes.forEach((childNode) => {
                    nodeTextConcat += childNode.textContent;
                });
                if (nodeTextConcat !== label) {
                    setCheckboxLabel(label);
                }
            }
        }
    }, [label]);

    const openCheckboxLabelContextMenu = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setCheckboxLabelContextMenu(checkboxLabelContextMenu === null ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 } : null);
    };

    const closeCheckboxLabelContextMenu = (event) => {
        event.stopPropagation();
        setCheckboxLabelContextMenu(null);
    };

    const addEditHyperLink = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setHyperlinkCip(true);
        setHyperlinkCipText(checkboxLabelRef?.current?.textContent);
        setHyperlinkComponent(componentProps);
        handleContextMenu(
            event,
            componentParent2?.components[componentIndex2],
            { ...componentParent2, components: [{ ...componentParent2?.components[componentIndex2] }] },
            componentIndex2,
            componentColumn
        );
    };

    const removeHyperLink = (event) => {
        event.stopPropagation();
        delete componentProps?.props?.hyperlink;
        setCheckboxLabelContextMenu(null);
        setCheckboxLabel(label);
        updateTimeline();
    };

    useEffect(() => {
        setTimeout(() => {
            compHeightChange();
        }, 0);
    }, [protocolComponent?.props?.pcRelationship]);

    useEffect(() => {
        if (workshopComponent?.props?.['wow feature'] === 'yes' && !inWorkshop) {
            const workShopList = [];
            buildSettingsTreePage(workShopList, workshopComponent);
            const activeRow = workShopList.find((val) => val.type === 'Row');
            setActiveRow(activeRow);
            const currentFirstCol = workShopList.find((val) => val.props.index === 0 && val.props.isComplexForm);
            const matchingEl = currentFirstCol?.props?.components.find((val) => val.id === component.props.selectId);
            if (matchingEl) {
                const currentSecondCol = workShopList.find((val) => val.props.index === 1
                && val.props.isComplexForm)?.props?.components.find((val) => matchingEl.id === val.props.associatedComponent);
                const currentThirdCol = workShopList.find((val) => val.props.index === 2 && val.props.isComplexForm)?.props?.components[0];
                const currentThirdColumn = workShopList.find((val) => val.props.index === 2
                && val.props.isComplexForm);
                if ((currentFirstCol?.props?.components[0].type === 'Checkbox' || (workshopComponent?.component === 'Select/Text-Complete-Notes'
                && currentFirstCol?.props?.components[0].type === 'TextAreaCF'))
                && currentSecondCol?.props?.components.some((val) => val.type === 'Select')
                && !currentThirdCol?.props?.components?.some((val) => ['Checkbox', 'Select', 'TextBoxCF'].includes(val.type))) {
                    const secondColAssEl = workShopList.find((val) => val.type === 'Select' && val.parent === component.props.associatedCell);
                    if (secondColAssEl) {
                        if (matchingEl?.props.isChecked && secondColAssEl?.props?.options.some((el) => el.isSelect)) {
                            matchingEl.props.isCompleted = 'completed';
                            component.props.isCompleted = 'completed';
                            secondColAssEl.props.isCompleted = 'completed';
                            if (workshopComponent?.component === 'Select-Complete-Notes' || workshopComponent?.component === 'Select/Text-Complete-Notes') {
                                const secondColAssEl = workShopList.find((val) => val.type === 'Select'
                                && val.parent === component.props.associatedCell) || currentSecondCol?.props?.components[0];
                                const thirdColAssEl = currentThirdColumn?.props?.
                                    components?.find((val) => val.props.associatedComponent === secondColAssEl?.id);
                                thirdColAssEl.props.isCompleted = 'completed';
                            }
                        } else if (!matchingEl?.props.isChecked && secondColAssEl?.props?.options.every((el) => !el.isSelect)) {
                            matchingEl.props.isCompleted = undefined;
                            component.props.isCompleted = undefined;
                            secondColAssEl.props.isCompleted = undefined;
                            if (workshopComponent?.component === 'Select-Complete-Notes' || workshopComponent?.component === 'Select/Text-Complete-Notes') {
                                const secondColAssEl = workShopList.find((val) => val.type === 'Select'
                                && val.parent === component.props.associatedCell) || currentSecondCol?.props?.components[0];
                                const thirdColAssEl = currentThirdColumn?.props?.
                                    components?.find((val) => val.props.associatedComponent === secondColAssEl?.id);
                                thirdColAssEl.props.isCompleted = undefined;
                            }
                        } else if (!matchingEl?.props.isChecked || secondColAssEl?.props?.options.every((el) => !el.isSelect)) {
                            matchingEl.props.isCompleted = 'inComplete';
                            component.props.isCompleted = 'inComplete';
                            secondColAssEl.props.isCompleted = 'inComplete';
                            if (workshopComponent?.component === 'Select-Complete-Notes' || workshopComponent?.component === 'Select/Text-Complete-Notes') {
                                const secondColAssEl = workShopList.find((val) => val.type === 'Select'
                                && val.parent === component.props.associatedCell) || currentSecondCol?.props?.components[0];
                                const thirdColAssEl = currentThirdColumn?.props?.
                                components?.find((val) => val.props.associatedComponent === secondColAssEl?.id);
                                thirdColAssEl.props.isCompleted = 'inComplete';
                            }
                        }
                        if (currentFirstCol?.props?.components.some((el) => el.props.isCompleted === 'inComplete')) {
                            const protocolEl = workShopList.find((el) => el.type === 'Protocol');
                            protocolEl.props.isCompleted = 'inComplete';
                        } else if (currentFirstCol?.props?.components.some((el) => el.props.isCompleted === 'completed')) {
                            const protocolEl = workShopList.find((el) => el.type === 'Protocol');
                            protocolEl.props.isCompleted = 'completed';
                        } else if (currentFirstCol?.props?.components.every((el) => el.props.isCompleted === undefined)) {
                            const protocolEl = workShopList.find((el) => el.type === 'Protocol');
                            protocolEl.props.isCompleted = undefined;
                        }
                    }
                    setUpdateFlag(!updateFlag);
                    updateTimeline();
                }
            }
        }
        if (!inWorkshop && workshopComponent?.props?.['wow feature'] === 'no') {
            const workShopList = [];
            buildSettingsTreePage(workShopList, workshopComponent);
            const activeRow = workShopList.find((val) => val.type === 'Row');
            setActiveRow(activeRow);
            const currentFirstCol = workShopList.find((val) => val.props.index === 0 && val.props.isComplexForm);
            const matchingEl = currentFirstCol?.props?.components.find((val) => val.id === component.props.selectId);
            if (matchingEl) {
                const currentSecondCol = workShopList.find((val) => val.props.index === 1
                && val.props.isComplexForm)?.props?.components.find((val) => matchingEl.id === val.props.associatedComponent);
                const currentThirdCol = workShopList.find((val) => val.props.index === 2 && val.props.isComplexForm)?.props?.components[0];
                const currentThirdColumn = workShopList.find((val) => val.props.index === 2
                && val.props.isComplexForm);
                if ((currentFirstCol?.props?.components[0].type === 'Checkbox' || (workshopComponent?.component === 'Select/Text-Complete-Notes'
                && currentFirstCol?.props?.components[0].type === 'TextAreaCF')) && currentSecondCol?.props?.
                    components.some((val) => val.type === 'Select')
                    && !currentThirdCol?.props?.components?.some((val) => ['Checkbox', 'Select', 'TextBoxCF'].includes(val.type))) {
                    const secondColAssEl = workShopList.find((val) => val.type === 'Select' && val.parent === component.props.associatedCell);
                    if (secondColAssEl) {
                        matchingEl.props.isCompleted = undefined;
                        component.props.isCompleted = undefined;
                        secondColAssEl.props.isCompleted = undefined;
                        if (workshopComponent?.component === 'Select-Complete-Notes' || workshopComponent?.component === 'Select/Text-Complete-Notes') {
                            const secondColAssEl = workShopList.find((val) => val.type === 'Select'
                            && val.parent === component.props.associatedCell) || currentSecondCol?.props?.components[0];
                            const thirdColAssEl = currentThirdColumn?.props?.
                                components?.find((val) => val.props.associatedComponent === secondColAssEl?.id);
                            thirdColAssEl.props.isCompleted = undefined;
                        }
                        const protocolEl = workShopList.find((el) => el.type === 'Protocol');
                        protocolEl.props.isCompleted = undefined;
                    }
                }
                setUpdateFlag(!updateFlag);
                updateTimeline();
            }
        }
    }, [workshopComponent?.props?.['wow feature']]);

    const renderView = () => {
        let isCheckboxDisabled = true;
        if ((componentColumn?.props?.components[0]?.supergroup === 'Form' || workshopComponent?.supergroup === 'Form')
            && !component?.props?.label) return null;
        if (component?.props?.cfType === 'Select and Suggest') {
            if (complexFormColumnIndex === 0 && activeSelectSuggestParent?.props?.selectId === component?.props?.selectId) {
                isCheckboxDisabled = false;
            } else if (complexFormColumnIndex === 1 && component?.props?.protocolId === activeSelectSuggestParent?.props?.protocolId
                && activeSelectSuggestParent?.props?.isChecked) {
                isCheckboxDisabled = false;
            }

            if (complexFormColumnIndex === 0 && activeSelectSuggestData?.childrenData?.hasOwnProperty(activeSelectSuggestParent?.props?.selectId)) {
                activeSelectSuggestParent.props.isChecked = true;
            } else if (complexFormColumnIndex === 1
                && activeSelectSuggestData?.childrenData?.[activeSelectSuggestParent?.props?.selectId]?.children?.
                hasOwnProperty(component?.props?.selectId)) {
                component.props.isChecked = true;
            } else if (complexFormColumnIndex === 1) {
                component.props.isChecked = false;
            }
            if (component?.props?.LRProps?.LRtype && activeSelectSuggestParent?.props?.LRProps?.LRtype !== component?.props?.LRProps?.LRtype) {
                isCheckboxDisabled = true;
            }
        } else {
            isCheckboxDisabled = false;
        }

        if (isPatient && !isPatientWrite) {
            isCheckboxDisabled = true;
        }

        /** Height fix */
        let flag = true;
        let associatedChild = [];
        const data = colComp.props.pcRelationship === 'manual' ? componentProps.props.associatedCellTimeline : componentProps.props.associatedCell;
        if (data) {
            // #PR1677
            const id = `false-${data}`;
            const ele = document.getElementById(id);
            if (ele && divHeightRef.current
                && componentParent2?.components?.[componentIndex2]?.component !== 'LR Mixed Form') {
                divHeightRef.current.style.height = `${ele.clientHeight}px`;
                associatedChild = ele.querySelectorAll("[id^='false-']");
            }
            flag = false;
        }
        return (
            <div
                className={[classes.checkboxMain, classes.dummyHeight, flag && classes.dummyHeight,
                    selectSuggestColor && `${selectSuggestColor?.color}Cls`,
                activeSelectSuggestParent?.props?.selectId === componentProps.id && 'yellowCls', (!inWorkshop && componentProps.props['wow feature']
                === 'yes') ? componentProps.props.isCompleted === 'inComplete' ? classes.redBg
                        : componentProps.props.isCompleted === 'completed' ? classes.greenBg : '' : ''].join(' , ')}
                // #PR1677
                style={{ borderBottom: '0.25px solid #CBCBCB', overflowX: !inWorkshop && 'auto', alignItems: 'center' }}
                ref={divHeightRef}
                id="renderview"
                aria-labelledby="Primary"
                role="region"
                onClick={(event) => event.preventDefault()}
            >
                <FormControlLabel
                    className={classes.labels}
                    classes={{
                        root: isCheckboxDisabled ? classes.marginLeft0
                            : renderAsTextArea || renderAsTextBox ? classes.checkboxTextRoot : classes.checkboxRoot,
                    }}
                    disabled={isCheckboxDisabled}
                    control={
                        ((renderAsTextArea
                            && (!component?.props?.['form-control'] || component?.props?.['form-control'] === 'no')
                            && !inWorkshop) || (renderAsTextBox && component?.props?.hasOwnProperty('form-control')
                            && (!component?.props?.['form-control'] || component?.props?.['form-control'] === 'no'))
                            || associatedChild.length === 0 && checkboxLabel?.length === 0) ? <></> : (
                                <CheckboxUI
                                    className={classes.checkbox1}
                                    onChange={(event) => handleChange(
                                        event,
                                    component?.props?.cfType,
                                    complexFormColumnIndex,
                                    component?.props?.selectId,
                                    checkboxLabel,
                                    component
                                    )}
                                    checked={isPatient ? (!!component?.props?.isChecked) : (!!(!isCheckboxDisabled && component?.props?.isChecked))}
                                    onClick={(e) => e.stopPropagation()}
                                />
                            )
                    }
                    label={
                        componentProps?.props.hyperlink ? (
                            <a
                                ref={checkboxLabelRef}
                                href={hyperlink}
                                onClick={(event) => event.stopPropagation()}
                                style={{ pointerEvents: 'all' }}
                                className={classes.anchorTag}
                                target="_blank"
                                rel="noreferrer"
                                onContextMenu={!hyperlinkCip
                                    && !componentProps?.isProgression && componentProps?.props?.label ? openCheckboxLabelContextMenu : undefined}
                            >
                                {checkboxLabel}
                            </a>
                        ) : (
                            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                            <Input
                                // style={{ pointerEvents: 'all', display: 'inline' }}
                                style= {{
                                    // textAlign: field?.label === 'Slide Title' || field?.label === 'Slide Caption'
                                    //     || field?.label === 'Image Title' || field?.label === 'Image Caption' ? 'center' : 'left',
                                    fontWeight: 100,
                                    fontSize: '10px',
                                    lineHeight: '10px',
                                    // marginTop: !inWorkshop && '-10px',
                                }}
                                className={classes.timeline_input}
                                id={`${component?.props?.selectId}-input-field-false`}
                                multiline={true}
                                ref={checkboxLabelRef}
                                disabled={true}
                                onClick={(event) => event.stopPropagation()}
                                value={checkboxLabel}
                                onContextMenu={!hyperlinkCip
                                    && !componentProps?.isProgression && componentProps?.props?.label ? openCheckboxLabelContextMenu : undefined}
                            />
                        )
                    }
                />
                <Menu
                    open={checkboxLabelContextMenu !== null}
                    onClose={closeCheckboxLabelContextMenu}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        checkboxLabelContextMenu !== null
                            ? { top: checkboxLabelContextMenu.mouseY, left: checkboxLabelContextMenu.mouseX }
                            : undefined
                    }
                >
                    {hyperlink ? (
                        <>
                            <MenuItem onClick={addEditHyperLink}>Edit Hyperlink</MenuItem>
                            <MenuItem onClick={removeHyperLink}>Remove Hyperlink</MenuItem>
                        </>
                    ) : (
                        <MenuItem onClick={addEditHyperLink}>Add Hyperlink</MenuItem>
                    )}
                </Menu>
            </div>
        );
    };

    return (
        <>
            <DefaultComponentLayout
                component={component}
                id={id}
                handleDoubleClick={!inWorkshop && component?.props?.cfType === 'Select and Suggest' ? handleDoubleClick : null}
            >
                {((((renderAsTextArea && (!component?.props?.['read-only'] || component?.props?.['read-only'] === 'no'))
                    || (renderAsTextBox && (!component?.props?.['read-only'] || component?.props?.['read-only'] === 'no'))) && !workshopComponent)
                    || (inWorkshop && !compareModal)) ? renderEditor() : renderView()}
            </DefaultComponentLayout>
        </>
    );
};

const CheckboxPreview = ({ component }) => {
    const classes = useStyles();
    const { handleCompPreviewClick, settingComponent } = useContext(TimelineContext);
    const [divHeightView, setDivHeightView] = useState(null);

    let isSelected = false;

    if (settingComponent && settingComponent.id === component.id) {
        isSelected = true;
    }

    const [, setCheckedEl] = useState([]);

    const updateChecked = (el) => {
        setCheckedEl((i) => [...i, el]);
    };

    useEffect(() => {
        /** Height fix */
        setTimeout(() => {
            if (component.props.associatedCell) {
                const id = `preview-${component.props.associatedCell}`;
                const ele = document.getElementById(id);
                if (ele) {
                    setDivHeightView(ele.clientHeight);
                }
            }
        }, 0);
    }, []);

    return (
        <div className={classNames('mainCompWrapper', isSelected ? classes.selectedComp : null)} id={isSelected ? 'selectedCompId' : ''}>
            <DefaultComponentLayout preview component={component}>
                <FormControlLabel
                    style={{ height: divHeightView || 'inherit' }}
                    classes={{ root: classes.checkboxRoot1, label: classes.labelCont }}
                    className={classes.labelsPrev}
                    control={
                        <CheckboxUI className={classes.checkbox} />
                    }
                    onClick={(e) => handleCompPreviewClick(e, component)}
                    label={component.props.label}
                    updateChecked={updateChecked}
                />
            </DefaultComponentLayout>
        </div>
    );
};

export const CheckboxProps = {
    display: [
        {
            key: 'label',
            label: 'Checkbox Title',
            type: 'TextField',
            id: 'Checkbox',
        },
        {
            key: 'hyperlink',
            type: 'TextFieldHyperlinkDebounce',
            label: 'Hyperlink',
            isBasic: true,
            isAdvance: true,
            isAdmin: true,
            id: 'Checkbox',
        },
    ],
    poi: false,
};

export const TextBoxProps = {
    display: [
        {
            key: 'label',
            type: 'TextField',
            label: 'TextBox Title',
            id: 'TextBoxCF',
            isInherited: false,
        },
        {
            key: 'textPlaceholder',
            type: 'TextField',
            label: 'Placeholder',
            default: 'Text Input',
            id: 'TextBoxCF',
            isInherited: true,
        },
        {
            key: 'form-control',
            type: 'Radio',
            label: 'Add Checkbox',
            options: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }],
            default: 'yes',
            isBasic: true,
            isAdvance: true,
            isAdmin: true,
            id: 'TextBoxCF',
            isInherited: true,
        },
        {
            key: 'read-only',
            type: 'Radio',
            label: 'Read only',
            options: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }],
            default: 'no',
            isBasic: true,
            isAdvance: true,
            isAdmin: true,
            id: 'TextBoxCF',
            isInherited: true,
        },
    ],
    poi: false,
};

export const TextAreaProps = {
    display: [
        {
            key: 'label',
            type: 'TextField',
            label: 'TextArea Title',
            id: 'TextAreaCF',
            isInherited: false,
        },
        {
            key: 'typeOfTextField',
            type: 'Radio',
            label: 'Type of Text Field',
            options: [{ label: 'Text Editor – Admin Input', value: 'admin' }, { label: 'Text Field – User Input', value: 'user' }],
            id: 'TextAreaCF',
            isBasic: false,
            isAdvance: true,
            isAdmin: true,
        },
        {
            key: 'textPlaceholder',
            type: 'TextField',
            label: 'Placeholder',
            default: 'Text Input',
            id: 'TextAreaCF',
            isInherited: true,
        },
        {
            key: 'form-control',
            type: 'Radio',
            label: 'Add Checkbox',
            options: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }],
            default: 'no',
            isBasic: true,
            isAdvance: true,
            isAdmin: true,
            id: 'TextAreaCF',
            isInherited: true,
        },
        {
            key: 'read-only',
            type: 'Radio',
            label: 'Read only',
            options: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }],
            default: 'no',
            isBasic: true,
            isAdvance: true,
            isAdmin: true,
            id: 'TextAreaCF',
            isInherited: true,
        },
        {
            key: 'single-continuous',
            type: 'Select',
            label: 'Single vs Continuous Textarea',
            id: 'TextAreaCF',
            options: [
                { label: 'Single', value: 'single' },
                { label: 'Continuous', value: 'continuous' },
            ],
            default: 'continuous',
            isBasic: false,
            isAdvance: true,
            isAdmin: true,
        },
    ],
    poi: false,
};

const CheckboxEditor = ({
    config,
    updateComponent,
    renderAsTextArea,
    renderAsTextBox,
    heading,
}) => {
    const classes = useStyles();
    const propsCopy = JSON.parse(JSON.stringify(renderAsTextArea ? TextAreaProps : (
        renderAsTextBox ? TextBoxProps : CheckboxProps
    )));
    // eslint-disable-next-line prefer-const
    let [component, setComponentState] = useRecoilState(componentWithId(config.id));
    const {
        settingMenuType, workshopComponent, workshopSelectedTemplate, selectedComponent, workshopActiveComponent,
        setUpdateFlag, updateFlag,
    } = useContext(TimelineContext);

    let propsComp = workshopComponent && workshopComponent.props.components;
    let configParent = config.parent && config.parent;

    propsCopy.display.forEach((obj) => {
        if (obj.key === 'currentAssociation') {
            obj.options = [{ label: config.props.label, value: config.props.label }];
        }
    });
    if (!component.props) {
        component = config;
    }
    component.props.options = [{ label: component?.props?.label, value: component?.props?.label }];
    if (!component?.props?.currentAssociation) {
        component.props.currentAssociation = component.props.label;
    }
    const currentAssociation = component?.props?.currentAssociation;
    if (!component?.props?.association) {
        component.props.options.forEach((associationKey) => {
            component.props.association = {
                [associationKey]: {
                    highlightedChildren: [],
                    disabledChildren: [],
                },
            };
        });
    }

    if (!propsComp && workshopSelectedTemplate) {
        propsComp = workshopSelectedTemplate.template.props.components;
    }

    if (!configParent) {
        configParent = selectedComponent.parent;
    }

    component.props.highlightedChildren = component?.props?.association?.[currentAssociation]?.highlightedChildren || [];
    component.props.disabledChildren = component?.props?.association?.[currentAssociation]?.disabledChildren || [];

    const componentList = findPage(propsComp, configParent);
    const componentIndex = componentList && componentList.findIndex((obj) => obj.id === configParent);
    const childComp = componentList && componentList[componentIndex + 1];
    if (childComp) {
        const selectComp = childComp.props.components?.find((obj) => obj.type === 'Select' || obj.type === 'MultiSelect');

        if (component.props.association[currentAssociation]) {
            // eslint-disable-next-line max-len
            component.props.association[currentAssociation].notHighlightedChildren = component?.props?.association?.[currentAssociation]?.notHighlightedChildren || selectComp.props.options.map((item) => item.value);
            component.props.association[currentAssociation].notHighlightedChildren = selectComp.props.options
                .map((item) => item.value).filter((item) => !component.props.highlightedChildren.includes(item));
        }
        if (selectComp) {
            propsCopy.display.forEach((obj) => {
                if (obj.key === 'highlightedChildren' || obj.key === 'disabledChildren') {
                    obj.options = selectComp.props.options;
                }
            });
        }
    } else {
        propsCopy.display = propsCopy.display
            .filter((obj) => (obj.key !== 'association' && obj.key !== 'highlightedChildren'
                && obj.key !== 'disabledChildren' && obj.key !== 'notHighlightedChildren'));
    }
    const filterCheckboxProps = {
        display: FilterSettings(propsCopy, settingMenuType, config.type),
        poi: renderAsTextArea ? TextAreaProps?.poi : (
            renderAsTextBox ? TextBoxProps?.poi : CheckboxProps?.poi
        ),
    };
    if (selectedComponent?.isProgression) {
        filterCheckboxProps.display = filterCheckboxProps.display.filter((ele) => ele?.key !== 'hyperlink');
    }
    let requiredColumn = findComponent(workshopComponent?.props.components, component?.parent?.[0]?.colRef || config?.colRef);
    const selectedCompColumn = findComponent(workshopComponent?.props.components, selectedComponent?.colRef);
    const colComponentList = [];
    buildSettingsTreePage(colComponentList, requiredColumn);

    // when user change single-continuous to this will chane on every textArea and protocol level
    useEffect(() => {
        if (colComponentList.length) {
            for (let i = 0, l = colComponentList.length; i < l; i += 1) {
                if (colComponentList[i].type === 'TextAreaCF') {
                    colComponentList[i].props['single-continuous'] = component?.props?.['single-continuous'];
                }
            }
            workshopComponent.props['single-continuous'] = component?.props?.['single-continuous'];
            setUpdateFlag(!updateFlag);
        }
    }, [component?.props?.['single-continuous']]);

    if (config?.id === workshopActiveComponent?.id) {
        const cloneColumnComponents = [...colComponentList];
        requiredColumn = colComponentList.filter((ele) => ele?.type === config.type && !ele?.props?.hideFromWorkshop);
        const compIndexValue = requiredColumn.findIndex((c) => c.id === component?.props?.selectId);
        for (let i = 0, l = filterCheckboxProps?.display?.length; i < l; i += 1) {
            if (filterCheckboxProps?.display?.[i]?.key === 'compRowNumber') {
                const mixedCompIndex = component.props.isMixedForm ? cloneColumnComponents.findIndex((c) => c.id === config.id) : compIndexValue + 1;
                filterCheckboxProps.display[i].value = mixedCompIndex;
                break;
            }
        }
    }
    for (let i = 0, l = filterCheckboxProps?.display?.length; i < l; i += 1) {
        if (filterCheckboxProps?.display?.[i]?.key === 'columnNumber') {
            filterCheckboxProps.display[i].value = (selectedCompColumn?.compIndex + 1) || 1;
            break;
        }
    }
    return (
        <>
            {heading && <Typography className={classes.settingHeading}>{heading}</Typography>}
            <Settings editorProps={filterCheckboxProps} component={component} setComponentState={setComponentState} saveComponent={updateComponent} />
        </>
    );
};

export { Checkbox, CheckboxEditor, CheckboxPreview };
