import {
    ColumnContext, OrgContext, TimelineContext, UserAccessContext, WorkshopContext,
} from '../context';
import { ComponentPreview, ComponentViewLookup } from './index';
import { Grid } from '@material-ui/core';
import { ProcedureLibrary } from '../initialConfig';
import { buildSettingsTreePage } from '../helper/commonHelper';
import { componentWithId } from './Atoms';
import { flattenSelected } from './ProcedureSelector';
import { makeStyles } from '@material-ui/core/styles';
import React, { Fragment, useContext } from 'react';

/**
 * Set the Media Editor Style
 */
const containerStyles = makeStyles(() => ({
    dynamicClassCb1: {
        display: 'grid',
        gridTemplateColumns: 'repeat(1, 1fr)',
        '& >div': {
            borderRight: '0.25px solid rgb(203, 203, 203) !important',
        },
    },
    dynamicClassCb2: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        '& >div': {
            borderRight: '0.25px solid rgb(203, 203, 203) !important',
        },
    },
    dynamicClassCb3: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        '& >div': {
            borderRight: '0.25px solid rgb(203, 203, 203) !important',
        },
    },
    dynamicClass1: {
        '& form': {
            borderTop: '0.25px solid rgb(203, 203, 203) !important',
        },
        '& div[render-as="radio"]': {
            width: '100%',
        },
        '& div[role="radiogroup"]': {
            display: 'grid',
            gridTemplateColumns: 'repeat(1, 1fr)',
            '& >label': {
                borderRight: '0.25px solid rgb(203, 203, 203) !important',
            },
        },
        '& ul>div': {
            display: 'grid',
            gridTemplateColumns: 'repeat(1, 1fr)',
            '& >div': {
                borderRight: '0.25px solid rgb(203, 203, 203) !important',
            },
        },
    },
    dynamicClass2: {
        '& form': {
            borderTop: '0.25px solid rgb(203, 203, 203) !important',
        },
        '& div[render-as="radio"]': {
            width: '100%',
        },
        '& div[role="radiogroup"]': {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            '& >label': {
                borderRight: '0.25px solid rgb(203, 203, 203) !important',
            },
        },
        '& ul>div': {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            '& >div': {
                borderRight: '0.25px solid rgb(203, 203, 203) !important',
            },
        },
    },
    dynamicClass3: {
        '& form': {
            borderTop: '0.25px solid rgb(203, 203, 203) !important',
        },
        '& div[render-as="radio"]': {
            width: '100%',
        },
        '& div[role="radiogroup"]': {
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            '& >label': {
                borderRight: '0.25px solid rgb(203, 203, 203) !important',
            },
        },
        '& ul>div': {
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            '& >div': {
                borderRight: '0.25px solid rgb(203, 203, 203) !important',
            },
        },
    },
    containerStyle: {
        width: '100%',
        minHeight: 'auto',
        position: 'relative',
        '& div': {
            borderRadius: '0px',
        },
    },
    textInputWrapper: {
        textAlign: 'center',
    },
    datePickerWrapper: {
        marginLeft: 4,
    },
    columnDivided: {
        '& >div': {
            borderRight: '0.25px solid rgb(203, 203, 203) !important',
            maxWidth: '100% !important',
            width: '100%',
        },
    },

}));

const Container = ({
    props, previewDomain, single = false, singleIndex = 0, complexFormColumnIndex,
}) => {
    const classes = containerStyles();
    const {
        components = [], isTimlineCol, isMergeSelect = false,
        componentPropsCip, componentParentCip, componentIndexCip,
        componentColumnCip, flexTextField, listTypePreview,
    } = props;
    const {
        poiData,
        workshopAccessData,
        workshopComponent,
        handleContextMenu,
        styleComponent,
        contextMenuPosition,
        selectedPOI,
        selectedTimeline,
        getComponentRef,
        selectedView,
        contextMenuTarget,
        selectedColumns,
        isDMRC,
        isDMViewMode,
        specificSelect,
        specificOptions,
        associationValue,
        selectedTemplate,
        complexFormType,
        handleColumnClick,
    } = useContext(TimelineContext);
    const category = selectedTimeline?.category;
    const { userAccess } = useContext(UserAccessContext);
    const { inWorkshop } = useContext(WorkshopContext);
    const { column } = useContext(ColumnContext);
    const { loggedUserType } = useContext(OrgContext);
    const isProgression = props?.isProgression;
    const columnId = props?.columnId;
    const poiId = [];

    const propsCopy = JSON.parse(JSON.stringify(props));// doing deep copy
    const componentsCopy = propsCopy.components ? propsCopy.components : [];
    let optionsArray = [];
    const componentsArray = [];
    let isParentRadio = false;

    // eslint-disable-next-line no-unused-vars
    const poiResponse = (poiData?.['components_poi'] || [])?.find((item) => item.protocol_id === components?.[0]?.props?.protocolId);
    if (!inWorkshop) {
        let obj = null;
        componentsCopy.forEach((ele) => {
            obj = { ...ele };
            if ((ele?.type === 'Select' || ele?.type === 'MultiSelect') && ele?.props?.renderAs === 'select' && ele?.associatedParent) {
                if (document.getElementById(`false-${ele?.associatedParent}`)?.getAttribute('render-as') === 'radio') {
                    isParentRadio = true;
                }
            }
            if ((ele?.type === 'Select' || ele?.type === 'MultiSelect')
                && !ele.mirrorId && ele.props.renderAs !== 'radio'
                && ele.props.renderAs !== 'Checkbox'
                && !Object.prototype.hasOwnProperty.call(ele.props, 'associatedCell')
                && !specificSelect[ele.associatedParent]
                && !isParentRadio) {
                if (isMergeSelect) {
                    specificOptions[ele.associatedParent] = {
                        firstComponent: { ...ele },
                    };
                } else if (Object.prototype.hasOwnProperty.call(specificOptions, ele.associatedParent)) {
                    ele.props.options.forEach((opt) => {
                        opt.requiredId = ele.id;
                    });
                    specificOptions[ele.associatedParent][ele.id] = [...ele.props.options];
                } else {
                    ele.props.options.forEach((opt) => {
                        opt.requiredId = ele.id;
                    });
                    specificOptions[ele.associatedParent] = {
                        firstComponent: { ...ele },
                    };
                    specificOptions[ele.associatedParent] = { ...specificOptions[ele.associatedParent], [ele.id]: [...ele.props.options] };
                }
            }
        });
        if (obj) {
            if (Object.prototype.hasOwnProperty.call(specificSelect, obj.associatedParent) && !obj.props.isHighlight) {
                const requiredId = specificSelect[obj.associatedParent];
                if (Array.isArray(requiredId)) {
                    // eslint-disable-next-line no-unused-vars
                    for (const key in specificOptions[obj.associatedParent]) {
                        if (requiredId.length > 0) {
                            if (requiredId.includes(key)) {
                                optionsArray = [...optionsArray, ...specificOptions[obj.associatedParent][key]];
                            }
                        } else if (key !== 'firstComponent') {
                            optionsArray = [...optionsArray, ...specificOptions[obj.associatedParent][key]];
                        }
                    }
                } else {
                    // eslint-disable-next-line no-unused-vars
                    for (const key in specificOptions[obj.associatedParent]) {
                        if (key === requiredId) {
                            optionsArray = [...specificOptions[obj.associatedParent][key]];
                            break;
                        }
                    }
                }
                if (specificOptions[obj.associatedParent]) {
                    if (optionsArray.length === 0) {
                        specificOptions[obj.associatedParent].firstComponent.props.options = [...obj.props.options];
                    } else {
                        specificOptions[obj.associatedParent].firstComponent.props.options = [...optionsArray];
                    }
                    specificOptions[obj.associatedParent].firstComponent.props.currentAssociation = optionsArray.length > 0
                        ? optionsArray[0].value : '';
                    componentsArray.push(specificOptions[obj.associatedParent].firstComponent);
                }
            } else if (Object.prototype.hasOwnProperty.call(specificSelect, obj.associatedParent) && obj.props.isHighlight) {
                const requiredId = specificSelect[obj.associatedParent];
                const specificOptionsCopy = JSON.parse(JSON.stringify(specificOptions));
                let hasKey = false;
                if (Array.isArray(requiredId)) {
                    // eslint-disable-next-line no-unused-vars
                    for (const key in specificOptionsCopy[obj.associatedParent]) {
                        if (requiredId.includes(key)) {
                            hasKey = true;
                            specificOptionsCopy[obj.associatedParent][key].forEach((ele) => {
                                ele.isHighlight = true;
                                ele.aData = associationValue[key];
                            });
                        }
                    }
                    if (!hasKey && specificOptionsCopy[obj.associatedParent]) {
                        specificOptionsCopy[obj.associatedParent].firstComponent.props.options = [...obj.props.options];
                    }
                } else {
                    // eslint-disable-next-line no-unused-vars
                    for (const key in specificOptionsCopy[obj.associatedParent]) {
                        if (key === requiredId) {
                            specificOptionsCopy[obj.associatedParent][key].forEach((ele) => {
                                ele.isHighlight = true;
                            });
                            break;
                        }
                    }
                }
                if (isMergeSelect) {
                    // specificOptions[obj.associatedParent].firstComponent.id = obj.id;
                    componentsArray.push(specificOptionsCopy[obj.associatedParent]?.firstComponent);
                } else {
                    // eslint-disable-next-line no-unused-vars
                    for (const key in specificOptionsCopy[obj.associatedParent]) {
                        if (key !== 'firstComponent') {
                            optionsArray.push(...specificOptionsCopy[obj.associatedParent][key]);
                        }
                    }
                    specificOptionsCopy[obj.associatedParent].firstComponent.props.options = [...optionsArray];
                    componentsArray.push(specificOptionsCopy[obj.associatedParent].firstComponent);
                }
            } else if (Object.prototype.hasOwnProperty.call(specificOptions, obj.associatedParent)) {
                if (isMergeSelect) {
                    componentsArray.push(specificOptions[obj.associatedParent].firstComponent);
                } else {
                    // eslint-disable-next-line no-unused-vars
                    for (const key in specificOptions[obj.associatedParent]) {
                        if (key !== 'firstComponent') {
                            optionsArray.push(...specificOptions[obj.associatedParent][key]);
                        }
                    }
                    specificOptions[obj.associatedParent].firstComponent.props.options = [...optionsArray];
                    componentsArray.push(specificOptions[obj.associatedParent].firstComponent);
                }
            }
        }
    }

    // TODO this should be done in the context and only when the selectedPOI changes, not on every congtainer render...
    if (selectedPOI) {
        const flatternPOI = flattenSelected(ProcedureLibrary, selectedPOI);
        flatternPOI.forEach((item) => {
            poiId.push(item.id); // this should be a map of poi keys so that a loop isn't needed later.
        });
    }

    /* eslint-disable jsx-a11y/no-static-element-interactions */
    const renderComponent = (item, index) => {
        if (!item) {
            return false;
        }

        if (props?.componentParentCip?.components?.[componentIndexCip]?.component === 'LR Mixed Form' && item.type === 'Cell') {
            return false;
        }

        let isShowComponent = true;
        const filterPOI = [];

        if (!ComponentViewLookup[item.type === 'TextAreaCF' || item?.type === 'TextBoxCF' ? 'Checkbox' : item?.type]) {
            return item.type;
        }

        const poiResponses = (poiData?.['components_poi'] || [])?.find((poi) => poi.protocol_id === item.props?.protocolId);
        const poiValue = poiResponses?.['poi_data'];
        if (isProgression) {
            if (workshopAccessData[item.id] && workshopAccessData[item.id][selectedView] === false) {
                isShowComponent = false;
            }
        } else if (columnId === selectedColumns?.id && workshopAccessData[item.id] && workshopAccessData[item.id][selectedView] === false) {
            isShowComponent = false;
        }
        if (!isProgression && workshopAccessData[item.id] && workshopAccessData[item.id][selectedView] === false) {
            isShowComponent = false;
        }

        if (!inWorkshop && poiValue && poiId && poiId.length > 0) {
            // eslint-disable-next-line array-callback-return
            poiId.filter((poiItem) => { // TODO see comments above about not having a loop here.
                if (poiValue[poiItem]) {
                    filterPOI.push(poiItem);
                }
            });
            if (selectedView) {
                isShowComponent = !!((filterPOI.length > 0) && workshopAccessData[item.id][selectedView]);
            } else {
                isShowComponent = (filterPOI.length > 0);
            }
        }

        if (props.dmColumViewType) {
            item.dmColumViewType = props.dmColumViewType;
        }
        if (props.selectedDropdownValue) {
            item.selectedDropdownValue = props.selectedDropdownValue;
        }

        if (isDMRC && isDMViewMode && (item.type === 'Protocol' || item.type === 'Text' || item.type === 'ProtocolGroup')) {
            const usersData = [
                { id: 1, type: 'Staff' },
                { id: 2, type: 'Patients' },
                { id: 3, type: 'All' },
            ];
            let componentUserAccess = null;
            let selectedOrgAccess = null;
            let selectedUserAccess = null;
            let userType = null;
            const { selectedDropdownValue = { org: 1, userType: 1 } } = item;
            if (userAccess) {
                const userData = usersData.find((user) => user.id === selectedDropdownValue.userType);
                userType = userData.type;
                componentUserAccess = userAccess.find((access) => access.protocol_id === item.id);
                selectedOrgAccess = componentUserAccess && componentUserAccess.access_data.Org.find(
                    (orgAccess) => orgAccess.orgId === selectedDropdownValue.org
                );
                selectedUserAccess = selectedOrgAccess && selectedOrgAccess.visibility.find((obj) => userType in obj);
            }

            const type = props.dmColumViewType || item.dmColumViewType;
            switch (type) {
            case 'Medguide':
                isShowComponent = true;
                break;
            case 'Org':
                isShowComponent = selectedOrgAccess?.isVisible || false;
                break;
            case 'User':
                isShowComponent = selectedUserAccess?.[userType][0] || false;
                break;
            case 'Advance':
                isShowComponent = selectedUserAccess?.[userType][1] || false;
                break;
            case 'Basic':
                isShowComponent = selectedUserAccess?.[userType][2] || false;
                break;
            default:
            }
        }

        if (!isShowComponent) {
            return null;
        }

        // Context menu Hack.
        // We can do this better
        let onContextMenu;

        if (inWorkshop || item.type === 'Protocol' || item.type === 'Progression' || item.type === 'ProtocolGroup' || item.type === 'Text') {
            onContextMenu = (e) => {
                if (loggedUserType === 'Doctor' && category === 'Shared') return;
                handleContextMenu(e, item, props, index, column);
            };
        }

        const componentRef = getComponentRef(item, inWorkshop);

        const subComponents = [];
        buildSettingsTreePage(subComponents, item);
        subComponents.forEach((comp) => {
            comp.previewDomain = previewDomain;
        });

        componentWithId(item.id, item.props, components); // atomize the component
        if (previewDomain) {
            item.previewDomain = previewDomain;
        }
        item.props.selectId = item.id;
        let ComponentViewLookupJsx = null;

        if (!inWorkshop && ((item.type === 'Select' || item.type === 'MultiSelect') && !item.props.isMixedForm)
            && !item.mirrorId && item.props.renderAs !== 'radio'
            && item.props.renderAs !== 'Checkbox' && !Object.prototype.hasOwnProperty.call(item.props, 'associatedCell') && !isParentRadio) {
            // eslint-disable-next-line no-unused-vars
            for (const i of componentsArray) {
                if (i.id === item.id || `${i.id}-preview` === item.id) {
                    ComponentViewLookupJsx = (
                        <Fragment key={index}>
                            <div
                                ref={componentRef}
                                style={{
                                    ...styleComponent({
                                        component: i,
                                        inWorkshop,
                                        isSelectedComp: isTimlineCol
                                            && contextMenuPosition && contextMenuTarget?.component?.id === i?.id,
                                    }),
                                }}
                                onContextMenu={!(category === 'Super'
                                    // || category === 'Shared'
                                    || loggedUserType === 'Admin'
                                    || loggedUserType === 'User'
                                    || loggedUserType === 'Patient'
                                )
                                    ? onContextMenu : undefined}
                                key={i.id}
                                id={`${inWorkshop || false}-${i.id}`}
                            >
                                {i.type === 'Select' ? React.createElement(ComponentViewLookup[i.type],
                                    { componentProps: i, componentColumn: column })
                                    : React.createElement(ComponentViewLookup[i.type], i)}
                            </div>
                        </Fragment>
                    );
                    break;
                }
            }
        } else {
            let reactElement = null;
            if (item.type === 'Protocol') {
                reactElement = React.createElement(ComponentViewLookup[item.type], {
                    componentProps: componentPropsCip || item,
                    componentParent: componentParentCip || props,
                    componentIndex: componentIndexCip || index,
                    componentColumn: componentColumnCip || column,
                    flexTextField,
                });
            } else if (item.type === 'Row') {
                reactElement = React.createElement(ComponentViewLookup[item.type], {
                    row: item,
                    componentParent: componentParentCip,
                    componentIndex: componentIndexCip,
                    componentColumn: column,
                    flexTextField,
                });
            } else if (item.type === 'Cell') {
                if ((inWorkshop || !item?.skipTimelinePreview)
                        && props?.componentParentCip?.components?.[0]?.component !== 'LR Mixed Form') {
                    reactElement = React.createElement(ComponentViewLookup[item.type], {
                        Cell: item,
                        componentParent: componentParentCip,
                        componentIndex: componentIndexCip,
                        componentColumn: column,
                    });
                }
            } else if (item.type === 'SelectCell') {
                if (inWorkshop || !item?.skipTimelinePreview) {
                    reactElement = React.createElement(ComponentViewLookup[item.type], {
                        Cell: item,
                        componentParent: componentParentCip,
                        componentIndex: componentIndexCip,
                        componentColumn: column,
                    });
                }
            } else if (item.type === 'EmptyCell') {
                if (inWorkshop || !item?.skipTimelinePreview) {
                    reactElement = React.createElement(ComponentViewLookup[item.type], item);
                }
            } else if (item.type === 'OptionCell') {
                reactElement = React.createElement(ComponentViewLookup[item.type], {
                    Cell: item,
                    componentParent: componentParentCip,
                    componentIndex: componentIndexCip,
                    componentColumn: column,
                });
            } else if (item.type === 'Label') {
                reactElement = React.createElement(ComponentViewLookup[item.type], {
                    componentProps: item,
                    componentParent2: componentParentCip,
                    componentIndex: componentIndexCip,
                    componentColumn: column,
                });
            } else if (item.type === 'Checkbox') {
                if (complexFormType === 'Select and Suggest') {
                    item.props.cfType = 'Select and Suggest';
                    item.props.checkboxIndex = index;
                }
                reactElement = React.createElement(ComponentViewLookup[item.type], {
                    componentProps: item,
                    componentParent2: componentParentCip,
                    componentIndex2: componentIndexCip,
                    componentColumn: column,
                    complexFormColumnIndex,
                });
            } else if (item?.type === 'TextAreaCF') {
                reactElement = React.createElement(ComponentViewLookup?.Checkbox, {
                    componentProps: item,
                    componentParent2: componentParentCip,
                    componentIndex2: componentIndexCip,
                    componentColumn: column,
                    complexFormColumnIndex,
                    renderAsTextArea: true,
                    flexTextField,
                });
            } else if (item?.type === 'TextBoxCF') {
                reactElement = React.createElement(ComponentViewLookup?.Checkbox, {
                    componentProps: item,
                    componentParent2: componentParentCip,
                    componentIndex2: componentIndexCip,
                    componentColumn: column,
                    complexFormColumnIndex,
                    renderAsTextBox: true,
                });
            } else if (item.type === 'Select') {
                reactElement = React.createElement(ComponentViewLookup[item.type], {
                    componentProps: item,
                    componentParent2: componentParentCip,
                    componentIndex: componentIndexCip,
                    componentColumn: column,
                });
            } else if (item.type === 'Gallery') {
                reactElement = React.createElement(ComponentViewLookup[item.type], {
                    config: item,
                    componentParent: componentParentCip,
                    componentIndex: componentIndexCip,
                    componentColumn: column,
                });
            } else if (item.type === 'Image') {
                reactElement = React.createElement(ComponentViewLookup[item.type], {
                    config: item,
                    componentParent: componentParentCip,
                    componentIndex: componentIndexCip,
                    componentColumn: column,
                });
            } else if (item.type === 'Carousel') {
                reactElement = React.createElement(ComponentViewLookup[item.type], {
                    props: item,
                    componentParent: componentParentCip,
                    componentIndex: componentIndexCip,
                    componentColumn: column,
                });
            } else if (item.type === 'TextInput') {
                item.props.position = index + 1;
                item.props.listTypePreview = listTypePreview;
                reactElement = React.createElement(ComponentViewLookup[item.type], { item, componentColumn: column }, componentParentCip);
            } else {
                reactElement = React.createElement(ComponentViewLookup[item.type], item, componentParentCip);
            }
            ComponentViewLookupJsx = (
                components?.[0]?.type === 'TextInput'
                    ? (
                        <Grid
                            item
                            xs={12 / flexTextField}
                            style={{
                                borderRight: (index) % flexTextField !== 0 ? '.25px solid #cbcbcb' : 'none',
                                display: (flexTextField > 1 && index === 0
                                    && (workshopComponent?.isProgression || !workshopComponent)) ? 'none' : 'block',
                                width: '100%',
                            }}
                        >
                            <Fragment key={index}>
                                <div
                                    ref={componentRef}
                                    style={{
                                        ...styleComponent({
                                            component: item,
                                            inWorkshop,
                                            isSelectedComp: isTimlineCol && contextMenuPosition && contextMenuTarget?.component?.id === item?.id,
                                        }),
                                        textAlign: item.type === 'DatePicker'
                                            && selectedTemplate?.name === 'Date Picker- Without Protocol' && 'center',
                                        width: item?.type === 'TextInput' && '100%',
                                    }}
                                    onContextMenu={!(category === 'Super'
                                        // || category === 'Shared'
                                        || loggedUserType === 'Admin'
                                        || loggedUserType === 'User'
                                        || loggedUserType === 'Patient'
                                        // || columnWidthData?.column?.props?.isProfile
                                    ) ? onContextMenu : undefined}
                                    key={item.id}
                                    // eslint-disable-next-line react/no-unknown-property
                                    render-as={item?.props?.renderAs === 'radio' ? 'radio' : 'notRadio'}
                                    id={`${inWorkshop || false}-${item.id}`}
                                >
                                    {reactElement}
                                </div>
                            </Fragment>
                        </Grid>
                    )
                    : (
                        <Fragment key={index}>
                            <div
                                ref={componentRef}
                                style={{
                                    ...styleComponent({
                                        component: item,
                                        inWorkshop,
                                        isSelectedComp: isTimlineCol && contextMenuPosition && contextMenuTarget?.component?.id === item?.id,
                                    }),
                                    textAlign: item.type === 'DatePicker'
                                        && selectedTemplate?.name === 'Date Picker- Without Protocol' && 'center',
                                    width: item?.type === 'TextInput' && '100%',
                                }}
                                onContextMenu={!(category === 'Super'
                                    // || category === 'Shared'
                                    || loggedUserType === 'Admin'
                                    || loggedUserType === 'User'
                                    || loggedUserType === 'Patient'
                                    // || columnWidthData?.column?.props?.title === 'Profile'
                                ) ? onContextMenu : undefined}
                                key={item.id}
                                // eslint-disable-next-line react/no-unknown-property
                                render-as={item?.props?.renderAs === 'radio' ? 'radio' : 'notRadio'}
                                id={`${inWorkshop || false}-${item.id}`}
                                onClick={(e) => !inWorkshop && handleColumnClick(e, column, item)}
                            >
                                {reactElement}
                            </div>
                        </Fragment>
                    )
            );
        }
        return (ComponentViewLookupJsx);
    };

    const getDynamic = (flexTextField) => {
        if (flexTextField && components?.[0]?.type === 'Checkbox') {
            return `Cb${flexTextField}`;
        }
        if (flexTextField) {
            return `${flexTextField}`;
        }
        return false;
    };

    const defineClass = `dynamicClass${getDynamic(flexTextField)}`;
    const compTypes = ['TextInput', 'TextAreaCF', 'Select', 'Checkbox'];

    return (
        compTypes.includes(components?.[0]?.type)
            ? (
                <Grid
                    container
                    className={components?.[0]?.type === 'TextAreaCF' || components?.[0]?.type === 'Select' || components?.[0]?.type === 'Checkbox'
                        ? `${classes.containerStyle} ${classes.columnDivided} ${classes[defineClass]
                            ? classes[defineClass] : 'dynamicClass1'}` : classes.containerStyle}
                    onClick={(e) => !inWorkshop && handleColumnClick(e, column)}
                    style={{
                        display: components?.[0]?.type === 'TextAreaCF' ? 'grid' : '',
                        gridTemplateColumns: components?.[0]?.type === 'TextAreaCF' ? `repeat(${flexTextField}, 1fr)` : '',
                    }}
                >
                    {single ? renderComponent(components[singleIndex], singleIndex) : components.map(renderComponent)}
                </Grid>
            )
            : (
                // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                <div className={classes.containerStyle}>
                    {single ? renderComponent(components[singleIndex], singleIndex) : components.map(renderComponent)}
                </div>
            )
    );
};

const ContainerPreview = ({ props, single = false, singleIndex = 0 }) => {
    const classes = containerStyles();
    const {
        components = [], isAdminSetting = false, isTimlineCol = false, isMergeSelect = false,
    } = props;
    const { specificOptions, specificSelect, associationValue } = useContext(TimelineContext);
    const inWorkshop = false;

    const propsCopy = JSON.parse(JSON.stringify(props));// doing deep copy
    const componentsCopy = propsCopy.components ? propsCopy.components : [];
    let optionsArray = [];
    const componentsArray = [];
    if (!inWorkshop) {
        let obj = null;
        componentsCopy.forEach((ele) => {
            obj = { ...ele };
            if ((ele?.type === 'Select' || ele?.type === 'MultiSelect') && !ele.mirrorId && ele.props.renderAs !== 'radio'
                && ele.props.renderAs !== 'Checkbox'
                && !Object.prototype.hasOwnProperty.call(ele.props, 'associatedCell') && !specificSelect[ele.associatedParent]) {
                if (isMergeSelect) {
                    specificOptions[ele.associatedParent] = {
                        firstComponent: { ...ele },
                    };
                } else if (Object.prototype.hasOwnProperty.call(specificOptions, ele.associatedParent)) {
                    ele.props.options.forEach((opt) => {
                        opt.requiredId = ele.id;
                    });
                    specificOptions[ele.associatedParent][ele.id] = [...ele.props.options];
                } else {
                    ele.props.options.forEach((opt) => {
                        opt.requiredId = ele.id;
                    });
                    specificOptions[ele.associatedParent] = {
                        firstComponent: { ...ele },
                    };
                    specificOptions[ele.associatedParent] = { ...specificOptions[ele.associatedParent], [ele.id]: [...ele.props.options] };
                }
            }
        });
        if (obj) {
            if (Object.prototype.hasOwnProperty.call(specificSelect, obj.associatedParent) && !obj.props.isHighlight) {
                const requiredId = specificSelect[obj.associatedParent];
                if (Array.isArray(requiredId)) {
                    // eslint-disable-next-line no-unused-vars
                    for (const key in specificOptions[obj.associatedParent]) {
                        if (requiredId.length > 0) {
                            if (requiredId.includes(key)) {
                                optionsArray = [...optionsArray, ...specificOptions[obj.associatedParent][key]];
                            }
                        } else if (key !== 'firstComponent') {
                            optionsArray = [...optionsArray, ...specificOptions[obj.associatedParent][key]];
                        }
                    }
                } else {
                    // eslint-disable-next-line no-unused-vars
                    for (const key in specificOptions[obj.associatedParent]) {
                        if (key === requiredId) {
                            optionsArray = [...specificOptions[obj.associatedParent][key]];
                            break;
                        }
                    }
                }
                if (specificOptions[obj.associatedParent]) {
                    if (optionsArray.length === 0) {
                        specificOptions[obj.associatedParent].firstComponent.props.options = [...obj.props.options];
                    } else {
                        specificOptions[obj.associatedParent].firstComponent.props.options = [...optionsArray];
                    }
                    specificOptions[obj.associatedParent].firstComponent.props.currentAssociation = optionsArray.length > 0
                        ? optionsArray[0].value : '';
                    componentsArray.push(specificOptions[obj.associatedParent].firstComponent);
                }
            } else if (Object.prototype.hasOwnProperty.call(specificSelect, obj.associatedParent) && obj.props.isHighlight) {
                const requiredId = specificSelect[obj.associatedParent];
                const specificOptionsCopy = JSON.parse(JSON.stringify(specificOptions));
                let hasKey = false;
                if (Array.isArray(requiredId)) {
                    // eslint-disable-next-line no-unused-vars
                    for (const key in specificOptionsCopy[obj.associatedParent]) {
                        if (requiredId.includes(key)) {
                            hasKey = true;
                            specificOptionsCopy[obj.associatedParent][key].forEach((ele) => {
                                ele.isHighlight = true;
                                ele.aData = associationValue[key];
                            });
                        }
                    }
                    if (!hasKey && specificOptionsCopy[obj.associatedParent]) {
                        specificOptionsCopy[obj.associatedParent].firstComponent.props.options = [...obj.props.options];
                    }
                } else {
                    // eslint-disable-next-line no-unused-vars
                    for (const key in specificOptionsCopy[obj.associatedParent]) {
                        if (key === requiredId) {
                            // eslint-disable-next-line no-return-assign
                            specificOptionsCopy[obj.associatedParent][key].forEach((ele) => ele.isHighlight = true);
                            break;
                        }
                    }
                }
                if (isMergeSelect) {
                    // specificOptions[obj.associatedParent].firstComponent.id = obj.id;
                    componentsArray.push(specificOptionsCopy[obj.associatedParent]?.firstComponent);
                } else {
                    // eslint-disable-next-line no-unused-vars
                    for (const key in specificOptionsCopy[obj.associatedParent]) {
                        if (key !== 'firstComponent') {
                            optionsArray.push(...specificOptionsCopy[obj.associatedParent][key]);
                        }
                    }
                    specificOptionsCopy[obj.associatedParent].firstComponent.props.options = [...optionsArray];
                    componentsArray.push(specificOptionsCopy[obj.associatedParent].firstComponent);
                }
            } else if (Object.prototype.hasOwnProperty.call(specificOptions, obj.associatedParent)) {
                if (isMergeSelect) {
                    // specificOptions[obj.associatedParent].firstComponent.id = obj.id;
                    componentsArray.push(specificOptions[obj.associatedParent].firstComponent);
                } else {
                    // eslint-disable-next-line no-unused-vars
                    for (const key in specificOptions[obj.associatedParent]) {
                        if (key !== 'firstComponent') {
                            optionsArray.push(...specificOptions[obj.associatedParent][key]);
                        }
                    }
                    specificOptions[obj.associatedParent].firstComponent.props.options = [...optionsArray];
                    componentsArray.push(specificOptions[obj.associatedParent].firstComponent);
                }
            }
        }
    }

    const renderComponent = (component) => {
        const render = ComponentPreview[component.type];
        if (!render) {
            return component.type;
        }
        component.props.selectId = component.id;
        let ComponentViewLookupJsx = null;
        if (!inWorkshop && (component.type === 'Select' || component.type === 'MultiSelect')
            && !component.mirrorId && component.props.renderAs !== 'radio' && component.props.renderAs !== 'Checkbox'
            && !Object.prototype.hasOwnProperty.call(component.props, 'associatedCell')) {
            // eslint-disable-next-line no-unused-vars
            for (const i of componentsArray) {
                if (i.id === component.id || `${i.id}-preview` === component.id/* && i.props.options.length > 0 */) {
                    ComponentViewLookupJsx = React.createElement(render, { component, isAdminSetting, isTimlineCol });
                    break;
                }
            }
        } else {
            ComponentViewLookupJsx = React.createElement(render, { component, isAdminSetting, isTimlineCol });
        }
        return ComponentViewLookupJsx;
    };

    return (
        <div
            className={`${classes.containerStyle} ${components[0]?.type === 'TextInput'
                && classes.textInputWrapper} ${components[0]?.type === 'DatePicker' && classes.datePickerWrapper}`}
        >
            {single ? renderComponent(components[singleIndex], singleIndex) : components.map(renderComponent)}
        </div>
    );
};

export { Container, ContainerPreview };
