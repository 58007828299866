import 'date-fns';
import { ADD_TO_ORGANIZATION_MEMBER } from '../context/User';
import {
    Box, Button, Grid, Paper, TextField, Typography,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { OrgContext, UserContext } from '../context';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { emailValidator, getFormattedDate } from '../helper/commonHelper';
import { showLoader } from '../App';
import { toast } from 'react-toastify';
import { useLazyQuery, useMutation } from 'react-apollo';
import Backdrop from '@material-ui/core/Backdrop';
import CloseIcon from '@material-ui/icons/Close';
import DateFnsUtils from '@date-io/date-fns';
import MessageDialog from './dialog/MessageDialog';
import Modal from '@material-ui/core/Modal';
import React, {
    Fragment, useContext, useEffect, useState,
} from 'react';
import gql from 'graphql-tag';

const CHECK_USER_EXISTENCE = gql`
    query user($email: String) {
        user(where: { is_active: { _eq: true }, email: { _eq: $email } }) {
            id,
            name,
            email,
            organization_members{
                role
                organization_id
            }
        },
    }
`;

/**
 * @function useStyles
 * @description css for modal popup
 * @returns {object}
 */
const useStyles = makeStyles((theme) => createStyles({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: '.25rem',
        boxShadow: theme.shadows[5],
        maxWidth: 700,
        padding: '1rem 0rem',
    },
    modalHeader: {
        borderBottom: '1px solid #cbcbcb',
        padding: '0rem 1rem .375rem 1rem',
    },
    modalCancel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    submitButton: {
        margin: 8,
    },
    layoutTypeWidth: {
        width: '100%',
    },
    primaryBtn: {
        maxHeight: '56px',
        padding: '.25rem 1rem',
        boxShadow: '0px 0px 20px -4px rgba(51,36,5,0.37)',
        '&:hover': {
            boxShadow: 'none',
        },
    },
    cancelBtn: {
        maxHeight: '56px',
        padding: '.25rem 1.5rem',
        boxShadow: 'none',
        marginLeft: '1rem',
        '&:hover': {
            boxShadow: 'none',
        },
    },
    showError: {
        color: '#DC143C',
    },
    genricDate: {
        width: '100%',
    },
    buttonCont: {
        textAlign: 'end',
    },
    closeIcon: {
        cursor: 'pointer',
    },
}));

const messageObj = {
    title: 'Information',
    content: 'is already registered in the other organzation, are you want add it in your organzation?',
};

/**
 * @constructor EditorModal
 * @description create the modal pop. Taking props from parent and pass props to child component
 * @param {orgID}
 * @param {selectedPatient}
 * @param {toggleDrawer}
 * @param {initialPatientData}
 */
const ManagePatient = ({
    orgID, selectedPatient, initialPatientData = null, routeLabel, open, handleClose, timelineUser = null,
}) => {
    const classes = useStyles();
    const [loaderState, setLoaderState] = useState(false);
    const [messageState, setMessageState] = useState({ open: false });
    const {
        validateUserEmail,
        userDetailsContext,
        updateUserOrganizationContext,
        addNewUser,
    } = useContext(UserContext);
    const { loggedInUserDetails } = useContext(OrgContext);
    let patientData = (!userDetailsContext) ? [] : userDetailsContext;
    const [state, setState] = useState({
        formData: {
            patientEmail: '',
            patientName: '',
            lastName: '',
            phoneNo: '',
            dob: new Date(),
            patientRole: routeLabel === 'Patient' ? routeLabel : '',
        },
        error: {
            patientEmail: false,
            patientName: false,
            patientRole: false,
            orgEmail: false,
        },
        isEmailVerified: false,
        isShowValidateBtn: true,
        patientID: null,
        patientCreationDate: '',
        isDateCreation: false,
        isPatientFromSameOrg: false,
    });
    const [validateError, setValidateError] = useState(false);
    const [checkUserExistence] = useLazyQuery(CHECK_USER_EXISTENCE, {
        onCompleted: (data) => {
            if (data.user.length > 0) {
                const findUser = data.user[0]?.['organization_members']?.find(
                    (u) => u.organization_id === loggedInUserDetails?.['organization_member'][0]?.organization.id);
                if (findUser) {
                    setValidateError(true);
                } else {
                    setMessageState({ ...messageState, open: true, patientDetail: data.user[0] });
                }
            } else {
                setState({
                    ...state,
                    isEmailVerified: true,
                    isDateCreation: false,
                    patientID: null,
                    formData: {
                        ...state.formData,
                        patientName: '',
                    },
                });
            }
        },
    });

    const [createUserOrgMember] = useMutation(ADD_TO_ORGANIZATION_MEMBER);

    /**
     * @function onHandleChange
     * @description set the updated value
     */
    const onHandleChange = (event) => {
        if (!event.target) {
            setState({
                ...state,
                formData: {
                    ...state.formData,
                    dob: event,
                },
            });
        } else {
            const { name, value } = event.target;
            let status = (!(value));

            if (name === 'patientEmail' || name === 'orgEmail') {
                status = !(emailValidator(value));
            }

            setState({
                ...state,
                formData: {
                    ...state.formData,
                    [name]: value,
                },
                error: {
                    ...state.error,
                    [name]: status,
                },
                isShowValidateBtn: status,
            });
        }
    };

    /**
     * @function onVerifyEmail
     * @description get the entered email to validate, is it valid or not
     */
    const onVerifyEmail = () => {
        validateUserEmail(state.formData.patientEmail);
        setValidateError(false);
        checkUserExistence({
            variables: {
                email: state?.formData?.patientEmail,
            },
        });
    };

    /**
     * @function onSubmit
     * @description pass the patient data to save into database
     */
    const onSubmit = async () => {
        let response;
        const requestObj = {
            userRole: state.formData.patientRole,
            orgID,
            userName: state.formData.patientName,
            userEmail: state.formData.patientEmail,
            last_name: state.formData.lastName,
            dob: state.formData.dob,
            phone_no: state.formData.phoneNo,
        };

        if (state.error.patientName || state.error.patientRole || !state.formData.patientName || !state.formData.patientRole) {
            return false;
        }

        if ((patientData && patientData.user && patientData.user.length > 0) || Object.values(selectedPatient).length > 0) {
            requestObj.isUserFromSameOrg = state.isPatientFromSameOrg;
            requestObj.userID = state.patientID;
            setLoaderState(true);
            response = await updateUserOrganizationContext(requestObj);
        } else {
            setLoaderState(true);
            response = await addNewUser(requestObj);
        }

        if (response) {
            const message = (state.isPatientFromSameOrg) ? `${routeLabel} Updated successfully` : `${routeLabel} added successfully`;
            toast.success(message);
            if (timelineUser) {
                timelineUser(response?.data?.signup?.id);
            }
            setLoaderState(false);
            handleClose();
        } else {
            setLoaderState(false);
            toast.error('Please try again!!!');
        }

        if (initialPatientData) initialPatientData();
        patientData = [];

        return null;
    };

    const handleMessageDialog = () => {
        setMessageState({ ...messageState, open: false });
    };

    const handleMessageSubmit = async () => {
        const queryObj = {
            variables: {
                userID: messageState.patientDetail.id,
                orgID: loggedInUserDetails?.['organization_member'][0]?.organization.id,
                userRole: messageState.patientDetail.organization_members[0].role,
            },
        };
        try {
            await createUserOrgMember(queryObj);
            setMessageState({ ...messageState, open: false });
            handleMessageDialog();
            handleClose();
            toast.success('Patient added successfully in your organzation.');
        } catch (error) {
            toast.info('Something went wrong');
        }
    };

    useEffect(() => {
        const patientDataCount = (patientData && patientData.user && patientData.user.length) ? patientData.user.length : 0;

        if ((state.isEmailVerified && patientDataCount > 0) || Object.values(selectedPatient).length > 0) {
            let validatedData;
            let checkOrgCount = [];

            if (Object.values(selectedPatient).length > 0) {
                validatedData = selectedPatient;

                validatedData.role = selectedPatient.organization_members[0].role;
            } else {
                // eslint-disable-next-line prefer-destructuring
                validatedData = patientData.user[0];
                checkOrgCount = Object.values(validatedData.organization_members).filter((data) => data.organization_id === orgID);
            }

            const checkOrg = !!((checkOrgCount.length > 0 || Object.values(selectedPatient).length > 0));

            if (!state.isEmailVerified || patientDataCount > 0) {
                setState({
                    ...state,
                    formData: {
                        ...state.formData,
                        patientName: validatedData.name,
                        patientRole: validatedData.role,
                        patientEmail: validatedData.email,
                        lastName: validatedData.last_name,
                        dob: validatedData.dob,
                        phoneNo: validatedData.phone_no,
                    },
                    patientID: validatedData.id,
                    patientCreationDate: getFormattedDate(validatedData.created_at),
                    isDateCreation: true,
                    isEmailVerified: true,
                    isPatientFromSameOrg: checkOrg,
                });
            }
        }
    }, [patientData, selectedPatient]);

    /**
     * @function renderActionButton
     * @description enable and disable the action button
     */
    const renderActionButton = () => {
        let isDisable = true;

        if (state.formData.patientName && state.formData.patientRole) {
            isDisable = !!(routeLabel === 'Doctor' && !state.patientID);
        }
        return (
            <Fragment>
                <Box clone mt={1.5}>
                    <Grid container>
                        <Grid item xs={12} className={classes.buttonCont}>
                            <Button
                                className={classes.primaryBtn}
                                disabled={isDisable}
                                variant="contained"
                                color="primary"
                                onClick={onSubmit}
                            >
                                {state.patientID ? 'Update' : 'Save'}
                                {' '}
                                {routeLabel}
                            </Button>
                            <Button
                                className={classes.cancelBtn}
                                variant="contained"
                                color="default"
                                onClick={() => handleClose()}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Fragment>
        );
    };

    /**
     * @function renderAdditionalField
     * @description render name field and patient role field
     */
    const renderAdditionalField = () => {
        let isDisable = false;

        if (!state.isPatientFromSameOrg && (patientData && patientData.user && patientData.user.length > 0)) {
            isDisable = true;
        }

        if (state.isEmailVerified) {
            return (
                <Fragment>
                    <Grid item md={12} lg={12}>
                        <Box my={2}>
                            <TextField
                                fullWidth
                                id="outlined-basic"
                                label="First Name"
                                variant="outlined"
                                name="patientName"
                                value={state.formData.patientName}
                                onChange={onHandleChange}
                                autoComplete="false"
                                disabled={isDisable}
                                required
                            />
                            <p className={classes.showError}>{(state.error.patientName) ? "Name can't be blank. " : ''}</p>
                        </Box>
                    </Grid>
                    {routeLabel === 'Patient'
                        && (
                            <Grid item md={12} lg={12}>
                                <Box my={2}>
                                    <TextField
                                        fullWidth
                                        id="outlined-basic"
                                        label="Last Name"
                                        variant="outlined"
                                        name="lastName"
                                        onChange={onHandleChange}
                                        autoComplete="false"
                                        value={state.formData.lastName}
                                    />
                                </Box>
                            </Grid>
                        )}
                </Fragment>
            );
        }
        return '';
    };

    /**
     * @function renderValidationButton
     * @description render the validation button conditionally
     */
    const renderValidationButton = () => {
        if (!state.isEmailVerified) {
            return (
                <Box my={2}>
                    {messageState.open && (
                        <MessageDialog
                            open={messageState.open}
                            title={`${messageState.patientDetail.name} ${messageObj.content}`}
                            handleClose={handleMessageDialog}
                            handleSubmit={handleMessageSubmit}
                        />
                    )}
                    { validateError && <p className={classes.showError}>User is already registered, please try different email id.</p>}
                    <Button
                        className={classes.primaryBtn}
                        disabled={state.isShowValidateBtn}
                        variant="contained"
                        color="primary"
                        onClick={onVerifyEmail}
                    >
                        Verify Email
                    </Button>
                </Box>
            );
        }

        return '';
    };

    /**
     * @function renderDateCreation
     * @description render the patient creation date
     */
    const renderDateCreation = () => {
        const isDisable = true;

        if (state.isDateCreation && routeLabel !== 'Doctor') {
            return (
                <Grid item md={12} lg={12}>
                    <Box my={2}>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Date of Joining"
                            variant="outlined"
                            name="patientCreationDate"
                            value={state.patientCreationDate}
                            onChange={onHandleChange}
                            autoComplete="false"
                            disabled={isDisable}
                        />
                    </Box>
                </Grid>
            );
        }
        return '';
    };

    const renderDoctorMoreField = () => {
        if (state.isEmailVerified) {
            return (
                <>
                    <Grid item md={12} lg={12}>
                        <Box my={2}>
                            <TextField
                                fullWidth
                                id="outlined-basic"
                                label="Phone No."
                                variant="outlined"
                                name="phoneNo"
                                onChange={onHandleChange}
                                autoComplete="false"
                                value={state.formData.phoneNo}
                            />
                        </Box>
                    </Grid>
                    <Grid item md={12} lg={12}>
                        <Box mb={1}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    className={classes.genricDate}
                                    disableToolbar
                                    variant="outlined"
                                    inputVariant="outlined"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    label="DOB"
                                    value={state.formData.dob}
                                    id="date-picker-inline"
                                    onChange={(e) => onHandleChange(e)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Box>
                    </Grid>
                </>
            );
        }
        return null;
    };

    return (
        <Modal
            aria-labelledby="spring-modal-title"
            aria-describedby="spring-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <div className={classes.paper}>
                {loaderState && showLoader(loaderState)}
                <Grid container className={classes.modalHeader}>
                    <Grid item md={11}>
                        <Typography variant="h2" id="simple-modal-title">
                            Manage
                            {' '}
                            {`${routeLabel}s`}
                        </Typography>
                    </Grid>
                    <Grid item md={1} className={classes.buttonCont}>
                        <CloseIcon className={classes.closeIcon} onClick={() => handleClose()} />
                    </Grid>
                </Grid>
                <Box px={4} py={1}>
                    <Grid container>
                        <Grid item md={12} lg={12}>
                            <div>
                                <Box>
                                    <Paper elevation={0}>
                                        <Grid container>
                                            <Grid item md={12} lg={12}>
                                                <Box my={2}>
                                                    <TextField
                                                        fullWidth
                                                        id="outlined-basic"
                                                        label={`${routeLabel} Email`}
                                                        variant="outlined"
                                                        name="patientEmail"
                                                        onChange={onHandleChange}
                                                        autoComplete="false"
                                                        value={state.formData.patientEmail}
                                                        disabled={state.isEmailVerified}
                                                    />
                                                </Box>
                                                <p className={classes.showError}>{(state.error.patientEmail) ? 'Enter the valid email.' : ''}</p>
                                                {renderValidationButton()}
                                            </Grid>
                                            {renderAdditionalField()}
                                            {renderDateCreation()}
                                            {routeLabel === 'Patient' && renderDoctorMoreField()}
                                        </Grid>
                                    </Paper>
                                </Box>
                            </div>
                        </Grid>
                        <Grid item md={12} lg={12}>
                            <Grid container direction="row" justify="flex-end" alignItems="center">
                                {renderActionButton()}
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </Modal>
    );
};

export default ManagePatient;
