import {
    Card, CardContent, CardMedia, Grid, TextField,
} from '@material-ui/core';
import { ComponentViewLookup } from '../index';
import { PhotoCamera } from '@material-ui/icons';
import { buildSettingsTreePage, deepCopy } from '../../helper/commonHelper';
import { componentWithId } from '../Atoms';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import MobileStepper from '@material-ui/core/MobileStepper';
import React, { useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '98%',
        margin: 'auto',
        flexGrow: 1,
        position: 'relative',
        '& .fullHeightNav': {
            opacity: 0,
            transition: 'opacity .5s ease-in-out',
        },
        '&:hover .fullHeightNav': {
            opacity: 1,
        },
        '&:hover .fullHeightNav.disabled': {
            pointerEvents: 'none',
            opacity: 0.57,
        },
    },
    rootCD: {
        maxWidth: 495,
        margin: '2.25rem auto',
        flexGrow: 1,
        position: 'relative',
        '& .fullHeightNav': {
            opacity: 0,
            transition: 'opacity .5s ease-in-out',
        },
        '&:hover .fullHeightNav': {
            opacity: 1,
        },
        '&:hover .fullHeightNav.disabled': {
            pointerEvents: 'none',
            opacity: 0.57,
        },
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        height: 50,
        paddingLeft: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
    },
    img: {
        height: 255,
        display: 'block',
        maxWidth: 400,
        overflow: 'hidden',
        width: '100%',
        borderTop: '.25px solid #ececec',
        borderBottom: '.25px solid #ececec',
    },
    inlineNext: {
        position: 'absolute',
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        background: 'transparent',
        padding: 0,
        zIndex: 2,
        color: '#000000de',
        cursor: 'pointer',
    },
    inlineBack: {
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        background: 'transparent',
        padding: 0,
        zIndex: 2,
        color: '#000000de',
        cursor: 'pointer',
    },
    mobileStepper: {
        justifyContent: 'center',
        position: 'absolute',
        bottom: '27px',
        left: '50%',
        transform: 'translate(-50%)',
        padding: '0px',
    },
    mobileStepperCD: {
        justifyContent: 'center',
        position: 'absolute',
        left: '50%',
        transform: 'translate(-50%)',
    },
    contentDisplayImgCont: {
        textAlign: 'center',
        border: '.25px solid #ececec',
        borderBottom: 'none',
        margin: 'auto',
        boxShadow: 'none',
        minWidth: 338.5,
        width: 'max-content',
        '& :nth-child(3)': {
            borderTop: '.25px solid #ececec',
        },
        '& div': {
            padding: '0px !important',
        },
    },
    contentDisplayMulImgCont: {
        textAlign: 'center',
        border: '.25px solid #ececec',
        borderBottom: 'none',
        margin: 'auto',
        boxShadow: 'none',
        '& :nth-child(3)': {
            borderTop: '.25px solid #ececec',
        },
        '& div': {
            padding: '0px !important',
        },
    },
    photoCameraStyle: {
        fontSize: '4.5rem',
        color: '#d3d3d3',
        margin: '25px 0px',
    },
    contentDisplayMulImgContChildStyle: {
        '& :last-child': {
            borderTop: '.25px solid #ececec',
            flex: '1 1 100%',
            maxWidth: '100%',
        },
        '& div': {
            flex: '1 1 auto', /* Initially, all items take equal space */
            maxWidth: '50%', /* Adjust as needed */
        },
    },
    flexItem: {
        flex: '1 1 50%', /* Adjust as needed */
        maxWidth: '50%', /* Adjust as needed */
    },
    lastItem: {
        flex: '1 1 100%',
        maxWidth: '100%',
    },
    imgTitleInput: {
        textAlign: 'center',
        width: '100%',
        border: 'none',
        borderBottom: '.25px solid #ececec',
        display: 'grid',
        '& input': {
            textAlign: 'center',
            fontSize: 11,
            fontWeight: 400,
            padding: 2,
            color: '#000000de',
        },
        '& div:before': {
            borderBottom: 'none !important',
        },
        '& div:after': {
            borderBottom: 'none',
        },
        '& div:hover': {
            borderBottom: 'none',
        },
    },
    imageStyle: {
        display: 'block',
        height: 128,
        margin: 'auto',
    },
}));

function CarouselSwiper({
    props,
    componentParent,
    componentIndex,
    componentColumn,
    contentDisplayImageList,
    setContentDisplayImageList,
    isCD = false,
    fullHeightNav = true,
    inWorkshop,
}) {
    const classes = useStyles();
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const [currentGallery, setCurrentGallery] = useState();
    const [multiSwipeData, setMultiSwipeData] = useState();
    let components = [];
    if (isCD) {
        components = contentDisplayImageList || [];
    } else {
        components = props.props?.components;
    }
    const poppedData = deepCopy(components);
    poppedData.length -= 1;
    poppedData.sort((a, b) => {
        if (a.slideNumber !== b.slideNumber) {
            return a.slideNumber - b.slideNumber;
        }
        return a.positionIndex - b.positionIndex;
    });
    const lastData = components?.[components.length - 1];
    const templateType = lastData?.compTemplate?.component?.[0];
    const maxSteps = templateType === '1' ? poppedData?.length : multiSwipeData?.length;
    function chunkArray(arr, n) {
        const result = [];
        for (let i = 0; i < arr.length; i += n) {
            result.push(arr.slice(i, i + n));
        }
        return result;
    }
    useEffect(() => {
        if (templateType !== '1') {
            const swipeAbleData = chunkArray(poppedData, Number(templateType));
            setMultiSwipeData(swipeAbleData);
        }
        return () => {
            setMultiSwipeData(null);
            setCurrentGallery(null);
        };
    }, [components, templateType]);
    useEffect(() => {
        const parentCompList = [];
        buildSettingsTreePage(parentCompList, lastData?.compTemplate);
        const galleryData = parentCompList.filter((c) => c.type === 'Gallery');
        setCurrentGallery(galleryData);
    }, [components, templateType]);
    const GetGalleryType = (imgData) => (
        <Card className={`${classes.contentDisplayMulImgCont}`}>
            <CardContent>
                {/* <TextField
                    className={classes.imgTitleInput}
                    variant="standard"
                    placeholder="Slide Title"
                    disabled={!inWorkshop}
                    value={imgData?.slideTitle}
                    onChange={(event) => handleTitleOrCaptionChange(event, 'step?.url', 'slideTitle')}
                /> */}
                <TextField
                    className={classes.imgTitleInput}
                    variant="standard"
                    placeholder="Image Title"
                    disabled={!inWorkshop}
                    value={imgData?.imageTitle}
                    onChange={(event) => handleTitleOrCaptionChange(event, 'step?.url', 'imageTitle')}
                />
            </CardContent>
            <CardMedia>
                {imgData?.url ? (
                    <img
                        className={classes.imageStyle}
                        src={imgData?.url}
                        alt={imgData?.imageTitle}
                        loading="lazy"
                    />
                ) : <PhotoCamera className={classes.photoCameraStyle} />}
            </CardMedia>
            <CardContent>
                <TextField
                    className={classes.imgTitleInput}
                    variant="standard"
                    placeholder="Image Caption"
                    disabled={!inWorkshop}
                    value={imgData?.imageCaption}
                    onChange={(event) => handleTitleOrCaptionChange(event, 'step?.url', 'imageCaption')}
                />
                {/* <TextField
                    className={classes.imgTitleInput}
                    variant="standard"
                    placeholder="Slide Caption"
                    disabled={!inWorkshop}
                    value={imgData?.SlideCaption}
                    onChange={(event) => handleTitleOrCaptionChange(event, 'step?.url', 'slideCaption')}
                /> */}
            </CardContent>
        </Card>
    );

    useEffect(() => {
        if (poppedData[activeStep]?.url) {
            setActiveStep((prevActiveStep) => prevActiveStep);
        } else if (activeStep > 0) {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    }, [maxSteps]);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };
    const handleTitleOrCaptionChange = (event, mediaUrl, type) => {
        const contentDisplayImageListCopy = [...contentDisplayImageList];
        for (let i = 0, l = contentDisplayImageListCopy?.length; i < l; i += 1) {
            if (contentDisplayImageListCopy?.[i]?.url === mediaUrl) {
                contentDisplayImageListCopy[i][type] = event?.target?.value;
                break;
            }
        }
        setContentDisplayImageList([...contentDisplayImageListCopy]);
    };

    return (
        <div className={isCD ? classes.rootCD : classes.root}>
            {fullHeightNav && poppedData?.length > 1 && (
                <>
                    <div
                        aria-hidden="true"
                        onClick={handleBack}
                        className={`${classes.inlineBack} fullHeightNav ${activeStep === 0 ? 'disabled' : ''}`}
                    >
                        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                    </div>
                    <div
                        aria-hidden="true"
                        onClick={handleNext}
                        className={`${classes.inlineNext} fullHeightNav ${activeStep === maxSteps - 1 ? 'disabled' : ''}`}
                    >
                        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                    </div>
                </>
            )}

            {templateType === '1' ? (
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                >
                    {poppedData.map((step, index) => {
                        if (!isCD) {
                            componentWithId(step?.id, step?.props, poppedData);
                        }
                        return (
                            <div key={step.id}>
                                {Math.abs(activeStep - index) <= 2 ? (
                                    <>
                                        {
                                            isCD
                                                ? (
                                                    <Card className={classes.contentDisplayImgCont}>
                                                        <CardContent>
                                                            <TextField
                                                                className={classes.imgTitleInput}
                                                                variant="standard"
                                                                placeholder="Slide Title"
                                                                disabled={!inWorkshop}
                                                                value={step?.slideTitle}
                                                                onChange={(event) => handleTitleOrCaptionChange(event, step?.url, 'slideTitle')}
                                                            />
                                                            <TextField
                                                                className={classes.imgTitleInput}
                                                                variant="standard"
                                                                placeholder="Image Title"
                                                                disabled={!inWorkshop}
                                                                value={step?.imageTitle}
                                                                onChange={(event) => handleTitleOrCaptionChange(event, step?.url, 'imageTitle')}
                                                            />
                                                        </CardContent>
                                                        <CardMedia>
                                                            <img
                                                                className={classes.imageStyle}
                                                                src={step?.url}
                                                                alt={step?.imageTitle}
                                                                loading="lazy"
                                                            />
                                                        </CardMedia>
                                                        <CardContent>
                                                            <TextField
                                                                className={classes.imgTitleInput}
                                                                variant="standard"
                                                                placeholder="Image Caption"
                                                                disabled={!inWorkshop}
                                                                value={step?.imageCaption}
                                                                onChange={(event) => handleTitleOrCaptionChange(event, step?.url, 'imageCaption')}
                                                            />
                                                            <TextField
                                                                className={classes.imgTitleInput}
                                                                variant="standard"
                                                                placeholder="Slide Caption"
                                                                disabled={!inWorkshop}
                                                                value={step?.slideCaption}
                                                                onChange={(event) => handleTitleOrCaptionChange(event, step?.url, 'slideCaption')}
                                                            />
                                                        </CardContent>
                                                    </Card>
                                                ) : ComponentViewLookup[step.type] && React.createElement(ComponentViewLookup[step.type], {
                                                    config: step,
                                                    componentParent,
                                                    componentIndex,
                                                    componentColumn,
                                                })
                                        }
                                    </>
                                ) : null}
                            </div>
                        );
                    })}
                </SwipeableViews>
            ) : (
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                >
                    { multiSwipeData?.map((step, index) => {
                        if (!isCD) {
                            componentWithId(step?.id, step?.props, multiSwipeData);
                        }
                        return (
                            <div>
                                { Math.abs(activeStep - index) <= 2 ? (
                                    <Card className={classes.contentDisplayMulImgCont}>
                                        <CardContent>
                                            {/* <TextField
                        className={classes.imgTitleInput}
                        variant="standard"
                        placeholder="Title"
                        disabled={!inWorkshop}
                        value={currentGallery?.props?.title}
                        onChange={(event) => handleTitleOrCaptionChange(event, 'step?.url', 'slideTitle')}
                    /> */}

                                            <TextField
                                                className={classes.imgTitleInput}
                                                variant="standard"
                                                placeholder="Slide Title"
                                                disabled={!inWorkshop}
                                                value={currentGallery?.[index]?.props?.title}
                                                onChange={(event) => handleTitleOrCaptionChange(event, 'step?.url', 'slideTitle')}
                                            />
                                        </CardContent>
                                        <CardMedia>
                                            <Grid container className={classes.contentDisplayMulImgCont}>
                                                {
                        step?.map((val, i) => (
                            <Grid item key={i} xs={12 / poppedData?.length} className={(templateType % 2 !== 0 && templateType - 1 === i) ? classes.lastItem : classes.flexItem}>
                                {GetGalleryType(val)}
                            </Grid>
                        ))
                                                }
                                            </Grid>
                                        </CardMedia>
                                        <CardContent>
                                            <TextField
                                                className={classes.imgTitleInput}
                                                variant="standard"
                                                placeholder="Slide Caption"
                                                disabled={!inWorkshop}
                                                value={currentGallery?.[index]?.props?.caption}
                                                onChange={(event) => handleTitleOrCaptionChange(event, 'step?.url', 'slideCaption')}
                                            />
                                        </CardContent>
                                    </Card>
                                ) : null}
                            </div>
                        );
                    })}
                </SwipeableViews>
            )}
            <MobileStepper
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                variant={poppedData.length === 1 ? 'none' : 'dots'}
                classes={{ root: isCD ? classes.mobileStepperCD : classes.mobileStepper }}
            />
        </div>
    );
}

export default CarouselSwiper;
