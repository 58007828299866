import {
    useHistory,
    useRouteMatch,
} from 'react-router-dom';
import { useLazyQuery } from 'react-apollo';
import { useMutation } from '@apollo/react-hooks';
import React, { createContext, useEffect, useState } from 'react';
import gql from 'graphql-tag';

export const MY_ORGS = gql`
query MyOrgs {
    logged_in_user {
        id,
        name,
        created_at,
        profile_photo,
        email,
        organization_member {
            role,
            active_role,
            organization {
                id,
                name,
                orgCode,
            },
            user {
                industry,
                specialty,
                is_expert,
                dob,
                phone_no,
                last_name,
                name,
                is_dummy_password,
                poi_accesses {
                    poi,
                }
            },
            organization_data {
                name,
                org_email,
            },
        }
    }
}
`;

/**
 * query to update user profile
 */
const UPDATE_USER = gql`
    mutation updateUserDetail($id: uuid, $name: String, $profile_photo: String) {
        changeEmail(userId: $id, name: $name, profilePictureUrl: $profile_photo ) {
            user_id
        }
    } 
`;

/**
 * query to update the user name
 */
const UPDATE_LOGGED_USER = gql`
 mutation updateUser($userID: uuid, $is_dummy_password: Boolean){
    update_user(where: { id: {_eq: $userID} }, _set: {is_dummy_password: $is_dummy_password} ) {
        affected_rows
        returning {
            id
        }
    }
 } 
`;

/**
 * query to update the user password
 */
const UPDATE_USER_PASSWORD = gql`
    mutation changePassword($oldPass: String!, $newPass: String!, $userId: uuid!){
        changePassword(credentials: { newPassword: $newPass, oldPassword: $oldPass }, userId: $userId ) {
            user_id
        }
    }
`;

export const Context = createContext({});
export const Provider = ({ children, idToken }) => {
    const [selectedOrg, setSelectedOrg] = useState(null);
    const [getMyOrgs, { loading: orgsLoading, error: orgsError, data: myorgs }] = useLazyQuery(MY_ORGS);
    const [updateUserDetail] = useMutation(UPDATE_USER);
    const [updateLoggedUser] = useMutation(UPDATE_LOGGED_USER);
    const [loggedUserType, setlLoggedUserType] = useState(null);

    let userOrgs;
    if (!orgsLoading && myorgs) {
        userOrgs = [];
        myorgs.logged_in_user[0].organization_member.forEach((org) => {
            userOrgs.push(org.organization);
        });
    }

    const loggedInUserID = !orgsLoading && myorgs ? myorgs.logged_in_user[0].id : undefined;
    const loggedInUserDetails = !orgsLoading && myorgs ? myorgs.logged_in_user[0] : undefined;

    let userOrgRoles;

    if (!orgsLoading && myorgs) {
        userOrgRoles = [];
        myorgs.logged_in_user[0].organization_member.forEach((org) => {
            userOrgRoles.push({ role: org.role, orgId: org.organization.id, active_role: org.active_role });
        });
    }

    let userPoi;
    if (!orgsLoading && myorgs) {
        userPoi = myorgs.logged_in_user[0].organization_member[0].user.poi_accesses[0]
            && myorgs.logged_in_user[0].organization_member[0].user.poi_accesses[0].poi;
    }

    const [changePassword] = useMutation(UPDATE_USER_PASSWORD);

    /**
     * @function changeUserPassword
     * @param {Object} userdetails
     * @returns updated row
     */
    const changeUserPassword = (userdetails) => {
        const userPass = changePassword({
            variables: {
                oldPass: userdetails.currentPassword, newPass: userdetails.newPassword, userId: userdetails.userId,
            },
        });

        return userPass;
    };

    const history = useHistory();
    const orgMatch = useRouteMatch('/:orgCode');
    const changeOrg = ({ org, ladningPath = '/timelines/', redirect = true }) => {
        // Full page reload for for now.
        let goTo = (userOrgRoles[0].role === 'Doctor' || userOrgRoles[0].role === 'Super Admin')
            ? `/${org.orgCode}` : `/${org.orgCode}${ladningPath}`;
        goTo = (userOrgRoles[0].role !== 'Patient'
        && loggedInUserDetails?.['organization_member'][0]?.user?.['is_dummy_password']) ? '/profile' : goTo;
        if (redirect) {
            window.location.assign(goTo);
        } else {
            history.push(goTo);
        }
    };

    /**
     * @function updateUser
     * @param {object} userObj [user object]
     * @param {boolean} emailChangeFlag [flag]
     * @returns get updated response
     */
    const updateUser = (userObj) => {
        const obj = {
            variables: {
                id: userObj.id, name: userObj.name, profile_photo: userObj.profile_photo,
            },
        };

        const result = updateUserDetail(obj);
        return result.then(() => {

        });
    };

    // Org selection logic
    // inputs:
    //   orgPath = org code from path
    //   selectedOrg = we have a selected org object
    //   userOrgs = orgs user has access to

    // if no orgPath
    //    if userOrgs.lengh == 1
    //        changeOrg(userOrgs[0])
    //    if userOrgs.length > 1
    //        // org route will display list of member orgs to choose from
    //               selectedOrg is null
    // else if orgPath
    //    if no selectedOrg
    //      if userOrgs == 1
    //        setSelectedOrg(userOrgs[0])
    //      else if userOrgs.lengh > 1
    //        loop userOrgs
    //            if(org.orgCode == orgPath)
    //              setSelectedOrg(org)
    //     if selectedOrg.orgCode != orgPath
    //        if(selectedOrg) changeOrg(userOrgs[0])
    //        else redirect("/");

    useEffect(() => {
        const orgPath = orgMatch ? orgMatch.params.orgCode : undefined;
        if (!orgPath) {
            if (userOrgs && userOrgs.length === 1) {
                setSelectedOrg(userOrgs[0]);
                changeOrg({ org: userOrgs[0], redirect: false });
            }
        } else if (!selectedOrg && userOrgs) {
            let tempOrg;
            userOrgs.forEach((org) => {
                if (org.orgCode === orgPath) {
                    setSelectedOrg(org);
                    tempOrg = org;
                }
            });
            // wait to redirect till the loggedInUserDetails are loaded
            if (!tempOrg && orgPath !== 'profile' && loggedInUserDetails) {
                // user is not a memeber of current orgPath
                // bounce back to root, however it maybe be better
                // to have an error view condition to let them know they don't have permissions to access the org they are requesting.

                // Allowing super admin to view the org even though they are not
                // part of it
                const isSuperAdmin = loggedUserType === 'Super Admin';
                if (!isSuperAdmin) {
                    history.push('/');
                } else {
                    setSelectedOrg(orgPath);
                }
            }
        } else if (selectedOrg && selectedOrg.orgCode !== orgPath) {
            // eslint-disable-next-line no-console
            userOrgs.forEach((org) => {
                if (org.orgCode === orgPath) {
                    setSelectedOrg(org);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orgMatch, userOrgs, loggedInUserDetails]);

    useEffect(() => {
        if (userOrgRoles && !loggedUserType) {
            setlLoggedUserType(userOrgRoles[0].active_role || userOrgRoles[0].role);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userOrgRoles]);

    useEffect(() => {
        getMyOrgs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const orgContext = {
        orgsLoading,
        orgsError,
        userOrgs,
        userOrgRoles,
        loggedInUserID,
        selectedOrg,
        changeOrg,
        idToken,
        loggedInUserDetails,
        updateUser,
        changeUserPassword,
        loggedUserType,
        setlLoggedUserType,
        userPoi,
        updateLoggedUser,
    };

    // pass the value in provider and return
    return <Context.Provider value={orgContext}>{children}</Context.Provider>;
};

export const { Consumer } = Context;
