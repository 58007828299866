import {
    Box, Breadcrumbs, Button, Fade, Grid, GridList, GridListTile, Menu, MenuItem, TextField, Typography,
} from '@material-ui/core';
import { MediaEditorContext, OrgContext, TimelineContext } from '../context';
import { UPDATE_MEDIA_FILE } from '../context/MediaEditor';
import {
    deepCopy, getFileType, isVideo,
} from '../helper/commonHelper';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useLazyQuery, useMutation } from 'react-apollo';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import FolderIcon from '@material-ui/icons/Folder';
import MediaDetails from './MediaDetails';
import MediaFolderTree from './MediaFolderTree';
import MediaGalleryModal from './MediaGalleryModal';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import OrgBreadcrumbs from './utils/OrgBreadcrumbs';
import React, {
    Fragment, useContext, useEffect, useState,
} from 'react';
import gql from 'graphql-tag';
import noMediaSelected from '../images/NoMediaSelected.svg';

const GET_FOLDER_TYPE_TIMELINE = gql`
    query GetBreadcrumbTypeTimeline($timeline_id: uuid) {
        media_folder_new(where: {timeline_id: {_eq: $timeline_id}, column_id: {_is_null: true}, parent_id: {_is_null: true}}) {
            id
            folder_name
        }
    }
`;

const GET_FOLDER_TYPE_COLUMN = gql`
    query GetFolderTypeColumnDetails($timeline_id: uuid, $column_id: uuid) {
        media_folder_new(where: {timeline_id: {_eq: $timeline_id}, 
            column_id: {_eq: $column_id}, 
            is_progression: {_is_null: true}, 
            parent_id: {_is_null: true}}) {
            id
            folder_name
            column_id
        }
    }
`;

const GET_FOLDER_TYPE_OTHERS_DETAILS = gql`
    query GetFolderTypeOthersDetails($timeline_id: uuid, $is_progression: Boolean, $component_id: uuid) {
        media_folder_new(where: {timeline_id: {_eq: $timeline_id}, 
            is_progression: {_eq: $is_progression}, 
            component_id: {_eq: $component_id}, 
            parent_id: {_is_null: true}}) {
            id
            parent_id
            folder_name
        }
    }
`;

const GET_FOLDER_TYPE_CUSTOM_DETAILS = gql`
    query GetFolderTypeCustomDetails($parent_id: uuid) {
        media_folder_new(where: {parent_id: {_eq: $parent_id}}) {
            id
            timeline_id
            column_id
            is_progression
            component_id
            parent_id
            folder_name
        }
    }
`;

const CREATE_TIMELINE_FOLDER = gql`
    mutation CreateTimelineFolder($timeline_id: uuid) {
        insert_media_folder_new(objects: {timeline_id: $timeline_id}) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

const CREATE_COLUMN_FOLDER = gql`
    mutation CreateColumnFolder($timeline_id: uuid, $column_id: uuid) {
        insert_media_folder_new(objects: {timeline_id: $timeline_id, column_id: $column_id}) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

const CREATE_OTHERS_FOLDER = gql`
    mutation CreateOthersFolder($timeline_id: uuid, $column_id: uuid, $is_progression: Boolean, $component_id: uuid) {
        insert_media_folder_new(objects: {timeline_id: $timeline_id, 
            column_id: $column_id, 
            is_progression: $is_progression, 
            component_id: $component_id}) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

/**
 * query to get the recent uploaded media
 */
export const GET_MEDIA_BY_FOLDERID = gql`
 query mediaList($orgID: uuid, $orderBy: [media_order_by!], $folder_id: uuid) {
     media(order_by: $orderBy, where: {folder_id: {_eq: $folder_id}, 
        organization_id: {_eq: $orgID} is_delete: {_eq: false}, 
        _or: [{filetype: {_like: "image/%"}}, {filetype: {_like: "video/%"}}]}) {
     id
     filetype
     name
     url
     is_delete
     is_youtube
     folder_id
     created_at
     updated_at
     }
 }
 `;

const DELETE_MEDIA_FOLDER = gql`
mutation DeleteMediaFolder($id: uuid, ) {
    delete_media_folder_new(where: {id: {_eq: $id}}) {
        affected_rows
        returning {
            id
          }
    }
  }
`;

const useRowStyles = makeStyles(() => ({
    container: {
        marginTop: '-20px',
    },
    root: {
        height: 240,
        flexGrow: 1,
        maxWidth: 400,
    },
    folderCls: {
        height: 80,
        width: 80,
        color: '#5d94f6',
        cursor: 'pointer',
    },
    folderWrapper: {
        width: 90,
        '& h6': {
            margin: '0px',
            textAlign: 'center',
            fontSize: 14,
        },
    },
    iconCls: {
        height: 80,
        width: 80,
        color: '#5d94f6',
        cursor: 'pointer',
    },
    cursorCls: {
        cursor: 'pointer',
    },
    subHeader: {
        maxHeight: 'calc(100vh - 240px)',
    },
    addMediaButtonCont: {
        padding: '2.25rem 1.5rem 0rem 0rem',
    },
    noMediaSelectedDiv: {
        backgroundColor: '#fff',
        height: 'calc(100vh - 182px)',
    },
    noMediaSeleted: {
        marginTop: '10%',
    },
    mediaDetails: {
        paddingLeft: '1.25rem',
        backgroundColor: '#fff',
        height: 'calc(100vh - 150px)',
        borderLeft: '1px solid #33333344',
        borderTop: '1px solid #33333344',
    },
    mediaMainCont: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    folderCont: {
        display: 'flex',
        marginBottom: '1.75rem',
    },
    autocompleteRoot: {
        width: 300,
        margin: '1rem auto',
        '& input': {
            padding: '2px 4px !important',
        },
        '& fieldset': {
            borderRadius: '0px',
        },
    },
    primaryBtn: {
        marginLeft: 8,
    },
    width100: {
        width: '100%',
    },
    width72: {
        width: '72%',
    },
    width28: {
        width: '28%',
    },
    widthAuto: {
        width: 'auto',
    },
    dFlex: {
        display: 'flex',
    },
}));

const listItems = [
    {
        id: 1, label: 'Progression', value: 'progressions', type: 'Progression',
    },
    {
        id: 2, label: 'Protocol', value: 'components', type: 'ProtocolItem',
    },
];

const messageObj = {
    noData: 'There are no data.',
    errorMessage: 'Something went wrong.',
};

const typeObj = {
    showTimeline: 'ShowTimeline',
    timeline: 'Timeline',
    column: 'Column',
    progression: 'Progression',
    protocolItem: 'ProtocolItem',
    protocol: 'Protocol',
    component: 'Component',
    custom: 'Custom',
    move: 'Move',
    copy: 'Copy',
    duplicate: 'Duplicate',
    addMedia: 'AddMedia',
    delete: 'Delete',
};

const initialState = {
    selectedTimeline: null,
    selectedColumn: -1,
    loader: false,
    stackData: [],
    breadCrumbsData: [],
    selectedComponent: null,
    selectedKey: null,
    treeDialogFlag: false,
    type: null,
    selectedMedia: null,
    isShowLoader: false,
    isMediaDelete: false,
};

const MediaFolder = (props) => {
    const { showLoader } = props;
    const { selectedOrg } = useContext(OrgContext);
    const { timelines } = useContext(TimelineContext);
    const { insertImage, setSelectedMediaAction } = useContext(MediaEditorContext);
    const classes = useRowStyles();
    const [state, setState] = useState(deepCopy(initialState));
    const [customData, setCustomData] = useState([]);
    const [mediaData, setMediaData] = useState([]);
    const [lastMedia, setLastMedia] = useState(null);
    const [selectedMedia, setSelectedMedia] = useState({});
    const [mediaFolderId, setMediaFolderId] = useState(null);
    const [isRefetchMedia, setIsRefetchMedia] = useState(false);
    const [isModalOpen, setModalStatus] = useState(false);
    const [treeState, setTreeState] = useState({ folderData: [], treeSelectedComp: null });
    const [mediaAction, setMediaAction] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [updateMediaFile] = useMutation(UPDATE_MEDIA_FILE);
    const history = useHistory();

    const [getFolderTypeTimelineDetails, {
        loading: timelineFolderLoading,
        error: timelineFolderError,
    }] = useLazyQuery(GET_FOLDER_TYPE_TIMELINE, {
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data.media_folder_new && !state.treeDialogFlag) { setCustomData(data.media_folder_new); }

            if (data.media_folder_new && state.treeDialogFlag) { setTreeState({ ...treeState, folderData: data.media_folder_new }); }
        },
    });

    const [getFolderTypeColumnDetails, {
        loading: columnFolderLoading,
        error: columnFolderError,
    }] = useLazyQuery(GET_FOLDER_TYPE_COLUMN, {
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data.media_folder_new && !state.treeDialogFlag) { setCustomData(data.media_folder_new); }
            if (data.media_folder_new && state.treeDialogFlag) { setTreeState({ ...treeState, folderData: data.media_folder_new }); }
        },
    });

    const [getFolderTypeCustomDetails, {
        loading: customFolderLoading,
        error: customFolderError,
    }] = useLazyQuery(GET_FOLDER_TYPE_CUSTOM_DETAILS, {
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data.media_folder_new && !state.treeDialogFlag) { setCustomData(data.media_folder_new); }
            if (data.media_folder_new && state.treeDialogFlag) { setTreeState({ ...treeState, folderData: data.media_folder_new }); }
        },
    });

    const [getFolderTypeOthersDetails, {
        loading: othersFolderLoading,
        error: othersFolderError,
    }] = useLazyQuery(GET_FOLDER_TYPE_OTHERS_DETAILS, {
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data.media_folder_new && !state.treeDialogFlag) { setCustomData(data.media_folder_new); }
            if (data.media_folder_new && state.treeDialogFlag) { setTreeState({ ...treeState, folderData: data.media_folder_new }); }
        },
    });

    const [getMediaByFolderId, {
        loading: mediaLoading,
        error: mediaError,
        refetch: refetchTimelineMedia,
    }] = useLazyQuery(GET_MEDIA_BY_FOLDERID, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            if (data.media) { setMediaData(data.media); }
        },
    });

    const [createTimelineFolder] = useMutation(CREATE_TIMELINE_FOLDER);
    const [createColumnFolder] = useMutation(CREATE_COLUMN_FOLDER);
    const [createOthersFolder] = useMutation(CREATE_OTHERS_FOLDER);
    const [deleteMediaFolderMutation] = useMutation(DELETE_MEDIA_FOLDER);

    const templateNavigation = () => {
        history.push(`/${selectedOrg.orgCode}/templates?imageAcc=open`);
    };

    const handleAutoSelect = (e, newValue) => {
        initialState.breadCrumbsData = [];
        initialState.stackData = [];
        const intialStateClone = deepCopy(initialState);
        if (newValue) {
            intialStateClone.selectedTimeline = newValue;
            intialStateClone.selectedKey = typeObj.showTimeline;
            setState(intialStateClone);
        } else {
            setState(intialStateClone);
        }
        setCustomData([]);
    };

    const fetchCustomFolder = (type, updateState, treeView = false) => {
        const reqObj = {
            timelineId: state.selectedTimeline.id,
            columnId: !treeView && ((updateState && state.selectedTimeline.columns[updateState.selectedColumn]?.id)
                || state.selectedTimeline.columns[state.selectedColumn]?.id),
            progressionSectionId: state.selectedTimeline.columns[state.selectedColumn]?.progressionSectionId,
            protocolSectionId: state.selectedTimeline.columns[state.selectedColumn]?.protocolSectionId,
            componentId: updateState?.id,
            parentId: updateState?.id,
            isProgression: updateState?.component ? !!updateState?.component?.isProgression : !!updateState?.isProgression,
        };

        if (treeView) {
            reqObj.timelineId = updateState.timeline.id;
            reqObj.columnId = updateState.column.id;
            reqObj.progressionSectionId = updateState.column.progressionSectionId;
            reqObj.protocolSectionId = updateState.column.protocolSectionId;
            reqObj.componentId = updateState.component.id;
            reqObj.isProgression = updateState?.component ? !!updateState?.component?.isProgression : !!updateState?.isProgression;
        }

        if (reqObj.isProgression && updateState?.component?.commonId) {
            reqObj.componentId = updateState.component.commonId;
        }

        if (reqObj.isProgression && !updateState?.component && updateState.commonId) {
            reqObj.componentId = updateState.commonId;
        }

        switch (type) {
        case 'Timeline':
            getFolderTypeTimelineDetails({
                variables: {
                    timeline_id: reqObj.timelineId,
                },
            });

            break;
        case 'Column':
            getFolderTypeColumnDetails({
                variables: {
                    timeline_id: reqObj.timelineId,
                    column_id: reqObj.columnId,
                },
            });
            break;
        case 'Progression':
            getFolderTypeOthersDetails({
                variables: {
                    timeline_id: reqObj.timelineId,
                    is_progression: true,
                    component_id: reqObj.progressionSectionId,
                },
            });
            break;
        case 'ProtocolItem':
            getFolderTypeOthersDetails({
                variables: {
                    timeline_id: reqObj.timelineId,
                    is_progression: false,
                    component_id: reqObj.protocolSectionId,
                },
            });
            break;
        case 'Text':
            getFolderTypeOthersDetails({
                variables: {
                    timeline_id: reqObj.timelineId,
                    is_progression: reqObj.isProgression,
                    component_id: reqObj.componentId,
                },
            });
            break;
        case 'Custom':
            getFolderTypeCustomDetails({
                variables: {
                    parent_id: reqObj.parentId,
                },
            });
            break;
        default:
        }
        // test it
        if (!treeView) setCustomData([]);
    };

    const getSelectedComp = (item, filterData) => {
        const updateState = { ...state };
        let findCIndex = -1;
        findCIndex = updateState.stackData.findIndex((b) => b.id === item.id);
        if (findCIndex === -1) return null;
        if (findCIndex !== -1) {
            updateState.selectedKey = Object.prototype.hasOwnProperty.call(item, 'folder_name') ? typeObj.custom : typeObj.component;
            updateState.selectedComponent = updateState.stackData[findCIndex];
            updateState.stackData = updateState.stackData.slice(0, (findCIndex + 1));
        }
        setState({ ...updateState, breadCrumbsData: filterData });
        return true;
    };

    const clearOtherState = () => {
        setCustomData([]);
        setMediaData([]);
    };

    const handleBreadCrumbs = async (e, item) => {
        e.preventDefault();
        const { breadCrumbsData } = state;
        let findBIndex = -1;
        let obj = {};
        findBIndex = breadCrumbsData.findIndex((b) => b.id === item.id);
        if (findBIndex !== -1) {
            const filterData = breadCrumbsData.slice(0, (findBIndex === 0 ? findBIndex : findBIndex + 1));
            const { type } = item;
            switch (type) {
            case 'Timeline':
                obj = {
                    ...initialState,
                    selectedKey: typeObj.showTimeline,
                    selectedTimeline: state.selectedTimeline,
                    breadCrumbsData: filterData,
                };
                setState({ ...obj });
                clearOtherState();
                if (filterData.length > 0) fetchCustomFolder(type, obj);
                break;
            case 'Column':
                setState({
                    ...state,
                    selectedComponent: state.selectedTimeline.columns[state.selectedColumn],
                    selectedKey: typeObj.column,
                    breadCrumbsData: filterData,
                    stackData: [],
                });
                clearOtherState();
                fetchCustomFolder(type);
                break;
            case 'Progression':
                setState({
                    ...state,
                    selectedComponent: state.selectedTimeline.columns[state.selectedColumn],
                    selectedKey: typeObj.progression,
                    breadCrumbsData: filterData,
                    stackData: [],
                });
                clearOtherState();
                fetchCustomFolder(type);
                break;
            case 'ProtocolItem':
                setState({
                    ...state,
                    selectedComponent: state.selectedTimeline.columns[state.selectedColumn],
                    selectedKey: typeObj.protocolItem,
                    breadCrumbsData: filterData,
                    stackData: [],
                });
                clearOtherState();
                fetchCustomFolder(type);
                break;
            case 'Text':
                clearOtherState();
                fetchCustomFolder(type, item);
                getSelectedComp(item, filterData);
                break;
            case 'Custom':
                setState({
                    ...state,
                    selectedComponent: item,
                    selectedKey: typeObj.custom,
                    breadCrumbsData: filterData,
                });
                clearOtherState();
                fetchCustomFolder(type, item);
                getSelectedComp(item, filterData);
                break;
            default:
            }
        }
        setSelectedMedia({});
    };

    const handleFolderClick = async (e, item, type = null) => {
        e.preventDefault();
        let obj = {};
        let findColIndex = -1;
        const { breadCrumbsData } = state;

        switch (type) {
        case 'Timeline':
            breadCrumbsData.push({
                id: 1, type: typeObj.timeline, label: state.selectedTimeline.props.name, selectedKey: typeObj.timeline,
            });
            obj = {
                ...state, selectedComponent: item, selectedKey: typeObj.timeline, breadCrumbsData,
            };
            setState({ ...obj });
            setCustomData([]);
            fetchCustomFolder(type, obj);
            break;
        case 'Column':
            findColIndex = state.selectedTimeline.columns.findIndex((c) => c.id === item.id);
            breadCrumbsData.push({
                id: 2, type: typeObj.column, label: state.selectedTimeline.columns[findColIndex].props.title, selectedKey: typeObj.column,
            });
            obj = {
                ...state, selectedComponent: item, selectedColumn: findColIndex, selectedKey: typeObj.column,
            };
            setState(obj);
            setCustomData([]);
            fetchCustomFolder(type, obj);
            break;
        case 'Progression':
            breadCrumbsData.push({
                id: state.selectedTimeline.columns[state.selectedColumn].progressionSectionId || 3,
                type: typeObj.progression,
                label: typeObj.progression,
                selectedKey: typeObj.progression,
            });
            setCustomData([]);
            setState({
                ...state, selectedComponent: item, selectedKey: typeObj.progression,
            });
            fetchCustomFolder(type);
            break;
        case 'ProtocolItem':
            breadCrumbsData.push({
                id: state.selectedTimeline.columns[state.selectedColumn].protocolSectionId || 4,
                type: typeObj.protocolItem,
                label: 'Protocol',
                selectedKey: typeObj.protocolItem,
            });
            setCustomData([]);
            fetchCustomFolder(type);
            setState({
                ...state, selectedComponent: item, selectedKey: typeObj.protocolItem,
            });
            break;
        default:
        }
        setSelectedMedia({});
    };

    const handleStackComp = (e, component) => {
        e.preventDefault();
        const updateState = { ...state };
        const isCustomFolder = !!Object.prototype.hasOwnProperty.call(component, 'folder_name');
        const breadcrumbObj = {};
        if ((component.props?.components.length > 0 && component.type === 'Text') || isCustomFolder) {
            updateState.stackData.push(component);
            updateState.selectedComponent = component;

            breadcrumbObj.id = component.id;
            breadcrumbObj.type = isCustomFolder ? typeObj.custom : component.type;
            breadcrumbObj.label = component.props?.text || component.props?.label || component.folder_name;
            breadcrumbObj.selectedKey = isCustomFolder ? typeObj.custom : typeObj.component;
            breadcrumbObj.isProgression = isCustomFolder ? null : component.isProgression;
            if (component.isProgression) {
                breadcrumbObj.commonId = component.commonId || null;
            }

            updateState.breadCrumbsData.push(breadcrumbObj);
            setState({ ...updateState, selectedKey: typeObj.component });
            setCustomData([]);
            if (isCustomFolder) {
                getFolderTypeCustomDetails({
                    variables: {
                        parent_id: component?.id,
                    },
                });
            } else {
                getFolderTypeOthersDetails({
                    variables: {
                        timeline_id: state.selectedTimeline.id,
                        is_progression: component.isProgression,
                        component_id: component.isProgression ? component.commonId : component.id,
                    },
                });
            }
        } else if (component.type === 'Protocol') {
            updateState.stackData.push(component);
            updateState.selectedComponent = component;

            breadcrumbObj.id = component.id;
            breadcrumbObj.type = component.type;
            breadcrumbObj.label = component.props?.text || component.props?.label || component.props.title;
            breadcrumbObj.selectedKey = typeObj.protocol;
            breadcrumbObj.isProgression = isCustomFolder ? null : component.isProgression;
            if (component.isProgression) {
                breadcrumbObj.commonId = component.commonId || null;
            }

            updateState.breadCrumbsData.push(breadcrumbObj);
            setCustomData([]);
            setState({ ...updateState, selectedKey: typeObj.protocol });
            getFolderTypeOthersDetails({
                variables: {
                    timeline_id: state.selectedTimeline.id,
                    is_progression: component.isProgression,
                    component_id: component.isProgression ? component.commonId : component.id,
                },
            });
        } else if (component.props?.components.length === 0) {
            toast.info(messageObj.noData);
        }
    };

    const handleMediaMenu = (e, mediaItem) => {
        e.preventDefault();
        setAnchorEl(e.currentTarget);
        setState({ ...state, selectedMedia: mediaItem });
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMediaAction = (e, type) => {
        switch (type) {
        case typeObj.copy:
            setState({
                ...state, treeDialogFlag: true, type: typeObj.copy,
            });
            setSelectedMediaAction({ type: typeObj.copy });
            handleClose();
            break;
        case typeObj.move:
            setState({
                ...state, treeDialogFlag: true, type: typeObj.move,
            });
            handleClose();
            break;
        case typeObj.duplicate:
            setState({
                ...state, treeDialogFlag: false, type: typeObj.duplicate, isShowLoader: true,
            });
            setSelectedMediaAction({ type: typeObj.duplicate });
            setMediaAction({ ...mediaAction, type: typeObj.duplicate });
            handleClose();
            break;
        case typeObj.delete:
            if (mediaData?.length === 1) {
                setLastMedia(mediaData[0]);
            }
            setState({ ...state, isMediaDelete: true });
            handleClose();
            break;
        default: break;
        }
    };

    /**
     * @function onSelectMedia
     * @param {object} get the selected media to pass data to the child component
     */
    const onSelectMedia = (media) => {
        setSelectedMedia(media);
    };

    const getFolderId = async () => {
        const { selectedKey } = state;
        let folderData = customData;
        let compType = selectedKey;
        const reqObj = {
            timelineId: state?.selectedTimeline?.id,
            columnId: state.selectedTimeline?.columns[state.selectedColumn]?.id,
            progressionSectionId: state.selectedTimeline.columns[state.selectedColumn]?.progressionSectionId,
            protocolSectionId: state.selectedTimeline.columns[state.selectedColumn]?.protocolSectionId,
            componentId: state.selectedComponent?.isProgression ? (state.selectedComponent.commonId ? state.selectedComponent.commonId
                : state.selectedComponent.id)
                : state.selectedComponent?.id,
        };
        if (state.treeDialogFlag) {
            folderData = treeState.folderData;
            reqObj.timelineId = treeState.treeSelectedComp?.timeline?.id;
            reqObj.columnId = treeState.treeSelectedComp?.column?.id;
            reqObj.progressionSectionId = treeState.treeSelectedComp?.column?.progressionSectionId;
            reqObj.protocolSectionId = treeState.treeSelectedComp?.column?.protocolSectionId;
            reqObj.componentId = treeState.treeSelectedComp?.component?.id;
            // reqObj.isProgression = !!treeState.treeSelectedComp?.component?.props?.isProgression;
            reqObj.isProgression = !!treeState.treeSelectedComp?.component?.isProgression;
            compType = treeState.treeSelectedComp?.componentType;
            if (compType === 'Text' || compType === 'Protocol') {
                compType = typeObj.component;
            }

            if (reqObj.isProgression && treeState.treeSelectedComp?.component?.commonId) {
                reqObj.componentId = treeState.treeSelectedComp?.component?.commonId;
            }
        }
        let response = null;
        let folderId = null;
        let obj = {};
        switch (compType) {
        case 'Timeline':
            folderId = folderData?.find((c) => !c.folder_name)?.id;
            if (!folderId) {
                response = !folderId && await createTimelineFolder({
                    variables: {
                        timeline_id: reqObj.timelineId,
                    },
                });
                folderId = response.data.insert_media_folder_new?.returning?.[0]?.id;
                obj = {
                    folder_name: null,
                    id: folderId,
                };
                folderData.push(obj);
            }
            break;
        case 'Column':
            folderId = folderData?.find((c) => !c.folder_name)?.id;
            if (!folderId) {
                response = !folderId && await createColumnFolder({
                    variables: {
                        timeline_id: reqObj.timelineId,
                        column_id: reqObj.columnId,
                    },
                });
                folderId = response.data.insert_media_folder_new?.returning?.[0]?.id;
                obj = {
                    folder_name: null,
                    id: folderId,
                    column_id: state.selectedTimeline.columns[state.selectedColumn].id,
                };
                folderData.push(obj);
            }
            break;
        case 'Progression':
            folderId = folderData?.find((c) => !c.folder_name)?.id;
            if (!folderId) {
                response = !folderId && await createOthersFolder({
                    variables: {
                        timeline_id: reqObj.timelineId,
                        column_id: reqObj.columnId,
                        component_id: reqObj.progressionSectionId,
                        is_progression: true,
                    },
                });
                folderId = response.data.insert_media_folder_new?.returning?.[0]?.id;
                obj = {
                    folder_name: null,
                    id: folderId,
                    parent_id: null,
                };
                folderData.push(obj);
            }
            break;
        case 'ProtocolItem':
            folderId = folderData?.find((c) => !c.folder_name)?.id;
            if (!folderId) {
                response = !folderId && await createOthersFolder({
                    variables: {
                        timeline_id: reqObj.timelineId,
                        column_id: reqObj.columnId,
                        component_id: reqObj.protocolSectionId,
                        is_progression: false,
                    },
                });
                folderId = response.data.insert_media_folder_new?.returning?.[0]?.id;
                obj = {
                    folder_name: null,
                    id: folderId,
                    parent_id: null,
                };
                folderData.push(obj);
            }
            break;
        case 'Component':
            folderId = folderData?.find((c) => !c.folder_name)?.id;
            if (!folderId) {
                response = !folderId && await createOthersFolder({
                    variables: {
                        timeline_id: reqObj.timelineId,
                        column_id: reqObj.columnId,
                        component_id: reqObj.componentId,
                        is_progression: !!reqObj.isProgression,
                    },
                });
                folderId = response.data.insert_media_folder_new?.returning?.[0]?.id;
                obj = {
                    folder_name: null,
                    id: folderId,
                    parent_id: null,
                };
                folderData.push(obj);
            }
            break;
        default:
        }
        if (!state.treeDialogFlag) {
            setCustomData(folderData);
        }
        setMediaFolderId(folderId);
        return folderId;
    };

    const getMediaData = () => {
        let folderId = customData?.find((c) => c.id === state.selectedComponent.id);
        if (!folderId) { folderId = customData?.find((c) => !c.folder_name)?.id; }
        if (Object.prototype.hasOwnProperty.call(state?.selectedComponent, 'folder_name')) folderId = state.selectedComponent.id;
        if (folderId) {
            getMediaByFolderId({
                variables:
                {
                    orgID: selectedOrg.id,
                    folder_id: folderId,
                    orderBy: { updated_at: 'desc' },
                },
                skip: false,
                fetchPolicy: 'no-cache',
            });
            setIsRefetchMedia(false);
        }
    };

    const imageUploadHandler = (isRefetch = false) => {
        if (isRefetch && refetchTimelineMedia) {
            refetchTimelineMedia();
            setIsRefetchMedia(isRefetch);
        } else if (!refetchTimelineMedia) {
            getMediaData();
        }
        if (state.type === typeObj.duplicate) {
            setState({
                ...state, isShowLoader: false, type: null, selectedMedia: null,
            });
            setModalStatus(false);
            setSelectedMediaAction(null); // media context state
            setMediaAction(null); // media component state
            toast.success(`Media ${state.type} is successfully`);
        }
    };

    const handleTreeDialogClose = () => {
        const updateState = { ...state };
        updateState.treeDialogFlag = false;
        updateState.isShowLoader = false;
        updateState.type = null;
        updateState.selectedMedia = null;
        setState(updateState);
        setSelectedMediaAction(null); // media context state
        setMediaAction(null); // media component state
        setModalStatus(false);
    };

    const handleTreeViewSubmit = (folder) => {
        switch (state.type) {
        case typeObj.move:
            fetchCustomFolder(folder.componentType, folder, true);
            setTreeState({ ...treeState, treeSelectedComp: folder });
            setState({ ...state, isShowLoader: true });
            break;
        case typeObj.copy:
            setTreeState({ ...treeState, treeSelectedComp: folder });
            setMediaAction({ ...mediaAction, type: typeObj.copy });
            setState({ ...state, isShowLoader: true });
            break;
        default:
        }
    };

    const handleModalStatus = () => {
        if (isModalOpen) setSelectedMediaAction(null);
        setModalStatus(!isModalOpen);
    };

    const updateMediaStatus = async () => {
        try {
            const folderId = await getFolderId();
            if (folderId) {
                if (state.type === typeObj.copy) {
                    state.selectedMedia[0].folder_id = folderId;
                    await insertImage(state.selectedMedia);
                } else if (state.type === typeObj.move) {
                    if (state.selectedMedia.folder_id === folderId) {
                        toast.error('Please select other folder');
                        setState({ ...state, isShowLoader: false });
                        return false;
                    }
                    await updateMediaFile({
                        variables: {
                            id: state.selectedMedia.id,
                            folderId,
                        },
                    });
                    if (mediaData?.length === 1) {
                        await deleteMediaFolderMutation({ variables: { id: mediaData[0].folder_id } });
                    }
                }
                toast.success(`Media ${state.type} is successfully`);
                handleTreeDialogClose();
                imageUploadHandler(true);
            }
        } catch {
            toast.error('Something went wrong.');
        }
        return null;
    };

    const imageActionSuccessfull = (mediaData) => {
        const { treeSelectedComp } = treeState;
        if (state.type === typeObj.copy) {
            fetchCustomFolder(treeSelectedComp.componentType, treeSelectedComp, true);
            setState({ ...state, selectedMedia: mediaData });
        }
    };

    const mediaDeleteSuccess = async () => {
        setState({ ...state, isMediaDelete: false, selectedMedia: null });
        if (lastMedia) {
            try {
                await deleteMediaFolderMutation({ variables: { id: lastMedia.folder_id } });
                setLastMedia(null);
            } catch (error) {
                toast.error(messageObj.errorMessage);
                setLastMedia(null);
            }
        }
    };

    useEffect(() => {
        setMediaData([]);
        const loadingCustomFolder = timelineFolderLoading || columnFolderLoading || customFolderLoading || othersFolderLoading;
        if ((state?.selectedComponent?.id && !loadingCustomFolder) || isRefetchMedia) {
            getMediaData();
        }
    }, [state.selectedComponent, customData, isRefetchMedia]);

    useEffect(() => {
        const loadingCustomFolder = timelineFolderLoading || columnFolderLoading || customFolderLoading || othersFolderLoading;
        if (!loadingCustomFolder && state.treeDialogFlag) {
            updateMediaStatus();
        }
    }, [treeState.folderData]);

    if (!timelines) {
        return showLoader(true);
    }

    const renderFolderView = (data, type = true) => {
        let item = null;
        let renderData = [];
        if (type) {
            renderData = data;
        } else {
            renderData = data.filter((d) => Boolean(d.folder_name));
        }
        if (renderData.length > 0) {
            item = (
                <div className={classes.dFlex}>
                    {renderData.map((d) => (
                        <div className={classes.folderWrapper} key={d.id}>
                            <FolderIcon className={classes.iconCls} onDoubleClick={(e) => handleStackComp(e, d)} />
                            <h6>{d.props?.text || d.props?.title || d.folder_name}</h6>
                        </div>
                    ))}
                </div>
            );
        }
        return item;
    };

    const renderBreadcrumbs = () => {
        let item = null;
        if (state.breadCrumbsData.length === 1) {
            item = (
                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                    {state.breadCrumbsData.map((b) => (
                        <Typography color="inherit" onClick={(e) => handleBreadCrumbs(e, b)} key={b.id} className={classes.cursorCls}>
                            {b.label}
                        </Typography>

                    ))}
                </Breadcrumbs>
            );
        } else if (state.breadCrumbsData.length > 1) {
            item = (
                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                    {state.breadCrumbsData.map((b, i) => (
                        i === state.breadCrumbsData.length - 1
                            ? (
                                <Typography color="textPrimary">
                                    {b.label}
                                </Typography>
                            )
                            : (
                                <Typography color="inherit" onClick={(e) => handleBreadCrumbs(e, b)} key={b.id} className={classes.cursorCls}>
                                    {b.label}
                                </Typography>
                            )
                    ))}
                </Breadcrumbs>
            );
        }
        return item;
    };

    const renderTimeline = () => {
        let item = null;
        if (state.selectedKey === typeObj.showTimeline) {
            item = (
                <div className={classes.folderWrapper}>
                    <FolderIcon className={classes.iconCls} onDoubleClick={(e) => handleFolderClick(e, state.selectedTimeline, typeObj.timeline)} />
                    <h6>{state.selectedTimeline.props.name}</h6>
                </div>
            );
        }
        return item;
    };

    const renderTimelineColumn = () => {
        let item = null;
        if (state.selectedKey === typeObj.timeline) {
            const { columns } = state.selectedComponent;
            item = (
                <div className={classes.dFlex}>
                    {columns.map((d, i) => (
                        <Fragment key={d.id ? d.id : i}>
                            {d?.props?.isProfile ? null
                                : (
                                    <div className={classes.folderWrapper} key={d.id}>
                                        <FolderIcon className={classes.iconCls} onDoubleClick={(e) => handleFolderClick(e, d, typeObj.column)} />
                                        <h6>{d.props.title}</h6>
                                    </div>
                                )}
                        </Fragment>
                    ))}
                </div>
            );
        }
        return item;
    };

    const renderProgProtocol = () => {
        let item = null;
        if (state.selectedKey === typeObj.column) {
            item = (
                <div className={classes.dFlex}>
                    {listItems.map((d) => (
                        <div className={classes.folderWrapper} key={d.id}>
                            <FolderIcon className={classes.iconCls} onDoubleClick={(e) => handleFolderClick(e, d, d.type)} />
                            <h6>{d.label}</h6>
                        </div>
                    ))}
                </div>
            );
        }
        return item;
    };

    const renderComponent = () => {
        let item = null;
        const isProgression = state.selectedKey === typeObj.progression;
        if (state.selectedColumn !== -1 && (state.selectedKey === typeObj.progression || state.selectedKey === typeObj.protocolItem)) {
            const data = isProgression ? state.selectedTimeline.columns[state.selectedColumn].props.progressions
                : state.selectedTimeline.columns[state.selectedColumn].props.components;
            if (data) {
                item = renderFolderView(data);
            }
        }

        return item;
    };

    const renderStackComponent = () => {
        let item = null;
        const { selectedComponent } = state;
        if (state.stackData.length > 0 && (state.selectedKey === typeObj.component) && selectedComponent.props?.components?.length > 0) {
            item = renderFolderView(selectedComponent.props.components);
        }
        return item;
    };

    const renderProtocolComponent = () => {
        let item = null;
        if (mediaData.length > 0) {
            mediaData.forEach((media, key) => {
                if (isVideo(media.name)) {
                    media.filetype = getFileType(media.name);
                    media.videoDivId = `video-div-gallery-${key}`;
                    media.isVideo = true;
                }
            });
            item = (
                <GridList key="Subheader" cols={4} className={classes.subHeader}>
                    {mediaData.map((media) => (
                        <GridListTile key={media.id} style={{ height: '120px', width: '210px' }} onContextMenu={(e) => handleMediaMenu(e, media)}>
                            {media.isVideo
                                ? (
                                    <video style={{ height: '120px', width: '210px' }} controls>
                                        <source src={media.url} type="video/mp4" />
                                    </video>
                                )
                                : <img id="mdiaMngrGrid" src={media.url} className={classes.imagePointer} alt={media.name} />}
                            <Typography className="viewTxtBtn" onClick={() => onSelectMedia(media)}> View Details </Typography>
                        </GridListTile>
                    ))}
                </GridList>

            );
        }
        return item;
    };

    const renderCustomLoader = () => {
        let item = null;
        if (timelineFolderLoading || columnFolderLoading || customFolderLoading || othersFolderLoading || mediaLoading) {
            item = <div style={{ textAlign: 'center' }}><CircularProgress /></div>;
        }

        if (timelineFolderError || columnFolderError || customFolderError || othersFolderError || mediaError) {
            item = <Typography>Can not load folders. Please retry.</Typography>;
        }
        return item;
    };

    const renderCustomComponent = () => {
        let item = null;
        if (customData?.length > 0) {
            item = renderFolderView(customData, false);
        }
        return item;
    };

    /**
     * Render the media details.
     */
    const renderMediaDetails = () => {
        let mediaItems = (
            <div className={classes.noMediaSelectedDiv}>
                <Grid container className={classes.noMediaSeleted}>
                    <Grid item md={12} lg={12}>
                        <Box textAlign="center">
                            <img src={noMediaSelected} alt="No Media" />
                        </Box>
                    </Grid>
                    <Grid item md={12} lg={12}>
                        <Box textAlign="center">
                            <Typography>
                                Click on View Detail button to
                                <br />
                                see Media details
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </div>
        );
        if (Object.keys(selectedMedia).length > 0 || state.isMediaDelete) {
            mediaItems = (
                <div className={!state.isMediaDelete && classes.mediaDetails}>
                    <MediaDetails
                        selectedMedia={selectedMedia}
                        onSelectMedia={onSelectMedia}
                        imageUploadHandler={imageUploadHandler}
                        isMediaDelete={state.isMediaDelete}
                        mediaDataDelete={state.selectedMedia}
                        mediaDeleteSuccess={mediaDeleteSuccess}
                        mediaData={state}
                    />
                </div>
            );
        }
        return mediaItems;
    };

    const renderContextMenu = () => (
        <Menu
            id="fade-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
        >
            <MenuItem onClick={(e) => handleMediaAction(e, typeObj.copy)}>Copy To</MenuItem>
            <MenuItem onClick={(e) => handleMediaAction(e, typeObj.move)}>Move To</MenuItem>
            <MenuItem onClick={(e) => handleMediaAction(e, typeObj.duplicate)}>Duplicate</MenuItem>
            <MenuItem onClick={(e) => handleMediaAction(e, typeObj.delete)}>Delete</MenuItem>
        </Menu>
    );
    return (
        <div className={classes.container}>
            {state.isShowLoader
                && showLoader(true)}
            <Grid container>
                <Grid item md={8} lg={8}>
                    <Typography variant="h2">
                        <Box mt={5} color="secondary.main">Media Gallery</Box>
                    </Typography>
                    <Box mt={1}>
                        <OrgBreadcrumbs current="Media Gallery" />
                    </Box>
                </Grid>
                <Box mt={5} textAlign="right" clone>
                    <Grid item md={4} lg={4} className={classes.addMediaButtonCont}>
                        <Button
                            variant="contained"
                            onClick={() => templateNavigation()}
                        >
                            Back
                        </Button>
                        {'\u00A0'}
                        {'\u00A0'}
                        {state.selectedComponent && (
                            <Button
                                color="primary"
                                variant="contained"
                                className={classes.primaryBtn}
                                onClick={() => handleModalStatus(typeObj.addMedia)}
                            >
                                Add Media
                            </Button>
                        )}
                    </Grid>
                </Box>
            </Grid>
            <div className={classes.mediaMainCont}>
                <div className={state.selectedComponent && mediaData?.length > 0 ? classes.width72 : classes.width100}>
                    {timelines && (
                        <Autocomplete
                            id="combo-box-demo"
                            options={timelines}
                            getOptionLabel={(option) => option.name}
                            classes={{ root: classes.autocompleteRoot }}
                            renderInput={(params) => <TextField {...params} placeholder="Select Timeline" variant="outlined" />}
                            onChange={(event, newValue) => {
                                handleAutoSelect(event, newValue);
                            }}
                        />
                    )}
                    {renderBreadcrumbs()}
                    <div className={classes.folderCont}>
                        {renderTimeline()}
                        {renderTimelineColumn()}
                        {renderProgProtocol()}
                        {renderComponent()}
                        {renderStackComponent()}
                        {renderCustomLoader()}
                        {renderCustomComponent()}
                    </div>
                    {renderProtocolComponent()}
                </div>
                <div className={state?.selectedComponent && mediaData?.length > 0 ? classes.width28 : classes.widthAuto}>
                    {state.selectedComponent && mediaData?.length > 0 && renderMediaDetails()}
                </div>
            </div>
            {renderContextMenu()}
            {state.treeDialogFlag && (
                <MediaFolderTree
                    open={state.treeDialogFlag}
                    handleClose={handleTreeDialogClose}
                    type={state.type}
                    handleTypeSubmit={handleTreeViewSubmit}
                    selectedTimeline={state.selectedTimeline}
                    isShowLoader={state.isShowLoader}
                    showLoader={showLoader}
                />
            )}

            {(isModalOpen || state.type === typeObj.copy || state.type === typeObj.duplicate) && (
                <MediaGalleryModal
                    open={isModalOpen}
                    handleClose={handleModalStatus}
                    folderId={mediaFolderId}
                    fetchFolderId={getFolderId}
                    imageUploadHandler={imageUploadHandler}
                    mediaAction={mediaAction}
                    selectedMedia={state.selectedMedia}
                    imageActionSuccessfull={imageActionSuccessfull}
                />
            )}
        </div>
    );
};

export default MediaFolder;
