import { MenuDefaults, buildComponentMenu } from './utils/EditorMenu';
import { Settings, makeInlineEditor } from './Settings';
import { TimelineContext, WorkshopContext } from '../context';
import { buildSettingsTreePage, setActiveComponent } from '../helper/commonHelper';
import { componentWithId } from './Atoms';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useRecoilState } from 'recoil';
import React, { useContext, useEffect } from 'react';

const useStyles = makeStyles(() => createStyles({
    formControl: {
        marginTop: 10,
        minWidth: 300,
    },
    h2: {
        background: '#B4B4B4',
        padding: '4px',
        marginBottom: '10px',
    },
    selectedComp: {
        background: 'cornflowerblue',
    },
}));

/**
 * Set the key and type for page in workshop
 */
const PagePropForWorkshop = {
    key: 'html',
    type: 'Page',
    placeholder: 'Edit Your Content Here!',
};

/**
 * This component is used in workshop section for editor
 * @param {object} config [configuration recieved from props]
 */
const HTML = (config) => {
    const classes = useStyles();
    const { inWorkshop, compareModal } = useContext(WorkshopContext);
    const { id, props } = config;
    const {
        settingComponent, setSettingComponentHandle, pageComponent, domain, dirtyDomain, setDirtyDomain,
    } = useContext(TimelineContext);
    const [component, setComponentState] = useRecoilState(componentWithId(id));
    const pageDomain = pageComponent && pageComponent[domain];
    const currentDomain = pageDomain && component[domain];
    let domainValue = currentDomain || { html: component?.props?.html };
    const componentList = [];
    buildSettingsTreePage(componentList, pageComponent && pageComponent[domain]);
    const newComp = componentList.find((c) => c.id === config.id);
    if (newComp && newComp[domain] && props.pageChild) {
        component[domain] = newComp[domain];
    }
    const currentComp = pageDomain?.components?.find((component) => config.id === component.id);
    if (currentComp && currentComp[domain]) {
        domainValue = currentComp[domain];
    }
    useEffect(() => {
        if (config.props.pageChild) {
            return;
        }
        if (!currentComp) return;
        if (!currentComp[domain]) {
            currentComp[domain] = domainValue;
        }
    }, [currentComp]);

    useEffect(() => {
        if (config.props.pageChild) {
            return;
        }
        if (currentDomain && pageDomain && !dirtyDomain) {
            const currentComp = pageDomain?.components?.find((component) => config.id === component.id);
            if (currentComp) {
                currentComp[domain] = currentDomain;
            }
        }
    }, [currentDomain, pageDomain, domain, dirtyDomain]);

    useEffect(() => {
        const pageDomain = component[domain];
        if (dirtyDomain) {
            return;
        }
        if (pageDomain && Object.keys(pageDomain).length > 0) {
            const newComp = componentList.find((c) => c.id === config.id);
            if (newComp) {
                newComp[domain] = pageDomain;
            }
        }
    }, [domain, component, id, dirtyDomain]);

    let isSelected = false;

    if (settingComponent && settingComponent.id === id) {
        isSelected = true;
    }

    /**
    * @function renderEditorTitle
    * To render the Froala editor component.
    */

    const renderEditor = () => (
        <>
            {
                makeInlineEditor({
                    field: PagePropForWorkshop,
                    component: config.props.pageChild ? { ...component, type: pageComponent?.type }
                        : { ...component, type: pageComponent?.type, [domain]: domainValue },
                    setComponentState,
                    classes: null,
                    domain,
                    setDirtyDomain,
                })
            }
        </>
    );

    const renderContent = () => (
        <div dangerouslySetInnerHTML={{ __html: config.props.html }} />
    );

    return (inWorkshop && !compareModal) ? (
        <div
            aria-hidden="true"
            className={isSelected ? classes.selectedComp : null}
            id={isSelected ? 'selectedCompId' : ''}
            onClick={(event) => { event.stopPropagation(); setActiveComponent(setSettingComponentHandle, config); }}
        >
            {renderEditor()}
        </div>
    ) : renderContent();
};

/**
 * This component is preview of page
 * @param {object} config [configuration recieved from props]
 */
const HTMLPreview = ({ component }) => (
    <div dangerouslySetInnerHTML={{ __html: component.props.html }} />
);

/**
 * Set the key and type for drag item in workshop
 */
const DragPropForWorkshop = {
    key: 'dragItem',
    type: 'DragItem',
    label: 'dragItem',
    placeholder: 'Enter drag Title',
    templates: [],
};

const PagePropsForSetting = {
    display: [],
    poi: false,
};

/**
 * This component is used in setting section with template library
 * @param {object} config [configuration recieved from props]
 * @param {function} updateComponent [recieved function as props to save page editor]
 */
const HTMLEditor = ({ config, updateComponent }) => {
    const [component, setComponentState] = useRecoilState(componentWithId(config.id));
    const { templateLibraryByType } = useContext(TimelineContext);
    const byType = templateLibraryByType.HTML;
    DragPropForWorkshop.templates = byType;

    return (
        <Settings editorProps={PagePropsForSetting} component={component} setComponentState={setComponentState} saveComponent={updateComponent} />
    );
};

const HTMLEditMenu = ({
    componentLibrary,
}) => {
    const all = buildComponentMenu(componentLibrary);
    const menu = [
        MenuDefaults.EDIT,
        MenuDefaults.DELETE,
        MenuDefaults.ADD_SIBLING(all),
        MenuDefaults.DUPLICATE,
    ];
    return menu;
};

export {
    HTML, HTMLEditor, HTMLPreview, HTMLEditMenu,
};
