import React, { useContext } from 'react';

import {
    Redirect,
    Route,
    Switch,
    useRouteMatch,
} from 'react-router-dom';

import {
    MediaProvider, OrgContext, TimelineContextProvider, UniversalContextProvider, UserAccessContextProvider, UserContextProvider,
} from './context';
import { RecoilRoot } from 'recoil';
import { TimelineContainer } from './TimelineContainer';
import ComponentTests from './ComponentTests';
import DataMatrix from './components/DataMatrix';
import ManageMedia from './components/ManageMedia';
import OrgLandingPage from './OrgLandingPage';
import PatientList from './PatientList';
import TemplateManager from './TemplateManager';
import TimelineList from './TimelineList';
import UserList from './UserList';

const Org = ({ showLoader }) => {
    const { loggedUserType, selectedOrg, idToken } = useContext(OrgContext);
    const { path } = useRouteMatch();

    if (!selectedOrg) {
        return showLoader(true);
    }

    return (
        <div>

            <Switch>
                <Route exact path={`${path}component-testing/`}>
                    <ComponentTests />
                </Route>
                <MediaProvider bearerToken={idToken} selectedOrg={selectedOrg}>
                    <TimelineContextProvider currentOrg={selectedOrg}>
                        <Route exact path={path}>
                            <UserContextProvider currentOrg={selectedOrg}>
                                <OrgLandingPage />
                            </UserContextProvider>
                        </Route>
                        <Route path={`${path}timelines/`}>
                            <UserContextProvider currentOrg={selectedOrg}>
                                <TimelineList showLoader={showLoader} />
                            </UserContextProvider>
                        </Route>
                        <Route path={`${path}templates/`}>
                            {loggedUserType !== 'Super Admin' && loggedUserType !== 'Doctor' ? <Redirect path={`${selectedOrg.orgCode}/timelines/`} />
                                : (
                                    <UserContextProvider currentOrg={selectedOrg}>
                                        <TemplateManager showLoader={showLoader} />
                                    </UserContextProvider>
                                ) }
                        </Route>
                        <Route path={`${path}timeline/:timelineId`}>
                            <UserAccessContextProvider>
                                <UserContextProvider currentOrg={selectedOrg}>
                                    <UniversalContextProvider currentOrg={selectedOrg}>
                                        <TimelineContainer showLoader={showLoader} />
                                    </UniversalContextProvider>
                                </UserContextProvider>
                            </UserAccessContextProvider>
                        </Route>
                        <Route exact path={`${path}timeline/:timelineId/dataMatrix/`}>
                            <UserAccessContextProvider>
                                <UserContextProvider currentOrg={selectedOrg}>
                                    <RecoilRoot>
                                        <DataMatrix showLoader={showLoader} />
                                    </RecoilRoot>
                                </UserContextProvider>
                            </UserAccessContextProvider>
                        </Route>
                        <Route path={`${path}media`}>
                            {loggedUserType !== 'Super Admin' && loggedUserType !== 'Doctor' ? <Redirect path={`${selectedOrg.orgCode}/timelines/`} />
                                : (
                                    <ManageMedia showLoader={showLoader} />
                                )}
                        </Route>
                        <Route path={`${path}patients/`}>
                            {loggedUserType !== 'Super Admin' && loggedUserType !== 'Doctor' ? <Redirect path={`${selectedOrg.orgCode}/timelines/`} />
                                : (
                                    <UserContextProvider currentOrg={selectedOrg}>
                                        <PatientList showLoader={showLoader} />
                                    </UserContextProvider>
                                )}
                        </Route>
                        <Route path={`${path}doctors/`}>
                            {loggedUserType !== 'Super Admin' && loggedUserType !== 'Doctor' ? <Redirect path={`${selectedOrg.orgCode}/timelines/`} />
                                : (
                                    <UserContextProvider currentOrg={selectedOrg}>
                                        <UserList showLoader={showLoader} doctorURL="doctor" />
                                    </UserContextProvider>
                                )}
                        </Route>
                        <Route path={`${path}users/`}>
                            {loggedUserType !== 'Super Admin' && loggedUserType !== 'Doctor' ? <Redirect path={`${selectedOrg.orgCode}/timelines/`} />
                                : (
                                    <UserContextProvider currentOrg={selectedOrg}>
                                        <UserList showLoader={showLoader} />
                                    </UserContextProvider>
                                )}
                        </Route>
                    </TimelineContextProvider>
                </MediaProvider>
            </Switch>

        </div>
    );
};

export default Org;
