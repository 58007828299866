/* eslint-disable no-param-reassign */
import {
    Box, Grid, Typography,
} from '@material-ui/core';
import { CommonContext, OrgContext } from './context';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import LogoImg from './images/logoFileDummy.svg';
import React, { useContext } from 'react';

const useStyles = makeStyles(() => ({
    manageMedia: {
        paddingLeft: 20,
    },
    orgCard: {
        backgroundColor: '#fff',
        borderRadius: 4,
        boxShadow: '0px 3px 12px #3e3c3c11',
        border: '0.5px solid #0000001a',
        cursor: 'pointer',
        '&:hover': {
            boxShadow: 'none',
            border: '0.8px solid #00000022',
        },
    },
    alignCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    orgNameDiv: {
        display: 'flex',
        alignItems: 'center',
    },
    orgIconFont: {
        fontFamily: 'CPS-Icon',
        fontSize: 20,
        padding: '7px 11px',
        borderRadius: '4px',
        backgroundColor: '#F7F9FB',
        border: '0.5px solid #EAF0F5',
    },
    orgAnchr: {
        textDecoration: 'none',
        color: 'black',
    },
    navLinkText: {
        textDecoration: 'none',
    },
    orgListContainer: {
        paddingLeft: 8,
    },
}));

const Org = ({ showLoader }) => {
    const { userOrgs } = useContext(OrgContext);
    const { countList } = useContext(CommonContext);
    const classes = useStyles();

    /**
     * Set the count of media, timeline and patient
     */
    if (userOrgs && userOrgs.length > 0) {
        userOrgs.forEach((data) => {
            if (countList && countList.organization_stats) {
                const orgStats = countList.organization_stats;
                const filterOrg = orgStats.filter((media) => media.id === data.id);

                if (filterOrg.length > 0) {
                    data.mediaCount = filterOrg[0].media_count;
                    data.timelineCount = filterOrg[0].timeline_count;
                    data.patientCount = filterOrg[0].patient_count;
                }
            }
        });
    }

    return (
        <div>
            <Grid container>
                <Grid item md={12} lg={12}>
                    <Typography variant="h2">
                        <Box ml={4} mt={5} color="secondary.main">MedGuide Contents</Box>
                    </Typography>
                </Grid>
                <Box mt={4} ml={3} mr={3} clone>
                    <Grid container>
                        {userOrgs && userOrgs.map((org) => (
                            <Grid key={org.orgCode} item md={6} lg={4} className={classes.orgListContainer}>
                                <div className={classes.orgCard}>
                                    <NavLink className={classes.orgAnchr} to={`/${org.orgCode}/`}>
                                        <Box mt={2} mb={1}>
                                            <Grid container>
                                                <Grid item md={2} lg={2} className={classes.alignCenter}>
                                                    <img src={LogoImg} alt="logo" />
                                                </Grid>
                                                <Grid item md={9} lg={9} className={classes.orgNameDiv}>
                                                    <Typography variant="h4">
                                                        <Box color="secondary.main">{org.name}</Box>
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </NavLink>
                                    <NavLink className={classes.orgAnchr} to={`/${org.orgCode}/`}>
                                        <Box mt={3} ml={2} mb={2}>
                                            <Grid container>
                                                <Grid item md={4} lg={4} className={classes.orgLogoDiv}>
                                                    <Box display="flex">
                                                        <Box order={1}>
                                                            <Typography color="primary" className={classes.orgIconFont}>C</Typography>
                                                        </Box>
                                                        <Box order={2} ml={0.5}>
                                                            <Grid container>
                                                                <Grid item md={12} lg={12} className={classes.alignCenter}>
                                                                    <Typography variant="h4">
                                                                        <Box color="secondary.main">{org.timelineCount}</Box>
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item md={12} lg={12} className={classes.alignCenter}>
                                                                    <Typography variant="subtitle1">
                                                                        <Box color="secondary.light">Timeline</Box>
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                                <Grid item md={4} lg={4} className={classes.orgLogoDiv}>
                                                    <Box display="flex">
                                                        <Box order={1}>
                                                            <Typography color="primary" className={classes.orgIconFont}>B</Typography>
                                                        </Box>
                                                        <Box order={2} ml={0.5}>
                                                            <Grid container>
                                                                <Grid item md={12} lg={12} className={classes.alignCenter}>
                                                                    <Typography variant="h4">
                                                                        <Box color="secondary.main">{org.patientCount}</Box>
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item md={12} lg={12} className={classes.alignCenter}>
                                                                    <Typography variant="subtitle1">
                                                                        <Box color="secondary.light">Patients</Box>
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                                <Grid item md={4} lg={4} className={classes.orgLogoDiv}>
                                                    <Box display="flex">
                                                        <Box order={1}>
                                                            <Typography color="primary" className={classes.orgIconFont}>A</Typography>
                                                        </Box>
                                                        <Box order={2} ml={0.5}>
                                                            <Grid container>
                                                                <Grid item md={12} lg={12} className={classes.alignCenter}>
                                                                    <Typography variant="h4">
                                                                        <Box color="secondary.main">{org.mediaCount}</Box>
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item md={12} lg={12} className={classes.alignCenter}>
                                                                    <Typography variant="subtitle1">
                                                                        <Box color="secondary.light">Media</Box>
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </NavLink>
                                </div>
                            </Grid>
                        ))}
                        {!userOrgs && showLoader(true)}
                    </Grid>
                </Box>
            </Grid>
        </div>
    );
};
export default Org;
