import 'date-fns';
import { DefaultChildren, DefaultChildrenPreview } from '../DefaultChildren';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { OrgContext, TimelineContext, WorkshopContext } from '../../context';
import { Settings, makeInlineEditor } from '../Settings';
import {
    ThemeProvider, createMuiTheme, createStyles, makeStyles,
} from '@material-ui/core/styles';
import { buildSettingsTreePage, setActiveComponent } from '../../helper/commonHelper';
import { componentWithId } from '../Atoms';
import { useRecoilState } from 'recoil';
import DateFnsUtils from '@date-io/date-fns';
import React, {
    Fragment, useContext, useEffect, useState,
} from 'react';

const theme = createMuiTheme({
    overrides: {
        MuiInputBase: {
            input: {
                fontSize: '10px',
                paddingLeft: '5px',
            },
        },
        MuiSvgIcon: {
            root: {
                fontSize: '1rem',
                marginRight: '12px',
            },
        },
        MuiIconButton: {
            root: {
                padding: '0px',
            },
        },
    },
});

const useStyles = makeStyles(() => createStyles({
    gridList: {
        width: 500,
        height: 450,
    },
    imageSet: {
        height: '150px',
    },
    columHeadType: {
        fontSize: '0.75rem',
    },
    genricDate: {
        margin: '0px',
    },
    selectedComp: {
        background: 'cornflowerblue',
    },
}));

/**
 * Set the key and type of the field
 */
const datePropForWorkshop = {
    key: 'label',
    type: 'DatePicker',
    label: 'Default Date',
    id: 'DatePicker',
};

/**
 * Define the setting's dropdown value
 */
export const datePropForSettings = {
    display: [{
        key: 'columnNumber',
        type: 'StaticLabel',
        label: 'Column',
        value: '1',
        id: 'DatePicker',
    }],
    poi: true,
};

/**
 * @function DatePicker [create the datepicker]
 * @param {string} id
 * @param {object} components
 * @param {boolean} noChildren
 * @returns {component}
 */
const DatePicker = (componentProps) => {
    const {
        id, components, noChildren = false, column,
    } = componentProps;
    const classes = useStyles();
    const date = column?.props?.date ? new Date(column.props.date) : new Date();
    const [selectedDate, setDate] = useState(date);
    const { inWorkshop, compareModal } = useContext(WorkshopContext);
    const {
        settingComponent,
        setSettingComponentHandle,
        setDirtyDomain,
        domain,
        pageComponent,
        dirtyDomain,
        selectedComponent,
        updateTimeline,
    } = useContext(TimelineContext);
    const [component, setComponentState] = useRecoilState(componentWithId(id || `${selectedComponent?.id}_date`));
    const componentList = [];
    buildSettingsTreePage(componentList, pageComponent && pageComponent[domain]);
    if (componentProps?.[domain]) {
        component[domain] = componentProps[domain];
    }
    let isSelected = false;
    if (settingComponent && settingComponent.id === id) {
        isSelected = true;
    }
    useEffect(() => {
        const pageDomain = component[domain];
        if (dirtyDomain) {
            return;
        }
        if (pageDomain && Object.keys(pageDomain).length > 0) {
            const currentComp = componentList.find((c) => c.id === id);
            if (currentComp) {
                currentComp[domain] = pageDomain;
            }
        }
    }, [domain, component, id, dirtyDomain]);

    const renderDefaultDateEditor = () => makeInlineEditor({
        field: datePropForWorkshop,
        component,
        setComponentState,
        classes,
        domain,
        setDirtyDomain,
    });
    const { loggedUserType } = useContext(OrgContext);
    const isSuperAdmin = loggedUserType === 'Super Admin';

    const handleChangeDate = (date) => {
        if (column.props.date) column.props.date = date.toString();
        setDate(date);
        if (!isSuperAdmin) {
            updateTimeline();
        }
    };

    const renderDate = () => {
        let selectedValue = selectedDate;

        if (component && component.props) {
            selectedValue = component.props.label;
        }

        return (
            <ThemeProvider theme={theme}>
                <div className={classes.columHeadType} color="inherit">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            className={classes.genricDate}
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            value={selectedValue}
                            id="date-picker-inline"
                            onChange={handleChangeDate}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </div>
            </ThemeProvider>
        );
    };

    return (
        <Fragment key="key">
            {(inWorkshop && !compareModal) ? (
                <div
                    className={isSelected ? classes.selectedComp : null}
                    id={isSelected ? 'selectedCompId' : ''}
                    onClick={(event) => { event.stopPropagation(); setActiveComponent(setSettingComponentHandle, componentProps); }}
                    aria-hidden="true"
                >
                    {renderDefaultDateEditor()}
                </div>
            ) : renderDate()}
            {!noChildren && <DefaultChildren indent components={components} id={id} />}
        </Fragment>
    );
};

/**
 * @function DatePickerPreview [Preview the datepicker]
 * @param {object} components
 * @param {boolean} noChildren
 * @returns {component}
 */
const DatePickerPreview = ({ components, noChildren = false }) => (
    <Fragment>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                disableToolbar
                disabled
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
            />
        </MuiPickersUtilsProvider>
        {!noChildren && <DefaultChildrenPreview indent components={components} />}
    </Fragment>
);

/**
 * @function DatePickerEditor [Open setting for datepicker]
 * @param {object} config
 * @param {object} updateComponent
 * @returns {component}
 */
const DatePickerEditor = ({ config, updateComponent }) => {
    const [component, setComponentState] = useRecoilState(componentWithId(config.id));

    return (
        <Settings editorProps={datePropForSettings} component={component} setComponentState={setComponentState} saveComponent={updateComponent} />
    );
};

export { DatePicker, DatePickerPreview, DatePickerEditor };
