import 'tui-image-editor/dist/tui-image-editor.css';
import { MediaContext } from '../../context';
import { getUniqueUUID } from '../../context/util';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import ImageEditor from '@toast-ui/react-image-editor';
import Loading from '../../images/loading.png';
import React, {
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import Resizer from 'react-image-file-resizer';

const useStyles = makeStyles(() => ({
    widthSectPart: {
        width: '90%',
        height: '90%',
        overflow: 'auto',
    },
    alignRight: {
        textAlign: 'right',
    },
    closeIconStyle: {
        cursor: 'pointer',
        marginTop: '.5rem',
    },
    cancelBtn: {
        maxHeight: '56px',
        padding: '.25rem 1rem',
        boxShadow: 'none',
        '&:hover': {
            boxShadow: 'none',
        },
    },
    primaryBtn: {
        maxHeight: '56px',
        padding: '.25rem 1rem',
        boxShadow: 'none',
        '&:hover': {
            boxShadow: 'none',
        },
    },
}));

const ToastUiImageEditor = ({
    imageEditorData,
    imageEditorRef,
}) => {
    const [initialImage, setInitialImage] = useState({
        url: Loading,
        name: 'loading.png',
    });

    useEffect(() => {
        let imageURL = imageEditorData.url;
        if (imageEditorData.url.endsWith('?no-cache-image')) {
            const noImageCacheIndex = imageEditorData.url.indexOf('?no-cache-image');
            imageURL = imageEditorData.url.substring(0, noImageCacheIndex);
        }
        fetch(`${imageURL}?${getUniqueUUID}`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                Origin: '*',
            },
        })
            .then((value) => value.blob())
            .then((value) => {
                const objectURL = URL.createObjectURL(value);
                const img = new Image();
                img.crossOrigin = 'Anonymous';
                img.onload = () => {
                    setInitialImage({
                        url: objectURL,
                        name: imageEditorData.name,
                    });
                };
                img.src = objectURL;
            })
            .catch((reason) => {
                // eslint-disable-next-line no-console
                console.log(reason);
            });
    }, []);

    useEffect(() => {
        document.getElementsByClassName('tie-btn-zoomIn')[0].remove();
        document.getElementsByClassName('tie-btn-zoomOut')[0].remove();
        document.getElementsByClassName('tie-btn-hand')[0].remove();
        document.getElementsByClassName('tie-btn-delete')[0].remove();
        document.getElementsByClassName('tie-btn-deleteAll')[0].remove();
    }, [initialImage.url]);

    const myTheme = {
        'header.display': 'none',
    };

    return (
        <ImageEditor
            key={initialImage.url}
            ref={imageEditorRef}
            includeUI={{
                loadImage: {
                    path: initialImage.url,
                    name: initialImage.name,
                },
                theme: myTheme,
                menu: ['crop', 'flip', 'rotate', 'draw', 'text', 'filter'],
                initMenu: 'filter',
                uiSize: {
                    width: '1000px',
                    height: '700px',
                },
                menuBarPosition: 'right',
            }}
            cssMaxHeight={500}
            cssMaxWidth={700}
            selectionStyle={{
                cornerSize: 20,
                rotatingPointOffset: 70,
            }}
            usageStatistics
        />
    );
};

const ImageEditorDialog = ({
    imageEditorDialog,
    setImageEditorDialog,
    setImageEditorLoader,
    imageEditorData,
    imgSrc,
}) => {
    const classes = useStyles();
    const imageEditorRef = useRef(null);
    const { uploadImage } = useContext(MediaContext);

    const closeImageEditorDialog = () => {
        setImageEditorDialog(false);
    };

    const saveImage = () => {
        const imageEditorInstance = imageEditorRef.current.getInstance();
        const url = imageEditorInstance.toDataURL();
        fetch(url)
            .then((res) => res.blob())
            .then((blob) => {
                const file = new File([blob], imageEditorData.name, { type: imageEditorData.filetype });
                setImageEditorLoader(true);
                const img = new Image();
                const objectUrl = URL.createObjectURL(file);
                img.onload = () => {
                    try {
                        let compressionFormat = '';
                        if (imageEditorData.filetype.includes('jpeg') || imageEditorData.filetype.includes('jpg')) {
                            compressionFormat = 'JPEG';
                        } else if (imageEditorData.filetype.includes('png')) {
                            compressionFormat = 'PNG';
                        } else {
                            compressionFormat = 'WEBP';
                        }
                        Resizer.imageFileResizer(
                            file,
                            img.width,
                            img.height,
                            compressionFormat,
                            95,
                            0,
                            (uri) => {
                                uploadImage(
                                    imgSrc?.['folder_id'],
                                    [uri],
                                    () => { setImageEditorDialog(false); },
                                    (insertMediaResult) => {
                                        imgSrc.id = insertMediaResult.id;
                                        imgSrc.url = `${insertMediaResult.url}?no-cache-image`;
                                        imgSrc.created_at = insertMediaResult.created_at;
                                        imgSrc.updated_at = insertMediaResult.updated_at;
                                        const refetchMediaButton = document.getElementById('refetch-media-button');
                                        if (refetchMediaButton) {
                                            refetchMediaButton.click();
                                        }
                                        setImageEditorLoader(false);
                                    },
                                    () => { setImageEditorLoader(false); return true; }
                                );
                            },
                            'file',
                            img.width,
                            img.height,
                        );
                    } catch {
                        // console.error(err);
                        toast.error('Something went wrong');
                    }
                };
                img.src = objectUrl;
            });
    };

    return (
        <Dialog
            onClose={closeImageEditorDialog}
            open={imageEditorDialog}
            classes={{ paperWidthSm: classes.widthSectPart }}
            fullScreen
            disableEnforceFocus
        >
            <DialogTitle>
                <Grid container>
                    <Grid item xs={10}>
                        Image Editor
                    </Grid>
                    <Grid item xs={2}>
                        <Box
                            display="flex"
                            justifyContent="right"
                            alignItems="right"
                        >
                            <CloseIcon onClick={closeImageEditorDialog} className={classes.closeIconStyle} />
                        </Box>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <ToastUiImageEditor imageEditorData={imageEditorData} imageEditorRef={imageEditorRef} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeImageEditorDialog} variant="contained" color="default" className={classes.cancelBtn}>
                    Cancel
                </Button>
                <Button onClick={saveImage} variant="contained" color="primary" className={classes.primaryBtn}>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ImageEditorDialog;
