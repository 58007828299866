/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
import { DefaultChildren, DefaultChildrenPreview } from './DefaultChildren';
import { FilterSettings, buildSettingsTreePage, setActiveComponent } from '../helper/commonHelper';
import { IconButton } from '@material-ui/core';
import { MenuDefaults, buildComponentMenu } from './utils/EditorMenu';
import { OrgContext, TimelineContext, WorkshopContext } from '../context';
import { Settings, makeInlineEditor } from './Settings';
import { componentWithId, updateComponentCallback } from './Atoms';
import { makeStyles } from '@material-ui/core/styles';
import { standardHeaders } from './utils/StandardStyles';
import { useRecoilCallback, useRecoilState } from 'recoil';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, {
    useCallback, useContext, useEffect, useState,
} from 'react';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
    formControl: {
        margin: '10px',
        width: '95%',
    },
    headerFormControl: {
        width: '98%',
        margin: '2px 8px',
        padding: '0px 0px 8px 8px',
    },
    ...standardHeaders,
    columHeadType: {
        fontSize: '0.75rem',
        width: '100%',
        color: '#000000de',
    },
    sectionHeader: {
        margin: 0,
        minHeight: 'auto !important',
        height: 22,
        border: '1px solid #ececec',
    },
    iconButton: {
        color: '#fff',
        position: 'absolute',
        left: 0,
        padding: 0,
        marginLeft: 12,
    },
    iconButtonHide: {
        display: 'none',
        position: 'absolute',
        left: 0,
    },
    selectedComp: {
        border: '2px solid cornflowerblue',
    },
    diaplayNone: {
        display: 'none',
    },
    dmTextLabel: {
        padding: 8,
        fontSize: 12,
    },
    accordionStyle: {
        margin: 0,
    },
    removeBoxShadow: {
        boxShadow: 'none',
    },
}));

const TextProp = {
    key: 'text',
    type: 'TextField',
    label: 'Header',
    id: 'Text',
};

const Text = (componentProps) => {
    const {
        id, isProgression, props: { components }, noChildren = false,
        dmColumViewType = null, selectedDropdownValue = null, domain: propsDomain,
    } = componentProps;

    const {
        selectedTimeline,
        getLocationOfTargetComponent,
        settingComponent,
        setSettingComponentHandle,
        domain,
        setDirtyDomain,
        dirtyDomain,
        pageComponent,
        filteredChildIds,
        selectedHeader,
        isDMRC,
        isDMViewMode,
        workshopComponent,
        editTimelineColumn,
        progressionAccCollapse,
        contextMenuPosition,
        contextMenuTarget,
        setCollapseProtocol,
        setCDComponent,
        workshopAccessData,
        wpHeaders,
        updateFlag,
        setUpdateFlag,
    } = useContext(TimelineContext);

    const { loggedUserType } = useContext(OrgContext);

    const componentList = [];
    buildSettingsTreePage(componentList, pageComponent && pageComponent[domain]);
    const [component, setComponentState] = useRecoilState(componentWithId(id));
    const childComponents = component?.props?.childComponents;
    const componentsFiltered = selectedHeader?.id !== id ? components : components.filter((item) => !filteredChildIds.includes(item.id));
    const updateRecoilComponent = useRecoilCallback(updateComponentCallback);
    const [accordionFlag, setAccordionFlag] = useState(false);
    let isDblClick = false;

    const classes = useStyles();
    const { inWorkshop, compareModal } = useContext(WorkshopContext);
    const renderTextEditor = () => makeInlineEditor({
        field: TextProp,
        component,
        setComponentState,
        classes,
        domain,
        setDirtyDomain,
    });
    const style = classes[component?.props?.type];

    useEffect(() => {
        if (isProgression && progressionAccCollapse) {
            let location = [];
            for (let columnIterator = 0; columnIterator < selectedTimeline.columns.length; columnIterator += 1) {
                location = getLocationOfTargetComponent(component, columnIterator, true, id);

                if (location && location.length > 0) {
                    break;
                }
            }

            selectedTimeline.columns.forEach((column) => {
                if (!column?.props?.isProfile) {
                    let targetProgressionElement = column.props.progressions[location[0]];
                    if (targetProgressionElement) {
                        for (let iterator = 1; iterator < location.length; iterator += 1) {
                            targetProgressionElement = targetProgressionElement.props.components[location[iterator]];
                        }
                    }

                    if (targetProgressionElement && targetProgressionElement.props && targetProgressionElement.props.isExpanded) {
                        targetProgressionElement.props.isExpanded = false;
                        updateRecoilComponent(targetProgressionElement.id, targetProgressionElement.props);
                    }
                }
            });
        } else if (progressionAccCollapse) {
            component.props.isExpanded = false;
            updateRecoilComponent(component.id, component.props);
            setAccordionFlag(!accordionFlag);
        }
    }, []);

    useEffect(() => {
        if (childComponents) {
            component.props.components.forEach((item) => {
                if (item?.type === 'Text') {
                    item.props.childComponents = true;
                }
            });
        }
    }, [childComponents, component]);

    let isSelected = false;
    if (propsDomain) {
        component[domain] = propsDomain;
    }

    if ((settingComponent && settingComponent.id === id)
        || (!workshopComponent && contextMenuPosition && contextMenuTarget?.component?.id === componentProps?.id)) {
        isSelected = true;
    }

    const handleChange = (isExpanded, clickedComponent) => {
        if (isProgression) {
            let location = [];
            for (let columnIterator = 0; columnIterator < selectedTimeline.columns.length; columnIterator += 1) {
                location = getLocationOfTargetComponent(clickedComponent, columnIterator, true, id);

                if (location && location.length > 0) {
                    break;
                }
            }

            selectedTimeline.columns.forEach((column) => {
                if (!column?.props?.isProfile) {
                    let targetProgressionElement = column.props.progressions[location[0]];
                    if (targetProgressionElement) {
                        for (let iterator = 1; iterator < location.length; iterator += 1) {
                            targetProgressionElement = targetProgressionElement.props.components[location[iterator]];
                        }
                    }

                    if (targetProgressionElement && targetProgressionElement.props) {
                        targetProgressionElement.props.isExpanded = isExpanded;
                        updateRecoilComponent(targetProgressionElement.id, targetProgressionElement.props);
                    }
                }
            });
        } else {
            clickedComponent.props.isExpanded = isExpanded;
            updateRecoilComponent(clickedComponent.id, clickedComponent.props);
            setAccordionFlag(!accordionFlag);
            if (isDblClick) {
                setCollapseProtocol([...[{ id: 1, dbClick: true }], ...clickedComponent.props.components]);
                isDblClick = false;
            } else {
                setCollapseProtocol([]);
            }
        }
    };

    useEffect(() => {
        const pageDomain = component[domain];
        if (dirtyDomain) {
            return;
        }
        if (pageDomain && Object.keys(pageDomain).length > 0) {
            const currentComp = componentList.find((c) => c.id === id);

            if (currentComp) {
                currentComp[domain] = pageDomain;
            }
        }
    }, [domain, component, id, dirtyDomain]);

    useEffect(() => {
        if (wpHeaders !== null) {
            const isExpand = wpHeaders?.id === component?.props?.protocolId;
            if (isExpand) {
                component.props.isExpanded = true;
                setUpdateFlag(!updateFlag);
            }
        }
    }, [wpHeaders]);

    const headerType = component?.props?.type;

    const debouncer = (fn, delay, doubleClick) => {
        let timer = null;

        // eslint-disable-next-line func-names
        return function () {
            // eslint-disable-next-line prefer-rest-params
            const args = arguments;
            if (args[0]?.detail === 2 || doubleClick) {
                isDblClick = true;
            }

            const context = this;
            clearTimeout(timer);
            timer = setTimeout(() => {
                fn.apply(context, args);
            }, delay);
        };
    };

    const clickHandler = () => {
        handleChange(!component?.props?.isExpanded, componentProps);
    };

    const debouncedClickHandler = debouncer(clickHandler, 300);
    const debouncedDoubleClickHandler = debouncer(clickHandler, 300, true);
    const memoizedClickHandler = useCallback(debouncedClickHandler, [component.props.isExpanded]);
    const handleExpandALlClick = useCallback((e) => {
        e.stopPropagation();
        debouncedDoubleClickHandler();
    }, [component.props.isExpanded]);

    if (inWorkshop && !compareModal) {
        return (
            <div onClick={(e) => { e.stopPropagation(); }}>
                <div
                    id={isSelected ? 'selectedCompId' : ''}
                    onClick={!isSelected ? () => setActiveComponent(setSettingComponentHandle, componentProps) : ''}
                    key="key"
                >
                    {renderTextEditor()}
                    {!noChildren && childComponents && <DefaultChildren components={components} />}
                </div>
            </div>
        );
    }

    if (isDMRC && !isDMViewMode) {
        return (
            <Typography
                className={[classes.dmTextLabel, `${component?.props?.type ? component?.props?.type : 'other'}Cls`].join(' ')}
                color="inherit"
            >
                {component?.props?.text}
            </Typography>
        );
    }

    if (loggedUserType === 'Doctor') {
        if (workshopAccessData?.[`${componentProps?.id}`]?.['user_type'] === 'Staff') {
            return '';
        }
    }
    if (loggedUserType === 'Patient') {
        if (workshopAccessData?.[`${componentProps?.id}`]?.['user_type'] === 'Staff'
            || workshopAccessData?.[`${componentProps?.id}`]?.['user_type'] === 'Patient') {
            return '';
        }
    }

    return (
        <Accordion
            expanded={component?.props?.isExpanded}
            onChange={() => setCDComponent(null)}
            classes={{ root: classes.accordionStyle }}
            className={[classes.removeBoxShadow, isSelected && classes.selectedComp].join(' , ')}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={style}
                onClick={memoizedClickHandler}
                classes={{
                    root: classes.sectionHeader,
                    expandIcon: headerType === 'h4' ? classes.iconButtonHide : classes.iconButton,
                }}
            >
                <Typography className={classes.columHeadType} color="inherit">
                    {component?.props?.text}
                </Typography>
                <IconButton
                    aria-label="expand-more"
                    size="small"
                    onClick={handleExpandALlClick}
                    style={{
                        rotate: '90deg',
                    }}
                >
                    <DoubleArrowIcon style={{
                        fontSize: '14px',
                        color: 'white',
                    }}
                    />
                </IconButton>
            </AccordionSummary>
            <AccordionDetails style={{ display: 'block', padding: '0px' }}>
                {component?.props?.isExpanded && !noChildren
                    && (editTimelineColumn || !workshopComponent)
                    && (
                        <DefaultChildren
                            components={componentsFiltered}
                            id={id}
                            dmColumViewType={dmColumViewType}
                            selectedDropdownValue={selectedDropdownValue}
                        />
                    )}
            </AccordionDetails>
        </Accordion>
    );
};

const TextPreview = ({ component, isAdminSetting = false, isTimlineCol = false }) => {
    const classes = useStyles();
    const { id, props: { text, type = 'body1', components } } = component;
    const style = classes[type];

    const renderContainer = () => {
        let renderItem = null;
        if (isAdminSetting && isTimlineCol) { // render timeline column
            renderItem = <DefaultChildrenPreview components={components} id={id} />;
        } else if (!isAdminSetting && !isTimlineCol) { // render other component
            renderItem = <DefaultChildrenPreview components={components} id={id} />;
        }
        return renderItem;
    };

    return (
        <React.Fragment key={id}>
            <Typography className={`${classes.columHeadType} ${style}`} color="inherit">
                {text}
            </Typography>
            {renderContainer()}
        </React.Fragment>
    );
};

export const TextProps = {
    display: [
        TextProp,
        {
            key: 'childComponents',
            type: 'Checkbox',
            label: 'Child Components',
            secondaryLabel: 'Show Child Components',
            value: false,
            id: 'Text',
        },
        {
            key: 'component',
            type: 'StaticLabel',
            label: 'Component',
            value: 'N/A',
            id: 'Text',
        },
    ],
    poi: true,
};

const TextEditor = ({ config, updateComponent }) => {
    const [component, setComponentState] = useRecoilState(componentWithId(config.id));
    const { settingMenuType } = useContext(TimelineContext);
    const filterHeaderChild = (displayConfig) => {
        const hasHeader = (config.type === 'Text');
        if (hasHeader && config.props.components.length === 0) {
            displayConfig = displayConfig.filter((obj) => obj?.key !== 'childComponents');
        }
        return displayConfig;
    };
    const editorProps = { display: filterHeaderChild(FilterSettings(TextProps, settingMenuType)), poi: TextProps.poi };
    editorProps.display.map((element) => {
        let name = null;
        switch (config.props.type) {
        case 'h1':
            name = 'H1 Header';
            break;
        case 'h2':
            name = 'H2 Header';
            break;
        case 'h3':
            name = 'H3 Header';
            break;
        case 'h4':
            name = 'Protocol Group';
            break;
        default:
            name = 'Header';
        }
        if (element.key === 'component') {
            element.value = name || 'NA';
        }

        return null;
    });

    return (
        <Settings editorProps={editorProps} component={component} setComponentState={setComponentState} saveComponent={updateComponent} />
    );
};

const TextEditMenu = ({
    componentLibrary, component, parent, copyProtocolComponent,
}) => {
    const clickedArea = component.clickedArea || ((component.isProgression || parent.isProgression) ? 'Progression' : 'Protocol');
    const clickedComponent = component?.props?.type;
    let headerId = 'cl-112';
    if (clickedComponent === 'h1') {
        headerId = 'cl-111';
    }
    const filter = (item) => (item.id === headerId || item.type === 'Protocol');
    const labelMaker = (label) => (`Add ${label}`);
    const child = buildComponentMenu(componentLibrary, filter, labelMaker, 'child', component);
    const acccessViewChild = [
        {
            label: 'Basic',
            children: null,
            action: { type: 'accessViewHeader', value: 'isBasic' },
        },
        {
            label: 'Advanced',
            children: null,
            action: { type: 'accessViewHeader', value: 'isAdvanced' },
        },
        {
            label: 'Comprehensive',
            children: null,
            action: { type: 'accessViewHeader', value: 'isUserView' },
        },
    ];
    let menu = [
        ...child,
        MenuDefaults.EDIT_PROTOCOL(component),
        MenuDefaults.TIMELINE_USER_VIEW,
        MenuDefaults.DELETE,
        MenuDefaults.ADD_ACCESS_VIEW(acccessViewChild),
    ];

    if (copyProtocolComponent && clickedArea === 'Protocol') {
        menu = [...menu, MenuDefaults.PASTE_HEADER_CHILD];
    }
    if (clickedArea === 'Progression') {
        delete menu[1].children[4];
    }

    if (clickedArea === 'Protocol') {
        delete menu[1].children[3];
    }
    return menu;
};

const TimelineDeleteTextMenu = ({
    componentLibrary, component, parent, copyProtocolComponent,
}) => {
    const customMenu = TextEditMenu({
        componentLibrary, component, parent, copyProtocolComponent,
    });
    const deleteIndex = customMenu.findIndex((menu) => menu?.label === 'Delete');
    if (deleteIndex !== -1) {
        customMenu[deleteIndex].children = [
            { label: 'Delete without children', action: { type: 'deleteWithoutChild' } },
            { label: 'Delete with children', action: { type: 'deleteWithChild' } },
        ];
    }
    return customMenu;
};

export {
    Text, TextEditor, TextEditMenu, TextPreview, TimelineDeleteTextMenu,
};
