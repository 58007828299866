import {
    Box, Grid, Tooltip, Typography,
} from '@material-ui/core';
import { Container } from './Container';
import { RecoilRoot } from 'recoil';
import { TemplateBrowser } from '../TemplateBrowser';
import { TimelineContext } from '../context';
import { findParent } from '../helper/commonHelper';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useRouteMatch } from 'react-router-dom';
import DataMatrixColumn from './DataMatrixColumn';
import DataMatrixPOI from './DataMatrixPOI';
import DataMatrixRClick from './DataMatrixRClick';
import DataMatrixSetting from './DataMatrixSetting';
import DataMatrixWorkshop from './DataMatrixWorkshop';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import OrgBreadcrumbs from './utils/OrgBreadcrumbs';
import React, { useContext, useEffect, useState } from 'react';
import TemplateLibraryDialog from './TemplateLibraryDialog';

const useStyles = makeStyles(() => ({
    library: {
        width: '100%',
        height: 'auto',
        border: '1px solid #DFDFDF',
    },
    library1: {
        width: '100%',
        border: '1px solid #DFDFDF;',
    },
    templateLibraryTextCont: {
        textAlign: 'center',
        paddingTop: 8,
        fontSize: 12,
        height: 35,
        borderBottom: '1px solid #D5D5D5',
    },
    templateLibraryTextCont1: {
        textAlign: 'center',
        paddingTop: 8,
        fontSize: 12,
        height: 35,
    },
    templateLibraryIconCont: {
        marginTop: 3,
        textAlign: 'right',
        paddingRight: 4,
    },
    workshopTitle1: {
        padding: '8px 0px',
        textAlign: 'center',
        width: '100%',
        height: 35,
        border: '1px solid #011E27',
        marginBottom: '1rem',
        fontSize: 12,
        textTransform: 'uppercase',
    },
    workshopTitle2: {
        textAlign: 'center',
        width: '100%',
        height: 35,
        border: '1px solid #011E27',
        marginBottom: '1rem',
        fontSize: 12,
        alignItems: 'center',
    },
    workshopTitle: {
        padding: '8px 0px',
        textAlign: 'center',
        width: '100%',
        border: '1px solid #D5D5D5',
        borderTop: 'none',
        fontSize: 12,
        height: 35,
    },
    workshopTitle3: {
        textAlign: 'center',
        width: '100%',
        borderLeft: '1px solid #D5D5D5',
        fontSize: 12,
        height: 35,
    },
    workshopTitleItem: {
        cursor: 'pointer',
    },
    dataMatrixColumn2Cont: {
        padding: '4px 2px',
        background: '#FFFFFF',
    },
    dataMatrixColumn3Cont: {
        padding: '4px 2px',
        background: '#FFFFFF',
        width: 775,
    },
    dataMatrixColumn4Cont: {
        padding: '4px 2px',
        background: '#FFFFFF',
        height: 'max-content',
        width: 600,
    },
    workShopTemplateCont: {
        border: '1px solid #D5D5D5',
        margin: '3rem .25rem 0rem .25rem',
        height: 'max-content',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    dataMatrixSettingCont: {
        border: '1px solid #D5D5D5',
        margin: '3rem .5rem 0rem 0rem',
        padding: 4,
        maxHeight: '77.65vh',
    },
    dataMatrixSettingContLeft: {
        padding: '0px 0px 4px 0px',
        width: 1125,
    },
    workshopSectionCont: {
        color: '#FFFFFF',
        background: '#1B1B1B',
        border: '1px solid #D5D5D5',
    },
    columnSectionCont: {
        borderTop: '1px solid #D5D5D5',
        height: '67vh',
        overflowY: 'auto',
    },
    highLightCls: {
        border: '3px solid cornflowerblue',
    },
    columnCont: {
        background: '#E6E5E5',
        textAlign: 'center',
        padding: '4px 0px',
        marginBottom: 4,
        fontSize: 12,
    },
    columnCont1: {
        background: '#E6E5E5',
        textAlign: 'center',
        padding: '4px 0px',
        marginBottom: 4,
        fontSize: 12,
        paddingLeft: '35%',
    },
    columnCont2: {
        background: '#E6E5E5',
        textAlign: 'center',
        padding: '4px 0px',
        marginBottom: 4,
        fontSize: 12,
        paddingLeft: '20%',
    },
    dmContainerMain: {
        display: 'flex',
        flexDirection: 'row',
        overflowX: 'auto',
    },
    viewPOICont: {
        paddingLeft: 4,
    },
    templateBrowserCont: {
        overflowY: 'auto',
        overflowX: 'hidden',
        maxHeight: '63vh',
    },
    dmTitleCont: {
        marginLeft: 4,
    },
    dmTitle: {
        textAlign: 'left',
        margin: '10px 0px',
    },
    SwitchContMain: {
        background: '#E6E5E5',
        height: 29,
        padding: 5,
    },
    switchCont: {
        margin: '-2px 5px 0px -10px',
        textAlign: 'end',
    },
    dmCont1: {
        marginTop: '3rem',
    },
    fontSize12Px: {
        fontSize: 12,
    },
    clickMenuContMain: {
        paddingLeft: 4,
    },
    withoutComponentCont: {
        color: '#066785',
        background: '#FFFFFF',
        textAlign: 'center',
        paddingTop: 2,
        cursor: 'pointer',
    },
    withComponentCont: {
        color: '#FFFFFF',
        background: '#066785',
        textAlign: 'center',
        paddingTop: 2,
        cursor: 'pointer',
    },
    viewAssignCont: {
        background: '#FFFFFF',
        border: '0.5px solid #066785',
        borderRadius: 2,
        height: 18,
        fontSize: 10,
        width: '67%',
        marginLeft: '32%',
    },
    templateLibraryRClickMenuCont: {
        paddingLeft: '10%',
    },
}));

// Here TL denote the Template Library
const DataMatrix = ({ showLoader }) => {
    const classes = useStyles();
    const history = useHistory();
    const {
        dataMatrixComponent, selectedDMWorkshopComponent, templateLibraryByType,
        dmTemplateLibraryFlag, clearDMComponent, setDMTemplateLibraryFlag,
        getLocationOfTargetComponent,
        selectedDMUAComponent,
        contextMenuTarget,
        isProgressionDM,
        selectedTimeline,
        selectedColIndex,
        clearWorkshopComponent,
        setWorkshopComponent,
        setSelectedComponent,
    } = useContext(TimelineContext);

    const currentTimeLineWidth = selectedTimeline?.props?.width;
    const eachColumnWidth = parseInt(currentTimeLineWidth, 10) / (selectedTimeline?.columns?.length);

    const timelineMatch = useRouteMatch('/:orgId/timeline/:timelineId');
    if (!contextMenuTarget) {
        history.push(timelineMatch.url);
        window.location.href = timelineMatch.url;
    }
    const { column } = contextMenuTarget;
    const [component, setComponentState] = useState(column);
    const [switchCheked, setSwitchChked] = useState(false);
    const [workshopViewType, setWorkshopViewType] = useState('component');
    const { props } = component;
    const {
        progressions, components,
    } = props;
    // const selectedColIndex = selectedColumn;

    const libraryWithColumn = templateLibraryByType && templateLibraryByType.Protocol
        && templateLibraryByType.Protocol.concat(templateLibraryByType.Text,
            templateLibraryByType.Row);
    const filteredLibrary = libraryWithColumn && libraryWithColumn.filter((fl) => fl?.supergroup !== 'Column');
    const [state, setState] = useState({ tlModalFlag: false, selectedTLComp: [] });

    const onHover = () => {

    };

    const selectTemplate = () => {

    };

    const loader = () => showLoader(false);

    const openTLModalHandler = () => {
        setState({ ...state, tlModalFlag: true });
    };

    const handleClose = () => {
        setState({ ...state, tlModalFlag: false });
    };

    const handleSelectTL = (value) => {
        setState({ ...state, selectedTLComp: value });
    };

    const workshopViewHandler = (e, type) => {
        setWorkshopViewType(type);
        if (type === 'parent') {
            const isProgression = !!isProgressionDM;
            const routeOfTheSelectedComp = getLocationOfTargetComponent(selectedDMUAComponent, selectedColIndex, false, null, isProgression);
            const locationMap = [];
            let data = isProgressionDM ? progressions : components;
            routeOfTheSelectedComp.forEach((val) => {
                locationMap.push(data[val]);
                data = data[val].props.components;
            });
            setWorkshopComponent(locationMap);
            setSelectedComponent(locationMap);
        } else if (type === 'component') {
            setWorkshopComponent(selectedDMUAComponent);
            setSelectedComponent(selectedDMUAComponent);
        }
    };

    useEffect(() => {
        setDMTemplateLibraryFlag(false);
        setWorkshopViewType('component');
        setState({
            ...state, tlModalFlag: false, selectedTLComp: [], selectedDMWorkshopComponent: [],
        });
        setTimeout(() => {
            setState({ ...state, selectedTLComp: selectedDMWorkshopComponent?.props?.selectedTemplateLibrary || [] });
            setDMTemplateLibraryFlag(true);
        }, 0);
    }, [selectedDMWorkshopComponent?.id]);

    useEffect(() => () => {
        clearDMComponent();
        clearWorkshopComponent();
    }, []);

    useEffect(() => {
        const timelineColumn = selectedTimeline.columns[selectedColIndex];
        setComponentState(timelineColumn);
    }, [dataMatrixComponent?.id]);

    const getDMColumn = () => (
        <Grid container>
            <Grid item xs={12}>
                <Grid container className={classes.library1} alignItems="center" justify="center">
                    <Grid item xs={12} className={classes.templateLibraryTextCont}>
                        Template Library
                    </Grid>
                    <Grid item xs={12} className={classes.templateBrowserCont}>
                        {dmTemplateLibraryFlag && selectedDMWorkshopComponent && (
                            <TemplateBrowser
                                templates={filteredLibrary}
                                hoverItem={onHover}
                                selectItem={selectTemplate}
                                showTimeLine
                                showLoader={loader}
                                handleSelectDMTL={handleSelectTL}
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );

    const renderTimeline = () => {
        let protocolItem = null;
        const isProgression = !!isProgressionDM;
        const routeOfTheSelectedComp = getLocationOfTargetComponent(selectedDMUAComponent, selectedColIndex, false, null, isProgression);
        const locationMap = [];
        let data = isProgressionDM ? progressions : components;
        routeOfTheSelectedComp.forEach((val) => {
            locationMap.push(data[val]);
            data = data[val].props.components;
        });
        locationMap.splice(locationMap.length - 1, 1);
        let compList = [];
        const resultParent = [];
        const propsData = isProgression ? dataMatrixComponent.props.progressions : dataMatrixComponent.props.components;

        findParent(propsData, selectedDMUAComponent.id, resultParent);

        if (resultParent.length > 0 && resultParent) {
            compList = [...locationMap, ...resultParent[0].props.components];
        } else {
            compList = [...locationMap, ...propsData];
        }

        if (!switchCheked) {
            protocolItem = compList.map((comp) => (
                <Box
                    className={[classes.workshopTitle,
                        comp.id === selectedDMUAComponent.id ? classes.highLightCls : null,
                        `${comp.props.type ? comp.props.type : 'other'}Cls`,
                        classes.workshopTitleItem].join(' ')}
                >
                    {' '}
                    {comp.props.title || comp.props.text}

                </Box>
            ));
        }

        if (switchCheked) {
            protocolItem = (
                <Box
                    className={[classes.workshopTitle3,
                        classes.workshopTitleItem].join(' ')}
                >
                    <Container props={{
                        components: compList, isProgression: isProgressionDM,
                    }}
                    />
                </Box>
            );
        }
        return protocolItem;
    };

    return (
        <>
            <RecoilRoot>
                <Grid container className={classes.dmTitleCont}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h1" className={classes.dmTitle}>Data Matrix</Typography>
                    </Grid>
                    <Grid item xs={12} md={8} lg={8} display>
                        <Box>
                            <OrgBreadcrumbs isDataMatrix="true" current="Timelines" timelineChild={[selectedTimeline.name, 'Data Matrix']} />
                        </Box>
                    </Grid>
                </Grid>
                <div className={classes.dmContainerMain}>
                    <div className={classes.dmCont1}>
                        {selectedTimeline?.columns?.length > 0 && (
                            <DataMatrixColumn
                                showLoader={showLoader}
                                column={dataMatrixComponent}
                                parent={{ id: selectedTimeline.id, type: 'Timeline', props: { components: selectedTimeline.columns } }}
                            />
                        )}
                    </div>
                    <div className={classes.workShopTemplateCont}>
                        <div style={{ width: eachColumnWidth }} className={classes.dataMatrixColumn2Cont}>
                            <Grid container>
                                <Grid item xs={9}>
                                    <Typography variant="subtitle1" className={classes.columnCont1}>Column 2</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.SwitchContMain}>
                                    <Grid container className={classes.viewAssignCont}>
                                        <Grid item xs={6}>
                                            <Tooltip title="Without Component" placement="top">
                                                <div
                                                    aria-hidden="true"
                                                    className={switchCheked ? classes.withoutComponentCont : classes.withComponentCont}
                                                    onClick={() => setSwitchChked(!switchCheked)}
                                                >
                                                    W
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Tooltip title="With Component" placement="top">
                                                <div
                                                    aria-hidden="true"
                                                    className={switchCheked ? classes.withComponentCont : classes.withoutComponentCont}
                                                    onClick={() => setSwitchChked(!switchCheked)}
                                                >
                                                    C
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                    {/* </div> */}
                                </Grid>
                                <Grid item xs={12} className={classes.workshopTitle1}>
                                    Selected Title Hierarchy Display
                                </Grid>
                            </Grid>
                            <div className={classes.columnSectionCont}>
                                <Grid container>
                                    <Grid item xs={12} className={classes.workshopTitle}>
                                        {props.title}
                                    </Grid>
                                    <Grid item xs={12} className={`${classes.workshopTitle} ${classes.workshopSectionCont}`}>
                                        {isProgressionDM ? 'Progression' : 'Protocol'}
                                        {' '}
                                        Section
                                    </Grid>
                                </Grid>
                                {renderTimeline()}
                            </div>
                        </div>
                        <div className={classes.dataMatrixColumn3Cont}>
                            <div style={{ display: 'flex' }}>
                                <Grid item xs={10}>
                                    <Typography variant="subtitle1" className={classes.columnCont2}>Column 3</Typography>
                                </Grid>
                                <Grid item xs={2} className={classes.SwitchContMain}>
                                    <Grid container className={classes.viewAssignCont}>
                                        <Grid item xs={6}>
                                            <Tooltip title="Component View" placement="top">
                                                <div
                                                    aria-hidden="true"
                                                    // item
                                                    // xs={6}
                                                    className={workshopViewType === 'component' ? classes.withComponentCont
                                                        : classes.withoutComponentCont}
                                                    onClick={(e) => workshopViewHandler(e, 'component')}
                                                >
                                                    C
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Tooltip title="Parent View" placement="top">
                                                <div
                                                    aria-hidden="true"
                                                    className={workshopViewType === 'parent' ? classes.withComponentCont
                                                        : classes.withoutComponentCont}
                                                    onClick={(e) => workshopViewHandler(e, 'parent')}
                                                >
                                                    P
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                            <Grid item xs={12} className={classes.workshopTitle1}>
                                WORKSHOP
                            </Grid>
                            <div className={classes.columnSectionCont}>
                                <DataMatrixWorkshop />
                            </div>
                        </div>
                        <div className={classes.dataMatrixColumn4Cont}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" className={classes.columnCont}>Column 4</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container className={classes.workshopTitle2}>
                                    <Grid item xs={10} md={11} className={classes.templateLibraryRClickMenuCont}>
                                        <Typography variant="subtitle1" className={classes.fontSize12Px}>TEMPLATE LIBRARY / R-CLICK MENU</Typography>
                                    </Grid>
                                    <Grid item xs={2} md={1} className={classes.templateLibraryIconCont}>
                                        <OpenInNewIcon onClick={openTLModalHandler} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        {getDMColumn()}
                                    </Grid>
                                    <Grid item xs={6} className={classes.clickMenuContMain}>
                                        <Grid container className={classes.library}>
                                            <Grid item xs={12} className={classes.templateLibraryTextCont1}>
                                                R Click Menu
                                            </Grid>
                                            <Grid item xs={12} className={classes.templateBrowserCont}>
                                                {state?.selectedTLComp?.length > 0
                                                    && (
                                                        <DataMatrixRClick templates={state.selectedTLComp} />

                                                    )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    <div className={classes.dataMatrixSettingCont}>
                        <Grid container>
                            <Grid item xs={5} className={classes.dataMatrixSettingContLeft}>
                                <Typography variant="subtitle1" className={classes.columnCont}>Column 5</Typography>
                                <DataMatrixSetting
                                    title="Settings"
                                    rClickMenu={state.selectedTLComp}
                                    templates={filteredLibrary}
                                    templateItems={{
                                        hoverItem: onHover,
                                        selectItem: selectTemplate,
                                        showLoader,
                                        handleSelectDMTL: handleSelectTL,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={7} className={classes.viewPOICont}>
                                <Typography variant="subtitle1" className={classes.columnCont}>Column 6</Typography>
                                <DataMatrixPOI
                                    title="POI"
                                    rClickMenu={state.selectedTLComp}
                                    templates={filteredLibrary}
                                    templateItems={{
                                        hoverItem: onHover,
                                        selectItem: selectTemplate,
                                        showLoader,
                                        handleSelectDMTL: handleSelectTL,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </div>
                {state?.tlModalFlag && (
                    <TemplateLibraryDialog
                        open={state.tlModalFlag}
                        handleClose={handleClose}
                        templates={filteredLibrary}
                        hoverItem={onHover}
                        selectItem={selectTemplate}
                        showTimeLine
                        showLoader={loader}
                        handleSelectDMTL={handleSelectTL}
                    />
                )}
            </RecoilRoot>
        </>
    );
};

export default DataMatrix;
