import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    stepperStyle: {
        padding: '2rem 2rem 0rem 2rem',
    },
}));

function getSteps() {
    return ['Create Doctor', 'PA/PV'];
}

export default function CustomizedSteppers(props) {
    const classes = useStyles();
    const { stepValue } = props;
    const steps = getSteps();

    return (
        <div className={classes.root}>
            <Stepper classes={{ root: classes.stepperStyle }} alternativeLabel activeStep={stepValue - 1}>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </div>
    );
}
