/* eslint no-unused-vars: 0 */
// import { useState } from 'react';
import { findComponent } from '../helper/commonHelper';
import { v4 as uuid } from 'uuid';

/**
* Deep copy a template and update the ids and poi to new UUIDs for all nested components
* @param {*} template
*/
const copyTemplateWithPOI = (template, columnPOI = {}) => {
    const from = JSON.parse(JSON.stringify(template));
    const poiData = columnPOI.poiObj && Object.keys(columnPOI.poiObj).length !== 0 && JSON.parse(JSON.stringify(columnPOI.poiObj));
    const acData = columnPOI.acObj && Object.keys(columnPOI.acObj).length !== 0 && JSON.parse(JSON.stringify(columnPOI.acObj));
    const poiClone = [];
    const acClone = [];

    function traverse(node) {
        if (Array.isArray(node)) {
            node.forEach(traverse);
        } else if (typeof node === 'object') {
            for (const property in node) {
                if (property === 'id') {
                    const id = uuid();
                    if ((node?.type === 'Text' || node?.type === 'Protocol') && poiData && poiData[node.id] && poiData[node.id].poi_data) {
                        const obj = { ...poiData[node.id] };
                        obj.protocol_id = id;
                        poiClone.push(obj);
                    }

                    if ((node?.type === 'Text' || node?.type === 'Protocol') && acData && acData[node.id] && acData[node.id].access_data) {
                        const obj = { ...acData[node.id] };
                        obj.protocol_id = id;
                        acClone.push(obj);
                    }

                    node.id = id;
                } else if (property === 'associatedCell') {
                    delete node.associatedCell;
                } else if (property === 'imgSrc') {
                    delete node.imgSrc;
                } else if (property !== 'poi' && property !== 'poiWithParent') {
                    traverse(node[property]);
                }
            }
        }
    }
    traverse(from);
    return { component: from, poi: poiData, poiACData: { poiClone, acClone } };
};

/**
* Deep copy a template and update the ids to new UUIDs for all nested components  will remove the image src
* @param {*} template
*/
const copyTemplate = (template) => {
    const from = JSON.parse(JSON.stringify(template));
    function traverse(node) {
        if (Array.isArray(node)) {
            node.forEach(traverse);
        } else if (typeof node === 'object') {
            for (const property in node) {
                if (property === 'id') {
                    node.id = uuid();
                } else if (property === 'associatedCell') {
                    delete node.associatedCell;
                } else if (property === 'imgSrc') {
                    delete node.imgSrc;
                } else if (property !== 'poi' && property !== 'poiWithParent') {
                    traverse(node[property]);
                }
            }
        }
    }
    traverse(from);
    return from;
};

const copyTemplateWithAssociatedCell = (template, retainImage, columnPOI = {}) => {
    const from = JSON.parse(JSON.stringify(template));
    const poiData = columnPOI.poiObj && Object.keys(columnPOI.poiObj).length !== 0 && JSON.parse(JSON.stringify(columnPOI.poiObj));
    const acData = columnPOI.acObj && Object.keys(columnPOI.acObj).length !== 0 && JSON.parse(JSON.stringify(columnPOI.acObj));
    const poiClone = [];
    const acClone = [];
    const obj = {};
    function traverse(node) {
        if (Array.isArray(node)) {
            node.forEach(traverse);
        } else if (typeof node === 'object') {
            for (const property in node) {
                if (property === 'id') {
                    const nodeId = node.id;
                    node.clonedFrom = node.id;
                    const id = uuid();

                    if ((node?.type === 'Text' || node?.type === 'Protocol') && poiData && poiData[node.id] && poiData[node.id].poi_data) {
                        const objNode = { ...poiData[node.id] };
                        objNode.protocol_id = id;
                        poiClone.push(objNode);
                    }

                    if ((node?.type === 'Text' || node?.type === 'Protocol') && acData && acData[node.id] && acData[node.id].access_data) {
                        const objNode = { ...acData[node.id] };
                        objNode.protocol_id = id;
                        acClone.push(objNode);
                    }

                    node.id = id;
                    // if (!Object.prototype.hasOwnProperty.call(obj, nodeId)) {
                    //     obj[nodeId] = id;
                    // }
                    obj[nodeId] = id;
                } else if (property === 'imgSrc' && !retainImage) {
                    delete node.imgSrc;
                } else if (property === 'hasContentDisplay') {
                    delete node[property];
                } else if (property !== 'poi' && property !== 'poiWithParent') {
                    traverse(node[property]);
                }
            }
        }
    }
    function traverseCopy(node) {
        if (Array.isArray(node)) {
            node.forEach(traverseCopy);
        } else if (typeof node === 'object') {
            for (const property in node) {
                /* if(property === 'associatedCell') {
                    node.associatedCell = obj[node.associatedCell];
                } else if(property === 'associatedParent') {
                    node.associatedParent = obj[node.associatedParent];
                } else if(property === 'selectId') {
                    node.selectId = obj[node.selectId];
                } else if(property === 'selectComponentId') {
                    node.selectComponentId = obj[node.selectComponentId];
                } else if(property === 'associatedComponent') {
                    node.associatedComponent = obj[node.associatedComponent]
                } */
                if (property === 'associatedCells') {
                    node[property] = node[property].map((ele) => obj[ele]);
                } else if (property === 'highlightedChildren' || property === 'notHighlightedChildren') {
                    node[property] = node[property].map((ele) => {
                        const requiredSubstring = ele.substring(ele.lastIndexOf('#') + 1, ele.length);
                        return (ele.replace(requiredSubstring, obj[requiredSubstring]));
                    });
                } else if (property === 'immediateChildren') {
                    node[property] = node[property].map((ele) => ({ ...ele, requiredId: obj[ele.requiredId] }));
                } else if (property === 'selectId') {
                    node[property] = obj[node[property]];
                } else if (property === 'select-suggest') {
                    const selectSuggest = {};
                    for (const key in node[property]) {
                        selectSuggest[obj[key]] = node[property][key];
                    }
                    node[property] = selectSuggest;
                }
                if (Object.prototype.hasOwnProperty.call(obj, node[property])) {
                    node[property] = obj[node[property]];
                } else if (property !== 'poi' && property !== 'poiWithParent') {
                    traverseCopy(node[property]);
                }
            }
        }
    }
    traverse(from);
    traverseCopy(from);

    if (poiData || acData) return { component: from, poi: poiData, poiACData: { poiClone, acClone } };
    return from;
};

const copyTemplateLR = (template, LRProps, workshopComponent) => {
    const from = JSON.parse(JSON.stringify(template));
    function traverse(node) {
        if (Array.isArray(node)) {
            node.forEach(traverse);
        } else if (typeof node === 'object') {
            for (const property in node) {
                if (property === 'id') {
                    if (node?.props?.cfType === 'Select and Suggest' && node?.type === 'Checkbox' && node?.mirrorId) {
                        const nodeId = node?.mirrorId;
                        node.mirrorId = node.id;
                        node.id = nodeId;
                        if (node?.props?.['select-suggest']) {
                            const selectSuggest = node?.props?.['select-suggest'];
                            const newSelectSuggest = {};
                            for (const key in selectSuggest) {
                                const leftCfComponent = findComponent(workshopComponent?.props?.components, key);
                                newSelectSuggest[leftCfComponent?.mirrorId] = selectSuggest[key];
                            }
                            node.props['select-suggest'] = newSelectSuggest;
                        }
                    } else {
                        node.mirrorId = node.id;
                        node.id = uuid();
                    }
                } else if (property === 'focused') {
                    node.focused = false;
                } else if (property === 'LRProps') {
                    node.LRProps = LRProps;
                } else if (property === 'imgSrc') {
                    delete node.imgSrc;
                } else if (property !== 'poi' && property !== 'poiWithParent') {
                    traverse(node[property]);
                }
            }
        }
    }
    traverse(from);
    return from;
};

/**
* Deep copy a template and update the ids to new UUIDs for all nested components will keep the image src
* @param {*} template
*/
const copyTemplateWithImg = (template) => {
    const from = JSON.parse(JSON.stringify(template));
    function traverse(node) {
        if (Array.isArray(node)) {
            node.forEach(traverse);
        } else if (typeof node === 'object') {
            for (const property in node) {
                if (property === 'id') {
                    node.id = uuid();
                } else if (property !== 'poi' && property !== 'poiWithParent') {
                    traverse(node[property]);
                }
            }
        }
    }
    traverse(from);
    return from;
};
const copyTemplateForPage = (template) => {
    const from = JSON.parse(JSON.stringify(template));
    const parentID = uuid();
    const pool = { [template.id]: parentID };
    function traverse(node) {
        if (Array.isArray(node)) {
            node.forEach(traverse);
        } else if (typeof node === 'object') {
            for (const property in node) {
                if (property === 'id') {
                    if (pool[node.id]) {
                        node.id = pool[node.id];
                    } else {
                        const newId = uuid();
                        pool[node.id] = newId;
                        node.id = newId;
                    }
                }
                if (property === 'parentID') {
                    node.parentID = parentID;
                } else if (property !== 'poi' && property !== 'poiWithParent') {
                    traverse(node[property]);
                }
            }
        }
    }
    traverse(from);
    return from;
};

const getUniqueUUID = () => uuid();

// console.log('CPS template', JSON.stringify(copyTemplate({
//   id: 0,
//   name: 'Default Plastic Surgery',
//   type: 'Timeline',
//   props: { width: 5000 },
//   columns: [{
//     id: 0, type: 'Column', config: { size: 2 }, components: [{ id: 0, type: 'Text', props: { text: 'Consultation', type: 'h5' } }],
//   }, {
//     id: 0, type: 'Column', config: { size: 2 }, components: [{ id: 0, type: 'Text', props: { text: 'Pre-Op', type: 'h5' } }],
//   }, {
//     id: 0, type: 'Column', config: { size: 2 }, components: [{ id: 0, type: 'Text', props: { text: 'Surgery', type: 'h5' } }],
//   }, {
//     id: 0, type: 'Column', config: { size: 2 }, components: [{ id: 0, type: 'Text', props: { text: 'Post-Op 1', type: 'h5' } }],
//   }, {
//     id: 0, type: 'Column', config: { size: 2 }, components: [{ id: 0, type: 'Text', props: { text: 'Post-Op 2', type: 'h5' } }],
//   }],
// })));

// Hook
// function useLocalStorage(key, initialValue) {
//     // State to store our value
//     // Pass initial state function to useState so logic is only executed once
//     const [storedValue, setStoredValue] = useState(() => {
//         try {
//             // Get from local storage by key
//             const item = window.localStorage.getItem(key);
//             // Parse stored json or if none return initialValue
//             return item ? JSON.parse(item) : initialValue;
//         } catch (error) {
//             // If error also return initialValue
//             console.log(error);
//             return initialValue;
//         }
//     });

//     // Return a wrapped version of useState's setter function that ...
//     // ... persists the new value to localStorage.
//     const setValue = (value) => {
//         try {
//             // Allow value to be a function so we have same API as useState
//             const valueToStore = value instanceof Function ? value(storedValue) : value;
//             // Save state
//             setStoredValue(valueToStore);
//             // Save to local storage
//             window.localStorage.setItem(key, JSON.stringify(valueToStore));
//         } catch (error) {
//             // A more advanced implementation would handle the error case
//             console.log(error);
//         }
//     };

//     return [storedValue, setValue];
// }

export {
    copyTemplateWithPOI, copyTemplate, copyTemplateWithImg, copyTemplateForPage, copyTemplateLR, copyTemplateWithAssociatedCell, getUniqueUUID,
};
