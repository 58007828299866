import 'react-toastify/dist/ReactToastify.css';
import { ADD_NEW_USER } from './context/User';
import {
    ADD_PATIENT_USER_TIMELINE, ADD_TIMELINE, GET_ALL_CONTENT_DISPLAY, GET_COMPONENT_AC, GET_COMPONENT_POI, GET_TIMELINES,
} from './context/Timeline';
import {
    Box, Button, Grid, TextField, Typography,
} from '@material-ui/core';
import { CHECK_USER_EXISTENCE } from './components/TimelineColumn';
import { CheckCircleOutline } from '@material-ui/icons';
import { createTimelineForPatient, deepCopy, emailValidator } from './helper/commonHelper';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import { useLazyQuery, useMutation, useQuery } from 'react-apollo';
import FormControl from '@material-ui/core/FormControl';
import Loader from 'react-loaders';
import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';

export const GET_TIMELINE_BY_ID = gql`
query timeline($timelineID: uuid!) {
    timeline_by_pk(id: $timelineID ) {
        id
        name
        props
        columns
        category
        sub_category
        industries
        sub_specialities
        organization_id
        created_at
    }
  }
`;

const useStyles = makeStyles(() => ({
    modalStyle: {
        background: '#dedcdc',
        height: '100vh',
        top: 0,
        position: 'fixed',
        width: '100%',
    },
    sucessMessageCont: {
        width: 375,
        margin: '200px auto',
        borderRadius: 16,
        textAlign: 'center',
        boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
        paddingBottom: '1.25rem',
        '& > :first-child': {
            background: '#04AA6D',
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
        },
        '& svg': {
            fill: '#fff',
            fontSize: '4rem',
            margin: '4rem 0rem 3.5rem 0rem',
        },
        '& h2': {
            margin: '2rem 0rem 1.75rem 0rem',
        },
        '& h3': {
            color: '#a8a8a8',
            fontSize: 18,
            margin: '0px 1.75rem 1.5rem 1.75rem',
        },
    },
    widthSectPart: {
        width: 560,
        border: '1px solid #cbcbcb',
        borderRadius: '8px',
        margin: '5rem auto',
        background: '#fff',
    },
    headerTitle: {
        fontSize: '1.25rem',
        fontWeight: 500,
    },
    modalHeader: {
        borderBottom: '1px solid #cbcbcb',
        padding: '.5rem 1rem',
        marginBottom: '1rem',
    },
    dialogContentRoot: {
        padding: '0.5rem 3rem',
    },
    primaryBtn: {
        maxHeight: '56px',
        padding: '.25rem 1rem',
        boxShadow: '0px 0px 20px -4px rgba(51,36,5,0.37)',
        marginLeft: '1.5rem !important',
        '&:hover': {
            boxShadow: 'none',
        },
    },
    resetBtn: {
        maxHeight: '56px',
        padding: '.25rem 1.25rem',
        boxShadow: 'none',
        '&:hover': {
            boxShadow: 'none',
        },
    },
    inputValue: {
        fontSize: 16,
        fontWeight: 300,
    },
    radioCont: {
        border: '1px solid #cbcbcb',
        borderRadius: '4px',
        margin: '1.5rem 3.5rem 2.5rem 3.5rem',
        '& > :first-child': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '0px 12px',
        },
    },
    radioLabel: {
        paddingRight: '6.5rem',
        lineHeight: '54px',
        color: '#000000de !important',
    },
    radioGroupStyle: {
        '& svg': {
            fontSize: '1rem',
            color: '#000000de',
        },
        '& span': {
            color: '#0000008a',
        },
        '& > :first-child': {
            paddingRight: '2rem',
        },
    },
    footerCont: {
        padding: '.5rem 1rem .55rem 0rem',
        borderTop: '1px solid #cbcbcb',
        textAlign: 'end',
    },
}));

export default function SignUpForm({ setSource, reqObj }) {
    const classes = useStyles();
    const { timelineId } = reqObj;
    const timelineID = timelineId;
    const initialState = {
        firstName: '',
        lastName: '',
        email: '',
    };
    const [patient, setPatientValue] = useState(initialState);
    const [showLoader, setShowLoder] = useState(true);
    const [accSuccess, setAccSuccess] = useState(false);
    const [counter, setCounter] = useState(5);
    // eslint-disable-next-line no-unused-vars
    const [currentTimeline, setCurrentTimeline] = useState(timelineId);

    const profile = {};
    useEffect(() => {
        setShowLoder(true);
    }, []);

    const { data: orgData, loading: timelineLoading, error: timelineErr } = useQuery(GET_TIMELINE_BY_ID, {
        variables: {
            timelineID,
        },
        skip: !timelineID,
    });
    const orgID = orgData?.['timeline_by_pk']?.['organization_id'];
    const selectedTimeline = orgData?.['timeline_by_pk'];

    const { data: poiData, loading: poiLoding, error: poiErr } = useQuery(
        GET_COMPONENT_POI, {
            variables: {
                timeline_id: timelineId,
            },
        },
    );
    const { data: AC_DATA, loading: acLoding, error: acErr } = useQuery(
        GET_COMPONENT_AC, {
            variables: {
                timeline_id: timelineId,
            },
        }
    );

    const { data: allContentDisplayData, loading: cdLoading, cdErr } = useQuery(
        GET_ALL_CONTENT_DISPLAY, {
            variables: {
                timeline_id: timelineId,
            },
        }
    );

    useEffect(() => {
        if (!timelineLoading && !poiLoding && !cdLoading && !acLoding && !timelineErr && !poiErr && !cdErr && !acErr) {
            setShowLoder(false);
        }
    }, [timelineLoading, poiLoding, cdLoading, acLoding, timelineErr, poiErr, cdErr, acErr]);

    const calculateTotalShareableColumns = (selectedTimeline) => {
        const totalTC = [];
        if (selectedTimeline?.columns.length > 0) {
            for (let i = 1, l = selectedTimeline.columns.length; i < l; i += 1) {
                if (selectedTimeline?.columns?.[i]?.props?.userAccess?.isDoctor) {
                    totalTC.push(selectedTimeline.columns[i].id);
                }
            }
        }
        return totalTC;
    };

    const checkProfileError = () => {
        let errorFlag = false;
        if (emailValidator(patient.email)) {
            errorFlag = true;
            return errorFlag;
        }
        return errorFlag;
    };
    const [createUser] = useMutation(ADD_NEW_USER);
    const [addTimeline] = useMutation(
        ADD_TIMELINE,
        {
            refetchQueries: [{ query: GET_TIMELINES, variables: { orgID } }],
        }
    );
    const [insertPatientUTAssociation] = useMutation(ADD_PATIENT_USER_TIMELINE);

    const createNewPatient = async () => {
        try {
            const variables = {
                email: patient.email,
                name: patient?.firstName,
                orgId: orgID,
                role: 'Patient',
                last_name: patient.lastName,
                is_expert: false,
            };
            const userResult = await createUser({ variables });
            createUserTimelineAssociation(userResult?.data?.signup?.id, `${patient.firstName} ${patient.lastName}`);
        } catch (error) {
            const statement = 'Email already in use in Hasura Database';
            if (error.message.includes(statement)) {
                toast.warn('Email address already in use.');
            } else {
                toast.error('Something went wrong');
            }
            setShowLoder(false);
        }
    };

    const [checkUserExistence] = useLazyQuery(CHECK_USER_EXISTENCE, {
        onCompleted: (data) => {
            if (data.user.length > 0) {
                setShowLoder(false);
                toast.warn('A patient is already exist with this email');
            } else {
                createNewPatient();
            }
        },
    });

    const patientAccountCreation = () => {
        if (checkProfileError()) {
            checkUserExistence({
                variables: {
                    email: patient?.email,
                },
            });
        } else {
            setShowLoder(false);
            toast.warn('Enter a Valid Email address');
        }
    };
    const handleChange = (event) => {
        setPatientValue((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));
    };
    const otherArg = {
        patientDetails: patient,
        orgId: orgID,
        addTimeline,
        poiData,
        AC_DATA,
        allContentDisplayData,
        setCurrentTimeline,
        // bulkInsertPoi,
        // bulkInsertAC,
        // bulkInsertCD,
    };

    // eslint-disable-next-line consistent-return
    const createUserTimelineAssociation = (userId, patientName) => {
        if (!userId) return null;
        profile.id = userId;
        const cloneTimeline = deepCopy(selectedTimeline);
        const { name } = cloneTimeline;
        const timelineTitle = `${patientName} - ${name}`;
        cloneTimeline.name = timelineTitle;
        cloneTimeline.props.name = timelineTitle;
        const totalColumns = calculateTotalShareableColumns(selectedTimeline);
        cloneTimeline.columns.forEach((col) => {
            if (!col?.props?.isProfile) {
                if (totalColumns.findIndex((tc) => tc === col.id) === -1) {
                    col.props.components = [];
                    col.props.progressions = [];
                }
            }
        });
        createTimelineForPatient({
            title: timelineTitle,
            timelineTemplate: cloneTimeline,
            category: 'Shared With Patient',
            otherArg,
            userId: profile.id,
        }).then((timeline) => {
            const userObj = {
                userId: profile.id,
                orgId: orgID,
                timeline_id: timeline?.id,
                parentTimelineId: selectedTimeline.id,
            };
            insertPatientUTAssociation({ variables: userObj });
            setShowLoder(false);
            setPatientValue(initialState);
            setAccSuccess(true);
            redirectToLogin();
            decreaseCounter();
        }).catch(() => {
            toast.error('Something went wrong');
            setShowLoder(false);
        });
    };

    const redirectToLogin = () => {
        setTimeout(() => {
            setSource(null);
        }, 5000);
    };

    const decreaseCounter = () => {
        if (counter > 0) {
            setInterval(() => {
                setCounter((c) => c - 1);
            }, 1000);
        }
    };

    const handleFormSubmit = () => {
        if (!patient.firstName || !patient.lastName || !patient.email) {
            toast.info('All fields are mendatory.');
        } else if (!emailValidator(patient.email)) {
            toast.error('Enter a valid Email Address');
        } else {
            setShowLoder(true);
            patientAccountCreation();
        }
    };
    return (
        <div>
            {accSuccess ? (
                <div className={classes.sucessMessageCont}>
                    <div><CheckCircleOutline /></div>
                    <h2>Account Created Successfully</h2>
                    <h3>Login Credentials has been sent to your Email.</h3>
                    <h3>
                        Redirecting to Login in
                        {(counter === 1) ? ` ${counter} second` : ` ${counter} seconds`}
                    </h3>
                </div>
            )
                : (showLoader ? (
                    <div id="overlay">
                        <div id="loader">
                            <Loader type="line-scale" />
                        </div>
                    </div>
                ) : (
                    <div className={classes.modalStyle}>
                        <Grid container className={classes.widthSectPart}>
                            <Grid item xs={12} className={classes.modalHeader}>
                                <Typography className={classes.headerTitle}>Create New Patient</Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.dialogContentRoot}>
                                <Box my={2} mx={1} mb={1}>
                                    <FormControl fullWidth variant="outlined" required>
                                        <TextField
                                            color="secondary"
                                            variant="outlined"
                                            label="First Name"
                                            name="firstName"
                                            value={patient.firstName}
                                            onChange={(e) => { handleChange(e); }}
                                            inputProps={{ className: classes.inputValue }}
                                        />
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={12} className={classes.dialogContentRoot}>
                                <Box my={2} mx={1} mb={1}>
                                    <FormControl fullWidth variant="outlined" required>
                                        <TextField
                                            color="secondary"
                                            variant="outlined"
                                            label="Last Name"
                                            name="lastName"
                                            value={patient.lastName}
                                            onChange={(e) => { handleChange(e); }}
                                            inputProps={{ className: classes.inputValue }}
                                        />
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={12} className={classes.dialogContentRoot}>
                                <Box my={2} mx={1} mb={1}>
                                    <FormControl fullWidth variant="outlined" required>
                                        <TextField
                                            color="secondary"
                                            label="Email Address"
                                            variant="outlined"
                                            name="email"
                                            value={patient.email}
                                            onChange={(e) => { handleChange(e); }}
                                            inputProps={{ className: classes.inputValue }}
                                        />
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={12} className={classes.footerCont}>
                                <Button className={classes.resetBtn} variant="contained" onClick={() => setPatientValue(initialState)}>Reset</Button>
                                <Button
                                    className={classes.primaryBtn}
                                    variant="contained"
                                    color="primary"
                                    onClick={handleFormSubmit}
                                >
                                    Create Account
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                ))}
        </div>
    );
}
