import { TemplateBrowser } from './TemplateBrowser';
import React from 'react';

const fakeData = {
    Column: [{
        id: '24a1bf10-f085-4f9e-a659-6b14cfe854c2',
        name: 'Empty',
        type: 'Column',
        template: {
            id: 'a647f3cc-3226-4c32-b6f0-cc568a1fb540',
            type: 'Column',
            props: { size: 2, components: [{ id: 'f7c216ef-0f3a-4bd2-98a8-c5a57995e5df', type: 'Text', props: { text: 'Empty', type: 'h5' } }] },
        },
        __typename: 'template',
    }],
    Protocol: [{
        id: '1aeb08cb-8eee-47ad-a1a6-b934e6c36103',
        name: 'test 1',
        type: 'Protocol',
        group: '',
        template: {
            id: '681668d8-8c32-409a-958f-22ba2baeaaf1',
            type: 'Protocol',
            props: {
                title: 'SN-Breast',
                components: [{
                    id: '9d26eafa-7f21-4c23-a402-69f338d02252',
                    type: 'Column',
                    props: {
                        size: 5,
                        components: [{
                            id: '71c94e2a-f7c5-403e-b574-11058b608fda',
                            type: 'Checkbox',
                            props: {
                                checkboxes: [{ label: '10 cm', value: '10 cm' },
                                    { label: '11 cm', value: '11 cm' }, { label: '12 cm', value: '12 cm' }],
                            },
                        }],
                    },
                }, {
                    id: 'd3428d17-5381-4b71-a485-c7597c4948b7',
                    type: 'Column',
                    props: {
                        size: 5,
                        components: [{
                            id: '64c16c69-b51a-4aec-904f-5232a791647c',
                            type: 'Checkbox',
                            props: {
                                checkboxes: [{ label: '10 cm', value: '10 cm' },
                                    { label: '11 cm', value: '11 cm' }, { label: '12 cm', value: '12 cm' }],
                            },
                        }],
                    },
                }],
            },
        },
        __typename: 'template',
    }, {
        id: '1aeb08cb-8eee-47ad-a1a6-b934e6c36102',
        name: 'test 2',
        type: 'Protocol',
        template: {
            id: '681668d8-8c32-409a-958f-22ba2baeaaf1',
            type: 'Protocol',
            props: {
                title: 'SN-Breast',
                components: [{
                    id: '9d26eafa-7f21-4c23-a402-69f338d02252',
                    type: 'Column',
                    props: {
                        size: 5,
                        components: [{
                            id: '71c94e2a-f7c5-403e-b574-11058b608fda',
                            type: 'Checkbox',
                            props: {
                                checkboxes: [{ label: '10 cm', value: '10 cm' },
                                    { label: '11 cm', value: '11 cm' }, { label: '12 cm', value: '12 cm' }],
                            },
                        }],
                    },
                }, {
                    id: 'd3428d17-5381-4b71-a485-c7597c4948b7',
                    type: 'Column',
                    props: {
                        size: 5,
                        components: [{
                            id: '64c16c69-b51a-4aec-904f-5232a791647c',
                            type: 'Checkbox',
                            props: {
                                checkboxes: [{ label: '10 cm', value: '10 cm' },
                                    { label: '11 cm', value: '11 cm' }, { label: '12 cm', value: '12 cm' }],
                            },
                        }],
                    },
                }],
            },
        },
        __typename: 'template',
    }, {
        id: '1aeb08cb-8eee-47ad-a1a6-b934e6c36101',
        name: 'test 3',
        type: 'Protocol',
        template: {
            id: '681668d8-8c32-409a-958f-22ba2baeaaf1',
            type: 'Protocol',
            props: {
                title: 'SN-Breast',
                components: [{
                    id: '9d26eafa-7f21-4c23-a402-69f338d02252',
                    type: 'Column',
                    props: {
                        size: 5,
                        components:
                    [{
                        id: '71c94e2a-f7c5-403e-b574-11058b608fda',
                        type: 'Checkbox',
                        props: {
                            checkboxes: [{ label: '10 cm', value: '10 cm' },
                                { label: '11 cm', value: '11 cm' }, { label: '12 cm', value: '12 cm' }],
                        },
                    }],
                    },
                }, {
                    id: 'd3428d17-5381-4b71-a485-c7597c4948b7',
                    type: 'Column',
                    props: {
                        size: 5,
                        components: [{
                            id: '64c16c69-b51a-4aec-904f-5232a791647c',
                            type: 'Checkbox',
                            props: {
                                checkboxes: [{ label: '10 cm', value: '10 cm' },
                                    { label: '11 cm', value: '11 cm' }, { label: '12 cm', value: '12 cm' }],
                            },
                        }],
                    },
                }],
            },
        },
        __typename: 'template',
    }],
    ProtocolGroup: [{
        id: '532bff0f-b3f6-4c0e-96ae-8e99f58b67c1',
        name: 'test PG',
        type: 'ProtocolGroup',
        template: {
            id: '2f934a41-fea3-4d1a-af5d-8a6f19951d6f',
            type: 'ProtocolGroup',
            props: {
                title: 'Enter Protocol Group Title',
                components: [{
                    id: '48f50f7f-ef8f-4d39-870b-adb7b379b9ae',
                    type: 'Protocol',
                    props: {
                        title: 'SN-Breast',
                        components: [{
                            id: '1693dc83-eaca-454d-ac83-b751c3c75f37',
                            type: 'Column',
                            props: {
                                size: 5,
                                components: [{
                                    id: '0c3a8f9e-ec7d-46e9-bbfc-f33e9c041008',
                                    type: 'Checkbox',
                                    props: {
                                        checkboxes: [{ label: '11 cm', value: '11 cm' },
                                            { label: '12 cm', value: '12 cm' }, { label: '13 cm', value: '13 cm' }],
                                    },
                                }],
                            },
                        }, {
                            id: 'c93a7a24-f75a-450e-b3f5-dad446267246',
                            type: 'Column',
                            props: {
                                size: 5,
                                components: [{
                                    id: 'acd8b2bd-02a3-4ddd-a19d-8e938db3a644',
                                    type: 'Checkbox',
                                    props: {
                                        checkboxes: [{ label: '11 cm', value: '11 cm' },
                                            { label: '12 cm', value: '12 cm' }, { label: '13 cm', value: '13 cm' }],
                                    },
                                }],
                            },
                        }],
                    },
                }],
            },
        },
        __typename: 'template',
    }],
    Text: [{
        id: 'a825c13b-8f79-4347-b38a-c964c442e098',
        name: 'test text type',
        type: 'Text',
        template: { id: '5a87e448-ce2d-47bd-a3f9-693b464ec4fa', type: 'Text', props: { text: 'Empty', type: 'h5' } },
        __typename: 'template',
    }],
};

const selectTemplate = () => {
    // console.log('selectTemplate', template);
};

const hoverTemplate = () => {
    // console.log('hoverTemplate', template);
};

const ComponentTests = () => (
    <div style={{ width: '420px', padding: '20px' }}>
        Fake Templates:
        <TemplateBrowser templates={fakeData.Protocol} hoverItem={hoverTemplate} selectItem={selectTemplate} />
        <br />
        Empty Templates:
        <TemplateBrowser />
    </div>
);

export default ComponentTests;
