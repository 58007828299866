/* eslint-disable no-nested-ternary */
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    FormControlLabel,
    Grid,
    makeStyles,
} from '@material-ui/core';
import { CheckboxProps, TextAreaProps, TextBoxProps } from './forms/checkbox';
import {
    ColumnLRProps, ColumnProps, LRtitle, TimelineColumnProps,
} from './Column';
import { GalleryProps } from './Gallery';
import { ImageProps } from './Image';
import { LabelProps } from './forms/label';
import { ProgressionProps } from './Progression';
import { ProtocolProps } from './Protocol';
import { SelectProps } from './forms/select';
import { TextProps as TextInputProps } from './forms/textinput';
import { TextProps } from './Text';
import FilterNoneIcon from '@material-ui/icons/FilterNone';

import { TimelineContext } from '../context';
import { buildSettingsTreePage, filterFormTextAreaSettings } from '../helper/commonHelper';
import { datePropForSettings } from './forms/datepicker';
import { SelectProps as multiselect } from './forms/multiselect';
import { standardHeaders } from './utils/StandardStyles';
import { ProtocolProps as timelineCF } from './TimelineEditor';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import React, {
    useContext, useEffect, useMemo, useState,
} from 'react';

const useStyles = makeStyles(() => ({
    adminSettingAccSumContMain: {
        textAlign: 'center',
    },
    filterIconStyle: {
        marginLeft: 'auto',
        fontSize: 16,
        marginTop: 2,
        marginRight: 6,
    },
    accordionStyle: {
        margin: '1px 0px 2px 4px !important',
        boxShadow: 'none',
        width: '99%',
        borderRadius: '0px !important',
    },
    accordionDetailStyle: {
        display: 'block',
        padding: '0px',
    },
    expandedAccIcon1: {
        transform: 'rotateZ(-90deg) translateY(-10px)',
        color: '#000000',
        height: 36,
    },
    expandedAccIcon3: {
        transform: 'rotateZ(-90deg) translateY(-10px)',
        color: '#000000',
        height: 36,
    },
    expandedAcc3: {
        color: '#000000',
        transition: 'none',
    },
    iconButton1: {
        margin: '0 8px 0 2px',
        padding: 0,
        color: 'black',
        borderRadius: 2,
    },
    iconButton3: {
        margin: '0 8px 0 2px',
        padding: 0,
        color: '#000000',
        borderRadius: 2,
    },
    sectionHeader3: {
        margin: 0,
        minHeight: 'auto !important',
        height: 26,
        fontSize: 13,
        padding: '4px 0px',
        border: '1px solid #d9d1d1',
        ...standardHeaders.sectionHeader,
        position: 'relative',
        flexDirection: 'row-reverse',
        background: '#efefef',
        color: '#000000',
    },
    sectionHeader4: {
        margin: '0 4px',
        minHeight: 'auto !important',
        height: 26,
        fontSize: 13,
        padding: '4px 0px',
        border: '1px solid #d9d1d1',
        ...standardHeaders.sectionHeader,
        position: 'relative',
        flexDirection: 'row-reverse',
        background: '#fff',
        color: '#000000',
    },
    accordionDetailStyle2: {
        display: 'block',
        margin: '0 4px',
        padding: '0px',
    },
    settingListCont: {
        width: '-webkit-fill-available',
    },
    accRoot: {
        width: 'inherit',
        margin: '0px !important',
        borderRadius: '0px !important',
    },
    labels: {
        '& span': {
            fontSize: '.75rem',
        },
        width: 'auto',
    },
    labelCont: {
        paddingTop: 4,
    },
    checkboxRoot: {
        whiteSpace: 'pre',
        maxWidth: '5.25rem',
        marginRight: 0,
    },
    checkbox: {
        padding: 5,
        '& svg': {
            height: '0.75rem',
            width: '0.75rem',
        },
        marginLeft: '.75rem',
    },
    userTypeAccCont: {
        marginBottom: 2,
    },
    checkboxCont: {
        border: '1px solid #CBCBCB',
        padding: 0,
        display: 'flex',
        justifyContent: 'center',
    },
    headingText: {
        fontSize: 14,
        border: '1px solid #CBCBCB',
        padding: 4,
    },
    headingTextLeft: {
        fontSize: 14,
        border: '1px solid #CBCBCB',
        padding: '4px 4px 4px 8px',
        textAlign: 'left',
    },
    labelContLeft: {
        border: '1px solid #CBCBCB',
        padding: '4px 8px',
        textAlign: 'start',
        fontSize: 12,
    },
}));

const activationMap = {
    isAdmin: ['isBasic', 'isAdvanced'],
    isAdvanced: ['isBasic'],
};
export const SettingsAccessGrid = (props) => {
    const defaultAccess = {
        isBasic: false,
        isAdvanced: false,
        isAdmin: true,
    };
    const [value, setValue] = useState(defaultAccess);
    const classes = useStyles();
    const { label, type, setting } = props;
    const { settingsAccessData, settingUserViewDialog, setUserSettingsValues } = useContext(TimelineContext);
    const settingsAccessForType = useMemo(
        () => settingsAccessData?.find((item) => item?.type === type && item?.setting === setting), [settingsAccessData, type, setting]
    );
    // eslint-disable-next-line no-shadow
    const handleChange = (type, newAccess) => {
        let updatedValue = { ...value, [type]: newAccess };
        /**
         * DeSelect Basic if Advanced is Deselected
         * Deselect Advanced and Basic if Admin is Deselected
         */
        if (!newAccess && activationMap[type]) {
            const lowerAccessTypes = activationMap[type];
            lowerAccessTypes.forEach((accessType) => {
                updatedValue = { ...updatedValue, [accessType]: false };
            });
        }
        setValue(updatedValue);
        const newSettingsAccess = { type: props.type, access: updatedValue, setting };
        setUserSettingsValues((prev) => [...prev, newSettingsAccess]);
        if (props.onAccessChange && !settingUserViewDialog) {
            props.onAccessChange(updatedValue);
        }
    };

    useEffect(() => {
        if (settingsAccessForType) {
            setValue({ isAdmin: true, ...settingsAccessForType.access }); // even the backend data for isAdmin false hardcoding it to true
        }
    }, [settingsAccessForType]);
    return (
        <>
            <Grid item xs={5} className={classes.labelContLeft}>
                {label}
            </Grid>
            <Grid item xs={3} className={classes.checkboxCont}>
                <FormControlLabel
                    className={classes.labels}
                    classes={{ label: classes.labelCont, root: classes.checkboxRoot }}
                    control={(
                        <Checkbox
                            className={classes.checkbox}
                            checked={value.isAdmin}
                            disabled={value.isAdmin}
                            onChange={(e) => handleChange('isAdmin', e.target.checked)}
                        />
                    )}
                    label=""
                />
            </Grid>
            <Grid item xs={2} className={classes.checkboxCont}>
                <FormControlLabel
                    className={classes.labels}
                    classes={{ label: classes.labelCont, root: classes.checkboxRoot }}
                    control={(
                        <Checkbox
                            checked={value.isAdvanced}
                            className={classes.checkbox}
                            onChange={(e) => handleChange('isAdvanced', e.target.checked)}
                            disabled={!value.isAdmin}
                        />
                    )}
                    label=""

                />
            </Grid>
            <Grid item xs={2} className={classes.checkboxCont}>
                <FormControlLabel
                    className={classes.labels}
                    classes={{ label: classes.labelCont, root: classes.checkboxRoot }}
                    control={(
                        <Checkbox
                            checked={value.isBasic}
                            className={classes.checkbox}
                            onChange={(e) => handleChange('isBasic', e.target.checked)}
                            disabled={!value.isAdvanced}
                        />
                    )}
                    label=""
                />
            </Grid>
        </>
    );
};

export const SETTINGS_ACCESS_MAP = {
    Text: TextProps.display,
    Label: LabelProps.display,
    Checkbox: CheckboxProps.display,
    TextInput: TextInputProps.display,
    TextAreaCF: TextAreaProps.display,
    TextBoxCF: TextBoxProps.display,
    Select: SelectProps.display,
    MultiSelect: multiselect.display,
    DatePicker: datePropForSettings?.display,
    Gallery: GalleryProps.display,
    Image: ImageProps.display,
    Protocol: ProtocolProps.display,
    Progression: ProgressionProps.display,
    Column: ColumnProps.display,
    Timeline: timelineCF.display,
    TimelineColumn: TimelineColumnProps.display,
};

const SettingsAccordionItem = (props) => {
    const classes = useStyles();
    const { type, activeUserTypeAcc, onActiveUserTypeAcc } = props;
    const { updateSettingsAccess, selectedComponent, workshopComponent } = useContext(TimelineContext);
    let settingsItems = SETTINGS_ACCESS_MAP[type]?.length > 0 && SETTINGS_ACCESS_MAP[type].filter((ele) => {
        if (props?.isProgression && ele?.key?.includes('hyperlink')) {
            return false;
        }
        return true;
    }) || [];
    settingsItems = filterFormTextAreaSettings(settingsItems, selectedComponent);
    if (workshopComponent?.props?.leftRightColumn && settingsItems?.[0]?.id === 'Column') {
        settingsItems = [
            LRtitle,
            ColumnLRProps?.display[1],
            ...settingsItems];
    }
    const handleAccessChange = (access, setting) => {
        const newSettingsAccess = { type, access, setting };
        updateSettingsAccess(newSettingsAccess);
    };

    return (
        <Accordion expanded={activeUserTypeAcc === type} onChange={(e) => onActiveUserTypeAcc(e, type)} classes={{ root: classes.accRoot }}>
            <AccordionSummary
                expandIcon={
                    activeUserTypeAcc === type ? (
                        <ArrowDropUpIcon />
                    ) : (
                        <ArrowDropDownIcon />
                    )
                }
                aria-controls="panel1a-content"
                className={classes.sectionHeader4}
                classes={{
                    expandIcon: activeUserTypeAcc === type ? classes.iconButton3 : classes.expandedAccIcon3,
                    expanded: classes.expandedAcc3,
                }}
            >
                <Grid container>
                    <Grid item xs={12}>
                        {type}
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails classes={{ root: classes.accordionDetailStyle2 }}>
                <Grid container>
                    <Grid item xs={5} className={classes.headingTextLeft}>Master</Grid>
                    <Grid item xs={3} className={classes.headingText}>Comprehensive</Grid>
                    <Grid item xs={2} className={classes.headingText}>Advance</Grid>
                    <Grid item xs={2} className={classes.headingText}>Basic</Grid>
                    {settingsItems.map((item) => (
                        <SettingsAccessGrid
                            type={type}
                            setting={item.key}
                            onAccessChange={(access) => handleAccessChange(access, item.key)}
                            key={item.key}
                            label={item.label}
                        />
                    ))}
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

const SelectTypes = ['Cell', 'SelectCell'];
const getItems = (component, compChildren = [], editTimelineColumn) => {
    const items = [component, ...compChildren || []].map((item) => {
        if (SelectTypes.includes(item?.type)) {
            const childList = [];
            buildSettingsTreePage(childList, item);
            return childList.some(
                (data) => data?.type === 'Select'
            ) ? 'Select' : childList.some((data) => data?.type === 'MultiSelect') ? 'MultiSelect' : childList.some(
                    (data) => data?.type === 'Checkbox'
                ) ? 'Checkbox' : childList?.some((data) => data?.type === 'TextAreaCF')
                    ? 'TextAreaCF' : childList?.some((data) => data?.type === 'TextBoxCF') ? 'TextBoxCF' : null;
        }
        return editTimelineColumn ? 'TimelineColumn' : item?.type;
    }).filter((item) => item);
    return Array.from(new Set(items));
};

const SettingsAccess = ({ component }) => {
    const [settingsAccessAcc, setSettingsAccessAcc] = useState(false);
    const { editTimelineColumn, setSettingUserViewDialog, workshopComponent } = useContext(TimelineContext);
    const classes = useStyles();

    const protocolSettingsItems = getItems(workshopComponent, [], editTimelineColumn);
    const columnAndCompSettings = getItems(component, component?.props?.components, editTimelineColumn);

    // combine protocol, column and respective component settings items
    const settingAccessItems = component?.type !== 'Text' ? [...protocolSettingsItems, ...columnAndCompSettings] : protocolSettingsItems;

    const [activeUserTypeAcc, setActiveUserTypeAcc] = useState(settingAccessItems?.[0]);
    const handleActiveUserTypeAcc = (e, acc) => {
        e.stopPropagation();
        e.preventDefault();
        if (acc === activeUserTypeAcc) {
            setActiveUserTypeAcc('');
        } else {
            setActiveUserTypeAcc(acc);
        }
    };

    const handleSettingsAcc = () => {
        setSettingsAccessAcc(!settingsAccessAcc);
    };

    const handlePopUp = (e) => {
        setSettingUserViewDialog(true);
        e.stopPropagation();
    };

    return (
        <div className={classes.settingListCont}>
            <Grid item xs={12}>
                <Accordion
                    expanded={settingsAccessAcc}
                    onChange={(e) => handleSettingsAcc(e)}
                    classes={{ root: classes.accordionStyle }}
                >
                    <AccordionSummary
                        expandIcon={
                            settingsAccessAcc ? (
                                <ArrowDropUpIcon />
                            ) : (
                                <ArrowDropDownIcon />
                            )
                        }
                        aria-controls="panel1a-content"
                        className={classes.sectionHeader3}
                        classes={{
                            expandIcon: settingsAccessAcc ? classes.iconButton1 : classes.expandedAccIcon1,
                            expanded: classes.expandedAcc3,
                        }}
                    >
                        <Grid container>
                            <Grid item xs={12} className={classes.adminSettingAccSumContMain}>Setting User View</Grid>
                        </Grid>
                        <FilterNoneIcon
                            className={classes.filterIconStyle}
                            onClick={handlePopUp}
                        />
                    </AccordionSummary>
                    <AccordionDetails classes={{ root: classes.accordionDetailStyle }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid container className={classes.userTypeAccCont}>
                                    <Grid item xs={12}>
                                        {settingAccessItems?.map((item) => (
                                            <SettingsAccordionItem
                                                key={item}
                                                type={item}
                                                onActiveUserTypeAcc={handleActiveUserTypeAcc}
                                                activeUserTypeAcc={activeUserTypeAcc}
                                                isProgression={component?.isProgression}
                                            />
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </div>
    );
};

export default SettingsAccess;
