import { TimelineContext } from '../../context';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from 'react-apollo';
import React, { useContext, useEffect } from 'react';
import gql from 'graphql-tag';

const INSERT_PHONE_CALLS = gql`
    mutation InsertPhoneCalls($timeline_id: uuid, $call_data: jsonb) {
        insert_phone_calls(objects: {timeline_id: $timeline_id, call_data: $call_data}) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const GET_PHONE_CALLS = gql`
    query GetPhoneCalls($timeline_id: uuid){
        phone_calls(where: {timeline_id: {_eq: $timeline_id}}) {
            id
            call_data
        }
    }
`;

const useRowStyles = makeStyles(() => ({
    ringCentralCompCont: {
        position: 'fixed',
        right: 60,
        bottom: 0,
        zIndex: 999,
    },
}));

const RingCentralComp = (props) => {
    const classes = useRowStyles();
    const { toNumber = '1234567' } = props;
    const { selectedTimeline, refetchCallData } = useContext(TimelineContext);

    const [InsertPhoneCalls] = useMutation(INSERT_PHONE_CALLS);

    const iframeSrc = `https://apps.ringcentral.com/integration/ringcentral-embeddable/latest/app.html?clientId=${process.env.REACT_APP_API_CLIENT_ID}&appServer=https://platform.devtest.ringcentral.com`;

    if (document.querySelector('#rc-widget') && toNumber) {
        document.querySelector('#rc-widget').contentWindow.postMessage({
            type: 'rc-adapter-new-call',
            phoneNumber: toNumber,
            toCall: false,
        }, '*');
    }

    const handleMessage = (e) => {
        const { data } = e;
        if (data && data.type === 'rc-telephony-session-notify' && data.telephonySession?.parties[0]?.status?.code === 'Disconnected') {
            InsertPhoneCalls({
                variables: {
                    timeline_id: selectedTimeline?.id,
                    call_data: data?.telephonySession,
                },
            }).then(() => {
                refetchCallData();
            });
        }
    };

    useEffect(() => {
        window.addEventListener('message', handleMessage);
        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    return (
        <>
            <div className={classes.ringCentralCompCont}>
                <iframe
                    width="280"
                    height="415"
                    id="rc-widget"
                    allow="autoplay; microphone"
                    title="rc"
                    src={iframeSrc}
                />
            </div>
        </>
    );
};
export default RingCentralComp;
