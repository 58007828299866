/* eslint-disable import/order */
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/js/plugins.pkgd.min.js';
import 'react-toastify/dist/ReactToastify.css';
import { Auth0Provider } from './react-auth0-spa';
import { createBrowserHistory } from 'history';
import { createRoot } from 'react-dom/client';
import Main from './Main';
import React from 'react';

const history = createBrowserHistory();

const container = document.getElementById('root');
const root = createRoot(container);

const onRedirectCallback = (appState) => {
    history.push(
        appState && appState.targetUrl
            ? appState.targetUrl
            : window.location.pathname,
    );
};

if (module.hot) {
    module.hot.accept();
}
root.render(
    <Auth0Provider
        domain={process.env.REACT_APP_AUTH_DOMAIN}
        client_id={process.env.REACT_APP_AUTH_CLIENTID}
        redirect_uri={window.location.origin}
        onRedirectCallback={onRedirectCallback}
        cacheLocation="localstorage"
    >
        <Main />
    </Auth0Provider>
);
