import { Delete } from '@material-ui/icons';
import { Grid, IconButton, Typography } from '@material-ui/core';
import { TimelineContext } from '../../context';
import { makeStyles } from '@material-ui/core/styles';
import { showLoader } from '../../App';
import { useMutation } from '@apollo/react-hooks';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useContext, useState } from 'react';
import gql from 'graphql-tag';

const DELETE_PHONE_CALLS = gql`
    mutation DeletePhoneCalls($id: uuid) {
        delete_phone_calls(where: {id: {_eq: $id}}) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

const useStyles = makeStyles(() => ({
    widthSectPart: {
        width: '90%',
        minHeight: 'auto',
        maxHeight: '75vh',
    },
    headerStyle: {
        borderBottom: '1px solid #CBCBCB',
        padding: '.5rem 1rem',
        '& p': {
            fontSize: '1rem',
        },
    },
    alignRight: {
        textAlign: 'right',
    },
    closeIconSTyle: {
        cursor: 'pointer',
        marginTop: '.5rem',
    },
    callTableCell: {
        borderTop: '1px solid #ececec',
        borderRight: '1px solid #ececec',
        padding: '10px 8px',
        textAlign: 'center',
    },
    callTableCellIcon: {
        borderTop: '1px solid #ececec',
        padding: 4,
    },
    tabelHeaderCell: {
        borderRight: '1px solid #ececec',
        padding: '4px 8px',
        fontSize: '1rem',
        textAlign: 'center',
    },
    tabelHeaderLastCell: {
        padding: '4px 8px',
        fontSize: '1rem',
        textAlign: 'center',
    },
    noRecordCont: {
        borderTop: '1px solid #ececec',
        width: '100%',
        textAlign: 'center',
        padding: '3rem',
    },
    editIconCont: {
        borderTop: '1px solid #ececec',
        borderRight: '1px solid #ececec',
        padding: '4px 10px 4px 0px',
        textAlign: 'center',
    },
    iconBtn: {
        borderRadius: 4,
        padding: 5,
        backgroundColor: '#f9f9f9',
        border: '0.5px solid #33333311',
    },
    deleteIcon: {
        color: '#FF6A6A',
    },
    bodyStyle: {
        padding: 0,
        borderLeft: '1px solid #ececec',
        borderRight: '1px solid #ececec',
    },
    editorCont: {
        padding: 4,
        margin: 4,
        border: '.5px solid #ececec',
        borderRadius: 10,
    },
    showError: {
        color: '#DC143C',
    },
    footerStyle: {
        padding: '.5rem 1rem',
        borderTop: '1px solid #CBCBCB',
    },
    primaryBtn: {
        maxHeight: '56px',
        padding: '.25rem 1rem',
        boxShadow: '0px 0px 20px -4px rgba(51,36,5,0.37)',
        '&:hover': {
            boxShadow: 'none',
        },
    },
}));

const CallDataDialog = ({
    showCallData, setShowCallData,
}) => {
    const classes = useStyles();
    const { callData, refetchCallData } = useContext(TimelineContext);
    const [loaderState, setLoaderState] = useState(false);

    const [deletePhoneCalls] = useMutation(DELETE_PHONE_CALLS);

    const handleClose = () => {
        setShowCallData(false);
    };

    const handleDeleteCallData = (callId) => {
        setLoaderState(true);
        deletePhoneCalls({
            variables: {
                id: callId,
            },
        }).then(() => {
            refetchCallData();
            setLoaderState(false);
        });
    };

    return (
        <Dialog onClose={handleClose} open={showCallData} fullWidth classes={{ paperWidthSm: classes.widthSectPart }}>
            {(loaderState) && showLoader(loaderState)}
            <DialogTitle classes={{ root: classes.headerStyle }}>
                <Grid container justify="space-between" alignItems="center">
                    <Grid item xs={10}>
                        <Typography variant="h4">Call Record Data</Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.alignRight}>
                        <CloseIcon onClick={handleClose} className={classes.closeIconSTyle} />
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent className={classes.bodyStyle}>
                {callData?.['phone_calls']?.length === 0
                    ? <div className={classes.noRecordCont}><Typography>No Call Record Found</Typography></div>
                    : (
                        <Grid container>
                            <Grid item xs={4} className={classes.tabelHeaderCell}>From</Grid>
                            <Grid item xs={4} className={classes.tabelHeaderCell}>To</Grid>
                            <Grid item xs={2} className={classes.tabelHeaderCell}>Date</Grid>
                            <Grid item xs={2} className={classes.tabelHeaderLastCell}>Delete</Grid>
                            {callData?.['phone_calls'].map((i, index) => (
                                <Grid container key={index}>
                                    <Grid item xs={4} className={classes.callTableCell}>{i?.['call_data']?.parties?.[0]?.from?.phoneNumber}</Grid>
                                    <Grid item xs={4} className={classes.callTableCell}>{i?.['call_data']?.parties?.[0]?.to?.phoneNumber}</Grid>
                                    <Grid item xs={2} className={classes.callTableCell}>{i?.['call_data']?.creationTime?.substring(0, 10)}</Grid>
                                    <Grid item xs={2} className={classes.editIconCont}>
                                        <IconButton
                                            className={classes.iconBtn}
                                            edge="end"
                                            aria-label="delete"
                                            onClick={() => handleDeleteCallData(i?.id)}
                                        >
                                            <Delete fontSize="small" className={classes.deleteIcon} />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    )}
            </DialogContent>
            <DialogActions classes={{ root: classes.footerStyle }}>
                <Button
                    className={classes.primaryBtn}
                    variant="contained"
                    color="primary"
                    onClick={handleClose}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CallDataDialog;
