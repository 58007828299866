import {
    FilterSettings,
    buildSettingsTreePage, checkEmptyObject, createPlayer, findCompByType, findPage, getFileType, isVideo, randomString, setActiveComponent,
} from '../helper/commonHelper';
import { MenuDefaults, buildComponentMenu } from './utils/EditorMenu';
import { Settings, makeInlineEditor } from './Settings';
import { TimelineContext, WorkshopContext } from '../context';
import { componentWithId } from './Atoms';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useRecoilState } from 'recoil';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import React, {
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => createStyles({
    formControl: {
        marginTop: 0,
        minWidth: '-webkit-fill-available',
    },
    h2: {
        background: '#B4B4B4',
        padding: '4px',
        marginBottom: '10px',
    },
    widthSec: {
        width: '100%',
        textAlign: 'center',
    },
    imageRoot: {
        padding: 4,
    },
    titleStyle: {
        fontSize: 11,
        padding: '0px',
        maxHeight: 13,
        borderTop: '.5px solid #CBCBCB',
        alignItems: 'start',
    },
    captionContainer: {
        padding: '2px 0px 0px 0px',
        '&:last-child': {
            paddingBottom: 0,
        },
    },
    captionTypography: {
        margin: 0,
        fontSize: 11,
        textAlign: 'center',
        lineHeight: '12px',
        fontWeight: '400',
    },
    selectedComp: {
        background: 'cornflowerblue',
        borderRadius: '0px',
    },
    removeBorderRad: {
        borderRadius: '0px',
        boxShadow: 'none',
        borderBottom: '0.5px solid rgb(203, 203, 203)',
        borderLeft: '0.4px solid rgb(203, 203, 203)',
    },
    hideTextCls: {
        background: '#DEDEDE',
    },
    imgaeTitle: {
        fontSize: 12,
        fontWeight: 500,
    },
    cardRoot: {
        padding: '0px',
        minWidth: 'auto',
        borderRadius: '0px',
        borderLeft: '0px',
        borderRight: '0px',
    },
    photoCameraStyle: {
        fontSize: '4.5rem',
        color: '#d3d3d3',
    },
    cardContentRoot: {
        padding: '4px !important',
    },
    titleViewCls: {
        maxHeight: 15,
        minHeight: 13,
        padding: '0px',
    },
    scrollCls: {
        overflow: 'hidden',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            display:
                'none',
        },
        '&:hover': {
            '&::-webkit-scrollbar': {
                height: '2.5px',
                width: '2.5px',
                display: 'block',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#888',
            },
        },
    },
    anchorTag: {
        color: '#066785',
        textDecoration: 'none',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));

/**
 * Set the key and type for page in workshop
 */
const ImageProp = {
    key: 'imgSrc',
    type: 'Image',
    label: 'Image',
    id: 'Image',
};

const TitleProp = {
    key: 'title',
    type: 'TextField',
    label: 'Image Title',
    isBasic: true,
    isAdvance: true,
    isAdmin: true,
    id: 'Image',
};
const CaptionProp = {
    key: 'caption',
    type: 'TextField',
    label: 'Image Caption',
    isBasic: true,
    isAdvance: true,
    isAdmin: true,
    id: 'Image',
};

const CardHeaderComponent = ({
    title,
    classes,
    componentProps,
    componentParent,
    componentIndex,
    componentColumn,
}) => {
    const [imageTitle, setImageTitle] = useState({
        __html: componentProps?.props?.['hyperlink-imageTitle']
            ? `<a href=${componentProps?.props?.['hyperlink-imageTitle']} class=${classes.anchorTag} target='_blank'>${title}</a>`
            : title,
    });
    const imageTitleRef = useRef(null);
    const [imageTitleContextMenu, setImageTitleContextMenu] = useState(null);
    const {
        handleContextMenu,
        hyperlinkCip,
        setHyperlinkCip,
        setHyperlinkCipText,
        setHyperlinkComponent,
        setHyperlinkImageVideoComponent,
        updateTimeline,
    } = useContext(TimelineContext);

    useEffect(() => {
        if (!hyperlinkCip) {
            let nodeTextConcat = '';
            const requiredChildNodes = imageTitleRef?.current?.childNodes;
            if (requiredChildNodes) {
                requiredChildNodes.forEach((childNode) => {
                    nodeTextConcat += childNode.textContent;
                });
                if (nodeTextConcat !== title) {
                    setImageTitle({
                        __html: title,
                    });
                }
            }
        }
        updateTimeline();
    }, [title]);

    const openImageTitleContextMenu = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setImageTitleContextMenu(imageTitleContextMenu === null ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 } : null);
    };

    const closeImageTitleContextMenu = () => {
        setImageTitleContextMenu(null);
    };

    const addEditHyperLink = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setHyperlinkCip(true);
        setHyperlinkCipText(imageTitleRef?.current?.textContent);
        setHyperlinkComponent(componentProps);
        setHyperlinkImageVideoComponent('Image Title');
        handleContextMenu(
            event,
            componentParent?.components[componentIndex],
            { ...componentParent, components: [{ ...componentParent?.components[componentIndex] }] },
            componentIndex,
            componentColumn
        );
    };

    const removeHyperLink = () => {
        delete componentProps?.props?.['hyperlink-imageTitle'];
        setImageTitleContextMenu(null);
        setImageTitle({
            __html: title,
        });
        updateTimeline();
    };

    return (
        <>
            <CardHeader
                title={(
                    <Typography
                        classes={{ root: classes.captionTypography }}
                        ref={imageTitleRef}
                        onContextMenu={!hyperlinkCip && !componentProps?.isProgression && imageTitle ? openImageTitleContextMenu : undefined}
                        dangerouslySetInnerHTML={imageTitle}
                    />
                )}
                classes={{
                    root: classes.titleStyle,
                }}
                className={[classes.titleViewCls, classes.scrollCls].join(' , ')}
            />
            <Menu
                open={imageTitleContextMenu !== null}
                onClose={closeImageTitleContextMenu}
                anchorReference="anchorPosition"
                anchorPosition={
                    imageTitleContextMenu !== null
                        ? { top: imageTitleContextMenu.mouseY, left: imageTitleContextMenu.mouseX }
                        : undefined
                }
            >
                {componentProps?.props?.['hyperlink-imageTitle'] ? (
                    <>
                        <MenuItem onClick={addEditHyperLink}>Edit Hyperlink</MenuItem>
                        <MenuItem onClick={removeHyperLink}>Remove Hyperlink</MenuItem>
                    </>
                ) : (
                    <MenuItem onClick={addEditHyperLink}>Add Hyperlink</MenuItem>
                )}
            </Menu>
        </>
    );
};

const CardContentComponent = ({
    caption,
    classes,
    componentProps,
    componentParent,
    componentIndex,
    componentColumn,
}) => {
    const [imageCaption, setImageCaption] = useState({
        __html: componentProps?.props?.['hyperlink-imageCaption']
            ? `<a href=${componentProps?.props?.['hyperlink-imageCaption']} class=${classes.anchorTag} target='_blank'>${caption}</a>`
            : caption,
    });
    const imageCaptionRef = useRef(null);
    const [imageCaptionContextMenu, setImageCaptionContextMenu] = useState(null);
    const {
        handleContextMenu,
        hyperlinkCip,
        setHyperlinkCip,
        setHyperlinkCipText,
        setHyperlinkComponent,
        setHyperlinkImageVideoComponent,
        updateTimeline,
    } = useContext(TimelineContext);

    useEffect(() => {
        if (!hyperlinkCip) {
            let nodeTextConcat = '';
            const requiredChildNodes = imageCaptionRef?.current?.childNodes;
            if (requiredChildNodes) {
                requiredChildNodes.forEach((childNode) => {
                    nodeTextConcat += childNode.textContent;
                });
                if (nodeTextConcat !== caption) {
                    setImageCaption({
                        __html: caption,
                    });
                }
            }
        }
        updateTimeline();
    }, [caption]);

    const openImageCaptionContextMenu = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setImageCaptionContextMenu(imageCaptionContextMenu === null ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 } : null);
    };

    const closeImageCaptionContextMenu = () => {
        setImageCaptionContextMenu(null);
    };

    const addEditHyperLink = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setHyperlinkCip(true);
        setHyperlinkCipText(imageCaptionRef?.current?.textContent);
        setHyperlinkComponent(componentProps);
        setHyperlinkImageVideoComponent('Image Caption');
        handleContextMenu(
            event,
            componentParent?.components[componentIndex],
            { ...componentParent, components: [{ ...componentParent?.components[componentIndex] }] },
            componentIndex,
            componentColumn
        );
    };

    const removeHyperLink = () => {
        delete componentProps?.props?.['hyperlink-imageCaption'];
        setImageCaptionContextMenu(null);
        setImageCaption({
            __html: caption,
        });
        updateTimeline();
    };

    return (
        <>
            <CardContent classes={{ root: classes.captionContainer }} className={[classes.titleViewCls, classes.scrollCls].join(' , ')}>
                <Typography
                    classes={{ root: classes.captionTypography }}
                    ref={imageCaptionRef}
                    onContextMenu={!hyperlinkCip && !componentProps?.isProgression && imageCaption ? openImageCaptionContextMenu : undefined}
                    dangerouslySetInnerHTML={imageCaption}
                />
            </CardContent>
            <Menu
                open={imageCaptionContextMenu !== null}
                onClose={closeImageCaptionContextMenu}
                anchorReference="anchorPosition"
                anchorPosition={
                    imageCaptionContextMenu !== null
                        ? { top: imageCaptionContextMenu.mouseY, left: imageCaptionContextMenu.mouseX }
                        : undefined
                }
            >
                {componentProps?.props?.['hyperlink-imageCaption'] ? (
                    <>
                        <MenuItem onClick={addEditHyperLink}>Edit Hyperlink</MenuItem>
                        <MenuItem onClick={removeHyperLink}>Remove Hyperlink</MenuItem>
                    </>
                ) : (
                    <MenuItem onClick={addEditHyperLink}>Add Hyperlink</MenuItem>
                )}
            </Menu>
        </>
    );
};

const Image = ({
    config,
    componentParent,
    componentIndex,
    componentColumn,
}) => {
    const classes = useStyles();
    const { inWorkshop, compareModal } = useContext(WorkshopContext);
    const {
        settingComponent,
        setSettingComponentHandle,
        setDirtyDomain,
        domain: contextDomain,
        pageComponent: pageComponentRef,
        selectedTimeline,
        dirtyDomain,
        updateFlag,
        setUpdateFlag,
        workshopComponent,
        // updateTimeline,
    } = useContext(TimelineContext);
    let mediaObj = {};
    const { id } = config;
    const [component, setComponentState] = useRecoilState(componentWithId(config.id));
    const [componentPropsParent] = useRecoilState(componentWithId(config?.parent));
    const componentList = [];
    let domain = contextDomain;
    if (config?.previewDomain) {
        domain = config.previewDomain;
    }
    // const updateRecoilComponent = useRecoilCallback(updateComponentCallback);
    const pageComponent = pageComponentRef || selectedTimeline && findPage(selectedTimeline?.columns, component?.props?.parentID)?.[0];
    buildSettingsTreePage(componentList, pageComponent && pageComponent[domain]);
    const currentComp = componentList.find((item) => item.id === id);
    const isPageElem = currentComp && domain && (
        currentComp.type === 'Page' || currentComp.props.pageChild || (currentComp.name === 'Page' && currentComp.type !== 'Protocol')
    );
    if (isPageElem && currentComp && currentComp[domain] && !component.id && component[domain] && checkEmptyObject(component[domain])) {
        component[domain] = currentComp[domain];
    }

    const {
        title, imgSrc, caption,
    } = isPageElem ? (component[domain] || component.props) : component.props;
    const uniqueId = randomString(10);
    if (imgSrc) {
        mediaObj = imgSrc;
    }

    let isSelected = false;

    if (settingComponent && settingComponent.id === config.id) {
        isSelected = true;
    }

    if (mediaObj && mediaObj.name && isVideo(mediaObj.name)) {
        mediaObj.filetype = getFileType(mediaObj.name);
        mediaObj.videoDivId = `video-div-timeline-new-${uniqueId}`;
        mediaObj.isVideo = true;
    }
    useEffect(() => {
        const pageDomain = component[domain];
        if (dirtyDomain) {
            return;
        }
        if (pageDomain && Object.keys(pageDomain).length > 0) {
            const currentComp = componentList.find((c) => c.id === id);
            if (currentComp && !component.id) {
                currentComp[domain] = pageDomain;
                component.props = component[domain];
                component.parent[0][domain] = component[domain];
                component.parent[0].props = component.props;
            }
        }
    }, [domain, component, id, dirtyDomain]);

    const renderMedia = () => makeInlineEditor({
        field: ImageProp,
        component,
        setComponentState,
        classes: null,
        domain: isPageElem ? domain : undefined,
        setDirtyDomain: isPageElem ? setDirtyDomain : undefined,
    });

    if (typeof config?.props?.showTitle === 'boolean') {
        config.props.showTitle = config.props.showTitle ? 'yes' : 'no';
    }

    let { showTitle = 'yes' } = config.props;
    showTitle = (showTitle === 'yes');

    if (typeof config?.props?.showCaption === 'boolean') {
        config.props.showCaption = config.props.showCaption ? 'yes' : 'no';
    }

    let { showCaption = 'yes' } = config.props;
    showCaption = (showCaption === 'yes');

    useEffect(() => {
        if (component?.props?.childOf === componentPropsParent?.props?.selectId && inWorkshop) {
            const tempTitle = config?.props?.showTitle;
            const tempCaption = config?.props?.showCaption;
            const innerComps = findCompByType(workshopComponent, 'Image');
            innerComps.forEach((c) => {
                c.props.showTitle = tempTitle;
                c.props.showCaption = tempCaption;
            });
        }
        setUpdateFlag(!updateFlag);
    }, [config?.props?.showTitle, config?.props?.showCaption]);

    useEffect(() => {
        if (inWorkshop && component?.props?.childOf === componentPropsParent?.props?.selectId) {
            const workshopList = [];
            buildSettingsTreePage(workshopList, workshopComponent);
            const galleryComp = workshopList?.filter((i) => i?.type === 'Gallery');
            if (galleryComp.length > 0) {
                galleryComp.forEach((gallery) => {
                    gallery.props.components.forEach((galComp) => {
                        if (galComp.id === config?.id) {
                            galComp.props = config.props;
                        }
                    });
                });
            }
        }
    }, [config?.props?.title, config?.props?.caption, config?.props?.imgSrc]);

    const renderEditor = () => (
        <Card
            variant="outlined"
            className={isSelected ? classes.selectedComp : classes.removeBorderRad}
            id={isSelected ? 'selectedCompId' : ''}
            onClick={(event) => { event.stopPropagation(); setActiveComponent(setSettingComponentHandle, config); }}
        >
            <CardContent
                style={{ textAlign: 'center', padding: 4 }}
                classes={{
                    root: [((!showTitle) ? classes.hideTextCls : null)].join(' '),
                }}
            >
                {
                    makeInlineEditor({
                        field: TitleProp,
                        component,
                        setComponentState,
                        classes,
                        domain,
                        setDirtyDomain,
                        config,
                    })
                }
            </CardContent>
            {renderMedia()}
            <CardContent
                style={{ textAlign: 'center', padding: 4 }}
                className={[((!showCaption) ? classes.hideTextCls : null)].join(' ')}
            >
                {
                    makeInlineEditor({
                        field: CaptionProp,
                        component,
                        setComponentState,
                        classes,
                        domain,
                        setDirtyDomain,
                        config,
                    })
                }
            </CardContent>
        </Card>
    );

    const renderContent = (
        componentProps,
        componentParent,
        componentIndex,
        componentColumn
    ) => (
        <Card
            variant="outlined"
            className={classes.cardRoot}
            style={{ borderLeft: (component.props.index % 2 === 0) ? '1px solid #ececec' : 'none' }}
        >
            {showTitle && (
                <CardHeaderComponent
                    title={title}
                    classes={classes}
                    componentProps={componentProps}
                    componentParent={componentParent}
                    componentIndex={componentIndex}
                    componentColumn={componentColumn}
                />
            )}
            {renderMedia()}
            {showCaption && (
                <CardContentComponent
                    caption={caption}
                    classes={classes}
                    componentProps={componentProps}
                    componentParent={componentParent}
                    componentIndex={componentIndex}
                    componentColumn={componentColumn}
                />
            )}
        </Card>
    );

    useEffect(() => {
        if (mediaObj && mediaObj.isVideo) {
            createPlayer([mediaObj], false, 600);
        }
    });

    return (inWorkshop && !compareModal) ? renderEditor() : renderContent(config, componentParent, componentIndex, componentColumn);
};

/**
 * This component is preview of page
 * @param {object} config [configuration recieved from props]
 */
const ImagePreview = ({ component }) => {
    const classes = useStyles();
    const { settingComponent, handleCompPreviewClick } = useContext(TimelineContext);

    let isSelected = false;

    if (settingComponent && settingComponent.id === component.id) {
        isSelected = true;
    }

    return (
        <Card
            variant="outlined"
            className={[classes.imageRoot, isSelected ? classes.selectedComp : null].join(' , ')}
            id={isSelected ? 'selectedCompId' : ''}
            onClick={(e) => handleCompPreviewClick(e, component)}
        >
            <CardHeader classes={{ title: classes.imgaeTitle }} title={component.props.title} />
            <div style={{ textAlign: 'center' }}>
                <PhotoCamera className={classes.photoCameraStyle} />
            </div>
            <Typography className={classes.imgaeTitle} style={{ marginLeft: 16 }}>{component.props.caption}</Typography>
        </Card>
    );
};

export const ImageProps = {
    display: [
        ImageProp,
        TitleProp,
        {
            key: 'showTitle',
            type: 'Radio',
            label: 'Show Image Title',
            default: 'yes',
            id: 'Image',
            options: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }],
            isBasic: true,
            isAdvance: true,
            isAdmin: true,
        },
        CaptionProp,
        {
            key: 'showCaption',
            type: 'Radio',
            label: 'Show Image Caption',
            default: 'yes',
            id: 'Image',
            options: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }],
            isBasic: true,
            isAdvance: true,
            isAdmin: true,
        },
        {
            key: 'hyperlink-imageTitle',
            type: 'TextFieldHyperlinkDebounce',
            label: 'Image Title Hyperlink',
            isBasic: true,
            isAdvance: true,
            isAdmin: true,
            id: 'Image',
        },
        {
            key: 'hyperlink-imageCaption',
            type: 'TextFieldHyperlinkDebounce',
            label: 'Image Caption Hyperlink',
            isBasic: true,
            isAdvance: true,
            isAdmin: true,
            id: 'Image',
        },
    ],
    poi: false,
};

/**
 * This component is used in setting section with template library
 * @param {object} config [configuration recieved from props]
 * @param {function} updateComponent [recieved function as props to save page editor]
 */
const ImageEditor = ({ config, updateComponent }) => {
    const [component, setComponentState] = useRecoilState(componentWithId(config.id));
    const {
        selectedComponent,
    } = useContext(TimelineContext);
    const { settingMenuType } = useContext(TimelineContext);
    const editorProps = { display: FilterSettings(ImageProps, settingMenuType), poi: ImageProps.poi };
    if (selectedComponent?.isProgression) {
        editorProps.display = editorProps.display.filter((ele) => !ele?.key.includes('hyperlink-'));
    }

    return (
        <Settings editorProps={editorProps} component={component} setComponentState={setComponentState} saveComponent={updateComponent} />
    );
};

const ImageEditMenu = ({
    componentLibrary,
}) => {
    const all = buildComponentMenu(componentLibrary);
    const menu = [
        MenuDefaults.EDIT,
        MenuDefaults.ADD_SIBLING(all),
        MenuDefaults.DUPLICATE,
    ];
    return menu;
};

export {
    Image, ImageEditor, ImagePreview, ImageEditMenu,
};
