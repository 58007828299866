/* eslint-disable no-case-declarations */
import { OrgContext, TimelineContext } from '.';
import { findRouteUserType, getRouteValidation } from '../helper/commonHelper';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { useLocation } from 'react-router-dom';
import React, {
    createContext, useContext, useEffect, useState,
} from 'react';
import _ from 'lodash';
import gql from 'graphql-tag';

export const GET_USER_BY_ID = gql`
    query userById($id:uuid!){
        user_by_pk(id:$id){
            name,
            last_name
        }
    }
`;
const GET_ALL_USERS = gql`
    query user {
        user(where: { is_active: { _eq: true } }) {
            id,
            name,
            last_name,
            is_active,
            email,
            organization_members{
                role
                organization_id
            }
            industry
            is_expert
            specialty
        },
    }
`;

export const GET_ALL_DOCTORS = gql`
    query user($role : String) {
        user(where: {is_active: { _eq: true }, _and: {organization_members: { role: {_eq: $role }}}}) {
            id,
            name,
            last_name,
            industry,
            specialty,
            is_expert,
            is_active,
            email,
            created_at,
            organization_members(where: {role: {_eq: $role}}) {
                role,
                organization_id,
                organization{
                    name
                    org_email
                }
            },
            
            poi_accesses{
                poi
            }
        },
    }
`;

export const GET_ALL_DOCTORS_BY_ORG_ID = gql`
    query user($orgID : uuid) {
        user(where: {is_active: { _eq: true }, _and: {organization_members: { organization_id: {_eq: $orgID }, role: {_eq: "Admin" }}}}) {
            id,
            name,
            last_name,
            email,
            created_at,
        },
    }
`;

export const GET_ORG_PATIENTS_WITH_TIMELINE = gql`
    query user($role : String, $orgName: String) {
        user(where: {is_active: { _eq: true },
            _and: {organization_members: { role: {_eq: $role }, ,
            _and: {organization: {name: {_eq: $orgName}}}}}}) {
            id,
            name,
            last_name,
            industry,
            specialty,
            is_expert,
            is_active,
            email,
            dob,
            phone_no,
            organization_members(where: {role: {_eq: $role}, _and: {organization: {name: {_eq: $orgName}}}}) {
                role,
                organization {
                    name
                    org_email
                }
            },
            poi_accesses {
                poi
            },
            timeline_column_accesses {
                timeline_id
            },
        },
    }
`;

const GET_ORG_PATIENTS = gql`
    query user($role : String, $orgName: String) {
        user(where: {is_active: { _eq: true },
            _and: {organization_members: { role: {_eq: $role },
            _and: {organization: {name: {_eq: $orgName}}}}}}) {
            id,
            name,
            last_name,
            industry,
            specialty,
            is_expert,
            is_active,
            email,
            dob,
            phone_no,
            organization_members(where: {role: {_eq: $role}, _and: {organization: {name: {_eq: $orgName}}}}) {
                role,
                organization {
                    name
                    org_email
                }
            },
            poi_accesses {
                poi
            }
        },
    }
`;

const GET_USERS = gql`
    query user(
        $orgID: uuid,
        $role: [String!],
        $offset: Int,
        $limit: Int,
        $order_by: [user_order_by!],
        $nameFilter: String,
        $emailFilter: String,
        $roleFilter: String) {
        user(order_by: $order_by,
            offset: $offset,
            limit: $limit,
            where: {_and: {organization_members: { organization_id: {_eq: $orgID },
            role: {_in: $role }}, _and: { name: { _like: $nameFilter },
            email: { _like: $emailFilter }, is_active: { _eq: true },
            organization_members: { role: { _like: $roleFilter }}}}}) {
            id,
            name,
            last_name,
            email,
            phone_no,
            dob,
            created_at,
            industry,
            specialty,
            is_expert,
            is_active,
            organization_members(where: {organization_id: {_eq: $orgID}}){
                role,
                organization {
                    id,
                    name,
                    orgCode,
                    org_email
                }
            },
            poi_accesses{
                poi
                id
            }
        },
        user_aggregate(where: {_and: {organization_members: { organization_id: {_eq: $orgID },
            role: {_in: $role }}, _and: { name: { _like: $nameFilter },
            email: { _like: $emailFilter }, is_active: { _eq: true },
            organization_members: { role: { _like: $roleFilter }}}}}) {
            aggregate {
                count
            }
        }
    }
`;

/**
 * query to Delete user
 */
// const DELETE_USER = gql`
//     mutation unlinkUserFromOrg($userId: String!, $orgId: String!) {
//         unlinkUserFromOrgAndDeleteUserAccount(orgId: $orgId, userId: $userId) {
//             user_id
//         }
//     }
// `;

const DEACTIVATE_USER = gql`
mutation unlinkUserFromOrg($userId: uuid, $status: Boolean) {
    update_user(where: {id: {_eq: $userId}}, _set: {is_active: $status}) {
        returning {
            id
        }
    }
}
`;

const DEACTIVATE_ORG_USER = gql`
mutation unlinkUserFromOrg($orgId: uuid, $status: Boolean) {
    update_user(where: {organization_members: {organization_id: {_eq: $orgId}}}, _set: {is_active: $status}) {
        returning {
            id
        }
    }
}
`;

/**
 * query to validate the user
 */
const VALIDATE_USER_EMAIL = gql`
    query user($emailID: String){
        user(where: { email: { _eq: $emailID } }) {
            id
            name
            email
            created_at
            organization_members {
                organization_id
                role
            }
        }
      }
`;

/**
 * query to Add reference of user to organiozation member
 */
export const ADD_TO_ORGANIZATION_MEMBER = gql`
    mutation insertOrganizationMember($userID: uuid, $orgID: uuid, $userRole: String){
        insert_organization_member(objects:{ user_id: $userID, organization_id: $orgID, role: $userRole } ) {
            affected_rows
            returning {
                user_id
            }
        }
    } 
`;

/**
 * query to update the organiozation member
 */
const UPDATE_TO_ORGANIZATION_MEMBER = gql`
    mutation updateOrganizationMember($userID: uuid, $orgID: uuid, $userRole: String){
        update_organization_member(where: { user_id: {_eq: $userID}, organization_id: {_eq: $orgID} }, _set: {role: $userRole} ) {
            affected_rows
            returning {
                user_id
            }
        }
    } 
`;

/**
 * query to update the user name
 */
const UPDATE_USER = gql`
    mutation updateUser($userID: uuid,
        $userName: String,
        $last_name: String,
        $phone_no: String,
        $dob: timestamptz,
        $industry: String,
        $specialty: String,
        $is_expert: Boolean) {
        update_user(where: { id: {_eq: $userID} },
            _set: {
                name: $userName,
                last_name: $last_name,
                phone_no: $phone_no,
                dob: $dob,
                industry: $industry,
                specialty: $specialty,
                is_expert: $is_expert} ) {
            affected_rows
            returning {
                id
            }
        }
    } 
`;

/**
 * query to add new user
 */
export const ADD_NEW_USER = gql`
    mutation createUser(
        $email: String,
        $name: String,
        $orgId: uuid,
        $role: String,
        $last_name: String,
        $phone_no: String,
        $dob: timestamptz,
        $industry: String,
        $specialty: String,
        $is_expert: Boolean) { 
        signup(
            email: $email,
            name: $name,
            orgId: $orgId,
            role: $role,
            last_name: $last_name,
            phone_no: $phone_no,
            dob: $dob,
            industry: $industry,
            specialty: $specialty,
            is_expert: $is_expert ) {
            id 
        } 
    }
`;

/**
 * query to Update File Name
 */
const ADD_NEW_ORG = gql`
 mutation insertOrganization($name: String, $org_email: String, $code: String){
     insert_organization(objects:{ name: $name, org_email: $org_email, orgCode: $code} ) {
         affected_rows
         returning {
             id
         }
     }
 } 
 `;

export const GET_ORG_ID_BY_USER = gql`
    query organization_member($user_id: uuid) {
        organization_member(where: { user_id: {_eq:$user_id} }) {
            organization_id
        },
    }
`;

export const GET_ROLES_BY_ORG_ID = gql`
    query organization_member($orgID: uuid) {
        organization_member(where: { organization_id : {_eq:$orgID}, role:{_neq:"Doctor"}}) {
            role
            user_id
        },
    }
`;

const GET_ROLES = gql`
    query organization_member {
        organization_member(where: { role: { _neq: "Super Admin" } }) {
            role
        },
    }
    `;

const ADD_USER_POI = gql`
mutation insertPOIAcess($orgId: uuid, $userId: uuid, $poi: jsonb) {
    insert_poi_access(objects:{ user_id: $userId, organisation_id: $orgId, poi: $poi} ) {
        affected_rows
    }
} 
`;

const UPDATE_USER_POI = gql`
mutation updatePOIAccess($id: uuid, $poi: jsonb) {
    update_poi_access(where: { id: {_eq: $id} }, _set: {poi: $poi} ) {
        affected_rows
    }
} 
`;

export const Context = createContext({});

export const Provider = ({ children, currentOrg }) => {
    const {
        found, timelineMatch,
    } = useContext(TimelineContext);
    const { loggedUserType } = useContext(OrgContext);
    const orgId = currentOrg.id;
    const orgName = currentOrg.name;
    const [userDetailsContext, setUserDetailsContext] = useState(null);
    const pathURL = useLocation();
    const doctorRole = findRouteUserType(pathURL);

    const usersResponse = useQuery(GET_USERS, {
        variables: {
            orgID: doctorRole === 'Doctor' ? null : orgId,
            role: doctorRole,
            limit: 20,
            offset: 0,
            order_by: { updated_at: 'asc' },
            nameFilter: '%%',
            emailFilter: '%%',
            roleFilter: '%%',
        },
        skip: (getRouteValidation(['timelines']) || (loggedUserType === 'Super Admin' && timelineMatch?.params?.timelineId)),
    });
    const usersLoading = usersResponse.loading;
    const userError = usersResponse.error;
    let users = !usersResponse.loading ? usersResponse?.data?.user : undefined;
    users = _.uniqWith(users, _.isEqual);
    const totalUser = !usersResponse.loading ? usersResponse?.data?.['user_aggregate']?.aggregate?.count : 0;

    const allUserDataResponse = useQuery(
        GET_ALL_USERS,
        { skip: (found || timelineMatch?.params?.timelineId || getRouteValidation(['patients', 'users', 'doctors'])) }
    );
    let allUserData = !allUserDataResponse.loading ? allUserDataResponse?.data?.user : undefined;

    const doctorsResponse = useQuery(GET_ALL_DOCTORS, {
        variables: { role: 'Doctor' },
        skip: (found || (loggedUserType === 'Doctor' && timelineMatch?.params?.timelineId) || getRouteValidation(['patients', 'users', 'doctors'])),
    });
    const doctors = !doctorsResponse.loading && doctorsResponse?.data ? doctorsResponse?.data?.user : [];
    const patientsResponse = useQuery(GET_ORG_PATIENTS,
        {
            variables: { orgName: doctorRole === 'Doctor' ? null : orgName, role: 'Patient' },
            skip: (found || (loggedUserType === 'Super Admin' && timelineMatch?.params?.timelineId)
                || getRouteValidation(['patients', 'users', 'doctors'])),
        });
    const patients = !patientsResponse.loading && patientsResponse?.data ? patientsResponse?.data?.user : [];

    const roleResponse = useQuery(GET_ROLES, { skip: getRouteValidation(['timelines', 'templates', 'patients', 'users', 'doctors']) });
    const roles = !roleResponse.loading ? roleResponse?.data?.['organization_member'] : [];

    /**
     * Execute the query to validate the user email
     */
    const [getUser, { data }] = useLazyQuery(VALIDATE_USER_EMAIL, { fetchPolicy: 'no-cache' });

    useEffect(() => {
        setUserDetailsContext(data);
    }, [data]);

    const [deleteUser] = useMutation(DEACTIVATE_USER);
    const [deleteOrgsUser] = useMutation(DEACTIVATE_ORG_USER);
    const [insertOrganizationMember] = useMutation(ADD_TO_ORGANIZATION_MEMBER);
    const [updateOrganizationMember] = useMutation(UPDATE_TO_ORGANIZATION_MEMBER);
    const [updateUser] = useMutation(UPDATE_USER);
    const [createUser] = useMutation(ADD_NEW_USER, {
        refetchQueries: [
            {
                query: GET_ALL_USERS,
            },
        ],
    });
    const [createOrg] = useMutation(ADD_NEW_ORG);
    const [createUserPOI] = useMutation(ADD_USER_POI);
    const [updatePOI] = useMutation(UPDATE_USER_POI);

    /**
     * @function refetchUserDetails
     * @returns get updated response
     */
    const refetchUserDetails = (emailQuery = '') => usersResponse.refetch(emailQuery);

    /**
     * @function validateUserEmail
     * @param {value} userEmail
     * @returns get user details
     */
    const validateUserEmail = (userEmail) => {
        getUser({ variables: { emailID: userEmail } });
    };

    /**
     * @function addNewUser
     * @param {Object} userdetails
     * @returns updated row
     */
    const addNewUser = async (userdetails) => {
        let orgResult = null;
        if (userdetails.userRole === 'Doctor') {
            const orgVariables = {
                name: userdetails.name,
                code: userdetails.code,
                org_email: userdetails.org_email,
            };
            orgResult = await createOrg({ variables: orgVariables });
        }

        const variables = {
            email: userdetails.userEmail || userdetails?.email,
            name: userdetails.userName || userdetails?.firstName,
            orgId: orgResult?.data?.['insert_organization']?.returning[0]?.id || userdetails.orgID,
            role: userdetails.userRole,
            last_name: userdetails.last_name,
            phone_no: userdetails.phone_no,
            dob: userdetails.dob,
            industry: userdetails.industry,
            specialty: userdetails.specialty,
            is_expert: userdetails.is_expert || false,
        };

        const userResult = await createUser({ variables });
        if (orgResult && userResult) {
            const poiVariables = {
                userId: userResult?.data?.signup?.id,
                orgId: orgResult?.data?.['insert_organization']?.returning[0]?.id,
                poi: userdetails.poi,
            };

            await createUserPOI({ variables: poiVariables });
        }
        usersResponse.data.user = [];
        return userResult;
        // return orgResult.then(() => {
        // refetchUserDetails();
        // });
    };

    /**
     * @function updateUserOrganizationContext
     * @param {Object} userdetails
     * @returns updated row
     */
    const updateUserOrganizationContext = (userdetails) => {
        let orgResult;
        const queryObj = {
            variables: {
                userID: userdetails.userID,
                orgID: userdetails.orgID,
                userRole: userdetails.userRole,
                // userRole: !Array.isArray(doctorRole) || userdetails.userRole,
            },
        };

        if (userdetails.isUserFromSameOrg) {
            orgResult = updateOrganizationMember(queryObj);
            orgResult = updateUser({
                variables: {
                    userID: userdetails.userID,
                    userName: userdetails.userName,
                    last_name: userdetails.last_name || '',
                    phone_no: userdetails.phone_no || '',
                    dob: userdetails.dob || null,
                    industry: userdetails.industry,
                    specialty: userdetails.specialty,
                    is_expert: userdetails.is_expert || false,
                },
            });

            const poiVariables = {
                userId: userdetails.userID,
                orgId: userdetails.orgID,
                poi: userdetails.poi,
            };

            if (userdetails.poiId) {
                updatePOI({ variables: { id: userdetails.poiId, poi: userdetails.poi } });
            } else {
                createUserPOI({ variables: poiVariables });
            }
        } else {
            orgResult = insertOrganizationMember(queryObj);
        }
        usersResponse.data.user = [];
        // refetchUserDetails();
        return orgResult;
    };

    /**
     * @function deleteUserById
     * @param {string} id [primary key]
     * @returns get updated response
     */
    const deleteUserCotext = (userdetails) => {
        const result = deleteUser({
            variables: {
                userId: userdetails.userId,
                status: userdetails.status,
            },
        });

        return result.then(() => {
            refetchUserDetails();
        });
    };

    /**
     * @function deleteOrgUsersByOrgId
     * @param {string} id [primary key]
     * @returns get updated response
     */
    const deleteOrgUsersCotext = (userdetails) => {
        const result = deleteOrgsUser({
            variables: {
                orgId: userdetails.orgId,
                status: userdetails.status,
            },
        });

        return result.then(() => {
            refetchUserDetails();
        });
    };

    /**
     * Implement pagination
     * @param {object} pagingObj
     */
    const fetchUsers = (pagingObj) => {
        let sortObj = {};

        if (pagingObj.clearData) {
            usersResponse.data.user = [];
        }
        if (pagingObj.orderBy) {
            sortObj = { [pagingObj.orderBy.name]: pagingObj.orderBy.type };
        }

        if (pagingObj.filteringObj) {
            pagingObj.filteringObj.name = `%${pagingObj.filteringObj.name}%`;
            pagingObj.filteringObj.email = `%${pagingObj.filteringObj.email}%`;
            pagingObj.filteringObj.role = `%${pagingObj.filteringObj.role}%`;
        }

        usersResponse.fetchMore({
            variables: {
                orgID: doctorRole === 'Doctor' ? null : orgId,
                offset: pagingObj.offset,
                limit: pagingObj.limit,
                order_by: sortObj,
                nameFilter: pagingObj.filteringObj.name,
                emailFilter: pagingObj.filteringObj.email,
                roleFilter: pagingObj.filteringObj.role,
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return {
                    ...prev,
                    user: [...prev.user, ...fetchMoreResult.user],
                    user_aggregate: fetchMoreResult.user_aggregate,
                };
            },
        });
    };

    const fetchAllUserData = async () => {
        const response = await allUserDataResponse.fetchMore({
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return {
                    ...prev,
                    user: [...prev.user, ...fetchMoreResult.user],
                };
            },
        });
        allUserData = [...response?.data?.user];
        return allUserData;
    };

    const userContext = {
        usersLoading,
        userError,
        users,
        allUserData,
        fetchAllUserData,
        deleteUserCotext,
        deleteOrgUsersCotext,
        validateUserEmail,
        userDetailsContext,
        updateUserOrganizationContext,
        addNewUser,
        totalUser,
        fetchUsers,
        doctors,
        roles,
        patients,
    };

    // pass the value in provider and return
    return <Context.Provider value={userContext}>{children}</Context.Provider>;
};

export const { Consumer } = Context;
